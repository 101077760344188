import { Record } from 'immutable'
import { ReadonlyRecord } from '@bimser/core';
export interface IFlowViewerMenuItemsVisibility {
    flowProperties: boolean,
    flowHistory: boolean,
    flowDocuments: boolean
}

export type FLowViewerMenuItemsVisibility = ReadonlyRecord<IFlowViewerMenuItemsVisibility>;


export const FlowViewerMenuItemsVisibilityFactory = Record<IFlowViewerMenuItemsVisibility>({
    flowDocuments: true,
    flowHistory: false,
    flowProperties: false,
})

export const FlowViewerMenuItemsVisibilityFactoryFromJS = function (data: IFlowViewerMenuItemsVisibility) {
    return FlowViewerMenuItemsVisibilityFactory(data)
}