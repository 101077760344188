import IconCloseWindow from "@bimser/icons/16/close-window";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import 'devexpress-reporting/dx-reportdesigner';
import 'devexpress-reporting/dx-webdocumentviewer';
import trMessages from 'devextreme/localization/messages/tr.json';
import ko from 'knockout';
import React from 'react';
import { BCAjax } from '../../index';
import * as Styles from '../assets/reportContainerStyles.scss';
import { IReportDesignerProps } from '../entities';
const trCore = require('../assets/dx-analytics-core.tr.json');

export default function ReportDesigner(props: IReportDesignerProps) {

    const ref = React.useRef<HTMLDivElement>();
    const callbacks = React.useRef<any>();
    const designerModel = React.useRef<any>();

    React.useEffect(() => {

        const reportUrl = ko.observable(`reportId=${props.reportId}&projectName=${props.projectName}&reportName=${props.reportName}`);
        designerModel.current = ko.observable();

        const requestOptions = {
            host: `${(window as any).serviceConfig.DomainAddress}/api/ide`,
            getDesignerModelAction: "/Report/GetDesignerModel"
        };

        callbacks.current = {
            CustomizeMenuActions: function (s, e) {
                e.GetById('dxrd-exit').visible = false
                e.GetById('dxrd-newreport').visible = false;
                e.GetById('dxrd-newreport-via-wizard').visible = false;
                e.GetById('dxrd-open-report').visible = false;
                e.GetById('dxrd-save-as').visible = false;
                e.GetById('dxrd-exit').visible = false;

                let newReportAction = e.GetById('dxrd-newreport');
                if (newReportAction) {
                    newReportAction.clickAction = function () {
                        s.OpenReport(encodeURIComponent(reportUrl + '&newReport=true'));
                    }
                }
            },
            CustomizeLocalization: function (s, e) {
                e.LoadMessages(trCore);
                e.LoadMessages(trMessages);
            }
        };

        ajaxSetup.ajaxSettings = {
            headers: BCAjax.defaultHeaders,
            async: false
        };

        try {
            ko.applyBindings({
                reportUrl: reportUrl,
                requestOptions: requestOptions,
                callbacks: callbacks,
                designerModel: designerModel,
            }, ref.current);
        } catch (error) {
            console.log(error);
        }

        return () => {
            ko.cleanNode(ref.current);
        }

    }, []);

    function getPanelHeader() {
        return (
            <div className={Styles.WorkflowMenuHeader}>
                <div className={Styles.WorkflowMenuTitle}>{"Report Designer"}</div>
                <div className={Styles.WorkFlowMenuEvents}>
                    <div className={Styles.Actions}>
                        <div className={Styles.ButtonClose}>
                            <IconCloseWindow onClick={props.exitDesigner} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={Styles.reportDesignerContent}>
            {getPanelHeader()}
            <div ref={ref} className={Styles.reportDesigner} data-bind="dxReportDesigner: $data"></div>
        </div>
    )
}