import { IProps, ReadonlyRecord } from '@bimser/core';
import { List, Record } from 'immutable';

//export interface TreeData {
//    key: string;
//    value: string;
//    label?: React.ReactNode;
//    title?: React.ReactNode;
//    children?: TreeData[];
//    selectable?: boolean;
//    disableCheckbox?: boolean;
//    disable?: boolean;
//    isLeaf?: boolean;
//}

export interface IBaseTreeData {
    key: string;
    value: string;
    label?: React.ReactNode;
    title?: React.ReactNode;
    selectable?: boolean;
    disableCheckbox?: boolean;
    disable?: boolean;
    isLeaf?: boolean;
    externalData?: any
}

export interface ITreeData extends IBaseTreeData {
    children?: ITreeData[]
}

export interface ReadOnlyTreeData extends IBaseTreeData {
    children?: List<TreeData>;
}

export type TreeData = ReadonlyRecord<ReadOnlyTreeData>;

export const TreeDataFactory = Record<ReadOnlyTreeData>({
    key: null,
    value: null,
    label: null,
    title: null,
    selectable: null,
    disableCheckbox: null,
    disable: null,
    isLeaf: null,
    children: List(),
    externalData: null
});

export const TreeDataFactoryFromJS = function (data: ITreeData) {

    let children: Array<TreeData> = Array();

    if (data.children && data.children.length) {

        children = data.children && data.children.length ? data.children.map(function (value: ITreeData) {
            return TreeDataFactoryFromJS(value);
        }) : [];

    }

    return TreeDataFactory({
        key: data.key,
        value: data.value,
        label: data.label,
        title: data.title,
        selectable: data.selectable,
        disableCheckbox: data.disableCheckbox,
        disable: data.disable,
        isLeaf: data.isLeaf,
        children: List(children),
        externalData: data.externalData
    });
}


/*** */

export interface TreeDataSimpleMode {
    id?: string;
    pId?: string;
    rootPId?: string;
}

export enum treeSelectShowCheckedStrategy {
    SHOW_ALL = "SHOW_ALL", SHOW_PARENT = "SHOW_PARENT", SHOW_CHILD = "SHOW_CHILD"
}

export interface ITreeSelectLoadingArgs {
    allow: boolean,
    options: Array<ITreeData>
}

export default interface ITreeSelectProps extends IProps {

    treeDataSimpleMode?: boolean | TreeDataSimpleMode;
    treeData?: Array<ITreeData>;
    size?: "middle" | "small" | "large";
    showSearch?: boolean;
    dropdownMatchSelectWidth?: boolean;
    disabled?: boolean;
    allowClear?: boolean;
    value?: string | Array<any>;
    defaultValue?: string | Array<any>;
    multiple?: boolean;
    readOnly?: boolean;
    searchPlaceholder?: string;
    dropdownStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    treeDefaultExpandAll?: boolean;
    treeCheckable?: boolean | React.ReactNode;
    treeDefaultExpandedKeys?: Array<string>;
    treeExpandedKeys?: Array<string>;
    suffixIcon?: React.ReactNode;
    showArrow?: boolean;
    filterTreeNode?: (inputValue: string, treeNode: any) => boolean | boolean;
    treeNodeFilterProp?: string;
    treeNodeLabelProp?: string;
    loadData?: (node: any) => Promise<unknown>;
    showCheckedStrategy?: treeSelectShowCheckedStrategy;
    labelInValue?: boolean;
    treeCheckStrictly?: boolean;
    getPopupContainer?: (triggerNode: Element) => HTMLElement;
    onSelect?: (value: any, option?: any) => void;
    onChange?: (value: any, label?: any, extra?: any) => void;
    onSearch?: (value: any) => void;
    dropdownClassName?: string;
    title?: string;
    maxTagTextLength?: number;
    maxTagCount?: number;
    maxTagPlaceholder?: (omittedValues: any[]) => React.ReactNode;

    onLoadOptions?: () => Promise<ITreeSelectLoadingArgs> | Array<ITreeData>;
    isRunOnLoadOptionsStartup?: boolean;
    dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
    notFoundContent?: React.ReactNode | string;
    onTreeExpand?: (expandedKeys: Array<string>) => void;
}