import * as React from "react";
import { findMessage } from "../BCIntl";
import * as Styles from './assets/flexStartupStyles.scss';
import ButtonList from './components/ButtonList';
import RecentItems from "./components/RecentItems";
import StartupPlugin from "./components/StartupPlugin";
import {
    IFlexStartupProps, IFlexStartupState, IReadonlyRecentItem, IRecentItem, RecentItem,
    RecentItemFactory, RecentItemFactoryFromJS, IRecentItems,
    IButtonList, IButtonListItem, ButtonListItem, ButtonListItemFactory, ButtonListItemFactoryFromJS, IReadonlyButtonListItem
} from './entities';

export default class BCFlexStartup extends React.Component<IFlexStartupProps, IFlexStartupState> {

    constructor (props: IFlexStartupProps) {
        super(props);
        this.onRecentProjectsSearch = this.onRecentProjectsSearch.bind(this);

        this.state = {
            recentProjectList: this.props.recentProjectList.items,
            recentItemsSearchValue: ""
        };
    }

    static getDerivedStateFromProps(props: IFlexStartupProps, state: IFlexStartupState) {
        if (props.recentProjectList?.items?.length) {
            return ({
                recentProjectList: state.recentItemsSearchValue
                    ? props.recentProjectList.items.filter(item => item.title.toLowerCase().includes(state.recentItemsSearchValue.toLowerCase()))
                    : props.recentProjectList.items
            });
        } else {
            return null;
        }
    }

    onRecentProjectsSearch(value: string) {
        this.setState({ recentItemsSearchValue: value })
    }

    render() {

        let colSize: string;
        let firstHalfClassNames: string = `col-md-${colSize}`;

        if (this.props.windowSize.width > 1000) {
            colSize = '6';
            firstHalfClassNames = [`col-md-${colSize}`, Styles.borderRight].join(' ');
        } else {
            colSize = '12';
            firstHalfClassNames = [`col-md-${colSize}`, Styles.marginBottom].join(' ');
        }

        return (
            <div className={Styles.flexStartup}>
                <div className="row no-gutters">
                    <div className={firstHalfClassNames}>
                        <StartupPlugin
                            title={findMessage.get('100655')}
                            description={this.props.getStarted.description}
                            paddingBottom={true}
                        />
                        <div className={"row mb-4"} />
                        <StartupPlugin
                            title={findMessage.get('100657')}
                            description={findMessage.get('101872')}
                            scrollbarHeight={213}
                            paddingBottom={true}
                        >
                            <ButtonList
                                items={this.props.newProjectList.items}
                                onClick={this.props.onNewProjectBtnClick}
                            />
                        </StartupPlugin>
                        <StartupPlugin
                            title={findMessage.get('100014')}
                            description={findMessage.get('100795')}
                            scrollbarHeight={213}
                            paddingBottom={false}
                        >
                            <ButtonList
                                items={this.props.openProjectList.items}
                                onClick={this.props.onOpenProjectBtnClick}
                            />
                        </StartupPlugin>
                    </div>
                    <div className={`col-md-${colSize}`}>
                        <StartupPlugin
                            title={findMessage.get('101873')}
                            scrollbarHeight={374}
                            searchable
                            onSearch={this.onRecentProjectsSearch}
                            paddingBottom={false}
                        >
                            <RecentItems
                                items={this.state.recentProjectList}
                                onClick={this.props.onRecentItemClick}
                                onTogglePin={this.props.recentProjectList.onTogglePin}
                                loading={this.props.recentProjectList.loading}
                            />
                        </StartupPlugin>
                    </div>
                </div>
            </div>
        )
    }

}

export {
    IFlexStartupProps, IRecentItems, IRecentItem, IReadonlyRecentItem, RecentItem, RecentItemFactory, RecentItemFactoryFromJS,
    IButtonList, IButtonListItem, ButtonListItem, ButtonListItemFactory, ButtonListItemFactoryFromJS, IReadonlyButtonListItem,
    RecentItems
};

