import Divider from 'antd/lib/divider';
import * as React from "react";
import * as Styles from './assets/divider.scss';
import { IDividerProps } from './entities';

const BCDivider: React.FC<IDividerProps> = React.memo(props =>
    <Divider
        className={[Styles.customDividerVertical, props.className].join(' ')}
        type={props.type}
        style={props.styles}
    />
);

export { IDividerProps };
export default BCDivider;