import mimeTypeExtensionList from "./mimeTypeExtensionList";

const getMimeTypeFromExtension = (extension: string) => {
    const index = mimeTypeExtensionList.findIndex(t => t.extension.includes(extension.toLowerCase()));
    if (index > -1) {
        return mimeTypeExtensionList[index].mimeType[0];
    }
    return null;
}

export default getMimeTypeFromExtension;