import { connect } from "react-redux";
import HoldingForm from "../components";
import { closeHRFormTransaction, saveHRFormTransaction } from "../../../../actions"
import IHoldingFormProps from "../entities/IProps";
import { WebInterfaceBaseState } from "../../../../../../common/base/state";
import { IWebInterfaceAction } from "../../../../../../common/entities";

const mapStateToProps = function (state: WebInterfaceBaseState, props: IHoldingFormProps): IHoldingFormProps {
    return {
    };
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IHoldingFormProps {
    return {
        onClose: function(data: IWebInterfaceAction) {
            dispatch(closeHRFormTransaction(data));
        },
        onSave: function(data: IWebInterfaceAction) {
            dispatch(saveHRFormTransaction(data));
        }
    };
}

// react - redux connection
const HoldingFormContainer = connect(mapStateToProps, mapDispatchToProps)(HoldingForm);

export default HoldingFormContainer;