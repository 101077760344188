import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";

interface IBaseLoginSliderItem {
    imageDownloadUrl: {
        downloadUrl: string,
        mimeType: string,
        path: string
    },
    url: string
}

export interface ILoginSliderItem extends IBaseLoginSliderItem {
    caption: IDictionary<string>,
    text: IDictionary<string>
}

export interface IReadonlyLoginSliderItem extends IBaseLoginSliderItem {
    caption: Map<string, string>,
    text: Map<string, string>
}

export type LoginSliderItem = ReadonlyRecord<IReadonlyLoginSliderItem>;

export const LoginSliderItemFactory = Record<IReadonlyLoginSliderItem>({
    caption: Map(),
    text: Map(),
    imageDownloadUrl: null,
    url: null
});

export const LoginSliderItemFactoryFromJS = function (data: ILoginSliderItem) {
    return LoginSliderItemFactory({
        caption: Map(data.caption),
        text: Map(data.text),
        imageDownloadUrl: data.imageDownloadUrl,
        url: data.url
    });
}