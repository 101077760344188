import { DmObjectType } from "@bimser/common";
import { IDictionary, isNullOrUndefined, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";

export interface IBaseInherit {
    id?: number,
    type?: DmObjectType
}

export interface IInheritContent extends IBaseInherit {
    name: IDictionary<string>,
    child: IInheritContent
}

export interface IReadonlyInherit extends IBaseInherit {
    name: Map<string, string>,
    child: InheritContent
}

export type InheritContent = ReadonlyRecord<IReadonlyInherit>;

export const InheritFactory = Record<IReadonlyInherit>({
    id: null,
    type: 0,
    name: Map(),
    child: null
});

export const InheritContentFactoryFromJS = function (data: IInheritContent): any {

    if (!isNullOrUndefined(data)) {
        return InheritFactory({
            id: data.id,
            type: data.type,
            name: Map(data.name),
            child: InheritContentFactoryFromJS(data.child)
        });
    }
    return null;
}