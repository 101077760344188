import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseSigner {
    roleName: string;
    email: string;
    fullName: string;
}

export interface ISigner extends IBaseSigner {
    
}

export interface IReadonlySigner extends IBaseSigner {
    
}

export type Signer = ReadonlyRecord<IReadonlySigner>;

export const SignerFactory = Record<IReadonlySigner>({
    roleName: null,
    email: null,
    fullName: null
});

export const SignerFactoryFromJS = function (data: ISigner) {
    return SignerFactory(data);
}