import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { DmObjectMetadataProperty, DmObjectMetadataPropertyFactory, DmObjectMetadataPropertyFactoryFromJS, IDmObjectMetadataProperty } from "./IDmObjectMetadataProperty";
import { DmObjectProperty, DmObjectPropertyFactoryFromJS, IDmObjectProperty } from "./IDmObjectProperty";

export interface IBaseDmObjectProperties {

}
export interface IDmObjectProperties extends IBaseDmObjectProperties {
    accessLevel: IDmObjectProperty,
    accessLevelPolicy: IDmObjectProperty,
    accessPassword: IDmObjectProperty,
    allowedExt: IDmObjectProperty,
    closingDate: IDmObjectProperty,
    compressionEnable: IDmObjectProperty,
    controlledTerminology: IDmObjectProperty,
    defaultStorageId: IDmObjectProperty,
    encryptionEnable: IDmObjectProperty,
    indexSettings: IDmObjectProperty,
    keepMinorVersionCount: IDmObjectProperty,
    keepVersionCount: IDmObjectProperty,
    maxAllowedFileSize: IDmObjectProperty,
    allowedMimeTypes: IDmObjectProperty,
    metaData: IDmObjectMetadataProperty,
    retentionPolicy: IDmObjectProperty,
    startWorkFlow: IDmObjectProperty,
    versioningType: IDmObjectProperty,
    volumeEnable: IDmObjectProperty
}

export interface IReadonlyDmObjectProperties extends IBaseDmObjectProperties {
    accessLevel: DmObjectProperty,
    accessLevelPolicy: DmObjectProperty,
    accessPassword: DmObjectProperty,
    allowedExt: DmObjectProperty,
    closingDate: DmObjectProperty,
    compressionEnable: DmObjectProperty,
    controlledTerminology: DmObjectProperty,
    defaultStorageId: DmObjectProperty,
    encryptionEnable: DmObjectProperty,
    indexSettings: DmObjectProperty,
    keepMinorVersionCount: DmObjectProperty,
    keepVersionCount: DmObjectProperty,
    maxAllowedFileSize: DmObjectProperty,
    allowedMimeTypes: DmObjectProperty,
    metaData: DmObjectMetadataProperty,
    retentionPolicy: DmObjectProperty,
    startWorkFlow: DmObjectProperty,
    versioningType: DmObjectProperty,
    volumeEnable: DmObjectProperty
}

export type DmObjectProperties = ReadonlyRecord<IReadonlyDmObjectProperties>;

export const DmObjectPropertiesFactory = Record<IReadonlyDmObjectProperties>({
    accessLevel: null,
    accessLevelPolicy: null,
    accessPassword: null,
    allowedExt: null,
    closingDate: null,
    compressionEnable: null,
    controlledTerminology: null,
    defaultStorageId: null,
    encryptionEnable: null,
    indexSettings: null,
    keepMinorVersionCount: null,
    keepVersionCount: null,
    maxAllowedFileSize: null,
    metaData: DmObjectMetadataPropertyFactory(),
    retentionPolicy: null,
    startWorkFlow: null,
    versioningType: null,
    volumeEnable: null,
    allowedMimeTypes: null
});

export const DmObjectPropertiesFactoryFromJS = function (data: IDmObjectProperties) {
    if (!data) return DmObjectPropertiesFactory();
    return DmObjectPropertiesFactory({
        accessLevel: DmObjectPropertyFactoryFromJS(data.accessLevel),
        accessLevelPolicy: DmObjectPropertyFactoryFromJS(data.accessLevelPolicy),
        accessPassword: DmObjectPropertyFactoryFromJS(data.accessPassword),
        allowedExt: DmObjectPropertyFactoryFromJS(data.allowedExt),
        closingDate: DmObjectPropertyFactoryFromJS(data.closingDate),
        compressionEnable: DmObjectPropertyFactoryFromJS(data.compressionEnable),
        controlledTerminology: DmObjectPropertyFactoryFromJS(data.controlledTerminology),
        defaultStorageId: DmObjectPropertyFactoryFromJS(data.defaultStorageId),
        encryptionEnable: DmObjectPropertyFactoryFromJS(data.encryptionEnable),
        indexSettings: DmObjectPropertyFactoryFromJS(data.indexSettings),
        keepMinorVersionCount: DmObjectPropertyFactoryFromJS(data.keepMinorVersionCount),
        keepVersionCount: DmObjectPropertyFactoryFromJS(data.keepVersionCount),
        maxAllowedFileSize: DmObjectPropertyFactoryFromJS(data.maxAllowedFileSize),
        allowedMimeTypes: DmObjectPropertyFactoryFromJS(data.allowedMimeTypes),
        metaData: DmObjectMetadataPropertyFactoryFromJS(data.metaData),
        retentionPolicy: DmObjectPropertyFactoryFromJS(data.retentionPolicy),
        startWorkFlow: DmObjectPropertyFactoryFromJS(data.startWorkFlow),
        versioningType: DmObjectPropertyFactoryFromJS(data.versioningType),
        volumeEnable: DmObjectPropertyFactoryFromJS(data.volumeEnable)
    });
}