import { findMessage } from "../BCIntl";
import * as React from "react";
import * as Styles from "./assets/styles.scss";
import BCCollapse from "../BCCollapse";
import BCScrollbars from "../BCScrollbars";
import BCButton from "../BCButton";
import IconHome from "@bimser/icons/16/home";
const SomethingWentWrongIcon = require("./assets/something-went-wrong.svg");
import { createGuid } from "@bimser/core";

export default class ErrorBoundary extends React.Component<{ mobile?: any }, { error: Error, errorInfo: { componentStack: string } }> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
        this.getContent = this.getContent.bind(this);
        this.getCollapseItems = this.getCollapseItems.bind(this);
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error);
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    getCollapseItems(componentStack: string) {
        return [
            {
                content: this.getContent(componentStack),
                key: "0",
                label: findMessage.get("102073"),
                expanded: false
            }
        ]
    }

    getContent(componentStack: string) {
        return (
            <BCScrollbars autoHide cssClass={Styles.scrollbars}>
                <div className={Styles.details}>
                    {this.state?.error?.stack + "\n" + componentStack}
                </div>
            </BCScrollbars>
        )
    }

    render() {
        if (this.state.errorInfo) {
            let message: any = {
                typeName: "ShowMessageAction",
                approvalActions: [],
                rejectionActions: [],
                dialogType: "Popup",
                alertType: "didCatch",
                id: createGuid(),
                messages: findMessage.get("102284"),
                name: findMessage.get("102284"),
                enabled: true,
            }
            this.props.mobile?.MobileView.mobileClientSideMessages([message]);

            return (
                <div className={Styles.errorBoundary}>
                    <div className={Styles.somethingsWentWrongContainerStyle}>
                        <img src={SomethingWentWrongIcon.default} className={Styles.somethingsWentWrongStyle} />
                    </div>
                    <h2>{findMessage.get("102284")}</h2>
                    <BCButton
                        icon={<IconHome height="20px" />}
                        onClick={() => location.reload()}
                        type="link"
                    >
                        {findMessage.get("103030")}
                    </BCButton>
                    <BCCollapse
                        classNames={[Styles.collapse]}
                        items={this.getCollapseItems(this.state.errorInfo.componentStack)}
                    />
                </div>
            );
        }

        return this.props.children;
    }
}