import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export enum FilterType {
    Equal = 1,
    Greater = 2,
    GreaterOrEqual = 3,
    Less = 4,
    LessOrEqual = 5,
    Contain = 6,
    StartsWith = 7,
    EndsWith = 8
}
export enum ItemType {
    String = 1,
    Int16 = 2,
    Int32 = 3,
    Int64 = 4,
    DateTime = 5,
    DateTimeOffset = 6,
    Boolean = 7,
    Double = 8,
    Float = 9,
    Decimal = 10
}
export interface IBaseLoadFilterItem {
    Column: string;
    Not: boolean;
    Type: FilterType;
    Value: any;
    ItemType: ItemType;
}

export interface ILoadFilterItem extends IBaseLoadFilterItem { }

export interface IReadonlyLoadFilterItem extends IBaseLoadFilterItem { }

export type LoadFilterItem = ReadonlyRecord<IReadonlyLoadFilterItem>;

export const LoadFilterItemFactory = Record<IReadonlyLoadFilterItem>({
    Column: null,
    ItemType: null,
    Not: false,
    Type: null,
    Value: null,

});

export const LoadFilterItemFactoryFromJS = function (data: ILoadFilterItem) {
    return LoadFilterItemFactory(data);
}