import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseFlowDocumentSaveOption {
    eventId: number,
    secretKey: string
}

export interface IFlowDocumentSaveOption extends IBaseFlowDocumentSaveOption {}

export interface IReadonlyFlowDocumentSaveOption extends IBaseFlowDocumentSaveOption {}

export type FlowDocumentSaveOption = ReadonlyRecord<IReadonlyFlowDocumentSaveOption>;

export const FlowDocumentSaveOptionFactory = Record<IReadonlyFlowDocumentSaveOption>({
    eventId: null,
    secretKey: null
});

export const FlowDocumentSaveOptionFactoryFromJS = function (data: IFlowDocumentSaveOption) {
    return FlowDocumentSaveOptionFactory(data);
}