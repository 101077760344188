export const LOAD = 'DMExplorer.LOAD';
export const SET_LOADED_DATA = 'DMExplorer.SET_LOADED_DATA';
export const UPDATE_FILTER = 'DMExplorer.UPDATE_FILTER';
export const UPDATE_LOADING_STATUS = 'DMExplorer.UPDATE_LOADING_STATUS';
export const UPDATE_OPENED_STATUS = 'DMExplorer.UPDATE_OPENED_STATUS';
export const UPDATE_CURRENT_ITEM = 'DMExplorer.UPDATE_CURRENT_ITEM';
export const SET_SELECTION = 'DMExplorer.SET_SELECTION';
export const PUSH_NAVIGATION_HISTORY = 'DMExplorer.PUSH_NAVIGATION_HISTORY';
export const POP_NAVIGATION_HISTORY = 'DMExplorer.POP_NAVIGATION_HISTORY';
export const CLEAR_NAVIGATION_HISTORY = 'DMExplorer.CLEAR_NAVIGATION_HISTORY';
export const GO_UP = 'DMExplorer.GO_UP';
export const ON_SEARCH_TEXT_CHANGED = 'DMExplorer.ON_SEARCH_TEXT_CHANGED';
export const CLEAR_DATA = 'DMExplorer.CLEAR_DATA';
export const PATH_CLICKED = 'DMExplorer.PATH_CLICKED';
export const SET_NAVIGATION_PATH = 'DMExplorer.SET_NAVIGATION_PATH';