import * as React from "react";
import { BCCombobox, BCInput, BCInputMultiLanguage, BCLabel, BCMultipleCombobox, IComboboxSelectedChangeEventArgs } from "../..";
import { FormItemTypes, IItem } from "@bimser/core";
import { ConditionalStatementEntities } from "../../BCConditionalStatement";
import { findMessage } from "../../BCIntl";
import * as Styles from "../assets/styles.scss";
import { IActionContentProps } from "../entities/IActionContentProps";

const acceptedControls: string[] = [
    FormItemTypes.EControlTypes.TextBox,
    FormItemTypes.EControlTypes.MaskInput,
    FormItemTypes.EControlTypes.Password,
    FormItemTypes.EControlTypes.ComboBox,
    FormItemTypes.EControlTypes.Lookup,
    FormItemTypes.EControlTypes.ListBox,
    FormItemTypes.EControlTypes.RadioList,
    FormItemTypes.EControlTypes.TextArea,
    FormItemTypes.EControlTypes.NumberBox,
    FormItemTypes.EControlTypes.DateTimePicker,
    FormItemTypes.EControlTypes.DateRangePicker,
    FormItemTypes.EControlTypes.TimePicker,
    FormItemTypes.EControlTypes.Rate,
    FormItemTypes.EControlTypes.SearchBox,
    FormItemTypes.EControlTypes.TagBox,
    FormItemTypes.EControlTypes.TreeSelect,
    FormItemTypes.EControlTypes.DataGrid
];

const dialogTypes: IItem[] = [
    { name: 'Popup', text: 'Popup', key: 'Popup', value: 'Popup' },
    { name: 'Toaster', text: 'Toaster', key: 'Toaster', value: 'Toaster' },
    { name: 'Summary', text: 'Summary', key: 'Summary', value: 'Summary' }
];

const alertTypes: IItem[] = [
    { name: 'Success', text: findMessage.get('101879'), key: 'Success', value: 'Success' },
    { name: 'Error', text: findMessage.get('100926'), key: 'Error', value: 'Error' },
    { name: 'Warning', text: findMessage.get('100652'), key: 'Warning', value: 'Warning' },
    { name: 'Info', text: findMessage.get('100647'), key: 'Info', value: 'Info' },
    { name: 'Blocked', text: findMessage.get('102703'), key: 'Blocked', value: 'Blocked' },
    { name: 'SevereWarning', text: findMessage.get('102702'), key: 'SevereWarning', value: 'SevereWarning' }
];

const messageTypes: IItem[] = [
    { name: 'ShowMessageAction', text: findMessage.get('101301'), key: 'ShowMessageAction', value: 'ShowMessageAction' },
    { name: 'ValidationFailureAction', text: findMessage.get('101305'), key: 'ValidationFailureAction', value: 'ValidationFailureAction' },
    { name: 'ConfirmationAction', text: findMessage.get('100124'), key: 'ConfirmationAction', value: 'ConfirmationAction' }
];

const ShowMessageAction = React.memo((props: IActionContentProps<ConditionalStatementEntities.IShowMessageAction> & { actions: Array<ConditionalStatementEntities.IBaseAction> }) => {

    const renderConfirmationActionFields = () => {
        return (
            <>
                <div className={'row'}>
                    <div className={'col-sm-4 mb-2'}>
                        <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('101936')}>
                            {findMessage.get('101936')}
                        </BCLabel>
                    </div>
                    <div className={'col-sm-8 mb-2'}>
                        <BCMultipleCombobox
                            value={props.action.approvalActions.map(i => i.id)}
                            maxTagCount={2}
                            options={props.actions.filter(i => i.id !== props.action.id).map(i => { return { key: i.id, text: i.name, value: i.id, disabled: !i.enabled } })}
                            showSearch={true}
                            onSelectedChange={e => {

                                const selectedItems = e.data as string[];

                                props.onActionChanged({
                                    ...props.action,
                                    approvalActions: props.actions.filter(action => {
                                        return selectedItems.findIndex(i => action.id === i) > -1
                                    })
                                });

                            }}
                            placeholder={findMessage.get('101519')}
                            className={Styles.selectConfirmActions}
                            mode={"tags"}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-sm-4 mb-2'}>
                        <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('101937')}>
                            {findMessage.get('101937')}
                        </BCLabel>
                    </div>
                    <div className={'col-sm-8 mb-2'}>
                        <BCMultipleCombobox
                            value={props.action.rejectionActions.map(i => i.id)}
                            maxTagCount={2}
                            options={props.actions.filter(i => i.id !== props.action.id).map(i => { return { key: i.id, text: i.name, value: i.id, disabled: !i.enabled } })}
                            showSearch={true}
                            onSelectedChange={e => {

                                const selectedItems = e.data as string[];

                                props.onActionChanged({
                                    ...props.action,
                                    rejectionActions: props.actions.filter(action => {
                                        return selectedItems.findIndex(i => action.id === i) > -1
                                    })
                                });

                            }}
                            placeholder={findMessage.get('101519')}
                            className={Styles.selectConfirmActions}
                            mode={"tags"}
                        />
                    </div>
                </div>
            </>
        );
    }

    const renderOtherFields = () => {
        return (
            <>
                <div className={'row'}>
                    <div className={'col-sm-4 mb-2'}>
                        <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('101299')}>
                            {findMessage.get('101299')}
                        </BCLabel>
                    </div>
                    <div className={'col-sm-8 mb-2'}>
                        <BCCombobox
                            value={props.action.dialogType}
                            options={dialogTypes}
                            showSearch={false}
                            style={{ width: '100%' }}
                            onSelectedChange={e => {
                                props.onActionChanged({
                                    ...props.action,
                                    dialogType: e.data as ConditionalStatementEntities.DialogType
                                })
                            }}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-sm-4 mb-2'}>
                        <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('101300')}>
                            {findMessage.get('101300')}
                        </BCLabel>
                    </div>
                    <div className={'col-sm-8 mb-2'}>
                        <BCCombobox
                            value={props.action.alertType}
                            options={alertTypes}
                            showSearch={false}
                            style={{ width: '100%' }}
                            onSelectedChange={e => {
                                props.onActionChanged({
                                    ...props.action,
                                    alertType: e.data as ConditionalStatementEntities.AlertType
                                })
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderDialogAlertTypes = () => {
        switch (props.action.typeName) {
            case 'ConfirmationAction': return renderConfirmationActionFields()
            default: return renderOtherFields()
        }
    }

    const getWillHightlightFields = (): IItem[] => {
        let items: IItem[] = [];
        props.formItems?.keySeq().forEach((key) => {
            let item = props.formItems?.get(key);
            if (acceptedControls.includes(item?.type)) {
                items.push({ key: item.id, value: item.id, text: item.id });
            }
        });
        return items;
    }

    return (
        <div className={'mb-2 mt-2 mr-2 ml-2'}>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('100131')}>
                        {findMessage.get('100131')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <BCInput
                        value={props.action.name}
                        onChange={e => {
                            props.onActionChanged({
                                ...props.action,
                                name: e.data
                            })
                        }}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('100296')}>
                        {findMessage.get('100296')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <BCInputMultiLanguage
                        values={props.action.messages || {}}
                        currentLanguage={props.currentLanguage}
                        supportedLanguages={props.supportedLanguages}
                        onLanguageValueChange={(culture, value) => {
                            props.onActionChanged({
                                ...props.action,
                                messages: {
                                    ...props.action.messages,
                                    [culture]: value
                                }
                            })
                        }}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get('100331')}>
                        {findMessage.get('100331')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <BCCombobox
                        value={props.action.typeName}
                        options={messageTypes}
                        showSearch={false}
                        style={{ width: '100%' }}
                        onSelectedChange={e => {
                            props.onActionChanged({
                                ...props.action,
                                typeName: e.data as ConditionalStatementEntities.ActionType
                            })
                        }}
                    />
                </div>
            </div>

            {props.action.typeName === "ValidationFailureAction" &&
                <div className={'row'}>
                    <div className={'col-sm-4 mb-2'}>
                        <BCLabel className={Styles.labelCaptionStyles} title={findMessage.get("102720")}>
                            {findMessage.get("102720")}
                        </BCLabel>
                    </div>
                    <div className={'col-sm-8 mb-2'}>
                        <BCMultipleCombobox
                            showSearch
                            options={getWillHightlightFields()}
                            value={props.action.highlightList}
                            allowClear
                            onSelectedChange={(args: IComboboxSelectedChangeEventArgs) => {
                                props.onActionChanged({
                                    ...props.action,
                                    highlightList: args.data as string[]
                                })
                            }}
                            mode={"tags"}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>}
            {renderDialogAlertTypes()}
        </div>
    )

})

export default ShowMessageAction