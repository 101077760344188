import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { Field, FieldFactory, FieldFactoryFromJS, IField } from "../IField";
import { BaseValueAssignActionFactoryObject, BaseValueAssignActionFactoryObjectFromJS, IBaseValueAssignAction, ReadOnlyBaseValueAssignAction } from "./IBaseValueAssignAction";

export interface IValueAssignActionWithSelection extends IBaseValueAssignAction {
    selectedField: IField
}

export interface ReadOnlyValueAssignActionWithSelection extends ReadOnlyBaseValueAssignAction {
    selectedField: Field
}

export type ValueAssignActionWithSelection = ReadonlyRecord<ReadOnlyValueAssignActionWithSelection>;

export const ValueAssignActionWithSelectionFactory = Record<ReadOnlyValueAssignActionWithSelection>({
    selectedField: FieldFactory(),
    ...BaseValueAssignActionFactoryObject
});

export const ValueAssignActionWithSelectionFactoryFromJS = function (data: IValueAssignActionWithSelection) {
    return ValueAssignActionWithSelectionFactory({
        ...BaseValueAssignActionFactoryObjectFromJS(data),
        selectedField: FieldFactoryFromJS(data.selectedField)
    });
}