import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record, List } from "immutable";

export interface IBaseSettings {
    processorConfigName: string
}

export interface ISettingsContent extends IBaseSettings {
    mimeTypes: Array<string>
}

export interface IReadonlySettings extends IBaseSettings {
    mimeTypes: List<string>
}

export type SettingsContent = ReadonlyRecord<IReadonlySettings>;

export const SettingsFactory = Record<IReadonlySettings>({
    processorConfigName: "",
    mimeTypes: List()
});

export const SettingsContentFactoryFromJS = function (data: ISettingsContent) {

    return SettingsFactory({
        processorConfigName: data.processorConfigName,
        mimeTypes: List(data.mimeTypes)
    });
}