import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { IPagination } from '../entities';

const getPagination = (state: BaseState) => state.DMExplorer.itemsData?.get(state.DMExplorer.currentLocation.id)?.pagination;

const getPaginationSelector = createSelector(
    [
        getPagination,
    ],
    (pagination): IPagination => {
        return pagination ? pagination.toJS() : { skip: 1, take: 24 };
    }
);

export default getPaginationSelector;
