import { BCAjax, BCNotification, findMessage } from '@bimser/components';
import { base64Converter } from "@bimser/core";
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import DMExplorerContainer from '../../DMExplorer/containers';
import LoginMainContainer from '../../Login/containers/LoginMainContainer';
import UITourContainer from '../../UITour/containers';
import * as Style from '../assets/style.scss';
import IRouterCProps from '../entities/IRouterCProps';
import IRouterProps from '../entities/IRouterProps';

export default class Router extends React.Component<IRouterProps & IRouterCProps, { fromMobile: boolean }> {

    constructor(props: IRouterProps & IRouterCProps) {
        super(props);

        this.state = {
            fromMobile: false
        }
    }

    componentDidMount() {
        let synergyLogonApp: any = this.props.SynergyLogonApp;
        if (location.hash.includes("mobile")) {
            this.setState({ fromMobile: true })
        } else if (location.hash.includes("delegate") || location.hash.includes("loginAs")) {
            try {
                let payload = location.hash.includes("delegate") ? location.hash.split("delegate/")[1] : location.hash.split("loginAs/")[1];
                if (payload) {
                    let querystringJson = base64Converter.Base64ToString(payload);
                    let querystring = JSON.parse(querystringJson);
                    if (querystring && querystring.token && querystring.encryptedData) {
                        sessionStorage.setItem("token", querystring.token);
                        BCAjax.setHeaders({
                            "bimser-encrypted-data": querystring.encryptedData
                        })
                        let user = querystring.user;
                        let title = location.hash.includes("loginAs") ? findMessage.get("102389") : findMessage.get('102464');
                        BCNotification.success({
                            message: title,
                            description: findMessage.get('102465').replace("{0}", user)
                        })
                        if (!location.hash.includes("loginAs"))
                            this.props.setDelegateInformation(true);
                    }
                }
            } catch (error) {
                console.log("TCL: Router -> componentDidMount -> error", error)
            }
            location.hash = "";
        } else if (location.hash.includes("/auth")) {
            let hashWithoutParameters = location.hash.split("?")[0];
            let splittedHash = hashWithoutParameters.split("/");
            let authIndex = splittedHash.findIndex(i => i == "auth");
            if (authIndex > -1) {
                let id = splittedHash[authIndex + 1];
                let language = splittedHash[authIndex + 2];
                let useAuthCode = splittedHash[authIndex + 3] == "UseAuthCode";
                if (id && language && typeof id == "string" && typeof language == "string") {
                    this.props.onOAuthLogin({
                        id,
                        language,
                        useAuthCode
                    });
                } else {
                    BCNotification.warning({
                        message: findMessage.get('102431')
                    })
                    if (useAuthCode) {
                        location.hash = "#/OAuth/Failed";
                    } else {
                        location.hash = "#/";
                    }
                }
            }
        } else {
            if (this.props.onSSOControl) {
                this.props.onSSOControl();
            }
        }
        if (location.hash.includes("/_redirect")) {
            synergyLogonApp = "SharedLink";
        }

        this.props.getLoginParameters({ DomainAddress: location.origin, synergyLogonApp });
    }

    private generateViews() {
        if (this.state.fromMobile && this.props.checkAuthenticationFinished) {
            return this.props.children;
        }
        else if (this.props.checkAuthenticationFinished) {
            if (location.hash.includes("/OAuthResult/")) {
                if (!location.hash.split("OAuthResult/")[1]) {
                    location.hash = "#/";
                } else {
                    return (
                        <div className={Style.OAuthResultMessage}>
                            <span>{findMessage.get('102382')}</span>
                            <span>{findMessage.get('102383')}</span>
                        </div>
                    )
                }
            }
            if (this.props.isAuthenticated) {
                return this.props.children;
            }
            else {
                return <Route path="/:hash?" component={LoginApp} />
            }
        }
        else {
            return null;
        }
    }

    private generateTemplate(): JSX.Element {

        return (

            <React.Fragment>
                <UITourContainer />
                {
                    this.generateViews()
                }
            </React.Fragment>
        );
    }

    public render() {
        // return <DigitalSignature  />

        //return <DigitalSignature signatureOptions={{ type: "login" }} />

        return (
            this.generateTemplate()
        );
    }
}

const LoginApp = ({ match: { params }, location }: RouteComponentProps<{ hash: string }>) => {
    return <LoginMainContainer hash={params.hash} urlPath={location.pathname} />;
}

const test = () => (<DMExplorerContainer useScrollbar currentItem={{ id: '#Root', secretKey: null, name: "" }} />)