import { createGuid } from "@bimser/core";
import { ICondition, IConditionGroup } from "../entities";

export function addCondition(root: IConditionGroup, id: string): IConditionGroup {

    if (root.id == id) {
        const newCondition: ICondition = {
            id: createGuid(),
            typeName: 'ValueEntryCondition',
            field: null,
            operation: 'Equals',
            value: null,
            selectedField: null
        };

        return {
            ...root,
            conditions: [
                ...root.conditions,
                newCondition
            ]
        };
    } else {
        const children = root.children.map(child => {
            return addCondition(child, id);
        });

        return {
            ...root,
            children
        };
    }
}

export function updateCondition(root: IConditionGroup, condition: ICondition): IConditionGroup {

    if (root) {

        let hasChanged = false;

        const conditions = root.conditions.map(c => {
            if (c.id == condition.id) {
                hasChanged = true;
                return condition;
            } else {
                return c;
            }
        });

        if (!hasChanged) {
            const children = root.children.map(child => {
                return updateCondition(child, condition);
            });

            return {
                ...root,
                children,
                conditions
            };
        } else {
            return {
                ...root,
                conditions
            };
        }
    }

    return root


}

export function deleteCondition(root: IConditionGroup, id: string): IConditionGroup {

    const filtered = root.conditions.filter(condition => condition.id != id);
    if (filtered.length != root.conditions.length) {
        return {
            ...root,
            conditions: filtered
        };
    } else {
        const children = root.children.map(child => {
            return deleteCondition(child, id);
        });

        return {
            ...root,
            children
        };
    }
}