import { ServiceList } from '@bimser/common';
import { BCButton, BCForm, BCFormItem, BCInputMultiLanguage, BCLabel, BCModal, BCNotification, BCTextAreaMultiLanguage, findMessage, IRegExp, ValidateStatus } from '@bimser/components';
import { IDictionary, ILanguage, isNullOrUndefined } from "@bimser/core";
import IconUserGroups from "@bimser/icons/16/user-groups";
import * as React from 'react';
import * as Style from '../../assets/Style.scss';
import { PermissionGroup, PermissionGroupFactoryFromJS } from '../../entities/PermissionGroup';

class CreateGroup extends React.Component<any, { groupNameValidation: ValidateStatus, groupName: IDictionary<string>, description: IDictionary<string>, loading: boolean }>{
    groupNameRegex: IRegExp;
    groupDescriptionRegex: IRegExp;
    constructor(props: any) {
        super(props);

        this.onValueChanged = this.onValueChanged.bind(this);
        this.onGroupNameValueChange = this.onGroupNameValueChange.bind(this);
        this.onGroupDescriptionValueChange = this.onGroupDescriptionValueChange.bind(this);
        this.state = {
            loading: false,
            groupName: this.props.permissionGroup?.name || {},
            description: this.props.permissionGroup?.description || {},
            groupNameValidation: this.props.permissionGroup?.name ? ValidateStatus.success : ValidateStatus.validating,
        }

        this.groupNameRegex = {
            regExpString: new RegExp(/^[\w+şŞıİçÇöÖüÜğĞ\-()\s]+$/ig),
            validationFailedMessage: findMessage.get("100179"),
            allowNull: false
        }
        this.groupDescriptionRegex = {
            regExpString: new RegExp(/^((?!(")).)*$/ig),
            validationFailedMessage: findMessage.get("100179"),
            allowNull: true
        }
    }

    onGroupNameValueChange = (culture: string, value: string) => {
        let _groupName: IDictionary<string> = this.state.groupName;

        _groupName[culture] = value;
        let validation = !isNullOrUndefined(value) && value != "" ? ValidateStatus.success : ValidateStatus.error;
        this.setState({
            groupName: _groupName,
            groupNameValidation: validation,
        })
    }

    onGroupDescriptionValueChange = (culture: string, value: string) => {
        let _groupDescription: IDictionary<string> = this.state.description;

        _groupDescription[culture] = value;
        this.setState({
            description: _groupDescription
        })
    }

    onValueChanged = () => (args: any) => {
        this.setState({
            description: args.data
        })

        if (this.props.onDataChanged) {
            this.props.onDataChanged({ groupName: this.state.groupName, description: args.data });
        }
    }

    getContent() {
        return (
            <BCForm layout={"horizontal"} >
                <div className={Style.avatarColumn}>
                    <IconUserGroups />
                </div>
                <div className={Style.contentColumn}>
                    <BCFormItem
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: "0px" }}
                    >
                        <BCLabel>{findMessage.get("100447")}</BCLabel>
                    </BCFormItem>
                    <BCFormItem
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: "15px" }}
                        validateStatus={this.state.groupNameValidation}
                    >
                        <BCInputMultiLanguage
                            overlayClassName={Style.inputElement}
                            values={this.state.groupName}
                            onLanguageValueChange={this.onGroupNameValueChange}
                            placeHolder={findMessage.get("101444")}
                            currentLanguage={this.props.languages.find((l: ILanguage) => l.culture == this.props.selectedCulture)}
                            supportedLanguages={this.props.languages}
                            regExp={this.groupNameRegex}
                        ></BCInputMultiLanguage>
                    </BCFormItem>

                    <BCFormItem
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: "0px" }}
                    >
                        <BCLabel>{findMessage.get("100448")}</BCLabel>
                    </BCFormItem>
                    <BCFormItem
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: "0px" }}
                    >
                        <BCTextAreaMultiLanguage
                            overlayClassName={Style.inputElement}
                            values={this.state.description}
                            onLanguageValueChange={this.onGroupDescriptionValueChange}
                            placeHolder={findMessage.get("101445")}
                            autosize={{ minRows: 7, maxRows: 10 }}
                            currentLanguage={this.props.languages.find((l: ILanguage) => l.culture == this.props.selectedCulture)}
                            supportedLanguages={this.props.languages}
                            regExp={this.groupDescriptionRegex}
                        />
                    </BCFormItem>
                </div>
            </BCForm>
        )
    }

    renderFooter = () => {
        return [
            <BCButton
                key={"saveBtn"}
                type={"primary"}
                size="middle"
                text={findMessage.get("100620")}
                onClick={this.handleSave}
            />,
            <BCButton
                key={"cancelBtn"}
                type={"ghost"}
                size="middle"
                text={findMessage.get("100034")}
                onClick={this.handleCancel}
            />
        ]
    }

    handleSave = () => {

        if (Object.keys(this.state.groupName).length === 0) {
            BCNotification.warning({
                message: findMessage.get("100743"),
                description: findMessage.get("100079").replace("$0", findMessage.get("100447"))
            });
            return;
        } else if (this.state.groupName[this.props.selectedCulture].length < 3) {
            BCNotification.warning({
                message: findMessage.get("100743"),
                description: findMessage.get("101935")
            });
            return;
        }

        this.setState({ loading: true });

        if (this.props.type == "Add") {
            ServiceList.main.PermissionGroup.Create.callPromise({
                name: this.state.groupName,
                description: this.state.description
            }).then((response) => {
                if (response) {
                    let newItem: PermissionGroup = PermissionGroupFactoryFromJS(response);
                    this.props.updateGroupAction({
                        data: {
                            type: "add",
                            newItem: newItem
                        }
                    })
                    this.props.onCloseModal(this.props.id)
                    BCNotification.success({
                        message: findMessage.get("100713"),
                        description: findMessage.get("102456")
                    });
                }
            }).catch(err => {
                this.setState({ loading: false });
                BCNotification.error({
                    message: findMessage.get("100926"),
                    description: findMessage.get("100704")
                });
                console.log(err);
                //TODO ErrorMessagebox eklenecek;Eklenemedi
            })
        } else if (this.props.type == "Edit") {
            ServiceList.main.PermissionGroup.Rename.callPromise({
                secretKey: this.props.permissionGroup.secretKey,
                name: this.state.groupName,
                description: this.state.description
            }).then((response) => {
                if (response) {
                    this.props.updateGroupAction({
                        data: {
                            type: "update",
                            secretKey: this.props.permissionGroup.secretKey,
                            name: this.state.groupName,
                            description: this.state.description
                        }
                    })
                    this.props.onCloseModal(this.props.id)
                    BCNotification.success({
                        message: findMessage.get("100713"),
                        description: findMessage.get("102457")
                    });
                }
            }).catch(err => {
                this.setState({ loading: false });
                BCNotification.error({
                    message: findMessage.get("100926"),
                    description: findMessage.get("100704")
                });
                console.log(err);
                //TODO ErrorMessagebox eklenecek;Düzenlenemedi
            });
        }

    }

    handleCancel = () => {
        this.props.onCloseModal(this.props.id)
    }

    render() {
        return (
            <BCModal
                className={Style.groupModal}
                renderHeader={null}
                title={this.props.type == "Edit" ? findMessage.get("101443") : findMessage.get("101442")}
                footer={this.renderFooter()}
                width={"default"}
                visible={true}
                handleCancel={this.handleCancel}
                loading={this.state.loading}
                zIndex={99}
            >
                {this.getContent()}
            </BCModal>
        )
    }
}

export default CreateGroup