import { FormItemTypes } from "@bimser/core";
import React from "react";
import { useDrop } from "react-dnd";
import * as Styles from "./assets/styles.scss";
import { IDropData, IRootDropZone } from "./entities";
import { ELayoutItemTypes } from "./entities/Constants";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const ACCEPTS = [ELayoutItemTypes.SIDEBAR_ITEM, ELayoutItemTypes.OBJECT_EXPLORER_ITEM, ELayoutItemTypes.COMPONENT];

const RootDropZone = (args: IRootDropZone) => {
    const { onDrop } = args;
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ACCEPTS,
        drop: (item) => {
            onDrop({ path: "" } as IDropData, item);
        },
        canDrop: (item: any) => {
            const dropZonePath = "0";
            const splitDropZonePath = dropZonePath.split("-");
            const itemPath = item.path;

            if (!itemPath) {
                // sidebar items
                const type = item.props.data.type;
                return FormItemTypes.EGhostItemTypes[type] ? true : false;
            }

            const splitItemPath = itemPath.split("-");

            // Current item can't possible move to it's own location
            if (itemPath === dropZonePath) return false;

            // Current area
            const dropControlInsideSection = splitItemPath.length === 3 && splitDropZonePath.length === 2;
            if (splitItemPath.length === splitDropZonePath.length || dropControlInsideSection) {
                const pathToItem = splitItemPath.slice(0, -1).join("-");
                const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

                const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
                const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

                if (pathToItem === pathToDropZone) {
                    const nextDropZoneIndex = currentItemIndex + 1;
                    if (nextDropZoneIndex === currentDropZoneIndex) return false;
                }

                return true
            }

            return false

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const isActive = isOver && canDrop;

    const dropZoneClassNames = cx({
        dropZone: true,
        highlight: isActive,
        fillHeight: true
    });

    return (
        <div
            className={dropZoneClassNames}
            ref={drop}
        >
            {args.children}
        </div>
    );
};
export default RootDropZone;
