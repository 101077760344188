import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseNavigationItem {
    id: string;
    name: string;
    extension?: string;
    type: number;
    path?: string;
    fullPath?: string;
    secretKey: string;
}

export interface INavigationItem extends IBaseNavigationItem {

}

export interface IReadonlyNavigationItem extends IBaseNavigationItem {
}

export type NavigationItem = ReadonlyRecord<IReadonlyNavigationItem>;

export const NavigationItemFactory = Record<IReadonlyNavigationItem>({
    extension: null,
    id: null,
    name: null,
    type: null,
    path: null,
    fullPath: null,
    secretKey: null
});

export const NavigationItemFactoryFromJS = function (data: INavigationItem) {
    return NavigationItemFactory(data);
}