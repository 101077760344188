import { IExtremeGridState } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changePageIndexReducer(state: IExtremeGridState, action: IBaseMutableAction<number>): IExtremeGridState {
    return Object.assign({}, state, {
        paging: {
            ...state.paging,
            pageIndex: action.payload
        }
    })
}