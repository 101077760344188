import { Map } from "immutable";
import { isNullOrUndefined, IBaseAction, FormItemTypes } from '@bimser/core';
import { FormsState, PropertyChangeEventArgs, PropertyChangeAction } from '../../entities';


function overridePayload(props: Map<string, any>, type: string, key: string): Map<string, any> {
    if (props && type) {
        switch (type) {
            case FormItemTypes.EControlTypes.TextBox:
            case FormItemTypes.EControlTypes.TextArea:
            case FormItemTypes.EControlTypes.Password:
            case FormItemTypes.EControlTypes.SearchBox:
            case FormItemTypes.EControlTypes.TimePicker:
            case FormItemTypes.EControlTypes.MaskInput:
            case FormItemTypes.EControlTypes.DateTimePicker: {
                props = setTextControl(props, key);
                break;
            }
            case FormItemTypes.EControlTypes.NumberBox: {
                props = setNumberControl(props, key);
                break;
            }
        }
    }

    return props
}

// #region [setNumberControl]

function setNumberControl(properties: Map<string, any>, key: string) {
    switch (key) {
        case "value": {

            const value = properties.get('value');
            if (isNullOrUndefined(value) || Number.isNaN(value)) {
                return properties.set('text', "")
            }

            return properties.set('text', value.toString());
        }
        case "text": {
            const text = properties.get('text');
            return properties.set('value', text !== "" ? Number(text) : null);
        }
    }
    return properties
}

// #endregion [setNumberControl]


// #region [setTextControl]

function setTextControl(properties: Map<string, any>, key: string) {
    switch (key) {
        case "value": {
            let value = properties.get('value');
            return properties.set('text', value);
        }
        case "text": {
            let text = properties.get('text');
            return properties.set('value', text);
        }
    }
    return properties
}

// #endregion [setTextControl]


// #region [overrideTabsProps]

function overrideTabsProps(state: Map<string, FormsState>, changeData: PropertyChangeEventArgs, action: IBaseAction<PropertyChangeAction>) {
    let items = state.get(action.payload.windowId).items.entities.items;
    let dataItems = state.get(action.payload.windowId).dataItems;

    if (changeData.dataField.findIndex(i => i === "items") > -1) {

        changeData.value.forEach((i: any) => {
            items = items.setIn([i.key, "properties", "selected"], i.selected);
            dataItems = dataItems.setIn([i.key, "properties", "selected"], i.selected);
            if (i.selected) {
                items = items.setIn([changeData.id, "properties", "selectedItem"], i);
                dataItems = dataItems.setIn([changeData.id, "properties", "selectedItem"], i);
            }
        });

        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);
    }
    return state;
}

function overrideTabProps(state: Map<string, FormsState>, changeData: PropertyChangeEventArgs, action: IBaseAction<PropertyChangeAction>) {

    let items = state.get(action.payload.windowId).items.entities.items;
    let dataItems = state.get(action.payload.windowId).dataItems;
    const item = items.get(changeData.id);
    const isSelected = changeData.dataField.findIndex(i => i === "selected") > -1;
    const isSelectedItem = changeData.dataField.findIndex(i => i === "selectedItem") > -1;
    const isSelectedIndex = changeData.dataField.findIndex(i => i === "selectedIndex") > -1;

    if (isSelected && changeData.value) {

        let tabsItems = items.getIn([item.parentId, "properties", "items"]);

        tabsItems = tabsItems.map((i: any) => {

            i.selected = false;

            if (i.key === changeData.id) {
                i.selected = true;
                items = items.setIn([item.parentId, "properties", "selectedItem"], i);
                dataItems = dataItems.setIn([item.parentId, "properties", "selectedItem"], i);
            }

            return i;
        });

        items = items.setIn([item.parentId, "properties", "items"], tabsItems);
        dataItems = dataItems.setIn([item.parentId, "properties", "items"], tabsItems);

        const commitId = items.getIn([item.parentId, "properties", "commitId"]);
        const nextCommitId = Number.isInteger(commitId) ? (commitId + 1) : 0;

        items = items.setIn([item.parentId, "properties", "commitId"], nextCommitId);
        dataItems = dataItems.setIn([item.parentId, "properties", "commitId"], nextCommitId);

        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);

    }
    else if (isSelected && !changeData.value) {

        items = items.setIn([item.parentId, "properties", "items", changeData.id, 'selected'], changeData.value);
        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);
        dataItems = dataItems.setIn([item.parentId, "properties", "items", changeData.id, 'selected'], changeData.value);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);

    }
    else if (isSelectedItem && !isNullOrUndefined(changeData.value)) {

        let tabsItems = item.getIn(["properties", "items"]);
        let key: string = "";

        if (typeof changeData.value === "object" && changeData.value.key) key = changeData.value.key;
        else if (typeof changeData.value === "string") key = changeData.value;

        tabsItems = tabsItems.map((i: any) => {

            i.selected = i.key === key;
            items = items.setIn([i.key, "properties", "selected"], i.key === key);
            return i;
        })

        items = items.setIn([item.id, "properties", "items"], tabsItems);
        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);
        dataItems = dataItems.setIn([item.id, "properties", "items"], tabsItems);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);
    }
    else if (isSelectedIndex && !isNullOrUndefined(changeData.value) && !Number.isNaN(Number(changeData.value))) {

        let tabsItems = item.getIn(["properties", "items"]);
        let tabSelectedItem = item.getIn(['properties', 'items', Number(changeData.value)]);

        tabsItems = tabsItems.map((i: any) => {

            i.selected = i.key === tabSelectedItem.key;
            items = items.setIn([i.key, "properties", "selected"], i.key === tabSelectedItem.key);
            return i;
        });

        items = items.setIn([item.id, "properties", "items"], tabsItems);
        items = items.setIn([item.id, "properties", "selectedItem"], tabSelectedItem);
        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);

        dataItems = dataItems.setIn([item.id, "properties", "items"], tabsItems);
        dataItems = dataItems.setIn([item.id, "properties", "selectedItem"], tabSelectedItem);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);
    }

    return state;
}

function overrideTabMenuProps(state: Map<string, FormsState>, changeData: PropertyChangeEventArgs, action: IBaseAction<PropertyChangeAction>) {

    let items = state.get(action.payload.windowId).items.entities.items;
    let dataItems = state.get(action.payload.windowId).dataItems;
    const item = items.get(changeData.id);
    const isSelectedItem = changeData.dataField.findIndex(i => i === "selectedItem") > -1;
    const isSelectedIndex = changeData.dataField.findIndex(i => i === "selectedIndex") > -1;
    const isTabItems = changeData.dataField.findIndex(i => i === "tabItems") > -1;

    if (isSelectedIndex && !Number.isNaN(Number(changeData.value))) {
        let tabItems = item.getIn(["properties", "tabItems"]);
        let tabSelectedItem = item.getIn(['properties', 'items', Number(changeData.value)]);

        tabItems = tabItems.map((i: any) => {
            i.selected = i.key === tabSelectedItem.key;
            return i;
        });

        items = items.setIn([item.id, "properties", "items"], tabItems);
        items = items.setIn([item.id, "properties", "selectedItem"], tabSelectedItem);
        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);

        dataItems = dataItems.setIn([item.id, "properties", "items"], tabItems);
        dataItems = dataItems.setIn([item.id, "properties", "selectedItem"], tabSelectedItem);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);
    }
    else if (isSelectedItem) {
        let tabItems = item.getIn(["properties", "tabItems"]);
        let key: string = "";

        if (changeData.value?.key) key = changeData.value.key;
        else if (typeof changeData.value === "string") key = changeData.value;

        tabItems = tabItems.map((i: any) => {
            i.selected = i.key === key;
            return i;
        });

        const selectedIndex = tabItems.findIndex(_item => _item.selected);

        items = items.setIn([item.id, "properties", "tabItems"], tabItems);
        items = items.setIn([item.id, "properties", "selectedIndex"], selectedIndex);
        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);

        dataItems = dataItems.setIn([item.id, "properties", "tabItems"], tabItems);
        dataItems = dataItems.setIn([item.id, "properties", "selectedIndex"], selectedIndex);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);
    }
    else if (isTabItems) {
        let tabItems = changeData.value;
        const selectedIndex = tabItems.findIndex(_item => _item.selected);
        const selectedItem = tabItems.find(_item => _item.selected);

        items = items.setIn([item.id, "properties", "selectedIndex"], selectedIndex);
        items = items.setIn([item.id, "properties", "selectedItem"], selectedItem);
        state = state.setIn([action.payload.windowId, "items", "entities", "items"], items);

        dataItems = dataItems.setIn([item.id, "properties", "selectedIndex"], selectedIndex);
        dataItems = dataItems.setIn([item.id, "properties", "selectedItem"], selectedItem);
        state = state.setIn([action.payload.windowId, "dataItems"], dataItems);
    }

    return state;
}

function setOtherChangeRequirements(state: Map<string, FormsState>, changeData: PropertyChangeEventArgs, action: IBaseAction<PropertyChangeAction>) {
    const items = state.get(action.payload.windowId).items.entities.items;
    const item = items.get(changeData.id);

    switch (item.type) {
        case FormItemTypes.EControlTypes.Tabs: {
            state = overrideTabsProps(state, changeData, action);
            break;
        }
        case FormItemTypes.EControlTypes.Tab: {
            state = overrideTabProps(state, changeData, action);
            break;
        }
        case FormItemTypes.EControlTypes.TabMenu: {
            state = overrideTabMenuProps(state, changeData, action);
            break;
        }
    }

    return state;
}

// #endregion [overrideTabsProps]

export { setTextControl, setOtherChangeRequirements, overridePayload, setNumberControl }
