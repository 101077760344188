
import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { IDmRelation, DmRelation, DmRelationFactoryFromJS } from './IDmRelation';

interface IBaseRelationListPanelContent {
    isLoading?: boolean;
    childPanelGuid?: string;
}

export interface IRelationListPanelContent extends IBaseRelationListPanelContent {
    items: IDmRelation[];
}

export interface IReadonlyRelationListPanelContent extends IBaseRelationListPanelContent {
    items: List<DmRelation>;
}

export type RelationListPanelContent = ReadonlyRecord<IReadonlyRelationListPanelContent>;

export const RelationListPanelContentFactory = Record<IReadonlyRelationListPanelContent>({
    isLoading: false,
    items: List(),
    childPanelGuid: null
});

export const RelationListPanelContentFactoryFromJS = function (data: IRelationListPanelContent) {
    return RelationListPanelContentFactory({
        ...data,
        items: List(data?.items?.map(i => DmRelationFactoryFromJS(i)) || [])
    });
}