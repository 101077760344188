
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map, List } from "immutable";
import { IDmContent, DmContent, DmContentFactoryFromJS } from './IDmContent';
import { IContentListPanelContent, ContentListPanelContent, ContentListPanelContentFactoryFromJS } from '../components/ContentList/entities/IContentListPanelContent';

interface IBaseContentState {

}

export interface IContentState extends IBaseContentState {
    versionContents: IDictionary<IDmContent[]>
    panels: IDictionary<IContentListPanelContent>
}

export interface IReadonlyContentState extends IBaseContentState {
    versionContents: Map<string, List<DmContent>>
    panels: Map<string, ContentListPanelContent>
}

export type ContentState = ReadonlyRecord<IReadonlyContentState>;

export const ContentStateFactory = Record<IReadonlyContentState>({
    versionContents: Map(),
    panels: Map()
});

export const ContentStateFactoryFromJS = function (data: IContentState) {
    let _versionContents: IDictionary<List<DmContent>> = Object.keys(data.versionContents).reduce(function (previous: IDictionary<List<DmContent>>, current) {
        previous[current] = List(data.versionContents[current].map(c => DmContentFactoryFromJS(c)));
        return previous;
    }, {});
    let _panels: IDictionary<ContentListPanelContent> = Object.keys(data.panels).reduce(function (previous: IDictionary<ContentListPanelContent>, current) {
        previous[current] = ContentListPanelContentFactoryFromJS(data.panels[current]);
        return previous;
    }, {});
    return ContentStateFactory({
        ...data,
        versionContents: Map(_versionContents),
        panels: Map(_panels)
    });
}