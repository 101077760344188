import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IDepartmentMaintenanceListFormContent extends IBaseFormContent {
    // departmentReplacements?: Array<IReplacement>,
    selectedReplacement?: IReplacement
}

export interface IReadonlyDepartmentMaintenanceListFormContent extends IBaseFormContent {
    // departmentReplacements: List<Replacement>,
    selectedReplacement: Replacement
}

export type DepartmentMaintenanceListFormContent = ReadonlyRecord<IReadonlyDepartmentMaintenanceListFormContent>;

export const DepartmentMaintenanceListFormContentFactory = Record<IReadonlyDepartmentMaintenanceListFormContent>({
    panelId: "",
    selectedReplacement: ReplacementFactory(),
    showLoading: false,
    editWindowGuid: "",
    editWindowisActive: false,
    selectedCompanyId: 0
});

export const DepartmentMaintenanceListFormContentFactoryFromJS = function (data: IDepartmentMaintenanceListFormContent) {
    // let _departmentReplacements: Array<Replacement> = data.departmentReplacements && data.departmentReplacements.length ? data.departmentReplacements.map((replacement) => {
    //     return ReplacementFactoryFromJS(replacement);
    // }) : [];

    return DepartmentMaintenanceListFormContentFactory({
        panelId: data.panelId,
        // departmentReplacements: List(_departmentReplacements),
        selectedReplacement: ReplacementFactoryFromJS(data.selectedReplacement),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        selectedCompanyId: data.selectedCompanyId
    });
}