import { isNullOrUndefined, RxEventEmitter } from "@bimser/core";
import IconCloseWindow from "@bimser/icons/16/close-window";
import IconPanelSize from "@bimser/icons/16/panel-size";
import IconPanelBig from "@bimser/icons/32/panel-big";
import IconPanelMiddle from "@bimser/icons/32/panel-middle";
import IconPanelSmall from "@bimser/icons/32/panel-small";
import classNames from 'classnames/bind';
import * as React from "react";
import { SizeMe } from "react-sizeme";
import { Subscription } from 'rxjs';
import { BCDivider, BCPopover, BCSkeleton, ContentType, findMessage } from "..";
import BCRibbonBar, { IRibbonBarItemProps, IRibbonBarProps } from "../BCRibbonBar";
import BCScrollbars from "../BCScrollbars";
import * as Style from "./assets/css/style.scss";
import { IFlexPanelClickEventArgs, IFlexPanelProps, IFlexPanelState, IMessageReceivedEventArgs } from "./entities";
import IFlexPanelComponent from "./entities/IFlexPanelComponent";

const cx = classNames.bind(Style);

export default class FlexPanel extends React.Component<IFlexPanelProps, IFlexPanelState> {
    rxSubscription: Subscription = null;
    wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: IFlexPanelProps) {

        super(props);

        this.onMaximizeClick = this.onMaximizeClick.bind(this);
        this.onPinClick = this.onPinClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onPanelClick = this.onPanelClick.bind(this);
        this.onAnimationEnd = this.onAnimationEnd.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.rxEventHandler = this.rxEventHandler.bind(this);
        this.headerEventsReceiver = this.headerEventsReceiver.bind(this);
        this.customHeaderReceiver = this.customHeaderReceiver.bind(this);
        this.setTitleVisReceiver = this.setTitleVisReceiver.bind(this);
        this.getRibbonProps = this.getRibbonProps.bind(this);
        this.onChangePopoverVisible = this.onChangePopoverVisible.bind(this);
        this.onClickPanelSize = this.onClickPanelSize.bind(this);
        this.onClickPopoverOutside = this.onClickPopoverOutside.bind(this);
        this.wrapperRef = React.createRef();

        this.state = {
            headerEventsProps: null,
            customHeader: null,
            hideTitle: false,
            sizePopoverVisible: false,
        }
    }

    rxEventHandler(data: IMessageReceivedEventArgs) {
        if (data.type == "FLEX_PANEL.SET_HEADER_EVENTS") {
            this.headerEventsReceiver(data.data);
        } else if (data.type == "FLEX_PANEL.SET_CUSTOM_HEADER") {
            this.customHeaderReceiver(data.data);
        } else if (data.type == "FLEX_PANEL.SET_TITLE_VISIBILITY") {
            this.setTitleVisReceiver(data.data);
        }
    }

    setTitleVisReceiver(data: any) {
        this.setState({ hideTitle: !data })
    }

    customHeaderReceiver(data: any) {
        this.setState({ customHeader: data })
    }

    headerEventsReceiver(data: IRibbonBarProps) {
        if (!this.props.fromMobile) {
            this.setState({ headerEventsProps: data });
        }
    }

    componentDidMount() {
        RxEventEmitter.listen(this.props.id, {
            next: this.rxEventHandler
        }).then((rxSubscription) => {
            this.rxSubscription = rxSubscription;
        });
        document.addEventListener('mousedown', this.onClickPopoverOutside);
    }

    componentWillUnmount() {
        if (this.rxSubscription) {
            this.rxSubscription.unsubscribe();
        }
        document.removeEventListener('mousedown', this.onClickPopoverOutside);
    }

    onClickPopoverOutside(event: any) {
        if (this.wrapperRef?.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ sizePopoverVisible: false });
        }
    }

    getRibbonProps(): IRibbonBarProps {
        if (this.state.headerEventsProps && this.state.headerEventsProps.items && this.props.size == 1) {
            let _items: IRibbonBarItemProps[] = this.state.headerEventsProps.items.map(item => {
                return item;
            })
            return {
                ...this.state.headerEventsProps,
                items: _items
            }
        }
        return this.state.headerEventsProps;
    }

    renderTitle() {
        const isTitleHideCssClass = this.props.isTitleHide ? Style.hide : "";
        if (!this.state.hideTitle) {
            return (
                <BCSkeleton
                    className={Style.viewerSkeleton}
                    contentType={ContentType.Title}
                    loadingStatus={isNullOrUndefined(this.props.title)}
                >
                    <div className={[Style.title, isTitleHideCssClass].join(' ')}>{this.props.title}</div>
                </BCSkeleton>
            )
        }
    }

    onChangePopoverVisible() {
        this.setState({ sizePopoverVisible: !this.state.sizePopoverVisible });
    }

    onClickPanelSize(args: any, size: number) {
        this.onMaximizeClick({ data: { ...this.props, size: size }, senderArgs: args })
        this.setState({ sizePopoverVisible: false });
    }

    generateTemplate(): JSX.Element {

        let isActiveCssClass = this.props.isActive ? [Style.active, 'activeFlexPanel'].join(' ') : "";

        let isPinnedCssClass = this.props.isPinned ? Style.pinned : "";

        let isMaximizedCssClass = this.props.isMaximized ? Style.maximized : "";

        let isMaximizedButtonHideCssClass = this.props.isMaximizeButtonHide ? Style.hide : "";

        let isCloseButtonHideCssClass = this.props.isCloseButtonHide ? Style.hide : "";

        let isPinButtonHideCssClass = this.props.isPinButtonHide ? Style.hide : "";

        let isHeaderAreaHideCssClass = this.props.isHeaderAreaHide || this.props.isStartupPanel ? Style.hide : "";

        let isHeaderAreaHideContentCssClass = isHeaderAreaHideCssClass.length > 0 ? Style.contentWithHiddenHeader : ""

        let isHeaderActionsHideCssClass = this.props.isHeaderActionsHide ? Style.hide : "";

        let isStartupPanelCssClass = (this.props.isStartupPanel || this.props.isTransparentBackground) ? Style.startupPanel : "";

        let sizeCssClass = Style.flexPanelSize1;

        if (this.props.size == 2) {
            sizeCssClass = Style.flexPanelSize2;
        }
        if (this.props.size == 3) {
            sizeCssClass = Style.flexPanelSize3;
        }

        if (this.props.size < 1 || this.props.size > 3) {
            sizeCssClass = Style.flexPanelSize1;
        }

        return (
            <React.Fragment>
                {
                    <div className={[Style.flexPanel, isActiveCssClass, sizeCssClass, isPinnedCssClass, this.props.animationCssClass, isStartupPanelCssClass, this.props.fromMobile && Style.fromMobile].join(' ')}
                        onClick={(args) => this.onPanelClick({ data: this.props, senderArgs: args })}
                        onAnimationEnd={this.onAnimationEnd}
                        onTransitionEnd={this.onTransitionEnd}
                        id={this.props.id}
                        data-flex-panel
                    >
                        <div className={[Style.header, isHeaderAreaHideCssClass].join(' ')}>
                            {this.renderTitle()}
                            {this.state.customHeader}
                            <div className={Style.events}>
                                {
                                    this.state.headerEventsProps && (
                                        <>
                                            <BCFlexRibbon size={this.props.size} items={this.getRibbonProps()} />
                                            {!this.props.isHeaderActionsHide && <BCDivider type={"vertical"} />}
                                        </>
                                    )
                                }
                                <div className={[Style.actions, isHeaderActionsHideCssClass].join(' ')}>
                                    <BCPopover
                                        overlayClassName={Style.popoverStyles}
                                        title={
                                            <div className={Style.popoverTitleContainer}>
                                                <div className={Style.popoverTitleText}>{findMessage.get("101787")}</div>
                                            </div>
                                        }
                                        visible={this.state.sizePopoverVisible}
                                        content={
                                            <div ref={this.wrapperRef} className={Style.popoverContentStyles}>
                                                <div className={cx({ popoverIconContainer: true, popoverIconSelected: this.props.size === 1 })} onClick={(args) => this.onClickPanelSize(args, 1)}>
                                                    <IconPanelSmall />
                                                </div>
                                                <div className={cx({ popoverIconContainer: true, popoverIconSelected: this.props.size === 2 })} onClick={(args) => this.onClickPanelSize(args, 2)}>
                                                    <IconPanelMiddle />
                                                </div>
                                                <div className={cx({ popoverIconContainer: true, popoverIconSelected: this.props.size === 3 })} onClick={(args) => this.onClickPanelSize(args, 3)}>
                                                    <IconPanelBig />
                                                </div>
                                            </div>
                                        }
                                        placement={"bottomRight"}
                                    >
                                        <div onClick={() => this.onChangePopoverVisible()} className={[Style.button, "panelHeaderButton", isMaximizedCssClass, isMaximizedButtonHideCssClass].join(' ')} >
                                            <IconPanelSize className={Style.iconPanelSize} />
                                        </div>
                                    </BCPopover>

                                    <div onClick={(args) => this.onCloseClick({ data: this.props, senderArgs: args })} className={[Style.button, "panelHeaderButton", Style.close, isCloseButtonHideCssClass].join(' ')} >
                                        <IconCloseWindow style={{ fontSize: "12px", color: "#252422" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.components.map((Component: IFlexPanelComponent, index) => {
                                return (
                                    <div className={[Style.content, isHeaderAreaHideContentCssClass, this.props.fromMobile && Style.fromMobile].join(" ")} key={index}>
                                        <BCScrollbars styles={{ width: '100%', height: '100%' }} cssClass={Style.contentScroll} autoHide>
                                            <Component.component {...Component.props} />
                                        </BCScrollbars>
                                    </div>
                                );
                            })
                        }
                    </div>
                }

            </React.Fragment >
        );
    }

    onPanelClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onPanelClick)
            this.props.onPanelClick(args);
    }

    onPinClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onPinClick)
            this.props.onPinClick(args);
    }

    onMaximizeClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onMaximizeClick)
            this.props.onMaximizeClick(args);
    }

    onAnimationEnd(e: React.AnimationEvent<HTMLDivElement>) {
        if (this.props.onAnimationEnd) {
            this.props.onAnimationEnd({
                panel: this.props,
                animationName: e.animationName,
                elapsedTime: e.elapsedTime
            });
        }
    }
    onTransitionEnd(e: React.TransitionEvent<HTMLDivElement>) {
        if (this.props.onAnimationEnd && e.currentTarget === e.target && e.propertyName === "width") {
            this.props.onAnimationEnd({
                panel: this.props,
                animationName: e.propertyName,
                elapsedTime: e.elapsedTime
            });
        }
    }

    onCloseClick(args: IFlexPanelClickEventArgs) {

        args.senderArgs.persist();

        args.senderArgs.stopPropagation();

        let propPromises: Array<Promise<any>> = [];

        this.props.components.forEach((component: IFlexPanelComponent, index) => {
            if (component.props && component.props.onBeforePanelClose && typeof component.props.onBeforePanelClose === "function") {
                let componentPropsClosePromise = component.props.onBeforePanelClose(args);
                if (componentPropsClosePromise !== undefined) {
                    propPromises.push(componentPropsClosePromise);
                }
            }
        });

        Promise.all(propPromises).then(() => {
            if (this.props.onCloseClick) {
                this.props.onCloseClick(args);
            }
        });
    }

    render() {
        return (
            this.generateTemplate()
        );
    };
}

const BCFlexRibbon = React.memo((props: { size: number, items: IRibbonBarProps }) => {
    return (
        <SizeMe>
            {({ size: _size }) => <BCRibbonBar key={"ribbonsized_" + _size.width} {...props.items} />}
        </SizeMe>
    )
}, propsEqual)

function propsEqual(oldProps: { size: number, items: IRibbonBarProps }, newProps: { size: number, items: IRibbonBarProps }) {
    return oldProps.size === newProps.size && oldProps.items.items.every((oldPoint, index) => {
        const newPoint = newProps.items.items[index];
        return oldPoint.disabled === newPoint.disabled;
    })
}

export { IFlexPanelProps, IFlexPanelState, IFlexPanelClickEventArgs };