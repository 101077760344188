import Resizable from 're-resizable';
import * as React from "react";
import * as Styles from "../assets/deviceViewerStyles.scss";
import { IDeviceContentProps, ISize, EDeviceTypes } from "../entities";
import { createGuid } from '@bimser/core';

const [ minWidth, minHeight ] = [250, 250];
const handleClasses = { right: Styles.right, bottom: Styles.bottom, left: Styles.left, bottomRight: Styles.bottomRight, bottomLeft: Styles.bottomLeft };

export default (props: React.PropsWithChildren<IDeviceContentProps>) => {
    const resizeTooltipId = createGuid();
    const resizableRef = React.useRef<Resizable>();

    const updateResizer = (dimension: ISize) => {
        const { width, height } = dimension;

        resizableRef.current.updateSize({
            width,
            height
        });
    }

    const getEnableResize = () => {
        const enableResize = {
            top: false,
            right: true,
            bottom: true,
            left: true,
            topRight: false,
            bottomRight: true,
            bottomLeft: true,
            topLeft: false
        };

        return props.selectedDevice !== EDeviceTypes.Responsive ? null : enableResize;
    }

    const onResize = React.useCallback((...[,,,delta]) : void => {
        const width = Math.max(props.dimension.width + (delta.width * 2), minWidth);
        const height = Math.max(props.dimension.height + delta.height, minHeight);

        updateResizer({
            width,
            height
        });


        document.getElementById(resizeTooltipId).title = width + " x " + height;
    }, [props.dimension]);

    const onResizeStop = React.useCallback((...[,,,delta]) : void => {
        document.getElementById(resizeTooltipId).title = "";

        props.onDimensionChanged({
            width: Math.max(props.dimension.width + delta.width, minWidth),
            height: Math.max(props.dimension.height + delta.height, minHeight)
        })
    }, [props.dimension]);

    return (
        <div className={Styles.contentResizer} style={props.zoomStyle}>
            <Resizable
                ref={resizableRef}
                className={Styles.content}
                size={props.dimension}
                onResize={onResize}
                onResizeStop={onResizeStop}
                minWidth={minWidth}
                minHeight={minHeight}
                bounds={'parent'}
                enable={getEnableResize()}
                handleWrapperClass={Styles.deviceViewerResizerHandlers}
                handleClasses={handleClasses}
            >
                {props.children}
                <div id={resizeTooltipId} className={Styles.resizeTooltip} />
            </Resizable>
        </div>
    )

}
