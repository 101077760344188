import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { AnnouncementFormPanelContent, IAnnouncementFormPanelContent, AnnouncementFormPanelContentFactory, AnnouncementFormPanelContentFactoryFromJS } from "./IAnnouncementFormPanelContent";
import { AnnouncementListPanelContent, IAnnouncementListPanelContent, AnnouncementListPanelContentFactory, AnnouncementListPanelContentFactoryFromJS } from "./IAnnouncementListPanelContent";

export interface IAnnouncementPanels {
    listPanel: AnnouncementListPanelContent,
    formPanel: AnnouncementFormPanelContent
}


export type AnnouncementPanels = ReadonlyRecord<IAnnouncementPanels>;

export const AnnouncementPanelsFactory = Record<IAnnouncementPanels>({
    listPanel: AnnouncementListPanelContentFactory(),
    formPanel: AnnouncementFormPanelContentFactory()
});

export const AnnouncementPanelsFactoryFromJS = function (data: IAnnouncementPanels) {
    return AnnouncementPanelsFactory({
        listPanel: AnnouncementListPanelContentFactoryFromJS(data.listPanel),
        formPanel: AnnouncementFormPanelContentFactoryFromJS(data.formPanel)
    });
}