import { connect } from "react-redux";
import { BaseState } from '../../..';
import { onCloseDigitalSignatureAction } from '../actions';
import DigitalSignature from '../components';
import { IDigitalSignatureProps } from '../entities';

const mapStateToProps = function (state: BaseState, props: IDigitalSignatureProps) {
    return {

    };
}
const mapDispatchToProps = function (dispatch: Function) {
    return {
        onCloseDigitalSignature: (id: string, result: boolean) => {
            dispatch(onCloseDigitalSignatureAction(id, result));
        }
    };
}

const DigitalSignatureContainer = connect(mapStateToProps, mapDispatchToProps)(DigitalSignature);
export default DigitalSignatureContainer;