import * as React from 'react'
import { IMLSelectorProps } from '../entities';
import { getMLValueSelector } from '../helpers';

export default class MLSelector extends React.Component<IMLSelectorProps, {}>{
    constructor(props: IMLSelectorProps) {
        super(props);
    }

    render() {
        if (this.props.data) {
            return <>{getMLValueSelector(this.props.data, this.props.selectedCulture, this.props.defaultLanguage, this.props.isUpperCase)}</>
        } else if (typeof this.props.children == "object") {
            return <>{getMLValueSelector(this.props.children as any, this.props.selectedCulture, this.props.defaultLanguage, this.props.isUpperCase)}</>
        } else {
            return <></>
        }
    }
}