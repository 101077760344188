import { BCButton, BCCombobox, BCDrawer, BCForm, BCFormItem, BCInput, BCInputMultiLanguage, BCLoading, BCSwitch, findMessage, IInputChangeEventArgs, ValidateStatus } from "@bimser/components";
import React from "react";
import Style from "../../common/assets/style.scss";
import DrawerFormHeader from "../../../common/DrawerFormHeader"
import { IHumanResourceModule, Modules } from "../../../../entities";
import { formRegExp } from "../../../common/helper";
import { IDictionary, MLHelper } from "@bimser/core";
import SaveIcon from "@bimser/icons/16/save";
import IPlantFormProps from "../entities/IProps";

export default (props: IPlantFormProps) => {
    const [state, setState] = React.useState<Modules.IPlant>(Modules.PlantFactory().toJS());
    const [validateStatus, setValidateStatus] = React.useState<IDictionary<ValidateStatus>>({
        code: ValidateStatus.validating,
        description: ValidateStatus.validating,
        company: ValidateStatus.validating
    });
    const [ saveButtonDisabled, setSaveButtonDisabled ] = React.useState<boolean>(true);

    React.useEffect(() => {
        if(props.data)
            setState(props.data)
    }, [])

    React.useEffect(() => {
        const status = {
            code: state.code ? ValidateStatus.success : ValidateStatus.error,
            description: MLHelper.getMLText(state.description) ? ValidateStatus.success : ValidateStatus.error,
            company: state.companyId ? ValidateStatus.success : ValidateStatus.error
        }

        setValidateStatus(status);
        setSaveButtonDisabled(Object.values(status).includes(ValidateStatus.error));
    }, [ state ])

    const onChangeInputData = (key: string, value: any) => {
        setState({ ...state, [key]: value })
    }

    const onSave = () => {
        props.onSave({
            data: {
                modalId: props.modalId,
                form: state
            }
        });
    }

    const onClose = () => {
        props.onClose({
            data: {
                modalId: props.modalId,
                data: props.data || Modules.PlantFactory().toJS(),
                form: state
            }
        })
    }

    const saveButton = () => (
        <BCButton
            className={Style.saveButton}
            icon={<SaveIcon/>}
            onClick={onSave}
            type="ghost"
            disabled={saveButtonDisabled || props.loading}
        />
    )

    const onSelectCompany = (value: string) => {
        let company = MLHelper.getMLText(props.companies.find(item => item.id == value).description)

        setState({
            ...state,
            company,
            companyId: value
        })
    }

    const renderCompanyCombobox = () => {
        const options = props.companies.filter(company => company.status).map(company => ({
            text: MLHelper.getMLText(company.description),
            key: company.id,
            value: company.id
        }));

        const filterOption = (value: string, option: any) => option.text.toLocaleLowerCase().includes(value.toLocaleLowerCase());

        return <BCCombobox
            showSearch
            options={options}
            filterOption={filterOption}
            dropdownClassName={Style.companyComboboxDropdown}
            value={state.companyId}
            onSelectedChange={(args) => onSelectCompany(args.data as string)}
            allowClear
            getPopupContainer={() => document.body}
            placeholder={findMessage.get("101375")}
        />
    }

    const formContent = () => {
        return (
            <BCForm
                layout="vertical"
            >
                <BCFormItem
                    label={findMessage.get("100129")}
                    required={true}
                    validateStatus={validateStatus.code}
                >
                    <BCInput
                        regExp={{ regExpString: formRegExp[IHumanResourceModule.Plant].code }}
                        placeHolder={findMessage.get("100129")}
                        value={state.code}
                        disabled={!!state.id}
                        onChange={(args: IInputChangeEventArgs) => onChangeInputData("code", args.data)}   
                    />
                </BCFormItem>
                <BCFormItem
                    label={findMessage.get("100022")}
                    required={true}
                    validateStatus={validateStatus.description}
                >
                    <BCInputMultiLanguage
                        placeHolder={findMessage.get("100022")}
                        values={state.description}
                        currentLanguage={MLHelper.getMLInfo().currentLanguage}
                        supportedLanguages={MLHelper.getMLInfo().supportedLanguages}
                        onLanguageValueChange={(culture: string, value: string) => {
                            let _description = state.description;
                            _description[culture] = value;
                            onChangeInputData("description", _description)
                        }}
                    />
                </BCFormItem>
                <BCFormItem
                    label={findMessage.get("101375")}
                    required={true}
                    validateStatus={validateStatus.company}
                >
                    {renderCompanyCombobox()}
                </BCFormItem>
                <BCFormItem
                    label={findMessage.get("100199")}
                    required={false}
                >
                    <BCSwitch
                        checked={state.status}
                        onChange={(checked: boolean) => onChangeInputData("status", checked)}
                    />
                </BCFormItem>
            </BCForm>
        )
    }

    return (
        <BCDrawer
            key={props.modalId}
            className={Style.HRDrawerForm}
            placement="right"
            visible={true}
            title={findMessage.get("100657")}
            destroyOnClose={true}
            renderTitle={(title: string) => <DrawerFormHeader title={title} onClose={onClose} saveButton={saveButton()}/>}
            closable={false}
        >
            <BCLoading show={props.loading}>
                {formContent()}
            </BCLoading>
        </BCDrawer>
    )
}