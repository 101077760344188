import { Record } from "immutable";

export interface IViewHideInfo {
    hideLeftMenu: boolean;
    hideBreadcrumbNavigation: boolean;
    hideBreadcrumbItems: boolean;
    hideAppSearch: boolean;
    hideAdminTools: boolean;
    hideProfileMenu: boolean;
    hideApprovals: boolean;
    hideLastVisitedDocuments: boolean;
    hideAnnouncements: boolean;
    hideActivites: boolean;
}

export type ViewHideInfo = Record<IViewHideInfo> & Readonly<IViewHideInfo>;

export const ViewHideInfoFactory = Record<IViewHideInfo>({
    hideLeftMenu: false,
    hideBreadcrumbNavigation: false,
    hideBreadcrumbItems: false,
    hideAppSearch: false,
    hideAdminTools: false,
    hideProfileMenu: false,
    hideApprovals: false,
    hideLastVisitedDocuments: false,
    hideAnnouncements: false,
    hideActivites: false
});

export const ViewHideInfoFactoryFromJS = function (data: IViewHideInfo) {
    return ViewHideInfoFactory(data);
}