import { IDictionary } from "../common/base/interfaces";

export function toUpperCase(str: string, locale?: string) {
    var turkishLetters: IDictionary<string> = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
    var englishLetters: IDictionary<string> = { "i": "I", "ş": "S", "ğ": "G", "ü": "U", "ö": "O", "ç": "C", "ı": "I" };

    str = str.replace(/(([iışğüçö]))/g, function (letter: string) {
        if (locale && locale == "tr-TR") {
            return turkishLetters[letter]
        }
        return englishLetters[letter];
    })

    return str.toUpperCase();
    
}