import * as React from "react";
import BCMenu, { IMenuItemClickEventArgs } from '../BCMenu';
import * as Styles from './assets/style.scss';
import { IMenubarItemClickEventArgs, IMenubarProps } from './entities';

const BCMenubar: React.FC<IMenubarProps> = React.memo(props => {

    const onClick = (senderEventArgs: IMenuItemClickEventArgs) => {
        if (props.onClick)
            props.onClick({ data: senderEventArgs.data });
    }

    return (
        <div className={[Styles.menubar, props.className].join(" ")}>
            <BCMenu
                items={props.items}
                mode="horizontal"
                onClick={onClick}
                showEmptyIconSpace
                showShortcutSpace
                onOpenChange={props.onOpenChange}
                openKeys={props.openKeys}
                triggerMode={props.triggerMode}
                selectable={props.selectable}
                forceSubMenuRender={props.forceSubMenuRender}
                popupClassName={props.popupClassName}
            />
        </div>
    )
});

export { IMenubarProps, IMenubarItemClickEventArgs };
export default BCMenubar;