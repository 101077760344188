import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map, List } from "immutable";
import { IViewerItem, IViewerPanel, ViewerItem, ViewerPanel, ViewerItemFactoryFromJS, ViewerPanelFactoryFromJS } from '.';
import { IViewerConfigItem, ViewerConfigItem, ViewerConfigItemFactoryFromJS } from "./IViewerConfigItem";

interface IBaseViewerState {

}

export interface IViewerState extends IBaseViewerState {
    items: IDictionary<IViewerItem>,
    panels: IDictionary<IViewerPanel>,
    viewersConfig: Array<IViewerConfigItem>

}

export interface IReadonlyViewerState extends IBaseViewerState {
    items: Map<string, ViewerItem>,
    panels: Map<string, ViewerPanel>,
    viewersConfig: List<ViewerConfigItem>,

}

export type ViewerState = ReadonlyRecord<IReadonlyViewerState>;

export const ViewerStateFactory = Record<IReadonlyViewerState>({
    items: Map(),
    panels: Map(),
    viewersConfig: List()

});

export const ViewerStateFactoryFromJS = function (data: IViewerState) {
    let items: IDictionary<ViewerItem> = Object.keys(data.items).reduce(function (previous: IDictionary<ViewerItem>, current) {
        previous[current] = ViewerItemFactoryFromJS(data.items[current]);
        return previous;
    }, {});
    let panels: IDictionary<ViewerPanel> = Object.keys(data.panels).reduce(function (previous: IDictionary<ViewerPanel>, current) {
        previous[current] = ViewerPanelFactoryFromJS(data.panels[current]);
        return previous;
    }, {});

    let _viewersConfig = data?.viewersConfig.map(i => ViewerConfigItemFactoryFromJS(i)) || [];

    return ViewerStateFactory({
        items: Map(items),
        panels: Map(panels),
        viewersConfig: List(_viewersConfig)
    });
}