import Timeline from 'antd/lib/timeline';
import * as React from 'react';
import ITimeLine from '../entities/ITimeLineProps';
const TimeLineItem = Timeline.Item;

export default class TimeLine extends React.Component<ITimeLine, {}> {

    render() {

        const { mode, reverse, pendingDot, pending, children } = this.props;

        return (
            <Timeline
                mode={mode}
                reverse={reverse}
                pendingDot={pendingDot}
                pending={pending}
            >
                {children}
            </Timeline>
        )

    }

}

export { TimeLineItem, ITimeLine }