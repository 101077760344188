import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';

interface IBaseLoadingStatus {
    permissionGroupLoading: boolean,
    permissionLoading: boolean,
    memberLoading: boolean,
    modalLoading: boolean,
    securityLoading: boolean
}

export interface ILoadingStatus extends IBaseLoadingStatus {

}

export interface IReadonlyLoadingStatus extends IBaseLoadingStatus {

}

export type LoadingStatus = ReadonlyRecord<IReadonlyLoadingStatus>;

export const LoadingStatusFactory = Record<IReadonlyLoadingStatus>({
    permissionGroupLoading: false,
    permissionLoading: false,
    memberLoading: false,
    modalLoading: false,
    securityLoading: false
});

export const LoadingStatusFactoryFromJS = function (data: ILoadingStatus) {
    return LoadingStatusFactory(data);
}