import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { BaseResponse } from "../../../entities/BaseResponse";
import { BrowserProperties, BrowserPropertiesFactory, BrowserPropertiesFactoryFromJS, IBrowserProperties } from "./BrowserProperties";
import { DomainProperties, DomainPropertiesFactory, DomainPropertiesFactoryFromJS, IDomainProperties } from "./DomainProperties";
import { ILanguageProperties, LanguageProperties, LanguagePropertiesFactory, LanguagePropertiesFactoryFromJS } from "./LanguageProperties";
import { ILoginFormProperties, LoginFormProperties, LoginFormPropertiesFactory, LoginFormPropertiesFactoryFromJS } from "./LoginFormProperties";
import { ILoginPageProperties, LoginPageProperties, LoginPagePropertiesFactory, LoginPagePropertiesFactoryFromJS } from "./LoginPageProperties";
import { IUserCustomProperties, UserCustomProperties, UserCustomPropertiesFactory, UserCustomPropertiesFactoryFromJS } from "./UserCustomProperties";
import { ITenantCustom, TenantCustom, TenantCustomFactory, TenantCustomFactoryFromJS } from "../../../system/tenant/entities/ITenantCustom";
import { MFAType } from ".";

interface IGetLoginParametersBaseResponse {
    logoutOldSessionDirectly: boolean,
    indefiniteAccessToken: boolean
}


export interface IGetLoginParametersResponse extends IGetLoginParametersBaseResponse {
    loginPageProperties: ILoginPageProperties,
    languageProperties: ILanguageProperties,
    domainProperties: IDomainProperties,
    browserProperties: IBrowserProperties,
    loginFormProperties: ILoginFormProperties,
    userCustomProperties: IUserCustomProperties,
    tenantCustomProperties: ITenantCustom,
    mfaTypes: Array<MFAType>
}

export interface IReadonlyGetLoginParametersResponse extends IGetLoginParametersBaseResponse {
    loginPageProperties: LoginPageProperties,
    languageProperties: LanguageProperties,
    domainProperties: DomainProperties,
    browserProperties: BrowserProperties,
    loginFormProperties: LoginFormProperties,
    userCustomProperties: UserCustomProperties,
    tenantCustomProperties: TenantCustom,
    mfaTypes: List<MFAType>
}

export type GetLoginParametersResponse = ReadonlyRecord<IReadonlyGetLoginParametersResponse>;

export const GetLoginParametersResponseFactory = Record<IReadonlyGetLoginParametersResponse>({
    loginPageProperties: LoginPagePropertiesFactory(),
    languageProperties: LanguagePropertiesFactory(),
    domainProperties: DomainPropertiesFactory(),
    browserProperties: BrowserPropertiesFactory(),
    loginFormProperties: LoginFormPropertiesFactory(),
    userCustomProperties: UserCustomPropertiesFactory(),
    logoutOldSessionDirectly: false,
    indefiniteAccessToken: false,
    tenantCustomProperties: TenantCustomFactory(),
    mfaTypes: List()
});

export const GetLoginParametersResponseFactoryFromJS = function (data: IGetLoginParametersResponse) {
    return GetLoginParametersResponseFactory({
        loginPageProperties: LoginPagePropertiesFactoryFromJS(data.loginPageProperties),
        languageProperties: LanguagePropertiesFactoryFromJS(data.languageProperties),
        domainProperties: DomainPropertiesFactoryFromJS(data.domainProperties),
        browserProperties: BrowserPropertiesFactoryFromJS(data.browserProperties),
        loginFormProperties: LoginFormPropertiesFactoryFromJS(data.loginFormProperties),
        userCustomProperties: UserCustomPropertiesFactoryFromJS(data.userCustomProperties),
        logoutOldSessionDirectly: data.logoutOldSessionDirectly,
        tenantCustomProperties: TenantCustomFactoryFromJS(data.tenantCustomProperties),
        mfaTypes: List(data.mfaTypes),
        indefiniteAccessToken: data.indefiniteAccessToken
    });
}