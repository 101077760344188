import { Map } from "immutable";
import { NormalizedLayoutStructureItems } from "../../../common/entities";

export const removeItemFromChild = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    id: string
) => {
    const item = views.getIn([activeView, "entities", "items", id]);
    const parentItem = views.getIn([activeView, "entities", "items", item.parentId]);
    let items = parentItem.get("items");
    const itemIndex = items.findIndex((i) => i === id);
    items = items.remove(itemIndex);

    return views.setIn([activeView, "entities", "items", item.parentId, "items"], items);
};