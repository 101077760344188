import { reorderOnView, reorderOnParentView } from ".";
import { Map, List } from "immutable";
import { LayoutItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const reorderChildren = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    splitDropZonePath: List<string>,
    splitItemPath: List<string>,
    item: LayoutItem
) => {
    if (splitDropZonePath.count() === 1) {
        const dropZoneIndex = Number(splitDropZonePath.get(0));
        const itemIndex = Number(splitItemPath.get(0));
        return reorderOnView(views, activeView, itemIndex, dropZoneIndex);
    } else {
        const dropZoneIndex = Number(splitDropZonePath.get(splitDropZonePath.count() - 1));
        const itemIndex = Number(splitItemPath.get(splitItemPath.count() - 1));
        return reorderOnParentView(views, activeView, itemIndex, dropZoneIndex, item);
    }
};