import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBasePositionDetail {
    id: string,
    caption: string,
    positionCode:string
}

export interface IPositionDetail extends IBasePositionDetail { }

export interface IReadonlyPositionDetail { }

export type PositionDetail = ReadonlyRecord<IReadonlyPositionDetail>;

export const PositionDetailFactory = Record<IReadonlyPositionDetail>({
    id: "",
    caption: "",
    positionCode:""
});

export const PositionDetailFactoryFromJS = function (data: IPositionDetail) {
    return PositionDetailFactory({
        id: data.id,
        caption: data.caption,
        positionCode:data.positionCode
    });
}