import { connect } from "react-redux";
import { BaseState } from "../../..";
import { closeMobileSignModalAction, startMobileSigningAction } from "../actions";
import MobileSignature from "../components";
import { IMobileSignatureProps } from '../entities';
import IStartMobileSigningPayload from "../entities/IStartMobileSigningPayload";
import { getMobileSignFingerprint, getMobileSignLoading } from "../selectors";

const mapStateToProps = function (state: BaseState, props: IMobileSignatureProps): IMobileSignatureProps {
    return {
        fingerPrint: getMobileSignFingerprint(state, props),
        loading: getMobileSignLoading(state, props)
    };
}
const mapDispatchToProps = function (dispatch: Function): IMobileSignatureProps {
    return {
        startMobileSigning: (data: IStartMobileSigningPayload) => {
            dispatch(startMobileSigningAction(data));
        },
        onCloseModal: (id: string) => {
            dispatch(closeMobileSignModalAction(id, false));
        }
    };
}

const MobileSignatureContainer = connect(mapStateToProps, mapDispatchToProps)(MobileSignature);
export default MobileSignatureContainer;