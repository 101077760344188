
export type ILoadFilterOptions = Array<ILoadFilterItem | ILoadFilterGroup>;

export interface ILoadFilterItem {
    Column: string;
    Not: boolean;
    Type: FilterType;
    Value: any;
    ItemType: ItemType;
}

export interface ILoadFilterGroup {
    Operator: GroupOperator;
    Filters: Array<ILoadFilterItem | ILoadFilterGroup>;
}

export enum FilterType {
    Equal = 1,
    Greater = 2,
    GreaterOrEqual = 3,
    Less = 4,
    LessOrEqual = 5,
    Contain = 6,
    StartsWith = 7,
    EndsWith = 8
}

export enum ItemType {
    String = 1,
    Int16 = 2,
    Int32 = 3,
    Int64 = 4,
    DateTime = 5,
    DateTimeOffset = 6,
    Boolean = 7,
    Double = 8,
    Float = 9,
    Decimal = 10
}

export enum GroupOperator {
    And = 1,
    Or = 2
}