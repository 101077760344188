import { IBaseAction } from "@bimser/core";
import { List } from 'immutable';
import * as actionTypes from '../actions/actionTypes';
import { TenantCustomFactory, TenantCustom } from "../entities/ITenantCustom";



export default function (state: TenantCustom = TenantCustomFactory(), action: IBaseAction<any>): TenantCustom {
    switch (action.type) {
        case actionTypes.SET_TENANT_ACTION: {
            return tenantToStore(state, action);
        }
        default:
            return state;
    }
}

function tenantToStore(state: TenantCustom, action: IBaseAction<TenantCustom>): TenantCustom {
    return action.payload;
}