import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { panelType } from "../../../../common/entities/enums";
import { IMenuItem, MenuItem, MenuItemFactoryFromJS } from "../../../../common/entities/IMenuItem";
import { IMenuDesignerState, MenuDesignerState, MenuDesignerStateFactoryFromJS, MenuDesignerStateFactory } from "./IMenuDesignerState";
import { IEBA6Integration, EBA6IntegrationFactory, EBA6IntegrationFactoryFromJS, EBA6Integration } from './IEBA6IntegrationState';

interface IBaseLeftMenuState {
    activePanelId: string,
    activePanelType: panelType,
    activeNodeKey?: string,
    showLoading?: boolean,
}

export interface ILeftMenuState extends IBaseLeftMenuState {
    items: Array<IMenuItem>,
    menuDesigner: IMenuDesignerState,
    eBA6Integration?: IEBA6Integration,
}

interface IReadOnlyLeftMenuState extends IBaseLeftMenuState {
    items: List<MenuItem>,
    menuDesigner: MenuDesignerState,
    eBA6Integration?: EBA6Integration,
}

export type LeftMenuState = ReadonlyRecord<IReadOnlyLeftMenuState>;

export const LeftMenuStateFactory = Record<IReadOnlyLeftMenuState>({
    items: List<MenuItem>(),
    activePanelId: "",
    activePanelType: panelType.startupLayout,
    activeNodeKey: "",
    showLoading: false,
    eBA6Integration: EBA6IntegrationFactory(),
    menuDesigner: MenuDesignerStateFactory(),
});

export const LeftMenuStateFactoryFromJS = function (data: ILeftMenuState) {
    let _items: Array<MenuItem> = data.items && data.items.length ? data.items.map((item) => {
        return MenuItemFactoryFromJS(item);
    }) : [];


    return LeftMenuStateFactory({
        items: List(_items),
        activePanelId: data.activePanelId,
        activePanelType: data.activePanelType,
        activeNodeKey: data.activeNodeKey,
        showLoading: data.showLoading,
        menuDesigner: MenuDesignerStateFactoryFromJS(data.menuDesigner),
        eBA6Integration: EBA6IntegrationFactoryFromJS(data.eBA6Integration),
    });
}