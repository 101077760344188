import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { ILoadFilterItem, LoadFilterItem, LoadFilterItemFactoryFromJS } from "./ILoadFilterItem";
export enum GroupOperator {
    And = 1,
    Or = 2
}
export interface IBaseLoadFilterGroup {
    Operator: GroupOperator;
}

export interface ILoadFilterGroup extends IBaseLoadFilterGroup {
    Filters: Array<ILoadFilterItem | ILoadFilterGroup>;
}

export interface IReadonlyLoadFilterGroup extends IBaseLoadFilterGroup {
    Filters: List<LoadFilterItem | LoadFilterGroup>;
}

export type LoadFilterGroup = ReadonlyRecord<IReadonlyLoadFilterGroup>;

export const LoadFilterGroupFactory = Record<IReadonlyLoadFilterGroup>({
    Filters: List(),
    Operator: null,
});

export const LoadFilterGroupFactoryFromJS = function (data: ILoadFilterGroup) {
    return LoadFilterGroupFactory({
        Operator: data.Operator,
        Filters: data.Filters?.map((d: ILoadFilterItem | ILoadFilterGroup) => (d as ILoadFilterItem)?.Column ?
            LoadFilterItemFactoryFromJS(d as ILoadFilterItem) : LoadFilterGroupFactoryFromJS(d as ILoadFilterGroup)) as any
    });
}