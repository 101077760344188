import { Record } from "immutable";
import { ViewHideInfo, ViewHideInfoFactory, ViewHideInfoFactoryFromJS } from "./IViewHideInfo";

export interface IRedirectState {
    hideMenus?: boolean,
    fromRedirect?: boolean,
    viewHideInfo: ViewHideInfo,
}

export type RedirectState = Record<IRedirectState> & Readonly<IRedirectState>;

export const RedirectStateFactory = Record<IRedirectState>({
    hideMenus: false,
    fromRedirect: false,
    viewHideInfo: ViewHideInfoFactory()
});

export const RedirectStateFactoryFromJS = function (data: IRedirectState) {
    return RedirectStateFactory({
        hideMenus: data.hideMenus,
        fromRedirect: data.fromRedirect,
        viewHideInfo: ViewHideInfoFactoryFromJS(data.viewHideInfo)
    });
}