import { ConditionalStatementEntities } from '@bimser/components/dist/BCConditionalStatement';
import { IDictionary, RxEventEmitter, MLHelper } from "@bimser/core";
import React from "react";
import { IMessageBoxRequest, MessageBox } from "../../..";
import { MessageBoxButtons } from "../../ModalManager/enums";

export function showPopupMessage(title: IDictionary<string> | string, type: ConditionalStatementEntities.AlertType | "Validation", message: IDictionary<string> | string | React.ReactNode, highlightList?: string[], windowId?: string) {

    let data: IMessageBoxRequest = {
        title: MLHelper.getMLText(title),
        content: React.isValidElement(message) ? message : MLHelper.getMLText(message),
        buttons: MessageBoxButtons.OK
    };

    switch (type) {
        case 'Error': {
            MessageBox.error(data);
            break;
        }
        case 'Info': {
            MessageBox.info(data);
            break;
        }
        case 'Success': {
            MessageBox.success(data);
            break;
        }
        case 'Warning': {
            MessageBox.warning(data);
            break;
        }
        case 'Validation': {
            if (highlightList?.length) {
                highlightList.forEach(controlName => {
                    RxEventEmitter.emit("highlight_" + windowId + controlName, { type: "ACTIVATE_CONTROL_HIGHLIGHT", windowId: windowId, status: true, alertType: "Warning", messages: data.content });
                });
            }
            MessageBox.warning(data);
            break;
        }
        case 'SevereWarning': {
            MessageBox.severeWarning(data);
            break;
        }
        case 'Blocked': {
            MessageBox.Blocked(data);
            break;
        }

        default: console.warn('Action alert type not found');
    }

}