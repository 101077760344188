export const CREATE_EXTREMEGRID = "EXTREMEGRID.CREATE_EXTREMEGRID"
export const CREATE_EXTREMEGRID_OVERRIDE = "EXTREMEGRID.CREATE_EXTREMEGRID_OVERRIDE"
export const CHANGE_STATE = "EXTREMEGRID.CHANGE_STATE"
export const SET_DATA = "EXTREMEGRID.SET_DATA"
export const SET_LOADING = "EXTREMEGRID.SET_LOADING"
export const SET_DATAREF = "EXTREMEGRID.SET_DATAREF"
export const SET_CURRENT_CACHEID = "EXTREMEGRID.SET_CURRENT_CACHEID"
export const CLEAR_CACHE = "EXTREMEGRID.CLEAR_CACHE"
export const CLEAR_CACHE_ASYNC = "EXTREMEGRID.CLEAR_CACHE_ASYNC"
export const SET_COLUMNS = "EXTREMEGRID.SET_COLUMNS";
export const SET_SELECTIONIDS = "EXTREMEGRID.SET_SELECTIONIDS";
export const REMOVE_EXTREMEGRID = "EXTREMEGRID.REMOVE_EXTREMEGRID";
export const UPDATE_CACHE_KEY = "EXTREMEGRID.UPDATE_CACHE_KEY";
export const UPDATE_VIRTUAL_SCROLLING_OPTIONS = "EXTREMEGRID.UPDATE_VIRTUAL_SCROLLING_OPTIONS";
