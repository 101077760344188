import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

export interface IBaseCompanyAdmin {
    id: number,
    companyId: number,
    userId: number,
    loadDetail: boolean,
    companyDescription: string,
    username: string,
    userFullname: string
}

export interface ICompanyAdmin extends IBaseCompanyAdmin { }

export interface IReadonlyCompanyAdmin extends IBaseCompanyAdmin { }

export type CompanyAdmin = ReadonlyRecord<IReadonlyCompanyAdmin>;

export const CompanyAdminFactory = Record<IReadonlyCompanyAdmin>({
    id: 0,
    companyId: 0,
    userId: 0,
    loadDetail: false,
    companyDescription: "",
    username: "",
    userFullname: ""
});

export const CompanyAdminFactoryFromJS = function (data: ICompanyAdmin) {
    return CompanyAdminFactory(data);
}