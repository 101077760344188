import { DmObjectType, panelType } from '@bimser/common';
import { BCButton, BCLoading, BCNotification, findMessage } from '@bimser/components';
import { MLHelper, isNullOrUndefined } from "@bimser/core";
import * as React from 'react';
import * as Style from '../assets/style.scss';
import { IProfileFormProps } from '../entities';
import OnChangeFilePropertyPayload from '../entities/OnChangeFilePropertyPayload';
import FileList from './FileList';
import FolderOptions from './FolderOptions';
import ProfilesTabs from './ProfilesTabs';

var classNames = require('classnames/bind');
let cx = classNames.bind(Style);

const ProfileForm = (props: IProfileFormProps) => {

    const onChangeFileProperty = (payload: OnChangeFilePropertyPayload) => {
        props.onChangeFileProperty(payload);
    }

    const onRemoveFile = (fileId: string) => {
        props.onRemoveFile(fileId);
    }

    const onSelectedChange = (_selectedIds: string[]) => {
        props.onSetSelectedIds(_selectedIds);
    }

    const isFolderMode = (props.dmObject.originType == DmObjectType.Folder || props.dmObject.originType == DmObjectType.Repository) && !isNullOrUndefined(props.folderProps);

    const renderFileList = () => {
        if (!isFolderMode) {
            return (
                <div className={Style.fileList}>
                    <FileList
                        disableFileAdd={props.disableFileAdd}
                        readOnly={props.readOnly}
                        panelGuid={props.panelGuid}
                        onFilePropertyChange={onChangeFileProperty}
                        onRemoveFile={onRemoveFile}
                        onSelectedChange={onSelectedChange}
                        selectedIds={props.selectedIds}
                        fileList={props.fileList}
                        onAddNewFiles={props.onAddNewFiles}
                    />
                </div>
            )
        }
    }

    const renderFolderOptions = () => {
        if (isFolderMode) {
            return (
                <FolderOptions
                    folderProps={props.folderProps}
                    onFolderPropChange={props.onFolderPropertyChange}
                />
            )
        }
    }

    const validateProps = () => {
        if (!isFolderMode) return true;
        let currCulture = MLHelper.currentLanguage.culture;
        let isNameFilled = Object.keys(props.folderProps.folderName).indexOf(currCulture) > -1 && props.folderProps.folderName[currCulture].length > 0;
        if (!isNameFilled) {
            BCNotification.warning({
                message: findMessage.get("100767"),
                duration: 4
            })
            return false;
        }
        return true;
    }

    const onOkClick = () => {
        if (validateProps()) {
            props.onSaveAllProfileForms({
                panelId: props.panelGuid,
                panelType: panelType.profileForm
            })
        }
    }

    const onCancelClick = () => {
        props.onCancel({
            panelId: props.panelGuid
        })
    }

    const getFormDataList = () => {
        if (isFolderMode) {
            return props.formList.filter(f => f.fId == props.dmObject.id);
        } else {
            return props.formList.filter(f => props.selectedIds.includes(f.fId));
        }
    }
    return (
        <BCLoading show={props.isLoading}>
            <div className={Style.ProfileFormContainer}>
                {renderFileList()}
                <div className={cx({ formTabs: true, withFolderSelection: isFolderMode })}>
                    {renderFolderOptions()}
                    <div className={Style.formTabsContent}>
                        <ProfilesTabs
                            panelGuid={props.panelGuid}
                            formData={getFormDataList()}
                            initializePanelManager={props.initializePanelManager}
                            dmObject={props.dmObject}
                            selectedIds={props.selectedIds}
                            initializedForms={props.initializedForms}
                            activeTabKey={props.activeTabKey}
                            onTabChange={props.onSetSelectedFormTab}
                        />
                    </div>
                    <div className={Style.formTabsOptions}>
                        <BCButton
                            text={findMessage.get("100004")}
                            type="ghost"
                            onClick={onOkClick}
                        />
                        <BCButton
                            text={findMessage.get("100034")}
                            type={"ghost"}
                            onClick={onCancelClick}
                        />
                    </div>
                </div>
            </div>
        </BCLoading>
    )
}
export default ProfileForm;