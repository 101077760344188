import React, { lazy, Suspense } from 'react';
import IDMSecurityModalProps from './entities/IDMSecurityModalProps';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-dm" */ "./containers").then(({ default: container }) => {
    import(/* webpackChunkName: "web-dm" */ "modules/dm/injector");
    return { default: container };
}));

function Container(props: React.PropsWithChildren<IDMSecurityModalProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;