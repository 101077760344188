import * as React from "react";
import IBreadCrumbNavigationClickEventArgs, { NavigationType } from "../entities/IBreadCrumbNavigationClickEventArgs";
import IBreadCrumbNavigationProps from "../entities/IBreadCrumbNavigationProps";
import * as Style from "../assets/css/style.scss";
import IconBack from "@bimser/icons/16/back";
import IconForward from "@bimser/icons/16/forward";
import IconHome from "@bimser/icons/16/home";

export default class BCNavigator extends React.Component<IBreadCrumbNavigationProps, { disable: boolean }> {

    constructor(props: IBreadCrumbNavigationProps) {
        super(props);
        this.state = {
            disable: false
        }
        this.onClick = this.onClick.bind(this);
    }

    generateTemplate(): JSX.Element {

        let { isLeftNavigatorDisable, isRightNavigatorDisable, visible } = this.props;

        if (!visible) {
            return null
        }

        let leftDisabledClass = isLeftNavigatorDisable ? Style.disable : "" || this.state.disable;
        let rightDisabledClass = isRightNavigatorDisable ? Style.disable : "" || this.state.disable;

        return (
            <div className={Style.breadCrumbNavigationContainer} style={this.props.navigatorStyle}>
                <div onClick={(args) => { this.onClick({ senderArgs: args, navigationType: NavigationType.Left }) }} className={[Style.navItem, Style.left, leftDisabledClass].join(' ')}>
                    <IconBack />
                </div>
                <div onClick={(args) => { this.onClick({ senderArgs: args, navigationType: NavigationType.Right }) }} className={[Style.navItem, Style.right, rightDisabledClass].join(' ')}>
                    <IconForward />
                </div>
                <div onClick={(args) => { this.onClick({ senderArgs: args, navigationType: NavigationType.Home }) }} className={[Style.navItem, Style.home].join(' ')}>
                    <IconHome />
                </div>
            </div>
        );
    }

    onClick(args: IBreadCrumbNavigationClickEventArgs) {
        if (!this.state.disable) {
            args.senderArgs.persist();
            args.senderArgs.stopPropagation();
            this.setState({ disable: true });
            if (this.props.onClick)
                this.props.onClick(args);
            setTimeout(() => {
                this.setState({ disable: false });
            }, 500);
        }
    }

    render() {
        return (
            this.generateTemplate()
        );
    };
}

export { IBreadCrumbNavigationProps, IBreadCrumbNavigationClickEventArgs };
