import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import * as Styles from '../assets/style.scss';
import IServerInfoWithQrComponentProps from '../entities/IServerInfoWithQrComponentProps';
import { BCBarcode, BCInput, EBarcodeTypes, findMessage, BCNotification } from '@bimser/components';
import { copyToClipboard } from "@bimser/core";
import IconCopy from "@bimser/icons/16/copy";
import qrScan from '../assets/img/qrScan.png';

export default class ServerInfoWithQr extends React.Component<IServerInfoWithQrComponentProps, {}> {

    constructor(props: IServerInfoWithQrComponentProps) {
        super(props);
        this.onClickLoginPanel = this.onClickLoginPanel.bind(this);
    }

    onClickLoginPanel() {
        if (this.props.onClickLoginPanel) {
            this.props.onClickLoginPanel();
        }
    }

    getQRValue() {
        let url = (window as any).serviceConfig.services.login.baseURL;
        let allurl = new URL((window as any).serviceConfig.services.login.baseURL).origin

        let serverInfo = {
            serverNick: url.split("//")[1].split(".")[0],
            serverAddress: allurl,
            domainAddress: allurl,
            serverMainPath: url.split("//")[1].split("/").slice(1).join("/")
        }
        return JSON.stringify(serverInfo);
    }

    onClickCopy = () => {
        const copyText = (JSON.parse(this.getQRValue())).domainAddress
        copyToClipboard(copyText);

        BCNotification.info({
            message: findMessage.get('101743')
        });
    }

    render() {
        return (
            <div className={Styles.qrCodeContainer}>
                {!this.props.hideBackLink && <div className={Styles.qrPageTitle}>{findMessage.get("102430")}</div>}
                <div className={Styles.qrCodeLine}>
                    <div className={Styles.qrScanImage}><img src={qrScan} /></div>
                    <div className={Styles.qrCode}><BCBarcode codeType={EBarcodeTypes.QR} value={this.getQRValue()} /></div>
                    <div className={Styles.qrText}>
                        <BCInput value={(JSON.parse(this.getQRValue())).domainAddress} style={{ height: "24px", textAlign: "center" }} readOnly />
                        <IconCopy height="24px" width="24px" onClick={this.onClickCopy} />
                    </div>
                    <div className={Styles.qrExplain}>
                        {findMessage.get('101930')}
                    </div>
                </div>
                {!this.props.hideBackLink && <span onClick={this.onClickLoginPanel}>{findMessage.get('100753')}</span>}
            </div>
        );
    }
}