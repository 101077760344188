import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';
import { UploadManagerStatus } from './UploadManagerStatus';

interface IBaseUploadFileThread{
    id?: string,
    startByte?: number,
    endByte?: number,
    status?: UploadManagerStatus,
    parentHash?: string,
    uploadUrl?: string
}

export interface IUploadFileThread extends IBaseUploadFileThread {
    
}

export interface IReadonlyUploadFileThread extends IBaseUploadFileThread {
    
}

export type UploadFileThread = ReadonlyRecord<IReadonlyUploadFileThread>;

export const  UploadFileThreadFactory = Record<IReadonlyUploadFileThread>({
    id: "0",
    startByte: 0,
    endByte: 0,
    status: UploadManagerStatus.Standby,
    parentHash: null,
    uploadUrl: null,
});

export const UploadFileThreadFactoryFromJS = function (data: IUploadFileThread) {
    return UploadFileThreadFactory(data);
}