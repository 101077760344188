import { BCAjax, BCAjaxRequestConfig, BCNotification, findMessage } from '@bimser/components';
import { createGuid, RxEventEmitter } from '@bimser/core';
import { createElement } from 'react';
import { call } from 'redux-saga/effects';
import { DeployAgentEntities, MessageBox, MessageBoxButtons, Mobile } from '..';
import ProjectDeploymentProgressContainer from '../components/ProjectDeploymentProgress/containers';
import { BaseServicePayload, DeployStatusResponse, IGlobalProjectInterface, RunFlowServicePayload, RunFormServicePayload, AgentProjects } from '../entities/ProjectHelper';
const _get = require("lodash/get");
const _set = require("lodash/set");

function getDeployAgentUrlTemplate(deployAgent: any, projectName: string, projectVersion: number, restOfAll: string) {
    if (!deployAgent.url.endsWith("/")) {
        deployAgent.url += "/";
    }
    return `${deployAgent.url}apps/${projectName}/${projectVersion ?? "latest"}/api${restOfAll ? ("/" + restOfAll) : ""}`;
}

function* _RunFlowService(payload: RunFlowServicePayload) {
    let deployAgent: any = ProjectHelper.getDeployAgent(payload.projectName, payload.projectVersion);
    if (deployAgent) {
        try {
            let requestUrl = getDeployAgentUrlTemplate(deployAgent, payload.projectName, payload.projectVersion, `${payload.flowName}/${payload.serviceName}`);
            let serviceResponse = yield call(sendServiceRequestGenerator, payload.serviceMethod, requestUrl, payload.requestParameters, payload.requestConfig);
            return yield call(OnServiceResponseGenerator, requestUrl, serviceResponse, payload);
        } catch (error) {
            console.log(`[${payload.projectName}][${payload.flowName}][${payload.serviceName}] Error.`, error);
            throw error;
        }
    } else {
        onDeployAgentNotFound();
        throw new Error("Deploy agent not found");
    }
}

function* _RunFormService(payload: RunFormServicePayload) {
    let deployAgent: any = ProjectHelper.getDeployAgent(payload.projectName, null);
    if (deployAgent) {
        try {
            let requestUrl = getDeployAgentUrlTemplate(deployAgent, payload.projectName, null, `${payload.formName}/${payload.serviceName}`);
            let serviceResponse = yield call(sendServiceRequestGenerator, payload.serviceMethod, requestUrl, payload.requestParameters, payload.requestConfig);
            return yield call(OnServiceResponseGenerator, requestUrl, serviceResponse, payload);
        } catch (error) {
            console.log(`[${payload.projectName}][${payload.formName}][${payload.serviceName}] Error.`, error);
            throw error;
        }
    } else {
        onDeployAgentNotFound();
        throw new Error("Deploy agent not found");
    }
}

function* OnServiceResponseGenerator(requestUrl: string, serviceResponse: any, payload: BaseServicePayload): Generator<any, any, any> {
    if (!serviceResponse) {
        console.log(`[${payload.projectName}][${payload.serviceName}] Response Error.`);
        return null;
    }
    if (Object.keys(serviceResponse.data).includes("queued")) {
        //Project is not ready. listen socket
        let modalId = openDeploymentProgressModal(payload.projectName, payload.projectVersion, true);
        let deployStatus: DeployStatusResponse = yield waitForDeployStatus(payload.projectName, payload.projectVersion, payload.cancelOnError);
        if (deployStatus == DeployStatusResponse.Success) {
            let _serviceResponse = yield call(sendServiceRequestGenerator, payload.serviceMethod, requestUrl, payload.requestParameters, payload.requestConfig);
            return yield call(OnServiceResponseGenerator, requestUrl, _serviceResponse, payload);
        } else if ((deployStatus == DeployStatusResponse.Error && payload.cancelOnError) || deployStatus == DeployStatusResponse.Cancelled) {
            MessageBox.closeModal(modalId);
            throw new Error(deployStatus == DeployStatusResponse.Error ? null : "-1");
        }
    } else {
        return serviceResponse;
    }
}

function _RunFormServicePromise<T = any>(payload: RunFormServicePayload) {
    let deployAgent: any = ProjectHelper.getDeployAgent(payload.projectName, null);
    return new Promise<T>((resolve, reject) => {
        if (deployAgent) {
            try {
                let requestUrl = getDeployAgentUrlTemplate(deployAgent, payload.projectName, null, `${payload.formName}/${payload.serviceName}`);
                sendServiceRequestPromise(payload.serviceMethod, requestUrl, getServiceResponse(resolve, reject, payload, requestUrl), getServiceError(reject, payload), payload.requestParameters, payload.requestConfig);
            } catch (error) {
                console.log(`[${payload.projectName}][${payload.formName}][${payload.serviceName}] Error.`, error);
                reject(error);
            }
        } else {
            onDeployAgentNotFound();
            reject("Deploy agent not found")
        }
    })
}

function _RunFlowServicePromise(payload: RunFlowServicePayload) {
    let deployAgent: any = ProjectHelper.getDeployAgent(payload.projectName, payload.projectVersion);
    return new Promise<any>((resolve, reject) => {
        if (deployAgent) {
            try {
                let requestUrl = getDeployAgentUrlTemplate(deployAgent, payload.projectName, payload.projectVersion, `${payload.flowName}/${payload.serviceName}`);
                sendServiceRequestPromise(payload.serviceMethod, requestUrl, getServiceResponse(resolve, reject, payload, requestUrl), getServiceError(reject, payload), payload.requestParameters, payload.requestConfig);
            } catch (error) {
                console.log(`[${payload.projectName}][${payload.flowName}][${payload.serviceName}] Error.`, error);
                reject(error);
            }
        } else {
            onDeployAgentNotFound();
            reject("Deploy agent not found");
        }
    })
}

function onDeployAgentNotFound() {
    BCNotification.severeWarning({
        message: findMessage.get('102684'),
        description: findMessage.get('102685'),
        duration: 5
    });
    sessionStorage.removeItem("deployAgents");
}

function _GetDeploymentUrl(projectName: string, defaultUrl: string, projectVersion?: number) {
    let deployAgent: any = ProjectHelper.getDeployAgent(projectName, projectVersion);
    if (deployAgent) {
        return getDeployAgentUrlTemplate(deployAgent, projectName, projectVersion, null);
    } else {
        return defaultUrl;
    }
}

function waitForDeployStatus(projectName: string, projectVersion?: number, cancelOnError?: boolean): Promise<DeployStatusResponse> {
    return new Promise((resolve) => {
        let _sub: any = null;
        RxEventEmitter.listen(DeployAgentEntities.DeployAgentEventKey, {
            next: (message: DeployAgentEntities.IDeployAgentMessage) => {
                if (message?.ProjectName == projectName && (projectVersion ? (message?.ProjectVersion == projectVersion) : true)) {
                    if (message.Type == DeployAgentEntities.OutputMessageType.Success) {
                        resolve(DeployStatusResponse.Success);
                        _sub?.unsubscribe();
                    } else if (message.Status == DeployAgentEntities.OutputMessageStatus.Cancelled) {
                        resolve(DeployStatusResponse.Cancelled);
                        _sub?.unsubscribe();
                    } else if (message.Type == DeployAgentEntities.OutputMessageType.Error && cancelOnError) {
                        resolve(DeployStatusResponse.Error);
                        _sub?.unsubscribe();
                    }
                }
            }
        }).then(sub => _sub = sub);

    })
}

const getServiceResponse = (resolve: Function, reject: Function, payload: any, requestUrl: string) => (serviceResponse: any) => {
    if (!serviceResponse) {
        console.log(`[${payload.projectName}][${payload.flowName || payload.formName}][${payload.serviceName}] Response Error.`);
        reject();
        return;
    }
    if (Object.keys(serviceResponse.data).includes("queued")) {
        //Project is not ready. listen socket
        let modalId = openDeploymentProgressModal(payload.projectName, payload.projectVersion, true);
        waitForDeployStatus(payload.projectName, payload.projectVersion, payload.cancelOnError).then((res: DeployStatusResponse) => {
            if (res == DeployStatusResponse.Success) {
                sendServiceRequestPromise(payload.serviceMethod, requestUrl, getServiceResponse(resolve, reject, payload, requestUrl), getServiceError(reject, payload), payload.requestParameters, payload.requestConfig);
            } else if ((res == DeployStatusResponse.Error && payload.cancelOnError) || res == DeployStatusResponse.Cancelled) {
                MessageBox.closeModal(modalId);
                reject(res == DeployStatusResponse.Error ? null : -1);
            }
        })

    } else {
        resolve(serviceResponse);
    }
};

const getServiceError = (reject: Function, payload: any) => (error: any) => {
    console.log(`[${payload.projectName}][${payload.flowName || payload.formName}][${payload.serviceName}] Error.`, error);
    reject(error);
}

function sendServiceRequestPromise(method: "get" | "post", requestUrl: string, onResponse: (response: any) => void, onError: (error: any) => void, requestParameters?: any, requestConfig?: BCAjaxRequestConfig) {
    if (method == "get") {
        BCAjax.get(requestUrl, requestConfig).then(onResponse).catch(onError);
    } else {
        BCAjax.post(requestUrl, requestParameters, requestConfig).then(onResponse).catch(onError);
    }
}

function* sendServiceRequestGenerator(method: "get" | "post", requestUrl: string, requestParameters?: any, requestConfig?: BCAjaxRequestConfig) {
    if (method == "get") {
        return yield BCAjax.get(requestUrl, requestConfig);
    } else {
        return yield BCAjax.post(requestUrl, requestParameters, requestConfig);
    }
}

function openDeploymentProgressModal(projectName: string, projectVersion?: number, autoClose?: boolean) {
    let modalId = createGuid();
    MessageBox.show({
        id: modalId,
        content: createElement(ProjectDeploymentProgressContainer, { modalId, projectName, projectVersion, autoClose }),
        props: {
            closable: false,
            maskClosable: false,
            keyboard: false,
            footer: null,
            title: null,
            renderHeader: () => null
        },
        width: "400px",
        buttons: MessageBoxButtons.OK,
        notInCloseIcon: true
    });
    return modalId;
}

function _setProject(name: string, module: unknown) {
    _set(_getGlobalProjectsObject(), ['projects', name], module);
}

function _getProject(name: string) {
    return _get(_getGlobalProjectsObject(), ['projects', name]);
}

function _setProjectInstance(id: string, instance: unknown) {
    _set(_getGlobalProjectsObject(), ['instances', id], instance);
}

function _getProjectInstance(id: string) {
    return _get(_getGlobalProjectsObject(), ['instances', id], null);
}

function _getProjects() {
    return _get(_getGlobalProjectsObject(), ['projects']);
}

function _getInstances() {
    return _get(_getGlobalProjectsObject(), ['instances']);
}

function _initGlobalProjectsObject(initialData: IGlobalProjectInterface) {
    for (const key of ProjectHelper.projectsKeywords) {
        if (!_get(window, [key])) {
            _set(window, [key], initialData)
        }
    }
}

function _getGlobalProjectsObject(): IGlobalProjectInterface {
    return _get(window, [ProjectHelper.projectsKeywords[0]]) || _get(window, [ProjectHelper.projectsKeywords[1]]);
}

function _setProjectLoadListener(listener: (module: unknown) => void) {
    for (const key of ProjectHelper.projectLoadFnKeywords) {
        _set(window, [key], listener)
    }
}

class ProjectHelper {
    static isAgentsReady: boolean = false;
    static deployAgents: any[] = [];
    static agentProjects: AgentProjects = {};
    static setAgentProjects = (projects: AgentProjects) => {
        ProjectHelper.agentProjects = projects;
        Mobile.MobileView.setDeployAgentProjects(projects);
    }
    static setDeployAgents = (deployAgents: any[]) => {
        ProjectHelper.deployAgents = deployAgents;
        Mobile.MobileView.setDeployAgents(deployAgents);
    }
    static setAgentsReady = (isReady: boolean) => {
        ProjectHelper.isAgentsReady = isReady;
    }
    static getDeployAgent = (projectName: string, projectVersion?: number) => {
        if (ProjectHelper.deployAgents.length == 1) {
            return ProjectHelper.deployAgents[0];
        } else if (ProjectHelper.deployAgents.length > 1) {
            let agentProject = ProjectHelper.agentProjects[projectName];
            let projectWithVersion = projectVersion ? agentProject[projectVersion.toString()] : agentProject[Object.keys(agentProject).pop()];
            let agent = ProjectHelper.deployAgents.find(a => a.uId == projectWithVersion.agentUId);
            if (agent) {
                return agent;
            } else return null;
        } else {
            return null;
        }
    }
    static RunGeneratorFlowService = _RunFlowService;
    static RunGeneratorFormService = _RunFormService;
    static RunPromiseFormService = _RunFormServicePromise;
    static RunPromiseFlowService = _RunFlowServicePromise;
    static getDeploymentUrl = _GetDeploymentUrl;

    // Global Project Access

    // eba keywordleri breaking change oluşturmamak adına eklenmiştir. 2024 yılında bu değerler kaldırılabilir.
    static projectsKeywords: string[] = ["SynergyProjects", "eBAProjects"];
    static projectLoadFnKeywords: string[] = ["__loadSynergyProject__", "__loadeBAProject__"];

    static setProjectLoadListener = _setProjectLoadListener;

    static setProject = _setProject;
    static getProject = _getProject;
    static setProjectInstance = _setProjectInstance;
    static getProjectInstance = _getProjectInstance;
    static getProjects = _getProjects;
    static getInstances = _getInstances;
    static getGlobalProjectsObject = _getGlobalProjectsObject;
    static initGlobalProjectsObject = _initGlobalProjectsObject;
    // Global Project Access : End
}
export default ProjectHelper;
export { AgentProjects }