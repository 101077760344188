export default function getBrowser() {
    const userAgent = window.navigator.userAgent;
    if (/Edge/.test(userAgent)) {
        return 'Edge';
    } else if (/Chrome/.test(userAgent)) {
        return 'Chrome';
    } else if (/Safari/.test(userAgent)) {
        return 'Safari';
    } else if (/Firefox/.test(userAgent)) {
        return 'Firefox';
    }
    return 'Unknown';
}