import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { WFListType } from '../../../entities';
import { MenuItem, IMenuItem, MenuItemFactoryFromJS } from '../../../../../common/entities/IMenuItem';

interface IBaseWFManagementPanelContent {
    isLoading: boolean,
    activeItemKey: string,
    activeChildPanelGuid: string,
    activeChildType: WFListType,
}

export interface IWFManagementPanelContent extends IBaseWFManagementPanelContent {
    items: IMenuItem[]

}

export interface IReadonlyWFManagementPanelContent extends IBaseWFManagementPanelContent {
    items: List<MenuItem>
}

export type WFManagementPanelContent = ReadonlyRecord<IReadonlyWFManagementPanelContent>;

export const WFManagementPanelContentFactory = Record<IReadonlyWFManagementPanelContent>({
    isLoading: false,
    activeItemKey: null,
    activeChildPanelGuid: null,
    activeChildType: null,
    items: List()
});

export const WFManagementPanelContentFactoryFromJS = function (data: IWFManagementPanelContent) {
    let _items: MenuItem[] = data?.items.map(i => MenuItemFactoryFromJS(i)) || [];
    return WFManagementPanelContentFactory({
        ...data,
        items: List(_items)
    });
}