import { extend, IDictionary } from "@bimser/core";
import IconHome from "@bimser/icons/16/home";
import * as React from "react";
import PropertyItemsPanel from "../components/PropertyItemsPanel";
import PropertyTabPanel from "../components/PropertyTabPanel";
import { IPropertyItem, IProps, ITabItem } from '../entities';
const _update = require("lodash/update");

const PropertyInspector = React.memo((props: IProps) => {

    const mostUsedProperties = React.useMemo(() => {
        const mostUsedPropertiesString = localStorage.getItem(`PI.MUP.${props.pluginId}`);
        if (mostUsedPropertiesString) {
            const counts = JSON.parse(mostUsedPropertiesString);

            let visiblePropertyKeys: string[] = [];
            if (counts) {
                const minUsed = 10;
                Object.keys(counts).forEach(p => {
                    if (counts[p] > minUsed) visiblePropertyKeys.push(p);
                });
            }

            visiblePropertyKeys = visiblePropertyKeys.sort((a, b) => counts[b] - counts[a]).slice(0, 7);

            let filteredItems: IDictionary<IPropertyItem[]> = {};

            if (visiblePropertyKeys?.length) {
                props.structure.forEach(item => {
                    if (item.hasOwnProperty("panels") || item.hasOwnProperty("properties")) {
                        let tabItem = item as ITabItem;

                        if (tabItem.panels?.length) {
                            tabItem.panels.forEach(panel => {
                                if (!panel.key.includes("mostUsedPropertiesCollapse_")) {
                                    panel.properties.forEach(property => {
                                        if (visiblePropertyKeys.includes(`${props.itemType}.${property.key}`)) {

                                            let newProperty = {
                                                ...property,
                                                groupName: panel.label
                                            };

                                            if (filteredItems[item.key]) {
                                                filteredItems[item.key].push(newProperty);
                                            } else {
                                                filteredItems[item.key] = [newProperty];
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
            }

            return filteredItems
        }
    }, [props.structure]);

    const structureItems = React.useMemo(() => {
        if (mostUsedProperties && Object.keys(mostUsedProperties)?.length) {
            return props.structure.map(i => {
                if (i.hasOwnProperty("panels")) {
                    let tabItem = i as ITabItem;
                    if (mostUsedProperties && mostUsedProperties[tabItem.key]) {

                        const panelKey = "mostUsedPropertiesCollapse_" + tabItem.key;

                        if (tabItem.panels.find(i => i.key === panelKey)) {
                            tabItem.panels = tabItem.panels.map(i => {
                                if (i.key === panelKey) {
                                    i.properties = mostUsedProperties[tabItem.key];
                                }
                                return i
                            })
                        } else {
                            tabItem.panels = [{
                                key: panelKey,
                                expanded: true,
                                label: 'Most Used',
                                properties: mostUsedProperties[tabItem.key],
                                orderNo: 0
                            }, ...tabItem.panels];
                        }
                    }
                }

                return i
            });
        }

        return props.structure || []
    }, [mostUsedProperties, props.structure]);

    const getScrollbarStyles = React.useCallback((): React.CSSProperties => {
        return props.isModal ? { height: props.searchable ? "calc(100% - 60px)" : '100%' } : { height: '100%' }
    }, [props.searchable, props.isModal]);

    const reload = React.useCallback(() => props.reload?.(), []);

    const fireAction = React.useCallback((item: IPropertyItem, actionType: string, args?: string | any) => {
        props.fireAction(item, props.data, actionType, args);
    }, [props.data]);

    const onValueChanged = React.useCallback((item: IPropertyItem, value: any) => {
        let newData: IDictionary<any> = extend(true, {}, props.data);

        if (item.key.includes('.')) {
            _update(newData, item.key, () => value);
        } else {
            newData = Object.assign({}, props.data, {
                ...props.data,
                [item.key]: value
            });
        }

        props.onValueChanged(item, newData, structureItems, props.itemType);
    }, [structureItems, props.itemType, props.data]);

    const renderEmptyTemplate = () => {
        return (
            <PropertyTabPanel
                cssClass={props.cssClass}
                tabItems={emptyStructure}
                data={null}
                renderCustomEmpty={props.renderCustomEmpty}
                emptyTemplateSubText={props.emptyTemplateSubText}
                renderCustomTabContent={props.renderCustomTabContent}
                guid={props.guid}
            />
        )
    }

    if (!structureItems?.length) return renderEmptyTemplate();

    if (structureItems[0].hasOwnProperty("panels") || structureItems[0].hasOwnProperty("properties")) {
        return (
            <PropertyTabPanel
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                cssClass={props.cssClass}
                searchable={props.searchable}
                tabItems={structureItems as Array<ITabItem>}
                onValueChanged={onValueChanged}
                reload={reload}
                fireAction={fireAction}
                data={props.data}
                isModal={props.isModal}
                guid={props.guid}
                emptyTemplateSubText={props.emptyTemplateSubText}
                renderCustomEmpty={props.renderCustomEmpty}
                scrollbarStyles={getScrollbarStyles()}
                getOptionsPromise={props.getOptionsPromise}
                onRefresh={props.onRefresh}
                renderCustomTabContent={props.renderCustomTabContent}
                commonPopoverItems={props.commonPopoverItems}
                showCustomBadgeOnLabel={props.showCustomBadgeOnLabel}
            />
        )
    } else {
        return (
            <PropertyItemsPanel
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                propertyItems={structureItems as Array<IPropertyItem>}
                searchable={props.searchable}
                onValueChanged={onValueChanged}
                fireAction={fireAction}
                data={props.data}
                scrollbarStyles={getScrollbarStyles()}
                getOptionsPromise={props.getOptionsPromise}
                onRefresh={props.onRefresh}
                cssClass={props.cssClass}
                guid={props.guid}
                commonPopoverItems={props.commonPopoverItems}
                showCustomBadgeOnLabel={props.showCustomBadgeOnLabel}
            />
        )
    }

});

const emptyStructure: Array<ITabItem> = [
    {
        key: '__emptyGeneralTab',
        icon: IconHome.info,
        label: "General",
        selected: true,
        panels: null
    }
];

export default PropertyInspector