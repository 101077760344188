import { IWebInterfaceAction } from 'common/entities';
import { connect } from 'react-redux';
import { WebInterfaceBaseState } from '../../../base/state';
import PanelMenu from '../components';
import { IPanelMenuCProps, IPanelMenuProps } from '../entities';
import { getPanelMenuItems, getPanelActiveNodeId } from '../selectors';
import { panelMenuItemClickedTransaction } from '../../../actions';

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IPanelMenuProps): IPanelMenuProps {
    return {
        items: getPanelMenuItems(state, props),
        activeNodeId: getPanelActiveNodeId(state, props)
    }
}

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IPanelMenuCProps {
    return {
        onItemClicked: function (data: IWebInterfaceAction) {
            dispatch(panelMenuItemClickedTransaction(data));
        }
    };
}

// react - redux connection
const PanelMenuContainer = connect(mapStateToProps, mapDispatchToProps)(PanelMenu);

export default PanelMenuContainer;