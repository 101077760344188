import { checkPropNullOrUndefined, getPopupContainer } from '@bimser/core';
import ConfigProvider from 'antd/lib/config-provider';
import DatePicker from 'antd/lib/date-picker';
import { CommonPickerMethods } from 'antd/lib/date-picker/generatePicker/interface';
import en_EN from 'antd/lib/locale-provider/en_GB';
import tr_TR from 'antd/lib/locale-provider/tr_TR';
import * as React from "react";
import * as Styles from './assets/styles.scss';
import { DatePickerMode, IDateRangePickerProps, RangePickerPresetRange, RangePickerValue } from './entities';

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const BCDateRangePicker = React.memo(React.forwardRef((props: IDateRangePickerProps, ref: React.ForwardedRef<CommonPickerMethods>) => {

    const [open, setOpen] = React.useState<boolean>();
    const [mustBeClosed, setMustBeClosed] = React.useState(false);

    const innerRef = React.useRef<CommonPickerMethods>(null);
    React.useImperativeHandle(ref, () => innerRef.current);

    const onChange = (dates: RangePickerValue, dateStrings: [string, string]) => {
        if (props.onChange) {

            let startDate = null;
            let endDate = null;

            if (dates) {
                startDate = dates[0] ? dates[0].format() : null;
                endDate = dates[1] ? dates[1].format() : null;
            }

            props.onChange(dates, [startDate, endDate], dateStrings);
        }

        if ((props.value[0] && !dateStrings[0]) || (props.value[1] && !dateStrings[1])) {
            innerRef.current?.blur();
            setMustBeClosed(false);
        }
    }

    const getFormat = () => {
        if (props.format) return props.format;
        return props.showTime ? "L HH:mm:ss" : "L";
    }

    const onOpenChange = (open: boolean) => {
        if ((props.value[0] || props.value[1]) && mustBeClosed) {
            setOpen(false);
        } else {
            setOpen(open);
        }
        setMustBeClosed(false);
    }

    const getPopupContainerFn = (node: HTMLDivElement) => {
        const candidateGeneralTab = getPopupContainer(node)?.parentNode?.parentNode?.parentNode;
        return candidateGeneralTab?.id?.startsWith("rc-tabs-") ? candidateGeneralTab : document.body;
    }

    const className = cx({
        dateRangePickerStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center",
        [props.className]: true
    });

    return (
        <ConfigProvider locale={localStorage.getItem("locale") === "tr-TR" ? tr_TR : en_EN}>
            <DatePicker.RangePicker
                ref={innerRef}
                picker={props.mode}
                getPopupContainer={getPopupContainerFn}
                autoFocus={props.autoFocus}
                className={className}
                defaultValue={props.defaultValue}
                onChange={onChange}
                placeholder={props.placeholder}
                size={props.size}
                disabled={props.disabled}
                disabledDate={props.disabledDate}
                showTime={props.showTime}
                showToday={props.showToday}
                format={props.mode === 'date' || !props.mode ? getFormat() : undefined}
                style={props.style}
                value={props.value}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                onKeyDown={props.onKeyDown}
                allowClear={props.allowClear && !props.readOnly}
                disabledTime={props.disabledTime}
                open={props.readOnly ? false : open}
                inputReadOnly={props.readOnly}
                onOpenChange={onOpenChange}
                {...checkPropNullOrUndefined(props, "disabledTime")}
                {...checkPropNullOrUndefined(props, "onPanelChange")}
                {...checkPropNullOrUndefined(props, "renderExtraFooter")}
                {...checkPropNullOrUndefined(props, "onCalendarChange")}
                {...checkPropNullOrUndefined(props, "ranges")}
                {...checkPropNullOrUndefined(props, "disabledDate")}
                {...checkPropNullOrUndefined(props, "allowEmpty")}

            />
        </ConfigProvider>
    )

}));

export default BCDateRangePicker
export { IDateRangePickerProps, RangePickerValue, RangePickerPresetRange, DatePickerMode , CommonPickerMethods as BCDateRangePickerRef };

