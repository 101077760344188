import { BCLabel, BCList, BCSkeleton, ContentType, findMessage } from "@bimser/components";
import IconGo from "@bimser/icons/16/go";
import IconRefresh from "@bimser/icons/16/refresh";
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import { panelType } from "common/entities/enums";
import * as React from "react";
import { isNullOrUndefined } from "@bimser/core";
import { IEventItem } from "../../../workflow/entities/IEventItem";
import { IProcessItem } from "../../../workflow/entities/IProcessItem";
import * as Styles from "../../assets/styles.scss";
import IApprovesBoxCProps from "../../entities/IApprovesBoxCProps";
import IApprovesBoxProps from "../../entities/IApprovesBoxProps";
import IApprovesBoxState from "../../entities/IApprovesBoxState";
import ApproveItem from "./ApproveItem";
const approveEmptyImage = require("../../assets/img/approval-empty.svg");

const sizeme = require('react-sizeme');

var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

class ApprovesBox extends React.Component<IApprovesBoxProps & IApprovesBoxCProps, IApprovesBoxState> {

    constructor(props: IApprovesBoxProps & IApprovesBoxCProps) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.onClickAllApproves = this.onClickAllApproves.bind(this);
        this.openApproveDetail = this.openApproveDetail.bind(this);
        this.onQuickEvent = this.onQuickEvent.bind(this);
        this.renderCustomEmpty = this.renderCustomEmpty.bind(this);
        this.onClickRefreshApprovesList = this.onClickRefreshApprovesList.bind(this);
        this.getRef = this.getRef.bind(this);
        this.calculateItems = this.calculateItems.bind(this);

        this.state = {
            contentRef: null
        }
    }
    scrollbarStyle = { width: '100%', height: '100%' };

    strings = {
        MY_TASKS: findMessage.get('100965'),
        SEE_ALL_TASKS: findMessage.get('100964'),
        EMPTY_TITLE: findMessage.get('101703'),
        EMPTY_DESC: findMessage.get('101704'),
        EMPTY_UPDATE_LIST: findMessage.get('101705')
    }


    private onClickAllApproves(e: any) {
        this.props.onClickAllApproves({
            panelId: this.props.startupPanelId
        });
    }

    private onQuickEvent(process: IProcessItem, event: IEventItem) {
        this.props.onQuickEvent({
            panelId: this.props.startupPanelId,
            panelType: panelType.deprecatedStartupLayout,
            data: {
                process,
                event
            }
        })
    }

    onClickRefreshApprovesList() {
        this.props.onRefreshApprovesList({
            panelId: this.props.startupPanelId
        });
    }

    openApproveDetail(args: IProcessItem) {
        this.props.openApproveDetail({
            panelId: this.props.startupPanelId,
            panelType: panelType.deprecatedStartupLayout,
            data: args
        });
    }

    private renderItem(itemProps: IProcessItem): React.ReactNode {
        return (
            <ApproveItem item={itemProps} onClick={this.openApproveDetail} />
        )
    }

    renderCustomEmpty() {
        return (
            <div className={Styles.startupBoxEmptyWrapper}>
                <img src={approveEmptyImage.default} className={Styles.emptyImage} />
                <span className={Styles.emptyInfoTitle}>{this.strings.EMPTY_TITLE}</span>
                <span className={Styles.emptyInfo}>{this.strings.EMPTY_DESC} <BCLabel onClick={this.onClickRefreshApprovesList}>{this.strings.EMPTY_UPDATE_LIST}</BCLabel></span>
            </div>
        )
    }

    getRef(node: HTMLDivElement) {
        this.setState({
            contentRef: node
        })
    }

    calculateItems() {
        if (isNullOrUndefined(this.state.contentRef)) {
            return this.props.items.slice(0, 10);
        } else {
            let height = this.state.contentRef.clientHeight;
            let itemCount = Math.floor(height / 56);
            return this.props.items.slice(0, itemCount);
        }
    }

    private generateTemplate(): JSX.Element {

        return (
            <div className={Styles.BoxContainer}>
                <div className={Styles.header}>
                    <span className={Styles.title}>{this.strings.MY_TASKS}
                    <IconRefresh className={Styles.refreshApproves}  onClick={this.onClickRefreshApprovesList} /></span>
                </div>
                <div className={[Styles.content, Styles.withHeader, Styles.withFooter].join(' ')} ref={this.getRef}>
                    <BCSkeleton
                        contentType={ContentType.BigBulletSingle}
                        loadingStatus={this.props.showLoading}
                        className={"skeleton"}
                        rowCount={-1}
                    >
                        <BCList dataSource={this.calculateItems()}
                            bordered={false}
                            split={false}
                            loading={false}
                            className={Styles.approvesBoxContainer}
                            customEmpty={this.renderCustomEmpty()}
                            renderItem={this.renderItem} />
                    </BCSkeleton>
                </div>
                {
                    this.props.items && this.props.items.length > 0 &&
                    <div className={Styles.footer}>
                        <a className={Styles.link} onClick={this.onClickAllApproves}>
                            {this.strings.SEE_ALL_TASKS}
                            <span className={Styles.icon}><IconGo /></span>
                        </a>
                    </div>
                }
            </div>
        );
    }

    public render() {
        return (
            this.generateTemplate()
        );
    }
}

export default sizeme({ refreshRate: 1000, monitorHeight: true })(ApprovesBox);