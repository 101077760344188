import { getMessages } from "@bimser/components";
import { IBaseAction, IDictionary } from "@bimser/core";
import { applyMiddleware, compose, createStore, Reducer, Store } from "redux";
import createSagaMiddleware from 'redux-saga';
import onLoadMessagesAction from "../action/onLoadMessagesAction";

const filteredActionTypes: IDictionary<any> = {
    'APP_COMMON.ON_LOAD_MESSAGES': 'APP_COMMON.ON_LOAD_MESSAGES',
    'APP_COMMON.GET_USER_SETTING_PARAMATERS': 'APP_COMMON.GET_USER_SETTING_PARAMATERS',

    //Web Interface
    'LEFT_MENU.SAVE_ITEMS': 'LEFT_MENU.SAVE_ITEMS',
    'ANNOUNCEMENT.GET_ALL_ANNOUNCEMENT_ACTION': 'ANNOUNCEMENT.GET_ALL_ANNOUNCEMENT_ACTION',

    //IDE
    'MENUBAR.ADD_OR_UPDATE_ITEMS': 'MENUBAR.ADD_OR_UPDATE_ITEMS',
    'CODE_EDITOR.ADD_OR_UPDATE_EXTRA_LIBRARY': 'CODE_EDITOR.ADD_OR_UPDATE_EXTRA_LIBRARY',
    'STARTUP.SET_RECENT_ITEMS': 'STARTUP.SET_RECENT_ITEMS',
    'MESSAGEBOX.OPEN_MESSAGEBOX_TRANSACTION_ACTION': 'MESSAGEBOX.OPEN_MESSAGEBOX_TRANSACTION_ACTION',
    'MODALMANAGER.OPEN_MODAL': 'MODALMANAGER.OPEN_MODAL',
    'PROPERTY_INSPECTOR.ON_COLLECTION_OPEN': 'PROPERTY_INSPECTOR.ON_COLLECTION_OPEN',
};

const actionSanitizer = (action: IBaseAction<any>) => {
    //Test Large Data
    //if (action.payload && action.payload.toJS && JSON.stringify(action.payload.toJS()).length > 4000) {
    //    console.warn("Large data detected on action. Please remove it if not necessarry! ", action.type, action.payload.toJS());
    //}

    return filteredActionTypes[action.type] ? { ...action, payload: '<<LONG_BLOB>>' } : action;
};

const stateSanitizer = (state: any) => {
    try {
        state = state.set('i18n', '<<LONG_BLOB>>');
        state = state.setIn(['user', 'token'], '<<LONG_BLOB>>');
        state = state.setIn(['user', 'profileImage'], '<<LONG_BLOB>>');
        state = state.setIn(['user', 'settingParameters', 'profileImage'], '<<LONG_BLOB>>');
        state = state.setIn(['user', 'MFAProperties', 'tempTokenKey'], '<<LONG_BLOB>>');
        state = state.setIn(['user', 'MFAProperties', 'totpRegisterProperties', 'provisionUrl'], '<<LONG_BLOB>>');
        state = state.setIn(['user', 'MFAProperties', 'totpRegisterProperties', 'manuelEntryCode'], '<<LONG_BLOB>>');
        //Web Interface
        state = state.setIn(['modules', 'menuDesigner', 'leftmenuItems'], '<<LONG_BLOB>>');
        state = state.setIn(['modules', 'announcements', 'items'], '<<LONG_BLOB>>');
        state = state.setIn(['layouts', 'leftMenu', 'items'], '<<LONG_BLOB>>');
        state = state.setIn(['layouts', 'leftMenu', 'menuDesigner', 'menuStructure', 'nodes'], '<<LONG_BLOB>>');
        state = state.setIn(['layouts', 'leftMenu', 'menuDesigner', 'menuStructure', 'profiles'], '<<LONG_BLOB>>');
        //IDE
        state = state.setIn(['layout', 'menubar'], '<<LONG_BLOB>>');
        state = state.setIn(['plugins', 'CODE_EDITOR', 'extraLibraries'], '<<LONG_BLOB>>');
        state = state.setIn(['plugins', 'STARTUP', 'data', 'recentItems'], '<<LONG_BLOB>>');
        state = state.setIn(['modals', 'items'], '<<LONG_BLOB>>');
    } catch (e) {
        console.warn('stateSanitizer', e);
    }
    return state
}

const composeEnhancers =
    typeof window === 'object' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            actionSanitizer,
            stateSanitizer,
            features: {
                test: false, // generate tests for the selected actions
                jump: true, // jump back and forth (time travelling)
                skip: true, // skip (cancel) actions
                dispatch: true, // dispatch custom actions or action creators
            },
            serialize: {
                options: {
                    undefined: true,
                    function: function (fn: any) { return fn.toString() }
                }
            }
        }) : compose;

export default function (rootReducer: Reducer<any>, rootSaga: any): Store<any> {

    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );

    (store as any).injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

    store.dispatch(onLoadMessagesAction(getMessages()));

    return store;
}

function createSagaInjector(runSaga: any, rootSaga: any) {
    // Create a dictionary to keep track of injected sagas
    const injectedSagas = new Map();

    const isInjected = (key: string) => injectedSagas.has(key);

    const injectSaga = (key: string, saga: any) => {
        // We won't run saga if it is already injected
        if (isInjected(key)) return;

        // Sagas return task when they executed, which can be used
        // to cancel them
        const task = runSaga(saga);

        // Save the task if we want to cancel it in the future
        injectedSagas.set(key, task);
    };

    // Inject the root saga as it a staticlly loaded file,
    injectSaga('root', rootSaga);

    return injectSaga;
}