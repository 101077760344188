import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { List, Map, Record } from 'immutable';
import { IPermission, Permission, PermissionFactoryFromJS } from './Permission';
import { IPermissionGroupMember, PermissionGroupMember, PermissionGroupMemberFactoryFromJS } from './PermissionGroupMember';

interface IBasePermissionGroup {
    id: number,
    createdAt?: string,
    createdBy?: number,
    deletedAt?: string,
    deletedBy?: number,
    modifiedAt?: string,
    modifiedBy?: number,
    secretKey: string,
    isPermissionsFetched?: boolean,
    isMemberFetched?: boolean,
    isVirtual?: boolean,
}

export interface IPermissionGroup extends IBasePermissionGroup {
    name?: IDictionary<string>,
    description?: IDictionary<string>,
    members?: IPermissionGroupMember[],
    membersOf?: IPermissionGroupMember[],
    permissionItems?: Array<IPermission>,
}

export interface IReadonlyPermissionGroup extends IBasePermissionGroup {
    name?: Map<string, string>,
    description?: Map<string, string>,
    members?: List<PermissionGroupMember>,
    membersOf?: List<PermissionGroupMember>,
    permissionItems?: List<Permission>
}

export type PermissionGroup = ReadonlyRecord<IReadonlyPermissionGroup>;

export const PermissionGroupFactory = Record<IReadonlyPermissionGroup>({
    id: null,
    createdAt: null,
    createdBy: null,
    deletedAt: null,
    deletedBy: null,
    modifiedAt: null,
    modifiedBy: null,
    secretKey: null,
    name: null,
    description: null,
    members: null,
    membersOf: null,
    permissionItems: List(),
    isPermissionsFetched: false,
    isMemberFetched: false,
    isVirtual: false
});

export const PermissionGroupFactoryFromJS = function (data: IPermissionGroup) {
    let _members: PermissionGroupMember[] = data && data.members && data.members.map(member => {
        return PermissionGroupMemberFactoryFromJS(member);
    })

    let _membersOf: PermissionGroupMember[] = data && data.membersOf && data.membersOf.map(member => {
        return PermissionGroupMemberFactoryFromJS(member);
    })

    let _permissionItems: Permission[] = data && data.permissionItems && data.permissionItems.map(permission => {
        return PermissionFactoryFromJS(permission);
    })

    return PermissionGroupFactory({
        ...data,
        name: Map(data.name),
        description: Map(data.description),
        members: List(_members),
        membersOf: List(_membersOf),
        permissionItems: List(_permissionItems)
    });
}
