import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from 'immutable';
import { IFolderItem } from ".";
import { FolderItem, FolderItemFactoryFromJS } from "./IFolderItem";
import { IFileMoveHistoryItem, FileMoveHistoryItem, FileMoveHistoryItemFactoryFromJS } from "./IFileMoveHistoryItem";

interface IBaseFileMovePanelContent{
    loading?: boolean,
    isLoadingMore?: boolean,
    canLoadMore?:boolean
}

export interface IFileMovePanelContent extends IBaseFileMovePanelContent {
    sourceSecretKeys?: Array<string>,
    selectedFileNames?: Array<string>,
    history?: Array<IFileMoveHistoryItem>
    folderList?: Array<IFolderItem>
}

export interface IReadonlyFileMovePanelContent extends IBaseFileMovePanelContent {
    sourceSecretKeys?: List<string>,
    selectedFileNames?: List<string>,
    history?: List<FileMoveHistoryItem>,
    folderList?: List<FolderItem>
}

export type FileMovePanelContent = ReadonlyRecord<IReadonlyFileMovePanelContent>;

export const  FileMovePanelContentFactory = Record<IReadonlyFileMovePanelContent>({
    loading: false,
    isLoadingMore: false,
    canLoadMore: true,
    sourceSecretKeys: null,
    selectedFileNames: null,
    folderList : null,
    history: null
});

export const FileMovePanelContentFactoryFromJS = function (data: IFileMovePanelContent) {
    let _folderList: Array<FolderItem> = (data && data.folderList) ? data.folderList.map((folder)=>{
        return FolderItemFactoryFromJS(folder);
    }): [];

    let _history: Array<FileMoveHistoryItem> = (data && data.history) ? data.history.map((d)=> {
        return FileMoveHistoryItemFactoryFromJS(d);
    }): [];

    return FileMovePanelContentFactory({
        ...data,
        history: List(_history),
        sourceSecretKeys: List(data.sourceSecretKeys),
        selectedFileNames: List(data.selectedFileNames),
        folderList: List(_folderList)
    });
}

/**
 *      "name": "string",
        "description": "string",
        "defaultName": "string",
        "defaultDescription": "string",
        "isDeleted": true,
        "deleteUserId": "string",
        "deletionTime": "2019-05-28T10:39:03.184Z",
        "creatorUserId": "string",
        "creationTime": "2019-05-28T10:39:03.184Z",
        "lastModifierUserId": "string",
        "lastModificationTime": "2019-05-28T10:39:03.184Z",
        "type": 0,
        "id": 0
 */