enum panelType {
    none,
    HrMenuForm,
    userForm,
    userListForm,
    companyForm,
    companyListForm,
    companyAdminListForm,
    managerKeyForm,
    managerKeyListForm,
    departmentForm,
    departmentListForm,
    positionForm,
    positionListForm,
    professionForm,
    professionListForm,
    userGroupForm,
    userGroupListForm,
    organizationMaintenance,
    userMaintenanceForm,
    userMaintenanceListForm,
    departmentMaintenanceForm,
    departmentMaintenanceListForm,
    positionMaintenanceForm,
    positionMaintenanceListForm,
    professionMaintenanceForm,
    professionMaintenanceListForm,
    propertyDefinition,
    propertiesForm,
    userPropertiesForm,
    positionPropertiesForm,
    professionPropertiesForm,
    departmentPropertiesForm,
    userGroupPropertiesForm,
    propertyTypeForm,
    shiftListForm,
    shiftForm,
    overtimeForm,
    overtimeListForm,
    salaryForm,
    salaryListForm,

    announcementList,
    announcementForm,
    delegationList,
    delegationForm,
    WorkflowFrame,
    flowViewer,
    flowSchema,
    flowHistory,
    startupLayout,
    deprecatedStartupLayout,
    userMenuPopover,

    LeftMenu,
    sharedUsersList,
    legacyFrame,
    dmExplorer,
    DMExplorerPanel,
    panelMenu,
    configurationManagerPanel,
    profileFormList,
    eBAApplicationForm,
    dmDashboard,
    dmComparison,
    menuDesigner,
    menuDesignerPanel,
    openSelectionForm,
    dmAdvancedSearch,
    Security,
    AllApps,
    profileForm,
    LogViewer,
    wfManagement,
    wfList,
    wfProcessList,
    dmViewer,
    dmContentList,
    dmRelationList,
    indexReport,
    activities,
    taskManagement,
    dataSecurity,
    dsDataTypes,

    HumanResource,

    ActionManagement,
    ActionMenu,
    ActionViewer
}

export { panelType }