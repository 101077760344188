import { createMutableAction, createSyncAction } from "@bimser/core";
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS, panelType } from "../../../../common/entities";
import * as ActionTypes from './actionTypes';
import { IMenuItem, MenuItemFactoryFromJS } from "../../../../common/entities/IMenuItem";
import { List } from 'immutable'
import { ILeftMenuSaveItemsActionPayload } from '../entities';

export function leftMenuAddNewItem(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.ADD_NEW_ITEM, WebInterfaceActionFactoryFromJS(data));
}

export function leftMenuToggleActive(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.TOGGLE_ACTIVE, WebInterfaceActionFactoryFromJS(data));
}

export function leftMenuSetActiveNodeKey(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.SET_ACTIVE_NODEKEY, WebInterfaceActionFactoryFromJS(data));
}

export function leftMenuRemoveItem(data: IMenuItem) {

    let payload = MenuItemFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.LEFT_MENU_REMOVE_ITEM, payload);

    return action;
}

export function leftMenuItemClickedTransaction(data: IWebInterfaceAction) {

    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.ITEM_CLICKED, payload);

    return action;
}

export function leftMenuSaveItems(payload: ILeftMenuSaveItemsActionPayload) {

    let _payload = { items: List(payload.items.map(item => MenuItemFactoryFromJS(item))) };

    let action = createMutableAction(ActionTypes.LEFT_MENU_SAVE_ITEMS, _payload);

    return action;
}

export function seteBA6Enabled(payload: boolean) {
    return createMutableAction("SYSTEM.SET_EBA6_ENABLED", payload);
}

export function leftMenuSetActivePanel(activePanelId: string, activePanelType: panelType, activeNodeKey?: string) {

    let action = createMutableAction(ActionTypes.LEFT_MENU_SET_ACTIVE_PANEL, { activePanelId, activePanelType, activeNodeKey });

    return action;
}

/* Designer */


export function menudesignerSaveAllAction() {
    let action = createSyncAction(ActionTypes.SAVE_ALL, null);
    return action;
}


export function menudesignerSetMenuStructureAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.SET_MENU_STRUCTURE_DATA, payload);
    return action;
}

export function menudesignerPintoLauncherAction(data: IMenuItem) {
    let payload = MenuItemFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.PIN_TO_LAUNCHER, payload);
    return action;
}


export function menuDesignerChangeVisibility(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.CHANGE_VISIBILITY, payload);
    return action;
}

export function menuDesignerApplyToSubNodesProfileAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.APPLY_TO_SUB_NODES_PROFILE, payload);
    return action;
}

export function menudesignerSetFormsAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.SET_FORMS, payload);
    return action;
}

export function menudesignerSetFlowsAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.SET_FLOWS, payload);
    return action;
}

export function menudesignerSetDeploymentsAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.SET_DEPLOYMENTS, payload);
    return action;
}

export function menudesignerChangeSelectedProjectTransaction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.CHANGE_SELECTED_PROJECT, payload);
    return action;
}

export function menudesignerChangeSelectedFlowTransaction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.CHANGE_SELECTED_FLOW, payload);
    return action;
}

export function menudesignerChangeSelectedFormTransaction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.CHANGE_SELECTED_FORM, payload);
    return action;
}

export function menudesignerSetProjectsAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.SET_PROJECTS, payload);
    return action;
}

export function menudesignerChangeSelectedProfileAction(data: string) {
    let action = createMutableAction(ActionTypes.CHANGE_SELECTED_PROFILE, data);
    return action;
}

export function menuDesignerOnChangeItemDataAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.ONCHANGE_ITEM_DATA, payload);
    return action;
}

export function menuDesignerOnChangeItemDataTransactionAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.ONCHANGE_ITEM_DATA_TRANSACTION_ACTION, payload);
    return action;
}

export function menudesignerEditMenuProfileItemAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.EDIT_MENU_PROFILE, payload);
    return action;
}

export function menudesignerDeleteMenuProfileItemAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.DELETE_MENU_PROFILE_ITEM, payload);
    return action;
}

export function menudesignerAddNewMenuProfileItemAction(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.ADD_NEW_MENU_PROFILE_ITEM, payload);
    return action;
}

export function setEBA6IntegrationDataAction(data: any) {
    return createMutableAction(ActionTypes.SET_EBA6_INTEGRATION_DATA, data);
}

export function setEBA6IntegrationLoadingAction(active: boolean) {
    return createMutableAction(ActionTypes.SET_EBA6_INTEGRATION_LOADING, active);
}

export function eBA6IntegrationResolveTransaction(payload: any) {
    return createSyncAction(ActionTypes.RESOLVE_EBA6_INTEGRATION_TRANSACTION, payload);
}

export function eBA6IntegrationRejectTransaction(payload: any) {
    return createSyncAction(ActionTypes.REJECT_EBA6_INTEGRATION_TRANSACTION, payload);
}