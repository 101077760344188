import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

export interface IRole {
    type: number,
    id: string
}
export type Role = ReadonlyRecord<IRole>;

export const AnnouncementRoleFactory = Record<IRole>({
    id: null,
    type: null
});

export const AnnouncementRoleFactoryFromJS = function (role: IRole) {
    return AnnouncementRoleFactory(role);
}