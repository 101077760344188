import { List, Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

interface IContentLayoutResult {
    panels: Array<string>
}

interface IReadOnlyContentLayoutResult {
    panels: List<string>
}

export type ContentLayoutResult = ReadonlyRecord<IReadOnlyContentLayoutResult>;

export const ContentLayoutResultFactory = Record<IReadOnlyContentLayoutResult>({
    panels: List<string>(),
});

export const ContentLayoutResultFactoryFromJS = function (data: IContentLayoutResult) {
    return ContentLayoutResultFactory({
        panels: List(data.panels)
    });
}