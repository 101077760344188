enum OutputMessageType {
    Info,
    Success,
    Warning,
    Error
}

enum OutputMessageStatus {
    Processing,
    Waiting,
    Done,
    Cancelled,
    Timeout
}

export {
    OutputMessageStatus,
    OutputMessageType
}