import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IProperty, Property, PropertyFactory, PropertyFactoryFromJS } from "..";

export interface IBaseUserPropertiesFormContent extends IBaseFormContent {
    selectedPropertyId?: number
}

export interface IUserPropertiesFormContent extends IBaseUserPropertiesFormContent {
    
}

export interface IReadonlyUserPropertiesFormContent extends IBaseUserPropertiesFormContent {
}

export type UserPropertiesFormContent = ReadonlyRecord<IReadonlyUserPropertiesFormContent>;

export const UserPropertiesFormContentFactory = Record<IReadonlyUserPropertiesFormContent>({
    panelId: "",
    showLoading: true,
    selectedPropertyId: -1,
    editWindowGuid: "",
    editWindowisActive: false,
    selectableListPanelisActive : false,
    isEventButtonsActive: true
});

export const UserPropertiesFormContentFactoryFromJS = function (data: IUserPropertiesFormContent) {
    return UserPropertiesFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedPropertyId: data.selectedPropertyId,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectableListPanelisActive : data.selectableListPanelisActive,
        isEventButtonsActive: data.isEventButtonsActive
    });
}