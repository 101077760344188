import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Record, Map } from "immutable";
import { ISummaryMessage, SummaryMessage, SummaryMessageFactoryFromJS } from ".";

export interface IBaseFormsState {
    documentId: number,
    lastBatchGuid: string,
    endTransactionGuid: string,
    overrideClientUrl?: string,
    runSecretKey?: string,
}

export interface IFormsState extends IBaseFormsState {
    items: LayoutCreatorEntities.INormalizedLayoutItems,
    structureItems: LayoutCreatorEntities.INormalizedLayoutStructureItems,
    dataItems: IDictionary<LayoutCreatorEntities.ILayoutDataItem>,
    summaryMessages?: Array<ISummaryMessage>
}


export interface IReadonlyFormsState extends IBaseFormsState {
    items: LayoutCreatorEntities.NormalizedLayoutItems,
    structureItems: LayoutCreatorEntities.NormalizedLayoutStructureItems,
    dataItems: Map<string, LayoutCreatorEntities.LayoutDataItem>,
    summaryMessages?: List<SummaryMessage>
}

export type FormsState = ReadonlyRecord<IReadonlyFormsState>;

export const FormsStateFactory = Record<IReadonlyFormsState>({
    items: LayoutCreatorEntities.NormalizedLayoutItemsFactory(),
    structureItems: LayoutCreatorEntities.NormalizedLayoutStructureItemsFactory(),
    dataItems: Map(),
    documentId: 0,
    lastBatchGuid: null,
    endTransactionGuid: null,
    summaryMessages: List(),
    overrideClientUrl: null,
    runSecretKey: null
});

export const FormsStateFactoryFromJS = function (data: IFormsState) {

    let summaryMessages: Array<SummaryMessage> = data.summaryMessages && data.summaryMessages.length ? data.summaryMessages.map(message => {
        return SummaryMessageFactoryFromJS(message)
    }) : [];

    let dataItems: IDictionary<LayoutCreatorEntities.LayoutDataItem> = {};

    for (let key in data.dataItems) {
        let item = data.dataItems[key];
        dataItems[key] = LayoutCreatorEntities.LayoutDataItemFactoryFromJS(item);
    }

    return FormsStateFactory({
        items: LayoutCreatorEntities.NormalizedLayoutItemsFactoryFromJS(data.items),
        structureItems: LayoutCreatorEntities.NormalizedLayoutStructureItemsFactoryFromJS(data.structureItems),
        dataItems: Map(dataItems),
        documentId: data.documentId,
        lastBatchGuid: data.lastBatchGuid,
        endTransactionGuid: data.endTransactionGuid,
        summaryMessages: List(summaryMessages),
        overrideClientUrl: data.overrideClientUrl,
        runSecretKey: data.runSecretKey
    });
}