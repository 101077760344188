import { createGuid, ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import ActivityOriginType from "./ActivityOriginType";
import { IActivity, Activity, ActivityFactoryFromJS } from "./IActivity";

interface IBaseActivityPanelContent {
    panelId: string,
    isLoading?: boolean
    originType?: ActivityOriginType,
    originObjectId?: number,
    originSecretKey?: string,
    hasMore?: boolean,
    scrollKey?: string,
    onlyMajor?: boolean
}

export interface IActivityPanelContent extends IBaseActivityPanelContent {
    activities?: Array<IActivity>

}

export interface IReadonlyActivityPanelContent extends IBaseActivityPanelContent {
    activities?: List<Activity>

}

export type ActivityPanelContent = ReadonlyRecord<IReadonlyActivityPanelContent>;

export const ActivityPanelContentFactory = Record<IReadonlyActivityPanelContent>({
    panelId: null,
    isLoading: false,
    activities: List(),
    originType: ActivityOriginType.None,
    originObjectId: null,
    originSecretKey: null,
    hasMore: false,
    onlyMajor: false,
    scrollKey: createGuid()
});

export const ActivityPanelContentFactoryFromJS = function (data: IActivityPanelContent) {
    return ActivityPanelContentFactory({
        ...data,
        activities: List(data?.activities?.map(a => ActivityFactoryFromJS(a)) || [])
    });
}