import { createSelector ,OutputSelector} from "reselect";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import { TenantCustom ,IReadonlyTenantCustom, TenantCustomFactory} from "../entities/ITenantCustom";
import {Record} from 'immutable'

const defaultTenant = (state: BaseState) => {
    return state.system.tenant;
}
const getTenantCustom = createSelector(
    [
        defaultTenant
    ],
    (tenant: TenantCustom) => {
        if(tenant){
            return tenant.toJS()
        }
        return TenantCustomFactory().toJS()
    }
);

export {
    getTenantCustom
}