import { all } from 'redux-saga/effects';
import watchLoginTransaction from "./loginTransaction";
import watchLoginParametersTransaction from './loginParametersTransaction';
import watchControlTokenTransaction from './controlTokenTransaction';
import watchGetUserStartupTransaction from './getUserStartupTransaction';
import watchPasswordRecoveryTransaction from './passwordRecoveryTransaction';
import watchChangeMyPasswordTransaction from './changeMyPasswordRecoveryTransaction';
import watchChangeProfileImageTransaction from './changeProfileImageTransaction';
import watchChangeSelectedCultureTransaction from './changeSelectedCultureTransaction';
import watchLoginWithTokenTransaction from "./loginWithTokenTransaction";
import watchMobileInitializerTransaction from './mobileInitializerTransaction';
import watchloginWithDigitalSignatureTransaction from './loginWithDigitalSignatureTransaction';
import watchloginWithMobileSignatureTransaction from './loginWithMobileSignatureTransaction';
import watchLoginWithExternalAccountTransaction from './loginWithExternalAccountTransaction';
import watchSSOControlTransaction from './ssoControlTransaction';
import watchSelectMFATypeTransaction from './selectMFATypeTransaction';
import watchValidationMFATransaction from './validateMFATransaction';

export default function* rootSaga() {
    yield all([
        watchLoginTransaction(),
        watchLoginParametersTransaction(),
        watchControlTokenTransaction(),
        watchGetUserStartupTransaction(),
        watchPasswordRecoveryTransaction(),
        watchChangeMyPasswordTransaction(),
        watchChangeProfileImageTransaction(),
        watchChangeSelectedCultureTransaction(),
        watchLoginWithTokenTransaction(),
        watchMobileInitializerTransaction(),
        watchloginWithDigitalSignatureTransaction(),
        watchloginWithMobileSignatureTransaction(),
        watchLoginWithExternalAccountTransaction(),
        watchSSOControlTransaction(),
        watchSelectMFATypeTransaction(),
        watchValidationMFATransaction()
    ]);
}
