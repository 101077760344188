const checkPropNullOrUndefined = (obj: any, propName: string): any => {
    if (obj === null || obj === undefined) {
        return {}
    } else {
        if (obj[propName] === null || obj[propName] === undefined) {
            return {}
        } else {
            return { [propName]: obj[propName] }
        }
    }
}

export { checkPropNullOrUndefined }