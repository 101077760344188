import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";

interface IBaseHolding {
    id: string,
    code: string,
    status: boolean,
}

export interface IHolding extends IBaseHolding {
    description: IDictionary<string>
}

export interface IReadonlyHolding extends IBaseHolding {
    description: Map<string, string>
}

export type Holding = ReadonlyRecord<IReadonlyHolding>;

export const HoldingFactory = Record<IReadonlyHolding>({
    id: null,
    code: '',
    description: Map(),
    status: false
});

export const HoldingFactoryFromJS = function (holding: IHolding) {
    return HoldingFactory({
        ...holding,
        description: Map(holding.description)
    })
}