import { IDmData } from '@bimser/common';
import { BCTabs, findMessage, ITabsItem } from '@bimser/components';
import * as React from 'react';
import AppFormContainer from '../../../../../../../common/components/appForm';
import { IWebInterfaceAction, panelType } from '../../../../../../../common/entities';
import * as Style from '../assets/style.scss';
import { IProfileFormData } from '../entities/IProfileFormData';


interface IProfilesTabsProps {
    panelGuid: string;
    dmObject: IDmData;
    formData: IProfileFormData[];
    selectedIds: string[];
    activeTabKey: string;
    initializedForms?: any;
    initializePanelManager?: (action: IWebInterfaceAction) => void;
    onTabChange?: (activeKey: string) => void;
}

const ProfilesTabs = (props: IProfilesTabsProps) => {

    React.useEffect(() => {
        props.onTabChange(props.dmObject.properties.metaData.value.profiles[0].formId);
    }, []);

    const NoFileSelected = () => (<div className={Style.noFileSelected}><span>{findMessage.get('100026')}</span><span>{findMessage.get('102308')}</span></div>)

    const getTabItems = (): ITabsItem[] => {
        return props.dmObject.properties.metaData.value.profiles.map(profile => {
            return {
                key: profile.formId,
                label: profile.formName + (profile.isRequired ? "(*)" : ""),
            } as ITabsItem
        })
    }

    const renderTabContent = (item: ITabsItem) => {
        let form = props.formData.find(f => f.formData.formId == item.key);
        if (!form) return <NoFileSelected />
        let formViewerId = props.selectedIds.length > 1 ? (item.key + "_multiple") : form.formViewerId
        if (!props.initializedForms[formViewerId]) {
            //initializePanelManager
            AppFormContainer.onPanelOpening().then(() => {
                props.initializePanelManager({
                    panelId: formViewerId,
                    panelType: panelType.eBAApplicationForm,
                    data: {
                        parentPanel: {
                            panelGuid: props.panelGuid,
                            panelType: panelType.profileForm
                        }
                    }
                });
            })
        }
        return <AppFormContainer key={formViewerId} documentId={form.documentId} childFormData={form.formData} panelGuid={formViewerId} disableStored hideFormToolbar />
    }

    return (
        <div>
            <BCTabs
                items={getTabItems()}
                renderTabContent={renderTabContent}
                activeKey={props.activeTabKey}
                onChange={props.onTabChange}
            />
        </div>
    )
}

export default ProfilesTabs