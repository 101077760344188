import React from "react";
import Menu from 'antd/lib/menu';
import Spin from 'antd/lib/spin';
import { IItem } from "@bimser/core";
import * as Styles from '../assets/style.scss';
import MenuItemInline from "../components/MenuItemInline";
import MenuItem from "../components/MenuItem";
import { IMenuProps } from "../";
import { IMenuInfo } from 'BCMenu/entities';
import { ItemType } from "antd/lib/menu/hooks/useItems";
import classNames from 'classnames/bind';

const cx = classNames.bind(Styles);

export function generateMenuContent(props: React.PropsWithChildren<IMenuProps>, addRefToMenuItemList: (domNode: HTMLDivElement, item: IItem) => void) {

    function renderMenuItemComponent(item: IItem) {
        return props.mode === "inline"
            ? (<MenuItemInline item={item} />)
            : (<MenuItem item={item} showEmptyIconSpace={props.showEmptyIconSpace} showShortcutSpace={props.showShortcutSpace} />);
    }

    function generateClassNames(item: IItem) {
        return cx({
            menubarHorizontalSubItem: props.mode === 'horizontal',
            menubarVerticalSubItem: props.mode === 'vertical',
            isMoreOverageItems: item.externalData
        });
    }

    function onSubMenuClick(args: IMenuInfo) {
        const subMenuIndex = props.openKeys.findIndex(key => key === args.key);
        const subMenuPath = props.openKeys.slice(0, subMenuIndex + 1);
        const data = findItemByPath(props.items, (subMenuPath && [...subMenuPath]));
        props.onClick({
            domEvent: args.domEvent,
            data
        });
    }

    function renderMenuItem(item: IItem): ItemType {
        if (item?.items?.length) {
            return item.customTemplate
                ? {
                    key: item.key,
                    label: item.customTemplate(item) as JSX.Element
                }
                : {
                    key: item.key,
                    onTitleClick: onSubMenuClick,
                    className: [props.className, generateClassNames(item), item.className].join(' '),
                    label: <div key={item.key} title={item.title} >{renderMenuItemComponent(item)}</div>,
                    style: item.style,
                    popupClassName: props.popupClassName,
                    children: menuContent(item.items)
                    
                };
        }
        if (item.isLoadingItem) {
            return {
                key: item.key,
                disabled: item.disabled,
                style: item.style,
                className: item.className,
                title: item.title,
                label: <Spin size={"small"} spinning={true} />
            }
        }
        else if (item.isDivider) {
            return {
                type: "divider",
                key: item.key
            }
        }
        else if (props.forceNativeClick) {
            return {
                key: item.key,
                disabled: item.disabled,
                style: item.style,
                className: item.className,
                title: item.title,
                label: <div ref={(domNode) => { addRefToMenuItemList(domNode, item); }}>
                    {renderMenuItemComponent(item)}
                </div>

            }
        }
        else if (item.customTemplate) {
            return {
                key: item.key,
                disabled: item.disabled,
                style: item.style,
                className: item.className,
                label: item.customTemplate(item) as any
            }
        }
        else {
            return {
                key: item.key,
                disabled: item.disabled,
                style: item.style,
                className: item.className,
                title: item.title,
                label: renderMenuItemComponent(item)
            }
        }
    }

    const menuContent = (items: Array<IItem>) => items?.length
        ? items.map(item => renderMenuItem(item))
        : null
    return menuContent;
}

export function findItemByPath(childrens: IItem[], path: string[], index = 0) {
    let item = childrens.find(i => i.key == path[index]);
    const end = index === path.length - 1;
    if (end) {
        return item
    } else {
        return findItemByPath(item.items, path, index + 1)
    }
}