import { BCNotification, ErrorInfo, findMessage } from "@bimser/components";
import { ErrorCodes } from "../..";

function customErrorHandler(e: ErrorInfo) {
    return function* () {
        var errorCode: string = getTaleException(e?.exception?.data?.exception);
        var message = ErrorCodes[errorCode] || findMessage.get("100704");
        BCNotification.error({ message });
    };
}

function getTaleException(e: any): string {
    if (e?.InnerException) {
        return getTaleException(e?.InnerException);
    } else if (e?.ExternalException) {
        return getTaleException(e?.ExternalException);
    }
    return e["Code"];
}


export { customErrorHandler };
