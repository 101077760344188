import { CommandBar } from '../fabric-ui-components';
import * as React from "react";
import * as Style from "./assets/styles.scss";
import IRibbonBarItemProps from './entities/IRibbonBarItemProps';
import IRibbonBarProps from "./entities/IRibbonBarProps";
import { GetIconComponent } from "@bimser/icons";

export default class BCRibbonBar extends React.Component<IRibbonBarProps, {}> {

    constructor(props: IRibbonBarProps) {
        super(props);
        this.modifyItems = this.modifyItems.bind(this);
    }

    modifyItems(): IRibbonBarItemProps[] {
        return this.props.items ? this.props.items.map((item: IRibbonBarItemProps): IRibbonBarItemProps => {
            //tooltip hatasına sebebiyet veriyor. => item.title ribbon item içersindeki butona title attr olarak iletiliyor ve default tooltip çıkıyor.
            //item.title = null;
            return {
                ...item,
                onRenderIcon: (props: any, defaultRender: any) => {
                    if (item.onRenderIcon && typeof item.onRenderIcon === "function") {
                        return item.onRenderIcon(props, defaultRender);
                    }
                    if (props.icon) {
                        return GetIconComponent(props.icon);
                    }
                    if (props.item && props.item.icon && this.props.renderIconInOverflowItems) {
                        return GetIconComponent(props.item.icon);
                    }
                    if (item.icon) {
                        return GetIconComponent(item.icon);
                    }

                    return defaultRender
                },
                tooltipHostProps: {
                    overflowMode: 1,
                    calloutProps: {
                        hidden: true,
                    }
                }
            }
        }) : [];
    }


    render() {

        let { children, customClassName, farItems, overflowButtonAs, overflowButtonProps, overflowItems, commandBarClassName } = this.props;

        return (
            <CommandBar
                className={[Style.BCRibbonBar, customClassName, "BCRibbonBar", commandBarClassName].join(' ')}
                items={this.modifyItems()}
                children={children}
                farItems={farItems}
                overflowButtonAs={overflowButtonAs}
                overflowButtonProps={overflowButtonProps}
                overflowItems={overflowItems}
                style={this.props.customStyles}
            />
        );
    }
}

export { IRibbonBarProps, IRibbonBarItemProps };
