import BCErrorBoundary from '../BCErrorBoundary';
import React, { lazy, Suspense } from 'react';
import { ISchedulerProps, AppointmentsProps } from "./entities/ISchedulerProps";

const BCSchedulerLazy = lazy(
    () => import(/* webpackChunkName: "scheduler" */ "./components")
);

function BCScheduler(props: ISchedulerProps) {
    return (
        <BCErrorBoundary>
            <Suspense fallback={<div></div>}>
                <BCSchedulerLazy {...props} />
            </Suspense>
        </BCErrorBoundary>
    );
}


export {
    ISchedulerProps,
    AppointmentsProps,
    BCScheduler
}
export {
    IAllDayPanelProps,
    IAppointmentFormProps,
    IAppointmentTooltipProps,
    IConfirmationDialogProps,
    ICurrentTimeIndicatorProps,
    IDateNavigatorProps,
    IDayViewProps,
    IDragDropProviderProps,
    IEditingStateProps,
    IEditRecurrenceMenuProps,
    IIntegratedEditingProps,
    IMonthViewProps,
    IResourcesProps,
    ITodayButtonProps,
    IToolbarProps,
    IViewStateProps,
    IViewSwitcherProps,
    IWeekViewProps,
} from "./entities/IModules";
