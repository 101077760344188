import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IUserGroupFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';


const UserGroupFormContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function UserGroupFormContainer(props: React.PropsWithChildren<IUserGroupFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <UserGroupFormContainerLazy {...props} />
        </Suspense>
    );
}
UserGroupFormContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default UserGroupFormContainer;