import * as React from "react";
import * as ReactDOM from "react-dom";
import IClient from "../entities/IClient";

export default class ColorPickerPortal extends React.Component<{ client: IClient }, any> {
    el: HTMLDivElement;
    body = document.getElementsByTagName('body')[0];
    constructor(props: { client: IClient }) {
        super(props);
        this.el = document.createElement('div');
        (this.el as any).style = `position:absolute;top:${this.props.client.x};left:${this.props.client.y};z-index:1000000;`;
    }

    componentDidUpdate(newProps: { client: IClient }, prevState, snapshot) {
        (this.el as any).style = `position:absolute;top:${newProps.client.y}px;left:${newProps.client.x}px;z-index:1000000;`;

        if (snapshot !== null) {
            (this.el as any).style = snapshot;
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.client.y < this.props.client.y || prevProps.client.x < this.props.client.x) {
            return `position:absolute;top:${this.props.client.y}px;left:${this.props.client.x}px;z-index:1000000`;
        }
        return null;
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        this.body.appendChild(this.el);
    }

    componentWillUnmount() {
        this.body.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}