import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { IFormattedMessageProps } from '../entities'

export default class BCFormattedMessage extends React.Component<IFormattedMessageProps, {}> {

    constructor(props: IFormattedMessageProps) {
        super(props);
    }

    render() {
        return (
            <FormattedMessage id={this.props.id} />
        );
    }
}