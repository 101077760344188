import Editor, { BeforeMount, loader, OnChange, OnMount } from "@monaco-editor/react";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import * as React from "react";
import BCDiffEditor from "./DiffEditor";
import { CodeEditorBuiltinLanguage, CodeEditorBuiltinLanguages, CodeEditorBuiltinThemes, ICodeEditorBuiltinLanguage, ICodeEditorProps, IMethodInfo } from './entities';

loader.config({
    paths: { vs: "./vs" }
});

const defaultOptions: monacoEditor.editor.IEditorConstructionOptions = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: false,
    cursorStyle: 'line',
    automaticLayout: true,
    folding: true,
    fixedOverflowWidgets: false
};

export default function BCCodeEditor(props: ICodeEditorProps) {
    return (
        <Editor
            value={props.value}
            language={props.language}
            theme={props.theme}
            width={props.width}
            height={props.height}
            options={{ ...defaultOptions, ...props.options }}
            beforeMount={props.beforeMount}
            onMount={props.onMount}
            onChange={props.onChange}
        />
    );
}

export type IModelDeltaDecoration = monacoEditor.editor.IModelDeltaDecoration;

export type IDiffEditorOptions = monacoEditor.editor.IDiffEditorConstructionOptions;
export type IEditorOptions = monacoEditor.editor.IEditorConstructionOptions;

export type ICodeEditor = monacoEditor.editor.IStandaloneCodeEditor;
export type IDiffEditor = monacoEditor.editor.IStandaloneDiffEditor;
export type IMonacoEditor = typeof monacoEditor;

export type IModelContentChangedEvent = monacoEditor.editor.IModelContentChangedEvent;

export {
    CodeEditorBuiltinThemes,
    CodeEditorBuiltinLanguage,
    CodeEditorBuiltinLanguages,
    ICodeEditorBuiltinLanguage,
    IMethodInfo,
    BCDiffEditor
};

export type CodeEditorOnMount = OnMount;
export type CodeEditorBeforeMount = BeforeMount;
export type CodeEditorOnChange = OnChange;