import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import ILastVisitedDocumentProps from "../entities/ILastVisitedDocumentProps";
import ILastVisitedDocumentCProps from "../entities/ILastVisitedDocumentCProps";
import { IWebInterfaceAction } from "common/entities";
import LastVisitedDocumentsBox from '../components/LastVisitedDocumentsBox';
import { getRecentFiles } from "../../dm/components/dashboard/selectors/getRecentFiles";
import { getRecentFilesLoading } from "../selectors/RecentFilesLoadingSelector";
import { openAllRecentsAction, openDmViewerAction } from '../actions';

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: ILastVisitedDocumentProps): ILastVisitedDocumentProps {
    return {
        items: getRecentFiles(state),
        loading: getRecentFilesLoading(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): ILastVisitedDocumentCProps {
    return {
        onItemClicked: function (data: IWebInterfaceAction) {
            dispatch(openDmViewerAction(data));
        },
        onClickAllRecents: function (data: IWebInterfaceAction) {
            dispatch(openAllRecentsAction(data))
        },
    };
}

// react - redux connection
const LastVisitedDocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(LastVisitedDocumentsBox);

export default LastVisitedDocumentsContainer;