import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IFlowEventForm, FlowEventForm, FlowEventFormFactoryFromJS } from './IFlowEventForm';

interface IBaseFlowEvent {
    id: number,
    description: string,
    default: boolean,
    reason: boolean,
    validate: boolean,
    color: string,
    icon: string,
    enable: boolean,
    system: boolean,
    confirm: boolean,
    visible: boolean,
    digitalSignatureRequired: boolean,
    conditionsType: number,
    conditionsValue: string
}

export interface IFlowEvent extends IBaseFlowEvent {
    form: IFlowEventForm
}

export interface IReadonlyFlowEvent extends IBaseFlowEvent {
    form: FlowEventForm
}

export type FlowEvent = ReadonlyRecord<IReadonlyFlowEvent>;

export const FlowEventFactory = Record<IReadonlyFlowEvent>({
    id: null,
    description: null,
    default: false,
    reason: false,
    validate: false,
    form: null,
    color: null,
    icon: null,
    enable: true,
    system: false,
    confirm: false,
    visible: true,
    digitalSignatureRequired: false,
    conditionsType: null,
    conditionsValue: null
});

export const FlowEventFactoryFromJS = function (data: IFlowEvent) {
    return FlowEventFactory({
        ...data,
        form: data.form ? FlowEventFormFactoryFromJS(data.form) : null
    });
}