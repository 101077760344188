import React, { lazy, Suspense } from 'react';
import IButtonProps from '../interfaces/IButtonProps';

const FabricCommandBarButton = lazy(
    () => import(/* webpackChunkName: "fabric-ui" */ "office-ui-fabric-react/lib/Button").then(module => ({ default: module.CommandBarButton }))
);

function CommandBarButton(props: IButtonProps) {
    return (
        <Suspense fallback={<div></div>}>
            <FabricCommandBarButton {...props} />
        </Suspense>
    );
}

export default CommandBarButton;