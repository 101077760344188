import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseManagerKey {
    id: number,
    description: string,
    importStatus?: number
}

export interface IManagerKey extends IBaseManagerKey { }

export interface IReadonlyManagerKey extends IBaseManagerKey { }

export type ManagerKey = ReadonlyRecord<IReadonlyManagerKey>;

export const ManagerKeyFactory = Record<IReadonlyManagerKey>({
    id: 0,
    description: "",
    importStatus: 0
});

export const ManagerKeyFactoryFromJS = function (data: IManagerKey) {
    return ManagerKeyFactory(data);
}