import Input from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import * as React from "react";
import { findMessage, notification } from "..";
import * as Styles from './assets/styles.scss';
import { ITextAreaChangeEventArgs, ITextAreaProps } from './entities';
const { TextArea } = Input;

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const BCTextArea = React.forwardRef((props: ITextAreaProps, ref: React.ForwardedRef<TextAreaRef>) => {
    const [value, setValue] = React.useState(props.value);
    const textareaRef = React.useRef<TextAreaRef>();
    React.useImperativeHandle(ref, () => textareaRef.current);
    Styles;

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.resizableTextArea?.textArea?.addEventListener?.('keydown', event => {
                if (event.which == 33) {
                    window.scrollTo(0, window.scrollY);
                    event.preventDefault();
                    (event.target as HTMLInputElement).setSelectionRange(0, 0);
                    (event.target as HTMLInputElement).scrollTop = 0
                }
                if (event.which == 34) {
                    window.scrollTo(0, window.scrollY);
                    event.preventDefault();
                    let content = (textareaRef.current as any).resizableTextArea?.textArea?.value;
                    if (content?.length) {
                        let textareaLength = content.length;
                        (event.target as HTMLInputElement).setSelectionRange(textareaLength, textareaLength);
                        (event.target as HTMLInputElement).scrollTop = 9999
                    }
                }
            });
        }
    }, []);

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (e: any) => {
        let validControl = false;
        if (props.regExp?.regExpString) {
            validControl = validationControl(e.currentTarget.value);
        }

        if (!validControl) {
            let args: ITextAreaChangeEventArgs = { senderArgs: e, data: e.currentTarget.value, itemKey: props.itemKey };
            setValue(args.data);
            triggerOnChange(args);
        }
    }

    const triggerOnChange = (args: ITextAreaChangeEventArgs) => {
        if (props.onChange) props.onChange(args);
    }

    const validationControl = (value?: string): boolean => {
        let validFormat = value.match(props.regExp.regExpString);
        if (value && !validFormat) {
            notification.error({
                duration: 5,
                message: findMessage.get("101291"),
                description: props.regExp.validationFailedMessage || findMessage.get("101313"),
                placement: "topRight"
            });

            setValue(props.value);
            return true;
        }
        else {
            if (!value && !props.regExp.allowNull) {
                setValue(value);
                return true;
            }
        }

        return false;
    }

    const className = cx({
        textAreaStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center",
        [props.className]: true
    });

    return (
        <TextArea
            tabIndex={props.tabIndex}
            style={props.style}
            placeholder={props.placeHolder}
            value={value}
            rows={props.rows}
            onChange={onChange}
            autoSize={props.autosize}
            readOnly={props.readOnly}
            disabled={props.disabled}
            className={className}
            maxLength={props.maxLength}
            showCount={props.showCount}
            onBlur={props.onBlur}
            ref={textareaRef}
        />
    );
});

export default BCTextArea

export { ITextAreaProps, ITextAreaChangeEventArgs, TextAreaRef as BCTextAreaRef };

