import { Record, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { IExtremeGridState } from ".";

export interface IBaseExtremeGridsState {

}

export interface IExtremeGridsState extends IBaseExtremeGridsState {
    items: IDictionary<IExtremeGridState>;
}

export interface IReadonlyExtremeGridsState extends IBaseExtremeGridsState {
    items: Map<string, IExtremeGridState>;
}

export type ExtremeGridsState = ReadonlyRecord<IReadonlyExtremeGridsState>;

export const ExtremeGridsStateFactory = Record<IReadonlyExtremeGridsState>({
    items: Map(),
});

export const ExtremeGridsStateFactoryFromJS = function (data: IExtremeGridsState) {
    return ExtremeGridsStateFactory({
        items: Map(data.items),
    });
}


