import { IBaseAction } from "@bimser/core";
import { List } from 'immutable';
import * as actionTypes from '../actions/actionTypes';
import { Culture, CultureState, CultureStateFactory } from "../entities";


export default function (state: CultureState = CultureStateFactory(), action: IBaseAction<any>): CultureState {
    switch (action.type) {
        case actionTypes.SET_CULTURE_ACTION: {
            return uploadCulturesToStore(state, action);
        }
        default:
            return state;
    }
}

function uploadCulturesToStore(state: CultureState, action: IBaseAction<List<Culture>>): CultureState {
    return state.set("items", action.payload);
}