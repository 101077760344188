import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IHRMenuProps } from './entities';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function Container(props: React.PropsWithChildren<IHRMenuProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
Container.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default Container;