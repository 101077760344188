import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { IWebInterfaceAction } from "../../../../common/entities";
import Announcements from "../../components/widgets/Announcements";
import IAnnouncementsProps from "../../entities/widgets/IAnnouncementsProps";
import { getAnnouncementsData, getAnnouncementsLoading } from "../../selectors/AnnouncementsSelector";
import { onClickAllAnnouncementsTransaction, onClickAnnouncementTransaction } from "../../actions";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IAnnouncementsProps): IAnnouncementsProps {
    return {
        data: getAnnouncementsData(state),
        loading: getAnnouncementsLoading(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IAnnouncementsProps {
    return {
        onClickAnnouncement: function(data: IWebInterfaceAction) {
            dispatch(onClickAnnouncementTransaction(data));
        },
        onClickAllAnnouncements: function(data: IWebInterfaceAction) {
            dispatch(onClickAllAnnouncementsTransaction(data));
        }
    };
}

// react - redux connection
const AnnouncementsContainer = connect(mapStateToProps, mapDispatchToProps)(Announcements);

export default AnnouncementsContainer;