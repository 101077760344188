import * as React from 'react';
import Cascader, { DefaultOptionType } from 'antd/lib/cascader';
import { IBCCascaderProps } from './entities'

export default class BCCascader<DataNodeType> extends React.Component<IBCCascaderProps<DataNodeType>, {}>{
    constructor(props: IBCCascaderProps<DataNodeType>) {
        super(props);
    }

    render() {
        return (
            <Cascader
                disabled={this.props.disabled}
                dropdownMenuColumnStyle={this.props.dropdownMenuColumnStyle}
                dropdownRender={this.props.dropdownRender}
                displayRender={this.props.displayRender}
                tagRender={this.props.tagRender}
                allowClear={this.props.allowClear}
                className={this.props.className}
                style={this.props.style}
                maxTagCount={this.props.maxTagCount}
                onChange={this.props.onChange as any}
                showSearch={this.props.showSearch}
                value={this.props.value}
                bordered={this.props.bordered}
                multiple={this.props.multiple}
                options={this.props.options}
                size={this.props.size}
                suffixIcon={this.props.suffixIcon}
            />
        )
    }
}

export { IBCCascaderProps, DefaultOptionType as CascaderOptionType }