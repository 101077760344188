import { IBaseAction, MLHelper } from "@bimser/core";
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import * as commonActionTypes from "../../../action/actionTypes";
import * as ActionTypes from "../actions/actionTypes";
import { ControlTokenResponse, GetLoginParametersResponse, GetUserDetailResponse, GetUserSettingParametersResponse, GetUserSettingPermissionsResponse, LoginResponseData, LoginState, LoginStateFactory, LoginUser, MFAPropertiesState, MFAType, UserPermissionsStateFactoryFromJS } from "../entities";
import { BrowserPropertiesFactoryFromJS } from "../entities/BrowserProperties";
import { DomainPropertiesFactoryFromJS } from "../entities/DomainProperties";
import { CaptchaState } from '../entities/ICaptchaState';
import { Theme } from "../entities/ITheme";

export default function (state: LoginState = LoginStateFactory(), action: IBaseAction<any>): LoginState {

    switch (action.type) {
        case ActionTypes.CHANGE_TOKEN_CHECK_STATUS: {
            return changeLoginCheckStatus(state, action);
        }
        case ActionTypes.LOGIN: {
            return login(state, action);
        }
        case ActionTypes.LOGIN_LOADING_STATUS: {
            return changeLoginLoadingStatus(state, action);
        }
        case ActionTypes.CONTROL_TOKEN: {
            return controlToken(state, action);
        }
        case ActionTypes.GET_USER_DETAIL_ACTION: {
            return userDetail(state, action);
        }
        case ActionTypes.SET_USER_PROFILE_IMAGE_ACTION: {
            return userProfileImage(state, action);
        }
        case ActionTypes.GET_USER_SETTING_PERMISSION: {
            return userPermission(state, action);
        }
        case ActionTypes.GET_USER_SETTING_PARAMATERS: {
            return userSetting(state, action);
        }
        case ActionTypes.CHANGE_SELECTED_CULTURE_ACTION: {
            return changeSelectedCulture(state, action);
        }
        case commonActionTypes.ON_LOAD_MESSAGES: {
            return checkCultureFromLocalStorage(state);
        }
        case commonActionTypes.SET_DELEGATION_INFORMATION: {
            return setDelegationInformation(state, action);
        }
        case ActionTypes.LOGIN_AS_USER_SELECTED_ACTION: {
            return loginAsUserSelected(state, action);
        }
        case ActionTypes.ONCLICK_CHANGE_MY_PASSWORD_TRANSACTION: {
            return loginAsUserSelected(state, action);
        }
        case ActionTypes.LOGIN_PARAMETERS: {
            return loginParameters(state, action);
        }
        case ActionTypes.SET_MOBILE_SIGNATURE_FINGERPRINT: {
            return setMobileSignatureFingerPrint(state, action);
        }
        case ActionTypes.SET_CAPTCHA_STATE_ACTION: {
            return setCaptchaState(state, action);
        }
        case ActionTypes.SET_MFA_PROPERTIES_ACTION: {
            return setMFAProperties(state, action);
        }
        case ActionTypes.SET_THEME_PROPERTIES: {
            return setThemeProperties(state, action);
        }
        case ActionTypes.SET_CURRENT_MFA_METHOD: {
            return setCurrentMFAMethod(state, action);
        }
        case ActionTypes.SET_DASHBOARD_SETTINGS_ACTION: {
            return setDashboardSettings(state, action);
        }
        case ActionTypes.CHANGE_ADMIN_TOOLS_MENU_VISIBILITY: {
            return setAdminToolsVisibility(state, action);
        }
        case ActionTypes.CHANGE_USER_MENU_VISIBILITY: {
            return setUserMenuVisibility(state, action);
        }
        default:
            return state;
    }
}

function setUserMenuVisibility(state: LoginState, action: IBaseAction<boolean>): LoginState {
    return state.set("userMenuVisibility", action.payload);
}
function setAdminToolsVisibility(state: LoginState, action: IBaseAction<boolean>): LoginState {
    return state.set("adminToolsVisibility", action.payload);
}

function setDashboardSettings(state: LoginState, action: IBaseAction<any>): LoginState {
    return state.set("dashboardSettings", action.payload).set("hasDashboardForm", !isEmpty(action.payload?.dashboardSettings?.projectName));
}
function setCaptchaState(state: LoginState, action: IBaseAction<CaptchaState>): LoginState {
    return state.set("captcha", action.payload);
}

function setMobileSignatureFingerPrint(state: LoginState, action: IBaseAction<string>): LoginState {
    return state.set("fingerPrint", action.payload);
}

function setDelegationInformation(state: LoginState, action: IBaseAction<boolean>): LoginState {
    return state.set("isDelegation", action.payload);
}

function loginAsUserSelected(state: LoginState, action: IBaseAction<any>): LoginState {
    return state.set("loginAsUser", action.payload);
}

function checkCultureFromLocalStorage(state: LoginState): LoginState {
    if (localStorage.locale) {
        return state.set("selectedCulture", localStorage.locale);
    }
    return state;
}

function changeSelectedCulture(state: LoginState, action: IBaseAction<string>): LoginState {
    if (!action.payload) return state;
    moment.locale(action.payload.split("-")[0]);
    localStorage.setItem("locale", action.payload);

    if (MLHelper.supportedLanguages)
        MLHelper.currentLanguage = MLHelper.supportedLanguages.find(i => i.culture === action.payload || i.twoLetterCulture === action.payload);

    return state.set("selectedCulture", action.payload);
}

function changeLoginCheckStatus(state: LoginState, action: IBaseAction<boolean>): LoginState {
    //document.getElementById('splash').classList.add('hidden');
    return state.set("chekAuthenticationFinished", action.payload);
}

function userDetail(state: LoginState, action: IBaseAction<GetUserDetailResponse>): LoginState {
    return state.set("data", action.payload.info).set('adminToolsPermissions', {
        canNavigateIDE: action.payload.canNavigateIDE,
        canAccessConfigurationManager: action.payload.canAccessConfigurationManager,
        canAccessFlowManager: action.payload.canAccessFlowManager,
        canAccessSecurity: action.payload.canAccessSecurity,
        canAccessMenuDesigner: action.payload.canAccessMenuDesigner,
        canViewLogs: action.payload.canViewLogs,
        canDocumentReport: action.payload.canDocumentReport,
        canAccessProcessRequestManager: action.payload.canAccessProcessRequestManager
    }).set("userPermissions", UserPermissionsStateFactoryFromJS(action.payload.toJS()))
        .set("dashboardSettings", action.payload.dashboardSettings).set("hasDashboardForm", !isEmpty(action.payload.dashboardSettings?.projectName));
}

function userProfileImage(state: LoginState, action: IBaseAction<string>): LoginState {
    let data: LoginUser = state.data.set("profileImage", action.payload);
    return state.set("data", data);
}

function userPermission(state: LoginState, action: IBaseAction<GetUserSettingPermissionsResponse>): LoginState {
    return state.set("settingPermissions", action.payload);
}

function userSetting(state: LoginState, action: IBaseAction<GetUserSettingParametersResponse>): LoginState {
    return state.set("settingParameters", action.payload);
}

function login(state: LoginState, action: IBaseAction<LoginResponseData>): LoginState {
    return state.set("token", action.payload.token);
}

function changeLoginLoadingStatus(state: LoginState, action: IBaseAction<boolean>): LoginState {
    return state.set("isLoading", action.payload);
}

function loginParameters(state: LoginState, action: IBaseAction<GetLoginParametersResponse>): LoginState {
    return state
        .set("loginFormProperties", action.payload.loginFormProperties)
        .setIn(["MFAProperties", "mfaTypes"], action.payload.mfaTypes)
        .set("indefiniteAccessToken", action.payload.indefiniteAccessToken)
        .set("domainProperties", DomainPropertiesFactoryFromJS(action.payload.domainProperties))
        .set("browserProperties", BrowserPropertiesFactoryFromJS(action.payload.browserProperties.toJS()));
}

function controlToken(state: LoginState, action: IBaseAction<ControlTokenResponse>): LoginState {
    return state.set("token", action.payload.token);
}

function setMFAProperties(state: LoginState, action: IBaseAction<MFAPropertiesState>): LoginState {
    return state
        .setIn(["MFAProperties", "mfaEnabled"], action.payload.mfaEnabled)
        .setIn(["MFAProperties", "authenticatorEnabled"], action.payload.authenticatorEnabled)
        .setIn(["MFAProperties", "tempTokenKey"], action.payload.tempTokenKey)
        .setIn(["MFAProperties", "currentLoginProcedure"], action.payload.currentLoginProcedure)
        .setIn(["MFAProperties", "currentMFAMethod"], action.payload.currentMFAMethod);
}

function setThemeProperties(state: LoginState, action: IBaseAction<Theme>): LoginState {
    return state
        .setIn(['theme','backgroundColor'], action.payload.backgroundColor)
        .setIn(['theme','image'], action.payload.image)
        .setIn(['theme','primaryColor'], action.payload.primaryColor);
}

function setCurrentMFAMethod(state: LoginState, action: IBaseAction<MFAType>): LoginState {
    return state.setIn(["MFAProperties", "currentMFAMethod"], action.payload);
}