enum IItemType {
    IButtonGroup = 0,
    IButton = 1,
    IDropDown = 2,
    IDropdownButtom = 3,
    IInput = 4,
    IColorPicker = 5,
    ICombobox = 6
}

export default IItemType;