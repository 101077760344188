import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseProjectItem {
    id: string;
    name: string;
    formType: string;
}

export interface IProjectItem extends IBaseProjectItem {

}

export interface IReadonlyProjectItem extends IBaseProjectItem {

}

export type ProjectItem = ReadonlyRecord<IReadonlyProjectItem>;

export const ProjectItemFactory = Record<IReadonlyProjectItem>({
    id: null,
    name: null,
    formType: null
});

export const ProjectItemFactoryFromJS = function (data: IProjectItem) {
    return ProjectItemFactory(data);
}