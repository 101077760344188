import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from 'immutable';
import { FlowDocument, FlowDocumentFactoryFromJS, IFlowDocument } from './models/IFlowDocument';
import { FlowEvent, FlowEventFactoryFromJS, IFlowEvent } from './models/IFlowEvent';

interface IBaseFlowStartParametersResponse {
    projectId?: string;
    flowSecretKey: string;
    startImmediately?: boolean;
    canSaveAsDraft?: boolean;
    eventButtonGroup?: boolean;
    eventButtonsAlignment?: "left" | "right" | "center";
}

export interface IFlowStartParametersResponse extends IBaseFlowStartParametersResponse {
    events: IFlowEvent[],
    documents: IFlowDocument[],
    variables: any[];
}

export interface IReadonlyFlowStartParametersResponse extends IBaseFlowStartParametersResponse {
    events: List<FlowEvent>,
    documents: List<FlowDocument>,
    variables: List<any>,
}

export type FlowStartParametersResponse = ReadonlyRecord<IReadonlyFlowStartParametersResponse>;

export const FlowStartParametersResponseFactory = Record<IReadonlyFlowStartParametersResponse>({
    projectId: null,
    flowSecretKey: null,
    events: List(),
    documents: List(),
    variables: List(),
    startImmediately: false,
    canSaveAsDraft: false
});

export const FlowStartParametersResponseFactoryFromJS = function (data: IFlowStartParametersResponse) {
    if (!data) return FlowStartParametersResponseFactory();
    let _events = data && data.events ? data.events.map(e => FlowEventFactoryFromJS(e)) : [];
    let _documents = data && data.documents ? data.documents.map(d => FlowDocumentFactoryFromJS(d)) : [];

    return FlowStartParametersResponseFactory({
        ...data,
        events: List(_events),
        documents: List(_documents),
        variables: List(data.variables)
    });
}