import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IFilter {
    extension?: string;
    name?: string;
}
export type Filter = ReadonlyRecord<IFilter>;
export const FilterFactory = Record<IFilter>({
    extension: null,
    name: null,

});

export const FilterFactoryFromJS = function (data: IFilter) {
    if (!data) {
        return null;
    }
    return FilterFactory({
        extension: data.extension,
        name: data.name,
    });
}


