import { ReadonlyRecord } from "@bimser/core";
import { Record } from 'immutable';

interface IBaseDmDashboardLoadingStatus{
    isFavoritesLoading?: boolean,
    isActivitiesLoading?: boolean,
    isWorkspacesLoading?: boolean,
    isRepositoriesLoading?: boolean,
    isRecentFilesLoading?: boolean,
    isPanelLoading?: boolean,
}

export interface IDmDashboardLoadingStatus extends IBaseDmDashboardLoadingStatus {
    
}

export interface IReadonlyDmDashboardLoadingStatus extends IBaseDmDashboardLoadingStatus {
    
}

export type DmDashboardLoadingStatus = ReadonlyRecord<IReadonlyDmDashboardLoadingStatus>;

export const  DmDashboardLoadingStatusFactory = Record<IReadonlyDmDashboardLoadingStatus>({
    isFavoritesLoading: false,
    isActivitiesLoading: false,
    isWorkspacesLoading: false,
    isRepositoriesLoading: false,
    isRecentFilesLoading: false,
    isPanelLoading: false
});

export const DmDashboardLoadingStatusFactoryFromJS = function (data: IDmDashboardLoadingStatus) {
    return DmDashboardLoadingStatusFactory(data);
}