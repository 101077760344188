import * as moment from 'moment';
import { isNullOrUndefined } from ".";
import { MLHelper } from '../locales/MLHelper';
import { toLowerCase } from './toLowerCase';

interface IcompareMatchWithCollectionArgs {
    value: any,
    compareValue: any,
    dataType: string;
    format?: string;
    editType?: string;
}

export default function compareMatchWithCollection({ value, compareValue, dataType, format, editType }: IcompareMatchWithCollectionArgs) {

    if (isNullOrUndefined(value) || isNullOrUndefined(compareValue)) {
        return false;
    }

    switch (dataType) {
        case "datetime":
            const datetimeDay = moment(value).isSame(moment(compareValue), "day")
            const datetimeHour = moment(value).isSame(moment(compareValue), "hour");
            const datetimeMinute = moment(value).isSame(moment(compareValue), "minute");
            return datetimeDay && datetimeHour && datetimeMinute;
        case "date":
            return moment(value).isSame(compareValue, "day");
        case "time":
            const hour = moment(value).isSame(moment(compareValue), "hour");
            const minute = moment(value).isSame(moment(compareValue), "minute");
            let second = moment(value).isSame(moment(compareValue), "second");
            if (format === moment().localeData().longDateFormat('LTS')) {
                second = true;
            }
            return hour && minute && second;
        case "boolean":
            return value.toString() == toLowerCase(compareValue).toString();
        case "multiLanguage": {
            return MLHelper.getMLText(value) === MLHelper.getMLText(compareValue);
        }
        case "object": {
            if (editType == "MultiLanguage") {
                return MLHelper.getMLText(value) === MLHelper.getMLText(compareValue);
            }
            return value?.text === compareValue;
        }
        default:
            return value == compareValue;
    }
}