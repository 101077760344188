import React from 'react';
import { ReportDesigner, ReportViewer } from './components'
import { IReportViewerProps, IReportDesignerProps } from './entities';
import * as Styles from './assets/reportContainerStyles.scss'

import "../../../node_modules/jquery-ui/themes/base/all.css";
import "../../../node_modules/devextreme/dist/css/dx.light.css";
import "../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css";

function BCReportViewer(props: IReportViewerProps) {
    return (
        <div className={Styles.reportContainerStyles}>
            <ReportViewer {...props} />
        </div>
    );
}

function BCReportDesigner(props: IReportDesignerProps) {
    return (
        <div className={Styles.reportContainerStyles}>
            <ReportDesigner {...props} />
        </div>
    );
}

export { BCReportViewer, BCReportDesigner }