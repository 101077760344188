import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Record, Map } from "immutable";
import { Action, ActionFactoryFromJS, IAction } from "./IAction";

interface IBaseActionWithState {
    stateId: number,
    hasMore: boolean,
    stateCategoryId: number,
}

export interface IActionWithState extends IBaseActionWithState {
    items: IAction[],
    stateName: IDictionary<string>,
    stateCategoryName: IDictionary<string>,
}

export interface IReadonlyActionWithState extends IBaseActionWithState {
    items: List<Action>,
    stateName: Map<string, string>,
    stateCategoryName: Map<string, string>,
}

export type ActionWithState = ReadonlyRecord<IReadonlyActionWithState>;

export const ActionWithStateFactory = Record<IReadonlyActionWithState>({
    stateId: null,
    stateName: Map(),
    hasMore: false,
    stateCategoryId: null,
    items: List<Action>(),
    stateCategoryName: Map()
});

export const ActionWithStateFactoryFromJS = function (data: IActionWithState) {
    return ActionWithStateFactory({
        ...data,
        stateName: Map(data?.stateName || {}),
        stateCategoryName: Map(data?.stateCategoryName || {}),
        items: List<Action>(data?.items.map(item => ActionFactoryFromJS(item)) || []),
    });
}
