import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseDeployAgent {
    id: number;
    uId: string;
    url: string;
    isActive: boolean;
    isDefault: boolean;
}

export interface IDeployAgent extends IBaseDeployAgent {

}

export interface IReadonlyDeployAgent extends IBaseDeployAgent {

}

export type DeployAgent = ReadonlyRecord<IReadonlyDeployAgent>;

export const DeployAgentFactory = Record<IReadonlyDeployAgent>({
    id: null,
    uId: null,
    url: null,
    isActive: false,
    isDefault: false
});

export const DeployAgentFactoryFromJS = function (data: IDeployAgent) {
    return DeployAgentFactory(data);
}