import { IBaseAction } from "@bimser/core";
import * as ActionTypes  from "../../../components/Login/actions/actionTypes";
import { GetLoginParametersResponse } from "../../../components/Login/entities/GetLoginParametersResponse";

export default function (state: string = null, action: IBaseAction<any>): string {
    switch (action.type) {
        case ActionTypes.LOGIN_PARAMETERS: {
            return setVersionToStore(state, action);
        }
        default:
            return state;
    }
}

function setVersionToStore(state: string, action: IBaseAction<GetLoginParametersResponse>): string {
    return action.payload.loginPageProperties.cspVersion;
}
