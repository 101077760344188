import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { IFlowDocumentCreateOptions, FlowDocumentCreateOptions, FlowDocumentCreateOptionsFactory, FlowDocumentCreateOptionsFactoryFromJS } from './IFlowDocumentCreateOptions';
import { IFlowDocumentSaveOption, FlowDocumentSaveOption, FlowDocumentSaveOptionFactoryFromJS } from './IFlowDocumentSaveOption';
import { IFlowDocumentViewOptions, FlowDocumentViewOptions, FlowDocumentViewOptionsFactory, FlowDocumentViewOptionsFactoryFromJS } from './IFlowDocumentViewOptions';
import { IFlowDocumentSignatureOptions, FlowDocumentSignatureOptions, FlowDocumentSignatureOptionsFactory, FlowDocumentSignatureOptionsFactoryFromJS } from './IFlowDocumentSignatureOptions';
import { IFlowDocumentDmObjectOptions, FlowDocumentDmObjectOptions, FlowDocumentDmObjectOptionsFactoryFromJS } from './IFlowDocumentDmObjectOptions';
import { IFlowDocumentProperty, FlowDocumentProperty, FlowDocumentPropertyFactoryFromJS } from './IFlowDocumentProperty';

interface IBaseFlowDocument {
    documentKey: string,
    name: string,
    type: number,
    endpoint: string,
    documentId: number
}

export interface IFlowDocument extends IBaseFlowDocument {
    createOptions: IFlowDocumentCreateOptions,
    saveOptions: IFlowDocumentSaveOption[],
    viewOptions: IFlowDocumentViewOptions,
    signatureOptions: IFlowDocumentSignatureOptions,
    dmObjectOptions: IFlowDocumentDmObjectOptions,
    documentProperties: IFlowDocumentProperty[]
}

export interface IReadonlyFlowDocument extends IBaseFlowDocument {
    createOptions: FlowDocumentCreateOptions,
    saveOptions: List<FlowDocumentSaveOption>,
    viewOptions: FlowDocumentViewOptions,
    signatureOptions: FlowDocumentSignatureOptions,
    dmObjectOptions: FlowDocumentDmObjectOptions,
    documentProperties: List<FlowDocumentProperty>
}

export type FlowDocument = ReadonlyRecord<IReadonlyFlowDocument>;

export const FlowDocumentFactory = Record<IReadonlyFlowDocument>({
    documentKey: null,
    name: null,
    type: null,
    endpoint: null,
    documentId: 0,
    createOptions: FlowDocumentCreateOptionsFactory(),
    saveOptions: List(),
    viewOptions: FlowDocumentViewOptionsFactory(),
    signatureOptions: FlowDocumentSignatureOptionsFactory(),
    dmObjectOptions: null,
    documentProperties: List()
});

export const FlowDocumentFactoryFromJS = function (data: IFlowDocument) {
    let _saveOptions = data?.saveOptions?.map(s => FlowDocumentSaveOptionFactoryFromJS(s)) ?? [];
    let _documentProperties = data?.documentProperties?.map(d => FlowDocumentPropertyFactoryFromJS(d)) ?? [];

    return FlowDocumentFactory({
        documentKey: data.documentKey,
        name: data.name,
        type: data.type,
        endpoint: data.endpoint,
        documentId: data.documentId,
        createOptions: FlowDocumentCreateOptionsFactoryFromJS(data.createOptions),
        saveOptions: List(_saveOptions),
        viewOptions: FlowDocumentViewOptionsFactoryFromJS(data.viewOptions),
        signatureOptions: FlowDocumentSignatureOptionsFactoryFromJS(data.signatureOptions),
        dmObjectOptions: FlowDocumentDmObjectOptionsFactoryFromJS(data.dmObjectOptions),
        documentProperties: List(_documentProperties)
    });
}