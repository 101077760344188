import '@bimser/components/dist/stylesheets/overridings/ant-custom.scss';
import initiliazer from "./app-init";
import './assets/styles.scss'; // Imports font files

const ancestorOrigin = document.location?.ancestorOrigins?.[0];
const microsoftTeamsUrl = "https://cspteamsaddon.bimser.net";
if (ancestorOrigin === microsoftTeamsUrl) {
    document.head.insertAdjacentHTML("beforeend", `<style>input,select,textarea{font-size:16px!important;}</style>`)
}

initiliazer();