import { ReadonlyRecord } from '@bimser/core';
import { List, Record } from "immutable";
import { IPropertyChangeEventArgs, PropertyChangeEventArgs, PropertyChangeEventArgsFactoryFromJS } from '.';

export interface IBasePropertyChangeTransaction {
    transactionId?: string;
    windowId?: string;
}

export interface IPropertyChangeTransaction extends IBasePropertyChangeTransaction {
    data: IPropertyChangeEventArgs[]
}

export interface IReadonlyPropertyChangeTransaction extends IBasePropertyChangeTransaction {
    data: List<PropertyChangeEventArgs>
}

export type PropertyChangeTransaction = ReadonlyRecord<IReadonlyPropertyChangeTransaction>;

export const PropertyChangeTransactionFactory = Record<IReadonlyPropertyChangeTransaction>({
    transactionId: null,
    data: List(),
    windowId: null
});

export const PropertyChangeTransactionFactoryFromJS = function (data: IPropertyChangeTransaction) {
    let _data: Array<PropertyChangeEventArgs> = data.data && data.data.length ? data.data.map((value) => {
        return PropertyChangeEventArgsFactoryFromJS(value);
    }) : []
    return PropertyChangeTransactionFactory({
        transactionId: data.transactionId,
        data: List(_data),
        windowId: data.windowId,
    });
}