import { BCList, findMessage, BCSkeleton, ContentType, BCLabel } from "@bimser/components";
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as moment from 'moment';
import * as React from "react";
import { IAnnouncement } from "../../announcements/entities";
import * as Styles from "../assets/styles.scss";
import IAnnouncementBoxCProps from "../entities/IAnnouncementBoxCProps";
import IAnnouncementBoxProps from "../entities/IAnnouncementBoxProps";
import IAnnouncementBoxState from "../entities/IAnnouncementBoxState";
import { panelType } from "../../../common/entities";
import { isNullOrUndefined, MLHelper } from "@bimser/core";
const announcementBoxEmptyImage = require("../assets/img/announcements-empty.svg");
import IconAnnouncementInfo from "@bimser/icons/32/announcement-info";
import IconGo from "@bimser/icons/16/go";
const sizeme = require('react-sizeme');

class AnnouncementsBox extends React.Component<IAnnouncementBoxProps & IAnnouncementBoxCProps, IAnnouncementBoxState> {

    constructor(props: IAnnouncementBoxProps & IAnnouncementBoxCProps) {
        super(props);
        this.onClickAllAnnouncement = this.onClickAllAnnouncement.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.onClickListItem = this.onClickListItem.bind(this);
        this.renderCustomEmpty = this.renderCustomEmpty.bind(this);
        this.onClickOpenAnnouncementForm = this.onClickOpenAnnouncementForm.bind(this);
        this.getRef = this.getRef.bind(this);
        this.calculateItems = this.calculateItems.bind(this);

        this.state = {
            contentRef: null
        }
    }
    scrollbarStyle = { width: '100%', height: '100%' };

    strings = {
        ANNOUNCEMENTS: findMessage.get("101048"),
        SEE_ALL_ANNOUNCEMENTS: findMessage.get("101234"),
        EMPTY_TITLE: findMessage.get('101706'),
        EMPTY_DESC: findMessage.get('101707'),
        EMPTY_UPDATE_LIST: findMessage.get('101708')
    }

    private onClickListItem(item: IAnnouncement) {
        this.props.openAnnouncementDetail({
            data: item.secretKey,
            panelId: this.props.startupPanelId
        });
    }

    private onClickAllAnnouncement() {
        this.props.onClickAllAnnouncement({
            panelId: this.props.startupPanelId
        });
    }

    onClickOpenAnnouncementForm() {
        this.props.onOpenAnnouncementForm({
            panelId: this.props.startupPanelId,
            panelType: panelType.deprecatedStartupLayout
        })
    }

    private renderItem(itemProps: IAnnouncement): React.ReactNode {
        const onClickItem = (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            this.onClickListItem(itemProps)
        }
        return (
            <div onClick={onClickItem} className={Styles.boxItem}>
                <IconAnnouncementInfo width="32px" height="32px" />
                <div className={Styles.itemInfo}>
                    <div className={Styles.infoTitle}>{MLHelper.getMLText(itemProps.subject)}</div>
                </div>
                <div className={Styles.itemDate}>{moment(itemProps.createdAt).fromNow()}</div>
            </div>
        )
    }

    renderCustomEmpty() {
        return (
            <div className={Styles.startupBoxEmptyWrapper}>
                <img src={announcementBoxEmptyImage.default} className={Styles.emptyImage} />
                <span className={Styles.emptyInfoTitle}>{this.strings.EMPTY_TITLE}</span>
                <span className={Styles.emptyInfo}>{this.strings.EMPTY_DESC} {this.props.announcementPermissions.canCreateAnnouncement ? <BCLabel onClick={this.onClickOpenAnnouncementForm}>{this.strings.EMPTY_UPDATE_LIST}</BCLabel> : <></>}</span>
            </div>
        )
    }

    getRef(node: HTMLDivElement) {
        this.setState({
            contentRef: node
        })
    }

    calculateItems() {
        if (isNullOrUndefined(this.state.contentRef)) {
            return this.props.items.slice(0, 5);
        } else {
            let height = this.state.contentRef.clientHeight;
            let itemCount = Math.floor(height / 56);
            return this.props.items.slice(0, itemCount);
        }
    }


    private generateTemplate(): JSX.Element {
        return (
            <div className={Styles.BoxContainer}>
                <div className={Styles.header}>
                    <span className={Styles.title}>{this.strings.ANNOUNCEMENTS}</span>
                </div>
                <div className={[Styles.content, Styles.withHeader, Styles.withFooter].join(' ')} ref={this.getRef}>
                    <BCSkeleton
                        contentType={ContentType.startupAnnouncement}
                        loadingStatus={this.props.showLoading}
                        className={"skeleton"}
                    >
                        <BCList dataSource={this.calculateItems()}
                            className={Styles.announcementBoxContainer}
                            bordered={false}
                            split={false}
                            customEmpty={this.renderCustomEmpty()}
                            renderItem={this.renderItem} />
                    </BCSkeleton>
                </div>
                <div className={Styles.footer}>
                    <a className={Styles.link} onClick={this.onClickAllAnnouncement}>
                        {this.strings.SEE_ALL_ANNOUNCEMENTS}
                        <span className={Styles.icon}><IconGo /></span>
                    </a>
                </div>
            </div>
        );
    }

    public render() {
        return (
            this.generateTemplate()
        );
    }
}

export default sizeme({ refreshRate: 1000, monitorHeight: true })(AnnouncementsBox);