import { ConfigProvider, TimePicker } from 'antd';
import en_EN from 'antd/lib/locale-provider/en_GB';
import tr_TR from 'antd/lib/locale-provider/tr_TR';
import * as React from "react";
import { getPopupContainer } from "@bimser/core";
import * as Styles from './assets/styles.scss';
import { ITimeRangePickerProps } from './entities';
const { RangePicker } = TimePicker;
export default class BCTimeRangePicker extends React.Component<ITimeRangePickerProps, {}> {

    inputReference: any = null;

    constructor(props: ITimeRangePickerProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        Styles;
    }

    checkPropNullOrUndefined(obj: any, propName: string): any {
        return (obj === null || obj === undefined) ? {} : (obj[propName] === null || obj[propName] === undefined) ? {} : { [propName]: obj[propName] };
    }

    onChange(dates: any, timeStrings: [string, string]) {
        if (this.props.onChange)
            this.props.onChange(dates, timeStrings);
    }

    componentDidMount() {
        if (this.props.getInputReference) {
            this.props.getInputReference(this.inputReference);
        }
    }

    onBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (this.props.onBlur && this.inputReference && this.inputReference.picker &&
            !this.inputReference.picker.state.open && e.relatedTarget !== this.inputReference.picker.input
        ) {
            this.props.onBlur(e);
        }
    }

    render() {

        let _locale = localStorage.getItem("locale") === "tr-TR" ? tr_TR : en_EN;
        return (
            <ConfigProvider locale={_locale}>
                <RangePicker
                    ref={(inputReference: any) => {
                        this.inputReference = inputReference;
                    }}
                    autoFocus={this.props.autoFocus}
                    className={this.props.className}
                    onChange={this.onChange}
                    size={this.props.size}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    value={this.props.value}
                    onBlur={this.onBlur}
                    onFocus={this.props.onFocus}
                    getPopupContainer={getPopupContainer}
                    order={this.props.order}

                />
            </ConfigProvider>
        );
    }
}

