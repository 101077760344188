import { BCNotification } from "@bimser/components";
import { IBaseAction } from "@bimser/core";
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ServiceList } from "../../../services/serviceList";
import { ActionTypes, changeLoginLoadingStatusAction, SetCaptchaStateAction, setMFAProperties } from '../actions';
import { ILoginResponseWithMFA } from "../entities";
import { getLoginError } from '../entities/LoginErrorCodes';
import commonLoginHandler from "./commonLoginHandler";


export default function* watchLoginTransaction() {
    yield all([
        takeEvery(ActionTypes.LOGIN_TRANSACTION, loginTransaction),
        takeLatest(ActionTypes.REFRESH_CAPTCHA_ACTION, refreshCaptcha)
    ]);
}

export function* loginTransaction(sagaAction: any) {
    try {
        let request: any = {
            ...sagaAction.payload.toJS()
        };
        if (window.location.hash.includes("/OAuth")) {
            request.useAuthCode = true;
        }

        let response: ILoginResponseWithMFA = yield ServiceList.login.Login.Login.handleError((err) => handleLoginError(err, request.language)).call(request, true, null, true);

        if (response?.data?.mfaEnabled) {
            yield put(setMFAProperties({ ...response.data, currentLoginProcedure: "usernamePassword" }));
            yield put(changeLoginLoadingStatusAction(false));
        }

        else {
            yield call(commonLoginHandler, request, response, "usernamePassword");
        }

        yield put(SetCaptchaStateAction({
            showCaptcha: false,
            captchaId: null,
            captchaImage: null
        }));

    }
    catch (error) {
        yield put(changeLoginLoadingStatusAction(false));
    }
}

function handleLoginError(error: any, language: string) {
    return function* () {
        let { message, showCaptcha } = getLoginError(error?.exception?.data?.exception);
        BCNotification.error({
            message: message
        });
        if (showCaptcha) {
            try {
                yield call(getCaptchaFromService, language);
            } catch (error) {

            }
        }
        yield put(changeLoginLoadingStatusAction(false));
    };
}

function* refreshCaptcha(action: IBaseAction<string>) {
    yield call(getCaptchaFromService, action.payload);
}

export function* getCaptchaFromService(language: string) {
    try {
        let captchaResponse = yield ServiceList.login.Login.GetCaptcha.call({ language });
        if (captchaResponse) {
            yield put(SetCaptchaStateAction({
                showCaptcha: true,
                captchaId: captchaResponse.id,
                captchaImage: captchaResponse.image
            }));
        }
    } catch (error) {

    }
}
