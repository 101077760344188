import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { iframeOnLoadTransaction } from '../actions';
import LegacyFrame from '../components';
import { ILegacyFrameCProps, ILegacyFrameProps } from "../entities";
import { getLegacyFrameShowLoading } from "../selectors";
import { IWebInterfaceAction } from "common/entities";


const mapStateToProps = function (state: WebInterfaceBaseState, props: ILegacyFrameProps) : ILegacyFrameProps{
    return {
        showLoading : getLegacyFrameShowLoading(state,props),
    };
}

const mapDispatchToProps = function (dispatch: Function): ILegacyFrameCProps {
    return {
        onFrameLoaded: function (data: IWebInterfaceAction) {
            dispatch(iframeOnLoadTransaction(data));
        },
    };
}

const LegacyFrameContainer = connect(mapStateToProps, mapDispatchToProps)(LegacyFrame);

export default LegacyFrameContainer;