import { createSelector } from 'reselect';
import { List } from "immutable";
import { BaseState } from '../../../entities/BaseState';
import { IModalState, ModalState } from '../entities/IModalState';

const getModalItemsSelector = (state: BaseState) => state.modals ? state.modals.items : null;


const getModalItems = createSelector(
    [
        getModalItemsSelector
    ],
    (data: List<ModalState>): Array<IModalState> => {

        return data ? data.toJS() : [];
    }
);


export {
    getModalItems
};
