import * as React from "react";
import * as Styles from './assets/style.scss';
import { IIndeterminateLoading } from "./entities/IIndeterminateLoading";

export default class BCIndeterminateLoading extends React.Component<IIndeterminateLoading, {}> {
    constructor(props: IIndeterminateLoading) {
        super(props);
    }

    render() {
        return (
            <div className={Styles.progressContainer} style={this.props.styles}>
                <div className={Styles.progressMaterializecss}>
                    <div className={Styles.indeterminate}></div>
                </div>
            </div>
        )
    }
}

