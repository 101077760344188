

import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseDmRecycleBinSettings {
    recycleBinAutoDeleteEnabled: boolean;
    recycleBinAutoDeleteDay: number;
    isFetched?: boolean;
}

export interface IDmRecycleBinSettings extends IBaseDmRecycleBinSettings {
    
}

export interface IReadonlyDmRecycleBinSettings extends IBaseDmRecycleBinSettings {
    
}

export type DmRecycleBinSettings = ReadonlyRecord<IReadonlyDmRecycleBinSettings>;

export const DmRecycleBinSettingsFactory = Record<IReadonlyDmRecycleBinSettings>({
    recycleBinAutoDeleteDay: 15,
    recycleBinAutoDeleteEnabled: true,
    isFetched: false
});

export const DmRecycleBinSettingsFactoryFromJS = function (data: IDmRecycleBinSettings) {
    return DmRecycleBinSettingsFactory(data);
}