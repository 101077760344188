import numbro from "numbro";
import { IDictionary, ILanguage, isNullOrUndefined } from "..";
import { azAZ } from "./numberVariablesLocale/az-AZ";

const _isEqual = require("lodash/isEqual");
const _isEmpty = require("lodash/isEmpty");

export class MLHelper {
    public static supportedLanguages?: Array<ILanguage>;
    public static currentLanguage?: ILanguage;
    public static defaultLanguage?: ILanguage;

    /**Var olan mevcut bir texti ml objesine dönüştürmek için */
    public static getMLObjectWithText(text?: any, noSetCultureForNull?: boolean) {
        if (noSetCultureForNull && isNullOrUndefined(text))
            return {}
        return typeof text === "object" ? text : { [MLHelper.currentLanguage.culture]: typeof text === "string" ? text : "" };
    }

    public static createMLObjectWithValue(mlCode: string) {
        let result: IDictionary<string> = {};
        MLHelper.supportedLanguages.forEach((i) => {
            result[i.culture] = mlCode
        });
        return result;
    }

    /**ML olan bir objenin içeriğinden doğru texti kullanmak için */
    public static getMLText(mlText?: any) {
        if (typeof mlText === "string") {
            return mlText;
        }
        if (typeof mlText === "object" && mlText && !_isEmpty(mlText)) {
            if (MLHelper.currentLanguage && !isNullOrUndefined(mlText[MLHelper.currentLanguage.culture]) && mlText[MLHelper.currentLanguage.culture] !== "") {
                return mlText[MLHelper.currentLanguage.culture];
            }
            if (!MLHelper.defaultLanguage && MLHelper.supportedLanguages) {
                MLHelper.defaultLanguage = MLHelper.supportedLanguages && MLHelper.supportedLanguages.find((i) => i.isDefault);
            }
            if (MLHelper.defaultLanguage && !isNullOrUndefined(mlText[MLHelper.defaultLanguage.culture]) && mlText[MLHelper.defaultLanguage.culture] !== "") {
                return mlText[MLHelper.defaultLanguage.culture];
            }
            let hasFirstKey = Object.keys(mlText).find((i) => !isNullOrUndefined(mlText[i]) && mlText[i] !== "")
            return mlText[hasFirstKey] || "";
        }
        if (typeof mlText === "boolean") {
            return mlText.toString()
        }
        return "";
    }

    public static isMLObject(object: IDictionary<any>, equalExactly?: boolean) {
        if (object && typeof object === "object") {
            const objectKeys = Object.keys(object);
            const cultureKeys = MLHelper.supportedLanguages.map(i => i.culture);

            if (equalExactly) {
                return _isEqual([...objectKeys].sort(), [...cultureKeys].sort());
            } else {
                return objectKeys.find(key => cultureKeys.includes(key)) ? true : false;
            }

        }
    }

    public static getMLInfo() {
        return {
            supportedLanguages: MLHelper.supportedLanguages,
            currentLanguage: MLHelper.currentLanguage,
            defaultLanguage: MLHelper.defaultLanguage
        }
    }

    /**ML olan bir objede mevcut culturelar dolu mu kontrol ediliyor. */
    public static isMLEmpty(mlData: any): boolean {
        if (mlData) {
            if (typeof mlData === "string") {
                return true;
            }
            if (MLHelper.supportedLanguages?.length && typeof mlData === 'object') {
                let filledLanguages = MLHelper.supportedLanguages.filter(language => mlData[language.culture] && mlData[language.culture] !== "");
                if (filledLanguages.length) {
                    return false;
                }
            }
        }
        return true;
    }

    public static getSystemCulture() {
        return this.supportedLanguages?.find(lang => lang.culture == localStorage.getItem("locale"));
    }

    public static async setNumberVariables(culture: string) {
        if (culture == "en-US") {
            MLHelper.numberVariables = require("numbro").languageData(culture);
        } else if (culture == "az-Latn-AZ") {
            MLHelper.numberVariables = azAZ as any;
        } else {
            MLHelper.numberVariables = require("numbro/languages/" + culture);
        }
    }

    public static numberVariables: numbro.NumbroLanguage;
}
