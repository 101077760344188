import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { IWebInterfaceAction } from "../../../../common/entities";
import RecentApps from "../../components/widgets/RecentApps";
import IRecentAppsProps from "../../entities/widgets/IRecentAppsProps";
import { getRecentAppsData, getRecentAppsLoading } from "../../selectors/RecentAppsSelector";
import { onClickRecentAppTransaction } from "../../actions";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IRecentAppsProps): IRecentAppsProps {
    return {
        data: getRecentAppsData(state),
        loading: getRecentAppsLoading(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IRecentAppsProps {
    return {
        onClickApp: function(data: IWebInterfaceAction) {
            dispatch(onClickRecentAppTransaction(data));
        }
    };
}

// react - redux connection
const RecentAppsContainer = connect(mapStateToProps, mapDispatchToProps)(RecentApps);

export default RecentAppsContainer;