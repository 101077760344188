import IconColumnSelect from "@bimser/icons/16/column-select";
import IconContains from "@bimser/icons/16/contains";
import IconDelete from "@bimser/icons/16/delete";
import IconEndWith from "@bimser/icons/16/end-with";
import IconEnterValue from "@bimser/icons/16/enter-value";
import IconEqual from "@bimser/icons/16/equal";
import IconGreater from "@bimser/icons/16/greater";
import IconGreaterEqual from "@bimser/icons/16/greater-equal";
import IconLesser from "@bimser/icons/16/lesser";
import IconLesserEqual from "@bimser/icons/16/lesser-equal";
import IconNotContains from "@bimser/icons/16/not-contains";
import IconNotEqual from "@bimser/icons/16/not-equal";
import IconNotNull from "@bimser/icons/16/not-null";
import IconNull from "@bimser/icons/16/null";
import IconStartWith from "@bimser/icons/16/start-with";
import * as Moment from "moment";
import * as React from "react";
import { BCButton, BCDatePicker, BCPopover, findMessage, IButtonClickEventArgs } from "../..";
import { IDictionary } from "@bimser/core";
import BCCombobox from "../../BCCombobox";
import BCDropdown, { IDropdownSelectedChangedEventArgs } from "../../BCDropdown";
import BCInput from "../../BCInput";
import BCInputNumber from "../../BCInputNumber";
import BCTimePicker from "../../BCTimePicker";
import { ITreeData } from "../../BCTreeSelect/entities";
import * as Styles from "../assets/conditionalStatementStyles.scss";
import { FieldValueType, ICondition, ICRule, ConditionType } from "../entities";
import IconInfo from "@bimser/icons/16/event-info";
import { sortFieldsByType } from '../helpers';
import { SelectedField } from "../../BCRuleManager/components";
var classNames = require('classnames/bind');

let cx = classNames.bind(Styles);

const runtimeProps: string[] = ['Form.session', 'DataGrid.rows.length', "UserMetadata.value", "DocumentMetadata.value"];
export default class Condition extends React.Component<ICondition & ICRule, {}> {

    private styles: any;

    constructor(props: ICondition & ICRule) {
        super(props);

        this.conditionTypesChanged = this.conditionTypesChanged.bind(this);
        this.operationChanged = this.operationChanged.bind(this);

        this.onDeleteConditionClicked = this.onDeleteConditionClicked.bind(this);
        this.onFieldChanged = this.onFieldChanged.bind(this);
        this.onConditionFieldValueChanged = this.onConditionFieldValueChanged.bind(this);
        this.onConditionValueChanged = this.onConditionValueChanged.bind(this);
        this.getDefaultValueByFieldType = this.getDefaultValueByFieldType.bind(this);

        this.styles = {
            clearFix: { clear: "both", height: "18px" },
            deleteButton: { width: "24px", height: "24px", background: "transparent", border: "transparent", padding: "0px", marginRight: "4px", marginLeft: "6px", float: "left", opacity: 0.25 },
            field: { width: "100px", marginLeft: "10px", float: "left" },
            fieldNoMargin: { width: "100px", float: "left" },
            conditionValueInputStyle: { width: "100px" },
            conditionDateValueInputStyle: { width: "100px" },
        };
        Styles;
    }

    getConditionData(): ICondition {
        let { id, field, operation, typeName, value, selectedField } = this.props;

        return {
            id,
            field,
            operation,
            typeName,
            value,
            selectedField
        }
    }

    onFieldChanged(value: any, option?: any) {

        let fieldData = option.externalData;

        let data: ICondition = {
            ...this.getConditionData(),
            field: {
                name: value.split('.').shift(),
                propertyName: value.split('.').slice(1, value.length).join('.'),
                type: fieldData.type,
                enumMembers: fieldData.enumMembers
            },
            selectedField: null,
            value: this.getDefaultValueByFieldType(fieldData.type)
        };
        this.props.updateCondition(data, this.props.statementType);
    }

    getDefaultValueByFieldType(type: FieldValueType) {

        switch (type) {
            case 'boolean': {
                return false
            }
            case 'date': {
                return Moment(new Date()).startOf('day').valueOf();
            }
            case 'time': {
                return Moment(new Date(), 'X').unix();
            }
            case 'number': {
                return 0
            }
            default: return null
        }

    }

    onConditionFieldValueChanged(value: string, options: any) {

        let fieldData = options.externalData;

        let data: ICondition = {
            ...this.getConditionData(),
            selectedField: {
                name: value.split('.').shift(),
                propertyName: value.split('.').slice(1, value.length).join('.'),
                type: fieldData.type,
                enumMembers: fieldData.enumMembers
            }
        };
        this.props.updateCondition(data, this.props.statementType);
    }

    conditionTypesChanged(args: IDropdownSelectedChangedEventArgs) {
        let data: ICondition = {
            ...this.getConditionData(),
            typeName: args.data.value,
            operation: 'Equals'
        };
        this.props.updateCondition(data, this.props.statementType);
    }

    operationChanged(args: IDropdownSelectedChangedEventArgs) {

        let data: ICondition = {
            ...this.getConditionData(),
            operation: args.data.value
        };

        switch (data.operation) {
            case 'GreaterThan':
            case 'GreaterThanOrEqual':
            case 'LessThan':
            case 'LessThanOrEqual': {
                data.value = null;
            }
        }

        this.props.updateCondition(data, this.props.statementType);
    }

    onConditionValueChanged(value: any) {
        this.props.updateCondition({
            ...this.getConditionData(),
            value
        }, this.props.statementType);
    }

    wrapFieldBoldTag(value: string) {
        if (value && value.split('.').length > 1) {
            let prop = value.split('.').pop();
            return (
                <React.Fragment>
                    {value.split('.').splice(0, value.split('.').length - 1).join('.')}
                    <b>{'.' + prop}</b>
                </React.Fragment>
            )
        } else {
            return <b>{value}</b>
        }
    }

    renderDeleteButton(): JSX.Element {

        if (this.props.readonly) return undefined;

        return (
            <BCButton
                key={"Delete_Condition"}
                type={'link'}
                onClick={this.onDeleteConditionClicked}
                cssClass={Styles.deleteButton}
                icon={<IconDelete />}
            />
        )
    }

    onDeleteConditionClicked(e: IButtonClickEventArgs) {
        if (this.props.deleteConditionFrom) this.props.deleteConditionFrom(this.props.id, this.props.statementType);
    }

    getConditionTypes() {
        return [
            { key: 'ValueEntryCondition', text: findMessage.get("102264"), value: 'ValueEntryCondition', name: 'ValueEntryCondition', icon: IconEnterValue.info },
            { key: 'FieldSelectionCondition', text: findMessage.get("102265"), value: 'FieldSelectionCondition', name: 'FieldSelectionCondition', icon: IconColumnSelect.info }
        ]
    }

    getConditionTitleByKey(typeName: ConditionType) {
        if (typeName === "FieldSelectionCondition") return findMessage.get("102265");
        else return findMessage.get("102264");
    }

    renderConditionType(): JSX.Element {
        return (
            <div className={Styles.qb_condition_matching_modifier} title={this.getConditionTitleByKey(this.props.typeName)}>
                <BCDropdown
                    items={this.getConditionTypes()}
                    onSelected={this.conditionTypesChanged}
                    defaultValue={this.props.typeName}
                    className={[Styles.selectionBox, Styles.matchingModifier].join(' ')}
                    optionClassName={Styles.selectionBoxOptions}
                    selectedItemShowType={'icon'}
                    disabled={this.props.readonly}
                    isHiddenDefaultItem={true}
                    buttonType={'ghost'}
                />
            </div>
        );
    }

    getOperationTypes() {
        let operationTypes = [
            { key: 'Equals', text: findMessage.get("101711"), value: 'Equals', name: 'Equals', icon: IconEqual.info }, // Eşittir
            { key: 'NotEquals', text: findMessage.get("101712"), value: 'NotEquals', name: 'NotEquals', icon: IconNotEqual.info }, // Eşit Değil
            { key: 'Null', text: findMessage.get("100523"), value: 'Null', name: 'Null', icon: IconNull.info }, // Boş
            { key: 'NotNull', text: findMessage.get("101717"), value: 'NotNull', name: 'NotNull', icon: IconNotNull.info }, // Boş Değil
        ];

        if (this.props.field && this.props.field.type) {
            switch (this.props.field.type) {
                case 'number':
                case 'date':
                case 'time': {
                    operationTypes.push(...[
                        { key: 'GreaterThan', text: findMessage.get("101713"), value: 'GreaterThan', name: 'GreaterThan', icon: IconGreater.info },
                        { key: 'GreaterThanOrEqual', text: findMessage.get("101714"), value: 'GreaterThanOrEqual', name: 'GreaterThanOrEqual', icon: IconGreaterEqual.info },
                        { key: 'LessThan', text: findMessage.get("101715"), value: 'LessThan', name: 'LessThan', icon: IconLesser.info },
                        { key: 'LessThanOrEqual', text: findMessage.get("101716"), value: 'LessThanOrEqual', name: 'LessThanOrEqual', icon: IconLesserEqual.info }
                    ]);
                    break;
                }
                case 'string': {
                    operationTypes.push(...[
                        { key: 'StartsWith', text: findMessage.get("101720"), value: 'StartsWith', name: 'StartsWith', icon: IconStartWith.info },
                        { key: 'EndsWith', text: findMessage.get("101721"), value: 'EndsWith', name: 'EndsWith', icon: IconEndWith.info },
                        { key: 'Contains', text: findMessage.get("101718"), value: 'Contains', name: 'Contains', icon: IconContains.info },
                        { key: 'NotContains', text: findMessage.get("101719"), value: 'NotContains', name: 'NotContains', icon: IconNotContains.info },
                    ]);
                    break;
                }
            }
        }

        return operationTypes;

    }

    renderOperation(): JSX.Element {

        let classNames = cx({
            selectionBox: true,
            operation: !this.props.readonly,
            matchingModifier: this.props.readonly
        });

        return (
            <div className={Styles.qb_condition_operation} title={this.props.operation}>
                <BCDropdown
                    items={this.getOperationTypes()}
                    onSelected={this.operationChanged}
                    defaultValue={this.props.operation}
                    className={classNames}
                    optionClassName={Styles.selectionBoxOptions}
                    selectedItemShowType={'icon'}
                    disabled={this.props.readonly}
                    isHiddenDefaultItem={true}
                    buttonType={'ghost'}
                />
            </div>
        );
    }

    renderConditionValueByType(type: FieldValueType) {
        switch (type) {
            case 'boolean': {
                return (
                    <div className={Styles.conditionValueInput}>
                        <BCCombobox
                            style={this.styles.conditionValueInputStyle}
                            value={this.props.value ? 'true' : 'false'}
                            onSelectedChange={(args) => { this.onConditionValueChanged(args.data === 'true' ? true : false) }}
                            disabled={this.props.readonly}
                            options={[
                                { key: 'true', text: 'true', value: 'true' },
                                { key: 'false', text: 'false', value: 'false' }
                            ]}
                            showSearch={false}
                        />
                    </div>
                )
            }
            case 'number': {
                return (
                    <div className={Styles.conditionValueInput}>
                        <BCInputNumber
                            style={this.styles.conditionValueInputStyle}
                            value={this.props.value}
                            onChange={(value) => { this.onConditionValueChanged(value) }}
                            disabled={this.props.readonly}
                        />
                    </div>
                )
            }
            case 'date': {

                const field = this.findFieldItem();
                const fieldOptions: any = field?.externalData?.options;

                let externalData: IDictionary<any> = {};

                if (fieldOptions) {
                    if (fieldOptions.format) externalData.format = fieldOptions.format;
                    if (fieldOptions.showTime) externalData.showTime = fieldOptions.showTime;
                    if (fieldOptions.showToday) externalData.showToday = fieldOptions.showToday;
                }

                return (
                    <div className={Styles.conditionValueInput}>
                        <BCDatePicker
                            style={this.styles.conditionDateValueInputStyle}
                            value={this.props.value ? Moment(new Date(this.props.value)) : undefined}
                            onChange={(value) => {
                                this.onConditionValueChanged(value ? value.startOf('day').valueOf() : value)
                            }}
                            disabled={this.props.readonly}
                            {...fieldOptions}
                        />
                    </div>
                )
            }
            case 'time': {

                const field = this.findFieldItem();
                const fieldOptions: any = field?.externalData?.options;

                let externalData: IDictionary<any> = {};

                if (fieldOptions) {
                    if (fieldOptions.format) externalData.format = fieldOptions.format;
                    if (fieldOptions.use12Hours) externalData.use12Hours = fieldOptions.use12Hours;
                }

                return (
                    <div className={Styles.conditionValueInput}>
                        <BCTimePicker
                            style={this.styles.conditionDateValueInputStyle}
                            value={this.props.value ? Moment(this.props.value) : undefined}
                            onChange={(value, timeString) => {

                                let v = value.set('year', 1993).set('month', 10).set('date', 10).set('millisecond', 10);
                                if (fieldOptions && fieldOptions.format && fieldOptions.format === 'HH:mm') {
                                    v = v.set('second', 0);
                                }

                                this.onConditionValueChanged(v.unix() * 1000);
                            }}
                            disabled={this.props.readonly}
                            {...fieldOptions}
                        />
                    </div>
                )

            }
            case 'enum': {
                return (
                    <div className={Styles.conditionValueInput}>
                        <BCCombobox
                            style={this.styles.conditionValueInputStyle}
                            value={this.props.value}
                            onSelectedChange={(args) => { this.onConditionValueChanged(args.data) }}
                            disabled={this.props.readonly}
                            options={this.props.field.enumMembers.map(i => {
                                return { key: i, text: i, value: i }
                            })}
                            showSearch={false}
                        />
                    </div>
                )
            }
            default: {
                return (
                    <div className={Styles.conditionValueInput}>
                        <BCInput
                            style={this.styles.conditionValueInputStyle}
                            value={this.props.value}
                            onChange={(args) => { this.onConditionValueChanged(args.data) }}
                            disabled={this.props.readonly}
                        />
                    </div>
                )
            }
        }
    }

    findFieldItem() {
        const field = this.props.field;
        let foundField = this.props.fields.find(f => f.key === field.name);
        const propertyNames = field.propertyName.split('.');
        let currentPath = field.name;

        if (foundField) {

            propertyNames.forEach(pName => {
                currentPath += '.' + pName;
                foundField = foundField.children.find(i => i.key === currentPath);
            });

        }

        return foundField
    }

    renderConditionValue(): JSX.Element {

        let operationValid = this.props.operation !== 'Null' && this.props.operation !== 'NotNull';
        if (!operationValid) return undefined;

        if (this.props.typeName === 'ValueEntryCondition') {

            return this.renderConditionValueByType(this.props.field ? this.props.field.type : null)

        }
        
        return (
            <div className={Styles.selectionTree}>
                <SelectedField
                    field={this.props.selectedField}
                    options={this.props.fields ? this.filterByField(this.props.fields.toJS()) : []}
                    onChange={this.onConditionFieldValueChanged}
                    disabled={this.props.readonly}
                />
            </div>
        )

    }

    filterByField(treeData: ITreeData[]): ITreeData[] {

        if (this.props.typeName === 'FieldSelectionCondition' && this.props.field && this.props.field.type) {
            treeData = this.filterTreeData(treeData, this.props.field.type, true);
        }

        return sortFieldsByType(treeData);
    }

    filterTreeData(treeData: ITreeData[], type: FieldValueType, root?: boolean): ITreeData[] {
        return treeData.filter(item => {

            let hasChild = item.children?.length;
            if (hasChild) {
                item.children = this.filterTreeData(item.children, type);
            }

            const isRootItem = root && hasChild;
            const isTypeObject = item.externalData?.type === "object";
            const equalTypes = item.externalData?.type === type;
            const anyChildLeft = item.children?.length;

            if (isRootItem && anyChildLeft) return true;
            if (isTypeObject && anyChildLeft) return true;
            if (equalTypes) return true;

        })
    }

    checkRuntimePropsForInfo() {

        let foundField = this.props.fields.find(i => i.key === this.props.field.name);

        if (foundField) {
            let controlProperty = `${foundField.externalData.type}.${this.props.field.propertyName}`;
            if (runtimeProps.filter(i => controlProperty.includes(i)).length !== 0) {
                return <BCPopover content={findMessage.get("102224")}><div><IconInfo /></div></BCPopover>
            }
        }

        return null

    }

    renderField(): JSX.Element {

        let value: string = null;
        if (this.props.field && this.props.field.name && this.props.field.propertyName) {
            value = [this.props.field.name, this.props.field.propertyName].join('.');
        }

        const suffixIcon = value ? this.checkRuntimePropsForInfo() : null;
        const cascaderClassName = [Styles.selectionTree, suffixIcon !== null ? Styles.selectionTreeIcon : ""].join(" ");

        return (
            <div className={cascaderClassName}>
                <SelectedField
                    field={this.props.field}
                    options={this.props.fields ? sortFieldsByType(this.props.fields.toJS()) : []}
                    onChange={this.onFieldChanged}
                    disabled={this.props.readonly}
                />
            </div>
        );
    }

    renderConditionRow() {
        return (
            <div className={[Styles.conditionItemContent, Styles.autoWidth].join(" ")}>
                {this.renderField()}
                {this.renderConditionType()}
                {this.renderOperation()}
                {this.renderConditionValue()}
                {this.renderDeleteButton()}
            </div>
        )
    }

    render() {
        return this.renderConditionRow()
    }
}