import { BCAjax, BCNotification, findMessage } from '@bimser/components';
import { copyToClipboard, createGuid, IBaseAction } from '@bimser/core';
import { Map } from 'immutable';
import { buffers, eventChannel } from 'redux-saga';
import { call, fork, put, select, spawn, take } from 'redux-saga/effects';
import { BaseState, DmData, GenerateDmData, ResumableFile, ResumableUploadManager, ServiceList, UploadFileThread, UploadFileThreadFactoryFromJS, UploadManagerStatus } from '../../..';
import * as umActions from '../actions/actionTypes';
import { createFileUploadThreadsAction } from '../actions/createFileUploadThreadsAction';
import { prepareFileToUploadAction } from '../actions/prepareFileToUploadAction';
import { removeFileDataAction } from '../actions/removeFileDataAction';
import { updateFileStatusAction } from '../actions/updateFileStatusAction';
import { updateProgressAction } from '../actions/updateProgressAction';
import { uploadResultAction } from '../actions/uploadResultAction';
import { IUploadFilePayload, IUploadManagerStartUploadPayload, IUploadPart, UploadFileInfo, UploadFileInfoFactoryFromJS, UploadManagerAddFilePayload, UploadManagerResultPayload } from '../entities';
import { IUploadPausePayload } from '../entities/IUploadPausePayload';
import generateMLFileName from './helper/generateMLFileName';

export { generateMLFileName };

const acceptedActions = [
    umActions.ADD_FILE_TO_UPLOAD,
    umActions.PAUSE_FILE_UPLOAD,
    umActions.RESUME_FILE_UPLOAD,
    umActions.START_FILE_UPLOAD,
    umActions.COPY_FILE_LINK,
    umActions.CLEAR_UPLOAD_LIST_ACTION
]

const getFileInfo = (hash: string) => (state: BaseState) => {
    let file = state.UploadManager.files.get(hash);
    if (file) return file.info;
    else return;
}


export default function* WatchUploadManager() {
    function generateId(file: File, uuid: string) {
        return uuid
    }

    let manager = new ResumableUploadManager({
        target: () => {
            return ""
        },
        chunkSize: 5242880,
        headers: BCAjax.defaultHeaders,
        uploadMethod: "PUT",
        generateUniqueIdentifier: generateId,
        forceChunkSize: true,
        simultaneousUploads: 3,
        query: { dataPartId: createGuid() }
    })

    yield fork(uploadEventListener, manager);

    while (true) {
        let action: IBaseAction<any> = yield take((action: IBaseAction<any>) => {
            if (acceptedActions.indexOf(action.type) > -1) return true
        });
        switch (action.type) {
            case umActions.ADD_FILE_TO_UPLOAD: {
                yield fork(handleAddFileToUpload, action.payload, manager);
                break;
            }
            case umActions.PAUSE_FILE_UPLOAD: {
                yield call(handleUploadPause, action.payload, true, manager);
                break;
            }
            case umActions.RESUME_FILE_UPLOAD: {
                yield call(handleUploadPause, action.payload, false, manager);
                break;
            }
            case umActions.START_FILE_UPLOAD: {
                yield call(handleStartFileUpload, action.payload, manager);
                break;
            }
            case umActions.COPY_FILE_LINK: {
                yield call(handleCopyFileLink, action.payload);
                break;
            }
            case umActions.CLEAR_UPLOAD_LIST_ACTION: {
                removeFile(manager, action.payload);
                break;
            }
        }
    }
};

function removeFile(manager: any, hash: string) {
    manager.fire('removeFile', hash);
}

function* handleCopyFileLink(payload: IUploadPausePayload) {
    let uploadFileData: DmData = yield select((state: BaseState) => {
        return state.UploadManager.files.get(payload.uuid)?.info.dmData
    })
    if (uploadFileData) {
        try {
            let downloadUrlResponse = yield ServiceList.dm.DocumentManagement.Objects.GetDownloadUrl.call({ secretKey: uploadFileData.secretKey });
            if (downloadUrlResponse && downloadUrlResponse.downloadUrl) {
                let downloadUrl = (window as any).serviceConfig.services.dm.baseURL + "/" + downloadUrlResponse.downloadUrl;
                copyToClipboard(downloadUrl);
                BCNotification.info({
                    message: findMessage.get("101743")
                });
            } else {
                BCNotification.error({
                    message: findMessage.get("100930")
                });
            }
        } catch (error) {
            console.log("function*handleCopyFileLink -> error", error)
            BCNotification.error({
                message: findMessage.get("100930")
            });
        }
    } else {
        BCNotification.error({
            message: findMessage.get("100930")
        });
    }
}

function handleStartFileUpload(payload: IUploadManagerStartUploadPayload, manager: ResumableUploadManager) {
    manager.addFile(payload.file, payload.parentHash, payload.threads);
}

function* handleAddFileToUpload(payload: UploadManagerAddFilePayload, manager: ResumableUploadManager) {
    let { files, folderPath, folderSecretKey, result } = payload;
    for (let file of files) {
        yield spawn(uploadFile, {
            file: file.file,
            fileId: file.id,
            forms: file.forms,
            folderPath,
            folderSecretKey,
            name: file.name,
            description: file.description
        }, result, manager);
    }
}

function* uploadFile(data: IUploadFilePayload, result: (payload: UploadManagerResultPayload) => void, manager: ResumableUploadManager) {
    const parentHash = data.fileId;
    try {
        let info: UploadFileInfo = UploadFileInfoFactoryFromJS({
            status: UploadManagerStatus.Preparing,
            lastModified: data.file.lastModified,
            type: data.file.type,
            size: data.file.size,
            name: data.file.name,
            path: data.folderPath
        })


        // Dosya UploadManager UI'ına gönderiliyor.
        yield put(prepareFileToUploadAction({
            parentHash: parentHash,
            info
        }));

        let fileName = yield call(generateMLFileName, data.file.name);

        let request: any = {
            secretKey: data.folderSecretKey,
            contentInfo: {
                mimeType: data.file.type || "application/octet-stream",
                length: data.file.size
            }
        };

        if (data.forms) {
            request.documents = data.forms;
        }

        if (data.name) {
            request.name = data.name;
        } else {
            request.name = fileName;
        }

        if (data.description) {
            request.description = data.description;
        }

        let createFileResponse = yield ServiceList.dm.DocumentManagement.Objects.CreateFile.handleError((e) => function* () {
            yield put(updateFileStatusAction({
                hash: parentHash,
                status: UploadManagerStatus.Failed
            }));
            yield put(uploadResultAction({
                id: data.fileId,
                success: false
            }))
            BCNotification.error({
                message: e.message
            })
            if (result) {
                result({
                    id: data.fileId,
                    success: false
                })
            }
        }, true).call(request);

        if (createFileResponse) {
            let dmData: DmData = yield call(GenerateDmData, createFileResponse);
            info = info.set("dmData", dmData);

            // Dosya UploadManager UI'ına gönderiliyor.
            yield put(prepareFileToUploadAction({
                parentHash: parentHash,
                info
            }))

            let res = yield ServiceList.dm.DocumentManagement.Explorer.GetPaths.call({ secretKeys: [dmData.secretKey] });
            if (res) {
                dmData = dmData.set("path", res.paths[dmData.id]);
            }

            yield call(startFileUpload, data, parentHash, dmData, result, manager);
        }

    } catch (error) {
        console.log("function*uploadFile -> error", error)
        yield put(updateFileStatusAction({
            hash: parentHash,
            status: UploadManagerStatus.Failed
        }))
        if (result) {
            result({
                id: data.fileId,
                success: false
            })
        }
    }
}

function* startFileUpload(data: IUploadFilePayload, parentHash: string, dmData: DmData, result: (payload: UploadManagerResultPayload) => void, manager: ResumableUploadManager) {

    yield call(generateFileUploadThreads, data.file, parentHash, dmData.secretKey, result, manager)

    manager.on('fileSuccess', (file: ResumableFile, message: any) => {
        if (file.uuid == data.fileId && result) {
            result({
                id: data.fileId,
                success: true,
                data: dmData
            })
        }
    });
    manager.on('fileError', (file: ResumableFile, message: any) => {
        if (file.uuid == data.fileId && result) {
            result({
                id: data.fileId,
                success: false
            })
        }
    });
    manager.on('removeFile', (hash: string) => {
        if (hash == data.fileId && result) {
            result({
                id: data.fileId,
                success: false
            })
        }
    });
}


/**
 * Generates Upload Threads of file
 * @param file File object
 * @param parentHash Hash of the file object
 * @param secretKey secretKey of file object
 */
function* generateFileUploadThreads(file: File, parentHash: string, secretKey: string, result: (payload: UploadManagerResultPayload) => void, manager: ResumableUploadManager) {
    let threads: Map<string, UploadFileThread> = Map();
    let threadUrlResponse = yield ServiceList.dm.DocumentManagement.Objects.GetUploadParts.call({ secretKey, length: file.size })

    if (threadUrlResponse && threadUrlResponse.uploadParts) {
        let parts: IUploadPart[] = threadUrlResponse.uploadParts

        for (let i = 0; i < parts.length; i++) {
            const urlResponse = parts[i];

            let thread: UploadFileThread = UploadFileThreadFactoryFromJS({
                id: urlResponse.id.toString(),
                startByte: urlResponse.startByte,
                endByte: urlResponse.endByte,
                status: UploadManagerStatus.Preparing,
                parentHash: parentHash,
                uploadUrl: (window as any).serviceConfig.services.dm.baseURL + "/" + urlResponse.url
            })
            threads = threads.set(thread.id, thread);
        }

        if (threads.size == 0) {
            //Boş dosya gönderiliyor. Upload yapıldı sayılacak.
            yield put(updateFileStatusAction({
                hash: parentHash,
                status: UploadManagerStatus.Done
            }))
            if (result) {
                result({
                    id: parentHash,
                    success: true
                })
            }
            let dmData: DmData = yield select(getDmDataFromUploadFileInfo(parentHash));
            if (dmData) {
                yield put(uploadResultAction({
                    id: parentHash,
                    success: true,
                    data: dmData
                }))
            }
            return;
        }

        yield put(createFileUploadThreadsAction({
            threads: threads,
            parentHash: parentHash
        }))

        manager.addFile(file, parentHash, threads);

    } else {
        yield put(updateFileStatusAction({
            hash: parentHash,
            status: UploadManagerStatus.Failed
        }));
        if (result) {
            result({
                id: parentHash,
                success: false
            })
        }
    }

}

function* handleUploadPause(payload: IUploadPausePayload, _pause: boolean, manager: ResumableUploadManager) {
    if (payload && payload.uuid) {
        manager.pause(_pause, payload.uuid);
    } else manager.pause(_pause);

    if (_pause) {
        yield put(updateFileStatusAction({
            hash: payload.uuid,
            status: UploadManagerStatus.Paused
        }))
    } else {
        yield put(updateFileStatusAction({
            hash: payload.uuid,
            status: UploadManagerStatus.Uploading
        }))
    }
}

function* uploadEventListener(manager: ResumableUploadManager) {
    try {
        const channel = yield call(createResumableUploadManagerChannel, manager);
        while (true) {
            let { fileAdded, fileError, fileSuccess, fileProgress } = yield take(channel);

            if (fileError) {
                yield put(updateFileStatusAction({
                    hash: fileError.file.uuid,
                    status: UploadManagerStatus.Failed
                }))
                //TODO ResultAction fırlatılacak. Error şeklinde.
                yield put(uploadResultAction({
                    id: fileError.file.uuid,
                    success: false
                }))
                BCNotification.error({
                    message: JSON.parse(fileError.message).errorDetail.message
                })
            }
            if (fileSuccess) {
                let hash = fileSuccess.file.uuid
                let message = JSON.parse(fileSuccess.message);
                if (message && message.result.fileUploaded) {
                    yield put(updateFileStatusAction({
                        hash: hash,
                        status: UploadManagerStatus.Done
                    }))
                    yield put(removeFileDataAction(hash))
                    //TODO ResultAction fırlatılacak. Success şeklinde.
                    let dmData: DmData = yield select(getDmDataFromUploadFileInfo(hash));
                    if (dmData) {
                        yield put(uploadResultAction({
                            id: hash,
                            success: true,
                            data: dmData
                        }))
                    }
                    // yield call(updateFileDmDataInfo, hash) 
                }
            }
            if (fileAdded) {
                let hash = fileAdded.uuid
                yield put(updateFileStatusAction({
                    hash: hash,
                    status: UploadManagerStatus.Uploading
                }))
            }
            if (fileProgress) {
                yield put(updateProgressAction({
                    hash: fileProgress.file.uuid,
                    uploadedSize: Math.floor(fileProgress.progress * 100)
                }));
            }
        }
    } catch (err) {
        console.log("LOG: function*uploadEventListener -> err", err)

    }
}

/**
 * Creates Resumable Upload Manager Channel to get events of uploading data.
 * @param formData FormData object that contains file
 * @param thread Thread object 
 * 
 * @return Object { progress, loaded, err, success }
 */
function createResumableUploadManagerChannel(manager: ResumableUploadManager) {
    return eventChannel(emitter => {

        manager.on('fileAdded', (file: ResumableFile) => {
            manager.upload();
            emitter({ fileAdded: file })
        });
        manager.on('fileSuccess', (file: ResumableFile, message: any) => {
            emitter({ fileSuccess: { file, message } })
        });
        manager.on('fileError', (file: ResumableFile, message: any) => {
            emitter({ fileError: { file, message } })
        });
        manager.on('fileProgress', (file: ResumableFile) => {
            emitter({ fileProgress: { file, progress: file.progress() } })
        });
        manager.on('filePause', (file: ResumableFile) => {
            emitter({ filePaused: file })
        })

        return () => {

        };
    }, buffers.expanding());
}

const getDmDataFromUploadFileInfo = (parentHash: string) => (state: BaseState) => {
    let file = state.UploadManager.files.get(parentHash);
    if (file) {
        return file.info.dmData
    }
    return null;
}