import { Map } from "immutable";
import { NormalizedLayoutStructureItems, LayoutStructureItem } from "../../../common/entities";

export const insertToView = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    items: LayoutStructureItem[]
) => {
    items.forEach(item => {
        views = views.setIn([activeView, 'entities', 'items', item.id], item)
    });
    return views
};