import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseAdminToolsPermissionsState {
    canNavigateIDE: boolean;
    canAccessConfigurationManager: boolean;
    canAccessFlowManager: boolean;
    canAccessSecurity: boolean;
    canAccessMenuDesigner: boolean;
    canViewLogs: boolean;
    canDocumentReport: boolean;
    canAccessProcessRequestManager: boolean;
}

export interface IAdminToolsPermissionsState extends IBaseAdminToolsPermissionsState {

}

export interface IReadonlyAdminToolsPermissionsState extends IBaseAdminToolsPermissionsState {

}

export type AdminToolsPermissionsState = ReadonlyRecord<IReadonlyAdminToolsPermissionsState>;

export const AdminToolsPermissionsStateFactory = Record<IReadonlyAdminToolsPermissionsState>({
    canNavigateIDE: false,
    canAccessConfigurationManager: false,
    canAccessFlowManager: true,
    canAccessSecurity: false,
    canAccessMenuDesigner: false,
    canViewLogs: false,
    canDocumentReport: false,
    canAccessProcessRequestManager: false
});

export const AdminToolsPermissionsStateFactoryFromJS = function (data: IAdminToolsPermissionsState) {
    return AdminToolsPermissionsStateFactory({
        ...data
    });
}