import * as React from "react";
import * as ReactDOM from "react-dom";
import Checkbox from 'antd/lib/checkbox';
import { ICheckboxListProps, ICheckboxGroupProps } from './entities';
import * as styles from './assets/ChecklistStyles.scss';

var classNames = require('classnames/bind');
let cx = classNames.bind(styles);

const CheckboxGroup = Checkbox.Group;

export default class BCCheckboxGroup extends React.Component<ICheckboxGroupProps, {}> {

    style: React.CSSProperties;

    constructor(props: ICheckboxGroupProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.style = this.props.style;
    }

    onChange(value: any) {
        if (this.props.onChange)
            this.props.onChange(value);
    }

    getOptions(options: Array<ICheckboxListProps>) {
        if (!options)
            return null;

        let classNames = cx({
            vertical: this.props.mode === 'vertical',
            horizontal: this.props.mode === 'horizontal'
        });

        return (
            options.map((item, i) => {
                return (<Checkbox key={item.key} className={classNames} style={this.props.style} value={item.value} disabled={item.disabled} >{item.label}</Checkbox>)
            })
        );
    }

    render() {
        return (
            <CheckboxGroup
                defaultValue={this.props.defaultValue}
                onChange={this.onChange}
                style={this.style}
                disabled={this.props.disabled}
                className={this.props.className}
                value={this.props.value}
            >
                {this.getOptions(this.props.options)}
            </CheckboxGroup>
        );
    }
}

export { ICheckboxListProps, ICheckboxGroupProps }