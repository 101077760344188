import { IDictionary } from "@bimser/core";
import { List, Map } from 'immutable';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { WebInterfaceBaseState } from "../../../../common/base/state";
import { IMenuDesignerItem, MenuDesignerItem } from "../../../../common/entities/IMenuDesignerItem";
import { IProjectDeployment } from "../entities";
import { Profile } from "../entities/IProfile";
import { menuItemDesignerDictinaryToArray } from "../helpers";
import { ProjectItem, IProjectItem } from '../entities/IProjectItem';
import { ModalState } from '@bimser/common';
const shallowEqual: any = require("fbjs/lib/shallowEqual");
const shallowEqualCreateSelector = createSelectorCreator(defaultMemoize, shallowEqual);

const visibility = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.active;

const eBA6Enabled = (state: WebInterfaceBaseState) => state.system.eBA6Enabled;

const eBA6Loading = (state: WebInterfaceBaseState) => state.layouts.leftMenu.eBA6Integration.loading;

const eBA6Url = (state: WebInterfaceBaseState) => state.layouts.leftMenu.eBA6Integration.url;

const eBA6Header = (state: WebInterfaceBaseState) => state.layouts.leftMenu.eBA6Integration.eBAIntegrationHeader;

const eBA6IntegrationActiveStatus = (state: WebInterfaceBaseState) => state.modals.items

const items = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.menuStructure.nodes;

const selectedProfile = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.selectedProfile;

const profiles = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.menuStructure.profiles;

const projects = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.projects;

const flows = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.flows;

const forms = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.forms;

const deployments = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.deployments;

const getProfilesInState = (state: WebInterfaceBaseState) => state.layouts.leftMenu.menuDesigner.menuStructure.profiles


export const getEBA6IntegrationLoading = createSelector(
    [
        eBA6Loading
    ],
    (data: boolean) => {
        return data;
    }
);

export const getEBA6IntegrationUrl = createSelector(
    [
        eBA6Url
    ],
    (data: string) => {
        return data;
    }
);

export const getEBA6IntegrationHeader = createSelector(
    [
        eBA6Header
    ],
    (data: string) => {
        return data;
    }
);

export const getEBA6IntegrationActiveStatus = createSelector(
    [
        eBA6IntegrationActiveStatus
    ],
    (items: List<ModalState>) => {
        return items?.findIndex(item => item.type === "EBA6IntegrationModal") > -1;
    }
);

export const getEBA6IntegrationModalId = createSelector(
    [
        eBA6IntegrationActiveStatus
    ],
    (items: List<ModalState>) => {
        return items?.find(item => item.type === "EBA6IntegrationModal")?.id || "";
    }
);

export const getEBA6EnabledStatus = createSelector(
    [
        eBA6Enabled
    ],
    (eBA6Enabled: boolean) => {
        return eBA6Enabled;
    }
)

export const getMenuDesignerVisibility = createSelector(
    [
        visibility
    ],
    (data: boolean) => {
        return data;
    }
);

export const getMenuDesignerItems = createSelector(
    [
        items
    ],
    (items: Map<string, MenuDesignerItem>) => {
        return menuItemDesignerDictinaryToArray(items.toJS() as IDictionary<IMenuDesignerItem>);
    }
)


export const getMenuDesignerSelectedProfileId = createSelector(
    [
        selectedProfile
    ],
    (profile: string) => {
        return profile;
    }
)


export const getMenuDesignerProfiles = createSelector(
    [
        profiles
    ],
    (profiles: List<Profile>) => {
        return profiles.toJS();
    }
)

export const getProjectListForCombobox = shallowEqualCreateSelector(
    [
        projects
    ],
    (items: List<ProjectItem>) => {
        return items.toJS().map((i: IProjectItem) => { return { key: i.id, text: i.name, value: i.id } })
    }
)

export const getFlowListForCombobox = shallowEqualCreateSelector(
    [
        flows
    ],
    (items: List<ProjectItem>) => {
        return items.toJS().map((i: IProjectItem) => { return { key: i.id, text: i.name, value: i.id } })
    }
)

export const getFormListForCombobox = shallowEqualCreateSelector(
    [
        forms
    ],
    (items: List<ProjectItem>) => {
        return items.toJS().map((i: IProjectItem) => { return { key: i.id, text: i.name, value: i.id } })
    }
)

export const getDeployments = shallowEqualCreateSelector(
    [
        deployments
    ],
    (items: List<IProjectDeployment>) => {
        return items.toJS();
    }
)

export const getProfilesItemsForCombobox = shallowEqualCreateSelector(
    [
        getProfilesInState
    ],
    (profiles) => {
        return profiles.toJS().map((i) => { return { key: i.id, text: i.caption, value: i.id, title: i.caption } })
    }
);