import * as React from "react";
import BCScrollbars from "../../BCScrollbars";
import BCInputSearch, { InputSizes } from "../../BCInputSearch";
import * as Styles from '../assets/flexStartupStyles.scss';
import { IStartupPlugin } from '../entities';
import { findMessage } from "../../BCIntl";
import { BCForm } from "../../BCForm";

var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

export default class StartupPlugin extends React.Component<IStartupPlugin, {}> {

    constructor(props: IStartupPlugin) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
    }
    scrollBarStyle: React.CSSProperties = { width: 'auto' };
    searchBarStyle: React.CSSProperties = { width: 'calc(100% - 15px)', marginBottom: '55px' };
    onSearch(args: any) {
        let value: string = (typeof args === "string") ? args : args.data;
        if (this.props.onSearch) this.props.onSearch(value);
    }

    getSearchBar() {
        return (
           <BCForm showLoading={false} autoComplete="off" style={this.searchBarStyle}>
                <BCInputSearch
                    placeHolder={findMessage.get('100002')}
                    size={'large'}
                    onSearch={this.onSearch}
                    onChange={this.onSearch}
                />
            </BCForm>
        )
    }

    render() {

        let { title, description, children, scrollbarHeight, noGutter, searchable } = this.props;

        let noGutterClass = noGutter ? Styles.noGutter : '';

        const classNames = cx({
            startupPlugin: true,
            paddingBottom: this.props.paddingBottom
        });

        return (
            <div className={[classNames, noGutterClass].join(' ')}>
                <h1 className={Styles.title}>
                    {title}
                </h1>
                {description ? <p className={Styles.description}>
                    {description}
                </p> : null}
                {searchable ? this.getSearchBar() : null}
                {children ? <BCScrollbars autoHide styles={this.scrollBarStyle} autoHeightMax={scrollbarHeight} autoHeight>
                    {children}
                </BCScrollbars> : null}
            </div>
        )
    }

}
