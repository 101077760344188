import { FormItemTypes, ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ELayoutItemTypes } from ".";
import { ILayoutStructureItem, LayoutStructureItem, LayoutStructureItemFactory, LayoutStructureItemFactoryFromJS } from "../../../common/entities";

export type StructureChangeMethods = "handleMoveSidebarComponentIntoParent" | "handleMoveSidebarContainerIntoParent" | "handleMoveGhostItemIntoRoot" | "handleMoveToDifferentParent" | "handleMoveWithinParent" | "delete" | "handleAddStructureToParent";

export interface IBaseStructureChange {
    method: StructureChangeMethods,
    dragItemType?: ELayoutItemTypes,
    shiftKey?: boolean,
    parentType?: FormItemTypes.EPanelBaseTypes
}

export interface IStructureChange extends IBaseStructureChange {
    splitItemPath?: string[],
    splitDropZonePath: string[],
    item?: ILayoutStructureItem
}

export interface IReadonlyStructureChange extends IBaseStructureChange {
    splitItemPath?: List<string>,
    splitDropZonePath: List<string>,
    item?: LayoutStructureItem
}

export type StructureChange = ReadonlyRecord<IReadonlyStructureChange>;

export const StructureChangeFactory = Record<IReadonlyStructureChange>({
    splitItemPath: List(),
    item: LayoutStructureItemFactory(),
    splitDropZonePath: List(),
    method: null,
    dragItemType: null,
    shiftKey: false,
    parentType: null
});

export const StructureChangeFactoryFromJS = function (data: IStructureChange) {
    return StructureChangeFactory({
        item: data.item && LayoutStructureItemFactoryFromJS(data.item),
        splitItemPath: data.splitItemPath && List(data.splitItemPath),
        splitDropZonePath: data.splitDropZonePath && List(data.splitDropZonePath),
        method: data.method,
        dragItemType: data.dragItemType,
        shiftKey: data.shiftKey,
        parentType: data.parentType
    });
}