import { WebInterfaceBaseState } from "../../../common/base/state";
import { createSelector } from 'reselect';
import { DashboardFormData } from '../entities/DashboardFormData';


const getDashboardFormDataSelector = (state: WebInterfaceBaseState) => state.modules.startup.dashboardFormData;

const getDashboardFormData = createSelector(
    [getDashboardFormDataSelector],
    (dashboardFormData: DashboardFormData) => dashboardFormData ? dashboardFormData.toJS() : null
)

export default getDashboardFormData;