import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { Content, ContentFactory, ContentFactoryFromJS, IContent, IPanels, IParameters, Panels, PanelsFactory, PanelsFactoryFromJS, Parameters, ParametersFactory, ParametersFactoryFromJS } from "./Interfaces";

export interface IBaseHrMainState {}

export interface IHrMainState extends IBaseHrMainState {
    content: IContent,
    parameters?: IParameters,
    panels?: IPanels,
}

export interface IReadonlyHrMainState extends IBaseHrMainState {
    content: Content,
    parameters?: Parameters,
    panels?: Panels,
}

export type HrMainState = ReadonlyRecord<IReadonlyHrMainState>;

export const HrMainStateFactory = Record<IReadonlyHrMainState>({
    content: ContentFactory(),
    parameters: ParametersFactory(),
    panels: PanelsFactory(),
});

export const HrMainStateFactoryFromJS = function (data: IHrMainState) {

    return HrMainStateFactory({
        content: ContentFactoryFromJS(data.content),
        parameters: ParametersFactoryFromJS(data.parameters),
        panels: PanelsFactoryFromJS(data.panels),
    });
}