import * as React from "react";
import { ILegacyFrameProps, ILegacyFrameCProps } from "../entities";
import WorkflowFrame from "modules/workflow/components/workflowFrame/components";
import { panelType } from "common/entities/enums";



export default class LegacyFrame extends React.Component<ILegacyFrameProps & ILegacyFrameCProps, { panelId: string }>{
    constructor(props: ILegacyFrameProps & ILegacyFrameCProps){
        super(props);
        this.onFrameLoad = this.onFrameLoad.bind(this);
    }

    onFrameLoad() {
        if (this.props.onFrameLoaded) {
            this.props.onFrameLoaded({
                panelId: this.props.panelGuid,
                panelType: panelType.legacyFrame,                
            });
        }
    }

    render(){
        return <WorkflowFrame
            url={this.props.url}
            showLoading={this.props.showLoading}
            onFrameLoaded={this.onFrameLoad}
        />
    }
}