import { IBaseAction } from "@bimser/core";
import { UITourState } from "../entities/UITourState";
import * as ActionTypes from '../actions/actionTypes';
import { Map } from 'immutable';
import { Tour } from '../entities/ITour';

const UITourReducer = (state: UITourState, action: IBaseAction<any>): UITourState => {
    switch (action.type) {
        case ActionTypes.SET_TOURS_ACTION: {
            return setTours(state, action.payload);
        }
        case ActionTypes.LOAD_TOUR_ACTION: {
            return loadTour(state, action.payload);
        }
        case ActionTypes.TOUR_NEXT_ACTION: {
            return onNext(state, action.payload);
        }
        case ActionTypes.TOUR_PREV_ACTION: {
            return onPrev(state, action.payload);
        }
        case ActionTypes.START_TOUR_ACTION: {
            return onStartStop(state, true);
        }
        case ActionTypes.STOP_TOUR_ACTION: {
            return onStartStop(state, false);
        }
        case ActionTypes.CHANGE_TOUR_LOADING_ACTION: {
            return onChangeLoading(state, action.payload);
        }
        default:
            return state;
    }
}

function onChangeLoading(state: UITourState, payload: boolean): UITourState {
    if (state.tours.has(state.activeTourId)) {
        state = state.setIn(["tours", state.activeTourId, "isLoading"], payload);
    }
    return state;
}

function onNext(state: UITourState, payload: any): UITourState {
    let tourId = state.activeTourId;
    if (state.tours.has(tourId) && state.tours.get(tourId).currentStep < state.tours.get(tourId).steps.size - 1) {
        state = state.setIn(["tours", tourId, "currentStep"], state.tours.get(tourId).currentStep + 1);
    }
    return state;
}

function onPrev(state: UITourState, payload: any): UITourState {
    let tourId = state.activeTourId;
    if (state.tours.has(tourId) && state.tours.get(tourId).currentStep > 0) {
        state = state.setIn(["tours", tourId, "currentStep"], state.tours.get(tourId).currentStep - 1);
    }
    return state;
}

function onStartStop(state: UITourState, payload: boolean): UITourState {
    let tourId = state.activeTourId;
    if (state.tours.has(tourId)) {
        state = state.setIn(["tours", tourId, "isOpen"], payload);
        if (payload == false) {
            state = state.setIn(["tours", tourId, "currentStep"], 0).set("activeTourId", null);
        }
    }
    return state;
}

function loadTour(state: UITourState, payload: string): UITourState {
    return state.set("activeTourId", payload);
}

function setTours(state: UITourState, payload: Map<string, Tour>): UITourState {
    return state.set("tours", payload);
}

export default UITourReducer;