export default function (accept: string, onSelect: any, multiple?: boolean, defaultPath?: string, onCancel?: () => void) {
    let windowAny: any = window;
    let lock = false

    const _onSelect = (e: any) => {
        lock = true;
        onSelect(e);
    }
    if (windowAny.File && windowAny.FileReader && windowAny.FileList && windowAny.Blob) {

        let fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = accept;
        fileInput.multiple = multiple || fileInput.multiple;
        fileInput.value = defaultPath || fileInput.value;
        fileInput.addEventListener('change', _onSelect, false);
        window.addEventListener('focus', () => {
            setTimeout(() => {
                if (!lock && fileInput) {
                    onCancel && onCancel();
                }
            }, 300)
        }, { once: true })
        fileInput.click();

    }
}