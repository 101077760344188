import { FormItemTypes } from "@bimser/core";
import { List, Map } from "immutable";
import { generateId, getItemByPath, getZoneType, insertToView, isInTabs, removeItemFromChild } from ".";
import { LayoutDataItem, LayoutStructureItem, LayoutStructureItemFactory, NormalizedLayoutStructureItems } from "../../../common/entities";
import { ELayoutItemTypes } from "../entities";

export const handleMoveToDifferentParent = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    item: LayoutStructureItem,
    dragItemType: ELayoutItemTypes
) => {

    const rootId = views.getIn([activeView, "result", 0]);
    const times = isInTabs(views.get(activeView), item.id);
    const zoneType = getZoneType(splitDropZonePath.count() - times);

    switch (zoneType) {
        case "Section": {
            if (dragItemType === ELayoutItemTypes.SECTION) {

                views = defaultFn(views, activeView, splitDropZonePath, item);

            } else if (dragItemType === ELayoutItemTypes.COLUMN) {

                // moving column outside into new section made on the fly
                const columnId = item.id;

                // Create new section
                const newSectionId = generateId(views, data, FormItemTypes.EContainerTypes.Section);
                let newSection = LayoutStructureItemFactory({
                    id: newSectionId,
                    items: List([columnId]),
                    parentId: rootId,
                    type: FormItemTypes.EContainerTypes.Section
                });

                // Remove item from view
                views = removeItemFromChild(views, activeView, columnId);

                // Link items
                views = views.setIn([activeView, "entities", "items", columnId, "parentId"], newSectionId);

                // Insert new items to view
                views = insertToView(views, activeView, [newSection]);

                // Insert item to parent's items
                const dropZoneIndex = Number(splitDropZonePath.last());

                const rootItems = views.getIn([activeView, "entities", "items", rootId, "items"]).insert(dropZoneIndex, newSectionId);
                views = views.setIn([activeView, "entities", "items", rootId, "items"], rootItems);

            } else {

                // moving component outside into new section made on the fly
                const newComponentId = item.id;

                // Create new column
                const newColumnId = generateId(views, data, FormItemTypes.EContainerTypes.Column);
                let newColumn = LayoutStructureItemFactory({
                    id: newColumnId,
                    items: List([newComponentId]),
                    type: FormItemTypes.EContainerTypes.Column
                });

                // Create new section
                const newSectionId = generateId(views, data, FormItemTypes.EContainerTypes.Section);
                let newSection = LayoutStructureItemFactory({
                    id: newSectionId,
                    items: List([newColumnId]),
                    parentId: rootId,
                    type: FormItemTypes.EContainerTypes.Section
                });

                // Link items
                newColumn = newColumn.set("parentId", newSectionId);

                // Remove item from view
                views = removeItemFromChild(views, activeView, newComponentId);

                // Change component parent
                views = views.setIn([activeView, "entities", "items", newComponentId, "parentId"], newColumnId);

                // Insert new items to view
                views = insertToView(views, activeView, [newSection, newColumn]);

                // Insert item to parent's items
                const dropZoneIndex = Number(splitDropZonePath.last());

                const rootItems = views.getIn([activeView, "entities", "items", rootId, "items"]).insert(dropZoneIndex, newSectionId);
                views = views.setIn([activeView, "entities", "items", rootId, "items"], rootItems);

            }
            break;
        }
        case "Column": {
            // moving component outside into a section which creates column
            if (dragItemType === ELayoutItemTypes.COMPONENT) {

                const componentId = item.id;
                const sectionId = getItemByPath(views, activeView, splitDropZonePath.pop());

                // Create new column
                const newColumnId = generateId(views, data, FormItemTypes.EContainerTypes.Column);
                let newColumn = LayoutStructureItemFactory({
                    id: newColumnId,
                    items: List([componentId]),
                    parentId: sectionId,
                    type: FormItemTypes.EContainerTypes.Column
                });

                // Remove item from view
                views = removeItemFromChild(views, activeView, componentId);

                // Link items
                views = views.setIn([activeView, "entities", "items", componentId, "parentId"], newColumnId);

                // Insert new items to view
                views = insertToView(views, activeView, [newColumn]);

                // Insert item to parent's items
                const dropZoneIndex = Number(splitDropZonePath.last());

                const sectionItems = views.getIn([activeView, "entities", "items", sectionId, "items"]).insert(dropZoneIndex, newColumnId);
                views = views.setIn([activeView, "entities", "items", sectionId, "items"], sectionItems);

            } else {
                // moving column into existing section

                const columnId = item.id;
                const sectionId = getItemByPath(views, activeView, splitDropZonePath.pop());

                // Remove item from view
                views = removeItemFromChild(views, activeView, columnId);

                // Link items
                views = views.setIn([activeView, "entities", "items", columnId, "parentId"], sectionId);

                // Insert item to parent's items
                const dropZoneIndex = Number(splitDropZonePath.last());

                const sectionItems = views.getIn([activeView, "entities", "items", sectionId, "items"]).insert(dropZoneIndex, columnId);
                views = views.setIn([activeView, "entities", "items", sectionId, "items"], sectionItems);

            }

            break;
        }
        default: {
            views = defaultFn(views, activeView, splitDropZonePath, item);
        }
    }

    return views
};

const defaultFn = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    splitDropZonePath: List<string>,
    item
) => {
    // moving component/section into existing column/container

    const componentId = item.id;
    const columnId = getItemByPath(views, activeView, splitDropZonePath.pop());

    // Remove item from view
    views = removeItemFromChild(views, activeView, componentId);

    // Link items
    views = views.setIn([activeView, "entities", "items", componentId, "parentId"], columnId);

    // Insert item to parent's items
    const dropZoneIndex = Number(splitDropZonePath.last());

    const columnItems = views.getIn([activeView, "entities", "items", columnId, "items"]).insert(dropZoneIndex, componentId);
    views = views.setIn([activeView, "entities", "items", columnId, "items"], columnItems);

    return views
}