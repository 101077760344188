import * as React from "react";
import { IModalManagerCProps, IModalManagerProps } from '../entities';

export default class BCModalManager extends React.Component<IModalManagerProps & IModalManagerCProps, { footer?: any }> {

    ResolvePromise: any;
    RejectPromise: any;

    constructor(props: IModalManagerProps) {
        super(props);
        this.handleModalCancel = this.handleModalCancel.bind(this);
        this.handleModalOk = this.handleModalOk.bind(this);
        this.state = {
        };
    }

    handleModalCancel(modalKey: string) {
        this.RejectPromise();
        this.closeModal(modalKey);
    }

    handleModalOk(modalKey: string) {
        this.ResolvePromise();
        this.closeModal(modalKey);
    }

    closeModal(modalKey: string) {
        this.props.closeModal(modalKey);
    }
    changeFooter(footer?: any) {
        this.setState({
            footer: footer
        })
    }

    render() {
        let modals = this.props.items.map((item, index) => {
            let ComponentType = this.props.components ? this.props.components[item.type] : null;
            return <ComponentType key={item.id} {...item.props} id={item.id} visible={item.visible} width={item.width}  />;
        });

        return (
            <React.Fragment>
                {modals}
            </React.Fragment>
        );
    }
}