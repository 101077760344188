import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';

const getApprovesBoxLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.startup.approveBoxShowLoading;
}

const getApprovesBoxLoading = createSelector(
    [
        getApprovesBoxLoadingSelector
    ],
    (loading: boolean) => {
        return loading;
    }
);

export { getApprovesBoxLoading };
