import { IBaseAction } from "@bimser/core";
import { put, take, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from "../../../action/actionTypes";
import { initializeApplicationStartup } from '../actions';

export default function* mobileInitializerTransaction() {
    yield takeEvery(ActionTypes.MOBILE_INITIALIZER, mobileFormInitilizer)
}

function* mobileFormInitilizer(action: IBaseAction<any>) {
    yield put(initializeApplicationStartup());
    yield take(ActionTypes.GENERAL_INITIALIZER_TRANSACTION_ACTION);
    yield put({
        type: "APP_MAIN_MODULE.OPEN_MOBILE_FORM_TRANSACTION",
        payload: action.payload
    });
}