import { FormItemTypes } from "@bimser/core";
import MouseAdd from "@bimser/icons/8/mouse-add";
import MouseDelete from "@bimser/icons/8/mouse-delete";
import IconMouseMenu from "@bimser/icons/8/mouse-menu";
import React, { useState } from "react";
import * as Styles from "./assets/styles.scss";
import { ELayoutItemTypes, IActionMenu, StructureChangeFactoryFromJS } from "./entities";
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const ActionMenu = React.memo(({ onStructureChange, data, path }: IActionMenu) => {

    const [collapsed, setCollapsed] = useState(true);
    const { type, items, id } = data;

    const isSection = type === FormItemTypes.EContainerTypes.Section;
    const isColumn = type === FormItemTypes.EContainerTypes.Column;
    const isComponent = !isSection && !isColumn;

    const cxClassNames = cx({
        actionMenu: true,
        isSection,
        isColumn,
        isComponent,
        collapsed
    });

    const addItem = (e: React.MouseEvent) => {
        const splitDropZonePath = [...path.split("-"), items.count().toString()];
        onStructureChange(StructureChangeFactoryFromJS({
            splitDropZonePath,
            method: "handleMoveSidebarContainerIntoParent",
            dragItemType: ELayoutItemTypes.SIDEBAR_ITEM,
            item: data.toJS()
        }));
        e.stopPropagation();
    }

    const deleteItem = (e: React.MouseEvent) => {
        onStructureChange(StructureChangeFactoryFromJS({
            splitDropZonePath: path.split("-"),
            method: "delete"
        }));
        e.stopPropagation();
    }

    const toggleActionMenu = () => {
        setCollapsed(!collapsed);
    }

    let autoHideMenuTimer;
    const mouseLeaveActionMenu = () => {
        autoHideMenuTimer = setTimeout(() => {
            if (!collapsed) setCollapsed(true);
        }, 1000)
    }

    const mouseEnterActionMenu = () => {
        clearTimeout(autoHideMenuTimer);
    }

    const mouseDownActionMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div className={cxClassNames} onMouseLeave={mouseLeaveActionMenu} onMouseEnter={mouseEnterActionMenu} onMouseDown={mouseDownActionMenu} id={id}>
            <div className={Styles.actionMenuContent}>
                <span>{id}</span>
                <ul>
                    {isSection && <li><button onClick={addItem}><MouseAdd /></button></li>}
                    <li><button onClick={deleteItem}><MouseDelete /></button></li>
                </ul>
            </div>
            <button className={Styles.toggleBtn} onClick={toggleActionMenu}><IconMouseMenu color="#fff" /></button>
        </div>
    )
})

export { ActionMenu };

