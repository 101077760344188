import { getPopupContainer, isNullOrUndefined } from "@bimser/core";
import AutoComplete from "antd/lib/auto-complete";
import Input from "antd/lib/input";
import * as React from "react";
import { IAutoCompleteProps, IOptionItem } from './entities';
import Style from "./assets/style.scss";


const optionStyle : React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
}

export { IAutoCompleteProps, IOptionItem };

export default (props: IAutoCompleteProps) => {
    const [value, setValue] = React.useState<string>();
    
    React.useEffect(() => {
        if(props.value) {
            const initialValue = getValue(props.value);
            setValue(initialValue);
        }
    }, [ props.value]);
    
    const getValue = React.useCallback((propsValue: string) => {
        let newValue = propsValue || null;
        const dataSource = props.dataSource || getAllGroupOptions;

        if (dataSource?.length && !isNullOrUndefined(propsValue)) {
            let val = dataSource.find(i => i.value === propsValue);

            if (val?.text) {
                newValue = val.text
            }
        }

        return newValue;

    }, [props.dataSource, props.dataSourceGroup])

    const getAllGroupOptions = React.useMemo(() => {
        let options: IOptionItem[] = [];

        props.dataSourceGroup?.forEach(g=> {
            options.push(...g.options);
        })

        return options;
    }, [ props.dataSourceGroup ])

    const onSelect = React.useCallback((componentValue: string, option: IOptionItem) => {
        if(!props.readOnly) {
            const patchedValue = getValue(componentValue);
            setValue(patchedValue);
            props.onChange?.(componentValue, option);
        }
    }, [props.onChange, props.readOnly])

    const onChange = React.useCallback((componentValue: string) => {
        if(!props.readOnly) {
            setValue(componentValue);
        }
    }, [props.readOnly])

    const onSearch = React.useCallback((searchValue: string) => {
        if (!props.readOnly)
            props.onSearch?.(searchValue);
    }, [props.onSearch, props.readOnly])

    const renderInput = React.useMemo(() => {
        if (props.renderCustomInput) {
            return props.renderCustomInput();
        }

        return <Input
            size={props.size}
            placeholder={props.placeholder}
            suffix={props.suffix}
            readOnly={props.readOnly}
        />
    }, [props.renderCustomInput, props.size, props.placeholder, props.suffix, props.readOnly])

    const getOptions = React.useMemo(() => {
        let options: any = [];

        if (props.dataSource) {
            props.dataSource.forEach((item: any) => {
                if (props.customItemRender) {
                    options.push({
                        value: item.value,
                        title: item.text,
                        key: item.key,
                        label: (<div
                            style={optionStyle}
                        >
                            {props.customItemRender(item)}
                        </div>)
                    });
                }
                else if (typeof item === "string") {
                    options.push({
                        value: item,
                        title: item,
                        key: item,
                        label: (<div
                            style={optionStyle}
                        >
                            {item}
                        </div>)
                    });
                }
                else {
                    options.push({
                        value: item.value,
                        title: item.text,
                        key: item.key,
                        label: (<div
                            style={optionStyle}
                        >
                            {item.text}
                        </div>)
                    });
                }
            });
        } else if (props.dataSourceGroup){
            return props.dataSourceGroup;
        }
        return options;
    }, [props.dataSource, props.dataSourceGroup, props.customItemRender])


    return (
        <AutoComplete
            tabIndex={props.tabIndex}
            options={getOptions}
            onChange={onChange}
            autoFocus={props.autoFocus}
            backfill={props.backFill}
            onSelect={onSelect}
            showSearch={props.showSearch}
            showArrow={props.showArrow}
            onSearch={onSearch}
            value={value}
            style={props.style}
            disabled={props.disabled}
            className={[Style.AutoCompleteClearButton, props.className].join(" ")}
            filterOption={props.showSearch ? (inputValue: any, option: any) =>
                option.title.toUpperCase().includes(inputValue.toUpperCase()) : undefined
            }
            dropdownClassName={props.dropdownClassName}
            allowClear={props.allowClear && !props.readOnly}
            getPopupContainer={props.disablePopupContainer ? undefined : getPopupContainer}
            open={props.readOnly ? false : undefined}
        >
            {renderInput}
        </AutoComplete>
    );


    
}
