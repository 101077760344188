import { findMessage } from "@bimser/components";
import moment from "moment";
import 'moment/locale/az';
import 'moment/locale/ru';
import { TileTypes } from "./entities/widgets/TileTypes";
import { EProcessStructureType } from "./entities/widgets/model/types/IProcessStructure";

const userTiles = () => [
    {
        caption: findMessage.get("102960"),
        badgeColor: "#004e8c",
        key: TileTypes.Waiting
    },
    {
        caption: findMessage.get("102961"),
        badgeColor: "#ffaa44",
        key: TileTypes.Started
    },
    {
        caption: findMessage.get("102962"),
        badgeColor: "#00ad56",
        key: TileTypes.OnGoing
    },
    {
        caption: findMessage.get("102963"),
        badgeColor: "#69797e",
        key: TileTypes.Notification
    }
]

const adminTiles = () => [
    {
        caption: findMessage.get("102964"),
        badgeColor: "#004e8c",
        key: TileTypes.Started
    },
    {
        caption: findMessage.get("102965"),
        badgeColor: "#ffaa44",
        key: TileTypes.OnGoing
    },
    {
        caption: findMessage.get("102966"),
        badgeColor: "#00ad56",
        key: TileTypes.Finished
    }
]

export const tiles = { user: userTiles, admin: adminTiles };

export const tileTypeAndKeys = {
    "started": TileTypes.Started,
    "onGoing": TileTypes.OnGoing,
    "finished": TileTypes.Finished,
    "waiting": TileTypes.Waiting,
    "notification": TileTypes.Notification
}

export const formatDates = (date: string) => {
    const momentDate = moment(date);
    const diff = moment.duration(moment().diff(momentDate));

    if (diff.asDays() >= 7) {
        return momentDate.format("LL");
    } else {
        return momentDate.fromNow();
    }
}

export const dataTypeOfProcessStructureType = {
    [EProcessStructureType.Integer]: "number",
    [EProcessStructureType.Boolean]: "boolean",
    [EProcessStructureType.Date]: "date",
    [EProcessStructureType.DateTime]: "datetime",
    [EProcessStructureType.DateTimeOffset]: "datetimeOffset",
    [EProcessStructureType.Time]: "time",
    [EProcessStructureType.Object]: "object",
    [EProcessStructureType.Array]: "object",
    [EProcessStructureType.Double]: "number",
    [EProcessStructureType.Float]: "number",
    [EProcessStructureType.Null]: "none",
    [EProcessStructureType.String]: "string",
    [EProcessStructureType.Decimal]: "number",
    [EProcessStructureType.Guid]: "string",
    [EProcessStructureType.Unknown]: "none"
}