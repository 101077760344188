import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { ILoginResponseData, IReadonlyLoginResponseData } from "./LoginResponseData";

export interface ILoginResponseWithMFAData extends ILoginResponseData {
    authCode?: string;
    mfaEnabled?: boolean;
    tempTokenKey?: string;
}

export interface IReadonlyLoginResponseWithMFAData extends IReadonlyLoginResponseData {
    authCode: string;
    mfaEnabled: boolean;
    tempTokenKey: string;
}

export type LoginResponseWithMFAData = ReadonlyRecord<IReadonlyLoginResponseWithMFAData>;

export const LoginResponseWithMFADataFactory = Record<IReadonlyLoginResponseWithMFAData>({
    token: null,
    authCode: null,
    mfaEnabled: false,
    tempTokenKey: null,
});

export const LoginResponseWithMFADataFactoryFromJS = function (data: ILoginResponseWithMFAData) {
    return LoginResponseWithMFADataFactory({
        token: data.token,
        authCode: data.authCode,
        mfaEnabled: data.mfaEnabled,
        tempTokenKey: data.tempTokenKey,
    });
};