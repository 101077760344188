import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IUserGroupListFormProps } from './entities';
import { IBaseListFormProps } from '../../../common/base/components/listForm/entities';


const GroupListContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function GroupListContainer(props: React.PropsWithChildren<IUserGroupListFormProps & IBaseListFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <GroupListContainerLazy {...props} />
        </Suspense>
    );
}
GroupListContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default GroupListContainer;