import * as React from "react";
import {IBarcodeProps, EBarcodeTypes} from './entities/IBarcodeProps';

var QRCode = require('qrcode.react');
var Barcode = require('react-barcode');

export default class BCBarcode extends React.Component<IBarcodeProps, {}> {
    constructor(props: IBarcodeProps) {
        super(props);
    }

    render() {
        return (
            <div style={this.props.style}>
                {
                    this.props.value ? (this.props.codeType == EBarcodeTypes.QR ?
                        (
                            <QRCode value={this.props.value}/>
                        ) :
                        (<Barcode value={this.props.value} displayValue={false} background={"transparent"}/>)) : ""
                }
            </div>
        );
    }
}

export {IBarcodeProps, EBarcodeTypes};
