export function generateUniqueName(itemNames: string[], name: string, prefer = false, regexpCheck = true): string {

    let prefix = 1;

    if (itemNames?.length) {

        if (prefer && itemNames.indexOf(name) === -1) return name;

        itemNames.forEach((itemName) => {
            if (regexpCheck) {
                let regexp = new RegExp('^' + name + '[0-9]+');
                let result = itemName.match(regexp);
                if (!result) return;
            }

            let namePrefix = itemName.split(name)[1];
            prefix = Math.max(parseInt(namePrefix) + 1, prefix);
        })

    }

    return name + prefix

}