import { connect } from "react-redux";
import { BCIntlProvider } from "@bimser/components";
import { getDefaultLanguage } from '../../system/language/selectors';

const mapStateToProps = function (state: any, props: any): any {
    return {
        defaultLocale: getDefaultLanguage(state).culture
    };
}

export default connect(mapStateToProps)(BCIntlProvider);