import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { DMExplorerState } from '../entities/DMExplorerState';

const getItemsCountSelector = (state: BaseState) => state.DMExplorer;
const getItemsCount = createSelector(
    [
        getItemsCountSelector
    ],
    (data: DMExplorerState): number => {
        let loadedItemsForPath = data.itemsData.find((item, key) => data.currentLocation.id === key);
        if (loadedItemsForPath) {
            if (data && data.itemsData && data.itemsData.has(data.currentLocation.id) && data.itemsData.get(data.currentLocation.id).itemTotal > -1) {
                return data.itemsData.get(data.currentLocation.id).itemTotal;
            }
            else {
                return -1;
            }
        }

        else {
            return -1;
        }
    }
);


export {
    getItemsCount
};

export function nullCaster(data: any): any {
    return data == "" || data == null || data == undefined ? null : data;
}