import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IProcessStep, ProcessStep, ProcessStepFactory, ProcessStepFactoryFromJS } from './ProcessStep';
import { OutputMessageType, OutputMessageStatus } from './Enums';

interface IBaseDeployAgentMessage {
    Code?: string,
    ProjectName: string,
    ProjectVersion: number,
    Description: string,
    Type: OutputMessageType,
    Status: OutputMessageStatus,
    InnerException?: any
}

export interface IDeployAgentMessage extends IBaseDeployAgentMessage {
    Step: IProcessStep;
}

export interface IReadonlyDeployAgentMessage extends IBaseDeployAgentMessage {
    Step: ProcessStep;
}

export type DeployAgentMessage = ReadonlyRecord<IReadonlyDeployAgentMessage>;

export const DeployAgentMessageFactory = Record<IReadonlyDeployAgentMessage>({
    Code: "",
    ProjectName: "",
    ProjectVersion: 0,
    Description: "",
    Type: OutputMessageType.Info,
    Status: OutputMessageStatus.Processing,
    Step: ProcessStepFactory(),
    InnerException: null
});

export const DeployAgentMessageFactoryFromJS = function (data: IDeployAgentMessage) {
    return DeployAgentMessageFactory({
        ...data,
        Step: ProcessStepFactoryFromJS(data.Step)
    });
}