import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";

export interface IBrowserProperties {
    blockingType: number
    browserList: string[],
    customBrowserQuery: string,
    isBlocked: boolean,
    customMessageHeader: IDictionary<string>,
    customMessageBody: IDictionary<string>
}

export interface IReadonlyBrowserProperties {
    blockingType: number
    browserList: string[],
    customBrowserQuery: string,
    isBlocked: boolean,
    customMessageHeader: Map<string, string>,
    customMessageBody: Map<string, string>
}

export type BrowserProperties = ReadonlyRecord<IReadonlyBrowserProperties>;

export const BrowserPropertiesFactory = Record<IReadonlyBrowserProperties>({
    blockingType: null,
    browserList: [],
    customBrowserQuery: '',
    isBlocked: null,
    customMessageHeader: Map(),
    customMessageBody: Map()
});

export const BrowserPropertiesFactoryFromJS = function (data: IBrowserProperties) {
    return BrowserPropertiesFactory({
        ...data,
        customMessageHeader: Map(data?.customMessageHeader),
        customMessageBody: Map(data?.customMessageBody)
    });
}