import * as React from "react";
import { BCButton, BCInputGroup, BCTreeSelect, BCTreeSelectRef } from "../../..";
import { ITreeSelectLoadingArgs } from '../../../BCTreeSelect/entities/ITreeSelectProps';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import IconRefresh from "@bimser/icons/16/refresh";

const inputStyleWithPromise: React.CSSProperties = { width: 'calc(100% - 35px)' };
const inputStyle: React.CSSProperties = { width: '100%' };
const buttonStyle: React.CSSProperties = { width: '35px', border: "1px solid #d9d9d9" };

export default function TreeSelectProperty(props: IPropertyComponentProps) {

    const treeSelect = React.useRef<BCTreeSelectRef>();

    const getOptionsPromise = (): Promise<any> => {
        if (props.item.options && props.item.options.isPromise && props.getOptionsPromise) {
            return props.getOptionsPromise(props.item)
        }
    }

    const onRefresh = () => {
        if (props.onRefresh) {
            let awaitedPromise = props.onRefresh(props.item);
            if (awaitedPromise) {
                awaitedPromise.then((result: ITreeSelectLoadingArgs) => {
                    treeSelect.current.setOptions(result ? result.options : []);
                });
            }
        }
    }

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const onChange = (e: any) => {
        props.customChangeEvent ? props.customChangeEvent(e) : replaceValue(props.item, e)
    }

    const getTreeSelect = (styleWithPromise?: React.CSSProperties) => {
        let _inputStyle = styleWithPromise ? styleWithPromise : inputStyle;
        return (
            <BCTreeSelect
                ref={treeSelect}
                treeData={props.item.externalData?.items}
                value={props.value || props.item.placeholder}
                onChange={onChange}
                disabled={props.item.disabled || props.item.readOnly}
                style={props.style || _inputStyle}
                dropdownStyle={{ minWidth: 200, maxHeight: 350 }}
                onLoadOptions={getOptionsPromise}
                isRunOnLoadOptionsStartup={props.item.options && props.item.options.isPromise}
                multiple={props.item.options && props.item.options.multiple}
                treeNodeLabelProp={'key'}
                showSearch={props.item?.externalData?.showSearch}
            />
        );
    }

    const renderTreeSelect = () => {
        if (props.item.options && props.item.options.isPromise) {
            return (<BCInputGroup compact={true} >
                {getTreeSelect(inputStyleWithPromise)}
                <BCButton
                    style={buttonStyle}
                    icon={<IconRefresh />}
                    onClick={onRefresh}
                />
            </BCInputGroup>);
        }
        else {
            return getTreeSelect();
        }
    }

    return renderTreeSelect();
}