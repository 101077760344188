import { createSyncAction } from "@bimser/core";
import * as ActionTypes from './actionTypes';
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "../../../common/entities";

export default function openWorkflowMenuTransaction(data: IWebInterfaceAction) {

    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.OPEN_WORKFLOW_MENU_TRANSACTION, payload);

    return action;
}