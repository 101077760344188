import React, { useState } from "react";
import Tag from "antd/lib/tag";
import Tooltip from "antd/lib/tooltip";
import IconAdd from "@bimser/icons/16/add";
import * as Styles from "./assets/style.scss";
import ITagGroupProps from "./entities/ITagGroupProps";
import BCInput, { IInputChangeEventArgs } from "../BCInput";
import BCNotification from "../BCNotification";
import { findMessage } from "../BCIntl";

export default function TagGroup(props: ITagGroupProps) {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputData, setEditInputData] = useState({ index: -1, value: "" });

  const maxTagLength = props.maxTagLength || 20;

  function handleRemove(removedTag: string) {
    const _tags = props.tagList?.filter(tag => tag !== removedTag);
    props.setTagList(_tags);
  }

  function showInput() {
    setInputVisible(true);
  }
  function handleInputChange(args: IInputChangeEventArgs) {
    setInputValue(args.data);
  }

  function handleEditTag(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    tag: string,
    index: number
  ) {
    setEditInputData({ index, value: tag });
    e.preventDefault();
  }

  function handleDuplicationError() {
    BCNotification.error({
      message: findMessage.get("102530"),
      description: findMessage.get("102531"),
    });
  }

  function handleInputConfirm() {
    const _newTag = inputValue.trim();
    if (_newTag && props.tagList?.indexOf(_newTag) === -1) {
      props.setTagList([...props.tagList, _newTag]);
      setInputVisible(false);
      setInputValue("");
    } else if (_newTag) {
      handleDuplicationError();
    }
    else {
      setInputVisible(false);
      setInputValue("");
    }
  }

  function handleEditInputChange(args: IInputChangeEventArgs) {
    setEditInputData(editInputData => ({ ...editInputData, value: args.data }));
  }

  function handleEditInputConfirm() {
    const _newTag = editInputData.value.trim();

    if (_newTag && !props.tagList?.find((tag, i) => tag === _newTag && i !== editInputData.index)) {
      props.setTagList(
        props.tagList?.map((tag, i) => {
          if (i == editInputData.index) {
            return _newTag;
          }
          return tag;
        })
      );
      setEditInputData({ index: -1, value: "" });
    } else if (_newTag && props.tagList?.find((tag, i) => tag === _newTag && i !== editInputData.index)) {
      handleDuplicationError();
    } else {
      setEditInputData({ index: -1, value: "" });
    }
  }

  function renderAddTag() {
    return (<>
      {inputVisible ? (
        <BCInput
          type="text"
          size="small"
          className={Styles.TagInput}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          autoFocus={true}
        />
      ) :
        (
          <Tag className={Styles.SiteTagPlus} onClick={showInput}>
            <span>
              <IconAdd className={Styles.TagIcon} />
            </span>
            <span>{props.addTagLabel ?? findMessage.get("102534")}</span>
          </Tag>
        )}
    </>);
  }

  function renderEditTag(tag: string) {
    return (
      <BCInput
        key={tag}
        size="small"
        className={Styles.TagInput}
        value={editInputData.value}
        onChange={handleEditInputChange}
        onBlur={handleEditInputConfirm}
        onPressEnter={handleEditInputConfirm}
        autoFocus={true}
      />
    );
  }

  function renderTag(tag: string, index: number, isLongTag: boolean) {
    const _Tag = (
      <Tag
        className={Styles.EditTag}
        key={tag}
        closable={true}
        onClose={() => handleRemove(tag)}
      >
        <span onDoubleClick={e => handleEditTag(e, tag, index)}>
          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
        </span>
      </Tag>
    );
    if (isLongTag) {
      return (
        <Tooltip title={tag} key={tag}>
          {_Tag}
        </Tooltip>
      );
    } else { return _Tag; }
  }

  function renderTagGroup() {
    return (
      <>
        {props.tagList?.map((tag, index) => {
          const isLongTag = tag.length > maxTagLength;
          if (editInputData.index == index) {
            return renderEditTag(tag);
          } else {
            return renderTag(tag, index, isLongTag);
          }
        })}
      </>
    );
  }

  return (
    <div className={Styles.TagGroup}>
      {renderTagGroup()}
      {renderAddTag()}
    </div>
  );
}

export { ITagGroupProps };
