import { BCExtremeGridEntities } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IWFProcessItem } from "modules/workflow/entities";
import { FilteringData, FilteringDataFactory, FilteringDataFactoryFromJS, IFilteringData } from "../components/TaskManagementFilter/entities/IFilteringData";

interface IBaseTaskManagementState {
    activeChildPanelGuid: string;
}

export interface ITaskManagementState extends IBaseTaskManagementState {
    filteringData: IFilteringData;
    processItems: IWFProcessItem[];
    selectedProcessItems: IWFProcessItem[];
    gridColumns: BCExtremeGridEntities.IExtremeGridColumn[];
}

export interface IReadonlyTaskManagementState extends IBaseTaskManagementState {
    filteringData: FilteringData;
    processItems: List<IWFProcessItem>;
    selectedProcessItems: List<IWFProcessItem>;
    gridColumns: List<BCExtremeGridEntities.IExtremeGridColumn>;
}

export type TaskManagementState = ReadonlyRecord<IReadonlyTaskManagementState>;

export const TaskManagementStateFactory = Record<IReadonlyTaskManagementState>({
    activeChildPanelGuid: null,
    filteringData: FilteringDataFactory(),
    gridColumns: List(),
    processItems: List(),
    selectedProcessItems: List(),
});

export const TaskManagementStateFactoryFromJS = function (data: ITaskManagementState) {
    return TaskManagementStateFactory({
        ...data,
        filteringData: FilteringDataFactoryFromJS(data.filteringData),
        gridColumns: List(data.gridColumns),
        processItems: List(data.processItems),
        selectedProcessItems: List(data.selectedProcessItems),
    });
}