import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseFlowDocumentDmObjectOptions {
    id: number,
    path: string,
    secretKey: string
}

export interface IFlowDocumentDmObjectOptions extends IBaseFlowDocumentDmObjectOptions { }

export interface IReadonlyFlowDocumentDmObjectOptions extends IBaseFlowDocumentDmObjectOptions { }

export type FlowDocumentDmObjectOptions = ReadonlyRecord<IReadonlyFlowDocumentDmObjectOptions>;

export const FlowDocumentDmObjectOptionsFactory = Record<IReadonlyFlowDocumentDmObjectOptions>({
    id: null,
    path: null,
    secretKey: null
});

export const FlowDocumentDmObjectOptionsFactoryFromJS = function (data: IFlowDocumentDmObjectOptions) {
    return FlowDocumentDmObjectOptionsFactory(data);
}