import { BCNotification, findMessage } from '@bimser/components';
import { IBaseAction, isNullOrUndefined } from "@bimser/core";
import _uniqBy from "lodash/uniqBy";
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ServiceList } from '../../..';
import { BaseState } from '../../../entities/BaseState';
import { clearNavigationHistory, DMExplorerActions, LoadAction, popNavigationHistory, pushNavigationHistory, setLoadedData, SetNavigationPath, updateCurrentLocation, updateFilter, UpdateLoadingStatus, UpdateOpenedStatus } from '../actions';
import { INavigationItem } from '../entities';
import { LoadedItemFactoryFromJS } from '../entities/ILoadedItem';
import { IReadonlyNavigationHistoryItem, NavigationHistoryItem } from '../entities/INavigationHistoryItem';
import { LoadPayload } from '../entities/LoadPayload';
import getNavigationItemById from '../selectors/getNavigationItemById';

export default function* (): any {
    yield all([
        yield takeLatest(DMExplorerActions.LOAD, handleLoad),
        yield takeLatest(DMExplorerActions.GO_UP, handleGoUp),
        yield takeLatest(DMExplorerActions.PATH_CLICKED, handlePathClicked),
        yield takeLatest(DMExplorerActions.ON_SEARCH_TEXT_CHANGED, handleOnSearchTextChanged)
    ])
}

function* handleLoad(action: IBaseAction<LoadPayload>) {
    let { data, isReload, filter, pagination, pageTotal } = action.payload.toJS();
    yield put(UpdateLoadingStatus(true));

    const state: BaseState = yield select();
    let navItem = getNavigationItemById(state, data.id, state.DMExplorer.filter.extension);
    if (!isReload && navItem) {
        //set to currentItem and push to history
        yield put(updateCurrentLocation(data));
        yield put(UpdateOpenedStatus(true));
        if (data.id == '#Root') {
            yield put(clearNavigationHistory());
        } else {
            yield put(pushNavigationHistory(data));
        }
    } else {
        try {
            // Path içindeki sadece item sayısını gösteren endpoint yazıldığında burası değiştirilebilir.
            const propsFilter = state.get("DMExplorer")?.filter;
            if (propsFilter.extension != filter.extension || propsFilter.name != filter.name) {
                yield put(updateFilter(filter));
            }

            let navigationItems: { items: INavigationItem[]; } = yield ServiceList.SolutionExplorer.SolutionExplorer.GetNavigationItems.call({ secretKey: data.secretKey, pagination: { skip: 0, take: 99999 } }, undefined, undefined, true);
            if (navigationItems?.items?.length > -1) {
                filter = state.DMExplorer.filter?.extension ? state.DMExplorer.filter : filter;
                let filteredItems = navigationItems.items.filter((item: INavigationItem) => {
                    if (filter?.extension) {
                        if (filter.extension && (filter.extension == "" || filter.extension == "folder_only") && item.extension == "") return true;
                        else return (item.extension == "" || item.extension == filter.extension);
                    }
                    return true;
                });

                if (pageTotal == -1) {
                    pageTotal = filteredItems.length;
                }

                let searchResults: INavigationItem[] = [];

                if (!isNullOrUndefined(filter) && !isNullOrUndefined(filter.name) && filter.name !== '') {
                    searchResults = filteredItems.filter((i: any) => i.name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase()));
                    filteredItems = [...filteredItems, ...searchResults]
                    filteredItems = _uniqBy(filteredItems, "id");
                }

                let _data = {
                    id: data.id,
                    item: LoadedItemFactoryFromJS({
                        itemTotal: pageTotal,
                        filter: filter,
                        items: filteredItems,
                        pagination
                    })
                }
                yield put(setLoadedData(_data));
                if (!isReload) {
                    yield put(updateCurrentLocation(data));
                    yield put(pushNavigationHistory(data));
                }
            }
            else {
                yield call(handleGoUp);
                BCNotification.error({
                    message: findMessage.get("102356")
                });
            }
            //}
            //

            //let response = yield ServiceList.SolutionExplorer.SolutionExplorer.GetNavigationItems.call({ secretKey: data.secretKey, pagination: { skip: (pagination.skip - 1) * pagination.take, take: pagination.take } }); //data.secretKey ? folderRes.result : rootRes.result;
            // if (response) {
            //     let _data = {
            //         id: data.id,
            //         item: LoadedItemFactoryFromJS({
            //             itemTotal: pageTotal,
            //             filter: filter,
            //             items: response.items,
            //             pagination
            //         })
            //     }
            //     yield put(setLoadedData(_data));
            //     if (!isReload) {
            //         yield put(updateCurrentLocation(data));
            //         yield put(pushNavigationHistory(data));
            //     }
            // }
        } catch (error) {
            console.log("LOG: function*handleLoad -> error", error)
        }

    }
    yield put(UpdateLoadingStatus(false));
    yield put(UpdateOpenedStatus(true));
}

function* handleGoUp() {
    let state: BaseState = yield select();
    let navHistory = state.DMExplorer.navigationHistory;
    if (navHistory.size > 1) {
        navHistory = navHistory.pop();
        let item: IReadonlyNavigationHistoryItem = navHistory.last();
        yield put(updateCurrentLocation(item));
        yield put(popNavigationHistory());
    }

}

function* handlePathClicked(action: IBaseAction<NavigationHistoryItem>) {
    let item = action.payload;
    //yield put(updateCurrentLocation(item));
    yield put(SetNavigationPath(item));
}

function* handleOnSearchTextChanged(action: IBaseAction<string>) {
    if (action.payload.length > 0) {
        yield put(updateFilter({ name: action.payload }));
        let state: BaseState = yield select();
        let currentItem = state.DMExplorer.currentLocation;
        yield put(LoadAction({ data: currentItem, isReload: true, filter: { ...state.DMExplorer.filter.toJS(), name: action.payload }, pagination: null, pageTotal: -1 }))
    }
}
