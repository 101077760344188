import { connect } from "react-redux";
import { WebInterfaceBaseState } from '../../../base/state';
import { closeSignatureModalAction, onSignDocumentTransaction } from '../actions';
import SignatureModal from "../components";
import { ISignatureModalProps, ISignDocumentPayload } from '../entities';
import { getDocumentSignature, getSignatureModalLoading, getSignaturePermissions } from '../selectors';

const mapStateToProps = function (state: WebInterfaceBaseState, props: ISignatureModalProps): ISignatureModalProps {
    return {
        documentSignature: getDocumentSignature(state, props.modalId),
        isLoading: getSignatureModalLoading(state, props.modalId),
        permissions: getSignaturePermissions(state)
    };
}
const mapDispatchToProps = function (dispatch: Function): ISignatureModalProps {
    return {
        onCloseModal: (result: boolean) => {
            dispatch(closeSignatureModalAction(result));
        },
        onSignDocument: (payload: ISignDocumentPayload) => {
            dispatch(onSignDocumentTransaction(payload));
        }
    };
}

const SignatureModalContainer = connect(mapStateToProps, mapDispatchToProps)(SignatureModal);
export default SignatureModalContainer;