import IconBreadCrumbArrow from "@bimser/icons/16/breadcrumps-arrow";
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import * as React from "react";
import { withSize } from 'react-sizeme';
import BCButton from '../../BCButton';
import * as Styles from '../assets/style.scss';
import { IPaginationItem, IPaginationProps, ISize, IPaginationState } from '../entities';
import PaginationItem from './PaginationItem';

const maxItemWidth = 136;

function useMergeState(initialState: IPaginationState): [IPaginationState, (state: IPaginationState) => void] {
    const [state, setState] = React.useState<IPaginationState>(initialState);
    const setMergedState = newState =>
        setState(prevState => Object.assign({}, prevState, newState)
        );
    return [state, setMergedState];
}

function Pagination(props: IPaginationProps) {

    let breadcrumbItems: IPaginationItem[] = [];
    let leftDropdownItems: IPaginationItem[] = [];
    let rightDropdownItems: IPaginationItem[] = [];

    const [state, setState] = useMergeState({
        itemCount: 0,
        itemWidthsList: [],
        itemKeys: []
    });

    const calculateItemMembers = () => {

        if (state.itemCount > 0) {

            let activeItemPosition = getActiveItemPosition();
            let sideShowCount = Math.floor(state.itemCount / 2);
            let leftSlice = props.items.slice(0, activeItemPosition);
            let activeItem = props.items[activeItemPosition];
            let rightSlice = props.items.slice(activeItemPosition + 1, props.items.length);

            let missingLeftItemCount = 0;
            let missingRightItemCount = 0;

            if (leftSlice.length < sideShowCount) {
                missingLeftItemCount = sideShowCount - leftSlice.length;
            } else if (rightSlice.length < sideShowCount) {
                missingRightItemCount = sideShowCount - rightSlice.length - (state.itemCount % 2 ? 0 : 1);
            }

            let leftBreadcrumbItems = leftSlice.slice(Math.max(0, leftSlice.length - sideShowCount - missingRightItemCount), leftSlice.length);
            let rightBreadcrumbItems = rightSlice.slice(0, Math.max(0, sideShowCount + missingLeftItemCount - (state.itemCount % 2 ? 0 : 1)));

            breadcrumbItems = [...leftBreadcrumbItems, activeItem, ...rightBreadcrumbItems];
            leftDropdownItems = leftSlice.slice(0, Math.max(0, leftSlice.length - sideShowCount - missingRightItemCount));
            rightDropdownItems = rightSlice.slice(Math.max(0, (rightSlice.length - (rightSlice.length - sideShowCount)) + missingLeftItemCount - (state.itemCount % 2 ? 0 : 1)), rightSlice.length);


        } else {
            breadcrumbItems = props.items;
            leftDropdownItems = [];
            rightDropdownItems = [];
        }

        calculateItemCount();
    }

    const getActiveItemPosition = () => {
        if (props.items.length == 1) return 0;
        return props.items.findIndex(item => item.isActive)
    }


    const renderBreadcrumbItems = () => {
        return breadcrumbItems.map((item: IPaginationItem) => {
            if (item) {
                return (
                    <PaginationItem
                        key={item.id}
                        data={item}
                        onSize={(size: ISize) => getItemWidth(size, item)}
                    />
                )
            }

        })
    }

    const calculateItemCount = () => {
        let totalWidth = 0;
        let bothOverflowButtonVisible = rightDropdownItems?.length ? true : false;
        let containerWidth = props.size.width;
        let num = 0;
        let count = 0;

        let items = state.itemWidthsList;
        for (let item in items) {
            if (totalWidth + maxItemWidth > containerWidth) {
                if (bothOverflowButtonVisible) {
                    if (num == 2) {
                        count = 1;
                    } else {
                        count = num - 2;
                    }
                } else {
                    count = num - 1;
                }
                break;
            } else {
                totalWidth += items[item];
                num++;
            }
        }

        if (state.itemCount !== count) setState({ itemCount: count });

    }

    const getItemWidth = (size: ISize, item: IPaginationItem) => {

        let { width } = size;
        let minWidth = 20;

        let itemIndex = state.itemKeys.findIndex(i => i === item.id);
        if (width === state.itemWidthsList[itemIndex]) return;

        if (width < maxItemWidth && width > minWidth) {
            if (itemIndex < 0) {
                setState({
                    itemWidthsList: [
                        ...state.itemWidthsList,
                        width
                    ],
                    itemKeys: [
                        ...state.itemKeys,
                        item.id
                    ]
                });
            } else {
                if (width !== state.itemWidthsList[itemIndex]) {
                    setState({
                        itemWidthsList: Object.assign([], state.itemWidthsList, { [itemIndex]: width })
                    });
                }

            }
        }

    }

    const renderOverflowItem = (item: IPaginationItem) => ({
        key: item.id,
        label: item.title
    })

    const handleMenuClick = React.useCallback((e: any) => {
        let clickedItem = props.items.find((item: IPaginationItem) => item.id === e.key);
        clickedItem.onClick(e.domEvent, clickedItem);
    }, [props.items]);

    const renderOverflowItems = (position: string) => {

        let overflowItems: IPaginationItem[] = [];

        if (position === 'start') {
            overflowItems = leftDropdownItems;
        } else {
            overflowItems = rightDropdownItems;
        }

        if (!overflowItems.length) return;

        const menu = <Menu
            onClick={handleMenuClick}
            items={overflowItems.map((item: IPaginationItem) => renderOverflowItem(item))}
        />;


        return (
            <li className={[Styles.paginationItem, "paginationItem"].join(' ')} >
                <Dropdown overlay={menu} placement="bottomLeft">
                    <BCButton cssClass={[Styles.overflowBtn, "overflowBtn"].join(' ')}>
                        {'...'}
                    </BCButton>
                </Dropdown>
                <i className={Styles.arrow}><IconBreadCrumbArrow /></i>
            </li>
        )
    }

    calculateItemMembers();

    return (
        <ul className={Styles.paginationContainer}>
            {renderOverflowItems('start')}
            {renderBreadcrumbItems()}
            {renderOverflowItems('end')}
        </ul>
    )

}

export default withSize()(Pagination)