enum mobileMenuPosition {
    bottom,
    top,
    left,
    right
}

enum FlowViewerMenuItems {
    FlowProperties,
    FlowHistory,
    FlowHistoryPopover,
    FlowSchema,
    FlowDocuments,
    Document,
    FlowNote
}

enum IFlowInfoMode {
    View = 0,
    Approval = 1,
    History = 2
}

enum FlowActionType {
    Start = 0,
    Continue = 1
}

enum FlowEventType {
    Send = 4,
    Approve = 5,
    Reject = 6,
    Cancel = 7,
}

enum FlowDocumentType {
    None,
    CreateForm,
    AddExisting
}

enum DocumentSignatureOption {
    NoSign,
    Optional,
    RequiredPossible,
    Required
}

enum FlowForwardEvents {
    Forward = 9,
    SendToBack = 10
}

export { mobileMenuPosition, FlowViewerMenuItems, IFlowInfoMode, FlowActionType, FlowEventType, FlowDocumentType, DocumentSignatureOption, FlowForwardEvents }
