import { BCCombobox, BCInput, BCList, BCModal, BCNotification, findMessage, IComboboxSelectedChangeEventArgs, IInputChangeEventArgs } from '@bimser/components';
import { IItem } from "@bimser/core";
import * as React from 'react';
import * as Style from '../assets/style.scss';
import { IESignatureProps } from '../entities';
import { ISigner } from '../entities/ISigner';
import Signer from './Signer';

const ESignature = (props: IESignatureProps) => {

    const [subjectStr, setSubjectStr] = React.useState("");

    const onChangeData = (roleName: string, field: string, data: string) => {
        props.onChangeTemplateData({
            templateId: props.selectedTemplateId,
            roleName,
            field,
            data
        })
    }

    React.useEffect(() => {
        if (props.selectedTemplateId == null && props.templates.length > 0) {
            props.onChangeSelectedTemplate(props.templates[0].templateId);
        }
    }, [props.templates]);

    const onChangeSelectedTemplate = (args: IComboboxSelectedChangeEventArgs) => {
        props.onChangeSelectedTemplate(args.data.toString());
    }

    const getTemplates = () => {
        return props.templates.map(t => {
            return { key: t.templateId, value: t.templateId, text: t.templateName } as IItem
        })
    }

    const getSigners = () => {
        if (!props.selectedTemplateId) return [];
        else {
            let template = props.templates.find(t => t.templateId == props.selectedTemplateId);
            return template ? template.signers : [];
        }
    }

    const renderSignerItem = (signer: ISigner) => {
        return (
            <Signer signer={signer} onChangeData={onChangeData} />
        )
    }

    const getIsAllFieldsFilled = () => {
        let result = true;
        if (!props.selectedTemplateId) return false;
        props.templates.find(t => t.templateId == props.selectedTemplateId)?.signers.forEach(s => {
            if (s.fullName == null || s.fullName.length == 0 || s.email == null || s.email.length == 0) {
                result = false;
            }
        });

        return result;
    }

    const onChangeSubjectChange = (args: IInputChangeEventArgs) => {
        setSubjectStr(args.data);
    }

    const handleOk = () => {
        if (!getIsAllFieldsFilled()) {
            BCNotification.warning({
                message: findMessage.get("100739")
            })
        } else {
            let signers = props.templates.find(t => t.templateId == props.selectedTemplateId).signers;
            props.onClickSend({
                modalId: props.id,
                templateId: props.selectedTemplateId,
                subject: subjectStr,
                secretKey: props.secretKey,
                versionSecretKey: props.versionSecretKey,
                signers
            })
        }
    }

    const handleCancel = () => {
        props.onCancel(props.id);
    }

    return (
        <BCModal
            id={props.id}
            visible={true}
            loading={props.isLoading}
            width={"450px"}
            handleOk={handleOk}
            handleCancel={handleCancel}
            title={findMessage.get('102168')}
        >
            <div className={Style.eSignatureContainer}>
                <div className={Style.optionItem}>
                    <span className={Style.optionLabel}>{findMessage.get('102169')}</span>
                    <div className={Style.optionContent}>
                        <BCInput className={Style.subject} size="middle" value={subjectStr} onChange={onChangeSubjectChange} />
                    </div>
                </div>
                <div className={Style.optionItem}>
                    <span className={Style.optionLabel}>{findMessage.get('102170')}</span>
                    <div className={Style.optionContent}>
                        <BCCombobox showSearch={false} options={getTemplates()} onSelectedChange={onChangeSelectedTemplate} value={props.selectedTemplateId} />
                    </div>
                </div>
                <BCList
                    className={Style.signerListWrapper}
                    dataSource={getSigners()}
                    renderItem={renderSignerItem}
                    split={false}
                />
            </div>
        </BCModal>
    )
}
export default ESignature;