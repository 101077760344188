import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export enum CompanyDangerType {
    None = "0",
    Low = "1",
    Average = "2",
    High = "3"
}
export interface IBaseCompany {
    id: number,
    companyCode: string,
    description: string,
    importstatus: number,
    status: number,
    dangerCategory: CompanyDangerType,
    sgkRegistrationNumber: string
}

export interface ICompany extends IBaseCompany { }

export interface IReadonlyCompany extends IBaseCompany { }

export type Company = ReadonlyRecord<IReadonlyCompany>;

export const CompanyFactory = Record<IReadonlyCompany>({
    id: 0,
    companyCode: '',
    description: "",
    importstatus: 0,
    status: 1,
    dangerCategory: CompanyDangerType.None,
    sgkRegistrationNumber: null
});

export const CompanyFactoryFromJS = function (company: ICompany) {
    return CompanyFactory(company);
}