import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

export interface IBaseLogviewerListPanelState{
    panelId?:string,
    activePanelId?:string,
    showLoading?:boolean
}

export interface ILogviewerListPanelState extends IBaseLogviewerListPanelState{
    items: Array<any>
}

export interface IReadonlyLogviewerListPanelState extends IBaseLogviewerListPanelState {
    items: List<any>
}

export type LogviewerListPanelState = ReadonlyRecord<IReadonlyLogviewerListPanelState>;

export const LogviewerListPanelStateFactory = Record({
    items: List<any>(),
    panelId: null,
    activePanelId: "",
    showLoading: true,
});

export const LogviewerListPanelStateFactoryFromJS = function (data: ILogviewerListPanelState) {
    return LogviewerListPanelStateFactory({
        items: List(data.items),
        panelId: data.panelId,
        activePanelId: data.activePanelId,
        showLoading: data.showLoading,
    });
};
