export const ADD_FLEX_PANEL_TRANSACTION = "APP_MAIN.ADD_FLEX_PANEL_TRANSACTION";

export const ADD_FLEX_PANEL = "APP_MAIN.ADD_FLEX_PANEL";

export const ADD_FLEX_PANEL_ADD_ANIMATION_CSS = "APP_MAIN.ADD_FLEX_PANEL_ADD_ANIMATION_CSS";

export const ADD_FLEX_PANEL_REMOVE_ANIMATION_CSS = "APP_MAIN.ADD_FLEX_PANEL_REMOVE_ANIMATION_CSS";


export const HIDE_FLEX_PANEL_OUT_LEFT_TRANSACTION = "APP_MAIN.HIDE_FLEX_PANEL_OUT_LEFT_TRANSACTION";

export const HIDE_FLEX_PANEL_OUT_RIGHT_TRANSACTION = "APP_MAIN.HIDE_FLEX_PANEL_OUT_RIGHT_TRANSACTION";

export const HIDE_FLEX_PANEL_FADE_OUT_TRANSACTION = "APP_MAIN.HIDE_FLEX_PANEL_FADE_OUT_TRANSACTION";

export const HIDE_FLEX_PANEL_FADE_OUT_ADD_ANIMATION_CSS = "APP_MAIN.HIDE_FLEX_PANEL_FADE_OUT_ADD_ANIMATION_CSS";

export const HIDE_FLEX_PANEL_FADE_OUT_REMOVE_ANIMATION_CSS = "APP_MAIN.HIDE_FLEX_PANEL_FADE_OUT_REMOVE_ANIMATION_CSS";


export const HIDE_FLEX_PANEL = "APP_MAIN.HIDE_FLEX_PANEL";

export const HIDE_FLEX_PANEL_OUT_LEFT_ADD_ANIMATION_CSS = "APP_MAIN.HIDE_FLEX_PANEL_OUT_LEFT_ADD_ANIMATION_CSS";

export const HIDE_FLEX_PANEL_OUT_LEFT_REMOVE_ANIMATION_CSS = "APP_MAIN.HIDE_FLEX_PANEL_OUT_LEFT_REMOVE_ANIMATION_CSS";

export const HIDE_FLEX_PANEL_OUT_RIGHT_ADD_ANIMATION_CSS = "APP_MAIN.HIDE_FLEX_PANEL_OUT_RIGHT_ADD_ANIMATION_CSS";

export const HIDE_FLEX_PANEL_OUT_RIGHT_REMOVE_ANIMATION_CSS = "APP_MAIN.HIDE_FLEX_PANEL_OUT_RIGHT_REMOVE_ANIMATION_CSS";


export const SHOW_FLEX_PANEL_FADE_IN_TRANSACTION = "APP_MAIN.SHOW_FLEX_PANEL_FADE_IN_TRANSACTION ";

export const SHOW_FLEX_PANEL_FADE_IN_ADD_ANIMATION_CSS = "APP_MAIN.SHOW_FLEX_PANEL_FADE_IN_ADD_ANIMATION_CSS ";

export const SHOW_FLEX_PANEL_FADE_IN_REMOVE_ANIMATION_CSS = "APP_MAIN.SHOW_FLEX_PANEL_FADE_IN_REMOVE_ANIMATION_CSS ";


export const SHOW_FLEX_PANEL_IN_LEFT_TRANSACTION = "APP_MAIN.SHOW_FLEX_PANEL_IN_LEFT_TRANSACTION ";

export const SHOW_FLEX_PANEL_IN_RIGHT_TRANSACTION = "APP_MAIN.SHOW_FLEX_PANEL_IN_RIGHT_TRANSACTION ";

export const SHOW_FLEX_PANEL = "APP_MAIN.SHOW_FLEX_PANEL";

export const SHOW_FLEX_PANEL_IN_LEFT_ADD_ANIMATION_CSS = "APP_MAIN.SHOW_FLEX_PANEL_IN_LEFT_ADD_ANIMATION_CSS";

export const SHOW_FLEX_PANEL_IN_LEFT_REMOVE_ANIMATION_CSS = "APP_MAIN.SHOW_FLEX_PANEL_IN_LEFT_REMOVE_ANIMATION_CSS";

export const SHOW_FLEX_PANEL_IN_RIGHT_ADD_ANIMATION_CSS = "APP_MAIN.SHOW_FLEX_PANEL_IN_RIGHT_ADD_ANIMATION_CSS";

export const SHOW_FLEX_PANEL_IN_RIGHT_REMOVE_ANIMATION_CSS = "APP_MAIN.SHOW_FLEX_PANEL_IN_RIGHT_REMOVE_ANIMATION_CSS";


export const CLICK_BREADCRUMB_NAVIGATION_ITEM_TRANSACTION = "APP_MAIN.CLICK_BREADCRUMB_NAVIGATION_ITEM_TRANSACTION";


export const CLICK_BREADCRUMB_ITEM_TRANSACTION = "APP_MAIN.CLICK_BREADCRUMB_ITEM_TRANSACTION";

export const CLICK_BREADCRUMB_ITEM = "APP_MAIN.CLICK_BREADCRUMB_ITEM";

export const CLICK_BREADCRUMB_ITEM_ADD_ANIMATION_CSS = "APP_MAIN.CLICK_BREADCRUMB_ITEM_ADD_ANIMATION_CSS";

export const CLICK_BREADCRUMB_ITEM_REMOVE_ANIMATION_CSS = "APP_MAIN.CLICK_BREADCRUMB_ITEM_REMOVE_ANIMATION_CSS";


export const CLOSE_FLEX_PANEL_TRANSACTION = "APP_MAIN.CLOSE_FLEX_PANEL_TRANSACTION";

export const CLOSE_FLEX_PANEL_BY_ID_TRANSACTION = "APP_MAIN.CLOSE_FLEX_PANEL_BY_ID_TRANSACTION";

export const CLOSE_UNPINNED_FLEX_PANELS_TRANSACTION = "APP_MAIN.CLOSE_UNPINNED_FLEX_PANELS_TRANSACTION";

export const CLOSE_FLEX_PANEL = "APP_MAIN.CLOSE_FLEX_PANEL";

export const CLOSE_FLEX_PANEL_BY_ID = "APP_MAIN.CLOSE_FLEX_PANEL_BY_ID";

export const CLOSE_FLEX_PANEL_LIST = "APP_MAIN.CLOSE_FLEX_PANEL_LIST";

export const CLOSE_FLEX_PANEL_ADD_ANIMATION_CSS = "APP_MAIN.CLOSE_FLEX_PANEL_ADD_ANIMATION_CSS";

export const CHANGE_FLEX_PANEL_TITLE_ACTION = "APP_MAIN.CHANGE_FLEX_PANEL_TITLE_ACTION";


export const RESIZE_FLEX_PANEL_TRANSACTION = "APP_MAIN.RESIZE_FLEX_PANEL_TRANSACTION";

export const RESIZE_FLEX_PANEL = "APP_MAIN.RESIZE_FLEX_PANEL";

export const RESIZE_FLEX_PANEL_ADD_ANIMATION_CSS = "APP_MAIN.RESIZE_FLEX_PANEL_ADD_ANIMATION_CSS";

export const RESIZE_FLEX_PANEL_REMOVE_ANIMATION_CSS = "APP_MAIN.RESIZE_FLEX_PANEL_REMOVE_ANIMATION_CSS";


export const CLICK_FLEX_PANEL_TRANSACTION = "APP_MAIN.CLICK_FLEX_PANEL_TRANSACTION ";

export const CLICK_FLEX_PANEL = "APP_MAIN.CLICK_FLEX_PANEL";

export const CLICK_FLEX_PANEL_ADD_ANIMATION_CSS = "APP_MAIN.CLICK_FLEX_PANEL_ADD_ANIMATION_CSS";

export const CLICK_FLEX_PANEL_REMOVE_ANIMATION_CSS = "APP_MAIN.CLICK_FLEX_PANEL_REMOVE_ANIMATION_CSS";


export const CLICK_PIN_FLEX_PANEL_TRANSACTION = "APP_MAIN.CLICK_PIN_FLEX_PANEL_TRANSACTION";

export const CLICK_PIN_FLEX_PANEL = "APP_MAIN.CLICK_PIN_FLEX_PANEL";

export const CLICK_MAXIMIZE_FLEX_PANEL_TRANSACTION = "APP_MAIN.CLICK_MAXIMIZE_FLEX_PANEL_TRANSACTION ";

export const CLICK_MAXIMIZE_FLEX_PANEL = "APP_MAIN.CLICK_MAXIMIZE_FLEX_PANEL";

export const CLICK_MAXIMIZE_FLEX_PANEL_ADD_ANIMATION_CSS = "APP_MAIN.CLICK_MAXIMIZE_FLEX_PANEL_ADD_ANIMATION_CSS";

export const CLICK_MAXIMIZE_FLEX_PANEL_REMOVE_ANIMATION_CSS = "APP_MAIN.CLICK_MAXIMIZE_FLEX_PANEL_REMOVE_ANIMATION_CSS";


export const SET_ACTIVE_FLEX_PANEL = "APP_MAIN.SET_ACTIVE_FLEX_PANEL";

export const ANIMATION_END_PANEL = "APP_MAIN.ANIMATION_END_PANEL";

export const OPEN_HR_PANEL_TRANSACTION = "APP_MAIN_MODULE.OPEN_HR_PANEL_TRANSACTION";

export const OPEN_STARTUP_PANEL_TRANSACTION = "APP_MAIN_MODULE.OPEN_STARTUP_PANEL_TRANSACTION";

export const OPEN_MOBILE_FORM_TRANSACTION = "APP_MAIN_MODULE.OPEN_MOBILE_FORM_TRANSACTION";

export const OPEN_REDIRECT_FORM_TRANSACTION = "APP_MAIN_MODULE.OPEN_REDIRECT_FORM_TRANSACTION";

export const INITIALIZE_LEFT_MENU_FOR_MOBILE_TRANSACTION = "APP_MAIN_MODULE.INITIALIZE_LEFT_MENU_FOR_MOBILE_TRANSACTION";

export const CHANGE_FOR_MOBILE_STATE = "APP_MAIN_MODULE.CHANGE_FOR_MOBILE_STATE";

export const CHANGE_FROM_REDIRECT_STATE = "APP_MAIN_MODULE.CHANGE_FROM_REDIRECT_STATE";

export const HIDE_LEFT_AND_TOP_MENU_ACTION = "APP_MAIN_MODULE.HIDE_LEFT_AND_TOP_MENU_ACTION";

export const SET_VIEW_HIDE_INFO_ACTION = "APP_MAIN_MODULE.SET_VIEW_HIDE_INFO_ACTION";

export const REDIRECT_FOR_MOBILE = "APP_MAIN_MODULE.REDIRECT_FOR_MOBILE";

export const DEPLOY_AGENT_NEW_PROJECT_VERSION_ACTION = "DEPLOY_AGENT.DEPLOY_AGENT_NEW_PROJECT_VERSION_ACTION";
