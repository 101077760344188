import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseIWindowSizeChangedAction {
    windowId: string
}

export interface IWindowSizeChangedAction extends IBaseIWindowSizeChangedAction {
    windowSize: LayoutCreatorEntities.IWindowSize
}

export interface IReadonlyBaseIWindowSizeChangedAction extends IBaseIWindowSizeChangedAction {
    windowSize: LayoutCreatorEntities.WindowSize
}

export type WindowSizeChangedAction = ReadonlyRecord<IReadonlyBaseIWindowSizeChangedAction>;

export const WindowSizeChangedActionFactory = Record<IReadonlyBaseIWindowSizeChangedAction>({
    windowId: null,
    windowSize: LayoutCreatorEntities.WindowSizeFactory()
});

export const WindowSizeChangedActionFactoryFromJS = function (data: IWindowSizeChangedAction) {
    return WindowSizeChangedActionFactory({
        windowId: data.windowId,
        windowSize: LayoutCreatorEntities.WindowSizeFactoryFromJS(data.windowSize)
    })
}