import { createSelector,OutputSelector } from "reselect";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import {Record} from 'immutable'
import { LoginUser,IReadonlyLoginUser } from "../../../components/Login/entities/LoginUser";

const LoginUser = (state: BaseState) => state.user.data;

const getUserCultureName = createSelector(
    [
        LoginUser
    ],
    (data: LoginUser) => {
        return data  ? data.culture : "";
    }
);

export { getUserCultureName };

