import * as React from "react";
import { BCInput, BCLabel } from "../..";
import { ConditionalStatementEntities } from "../../BCConditionalStatement";
import { FieldValueType } from '../../BCConditionalStatement/entities/Enums';
import { sortFieldsByType } from '../../BCConditionalStatement/helpers';
import { findMessage } from "../../BCIntl";
import { ITreeData } from '../../BCTreeSelect/entities/ITreeSelectProps';
import { filterReadonlyFields } from '../../BCTreeSelect/helpers';
import { IActionContentProps } from "../entities/IActionContentProps";
import { SelectedField } from "./SelectedField";

const ValueAssignActionWithSelection = React.memo((props: IActionContentProps<ConditionalStatementEntities.IValueAssignActionWithSelection>) => {
    
    const onSelectedFieldChanged = (value: string, options: ITreeData) => {
        props.onActionChanged({
            ...props.action,
            selectedField: {
                name: value.split('.').shift(),
                propertyName: value.split('.').slice(1, value.length).join('.'),
                type: options.externalData.type,
                enumMembers: options.externalData.enumMembers
            }
        });

    }

    const onFieldChanged = (value: string, options: ITreeData) => {
        props.onActionChanged({
            ...props.action,
            field: {
                name: value.split('.').shift(),
                propertyName: value.split('.').slice(1, value.length).join('.'),
                type: options.externalData.type,
                enumMembers: options.externalData.enumMembers
            }
        });

    }

    const renderSelectedField = (): JSX.Element => {
        return (
            <SelectedField
                options={props.fields ? filterByField(props.fields.toJS(), 'selectedField') : []}
                field={props.action.selectedField}
                onChange={onSelectedFieldChanged}
            />
        )
    }

    const filterByField = (treeData: ITreeData[], areaIdentity: string): ITreeData[] => {
        const { typeName, field, selectedField } = props.action

        if (typeName !== 'ValueAssignActionWithSelection') return treeData;

        if (areaIdentity === 'selectedField' && field && field.type) {
            treeData = filterTreeData(treeData, props.action.field.type, true);
        }
        if (areaIdentity === 'field' && selectedField && selectedField.type) {
            treeData = filterTreeData(treeData, props.action.selectedField.type, true);
        }
        return sortFieldsByType(treeData)
    }

    const filterTreeData = (treeData: ITreeData[], type: FieldValueType, root?: boolean): ITreeData[] => {
        return treeData.filter(item => {

            if (item.children && item.children.length) {
                item.children = filterTreeData(item.children, type);
                return true;
            }

            if (root) {
                return true
            } else {
                return item.externalData && item.externalData.type === type
            }

        })
    }

    const getWriteableFields = () => {
        return props.fields ? filterReadonlyFields(props.fields.toJS()) : []
    }

    const renderField = (): JSX.Element => {
        return <SelectedField
            options={props.fields ? filterByField(getWriteableFields(), 'field') : []}
            field={props.action.field}
            onChange={onFieldChanged}
        />
    }

    return (
        <div className={'mb-2 mt-2 mr-2 ml-2'}>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100131')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <BCInput
                        value={props.action.name}
                        onChange={e => {
                            props.onActionChanged({
                                ...props.action,
                                name: e.data
                            })
                        }}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100168')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    {renderSelectedField()}
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100338')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    {renderField()}
                </div>
            </div>
        </div>
    )
})

export default ValueAssignActionWithSelection