import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";
import { OSID } from "../enums";

export interface IBasePropertyValue {
    id?: number,
    osid: OSID,
    objectId: number,
    propertyTypeId: number,
    importStatus: number,
}

export interface IPropertyValue extends IBasePropertyValue {
    value?: IDictionary<any>
}

export interface IReadonlyPropertyValue extends IBasePropertyValue {
    value?: Map<string, any>
}

export type PropertyValue = ReadonlyRecord<IReadonlyPropertyValue>;

export const PropertyValueFactory = Record<IReadonlyPropertyValue>({
    id: 0,
    osid: OSID.Null,
    objectId: 0,
    propertyTypeId: 0,
    importStatus: 0,
    value: Map<string, any>()
});

export const PropertyValueFactoryFromJS = function (propertyValue: IPropertyValue) {
    let _value: IDictionary<any> = propertyValue.value ? Object.keys(propertyValue.value).reduce(function (previous: IDictionary<any>, current) {
        previous[current] = propertyValue.value[current];
        return previous;
    }, {}) : {};

    return PropertyValueFactory({
        id: propertyValue.id,
        importStatus: propertyValue.importStatus,
        objectId: propertyValue.objectId,
        osid: propertyValue.osid,
        propertyTypeId: propertyValue.propertyTypeId,
        value: Map(_value)
    });
}
