import { BCDivider } from "@bimser/components";
import React from "react";
import Style from "../../assets/style.scss";
import CloseIcon from "@bimser/icons/16/close-window";
import IDrawerFormHeaderProps from "./entities/IDrawerFormHeaderProps";

export default (props: IDrawerFormHeaderProps) => (
    <div className={Style.drawerFormHeader}>
        <div className={Style.drawerFormTitle}>{props.title}</div>
        <div className={Style.drawerHeaderButtons}>
            {props.saveButton}
            <BCDivider className={Style.drawerHeaderDivider} type="vertical" />
            <CloseIcon className={Style.closePanel} onClick={props.onClose}/>
        </div>
    </div>
)
