import { isNullOrUndefined } from '@bimser/core';
import { Col, Row } from 'antd';
import IconConfirmError from "@bimser/icons/16/confirm";
import * as React from "react";
import { BCForm, BCPopover, BCScrollbars, BCTreeview, findMessage, ITabsItem, ITreeviewItem } from '..';
import BCTabs from '../BCTabs';
import Style from "./assets/style.scss";
import IBCErrorViewProps from './entities/IBCErrorViewProps';

const classNames = require('classnames/bind');
const cx = classNames.bind(Style);

export default (props: IBCErrorViewProps) => {
    const tabItems: ITabsItem[] = [
        {
            key: "treeView",
            label: findMessage.get("100409"),
            selected: false,
            icon: null,
            hasScrollbarContent: true
        },
        {
            key: "codeView",
            label: findMessage.get("102299"),
            selected: false,
            icon: null,
            hasScrollbarContent: true
        }
    ]

    const mapTreeNodes = (exception: any, key: number) : ITreeviewItem<any>[] => {
        if (!isNullOrUndefined(exception)) {
            let childException: any[] = [];
            if (!isNullOrUndefined(exception.InnerException) && exception.InnerException !== findMessage.get("100526")) {
                childException = mapTreeNodes(exception.InnerException, key + 1)
            }
            else if (!isNullOrUndefined(exception.ExternalException) && exception.ExternalException !== findMessage.get("100526")) {
                let externalException = typeof exception.ExternalException === "object" ? exception.ExternalException : JSON.parse(exception.ExternalException);
                childException = mapTreeNodes(externalException, key + 1)
            }

            return [
                {
                    dataRef: exception,
                    key: key.toString(),
                    text: (exception.Message || exception.message),
                    children: childException,
                    icon: null
                }
            ]
        } else {
            return null;
        }
    }

    const getExpandedKeys = (node: ITreeviewItem<any>[], expandedKeys: string[]) : string[] => {
        if (!isNullOrUndefined(node?.[0])) {
            return getExpandedKeys(node[0].children, [...expandedKeys, node[0].key]);
        }
        else {
            return expandedKeys;
        }
    }

    const codeViewContent = (key?: string) => {
        let exceptionData = key ? getExceptionData(props.error.exception, key) : getExceptionData(props.error.exception, undefined, undefined, true);

        if (!isNullOrUndefined(exceptionData)) {
            if (typeof exceptionData !== 'object') {
                exceptionData = JSON.parse(exceptionData);
            }
            let StackTraceString: string = exceptionData.StackTraceString || exceptionData.stackTrace || exceptionData.StackTrace || "";
            let stackTraceContent = StackTraceString.split(/(at )|(in )/g).filter((i: string) => i?.trim());
            let _error = getValueOrNone(exceptionData);

            return (
                <BCScrollbars autoHide={true}>
                    <BCForm
                        layout={"horizontal"}
                        className={Style.codeViewContent}
                    >
                        <Row gutter={[8, 0]}>
                            {getPopoverFields("Code:", _error.Code)}
                            {getPopoverFields("FootPrint:", props.error?.exception?.FootPrint || findMessage.get("100526"))}
                            {getPopoverFields("ClassName:", _error.ClassName)}
                            {getPopoverFields("Source:", _error.Source)}
                            {getPopoverFields("StackTrace:")}
                            {!isNullOrUndefined(stackTraceContent) && getStackTraceContent(stackTraceContent)}
                        </Row>
                    </BCForm>
                </BCScrollbars>
            );
        }
    }

    const getValueOrNone = (value: any) => {
        let expectedValues: string[] = ["Code", "FootPrint", "ClassName", "Source"];

        Object.keys(value).forEach(i => {
            expectedValues.forEach((t: string) => { value[t] = value[t] ?? findMessage.get("100526") });

            if (i !== "InnerException" && isNullOrUndefined(value[i]) || (Array.isArray(value[i]) && value[i].length === 0) || value[i] === "") {
                value[i] = findMessage.get("100526");
            }
            else if (!isNullOrUndefined(value[i]) && typeof value[i] === 'object' && !Array.isArray(value[i])) {
                value[i] = getValueOrNone(value[i]);
            }
        });

        return value
    }

    const getPopoverFields = (label: string, content?: string) => {
        return (
            <React.Fragment>
                <Col span={4}>
                    <span className={Style.popoverLabelTitle}>{label}</span>
                </Col>
                <Col span={20}>
                    {content && <span className={Style.popoverLabelContent}>{content}</span>}
                </Col>
            </React.Fragment>)
    }

    const getStackTraceContent = (stackTraceContent: any) => {
        return stackTraceContent.map((value: any, index: number) => {
            let isTitleOrNot = ['at ', 'in '].includes(value);

            return (
                <React.Fragment>
                    <Col span={1}></Col>
                    <Col span={2}>
                        {isTitleOrNot && <span className={Style.popoverLabelTitle}>{value}</span>}
                    </Col>
                    <Col span={21}>
                        {!isTitleOrNot && <span className={cx({ popoverLabelContent: true, stackTraceContent: true, inOrAtContentColor: isTitleOrNot && stackTraceContent[index - 1] === 'in' })} >{value}</span>}
                    </Col>
                </React.Fragment>
            )
        })
    }

    const getExceptionData = (exception: any, controlKey?: string, exceptionCounter: number = 0, getLastException: boolean = false) => {
        if (exception) {
            if (getLastException && isNullOrUndefined(controlKey) && !isNullOrUndefined(exception.InnerException)) {
                return getExceptionData(exception.InnerException, undefined, undefined, true);
            }
            else if (getLastException && isNullOrUndefined(controlKey) && !isNullOrUndefined(exception.ExternalException) && exception.ExternalException !== findMessage.get("100526")) {
                if (typeof exception.ExternalException !== 'object') {
                    return getExceptionData(JSON.parse(exception.ExternalException), undefined, undefined, true);
                }
                else {
                    return getExceptionData(exception.ExternalException, undefined, undefined, true);
                }
            }
            else if (getLastException && isNullOrUndefined(controlKey) && isNullOrUndefined(exception.InnerException) && (isNullOrUndefined(exception.ExternalException) || exception.ExternalException === findMessage.get("100526"))) {
                return exception;
            }
            else if (controlKey === exceptionCounter.toString()) {
                return exception;
            }
            else if (!isNullOrUndefined(exception.InnerException)) {
                return getExceptionData(exception.InnerException, controlKey, exceptionCounter + 1);
            }
            else if (!isNullOrUndefined(exception.ExternalException) && exception.ExternalException !== findMessage.get("100526")) {
                if (typeof exception.ExternalException !== 'object') {
                    return getExceptionData(JSON.parse(exception.ExternalException), controlKey, exceptionCounter + 1);
                }
                else {
                    return getExceptionData(exception.ExternalException, controlKey, exceptionCounter + 1);
                }
            }
        }
    }

    

    const customItemComponent = (item: ITreeviewItem<any>) : JSX.Element => {
        return (
            <div className={Style.treeTextContainer}>
                <span title={item.text} className={Style.treeItemText}>{item.text}</span>
                <BCPopover
                    overlayClassName={Style.errorDetailPopover}
                    className={Style.codeViewPopover}
                    trigger={'hover'}
                    title={<div className={Style.popoverTitle}>Info</div>}
                    content={
                        <div className={Style.popoverContent}>
                            {codeViewContent(item.key)}
                        </div>
                    }
                >
                    <div>
                        <IconConfirmError />
                    </div>
                </BCPopover>
            </div>
        )
    }

    const treeContent = (tabItem: ITabsItem) : JSX.Element => {
        let treeNodes: ITreeviewItem<any>[] = mapTreeNodes(props.error.exception, 0);
        let expandedKeys: string[] = getExpandedKeys(treeNodes, []);
        let selectedKey: string = expandedKeys.slice(-1).pop();
        
        if (tabItem.key === "treeView") {
            return (
                <div className={Style.errorTree}>
                    <BCTreeview
                        className={Style.popoverTree}
                        showLine={false}
                        disableToolbar={true}
                        searchable={false}
                        hasSrollbar={true}
                        defaultExpandAll={true}
                        selectedKeys={[selectedKey]}
                        customItemComponent={customItemComponent}
                        expandedKeys={expandedKeys}
                        items={treeNodes}
                        scrollbarCssClass={Style.treeScrollbar}
                    />
                </div>
            )
        }
        else {
            return (
                <div className={Style.codeViewContainer}>
                    {codeViewContent()}
                </div>
            )
        }
    }
    
    return (
        <div className={Style.errorDetailTabs}>
            <BCTabs
                items={tabItems}
                tabPosition={'top'}
                renderTabContent={treeContent}
            />
        </div>
    )
}

