import { IItem } from "@bimser/core";
import { GetIconComponent } from "@bimser/icons";
import IconDelete from "@bimser/icons/16/delete";
import IconMore from "@bimser/icons/16/more";
import classNames from 'classnames/bind';
import * as React from "react";
import { BCDropdownWrapper, findMessage } from "../";
import * as Styles from "./assets/listEditorStyle.scss";
import { IListEditorItem, IListEditorItemProps } from "./entities";

const cx = classNames.bind(Styles);
export default class ListEditorItem extends React.Component<IListEditorItemProps, any>{
    constructor(props: IListEditorItemProps) {
        super(props);
        this.getItem = this.getItem.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.renderText = this.renderText.bind(this);
        this.onSelectDelete = this.onSelectDelete.bind(this);
        this.renderTypeItem = this.renderTypeItem.bind(this);
        this.renderSelectItem = this.renderSelectItem.bind(this);
    }
    getItem(): IListEditorItem {
        return {
            icon: this.props.icon,
            id: this.props.id,
            selected: this.props.selected,
            showDelete: this.props.showDelete,
            subText: this.props.subText,
            text: this.props.text,
            externalData: this.props.externalData,
            subTextPrefix: this.props.subTextPrefix
        };
    }
    onSelect() {
        if (this.props.onSelect) {
            this.props.onSelect(this.getItem());
        }
    }
    onDelete() {
        if (this.props.onDelete && this.props.showDelete) {
            this.props.onDelete(this.getItem());
        }
    }
    onSelectDelete() {
        if (this.props.onSelectDelete && this.props.showSelectDelete) {
            this.props.onSelectDelete(this.getItem());
        }
    }
    renderSubText() {
        if (this.props.subText) {
            if (this.props.subTextPrefix) {
                return (
                    <div>
                        <span className={Styles.serverText}>{this.props.subTextPrefix}</span>
                        <p>{this.props.subText}</p>
                    </div>);
            }
            else {
                return <div><p>{this.props.subText}</p></div>;
            }
        }
    }
    renderTypeItem(item: any) {
        return (
            <div className={Styles.removeTypeItem} onClick={this.onDelete}>
                <IconDelete />
                <span>{item.text}</span>
            </div>
        )
    }
    renderSelectItem(item: any) {
        return (
            <div className={Styles.removeTypeItem} onClick={this.onSelectDelete}>
                <IconDelete />
                <span>{item.text}</span>
            </div>
        )
    }
    renderDelete() {
        if (!this.props.showDelete && !this.props.showSelectDelete) return <></>

        const typeMenuProps: { items: IItem[] } = { items: [] };

        if (this.props.showDelete) {
            typeMenuProps.items.push({
                key: "delete",
                text: findMessage.get("100013"),
                icon: IconDelete.info,
                customTemplate: this.renderTypeItem
            })
        }

        if (this.props.showSelectDelete) {
            typeMenuProps.items.push({
                key: "selectDelete",
                text: findMessage.get("102949"),
                icon: IconDelete.info,
                customTemplate: this.renderSelectItem
            })
        }

        return (
            <BCDropdownWrapper
                trigger={['click']}
                stopPropagation={true}
                menuProps={typeMenuProps}
            >
                <div className={Styles.moreIcon}>
                    <IconMore className={Styles.removeButton} />
                </div>
            </BCDropdownWrapper>
        )
    }
    renderIcon() {
        if (this.props.iconType === "icon") {
            return GetIconComponent(this.props.icon);
        } else {
            return <div className={Styles.custom} style={{ background: this.props.text }} />
        }
    }
    renderText() {
        if (this.props.renderCustomListItemText)
            return this.props.renderCustomListItemText(this.getItem())
        return <h3>{this.props.text}</h3>
    }
    render() {
        let classNames = cx({
            item: true,
            selected: this.props.selected,
        });

        return (<div id={this.props.id} onClick={this.onSelect} className={classNames} >
            <div className={Styles.icon}>
                {this.renderIcon()}
            </div>
            <div className={Styles.listItemContent}>
                {this.renderText()}
                {this.renderSubText()}
            </div>
            {this.renderDelete()}
        </div>)
    }
}