import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { GeneralContent, GeneralFactory, GeneralFactoryFromJS, IGeneralContent } from "./IGeneral";
import { IndexSettingsContentFactoryFromJS, IndexSettingsContent, IndexSettingsFactory, IIndexSettingsContent } from "./IIndexSettings";
import { MetaDataContentFactoryFromJS, MetaDataContent, MetaDataFactory, IMetaDataContent } from "./IMetaData";
import { NewFileContentFactoryFromJS, NewFileFactory, NewFileContent, INewFileContent } from './INewFile';

export interface IBaseProperties {
}

export interface IPropertiesContent extends IBaseProperties {
    indexSettings?: IIndexSettingsContent,
    metaData?: IMetaDataContent,
    newFile?: INewFileContent,
    general?: IGeneralContent
}

export interface IReadonlyProperties extends IBaseProperties {
    indexSettings: IndexSettingsContent,
    metaData: MetaDataContent,
    newFile: NewFileContent,
    general: GeneralContent
}

export type PropertiesContent = ReadonlyRecord<IReadonlyProperties>;

export const PropertiesFactory = Record<IReadonlyProperties>({
    indexSettings: IndexSettingsFactory(),
    metaData: MetaDataFactory(),
    newFile: NewFileFactory(),
    general: GeneralFactory()
});

export const PropertiesContentFactoryFromJS = function (data: IPropertiesContent) {

    return PropertiesFactory({
        indexSettings: IndexSettingsContentFactoryFromJS(data.indexSettings),
        metaData: MetaDataContentFactoryFromJS(data.metaData),
        newFile: NewFileContentFactoryFromJS(data.newFile),
        general: GeneralFactoryFromJS(data.general)
    });
}
