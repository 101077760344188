import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IWhyContent, WhyContent, WhyContentFactoryFromJS } from "./IWhy";

interface IBaseDmObjectProperty {
    value: number | string | boolean | Array<any>,
    inherit: boolean
}

export interface IDmObjectProperty extends IBaseDmObjectProperty {
    why: IWhyContent,
}

export interface IReadonlyDmObjectProperty extends IBaseDmObjectProperty {
    why: WhyContent,
}

export type DmObjectProperty = ReadonlyRecord<IReadonlyDmObjectProperty>;

export const DmObjectPropertyFactory = Record<IReadonlyDmObjectProperty>({
    value: "",
    why: null,
    inherit: false
});

export const DmObjectPropertyFactoryFromJS = function (data: IDmObjectProperty) {
    if (!data) return DmObjectPropertyFactory();
    return DmObjectPropertyFactory({
        ...data,
        why: WhyContentFactoryFromJS(data.why)
    });
}