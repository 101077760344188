import { ILayoutDataItem, INormalizedLayoutItems, INormalizedLayoutStructureItems, LayoutDataItem, LayoutDataItemFactoryFromJS, NormalizedLayoutItems, NormalizedLayoutItemsFactory, NormalizedLayoutItemsFactoryFromJS, NormalizedLayoutStructureItems, NormalizedLayoutStructureItemsFactory, NormalizedLayoutStructureItemsFactoryFromJS } from './';
import { Record, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from '@bimser/core';
import { ConditionalStatementEntities } from '../../../BCConditionalStatement';

export interface IBaseIFormItemsLoadAction {
    formId: string,
    documentId?: number,
    lastBatchGuid?: string,
    isFirstLoad?: boolean,
    clientUrl?: string,
    endTransactionGuid?: string,
    runSecretKey?: string
}

export interface IFormItemsLoadAction extends IBaseIFormItemsLoadAction {
    items: INormalizedLayoutItems,
    rules: IDictionary<ConditionalStatementEntities.IRule>,
    structureItems: INormalizedLayoutStructureItems,
    dataItems: IDictionary<ILayoutDataItem>
}

export interface IReadonlyBaseIFormItemsLoadAction extends IBaseIFormItemsLoadAction {
    items: NormalizedLayoutItems,
    rules: Map<string, ConditionalStatementEntities.Rule>,
    structureItems: NormalizedLayoutStructureItems,
    dataItems: Map<string, LayoutDataItem>
}

export type FormItemsLoadAction = ReadonlyRecord<IReadonlyBaseIFormItemsLoadAction>;

export const FormItemsLoadActionFactory = Record<IReadonlyBaseIFormItemsLoadAction>({
    items: NormalizedLayoutItemsFactory(),
    formId: null,
    documentId: 0,
    lastBatchGuid: null,
    isFirstLoad: false,
    rules: Map(),
    structureItems: NormalizedLayoutStructureItemsFactory(),
    dataItems: Map(),
    clientUrl: null,
    endTransactionGuid: null,
    runSecretKey: null
});

export const FormItemsLoadActionFactoryFromJS = function (data: IFormItemsLoadAction) {

    let rules: IDictionary<ConditionalStatementEntities.Rule> = {};

    for (let key in data.rules) {
        let item = data.rules[key];
        rules[key] = ConditionalStatementEntities.RuleFactoryFromJS(item);
    }

    let dataItems: IDictionary<LayoutDataItem> = {};

    for (let key in data.dataItems) {
        let item = data.dataItems[key];
        dataItems[key] = LayoutDataItemFactoryFromJS(item);
    }

    return FormItemsLoadActionFactory({
        items: NormalizedLayoutItemsFactoryFromJS(data.items),
        dataItems: Map(dataItems),
        structureItems: NormalizedLayoutStructureItemsFactoryFromJS(data.structureItems),
        rules: Map(rules),
        formId: data.formId,
        documentId: data.documentId,
        lastBatchGuid: data.lastBatchGuid,
        isFirstLoad: data.isFirstLoad,
        clientUrl: data.clientUrl,
        endTransactionGuid: data.endTransactionGuid,
        runSecretKey: data.runSecretKey
    })
}