import { BCFileExplorer2, BCLoading, IFileExplorerItemProps } from "@bimser/components";
import { getIconInfoByExtension } from "@bimser/core";
import { IconInfo } from '@bimser/icons';
import IconAssets from "@bimser/icons/32/assets";
import IconClient from "@bimser/icons/32/client";
import IconFlowProject from "@bimser/icons/32/flow-project";
import IconFolder from "@bimser/icons/32/folder2";
import IconFormProject from "@bimser/icons/32/form-project";
import IconReferences from "@bimser/icons/32/references";
import IconServer from "@bimser/icons/32/server";
import _sortBy from 'lodash/sortBy';
import * as React from 'react';
import { useEffect, useLayoutEffect, useState } from "react";
import { DMExplorerProps, ExplorerItemType, Extensions, IFilter, ILoadPayload, INavigationHistoryItem, INavigationItem, IPagination, LoadPayload, LoadPayloadFactoryFromJS, NavigationHistoryItem } from "../entities";

const DMExplorer = (props: DMExplorerProps) => {
    const [searchText, setSearchText] = useState("");
    const [items, setItems] = useState<IFileExplorerItemProps[]>([]);

    useLayoutEffect(() => {
        if (props.propsFilter.name != props.currentFilter.name || props.propsFilter.extension != props.currentFilter.extension) {
            props.Load(createLoadPayload(props.currentItem, props.propsFilter, props.propsPagination, -1, true));
        }
    }, [props.propsFilter]);

    useLayoutEffect(() => {
        setItems(getFilteredItems);
    }, [props.propsPagination]);

    useEffect(() => {
        return () => {
            if (props.clearData)
                props.clearData();
        }
    }, []);

    const getFilteredItems = React.useMemo((): IFileExplorerItemProps[] => {
        let _items: INavigationItem[] = [];
        let skip = (props.propsPagination.skip - 1) * props.propsPagination.take;

        _items = props.items.filter(item => {
            if (props.currentFilter?.extension) {
                if (props.currentFilter.extension == "" || props.currentFilter.extension == "folder_only") return item.type == ExplorerItemType.Folder;
                else return item.type == ExplorerItemType.Folder || (item.type != ExplorerItemType.Folder && item.extension == props.currentFilter.extension);
            }
            return true;
        });

        _items = _items.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())).slice(skip, skip + props.propsPagination.take);
        _items = _sortBy(_items, ["type", "name"]);

        return _items.map((item) => {
            return {
                id: item.id,
                isRenameMod: false,
                selected: (props.selectedItem && props.selectedItem.id === item.id),
                type: item.type == ExplorerItemType.Folder ? ExplorerItemType.Folder.toString() : getItemType(item.extension).toString(),
                text: getFileNameWithExtenstion(item.name, item.extension),
                icon: getIcon(item),
                secretKey: item.secretKey,
                path: item.path,
                fullPath: item.fullPath,
                extension: item.extension
            };
        });
    }, [props.currentFilter?.extension, props.items, props.selectedItem, props.propsFilter, props.propsPagination, searchText]);

    useEffect(() => {
        if (props.events.onUpdatePaginationPageCount && props.pageTotal > -1) {
            props.events.onUpdatePaginationPageCount(getItemsCount(), { ...props.propsPagination }, { ...props.currentFilter });
        }

        if (props.items) {
            setItems(getFilteredItems);
        } else {
            setItems([]);
        }
    }, [props.items]);

    useEffect(() => {
        if (props.events.onUpdatePaginationPageCount && props.propsPagination.skip > 1 && props.isOpened) {
            props.events.onUpdatePaginationPageCount(getItemsCount(), { ...props.currentPagination }, { ...props.currentFilter });
        }

        if (props.isOpened) {
            clearSearchText();
            props.Opened(false);
        }
    }, [props.isOpened]);

    useEffect(() => {
        if (props.events.onUpdatePaginationPageCount) {
            props.events.onUpdatePaginationPageCount(getItemsCount(), { ...props.currentPagination }, { ...props.currentFilter });
        }

        setItems(getFilteredItems);
    }, [searchText]);

    function getIcon(item: INavigationItem): IconInfo {
        if (item.extension === "" && item.type == ExplorerItemType.Folder) {
            if (item.name == "asset") {
                return IconAssets.info;
            }
            else if (item.name == "references") {
                return IconReferences.info;
            }
            else if (item.name == "Server") {
                return IconServer.info;
            }
            else if (item.name == "Client") {
                return IconClient.info;
            }
            else if (item.name == "Forms") {
                return IconFormProject.info;
            }
            else if (item.name == "Flows") {
                return IconFlowProject.info;
            }

            return IconFolder.info;
        } else {
            return getIconInfoByExtension(item.extension);
        }
    }

    function getItemType(extension: string): ExplorerItemType {
        switch (extension) {
            case Extensions.PROJECT_EXTENSION:
                return ExplorerItemType.Project;
            case Extensions.SOLUTION_EXTENSION:
                return ExplorerItemType.Solution;
            case Extensions.FOLDER_EXTENSION_1:
                return ExplorerItemType.Folder;
            case Extensions.FOLDER_EXTENSION_2:
                return ExplorerItemType.Folder;
            default:
                return ExplorerItemType.File;
        }
    }

    function getFileNameWithExtenstion(fileName: string, extension: string) {
        switch (extension) {
            case Extensions.SOLUTION_EXTENSION:
                return fileName + '.' + extension;
            case Extensions.PROJECT_EXTENSION:
                return fileName + '.' + extension;
            case '':
                return fileName;
            case Extensions.FOLDER_EXTENSION_2:
                return fileName;
            default:
                return fileName + '.' + extension;
        }
    }

    function createLoadPayload(data: INavigationHistoryItem, filter: IFilter, pagination: IPagination, pageTotal: number, isReload?: boolean): LoadPayload {
        let payload: ILoadPayload = { data, isReload: isReload ? isReload : false, filter: filter || { extension: null, name: "" }, pagination, pageTotal: pageTotal };
        return LoadPayloadFactoryFromJS(payload);
    }

    function onChangeSelection(args: any) {
        // propsdan dışarı verilecek
        let cancelArgs = { cancelled: false };
        if (props.events?.onSelectionChange) {
            props.events.onSelectionChange(args, cancelArgs);
        }

        if (args && cancelArgs.cancelled === false) {
            props.setSelection(args)
        } else {
            props.setSelection(null)
        }
    }

    function onOpenItem(args: any) {
        // dosya açıldıysa propsdan dışarı verilecek. harici durumlarda load ile klasör açılacak.
        if (args.type == ExplorerItemType.Folder.toString()) {
            props.Load(createLoadPayload({ id: args.id, secretKey: args.secretKey, name: args.text }, props.currentFilter, { skip: 1, take: props.currentPagination.take }, -1));

            if (args.id != '#Root')
                onChangeSelection(args);
            else
                onChangeSelection(null);
        } else {
            if (props.events && props.events.onOpenItem) {
                props.events.onOpenItem(args);
            }
        }
    }

    function onReload() {
        props.Load(createLoadPayload(props.currentItem, props.currentFilter, props.currentPagination, -1, true));
    }

    function onSearch(value: string) {
        setSearchText(value || "");
    }

    function onPathClick(data: NavigationHistoryItem) {
        props.onPathClick(data);
    }

    function onClickup() {
        if (props.canGoBack)
            props.goUp();
    }

    function clearSearchText() {
        setSearchText("");
    }

    function onClickRootPath() {
        props.Load(createLoadPayload({ id: '#Root', secretKey: null, name: "" }, props.currentFilter, { skip: 1, take: props.currentPagination.take }, -1));
    }

    function getItemsCount() {
        const searchItems = props.items.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        return searchItems.length;
    }

    return (
        <BCLoading delay={300} show={props.isLoading}>
            <BCFileExplorer2
                useScrollbar={props.useScrollbar}
                items={items}
                historyItems={props.historyItems}
                path={props.path}
                onChangeSelection={onChangeSelection}
                onOpenItem={onOpenItem}
                onReload={onReload}
                onSearch={onSearch}
                searchText={searchText}
                canGoUp={props.canGoBack}
                onClickUp={onClickup}
                onPathClick={onPathClick}
                onClickRootPath={onClickRootPath}
            />
        </BCLoading>
    )
}

export default DMExplorer
