//
// Summary:
//     Specifies constants defining which buttons to display on a System.Windows.Forms.MessageBox.
export enum MessageBoxButtons {
    //
    // Summary:
    //     The message box contains an OK button. 
    //OK => resolve
    OK = 0,
    //
    // Summary:
    //     The message box contains OK and Cancel buttons. 
    // OK => resolve
    // Cancel => reject
    OKCancel = 1,
    //
    // Summary:
    //     The message box contains Abort, Retry, and Ignore buttons.
    // Abort => reject
    // Retry => resolve
    // Ignore => reject
    AbortRetryIgnore = 2,
    //
    // Summary:
    //     The message box contains Yes, No, and Cancel buttons.
    // Yes => resolve
    // No => reject
    // Cancel => reject
    YesNoCancel = 3,
    //
    // Summary:
    //     The message box contains Yes and No buttons.
    // Yes => resolve
    // No => reject
    YesNo = 4,
    //
    // Summary:
    //     The message box contains Retry and Cancel buttons.
    // Retry => resolve
    // Cancel => reject
    RetryCancel = 5,
    //
    // Summary:
    //     The message box contains Clear and Cancel buttons.
    // Clear=> resolve
    // Cancel => reject
    ClearCancel = 6,

}