import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseProfileFormData {
    fId: string;
    formId: string;
    formViewerId: string;
    isModified: boolean;
    isComplete: boolean;
    isRequired: boolean;
    hasValidation: boolean;
    documentId: number;
    formData: any;
    isFolder: boolean;
}

export interface IProfileFormData extends IBaseProfileFormData {

}

export interface IReadonlyProfileFormData extends IBaseProfileFormData {

}

export type ProfileFormData = ReadonlyRecord<IReadonlyProfileFormData>;

export const ProfileFormDataFactory = Record<IReadonlyProfileFormData>({
    fId: null,
    formId: null,
    formViewerId: null,
    isModified: false,
    isComplete: false,
    isRequired: false,
    hasValidation: false,
    documentId: 0,
    formData: null,
    isFolder: false
});

export const ProfileFormDataFactoryFromJS = function (data: IProfileFormData) {
    return ProfileFormDataFactory(data);
}