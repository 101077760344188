import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IManagerKey, ManagerKey, ManagerKeyFactory, ManagerKeyFactoryFromJS } from "..";

export interface IManagerKeyListFormContent extends IBaseFormContent {
    selectedManagerKey?: IManagerKey,
}

export interface IReadonlyManagerKeyListFormContent extends IBaseFormContent {
    selectedManagerKey?: ManagerKey
}

export type ManagerKeyListFormContent = ReadonlyRecord<IReadonlyManagerKeyListFormContent>;

export const ManagerKeyListFormContentFactory = Record<IReadonlyManagerKeyListFormContent>({
    panelId: "",
    selectedManagerKey: ManagerKeyFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: true,
});

export const ManagerKeyListFormContentFactoryFromJS = function (data: IManagerKeyListFormContent) {
    return ManagerKeyListFormContentFactory({
        panelId: data.panelId,
        selectedManagerKey: ManagerKeyFactoryFromJS(data.selectedManagerKey),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
    });
}