import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseOpenProcessPayload {
    clientUrl: string,
    formName: string,
    projectPath: string
}

export interface IOpenProcessPayload extends IBaseOpenProcessPayload {
}

export interface IReadonlyOpenProcessPayload extends IBaseOpenProcessPayload {
}

export type OpenProcessPayload = ReadonlyRecord<IReadonlyOpenProcessPayload>;

export const OpenProcessPayloadFactory = Record<IReadonlyOpenProcessPayload>({
    clientUrl: null,
    formName: null,
    projectPath: null
});

export const OpenProcessPayloadFactoryFromJS = function (data: IOpenProcessPayload) {
    return OpenProcessPayloadFactory(data);
}