import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

interface IBaseSearchFormMenuResult {
    documentId: string;
    formName: string;
    keyId: string;
    projectName: string;
}

export interface ISearchFormMenuResult extends IBaseSearchFormMenuResult {
    fields: Array<string>;
}

export interface IReadOnlySearchFormMenuResult extends IBaseSearchFormMenuResult {
    fields: List<string>;
}

export type SearchFormMenuResult = ReadonlyRecord<IReadOnlySearchFormMenuResult>;

export const SearchFormMenuResultFactory = Record({
    documentId: "",
    formName: "",
    keyId: "",
    projectName: "",
    fields: List<string>(),
});

export const SearchFormMenuResultFactoryFromJS = (data: ISearchFormMenuResult) => {
    return SearchFormMenuResultFactory({
        ...data,
        fields: List(data.fields),
    })
}