import { BaseRequest } from "../../../../entities/BaseRequest";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export enum MFAType {
    sms = 1,
    email = 2,
    authenticator = 3
}

export interface ISelectMFATypeRequest extends BaseRequest {
    mfaType: MFAType,
    tempTokenKey?: string,
    language: string,
}


export interface IReadonlySelectMFATypeRequest extends BaseRequest {
    mfaType: MFAType,
    tempTokenKey?: string,
    language: string,
}

export type SelectMFATypeRequest = ReadonlyRecord<IReadonlySelectMFATypeRequest>;

export const SelectMFATypeRequestFactory = Record<IReadonlySelectMFATypeRequest>({
    mfaType: null,
    tempTokenKey: null,
    language: null,
});

export const SelectMFATypeRequestFactoryFromJS = function (data: ISelectMFATypeRequest) {
    return SelectMFATypeRequestFactory({
        mfaType: data.mfaType,
        tempTokenKey: data.tempTokenKey,
        language: data.language,
    });
};
