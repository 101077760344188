import * as React from "react";
import * as Styles from "../assets/deviceViewerStyles.scss";
import { IDeviceViewerProps, ISize, EDeviceTypes } from "../entities";
import DeviceContent from "./DeviceContent";
import DeviceTypeSelector from "./DeviceTypeSelector";
import DeviceViewerToolbar from "./DeviceViewerToolbar";
import * as ReactDOM from 'react-dom';

export default (props: React.PropsWithChildren<IDeviceViewerProps>) => {
    const domContainer: HTMLElement = document.body.getElementsByTagName('main')[0];

    const [selectedDevice, setSelectedDevice] = React.useState<EDeviceTypes>(EDeviceTypes.Responsive);
    const [selectedZoomValue, setSelectedZoomValue] = React.useState<number>(1);

    const dimensions : ISize = React.useMemo(() => ({ width: props.width, height: props.height }), [props.width, props.height]);

    React.useEffect(() => {
        props.afterOpen?.();
        onDeviceSizeChanged(dimensions, EDeviceTypes.Responsive);

        return () => {
            props.afterClose?.();
        }
    }, []);

    
    const getZoomStyles = () => ({ transform: `scale(${selectedZoomValue}, 1)` });

    const calculateZoom = React.useCallback((dimension: ISize) => {
        const wHeight = window.innerHeight;
        const wWidth = window.innerWidth;
        const horizontalSpace = 70;
        const verticalSpace = 130;

        const avaiableHeight = wHeight - verticalSpace;
        const avaiableWidth = wWidth - horizontalSpace;

        let verticalPercentage = 100;
        let horizontalPercentage = 100;

        if (dimension.height > avaiableHeight) {
            verticalPercentage = verticalPercentage / (dimension.height / avaiableHeight);
        }

        if (dimension.width > avaiableWidth) {
            horizontalPercentage = horizontalPercentage / (dimension.width / avaiableWidth);
        }

        return Math.round(Math.min(verticalPercentage, horizontalPercentage)) / 100;
    }, []);

    const onDeviceSizeChanged = (dimension: ISize, sDevice?: EDeviceTypes) => {
        dimension.width = dimension.width || dimensions.width;
        dimension.height = dimension.height || dimensions.height;

        props.onDimensionChanged?.(dimension);
    
        if(sDevice) setSelectedDevice(sDevice);
        setSelectedZoomValue(calculateZoom(dimension));
    };

    const deviceViewer = () => (
        <div className={Styles.deviceViewer}>
            <DeviceViewerToolbar
                onDimensionChanged={onDeviceSizeChanged}
                dimension={{ width: props.width, height: props.height }}
                selectedDevice={selectedDevice}
                onClose={props.onClose}
                onZoomValueChanged={setSelectedZoomValue}
                selectedZoomValue={selectedZoomValue}
            />
            <DeviceTypeSelector
                onDimensionChanged={onDeviceSizeChanged}
                zoomStyle={getZoomStyles()}
            />
            <DeviceContent
                onDimensionChanged={onDeviceSizeChanged}
                dimension={{ width: props.width, height: props.height }}
                selectedDevice={selectedDevice}
                zoomStyle={getZoomStyles()}
            >
                {props.children}
            </DeviceContent>
        </div>
    )

    return ReactDOM.createPortal(deviceViewer(), domContainer);
}