import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IUserMaintenanceListFormContent extends IBaseFormContent {
    selectedReplacement?: IReplacement
}

export interface IReadonlyUserMaintenanceListFormContent extends IBaseFormContent {
    selectedReplacement: Replacement
}

export type UserMaintenanceListFormContent = ReadonlyRecord<IReadonlyUserMaintenanceListFormContent>;

export const UserMaintenanceListFormContentFactory = Record<IReadonlyUserMaintenanceListFormContent>({
    panelId: "",
    selectedReplacement: ReplacementFactory(),
    showLoading: false,
    editWindowGuid: "",
    editWindowisActive: false,
    selectedCompanyId : 0
});

export const UserMaintenanceListFormContentFactoryFromJS = function (data: IUserMaintenanceListFormContent) {
    return UserMaintenanceListFormContentFactory({
        panelId: data.panelId,
        selectedReplacement: ReplacementFactoryFromJS(data.selectedReplacement),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        selectedCompanyId : data.selectedCompanyId
    });
}