import { ViewerType } from ".";

const ViewerDropTypes = [
    ViewerType.VideoViewer,
    ViewerType.ImageViewer,
    ViewerType.PDFViewer,
    ViewerType.GroupDocsViewer
]

const isViewerDropType = (type: ViewerType) => {
    return ViewerDropTypes.indexOf(type) > -1;
}

export {
    isViewerDropType,
    ViewerDropTypes
}