import * as React from "react";
import Input from 'antd/lib/input';
import * as styles from './assets/style.scss';
import { IInputGroupProps } from './entities';
const InputGroup = Input.Group;

export default class BCInputGroup extends React.Component<IInputGroupProps, {}> {

    style: React.CSSProperties = {};

    constructor(props: IInputGroupProps) {
        super(props);
        this.style = this.props.style;
        styles;
    }

    render() {
        return (
            <InputGroup compact={this.props.compact} size={this.props.size} style={this.style} className={this.props.cssClassName}>
                {this.props.children}
            </InputGroup>
        );
    }
}

export { IInputGroupProps };
