import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from 'immutable';
import { IProjectDeployment } from ".";
import { DesignMenuStructure, DesignMenuStructureFactory, DesignMenuStructureFactoryFromJS, IDesignMenuStructure } from "./IDesignMenuStructure";
import { IProjectItem, ProjectItem, ProjectItemFactoryFromJS } from './IProjectItem';

export interface IBaseMenuDesignerState {
    active: boolean,
    selectedProfile: string,
}

export interface IMenuDesignerState extends IBaseMenuDesignerState {
    menuStructure: IDesignMenuStructure,
    projects: IProjectItem[],
    flows: IProjectItem[],
    forms: IProjectItem[],
    deployments: IProjectDeployment[],
}

export interface IReadonlyMenuDesignerState extends IBaseMenuDesignerState {
    menuStructure: DesignMenuStructure,
    projects: List<ProjectItem>,
    flows: List<ProjectItem>,
    forms: List<ProjectItem>,
    deployments: List<IProjectDeployment>,
}

export type MenuDesignerState = ReadonlyRecord<IReadonlyMenuDesignerState>;

export const MenuDesignerStateFactory = Record<IReadonlyMenuDesignerState>({
    active: false,
    menuStructure: DesignMenuStructureFactory(),
    selectedProfile: "admin",
    projects: List(),
    flows: List(),
    forms: List(),
    deployments: List(),
});

export const MenuDesignerStateFactoryFromJS = function (data: IMenuDesignerState) {
    let _projects = data && data.projects ? data.projects.map(p => ProjectItemFactoryFromJS(p)) : [];
    let _flows = data && data.flows ? data.flows.map(f => ProjectItemFactoryFromJS(f)) : [];
    let _forms = data && data.forms ? data.forms.map(f => ProjectItemFactoryFromJS(f)) : [];

    return MenuDesignerStateFactory({
        active: data.active,
        menuStructure: DesignMenuStructureFactoryFromJS(data.menuStructure),
        selectedProfile: data.selectedProfile,
        projects: List(_projects),
        flows: List(_flows),
        forms: List(_forms),
        deployments: List(data.deployments),
    });
}