import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { ISignatureParameters, SignatureParameters, SignatureParametersFactory, SignatureParametersFactoryFromJS } from './ISignatureParameters';
import { IRecycleBinParameters, RecycleBinParameters, RecycleBinParametersFactory, RecycleBinParametersFactoryFromJS } from './IRecycleBinParameters';
import { DmViewerEntities } from "@bimser/common";

interface IBaseDmParameters {
    allowMultipleCreateBranch: boolean;
    isReferenceFileEnabled: boolean;
    qdmEnabled: boolean;
}

export interface IDmParameters extends IBaseDmParameters {
    signatureParameters: ISignatureParameters,
    viewersConfig?: Array<DmViewerEntities.IViewerConfigItem>
    recycleBinParameters: IRecycleBinParameters
}

export interface IReadonlyDmParameters extends IBaseDmParameters {
    signatureParameters: SignatureParameters,
    viewersConfig?: List<DmViewerEntities.ViewerConfigItem>,
    recycleBinParameters: RecycleBinParameters
}

export type DmParameters = ReadonlyRecord<IReadonlyDmParameters>;

export const DmParametersFactory = Record<IReadonlyDmParameters>({
    recycleBinParameters: RecycleBinParametersFactory(),
    allowMultipleCreateBranch: false,
    signatureParameters: SignatureParametersFactory(),
    isReferenceFileEnabled: false,
    qdmEnabled: false
});

export const DmParametersFactoryFromJS = function (data: IDmParameters) {
    let _viewersConfig = data?.viewersConfig.map(i => DmViewerEntities.ViewerConfigItemFactoryFromJS(i)) || [];
    return DmParametersFactory({
        ...data,
        signatureParameters: SignatureParametersFactoryFromJS(data.signatureParameters),
        viewersConfig: List(_viewersConfig),
        recycleBinParameters: RecycleBinParametersFactoryFromJS(data.recycleBinParameters)
    });
}