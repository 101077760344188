import { BCNotification, findMessage } from '@bimser/components';
import { takeEvery, select, put } from 'redux-saga/effects';
import { customErrorHandler, } from '../../..';
import { ServiceList } from "../../../services/serviceList";
import { ActionTypes, setCurrentMFAMethodAction } from '../actions';
import { SelectMFATypeRequest } from "../entities";
import { getMFAState } from '../selectors/LoginUserSelector';

export default function* watchSelectMFATypeTransaction() {
    yield takeEvery(ActionTypes.SELECT_MFA_TYPE_TRANSACTION, selectMFATypeTransaction);
}

export function* selectMFATypeTransaction(sagaAction: any) {
    try {
        const { tempTokenKey } = yield select(getMFAState);

        let request: SelectMFATypeRequest = {
            ...sagaAction.payload.toJS(),
            tempTokenKey
        };

        const response: boolean = yield ServiceList.login.Login.SelectMfaType.handleError(customErrorHandler).call(request, false, null, true);

        if (response) {
            yield put(setCurrentMFAMethodAction(request.mfaType));
        }

        if (response == false) {
            BCNotification.error({ message: findMessage.get("102630") });
        }
    } catch (error) {
        console.log(error);
    }
}