import { DmObjectType, getDmIcon } from '@bimser/common';
import { BCInputMultiLanguage, BCNotification, findMessage } from '@bimser/components';
import { MLHelper } from '@bimser/core';
import DeleteIcon from "@bimser/icons/16/delete";
import IconImportant from "@bimser/icons/16/mail-important";
import IconOk from "@bimser/icons/16/ok";
import _get from 'lodash/get';
import * as React from 'react';
import * as Style from '../assets/style.scss';
import { IProfileFormFile } from '../entities/IProfileFormFile';
import OnChangeFilePropertyPayload from '../entities/OnChangeFilePropertyPayload';

interface FileListItemProps {
    item: IProfileFormFile;
    isSelected: boolean;
    readOnly?: boolean;
    onFilePropertyChange: (payload: OnChangeFilePropertyPayload) => void;
    onSelectionChange: (id: string, selected: boolean, ctrlKey: boolean) => void;
    onRemoveFile: (fileId: string) => void;
}

const FileListItem = (props: FileListItemProps) => {

    const [isEditMode, setEditMode] = React.useState({ fileName: false, fileDescription: false });

    const onClickRemove = () => {
        props.onRemoveFile(props.item.id);
    }

    const fnStopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    const onClickEdit = (property: string) => (e: React.MouseEvent) => {
        if (!props.readOnly) {
            setEditMode({ fileDescription: property == "fileDescription", fileName: property == "fileName" });
            e.stopPropagation();
        }
    }

    const onItemPropertyChange = (property: string) => (culture: string, value: string) => {
        let newValue: any = { ..._get(props.item, property) };
        newValue[culture] = value;
        props.onFilePropertyChange({
            id: props.item.id,
            propertyName: property,
            value: newValue
        })
    }

    const onInputBlur = (property: string) => () => {
        if (_get(props.item, property)) {
            setEditMode({ ...isEditMode, [property]: false });
        } else {
            BCNotification.warning({ message: findMessage.get('102258') })
        }
    }

    const renderItemProperty = (property: string, className: string, placeHolder: any = null) => {
        if (_get(isEditMode, property)) {
            return (
                <span className={className} onClick={fnStopPropagation}>
                    <BCInputMultiLanguage
                        autoFocus
                        size={"small"}
                        values={_get(props.item, property)}
                        supportedLanguages={MLHelper.supportedLanguages}
                        currentLanguage={MLHelper.currentLanguage}
                        onLanguageValueChange={onItemPropertyChange(property)}
                        onBlur={onInputBlur(property)}
                        placeHolder={placeHolder}
                    />
                </span>
            )
        } else {
            return (
                <span className={className} onClick={onClickEdit(property)} title={MLHelper.getMLText(_get(props.item, property))}>{MLHelper.getMLText(_get(props.item, property)) || placeHolder}</span>
            )
        }
    }

    const renderFileStatus = () => {
        if (props.item.hasValidation) {
            return (
                <div className={Style.itemStatusIcon}>
                    <IconImportant />
                </div>
            )
        } else if (props.item.isFormsComplete) {
            return (
                <div className={Style.itemStatusIcon}>
                    <IconOk />
                </div>
            )
        } else {
            return (<div className={Style.itemStatusIcon}></div>)
        }
    }

    return (
        <div className={Style.profileFormFileItem + " " + (props.isSelected ? Style.selectedItem : "")} onClick={(e: React.MouseEvent) => {
            props.onSelectionChange(props.item.id, !props.isSelected, e.ctrlKey);
        }}>
            {renderFileStatus()}
            <div className={Style.itemIcon}>
                {getDmIcon(DmObjectType.File, null, MLHelper.getMLText(props.item.fileName))}
            </div>
            <div className={Style.itemInfo}>
                {renderItemProperty("fileName", Style.itemName, "")}
                {renderItemProperty("fileDescription", Style.itemDescription, findMessage.get('102307'))}
            </div>
            {!props.readOnly ? (
                <div className={Style.itemOptions}>
                    <DeleteIcon className={Style.optionItem} onClick={onClickRemove} />
                </div>
            ) : null}
        </div>
    )
}

export default FileListItem;