import { createSelector } from "reselect";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import { ILanguage, LanguageFactory, LanguageState } from "../entities";

const languageParameters = (state: BaseState) => {
    if (state.user && state.user.selectedCulture) {
        return state.user.selectedCulture;
    }
    return null;
}
const languages = (state: BaseState) => state.system.language;

const getSelectedLanguage = createSelector(
    [
        languageParameters, languages
    ],
    (data: string, langs: LanguageState) => {
        if (data) {
            let index = langs.items.findIndex(q => q.culture === data);
            let language: ILanguage = index > -1 ? langs.items.get(index).toJS() : LanguageFactory().toJS();
            return language;
        }
        return LanguageFactory().toJS();
    }
);

export { getSelectedLanguage };