enum nodeActionType {
    None = 0,
    StartAProcess = 1,
    FillAForm = 2,
    ExternalWebApplication = 3,
    Archive = 4,

    Startup = 5,
    DMExplorer = 6,
    WorkflowManagement = 7,

    eBA6Integration = 8,
    HumanResources = 9,

    HR = 10,
    AllApps = 11,
    ExternalLink = 12,
    SubPanel = 13,

    ActionForm = 14,
    ActionManagement = 15,
}

export { nodeActionType }