import { getPopupContainer, IItem, isNullOrUndefined, isPromise } from "@bimser/core";
import { GetIconComponent } from "@bimser/icons";
import SuffixArrow from "@bimser/icons/16/tree-arrow-right";
import Select from 'antd/lib/select';
import { BaseSelectRef } from 'rc-select';
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Styles from './assets/comboboxStyles.scss';
import { IComboboxLoadingArgs, IComboboxProps, IComboboxSelectedChangeEventArgs } from './entities';
import { IRawOptionType } from "./entities/IComboboxProps";

export const Option = Select.Option;
export const OptionGroup = Select.OptGroup;

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const LoadingIcon: JSX.Element = <SuffixArrow />;

const BCCombobox = React.forwardRef((props: IComboboxProps, ref: React.ForwardedRef<BaseSelectRef>) => {

    const [currentItem, setCurrentItem] = React.useState({ ...(props.options && Array.isArray(props.options) && props.options.find(i => i.value === props.value) || {}) });
    const [options, setOptions] = React.useState(props.options);

    Styles;

    React.useEffect(() => {
        getInitialOptions(props);
    }, []);

    React.useEffect(() => {
        if (currentItem?.value !== props.value) {
            setCurrentItem({ ...(options && Array.isArray(options) && options.find(i => i.value === props.value) || {}) });
        }
    }, [props.value]);

    React.useEffect(() => {
        if (Array.isArray(props.options)) {
            setOptions(props.options);
            setCurrentItem(props.options.find(item => item.value === props.value));
        } else {
            getInitialOptions(props);
        }
    }, [props.options]);

    const getInitialOptions = (props: IComboboxProps) => {
        if (props.isRunOnLoadOptionsStartup) onLoadOptions(true);
    }

    const selectedChanged = (value: any, option: any) => {

        if (props.onSelectedChange) {

            if (props.rawOptions) {
                props.onSelectedChange(value);
            } else if (props.keyTextValue) {
                props.options.forEach((item) => {

                    if (!isNullOrUndefined(value) && item.value === value) {
                        setCurrentItem({ key: item.key, text: item.text, value: item.value });

                        props.onSelectedChange({
                            senderArgs: { value, option },
                            data: { key: item.key, text: item.text, value: item.value }
                        });
                    }
                    else if (isNullOrUndefined(value)) {
                        props.onSelectedChange({
                            senderArgs: { value, option },
                            data: undefined
                        });
                    }
                })
            } else {
                if (options && Array.isArray(options)) {
                    let item = options.find(f => f.value === value);
                    if (item) {
                        setCurrentItem({ key: item.key, text: item.text, value: item.value });
                    }

                    props.onSelectedChange({ senderArgs: { value, option }, data: value });
                }
            }
        } else {
            if (options && Array.isArray(options)) {
                let item = options.find(f => f.value === value);
                setCurrentItem(item)
            }

        }
    }

    const renderIcon = (item: IItem) => {
        return GetIconComponent(item.icon, {
            title: item.externalData?.iconTitle || undefined,
            className: item.externalData?.iconClassName || undefined
        })
    }

    const getOptions = () => {

        if (props.rawOptions) return undefined;

        let _options: Array<IItem> = [];

        if (options?.length) {
            _options = [...options];
        }

        if (props.renderItem) {
            return _options.map((item, i) => props.renderItem(item, i));
        }

        return (
            _options.map((item, i) => {
                const itemText = item.icon ? <span>{item.text}</span> : item.text;

                return (
                    <Option className={item.icon ? Styles.ComboboxSelectItemContent : ""} key={item.key} value={item.value} title={item.text}>
                        {renderIcon(item)}
                        {props.renderText?.(item) || itemText}
                    </Option>
                )
            })
        );
    }

    const onLoadOptions = (isInitialLoader?: boolean) => {
        if (props.onLoadOptions && (!props.isRunOnLoadOptionsStartup || isInitialLoader)) {
            const response: Promise<IComboboxLoadingArgs> = props.onLoadOptions() as any;
            if (isPromise(response)) {
                onLoadOptionsPromise(response);
            } else {
                setItemsInState(props.options);
            }
        } else {
            setItemsInState(props.options);
        }
    }

    const onLoadOptionsPromise = (response: Promise<IComboboxLoadingArgs>) => {
        if (response?.then) {
            response.then((args) => {
                if (args && args.options && Array.isArray(args.options) && args.allow !== false) {
                    setItemsInState(args.options);
                } else {
                    setItemsInState(props.options);
                }
            })
        } else {
            setItemsInState([]);
        }
    }

    const onFilterOption = (inputValue: string, option: any) => {
        //https://github.com/ant-design/ant-design/issues/23418
        return option?.title?.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) >= 0 || false;
    }

    const setItemsInState = (items: Array<IItem>) => {
        if (items && Array.isArray(items)) {
            let _items: Array<IItem> = [...items];
            let currentItem = _items.find((item) => item.value === props.value);
            setOptions(_items);
            setCurrentItem(currentItem ? currentItem : null);
        }
    }

    const getPopupContainerFn = (triggerNode: any) => {
        return getPopupContainer(triggerNode || ReactDOM.findDOMNode(ref as any) as HTMLDivElement || document.getElementsByClassName(props.className)[0])
    }

    const getValue = () => {
        if (currentItem) {
            if (options && Array.isArray(options) && options.find((i) => i.value === currentItem.value))
                return currentItem.value;
            return props.rawOptions ? props.value ?? undefined : undefined;
        }

        return undefined
    }

    const onFocus = () => {
        onLoadOptions();
        props.onFocus?.();
    };

    const comboBoxClassNames = cx({
        ComboboxArrow: true,
        fromMobile: props.fromMobile,
        [props.className]: true
    });

    return (
        <Select
            ref={ref}
            showArrow={props.showArrow}
            allowClear={props.allowClear && !props.readonly}
            showSearch={props.showSearch && !props.readonly}
            defaultValue={props.defaultValue}
            mode={props.mode}
            maxTagCount={props.maxTagCount}
            tokenSeparators={props.tokenSeparators}
            autoFocus={props.autoFocus}
            dropdownStyle={props.dropdownStyle}
            style={props.style}
            optionFilterProp="children"
            onChange={selectedChanged}
            filterOption={onFilterOption}
            value={getValue()}
            className={comboBoxClassNames}
            onFocus={onFocus}
            size={props.size}
            loading={props.loading}
            onBlur={props.onBlur}
            suffixIcon={!props.loading ? LoadingIcon : null}
            tabIndex={props.tabIndex}
            getPopupContainer={props.getPopupContainer || getPopupContainerFn}
            disabled={props.disabled}
            onKeyDown={props.onKeyDown}
            dropdownRender={props.dropdownRender}
            defaultOpen={props.defaultOpen}
            placeholder={props.placeholder}
            open={props.readonly ? false : undefined}
            onSearch={props.onSearch}
            notFoundContent={props.notFoundContent}
            dropdownClassName={props.dropdownClassName}
            onDropdownVisibleChange={props.onDropdownVisibleChange}
            options={props.rawOptions}
            children={getOptions()}
        />
    );
});

export default BCCombobox

export { IComboboxProps, IComboboxSelectedChangeEventArgs, IComboboxLoadingArgs, BaseSelectRef as BCComboBoxRef, IRawOptionType };

