import { createSelector,OutputSelector } from "reselect";
import { CultureState,IReadonlyCultureState } from "../entities";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import {Record} from 'immutable'

const cultureParameters = (state: BaseState) => state.system.culture;

const getCultures = createSelector(
    [
        cultureParameters
    ],
    (parameters: CultureState) => {
        return parameters.items.toJS();
    }
);

export { getCultures };
