import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";

export interface IBaseSalary {
    id: number,
    userId: number,
    username: string,
    userFullname: string,
    startDate?: string,
    endDate?: string,
    fee?: number,
    currency: string,
}

export interface ISalary extends IBaseSalary {
}

export interface IReadonlySalary extends IBaseSalary {
}

export type Salary = ReadonlyRecord<IReadonlySalary>;

export const SalaryFactory = Record<IReadonlySalary>({
    id: 0,
    userId: 0,
    username: "",
    userFullname: "",
    startDate: null,
    endDate: null,
    fee: 0,
    currency: "",
});

export const SalaryFactoryFromJS = function (data: ISalary) {
    return SalaryFactory(data);
}