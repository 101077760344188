import { WebInterfaceBaseState } from "../../../common/base/state"
import { createSelector } from 'reselect'


const getHasDashboardSelector = (state: WebInterfaceBaseState) => {
    return state.user.hasDashboardForm;
}

const getHasDashboard = createSelector(
    [getHasDashboardSelector],
    (hasDashboard) => hasDashboard
)

export default getHasDashboard;