import IDmViewerProps from '../entities/IDmViewerProps';
import { createSelector } from 'reselect';
import { ViewerItem } from '../entities';
import { ContentType } from '@bimser/components';
import { BaseState } from '../../../entities/BaseState';


const getViewerItemSelector = (state: BaseState, props: IDmViewerProps) => {
    let panel = state.Viewers.panels.get(props.panelId);
    if (panel) {
        return state.Viewers.items.get(panel.itemId, null);
    }
    return null;
}

const getViewerLoadingSelector = (state: BaseState, props: IDmViewerProps) => {
    let panel = state.Viewers.panels.get(props.panelId);
    if (panel) {
        return panel.loading;
    }
    return false;
}

const getViewerSkeletonTypeSelector = (state: BaseState, props: IDmViewerProps) => {
    let panel = state.Viewers.panels.get(props.panelId);
    if (panel) {
        return panel.skeletonType;
    }
    return ContentType.imageFile;
}

const getViewerPanelSizeSelector = (state: any, props: IDmViewerProps) => {
    let panel = state.layouts?.contentLayout?.entities?.panels?.get(props.panelId);
    if (panel) {
        return panel.size;
    }
    return null;
}

const getUserSelectableViewerTypeSelector = (state: BaseState, props: IDmViewerProps) => {
    return state.Viewers.viewersConfig;
}

const getUserSelectableViewerType = createSelector(
    [getUserSelectableViewerTypeSelector],
    (viewersConfig) => {
        return viewersConfig ? viewersConfig.map(v => v.viewerType).toJS() : [];
    }
)

const getViewerItem = createSelector(
    [
        getViewerItemSelector
    ],
    (item: ViewerItem) => {
        return item ? item.toJS() : null;
    }
)

const getViewerLoading = createSelector(
    [
        getViewerLoadingSelector
    ],
    (loading: boolean) => {
        return loading;
    }
)

const getViewerSkeletonType = createSelector(
    [
        getViewerSkeletonTypeSelector
    ],
    (type: ContentType) => {
        return type;
    }
)

const getViewerPanelSize = createSelector(
    [
        getViewerPanelSizeSelector
    ],
    (size: number) => {
        return size
    }
)

export {
    getViewerItem,
    getViewerLoading,
    getViewerSkeletonType,
    getViewerPanelSize,
    getUserSelectableViewerType
}