import { BCAjax, findMessage } from "@bimser/components";
import { IBaseAction } from "@bimser/core";
import { put, take, takeLatest } from "redux-saga/effects";
import { ServiceList } from "../../..";
import { SignatureType } from "../../../utilities/DigitalSignatureAPI/entities";
import IStartMobileLoginPayload from "../../MobileSignature/entities/IStartMobileLoginPayload";
import { LOGIN_WITH_MOBILE_SIGNATURE } from "../actions/actionTypes";
import * as ActionTypes from "../actions/actionTypes";
import generalInitializerTransactionAction from "../../../action/generalInitializerTransactionAction";
import { DSLoginData } from "../../../utilities/DigitalSignatureAPI";
import { changeLoginLoadingStatusAction, setMobileSignatureFingerprintAction, loginAction, initializeApplicationStartup, getUserStartupTransactionAction } from '../actions';


function* loginWithMobileSignatureTransaction() {
    yield takeLatest(LOGIN_WITH_MOBILE_SIGNATURE, handleLoginWithMobileSignature);
}
export default loginWithMobileSignatureTransaction;

function* handleLoginWithMobileSignature(action: IBaseAction<any>) {
    let payload: IStartMobileLoginPayload = action.payload;
    let request = {
        data: btoa(unescape(encodeURIComponent(DSLoginData))),
        cAdESSignatureType: 1,
        existSignature: "",
        signatureType: SignatureType.BES,
        phoneNumber: payload.phoneNumber,
        mobileOperator: payload.operator,
        messageToDisplay: findMessage.get('102138')
    };
    yield put(changeLoginLoadingStatusAction(true));

    try {
        let initializeResponse = yield ServiceList.main.Signature.CAdES.InitializeMobileSigningForAuthentication.call(request, null, null, true)
        if (initializeResponse) {
            yield put(setMobileSignatureFingerprintAction(initializeResponse.hex));
            let finalizeRequest = {
                transactionUUID: initializeResponse.transactionUUID,
                secretKey: initializeResponse.secretKey
            };

            let finalizeResponse = yield ServiceList.main.Signature.CAdES.FinalizeMobileSigningForAuthentication.call(finalizeRequest, null, null, true);
            if (finalizeResponse && finalizeResponse.signed) {
                let signatureData = finalizeResponse.signatureData;

                let loginResponse = yield ServiceList.login.Login.LoginWithDigitalSignature.call({
                    language: payload.bimserLanguage,
                    signatureData: signatureData
                }, null, null, true);
                if (loginResponse) {
                    let token = loginResponse.token;
                    
                    yield put(loginAction({ token }));
                    
                    let headers = {
                        ...BCAjax.defaultHeaders,
                        'bimser-language': payload.bimserLanguage,
                        "Authorization": "Bearer " + token,
                    }
                    
                    BCAjax.setHeaders(headers);
                    sessionStorage.setItem('token', token);
                    if (localStorage.getItem("rememberMe") == "1") {
                        localStorage.setItem('token', token);
                    }
                    localStorage.setItem("loginMethod", "mobileSignature");

                    yield put(initializeApplicationStartup());
                    yield take(ActionTypes.INITIALIZED_APPLICATION_STARTUP);

                    yield put(getUserStartupTransactionAction());
                    yield put(generalInitializerTransactionAction());
                } else {
                    yield put(changeLoginLoadingStatusAction(false));
                }
            } else {
                yield put(changeLoginLoadingStatusAction(false));
            }

        } else {
            yield put(changeLoginLoadingStatusAction(false));
        }
    } catch (error) {
        console.log("🚀 -> function*handleLoginWithMobileSignature -> error", error)
        yield put(changeLoginLoadingStatusAction(false));
    }

}