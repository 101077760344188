import { takeEvery, select, call, put } from 'redux-saga/effects';
import * as Hash from 'object-hash';
import * as ActionTypes from "../actionTypes";
import { BCExtremeGridActionTypes, BCExtremeGridActionCreators } from '@bimser/components';
import { IBaseMutableAction, IBaseAction } from '@bimser/core';
import { IChangeExtremeGridStatePayload } from '../entities/payloads';
import { ExtremeGridAPI } from '../api';
import { BaseState } from '../../../entities/BaseState';
import { changeExtremeGridState, clearCache, setCurrentCacheId } from '../actions';
import getLoadOptions from './getLoadOptions';

export default function* (): any {
    yield takeEvery((action: IBaseAction<any>) => {
        if (
            action.type === ActionTypes.CHANGE_STATE &&
            (
                action.payload?.action && !action.payload.unwatch &&
                (
                    action.payload.action.type === BCExtremeGridActionTypes.CHANGE_SORTING ||
                    action.payload.action.type === BCExtremeGridActionTypes.CHANGE_PAGING ||
                    action.payload.action.type === BCExtremeGridActionTypes.CHANGE_FILTERING ||
                    action.payload.action.type === BCExtremeGridActionTypes.CHANGE_FILTER_ITEMS ||
                    action.payload.action.type === BCExtremeGridActionTypes.CHANGE_SEARCHING
                )
            )
            ||
            (
                (action.type === ActionTypes.CREATE_EXTREMEGRID || action.type === ActionTypes.CREATE_EXTREMEGRID_OVERRIDE) &&
                action.payload?.remoteOperations
            )
            ||
            (action.type === ActionTypes.CLEAR_CACHE_ASYNC && action.payload?.id)
        ) {
            return true;
        }
    }, stateChanged);
}

function isRequireResetPageIndex(action: IBaseMutableAction<IChangeExtremeGridStatePayload<any>>) {
    return action.type === ActionTypes.CHANGE_STATE &&
        (
            action.payload?.action &&
            (
                action.payload.action.type === BCExtremeGridActionTypes.CHANGE_FILTERING ||
                action.payload.action.type === BCExtremeGridActionTypes.CHANGE_FILTER_ITEMS ||
                action.payload.action.type === BCExtremeGridActionTypes.CHANGE_SEARCHING
            )
        )
}


function* stateChanged(action: IBaseMutableAction<IChangeExtremeGridStatePayload<any>>): any {
    let state: BaseState = yield select();

    let gridState = state.ExtremeGrid.items.get(action.payload.id);
    if (gridState?.remoteOperations) {
        let loadOptions = getLoadOptions(gridState);

        if (loadOptions) {
            let dg = ExtremeGridAPI.get(action.payload.id);

            if (isRequireResetPageIndex(action)) {
                loadOptions.pagination.skip = 0;
            }

            let requestData = dg.customizeRequestData(loadOptions);
            if (gridState.externalData && action.type !== ActionTypes.CLEAR_CACHE_ASYNC) {
                let hash = Hash(requestData);

                if (gridState.dataRefs?.current !== hash && gridState.dataRefs?.caches[hash]) {
                    let setCurrentAction = setCurrentCacheId({
                        id: action.payload.id,
                        hash: hash
                    });
                    yield put(setCurrentAction);
                    return;
                }
            }

            dg.showLoading();
            let data = yield call(ExtremeGridAPI.loadDataSource, action.payload.id, requestData);
            if (data) {
                if (action.type === ActionTypes.CLEAR_CACHE_ASYNC) {
                    yield put(clearCache(action.payload))
                }

                if (isRequireResetPageIndex(action)) {
                    let dgAction = BCExtremeGridActionCreators.changePageIndex(0);
                    yield put(changeExtremeGridState({
                        id: action.payload.id,
                        action: dgAction,
                        unwatch: true
                    }));
                }

                if (gridState.externalData) {
                    dg.setDataRef({
                        items: data.data,
                        totalCount: data.totalCount,
                        requestParameters: requestData,
                        setCurrent: true
                    })
                }
                else {
                    dg.setData(data);
                }
            }
            dg.hideLoading();
        }
    }
}

