import * as React from "react";
import VideoViewer from "./components/VideoViewer";
import PDFViewer from "./components/PDFViewer";
import { CodeEditorBuiltinThemes } from "../index";
import { IViewerCProps, IViewerProps, ViewerType } from "./entities";
import TiffViewer from "./components/TiffViewer";
import ImageViewer from "./components/ImageViewer";
import TextViewerLayout from "./components/TextViewerLayout";
import OfficeViewer from "./components/OfficeViewer";
import GroupDocsViewer from "./components/GroupDocsViewer";
import BCAlert, { IAlertTypes } from "./../BCAlert";
import * as Style from './assets/style.scss';

export default class BCViewer extends React.Component<IViewerCProps & IViewerProps, {}> {
    constructor(props: IViewerCProps & IViewerProps) {
        super(props);
        this.generateTemplate = this.generateTemplate.bind(this);
    }

    generateTemplate() {
        switch (this.props.type) {
            case ViewerType.TextViewer: {
                return (
                    <>
                        {this.props.language ? <TextViewerLayout
                            value={this.props.data}
                            language={this.props.language}
                            mimeType={this.props.mimeType}
                            theme={CodeEditorBuiltinThemes.White}
                            options={{ readOnly: this.props.readOnly }}
                            width={"100%"}
                            height={"100%"}
                            onChange={this.props.onChange}
                            getRibbonItems={this.props.getRibbonItems}
                            panelSize={this.props.panelSize}
                        /> : ''}
                        {this.getTextViewerPrintComponent()}
                    </>
                )
            }
            case ViewerType.VideoViewer:
                return (
                    <VideoViewer
                        src={this.props.data}
                        mimeType={this.props.mimeType}
                        width={"100%"}
                        height={"100%"}
                    />
                );
            case ViewerType.PDFViewer:
                return (
                    <PDFViewer
                        printRef={this.props.printRef}
                        data={this.props.data}
                    />
                );
            case ViewerType.TiffViewer:
                return (
                    <TiffViewer
                        ref={this.props.printRef}
                        src={this.props.data}
                        mimeType={this.props.mimeType}
                        height={"100%"}
                        width={"auto"}
                    />
                );
            case ViewerType.ImageViewer:
                return (
                    <ImageViewer
                        ref={this.props.printRef}
                        src={this.props.data}
                        mimeType={this.props.mimeType}
                        height={"100%"}
                        width={"auto"}
                    />
                );
            case ViewerType.OfficeViewer:
                return (
                    <OfficeViewer
                        data={this.props.data}
                        mimeType={this.props.mimeType}
                        height={"100%"}
                        width={"auto"}
                    />
                );
            case ViewerType.GroupDocsViewer:
                return (
                    <GroupDocsViewer
                        data={this.props.data}
                        mimeType={this.props.mimeType}
                        noContentTitle={this.props.noContentTitle}
                        height={"100%"}
                        width={"auto"}
                    />
                );
            case ViewerType.Unsupported:
                return (
                    <BCAlert
                        message={this.props.data}
                        type={IAlertTypes.warning}
                        showIcon
                    />
                )
            case ViewerType.NotificationViewer:
                return <BCAlert
                    message={this.props.data}
                    type={IAlertTypes.error}
                    showIcon
                />
            default:
                return <></>
        }
    }

    getTextViewerPrintComponent() {
        const PrintComponent = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
            return <div className={Style.textViewerPrintableContent} ref={ref}> {this.props.data} </div>
        })

        return <div className={Style.textViewerPrintableWrapper}><PrintComponent ref={this.props.printRef} /></div>
    }

    render() {
        return (
            this.generateTemplate()
        );
    }
}

export { BCViewer };