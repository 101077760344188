import React, { lazy, Suspense } from 'react';
import { IShiftFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';

const ShiftContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function ShiftContainer(props: React.PropsWithChildren<IShiftFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ShiftContainerLazy {...props} />
        </Suspense>
    );
}
ShiftContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default ShiftContainer;