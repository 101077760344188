import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { IWebInterfaceAction } from "../../../../common/entities";
import RecentFiles from "../../components/widgets/RecentFiles";
import IRecentFilesProps from "../../entities/widgets/IRecentFilesProps";
import { getRecentFilesData, getRecentFilesLoading } from "../../selectors/RecentFilesSelector";
import { onClickAllRecentFilesTransaction, onClickRecentFileTransaction } from "../../actions";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IRecentFilesProps): IRecentFilesProps {
    return {
        data: getRecentFilesData(state),
        loading: getRecentFilesLoading(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IRecentFilesProps {
    return {
        onClickFile: function(data: IWebInterfaceAction) {
            dispatch(onClickRecentFileTransaction(data));
        },
        onClickAllFiles: function(data: IWebInterfaceAction) {
            dispatch(onClickAllRecentFilesTransaction(data));
        }
    };
}

// react - redux connection
const RecentFilesContainer = connect(mapStateToProps, mapDispatchToProps)(RecentFiles);

export default RecentFilesContainer;