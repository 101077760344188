export function push(keyValue: any, items1: Array<any>, items2: Array<any> | any, keyName?: string): Array<any> {

    if (!keyName || keyName == "") {
        keyName = "key";
    }

    for (const item of items1) {
        if (item[keyName] == keyValue) {
            if (Array.isArray(items2)) {
                if (item.items == null || item.items == undefined) {
                    item.items = items2;
                }
                else {
                    item.items.push(...items2);
                }
            }
            else {

                if (item.items == null || item.items == undefined) {
                    item.items = [];
                }

                item.items.push(items2);
            }
        }
        else {
            if (item.items && item.items.length > 0) {
                item.items = this.push(keyValue, item.items, items2, keyName);
            }
        }
    }

    return items1;
}

export function remove(keyValue: any, items: Array<any>, isAssign?: boolean, keyName?: string): Array<any> {

    let newItems: Array<any> = [];
    if (isAssign && isAssign === true) {
        newItems = Object.assign([], items);
    }
    else {
        newItems = items;
    }

    if (!keyName || keyName == "") {
        keyName = "key";
    }

    for (const item of newItems) {
        if (item[keyName] == keyValue) {
            let index = newItems.indexOf(item, 0);
            if (index > -1) {
                newItems.splice(index, 1);
            }
        }
        else {
            if (item.items && item.items.length > 0) {
                item.items = this.remove(keyValue, item.items, false, keyName);
            }
        }
    }

    return newItems;
}