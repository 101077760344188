import { systemFontFamilies } from "@bimser/core";
import IconTextAlignCenter from "@bimser/icons/16/text-align-center";
import IconTextAlignLeft from "@bimser/icons/16/text-align-left";
import IconTextAlignRight from "@bimser/icons/16/text-align-right";
import cloneDeep from "lodash/cloneDeep";
import * as React from "react";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import { ColorPickerProperty, InputNumberProperty, SelectProperty } from '../propertyItems';
import { PToggleButtonGroup } from './helper';

const inputStyle: React.CSSProperties = { width: '100%' };
const fontContainerStyle: React.CSSProperties = { width: 'calc(100% - 70px - 10px)', minWidth: '50px', display: 'inline-block', margin: '0 10px 5px 0', float: 'left' };
const selectDropdownStyle: React.CSSProperties = { minWidth: 200 };
const inputNumberContainerStyle: React.CSSProperties = { width: '70px', minWidth: '50px', display: 'inline-block', margin: '0 0 5px 0' };
const colorPickerStyle: React.CSSProperties = { display: 'inline-block', margin: '0 10px 5px 0', verticalAlign: 'top' }
const PTGButtonGroupStyle: React.CSSProperties = { margin: '0 10px 5px 0', verticalAlign: 'top' };
const colorPickerStyles: React.CSSProperties = { width: 115 };

export default function FontProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const changeFontFamily = (value: any) => {
        const valueInstance = cloneDeep(props.value);
        valueInstance.family = value;
        replaceValue(props.item, valueInstance)
    }

    const changeFontSize = (value: any) => {
        const valueInstance = cloneDeep(props.value);
        valueInstance.size = value;
        replaceValue(props.item, valueInstance)
    }

    const changeFontColor = (value: any) => {
        const valueInstance = cloneDeep(props.value);
        valueInstance.color = value;
        replaceValue(props.item, valueInstance)
    }

    const changeFontStyles = (changedValue: any) => {
        const valueInstance = cloneDeep(props.value);

        if (changedValue.key === "1") {
            valueInstance.bold = changedValue.value;
        } else if (changedValue.key === "2") {
            valueInstance.italic = changedValue.value;
        } else if (changedValue.key === "3") {
            valueInstance.underline = changedValue.value;
        }

        replaceValue(props.item, valueInstance)
    }

    const changeTextAlign = (changedValue: any) => {
        const valueInstance = cloneDeep(props.value);

        if (changedValue.key === "1") {
            valueInstance.textAlign = 'left';
        } else if (changedValue.key === "2") {
            valueInstance.textAlign = 'center';
        } else if (changedValue.key === "3") {
            valueInstance.textAlign = 'right';
        }

        replaceValue(props.item, valueInstance)
    }

    const renderAlignmentButtons = () => {
        if (props.value.textAlign != undefined) {
            const textAlignButtonGroups = [
                {
                    key: "1",
                    icon: IconTextAlignLeft.info,
                    value: (props.value.textAlign === 'left')
                },
                {
                    key: "2",
                    icon: IconTextAlignCenter.info,
                    value: (props.value.textAlign === 'center')
                },
                {
                    key: "3",
                    icon: IconTextAlignRight.info,
                    value: (props.value.textAlign === 'right')
                }
            ];
            return (<PToggleButtonGroup
                items={textAlignButtonGroups}
                customChangeEvent={changeTextAlign}
                singleSelect={true}
            />);
        }
        return null;
    }

    const renderFontDecoration = () => {
        const fontStyleButtonGroups = [
            {
                key: "1",
                label: <span>{'B'}</span>,
                value: props.value.bold,
                style: { width: 32 }
            },
            {
                key: "2",
                label: <span style={{ fontStyle: 'italic' }}>{'I'}</span>,
                value: props.value.italic,
                style: { width: 32 }
            },
            {
                key: "3",
                label: <span style={{ textDecoration: 'underline' }}>{'U'}</span>,
                value: props.value.underline,
                style: { width: 32 }
            }
        ];

        return <PToggleButtonGroup
            items={fontStyleButtonGroups}
            style={PTGButtonGroupStyle}
            customChangeEvent={changeFontStyles}
        />
    }

    const renderFontColor = () => {
        return (
            <div style={colorPickerStyle}>
                <ColorPickerProperty
                    item={props.item}
                    customChangeEvent={changeFontColor}
                    value={props.value.color}
                    style={colorPickerStyles}
                />
            </div>
        )
    }

    const renderFontSize = () => {
        return (
            <div style={inputNumberContainerStyle}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    customChangeEvent={changeFontSize}
                    value={props.value.size}
                    minValue={0}
                />
            </div>
        )
    }

    const renderText = (item: any) => {
        return (
            <span style={{ fontFamily: item.value }}>{item.text}</span>
        )
    }

    const renderFontFamily = () => {
        return (
            <div style={fontContainerStyle}>
                <SelectProperty
                    item={{
                        ...props.item, options: systemFontFamilies,
                        externalData: { ...(props.item.externalData ? props.item.externalData : {}), renderText: renderText }
                    }}
                    style={inputStyle}
                    customChangeEvent={changeFontFamily}
                    value={props.value.family}
                    dropdownStyle={selectDropdownStyle}
                />
            </div>
        )
    }

    const hideFontFamily = props.item.options?.hideFontFamily ?? false;
    const hideFontDecoration = props.item.options?.hideFontDecoration ?? false;
    const hideFontSize = props.item.options?.hideFontSize ?? false;
    const hideFontColor = props.item.options?.hideFontColor ?? false;

    if (props.value) {
        return (
            <>
                {!hideFontFamily && (renderFontFamily())}
                {!hideFontSize && (renderFontSize())}
                {!hideFontColor && (renderFontColor())}
                {!hideFontDecoration && (renderFontDecoration())}
                {renderAlignmentButtons()}
            </>
        )
    }

}