import * as React from "react";
import * as Styles from './assets/styles.scss';
import { ICollapseItem, ICollapseProps, ICollapseToolboxItem } from './entities';
import { IButtonBarItem } from '../BCButtonBar/entities';
import { findMessage } from '../BCIntl';
import Collapse from 'antd/lib/collapse';
import BCScrollbars from "../BCScrollbars";
import IconCollapse from "@bimser/icons/16/collapse";
import IconExpand from "@bimser/icons/16/expand";
import IconEmpty from "@bimser/icons/16/empty-icon";
import BCAddonButton from "../BCAddonButton";
import GetIcon16ByName from "@bimser/icons/lib/getIcon16ByName";

export default class BCCollapse extends React.Component<ICollapseProps, { activeKey: string | string[] }> {
    constructor(props: ICollapseProps) {
        super(props);
        this.wrapperWithScrollbars = this.wrapperWithScrollbars.bind(this);
        this.renderCollapse = this.renderCollapse.bind(this);
        this.renderCollapsePanel = this.renderCollapsePanel.bind(this);
        this.renderCollapseHeader = this.renderCollapseHeader.bind(this);
        this.toolboxItemClick = this.toolboxItemClick.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            activeKey: null
        }
    }

    onChange(activeKey: string | string[]) {
        if (this.props.onChangeExpandedKeys) {
            this.props.onChangeExpandedKeys(activeKey);
        } else {
            this.setState({
                activeKey: activeKey
            });
        }
    }

    wrapperWithScrollbars(children: JSX.Element) {
        return (
            <>
                {this.renderToolboxHeader()}
                <BCScrollbars styles={this.props.scrollbarStyles} autoHide>
                    {children}
                </BCScrollbars>
            </>
        )
    }
    renderContent(item: ICollapseItem) {
        if (this.props.renderCollapseItemContent) {
            return this.props.renderCollapseItemContent(item);
        }
        return item.content;
    }

    renderCollapsePanel(item: ICollapseItem) {
        return (
            <Collapse.Panel header={this.props.customHeader ? this.props.customHeader(item) : this.renderCollapseHeader(item)} key={item.key} collapsible={item.collapsible || 'header'} showArrow={this.props.showArrow ? true : false}>
                {this.renderContent(item)}
            </Collapse.Panel>
        )
    }

    renderCollapseHeader(item: ICollapseItem) {
        return (
            <div className={[Styles.header, this.props.headerClassnames].join(" ")}>
                <p>{item.label}</p>
                {
                    !this.props.showArrow &&
                    <>
                        <IconExpand className={"expandBtn"} />
                        <IconCollapse className={"collapseBtn"} />
                    </>
                }
            </div>
        )
    }

    toolboxItemClick(args: string) {
        if (this.props.onToolboxItemClick)
            this.props.onToolboxItemClick(args);
    }

    renderToolboxHeader() {
        if (this.props.toolboxItems && this.props.toolboxItems.length > 0) {
            const toolboxItems = this.props.toolboxItems;
            let items: IButtonBarItem[] = [];
            let farItems: IButtonBarItem[] = [];

            toolboxItems.forEach((toolbox, index) => {
                const Icon = GetIcon16ByName(toolbox.icon, IconEmpty);
                if (toolbox.farItem) {
                    farItems.push({
                        buttonProps: {
                            text: toolbox.text,
                            title: toolbox.text,
                            id: toolbox.key,
                            icon: <Icon />,
                            onClick: () => this.toolboxItemClick(toolbox.key)
                        },
                        externalData: {
                            showText: toolbox.showText,
                        }
                    });
                } else {
                    items.push({
                        buttonProps: {
                            text: toolbox.text,
                            title: toolbox.text,
                            id: toolbox.key,
                            icon: <Icon />,
                            className: toolbox.active ? "active" : "",
                            onClick: () => this.toolboxItemClick(toolbox.key)
                        },
                        externalData: {
                            showText: toolbox.showText,
                        }
                    });
                }
            });

            return (
                <BCAddonButton
                    items={items}
                    farItems={farItems}
                    elementsCountToShow={5}
                >
                    <span>{findMessage.get('100896')}</span>
                </BCAddonButton>
            )
        }
    }

    renderCollapse() {
        let { items, classNames, containerStyles } = this.props;

        let manageCollapseKeyProps = {
            activeKey: this.props.expandedKeys ? this.props.expandedKeys : this.state.activeKey,
        };

        return (
            <>
                {!this.props.hasScrollbar && this.renderToolboxHeader()}
                <Collapse
                    bordered={false}
                    defaultActiveKey={this.props.defaultActiveKey}
                    className={classNames && classNames.length ? classNames.join(' ') : undefined}
                    style={{ ...containerStyles }}
                    accordion={this.props.accordion}
                    expandIcon={this.props.expandIcon}
                    {...(this.props.manageStateBySelf ? {} : manageCollapseKeyProps)}
                    onChange={this.onChange}
                >
                    {
                        items.map((item: ICollapseItem) => { return this.renderCollapsePanel(item) })
                    }
                </Collapse>
            </>
        )

    }

    render() {
        if (this.props.hasScrollbar) {
            return this.wrapperWithScrollbars(this.renderCollapse())
        } else {
            return this.renderCollapse()
        }
    }
}

export { ICollapseProps, ICollapseItem, ICollapseToolboxItem };
