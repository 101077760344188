import { Record, List } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';
import { IUserInfo, UserInfo, UserInfoFactoryFromJS } from '@bimser/common';

interface IBaseDmVersion{
    id?: string;
    secretKey?: string;
    version?: string;
    createdAt?: string;
    isDefault?: boolean;
    objectId?: number;
    showingContentId?: string;
}
export interface IDmVersion extends IBaseDmVersion {
    contentIds?: Array<number>;
    createdBy?: IUserInfo;
}
export interface IReadonlyDmVersion extends IBaseDmVersion {
    contentIds?: List<number>;
    createdBy?: UserInfo;
}
export type DmVersion = ReadonlyRecord<IReadonlyDmVersion>;
export const  DmVersionFactory = Record<IReadonlyDmVersion>({
    id: null,
    secretKey: null,
    version: null,
    createdAt: null,
    createdBy: null,
    objectId: null,
    isDefault: false,
    showingContentId: null,
    contentIds: List(),
});
export const DmVersionFactoryFromJS = function (data: IDmVersion) {
    return DmVersionFactory({
        ...data,
        createdBy: UserInfoFactoryFromJS(data.createdBy),
        contentIds: List(data.contentIds),
    });
}