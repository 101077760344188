import { findMessage } from "@bimser/components";

const LoginErrorCodes = {
    AuthenticateFailedLoginRequestLimitPerUser: "AUTH_SERV_0064",
    AuthenticateFailedLoginRequestLimitPerIp: "AUTH_SERV_0065",
    AuthenticateWithAccessTokenFailedLoginRequestLimitPerUser: "AUTH_SERV_0072",
    AuthenticateWithAccessTokenFailedLoginRequestLimitPerIp: "AUTH_SERV_0073",
    AuthenticateWithDigitalSignatureFailedLoginRequestLimitPerUser: "AUTH_SERV_0080",
    AuthenticateWithDigitalSignatureFailedLoginRequestLimitPerIp: "AUTH_SERV_0081",
    //Yukarıdaki hata kodları döndüğünde captcha gösterilecek

    AuthenticateMustFilledCaptchaInfo: "AUTH_SERV_0058",
    AuthenticateCaptchaInfoIsInvalid: "AUTH_SERV_0059",
    AuthenticateCaptchaInfoNotFound: "AUTH_SERV_0060",
    AuthenticateMustFilledCaptchaInfo2: "AUTH_SERV_0061",
    AuthenticateCaptchaInfoIsInvalid2: "AUTH_SERV_0062",
    AuthenticateCaptchaIdNotFound2: "AUTH_SERV_0063",

    AuthenticateWithAccessTokenMustFilledCaptchaInfo: "AUTH_SERV_0066",
    AuthenticateWithAccessTokenCaptchaInfoIsInvalid: "AUTH_SERV_0067",
    AuthenticateWithAccessTokenCaptchaIdNotFound: "AUTH_SERV_0068",
    AuthenticateWithAccessTokenMustFilledCaptchaInfo2: "AUTH_SERV_0069",
    AuthenticateWithAccessTokenCaptchaInfoIsInvalid2: "AUTH_SERV_0070",
    AuthenticateWithAccessTokenCaptchaIdNotFound2: "AUTH_SERV_0071",

    AuthenticateWithDigitalSignatureMustFilledCaptchaInfo: "AUTH_SERV_0074",
    AuthenticateWithDigitalSignatureCaptchaInfoIsInvalid: "AUTH_SERV_0075",
    AuthenticateWithDigitalSignatureCaptchaIdNotFound: "AUTH_SERV_0076",
    AuthenticateWithDigitalSignatureMustFilledCaptchaInfo2: "AUTH_SERV_0077",
    AuthenticateWithDigitalSignatureCaptchaInfoIsInvalid2: "AUTH_SERV_0078",
    AuthenticateWithDigitalSignatureCaptchaIdNotFound2: "AUTH_SERV_0079",

    AuthenticationServiceAuthenticateFailedCaptchaSuccessButLoginFailed: "AUTH_SERV_0104",
    AuthenticationServiceAuthenticateWithAccessTokenFailedCaptchaSuccessButLoginFailed: "AUTH_SERV_0105",
    AuthenticationServiceAuthenticateWithDigitalSignatureFailedCaptchaSuccessButLoginFailed: "AUTH_SERV_0106",

    AuthenticationFailed: "AUTH_SERV_0014",//Yanlış Şifre
    UserNameCouldNotBeResolved: "AUTH_SERV_0051",//Kullanıcı adı sistemde kayıtlı değil.
    UserIsPassive: "AUTH_SERV_0043",//Kullanıcı Aktif değil.
    UserIsUnauthorized: "AUTH_SERV_0041"//Kullanıcı Erişim Yetkisi Yoktur.
};

const captchaRequiredErrors = [
    LoginErrorCodes.AuthenticateFailedLoginRequestLimitPerUser,
    LoginErrorCodes.AuthenticateFailedLoginRequestLimitPerIp,
    LoginErrorCodes.AuthenticateWithAccessTokenFailedLoginRequestLimitPerUser,
    LoginErrorCodes.AuthenticateWithAccessTokenFailedLoginRequestLimitPerIp,
    LoginErrorCodes.AuthenticateWithDigitalSignatureFailedLoginRequestLimitPerUser,
    LoginErrorCodes.AuthenticateWithDigitalSignatureFailedLoginRequestLimitPerIp
]

const captchaMustFilledErrors = [
    LoginErrorCodes.AuthenticateMustFilledCaptchaInfo,
    LoginErrorCodes.AuthenticateMustFilledCaptchaInfo2,
    LoginErrorCodes.AuthenticateWithAccessTokenMustFilledCaptchaInfo,
    LoginErrorCodes.AuthenticateWithAccessTokenMustFilledCaptchaInfo2,
    LoginErrorCodes.AuthenticateWithDigitalSignatureMustFilledCaptchaInfo,
    LoginErrorCodes.AuthenticateWithDigitalSignatureMustFilledCaptchaInfo2
]

const captchaInfoInvalidErrors = [
    LoginErrorCodes.AuthenticateCaptchaInfoIsInvalid,
    LoginErrorCodes.AuthenticateCaptchaInfoIsInvalid2,
    LoginErrorCodes.AuthenticateWithAccessTokenCaptchaInfoIsInvalid,
    LoginErrorCodes.AuthenticateWithAccessTokenCaptchaInfoIsInvalid2,
    LoginErrorCodes.AuthenticateWithDigitalSignatureCaptchaInfoIsInvalid,
    LoginErrorCodes.AuthenticateWithDigitalSignatureCaptchaInfoIsInvalid2
]

const captchaIdNotFoundErrors = [
    LoginErrorCodes.AuthenticateCaptchaInfoNotFound,
    LoginErrorCodes.AuthenticateCaptchaIdNotFound2,
    LoginErrorCodes.AuthenticateWithAccessTokenCaptchaIdNotFound,
    LoginErrorCodes.AuthenticateWithAccessTokenCaptchaIdNotFound2,
    LoginErrorCodes.AuthenticateWithDigitalSignatureCaptchaIdNotFound,
    LoginErrorCodes.AuthenticateWithDigitalSignatureCaptchaIdNotFound2
]

const captchaSuccessButLoginFailed = [
    LoginErrorCodes.AuthenticationServiceAuthenticateFailedCaptchaSuccessButLoginFailed,
    LoginErrorCodes.AuthenticationServiceAuthenticateWithAccessTokenFailedCaptchaSuccessButLoginFailed,
    LoginErrorCodes.AuthenticationServiceAuthenticateWithDigitalSignatureFailedCaptchaSuccessButLoginFailed
]

const usernameOrPasswordErrors = [
    LoginErrorCodes.AuthenticationFailed,
    LoginErrorCodes.UserNameCouldNotBeResolved
]

const getInnerExceptionErrorCode = (error: any): string => {
    if (error.InnerException) {
        return getInnerExceptionErrorCode(error.InnerException);
    } else if (error.Code) {
        return error.Code;
    } else {
        return "AUTH_SERV_0001";
    }
}

const getLoginError = (error: any) => {
    let errorCode = getInnerExceptionErrorCode(error);
    let message = findMessage.get('102541');
    let showCaptcha = false;

    if (captchaRequiredErrors.includes(errorCode)) {
        message = findMessage.get('102542');
        showCaptcha = true;
    } else if (captchaMustFilledErrors.includes(errorCode)) {
        message = findMessage.get('102543');
        showCaptcha = true;
    } else if (captchaInfoInvalidErrors.includes(errorCode)) {
        message = findMessage.get('102544');
        showCaptcha = true;
    } else if (captchaIdNotFoundErrors.includes(errorCode)) {
        message = findMessage.get('102545');
        showCaptcha = true;
    } else if (captchaSuccessButLoginFailed.includes(errorCode)) {
        message = findMessage.get('102764');//"Girilen captcha doğru ama kullanıcı adı yada şifre yanlış."
        showCaptcha = true;
    } else if (usernameOrPasswordErrors.includes(errorCode)) {
        message = findMessage.get('100592');//"Kullanıcı Adı veya Şifre Yanlış"
    } else if (errorCode == LoginErrorCodes.UserIsUnauthorized) {
        message = findMessage.get('102701');//"Kullanıcı Erişim Yetkisi Yoktur"
    } else if (errorCode == LoginErrorCodes.UserIsPassive) {
        message = findMessage.get('102546');
    }

    return { showCaptcha, message };
}

export {
    getLoginError
}