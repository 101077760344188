import { ViewerType } from '../entities';

export default class ViewerUtils {
    public static GetViewerTypeFromMimeType(type: string): ViewerType {
        switch (type) {
            case 'video/mp4':
            case 'video/webm':
                return ViewerType.VideoViewer;
            case 'application/pdf':
                return ViewerType.PDFViewer;
            case 'plain/text':
                return ViewerType.TextViewer;
            case 'image/jpeg':
            case 'image/bmp':
            case 'image/gif':
            case 'image/png':
                return ViewerType.ImageViewer;
            case 'image/tiff':
                return ViewerType.TiffViewer;
            case 'application/msword':
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
            case 'application/vnd.ms-word.document.macroEnabled.12':
            case 'application/vnd.ms-word.template.macroEnabled.12':
            case 'application/vnd.ms-excel':
            case 'application/vnd.ms-excel':
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
            case 'application/vnd.ms-excel.sheet.macroEnabled.12':
            case 'application/vnd.ms-excel.template.macroEnabled.12':
            case 'application/vnd.ms-excel.addin.macroEnabled.12':
            case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
            case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
            case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
            case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
                return ViewerType.OfficeViewer;
            default:
                return ViewerType.TextViewer;
        }
    }

    public static GetMonacoLanguageFromMimeType(type: string): string {
        switch (type) {
            case 'plain/text':
                return 'plaintext';
            case 'application/javascript':
            case 'text/jscript':
                return 'javascript';
            case 'application/json':
                return 'json';
            case 'text/html':
            case 'application/html':
            case 'application/xhtml+xml':
                return 'html';
            case 'text/css':
            case 'text/x-css':
                return 'css';
            case 'application/sql':
                return 'sql';
            case 'text/xml':
                return 'xml';
            case 'text/yaml':
            case 'text/x-yaml':
                return 'yaml';
            case 'text/bat':
            case 'application/x-bat':
                return 'bat';
            case 'text/x.typescript':
            case 'text/prs.typescript':
            case 'text/typescript':
                return 'typescript';
            default:
                return 'plaintext';
        }
    }


}