import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core"

export interface IBasePropertyReset {
    value: any
    onReset?: (oldVal: any) => void
}

export interface IPropertyReset extends IBasePropertyReset {

}

export interface IReadonlyPropertyReset extends IBasePropertyReset {

}

export type PropertyReset = ReadonlyRecord<IReadonlyPropertyReset>;

export const PropertyResetFactory = Record<IReadonlyPropertyReset>({
    value: null,
    onReset: null
});

export const PropertyResetFactoryFromJS = function (data: IPropertyReset) {
    return PropertyResetFactory(data);
}