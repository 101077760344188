
import { connect } from 'react-redux';
import { BaseState } from "../..";
import { getSelectedCultureName } from '../../system/culture/selectors';
import { getDefaultLanguage } from '../../system/language/selectors';
import MLSelector from './components';
import { IMLSelectorProps } from './entities';

const mapStateToProps = function (state: BaseState, props: IMLSelectorProps): IMLSelectorProps {
    return {
        selectedCulture: getSelectedCultureName(state as any),
        data: props.data,
        defaultLanguage: getDefaultLanguage(state as any)
    };
}

const MLSelectorContainer = connect(mapStateToProps, null)(MLSelector);

export default MLSelectorContainer;
export { getMLValueGeneratorSelector, getMLValueSelector } from './helpers';

