
export default function (text: string): number {
    var counter = 0;
    var lowercase = 0;
    for (; counter < text.length; counter++) {
        if (isLowerCase(text.charAt(counter))) { lowercase++; }
    }
    return lowercase;
}

function isLowerCase(character: string) {
    if (character.match(/[a-z]+/g)) return true;
    else return false;
}