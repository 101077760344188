import { ExtremeGridAPI } from ".";
import { BCExtremeGridEntities, BCExtremeGridActionCreators, IRibbonBarItemProps } from "@bimser/components";
import { EventEmitter, IBaseMutableAction } from "@bimser/core";
import { Events, ISetDataRefArgs, IExtremeGridLoadOptions } from "../entities/api";
import ExtremeGridDataSource from "./ExtremeGridDataSource";
import { setData, setLoading, setDataRef, changeExtremeGridState, clearCacheAsync, setColumns, setSelectionIds, updateVirtualScrollingOptions } from "../actions";
import { BaseState } from "../../..";
import { removeExtremeGrid } from "../actions/removeExtremeGrid";
import { updateCacheKey } from "../actions/updateCacheKey";
import { IUpdateVirtualScrollingOptions } from '../entities/payloads/IUpdateVirtualScrollingOptions';


export default class ExtremeGridInstance {
    private eventEmitter: EventEmitter;
    private dataResolver: (state: BaseState, dataIds: Array<string | number>, cacheId?: string) => any;
    private requestCustomizer: (loadOptions: IExtremeGridLoadOptions) => any;

    id: string;
    dataSource: ExtremeGridDataSource;

    constructor(id: string) {
        this.eventEmitter = new EventEmitter();
        this.dataSource = new ExtremeGridDataSource();

        this.id = id;
    }

    subscribe(eventName: Events, func: Function) {
        this.eventEmitter.subscribe(eventName, func);
    }

    emit(eventName: Events, data: any) {
        this.eventEmitter.emit(eventName, data);
    }

    off(eventName: Events) {
        this.eventEmitter.off(eventName);
    }

    updateCacheKey(cacheKey: string) {
        let action = updateCacheKey({
            id: this.id,
            cacheKey
        });
        ExtremeGridAPI.dispatch(action);
    }

    showLoading() {
        let action = setLoading({
            id: this.id,
            state: true
        });
        ExtremeGridAPI.dispatch(action);
    }

    hideLoading() {
        let action = setLoading({
            id: this.id,
            state: false
        });
        ExtremeGridAPI.dispatch(action);
    }

    removeDataGrid() {
        let action = removeExtremeGrid({
            id: this.id
        });
        ExtremeGridAPI.dispatch(action);
    }

    setData(dataSource: BCExtremeGridEntities.IDataSource) {
        let action = setData({
            id: this.id,
            dataSource: dataSource
        });
        ExtremeGridAPI.dispatch(action);
    }

    setColumns(columns: BCExtremeGridEntities.IExtremeGridColumn[]) {
        let action = setColumns({
            id: this.id,
            columns
        });
        ExtremeGridAPI.dispatch(action);
    }
    setSelectionIds(selectionIds: string[]) {
        let action = setSelectionIds({
            id: this.id,
            selectionIds: selectionIds
        });
        ExtremeGridAPI.dispatch(action);
    }
    setDataRef(args?: ISetDataRefArgs) {
        let action = setDataRef({
            id: this.id,
            items: args.items,
            totalCount: args.totalCount,
            requestParameters: args.requestParameters,
            setCurrent: args.setCurrent
        });
        ExtremeGridAPI.dispatch(action);
    }

    onDataResolver(resolver: (state: BaseState, dataIds: Array<string | number>, cacheId?: string) => any) {
        this.dataResolver = resolver.bind(this);
    }
    resolveData(state: BaseState, dataIds: Array<string | number>, cacheId?: string) {
        if (this.dataResolver) {
            return this.dataResolver(state, dataIds, cacheId);
        }
    }

    onCustomizeRequestData(customizer: (loadOptions: IExtremeGridLoadOptions) => any) {
        this.requestCustomizer = customizer.bind(this);
    }
    customizeRequestData(loadOptions: IExtremeGridLoadOptions) {
        if (this.requestCustomizer) {
            return this.requestCustomizer(loadOptions);
        }
        return loadOptions;
    }

    private dispatchStateChanging(dgAction: IBaseMutableAction<any>) {
        let action = changeExtremeGridState({
            id: this.id,
            action: dgAction
        });

        ExtremeGridAPI.dispatch(action);
    }

    changeToolbarButtons(toolbarButtons: Array<IRibbonBarItemProps>) {
        let dgAction = BCExtremeGridActionCreators.changeToolbarButtons(toolbarButtons);
        this.dispatchStateChanging(dgAction);
    }

    sort(sortingColumns: Array<BCExtremeGridEntities.ISortingColumn>) {
        let dgAction = BCExtremeGridActionCreators.changeSorting(sortingColumns);
        this.dispatchStateChanging(dgAction);
    }

    clearSorting() {
        this.sort([]);
    }

    changePageIndex(pageIndex: number) {
        let dgAction = BCExtremeGridActionCreators.changePageIndex(pageIndex);
        this.dispatchStateChanging(dgAction);
    }

    changePageSize(pageSize: number) {
        let dgAction = BCExtremeGridActionCreators.changePageSize(pageSize);
        this.dispatchStateChanging(dgAction);
    }

    filter(filters: Array<BCExtremeGridEntities.IFilterItem>) {
        let dgAction = BCExtremeGridActionCreators.changeFilterItems(filters);
        this.dispatchStateChanging(dgAction);
    }

    clearFilter() {
        this.filter([]);
    }

    search(searchText: string) {
        let dgAction = BCExtremeGridActionCreators.changeSearching(searchText);
        this.dispatchStateChanging(dgAction);
    }

    clearCache() {
        let action = clearCacheAsync({
            id: this.id
        });
        ExtremeGridAPI.dispatch(action);
    }

    updateVirtualScrollingOptions(payload: IUpdateVirtualScrollingOptions) {
        let action = updateVirtualScrollingOptions({
            id: this.id,
            ...payload
        });
        ExtremeGridAPI.dispatch(action);
    }

}