import ConfigProvider from 'antd/lib/config-provider';
import en_US from 'antd/lib/locale-provider/en_US';
import tr_TR from 'antd/lib/locale-provider/tr_TR';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import zh_TW from 'antd/lib/locale-provider/zh_TW';
import az_AZ from 'antd/lib/locale-provider/az_AZ';
import * as React from "react";
import { addLocaleData, IntlProvider } from 'react-intl';
import * as en_locale from 'react-intl/locale-data/en';
import * as tr_locale from 'react-intl/locale-data/tr';
import * as ru_locale from 'react-intl/locale-data/ru';
import * as zh_locale from 'react-intl/locale-data/zh';
import * as az_locale from 'react-intl/locale-data/az';
import { getMessages } from '../../BCIntl/helper/getMessages';
import { IIntlProps } from "../entities";

addLocaleData([...en_locale, ...tr_locale, ...ru_locale, ...zh_locale, ...az_locale]);

export default class BCIntlProvider extends React.Component<IIntlProps, {}> {
    constructor(props: IIntlProps) {
        super(props);
        this.selectLang = this.selectLang.bind(this);
    }

    selectLang(defaultLocale: string, locale?: string): any {
        let lc = locale ? locale : defaultLocale;
        switch (lc) {
            case 'tr':
                return tr_TR;
            case 'ru':
                return ru_RU;
            case 'en':
                return en_US;
            case 'zh':
                return zh_TW;
            case 'az':
                return az_AZ;
            default:
                return tr_TR;
        }
    }

    render() {
        const messages = getMessages();

        const defaultLanguage = this.props.defaultLanguage ? this.props.defaultLanguage : "en-US";
        const defaultLocale = defaultLanguage.slice(0, 2);
        const locale = localStorage.locale ? localStorage.locale.slice(0, 2) : defaultLocale;
        const message = messages?.[locale] || messages[defaultLocale];
        const antdLocale = this.selectLang(defaultLocale, locale);

        return (
            <IntlProvider locale={locale} messages={message}>
                <ConfigProvider locale={antdLocale}>
                    {this.props.children}
                </ConfigProvider>
            </IntlProvider>
        );

    }
}