import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { List } from 'immutable';
import { MenuItem, IMenuItem } from '../../../common/entities/IMenuItem';

const getRecentAppsDataSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.recentApps.data;
}

export const getRecentAppsData = createSelector(
    [
        getRecentAppsDataSelector
    ],
    (recentApps: List<MenuItem>) : Array<IMenuItem> => {
        return recentApps.map(app => app.toJS()).toJS();
    }
);

const getRecentAppsLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.recentApps.loading;
}

export const getRecentAppsLoading = createSelector(
    [
        getRecentAppsLoadingSelector
    ],
    (loading: boolean) : boolean => loading
);
