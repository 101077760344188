export const LOAD_TOUR_ACTION = "UITOUR.LOAD_TOUR_ACTION";
export const SET_TOURS_ACTION = "UITOUR.SET_TOURS_ACTION";
export const START_TOUR_ACTION = "UITOUR.START_TOUR_ACTION";
export const STOP_TOUR_ACTION = "UITOUR.STOP_TOUR_ACTION";
export const TOUR_NEXT_ACTION = "UITOUR.TOUR_NEXT_ACTION";
export const TOUR_PREV_ACTION = "UITOUR.TOUR_PREV_ACTION";
export const STOP_TOUR_TRANSACTION = "UITOUR.STOP_TOUR_TRANSACTION";
export const TOUR_NEXT_TRANSACTION = "UITOUR.TOUR_NEXT_TRANSACTION";
export const TOUR_PREV_TRANSACTION = "UITOUR.TOUR_PREV_TRANSACTION";
export const ON_PATH_OPENED_ACTION = "UITOUR.ON_PATH_OPENED_ACTION";
export const CHANGE_TOUR_LOADING_ACTION = "UITOUR.CHANGE_TOUR_LOADING_ACTION";
export const INITIALIZE_UI_TOUR_MANAGER = "UITOUR.INITIALIZE_UI_TOUR_MANAGER";
export const AFTER_STOP_TOUR_TRANSACTION = "UITOUR.AFTER_STOP_TOUR_TRANSACTION";