import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { getUserData } from "../../user/selectors";
import Dashboard from "../components";
import IProps from "../entities/IProps";
import { refreshDashboardDataTransaction } from "../actions";
import { IWebInterfaceAction } from "../../../common/entities";
import { getPanelActiveStatus } from "../../../common/components/appForm/selectors";
import { getDashboardWillRefresh } from "../selectors/DashboardSelector";
import { getDashboardFormData, getHasDashboard } from "../selectors/DashBoardFormDataSelector";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IProps): IProps {
    return {
        user: getUserData(state),
        isActive: getPanelActiveStatus(state, props.panelGuid),
        willRefresh: getDashboardWillRefresh(state),
        dashboardFormData: getDashboardFormData(state),
        hasDashboard: getHasDashboard(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IProps {
    return {
        handleRefresh: function(data: IWebInterfaceAction) {
            dispatch(refreshDashboardDataTransaction(data))
        }
    };
}

// react - redux connection
const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardContainer;