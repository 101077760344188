import IconBreadCrumbArrow from "@bimser/icons/16/breadcrumps-arrow";
import IconClosePanel from "@bimser/icons/16/close-panel";
import * as React from "react";
import { withSize } from "react-sizeme";
import * as Styles from '../assets/style.scss';
import { IPaginationItemProps } from '../entities';
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

function PaginationItem(props: IPaginationItemProps) {

    const onMouseDown = React.useCallback((event: any) => {
        if (event.nativeEvent.which === 3) {
            event.preventDefault();
            event.persist();
            event.stopPropagation();
            if (props.data.onRightClick) {
                props.data.onRightClick(event, props.data);
            }
        }
    }, []);

    const iconOnClick = (e: any) => props.data?.onClickCloseIcon?.(e, props.data);

    const noOp = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    let { isActive, isOnTheScreen, isPinned, title, onClick, hasCloseIcon } = props.data;

    let classNames = cx({
        paginationItem: true,
        isActive,
        isOnTheScreen,
        isPinned,
        hasCloseIcon: hasCloseIcon
    });

    let globalClasses = ["paginationItem"];
    if (isActive) {
        globalClasses.push("isActive")
    }

    const onItemClick = React.useCallback((senderArgs: any) => onClick(senderArgs, props.data), []);

    return (
        <li className={[classNames, globalClasses.join(" ")].join(' ')}>
            <span onContextMenu={noOp} className={"paginationItemSpan"} onClick={onItemClick} title={title} onMouseDown={onMouseDown}>
                {title}
            </span>
            <i className={Styles.arrow}><IconBreadCrumbArrow /></i>
            {
                hasCloseIcon && (<IconClosePanel className={cx({ closeIcon: true })} onClick={iconOnClick} />)
            }
        </li>
    )
}

export default withSize()(PaginationItem)