import * as Styles from './assets/styles.scss';
import message, { ArgsProps } from 'antd/lib/message';
import * as React from 'react';
import InfoWarningIcon from '@bimser/icons/16/toaster-info-warning';
import SuccessIcon from '@bimser/icons/16/toaster-success';
import ErrorIcon from '@bimser/icons/16/toaster-error';

Styles;

const BCMessage = {
    success: (args: ArgsProps) => {
        message.success({
            ...args,
            icon: React.createElement(SuccessIcon)
        });
    },
    loading: (args: ArgsProps) => {
        message.loading({
            ...args,
        });
    },
    warning: (args: ArgsProps) => {
        message.warning({
            ...args,
            icon: React.createElement(InfoWarningIcon)
        });
    },
    error: (args: ArgsProps) => {
        message.error({
            ...args,
            icon: React.createElement(ErrorIcon)
        });
    },
    info: (args: ArgsProps) => {
        message.info({
            ...args,
            icon: React.createElement(InfoWarningIcon)
        });
    },
    open: (args: ArgsProps) => {
        message.open(args);
    },
    destroy: (messageKey: string) => {
        message.destroy(messageKey);
    }
}

export default BCMessage;