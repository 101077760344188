import { Announcement, AnnouncementFactoryFromJS, IAnnouncement } from '../../../../announcements/entities';
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

interface IBaseAnnouncementsWidgetState {
    loading: boolean
}

export interface IAnnouncementsWidgetState extends IBaseAnnouncementsWidgetState {
    data: Array<IAnnouncement>
}

export interface IReadonlyAnnouncementsWidgetState extends IBaseAnnouncementsWidgetState {
    data: List<Announcement>
}

export type AnnouncementsWidgetState = ReadonlyRecord<IReadonlyAnnouncementsWidgetState>;

export const AnnouncementsWidgetStateFactory = Record<IReadonlyAnnouncementsWidgetState>({
    loading: false,
    data: List<Announcement>()
});

export const AnnouncementsWidgetStateFactoryFromJS = function (data: IAnnouncementsWidgetState) {
    const _data: Array<Announcement> = data.data.map((item: IAnnouncement) => {
        return AnnouncementFactoryFromJS(item);
    });
    
    return AnnouncementsWidgetStateFactory({
        ...data,
        data: List<Announcement>(_data)
    })
}