import { createSelector ,OutputSelector} from "reselect";
import { LocationState,IReadonlyLocationState } from "../entities";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import {Record} from 'immutable'

const locationParameters = (state: BaseState) => state.system.location;

const getLocations = createSelector(
    [
        locationParameters
    ],
    (parameters: LocationState) => {
        return parameters.items.toJS();
    }
);

export { getLocations };
