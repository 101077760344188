import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import IAppMainProps from '../app-main/entities/IAppMainProps';


const AppMainContainerLazy = React.lazy(() => import(/* webpackChunkName: "web-startup" */ "../app-main/containers/AppMainContainer"));
function AppMainContainer(props: IAppMainProps) {
    return (
        <React.Suspense fallback={<div></div>}>
            <AppMainContainerLazy {...props} />
        </React.Suspense>
    );
}

const MainApp = ({ match: { params }, location }: RouteComponentProps<{ hash: string }>) => {
    return <AppMainContainer hash={params.hash} location={location} />;
}

export default MainApp;