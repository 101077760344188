import IconDocumentPdf2 from "@bimser/icons/16/document-pdf2";
import IconDocumentExcel from "@bimser/icons/32/document-excel";
import IconDocumentImage from "@bimser/icons/32/document-image";
import IconDocumentPowerpoint from "@bimser/icons/32/document-powerpoint";
import IconDocumentText from "@bimser/icons/32/document-text";
import IconDocumentWord from "@bimser/icons/32/document-word";
import IconFileCS from "@bimser/icons/32/file-cs";
import IconFileCSS from "@bimser/icons/32/file-css";
import IconFileEAF from "@bimser/icons/32/file-eaf";
import IconFileJS from "@bimser/icons/32/file-js";
import IconFileJSON from "@bimser/icons/32/file-json";
import IconFileMP4 from "@bimser/icons/32/file-mp4";
import IconFileTS from "@bimser/icons/32/file-ts";
import IconNewProjFile from "@bimser/icons/32/new-proj-file";
import IconFolder2Compressed from "@bimser/icons/32/folder2-compressed";
import * as React from 'react';
import { TxtExtensionList, SynergyFormExtensionList, PdfExtensionList, WordExtensionList, ImageExtensionList, VideoExtensionList, PowerPointExtensionList, ExcelExtensionList, CompressedExtensionList } from './extensionList';
import mimeTypeExtensionList from './mimeTypeExtensionList';


export enum ExtensionType {
    SynergyFormExtensionList,
    TxtExtensionList,
    WordExtensionList,
    ImageExtensionList,
    VideoExtensionList,
    PowerPointExtensionList,
    ExcelExtensionList,
    PdfExtensionList
}


function importIconByExtension(extension: string) {

    switch (extension) {
        case TxtExtensionList.CS: return IconFileCS;
        case SynergyFormExtensionList.EAF: return IconFileEAF;
        case TxtExtensionList.TS: return IconFileTS;
        case TxtExtensionList.EPROJ: return IconNewProjFile;
        case PdfExtensionList.PDF: return IconDocumentPdf2;
        case TxtExtensionList.CSS: return IconFileCSS;
        case WordExtensionList.DOC: return IconDocumentWord;
        case WordExtensionList.DOCM: return IconDocumentWord;
        case WordExtensionList.DOCX: return IconDocumentWord;
        case WordExtensionList.DOTM: return IconDocumentWord;
        case WordExtensionList.DOTX: return IconDocumentWord;
        case ImageExtensionList.JPEG: return IconDocumentImage;
        case ImageExtensionList.JPG: return IconDocumentImage;
        case ImageExtensionList.PNG: return IconDocumentImage;
        case ImageExtensionList.TIFF: return IconDocumentImage;
        case TxtExtensionList.JS: return IconFileJS;
        case TxtExtensionList.JSON: return IconFileJSON;
        case VideoExtensionList.MP4: return IconFileMP4;
        case PowerPointExtensionList.POTM: return IconDocumentPowerpoint;
        case PowerPointExtensionList.PPAM: return IconDocumentPowerpoint;
        case PowerPointExtensionList.PPSM: return IconDocumentPowerpoint;
        case PowerPointExtensionList.PPT: return IconDocumentPowerpoint;
        case PowerPointExtensionList.PPTM: return IconDocumentPowerpoint;
        case PowerPointExtensionList.PPTX: return IconDocumentPowerpoint;
        case PowerPointExtensionList.SLDM: return IconDocumentPowerpoint;
        case TxtExtensionList.TXT: return IconDocumentText;
        case ExcelExtensionList.XLAM: return IconDocumentExcel;
        case ExcelExtensionList.XLS: return IconDocumentExcel;
        case ExcelExtensionList.XLSB: return IconDocumentExcel;
        case ExcelExtensionList.XLSM: return IconDocumentExcel;
        case ExcelExtensionList.XLSX: return IconDocumentExcel;
        case ExcelExtensionList.XLTM: return IconDocumentExcel;
        case ExcelExtensionList.XLTX: return IconDocumentExcel;
        case CompressedExtensionList.ZIP: return IconFolder2Compressed;
        case CompressedExtensionList.RAR: return IconFolder2Compressed;
        case CompressedExtensionList.TAR: return IconFolder2Compressed;
        case CompressedExtensionList.GZ: return IconFolder2Compressed;
        default: return IconDocumentText;
    }
}

export function getIconByMimeType(mimeType: string) {


    const index = mimeTypeExtensionList.findIndex(t => t.mimeType.includes(mimeType));

    if (index != -1) {
        return getIconByExtension(mimeTypeExtensionList[index].extension[0]);
    } else
        return getIconByExtension(); //return txt icon by default
}

export function getIconInfoByExtension(extension: string) {
    const Icon = importIconByExtension(extension);
    return Icon.info;
}

export default function getIconByExtension(extension?: string, className?: string) {
    const wh = "32px";

    const Icon = importIconByExtension(extension?.toLowerCase());

    return <Icon width={wh} height={wh} className={className} />
}

export function getExtensions(extensiontypes: ExtensionType[] = []): string[] {
    let extensionList: string[] = [];

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.SynergyFormExtensionList))
        for (const extension in SynergyFormExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.TxtExtensionList))
        for (const extension in TxtExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.WordExtensionList))
        for (const extension in WordExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.ImageExtensionList))
        for (const extension in ImageExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.VideoExtensionList))
        for (const extension in VideoExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.PowerPointExtensionList))
        for (const extension in PowerPointExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.ExcelExtensionList))
        for (const extension in ExcelExtensionList) {
            extensionList.push(extension);
        }

    if (extensiontypes.length == 0 || extensiontypes.includes(ExtensionType.PdfExtensionList))
        for (const extension in PdfExtensionList) {
            extensionList.push(extension);
        }

    return extensionList
}