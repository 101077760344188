import { IItemType, isNullOrUndefined } from "@bimser/core";
import IconBack from "@bimser/icons/16/back";
import IconHome from "@bimser/icons/16/home";
import IconRefresh from "@bimser/icons/16/refresh";
import IconTabNavRight from "@bimser/icons/16/tab-nav-right";
import * as React from "react";
import { BCButton, BCInputGroup, BCInputSearch, BCScrollbars, findMessage, IButtonClickEventArgs, IInputChangeEventArgs, IScrollbarsProps } from "..";
import * as Style from "./assets/style.scss";
import { FileExplorerHistoryItem, IFileExplorerItemClickEventArgs, IFileExplorerItemEvents, IFileExplorerItemProps, IFileExplorerProps, IFileExplorerState, IFileExplorerToolbar } from './entities';
import FileExplorerItem from './FileExplorerItem';

var classNames = require('classnames/bind');
let cx = classNames.bind(Style);

export default class BCFileExplorer2 extends React.Component<IFileExplorerProps, null> {

    private scrollbarProps: IScrollbarsProps;
    backButtonIconCss: React.CSSProperties = {
        marginLeft: 'auto',
        marginRight: 'auto'
    }
    searchBarStyle: React.CSSProperties = { width: '250px', marginLeft: 10, float: 'left' };
    toolbarGroupStyle: React.CSSProperties = { width: 'calc(100% - 260px)', float: 'left' };

    constructor(props: IFileExplorerProps) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.onItemDoubleClick = this.onItemDoubleClick.bind(this);
        this.onReload = this.onReload.bind(this);
        this.onBodyClick = this.onBodyClick.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClickToolbarButton = this.onClickToolbarButton.bind(this);
        this.onPathClick = this.onPathClick.bind(this);
        this.overridePathDisplay = this.overridePathDisplay.bind(this);
        this.onClickRootPath = this.onClickRootPath.bind(this);

        this.scrollbarProps = {
            autoHide: true,
            styles: {
                height: '385px',
                width: '100%'
            }
        }

        if (props.scrollbarProps) {
            this.scrollbarProps = props.scrollbarProps;
        }
    }

    onChangeSelection(item?: IFileExplorerItemProps) {
        if (this.props.onChangeSelection) {
            if (!isNullOrUndefined(item))
                this.props.onChangeSelection({
                    ...item,
                    path: item && item.path ? item.path + "/" + item.text : item.text
                });
            else
                this.props.onChangeSelection(null);
        }
    }

    onReload(e: IButtonClickEventArgs) {
        e.event.stopPropagation();
        if (this.props.onReload) {
            this.props.onReload();
        }
    }

    onItemClick(args: IFileExplorerItemClickEventArgs) {
        this.onChangeSelection(args.data);

    }
    onItemDoubleClick(args: IFileExplorerItemClickEventArgs) {
        if (this.props.onOpenItem) {
            let path = args.data.path && args.data.path.length > 0 ? args.data.path + "/" + args.data.text : args.data.text
            this.props.onOpenItem({
                ...args.data,
                path
            });
        }
    }

    onBodyClick() {
        this.onChangeSelection(null);
    }

    getExtenstion(path?: string) {
        if (path) {
            let pathArgs = path.split("/");
            let extensionArgs = pathArgs[pathArgs.length - 1].split(".");
            if (extensionArgs.length == 1) {
                return "";
            }
            return extensionArgs[extensionArgs.length - 1];
        }
    }

    getItems() {
        if (this.props.items) {
            return this.props.items.map((item) => {
                return <FileExplorerItem
                    key={item.id}
                    id={item.id}
                    icon={item.icon}
                    text={item.text}
                    type={item.type}
                    selected={item.selected}
                    secretKey={item.secretKey}
                    dataRef={item.dataRef}
                    onClick={this.onItemClick}
                    onDoubleClick={this.onItemDoubleClick}
                    path={item.path || ""}
                    fullPath={item.fullPath}
                    extension={item.extension}
                />
            })
        }
    }

    renderItems() {
        return (
            <div className={Style.fileExplorerItemContainer}>
                {this.getItems()}
            </div>
        )
    }
    onClickToolbarButton(e: IButtonClickEventArgs) {
        e.event.stopPropagation();
        if (this.props.onClickUp) {
            this.props.onClickUp();
        }
    }

    onPathClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: FileExplorerHistoryItem) {
        e.stopPropagation();
        if (this.props.onPathClick && data) {
            this.props.onPathClick(data);
        }
    }

    overridePathDisplay() {

        let pathArr = this.props.path.split("/").filter(Boolean);

        return pathArr.map((i, index) => {
            return (
                <div key={index}>
                    <IconTabNavRight />
                    <span title={i} onClick={(e) => { this.onPathClick(e, this.props.historyItems.find(h => h.name === i)) }}>{i}</span>
                </div>
            )
        })

    }

    onClickRootPath(e: IButtonClickEventArgs) {
        e.event.stopPropagation();
        if (this.props.onOpenItem) {
            this.props.onClickRootPath();
        }
    }

    renderToolbar() {
        return (
            <div className={Style.fileExplorerToolbar}>
                <BCInputGroup compact style={this.toolbarGroupStyle} cssClassName={Style.toolbarInputGroup}>
                    <BCButton
                        title={findMessage.get('100709')} // Üst dizine çık
                        disabled={!this.props.canGoUp}
                        onClick={this.onClickToolbarButton}
                        icon={<IconBack />}
                        cssClass={Style.backBtn}
                    />
                    <BCButton
                        title={findMessage.get("100764")} // Main directory
                        onClick={this.onClickRootPath}
                        icon={<IconHome />}
                        cssClass={Style.homeBtn}
                    />
                    <div className={Style.fileExplorerPathbar}>
                        {this.overridePathDisplay()}
                    </div>
                    <BCButton
                        title={findMessage.get('100025')} // Yenile
                        onClick={this.onReload}
                        icon={<IconRefresh />}
                        cssClass={Style.refreshBtn}
                    />

                </BCInputGroup>
                {this.renderSearchBar()}
            </div>
        )
    }
    onSearch(args: any) {
        let value: string = typeof args === "string" ? args : args.data;
        if (this.props.onSearch) {
            this.props.onSearch(value);
        }
    }
    renderSearchBar() {
        return (
            <BCInputSearch
                value={this.props.searchText}
                placeHolder={findMessage.get('100002')}
                style={this.searchBarStyle}
                onSearch={this.onSearch}
                onChange={this.onSearch}
                className={Style.inputSearch}
            />
        )
    }

    render() {

        return (
            <div className={[Style.fileExplorerContainer].join(' ')} onClick={this.onBodyClick}>
                {this.renderToolbar()}
                {
                    (this.props.useScrollbar && this.props.useScrollbar == true) ? (
                        <BCScrollbars {...this.scrollbarProps} cssClass={Style.scrollbarContainer}>
                            {this.renderItems()}
                        </BCScrollbars>
                    ) : (this.renderItems())
                }
            </div>
        )

    }
}

export { IFileExplorerProps, IFileExplorerState, IFileExplorerToolbar, IFileExplorerItemProps, IFileExplorerItemEvents, IFileExplorerItemClickEventArgs, IInputChangeEventArgs, IItemType };

