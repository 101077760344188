import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { Activity, ActivityFactoryFromJS, IActivity } from "./IActivity";
import { ActivityPanelContent, ActivityPanelContentFactoryFromJS, IActivityPanelContent } from "./IActivityPanelContent";

interface IBaseActivitiesState {

}

export interface IActivitiesState extends IBaseActivitiesState {
    panels: IDictionary<IActivityPanelContent>,
}

export interface IReadonlyActivitiesState extends IBaseActivitiesState {
    panels: Map<string, ActivityPanelContent>,
}

export type ActivitiesState = ReadonlyRecord<IReadonlyActivitiesState>;

export const ActivitiesStateFactory = Record<IReadonlyActivitiesState>({
    panels: Map(),
});

export const ActivitiesStateFactoryFromJS = function (data: IActivitiesState) {
    let _panels: IDictionary<ActivityPanelContent> = Object.keys(data.panels).reduce(function (previous: IDictionary<ActivityPanelContent>, current) {
        previous[current] = ActivityPanelContentFactoryFromJS(data.panels[current]);
        return previous;
    }, {});

    return ActivitiesStateFactory({
        ...data,
        panels: Map(_panels),
    });
}