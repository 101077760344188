import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, List, Map } from "immutable";
import { IDelegation, Delegation, DelegationFactoryFromJS } from "./IDelegation";
import { IDelegationFormData, DelegationFormData, DelegationFormDataFactory, DelegationFormDataFactoryFromJS } from "./IDelegationFormData";
import { IPermissionScope, PermissionScope, PermissionScopeFactory, PermissionScopeFactoryFromJS } from "./IPermissionScope";

interface IBaseDelegationState {

}

export interface IDelegationState extends IBaseDelegationState {
    scopeData: IPermissionScope[]
    tokens: number[],
    data: IDictionary<IDelegation>,
    taken: number[],
    given: number[],
    form: IDelegationFormData
}

export interface IReadonlyDelegationState extends IBaseDelegationState {
    scopeData: List<PermissionScope>,
    tokens: List<number>,
    data: Map<string, Delegation>,
    taken: List<number>,
    given: List<number>,
    form: DelegationFormData,
}

export type DelegationState = ReadonlyRecord<IReadonlyDelegationState>;

export const DelegationStateFactory = Record<IReadonlyDelegationState>({
    taken: List(),
    given: List(),
    data: Map(),
    form: DelegationFormDataFactory(),
    scopeData: List(),
    tokens: List(),
});

export const DelegationStateFactoryFromJS = function (data: IDelegationState) {
    let _data: IDictionary<Delegation> = Object.keys(data.data).reduce(function (previous: IDictionary<Delegation>, current) {
        previous[current] = DelegationFactoryFromJS(data.data[current]);
        return previous;
    }, {});

    let _scopeData = data && data.scopeData ? data.scopeData.map(d => PermissionScopeFactoryFromJS(d)) : [];

    return DelegationStateFactory({
        data: Map(_data),
        taken: List(data.taken),
        given: List(data.given),
        form: DelegationFormDataFactoryFromJS(data.form),
        scopeData: List(_scopeData),
        tokens: List(data.tokens),
    });
}