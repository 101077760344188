import * as React from "react";
import * as Styles from "../assets/style.scss";
import IImageViewerProps from "../entities/ImageViewer/IImageViewerProps";

export default class ImageViewer extends React.Component<IImageViewerProps, any> {
    constructor(props: IImageViewerProps) {
        super(props);
        this.generateTemplate = this.generateTemplate.bind(this);
    }

    generateTemplate(): JSX.Element {
        return <img src={this.props.src} height={this.props.height} width={this.props.width} />;
    }

    render() {
        return (
            <div className={Styles.imageViewerContent}>
                {this.generateTemplate()}
            </div>
        )
    }
}