import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";
import { IWidgetState, WidgetState, WidgetStateFactory, WidgetStateFactoryFromJS } from "./widgets/model";
import { DashboardFormData, DashboardFormDataFactory, DashboardFormDataFactoryFromJS, IDashboardFormData } from "./IDashboardFormData";

export interface IDashboardState {
    tileCounts: IDictionary<number>,
    widgets: IWidgetState,
    dashboardType: "user" | "admin",
    dashboardFormData: IDashboardFormData
}

export interface IReadonlyDashboardState {
    tileCounts: Map<string, number>,
    widgets: WidgetState,
    dashboardType: "user" | "admin",
    dashboardFormData: DashboardFormData
}

export type DashboardState = ReadonlyRecord<IReadonlyDashboardState>;

export const DashboardStateFactory = Record<IReadonlyDashboardState>({
    tileCounts: Map<string, number>(),
    widgets: WidgetStateFactory(),
    dashboardType: "user",
    dashboardFormData: DashboardFormDataFactory()
});

export const DashboardStateFactoryFromJS = function (data: IDashboardState) {
    return DashboardStateFactory({
        ...data,
        tileCounts: Map(data.tileCounts),
        widgets: WidgetStateFactoryFromJS(data.widgets),
        dashboardFormData: DashboardFormDataFactoryFromJS(data.dashboardFormData)
    })
}