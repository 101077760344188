import * as React from "react";
import * as ReactDOM from "react-dom";

import Button from 'antd/lib/button';
import { IButtonGroupProps } from './entities';
import BCDivider from '../BCDivider';

import * as Styles from './assets/styles.scss';

const ButtonGroup = Button.Group;

export default class BCButtonGroup extends React.Component<IButtonGroupProps, {}> {
    constructor(props: IButtonGroupProps) {
        super(props);
        Styles;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.holder ? <span>#</span> : null}
                <ButtonGroup className={this.props.className}>
                    {this.props.children}
                </ButtonGroup>
                {this.props.divider ? <BCDivider type="vertical" /> : null}
            </React.Fragment>
        );
    }
}

export { IButtonGroupProps }