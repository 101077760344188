import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { Map } from 'immutable';
import { IDictionary } from '@bimser/core';
import { TileTypes } from '../entities/widgets/TileTypes';

const getTilesCountsSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.countTiles.tileCounts;
}

const getSelectedTileSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.countTiles.selectedTile;
}

const getTilesLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.countTiles.loading;
}

export const getTilesCounts = createSelector(
    [
        getTilesCountsSelector
    ],
    (counts: Map<string, number>) : IDictionary<number> => {
        return counts?.toJS() as IDictionary<number>;
    }
);

export const getSelectedTile = createSelector(
    [
        getSelectedTileSelector
    ],
    (selectedTile: TileTypes) : TileTypes => selectedTile
);

export const getTilesLoading = createSelector(
    [
        getTilesLoadingSelector
    ],
    (loading: boolean) : boolean => loading
);
