import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record, List } from "immutable";
import { IFieldsContent, FieldsContent, FieldsContentFactoryFromJS } from "./IFields";

export interface IBaseProfiles {
    projectId: string,
    projectName: string,
    formId: string,
    formName: string,
    isRequired: boolean,
    type: number
}

export interface IProfilesContent extends IBaseProfiles {
    fields: Array<IFieldsContent>
}

export interface IReadonlyProfiles extends IBaseProfiles {
    fields: List<FieldsContent>
}

export type ProfilesContent = ReadonlyRecord<IReadonlyProfiles>;

export const ProfilesFactory = Record<IReadonlyProfiles>({
    projectId: "",
    projectName: "",
    formId: "",
    formName: "",
    isRequired: false,
    type: 0,
    fields: List()
});

export const ProfilesContentFactoryFromJS = function (data: IProfilesContent) {

    let _fields: Array<FieldsContent> = (data && data.fields) ? data.fields.map((d) => {
        return FieldsContentFactoryFromJS(d);
    }) : [];

    return ProfilesFactory({
        ...data,
        fields: List(_fields)
    });
}