import Tabs from 'antd/lib/tabs';
import * as React from "react";
import BCScrollbars from "../BCScrollbars";
import * as Styles from './assets/style.scss';
import { ITabItem, ITabProps } from './entities';
import { GetIconComponent } from "@bimser/icons";
import classNames from 'classnames/bind';

const cx = classNames.bind(Styles)

export default class BCTabs extends React.Component<ITabProps, {}> {

    constructor(props: ITabProps) {
        super(props);
    }

    renderTabBarItem(item: ITabItem) {
        return (
            <React.Fragment>
                {item.icon && (this.props.renderCustomIcon ? this.props.renderCustomIcon(item) : GetIconComponent(item.icon))}
                <span title={item.label}>{item.label}</span>
            </React.Fragment>
        )
    }

    _renderContentItem(tabItem: ITabItem) {
        if (this.props.renderTabContent) return this.props.renderTabContent(tabItem);
    }

    renderContentItem(tabItem: ITabItem) {
        if (this.props.hasScrollbar || tabItem.hasScrollbarContent) {
            return (<BCScrollbars autoHide={true}> {this._renderContentItem(tabItem)}</BCScrollbars>);
        }
        else {
            return this._renderContentItem(tabItem);
        }
    }

    render() {
        const TabPane = Tabs.TabPane;
        let selectedTab;

        if (this.props.items.length) {
            selectedTab = Array.prototype.filter.call(this.props.items, (item: ITabItem) => item.selected);
            if (selectedTab.length) {
                selectedTab = selectedTab[0].key
            } else {
                selectedTab = this.props.items[0].key
            }
        }

        const tabsClassname = cx({
            readOnlyCursor: this.props.readOnly,
            tabContainer: true,
            [this.props.cssClass ?? ""]: true
        });

        return (
            <Tabs
                id={this.props.id}
                tabBarExtraContent={this.props.tabBarExtraContent}
                defaultActiveKey={selectedTab}
                className={tabsClassname}
                style={this.props.style}
                onChange={this.props.onChange}
                onTabClick={this.props.onTabClick}
                tabBarGutter={0}
                tabBarStyle={this.props.tabBarStyle}
                renderTabBar={this.props.renderTabBar}
                tabPosition={this.props.tabPosition}
                type={this.props.type}
                hideAdd={this.props.hideAdd}
                onEdit={this.props.onEdit}
                destroyInactiveTabPane={this.props.destroyInactiveTabPane}
                {...(this.props.activeKey ? { activeKey: this.props.activeKey } : {})}>
                {this.props.items.map((i: ITabItem) => {
                    return (
                        <TabPane
                            className={i.className}
                            tab={this.renderTabBarItem(i)}
                            key={i.key}
                            disabled={i.disabled}
                            style={i.style}
                            closable={i.closable}
                        >
                            {this.renderContentItem(i)}
                        </TabPane>
                    )
                })}
            </Tabs>
        );
    }

}

export { ITabProps, ITabItem };
