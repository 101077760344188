import { BaseState } from "../../../entities/BaseState";
import { createSelector } from "reselect";
import { List } from 'immutable';
import { LoginSliderItem } from '../entities/ILoginSliderItem';

const getLoginSliderItemsSelector = (state: BaseState) => {
    return state.system.loginSliderItems;
}

export const getLoginSliderItems = createSelector(
    [getLoginSliderItemsSelector],
    (items: List<LoginSliderItem>) => items.toJS()
);