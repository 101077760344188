import Card from 'antd/lib/card';
import * as React from "react";
import * as Styles from './assets/styles.scss';
import { ICardGridProps, ICardMetaProps, ICardProps, ICardSizes, ICardTabListProps } from './entities';

export default class BCCard extends React.Component<ICardProps, {}> {
    constructor(props: ICardProps) {
        super(props);
        Styles;
    }

    render() {
        const { actions, activeTabKey, headStyle, bodyStyle, bordered, cover, defaultActiveTabKey, extra, hoverable, loading, size, title, type, onTabChange, children, className, style } = this.props;

        return (
            <React.Fragment>
                <Card
                    className={className}
                    actions={actions}
                    activeTabKey={activeTabKey}
                    headStyle={headStyle}
                    bodyStyle={bodyStyle}
                    bordered={bordered}
                    cover={cover}
                    defaultActiveTabKey={defaultActiveTabKey}
                    extra={extra}
                    hoverable={hoverable}
                    loading={loading}
                    size={size}
                    title={title}
                    type={type}
                    onTabChange={onTabChange}
                    style={style}
                >
                    {children}
                </Card>
            </React.Fragment>
        );
    }
}
const { Meta, Grid } = Card;

export { ICardProps, ICardSizes, ICardGridProps, ICardMetaProps, ICardTabListProps, Meta as BCCardMeta, Grid as BCCardGrid };

