import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { List } from 'immutable';
import { Announcement, IAnnouncement } from '../../announcements/entities';

const getAnnouncementsDataSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.announcements.data || List<Announcement>([]);
}

export const getAnnouncementsData = createSelector(
    [
        getAnnouncementsDataSelector
    ],
    (announcements: List<Announcement>) : Array<IAnnouncement> => {
        return announcements.map(announcement => announcement.toJS()).toJS();
    }
);

const getAnnouncementsLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.announcements.loading || false;
}

export const getAnnouncementsLoading = createSelector(
    [
        getAnnouncementsLoadingSelector
    ],
    (loading: boolean) : boolean => loading
);
