import { isNullOrUndefined } from '@bimser/core';
import { InputRef } from 'antd';
import classNames from 'classnames/bind';
import * as React from "react";
import BCInput from "../BCInput";
import * as Styles from './assets/styles.scss';
import IInputMaskProps from "./entities/IInputMaskProps";
const ReactInputMask = require('react-input-mask/dist/react-input-mask');
const cx = classNames.bind(Styles);

const BCInputMask = React.memo(React.forwardRef<InputRef, IInputMaskProps>((props, ref,) => {
    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
        if (!isNullOrUndefined(props.value)) setValue(props.value)
    }, [props.value])

    const formatChars = React.useMemo(() => ({ 9: "[0-9]", a: "[A-Za-z]", "*": "[A-Za-z0-9]", ...props.formatChars }), [props.formatChars])

    const classes = React.useMemo(() => cx({
        [props.className]: true,
        inputMaskStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center"
    }), [props.className, props.textAlign])

    const getInputSize = React.useCallback((size: string) => {
        switch (size) {
            case "small": return 25;
            case "middle": return 40;
            case "large": return 50;
            default: return 25;
        }
    }, [])

    const getReverseInputSize = React.useCallback((size: number) => {
        switch (size) {
            case 25: return "small";
            case 40: return "middle";
            case 50: return "large";
            default: return "small";
        }
    }, [])

    const onChange = (event: any) => {
        const _value = event?.senderArgs?.target?.value || "";

        if (value !== _value) {
            setValue(_value);
            props.onChange?.({ data: _value });
        }
    }

    const renderAsMaskInput = () => {
        return <ReactInputMask
            {...props}
            size={getInputSize(props.size)}
            maskChar={null}
            onChange={onChange}
            value={value || ""}
            formatChars={formatChars}
            alwaysShowMask={false}
        >
            {inputProps => <BCInput
                className={classes}
                {...inputProps}
                size={getReverseInputSize(inputProps.size)}
                isMaskInput
                tabIndex={props.tabIndex}
                disabled={props.disabled}
                readOnly={props.readOnly}
            />}
        </ReactInputMask>
    }


    if (props.mask) return renderAsMaskInput();

    return <BCInput
        ref={ref}
        tabIndex={props.tabIndex}
        placeHolder={props.placeHolder}
        value={value}
        onChange={props.onChange}
        style={props.style}
        disabled={props.disabled}
        readOnly={props.readOnly}
        className={classes}
        size={props.size}
    />
}))

export default BCInputMask
export { IInputMaskProps };
