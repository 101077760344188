export enum ValidateStatus {
    success = 'success',
    warning = 'warning',
    error = 'error',
    validating = 'validating'
}
export interface IColSize {
    span?: number;
    order?: number;
    offset?: number;
    push?: number;
    pull?: number;
}
export interface IColProps extends React.HTMLAttributes<HTMLDivElement> {
    span?: number;
    order?: number;
    offset?: number;
    push?: number;
    pull?: number;
    xs?: number | IColSize;
    sm?: number | IColSize;
    md?: number | IColSize;
    lg?: number | IColSize;
    xl?: number | IColSize;
    xxl?: number | IColSize;
    prefixCls?: string;
}
export interface IFormItemProps {
    itemkey?: string;
    label?: React.ReactNode;
    help?: React.ReactNode;
    required?: boolean;
    validateStatus?: ValidateStatus;
    prefixCls?: string;
    className?: string;
    labelCol?: IColProps;
    wrapperCol?: IColProps;
    extra?: React.ReactNode;
    hasFeedback?: boolean;
    style?: React.CSSProperties;
    colon?: boolean;
    labelAlign?: "left" | "right"
}