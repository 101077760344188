import { panelType } from '@bimser/common';
import { BCCard, BCList, BCSkeleton, ContentType, findMessage } from '@bimser/components';
import React from 'react';
import Style from '../../assets/style.scss';
import IAnnouncementsProps from '../../entities/widgets/IAnnouncementsProps';
import IconGo from "@bimser/icons/16/go";
import IconAnnouncement from "@bimser/icons/32/announcement"
import { IAnnouncement } from '../../../announcements/entities';
import { MLHelper } from '@bimser/core';
import IconHover from "@bimser/icons/16/sub-menu-arrow"
import { formatDates } from '../../utils';

const emptyView = require("../../../../assets/announcements-empty.svg");
const cx = require('classnames/bind').bind(Style);

export default (props: IAnnouncementsProps) => {

    const onClickAnnouncement = (secretKey: string) => {
        props.onClickAnnouncement({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: secretKey
        })
    }

    const onClickAllAnnouncements = () => {
        props.onClickAllAnnouncements({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: "#Recent"
        })
    }

    const renderCustomEmpty = () => (
        <div className={cx({ emptyView: true, inCard: true })}>
            <img src={emptyView.default} className={Style.emptyViewImg} />
            <span className={Style.emptyViewTitle}>{findMessage.get("102967")}</span>
            <span className={Style.emptyViewDesc}>{findMessage.get("102968")}</span>
        </div>
    )

    const renderItem = (item: IAnnouncement) => (
        <div className={Style.announcementItemContainer} onClick={() => onClickAnnouncement(item.secretKey)}>
            <div className={Style.announcementItem}>
                <div className={Style.announcementItemIcon}>
                    <IconAnnouncement width="28px" height="28px" />
                </div>
                <div className={Style.announcementItemText}>
                    <span className={cx({ announcementItemName: true, unRead: !item.isViewed })}>{MLHelper.getMLText(item.subject)}</span>
                    <span className={Style.announcementItemCreateInfo}>{formatDates(item.createdAt)}</span>
                </div>
            </div>
            <IconHover className={Style.iconHover} />
        </div>
    )

    const renderHeader = (
        <div className={Style.announcementsHeader}>
            <span className={Style.widgetHeader}>{findMessage.get("101048")}</span>
            {props.data && <span className={Style.announcementsShowAll} onClick={onClickAllAnnouncements} >{findMessage.get("102977")} <IconGo width="14px" height="14px" /></span>}
        </div>
    )
    
    
    return (
        <BCCard
            bordered={false}
            className={Style.announcementsContainer}
            title={renderHeader}
        >
            <BCSkeleton
                loadingStatus={props.loading}
                contentType={ContentType.DashboardAnnouncementsList}
            >
                <BCList
                    bordered={false}
                    dataSource={props.data}
                    customEmpty={renderCustomEmpty()}
                    renderItem={renderItem}
                    className={Style.announcementsList}
                />
            </BCSkeleton>
        </BCCard>
    )
}