import React, { useState } from 'react';
import { IMobileRangePickerProps, IDisableDate } from './entities'
import * as Styles from './assets/rangePickerStyles.scss';
import { BCMobileDateTimePicker, RangePickerValue } from '../';
import IconComparison from "@bimser/icons/16/comparison";
import IconCalendarWeekly from "@bimser/icons/16/calendar-weekly";
import moment from 'moment';

export default function BCMobileRangePicker(props: IMobileRangePickerProps) {

    const [startDate, setStartDate] = useState<moment.Moment>(props.value[0]);
    const [endDate, setEndDate] = useState<moment.Moment>(props.value[1]);


    function onChangeStartDate(date: moment.Moment, isoZoneString: string, dateString: string, shortISODateString: string, longISODate: string, dateTimeOffsetString: string) {
        setStartDate(date);
        onChange([date, endDate], [startDate?.format() || "", endDate?.format() || ""]);
    }

    function onChangeEndDate(date: moment.Moment, isoZoneString: string, dateString: string, shortISODateString: string, longISODate: string, dateTimeOffsetString: string) {
        setEndDate(date);
        onChange([startDate, date], [startDate?.format() || "", endDate?.format() || ""]);
    }

    function onChange(dates: RangePickerValue, dateStrings: [string, string]) {
        if (props.onChange) {

            let _startDate = null;
            let _endDate = null;

            if (dates) {
                _startDate = dates[0] ? dates[0].format() : null;
                _endDate = dates[1] ? dates[1].format() : null;
            }

            props.onChange(dates, [_startDate, _endDate], dateStrings);
        }
    }

    function getDisableBefore(): IDisableDate {

        switch (props.disabledDateType) {
            case "past":
                return {
                    enabled: true,
                    years: moment().year(),
                    months: moment().month(),
                    days: moment().date()
                }
            case "after":
                return {};
            case "before":
                return {};
            case "dynamicRange":
                return {
                    enabled: true,
                    years: moment(new Date(props.disabledDates[0])).year(),
                    months: moment(new Date(props.disabledDates[0])).month(),
                    days: moment(new Date(props.disabledDates[0])).date()
                }
            default:
                return {};
        }

    }


    function getDisableAfter() {

        switch (props.disabledDateType) {
            case "past":
                return {};
            case "after":
                return {
                    enabled: true,
                    years: moment(new Date(props.disabledDates[0])).year(),
                    months: moment(new Date(props.disabledDates[0])).month(),
                    days: moment(new Date(props.disabledDates[0])).date()
                };
            case "before":
                return {};
            case "dynamicRange":
                return {
                    enabled: true,
                    years: moment(new Date(props.disabledDates[1])).year(),
                    months: moment(new Date(props.disabledDates[1])).month(),
                    days: moment(new Date(props.disabledDates[1])).date()
                };

            default:
                return {};
        }

    }

    return (
        <>
            <div className={["ant-picker ant-picker-range ant-picker-middle", Styles.picker].join(" ")}>
                <div className="ant-picker-input ant-picker-input-active">
                    <BCMobileDateTimePicker
                        disableHours={props.startDate.disabledHours}
                        disableMinutes={props.startDate.disabledMinutes}
                        disableSeconds={props.startDate.disabledSeconds}
                        value={props.value[0]}
                        format={props.format as string}
                        disableAfter={props.disableAfter ? [props.disableAfter, getDisableAfter()] : [getDisableAfter()]}
                        disableBefore={props.disableBefore ? [props.disableBefore, getDisableBefore()] : [getDisableBefore()]}
                        onChange={onChangeStartDate}
                        showTime={props.showTime}
                        disabledDateType={props.disabledDateType}
                        activeDateType={props.activeDateType}
                        allowClear={props.allowClear}
                        readOnly={props.readOnly}
                        disabled={props.disabled}

                    />
                </div>
                <div className="ant-picker-range-separator">
                    <span className="ant-picker-separator">
                        <span className="anticon anticon-swap-right">
                            {<IconComparison />}
                        </span>
                    </span>
                </div>
                <div className="ant-picker-input">
                    <BCMobileDateTimePicker
                        value={props.value[1]}
                        format={props.format as string}
                        disableAfter={props.disableAfter ? [props.disableAfter, getDisableAfter()] : [getDisableAfter()]}
                        disableBefore={props.disableBefore ? [props.disableBefore, getDisableBefore()] : [getDisableBefore()]}
                        onChange={onChangeEndDate}
                        disableHours={props.endDate.disabledHours}
                        disableMinutes={props.endDate.disabledMinutes}
                        disableSeconds={props.endDate.disabledSeconds}
                        showTime={props.showTime}
                        disabledDateType={props.disabledDateType}
                        activeDateType={props.activeDateType}
                        allowClear={props.allowClear}
                        readOnly={props.readOnly}
                        disabled={props.disabled}
                    />
                </div>
                <span className="ant-picker-suffix">
                    <span className="anticon anticon-calendar">
                        {<IconCalendarWeekly />}
                    </span>
                </span>
            </div>
        </>
    );
}