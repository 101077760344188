import { BCAjax } from "@bimser/components";
import { ServiceList } from "../services/serviceList";

// export interface IWebInterfaceServices extends IDictionary<BCAjaxRequestConfig> {
//     login: BCAjaxRequestConfig,
//     main: BCAjaxRequestConfig,
//     workflow: BCAjaxRequestConfig,
//     hr: BCAjaxRequestConfig,
//     dm: BCAjaxRequestConfig,
//     Studio: BCAjaxRequestConfig
// }

function getServicesConfig(): any {
    return (<any>window).serviceConfig.services;
}

export function initalizeServices() {
    BCAjax.createInstances<any>(getServicesConfig(), {
        onRefreshToken: () => {
            return BCAjax.getInstances<any>().login.get("Login/Refresh");
        }
    });
    initalizeServiceList();
}

export default BCAjax.getInstances<any>();

function initalizeServiceList() {
    let findController = (controllers: any, service: string, controller: string) => {
        for (const c in controllers) {
            if (controllers[c].setDefinition) {
                controllers[c].setDefinition({
                    service: service,
                    controller: controller,
                    method: c
                })
            }
            else {
                let tmpController = controller + (controller ? "/" : "") + c;
                findController((controllers as any)[c], service, tmpController);
            }
        }
    }

    for (const s in ServiceList) {
        findController((ServiceList as any)[s], s, "")
    }
}
