import { LoginUserFactory } from "@bimser/common";
import { IBreadCrumbItemProps, IBreadCrumbProps } from "@bimser/components";
import { List } from "immutable";
import createCachedSelector, { LruObjectCache } from "re-reselect";
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { ContentLayoutEntities } from '../layouts/content-layout/entities';
import { WebInterfaceBaseState } from "../../common/base/state";
import Utils from "../../common/utils";
import { mobile } from '@bimser/core';
import { ViewHideInfo } from "app-main/entities/IViewHideInfo";

const getEntities = (state: WebInterfaceBaseState) => state.layouts.contentLayout.entities;
const getResult = (state: WebInterfaceBaseState) => state.layouts.contentLayout.result.panels;
const getUserDataSelector = (state: WebInterfaceBaseState) => state.user.data;
const getViewHideInfoSelector = (state: WebInterfaceBaseState) => state.layouts.redirect.viewHideInfo;

const shallowEqual: any = require("fbjs/lib/shallowEqual");

const breadCrumbItemSelector = createCachedSelector(
    [
        (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => id,
        (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => title,
        (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => isOnScreen,
        (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => isPinned,
        (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => isActive,
        (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => isHidden,
    ],
    (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean): IBreadCrumbItemProps => {
        return {
            id: id,
            title: title,
            isOnTheScreen: isOnScreen,
            isPinned: isPinned,
            isActive: isActive,
            isHidden: isHidden,
            hasCloseIcon: !mobile().any()
        };
    }
)(
    (id: string, title: string, isOnScreen: boolean, isPinned: boolean, isActive: boolean, isHidden: boolean) => { return id; },
    {
        cacheObject: new LruObjectCache({ cacheSize: 8 })
    }
)

const breadCrumbItemsSelector = createSelector(
    [
        getEntities,
        getResult,
    ],
    (entities: ContentLayoutEntities, result: List<string>) => {
        let panels: Array<IBreadCrumbItemProps> = [];
        result.forEach((item) => {
            let panel = entities.panels.get(item);
            panels.push(breadCrumbItemSelector(panel.id, panel.title, panel.isOnScreen, panel.isPinned, panel.isActive, panel.isStartupPanel));
        });
        return panels;
    }
)

const shallowEqualCreateSelector = createSelectorCreator(defaultMemoize, shallowEqual);

const getBreadCrumbs = shallowEqualCreateSelector(
    breadCrumbItemsSelector,
    (items) => {
        let breadCrumb: IBreadCrumbProps = { items: items };

        return breadCrumb;
    }
)//////////////

const getUserData = createSelector(
    [
        getUserDataSelector
    ],
    (data) => {
        return data ? data.toJS() : LoginUserFactory().toJS();
    }
);

const getViewHideInfo = createSelector(
    [getViewHideInfoSelector],
    (data: ViewHideInfo) => data.toJS()
)

export { getBreadCrumbs, getUserData, getViewHideInfo };
