import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, Property, IProperty, PropertyFactory, PropertyFactoryFromJS } from "..";

export interface IBaseUserGroupPropertiesFormContent extends IBaseFormContent{
    selectedPropertyId?: number
}

export interface IUserGroupPropertiesFormContent extends IBaseUserGroupPropertiesFormContent {
}

export interface IReadonlyUserGroupPropertiesFormContent extends IBaseUserGroupPropertiesFormContent {
}

export type UserGroupPropertiesFormContent = ReadonlyRecord<IReadonlyUserGroupPropertiesFormContent>;

export const UserGroupPropertiesFormContentFactory = Record<IReadonlyUserGroupPropertiesFormContent>({
    panelId: "",
    showLoading: true,
    selectedPropertyId: -1,
    editWindowGuid: "",
    editWindowisActive: false,
    selectableListPanelisActive : false,
    isEventButtonsActive: true
});

export const UserGroupPropertiesFormContentFactoryFromJS = function (data: IUserGroupPropertiesFormContent) {
    return UserGroupPropertiesFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedPropertyId: data.selectedPropertyId,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectableListPanelisActive : data.selectableListPanelisActive,
        isEventButtonsActive: data.isEventButtonsActive
    });
}