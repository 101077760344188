import { createSyncAction } from '@bimser/core';
import { FilterFactoryFromJS, IFilter, ILoadPayload, INavigationHistoryItem, INavigationItem, LoadPayloadFactoryFromJS, NavigationHistoryItem, NavigationHistoryItemFactoryFromJS, NavigationItemFactoryFromJS } from '../entities';
import * as DMExplorerActions from './actionTypes';

export const LoadAction = (data: ILoadPayload) => {
    return createSyncAction(DMExplorerActions.LOAD, LoadPayloadFactoryFromJS(data));
}

export const PathClickAction = (data: NavigationHistoryItem) => {
    return createSyncAction(DMExplorerActions.PATH_CLICKED, data);
}

export const SetNavigationPath = (data: NavigationHistoryItem) => {
    return createSyncAction(DMExplorerActions.SET_NAVIGATION_PATH, data);
}

export const UpdateLoadingStatus = (status: boolean) => {
    return createSyncAction(DMExplorerActions.UPDATE_LOADING_STATUS, status);
}

export const UpdateOpenedStatus = (status: boolean) => {
    return createSyncAction(DMExplorerActions.UPDATE_OPENED_STATUS, status);
}

export const setLoadedData = (data: any) => {
    return createSyncAction(DMExplorerActions.SET_LOADED_DATA, data);
}

export const updateCurrentLocation = (data: INavigationHistoryItem) => {
    return createSyncAction(DMExplorerActions.UPDATE_CURRENT_ITEM, NavigationHistoryItemFactoryFromJS(data));
}

export const setSelection = (data: INavigationItem) => {
    return createSyncAction(DMExplorerActions.SET_SELECTION, NavigationItemFactoryFromJS(data));
}

export const pushNavigationHistory = (data: INavigationHistoryItem) => {
    return createSyncAction(DMExplorerActions.PUSH_NAVIGATION_HISTORY, NavigationHistoryItemFactoryFromJS(data));
}

export const popNavigationHistory = () => {
    return createSyncAction(DMExplorerActions.POP_NAVIGATION_HISTORY, null);
}

export const clearNavigationHistory = () => {
    return createSyncAction(DMExplorerActions.CLEAR_NAVIGATION_HISTORY, null);
}

export const goUp = () => {
    return createSyncAction(DMExplorerActions.GO_UP, null);
}

export const onSearchTextChanged = (text: string) => {
    return createSyncAction(DMExplorerActions.ON_SEARCH_TEXT_CHANGED, text);
}

export const updateFilter = (data: IFilter) => {
    return createSyncAction(DMExplorerActions.UPDATE_FILTER, FilterFactoryFromJS(data));
}

export const clearData = () => {
    return createSyncAction(DMExplorerActions.CLEAR_DATA, null);
}

export { DMExplorerActions };

