import * as React from "react";
import BCCheckboxGroup from '../../../BCCheckboxGroup';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function CheckboxGroupProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (values: any) => {
        props.customChangeEvent ? props.customChangeEvent(values) : replaceValue(props.item, values)
    }

    return (
        <BCCheckboxGroup
            onChange={onChange}
            mode={'horizontal'}
            defaultValue={props.value}
            options={props.item.options}
            disabled={props.item.disabled || props.item.readOnly}
            style={props.style}
        />
    )
}