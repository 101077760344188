import * as React from "react";
import BCAutoComplete from "../../../BCAutoComplete";
import { IPropertyComponentProps, IPropertyItem, ISelectPropertyProps } from '../../entities';

const inputStyle: React.CSSProperties = { width: '100%' };

export default function AutoCompleteProperty(props: IPropertyComponentProps & ISelectPropertyProps) {

    const replaceValue = (item: IPropertyItem, newValue: string) => {
        props.onValueChanged(item, newValue);
    }

    const onChangePropertyItem = (value: string) => {
        replaceValue(props.item, wrapValue(value)); // Wrap value sadece onChangePropertyItem ile (popup üzerinden seçim yapıldığında) çalışmalı
    }

    const onSearch = (value: string) => {
        replaceValue(props.item, value);
    }

    const wrapValue = (value: string) => {
        let valueWrapper = props.item?.externalData?.valueWrapper;
        if (valueWrapper && value) {
            let valueSplit = value.split("");
            if (valueSplit[0] !== valueWrapper[0] && valueSplit[valueSplit.length] !== valueWrapper[1]) {
                return `${valueWrapper[0]}${value}${valueWrapper[1]}`;
            }
        }

        return value
    }

    const getValue = () => {

        let itemValue: any;

        if (props.item.options && props.item.options.length) {
            itemValue = props.item.options.find((i: any) => i.value === props.value);
        }

        return (itemValue && itemValue.value) || props.value || props.item.placeholder

    }

    return (
        <BCAutoComplete
            allowClear={true}
            dataSource={props.item.options}
            onChange={onChangePropertyItem}
            onSearch={onSearch}
            value={getValue()}
            style={inputStyle}
            disabled={props.item?.disabled || false}
        />
    )
}