import * as Hash from 'object-hash';
import * as React from "react";
import BCListEditor from ".";
import { IComboboxLoadingArgs } from '../BCCombobox';
import { IArgumentAboutRightClickEvent, IContextMenuClickEventArgs, IContextMenuOpenArgs } from "../BCContextMenu/entities";
import { IInputChangeEventArgs } from "../BCInput";
import { findMessage } from "../BCIntl";
import BCNotification from "../BCNotification";
import { IPropertyItem, ITabItem } from "../BCPropertyInspector";
import BCTreeview, { ITreeviewExpandEventArgs, ITreeviewSelectEventArgs } from "../BCTreeview";
import * as Styles from './assets/treeEditorStyle.scss';
import { IStatefulListEditorProps, IStatefulTreeEditorState, ITreeListItem } from "./entities";

/**List editor tiplerinde kullanılan ortak yöntemleri içerir.
 * Extend ettiğiniz componentlerin didMount olayında  yada ilgili işler sonrasına,
 * state { items,leftRibbonItems, rightRibbonItems, showLoading} parametrelerini belirleyiniz.
 * */
export default class StatefulTreeEditor<P, S> extends React.Component<IStatefulListEditorProps & P, IStatefulTreeEditorState & S> {
    _recursivePath?: string;
    constructor(props: IStatefulListEditorProps & P) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onOkClick = this.onOkClick.bind(this);
        this.onChangeData = this.onChangeData.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.openCollection = this.openCollection.bind(this);
        this.onContextMenuOpening = this.onContextMenuOpening.bind(this);
        this.onContextClick = this.onContextClick.bind(this);
        this.onExpandChange = this.onExpandChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.state = {
            items: [],
            searchValue: "",
            expandedKeys: [],
            selectedKey: "",
            showLoading: true,
            leftRibbonItems: [],
            rightRibbonItems: []
        } as IStatefulTreeEditorState & S;

        this.renderTreeView = this.renderTreeView.bind(this);
    }
    /** Bu metodu ovveri edip datanıza uygun item template  yazınız */
    generateItems(items: Array<ITreeListItem>): Array<ITreeListItem> {
        return [];
    }

    /**Bu metodu ovveride edip kullanmak istediğiniz veri yapısını belirleyiniz*/
    generateStructure(): Array<ITabItem> {
        return [];
    }
    /**Kendi koleksiyon tiplerinizi  yakalamak için ovveride ediniz */
    openCollection(item?: any, action?: string) {

    }
    /**Bu metodu ovveride ederek kendi validasyonunuzu yazabilirsiniz.
    Resolve ettiğiniz mesaj boş ise validasyon başarılı sayılır
    */
    validation(): Promise<string> {
        return new Promise<string>((resolve, rejet) => { resolve("") });
    }
    /**Ovveride edilebilmesi için bırakıldı. */
    showDeleteConfirm() {
        return new Promise<boolean>((resolve, reject) => {
            resolve(true);
        });
    }
    /**getOptionsPromise kullanmak için ovveride ediniz */
    getOptionsPromise(item: IPropertyItem): Promise<any> {
        return new Promise<IComboboxLoadingArgs>((resolve, reject) => {
            resolve({ allow: true, options: [] });
        });
    }
    /**onRefresh kullanmak için ovveride ediniz */
    onRefresh(item?: any) {
        return new Promise<IComboboxLoadingArgs>((resolve, reject) => {
            resolve({ allow: true, options: [] });
        })
    }

    onDelete(item: ITreeListItem) {
        this.showDeleteConfirm().then((result) => {
            if (result) {
                this.setState({
                    items: this.state.items.filter((i) => i.key != item.key),
                    selectedKey: "",
                } as IStatefulTreeEditorState & S, this.onAfterDelete);
            }
        });
    }

    /**onAfterDelete kullanmak için ovveride ediniz */
    onAfterDelete() { }

    onSearch(args: IInputChangeEventArgs) {
        this.setState({ searchValue: args.data } as IStatefulTreeEditorState & S);
    }

    onSelectionChange(args: ITreeviewSelectEventArgs) {
        this.setState({
            selectedKey: args.item.key,
        } as IStatefulTreeEditorState & S);
    }

    _setIn(path: string, sourceObj: any, setObj: any) {
        let schema = setObj;  // a moving reference to internal objects within obj
        let value = sourceObj;
        let pList = path.split('.');
        let len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            let elem = pList[i];
            value = value[elem];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value[pList[len - 1]];
    }

    _onChangeData(items: Array<ITreeListItem>, item: any, newData: any) {
        return items && items.map((i) => {
            if (i.key === this.state.selectedKey) {
                this._setIn(item.key, newData, i.externalData);
            } else {
                i.children = this._onChangeData(i.children, item, newData)
            }
            return i;
        });
    }

    onChangeData(item: any, newData: any) {
        this.setState({
            items: this._onChangeData(this.state.items, item, newData)
        } as IStatefulTreeEditorState & S)
    }

    getSaveData(items: Array<ITreeListItem>): Array<any> {
        if (items && Array.isArray(items)) {
            return items.map((i) => {
                return { ...i.externalData, [this._recursivePath]: this.getSaveData(i.children) };
            });
        }
        return [];
    }

    onOkClick() {
        this.validation().then((message) => {
            if (!message) {
                if (this.props.onSucces) {
                    this.props.onSucces(this.getSaveData(this.state.items));
                    this.onCancelClick();
                }
            }
            else {
                BCNotification.warning({
                    duration: 4,
                    description: message,
                    message: findMessage.get("100652") + " !",
                    placement: "topRight",
                })
            }
        });
    }

    findParentItemData(items: Array<ITreeListItem>, key?: string): ITreeListItem {
        if (items && Array.isArray(items)) {
            for (const item of items) {
                if (item.children?.find(childItem => childItem.key === key)) {
                    return item;
                } else {
                    let result = this.findParentItemData(item.children, key);
                    if (result) {
                        return result;
                    }
                }
            }
        }
    }

    findCurrentItemData(items: Array<ITreeListItem>, key?: string): ITreeListItem {
        if (items && Array.isArray(items)) {
            for (const item of items) {
                if (item.key === key) {
                    return item;
                } else {
                    let result = this.findCurrentItemData(item.children, key);
                    if (result) {
                        return result;
                    }
                }
            }
        }
    }

    getCurrentItemData(): any {
        let currentSelection = this.findCurrentItemData(this.state.items, this.state.selectedKey);
        return currentSelection ? currentSelection.externalData : {};
    }

    onCancelClick() {
        if (this.props.onCancelClick) {
            this.props.onCancelClick();
        }
    }
    onExpandChange(args: ITreeviewExpandEventArgs) {
        this.setState({
            expandedKeys: args.expandedKeys as any,
        });
    }
    /**Context menüyü kullanmak için ovveride ediniz */
    onContextMenuOpening(args: IArgumentAboutRightClickEvent): Promise<IContextMenuOpenArgs> {
        return new Promise<IContextMenuOpenArgs>((resolve, reject) => {
            resolve({ allow: true, items: [] })
        })
    }

    /**Context Click eventini kullanmak için ovveride ediniz */
    onContextClick(args: IContextMenuClickEventArgs) { }

    onDrop(info: any) {
        this.setState({ items: info.nodeItems })
    }

    renderTreeView() {
        return <BCTreeview
            className={Styles.treeEditor}
            searchable={true}
            contextMenu={{ isActive: true, contextMenuProps: { items: [], onContextMenuOpening: this.onContextMenuOpening, onClick: this.onContextClick } }}
            expandedKeys={this.state.expandedKeys}
            selectedKeys={[this.state.selectedKey]}
            onSelect={this.onSelectionChange}
            onExpand={this.onExpandChange}
            items={this.generateItems(this.state.items)}
            hasSrollbar={true}
            draggable={true}
            isTreeLeave={true}
            onDrop={this.onDrop}
        />
    }

    render() {
        const data = this.getCurrentItemData();
        return (<BCListEditor
            sortable={true}
            useDefaultButtons={true}
            renderLeftSide={this.renderTreeView}
            onSearch={this.onSearch}
            showLoading={this.state.showLoading}
            structure={this.generateStructure()}
            onDelete={this.onDelete}
            onSelect={this.onSelectionChange}
            onOkClick={this.onOkClick}
            onChangeData={this.onChangeData}
            data={this.getCurrentItemData()}
            leftRibbonItems={this.state.leftRibbonItems}
            rightRibbonItems={this.state.rightRibbonItems}
            openCollection={this.openCollection}
            onCancelClick={this.onCancelClick}
            getOptionsPromise={this.getOptionsPromise}
            onRefresh={this.onRefresh}
            id={Hash(data?.key ?? data)}
            {...this.props}
        />);
    }
}