import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseProfessionDetail {
    id: string,
    caption: string
}

export interface IProfessionDetail extends IBaseProfessionDetail { }

export interface IReadonlyProfessionDetail { }

export type ProfessionDetail = ReadonlyRecord<IReadonlyProfessionDetail>;

export const ProfessionDetailFactory = Record<IReadonlyProfessionDetail>({
    id: "",
    caption: ""
});

export const ProfessionDetailFactoryFromJS = function (data: IProfessionDetail) {
    return ProfessionDetailFactory({
        id: data.id,
        caption: data.caption
    });
}