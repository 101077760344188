import * as React from "react";
import { connect } from "react-redux";
import MessageBox from "../components";
import { IMessageBoxCProps, IMessageBoxProps, IMessageBoxResolvePayload, IMessageBoxButton } from '../entities';
import { BaseState } from "../../../../../entities/BaseState";
import { closeModalTransactionAction } from "../../../actions";
import { resolveTransactionAction } from "../actions";

// state to component properties
const mapStateToProps = function (state: BaseState, props: IMessageBoxProps): IMessageBoxProps {
    return {
        id: props.id,
        visible: props.visible,
        width: props.width,
        notInCloseIcon: props.notInCloseIcon,
        type: props.type,
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IMessageBoxCProps {
    return {
        closeModal: function (key: string) {
            dispatch(closeModalTransactionAction(key));
        },
        resolveAction: function (button: IMessageBoxResolvePayload) {
            dispatch(resolveTransactionAction(button));
        }
    };
}

// react - redux connection

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);