import { BCNotification, findMessage, BCAjax } from "@bimser/components";
import { takeEvery } from 'redux-saga/effects';
import * as ActionTypes from "../actions/actionTypes";
import { ServiceList } from '../../..';

export default function* watchPasswordRecoveryTransaction() {
    yield takeEvery(ActionTypes.ONCLICK_PASSWORD_RECOVERY_TRANSACTION, passwordRecoveryTransaction);
}
interface IRecoveryTransaction { EMailAddress: string }
export function* passwordRecoveryTransaction(sagaAction: any) {
    //yield 0;
    let actionPayload: { EMailAddress: string, callBackResponse: (args: any) => void } = sagaAction.payload;
    try {

        let email = actionPayload.EMailAddress;

        let response = yield ServiceList.main.Password.SendResetPasswordMail.call({ emailAddress: email }, undefined, undefined, true);
        if (response) {
            actionPayload.callBackResponse(response)
        } else {
            actionPayload.callBackResponse(false);
        }

    } catch (error) {
        BCNotification.error({
            message: findMessage.get('100592'),
        });
    }
}