import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core"
import { ICollapseItem, CollapseItem, CollapseItemFactoryFromJS } from '../entities';
import { IPropertyItem, PropertyItem, PropertyItemFactoryFromJS } from "./IPropertyItem";
import { IconInfo } from '@bimser/icons';

export interface IBaseTabItem {
    key: string,
    label: string,
    orderNo?: number,
    disabled?: boolean,
    icon?: IconInfo,
    selected?: boolean,
}

export interface ITabItem extends IBaseTabItem {
    panels?: Array<ICollapseItem>;
    properties?: Array<IPropertyItem>;
}

export interface IReadonlyTabItem extends IBaseTabItem {
    panels?: List<CollapseItem>;
    properties?: List<PropertyItem>;

}

export type TabItem = ReadonlyRecord<IReadonlyTabItem>;

export const TabItemFactory = Record<IReadonlyTabItem>({
    key: null,
    label: null,
    orderNo: null,
    disabled: false,
    icon: null,
    selected: false,
    panels: List(),
    properties: List(),
});

export const TabItemFactoryFromJS = function (data: ITabItem) {
    let items: Array<CollapseItem> = data.panels.length ? data.panels.map(function (value) {
        return CollapseItemFactoryFromJS(value);
    }) : [];

    return TabItemFactory({
        key: data.key,
        label: data.label,
        orderNo: data.orderNo,
        disabled: data.disabled,
        icon: data.icon,
        selected: data.selected,
        panels: List(items),
        properties: List(data.properties ? data.properties.map((i) => PropertyItemFactoryFromJS(i)) : []),
    });
}