import { Record, Map, List } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { ILogviewerListPanelState, LogviewerListPanelState, LogviewerListPanelStateFactory, LogviewerListPanelStateFactoryFromJS } from "../components/listPanel/entities/ILogviewerListPanelState";
import { ILogviewerDetailPanelState, LogviewerDetailPanelState, LogviewerDetailPanelStateFactory, LogviewerDetailPanelStateFactoryFromJS } from "../components/detailPanel/entities/ILogviewerDetailPanelState";
import { Log, ILog, LogFactoryFromJS } from './ILog';

export interface ILogviewerState {
    listPanel: ILogviewerListPanelState,
    detailPanel: ILogviewerDetailPanelState,
    filter: IDictionary<string>,
    items: IDictionary<ILog>,
    logNames: Array<string>,
    hasMoreItems: boolean,
}

export interface IReadonlyLogviewerState {
    listPanel: LogviewerListPanelState,
    detailPanel: LogviewerDetailPanelState,
    filter: Map<string, string>,
    items: Map<string, Log>,
    logNames: List<string>,
    hasMoreItems: boolean,
}

export type LogviewerState = ReadonlyRecord<IReadonlyLogviewerState>;

export const LogviewerStateFactory = Record({
    hasMoreItems: true,
    listPanel: LogviewerListPanelStateFactory(),
    detailPanel: LogviewerDetailPanelStateFactory(),
    filter: Map<string, string>(),
    items: Map<string, Log>(),
    logNames: List()
});

export const LogviewerStateFactoryFromJS = function (data: ILogviewerState) {

    let _items: IDictionary<Log> = Object.keys(data.items).reduce(function (previous: IDictionary<Log>, current) {
        previous[current] = LogFactoryFromJS(data.items[current]);
        return previous;
    }, {});

    return LogviewerStateFactory({
        listPanel: LogviewerListPanelStateFactoryFromJS(data.listPanel),
        detailPanel: LogviewerDetailPanelStateFactoryFromJS(data.detailPanel),
        filter: Map(data.filter),
        items: Map(_items),
        logNames: List<string>(data.logNames),
        hasMoreItems: data.hasMoreItems
    });
};