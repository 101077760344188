import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseManager {
    id: number,
    userId: number,
    managerKeyId: number,
    managerUserId: number
}

export interface IManager extends IBaseManager { }

export interface IReadonlyManager extends IBaseManager { }

export type Manager = ReadonlyRecord<IReadonlyManager>;

export const ManagerFactory = Record<IReadonlyManager>({
    id: 0,
    userId: 0,
    managerKeyId: 0,
    managerUserId: 0
});

export const ManagerFactoryFromJS = function (data: IManager) {
    return ManagerFactory(data);
}