import { MLHelper } from "@bimser/core";
import { azAZ } from "@bimser/core/src/locales/numberVariablesLocale/az-AZ";
import numbro from "numbro";
import { IFormatterInfo } from "../entities";

const languages = require("numbro/dist/languages.min");

export class NumberFormatter {
    public __initialized: boolean = false;
    private precision?: number = 0;
    private useThousandSeparator?: boolean = false;
    private decimalSeparator = ".";

    public format(v: number | string | undefined, info: IFormatterInfo): string {
        if (v && numbro(v).value() !== undefined) {

            if (this.precision > 0) {
                return this.__getPrecisionFormatter(v, info);
            }

            if (this.precision === 0) {
                v = Math.floor(v as number).toString();
                return numbro(v).format({ thousandSeparated: this.useThousandSeparator ?? false });
            }
        }
        return v?.toString() || info?.input || "";
    }

    private __getPrecisionFormatter(v: number | string, info: IFormatterInfo) {
        const isLastCharDecimalSeparator = v.toString().substr(v.toString().length - 1) === ".";
        const numbroObj = this.__getNumbroObject(info);

        v = numbro(Number(v)).format(numbroObj);

        if (isLastCharDecimalSeparator) {
            v += this.decimalSeparator;
        }

        return v;
    }

    private __getNumbroObject(info: IFormatterInfo): any {
        let numbroObj: any = { thousandSeparated: this.useThousandSeparator };
        if (info?.userTyping) {
            info.input = info.input.toString();
            const dotIndex = info.input.indexOf(this.decimalSeparator);
            if (dotIndex > -1) {
                const mantissa = info.input.length - (dotIndex + 1);
                numbroObj.mantissa = Math.min(mantissa, 100);
            }
        } else {
            numbroObj.mantissa = this.precision;
        }
        return numbroObj;
    }

    public init(precision: number, useThousandSeparator: boolean) {
        this.precision = precision;
        this.useThousandSeparator = useThousandSeparator;
        const currentLanguage = MLHelper.currentLanguage.culture;

        if (currentLanguage === 'az-Latn-AZ') numbro.registerLanguage(azAZ as any);
        else if (currentLanguage !== 'en-US') numbro.registerLanguage(languages[currentLanguage]);

        numbro.setLanguage(currentLanguage);
        this.decimalSeparator = numbro.languages()[currentLanguage].delimiters.decimal;
        this.__initialized = true;
    }

}
