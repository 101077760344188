import { IBaseAction, createMutableAction, createSyncAction } from '@bimser/core';
import { IControlTokenResponse, IGetUserDetailResponse, IGetUserSettingPermissionsResponse } from '../../..';
import IStartMobileLoginPayload from '../../MobileSignature/entities/IStartMobileLoginPayload';
import { DashboardSettings, IGetLoginParametersRequest, ILoginResponseData, IMFAPropertiesState, ISelectMFATypeRequest, IValidationMFARequest, LoginResponseDataFactoryFromJS, MFAPropertiesStateFactoryFromJS, MFAType, SelectMFATypeRequestFactoryFromJS, ValidationMFARequestFactoryFromJS } from '../entities';
import { ControlTokenResponseFactoryFromJS } from '../entities/ControlTokenResponse';
import { GetLoginParametersRequestFromJS } from '../entities/GetLoginParametersRequest';
import { GetLoginParametersResponseFactoryFromJS, IGetLoginParametersResponse } from '../entities/GetLoginParametersResponse';
import { GetUserDetailResponseFactoryFromJS } from '../entities/GetUserDetailResponse';
import { GetUserSettingParametersResponseFactoryFromJS, IGetUserSettingParametersResponse } from '../entities/GetUserSettingParametersResponse';
import { GetUserSettingPermissionsResponseFactoryFromJS } from '../entities/GetUserSettingPermissionsResponse';
import { CaptchaStateFactoryFromJS, ICaptchaState } from '../entities/ICaptchaState';
import { ILoginMethod, LoginMethodFactoryFromJS } from '../entities/ILoginMethod';
import ILoginWithDigitalSignaturePayload from '../entities/ILoginWithDigitalSignaturePayload';
import { ILoginWithTokenPayload, LoginWithTokenPayloadFactoryFromJS } from '../entities/ILoginWithTokenPayload';
import { IOAuthLoginRequest, OAuthLoginRequestFactoryFromJS } from '../entities/IOAuthLoginRequest';
import { ITheme, ThemeFactory } from "../entities/ITheme";
import { ILoginRequest, LoginRequestFactoryFromJS } from '../entities/LoginRequest';
import * as ActionTypes from "./actionTypes";
export { ActionTypes };


export const SetCaptchaStateAction = (data: ICaptchaState) => {
    return createSyncAction(ActionTypes.SET_CAPTCHA_STATE_ACTION, CaptchaStateFactoryFromJS(data));
}

export function changeLoginLoadingStatusAction(data: boolean) {
    let action = createSyncAction(ActionTypes.LOGIN_LOADING_STATUS, data);
    return action;
}

export function changeSelectedCultureAction(data: string) {
    let action = createSyncAction(ActionTypes.CHANGE_SELECTED_CULTURE_ACTION, data);
    return action;
}

export function changeSelectedCultureActionSaga(data: string) {
    let action = createSyncAction(ActionTypes.CHANGE_SELECTED_CULTURE_ACTION_SAGA, data);
    return action;
}

export function controlTokenAction(data: IControlTokenResponse) {

    if (!data) return undefined;

    let payload = ControlTokenResponseFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.CONTROL_TOKEN, payload);

    return action;
}

export function controlTokenTransactionAction() {

    let action = createSyncAction(ActionTypes.CONTROL_TOKEN_TRANSACTION, null);

    return action;
}

export function editUserProfileImageTransactionAction(data: File): IBaseAction<any> {

    if (!data) return undefined;

    let action = createMutableAction(ActionTypes.EDIT_USER_PROFILE_IMAGE_TRANSACTION, data);

    return action;
}

export function getChangePasswordRequirementsTransaction() {
    return createSyncAction(ActionTypes.GET_CHANGE_PASSWORD_REQUIREMENTS_TRANSACTION, null);
}

export function getUserDetailAction(data: IGetUserDetailResponse) {
    if (!data) return undefined;
    let payload = GetUserDetailResponseFactoryFromJS(data);
    return createSyncAction(ActionTypes.GET_USER_DETAIL_ACTION, payload);
}

export function getUserSettingParametersAction(data: IGetUserSettingParametersResponse) {

    if (!data) return undefined;

    let payload = GetUserSettingParametersResponseFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.GET_USER_SETTING_PARAMATERS, payload);

    return action;
}

export function getUserSettingPermissionsAction(data: IGetUserSettingPermissionsResponse): IBaseAction<any> {

    if (!data) return undefined;

    let payload = GetUserSettingPermissionsResponseFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.GET_USER_SETTING_PERMISSION, payload);

    return action;
}

export function getUserStartupTransactionAction() {

    let action = createSyncAction(ActionTypes.GET_USER_STARTUP_TRANSACTION, null);

    return action;
}


export function initializeApplicationStartup(): IBaseAction<any> {
    let action = createSyncAction(ActionTypes.INITIALIZE_APPLICATION_STARTUP, null);

    return action;
}

export function initializedApplicationStartup(): IBaseAction<any> {
    let action = createSyncAction(ActionTypes.INITIALIZED_APPLICATION_STARTUP, null);

    return action;
}

export function loginAction(data: ILoginResponseData) {
    if (!data) return undefined;

    let payload = LoginResponseDataFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.LOGIN, payload);

    return action;
}

export const loginAsUserSelectedAction = (data: any) => {
    return createSyncAction(ActionTypes.LOGIN_AS_USER_SELECTED_ACTION, data);
}


export function loginParametersAction(data: IGetLoginParametersResponse) {
    if (!data) return undefined;

    let payload = GetLoginParametersResponseFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.LOGIN_PARAMETERS, payload);

    return action;
}

export function loginParametersTransactionAction(data: IGetLoginParametersRequest) {

    if (!data) return undefined;

    let payload = GetLoginParametersRequestFromJS(data);

    let action = createSyncAction(ActionTypes.LOGIN_PARAMETERS_TRANSACTION, payload);

    return action;
}

export function loginTransactionAction(data: ILoginRequest) {
    if (!data) return undefined;

    let payload = LoginRequestFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.LOGIN_TRANSACTION, payload);

    return action;
}

export const loginWithDigitalSignatureAction = (payload: ILoginWithDigitalSignaturePayload) => {
    return createMutableAction(ActionTypes.LOGIN_WITH_DIGITAL_SIGNATURE, payload);
}

export const loginWithExternalAccountTransactionAction = (payload: ILoginMethod) => {
    return createSyncAction(ActionTypes.LOGIN_WITH_EXTERNAL_ACCOUNT_TRANSACTION, LoginMethodFactoryFromJS(payload));
}


export const loginWithMobileSignatureAction = (data: IStartMobileLoginPayload) => {
    return createMutableAction(ActionTypes.LOGIN_WITH_MOBILE_SIGNATURE, data);
}

export const loginWithTokenTransaction = (data: ILoginWithTokenPayload) => {
    return createSyncAction(ActionTypes.LOGIN_WITH_TOKEN_TRANSACTION, LoginWithTokenPayloadFactoryFromJS(data));
}

export function onClickChangeMyPasswordTransaction(data: any) {
    if (!data) return undefined;

    let action = createSyncAction(ActionTypes.ONCLICK_CHANGE_MY_PASSWORD_TRANSACTION, data);

    return action;
}

export function onClickPasswordRecoveryTransaction(data: any) {
    if (!data) return undefined;

    let action = createSyncAction(ActionTypes.ONCLICK_PASSWORD_RECOVERY_TRANSACTION, data);

    return action;
}

export const onOAuthLoginTransaction = (payload: IOAuthLoginRequest) => {
    return createSyncAction(ActionTypes.ON_OAUTH_LOGIN_TRANSACTION, OAuthLoginRequestFactoryFromJS(payload));
}

export function onSelectionMFATypeTransaction(data: ISelectMFATypeRequest) {
    if (!data) return undefined;

    let action = createSyncAction(ActionTypes.SELECT_MFA_TYPE_TRANSACTION, SelectMFATypeRequestFactoryFromJS(data));

    return action;
}

export const onSSOControlAction = () => {
    return createSyncAction(ActionTypes.SSO_CONTROL_TRANSACTION_ACTION, null);
}

export function onValidationMFA(data: IValidationMFARequest) {
    if (!data) return undefined;

    let action = createSyncAction(ActionTypes.VALIDATION_MFA, ValidationMFARequestFactoryFromJS(data));

    return action;
}

export const refreshCaptchaAction = (language: string) => {
    return createSyncAction(ActionTypes.REFRESH_CAPTCHA_ACTION, language);
}

export function setCurrentMFAMethodAction(data: MFAType) {
    if (!data) return undefined;

    let action = createSyncAction(ActionTypes.SET_CURRENT_MFA_METHOD, data);

    return action;
}
export const setDashboardSettingsAction = (dashboardSettings: DashboardSettings) => {
    return createSyncAction(ActionTypes.SET_DASHBOARD_SETTINGS_ACTION, dashboardSettings);
}

export function setMFAProperties(data: IMFAPropertiesState) {
    if (!data) return undefined;

    let payload = MFAPropertiesStateFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.SET_MFA_PROPERTIES_ACTION, payload);

    return action;
}

export function setThemeProperties(data: ITheme) {
    let payload = ThemeFactory();

    if (data.backgroundColor) payload = payload.set("backgroundColor", data.backgroundColor);
    if (data.image) payload = payload.set("image", data.image);
    if (data.primaryColor) payload = payload.set("primaryColor", data.primaryColor);

    return createSyncAction(ActionTypes.SET_THEME_PROPERTIES, payload);
}

export const setMobileSignatureFingerprintAction = (fingerPrint: string) => {
    return createSyncAction(ActionTypes.SET_MOBILE_SIGNATURE_FINGERPRINT, fingerPrint);
}

export function setUserProfileImageAction(data: string): IBaseAction<any> {
    return createSyncAction(ActionTypes.SET_USER_PROFILE_IMAGE_ACTION, data);
}

export const changeAdminToolsVisibilityAction = (data: boolean) => {
    return createSyncAction(ActionTypes.CHANGE_ADMIN_TOOLS_MENU_VISIBILITY, data);
}

export const changeUserMenuVisibilityAction = (data: boolean) => {
    return createSyncAction(ActionTypes.CHANGE_USER_MENU_VISIBILITY, data);
}