import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseFields {
    projectId?: string,
    formId?: string,
    fieldName?: string
}

export interface IFields extends IBaseFields {

}

export interface IReadonlyFields extends IBaseFields {

}

export type Fields = ReadonlyRecord<IReadonlyFields>;

export const FieldsFactory = Record<IReadonlyFields>({
    projectId: "",
    formId: "",
    fieldName: ""
});

export const FieldsFactoryFromJS = function (data: IFields) {
    return FieldsFactory(data);
}