import { PartialObserver, Subject } from 'rxjs';

class RxEventEmitter {
    static subjects: { [key: string]: Subject<any> } = {};
    static uncaughtSubjects: { [key: string]: any } = {};

    static hasOwnProp = {}.hasOwnProperty;

    static createName(name: string) {
        return `$${name}`;
    }

    static emit(name: string, data: any) {
        import(/* webpackChunkName: "rxjs" */ "rxjs").then((args) => {
            let fnName = RxEventEmitter.createName(name);
            this.subjects[fnName] || (this.subjects[fnName] = new args.Subject<any>());
            this.subjects[fnName].next(data);
        })
    }

    static listen(name: string, handler: PartialObserver<any>) {
        return import(/* webpackChunkName: "rxjs" */ "rxjs").then((args) => {
            var fnName = RxEventEmitter.createName(name);
            this.subjects[fnName] || (this.subjects[fnName] = new args.Subject<any>());
            return this.subjects[fnName].subscribe(handler);
        });
    };

    static dispose = () => {
        var subjects = RxEventEmitter.subjects;
        for (var prop in subjects) {
            if (RxEventEmitter.hasOwnProp.call(subjects, prop)) {
                subjects[prop].unsubscribe();
            }
        }
        RxEventEmitter.subjects = {};
    };
}

export default RxEventEmitter;