import Button from 'antd/lib/button';
import * as React from "react";
import * as styles from './assets/buttonStyles.scss';
import { IButtonClickEventArgs, IButtonProps, IButtonSizes } from './entities';
import { IButtonState } from "./entities/IButtonState";

var classNames = require('classnames/bind');
let cx = classNames.bind(styles);

export default class BCButton extends React.Component<IButtonProps, IButtonState> {

    constructor(props: IButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onFocus = this.onFocus.bind(this);
        styles;
    }

    onClick(e: React.FormEvent<any>) {
        if (this.props.onClick) {
            this.props.onClick({
                event: e,
                data: {
                    guid: this.props.guid,
                    size: this.props.size,
                    text: this.props.text,
                    type: this.props.type
                }
            });
        }
    }

    onDoubleClick(e: React.FormEvent<any>) {
        if (this.props.onDoubleClick) {
            this.props.onDoubleClick({
                event: e,
                data: {
                    guid: this.props.guid,
                    size: this.props.size,
                    text: this.props.text,
                    type: this.props.type
                }
            });
        }
    }

    onMouseEnter(e: React.FormEvent<any>) {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter({
                event: e
            });
        }
    }

    onMouseLeave(e: React.FormEvent<any>) {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave({
                event: e
            });
        }
    }

    onFocus(e: React.FormEvent<any>) {
        if (this.props.onFocus) {
            this.props.onFocus({
                event: e
            });
        }
    }

    render() {
        const { size, children, cssClass, className, danger, disabled, icon, text, type, title, tabIndex, href, id, shape, ref, buttonRef, block } = this.props;

        let buttonClassNames = cx({
            iconWithText: icon && (children || text)
        });

        return (
            <Button
                href={href}
                tabIndex={tabIndex}
                title={title}
                size={size}
                style={this.props.style}
                className={[buttonClassNames, cssClass, className].join(" ")}
                disabled={disabled}
                icon={icon}
                onClick={this.onClick}
                onDoubleClick={this.onDoubleClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onFocus={this.onFocus}
                loading={this.props.loading}
                type={type}
                id={id}
                shape={shape}
                ref={buttonRef || ref}
                danger={danger}
                block={block}
            >
                {children || text}
            </Button>
        );
    }
}

export { IButtonProps, IButtonClickEventArgs, IButtonSizes };

