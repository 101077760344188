import { IExtremeGridColumn } from "../entities";
import * as moment from "moment";
const _isEmpty = require("lodash/isEmpty")

export default function getFilters(columns: Array<IExtremeGridColumn>): Array<any> {
    return columns.filter(item => item?.filtering?.enabled !== false && !_isEmpty(item?.filtering?.value?.toString())).reduce((result, item) => {
        let value = item.filtering.value;
        if (item.dataType == "date" || item.dataType == "datetime") {
            if (item.filterFormat) {
                value = moment(value).format(item.filterFormat as string);
            }
            else {
                value = item.dataType == "datetime" ? value.toISOString() : moment(value).format("YYYY-MM-DD");
            }
        }

        result.push({
            columnName: item.name,
            operation: item.filtering.selectedOperation !== undefined ? item.filtering.selectedOperation : "contains",
            value: value,   // else durumunda undefined geçersen sonuç gelmiyor, null geçersen hata veriyor. 
        });                                                                         // selectedOperation ı value olmadan geçebilmek için empty string atandı.

        return result;
    }, []);
}