import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from 'immutable';
import { panelType } from "common/entities/enums";
import { IMenuItem, MenuItem, MenuItemFactoryFromJS } from "../../../entities/IMenuItem";
import { IBaseFormContent } from "../../../entities";



export interface IBasePanelMenuPanelContent extends IBaseFormContent {
    activePanelId?: string,
    activePanelType?: panelType,
    activeNodeId?: string,
    activeChildPanelId?: string
}

export interface IPanelMenuPanelContent extends IBasePanelMenuPanelContent {
    menuItems: Array<IMenuItem>
}

export interface IReadonlyPanelMenuPanelContent extends IBasePanelMenuPanelContent {
    menuItems: List<MenuItem>
}

export type PanelMenuPanelContent = ReadonlyRecord<IReadonlyPanelMenuPanelContent>;

export const PanelMenuPanelContentFactory = Record<IReadonlyPanelMenuPanelContent>({
    showLoading: false,
    menuItems: List<MenuItem>(),
    activePanelType: panelType.none,
    activePanelId: "",
    panelId: "",
    activeNodeId: "",
    activeChildPanelId: ""
})

export const PanelMenuPanelContentFactoryFromJS = function (data: IPanelMenuPanelContent) {
    let _menuItems: Array<MenuItem> = data.menuItems && data.menuItems.length ? data.menuItems.map((item) => {
        return MenuItemFactoryFromJS(item);
    }) : [];

    return PanelMenuPanelContentFactory({
        activePanelId: data.activePanelId,
        activePanelType: data.activePanelType,
        menuItems: List(_menuItems),
        showLoading: data.showLoading,
        panelId: data.panelId,
        activeNodeId: data.activeNodeId,
        activeChildPanelId: data.activeChildPanelId
    });
}