import * as React from "react";
import { BCButtonBar } from "..";
import * as Styles from './assets/style.scss';
import { IAddonButton } from './entities';

export default class BCAddonButton extends React.Component<IAddonButton, {}> {

    addonStyle: React.CSSProperties = { marginRight: 16 };

    constructor(props: IAddonButton) {
        super(props);
    }

    renderRibbon() {

        const { className, style, farItems, items, overflowButtonProps, elementsCountToShow, overflowMenuProps, maxWidthInPX } = this.props;
        let sortedSeparationList = ["0"].concat(items && items.length > 0 ? items.map(t => t.separationId) : []);
        let _items = items && items.length > 0 ? items.map(t => {
            t.separationId ? t.separationId : "0";
            return t;
        }) : [];

        return (
            <BCButtonBar
                className={[Styles.ribbonBar, className].join(" ")}
                style={style}
                elementsCountToShow={elementsCountToShow || 2}
                overflowMenuProps={overflowMenuProps}
                overflowButtonProps={overflowButtonProps}
                items={_items}
                farItems={farItems}
                sortedSeparationList={sortedSeparationList}
                isPopoverAutoClose={true}
            />
        )

        // return <div className={Styles.ribbonBar} style={{ width: ribbonBarWidth }}>
        //     <BCRibbonBar
        //         buttonAs={buttonAs}
        //         customClassName={customClassName}
        //         farItems={farItems}
        //         items={items}
        //         customStyles={customStyles}
        //         overflowButtonAs={overflowButtonAs}
        //         overflowButtonProps={overflowButtonProps}
        //         overflowItems={overflowItems}
        //     />
        // </div>
    }

    renderAddon() {
        return (
            <div className={Styles.addon} >
                {this.props.children}
            </div>
        )
    }

    render() {
        return (
            <div className={Styles.AddonButtonContainer}>
                {this.renderAddon()}
                {this.renderRibbon()}
            </div>
        );
    }

}

export { IAddonButton };

