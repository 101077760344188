import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ILoginMethod, LoginMethod, LoginMethodFactoryFromJS } from "./ILoginMethod";

export interface ILoginFormProperties {
    eSignVisible: boolean,
    mobileSignVisible: boolean,
    rememberMeVisible: boolean,
    forgottenPasswordVisible: boolean,
    captchaEnabled: boolean,
    signatureComponentLicense: string,
    additionalLoginMethods: Array<ILoginMethod>,
    defaultLoginMethod: string,
    autoLoginWithMainOAuthProvider: boolean
}

export interface IReadonlyLoginFormProperties {
    eSignVisible: boolean,
    mobileSignVisible: boolean,
    rememberMeVisible: boolean,
    forgottenPasswordVisible: boolean,
    captchaEnabled: boolean,
    signatureComponentLicense: string,
    additionalLoginMethods: List<LoginMethod>,
    defaultLoginMethod: string,
    autoLoginWithMainOAuthProvider: boolean
}

export type LoginFormProperties = ReadonlyRecord<IReadonlyLoginFormProperties>;

export const LoginFormPropertiesFactory = Record<IReadonlyLoginFormProperties>({
    eSignVisible: false,
    mobileSignVisible: false,
    rememberMeVisible: false,
    forgottenPasswordVisible: false,
    captchaEnabled: false,
    signatureComponentLicense: null,
    additionalLoginMethods: List(),
    defaultLoginMethod: "usernamePassword",
    autoLoginWithMainOAuthProvider: false
});

export const LoginFormPropertiesFactoryFromJS = function (data: ILoginFormProperties) {
    let _additionalLoginMethods = data && data.additionalLoginMethods ? data.additionalLoginMethods.map(m => LoginMethodFactoryFromJS(m)) : [];

    return LoginFormPropertiesFactory({
        ...data,
        additionalLoginMethods: List(_additionalLoginMethods)
    });
}