import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import * as moment from 'moment';

export interface IBaseLog {
    id: number,
    message: string,
    level: string,
    timeStamp: string,
    exception: string,
    instance: string,
    username: string,
    name: string
    footPrint: string,
    machineName: string
    logEvent: string
}

export interface ILog extends IBaseLog {
}

export interface IReadonlyLog extends IBaseLog {
}

export type Log = ReadonlyRecord<IReadonlyLog>;

export const LogFactory = Record<IReadonlyLog>({
    id: 0,
    message: "",
    level: "",
    timeStamp: moment(Date.now()).format(),
    exception: "",
    instance: "",
    username: "",
    name: "",
    footPrint: "",
    machineName: "",
    logEvent: ""
});

export const LogFactoryFromJS = function (data: ILog) {

    return LogFactory(data);
}