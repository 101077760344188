import IconRefresh from "@bimser/icons/16/refresh";
import * as React from "react";
import BCButton from '../../../BCButton';
import BCCombobox, { IComboboxLoadingArgs, IComboboxSelectedChangeEventArgs } from '../../../BCCombobox';
import BCInputGroup from '../../../BCInputGroup';
import { IPropertyComponentProps, IPropertyItem, ISelectPropertyProps } from '../../entities';
const inputStyleWithPromise: React.CSSProperties = { width: 'calc(100% - 35px)' };
const inputStyle: React.CSSProperties = { width: '100%' };
const buttonStyle: React.CSSProperties = { width: '35px', border: "1px solid #d9d9d9" };

const SelectProperty: React.FC<IPropertyComponentProps & ISelectPropertyProps> = React.memo(props => {
    const [options, setOptions] = React.useState(props.item.options || []);

    React.useEffect(() => {
        setOptions(props.item.options);
    }, [props.item.options]);

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onSelectedChange = (e: IComboboxSelectedChangeEventArgs) => {
        props.item?.externalData?.onChange?.({ key: e.senderArgs?.option.key, text: e.senderArgs?.option.title, value: e.senderArgs?.option.value });
        props.customChangeEvent ? props.customChangeEvent(e.data) : replaceValue(props.item, e.data)
    }

    const getOptionsPromise = (): Promise<any> => {
        if (props.item.options?.isPromise && props.getOptionsPromise) {
            return props.getOptionsPromise(props.item)
        }
    }

    const onRefresh = () => {
        if (props.onRefresh) {
            let awaitedPromise = props.onRefresh(props.item);
            if (awaitedPromise) {
                awaitedPromise.then((result: IComboboxLoadingArgs) => {
                    setOptions(result ? result.options : [])
                });
            }
        }
    }

    const getCombobox = (styleWithPromise?: any) => {
        const _inputStyle = styleWithPromise ? styleWithPromise : inputStyle;
        const allowClear = props.item.externalData && props.item.externalData.allowClear !== undefined ? props.item.externalData.allowClear : true;
        return (
            <BCCombobox
                onSelectedChange={onSelectedChange}
                options={options}
                showSearch={true}
                style={props.style || _inputStyle}
                disabled={props.item.disabled || props.item.readOnly}
                allowClear={allowClear}
                value={props.value}
                onLoadOptions={getOptionsPromise}
                isRunOnLoadOptionsStartup={props.item.options?.isPromise}
                dropdownStyle={props.dropdownStyle}
                renderText={props.item.externalData && props.item.externalData.renderText}
            />
        );
    }

    const renderSelect = () => {
        if (props.item.options?.isPromise && !props.item.options.disabledRefreshButton) {
            return (<BCInputGroup compact={true} >
                {getCombobox(inputStyleWithPromise)}
                <BCButton
                    style={buttonStyle}
                    icon={<IconRefresh />}
                    onClick={onRefresh}
                />
            </BCInputGroup>);
        }
        else {
            return getCombobox();
        }
    }

    return renderSelect();
});

export default SelectProperty;
