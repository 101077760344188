import { isNullOrUndefined, MLHelper, ValidationHelper } from "@bimser/core";
import InputNumber from 'antd/lib/input-number';
import * as React from "react";
import * as Styles from './assets/styles.scss';
import { IFormatterInfo, IInputNumberProps } from './entities';
import { NumberFormatter } from "./helpers/NumberFormatter";
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const ALLOWED_CHARS = /[0-9/\-]+/;
const ALLOWED_KEYS = ['Backspace', 'Enter', 'Tab', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Delete', 'Home', 'End'];

import numbro from "numbro";

const BCInputNumber = React.forwardRef((props: IInputNumberProps, ref: React.ForwardedRef<HTMLInputElement>) => {

    Styles;

    const delimiters = MLHelper.numberVariables.delimiters;
    const decimalSeparator = delimiters.decimal;
    const thousandsSeparator = delimiters.thousands;

    const [NumFormatter] = React.useState(() => new NumberFormatter());
    const [value, setValue] = React.useState(isNullOrUndefined(props.value) ? null : props.value.valueOf());

    React.useEffect(() => {
        initFormatter();
    }, [props.precision, props.useThousandSeparator]);

    const initFormatter = () => NumFormatter.init(props.precision, props.useThousandSeparator);

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const className = cx({
        inputNumberStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center",
        [props.className]: true
    });

    const onChange = (v: any) => {
        v = v === null ? "" : v;
        if ((!isNaN(v) && ValidationHelper.Test(ValidationHelper.RegularExpressions.InputNumberRegex, v)) || v === "") {
            setValue(v);
            props.onChange?.(v);
        }
    }

    const parser = React.useCallback((displayValue) => {
        if (props.useThousandSeparator) {
            displayValue = displayValue.replace(new RegExp("\\" + thousandsSeparator, "g"), '');
        }

        const decimalSeparatorCount = (displayValue.match(new RegExp("\\" + decimalSeparator, "g")) || []).length;

        if (decimalSeparatorCount > 1 && value) {

            let currentText = value.toString();
            const regexp = /\./g;

            if (currentText.includes('.')) {
                displayValue = currentText.replace(regexp, decimalSeparator)
            } else {
                let formattedNum = numbro(Number(currentText)).format({ mantissa: props.precision });
                displayValue = formattedNum.replace(regexp, decimalSeparator);
            }

        }

        displayValue = displayValue.replace(decimalSeparator, '.');
        return displayValue

    }, [props.useThousandSeparator, props.precision]);

    const formatter = React.useCallback((v, info) => {
        if (!NumFormatter.__initialized) initFormatter();
        return !isNullOrUndefined(value) ? NumFormatter.format(v, info) : v;
    }, [props.precision, props.useThousandSeparator, value]);

    const onKeyDownCapture = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const functionKey = e.ctrlKey || e.shiftKey || e.altKey;
        const allowedKeys = ALLOWED_KEYS.includes(e.key);
        if (!allowedKeys && !functionKey) {
            const allowedChars = ALLOWED_CHARS.test(e.key);

            if (e.key === decimalSeparator) {
                if (e.currentTarget.value.includes(decimalSeparator)) {
                    e.preventDefault();
                }
            } else if (!allowedChars) {
                e.preventDefault();
            }
        }

    }

    return (
        <InputNumber
            ref={ref}
            prefixCls={props.prefixCls}
            min={!isNullOrUndefined(props.min) ? props.min : -Infinity}
            max={!isNullOrUndefined(props.max) ? props.max : Infinity}
            step={props.step || 1}
            value={value}
            onChange={onChange}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
            readOnly={props.readOnly}
            size={props.size}
            formatter={props.useThousandSeparator ? formatter : props.formatter}
            parser={props.useThousandSeparator ? parser : props.parser}
            placeholder={props.placeholder}
            style={props.style}
            className={className}
            name={props.name}
            precision={!isNullOrUndefined(props.precision) ? Math.min(props.precision, 100) : undefined}
            id={props.id}
            autoFocus={props.autoFocus}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            decimalSeparator={decimalSeparator}
            onKeyDownCapture={onKeyDownCapture}
        />
    )
});
export default BCInputNumber

export { IInputNumberProps, IFormatterInfo, NumberFormatter };

