import Dropdown from 'antd/lib/dropdown';
import * as React from "react";
import { BCMenu } from '..';
import { IDropdownWrapperProps } from './entities';

export default class BCDropdownWrapper extends React.Component<IDropdownWrapperProps, {}> {

    constructor(props: IDropdownWrapperProps) {
        super(props);

        this.renderItems = this.renderItems.bind(this);
    }

    renderItems() {
        const items = this.props.menuProps.items.map(item => ({
            ...item,
            className: ["ant-menu-item ant-menu-item-only-child", item.className].filter(Boolean).join(" "),
        }))

        return (
            <BCMenu
                {...this.props.menuProps}
                className={["ant-menu ant-menu-root ant-menu-vertical", this.props.menuProps.className].filter(Boolean).join(" ")}
                items={items}
            />
        )
    }

    render() {
        const menu = this.renderItems();

        return (
            <div
                onClick={e => this.props.stopPropagation && e.stopPropagation()}
            >
                <Dropdown overlay={menu} trigger={this.props.trigger} placement={this.props.placement} overlayClassName={this.props.overlayClassName} disabled={this.props.disabled}>
                    {this.props.children}
                </Dropdown>
            </div>
        )
    }

}

export { IDropdownWrapperProps };
