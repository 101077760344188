import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ValidationErrorInfo } from "@bimser/components";

import {
    CompanyObject, CompanyObjectFactoryFromJS, IBaseFormContent, ICompanyObject, IManager,
    IPropertyValue, IUser, Manager, ManagerFactoryFromJS, PropertyValue, PropertyValueFactoryFromJS,
    User, UserFactory, UserFactoryFromJS
} from '..';

export interface IUserFormContent extends IBaseFormContent {
    showingUser?: IUser,
    customProperties?: Array<IPropertyValue>,
    userManagers?: Array<IManager>,
    companyObjects?: Array<ICompanyObject>,
    validationErrors?: Array<ValidationErrorInfo>,
}

export interface IReadonlyUserFormContent extends IBaseFormContent {
    showingUser?: User,
    customProperties?: List<PropertyValue>,
    userManagers?: List<Manager>,
    companyObjects?: List<CompanyObject>,
    validationErrors?: List<ValidationErrorInfo>,
}

export type UserFormContent = ReadonlyRecord<IReadonlyUserFormContent>;

export const UserFormContentFactory = Record<IReadonlyUserFormContent>({
    panelId: "",
    showingUser: UserFactory(),
    customProperties: List<PropertyValue>(),
    isModified: false,
    initialHash: "",
    selectableListPanelisActive: false,
    userManagers: List<Manager>(),
    showLoading: false,
    companyObjects: List<CompanyObject>(),
    validationErrors: List<ValidationErrorInfo>()
});

export const UserFormContentFactoryFromJS = function (data: IUserFormContent) {
    let _customProperties: Array<PropertyValue> = data.customProperties?.length ? data.customProperties.map((value: IPropertyValue) => {
        return PropertyValueFactoryFromJS(value);
    }) : [];

    let _managers: Array<Manager> = data.userManagers?.length ? data.userManagers.map((value: IManager) => {
        return ManagerFactoryFromJS(value);
    }) : [];

    let _companyObjects: Array<CompanyObject> = data.companyObjects?.length ? data.companyObjects.map((value: ICompanyObject) => {
        return CompanyObjectFactoryFromJS(value);
    }) : [];

    return UserFormContentFactory({
        panelId: data.panelId,
        showingUser: UserFactoryFromJS(data.showingUser),
        customProperties: List(_customProperties),
        isModified: data.isModified,
        initialHash: data.initialHash,
        selectableListPanelisActive: data.selectableListPanelisActive,
        userManagers: List(_managers),
        showLoading: data.showLoading,
        companyObjects: List(_companyObjects),
        validationErrors: List(data.validationErrors)
    });
}