import { findMessage } from '@bimser/components';
import { List } from 'immutable';
import { put } from 'redux-saga/effects';
import { ServiceList } from '../../..';
import setLoginSliderItemsAction from '../../../system/loginSlider/actions/setLoginSliderItemsAction';
import { ILoginSliderItem, LoginSliderItem, LoginSliderItemFactoryFromJS } from '../../../system/loginSlider/entities/ILoginSliderItem';
import slide1 from '../assets/img/slide1.jpg';
import slide2 from '../assets/img/slide2.jpg';
import slide3 from '../assets/img/slide3.jpg';
import slide4 from '../assets/img/slide4.jpg';
import slide5 from '../assets/img/slide5.jpg';

export default function* getLoginSliderItems(hasToken: boolean) {
    try {
        if (hasToken) {
            let items: List<LoginSliderItem> = List(getDefaultSliderItems().map((i: ILoginSliderItem) => LoginSliderItemFactoryFromJS(i)));
            yield put(setLoginSliderItemsAction(items));
            return;
        }
        let response = yield ServiceList.login.WebInterfaceManagement.GetLoginSliderItems.call(undefined, undefined, true);
        if (response && response.length) {
            let items: List<LoginSliderItem> = List(response.map((i: ILoginSliderItem) => {
                i.imageDownloadUrl.downloadUrl = (window as any).serviceConfig.services.login.baseURL + "/" + i.imageDownloadUrl.downloadUrl;
                return LoginSliderItemFactoryFromJS(i);
            }));
            yield put(setLoginSliderItemsAction(items));
        } else {
            let items: List<LoginSliderItem> = List(getDefaultSliderItems().map((i: ILoginSliderItem) => LoginSliderItemFactoryFromJS(i)));
            yield put(setLoginSliderItemsAction(items));
        }
    } catch (error) {
        let items: List<LoginSliderItem> = List(getDefaultSliderItems().map((i: ILoginSliderItem) => LoginSliderItemFactoryFromJS(i)));
        yield put(setLoginSliderItemsAction(items));
    }
}

const getDefaultSliderItems = () => {
    return [
        {
            caption: findMessage.getAsMultiLanguageObject("101690"),
            text: findMessage.getAsMultiLanguageObject("101691"),
            imageDownloadUrl: {
                downloadUrl: slide1,
                mimeType: "image/jpg",
                path: ""
            },
            url: ""
        },
        {
            caption: findMessage.getAsMultiLanguageObject("101692"),
            text: findMessage.getAsMultiLanguageObject("101693"),
            imageDownloadUrl: {
                downloadUrl: slide2,
                mimeType: "image/jpg",
                path: ""
            },
            url: ""
        },
        {
            caption: findMessage.getAsMultiLanguageObject("101690"),
            text: findMessage.getAsMultiLanguageObject("101691"),
            imageDownloadUrl: {
                downloadUrl: slide3,
                mimeType: "image/jpg",
                path: ""
            },
            url: ""
        },
        {
            caption: findMessage.getAsMultiLanguageObject("101692"),
            text: findMessage.getAsMultiLanguageObject("101693"),
            imageDownloadUrl: {
                downloadUrl: slide4,
                mimeType: "image/jpg",
                path: ""
            },
            url: ""
        },
        {
            caption: findMessage.getAsMultiLanguageObject("101690"),
            text: findMessage.getAsMultiLanguageObject("101691"),
            imageDownloadUrl: {
                downloadUrl: slide5,
                mimeType: "image/jpg",
                path: ""
            },
            url: ""
        }
    ];
}