import * as React from "react";
import Form from 'antd/lib/form';
import { IColProps, IColSize, IFormItemProps, ValidateStatus } from "../entities";

//validation helper => Utils/validationHelper in components

const FormItem = Form.Item;

export default class BCFormItem extends React.Component<IFormItemProps, {}> {


    render() {

        return (
            <FormItem
                wrapperCol={this.props.wrapperCol}
                labelCol={this.props.labelCol}
                key={this.props.itemkey}
                hasFeedback={this.props.hasFeedback ? true : false}
                validateStatus={this.props.validateStatus ? this.props.validateStatus : ValidateStatus.validating}
                help={this.props.validateStatus ? this.props.help : ""}
                label={this.props.label}
                colon={this.props.colon}
                required={this.props.required}
                className={this.props.className}
                style={this.props.style}
                labelAlign={this.props.labelAlign}
            >
                {this.props.children}
            </FormItem>
        );
    }
}

export { IFormItemProps, IColProps, IColSize, ValidateStatus };
