import Popover from 'antd/lib/popover';
import * as React from "react";
import * as styles from './assets/style.scss';
import { IPopoverProps } from './entities';


export default class BCPopover extends React.Component<IPopoverProps, any> {

    constructor(props: IPopoverProps) {
        super(props);
        styles;
    }

    render() {
        return (
            <Popover {...this.props} />
        );
    }
}