import Button from 'antd/lib/button';
import * as React from "react";
import BCButton from '../BCButton';
import BCInput from '../BCInput';
import BCInputGroup from '../BCInputGroup';
import BCPopover from '../BCPopover';
import { IDropdownBoxProps } from './entities';
import IconMore from "@bimser/icons/16/more2";

export default class BCDropdownBox extends React.Component<IDropdownBoxProps, any>{

    inputStyle: React.CSSProperties = { width: 'calc(100% - 50px)' };
    buttonStyle: React.CSSProperties = { width: '50px' };
    displayNone: React.CSSProperties = { 'display': 'none' }
    constructor(props: IDropdownBoxProps) {
        super(props);
    }

    render() {

        return (
            <BCPopover title={this.props.title} content={this.props.content} trigger={'click'}>
                <>
                    <BCInputGroup size={this.props.size == "middle" ? "default" : this.props.size} compact={true}>
                        <BCInput style={this.inputStyle} readOnly={true} value={this.props.value} tabIndex={this.props.tabIndex} />
                        <BCButton style={this.buttonStyle} size={this.props.size} icon={<IconMore />} />
                    </BCInputGroup>
                    <Button style={this.displayNone}></Button>
                </>
            </BCPopover>
        )
    }
}