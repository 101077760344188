import Layout from 'antd/lib/layout';
import * as React from "react";
import { IWrapperProps } from '../entities';
import * as Styles from '../assets/layoutStyles.scss';

export default class BCContent extends React.Component<IWrapperProps, {}> {

    constructor(props: IWrapperProps) {
        super(props);
    }

    render() {
        return (
            <Layout.Content {...this.props} className={Styles.content} />
        );
    }
}