import { put, takeEvery } from 'redux-saga/effects';
import { ServiceList } from '../../../services/serviceList';
import * as ActionTypes from '../actions/actionTypes';
import {setLanguagesAction} from '../actions';

export default function* watchGetAllLanguages() {
    yield takeEvery(ActionTypes.GET_LANGUAGES_TRANSACTION, handleGetLanguages);
}

export function* handleGetLanguages() {
    try {

        let response = yield ServiceList.main.Configuration.GetLanguageList.call();

        if (response && response.data) {

            yield put(setLanguagesAction(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}
