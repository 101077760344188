import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseUserPermissionsState {
    canListDelegations?: boolean;
    canManageDelegations?: boolean;
    canLoginAs?: boolean;
    canCreateAnnouncement?: boolean;
    canDeleteAnnouncement?: boolean;
    canUpdateAnnouncement?: boolean;
    canAccessActivities: boolean;
    canRecycleBin: boolean;
}

export interface IUserPermissionsState extends IBaseUserPermissionsState {

}

export interface IReadonlyUserPermissionsState extends IBaseUserPermissionsState {

}

export type UserPermissionsState = ReadonlyRecord<IReadonlyUserPermissionsState>;

export const UserPermissionsStateFactory = Record<IReadonlyUserPermissionsState>({
    //canChangeLanguage: false,
    canListDelegations: false,
    canManageDelegations: false,
    canLoginAs: false,
    canCreateAnnouncement: false,
    canDeleteAnnouncement: false,
    canUpdateAnnouncement: false,
    canAccessActivities: false,
    canRecycleBin: false,
});

export const UserPermissionsStateFactoryFromJS = function (data: IUserPermissionsState) {
    return UserPermissionsStateFactory({
        ...data
    });
}