import { IBaseAction } from "@bimser/core";
import { List } from 'immutable';
import * as actionTypes from '../actions/actionTypes';
import { Location, LocationState, LocationStateFactory } from "../entities";


export default function (state: LocationState = LocationStateFactory(), action: IBaseAction<any>): LocationState {
    switch (action.type) {
        case actionTypes.SET_LOCATION_ACTION: {
            return uploadLocationsToStore(state, action);
        }
        default:
            return state;
    }
}

function uploadLocationsToStore(state: LocationState, action: IBaseAction<List<Location>>): LocationState {
    return state.set("items", action.payload);
}