import { List, Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { StatementType } from "./Enums";
import { BaseAction, BaseActionFactoryFromJS, IBaseAction, ActionCastFactory } from ".";

interface IBaseBaseStatement {
    enabled?: boolean,
    collapsed?: boolean,
    id: string,
    typeName: StatementType,
}

export interface IBaseStatement extends IBaseBaseStatement {
    actions: Array<IBaseAction>
}

export interface ReadOnlyBaseStatement extends IBaseBaseStatement {
    actions: List<BaseAction>
}

export type BaseStatement = ReadonlyRecord<ReadOnlyBaseStatement>;

export const BaseStatementObject: ReadOnlyBaseStatement = {
    enabled: true,
    collapsed: false,
    id: null,
    typeName: null,
    actions: List()
};

export const BaseStatementFactory = Record<ReadOnlyBaseStatement>(BaseStatementObject);

export const BaseStatementFactoryFromJS = function (data: IBaseStatement) {
    return BaseStatementFactory({
        ...data,
        actions: List(ActionCastFactory(data.actions))
    });
}