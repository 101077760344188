import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IIndexReportItem, IndexReportItem, IndexReportItemFactoryFromJS } from "./IIndexReportItem";

interface IBaseIndexReport {
    createdDate: string,
    isAnalizing: boolean
}

export interface IIndexReport extends IBaseIndexReport {
    items: Array<IIndexReportItem>
}

export interface IReadonlyIndexReport extends IBaseIndexReport {
    items: List<IndexReportItem>
}

export type IndexReport = ReadonlyRecord<IReadonlyIndexReport>;

export const IndexReportFactory = Record<IReadonlyIndexReport>({
    createdDate: null,
    isAnalizing: false,
    items: List()
});

export const IndexReportFactoryFromJS = function (data: IIndexReport) {
    return IndexReportFactory({
        ...data,
        items: List(data?.items?.map(i => IndexReportItemFactoryFromJS(i)) || [])
    });
}