enum SynergyFormExtensionList {
    EAF = "eaf"
}

enum TxtExtensionList {
    CS = "cs", TS = "ts", EPROJ = "eproj", CSS = "css", JS = "js", JSON = "json", TXT = "txt", XML = "xml", LOG = "log"
}

enum WordExtensionList {
    DOC = "doc", DOCM = "docm", DOCX = "docx", DOTM = "dotm", DOTX = "dotx"
}

enum ImageExtensionList {
    JPEG = "jpeg", JPG = "jpg", PNG = "png", TIFF = "tiff",
}

enum VideoExtensionList {
    MP4 = "mp4",
}

enum PowerPointExtensionList {
    POTM = "potm", PPAM = "ppam", PPSM = "ppsm", PPT = "ppt", PPTM = "pptm", PPTX = "pptx", SLDM = "sldm",
}

enum ExcelExtensionList {
    XLAM = "xlam", XLS = "xls", XLSB = "xlsb", XLSM = "xlsm", XLSX = "xlsx", XLTM = "xltm", XLTX = "xltx"
}

enum PdfExtensionList {
    PDF = "pdf"
}

enum CompressedExtensionList {
    ZIP = "zip", RAR = "rar", TAR = "tar", GZ = "gz"
}

export {
    SynergyFormExtensionList,
    TxtExtensionList,
    WordExtensionList,
    ImageExtensionList,
    VideoExtensionList,
    PowerPointExtensionList,
    ExcelExtensionList,
    PdfExtensionList,
    CompressedExtensionList
}