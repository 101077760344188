import { DmObjectType } from "@bimser/common";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import EnrichStatusType from "./EnrichStatusType";

interface IBaseIndexReportItem {
    objectType: DmObjectType,
    secretKey: string,
    name: string,
    enrichStatus: EnrichStatusType,
    enrichDateTime: any
}

export interface IIndexReportItem extends IBaseIndexReportItem {

}

export interface IReadonlyIndexReportItem extends IBaseIndexReportItem {

}

export type IndexReportItem = ReadonlyRecord<IReadonlyIndexReportItem>;

export const IndexReportItemFactory = Record<IReadonlyIndexReportItem>({
    objectType: DmObjectType.Repository,
    secretKey: null,
    name: null,
    enrichStatus: EnrichStatusType.NOT,
    enrichDateTime: null
});

export const IndexReportItemFactoryFromJS = function (data: IIndexReportItem) {
    return IndexReportItemFactory(data);
}