import { NormalizedLayoutStructureItems } from "../../../common/entities";

export const isInTabs = (view: NormalizedLayoutStructureItems, id: string, isSelf: boolean = true) => {
    let times = 0;

    const control = view.entities.items.get(id);

    if (control) {
        if (control.type === "Tabs" && !isSelf) times++;

        const parent = control.parentId ? view.entities.items.get(control.parentId) : null;

        if (parent) times += isInTabs(view, parent.id, false);
    }

    return times;
}