import { BCExtremeGridEntities } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { GridContextItem, IGridContextItem, GridContextItemFactoryFromJS } from "./IGridContextItem";
import { IDmData, DmData, DmDataFactoryFromJS } from "./IDmData";
import { IDmActionMenuItem, DmActionMenuItem, DmActionMenuItemFactoryFromJS } from "./IDmActionMenuItem";

export interface IBaseDmScene {
    showMode: "Grid" | "Card",
    name: string,
    id: string,
    isActive: boolean,
    secretKey?: string,
    contextMenuVisibleOn?: string,
    hasMoreItems?: boolean,
    isLoadingMore?: boolean,
    refreshKey?: string,
}

export interface IDmScene extends IBaseDmScene {
    items: Array<string>,
    contextMenuActions: Array<string>,
    topbarActions: Array<IDmActionMenuItem>,
    topbarOverflowActions: Array<IDmActionMenuItem>,
    selectedItems: Array<string>,
    loadOptions: BCExtremeGridEntities.ILoadOptions,
    gridContext: Array<IGridContextItem>,
    parents: Array<IDmData>
}

export interface IReadonlyDmScene extends IBaseDmScene {
    items: List<string>,
    contextMenuActions: List<string>,
    topbarActions: List<DmActionMenuItem>
    topbarOverflowActions: List<DmActionMenuItem>,
    selectedItems: List<string>,
    loadOptions: BCExtremeGridEntities.LoadOptions,
    gridContext: List<GridContextItem>,
    parents: List<DmData>
}

export type DmScene = ReadonlyRecord<IReadonlyDmScene>;

export const DmSceneFactory = Record<IReadonlyDmScene>({
    showMode: "Card",
    name: "",
    id: "",
    secretKey: "",
    isActive: false,
    hasMoreItems: true,
    isLoadingMore: false,
    refreshKey: "",
    selectedItems: List(),
    items: List(),
    loadOptions: BCExtremeGridEntities.LoadOptionsFactory(),
    gridContext: List(),
    parents: List(),
    contextMenuVisibleOn: null,
    contextMenuActions: List(),
    topbarActions: List(),
    topbarOverflowActions: List()
});

export const DmSceneFactoryFromJS = function (scene: IDmScene) {
    let _gridContext: Array<GridContextItem> = scene && scene.gridContext ? scene.gridContext.map((i) => GridContextItemFactoryFromJS(i)) : [];
    let _parents: Array<DmData> = scene && scene.parents ? scene.parents.map(i => DmDataFactoryFromJS(i)) : [];
    let _topbarOverflowActions: Array<DmActionMenuItem> = scene && scene.topbarOverflowActions ? scene.topbarOverflowActions.map(i => DmActionMenuItemFactoryFromJS(i)) : [];
    let _topbarActions: Array<DmActionMenuItem> = scene && scene.topbarActions ? scene.topbarActions.map(i => DmActionMenuItemFactoryFromJS(i)) : [];

    return DmSceneFactory({
        ...scene,
        items: List(scene.items),
        selectedItems: List(scene.selectedItems),
        loadOptions: BCExtremeGridEntities.LoadOptionsFactoryFromJS(scene.loadOptions),
        gridContext: List(_gridContext),
        parents: List(_parents),
        contextMenuActions: List(scene.contextMenuActions),
        topbarActions: List(_topbarActions),
        topbarOverflowActions: List(_topbarOverflowActions)
    });

}