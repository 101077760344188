import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseLocation {
    name: string,
}

export interface ILocation extends IBaseLocation { }

export interface IReadonlyLocation extends IBaseLocation { }

export type Location = ReadonlyRecord<IReadonlyLocation>;

export const LocationFactory = Record<IReadonlyLocation>({
    name: null
});

export const LocationFactoryFromJS = function (data: ILocation) {
    return LocationFactory(data);
}