import { IBaseAction } from "@bimser/core";
import * as ActionTypes from "../../../components/Login/actions/actionTypes";
import { GetLoginParametersResponse } from "../../../components/Login/entities/GetLoginParametersResponse";

export default function (state: boolean = false, action: IBaseAction<any>): boolean {
    switch (action.type) {
        case ActionTypes.LOGIN_PARAMETERS: {
            return setMobileApp(state, action);
        }
        default:
            return state;
    }
}

function setMobileApp(state: boolean, action: IBaseAction<GetLoginParametersResponse>): boolean {
    return action.payload.userCustomProperties.mobileApp;
}
