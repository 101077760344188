import { put, takeEvery } from 'redux-saga/effects';
import { ServiceList } from '../../../services/serviceList';
import * as ActionTypes from '../actions/actionTypes';
import {setCultureAction} from '../actions';

export default function* watchGetAllCultures() {
    yield takeEvery(ActionTypes.GET_CULTURE_TRANSACTION, handleGetCultures);
}

export function* handleGetCultures() {
    try {

        let response = yield ServiceList.main.Configuration.GetCultureList.call();

        if (response) {
            yield put(setCultureAction(response));
        }
    } catch (error) {
        console.log(error);
    }
}
