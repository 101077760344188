import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseDepartmentDetail {
    id: string,
    caption: string
}

export interface IDepartmentDetail extends IBaseDepartmentDetail { }

export interface IReadonlyDepartmentDetail { }

export type DepartmentDetail = ReadonlyRecord<IReadonlyDepartmentDetail>;

export const DepartmentDetailFactory = Record<IReadonlyDepartmentDetail>({
    id: "",
    caption: ""
});

export const DepartmentDetailFactoryFromJS = function (data: IDepartmentDetail) {
    return DepartmentDetailFactory({
        id: data.id,
        caption: data.caption
    });
}