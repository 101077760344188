export const ON_LOAD_MESSAGES = "APP_COMMON.ON_LOAD_MESSAGES";
export const GENERAL_INITIALIZER_TRANSACTION_ACTION = "APP_COMMON.GENERAL_INITIALIZER_TRANSACTION_ACTION";
export const HUBCONNECTION_MESSAGES_TRANSACTION = "HUBCONNECTION_MESSAGES_TRANSACTION";
export const OPEN_DM_SELECTOR_ACTION = "APP_COMMON.OPEN_DM_SELECTOR_ACTION";
export const DM_SELECTOR_RESOLVED = "APP_COMMON.DM_SELECTOR_RESOLVED";
export const RELATED_DOCUMENTS_UPLOAD_FILE_ACTION = 'APP_COMMON.RELATED_DOCUMENTS_UPLOAD_FILE_ACTION';
export const RELATED_DOCUMENTS_VIEW_FILE = 'APP_COMMON.RELATED_DOCUMENTS_VIEW_FILE';
export const RELATED_DOCUMENTS_DOWNLOAD_FILE = 'APP_COMMON.RELATED_DOCUMENTS_DOWNLOAD_FILE';
export const SET_DELEGATION_INFORMATION = "DELEGATIONS.SET_DELEGATION_INFORMATION";

export const DM_INITIALIZER = "DM.DM_INITIALIZER";
export const MOBILE_INITIALIZER = "MOBILE.MOBILE_INITIALIZER";
//
export const DEPLOY_AGENT_MESSAGE_ACTION = "DEPLOY_AGENT.DEPLOY_AGENT_MESSAGE_ACTION";

export const SYNERGY_LOG_ACTION = "APP_COMMON.SYNERGY_LOG_ACTION";