import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IHumanResourceModule } from ".."

interface IBaseHumanResourceMenu {
    menuKey: IHumanResourceModule,
    count: number,
    showSwitch: boolean,
    switchOn: boolean
}

export interface IHumanResourceMenu extends IBaseHumanResourceMenu {
}

export interface IReadonlyHumanResourceMenu extends IBaseHumanResourceMenu {
}

export type HumanResourceMenu = ReadonlyRecord<IReadonlyHumanResourceMenu>;

export const HumanResourceMenuFactory = Record<IReadonlyHumanResourceMenu>({
    menuKey: null,
    count: 0,
    showSwitch: false,
    switchOn: false
});

export const HumanResourceMenuFactoryFromJS = function (data: IHumanResourceMenu) {
    return HumanResourceMenuFactory(data);
}