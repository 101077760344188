import { BCButton, BCCombobox, BCDrawer, BCForm, BCFormItem, BCInput, BCInputMultiLanguage, BCLoading, BCMultipleCombobox, BCSwitch, findMessage, IInputChangeEventArgs, ValidateStatus } from "@bimser/components";
import React from "react";
import Style from "../../common/assets/style.scss";
import DrawerFormHeader from "../../../common/DrawerFormHeader"
import { IHumanResourceModule, Modules } from "../../../../entities";
import { formRegExp } from "../../../common/helper";
import { IDictionary, MLHelper } from "@bimser/core";
import SaveIcon from "@bimser/icons/16/save";
import IOrganizationFormProps from "../entities/IProps";

let classNames = require('classnames/bind');
let cx = classNames.bind(Style);

export default (props: IOrganizationFormProps) => {
    const [state, setState] = React.useState<Modules.IOrganization>(Modules.OrganizationFactory().toJS());
    const [validateStatus, setValidateStatus] = React.useState<IDictionary<ValidateStatus>>({
        code: ValidateStatus.validating,
        description: ValidateStatus.validating,
        company: ValidateStatus.validating,
        plants: ValidateStatus.validating
    });
    const [ saveButtonDisabled, setSaveButtonDisabled ] = React.useState<boolean>(true);
    const [ plantSearchValue, setPlantSearchValue ] = React.useState<string>("");
    const [ parentSearchValue, setParentSearchValue ] = React.useState<string>("");

    const plantVisible = React.useMemo(() => (
         props.menus.find(menu => menu.menuKey == IHumanResourceModule.Plant).switchOn
    ), [props.menus])

    React.useEffect(() => {
        if(props.data)
            setState(props.data)
    }, [])

    React.useEffect(() => {
        const plantRequired = plantVisible ? ValidateStatus.error : ValidateStatus.success;

        const status = {
            code: state.code ? ValidateStatus.success : ValidateStatus.error,
            description: MLHelper.getMLText(state.description) ? ValidateStatus.success : ValidateStatus.error,
            company: state.companyId ? ValidateStatus.success : ValidateStatus.error,
            plants: state.plants.length ? ValidateStatus.success : plantRequired,
        }

        setValidateStatus(status);
        setSaveButtonDisabled(Object.values(status).includes(ValidateStatus.error));
    }, [ state ])

    const onChangeInputData = React.useCallback((key: string, value: any) => {
        setState({ ...state, [key]: value })
    }, [state])

    const onSave = React.useCallback(() => {
        props.onSave({
            data: {
                modalId: props.modalId,
                form: state,
                data: props.data || Modules.OrganizationFactory().toJS()
            }
        });
    }, [state])

    const onClose = React.useCallback(() => {
        props.onClose({
            data: {
                modalId: props.modalId,
                data: props.data || Modules.OrganizationFactory().toJS(),
                form: state
            }
        })
    }, [state])

    const saveButton = () => (
        <BCButton
            className={Style.saveButton}
            icon={<SaveIcon/>}
            onClick={onSave}
            type="ghost"
            disabled={saveButtonDisabled || props.loading}
        />
    )

    const onSelectCompany = React.useCallback((value: string) => {
        let company;
        if(value) {
            company = MLHelper.getMLText(props.companies.find(item => item.id == value)?.description);
        }

        setState({
            ...state,
            ...{
                company,
                companyId: value,
                plants: []
            }
        })
    }, [props.companies, state])

    const onSelectPlants = React.useCallback((value: string[]) => {
        const plants = value?.map(id => ({ id, description: props.plants?.find(plant => plant.id == id)?.description }));

        setState({
            ...state,
            plants,
        })
    }, [props.plants, state])

    const onSelectParent = React.useCallback((value: string) => {
        setState({
            ...state,
            parentDescription: props.parents?.find(parent => parent.id == value)?.description,
            parentId: value
        })
    }, [props.parents, state])

    const onSearchPlants = React.useCallback((value: string) => {
        setPlantSearchValue(value);

        props.onSearchPlants({
            data: {
                modalId: props.modalId,
                companyId: state.companyId,
                searchValue: value,
                plants: state.plants
            }
        })
    }, [state.plants, state.companyId])

    const onSearchOrganization = React.useCallback((value: string) => {
        setParentSearchValue(value);

        props.onSearchOrganizations({
            data: {
                modalId: props.modalId,
                searchValue: value,
                parents: state.parentId ? { id: state.parentId, description: state.parentDescription, status: true} : undefined
            }
        })
    }, [state.parentId, state.parentDescription])
    
    const filterOption = (value: string, option: any) => option.text.toLocaleLowerCase().includes(value.toLocaleLowerCase());

    const renderCompanyCombobox = () => {
        const options = props.companies.filter(company => company.status).map(company => ({
            text: MLHelper.getMLText(company.description),
            key: company.id,
            value: company.id
        }));

        return <BCCombobox
            showSearch
            options={options}
            filterOption={filterOption}
            dropdownClassName={Style.companyComboboxDropdown}
            value={state.companyId}
            onSelectedChange={(args) => onSelectCompany(args.data as string)}
            allowClear
            getPopupContainer={() => document.body}
            placeholder={findMessage.get("101375")}
        />
    }

    const renderPlantsCombobox = () => {
        const options = props.plants.filter(plant => state.companyId == plant.companyId).map(plant => ({
            text: plant.description,
            key: plant.id,
            value: plant.id
        }));

        return <BCMultipleCombobox
            mode="multiple"
            showSearch
            options={options}
            filterOption={filterOption}
            dropdownClassName={cx({ companyComboboxDropdown: true, hide: !(options.length || plantSearchValue.length) })}
            value={state.plants?.map(plant => plant.id) ?? []}
            onSelectedChange={(args) => onSelectPlants(args.data as string[])}
            allowClear
            getPopupContainer={() => document.body}
            placeholder={findMessage.get("102785")}
            disabled={!state.companyId}
            onSearch={onSearchPlants}
        />
    }

    const renderParentCombobox = () => {
        const options = props.parents.filter(parent => parent.status && parent.id != state.id).map(parent => ({
            text: parent.description,
            key: parent.id,
            value: parent.id
        }));

        return <BCCombobox
            showSearch
            options={options}
            filterOption={filterOption}
            dropdownClassName={cx({ companyComboboxDropdown: true, hide: !(options.length || parentSearchValue.length) })}
            value={state.parentId}
            onSelectedChange={(args) => onSelectParent(args.data as string)}
            allowClear
            getPopupContainer={() => document.body}
            placeholder={findMessage.get("102799")}
            onSearch={onSearchOrganization}
        />
    }

    const formContent = () => {
        return (
            <BCForm
                layout="vertical"
            >
                <BCFormItem
                    label={findMessage.get("100129")}
                    required={true}
                    validateStatus={validateStatus.code}
                >
                    <BCInput
                        regExp={{ regExpString: formRegExp[IHumanResourceModule.Organization].code }}
                        placeHolder={findMessage.get("100129")}
                        value={state.code}
                        disabled={!!state.id}
                        onChange={(args: IInputChangeEventArgs) => onChangeInputData("code", args.data)}   
                    />
                </BCFormItem>
                <BCFormItem
                    label={findMessage.get("100022")}
                    required={true}
                    validateStatus={validateStatus.description}
                >
                    <BCInputMultiLanguage
                        placeHolder={findMessage.get("100022")}
                        values={state.description}
                        currentLanguage={MLHelper.getMLInfo().currentLanguage}
                        supportedLanguages={MLHelper.getMLInfo().supportedLanguages}
                        onLanguageValueChange={(culture: string, value: string) => {
                            let _description = state.description;
                            _description[culture] = value;
                            onChangeInputData("description", _description)
                        }}
                    />
                </BCFormItem>
                <BCFormItem
                    label={findMessage.get("102799")}
                >
                    {renderParentCombobox()}
                </BCFormItem>
                <BCFormItem
                    label={findMessage.get("101375")}
                    required={true}
                    validateStatus={validateStatus.company}
                >
                    {renderCompanyCombobox()}
                </BCFormItem>
                {state.companyId && plantVisible ? <BCFormItem
                    label={findMessage.get("102785")}
                    required={true}
                    validateStatus={validateStatus.plants}
                >
                    {renderPlantsCombobox()}
                </BCFormItem>: ""}
                <BCFormItem
                    label={findMessage.get("100199")}
                    required={false}
                >
                    <BCSwitch
                        checked={state.status}
                        onChange={(checked: boolean) => onChangeInputData("status", checked)}
                    />
                </BCFormItem>
            </BCForm>
        )
    }

    return (
        <BCDrawer
            key={props.modalId}
            className={Style.HRDrawerForm}
            placement="right"
            visible={true}
            title={findMessage.get("100657")}
            destroyOnClose={true}
            renderTitle={(title: string) => <DrawerFormHeader title={title} onClose={onClose} saveButton={saveButton()}/>}
            closable={false}
        >
            <BCLoading show={props.loading}>
                {formContent()}
            </BCLoading>
        </BCDrawer>
    )
}