import { isNullOrUndefined } from "@bimser/core";
import * as React from "react";
import BCInputGroup from '../../../BCInputGroup';
import * as Styles from "../../assets/style.scss";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import { PLabel } from './helper';
import InputNumberProperty from './InputNumberProperty';

const inputStyle: React.CSSProperties = { width: '100%' };

export default function MarginProperty(props: IPropertyComponentProps) {

    const returnValueType: 'css' | 'numberArray' = props.item.externalData?.returnValueType || 'numberArray';

    const convertCSS2Arr = React.useMemo<[number, number, number, number]>(() => {

        if (returnValueType === 'numberArray') return props.value;

        let tokenValue = props.value || '0px 0px 0px 0px';
        let valueArr: number[] = tokenValue.split('px')
            .filter((n: string) => { return n !== undefined && n !== null && n !== "" })
            .map(Number);

        if (valueArr && valueArr.length === 4) {
            return [valueArr[0], valueArr[1], valueArr[2], valueArr[3]]
        } else {
            console.warn('Invalid margin property value, reset to [0, 0, 0, 0]')
            return [0, 0, 0, 0]
        }

    }, [props.value]);

    const converArr2CSS = (value: [number, number, number, number]): string => {
        return `${value[0]}px ${value[1]}px ${value[2]}px ${value[3]}px`
    }

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const changeMarginValue = (side: number, value: number) => {
        if (isNullOrUndefined(value)) value = 0;
        let v: any = null;
        if (typeof props.value === "object" && returnValueType != 'css') {
            v = { ...props.value };
            let changeKey = Object.keys(props.value)[side];
            v[changeKey] = value;
        }
        else {
            let currentValue = returnValueType === 'css' ? convertCSS2Arr : props.value;
            let valueInstance = currentValue || [0, 0, 0, 0];
            valueInstance[side] = value;
            v = returnValueType === 'css' ? converArr2CSS(valueInstance) : valueInstance;
        }

        if (props.customChangeEvent) {
            props.customChangeEvent(v);
        } else {
            replaceValue(props.item, v)
        }
    }

    const customChangeEvent = (value: any, props: IPropertyComponentProps) => {
        changeMarginValue(props.externalData, value);
    }

    const convertObjectToArray = React.useMemo(() => {
        return Object.keys(props.value || {}).map((k, i) => props.value[k]);
    }, [props.value]);

    let value = props.value;

    if (returnValueType === 'css') {
        value = convertCSS2Arr;
    } else if (typeof props.value === "object") {
        value = convertObjectToArray;
    }

    return (
        <BCInputGroup size={'small'} >
            <div className={Styles.propertyColumn}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    externalData={0}
                    customChangeEvent={customChangeEvent}
                    value={value[0]}
                />
                <PLabel label='Top' />
            </div>
            <div className={Styles.propertyColumn}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    externalData={1}
                    customChangeEvent={customChangeEvent}
                    value={value[1]}
                />
                <PLabel label='Right' />
            </div>
            <div className={Styles.propertyColumn}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    externalData={2}
                    customChangeEvent={customChangeEvent}
                    value={value[2]}
                />
                <PLabel label='Down' />
            </div>
            <div className={Styles.propertyColumn}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    externalData={3}
                    customChangeEvent={customChangeEvent}
                    value={value[3]}
                />
                <PLabel label='Left' />
            </div>
        </BCInputGroup>
    )
}