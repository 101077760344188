import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseCaptchaState {
    showCaptcha: boolean;
    captchaId?: string;
    captchaImage?: string;
}

export interface ICaptchaState extends IBaseCaptchaState {

}

export interface IReadonlyCaptchaState extends IBaseCaptchaState {

}

export type CaptchaState = ReadonlyRecord<IReadonlyCaptchaState>;

export const CaptchaStateFactory = Record<IReadonlyCaptchaState>({
    showCaptcha: false,
    captchaId: null,
    captchaImage: null
});

export const CaptchaStateFactoryFromJS = function (data: ICaptchaState) {
    return CaptchaStateFactory(data);
}