import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import ILoginMainProps from "../entities/ILoginMainProps";
import { ILoginUser } from '../entities/LoginUser';
import { LoginFormProperties, ILoginFormProperties } from '../entities/LoginFormProperties';
import { CaptchaState } from '../entities/ICaptchaState';
import { List } from 'immutable';
import { MFAType } from '../entities';
import { DomainProperties } from '../entities/DomainProperties';
import { BrowserProperties } from '../entities/IBrowserProperties';

const getLoginUserSelector = (state: BaseState, props: ILoginMainProps) => {
    let loginUser: ILoginUser = state.user.data.toJS();
    return loginUser;
};

const getCaptchaStateSelector = (state: BaseState) => {
    return state.user.captcha;
};

const loadingStatus = (state: BaseState) => {
    return state.user.isLoading;
};

const mobileSignatureFingerPrintSelector = (state: BaseState) => {
    return state.user.fingerPrint;
};

const selectedCulture = (state: BaseState) => state.user.selectedCulture;

const loginFormProperties = (state: BaseState) => {
    return state.user.loginFormProperties;
};

const canChangeLanguageSelector = (state: BaseState) => state.system.language.canChangeLanguage;

const getMFAStatusSelector = (state: BaseState) => state.user.MFAProperties.mfaEnabled;

const getCurrentLoginProcedure = (state: BaseState) => state.user.MFAProperties.currentLoginProcedure;

const getTempTokenKeySelector = (state: BaseState) => state.user.MFAProperties.tempTokenKey;

const getMFATypesSelector = (state: BaseState) => state.user.MFAProperties.mfaTypes;

const getCurrentMFAMethodSelector = (state: BaseState) => state.user.MFAProperties.currentMFAMethod;

const getDomainPropertiesSelector = (state: BaseState) => state.user.domainProperties;

const getBrowserPropertiesSelector = (state: BaseState) => state.user.browserProperties;

const getDomainProperties = createSelector(
    [getDomainPropertiesSelector],
    (domainProperties: DomainProperties) => {
        return domainProperties.toJS();
    }
)

const getLoginUser = createSelector(
    [
        getLoginUserSelector
    ],
    (data: ILoginUser) => {
        return data;
    }
);

const getCaptchaState = createSelector(
    [
        getCaptchaStateSelector
    ],
    (captcha: CaptchaState) => captcha.toJS()
);

const getLoadingStatus = createSelector(
    [
        loadingStatus
    ],
    (data: boolean) => {
        return data;
    }
);

const getSelectedCulture = createSelector(
    [
        selectedCulture
    ],

    (data: string) => {
        return data;
    }
);

const getLoginFormProperties = createSelector(
    [
        loginFormProperties
    ],

    (data: LoginFormProperties) => {
        return data.toJS();
    }
);

const getCanChangeLanguage = createSelector(
    [
        canChangeLanguageSelector
    ],
    (canChangeLanguage: boolean) => canChangeLanguage
);

const getMobileSignFingerprint = createSelector(
    [
        mobileSignatureFingerPrintSelector
    ],
    (fingerPrint: string) => fingerPrint
);

const getMFAStatus = createSelector(
    [
        getMFAStatusSelector
    ],
    (MFAStatus: boolean) => MFAStatus
);

const getMFAState = createSelector(
    [
        getCurrentLoginProcedure,
        getTempTokenKeySelector
    ],
    (currentLoginProcedure: string, tempTokenKey: string) => ({ currentLoginProcedure, tempTokenKey })
);

const getMFATypes = createSelector(
    [
        getMFATypesSelector
    ],
    (mfaTypes: List<MFAType>) => mfaTypes.toJS()
);
const getCurrentMFAMethod = createSelector(
    [
        getCurrentMFAMethodSelector
    ],
    (currentMFAMethod: MFAType) => currentMFAMethod
);

const getBrowserProperties = createSelector(
    [
        getBrowserPropertiesSelector
    ],
    (browserProperties: BrowserProperties) => browserProperties.toJS()
);

export { getDomainProperties, getLoginUser, getLoadingStatus, getSelectedCulture, getLoginFormProperties, getCanChangeLanguage, getMobileSignFingerprint, getCaptchaState, getMFAStatus, getMFAState, getMFATypes, getCurrentMFAMethod, getBrowserProperties };
