import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseICommonFormAction {
    windowId: string
}

export interface ICommonFormAction extends IBaseICommonFormAction {
}

export interface IReadonlyBaseICommonFormAction extends IBaseICommonFormAction {
}

export type CommonFormAction = ReadonlyRecord<IReadonlyBaseICommonFormAction>;

export const CommonFormActionRecord: IReadonlyBaseICommonFormAction = {
    windowId: null
}

export const CommonFormActionFactory = Record<IReadonlyBaseICommonFormAction>(CommonFormActionRecord);

export const CommonFormActionFactoryFromJS = function (data: ICommonFormAction) {
    return CommonFormActionFactory(data)
}