import React, { lazy, Suspense } from 'react';
import IWFProcessListProps from "./entities/IWFProcessListProps"
import { injectSaga } from "app-init"

const WorkflowManagementContainerLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./containers"));

function WFProcessListContainer(props: React.PropsWithChildren<IWFProcessListProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <WorkflowManagementContainerLazy {...props} />
        </Suspense>
    );
}
WFProcessListContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-workflow" */ "./sagas").then(({ default: saga }) => {
        injectSaga("workflowProcessList", saga);
    })
}

export default WFProcessListContainer;