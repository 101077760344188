import { Map, Record } from "immutable";
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { ILayoutStructureItem, LayoutStructureItem, LayoutStructureItemFactoryFromJS } from '../entities';

export interface ILayoutStructureItems {
    items: IDictionary<ILayoutStructureItem>,
    ghostItems: IDictionary<ILayoutStructureItem>
}

export interface IReadonlyLayoutStructureItems {
    items: Map<string, LayoutStructureItem>,
    ghostItems: Map<string, LayoutStructureItem>
}

export type LayoutStructureItems = ReadonlyRecord<IReadonlyLayoutStructureItems>;

export const LayoutStructureItemsFactory = Record<IReadonlyLayoutStructureItems>({
    items: Map(),
    ghostItems: Map()
});

export const LayoutStructureItemsFactoryFromJS = function (data: ILayoutStructureItems) {
    let items: IDictionary<LayoutStructureItem> = {};

    for (let key in data.items) {
        let item = data.items[key];
        items[key] = LayoutStructureItemFactoryFromJS(item);
    }

    let ghostItems: IDictionary<LayoutStructureItem> = {};

    for (let key in data.ghostItems) {
        let ghostItem = data.ghostItems[key];
        ghostItems[key] = LayoutStructureItemFactoryFromJS(ghostItem);
    }

    return LayoutStructureItemsFactory({
        items: Map(items),
        ghostItems: Map(ghostItems)
    });
}