import { debounce } from "@bimser/core";
import * as React from "react";
import BCTextArea from '../../../BCTextArea';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defultStyle: React.CSSProperties = { width: '100%' };

export default function TextAreaProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const triggerChange = (data: any) => {
        props.customChangeEvent ? props.customChangeEvent(data) : replaceValue(props.item, data)
    }

    const onChange = debounce((e: any) => {
        if (!props.item.externalData?.triggerChangeOnBlur) {
            triggerChange(e.data);
        }
    }, props.item?.externalData?.debounceOnChange ? 200 : 0)

    const onBlur = (e: any) => {
        if (props.item.externalData?.triggerChangeOnBlur) {
            triggerChange(e.target.value);
        }
    }

    return (
        <BCTextArea
            value={props.value || props.item.placeholder}
            onChange={onChange}
            disabled={props.item.disabled}
            readOnly={props.item.readOnly}
            style={props.style || defultStyle}
            rows={(props.item.options && props.item.options.rows) ? props.item.options.rows : 3}
            onBlur={onBlur}
        />
    )
}