import React, { lazy, Suspense } from 'react';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-data-security" */ "./containers"));

function DataSecurityContainer(props: React.PropsWithChildren<any>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
DataSecurityContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-data-security" */ "./injector");
}

export default DataSecurityContainer;