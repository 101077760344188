import { Record, List } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';
import { IconInfo } from '@bimser/icons';

interface IBaseDmActionMenuItem{
    key: string,
    name: string,
    title: string,
    icon?: IconInfo
}

export interface IDmActionMenuItem extends IBaseDmActionMenuItem {
    subItems: Array<string>
}

export interface IReadonlyDmActionMenuItem extends IBaseDmActionMenuItem {
    subItems: List<string>
}

export type DmActionMenuItem = ReadonlyRecord<IReadonlyDmActionMenuItem>;

export const  DmActionMenuItemFactory = Record<IReadonlyDmActionMenuItem>({
    key: null,
    name: null,
    title: null,
    icon: null,
    subItems: List()
});

export const DmActionMenuItemFactoryFromJS = function (data: IDmActionMenuItem) {
    return DmActionMenuItemFactory({
        ...data,
        subItems: List(data.subItems)
    });
}