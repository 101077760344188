
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseTheme {
    backgroundColor: string,
    image: string,
    primaryColor: string
}

export interface ITheme extends IBaseTheme {

}

export interface IReadonlyTheme extends IBaseTheme {

}

export type Theme = ReadonlyRecord<IReadonlyTheme>;

export const ThemeFactory = Record<IReadonlyTheme>({
    backgroundColor: null,
    image: null,
    primaryColor: null
});

export const ThemeFactoryFromJS = function (data: ITheme) {
    return ThemeFactory(data);
}