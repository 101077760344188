import { BCCard, BCLoading, BCSkeleton, ContentType, findMessage } from '@bimser/components';
import React from 'react';
import Style from '../../assets/style.scss';
import IProcessDetailsProps from '../../entities/widgets/ProcessDetailsProps';
import IconTask from "@bimser/icons/32/task-approve";
import moment from 'moment';
import { ExtremeGrid, ExtremeGridAPI, panelType } from '@bimser/common';
import { IDictionary, MLHelper } from '@bimser/core';
import { IExtremeGridColumn } from '@bimser/components/src/BCExtremeGrid/entities';
import { dataTypeOfProcessStructureType } from '../../utils';

const emptyView = require("../../../../assets/jobs-empty.svg");
const cx = require('classnames/bind').bind(Style);

const GRID_ID = "PROCESS_DETAILS";
const pageSize = 10;

export default (props: IProcessDetailsProps) => {

    React.useEffect(() => {
        ExtremeGridAPI.create({
            id: GRID_ID,
            columns: columns,
            uniqueDataField: "id",
            selection: {
                enabled: true,
                mode: "single"
            },
            pager: { visible: false, showPageSizeSelector: false, showNavigationButtons: false },
            paging: { enabled: false, pageSize: props.totalCount },
            filtering: { visible: false },
            scrolling: {
                mode: "infinite",
                rowRenderingMode: "virtual",
                columnRenderingMode: "virtual",
            },
            languageOptions: {
                currentLanguage: MLHelper.currentLanguage,
                supportedLanguages: MLHelper.supportedLanguages
            },
            columnAutoWidth: false
        });

        return () => ExtremeGridAPI.get(GRID_ID).removeDataGrid();
    }, []);

    const columnsCellTemplate = (args) => {
        let value = args.displayText;
        
        if(args.column.dataType == "datetimeOffset") {
            return (
                <span
                    title={moment(args.value).format("L LTS")}
                >
                    {moment(args.value).fromNow()}
                </span>
            )
        }

        return <span>{value}</span>
    }

    const columns = React.useMemo((): IExtremeGridColumn[] => {
        if(props.structure?.length) {
            const excludeItems = ["ProcessID", "RequestId"];
            const isCreateDateInColumn = props.structure.find(col => col.name == "CreateDate");
            const excludedItems = props.structure.filter(col => !excludeItems.includes(col.name));
            let items = excludedItems.slice(0, 3);

            if(isCreateDateInColumn) {
                items = [isCreateDateInColumn, ...excludedItems.filter(col => col.name != "CreateDate").slice(0, 2)];
            }

            const gridItems = items.map(i => i.name);
            const hidingItems = props.structure.filter(col => !gridItems.includes(col.name));
            const widths = ["%43", "%15", "%30"];

            const iconColumn : IExtremeGridColumn= {
                name: "icon",
                caption: "",
                visible: true,
                width: 45,
                cellTemplate: () => <IconTask />
            }
            
            const otherColumns: IExtremeGridColumn[] = [...items, ...hidingItems].map((item, index) => {
                return {
                    name: item.name,
                    caption: item.caption,
                    dataType: dataTypeOfProcessStructureType[item.type],
                    visible: true,
                    allowHiding: !gridItems.includes(item.name),
                    hidingPriority: !gridItems.includes(item.name) ? index : undefined,
                    width: widths[index],
                    cellTemplate: columnsCellTemplate
                }
            })


            return [iconColumn, ...otherColumns];
        }

        return [];
        
    }, [props.selectedProcess, props.structure]);

    React.useEffect(() => {
        ExtremeGridAPI.get(GRID_ID).setColumns(columns);
    }, [columns]);

    React.useEffect(() => {
        ExtremeGridAPI.get(GRID_ID).setData({
            data: props.data,
            totalCount: props.totalCount
        });
    }, [props.data]);

    React.useEffect(() => {
        if(props.loading)
            ExtremeGridAPI.get(GRID_ID).showLoading();
        else
            ExtremeGridAPI.get(GRID_ID).hideLoading();

    }, [props.loading]);

    const onLoadMore = () => {
        const limit = { skip: props.data?.length, take: pageSize }

        if(props.totalCount > limit.skip && !props.loading) {
            props.onLoadDetails({
                panelId: props.panelId,
                panelType: panelType.startupLayout,
                data: {
                    limit
                }
            })
        }
    }

    const renderCustomEmpty = () => (
        <BCLoading show={props.loading}>
            <div className={cx({ emptyView: true, inCard: true })}>
                <img src={emptyView.default} className={Style.emptyViewImg} />
                <span className={Style.emptyViewTitle}>{findMessage.get(props.selectedProcess ? "102969" : "102971")}</span>
                <span className={Style.emptyViewDesc}>{findMessage.get(props.selectedProcess ? "102970" : "102972")}</span>
            </div>
        </BCLoading>
    )

    const onClickItem = (item: IDictionary<any>) => {
        props.onClickItem({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: item
        })
    }

    return (
        <BCCard
            bordered={false}
            className={Style.processDetailsContainer}
            title={<span className={Style.widgetHeader}>{findMessage.get("102973")} {props.selectedProcess && <span>&#62; {MLHelper.getMLText(props.selectedProcess?.projectCaption)} - {MLHelper.getMLText(props.selectedProcess?.flowCaption)}</span>}</span>}
        >
            {((props.data?.length && props.structure?.length) || (!(props.data?.length && props.structure?.length) && props.loading)) ? (
                <BCSkeleton
                    loadingStatus={!props.data?.length && props.loading}
                    contentType={ContentType.DashboardProcessDetailsList}
                >
                    <ExtremeGrid
                        key={GRID_ID}
                        id={GRID_ID}
                        onLoadMoreRows={onLoadMore}
                        height="100%"
                        onDoubleClickRow={onClickItem}
                    />
                </BCSkeleton>
            ) : (
                renderCustomEmpty()
            )}
        </BCCard>
    )
}