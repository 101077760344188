import { ValidationErrorInfo } from ".";

export default class ErrorInfo {
    code: string;
    documentLink?: string;
    message: string;
    internalMessage?: string;
    exception?: any;
    hasValidation: boolean;
    validations?: ValidationErrorInfo[];
    url?: string;
    footPrint?: string

    constructor(err?: ErrorInfo) {
        if (err) {
            this.code = err.code;
            this.documentLink = err.documentLink;
            this.exception = err.exception;
            this.hasValidation = err.hasValidation;
            this.internalMessage = err.internalMessage;
            this.message = err.message;
            this.validations = err.validations;
            this.url = err.url
            this.footPrint = err.footPrint
        }
    }
}