import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IMenuItem, MenuItem, MenuItemFactoryFromJS } from "../../../../../common/entities/IMenuItem";

interface IBaseRecentAppsState {
    loading: boolean
}

export interface IRecentAppsState extends IBaseRecentAppsState {
    data: Array<IMenuItem>
}

export interface IReadonlyRecentAppsState extends IBaseRecentAppsState {
    data: List<MenuItem>
}

export type RecentAppsState = ReadonlyRecord<IReadonlyRecentAppsState>;

export const RecentAppsStateFactory = Record<IReadonlyRecentAppsState>({
    loading: false,
    data: List<MenuItem>()
});

export const RecentAppsStateFactoryFromJS = function (data: IRecentAppsState) {
    const _data = data.data.map(item => MenuItemFactoryFromJS(item));

    return RecentAppsStateFactory({
        ...data,
        data: List<MenuItem>(_data)
    })
}