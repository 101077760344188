import { isNullOrUndefined } from '@bimser/core';
import { Map } from 'immutable';
import * as Moment from "moment";
import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { Announcement } from '../../announcements/entities';
import IAnnouncementBoxProps from '../entities/IAnnouncementBoxProps';

const getAnnouncementsBoxSelector = (state: WebInterfaceBaseState, props: IAnnouncementBoxProps) => {
    return state.modules.announcements.items.filter(i =>
        i.status === true &&
        Moment(i.startDate).utc().isSameOrBefore(Moment().utc(), 'minute') &&
        (Moment(i.endDate).utc().isAfter(Moment().utc(), 'minute') || isNullOrUndefined(i.endDate))
    ).sort((a, b) => b.id - a.id);
}

const getAnnouncementsBoxItems = createSelector(
    [
        getAnnouncementsBoxSelector
    ],
    (announcements: Map<string, Announcement>) => {
        return announcements.valueSeq().toJS();
    }
);

export { getAnnouncementsBoxItems };

