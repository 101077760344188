import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from "../forms";

export interface IBasePropertiesFormContent extends IBaseFormContent {
    selectedPropertyId?: number
}

export interface IPropertiesFormContent extends IBasePropertiesFormContent {
}

export interface IReadonlyPropertiesFormContent extends IBasePropertiesFormContent {
}

export type PropertiesFormContent = ReadonlyRecord<IReadonlyPropertiesFormContent>;

export const PropertiesFormContentFactory = Record<IReadonlyPropertiesFormContent>({
    panelId: "",
    showLoading: true,
    selectedPropertyId: -1,
    editWindowGuid: "",
    editWindowisActive: false
});

export const PropertiesFormContentFactoryFromJS = function (data: IPropertiesFormContent) {
    return PropertiesFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedPropertyId: data.selectedPropertyId,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid
    });
}