import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import { IWorkflowFrameCProps, IWorkflowFrameProps, IWorkflowFrameState } from '../entities';
import * as Styles from "../assets/styles.scss";
import { BCLoading } from '@bimser/components';

export default class WorkflowFrame extends React.Component<IWorkflowFrameProps & IWorkflowFrameCProps, IWorkflowFrameState> {

    constructor(props: IWorkflowFrameProps & IWorkflowFrameCProps) {
        super(props);

        this.generateTemplate = this.generateTemplate.bind(this);
        this.iframeOnLoad = this.iframeOnLoad.bind(this);
    }

    private generateTemplate(): JSX.Element {
        return (
            <BCLoading show={this.props.showLoading} delay={500}>
                <iframe src={this.props.url} width="100%" height="100%" frameBorder="0" onLoad={this.iframeOnLoad} className={Styles.IframeContainer}>
                </iframe>
            </BCLoading>
        );
    }

    private iframeOnLoad() {
        if (this.props.onFrameLoaded) {
            this.props.onFrameLoaded();
        }
    }
    
    public render() {
        return (
            this.generateTemplate()
        );
    }
}