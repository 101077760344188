import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

import { DepartmentDetail, DepartmentDetailFactory, DepartmentDetailFactoryFromJS, IDepartmentDetail } from './IDepartmentDetail';
import { IPositionDetail, PositionDetail, PositionDetailFactoryFromJS } from './IPositionDetail';
import { IProfessionDetail, ProfessionDetail, ProfessionDetailFactory, ProfessionDetailFactoryFromJS } from './IProfessionDetail';
import { ITheme, Theme, ThemeFactory, ThemeFactoryFromJS } from "./ITheme";

export interface ILoginUserPosition {
    position: string,
    id: number
}

export type LoginUserPosition = ReadonlyRecord<ILoginUserPosition>;

export const LoginUserPositionFactory = Record<ILoginUserPosition>({
    id: null,
    position: null
});

export const LoginUserPositionFactoryFromJS = function (data: ILoginUserPosition) {
    return LoginUserPositionFactory(data);
}

export interface IBaseLoginUser {
    id: string,
    firstname: string,
    lastname: string,
    eMail?: string,
    department: string,
    profession: string,
    language: string,
    culture: string,
    location: string,
    fullname: string,
    profileImage?: string,
    mfaEnabled?: boolean,
    authenticatorEnabled?: boolean
}

export interface ILoginUser extends IBaseLoginUser {
    positions: Array<ILoginUserPosition>,
    departmentDetail?: IDepartmentDetail,
    professionDetail?: IProfessionDetail,
    positionDetails?: Array<IPositionDetail>,
    themeData: ITheme
}


export interface IReadonlyLoginUser extends IBaseLoginUser {
    positions: List<LoginUserPosition>,
    departmentDetail?: DepartmentDetail,
    professionDetail?: ProfessionDetail,
    positionDetails?: List<PositionDetail>,
    themeData: Theme
}

export type LoginUser = ReadonlyRecord<IReadonlyLoginUser>;

export const LoginUserFactory = Record<IReadonlyLoginUser>({
    id: null,
    firstname: '',
    lastname: '',
    eMail: '',
    department: '',
    profession: '',
    culture: '',
    fullname: '',
    language: '',
    location: '',
    positions: List<LoginUserPosition>(),
    departmentDetail: DepartmentDetailFactory(),
    professionDetail: ProfessionDetailFactory(),
    positionDetails: List<PositionDetail>(),
    profileImage: '',
    themeData: ThemeFactory()
});

export const LoginUserFactoryFromJS = function (user: ILoginUser) {

    let _positions: Array<LoginUserPosition> = user.positions ? user.positions.map((item: LoginUserPosition) => {
        return LoginUserPositionFactoryFromJS(item);
    }) : [];

    let _positionDetails: Array<PositionDetail> = user.positionDetails ? user.positionDetails.map((item: IPositionDetail) => {
        return PositionDetailFactoryFromJS(item);
    }) : [];

    return LoginUserFactory({
        culture: user.culture,
        department: user.department,
        eMail: user.eMail,
        firstname: user.firstname,
        fullname: user.fullname,
        id: user.id,
        language: user.language,
        lastname: user.lastname,
        location: user.location,
        profession: user.profession,
        positions: List(_positions),
        departmentDetail: DepartmentDetailFactoryFromJS(user.departmentDetail),
        professionDetail: ProfessionDetailFactoryFromJS(user.professionDetail),
        positionDetails: List<PositionDetail>(_positionDetails),
        profileImage: user.profileImage,
        themeData: ThemeFactoryFromJS(user.themeData)
    });
}