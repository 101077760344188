import { NormalizedLayoutStructureItems } from "../../../common/entities";

export const getFirstXParent = (view: NormalizedLayoutStructureItems, x: number, startId: string) => {
    const startItem = view.getIn(["entities", "items", startId]);
    if (!startItem) return [];
    let chain = [startItem.id];

    for (let index = 0; index < x; index++) {
        const parentId = view.getIn(["entities", "items", chain.at(-1)])?.parentId;
        parentId && chain.push(parentId);
    }

    return chain
};