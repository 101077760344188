import { Record, Map, List } from 'immutable';
import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { IUploadFileItem, UploadFileItem, UploadFileItemFactoryFromJS } from '.';
import { UploadManagerStatus } from '../../../utilities/ResumableUploadManager/entities/UploadManagerStatus';

interface IBaseUploadManagerState {
    status?: UploadManagerStatus,
    totalFileCount?: number,
    completedFileCount?: number,
    progress?: number,
    isLoading?: boolean,
    isVisible?: boolean,
}

export interface IUploadManagerState extends IBaseUploadManagerState {
    files?: IDictionary<IUploadFileItem>,
    //queue?: IDictionary<IUploadFileThread>
}

export interface IReadonlyUploadManagerState extends IBaseUploadManagerState {
    files?: Map<string, UploadFileItem>,
    //queue?: Map<string, UploadFileThread>
}

export type UploadManagerState = ReadonlyRecord<IReadonlyUploadManagerState>;

export const UploadManagerStateFactory = Record<IReadonlyUploadManagerState>({
    status: UploadManagerStatus.Standby,
    totalFileCount: 0,
    completedFileCount: 0,
    progress: 0,
    isLoading: false,
    isVisible: false,
    files: Map<string, UploadFileItem>(),
    //queue: Map<string, UploadFileThread>()
});

export const UploadManagerStateFactoryFromJS = function (data: IUploadManagerState) {

    let _files: IDictionary<UploadFileItem> = Object.keys(data.files).reduce((previous: unknown, current) => {
        return UploadFileItemFactoryFromJS(data.files[current]);
    }, {});

    return UploadManagerStateFactory({
        ...data,
        files: Map(_files),
    });
}