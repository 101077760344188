
const getURLParams = () => {
    try {

        let queryParameters = null;

        if (window.location.hash.includes("#/autoFill/")) {
            queryParameters = window.location.hash.split("#/autoFill/")[1]
        }
        else if (window.location.hash.includes("#/mobile/")) {
            queryParameters = window.location.hash.split("#/mobile/")[1];
        }

        if (queryParameters) {
            return JSON.parse(decodeURIComponent(window.atob(queryParameters)));
        }
        return null;

    } catch (error) {
        return null;
    }
}

export default getURLParams;