import React, { lazy, Suspense } from 'react';
import IFlowHistoryProps from "./entities/IFlowHistoryProps"

const FlowHistoryLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./containers"));

function FlowHistory(props: React.PropsWithChildren<IFlowHistoryProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <FlowHistoryLazy {...props} />
        </Suspense>
    );
}

export default FlowHistory;