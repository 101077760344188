import { List, Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ILayoutStructureItems, INormalizedObjExtension, LayoutStructureItems, LayoutStructureItemsFactory, LayoutStructureItemsFactoryFromJS } from '../entities';

export type INormalizedLayoutStructureItems = INormalizedObjExtension<ILayoutStructureItems, Array<string>>

export type IReadonlyNormalizedLayoutStructureItems = INormalizedObjExtension<LayoutStructureItems, List<string>>

export type NormalizedLayoutStructureItems = ReadonlyRecord<IReadonlyNormalizedLayoutStructureItems>;

export const NormalizedLayoutStructureItemsFactory = Record<IReadonlyNormalizedLayoutStructureItems>({
    entities: LayoutStructureItemsFactory(),
    result: List(),
    description: null
});

export const NormalizedLayoutStructureItemsFactoryFromJS = function (data: INormalizedLayoutStructureItems) {
    return NormalizedLayoutStructureItemsFactory({
        entities: LayoutStructureItemsFactoryFromJS(data.entities),
        result: List(data.result),
        description: data.description
    });
}