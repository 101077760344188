import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";
import moment from 'moment'
import 'moment-timezone';

export interface IBaseShift {
    id: number,
    code: string,
    startTime: string,
    endTime: string,
    totalMinute: number,
    breakMinute: number,
    timeZone: string
}

export interface IShift extends IBaseShift {
    name: IDictionary<string>,
}

export interface IReadonlyShift extends IBaseShift {
    name: Map<string, any>,
}

export type Shift = ReadonlyRecord<IReadonlyShift>;

export const ShiftFactory = Record<IReadonlyShift>({
    id: 0,
    code: "",
    name: Map(),
    startTime: null,
    endTime: null,
    totalMinute: 0,
    breakMinute: 0,
    timeZone: moment.tz.guess()
});

export const ShiftFactoryFromJS = function (data: IShift) {
    return ShiftFactory({
        ...data,
        name: Map(data.name)
    });
}