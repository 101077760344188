import Tag from "antd/lib/tag";
import * as React from "react";
import ITagProps from "./entities/ITagProps";

export default class BCTag extends React.Component<ITagProps, {}>{
    constructor(props: ITagProps) {
        super(props);
    }

    render() {
        return (<Tag
            color={this.props.color}
            closeIcon={this.props.closeIcon}
            closable={this.props.closable}
            onClose={this.props.onClose}
            visible={this.props.visible || true}
            icon={this.props.icon}
            style={this.props.style}
            className={this.props.className}
        >
            {this.props.text}
        </Tag>)
    }
}

export { ITagProps };