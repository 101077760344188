import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _merge from "lodash/merge";
import * as React from "react";
import { findMessage } from '..';
import BCInputSearch from '../BCInputSearch';
import * as Styles from './assets/listboxStyles.scss';
import { IListboxItem, IListboxProps } from './entities';

const CheckboxGroup = Checkbox.Group;

const BCListbox = React.memo<IListboxProps>(props => {
    const [searchKey, setSearchKey] = React.useState('');
    const [indeterminate, setIndeterminate] = React.useState(props.items && props.items.filter(i => i.selected).length > 0 ? props.items.length > props.items.filter(i => i.selected).length : false,);
    const [selectAll, setSelectAll] = React.useState(props.items && props.items.filter(i => i.selected).length > 0 ? props.items.length == props.items.filter(i => i.selected).length : false,);
    const [itemsState, setItems] = React.useState(props.items);

    React.useEffect(() => {
        const _items = props.items ? props.items.filter((item) => item.text.toLowerCase().includes(searchKey)) : [];
        const selectedItems = _items.length > 0 ? _items.filter(i => i.selected) : [];

        setItems(props.items);
        setIndeterminate(selectedItems.length > 0 && selectedItems.length < _items.length);
        setSelectAll(selectedItems.length > 0 ? selectedItems.length == _items.length : false);

    }, [props.items])

    const onChange = (key: any) => {
        return (e: any) => {
            const val = e.target.checked;

            const _items = itemsState.map(item => {
                const myItem = { ...item };
                if (myItem.key === key) myItem.selected = val;
                return myItem;
            });

            props.onChange?.(_items.filter(i => i.selected).map(k => k.key));

            const selectedItems = _items.filter(i => i.selected && i.text.toLowerCase().includes(searchKey)).map(i => i.key);

            setIndeterminate(!!selectedItems.length && selectedItems.length < _items.filter((item) => item.text.toLowerCase().includes(searchKey)).length);
            setSelectAll(selectedItems.length === _items.length);
            setItems(_items);
        }
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        const itemList = itemsState.filter(item => item.text.toLowerCase().includes(searchKey)).map(item => {
            const myItem = { ...item };
            myItem.selected = e.target.checked;
            return myItem
        });

        const _items = _merge(itemsState, itemList);

        props.onChange?.(_items.filter(i => i.selected).map(k => k.key));


        setIndeterminate(false);
        setSelectAll(e.target.checked)
        setItems(_items);
    }

    const onSearch = (_value: any) => {
        const value = typeof _value === "string" ? _value : _value.data;
        const _items = itemsState?.filter((item) => item.text.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0) || [];
        const selectedItems = _items.length > 0 ? _items.filter(i => i.selected) : [];

        props.onSearch?.(value);

        setSearchKey(value)
        setIndeterminate(selectedItems.length > 0 && selectedItems.length < _items.length)
        setSelectAll(selectedItems.length > 0 ? selectedItems.length == _items.length : false)
    }

    const renderSearchbox = () => (
        <div className={Styles.searchBox}>
            <BCInputSearch readOnly={props.disabled}
                placeHolder={findMessage.get('100002')}
                size={'middle'}
                onSearch={onSearch}
                onChange={onSearch}
            />
        </div>
    )


    const renderCheckAllBtn = () => (
        <div className={Styles.checkboxGroupContainer}>
            <Checkbox
                indeterminate={indeterminate}
                onChange={!props.readOnly && onCheckAllChange}
                checked={selectAll}
                disabled={props.disabled}
            >
                {findMessage.get('100706')}
            </Checkbox>
        </div>
    )

    const renderListItems = React.useCallback(() => {
        const selectedItems = itemsState.filter(i => i.selected).map(i => i.key);
        const className = props.direction && props.direction == "horizontal" ? Styles.horizontal + " horizontal" : Styles.vertical;

        return (
            <CheckboxGroup disabled={props.disabled} value={selectedItems} defaultValue={selectedItems}
                style={{ width: "100%" }}>
                {
                    itemsState.filter(item => item.text.toLocaleLowerCase().indexOf(searchKey?.toLocaleLowerCase()) >= 0)
                        .map((option, index) =>
                            <div key={index} className={className} title={option.text}>
                                <Checkbox onChange={!props.readOnly && onChange(option.key)} value={option.key} disabled={option.disabled}>{props.renderItemContent ? props.renderItemContent(option) : option.text}</Checkbox>
                            </div>
                        )
                }
            </CheckboxGroup>
        )
    }, [itemsState])

    return (
        <div className={props.className} style={props.style}>
            {props.search ? renderSearchbox() : null}
            {props.selectAllButton ? renderCheckAllBtn() : null}
            {renderListItems()}
        </div>
    )
})

export { IListboxProps, IListboxItem };
export default BCListbox;

