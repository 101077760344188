import { connect } from "react-redux";
import { BaseState } from "../../../entities/BaseState";
import {  closeModalTransactionAction } from "../actions";
import ModalManager from "../components";
import { IModalManagerCProps, IModalManagerProps } from '../entities';
import * as React from "react";
import { getModalItems } from "../selectors";

// state to component properties
const mapStateToProps = function (state: BaseState, props: IModalManagerProps): IModalManagerProps {
    return {
        items: getModalItems(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IModalManagerCProps {
    return {
        closeModal: function (key: string) {
            dispatch(closeModalTransactionAction(key));
        }
    };
}

// react - redux connection

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);