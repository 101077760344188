import * as Moment from 'moment';
import * as React from "react";
import BCTimeRangePicker from '../../../BCTimeRangePicker';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defaultStyle: React.CSSProperties = { width: '100%' };

export default function TimeRangePickerProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (dates: any, timeStrings: Array<string>) => {

        let startTime = null;
        let endTime = null;

        if (timeStrings) {
            startTime = timeStrings[0]
            endTime = timeStrings[1]
        }

        props.customChangeEvent ? props.customChangeEvent([startTime, endTime]) : replaceValue(props.item, [startTime, endTime])

    }

    const getDateValue = (value?: string) => {
        return value ? Moment(value, 'HH:mm:ss') : null;
    }

    const getValue = (): any => {
        if (props.value && Array.isArray(props.value)) {
            const startDate: string = props.value[0];
            const endDate: string = props.value[1];

            return [getDateValue(startDate), getDateValue(endDate)];
        } else {
            return [null, null];
        }
    }

    return (
        <BCTimeRangePicker
            value={getValue()}
            onChange={onChange}
            style={props.style || defaultStyle}
            disabled={props.item.disabled || props.item.readOnly}
        />
    )
}