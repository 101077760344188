import IconClosePanel from "@bimser/icons/16/close-panel";
import IconSave from "@bimser/icons/16/save";
import IconSubMenuArrow from "@bimser/icons/16/sub-menu-arrow";
import * as React from "react";
import { BCInputSearch, BCLabel, BCList, BCLoading, BCRibbonBar, BCScrollbars, BCSplitModal, findMessage, IInputChangeEventArgs } from "../";
import { PropertyInspectorComponent } from "../BCPropertyInspector";
import * as Styles from './assets/listEditorStyle.scss';
import { IListEditorItem, IListEditorLayoutProps } from "./entities";
import Item from "./Item";

export default class ListEditorLayout extends React.Component<IListEditorLayoutProps, any> {
    constructor(props: IListEditorLayoutProps) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.renderList = this.renderList.bind(this);
        this.onSelectDelete = this.onSelectDelete.bind(this);
        this.renderPropertyEditor = this.renderPropertyEditor.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }
    onDelete(item: IListEditorItem) {
        if (this.props.onDelete) {
            this.props.onDelete(item);
        }
    }
    onSelect(item: IListEditorItem) {
        if (this.props.onSelect) {
            this.props.onSelect(item);
        }
    }
    onSelectDelete(item: IListEditorItem) {
        if (this.props.onSelectDelete) {
            this.props.onSelectDelete(item);
        }
    }
    onSearch(args: IInputChangeEventArgs) {
        if (this.props.onSearch) {
            this.props.onSearch(args);
        }
    }
    renderItem(item: IListEditorItem) {
        let ItemTemplate = this.props.itemTemplate || Item;
        return (<ItemTemplate
            externalProps={this.props.itemExternalProps}
            externalData={item.externalData}
            icon={item.icon}
            iconType={item.iconType || "icon"}
            id={item.id}
            key={item.id}
            selected={item.selected}
            showDelete={item.showDelete}
            showSelectDelete={item.showSelectDelete}
            subText={item.subText}
            subTextPrefix={item.subTextPrefix}
            text={item.text}
            onDelete={this.onDelete}
            onSelect={this.onSelect}
            onSelectDelete={this.onSelectDelete}
            renderCustomListItemText={this.props.renderCustomListItemText}
        />);
    }
    scrollBarStyle: React.CSSProperties = { height: "100%" };
    renderList() {
        return (<div className={Styles.list}>
            <BCInputSearch key={"search_input"} onChange={this.onSearch} placeHolder={findMessage.get("100002")} />
            <span className={Styles.title}>{this.props.listTitle}{this.renderMaxLabel()}</span>
            <BCScrollbars styles={this.scrollBarStyle} autoHide={true} >
                <BCList
                    dataSource={this.props.items}
                    bordered={false}
                    split={false}
                    renderItem={this.renderItem}
                    sortable={this.props.sortable}
                    dropItem={this.props.dropItem}
                    moveItem={this.props.moveItem}
                    customEmpty={this.props.listCustomEmpty}
                />
            </BCScrollbars>
        </div>)
    }
    renderMaxLabel() {
        if (this.props.maxItemCount)
            return <i>{`(${findMessage.get('101855')} ${this.props.maxItemCount}/${this.props.items.length})`}</i>
    }
    renderPropertyEditor() {
        return (<div className={Styles.viewer}>
            <div className={Styles.inspector}>
                <PropertyInspectorComponent
                    currentLanguage={this.props.currentLanguage}
                    supportedLanguages={this.props.supportedLanguages}
                    searchable={this.props.searchable}
                    structure={this.props.structure}
                    data={this.props.data}
                    fireAction={this.props.openCollection as any}
                    isModal={true}
                    getOptionsPromise={this.props.getOptionsPromise}
                    renderCustomEmpty={this.props.renderCustomEmptyPI}
                    emptyTemplateSubText={this.props.emptyTemplateSubText}
                    onRefresh={this.props.onRefresh}
                    onValueChanged={this.props.onChangeData}
                    guid={this.props.id}
                />
            </div>
        </div >);
    }
    renderSubTitle() {
        let subTitle = this.props.subTitle?.split("/");
        return subTitle?.map((x, index) => {
            if (index == subTitle.length - 1)
                return <span key={x}>{x}</span>
            return <React.Fragment key={x}>
                <span>{x}</span>
                <span><IconSubMenuArrow width={"16px"} height={"16px"} /></span>
            </React.Fragment>
        })
    }
    renderHeader() {
        return (<div className={Styles.layoutHeader}>
            <div className={Styles.titleBox}>
                <BCLabel>{this.props.title}</BCLabel><br />
                <div className={Styles.subTitle}>{this.renderSubTitle()}</div>
            </div>
            <BCRibbonBar
                items={this.props.leftRibbonItems}
                customClassName={Styles.ribbonPanel}
            />
            {this.renderHeaderContent()}
        </div>);
    }
    renderHeaderContent() {
        return (
            <div className={Styles.header}>
                <div className={Styles.closeButton} onClick={this.props.onCancelClick}>
                    <IconClosePanel />
                </div>
                <div className={Styles.saveButton} onClick={this.props.onOkClick}>
                    <IconSave width={"16px"} height={"16px"} />
                    <span>{findMessage.get("100620")}</span>
                </div>
                <BCRibbonBar
                    items={this.props.rightRibbonItems}
                    customClassName={Styles.ribbonPanel}
                />
            </div>
        )
    }
    render() {
        return (
            <BCLoading delay={500} show={this.props.showLoading}>
                <BCSplitModal
                    cssClassName={Styles.listEditor + (this.props.className ? " " + this.props.className : "")}
                    leftSideWidth={this.props.leftSideWidth}
                    rightSideWidth={this.props.rightSideWidth}
                    renderHeader={this.renderHeader}
                    renderLeftSide={this.props.renderLeftSide || this.renderList}
                    renderRightSide={this.props.renderRightSide || this.renderPropertyEditor}
                    onCancelClick={this.props.onCancelClick}
                    onOkClick={this.props.onOkClick}
                    useDefaultButtons={this.props.useDefaultButtons}
                />
            </BCLoading>
        )
    }
}

export { IStatefulTreeEditorState } from "./entities";
export { default as StatefulListEditor, IStatefulListEditorProps, IStatefulListEditorState } from "./StatefulListEditor";
export { default as StatefulTreeEditor } from "./StatefulTreeEditor";
export { Item, IListEditorLayoutProps, IListEditorItem };