import TimePicker from 'antd/lib/time-picker';
import * as moment from 'moment';
import * as React from "react";
import * as Styles from './assets/styles.scss';
import { ITimePickerProps } from './entities';
import { getPopupContainer } from "@bimser/core";
import findMessage from './../BCIntl/helper/findMessage';
import { debounce } from '@bimser/core';
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const BCTimePicker = React.memo(React.forwardRef((props: ITimePickerProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    Styles;

    const innerRef = React.useRef<HTMLDivElement>(null);
    React.useImperativeHandle(ref, () => innerRef.current);

    const onBlurPickerByMove = (e: any) => {
        innerRef.current?.blur?.();
    }

    React.useEffect(() => {
        document.addEventListener('touchmove', onBlurPickerByMove);
        return () => {
            document.removeEventListener('touchmove', onBlurPickerByMove);
        }
    }, []);

    const localizeHourUnits = debounce(() => {
        if (!props.use12Hours) return;

        const tPickers = document.querySelectorAll("div[class*='ant-picker-dropdown']");

        tPickers?.forEach(tPicker => {
            const panels = tPicker.querySelectorAll("div[class*='ant-picker-time-panel'] > div > ul");
            const amPmUnitPanel = panels?.[panels?.length - 1] as HTMLDivElement;

            if (!amPmUnitPanel) return;

            const innerTextAM = (amPmUnitPanel.firstChild?.firstChild as HTMLDivElement)?.innerText;
            const innerTextPM = (amPmUnitPanel.lastChild?.firstChild as HTMLDivElement)?.innerText;

            if (innerTextAM === "AM" || innerTextPM === "PM") {
                amPmUnitPanel.style.overflowX = "hidden";
                (amPmUnitPanel.firstChild.firstChild as HTMLDivElement).innerText = findMessage.get("102777");
                (amPmUnitPanel.lastChild.firstChild as HTMLDivElement).innerText = findMessage.get("102778");
            }
        })
    }, 50);

    const onChange = (date: moment.Moment, dateString: string) => {
        props.onChange?.(date, date?.format(), dateString);
    }

    const onOpenChange = (open: boolean) => {
        if (props.onBlur && !open) {
            props.onBlur(null);
        }
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        localizeHourUnits()
        props.onFocus?.(e)
    }

    const getDisabledTime = () => {
        return {
            disabledHours: props.disabledHours,
            disabledMinutes: props.disabledMinutes,
            disabledSeconds: props.disabledSeconds
        }
    }

    const className = cx({
        timePickerStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center",
        [props.className]: true
    })

    return (
        <TimePicker
            ref={innerRef}
            autoFocus={props.autoFocus}
            className={className}
            defaultValue={props.defaultValue}
            onChange={onChange}
            placeholder={props.placeholder}
            size={props.size}
            disabled={props.disabled}
            format={props.format}
            style={props.style}
            value={props.value}
            disabledTime={getDisabledTime}
            use12Hours={props.use12Hours}
            hourStep={props.hourStep}
            minuteStep={props.minuteStep}
            secondStep={props.secondStep}
            inputReadOnly={props.inputReadOnly || props.readOnly}
            onOpenChange={onOpenChange}
            onBlur={props.onBlur}
            onFocus={onFocus}
            onKeyDown={props.onKeyDown}
            getPopupContainer={props.getPopupContainer || getPopupContainer}
            open={props.readOnly ? false : undefined}
            allowClear={props.allowClear && !props.readOnly}
        />
    );

}))

export default BCTimePicker
export { ITimePickerProps }
