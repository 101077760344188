import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IDepartmentMaintenanceFormContent extends IBaseFormContent {
    showingReplacement?: IReplacement
}

export interface IReadonlyDepartmentMaintenanceFormContent extends IBaseFormContent {
    showingReplacement?: Replacement
}

export type DepartmentMaintenanceFormContent = ReadonlyRecord<IReadonlyDepartmentMaintenanceFormContent>;

export const DepartmentMaintenanceFormContentFactory = Record<IReadonlyDepartmentMaintenanceFormContent>({
    panelId: "",
    showingReplacement: ReplacementFactory(),
    initialHash: "",
    isModified: false,
    selectableListPanelisActive: false,
    selectedCompanyId: 0
});

export const DepartmentMaintenanceFormContentFactoryFromJS = function (data: IDepartmentMaintenanceFormContent) {
    return DepartmentMaintenanceFormContentFactory({
        panelId: data.panelId,
        showingReplacement: ReplacementFactoryFromJS(data.showingReplacement),
        initialHash: data.initialHash,
        isModified: data.isModified,
        selectableListPanelisActive: data.selectableListPanelisActive,
        selectedCompanyId: data.selectedCompanyId
    });
}