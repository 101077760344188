import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ISignatureTemplate, SignatureTemplate, SignatureTemplateFactoryFromJS } from './ISignatureTemplate';

interface IBaseESignatureState {
    //isLoading: boolean;
    //subject: string;
    selectedTemplateId: string;
}

export interface IESignatureState extends IBaseESignatureState {
    templates: Array<ISignatureTemplate>;
}

export interface IReadonlyESignatureState extends IBaseESignatureState {
    templates: List<SignatureTemplate>;
}

export type ESignatureState = ReadonlyRecord<IReadonlyESignatureState>;

export const ESignatureStateFactory = Record<IReadonlyESignatureState>({
    //isLoading: true,
    //subject: "",
    selectedTemplateId: null,
    templates: List()
});

export const ESignatureStateFactoryFromJS = function (data: IESignatureState) {
    let _templates = data && data.templates ? data.templates.map(t => SignatureTemplateFactoryFromJS(t)) : [];
    return ESignatureStateFactory({
        // isLoading: data.isLoading,
        //subject: data.subject,
        selectedTemplateId: data.selectedTemplateId,
        templates: List(_templates)
    });
}