import { IItem, Item, ItemFactoryFromJS, ReadonlyRecord } from '@bimser/core';
import { List, Record } from "immutable";
import { IPropertyReset, PropertyReset, PropertyResetFactoryFromJS } from './IPropertyReset';
import IPropertyTypes from './IPropertyTypes';

export interface IBasePropertyItem {
    key: string,
    type: IPropertyTypes,
    label: string,
    value?: any,
    externalData?: any,
    options?: any,
    info?: string,
    readOnly?: boolean,
    disabled?: boolean,
    allowClear?: boolean,
    placeholder?: string;
    itemCssClass?: string;
    labelCssClass?: string;
    inputCssClass?: string;
    groupName?: string;
}

export interface IPropertyItem extends IBasePropertyItem {
    popoverItems?: Array<IItem>;
    reset?: IPropertyReset;
}

export interface IReadonlyPropertyItem extends IBasePropertyItem {
    popoverItems?: List<Item>;
    reset?: PropertyReset;
}

export type PropertyItem = ReadonlyRecord<IReadonlyPropertyItem>;

export const PropertyItemFactory = Record<IReadonlyPropertyItem>({
    key: null,
    type: null,
    label: null,
    value: null,
    externalData: null,
    options: null,
    info: null,
    readOnly: false,
    disabled: false,
    placeholder: null,
    itemCssClass: null,
    inputCssClass: null,
    labelCssClass: null,
    popoverItems: List(),
    reset: null,
    groupName: null
});

export const PropertyItemFactoryFromJS = function (data: IPropertyItem) {

    let popoverItems: Array<Item> = data && data.popoverItems && data.popoverItems.length ? data.popoverItems.map(item => {
        return ItemFactoryFromJS(item)
    }) : [];

    return PropertyItemFactory({
        ...data,
        popoverItems: List(popoverItems),
        reset: data && data.reset ? PropertyResetFactoryFromJS(data.reset) : undefined
    });
}