import { BCProgress, findMessage, IProgressStatus, IProgressType } from '@bimser/components';
import { RxEventEmitter } from "@bimser/core";
import IconClose from '@bimser/icons/16/close-panel';
import React from 'react';
import { Subscription } from 'rxjs';
import { DeployAgentEntities, Mobile } from '../../..';
import DeployImage from '../assets/deploy.svg';
import * as Style from '../assets/style.scss';
import { ProjectDeploymentProgressProps } from '../entities';

const ProjectDeploymentProgress = (props: ProjectDeploymentProgressProps) => {

    const [subscription, setSubscription] = React.useState<Subscription>(null);
    const [projectStatus, setProjectStatus] = React.useState<DeployAgentEntities.IDeployAgentMessage>(null);

    React.useEffect(() => {
        RxEventEmitter.listen(DeployAgentEntities.DeployAgentEventKey, { next: onMessage }).then(sub => setSubscription(sub));
        Mobile.MobileView.setFunctionForMobile(Mobile.MobileFunctions.OnDeploymentProgressCanceled, onCancelClick);
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, [])

    const onMessage = (message: DeployAgentEntities.IDeployAgentMessage) => {
        if (message?.ProjectName == props.projectName && (props.projectVersion ? (message?.ProjectVersion == props.projectVersion) : true)) {
            setProjectStatus(message);
            if (props.autoClose && (message.Type == DeployAgentEntities.OutputMessageType.Success)) {
                setTimeout(() => {
                    props.onClose(props.modalId);
                }, 3000);
            }
        }
    }

    const onCancelClick = () => {
        let payload = {
            ProjectName: props.projectName,
            ProjectVersion: props.projectVersion,
            Type: projectStatus ? projectStatus.Type : DeployAgentEntities.OutputMessageType.Warning,
            Description: "User cancelled request",
            Status: DeployAgentEntities.OutputMessageStatus.Cancelled,
            Step: projectStatus ? projectStatus.Step : { CurrentStep: 1, TotalStep: 1 }
        };
        props.onDeployAgentMessage(payload);
        RxEventEmitter.emit(DeployAgentEntities.DeployAgentEventKey, payload);
    }

    const generateTitleAndDescription = () => {
        if (projectStatus) {
            return {
                title: findMessage.get('102672') + projectStatus.Step.CurrentStep + "/" + projectStatus.Step.TotalStep,
                description: projectStatus.Description
            }
        }

        return { title: findMessage.get('102672'), description: findMessage.get('102673') }
    }

    const getProgressStatus = () => {
        if (projectStatus?.Type == DeployAgentEntities.OutputMessageType.Success) {
            return IProgressStatus.Success;
        } else if (projectStatus?.Type == DeployAgentEntities.OutputMessageType.Error) {
            return IProgressStatus.Exception;
        } else {
            return IProgressStatus.Normal;
        }
    }

    const message = generateTitleAndDescription();
    const status = getProgressStatus();
    const percent = projectStatus ? Math.floor(projectStatus.Step.CurrentStep / projectStatus.Step.TotalStep * 100) : 0;

    const renderStatus = () => {
        return (
            <>
                <div className={Style.DeployStatusTitle}>{message.title}</div>
                <div className={Style.DeployStatusDescription}>{message.description}</div>
            </>
        )
    }

    Mobile.MobileView.projectDeploymentProgress({
        title: message.title,
        description: message.description,
        percent,
        status
    });

    return (
        <div className={Style.DeployProgressContainer}>
            <img className={Style.DeployImage} src={DeployImage} />
            <div className={Style.DeployStatus}>
                {renderStatus()}
            </div>
            <BCProgress type={IProgressType.Line} percent={percent} status={status} />
            <div className={Style.CancelButton}>
                <IconClose onClick={onCancelClick} />
            </div>
        </div>
    )
}
export default ProjectDeploymentProgress;
