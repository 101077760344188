import React, { lazy, Suspense } from 'react';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-actions" */ "./containers"));

function ActionMenuContainer(props: React.PropsWithChildren<any>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
ActionMenuContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-actions" */ "./injector");
}

export default ActionMenuContainer;
