import { ReadonlyRecord } from "@bimser/core";
import { BCExtremeGridEntities } from "@bimser/components";
import { Record, List } from "immutable";
import { IWorkflowItem, WorkflowItem, WorkflowItemFactoryFromJS, IWFProcessItem } from '../../../entities';

interface IBaseWFProcessListPanelContent {
    isLoading: boolean;
    activeChildPanelGuid: string;
}

export interface IWFProcessListPanelContent extends IBaseWFProcessListPanelContent {
    workflowItem: IWorkflowItem;
    items: IWFProcessItem[];
    gridColumns: BCExtremeGridEntities.IExtremeGridColumn[];
}

export interface IReadonlyWFProcessListPanelContent extends IBaseWFProcessListPanelContent {
    workflowItem: WorkflowItem;
    items: List<IWFProcessItem>;
    gridColumns: List<BCExtremeGridEntities.IExtremeGridColumn>;
}

export type WFProcessListPanelContent = ReadonlyRecord<IReadonlyWFProcessListPanelContent>;

export const WFProcessListPanelContentFactory = Record<IReadonlyWFProcessListPanelContent>({
    isLoading: false,
    activeChildPanelGuid: null,
    workflowItem: null,
    items: List(),
    gridColumns: List()
});

export const WFProcessListPanelContentFactoryFromJS = function (data: IWFProcessListPanelContent) {
    return WFProcessListPanelContentFactory({
        ...data,
        workflowItem: WorkflowItemFactoryFromJS(data.workflowItem),
        items: List(data.items),
        gridColumns: List(data.gridColumns)
    });
}