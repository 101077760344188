import { IExtremeGridState, IExtremeGridColumn, IResizeColumn } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeColumnResizeReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<IResizeColumn>>): IExtremeGridState {
    let newColumns: Array<IExtremeGridColumn> = state.columns.map((item) => {
        let column = action.payload.find((c => c.columnName == item.name));

        return column?.width !== item.width
            ? Object.assign({}, item, { width: column.width })
            : item;
    });

    return {
        ...state,
        columns: newColumns
    };
}