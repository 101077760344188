import { IResumableFile } from "../entities/IResumableFile";
import ResumableUploadManager from "./ResumableUploadManager";
import ResumableChunk from "./ResumableChunk";
import { Map } from 'immutable';
import { UploadFileThread } from '../entities/IUploadFileThread';

export default class ResumableFile {
    resumableObj: ResumableUploadManager;
    file: File;
    fileName: string;
    relativePath: string;
    size: number;
    uuid: string;
    chunks: Array<ResumableChunk>;
    opts = {};
    getOpt: Function;
    container = '';
    threads: Map<string, UploadFileThread> = Map();

    private _prevProgress = 0;
    private _pause = false;
    private _error
    
    constructor(data: IResumableFile) {
        this.resumableObj = data.resumableObj;
        this.file = data.file;
        this.fileName = data.file.name;
        this.size = data.file.size;
        this.uuid = data.uniqueIdentifier;
        this.relativePath = this.fileName;
        this.chunks = [];
        this.getOpt = data.resumableObj.getOpt;
        this.threads = data.threads;

        this.chunkEvent = this.chunkEvent.bind(this);
        this.getOpt = this.getOpt.bind(this);
        this.isComplete = this.isComplete.bind(this);
        this.isPaused = this.isPaused.bind(this);
        this.isUploading = this.isUploading.bind(this);
        this.pause = this.pause.bind(this);
        this.progress = this.progress.bind(this);
        this.retry = this.retry.bind(this);
        this.abort = this.abort.bind(this);
        this.bootstrap = this.bootstrap.bind(this);
        this.cancel = this.cancel.bind(this);
        this._error = this.uuid !== undefined;

        this.resumableObj.fire('chunkingStart', this);
        this.bootstrap();
    }

    chunkEvent(event: string, message: any) {
        switch (event) {
            case 'progress':
                this.resumableObj.fire('fileProgress', this, message);
                break;
            case 'error':
                this.abort();
                this._error = true;
                this.chunks = [];
                this.resumableObj.fire('fileError', this, message);
                break;
            case 'success':
                if (this._error) return;
                this.resumableObj.fire('fileProgress', this);
                if (this.isComplete()) {
                    this.resumableObj.fire('fileSuccess', this, message);
                }
                break;
            case 'retry':
                this.resumableObj.fire('fileRetry', this);
                break;
        }
    }

    progress() {
        if (this._error) return 1;
        let ret = 0;
        let err = false;
        this.chunks.forEach(c => {
            if (c.status() == 'error') err = true;
            ret += c.progress(true);
        });
        ret = err ? 1 : (ret > 0.99999 ? 1 : ret);
        ret = Math.max(this._prevProgress, ret);
        this._prevProgress = ret;
        return ret;
    }

    abort() {
        let abortCount = 0;
        this.chunks.forEach((c) => {
            if (c.status() == "uploading") {
                c.abort();
                abortCount++;
            }
        })
        if (abortCount > 0) this.resumableObj.fire('fileProgress', this);
    }

    cancel() {
        let _chunks = this.chunks;
        this.chunks = [];
        _chunks.forEach(chunk => {
            if (chunk.status() == "uploading") {
                chunk.abort();
                this.resumableObj.uploadNextChunk();
            }
        });
        this.resumableObj.removeFile(this);
        this.resumableObj.fire('fileProgress', this);
    }

    retry() {
        this.bootstrap();
        let firedRetry = false;
        this.resumableObj.on('chunkingComplete', () => {
            if (!firedRetry) this.resumableObj.upload();
            firedRetry = true;
        })
    }

    bootstrap() {
        this.abort();
        this._error = false;
        this.chunks = [];
        this._prevProgress = 0;
        for (let offset = 0; offset < this.threads.size; offset++) {
            this.chunks.push(new ResumableChunk({ resumableObj: this.resumableObj, fileObj: this, offset: offset, callback: this.chunkEvent }));
        }
        window.setTimeout(() => {
            this.resumableObj.fire('chunkingComplete', this);
        }, 0)
    }

    isUploading(): boolean {
        return this.chunks.findIndex(c => c.status() == "uploading") > -1;
    }

    isComplete(): boolean {
        let result = false;
        this.chunks.forEach(c => {
            let s = c.status();
            if (s == 'pending' || s == 'uploading' || c.preprocessState === 1) {
                result = true;
                return false;
            }
        });
        return (!result);
    }

    isPaused() {
        return this._pause;
    }

    pause(pause?: boolean) {
        if (typeof pause === undefined) {
            this._pause = !this._pause;
        } else {
            this._pause = pause;
        }

        if (this._pause) this.abort();
        else this.resumableObj.upload();
    }
}