import * as React from "react";
import * as ReactDOM from "react-dom";

import Alert from 'antd/lib/alert';
import { IAlertProps, IAlertTypes } from './entities';
import * as styles from './assets/style.scss';

export default class BCAlert extends React.Component<IAlertProps, {}> {

    style: any = {}

    constructor(props: IAlertProps) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.style = this.props.style;
        styles;
    }

    onClose(e: React.FormEvent<any>) {
        if (this.props.onClose) {
            this.props.onClose({
                event: e
            });
        }
    }

    render() {
        return (
            <Alert message={this.props.message}
                description={this.props.description}
                banner={this.props.banner}
                closable={this.props.closable}
                closeText={this.props.closeText}
                showIcon={this.props.showIcon}
                icon={this.props.icon}
                type={this.props.type}
                onClose={this.onClose}
                style={this.style}
                className={this.props.className}
            />
        );
    }

}

export { IAlertProps, IAlertTypes }