import { FormsEntities, IWebViewModel, AgentProjects } from '..';
import { IOverflowSetItemProps } from '@bimser/components';
import { debounce, IDictionary } from '@bimser/core';

import { panelType } from '../entities/PanelType';
const _set = require("lodash/set");

class EventTriggerQueue {
    lastPaylaod: any;
    constructor() {
        this.trigger = debounce(this.trigger.bind(this), 500);
    }
    trigger(payload: any, fn: () => void) {
        if (payload !== this.lastPaylaod) fn?.();
        this.lastPaylaod = payload;
    }
}

export namespace Mobile {

    export class MobileView {
        static EventQueueMap: IDictionary<IDictionary<EventTriggerQueue>> = {};

        static isAndroidAvaliable: boolean = (window as any).SynergyAndroid;
        static isIosAvaliable: boolean = typeof ((window as any).webkit) !== "undefined" && (window as any).webkit && (window as any).webkit.messageHandlers;
        static mobile: boolean = (window as any).SynergyAndroid || typeof ((window as any).webkit) !== "undefined" && (window as any).webkit && (window as any).webkit.messageHandlers;

        static checkMethodIsExist(eventName: string): { avaliableForAndroid: boolean, avaliableForIos: boolean } {
            let avaliableForAndroid: boolean = false;
            let avaliableForIos: boolean = false;
            try {

                if (Mobile.MobileView.isAndroidAvaliable && (window as any).SynergyAndroid[eventName]) avaliableForAndroid = true;
                else if (
                    Mobile.MobileView.isIosAvaliable &&
                    (window as any).webkit.messageHandlers[eventName] &&
                    (window as any).webkit.messageHandlers[eventName].postMessage
                ) {
                    avaliableForIos = true;
                }
                return { avaliableForAndroid, avaliableForIos };
            } catch (err) {
                console.warn(`checkMethodIsExist ${eventName}`, err);
                return { avaliableForAndroid, avaliableForIos };
            }
        }

        static synergyFormEventClear() {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("synergyFormEventClear")
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.synergyFormEventClear();
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.synergyFormEventClear.postMessage("bu zorunlu bir mesajdır, lütfen silmeyiniz!");
            }
        }

        static synergyFormOnClose(guid: string) {
            if (this.EventQueueMap[guid]) {
                delete this.EventQueueMap[guid]
            }

            if (this.EventQueueMap["setFormDataGridRows"]) {
                delete this.EventQueueMap["setFormDataGridRows"]
            }

            if (this.EventQueueMap["setEventList"]) {
                delete this.EventQueueMap["setEventList"]
            }
        }

        static setFormStructure(response: FormsEntities.IFormResponse | IDictionary<any>) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setFormStructure");

            if (!this.EventQueueMap[response.formPanelId]) this.EventQueueMap[response.formPanelId] = {};

            if (existingStatus.avaliableForAndroid) {
                const responseString = response ? JSON.stringify(response) : "";
                (window as any).SynergyAndroid.setFormStructure(responseString);
            }
            if (existingStatus.avaliableForIos) {
                const responseString = response ? JSON.stringify(response) : "";
                (window as any).webkit.messageHandlers.setFormStructure.postMessage(responseString);
            }
        }

        static setControlProperties(controlId: string = "", properties: IDictionary<any> = {}, formPanelId: string = "") {
            if (!controlId) return;
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setControlProperties");
            let payload = JSON.stringify({ "controlId": controlId, "properties": properties || "", "formPanelId": formPanelId });

            if (!this.EventQueueMap[formPanelId]) this.EventQueueMap[formPanelId] = {};
            if (!this.EventQueueMap[formPanelId][controlId]) this.EventQueueMap[formPanelId][controlId] = new EventTriggerQueue();
            this.EventQueueMap[formPanelId][controlId].trigger(payload, () => {
                if (existingStatus.avaliableForAndroid) {
                    (window as any).SynergyAndroid.setControlProperties(payload);
                }
                if (existingStatus.avaliableForIos) {
                    (window as any).webkit.messageHandlers.setControlProperties.postMessage(payload);
                }
            })

        }

        static mobileServiceResponse(status: boolean, service: string, data: any) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("mobileServiceResponse")
            let payload = JSON.stringify({ "success": status, "service": service, "data": data });
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.mobileServiceResponse(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.mobileServiceResponse.postMessage(payload);
            }
        }
        static setDocumentStructure(service: string, data: any) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setDocumentStructure")
            let payload = JSON.stringify({ "service": service, "data": data });
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setDocumentStructure(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setDocumentStructure.postMessage(payload);
            }
        }

        static mobileClientSideMessages(messageData: any[]) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("mobileClientSideMessages")
            let messageTypes: string[] = ['ShowMessageAction', 'ConfirmationAction', 'ValidationFailureAction'];
            messageData = messageData.filter(i => messageTypes.includes(i.typeName));
            let messages = {
                toasters: messageData.filter(i => i.dialogType === "Toaster"),
                popups: messageData.filter(i => i.dialogType === "Popup"),
                summaries: messageData.filter(i => i.dialogType === "Summary"),
            }
            let payload = JSON.stringify(messages);
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.mobileClientSideMessages(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.mobileClientSideMessages.postMessage(payload);
            }
        }

        static synergyFormIsReady() {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("synergyFormIsReady")
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.synergyFormIsReady();
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.synergyFormIsReady.postMessage("bu zorunlu bir mesajdır, lütfen silmeyiniz!");
            }
        }

        static setEventList(model: { formPanelId: string, model: IWebViewModel[] }) {
            if (!model.formPanelId) return;
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setEventList");
            model.model = model.model.filter(button => !["divider-topbar", "flowDocuments"].includes(button.key));
            let _model = JSON.stringify(model);
            if (!this.EventQueueMap["setEventList"]) this.EventQueueMap["setEventList"] = {};
            if (!this.EventQueueMap["setEventList"][model.formPanelId]) this.EventQueueMap["setEventList"][model.formPanelId] = new EventTriggerQueue();
            this.EventQueueMap["setEventList"][model.formPanelId].trigger(_model, () => {
                if (existingStatus.avaliableForAndroid) {
                    (window as any).SynergyAndroid.setEventList(_model);
                }
                if (existingStatus.avaliableForIos) {
                    (window as any).webkit.messageHandlers.setEventList.postMessage(_model);
                }
            })
        }

        static setFunctionForMobile(functionName: MobileFunctions, method: Function) {
            try {

                if (Mobile.MobileView.isAndroidAvaliable) { // Android
                    _set(window, `SynergyAndroid.${functionName}`, method);
                }
                else {//IOS
                    _set(window, `SynergyIOS.${functionName}`, method);
                }
            } catch { //Outlook - DotnetBrowser her yeni eklenen enum değeri için geliştirme istiyor. Burada hata verdiğinde formlar açılmıyor.
            }
        }

        static setDataGridControlsProperties(controls: any[], gridId: string) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setDataGridControlsProperties");
            controls.forEach(control => {
                let payload = JSON.stringify({ "controlId": control.name, "properties": control || "", "gridId": gridId });
                if (existingStatus.avaliableForAndroid) {
                    (window as any).SynergyAndroid.setDataGridControlsProperties(payload);
                }
                if (existingStatus.avaliableForIos) {
                    (window as any).webkit.messageHandlers.setDataGridControlsProperties.postMessage(payload);
                }
            })
        }

        static mobileServerSideMessages(messageType: string, messages: any[]) {
            let payload = JSON.stringify({ messageType: messageType, messages: messages });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("mobileServerSideMessages");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.mobileServerSideMessages(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.mobileServerSideMessages.postMessage(payload);
            }
        }

        static modalFormOpening(formInfo: any) {
            let payload = JSON.stringify(formInfo);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("modalFormOpening");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.modalFormOpening(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.modalFormOpening.postMessage(payload);
            }
        }
        static onExecuteAction(responseUrl: any) {
            let payload = JSON.stringify(responseUrl);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("onExecuteAction");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.onExecuteAction(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.onExecuteAction.postMessage(payload);
            }
        }

        static gridRowCommitResponse(response: { status: boolean }) {
            let payload = JSON.stringify(response);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("gridRowCommitResponse");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.gridRowCommitResponse(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.gridRowCommitResponse.postMessage(payload);
            }
        }

        static setFormLookupRows(data: { controlId: string, rows: any[], count: number, pageIndex: number }) {
            let payload = JSON.stringify(data);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setFormLookupRows");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setFormLookupRows(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setFormLookupRows.postMessage(payload);
            }
        };


        static setActiveForm(data: { formPanelId: string }) {
            let payload = JSON.stringify(data);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setActiveForm");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setActiveForm(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setActiveForm.postMessage(payload);
            }
        };

        static modalFormClosing(formInfo: any) {
            let payload = JSON.stringify(formInfo);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("modalFormClosing");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.modalFormClosing(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.modalFormClosing.postMessage(payload);
            }
        }

        static setSelectionFormRows(rows: any) {
            let payload = JSON.stringify(rows);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setSelectionFormRows");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setSelectionFormRows(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setSelectionFormRows.postMessage(payload);
            }
        }

        static relatedDocumentsFilesToUploadInfo(files: any) {
            let payload = JSON.stringify(files);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("relatedDocumentsFilesToUploadInfo");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.relatedDocumentsFilesToUploadInfo(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.relatedDocumentsFilesToUploadInfo.postMessage(payload);
            }
        }

        static currentProcessStatus(status: MobilProcessStatus, data?: any) {
            let payload = JSON.stringify({ type: status, data: data || {} });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("currentProcessStatus");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.currentProcessStatus(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.currentProcessStatus.postMessage(payload);
            }
        }

        static flowViewerRibbonProperties(items: IOverflowSetItemProps[], documents: any[], flowProperties: any[], historyItems: any, menuItemsVisibility: any) {
            items = items.map(item => {
                delete item.content;
                switch (item.key) {
                    case "4": // flowDocument
                        item.data = documents;
                        item.key = 'flowDocuments';
                        item.visibility = menuItemsVisibility.flowDocuments;
                        return item;
                    case "0": // flowInfo
                        item.data = flowProperties;
                        item.key = 'flowInfo';
                        item.visibility = menuItemsVisibility.flowProperties;
                        return item;
                    case "1": // flowHistory
                        item.data = historyItems;
                        item.key = 'flowHistory';
                        item.visibility = menuItemsVisibility.flowHistory;
                        return item;
                    default:
                        return item;
                }
            });
            let payload = JSON.stringify({ items: items, menuItemsVisibility: menuItemsVisibility });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("flowViewerRibbonProperties");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.flowViewerRibbonProperties(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.flowViewerRibbonProperties.postMessage(payload);
            }
        }

        static closeFlexPanel(type: panelType) {
            let payload = JSON.stringify({ type });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("closeFlexPanel");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.closeFlexPanel(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.closeFlexPanel.postMessage(payload);
            }
        }

        static setSelectedCulture(culture: string) {
            let payload = JSON.stringify({ culture: culture });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setSelectedCulture");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setSelectedCulture(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setSelectedCulture.postMessage(payload);
            }
        }

        static setRememberMe(rememberMe: boolean) {
            let payload = JSON.stringify({ rememberMe: rememberMe });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setRememberMe");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setRememberMe(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setRememberMe.postMessage(payload);
            }
        }

        static onChangeFormView(view: string) {
            let payload = JSON.stringify({ view: view });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("onChangeFormView");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.onChangeFormView(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.onChangeFormView.postMessage(payload);
            }
        }

        static setDeployAgents(deployAgents: any[]) {
            let payload = JSON.stringify({ deployAgents: deployAgents });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setDeployAgents");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setDeployAgents(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setDeployAgents.postMessage(payload);
            }
        }

        static relatedDocumentsLoadingStatus(status: boolean) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("relatedDocumentsLoadingStatus");
            let payload = JSON.stringify({ loading: status });
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.relatedDocumentsLoadingStatus(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.relatedDocumentsLoadingStatus.postMessage(payload);
            }
        }

        static setDeployAgentProjects(deployAgentProjects: AgentProjects) {
            let payload = JSON.stringify({ deployAgentProjects: deployAgentProjects });
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setDeployAgentProjects");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.setDeployAgentProjects(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.setDeployAgentProjects.postMessage(payload);
            }
        }

        static closeLoginLoading() {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("closeLoginLoading");
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.closeLoginLoading("closeLoading");
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.closeLoginLoading.postMessage("closeLoading");
            }
        }

        static setFormDataGridRows(data: { controlId: string, rows: any[], count: number, pageIndex: number }) {
            let payload = JSON.stringify(data);
            let existingStatus = Mobile.MobileView.checkMethodIsExist("setFormDataGridRows");
            if (!this.EventQueueMap["setFormDataGridRows"]) this.EventQueueMap["setFormDataGridRows"] = {};
            if (!this.EventQueueMap["setFormDataGridRows"][data.controlId]) this.EventQueueMap["setFormDataGridRows"][data.controlId] = new EventTriggerQueue();
            this.EventQueueMap["setFormDataGridRows"][data.controlId].trigger(payload, () => {
                if (existingStatus.avaliableForAndroid) {
                    (window as any).SynergyAndroid.setFormDataGridRows(payload);
                }
                if (existingStatus.avaliableForIos) {
                    (window as any).webkit.messageHandlers.setFormDataGridRows.postMessage(payload);
                }
            })
        }

        static openBarcodeReader(controlId: string = "", formPanelId: string = "") {
            const existingStatus = Mobile.MobileView.checkMethodIsExist("openBarcodeReader")
            const payload = JSON.stringify({ "controlId": controlId, "formPanelId": formPanelId });
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.openBarcodeReader(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.openBarcodeReader.postMessage(payload);
            }
        }

        static closeBarcodeReader(controlId: string = "", formPanelId: string = "") {
            const existingStatus = Mobile.MobileView.checkMethodIsExist("closeBarcodeReader")
            const payload = JSON.stringify({ "controlId": controlId, "formPanelId": formPanelId });
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.closeBarcodeReader(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.closeBarcodeReader.postMessage(payload);
            }
        }

        static exportData(controlId: string, base64: string, type: number) {
            const existingStatus = Mobile.MobileView.checkMethodIsExist("exportData")
            const payload = JSON.stringify({ controlId, base64, type });
            if (existingStatus.avaliableForAndroid) {
                (window as any).SynergyAndroid.exportData(payload);
            }
            if (existingStatus.avaliableForIos) {
                (window as any).webkit.messageHandlers.exportData.postMessage(payload);
            }
        }

        static projectDeploymentProgress(response: { title: string, description: string, percent: number, status: string }) {
            let existingStatus = Mobile.MobileView.checkMethodIsExist("projectDeploymentProgress");
            if (existingStatus.avaliableForAndroid) {
                const responseString = response ? JSON.stringify(response) : "";
                (window as any).SynergyAndroid.projectDeploymentProgress(responseString);
            }
            if (existingStatus.avaliableForIos) {
                const responseString = response ? JSON.stringify(response) : "";
                (window as any).webkit.messageHandlers.projectDeploymentProgress.postMessage(responseString);
            }
        }

        static *showNotification(response: { id: string, type: "info" | "error" | "warning" | "success" | "deployAgent", title: string, content: string }) {

            let existingStatus = Mobile.MobileView.checkMethodIsExist("showNotification");
            if (existingStatus.avaliableForAndroid) {
                const responseString = response ? JSON.stringify(response) : "";
                (window as any).SynergyAndroid.showNotification(responseString);
            }
            if (existingStatus.avaliableForIos) {
                const responseString = response ? JSON.stringify(response) : "";
                (window as any).webkit.messageHandlers.showNotification.postMessage(responseString);
            }

            yield new Promise<void>((resolve) => {
                Mobile.MobileView.setFunctionForMobile(Mobile.MobileFunctions.CloseNotification, (_id: string) => {
                    if (response.id === _id) resolve();
                })
            })

        }
    }

    export enum MobilProcessStatus {
        ReasonProcessFail = "ReasonProcessFail",
        ReasonProcessSuccess = "ReasonProcessSuccess"
    }


    export enum MobileFunctions {
        CloseFormMobile = "closeFormMobile",
        RelatedDocumentOnUpload = "relatedDocumentOnUpload",
        OnSelectedChangedSelectionForm = "onSelectedChangedSelectionForm",
        BtnOkClickedSelectionForm = "btnOkClickedSelectionForm",
        ConfirmReason = "confirmReason",
        ConfirmUserApprove = "confirmUserApprove",
        OnChangeFlowDocument = "onChangeFlowDocument",
        OnClickFlowInfo = "onClickFlowInfo",
        OnClickFlowHistory = "onClickFlowHistory",
        CloseLookupModal = "closeLookupModal",
        SetBarcodeValue = "setBarcodeValue",
        TriggerEvent = "triggerEvent",
        CameraPermissonDenied = "cameraPermissonDenied",
        OnChangeDataGridEditorValue = "onChangeDataGridEditorValue",
        CloseNotification = "closeNotification",
        OnDeploymentProgressCanceled = "onDeploymentProgressCanceled",
        ForwardingOperation = "forwardingOperation",
        GeolocationPosition = "geolocationPosition"
    };

    export enum MobileFormView {
        WebView = 1,
        Native = 2,
        NativePlus = 3,
    }

}