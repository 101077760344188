import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
export enum SortOrder {
    Asc = 1,
    Desc = 2
}


export interface IBaseSort {
    Column: string;
    Type: SortOrder;
}

export interface ISort extends IBaseSort { }

export interface IReadonlySort extends IBaseSort { }

export type Sort = ReadonlyRecord<IReadonlySort>;

export const SortFactory = Record<IReadonlySort>({
    Column: null,
    Type: null,
});

export const SortFactoryFromJS = function (sort: ISort) {
    return SortFactory(sort);
}