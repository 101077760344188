import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IManagerKey, ManagerKey, ManagerKeyFactory, ManagerKeyFactoryFromJS } from "..";

export interface IManagerKeyFormContent extends IBaseFormContent {
    showingManagerKey?: IManagerKey
}

export interface IReadonlyManagerKeyFormContent extends IBaseFormContent {
    showingManagerKey?: ManagerKey
}

export type ManagerKeyFormContent = ReadonlyRecord<IReadonlyManagerKeyFormContent>;

export const ManagerKeyFormContentFactory = Record<IReadonlyManagerKeyFormContent>({
    panelId: "",
    showingManagerKey: ManagerKeyFactory(),
    isModified: false,
    initialHash: "",
    showLoading: false
});

export const ManagerKeyFormContentFactoryFromJS = function (data: IManagerKeyFormContent) {
    return ManagerKeyFormContentFactory({
        panelId: data.panelId,
        showingManagerKey: ManagerKeyFactoryFromJS(data.showingManagerKey),
        showLoading: data.showLoading,
        isModified: data.isModified,
        initialHash: data.initialHash
    });
}