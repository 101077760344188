import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseProcessStep {
    CurrentStep: number;
    TotalStep: number;
}

export interface IProcessStep extends IBaseProcessStep {

}

export interface IReadonlyProcessStep extends IBaseProcessStep {

}

export type ProcessStep = ReadonlyRecord<IReadonlyProcessStep>;

export const ProcessStepFactory = Record<IReadonlyProcessStep>({
    CurrentStep: 0,
    TotalStep: 0
});

export const ProcessStepFactoryFromJS = function (data: IProcessStep) {
    return ProcessStepFactory(data);
}