import { IBaseAction } from '@bimser/core';
import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import openModalFrameAction from "../actions/openModalFrameAction";

export default function* watchModalFrameManager() {
    yield takeEvery(actionTypes.OPEN_MODAL_FRAME_TRANSACTION, openModalFrame);
}

export function* openModalFrame(action: IBaseAction<string>) {
    yield put(openModalFrameAction(action.payload));
}