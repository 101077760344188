import * as React from "react";
import BCButton, { IButtonClickEventArgs } from '../BCButton';
import BCButtonGroup from '../BCButtonGroup';
import BCColorPicker from '../BCColorPicker';
import BCCombobox, { IComboboxSelectedChangeEventArgs } from '../BCCombobox';
import BCDropdown from '../BCDropdown';
import BCDropdownButton from '../BCDropdownButton';
import BCInput from '../BCInput';
import BCTooltip from "../BCTooltip";
import { IEventArgs, IItem, IItemType } from '@bimser/core';
import * as styles from './assets/toolbarStyles.scss';
import { IToolbarItemClickEventArgs, IToolbarProps, IToolbarState } from './entities';
import IconEmpty from "@bimser/icons/16/empty-icon";
import { GetIconComponent } from "@bimser/icons";

var classNames = require('classnames/bind');
let cx = classNames.bind(styles);

export default class BCToolbar extends React.Component<IToolbarProps, IToolbarState> {
    readonly state: IToolbarState;

    sortingKey: string = "";
    constructor(props: IToolbarProps) {
        super(props);
        this.sortingKey = this.props.sortingKey || "ToolbarItems";

        this.state = {
            items: JSON.parse(localStorage.getItem(this.sortingKey))
        }

        this.onSelectedChange_BCCombobox = this.onSelectedChange_BCCombobox.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onItemMove = this.onItemMove.bind(this);
        this.onClickButton = this.onClickButton.bind(this);
    }

    onClick = (senderEventArgs: IEventArgs, data: IItem) => {
        if (this.props.onClick)
            this.props.onClick({
                senderEventArgs: senderEventArgs,
                data: data
            });
    }

    onClickButton(args: IButtonClickEventArgs) {
        this.onClick(args, this.getClickButtonData(this.props.items, args.data.guid));
    }
    getClickButtonData(items: Array<any>, guid: string) {
        let result: any = null;
        for (let i = 0; i < items.length; i++) {
            if (items[i].key === guid) {
                result = { ...items[i] }
                break;
            }
            result = this.getClickButtonData(items[i].items, guid);
            if (result) {
                break;
            }
        }
        return result;
    }

    onItemMove(oldIndex: number, newIndex: number): void {

        let mergedItems = this.mergeData(this.state.items, this.props.items).sort(function (i, j) {
            return i.displayIndex - j.displayIndex
        });

        let _items: Array<IItem> = mergedItems;

        _items = _items.reduce((prev, current, idx, self) => {
            if (oldIndex === newIndex) {
                prev.push(current);
            }
            if (idx === oldIndex) {
                return prev;
            }
            if (oldIndex < newIndex) {
                prev.push(current);
            }
            if (idx === newIndex) {
                prev.push(self[oldIndex]);
            }
            if (oldIndex > newIndex) {
                prev.push(current);
            }
            return prev;
        }, []);


        let tempArray = new Array<IItem>();

        _items.forEach((item, i) => { item.displayIndex = i; tempArray.push(item); });

        tempArray.sort(function (i, j) {
            return i.displayIndex - j.displayIndex
        });

        localStorage.setItem(this.sortingKey, JSON.stringify(tempArray));

        this.setState({
            items: tempArray
        })
    }
    mergeData(localData: Array<IItem>, propsData: Array<IItem>): Array<IItem> {
        let _tempArray = new Array<IItem>();
        if (!localData)
            localData = new Array<IItem>();

        localData = Object.assign([], localData);
        propsData = Object.assign([], propsData);

        if ((propsData.length > 0 && Array.isArray(propsData))) {

            //Remove Item
            for (let i = 0; i <= localData.length - 1;) {
                let _item: IItem = propsData.find(j => j.key == localData[i].key)
                if (!_item) {
                    localData.splice(i, 1);
                } else {
                    if ((localData[i].items.length == 0 || !localData[i].items) && (_item.items.length == 0 || !_item.items))
                        this.mergeData(localData[i].items, _item.items);
                    i++;
                }
            }

            //Add and Update Item
            for (let i = 0; i < propsData.length; i++) {
                let _item: IItem = null;
                if (localData.length > 0)
                    _item = localData.find(j => j.key == propsData[i].key);

                if (_item) {
                    _item = propsData[i];
                    if ((propsData[i].items.length == 0 || !propsData[i].items) && (_item.items.length == 0 || !_item.items))
                        this.mergeData(_item.items, propsData[i].items);
                } else {
                    propsData[i].displayIndex = localData.length;
                    localData.push(propsData[i]);
                }

            }

            //Update Index
            localData.forEach((item, i) => { item.displayIndex = i; _tempArray.push(item); });
        }

        return _tempArray;
    }

    render() {
        let mergedItems = this.mergeData(this.state.items, this.props.items).sort(function (i, j) {
            return i.displayIndex - j.displayIndex
        });

        let classNames = cx({
            toolbar: true,
            bordered: this.props.borderedButtons,
            miniButtons: this.props.size === 'small'
        });

        return (
            <div className={classNames}>
                {
                    this.renderToolbar(mergedItems)
                }
            </div>
        );
    }

    renderToolbar(items: Array<IItem>) {
        if (!(items && items.length > 0))
            return null;

        let _temp = items.map((item, i) => {
            switch (item.itemType) {
                case IItemType.IButton:
                    return this.renderButtom(item);
                case IItemType.IButtonGroup:
                    return this.renderButtomGroup(item);
                case IItemType.IDropDown:
                    return this.renderDropDown(item);
                case IItemType.IDropdownButtom:
                    return this.renderDropdownButtom(item);
                case IItemType.IInput:
                    return this.renderInput(item);
                case IItemType.IColorPicker:
                    return this.renderColorPicker(item);
                case IItemType.ICombobox:
                    return this.renderCombobox(item);
                default:
            }
        });


        return (_temp);
    }


    renderButtom(item: IItem) {

        let iconW = "32px", iconH = "32px";
        if (this.props.iconDimension) {
            iconW = this.props.iconDimension.width;
            iconH = this.props.iconDimension.height;
        }
        const Icon = GetIconComponent(item.icon, { width: iconW, height: iconH });

        return (
            <BCButton
                key={item.key}
                guid={item.key}
                text={item.text}
                disabled={item.disabled}
                icon={<IconEmpty />} //textin renderını engellemek için
                onClick={this.onClickButton}
                cssClass={[styles.toolbarButton, (this.props.borderedButtons ? styles.bordered : ''), item.className, this.props.buttonClassName].join(' ')}
            >
                <BCTooltip
                    title={item.text}
                    placement={'bottom'}
                    trigger={'hover'}
                    mouseEnterDelay={1}
                >
                    <div className={styles.buttonContainer}>
                        {Icon}
                    </div>
                </BCTooltip>
            </BCButton>
        );
    }
    renderButtomGroup(item: IItem): JSX.Element {
        return (
            <BCButtonGroup key={item.key} className={item.className}>
                {this.renderToolbar(item.items)}
            </BCButtonGroup>
        );
    }
    dropDownStyle: React.CSSProperties = { width: 200, marginLeft: 10, transform: 'translate(0, 2px)' };
    renderDropDown(item: IItem) {
        return (
            <BCDropdown
                key={item.key}
                items={item.items}
                style={this.dropDownStyle}
                disabled={item.disabled}
                onSelected={(args) => this.onClick(args.domEvent, args.data)}
                defaultValue={item.value}
                className={item.className}
            />
        );
    }
    renderDropdownButtom(item: IItem) {
        return (
            <BCDropdownButton
                key={item.key}
                items={item.items}
                text={item.text}
                disabled={item.disabled}
                onClick={(args) => this.onClick(args.event, args.data)}
                selectedChanged={(args) => this.onClick(args.domEvent, args.data)}
                defaultValue={item.value}
                className={item.className}
            />
        )
    }
    findItem(sourceItems: Array<IItem>, itemKey: string): IItem {
        let result: IItem;
        if (sourceItems) {
            for (var i = 0; i < sourceItems.length; i++) {
                if (sourceItems[i].key === itemKey) {
                    result = sourceItems[i];
                    break;
                }
                else if (sourceItems[i].items && sourceItems[i].items.length > 0) {
                    let findSubItem = this.findItem(sourceItems[i].items, itemKey);
                    if (findSubItem) {
                        result = findSubItem;
                        break;
                    }
                }
            }
        }
        return result;
    }
    onSelectedChange_BCCombobox(args: IComboboxSelectedChangeEventArgs) {
        let item = this.findItem(this.props.items, args.senderArgs.itemKey);
        this.onClick(args, { key: item.key, text: item.text, actionKey: item.actionKey, value: args.data as string })
    }
    renderCombobox(item: IItem) {
        return (
            <BCCombobox
                key={item.key}
                placeholder="Seçiniz"
                style={this.dropDownStyle}
                showSearch={true}
                options={item.items}
                onSelectedChange={this.onSelectedChange_BCCombobox}
                value={item.value}
                disabled={item.disabled}
                className={item.className}
                size={'small'}
            />
        )
    }
    renderInput(item: IItem) {
        return (
            <BCInput
                key={item.key}
                placeHolder={item.placeHolder}
                value={item.value}
                onChange={(args) => this.onClick(args.senderArgs, { key: item.key, actionKey: item.actionKey, value: args.data })}
                size={'small'}
            />
        );
    }
    renderColorPicker(item: IItem) {
        return (
            <BCColorPicker
                color={item.value}
                onChange={(color: string) => this.onClick(null, { key: item.key, text: item.text, actionKey: item.actionKey, value: color })}
                size={'small'}
            />
        )
    }

}

export { IToolbarProps, IToolbarItemClickEventArgs };