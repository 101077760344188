import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseLoginMethod {
    enabled: boolean,
    icon: string,
    name: string,
    url: string
}

export interface ILoginMethod extends IBaseLoginMethod {

}

export interface IReadonlyLoginMethod extends IBaseLoginMethod {

}

export type LoginMethod = ReadonlyRecord<IReadonlyLoginMethod>;

export const LoginMethodFactory = Record<IReadonlyLoginMethod>({
    enabled: false,
    icon: "",
    name: null,
    url: null
});

export const LoginMethodFactoryFromJS = function (data: ILoginMethod) {
    return LoginMethodFactory(data);
}