import React, { lazy, Suspense } from 'react';
import { IIconFinderProps } from './entities';

const BCIconFinderLazy = lazy(() => import(/* webpackChunkName: "icon-finder" */ "./components/IconFinder"));

function BCIconFinder(props: IIconFinderProps) {
    return (
        <Suspense fallback={<div></div>}>
            <BCIconFinderLazy {...props} />
        </Suspense>
    );
}

export { BCIconFinder }