import { createSyncAction, IDictionary } from "@bimser/core";
import { List, Map } from "immutable";
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "../../../common/entities";
import * as ActionTypes from './actionTypes';
import { TileTypes } from "../entities/widgets/TileTypes";
import { DmData, DmDataFactoryFromJS, IDmData } from "@bimser/common";
import { Announcement, AnnouncementFactoryFromJS, IAnnouncement } from "../../announcements/entities";
import { IProcess, Process, ProcessFactoryFromJS } from "../entities/widgets/model/types/IProcess";
import { IMenuItem, MenuItemFactoryFromJS } from "../../../common/entities/IMenuItem";
import IProcessStructure from "../entities/widgets/model/types/IProcessStructure";
import { DashboardFormData } from "../entities/IDashboardFormData";

export const refreshDashboardDataTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.REFRESH_DASHBOARD_DATA_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
)

export const setDashboardWillRefreshDataAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_DASHBOARD_WILL_REFRESH_DATA_ACTION, data)
);

// #RecentApps
export const setRecentAppsDataAction = (data: Array<IMenuItem>) => {
    const _data = data.map(item => MenuItemFactoryFromJS(item));

    return createSyncAction(ActionTypes.SET_RECENT_APPS_DATA_ACTION, List(_data))
};

export const setRecentAppsLoadingAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_RECENT_APPS_LOADING_ACTION, data)
);

export const onClickRecentAppTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_RECENT_APP_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

// #Tiles
export const setTilesCountsAction = (data: IDictionary<number>) => (
    createSyncAction(ActionTypes.SET_TILES_COUNTS_ACTION, Map(data))
);

export const setTilesLoadingAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_TILES_LOADING_ACTION, data)
);

export const setSelectedTileAction = (data: TileTypes) => (
    createSyncAction(ActionTypes.SET_SELECTED_TILE_ACTION, data)
);

export const onChangeSelectedTileTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CHANGE_SELECTED_TILE_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

// #RecentFiles
export const setRecentFilesDataAction = (data: Array<IDmData>) => {
    const _data: Array<DmData> = data.map((item: IDmData) => DmDataFactoryFromJS(item));
    
    return createSyncAction(ActionTypes.SET_RECENT_FILES_DATA_ACTION, List(_data))
};

export const setRecentFilesLoadingAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_RECENT_FILES_LOADING_ACTION, data)
);

export const onClickRecentFileTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_RECENT_FILE_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

export const onClickAllRecentFilesTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_ALL_RECENT_FILES_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

// #Announcements
export const setAnnouncementsDataAction = (data: Array<IAnnouncement>) => {
    const _data: Array<Announcement> = data.map((item: IAnnouncement) => AnnouncementFactoryFromJS(item));
    
    return createSyncAction(ActionTypes.SET_ANNOUNCEMENTS_DATA_ACTION, List(_data))
};

export const setAnnouncementsLoadingAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_ANNOUNCEMENTS_LOADING_ACTION, data)
);

export const onClickAnnouncementTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_ANNOUNCEMENT_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

export const onClickAllAnnouncementsTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_ALL_ANNOUNCEMENTS_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

// #Proccesses
export const insertProcessesDataAction = (data: { data: Array<IProcess>, totalCount: number}) => {
    const _data: List<Process> = List(data.data.map((item: IProcess) => ProcessFactoryFromJS(item)));
    
    return createSyncAction(ActionTypes.INSERT_PROCESSES_DATA_ACTION, Map({ data: _data, totalCount: data.totalCount }))
};

export const resetProcessesDataAction = () => createSyncAction(ActionTypes.RESET_PROCESSES_DATA_ACTION, undefined)

export const onClickProcessTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_PROCESS_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

export const onLoadProcessesTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_LOAD_PROCESSES_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

export const setProcessesLoadingAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_PROCESSES_LOADING_ACTION, data)
);

export const setSelectedProcessAction = (selected: IProcess) => (
    createSyncAction(ActionTypes.SET_SELECTED_PROCESS_ACTION, ProcessFactoryFromJS(selected))
);

export const resetSelectedProcessAction = () => createSyncAction(ActionTypes.RESET_SELECTED_PROCESS_ACTION, undefined)

// #Proccess Details
export const insertProcessDetailsDataAction = (data: { data: Array<IDictionary<any>>, totalCount: number}) => {
    const _data : Array<Map<string, any>> = data.data.map((item: IDictionary<any>) => Map(item));

    return createSyncAction(ActionTypes.INSERT_PROCESS_DETAILS_DATA_ACTION, Map({ data: List<Map<string, any>>(_data), totalCount: data.totalCount }))
};

export const setProcessDetailsStructure = (data: Array<IProcessStructure>) => (
    createSyncAction(ActionTypes.SET_PROCESS_DETAILS_STRUCTURE_ACTION, List(data))
)

export const resetProcessDetailsDataAction = () => createSyncAction(ActionTypes.RESET_PROCESS_DETAILS_DATA_ACTION, undefined)

export const onClickProcessDetailTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_CLICK_PROCESS_DETAIL_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

export const onLoadProcessDetailsTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(ActionTypes.ON_LOAD_PROCESS_DETAILS_TRANSACTION, WebInterfaceActionFactoryFromJS(data))
);

export const setProcessDetailsLoadingAction = (data: boolean) => (
    createSyncAction(ActionTypes.SET_PROCESS_DETAILS_LOADING_ACTION, data)
);

export const setDashboardFormDataAction = (data: DashboardFormData) => (
    createSyncAction(ActionTypes.SET_DASHBOARD_FORM_DATA_ACTION, data)
);