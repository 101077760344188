import { Map } from "immutable";
import { generateUniqueName } from "@bimser/core";
import { LayoutDataItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const generateId = (views: Map<string, NormalizedLayoutStructureItems>, data: Map<string, LayoutDataItem>, name: string) => {
    let allItemKeys: string[] = [];

    views.forEach(view => {
        allItemKeys = [...allItemKeys, ...view.entities.items.keySeq().toArray()];
    });

    allItemKeys = [...allItemKeys, ...data.keySeq().toArray()];

    return generateUniqueName([...new Set(allItemKeys)], name);
};