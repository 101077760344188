import { Record } from "immutable";
import { IMethodInvokeAction, IShowMessageAction, IValueAssignActionWithEntry, IValueAssignActionWithSelection, MethodInvokeActionFactoryFromJS, ShowMessageActionFactoryFromJS, ValueAssignActionWithEntryFactoryFromJS, ValueAssignActionWithSelectionFactoryFromJS, IValueAssignActionWithFormula, ValueAssignActionWithFormulaFactoryFromJS } from ".";
import { ReadonlyRecord } from "@bimser/core";
import { ActionType } from "../Enums";

interface IBaseBaseAction {
    id: string,
    name: string,
    typeName: ActionType,
    enabled?: boolean
}

export interface IBaseAction extends IBaseBaseAction {

}

export interface ReadOnlyBaseAction extends IBaseBaseAction {

}

export type BaseAction = ReadonlyRecord<ReadOnlyBaseAction>;

export const BaseActionFactoryObject: ReadOnlyBaseAction = {
    enabled: true,
    id: null,
    typeName: null,
    name: null
};

export const BaseActionFactory = Record<ReadOnlyBaseAction>(BaseActionFactoryObject);

export const BaseActionFactoryObjectFromJS = function (data: IBaseAction) {
    return {
        id: data.id,
        name: data.name,
        typeName: data.typeName,
        enabled: data.enabled
    };
}

export const BaseActionFactoryFromJS = function (data: IBaseAction) {
    return BaseActionFactory(BaseActionFactoryObjectFromJS(data));
}

export const ActionCastFactory = function (actions: IBaseAction[]): Array<any> {

    return actions && actions.length ? actions.map(i => {

        switch (i.typeName) {
            case 'ShowMessageAction':
            case 'ValidationFailureAction':
            case 'ConfirmationAction': {
                return ShowMessageActionFactoryFromJS(i as IShowMessageAction)
            }
            case 'ValueAssignActionWithEntry': {
                return ValueAssignActionWithEntryFactoryFromJS(i as IValueAssignActionWithEntry)
            }
            case 'ValueAssignActionWithFormula': {
                return ValueAssignActionWithFormulaFactoryFromJS(i as IValueAssignActionWithFormula)
            }
            case 'ValueAssignActionWithSelection': {
                return ValueAssignActionWithSelectionFactoryFromJS(i as IValueAssignActionWithSelection)
            }
            case 'MethodInvokeAction': {
                return MethodInvokeActionFactoryFromJS(i as IMethodInvokeAction)
            }
            default: return BaseActionFactoryFromJS(i)
        }

    }) : [];

}