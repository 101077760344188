import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { 
    HumanResourceModules, HumanResourceModulesFactory, HumanResourceModulesFactoryFromJS, IHumanResourceModules,
    IHumanResourceMenu, HumanResourceMenu, HumanResourceMenuFactoryFromJS, 
} from "./models";

export interface IBaseHumanResourceMainState {
}

export interface IHumanResourceMainState extends IBaseHumanResourceMainState {
    modules: IHumanResourceModules,
    menu: Array<IHumanResourceMenu>
}

export interface IReadonlyHumanResourceMainState extends IBaseHumanResourceMainState {
    modules: HumanResourceModules,
    menu: List<HumanResourceMenu>
}

export type HumanResourceMainState = ReadonlyRecord<IReadonlyHumanResourceMainState>;

export const HumanResourceMainStateFactory = Record<IReadonlyHumanResourceMainState>({
    modules: HumanResourceModulesFactory(),
    menu: List<HumanResourceMenu>()
});

export const HumanResourceMainStateFactoryFromJS = function (data: IHumanResourceMainState) {
    const menu : Array<HumanResourceMenu> = data.menu.map((item: IHumanResourceMenu) => HumanResourceMenuFactoryFromJS(item));

    return HumanResourceMainStateFactory({
        modules: HumanResourceModulesFactoryFromJS(data.modules),
        menu: List(menu)
    });
}