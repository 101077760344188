import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { Fields, IFields, FieldsFactoryFromJS } from "./IFields";
import { IProfiles, Profiles, ProfilesFactoryFromJS } from "./IProfiles";
import { IWhyContent, WhyContent, WhyContentFactoryFromJS } from "./IWhy";

interface IBaseDmObjectMetadataProperty {
    inherit: boolean
}

export interface IDmObjectMetadataProperty extends IBaseDmObjectMetadataProperty {
    why: IWhyContent,
    value: {
        profiles: IProfiles[],
        fields: IFields[],
    }
}

export interface IReadonlyDmObjectMetadataProperty extends IBaseDmObjectMetadataProperty {
    why: WhyContent,
    value: {
        profiles: List<Profiles>,
        fields: List<Fields>,
    }
}

export type DmObjectMetadataProperty = ReadonlyRecord<IReadonlyDmObjectMetadataProperty>;

export const DmObjectMetadataPropertyFactory = Record<IReadonlyDmObjectMetadataProperty>({
    value: null,
    why: null,
    inherit: false
});

export const DmObjectMetadataPropertyFactoryFromJS = function (data: IDmObjectMetadataProperty) {
    if (!data) return DmObjectMetadataPropertyFactory();
    let _profiles = data.value?.profiles.map(p => ProfilesFactoryFromJS(p)) || [];
    let _fields = data.value?.fields.map(f => FieldsFactoryFromJS(f)) || [];

    return DmObjectMetadataPropertyFactory({
        ...data,
        why: WhyContentFactoryFromJS(data.why),
        value: {
            profiles: List(_profiles),
            fields: List(_fields)
        }
    });
}