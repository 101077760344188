import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseAction {
    id: number,
    stateName: string,
    tags: string,
    assignTo: string,
    projectName: string,
    formName: string,
    startDate: string,
    endDate: string,
    createDate: string,
    priority: number,
    assignToId: number,
    stateCategoryId: number,
    stateCategoryName: string,
    stateCategoryColor: string,
    stateId: number,
    title: string,
    description: string
    documentId: number,
}

export interface IAction extends IBaseAction {

}

export interface IReadonlyAction extends IBaseAction {

}

export type Action = ReadonlyRecord<IReadonlyAction>;

export const ActionFactory = Record<IReadonlyAction>({
    id: null,
    stateName: null,
    tags: null,
    assignTo: null,
    projectName: null,
    formName: null,
    startDate: null,
    endDate: null,
    createDate: null,
    priority: null,
    assignToId: null,
    stateCategoryId: null,
    stateCategoryName: null,
    stateCategoryColor: null,
    stateId: null,
    title: null,
    description: null,
    documentId: 0
});

export const ActionFactoryFromJS = function (data: IAction) {
    return ActionFactory({
        ...data,
    });
}
