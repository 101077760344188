import { debounce, IItem } from '@bimser/core';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames/bind';
import * as React from "react";
import { BCInputSearch, findMessage, IInputChangeEventArgs } from '..';
import * as Styles from './assets/radioStyles.scss';
import { IRadioButtonGroupProps, IRadioButtonGroupSelectedChangeEventArgs } from './entities';

const cx = classNames.bind(Styles);

const BCRadioButtonGroup: React.FC<IRadioButtonGroupProps> = props => {
    const [value, setValue] = React.useState(props.value);
    const [searchKey, setSearchKey] = React.useState<string>("");

    React.useEffect(() => setValue(props.value), [props.value])

    const selectedChanged = (e: RadioChangeEvent) => {
        const args: IRadioButtonGroupSelectedChangeEventArgs = { senderArgs: e, data: e.target.value }
        setValue(args.data)
        props.onSelectedChange?.({ senderArgs: args, data: args.data });
    }

    const getOptions = (options: Array<IItem>) => {
        if (!options) return null;

        const classname = cx({
            vertical: props.mode === 'vertical',
            horizontal: props.mode === 'horizontal',
            disabled: props.disabled
        });
        return options
            .filter(({ text }) => text.toLocaleLowerCase().indexOf(searchKey.toLocaleLowerCase()) >= 0)
            .map(item => props.buttonType === 'button' ? (
                <Radio.Button
                    key={item.key}
                    className={classname}
                    style={props.style}
                    value={item.value}
                    disabled={props.disabled}
                >
                    {item.text}
                </Radio.Button>
            ) : (
                <Radio
                    key={item.key}
                    className={classname}
                    style={props.style}
                    value={item.value}
                    disabled={props.disabled}
                >
                    {item.text}
                </Radio>
            ));
    }

    const onSearch = (_value: IInputChangeEventArgs | string) => {
        const val = typeof _value === "string" ? _value : _value.data;
        props.onSearch?.(val);
        setSearchKey(val);
    }

    const debouncedOnSearch = React.useMemo(() => debounce(onSearch, 300), [onSearch])

    const renderSearchbox = () => (
        <div className={Styles.searchBox}>
            <BCInputSearch readOnly={props.disabled}
                placeHolder={findMessage.get('100002')}
                size={'middle'}
                onSearch={debouncedOnSearch}
                onChange={debouncedOnSearch}
                value={props.onSearch && props.searchValue ? props.searchValue : searchKey}
            />
        </div>
    )


    const getContainerClassNames = () => props.mode === "horizontal" ?
        [Styles.radioGroupContent, props.containerClassName].join(' ')
        : ""

    return (
        <div className={getContainerClassNames()}>
            {props.showSearch && renderSearchbox()}
            <Radio.Group
                className={props.className}
                onChange={!props.readOnly && selectedChanged}
                value={value}
                disabled={props.disabled}
                style={props.style}>
                {getOptions(props.options)}
                {props.children}
            </Radio.Group>
        </div>

    );
}
export default BCRadioButtonGroup;
export { IRadioButtonGroupProps, IRadioButtonGroupSelectedChangeEventArgs };

