import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IUserMaintenanceFormContent extends IBaseFormContent {
    showingReplacement?: IReplacement
}

export interface IReadonlyUserMaintenanceFormContent extends IBaseFormContent {
    showingReplacement?: Replacement,
}

export type UserMaintenanceFormContent = ReadonlyRecord<IReadonlyUserMaintenanceFormContent>;

export const UserMaintenanceFormContentFactory = Record<IReadonlyUserMaintenanceFormContent>({
    panelId: "",
    showingReplacement: ReplacementFactory(),
    isModified: false,
    initialHash: "",
    selectableListPanelisActive: false,
    selectedCompanyId: 0
});

export const UserMaintenanceFormContentFactoryFromJS = function (data: IUserMaintenanceFormContent) {
    return UserMaintenanceFormContentFactory({
        panelId: data.panelId,
        showingReplacement: ReplacementFactoryFromJS(data.showingReplacement),
        isModified: data.isModified,
        initialHash: data.initialHash,
        selectableListPanelisActive: data.selectableListPanelisActive,
        selectedCompanyId: data.selectedCompanyId
    });
}