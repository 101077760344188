import { BaseState } from "../../../entities/BaseState";
import { createSelector } from 'reselect';
import { Tour } from '../entities/ITour';


const getActiveUITourSelector = (state: BaseState) => {
    return state.UITour.tours.get(state.UITour.activeTourId, undefined);
}

const getActiveUITour = createSelector(
    [
        getActiveUITourSelector
    ],
    (tour: Tour) => {
        return tour ? tour.toJS() : null
    }
)

export {
    getActiveUITour
}