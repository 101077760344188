import { BCNotification, findMessage } from "@bimser/components";
import { IBaseAction } from "@bimser/core";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionTypes, setMFAProperties } from '../actions';
import commonLoginHandler from "./commonLoginHandler";


function* loginWithDigitalSignatureTransaction() {
    yield takeLatest(ActionTypes.LOGIN_WITH_DIGITAL_SIGNATURE, handleLoginWithDigitalSignature)
}
export default loginWithDigitalSignatureTransaction;

function* handleLoginWithDigitalSignature(action: IBaseAction<any>) {
    try {
        let { token, bimserLanguage, tempTokenKey, mfaEnabled } = action.payload;

        if (mfaEnabled) {
            yield put(setMFAProperties({ mfaEnabled, tempTokenKey, currentLoginProcedure: "digitalSignature" }));
        } else {
            console.log("🚀 -> function*handleLoginWithDigitalSignature -> action.payload", action.payload);
            //return;
            yield call(commonLoginHandler, { language: bimserLanguage }, { data: { token } }, "digitalSignature")
        }
    } catch (error) {
        BCNotification.error({
            message: findMessage.get('100592')
        });
    }
}