import React, { lazy, Suspense } from 'react';
import { IPropertiesProps } from './entities';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-dm" */ "./containers"));

function Container(props: React.PropsWithChildren<IPropertiesProps>) {
    return (
        <Suspense fallback={< div > </div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
Container.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-dm" */ "modules/dm/injector");
}

export default Container;