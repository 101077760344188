import { isNullOrUndefined } from "@bimser/core";
import * as React from "react";
import BCInputNumber from '../../../BCInputNumber';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defaultStyle: React.CSSProperties = { width: '100%' };

export default function InputNumberProperty(props: IPropertyComponentProps) {

    const inputRef = React.useRef<HTMLInputElement>();

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        if (props.value || !isNullOrUndefined(newValue)) {
            props.onValueChanged(item, newValue);
        }
    }

    const onChange = (value: any) => {
        props.customChangeEvent ? props.customChangeEvent(value, props) : replaceValue(props.item, value)
    }

    const isInteger = () => {
        return !isNullOrUndefined(props.item.externalData?.isInteger) && props.item.externalData.isInteger;
    }

    const precision = () => {
        if (typeof props.item?.externalData === "object") {
            const precision = props.item?.externalData?.precision;
            if (isInteger() && isNullOrUndefined(precision)) {
                return { precision: 0 };
            }
            else if (!isNullOrUndefined(precision)) {
                return { precision: Math.min(precision, 100) }
            }

        }
        return {};
    }

    const onBlur = () => {
        if (props.item?.externalData?.allowNull === false && inputRef?.current && (isNullOrUndefined(inputRef.current.value) || inputRef.current.value === "")) {
            let _value = props.item.value || props.item.reset?.value;
            inputRef.current.value = _value;
        }
    }

    const getMinValue = () => {
        if (!isNullOrUndefined(props.minValue))
            return props.minValue;
        if (props.item.externalData && !isNullOrUndefined(props.item.externalData.minValue))
            return props.item.externalData.minValue;
        return -Infinity;
    }

    return (
        <BCInputNumber
            id={props.item.key}
            value={props.value === undefined || props.value === null ? props.item.placeholder : props.value}
            style={props.style || defaultStyle}
            disabled={props.item.disabled}
            readOnly={props.item.readOnly}
            min={getMinValue()}
            max={props.item.externalData?.max ?? undefined}
            formatter={props.item.externalData?.formatter ?? undefined}
            parser={props.item.externalData?.parser ?? undefined}
            ref={inputRef}
            onChange={onChange}
            onBlur={onBlur}
            {...precision()}
        />
    )
}