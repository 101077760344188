import React, { lazy, Suspense } from 'react';
import IFlowSchemaProps from "./entities/IFlowSchemaProps"

const FlowSchemaLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./containers"));

function FlowSchema(props: React.PropsWithChildren<IFlowSchemaProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <FlowSchemaLazy {...props} />
        </Suspense>
    );
}

export default FlowSchema;