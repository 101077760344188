interface MimeTypeExtension {
    mimeType: string[],
    extension: string[]
}

const mimeTypeExtensionList: MimeTypeExtension[] = [
    { mimeType: [], extension: ["cs"] },
    { mimeType: [], extension: ["eaf"] },
    { mimeType: [], extension: ["ts"] },
    { mimeType: [], extension: ["eproj"] },
    { mimeType: ["application/pdf"], extension: ["pdf"] },
    { mimeType: ["text/csv"], extension: ["csv"] },
    { mimeType: ["text/css"], extension: ["css"] },
    { mimeType: ["application/xml", "text/xml"], extension: ["xml"] },
    { mimeType: ["application/msword"], extension: ["doc"] },
    { mimeType: ["application/vnd.ms-word.document.macroenabled.12"], extension: ["docm"] },
    { mimeType: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"], extension: ["docx"] },
    { mimeType: ["application/vnd.ms-word.template.macroenabled.12"], extension: ["dotm"] },
    { mimeType: ["application/vnd.openxmlformats-officedocument.wordprocessingml.template"], extension: ["dotx"] },
    { mimeType: ["image/jpeg", "image/x-citrix-jpeg"], extension: ["jpeg", "jpg"] },
    { mimeType: ["image/gif"], extension: ["gif"] },
    { mimeType: ["image/png", "image/x-citrix-png", "image/x-png"], extension: ["png"] },
    { mimeType: ["image/tiff"], extension: ["tiff"] },
    { mimeType: ["image/bmp"], extension: ["bmp"] },
    { mimeType: ["application/javascript", "text/javascript"], extension: ["js"] },
    { mimeType: ["application/json"], extension: ["json"] },
    { mimeType: ["video/mp4", "application/mp4"], extension: ["mp4"] },
    { mimeType: ["application/vnd.ms-powerpoint.template.macroenabled.12"], extension: ["potm"] },
    { mimeType: ["application/vnd.ms-powerpoint.addin.macroenabled.12"], extension: ["ppam"] },
    { mimeType: ["application/vnd.ms-powerpoint.slideshow.macroenabled.12"], extension: ["ppsm"] },
    { mimeType: ["application/vnd.ms-powerpoint"], extension: ["ppt"] },
    { mimeType: ["application/vnd.ms-powerpoint.presentation.macroenabled.12"], extension: ["pptm"] },
    { mimeType: ["application/vnd.openxmlformats-officedocument.presentationml.presentation"], extension: ["pptx"] },
    { mimeType: ["application/vnd.ms-powerpoint.slide.macroenabled.12"], extension: ["sldm"] },
    { mimeType: ["text/plain"], extension: ["txt"] },
    { mimeType: ["application/vnd.ms-excel.addin.macroenabled.12"], extension: ["xlam"] },
    { mimeType: ["application/vnd.ms-excel"], extension: ["xls"] },
    { mimeType: ["application/vnd.ms-excel.sheet.binary.macroenabled.12"], extension: ["xlsb"] },
    { mimeType: ["application/vnd.ms-excel.sheet.macroenabled.12"], extension: ["xlsm"] },
    { mimeType: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"], extension: ["xlsx"] },
    { mimeType: ["application/vnd.ms-excel.template.macroenabled.12"], extension: ["xltm"] },
    { mimeType: ["application/vnd.openxmlformats-officedocument.spreadsheetml.template"], extension: ["xltx"] },
    { mimeType: ["application/gzip"], extension: ["gz"] },
    { mimeType: ["application/vnd.rar"], extension: ["rar"] },
    { mimeType: ["application/x-tar"], extension: ["tar"] },
    { mimeType: ["application/zip"], extension: ["zip"] },
    { mimeType: ["application/calendar"], extension: ["ics"] },
    { mimeType: ["text/html"], extension: ["html"] },
    { mimeType: ["application/xhtml+xml"], extension: ["xht", "xhtml"] },
    { mimeType: ["application/vnd.ms-fontobject"], extension: ["eot"] },
    { mimeType: ["application/rtf"], extension: ["rtf"] },
    { mimeType: ["application/vnd.oasis.opendocument.spreadsheet"], extension: ["ods"] },
    { mimeType: ["application/vnd.oasis.opendocument.text"], extension: ["odt"] },
    { mimeType: ["application/vnd.oasis.opendocument.presentation"], extension: ["odp"] },
    { mimeType: ["application/epub+zip"], extension: ["epub"] },
    { mimeType: ["application/octet-stream"], extension: ["a", "bin", "bpk", "deploy", "dist", "distz", "dmg", "dms", "dump", "elc", "iso", "lha", "lrf", "lzh", "o", "obj", "pkg", "so"] }
]
export default mimeTypeExtensionList;