import * as React from "react";
import * as ReactDOM from "react-dom";

import { IPLabelProps } from '../../../entities';
import * as Styles from "../../../assets/style.scss";

export default class IPLabel extends React.Component<IPLabelProps, any> {
    constructor(props: IPLabelProps) {
        super(props);
    }

    render() {
        return (
            <p className={Styles.borderLabel} style={this.props.style}>
                {this.props.label}
            </p>
        )
    }
}