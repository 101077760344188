import { IBaseAction, isNullOrUndefined } from "@bimser/core";
import { cancel, fork, put, take, takeEvery } from 'redux-saga/effects';
import { openModalAction } from '../../../actions';
import { MessageBoxIcon } from '../../../enums';
import * as ActionTypes from '../actions/actionTypes';
import { IMessageBoxResolvePayload, OpenMessageBoxPayload } from '../entities';


export default function* watchOpenMessageBoxTransaction() {
    yield takeEvery(ActionTypes.OPEN_MESSAGEBOX_TRANSACTION_ACTION, openMessageBox);
}

export function* openMessageBox(action: IBaseAction<OpenMessageBoxPayload>) {
    let resolvePromise: any = null;

    let promise = new Promise<IMessageBoxResolvePayload>((resolve, reject) => {
        resolvePromise = resolve;
    });

    let modalOpenAction = openModalAction({
        type: "MESSAGEBOX",
        id: action.payload.id,
        width: action.payload.width,
        props: {
            title: action.payload.title,
            content: action.payload.content,
            type: isNullOrUndefined(action.payload.type) ? MessageBoxIcon.None : action.payload.type,
            buttons: action.payload.buttons,
            props: action.payload.props,
            errorDetail: action.payload.errorDetail,
            showErrorDetail: action.payload.showErrorDetail,
            notInCloseIcon: action.payload.notInCloseIcon,
            errorCode: action.payload.errorCode,
            error: action.payload.error
        },
        visible: true,

    });

    yield put(modalOpenAction);

    yield fork(waitUserResponse, resolvePromise, action.payload.id);


    if (action.payload.onReturn) {
        action.payload.onReturn(promise);
    }
}

function* waitUserResponse(resolvePromise: any, id?: string) {
    while (true) {
        let action = yield take([ActionTypes.RESOLVE_TRANSACTION_ACTION]);
        if (action && action.payload && action.payload.toJS && resolvePromise && id === action.payload.id) {
            resolvePromise(action.payload.toJS());
            yield cancel();
        }
    }
    //yield put(closeModalTransactionAction(action.payload && action.payload.id));
}