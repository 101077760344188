import { List, Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ConjunctionType } from "./Enums";
import { Condition, ConditionFactoryFromJS, ICondition } from "./ICondition";

interface IBaseConditionGroup {
    id: string,
    typeName: ConjunctionType,
    collapsed?: boolean
}

export interface IConditionGroup extends IBaseConditionGroup {
    children: Array<IConditionGroup>,
    conditions: Array<ICondition>
}

export interface ReadOnlyConditionGroup extends IBaseConditionGroup {
    children: List<ConditionGroup>,
    conditions: List<Condition>
}

export type ConditionGroup = ReadonlyRecord<ReadOnlyConditionGroup>;

export const ConditionGroupFactory = Record<ReadOnlyConditionGroup>({
    id: null,
    typeName: null,
    children: List(),
    conditions: List(),
    collapsed: false
});

export const ConditionGroupFactoryFromJS = function (data: IConditionGroup) {

    if (!data) return null

    let children: Array<ConditionGroup> = data.children && data.children.length ? data.children.map(i => {
        return ConditionGroupFactoryFromJS(i)
    }) : [];

    let conditions: Array<Condition> = data.conditions && data.conditions.length ? data.conditions.map(i => {
        return ConditionFactoryFromJS(i)
    }) : [];

    return ConditionGroupFactory({
        ...data,
        children: List(children),
        conditions: List(conditions)
    });
}