import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";
import { ILayoutBaseItem, LayoutBaseItemRecord } from '.';
import { LayoutBaseItemFactoryObjectFromJS } from "./ILayoutBaseItem";

export interface IBaseLayoutDataItem extends ILayoutBaseItem {

}

export interface ILayoutDataItem extends IBaseLayoutDataItem {
    properties?: IDictionary<any>,
    viewProperties?: IDictionary<IDictionary<any>>
}

export interface IReadonlyLayoutDataItem extends IBaseLayoutDataItem {
    properties?: Map<string, any>,
    viewProperties?: Map<string, Map<string, any>>
}

export type LayoutDataItem = ReadonlyRecord<IReadonlyLayoutDataItem>;

export const LayoutDataItemRecord: IReadonlyLayoutDataItem = {
    properties: Map(),
    viewProperties: Map(),
    ...LayoutBaseItemRecord
};

export const LayoutDataItemFactory = Record<IReadonlyLayoutDataItem>(LayoutDataItemRecord);

export const LayoutDataItemFactoryFromJS = function (data: ILayoutDataItem) {
    return LayoutDataItemFactory(LayoutDataItemFactoryObjectFromJS(data));
}

export const LayoutDataItemFactoryObjectFromJS = function (data: ILayoutDataItem) {

    const viewProperties: IDictionary<Map<string, any>> = Object.keys(data.viewProperties || {}).reduce(function (previous: IDictionary<Map<string, any>>, current) {
        previous[current] = Map(data.viewProperties[current]);
        return previous;
    }, {});

    return {
        properties: Map(data.properties),
        viewProperties: Map(viewProperties),
        ...LayoutBaseItemFactoryObjectFromJS(data)
    };
}