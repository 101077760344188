import { IUserInfo, UserInfo, UserInfoFactoryFromJS } from '@bimser/common';
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, List, Record } from "immutable";
import * as moment from 'moment';
import { IRole, Role, AnnouncementRoleFactoryFromJS } from './IRole';

export interface IBaseAnnouncement {
    id: number,
    createdAt: string,
    sender?: string,
    status?: boolean,
    isDeleted?: boolean
    startDate?: string,
    endDate?: string,
    showInStartup?: boolean,
    isViewed: boolean,
    canEdit?: boolean,
    canDelete?: boolean,
    secretKey?: string,
    sendEmail?: boolean,
    neverExpire?: boolean
}

export interface IAnnouncement extends IBaseAnnouncement {
    attachments?: Array<string>,
    visibleFor?: Array<IRole>,
    createdBy?: IUserInfo,
    createDelegatedBy?: IUserInfo,
    deletedUserBy?: IUserInfo,
    deleteDelegatedBy?: IUserInfo,
    modifiedUserBy?: IUserInfo,
    modifyDelegatedBy?: IUserInfo,
    subject: IDictionary<string>,
    message?: IDictionary<string>,
}

export interface IReadonlyAnnouncement extends IBaseAnnouncement {
    attachments?: List<string>,
    visibleFor?: List<Role>,
    createdBy?: UserInfo,
    createDelegatedBy?: UserInfo,
    deletedUserBy?: UserInfo,
    deleteDelegatedBy?: UserInfo,
    modifiedUserBy?: UserInfo,
    modifyDelegatedBy?: UserInfo,
    subject: Map<string,string>,
    message?: Map<string,string>
}

export type Announcement = ReadonlyRecord<IReadonlyAnnouncement>;

export const AnnouncementFactory = Record<IReadonlyAnnouncement>({
    id: 0,
    subject: Map(),
    message: Map(),//'<p>&nbsp;</p>',
    createdAt: moment(Date.now()).format(),
    sender: '',
    status: false,
    isDeleted: false,
    startDate: moment(Date.now()).format(),
    endDate: moment(Date.now()).add(1, 'day').format(),
    visibleFor: List(),
    showInStartup: false,
    isViewed: false,
    secretKey: '',
    attachments: List(),
    canDelete: false,
    canEdit: false,
    sendEmail: false,
    neverExpire: false,
    createdBy: null,
    createDelegatedBy: null,
    deletedUserBy: null,
    deleteDelegatedBy: null,
    modifiedUserBy: null,
    modifyDelegatedBy: null
});

export const AnnouncementFactoryFromJS = function (announcement: IAnnouncement) {
    let _visibleFor: Array<Role> = announcement.visibleFor?.length ? announcement.visibleFor.map((item: IRole) => {
        return AnnouncementRoleFactoryFromJS(item);
    }) : [];

    return AnnouncementFactory({
        attachments: List(announcement.attachments),
        canDelete: announcement.canDelete,
        canEdit: announcement.canEdit,
        createdAt: announcement.createdAt,
        startDate: announcement.startDate,
        endDate: announcement.endDate,
        id: announcement.id,
        isDeleted: announcement.isDeleted,
        isViewed: announcement.isViewed,
        message: Map(announcement.message),
        secretKey: announcement.secretKey,
        sender: announcement.sender,
        showInStartup: announcement.showInStartup,
        status: announcement.status,
        subject: Map(announcement.subject),
        sendEmail: announcement.sendEmail,
        neverExpire: announcement.neverExpire,
        visibleFor: List(_visibleFor),
        createdBy: UserInfoFactoryFromJS(announcement.createdBy),
        createDelegatedBy: UserInfoFactoryFromJS(announcement.createDelegatedBy),
        deletedUserBy: UserInfoFactoryFromJS(announcement.deletedUserBy),
        deleteDelegatedBy: UserInfoFactoryFromJS(announcement.deleteDelegatedBy),
        modifiedUserBy: UserInfoFactoryFromJS(announcement.modifiedUserBy),
        modifyDelegatedBy: UserInfoFactoryFromJS(announcement.modifyDelegatedBy)
    });
}


