import Pagination from 'antd/lib/pagination';
import * as React from "react";
import * as Styles from './assets/style.scss';
import { IPagination } from './entities';

export default class BCAntPagination extends React.Component<IPagination, {}> {

    constructor(props: IPagination) {
        super(props);
        Styles;
    }

    render() {
        return (
            <Pagination {...this.props} />
        );
    }

}

export { IPagination };
