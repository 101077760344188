/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */

import { isNullOrUndefined } from "..";

const _transform = require("lodash/transform");
const _isEqual = require("lodash/isEqual");
const _isObject = require("lodash/isObject");
const _isArray = require("lodash/isArray");

export default function difference(object: any, base: any) {

    const objectEmpty = isNullOrUndefined(object);
    const baseEmpty = isNullOrUndefined(base);

    if (objectEmpty && !baseEmpty) return base;
    if (baseEmpty && !objectEmpty) return object;

    const changes = (o: any, b: any) => {
        return _transform(o, (result: any, value: any, key: any) => {
            if (b && !_isEqual(value, b[key])) {

                if (_isArray(value)) {
                    result[key] = o[key]
                } else {
                    result[key] = (_isObject(value) && _isObject(b[key])) ? changes(value, b[key]) : value;
                }

            }
        });
    }

    return changes(object, base);

}