import { ViewerType } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";

interface IBaseViewerConfigItem {
    name: string,
    parameters: any,
    viewerType: ViewerType
}

export interface IViewerConfigItem extends IBaseViewerConfigItem {
    mimeTypes: Array<string>
}

export interface IReadonlyViewerConfigItem extends IBaseViewerConfigItem {
    mimeTypes: List<string>
}

export type ViewerConfigItem = ReadonlyRecord<IReadonlyViewerConfigItem>;

export const ViewerConfigItemFactory = Record<IReadonlyViewerConfigItem>({
    name: null,
    parameters: null,
    viewerType: ViewerType.TextViewer,
    mimeTypes: List()
});

export const ViewerConfigItemFactoryFromJS = function (data: IViewerConfigItem) {
    return ViewerConfigItemFactory({
        ...data,
        mimeTypes: List(data.mimeTypes)
    });
}