import {
    createSyncAction,
    IBaseAction
} from "@bimser/core";
import * as ActionTypes from './actionTypes';

export default function closeModalTransactionAction(key: string): IBaseAction<any> {
    let action = createSyncAction(ActionTypes.CLOSE_MODAL_TRANSACTION, key);

    return action;
}