import { IDictionary } from "@bimser/core";
import { IPanelMenusState, PanelMenusState, PanelMenusStateFactory, PanelMenusStateFactoryFromJS } from "common/components/panelMenu/entities/IPanelMenusState";
import { Map, Record } from "immutable";
import { DataSecurityState, DataSecurityStateFactory, DataSecurityStateFactoryFromJS, IDataSecurityState } from "modules/dataSecurity/entities/IDataSecurityState";
import { ITaskManagementState, TaskManagementState, TaskManagementStateFactory, TaskManagementStateFactoryFromJS } from "modules/taskManagement/entites/ITaskManagementState";
import { ActionManagementState, ActionManagementStateFactoryFromJS, IActionManagementState } from "../../modules/action/actionManagement/entities/IActionManagementState";
import { ActionMenuState, ActionMenuStateFactoryFromJS, IActionMenuState } from "../../modules/action/actionMenu/entities/IActionMenuState";
import { ActionViewerState, ActionViewerStateFactoryFromJS, IActionViewerState } from "../../modules/action/actionViewer/entities/IActionViewerState";
import { ActivitiesState, ActivitiesStateFactory, ActivitiesStateFactoryFromJS, IActivitiesState } from "../../modules/activities/entities/IActivitiesState";
import { AnnouncementState, AnnouncementStateFactory, AnnouncementStateFactoryFromJS, IAnnouncementState } from "../../modules/announcements/entities/models/IAnnouncementState";
import { ConfigurationManagerState, ConfigurationManagerStateFactory, ConfigurationManagerStateFactoryFromJS, IConfigurationManagerState } from "../../modules/configurationManager/entities/IConfigurationManagerState";
import { DashboardState, DashboardStateFactory, DashboardStateFactoryFromJS, IDashboardState } from "../../modules/dashboard/entities/DashboardState";
import { DelegationState, DelegationStateFactory, DelegationStateFactoryFromJS, IDelegationState } from "../../modules/delegations/entities/IDelegationState";
import { DmMainState, DmMainStateFactory, DmMainStateFactoryFromJS, IDmMainState } from '../../modules/dm/entities';
import { HrMainState, HrMainStateFactory, HrMainStateFactoryFromJS, IHrMainState } from "../../modules/hr/entities";
import { HumanResourceMainState, HumanResourceMainStateFactory, HumanResourceMainStateFactoryFromJS, IHumanResourceMainState } from "../../modules/humanResource/entities/IHumanResourceMainState";
import { IIndexReportState, IndexReportState, IndexReportStateFactory, IndexReportStateFactoryFromJS } from "../../modules/indexReport/entities/IndexReportState";
import { ILegacyFrameState, LegacyFrameState, LegacyFrameStateFactory, LegacyFrameStateFactoryFromJS } from "../../modules/legacyFrame/entities";
import { ILogviewerState, LogviewerState, LogviewerStateFactory, LogviewerStateFactoryFromJS } from "../../modules/logviewer/entities/ILogviewerState";
import { ISearchState, SearchState, SearchStateFactory, SearchStateFactoryFromJS } from "../../modules/search/entities/ISearchState";
import { ISecurityPanelContent, SecurityPanelContent, SecurityPanelContentFactory, SecurityPanelContentFactoryFromJS } from "../../modules/security/entities/SecurityPanelContent";
import { IStartupState, StartupState, StartupStateFactory, StartupStateFactoryFromJS } from "../../modules/startup/entities/IStartupState";
import { IWorkflowState, WorkflowState, WorkflowStateFactory, WorkflowStateFactoryFromJS } from "../../modules/workflow/entities/IWorkflowState";
import { AppFormPanelContent, AppFormPanelContentFactoryFromJS, IAppFormPanelContent } from "../components/appForm/entities/IAppFormPanelContent";

export interface IModulesState {
    hr: IHrMainState,
    announcements: IAnnouncementState,
    delegations: IDelegationState
    workflow: IWorkflowState,
    startup: IStartupState,
    LegacyFrame: ILegacyFrameState,
    menus: IPanelMenusState,
    dm: IDmMainState,
    configurationManager: IConfigurationManagerState,
    logviewer: ILogviewerState,
    CSPFormPanels: IDictionary<IAppFormPanelContent>,
    security: ISecurityPanelContent,
    search: ISearchState,
    humanResource: IHumanResourceMainState,
    indexReport: IIndexReportState,
    activities: IActivitiesState,
    taskManagement: ITaskManagementState,
    dataSecurity: IDataSecurityState,
    actionManagement: IDictionary<IActionManagementState>,
    actionMenu: IDictionary<IActionMenuState>,
    actionViewer: IDictionary<IActionViewerState>
    dashboard: IDashboardState
}

export interface IReadonlyModulesState {
    hr: HrMainState,
    announcements: AnnouncementState,
    delegations: DelegationState,
    workflow: WorkflowState,
    startup: StartupState,
    LegacyFrame: LegacyFrameState,
    menus: PanelMenusState,
    dm: DmMainState,
    configurationManager: ConfigurationManagerState,
    logviewer: LogviewerState,
    CSPFormPanels: Map<string, AppFormPanelContent>,
    security: SecurityPanelContent,
    search: SearchState,
    humanResource: HumanResourceMainState,
    indexReport: IndexReportState,
    activities: ActivitiesState,
    taskManagement: TaskManagementState,
    actionManagement: Map<string, ActionManagementState>,
    actionMenu: Map<string, ActionMenuState>,
    actionViewer: Map<string, ActionViewerState>,
    dataSecurity: DataSecurityState,
    dashboard: DashboardState
}

export type ModulesState = Record<IReadonlyModulesState> & Readonly<IReadonlyModulesState>;

export const ModulesStateFactory = Record<IReadonlyModulesState>({
    hr: HrMainStateFactory(),
    announcements: AnnouncementStateFactory(),
    delegations: DelegationStateFactory(),
    workflow: WorkflowStateFactory(),
    startup: StartupStateFactory(),
    LegacyFrame: LegacyFrameStateFactory(),
    menus: PanelMenusStateFactory(),
    dm: DmMainStateFactory(),
    configurationManager: ConfigurationManagerStateFactory(),
    logviewer: LogviewerStateFactory(),
    CSPFormPanels: Map<string, AppFormPanelContent>(),
    security: SecurityPanelContentFactory(),
    search: SearchStateFactory(),
    humanResource: HumanResourceMainStateFactory(),
    indexReport: IndexReportStateFactory(),
    activities: ActivitiesStateFactory(),
    taskManagement: TaskManagementStateFactory(),
    dataSecurity: DataSecurityStateFactory(),
    actionManagement: Map<string, ActionManagementState>(),
    actionMenu: Map<string, ActionMenuState>(),
    actionViewer: Map<string, ActionViewerState>(),
    dashboard: DashboardStateFactory()
});

export const ModulesStateFactoryFromJS = function (data: IModulesState) {
    let _CSPFormPanels: IDictionary<AppFormPanelContent> = Object.keys(data.CSPFormPanels).reduce(function (previous: IDictionary<AppFormPanelContent>, current) {
        previous[current] = AppFormPanelContentFactoryFromJS(data.CSPFormPanels[current]);
        return previous;
    }, {});

    let _actionManagement: IDictionary<ActionManagementState> = Object.keys(data.actionManagement).reduce(function (previous: IDictionary<ActionManagementState>, current) {
        previous[current] = ActionManagementStateFactoryFromJS(data.actionManagement[current]);
        return previous;
    }, {});

    let _actionMenu: IDictionary<ActionMenuState> = Object.keys(data.actionMenu).reduce(function (previous: IDictionary<ActionMenuState>, current) {
        previous[current] = ActionMenuStateFactoryFromJS(data.actionMenu[current]);
        return previous;
    }, {});

    let _actionViewer: IDictionary<ActionViewerState> = Object.keys(data.actionViewer).reduce(function (previous: IDictionary<ActionViewerState>, current) {
        previous[current] = ActionViewerStateFactoryFromJS(data.actionViewer[current]);
        return previous;
    }, {});

    return ModulesStateFactory({
        hr: HrMainStateFactoryFromJS(data.hr),
        LegacyFrame: LegacyFrameStateFactoryFromJS(data.LegacyFrame),
        announcements: AnnouncementStateFactoryFromJS(data.announcements),
        delegations: DelegationStateFactoryFromJS(data.delegations),
        startup: StartupStateFactoryFromJS(data.startup),
        workflow: WorkflowStateFactoryFromJS(data.workflow),
        menus: PanelMenusStateFactoryFromJS(data.menus),
        dm: DmMainStateFactoryFromJS(data.dm),
        configurationManager: ConfigurationManagerStateFactoryFromJS(data.configurationManager),
        logviewer: LogviewerStateFactoryFromJS(data.logviewer),
        CSPFormPanels: Map(_CSPFormPanels),
        security: SecurityPanelContentFactoryFromJS(data.security),
        search: SearchStateFactoryFromJS(data.search),
        humanResource: HumanResourceMainStateFactoryFromJS(data.humanResource),
        indexReport: IndexReportStateFactoryFromJS(data.indexReport),
        activities: ActivitiesStateFactoryFromJS(data.activities),
        taskManagement: TaskManagementStateFactoryFromJS(data.taskManagement),
        dataSecurity: DataSecurityStateFactoryFromJS(data.dataSecurity),
        actionManagement: Map(_actionManagement),
        actionMenu: Map(_actionMenu),
        actionViewer: Map(_actionViewer),
        dashboard: DashboardStateFactoryFromJS(data.dashboard)
    });
}
