import { connect } from "react-redux";
import LeftMenuEBA6Integration from "../components/LeftMenuEBA6Integration";
import { WebInterfaceBaseState } from "common/base/state";
import { getEBA6IntegrationLoading, getEBA6IntegrationUrl, getEBA6IntegrationHeader } from '../selectors/menudesignerSelector';
import { eBA6IntegrationRejectTransaction, eBA6IntegrationResolveTransaction, setEBA6IntegrationLoadingAction } from '../actions';
import ILeftMenuEBA6IntegrationProps from '../entities/ILeftMenuEBA6IntegrationProps';

const mapStateToProps = function (state: WebInterfaceBaseState, props: ILeftMenuEBA6IntegrationProps): ILeftMenuEBA6IntegrationProps {
    return {
        id: props.id,
        loading: getEBA6IntegrationLoading(state),
        url: getEBA6IntegrationUrl(state),
        header: getEBA6IntegrationHeader(state),
    };
}

const mapDispatchToProps = function (dispatch: Function): ILeftMenuEBA6IntegrationProps {
    return {
        onCancelClick: (payload: any) => {
            dispatch(eBA6IntegrationRejectTransaction(payload))
        },
        onOkClick: (payload: any) => {
            dispatch(eBA6IntegrationResolveTransaction(payload))
        },
        setEBA6IntegrationLoading: (status: boolean) => {
            dispatch(setEBA6IntegrationLoadingAction(status))
        }
    };
}

const LeftMenuEBA6IntegrationContainer = connect(mapStateToProps, mapDispatchToProps)(LeftMenuEBA6Integration);
export default LeftMenuEBA6IntegrationContainer;