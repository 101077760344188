import { Record, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import {
    IPropertyTypeFormContent, PropertyTypeFormContent, PropertyTypeFormContentFactoryFromJS, IPropertiesFormContent,
    PropertiesFormContent, PropertiesFormContentFactoryFromJS, IUserPropertiesFormContent, UserPropertiesFormContent, UserPropertiesFormContentFactoryFromJS, IDepartmentPropertiesFormContent, IPositionPropertiesFormContent, IProfessionPropertiesFormContent, IUserGroupPropertiesFormContent, DepartmentPropertiesFormContent, PositionPropertiesFormContent, ProfessionPropertiesFormContent, UserGroupPropertiesFormContent, DepartmentPropertiesFormContentFactoryFromJS, PositionPropertiesFormContentFactoryFromJS, ProfessionPropertiesFormContentFactoryFromJS, UserGroupPropertiesFormContentFactoryFromJS
} from ".";

export interface IPropertyDefinitionsFormContentList {
    propertyTypeForms: IDictionary<IPropertyTypeFormContent>,
    propertiesForms: IDictionary<IPropertiesFormContent>,
    userPropertiesForms: IDictionary<IUserPropertiesFormContent>,
    departmentPropertiesForms: IDictionary<IDepartmentPropertiesFormContent>,
    positionPropertiesForms: IDictionary<IPositionPropertiesFormContent>,
    professionPropertiesForms: IDictionary<IProfessionPropertiesFormContent>,
    userGroupPropertiesForms: IDictionary<IUserGroupPropertiesFormContent>
}

export interface IReadonlyPropertyDefinitionsFormContentList {
    propertyTypeForms: Map<string, PropertyTypeFormContent>,
    propertiesForms: Map<string,PropertiesFormContent>
    userPropertiesForms: Map<string, UserPropertiesFormContent>,
    departmentPropertiesForms: Map<string, DepartmentPropertiesFormContent>,
    positionPropertiesForms: Map<string, PositionPropertiesFormContent>,
    professionPropertiesForms: Map<string, ProfessionPropertiesFormContent>,
    userGroupPropertiesForms: Map<string, UserGroupPropertiesFormContent>
}

export type PropertyDefinitionsFormContentList = ReadonlyRecord<IReadonlyPropertyDefinitionsFormContentList>;

export const PropertyDefinitionsFormContentListFactory = Record<IReadonlyPropertyDefinitionsFormContentList>({
    propertyTypeForms: Map<string, PropertyTypeFormContent>(),
    propertiesForms: Map<string, PropertiesFormContent>(),
    userPropertiesForms: Map<string, UserPropertiesFormContent>(), 
    departmentPropertiesForms: Map<string, DepartmentPropertiesFormContent>(),
    positionPropertiesForms: Map<string, PositionPropertiesFormContent>(),
    professionPropertiesForms: Map<string, ProfessionPropertiesFormContent>(),
    userGroupPropertiesForms: Map<string, UserGroupPropertiesFormContent>()
});

export const PropertyDefinitionsFormContentListFactoryFromJS = function (data: IPropertyDefinitionsFormContentList) {
    let _propertyTypeForms: IDictionary<PropertyTypeFormContent> = Object.keys(data.propertyTypeForms).reduce(function (previous: IDictionary<PropertyTypeFormContent>, current) {
        previous[current] = PropertyTypeFormContentFactoryFromJS(data.propertyTypeForms[current]);
        return previous;
    }, {});

    let _propertiesForms: IDictionary<PropertiesFormContent> = Object.keys(data.propertiesForms).reduce(function (previous: IDictionary<PropertiesFormContent>, current) {
        previous[current] = PropertiesFormContentFactoryFromJS(data.propertiesForms[current]);
        return previous;
    }, {});

    let _userPropertiesForms: IDictionary<UserPropertiesFormContent> = Object.keys(data.userPropertiesForms).reduce(function (previous: IDictionary<UserPropertiesFormContent>, current) {
        previous[current] = UserPropertiesFormContentFactoryFromJS(data.userPropertiesForms[current]);
        return previous;
    }, {});

    let _departmentPropertiesForms: IDictionary<DepartmentPropertiesFormContent> = Object.keys(data.departmentPropertiesForms).reduce(function (previous: IDictionary<DepartmentPropertiesFormContent>, current) {
        previous[current] = DepartmentPropertiesFormContentFactoryFromJS(data.departmentPropertiesForms[current]);
        return previous;
    }, {});

    let _positionPropertiesForms: IDictionary<PositionPropertiesFormContent> = Object.keys(data.positionPropertiesForms).reduce(function (previous: IDictionary<PositionPropertiesFormContent>, current) {
        previous[current] = PositionPropertiesFormContentFactoryFromJS(data.positionPropertiesForms[current]);
        return previous;
    }, {});

    let _professionPropertiesForms: IDictionary<ProfessionPropertiesFormContent> = Object.keys(data.professionPropertiesForms).reduce(function (previous: IDictionary<ProfessionPropertiesFormContent>, current) {
        previous[current] = ProfessionPropertiesFormContentFactoryFromJS(data.professionPropertiesForms[current]);
        return previous;
    }, {});

    let _userGroupPropertiesForms: IDictionary<UserGroupPropertiesFormContent> = Object.keys(data.userGroupPropertiesForms).reduce(function (previous: IDictionary<UserGroupPropertiesFormContent>, current) {
        previous[current] = UserGroupPropertiesFormContentFactoryFromJS(data.userGroupPropertiesForms[current]);
        return previous;
    }, {});

    return PropertyDefinitionsFormContentListFactory({
        propertyTypeForms: Map(_propertyTypeForms),
        propertiesForms: Map(_propertiesForms),
        userPropertiesForms: Map(_userPropertiesForms),
        departmentPropertiesForms: Map(_departmentPropertiesForms),
        positionPropertiesForms: Map(_positionPropertiesForms),
        professionPropertiesForms: Map(_professionPropertiesForms),
        userGroupPropertiesForms: Map(_userGroupPropertiesForms)
    });
}