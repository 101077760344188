export const SET_MENU_CONFIG = "HUMAN_RESOURCE.SET_MENU_CONFIG";
export const SET_MENU_STATUS = "HUMAN_RESOURCE.SET_MENU_STATUS";
export const CHANGE_MENU_STATUS = "HUMAN_RESOURCE.CHANGE_MENU_STATUS";

export const ON_CHANGE_SELECTED_MENU_ITEM = "HUMAN_RESOURCE.ON_CHANGE_SELECTED_MENU_ITEM";
export const ON_CHANGE_SELECTED_MENU_ITEM_ACTION = "HUMAN_RESOURCE.ON_CHANGE_SELECTED_MENU_ITEM_ACTION";

export const SET_MODULE_LOADING = "HUMAN_RESOURCE.SET_MODULE_LOADING";
export const OPEN_HR_FORM = "HUMAN_RESOURCE.OPEN_HR_FORM";
export const CLOSE_HR_FORM = "HUMAN_RESOURCE.CLOSE_HR_FORM";

export const ON_SAVE_FORM = "HUMAN_RESOURCE.ON_SAVE_FORM";
export const SET_HOLDINGS = "HUMAN_RESOURCE.SET_HOLDINGS";
export const SET_HOLDING = "HUMAN_RESOURCE.SET_HOLDING";
export const SET_COMPANIES = "HUMAN_RESOURCE.SET_COMPANIES";
export const SET_COMPANY = "HUMAN_RESOURCE.SET_COMPANY";
export const SET_PLANTS = "HUMAN_RESOURCE.SET_PLANTS";
export const SET_PLANT = "HUMAN_RESOURCE.SET_PLANT";
export const SET_ORGANIZATIONS = "HUMAN_RESOURCE.SET_ORGANIZATIONS";
export const SET_ORGANIZATION = "HUMAN_RESOURCE.SET_ORGANIZATION";

export const ON_SEARCH_PLANTS_COMBOBOX = "HUMAN_RESOURCE.ON_SEARCH_PLANTS_COMBOBOX";
export const ON_SEARCH_ORGANIZATONS_COMBOBOX = "HUMAN_RESOURCE.ON_SEARCH_ORGANIZATONS_COMBOBOX";
