import * as React from "react";
import { IModalFrameManagerProps, IModalFrameManagerCProps } from '../entities';
import { BCModalFrame } from "@bimser/components";
import { isNullOrUndefined } from "@bimser/core";

export default class BCModalFrameManager extends React.Component<IModalFrameManagerProps & IModalFrameManagerCProps, {  }> {

    constructor(props: IModalFrameManagerProps) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleCancel() {
        this.props.closeModal();
    }

    render() {
        if (isNullOrUndefined(this.props.url)) {
            return null;
        }
        return (
            <div>
                <BCModalFrame handleCancel={this.handleCancel} url={this.props.url} visible={true} width={"100%"}></BCModalFrame>
            </div>
        );
    }
}