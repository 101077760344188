import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Styles from "../assets/style.scss";
import DigitalSignatureContainer from '../components/DigitalSignature/containers';
import { ExtremeGridAPI } from "../components/ExtremeGrid/api";
import MobileSignatureContainer from "../components/MobileSignature/containers";
import IComponentList from "../components/ModalManager/entities/IComponentList";
import { ConfirmationBoxContainer } from "../components/ModalManager/modals/ConfirmationBox/containers";
import MessageBoxContainer from "../components/ModalManager/modals/MessageBox/containers";
import UserSelectModalContainer from "../components/ModalManager/modals/UserSelectModal/containers";
import BCProvider from "../components/Provider/Provider";
import { DmViewerAPI } from "../components/dmViewer/api";
import { ISynergyLogonApp } from '../entities/synergyLogonApp';
import { MessageBoxManager } from "./messageBoxManager";

export default function (args: IInitializeArgs) {
    let container = document.createElement("main");
    container.className = Styles.container;
    container.setAttribute("style", `background-image: var(--theme-image); background-color: var(--theme-color);`)

    let body = document.getElementsByTagName("body")[0];
    let themeName = localStorage.getItem('themeName');

    if (!themeName) {
        localStorage.setItem('themeName', 'themeLight')
    }
    body.className = localStorage.getItem('themeName');
    body.appendChild(container);

    if (args.consoleUpdateLogEnable && process.env.NODE_ENV !== 'production') {
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React, {
            // trackAllPureComponents: true,
            // exclude: [
            //     /^IconBase/, /^KeytipData/, /^BaseButton/, /^CommandBarButton/, /^CustomizedCommandBarButton/,
            //     /^OverflowSetBase/, /^Connect(MenuItem)/, /^MenuItem/, /^Tooltip/, /^MenuItem/,
            //     /^Connect(SubPopupMenu)/, /^SubPopupMenu/, /^AnimateChild/, /^FlexPanel/, /^Divider/, /^LocaleReceiver/, /^SaveRef/,
            //     /^Connect(SubMenu)/, /^SubMenu/, /^Popover/, /^Animate/, /^TemplateConnectorBase/
            // ]
        });
    }

    MessageBoxManager(args.store);
    ExtremeGridAPI.store = args.store;
    DmViewerAPI.store = args.store;

    ReactDOM.render(
        <BCProvider store={args.store} modalComponents={getModalComponents(args)} SynergyLogonApp={args.SynergyLogonApp} >
            {args.children}
        </BCProvider>,
        container
    );
}

function getModalComponents(args: IInitializeArgs) {
    if (!args.modalComponents) {
        args.modalComponents = {};
    }
    args.modalComponents["MESSAGEBOX"] = MessageBoxContainer as any;
    args.modalComponents["ConfirmationBoxModal"] = ConfirmationBoxContainer;
    args.modalComponents["UserSelectModal"] = UserSelectModalContainer;
    args.modalComponents["DigitalSign"] = DigitalSignatureContainer;
    args.modalComponents["MobileSign"] = MobileSignatureContainer;

    return args.modalComponents;
}

export interface IInitializeArgs {
    store: any,
    children: React.ReactNode,
    modalComponents?: IComponentList
    consoleUpdateLogEnable: boolean,
    SynergyLogonApp: ISynergyLogonApp,
}