import * as React from "react";
import * as styles from './assets/style.scss';
import { IRowProps } from './entities';
import Row from 'antd/lib/row';

var classNames = require('classnames/bind');
let cx = classNames.bind(styles);

export default class BCRow extends React.Component<IRowProps, {}> {

    constructor(props: IRowProps) {
        super(props);
    }

    render() {

        return (
            <Row className={[classNames, this.props.className].join(' ')} style={{ ...this.props.customStyles }} >{this.props.children}</Row>
        );
    }

}

export { IRowProps };
