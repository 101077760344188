import { IDictionary, isNullOrUndefined, moveArray, toLowerCase } from "@bimser/core";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import * as Hash from 'object-hash';
import * as React from "react";
import BCListEditor from ".";
import { IComboboxLoadingArgs } from '../BCCombobox';
import { IInputChangeEventArgs } from "../BCInput";
import { findMessage } from "../BCIntl";
import BCNotification from "../BCNotification";
import { IPropertyItem, ITabItem } from "../BCPropertyInspector";
import { IListEditorItem, IStatefulListEditorProps, IStatefulListEditorState } from "./entities";

/**List editor tiplerinde kullanılan ortak yöntemleri içerir.
 * Extend ettiğiniz componentlerin didMount olayında  yada ilgili işler sonrasına,
 * state { items,leftRibbonItems, rightRibbonItems, showLoading} parametrelerini belirleyiniz.
 * */
export default class StateFulListEditor<P, S> extends React.Component<IStatefulListEditorProps & P, IStatefulListEditorState & S> {
    constructor(props: IStatefulListEditorProps & P) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.moveItem = this.moveItem.bind(this);
        this.onOkClick = this.onOkClick.bind(this);
        this.onChangeData = this.onChangeData.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.openCollection = this.openCollection.bind(this);
        this.getOptionsPromise = this.getOptionsPromise.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.findRecursiveDuplicate = this.findRecursiveDuplicate.bind(this);
        this.getDuplicateItem = this.getDuplicateItem.bind(this);
        this.checkIfItemsPropertyNullOrUndefined = this.checkIfItemsPropertyNullOrUndefined.bind(this);

        this.state = {
            items: [],
            searchValue: "",
            selectedIndex: -1,
            showLoading: true,
            leftRibbonItems: [],
            rightRibbonItems: [],
            searchable: false
        } as IStatefulListEditorState & S;
    }
    /** Varsayılanda undefined olan bu metodu özel text gösterimleri için kullanabilirsiniz */
    renderCustomListItemText?: (item: IListEditorItem) => any;
    /** Bu metodu ovveri edip datanıza uygun item template  yazınız */
    generateItems(): Array<IListEditorItem> {
        return [];
    }

    /**Bu metodu ovveride edip kullanmak istediğiniz veri yapısını belirleyiniz*/
    generateStructure(): Array<ITabItem> {
        return [];
    }
    /**Kendi koleksiyon tiplerinizi  yakalamak için ovveride ediniz */
    openCollection(item: IPropertyItem, data: IDictionary<any>, actionType?: string, collectionName?: string) {

    }
    /**Bu metodu ovveride ederek kendi validasyonunuzu yazabilirsiniz.
    Resolve ettiğiniz mesaj boş ise validasyon başarılı sayılır
    */
    validation(): Promise<string> {
        return new Promise<string>((resolve, rejet) => { resolve("") });
    }
    /**Ovveride edilebilmesi için bırakıldı. */
    showDeleteConfirm() {
        return new Promise<boolean>((resolve, reject) => {
            resolve(true);
        });
    }
    /**getOptionsPromise kullanmak için ovveride ediniz */
    getOptionsPromise(item: IPropertyItem): Promise<any> {
        return new Promise<IComboboxLoadingArgs>((resolve, reject) => {
            resolve({ allow: true, options: [] });
        });
    }
    /**onRefresh kullanmak için ovveride ediniz */
    onRefresh(item?: any) {
        return new Promise<IComboboxLoadingArgs>((resolve, reject) => {
            resolve({ allow: true, options: [] });
        })
    }

    getFilteredItems() {
        return this.state.searchValue ? this.generateItems().filter((i) => i.text && toLowerCase(i.text).includes(toLowerCase(this.state.searchValue))) : this.generateItems();
    }

    onDelete(item: IListEditorItem) {
        this.showDeleteConfirm().then((result) => {
            if (result) {
                this.setState({
                    items: this.state.items.filter((i) => i.id != item.id).map((i, index) => { return { ...i, id: index.toString() } }),
                    selectedIndex: -1,
                } as IStatefulListEditorState & S, this.onAfterDelete);
            }
        });
    }

    /**onAfterDelete kullanmak için ovveride ediniz */
    onAfterDelete() { }

    onSearch(args: IInputChangeEventArgs) {
        this.setState({ searchValue: args.data } as IStatefulListEditorState & S);
    }

    onSelectionChange(item: IListEditorItem) {
        this.setState({
            selectedIndex: Number(item.id),
        } as IStatefulListEditorState & S);
    }

    moveItem(dragIndex: number, hoverIndex: number) {
        this.setState({
            items: moveArray(this.state.items, dragIndex, hoverIndex).map((i, index) => { return { ...i, id: index.toString() } }),
            selectedIndex: hoverIndex,
        } as IStatefulListEditorState & S);
    }

    _setIn(path: string, sourceObj: any, setObj: any) {
        let schema = setObj;  // a moving reference to internal objects within obj
        let value = sourceObj;
        let pList = path.split('.');
        let len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            let elem = pList[i];
            value = value[elem];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value[pList[len - 1]];
    }
    getChangedData(item: any, newData: any) {
        return {
            items: this.state.items.map((i, index) => {
                if (index === this.state.selectedIndex) {
                    this._setIn(item.key, newData, i.externalData);
                }
                return i;
            })
        } as IStatefulListEditorState & S;
    }
    onChangeData(item: any, newData: any) {
        this.setState(this.getChangedData(item, newData))
    }

    onOkClick() {
        this.validation().then((message) => {
            if (!message) {
                if (this.props.onSucces) {
                    this.props.onSucces(this.state.items.map((i) => i.externalData));
                    this.onCancelClick();
                }
            }
            else {
                BCNotification.warning({
                    duration: 4,
                    description: message,
                    message: findMessage.get("100652") + " !",
                    placement: "topRight",
                })
            }
        });
    }

    findRecursiveDuplicate(items: any[], item: any, propertyName?: string): any {
        for (let index = 0; index < items?.length; index++) {
            if (items[index][propertyName] === item[propertyName]) {
                return item;
            }
            else if (items[index].children?.length) {
                let result = this.findRecursiveDuplicate(items[index].children, item, propertyName);
                if (result) {
                    return result;
                }
            }
        }
    }
    /**Tree data içerisinde duplicate itemı bulabilmek için yazılmış helper
     * @allData mevcut tree kırılımmı dışındaki parent datalar
     * @propertyName aranacak property adı
     */
    getDuplicateItem(allData?: Array<any>, propertyName?: string) {
        if (allData) {
            let allDataDuplicate = this.state.items.find((x) => {
                return !this.props.startupData.find((i) => i[propertyName] == x.externalData[propertyName]) && this.findRecursiveDuplicate(allData.map((x) => x.externalData), x.externalData, propertyName);
            });
            if (allDataDuplicate)
                return allDataDuplicate;
        }
        for (let index = 0; index < this.state.items.length; index++) {
            let duplicateItem = this.state.items.find((x, i) => x.externalData[propertyName] === this.state.items[index].externalData[propertyName] && i != index)
            if (duplicateItem)
                return duplicateItem;

            let childDuplicate = this.state.items.find((x) => {
                return this.state.items.find((c) => this.findRecursiveDuplicate(c.externalData?.children, x.externalData, propertyName))
            });
            if (childDuplicate)
                return childDuplicate;
        }
    }

    /** items içinde belirli boş bırakılmış propertyi bulmak için yazılmış helper 
         * @items items
         * @propertyName aranacak property adı
         * @childrenPath example: args.children | children vb.
         */
    checkIfItemsPropertyNullOrUndefined(items?: Array<IListEditorItem>, propertyName?: string, childrensPath = "children") {
        if (!items) {
            return true;
        }
        return items.find((item) => {
            const externalData = item.externalData;
            if (!isNullOrUndefined(externalData) && !externalData[propertyName]) {
                return true;
            }
            if (isNullOrUndefined(externalData) && _isEmpty(item[propertyName])) {
                return true;
            }
            const childrens = _get(item.externalData, childrensPath);
            if (childrens?.length) {
                return this.checkIfItemsPropertyNullOrUndefined(childrens, propertyName, childrensPath);
            }
        });
    }

    getCurrentItemData(): any {
        let currentSelection = this.state.items.find((i) => i.id === this.state.selectedIndex.toString());
        return currentSelection ? { ...currentSelection.externalData } : {};
    }

    onCancelClick() {
        if (this.props.onCancelClick) {
            this.props.onCancelClick();
        }
    }

    render() {
        return (<BCListEditor
            sortable={true}
            useDefaultButtons={true}
            onSearch={this.onSearch}
            showLoading={this.state.showLoading}
            items={this.getFilteredItems()}
            structure={this.generateStructure()}
            onDelete={this.onDelete}
            onSelect={this.onSelectionChange}
            onOkClick={this.onOkClick}
            moveItem={this.moveItem}
            onChangeData={this.onChangeData}
            data={this.getCurrentItemData()}
            leftRibbonItems={this.state.leftRibbonItems}
            rightRibbonItems={this.state.rightRibbonItems}
            openCollection={this.openCollection}
            onCancelClick={this.onCancelClick}
            getOptionsPromise={this.getOptionsPromise}
            onRefresh={this.onRefresh}
            searchable={this.state.searchable}
            renderCustomListItemText={this.renderCustomListItemText}
            id={Hash(this.state.selectedIndex.toString())}
            {...this.props}
        />);
    }
}

export { IStatefulListEditorProps, IStatefulListEditorState };

