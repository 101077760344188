import { BCButton, BCCollapse, BCErrorView, BCModal, BCScrollbars, findMessage } from "@bimser/components";
import { ICollapseItem } from '@bimser/components/src/BCCollapse';
import { copyToClipboard, isNullOrUndefined } from "@bimser/core";
import IconExpandBlue from "@bimser/icons/16/tree-arrow-down-blue";
import IconExpand from "@bimser/icons/16/tree-arrow-right";
import IconBlocked from "@bimser/icons/64/blocked";
import IconConfirm from "@bimser/icons/64/confirm";
import IconError from "@bimser/icons/64/error";
import IconSuccess from "@bimser/icons/64/event-ok";
import IconInfo from "@bimser/icons/64/info";
import IconSevereWaring from "@bimser/icons/64/severe-warning";
import IconWarning from "@bimser/icons/64/warning";
import * as React from "react";
import { ErrorCodes } from '../../../../..';
import { MessageBoxButton, MessageBoxButtons, MessageBoxIcon } from "../../../enums";
import * as Style from '../assets/Style.scss';
import { IMessageBoxButton, IMessageBoxCProps, IMessageBoxProps, IMessageBoxResolvePayload, IMessageBoxState } from '../entities';
const SomethingWentWrongIcon = require("../assets/something-went-wrong.svg");

var classNames = require('classnames/bind');
let cx = classNames.bind(Style);

export default class MessageBox extends React.Component<IMessageBoxCProps & IMessageBoxProps, IMessageBoxState> {

    parentHandleCancel = () => { }
    constructor(props: IMessageBoxCProps & IMessageBoxProps) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.onChangeExpandedKeys = this.onChangeExpandedKeys.bind(this);
        this.getCollapseContent = this.getCollapseContent.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.pressOkConfirm = this.pressOkConfirm.bind(this);
        this.getFooterByData = this.getFooterByData.bind(this);
        this.getFooter = this.getFooter.bind(this);
        this.getContentByData = this.getContentByData.bind(this);
        this.collapseCustomHeader = this.collapseCustomHeader.bind(this);

        this.state = {
            expandedKeys: [],
            isErrorExist: false,
            isValidation: false,
            undefinedError: false,
        }
    }

    componentDidMount() {
        if (this.props.closeWhenEnterKey) {
            window.addEventListener('keydown', this.pressOkConfirm);
        }
        if (!this.props.error && !this.props.content && !this.props.title) {
            this.setState({ undefinedError: true });
        }
        if (!isNullOrUndefined(this.props.error) && !isNullOrUndefined(this.props.error.validationErrorInfos)) {
            this.setState({ isErrorExist: true, isValidation: true });
        }
        else if (!isNullOrUndefined(this.props.error) && isNullOrUndefined(this.props.error.validationErrorInfos)) {
            this.setState({ isErrorExist: true });
        }
    }

    componentWillUnmount() {
        if (this.props.closeWhenEnterKey) {
            window.removeEventListener('keydown', this.pressOkConfirm);
        }
    }

    onChangeExpandedKeys(expandedKeys: Array<string> | string) {
        this.setState({ expandedKeys });
    }

    swithIcon() {
        switch (this.props.type) {
            case MessageBoxIcon.Error: {
                if (this.state.isValidation) { // validation error ile gelebilir buna göre warning Iconu veriliyor.
                    return IconWarning;
                }
                return IconError;
            }
            case MessageBoxIcon.Warning: {
                return IconWarning;
            }
            case MessageBoxIcon.Info: {
                return IconInfo;
            }
            case MessageBoxIcon.Confirm: {
                return IconConfirm;
            }
            case MessageBoxIcon.Success: {
                return IconSuccess;
            }
            case MessageBoxIcon.SevereWarning: {
                return IconSevereWaring;
            }
            case MessageBoxIcon.Blocked: {
                return IconBlocked;
            }
            case MessageBoxIcon.None: {
                return React.Fragment;
            }
            default: {
                return React.Fragment;
            }
        }
    }

    getButtonList(): Array<IMessageBoxButton> {
        let buttons: Array<IMessageBoxButton> = [];

        if (!isNullOrUndefined(this.props.buttons)) {
            switch (this.props.buttons) {
                case MessageBoxButtons.OK: {
                    return [{
                        id: MessageBoxButton.OK,
                        text: findMessage.get("100004"),
                        type: 'primary'
                    }];
                }
                case MessageBoxButtons.OKCancel: {
                    return [
                        {
                            id: MessageBoxButton.OK,
                            text: findMessage.get("100004"),
                            type: 'primary'
                        },
                        {
                            id: MessageBoxButton.Cancel,
                            text: findMessage.get("100034"),
                            type: 'ghost'
                        }
                    ];
                }
                case MessageBoxButtons.AbortRetryIgnore: {
                    return [
                        {
                            id: MessageBoxButton.Abort,
                            text: findMessage.get("100904"),
                            type: 'ghost',
                            danger: true
                        },
                        {
                            id: MessageBoxButton.Retry,
                            text: findMessage.get("100905"),
                            type: 'ghost',
                            danger: true
                        },
                        {
                            id: MessageBoxButton.Ignore,
                            text: findMessage.get("100906"),
                            type: 'ghost',
                            danger: true
                        }
                    ];
                }
                case MessageBoxButtons.RetryCancel: {
                    return [
                        {
                            id: MessageBoxButton.Retry,
                            text: findMessage.get("100905"),
                            type: 'ghost'
                        },
                        {
                            id: MessageBoxButton.Cancel,
                            text: findMessage.get("100034"),
                            type: 'ghost'
                        }
                    ];
                }
                case MessageBoxButtons.YesNo: {
                    return [
                        {
                            id: MessageBoxButton.Yes,
                            text: findMessage.get("100587"),
                            type: 'primary'
                        },
                        {
                            id: MessageBoxButton.No,
                            text: findMessage.get("100588"),
                            type: 'ghost'
                        }
                    ];
                }
                case MessageBoxButtons.YesNoCancel: {
                    return [
                        {
                            id: MessageBoxButton.Yes,
                            text: findMessage.get("100587"),
                            type: 'primary'
                        },
                        {
                            id: MessageBoxButton.No,
                            text: findMessage.get("100588"),
                            type: 'ghost',
                            danger: true
                        },
                        {
                            id: MessageBoxButton.Cancel,
                            text: findMessage.get("100034"),
                            type: 'ghost'
                        }
                    ];
                }
                case MessageBoxButtons.ClearCancel: {
                    return [
                        {
                            id: MessageBoxButton.Clear,
                            text: findMessage.get("100913"),
                            type: 'ghost',
                            danger: true
                        },
                        {
                            id: MessageBoxButton.Cancel,
                            text: findMessage.get("100034"),
                            type: 'ghost'
                        }
                    ];
                }
                default:
                    return this.props.buttons;
            }
        }
        else {
            return [
                {
                    id: MessageBoxButton.OK,
                    text: findMessage.get("100004"),
                    type: 'primary'
                },
                {
                    id: MessageBoxButton.Cancel,
                    text: findMessage.get("100034"),
                    type: 'ghost'
                }
            ];
        }

        return buttons;
    }

    generateFooter(): any {
        if (this.props.buttons === null) {
            return null;
        }
        let buttons = this.getButtonList().map((button) => {
            return <BCButton
                key={button.id}
                text={button.text}
                icon={button.icon}
                type={button.type}
                danger={button.danger}
                onClick={() => {
                    this.handleOk(button);
                }} />;
        });

        return (
            <React.Fragment>
                {buttons}
            </React.Fragment>
        );
    }

    handleCancel() {
        let payload: IMessageBoxResolvePayload = {
            id: this.props.id,
            button: {
                id: MessageBoxButton.CloseButton,
                text: MessageBoxButton.CloseButton
            },
            notClose: false
        };
        this.props.resolveAction(payload);
        if (!payload.notClose) {
            this.closeModal();
        }

        if (this.parentHandleCancel) {
            this.parentHandleCancel();
        }
    }

    handleOk(button: IMessageBoxButton) {
        if (button.callback) {
            button.callback();
        }
        this.props.resolveAction({
            id: this.props.id,
            button: {
                id: button.id,
                text: button.text
            }
        });
        if (!button.disableAutoClose) {
            this.closeModal();
        }
    }

    pressOkConfirm(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.handleOk({
                id: MessageBoxButton.Yes,
            });
        }
    }

    closeModal() {
        this.props.closeModal(this.props.id);
    }

    getMessageBoxDefaultButtons() {
        let _buttons: Array<IMessageBoxButton> = [
            {
                id: MessageBoxButton.OK,
                text: findMessage.get("100004"),
                type: 'primary'
            }
        ];
        let buttons = _buttons.map((button) => {
            return <BCButton
                key={button.id}
                text={button.text}
                icon={button.icon}
                type={button.type}
                onClick={() => {
                    this.handleOk(button);
                }} />;
        });

        return (
            <React.Fragment>
                {buttons}
            </React.Fragment>
        );
    }

    //Exceptionların herhangi bir uniq id' si olmadığı için bir şekilde tree Item keyleri ile match olmalı.(Sıralamaları)
    getExceptionData(exception: any, controlKey?: string, exceptionCounter: number = 0, getLastException: boolean = false): any {
        if (exception) {
            if (getLastException && isNullOrUndefined(controlKey) && !isNullOrUndefined(exception.InnerException)) {
                return this.getExceptionData(exception.InnerException, undefined, undefined, true);
            }
            else if (getLastException && isNullOrUndefined(controlKey) && !isNullOrUndefined(exception.ExternalException) && exception.ExternalException !== findMessage.get("100526")) {
                if (typeof exception.ExternalException !== 'object') {
                    return this.getExceptionData(JSON.parse(exception.ExternalException), undefined, undefined, true);
                }
                else {
                    return this.getExceptionData(exception.ExternalException, undefined, undefined, true);
                }
            }
            else if (getLastException && isNullOrUndefined(controlKey) && isNullOrUndefined(exception.InnerException) && (isNullOrUndefined(exception.ExternalException) || exception.ExternalException === findMessage.get("100526"))) {
                return exception;
            }
            else if (controlKey === exceptionCounter.toString()) {
                return exception;
            }
            else if (!isNullOrUndefined(exception.InnerException)) {
                return this.getExceptionData(exception.InnerException, controlKey, exceptionCounter + 1);
            }
            else if (!isNullOrUndefined(exception.ExternalException) && exception.ExternalException !== findMessage.get("100526")) {
                if (typeof exception.ExternalException !== 'object') {
                    return this.getExceptionData(JSON.parse(exception.ExternalException), controlKey, exceptionCounter + 1);
                }
                else {
                    return this.getExceptionData(exception.ExternalException, controlKey, exceptionCounter + 1);
                }
            }
        }
    }

    getCollapseContent() {
        let content = typeof this.props.errorDetail === "string" ? this.props.errorDetail : (this.props.errorDetail as any).message

        if (this.props.type === 1 && this.state.isErrorExist) {
            return (
                <BCErrorView
                    key={"errorBox_tab"}
                    error={this.props.error}
                />
            )
        }
        return (
            <BCScrollbars styles={{ width: 'auto' }}
                autoHide={true}
                autoHeightMin={0}
                autoHeightMax={120}
                autoHeight={true}>
                {
                    content
                }
            </BCScrollbars>
        )
    }

    renderTitle() {
        if (this.state.isValidation) {
            let validationCount: number = this.props.error.validationErrorInfos.length;
            let content = `${findMessage.get("101525")} $`;
            let newContent = content.replace("$", `<span>(${validationCount})</span>`);

            return <div className={Style.validationTitle} dangerouslySetInnerHTML={{
                __html: newContent
            }}></div>
        }
        return this.props.title ? (
            <div title={this.props.title} className={"myCustom-title"}>
                {this.props.title}
            </div>
        ) : null;
    }

    copyToClipboardError() {
        copyToClipboard(JSON.stringify(this.props.error.exception));
    }

    getFooterByData() {
        let myCustom_ErrorTitle = cx({
            "myCustom-ErrorTitle": true,
            myCustomErrorTitleExceptionOverride: this.state.isErrorExist
        })

        let myCustom_Error_Footprint = cx({
            "myCustom-Error-footprint": true,
            myCustomErrorTitleExceptionOverride: this.state.isErrorExist
        })

        if (this.props.errorCode) {
            return (<React.Fragment>
                {this.props.errorCode && <div className={myCustom_ErrorTitle}>{this.props.errorCode.split("|")[0]}</div>}
                {this.props.errorCode.split("|")[1] && <div className={"myCustom-Error-footprint"}>{this.props.errorCode.split("|")[1]}</div>}
            </React.Fragment>
            )
        }
        else if (this.state.isErrorExist) {
            let exceptionData: any = this.getExceptionData(this.props.error.exception, undefined, undefined, true);
            let code: string = this.state.isErrorExist && exceptionData?.Code ? `Code: ${exceptionData?.Code}` : `Code: ${findMessage.get("100526")}`;
            let footPrint: string = this.state.isErrorExist && this.props.error?.exception?.FootPrint ? `FootPrint: ${this.props.error.exception.FootPrint}` : `FootPrint: ${findMessage.get("100526")}`;
            return (
                <React.Fragment>
                    <div className={myCustom_ErrorTitle}>{code}</div>
                    <div className={myCustom_Error_Footprint}>{footPrint}</div>
                    <div className={Style.copyToClipboardContainer}>
                        <span className={Style.copyToClipboardStyle} onClick={() => this.copyToClipboardError()}>{findMessage.get("102300")}</span>
                        <span className={Style.reportButtonStyle}><a href={"mailto:"}>{findMessage.get("102301")}</a></span>
                        <div className={Style.buttonStyle}>{this.getMessageBoxDefaultButtons()}</div>
                    </div>
                </React.Fragment>
            )
        }
        else return <React.Fragment></React.Fragment>
    }

    getFooter() {
        let myCustomModal_footer = cx({
            "myCustomModal-footer": true,
            exceptionExist: this.state.isErrorExist
        })

        if (this.props.showErrorDetail && this.state.isErrorExist) {
            return (
                <div className={[myCustomModal_footer, "hasError"].join(" ")}>
                    <div>
                        {this.getFooterByData()}
                    </div>
                    <div>
                        {this.getMessageBoxDefaultButtons()}
                    </div>
                </div>
            )
        }
        else if (this.state.isErrorExist) {
            return <React.Fragment></React.Fragment>
        }
        else {
            return (
                <div className={[myCustomModal_footer, "hasNoError"].join(" ")}>
                    {this.generateFooter()}
                    {this.getFooterByData()}
                </div>
            )
        }

    }

    getContentByData() {
        if (this.props.content) {
            return this.props.content
        }
        else if (this.state.isErrorExist && !this.state.isValidation && (this.props.error?.exception?.Message || this.props.error?.exception?.message)) {
            let exceptionData = this.getExceptionData(this.props.error.exception, undefined, undefined, true) // En sonda bulunan exception ı getirir.
            return ErrorCodes[exceptionData?.Code] || (this.props.error.exception.Message || this.props.error.exception.message)
        }
        else if (this.state.isValidation) {

            return this.props.error.validationErrorInfos.map((i: any) => {
                let content = `- ${(ErrorCodes[i.code] || i.message)}`;
                let newContent = content.replace(i.memberName, `<span>${i.memberName}</span>`);
                return <p className={Style.validationContent} dangerouslySetInnerHTML={{
                    __html: newContent
                }}></p>
            })
        }
        return "";
    }

    getInnerException() {
        if (this.state.isErrorExist && !this.state.isValidation && (this.props.error?.exception?.Message || this.props.error?.exception?.message)) {
            const innerException = this.getExceptionData(this.props.error.exception, undefined, undefined, true);
            if (innerException) {
                const exceptionClassName = cx({ "myCustom-ErrorTitle": true, myCustomErrorTitleExceptionOverride: this.state.isErrorExist });
                return <div className={exceptionClassName}>{innerException.Message || innerException.message}</div>
            }
        }
    }

    collapseCustomHeader(item: ICollapseItem) {
        return (
            <div className={Style.CollapseHeaderStyle}>
                <p>{item.label}</p>
                {this.state.expandedKeys.length > 0 ? <IconExpandBlue /> : <IconExpand />}
            </div>
        )
    }

    getDetailCollapse() {
        let collapseTitle: string = !this.state.isErrorExist ? findMessage.get("100909") : findMessage.get("102073")
        let attentionMessageDetailContentClasses = cx({
            attentionMessageDetailContent: true,
            detailsExceptionActive: this.state.expandedKeys.length > 0 && this.state.isErrorExist,
            detailsTransition: this.state.isErrorExist
        })
        return <>
            {
                (this.props.showErrorDetail || this.state.isErrorExist && !this.state.isValidation) && (
                    <>
                        <div style={{ clear: 'both' }}></div>
                        <BCCollapse
                            showArrow={false}
                            classNames={[attentionMessageDetailContentClasses]}
                            expandedKeys={this.state.expandedKeys}
                            onChangeExpandedKeys={this.onChangeExpandedKeys}
                            customHeader={this.state.isErrorExist ? this.collapseCustomHeader : undefined}
                            items={[{
                                content: this.getCollapseContent(),
                                key: "0",
                                label: collapseTitle,
                                expanded: false
                            }]}
                        />
                    </>
                )
            }
            {(this.state.isErrorExist || this.state.isValidation) &&
                <>
                    <hr className={Style.detailsDivider} />
                    {this.getFooterByData()}
                </>
            }
        </>
    }

    getContentWithIcon() {

        if (this.state.undefinedError) { // Gelen hata mesajı içeriği hakkında bilgi vermiyorsa
            return (
                <React.Fragment>
                    <div className={["myCustomContainer", Style.undefinedErrorContainer].join(" ")}>
                        <div className={Style.SomethingsWentWrongContainerStyle}>
                            <img src={SomethingWentWrongIcon.default} className={Style.SomethingsWentWrongStyle} />
                        </div>
                        <div className={Style.UndefinedErrorMessageContainerStyle}>
                            <p className={Style.UndefinedErrorMessageFirstStyle}>{`Aaaah! ${findMessage.get("102284")}`}</p>
                            <p className={Style.UndefinedErrorMessageSecondStyle}>{findMessage.get("102639")}<br />{findMessage.get("102640")}</p>
                        </div>
                    </div>
                </React.Fragment >
            )
        }

        const Icon = this.swithIcon();
        let autoHeightMax = !this.state.isErrorExist && !this.props.showErrorDetail ? 120 : 65;
        let overflow_content = cx({
            "overflow-content": true,
            overflowContentError: this.state.isErrorExist && !this.state.isValidation,
            isValidation: this.state.isValidation
        })

        return (
            <React.Fragment>
                <div className={"myCustomContainer"}>
                    <div className={"myCustom-icon"}><Icon /></div>
                    <div className={"myCustomRightContent"}>
                        {this.renderTitle()}
                        <div className={Style.myCustomModal} >
                            <div className={Style.myCustomContent}>
                                <div className={overflow_content}>
                                    <BCScrollbars styles={{ width: 'auto' }}
                                        autoHide={true}
                                        autoHeightMin={0}
                                        autoHeightMax={autoHeightMax}
                                        autoHeight={true}>
                                        {this.getContentByData()}
                                        {this.getInnerException()}
                                    </BCScrollbars>
                                </div>
                                {this.getDetailCollapse()}
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>
                {this.getFooter()}
            </React.Fragment >
        );
    }

    getContentWithoutIcon() {
        return (
            <React.Fragment>
                {this.props.content}
            </React.Fragment>
        );
    }

    generateDefaultForm() {
        let props = this.props.props;

        if (props && props.handleCancel) {
            this.parentHandleCancel = props.handleCancel;
            delete props.handleCancel;
        }
        let modalClasses = [Style.MessageBoxContainer, props && props.wrapClassName ? props.wrapClassName : '', this.props.notInCloseIcon ? Style.notInCloseIcon : ""].join(" ")
        return (
            <BCModal
                wrapClassName={modalClasses}
                footer={this.generateFooter()}
                width={this.props.width}
                key={this.props.id}
                id={this.props.id}
                visible={this.props.visible}
                title={this.props.title}
                handleCancel={this.handleCancel}
                {...props}
            >
                {this.getContentWithoutIcon()}
            </BCModal>
        );
    }

    getDefaultTilteWithIcon(): string {
        switch (this.props.type) {
            case MessageBoxIcon.Confirm:
                return findMessage.get("101878");
            case MessageBoxIcon.Error:
                return this.state.undefinedError ? "BimserSynergy" : findMessage.get("100926");
            case MessageBoxIcon.Info:
                return findMessage.get("100647");
            case MessageBoxIcon.None:
                return findMessage.get("100526");
            case MessageBoxIcon.Success:
                return findMessage.get("101879");
            case MessageBoxIcon.Warning:
                return findMessage.get("100652");
            case MessageBoxIcon.Blocked:
                return findMessage.get("102703");
            case MessageBoxIcon.SevereWarning:
                return findMessage.get("102702");
        }
    }
    generateIconForm() {
        let props = this.props.props;

        if (props && props.handleCancel) {
            this.parentHandleCancel = props.handleCancel;
            delete props.handleCancel;
        }

        let modalClasses = [Style.MessageBoxContainer, props && props.wrapClassName ? props.wrapClassName : '', this.props.notInCloseIcon ? Style.notInCloseIcon : ""].join(" ");
        let modalContentWrapperClasses = cx({
            modalContentWrapperFixHeight: true,
            expandHeightTransition: this.state.isErrorExist,
            collapsed: this.state.expandedKeys.length > 0 && !this.state.isErrorExist, // Exception gönderilmediğinde collapse durumu
            collapsedForExceptionView: this.props.type === 1 && this.state.expandedKeys.length > 0 && this.state.isErrorExist, // Exception gönderildiğinde collapse durumu
            exceptionViewFixHeight: this.state.isErrorExist && !this.state.isValidation && this.props.type === 1 && this.state.expandedKeys.length === 0,
            undefinedErrorFixHeight: this.state.undefinedError
        })
        let modalWidth = this.props.width;

        if (this.props.type === 1 && this.state.expandedKeys.length > 0 && this.state.isErrorExist) {
            modalWidth = "850px";
        }
        else if (this.props.type === 1 && this.state.expandedKeys.length === 0 && this.state.isErrorExist) {
            modalWidth = "650px";
        }
        else if (this.state.undefinedError) {
            modalWidth = "654px";
        }

        return (
            <BCModal
                wrapClassName={modalClasses}
                className={Style.modalClass}
                footer={null}
                width={modalWidth}
                key={this.props.id}
                id={this.props.id}
                visible={this.props.visible}
                title={this.getDefaultTilteWithIcon()}
                modalContentWrapperCssClass={modalContentWrapperClasses}
                handleCancel={this.handleCancel}
                {...props}
            >
                {this.getContentWithIcon()}
            </BCModal>
        );
    }

    generateForm() {
        if (this.props.type == MessageBoxIcon.None)
            return this.generateDefaultForm();
        else
            return this.generateIconForm();
    }

    render() {
        return this.generateForm();
    }
}