import { WebInterfaceBaseState } from "../../../common/base/state"
import { createSelector } from 'reselect'
import IStartupLayoutProps from "../entities/IStartupLayoutProps";

const getStartupPanelActiveStatusSelector = (state: WebInterfaceBaseState, props: IStartupLayoutProps) : boolean => {
    return state.layouts.contentLayout.entities.panels.get(props.panelGuid)?.isActive ?? false;
}

const getStartupPanelActiveStatus = createSelector(
    [getStartupPanelActiveStatusSelector],
    (isActive: boolean) => isActive
)

export default getStartupPanelActiveStatus;