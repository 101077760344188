import { createMutableAction } from "@bimser/core"
import * as ActionTypes from './actionTypes';

export const resolveUserSelectModal = (data: any) => {
    return createMutableAction(ActionTypes.RESOLVE_USER_SELECT_MODAL, data);
}

export const rejectUserSelectModal = (modalId: string) => {
    return createMutableAction(ActionTypes.REJECT_USER_SELECT_MODAL, modalId);
}

export const onSearchChanged = (data: { id: string, value: string }) => {
    return createMutableAction(ActionTypes.ON_SEARCH_CHANGE, data);
}

export const onSearchData = (data: any[]) => {
    return createMutableAction(ActionTypes.ON_SEARCH_DATA, data);
}