import * as React from "react";
import {
    BCCodeEditor,
    IMonacoEditor,
    ICodeEditor
} from '../../index';

export default class DiffViewer extends React.Component<{ isVisible?: boolean }, any>
{
    monaco: IMonacoEditor;
    language: string;
    container?: HTMLElement;
    constructor(props: { isVisible?: boolean }) {
        super(props);
        this.onWillMount = this.onWillMount.bind(this);
        this.onDidMount = this.onDidMount.bind(this);
        this.state = {
            value: "",
        }
    }

    componentDidMount() {
        if (this.container && this.container.childNodes && this.container.childNodes.length > 0) {
            this.container.removeChild(this.container.childNodes[0]);
        }
    }

    onWillMount(monaco: IMonacoEditor) {
        this.monaco = monaco;
    }

    onDidMount(editor: ICodeEditor, monaco: IMonacoEditor) {
        this.monaco = monaco;
    }
    createDifference(originalCode?: string, modifiedCode?: string, language?: string) {
        this.removeDifference();
        let originalModel = this.monaco.editor.createModel(originalCode, language);
        let modifiedModel = this.monaco.editor.createModel(modifiedCode, language);
        var diffEditor = this.monaco.editor.createDiffEditor(this.container);
        diffEditor.setModel({
            original: originalModel,
            modified: modifiedModel,
        });
    }
    removeDifference() {
        if (this.container.childNodes.length > 0) {
            this.container.removeChild(this.container.childNodes[0]);
        }
    }
    generateTemplate(props: any): JSX.Element {
        return (
            <div
                ref={(domNode) => {
                    this.container = domNode;
                }}
                style={{
                    width: "100%",
                    height: "100%",
                }}>
                {
                    <BCCodeEditor
                        value={""}
                        language={this.language}
                        width={"100%"}
                        height={"100%"}
                        beforeMount={this.onWillMount}
                        onMount={this.onDidMount}
                        options={{ lineNumbers: "off", readOnly: false }}
                    />
                }
            </div>
        );
    }

    render() {
        return (
            this.generateTemplate(this.props)
        );
    };
}
