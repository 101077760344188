import { IconInfo } from '@bimser/icons';
import { List, Record } from "immutable";
import { ReadonlyRecord } from "../..";
import IBadge, { Badge, BadgeFactory, BadgeFactoryFromJS } from './IBadgeProps';
import IItemType from './IItemType';

export interface IBaseItem {
    key?: string;
    itemType?: IItemType;
    name?: string;
    text?: string;
    title?: string;
    value?: any;
    icon?: IconInfo;
    displayIndex?: number;
    actionKey?: string;
    disabled?: boolean;
    selected?: boolean;
    isActive?: boolean;
    placeHolder?: string;
    externalData?: any;
    className?: string;
    style?: React.CSSProperties;
    isDivider?: boolean;
    shortCut?: string;
    customTemplate?: (item?: IItem) => void | JSX.Element,
    isLoadingItem?: boolean,
}

export default interface IItem extends IBaseItem {
    items?: Array<IItem>;
    badge?: IBadge;
}

export interface IReadonlyItem extends IBaseItem {
    items?: List<Item>;
    badge?: Badge;
}

export type Item = ReadonlyRecord<IReadonlyItem>;

export const ItemFactory = Record<IReadonlyItem>({
    items: List(),
    badge: BadgeFactory(),
    key: null,
    itemType: null,
    name: null,
    text: null,
    value: null,
    icon: null,
    displayIndex: null,
    actionKey: null,
    disabled: false,
    selected: false,
    isActive: false,
    placeHolder: null,
    externalData: null,
    className: null,
    style: null,
    isDivider: false,
    shortCut: null
});

export const ItemFactoryFromJS = function (data: IItem) {

    let items: Item[] = data && data.items ? data.items.map(function (value) {
        return ItemFactoryFromJS(value);
    }) : [];

    return ItemFactory({
        items: List(items),
        badge: data.badge ? BadgeFactoryFromJS(data.badge) : null,
        key: data.key,
        itemType: data.itemType,
        name: data.name,
        text: data.text,
        value: data.value,
        icon: data.icon,
        displayIndex: data.displayIndex,
        actionKey: data.actionKey,
        disabled: data.disabled,
        selected: data.selected,
        isActive: data.isActive,
        placeHolder: data.placeHolder,
        externalData: data.externalData,
        className: data.className,
        style: data.style,
        isDivider: data.isDivider,
        shortCut: data.shortCut
    });

}