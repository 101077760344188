import { BaseResponse } from "../../../..";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IGetTOTPRegisterResponse extends BaseResponse {
    provisionUrl: string,
    manuelEntryCode: string,
    title: string;
}


export interface IReadonlyGetTOTPRegisterResponse extends BaseResponse {
    provisionUrl: string,
    manuelEntryCode: string,
    title: string;
}

export type GetTOTPRegisterResponse = ReadonlyRecord<IReadonlyGetTOTPRegisterResponse>;

export const GetTOTPRegisterResponseFactory = Record<IReadonlyGetTOTPRegisterResponse>({
    provisionUrl: null,
    manuelEntryCode: null,
    title: null
});

export const GetTOTPRegisterResponseFactoryFromJS = function (data: IGetTOTPRegisterResponse) {
    return GetTOTPRegisterResponseFactory({
        provisionUrl: data?.provisionUrl,
        manuelEntryCode: data?.manuelEntryCode,
        title: data?.title
    });
};