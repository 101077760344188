import { createSelector ,OutputSelector} from "reselect";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import { LanguageState, IReadonlyLanguageState, ILanguage, Language, LanguageFactory, LanguageFactoryFromJS} from "../../language/entities";
import {Record,List} from 'immutable'

const languages = (state: BaseState) => state.system.language.items;
const culture = (state: BaseState) => state.user.selectedCulture;

const getTwoLetterCultureFromLanguages = createSelector(
    [
        languages,
        culture
    ],
    (datas: List<any>, selectedCulture: string) => {
        let _lang = datas.find(q => q.culture == selectedCulture);
        return _lang ? _lang.twoLetterCulture : "en";
    }
);

export { getTwoLetterCultureFromLanguages };