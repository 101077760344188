import { ReadonlyRecord } from "@bimser/core";
import { Record } from 'immutable';

interface IBaseGridContextItem{
    caption?: string,
    dataType?: string,
    displayFormat?: string,
    name?: string,
    order?: number
}

export interface IGridContextItem extends IBaseGridContextItem {
    
}

export interface IReadonlyGridContextItem extends IBaseGridContextItem {
    
}

export type GridContextItem = ReadonlyRecord<IReadonlyGridContextItem>;

export const  GridContextItemFactory = Record<IReadonlyGridContextItem>({
    caption: "",
    dataType: "",
    displayFormat: "",
    name: "",
    order: 0
});

export const GridContextItemFactoryFromJS = function (data: IGridContextItem) {
    return GridContextItemFactory(data);
}