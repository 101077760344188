import { IExtremeGridState, IExtremeGridColumn } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeColumnOrderReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<string>>): IExtremeGridState {
    let newColumns: Array<IExtremeGridColumn> = state.columns.map(item => {

        let index = action.payload.findIndex((c => c == item.name));

        return item.order !== index
            ? Object.assign({}, item, { order: index })
            : item;
    });

    return {
        ...state,
        columns: newColumns
    };
}