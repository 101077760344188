import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { childrenDisplayMode, nodeActionType } from "../components/panelMenu/entities/enums";
import { panelType } from "./enums";
import { IBaseMenuItem } from "./IMenuItem";
import { IMenuItemEvent } from "./IMenuItemEvent";

export interface IMenuDesignerItem extends IBaseMenuItem {
    children?: IDictionary<IMenuDesignerItem>,
    events?: Array<IMenuItemEvent>,
    path?: Array<string>,
    caption?: IDictionary<string>,
    parameters?: IDictionary<string>,
    profileIds?: Array<string>,
}

export interface IReadonlyMenuDesignerItem extends IBaseMenuItem {
    children?: Map<string, MenuDesignerItem>,
    events?: List<IMenuItemEvent>,
    path?: List<string>,
    caption?: Map<string, string>,
    parameters?: Map<string, string>,
    profileIds?: List<string>,
}

export type MenuDesignerItem = ReadonlyRecord<IReadonlyMenuDesignerItem>;

export const MenuDesignerItemFactory = Record<IReadonlyMenuDesignerItem>({
    badge: null,
    childrenDisplayMode: childrenDisplayMode.None,
    children: Map<string, MenuDesignerItem>(),
    enableActiveBorder: false,
    events: List<IMenuItemEvent>(),
    icon: "",
    info: "",
    isActive: false,
    isExpanded: false,
    isPinned: false,
    isSubItem: false,
    isSubMenu: false,
    id: "",
    panelType: panelType.none,
    path: List<string>(),
    style: null,
    text: "",
    data: null,
    caption: Map<string, string>(),
    parameters: Map<string, string>(),
    displayOrderNo: 0,
    nodeActionType: nodeActionType.None,
    nodeKey: "",
    isBaseItem: false,
    isMovingItem: false,
    isFirstItem: false,
    isMovedItem: false,
    // rowId: "",
    level: 0,
    key: "",
    // caption: ""
    profileIds: List<string>()
});

export const MenuDesignerItemFactoryFromJS = function (data: IMenuDesignerItem) {
    let _children: IDictionary<MenuDesignerItem> = data.children ? Object.keys(data.children).reduce(function (previous: IDictionary<MenuDesignerItem>, current) {
        previous[current] = MenuDesignerItemFactoryFromJS(data.children[current])
        return previous;
    }, {}) : {};

    let _caption: IDictionary<string> = data.caption ? Object.keys(data.caption).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.caption[current];
        return previous;
    }, {}) : {};

    let _parameters: IDictionary<string> = data.parameters ? Object.keys(data.parameters).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.parameters[current];
        return previous;
    }, {}) : {};

    return MenuDesignerItemFactory({
        isActive: data.isActive,
        text: data.text,
        panelType: data.panelType,
        info: data.info,
        children: Map(_children),
        events: List(data.events),
        badge: data.badge,
        childrenDisplayMode: data.childrenDisplayMode,
        enableActiveBorder: data.enableActiveBorder,
        icon: data.icon,
        isExpanded: data.isExpanded,
        isPinned: data.isPinned,
        isSubItem: data.isSubItem,
        isSubMenu: data.isSubMenu,
        id: data.id,
        path: List(data.path),
        style: data.style,
        data: data.data,
        caption: Map(_caption),
        parameters: Map(_parameters),
        displayOrderNo: data.displayOrderNo,
        isBaseItem: data.isBaseItem,
        isFirstItem: data.isFirstItem,
        isMovingItem: data.isMovingItem,
        nodeActionType: data.nodeActionType,
        nodeKey: data.nodeKey,
        isMovedItem: data.isMovedItem,
        // rowId: data.rowId,
        level: data.level,
        key: data.key,
        // caption: data.caption
        profileIds: List(data.profileIds)
    });
}