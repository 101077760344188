import { BCAjaxRequestConfig, ErrorInfo, findMessage } from '@bimser/components';
import { IDictionary } from "@bimser/core";
import * as Hash from "object-hash";
import { call, fork } from 'redux-saga/effects';
import { MessageBoxButtons } from '../components/ModalManager/enums';
import MessageBox from '../utilities/messageBoxManager';
import { ErrorCodes } from './ErrorCodes';
import Service from './IBaseServices';
let CacheStore: IDictionary<any> = {};

class MethodClass<T, K>{
    private def: IServiceDefinition;
    private listenedError: boolean;
    private disabledMessageBox: boolean;

    constructor(def?: IServiceDefinition) {
        if (def) {
            this.def = def;
        }
        Hash({}, {});
        this._call = this._call.bind(this);
        this._fork = this._fork.bind(this);
        this.cal = this.cal.bind(this);
        this._handleErrorListener = this._handleErrorListener.bind(this);
        this._handleDefaultError = this._handleDefaultError.bind(this);
        this.listenedError = false;
        this.disabledMessageBox = false;
    }

    /**
     * Bu method ile generator fonksiyonlar haricindeki alanlarda service istek yapılabilir.
     * @param disableMessageBox is parameter for disable default error messageBox.
     */
    protected getPromise(disableMessageBox?: boolean, config?: BCAjaxRequestConfig): Promise<T> {
        let { controller, method, service } = { ...this.def }
        let endpoint: string = `/${controller}/${method}`;
        return new Promise<T>((resolve, reject) => {
            let hash = Hash({ ...config, cache: null, endpoint });
            if ((config && config.cache) && CacheStore[hash]) {
                resolve(CacheStore[hash]);
            } else {
                Service[service].get<T>(endpoint, config).then(response => {
                    CacheStore[hash] = response.data;
                    resolve(response.data)
                }).catch((error: any) => {
                    if (!disableMessageBox) {
                        if (ErrorCodes[error.code]) {
                            MessageBox.error({
                                title: findMessage.get('100926'),
                                content: ErrorCodes[error.code] || findMessage.get('101454'),
                                buttons: MessageBoxButtons.OK,
                                errorCode: `Code : ${error.code} | FootPrint : ${error.footPrint}`,
                            });
                        }
                        else {
                            MessageBox.error({ error: error.exception.data });
                        }
                    }
                    reject(error)
                })
            }
        })
    }

    /**
     * Bu method ile generator fonksiyonlar haricindeki alanlarda service istek yapılabilir.
     * @param request is payload of request.
     * @param disableMessageBox is parameter for disable default error messageBox.
     */
    protected postPromise(request?: any, disableMessageBox?: boolean, config?: BCAjaxRequestConfig): Promise<T> {
        let { controller, method, service } = { ...this.def }
        let endpoint: string = `/${controller}/${method}`;
        return new Promise<T>((resolve, reject) => {
            let hash = Hash({ ...request, ...config, cache: null, endpoint });
            if ((config && config.cache) && CacheStore[hash]) {
                resolve(CacheStore[hash]);
            } else {
                Service[service].post<T>(endpoint, request, config).then(response => {
                    CacheStore[hash] = response.data;
                    resolve(response.data)
                }).catch((error: any) => {
                    if (!disableMessageBox) {
                        if (ErrorCodes[error.code]) {
                            MessageBox.error({
                                title: findMessage.get('100926'),
                                content: ErrorCodes[error.code] || findMessage.get('101454'),
                                buttons: MessageBoxButtons.OK,
                                errorCode: `Code : ${error.code} | FootPrint : ${error.footPrint}`,
                            });
                        }
                        else {
                            MessageBox.error({ error: error.exception.data });
                        }
                    }
                    reject(error)
                })
            }
        })
    }

    private cal(type: "get" | "post", request?: any, def?: IServiceDefinition, notFiltered?: boolean, config?: BCAjaxRequestConfig) {
        let { controller, method, service } = { ...def }
        let endpoint: string = `/${controller}/${method}`;
        if (type == "post") {
            let hash = Hash({ ...request, ...config, cache: null, endpoint });
            if ((config && config.cache) && CacheStore[hash]) {
                return CacheStore[hash];
            } else {
                return Service[service].post<T>(endpoint, request, config).then((response) => {
                    if (notFiltered) {
                        CacheStore[hash] = response;
                        return response;
                    } else {
                        CacheStore[hash] = response.data;
                        return response.data;
                    }
                })
            }

        } else {
            let hash = Hash({ ...request, ...config, cache: null, endpoint });
            if ((config && config.cache) && CacheStore[hash]) {
                return CacheStore[hash];
            } else {
                return Service[service].get<T>(endpoint, config).then((response) => {
                    if (notFiltered) {
                        CacheStore[hash] = response;
                        return response;
                    } else {
                        CacheStore[hash] = response.data;
                        return response.data;
                    }
                })
            }
        }
    }

    private *_handleDefaultError(error: ErrorInfo) {
        if (error.code === "FRMW_002") {
            // Token Expire olmuş ancak refreshToken çalıştırılacak. Bir şey yapmaya gerek yok.
        } else if (error.code === "FRMW_ASPN_0006") {
            // Unauthorized scope.
        } else if (error.code === "994") {
            // Refresh token çalıştırıldı. Ancak expire olmuş.
            localStorage.removeItem("token");
            sessionStorage.removeItem("token");
            yield MessageBox.warning({
                title: findMessage.get("101362"),
                errorCode: "Code : " + error.code,
                content: findMessage.get("101363"),
                buttons: MessageBoxButtons.OK,
                showErrorDetail: false
            })
            if (!window.location.hash.includes("/OAuth")) {
                window.location.hash = '';
            }
            window.location.reload();
        } else if (error.code === "995") {
            // Refresh Token endpointinden hata döndürüldüğü zaman.
            let intMsg = error.internalMessage as any;
            yield MessageBox.error({
                errorCode: "Code : " + error.code,
                content: findMessage.get("103028"),
                buttons: MessageBoxButtons.OK,
                showErrorDetail: true,
                errorDetail: intMsg.message
            });
            localStorage.removeItem("token");
            sessionStorage.removeItem("token");
            if (!window.location.hash.includes("/OAuth")) {
                window.location.hash = '';
            }
            window.location.reload();
        }
        else if (!this.disabledMessageBox && ErrorCodes[error.code]) {
            yield MessageBox.error({
                title: findMessage.get('100926'),
                content: ErrorCodes[error.code] || findMessage.get('101454'),
                buttons: MessageBoxButtons.OK,
                errorCode: `Code : ${error.code} | FootPrint : ${error.footPrint}`,
            });
        }
        else {
            yield MessageBox.error({ error: error.exception.data });
        }
    }

    private *_handleErrorListener(err: ErrorInfo) {
        if (this.listenedError) {
            yield call(this.errorListener(err))
        }
    }

    handleError(func: (e: ErrorInfo) => () => IterableIterator<any>, disableMessageBox?: boolean) {
        this.errorListener = func;
        this.listenedError = true;
        this.disabledMessageBox = disableMessageBox
        return this;
    }

    /**
     * bu method yardımıyla endpoint'e ait bilgiler set edilebilir.
     * @param def endpoint tanımlama parametreleri.
     */
    setDefinition(def: IServiceDefinition) {
        if (!this.def) {
            this.def = def;
        }
    }

    protected *_call(type: "get" | "post", request?: any, notFiltered?: boolean, config?: BCAjaxRequestConfig, disableMessageBox?: boolean) {
        try {
            return yield call(this.cal, type, request, this.def, notFiltered, config);
        } catch (error) {
            if (!disableMessageBox) {
                yield call(this._handleDefaultError, error)
            }
            yield call(this._handleErrorListener, error);
        }
    }

    protected *_fork(type: "get" | "post", request?: any, notFiltered?: boolean, config?: BCAjaxRequestConfig, disableMessageBox?: boolean) {
        try {
            return yield fork(this.cal, type, request, this.def, notFiltered, config);
        } catch (error) {
            if (!disableMessageBox) {
                yield call(this._handleDefaultError, error)
            }
            yield call(this._handleErrorListener, error);
        }
    }

    private errorListener: (e: ErrorInfo) => () => IterableIterator<any>;
}

interface IServiceDefinition {
    service: string,
    controller: string,
    method: string
}

/**
 * @typeparam `T` is type of called endpoint's response payload; 
 * @typeparam `K` is type of called endpoint's request payload; 
 */
class GetMethod<T, K> extends MethodClass<T, K>{
    /**
        * api'ye httpGet istegi atılır. Cevap olarak promise alınır.Daha sonra Promise yönetilerek data işlenir.
    */
    callPromise: (disableMessageBox?: boolean, config?: BCAjaxRequestConfig) => Promise<T>
    constructor(def?: IServiceDefinition, config?: BCAjaxRequestConfig) {
        super(def);

        this.callPromise = (dmb?: boolean, config?: BCAjaxRequestConfig) => { return this.getPromise(dmb, config) }
    }

    /**
       * bu method yardımıyla endpoint'e ait bilgiler ezilir. 
       * bu methodu kullanarak istek atılmadan önce runtime da öncesinde set edilmis enpoint parametreleri
       * olsa bile tek seferlik ezebilirsiniz. Bu method ile ezerek api'ye istek atarsanız tanımladıgınız 
       * parametreler kayıtlı kalmaz.
       * @param def endpoint tanımlama parametreleri
       */
    overrideDefinition(def: IServiceDefinition) {
        return new GetMethod(def);
    }

    /**
    * Bu method ile api'ye attıgımız istek cevap dönene kadar bulundugunuz satırda bekler.
    * Bir alt satıra devam etmez.
    */
    *call(notFiltered?: boolean, config?: BCAjaxRequestConfig, disableMessageBox?: boolean) {
        return yield call(this._call, "get", undefined, notFiltered, config, disableMessageBox);
    }

    /**
    * Bu method ile api'ye attıgımız istek cevap dönene kadar bulundugunuz satırda beklemez.
    * Bu method cagırıldıktan sonra kod blogumuz calısmaya devam eder.
    */
    *fork(disableMessageBox?: boolean) {
        return yield call(this._fork, "get", undefined, disableMessageBox);
    }
}

/**
 * @typeparam `T` is type of called endpoint's response payload; 
 * @typeparam `K` is type of called endpoint's request payload; 
 */
class PostMethod<T, K> extends MethodClass<T, K>{
    /**
        * api'ye httpPost istegi atılır. Cevap olarak promise alınır.Daha sonra Promise yönetilerek data işlenir.
    */
    callPromise: (request?: K, disableMessageBox?: boolean, config?: BCAjaxRequestConfig) => Promise<T>
    constructor(def?: IServiceDefinition, config?: BCAjaxRequestConfig) {
        super(def)

        this.callPromise = (request?: any, dmb?: boolean, config?: BCAjaxRequestConfig) => { return this.postPromise(request, dmb, config) }
    }

    /**
     * bu method yardımıyla endpoint'e ait bilgiler ezilir. 
     * bu methodu kullanarak istek atılmadan önce runtime da öncesinde set edilmis enpoint parametreleri
     * olsa bile tek seferlik ezebilirsiniz. Bu method ile ezerek api'ye istek atarsanız tanımladıgınız 
     * parametreler kayıtlı kalmaz.
     * @param def endpoint tanımlama parametreleri
     */
    overrideDefinition(def: IServiceDefinition) {
        return new PostMethod(def);
    }

    /**
     * Bu method ile api'ye attıgımız istek cevap dönene kadar bulundugunuz satırda bekler.
     * Bir alt satıra devam etmez.
     * @notFiltered eger true ise cevap olarak "response.data" degil header ile beraber "response" tamamı döner.
     * @param payload is api request's payload.
     * @param notFiltered is stops to header filter.
     */
    *call(payload?: K, notFiltered?: boolean, config?: BCAjaxRequestConfig, disableMessageBox?: boolean) {
        return yield call(this._call, "post", payload, notFiltered, config, disableMessageBox);
    }

    /**
     * Bu method ile api'ye attıgımız istek cevap dönene kadar bulundugunuz satırda beklemez.
     * Bu method cagırıldıktan sonra kod blogumuz calısmaya devam eder.
     * @param payload is api request's payload.
     */
    *fork(payload?: K, disableMessageBox?: boolean) {
        return yield call(this._fork, "post", payload, disableMessageBox);
    }
}

export { PostMethod, GetMethod };

