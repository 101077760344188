import { WebInterfaceBaseState } from "common/base/state";
import { IWebInterfaceAction } from "common/entities/IWebInterfaceAction";
import { connect } from "react-redux";
//import openApprovesMenuTransaction from "../../workflow/approves/actions/openApprovesMenuTransaction";
import ApprovesBox from "../components/ApprovesBox";
import IApprovesBoxCProps from "../entities/IApprovesBoxCProps";
import IApprovesBoxProps from "../entities/IApprovesBoxProps";
import { getApprovesBoxLoading } from "../selectors/ApprovesBoxLoadingSelector";
import { getApprovesBoxItems } from "../selectors/ApprovesBoxSelector";
import openWorkflowMenuTransaction from "../../workflow/actions/openWorkflowMenuTransaction";
import openApproveDetailTransaction from "../../workflow/actions/openApproveDetailTransaction";
import { onQuickEvent, refreshApprovesListTransaction } from "../actions";


// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IApprovesBoxProps): IApprovesBoxProps {
    return {
        items: getApprovesBoxItems(state, props),
        showLoading: getApprovesBoxLoading(state),
        startupPanelId: props.startupPanelId
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IApprovesBoxCProps {
    return {
        onClickAllApproves: function (data?: IWebInterfaceAction) {
            dispatch(openWorkflowMenuTransaction(data));
        },
        openApproveDetail: function (data: IWebInterfaceAction) {
            dispatch(openApproveDetailTransaction(data));
        },
        onQuickEvent: function (data:IWebInterfaceAction) {
            dispatch(onQuickEvent(data))
        },
        onRefreshApprovesList: function(data:IWebInterfaceAction){
            dispatch(refreshApprovesListTransaction(data));
        }
    };
}

// react - redux connection
const ApprovesBoxContainer = connect(mapStateToProps, mapDispatchToProps)(ApprovesBox);

export default ApprovesBoxContainer;