import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface ILoginPageProperties{
    cspVersion: string,
    restApiAddress: string    
}


export interface IReadonlyLoginPageProperties  {
    cspVersion: string,
    restApiAddress: string    
}

export type LoginPageProperties = ReadonlyRecord<IReadonlyLoginPageProperties>;

export const LoginPagePropertiesFactory = Record<IReadonlyLoginPageProperties>({
    cspVersion: '',
    restApiAddress: ''
});

export const LoginPagePropertiesFactoryFromJS = function (data: ILoginPageProperties) {
    return LoginPagePropertiesFactory(data);
}