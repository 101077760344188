import { IUserInfo, UserInfo, UserInfoFactoryFromJS } from "@bimser/common";
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import ActivityOriginType from "./ActivityOriginType";
import ActivityVisibleFor from "./ActivityVisibleFor";
import { ActivityReaction, ActivityReactionFactoryFromJS, IActivityReaction } from "./IActivityReaction";
import { ActivityRelation, ActivityRelationFactoryFromJS, IActivityRelation } from "./IActivityRelation";

interface IBaseActivity {
    message: string,
    visibleFor: ActivityVisibleFor,
    secretKey: string,
    originType: ActivityOriginType,
    originObjectId?: number,
    createDat: string,
    createdBy: number,
    createdByName: string,
    replyCount: number
}

export interface IActivity extends IBaseActivity {
    replies: IActivity[],
    relations: IActivityRelation[],
    reactions: IActivityReaction[]
}

export interface IReadonlyActivity extends IBaseActivity {
    replies: List<Activity>,
    relations: List<ActivityRelation>,
    reactions: List<ActivityReaction>
}

export type Activity = ReadonlyRecord<IReadonlyActivity>;

export const ActivityFactory = Record<IReadonlyActivity>({
    message: null,
    visibleFor: ActivityVisibleFor.Everyone,
    secretKey: null,
    originType: ActivityOriginType.None,
    originObjectId: null,
    createDat: null,
    createdBy: null,
    createdByName: null,
    relations: List(),
    reactions: List(),
    replies: List(),
    replyCount: 0
});

export const ActivityFactoryFromJS = function (data: IActivity): Activity {
    return ActivityFactory({
        ...data,
        relations: List(data?.relations?.map(r => ActivityRelationFactoryFromJS(r)) || []),
        reactions: List(data?.reactions?.map(r => ActivityReactionFactoryFromJS(r)) || []),
        replies: List(data?.replies?.map(r => ActivityFactoryFromJS(r)) || [])
    });
}