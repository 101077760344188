import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseDepartment {
    id: number,
    departmentCode: string,
    description: string,
    managerDepartmentId?: number,
    status: number,
    type: number,
    importStatus?: number,
    managerUserId?: number,
    loadDetail: boolean,
    managerUsername?: string,
    managerUserFullname?: string,
    managerDepartmentDescription?: string,
    managerDepartmentCode?: string,
}

export interface IDepartment extends IBaseDepartment { }

export interface IReadonlyDepartment extends IBaseDepartment { }

export type Department = ReadonlyRecord<IReadonlyDepartment>;

export const DepartmentFactory = Record<IReadonlyDepartment>({
    id: 0,
    departmentCode: '',
    description: "",
    managerDepartmentId: null,
    status: 0,
    type: 0,
    importStatus: 0,
    managerUserId: null,
    loadDetail: false,
    managerUsername: "",
    managerUserFullname: "",
    managerDepartmentDescription: "",
    managerDepartmentCode: ""
});

export const DepartmentFactoryFromJS = function (data: IDepartment) {
    return DepartmentFactory(data);
}