import { BCAjax, BCButton, BCNotification, findMessage } from "@bimser/components";
import { IDictionary, ILanguage, MLHelper, createSyncAction, isNullOrUndefined } from "@bimser/core";
import * as moment from 'moment';
import React from "react";
import { call, put, select, spawn, take, takeEvery } from 'redux-saga/effects';
import generalInitializerTransactionAction from '../../../action/generalInitializerTransactionAction';
import { ISynergyLogonApp } from "../../../entities/synergyLogonApp";
import { ServiceList } from "../../../services/serviceList";
import { setLanguagesAction } from "../../../system/language/actions";
import setTenantAction from "../../../system/tenant/actions/setTenantAction";
import { ITenantCustom } from "../../../system/tenant/entities/ITenantCustom";
import { Bowser, BowserHelper } from '../../../utilities/helpers';
import { initalizeServices } from "../../../utilities/initializeServices";
import { getMLValueGeneratorSelector } from "../../MLSelector";
import { changeSelectedCultureAction, getUserStartupTransactionAction, initializeApplicationStartup, loginParametersAction } from '../actions';
import * as ActionTypes from "../actions/actionTypes";
import { controlTokenTransaction } from "./controlTokenTransaction";
import getLoginSliderItems from './getLoginSliderItems';
import getURLParams from "./getURLParams";
import { getCaptchaFromService } from './loginTransaction';

export default function* watchLoginParametersTransaction() {
    yield takeEvery(ActionTypes.LOGIN_PARAMETERS_TRANSACTION, loginParametersTransaction);
}

export function* loginParametersTransaction(sagaAction: any) {
    try {
        let locale = null, isBlocked = false;
        const payload = sagaAction.payload;
        locale = localStorage.getItem("locale");
        if (locale) {
            moment.locale(locale.split("-")[0]);
            BCAjax.setHeaders({ 'bimser-language': locale });
        }
        if (!(window as any).serviceConfig) {
            (window as any).serviceConfig = getServiceFileTemplate(payload.synergyLogonApp, payload.DomainAddress);
        }
        initalizeServices();

        const domainUrl = (window as any).DomainAddress ?? (window as any).serviceConfig.DomainAddress;
        const domainAddress = process.env.NODE_ENV !== 'production' ? domainUrl : payload.DomainAddress;
        const request = { DomainAddress: domainAddress, Source: payload.synergyLogonApp }

        let response = yield ServiceList.login.Login.GetLoginParameters.call(request, true);
        let languages: ILanguage[] = [];
        if (response) {
            let canChangeLanguage: boolean = response.data.languageProperties.userCanChangeLanguage;
            if (response.data?.languageProperties?.languages) {
                languages = response.data.languageProperties.languages;
                if (languages.length === 0) {
                    languages = [{ culture: "en-US", id: 0, isDefault: true, name: "English", twoLetterCulture: "en" }]
                }
                let locale = localStorage.getItem("locale");
                let defLang = languages.filter((lang) => lang.isDefault);
                localStorage.setItem("defaultLanguage", defLang[0].culture);

                if (!canChangeLanguage && defLang && defLang.length > 0) {
                    locale = defLang[0].culture;
                }
                else if ((!isNullOrUndefined(locale) && languages.findIndex((lang) => lang.culture == locale) == -1) || isNullOrUndefined(locale)) {
                    if (defLang && defLang.length > 0) {
                        locale = defLang[0].culture;
                    }
                    else {
                        locale = languages[0].culture;
                    }
                }

                const queryData = getURLParams();
                if (queryData?.language && languages.findIndex((lang) => lang.culture == queryData.language) != -1) {
                    locale = queryData.language;
                }
                localStorage.setItem("locale", locale);
                yield put(changeSelectedCultureAction(locale));
            }

            let tenant: ITenantCustom = null
            if (response.data && response.data.tenantCustomProperties) {
                let tenantCustom = response.data.tenantCustomProperties;
                tenant = {
                    headerText: tenantCustom["header.text"] ? (JSON.parse(tenantCustom["header.text"]) as IDictionary<string>) : { "en-US": "BimserSynergy" },
                    pageFavicon: tenantCustom["page.favicon"],
                    logoBottom: tenantCustom["logo.bottom"],
                    logoMain: tenantCustom["logo.main"],
                    logoMenu: tenantCustom["logo.menu"],
                    productName: tenantCustom["product.name"] ? (JSON.parse(tenantCustom["product.name"]) as IDictionary<string>) : {},
                    pageTitle: tenantCustom["page.title"] ? (JSON.parse(tenantCustom["page.title"]) as IDictionary<string>) : {},
                };

                let _title = yield call(getMLValueGeneratorSelector, tenant.pageTitle);
                if (_title) {
                    document.title = _title;
                }

                if (tenant.pageFavicon) {
                    let favIconElement = document.querySelector("link[rel*='icon']");
                    let link: any = favIconElement || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = tenant.pageFavicon;
                    if (favIconElement) {
                        document.getElementsByTagName('head')[0].removeChild(favIconElement);
                    }
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
            }

            if (response.data.browserProperties) {
                const browser = Bowser.getParser(window.navigator.userAgent);
                isBlocked = BowserHelper.checkBrowserType(browser, response.data.browserProperties);

                response.data.browserProperties = {
                    ...response.data.browserProperties,
                    isBlocked
                }
            }

            yield put(setTenantAction(tenant));
            yield put(setLanguagesAction(languages, canChangeLanguage));

            const token = sessionStorage.getItem("token") || localStorage.getItem("token");
            const urlParams = getURLParams();

            if (!BCAjax.defaultHeaders["Authorization"]) {
                let headers: any = {
                    'bimser-encrypted-data': response.headers["bimser-encrypted-data"],
                    'bimser-language': response.headers["bimser-language"],
                    //"Authorization": token ? "Bearer " + token : ""
                }
                if (token?.length) {
                    headers["Authorization"] = `Bearer ${token}`
                }
                else if (urlParams?.token) {
                    headers["Authorization"] = `Bearer ${urlParams.token}`
                }

                BCAjax.setHeaders(headers);
            }

            if (urlParams?.mobileApp) {
                response.data.userCustomProperties.mobileApp = true;
            }

            if (response.data.showCaptcha) {
                yield call(getCaptchaFromService, response.headers["bimser-language"]);
            }

            yield put(loginParametersAction(response.data));

            if (token || BCAjax.defaultHeaders["Authorization"]) {
                let tokenIsValid = yield call(controlTokenTransaction);
                if (tokenIsValid) {
                    yield put(initializeApplicationStartup());
                    yield take(ActionTypes.INITIALIZED_APPLICATION_STARTUP);

                    yield put(getUserStartupTransactionAction());
                    yield put(generalInitializerTransactionAction());
                } else {
                    window.location.hash = '';
                    window.location.reload();
                }
            }
            else {
                yield put(createSyncAction(ActionTypes.CHANGE_TOKEN_CHECK_STATUS, true));
            }
            checkTrialInfo(response.data.trialInfo);

            if (response.data.globalCss) {
                const style = document.createElement('style');
                style.id = "GLOBAL_CSS";
                style.innerHTML = response.data.globalCss;
                document.head.appendChild(style);
            }
        }
        let state = yield select();
        MLHelper.supportedLanguages = state.system.language.items.toJS();
        MLHelper.currentLanguage = state.system.language.items.find((i: ILanguage) => i.culture === state.user.selectedCulture || i.twoLetterCulture === state.user.selectedCulture).toJS();
        MLHelper.defaultLanguage = state.system.language.items.find((i: ILanguage) => i.isDefault).toJS();
        MLHelper.setNumberVariables(MLHelper.currentLanguage.culture);
        if (isBlocked) return;

        let hasToken = sessionStorage.getItem("token") || localStorage.getItem("token");
        yield spawn(getLoginSliderItems, !isNullOrUndefined(hasToken));
    }
    catch (error) {
        console.log("error", error);
    }
}

function checkTrialInfo(trialInfo: { isActive: boolean, expiredDate: string }) {
    if (trialInfo?.isActive) {
        let expiredDate = moment(trialInfo.expiredDate).format("L");
        BCNotification.warning({
            message: findMessage.get("101022"),
            description: findMessage.get("101023").replace("{0}", expiredDate),
            duration: 0,
            btn: React.createElement(BCButton, {
                text: findMessage.get("101024"), type: "ghost", onClick: () => {
                    window.open("https://synergynow.io/#pricing", "_blank");
                }
            })
        })
    }
    return false;
}

function getServiceFileTemplate(logonApp: ISynergyLogonApp, domain: string) {
    let common = {
        main: {
            baseURL: `${domain}/api/web`
        },
        login: {
            baseURL: `${domain}/api/web`
        },
        hr: {
            baseURL: `${domain}/api/web/hr`
        },
        Studio: {
            baseURL: `${domain}/api/ide`
        },
        BuildManager: {
            baseURL: `${domain}/api/buildManager`
        },
        HumanResource: {
            baseURL: `${domain}/api/hr`
        }
    }

    let urls = {
        ensembleModelURL: {
            baseURL: `${domain}/ensemble/Index/(eb4ba59f3bf049e5b06aaa46fbcad89b)#/ExtendedDiagram`
        },
        socket: {
            baseURL: `${domain}/RealtimeInteractionHub`
        },
        predict: {
            baseURL: `${domain}`
        }
    }

    let ide = {
        SolutionExplorer: {
            baseURL: `${domain}/api/ide`
        },
        DMExplorer: {
            baseURL: `${domain}/api/ide`
        },
        FlowManager: {
            baseURL: `${domain}/api/ide`
        },
        IntegrationManager: {
            baseURL: `${domain}/api/ide`
        }
    }

    let WebInterface = {
        workflow: {
            baseURL: `${domain}/api/web`
        },
        dm: {
            baseURL: `${domain}/api/web`
        }
    }

    let result: any = {
        DomainAddress: domain
    };

    if (logonApp == "IDE") {
        result["urls"] = { ...urls, ...{ webInterface: domain } }
        result["services"] = { ...common, ...ide };
    } else {
        result["urls"] = { ...urls, ...{ ide: domain + "/ide/#/" } }
        result["services"] = { ...common, ...WebInterface };
    }
    return result;
}