import { toLowerCase, toUpperCase } from '.';

export function toTitleCase(str: string) {
    if (!str) return "";
    if (typeof str != "string") {
        console.warn("type error toTitleCase:", str);
        return "";
    }
    return str.split(' ').map(w => toUpperCase(w[0]) + toLowerCase(w.substr(1))).join(' ')
}
