import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IUser, User, UserFactory, UserFactoryFromJS } from "..";

export interface IUserListFormContent extends IBaseFormContent{
    selectedUser?: IUser,
}

export interface IReadonlyUserListFormContent extends IBaseFormContent {
    selectedUser?: User
}

export type UserListFormContent = ReadonlyRecord<IReadonlyUserListFormContent>;

export const UserListFormContentFactory = Record<IReadonlyUserListFormContent>({
    panelId: "",
    selectedUser: UserFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: true,
    selectedCompanyId: 0,
});

export const UserListFormContentFactoryFromJS = function (data: IUserListFormContent) {
    return UserListFormContentFactory({
        panelId: data.panelId,
        selectedUser: UserFactoryFromJS(data.selectedUser),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        selectedCompanyId : data.selectedCompanyId,
    });
}