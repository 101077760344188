import { IExtremeGridColumn } from "../entities";

export default function getSortingColumns(columns: Array<IExtremeGridColumn>): Array<{ name: string; sortIndex: number; sortOrder?: "asc" | "desc"; }> {
    let sortingColumns = columns.reduce((results, item) => {
        if (item?.sorting?.enabled !== false && item?.sorting?.index !== null && item?.sorting?.index !== undefined) {
            results.push({
                name: item.name,
                sortIndex: item.sorting.index,
                sortOrder: item.sorting.order
            });
        }
        return results;
    }, []);

    sortingColumns = sortingColumns.sort((a, b) => {
        return a.sortIndex - b.sortIndex;
    });

    return sortingColumns;
}