import { IExtremeGridState } from "../entities";
import { BCExtremeGridHelpers, BCExtremeGridConsts, BCExtremeGridEntities } from "@bimser/components";
import { isNullOrUndefined, MLHelper } from "@bimser/core";
import { ILoadSortOptions, SortOrder, ILoadFilterOptions, ILoadFilterItem, FilterType, ItemType, GroupOperator, IExtremeGridLoadOptions } from "../entities/api";

export default function getLoadOptions(gridState: IExtremeGridState) {
    if (gridState) {
        let sorts = getSorts(gridState);
        let pagination = getPagination(gridState);
        let filters = getFilters(gridState);

        let loadOptions: IExtremeGridLoadOptions = {
            sorts: sorts,
            pagination: pagination,
            filters: filters
        }

        return loadOptions;
    }
}

function getSorts(gridState: IExtremeGridState) {
    let sortingColumns = BCExtremeGridHelpers.getSortingColumns(gridState.columns);
    let sorts: Array<ILoadSortOptions> = sortingColumns.map((item) => {
        return {
            Column: item.name,
            Type: item.sortOrder === "desc" ? SortOrder.Desc : SortOrder.Asc
        }
    });

    return sorts;
}


function getPagination(gridState: IExtremeGridState) {
    let pagination = {
        skip: 0,
        take: BCExtremeGridConsts.defaultPageSize
    }
    if (gridState.paging) {
        let pageSize = gridState.paging.pageSize || BCExtremeGridConsts.defaultPageSize;
        let pageIndex = gridState.paging.pageIndex !== null && gridState.paging.pageIndex !== undefined ? gridState.paging.pageIndex : 0;

        pagination.skip = pageIndex * pageSize;
        pagination.take = pageSize;
    }

    return pagination;
}


function getFilters(gridState: IExtremeGridState) {
    let filters: ILoadFilterOptions = [];

    let filteredColumns = BCExtremeGridHelpers.getFilters(gridState.columns);

    let filterItems: ILoadFilterOptions = filteredColumns.reduce((result, item) => {
        if (!isNullOrUndefined(item.value) && item.value !== "") {
            let column = gridState.columns.find((c => c.name === item.columnName));

            result.push({
                Column: item.columnName,
                Not: item.operation === "notContains" || item.operation === "notEqual",
                Type: getFilterType(item.operation),
                Value: item.value,
                ItemType: getItemType(column.dataType || "string")
            })
        }

        return result;
    }, []);

    if (!isNullOrUndefined(gridState.searching?.value) && gridState.searching?.value !== "") {
        let searchItems: Array<ILoadFilterItem> = gridState.columns.reduce((result, item) => {
            let value = gridState?.searching?.value;
            if (item.searchingEnabled !== false && (item.dataType === "string" || item.dataType === "multiLanguage")) {
                result.push({
                    Column: item.name,
                    Not: false,
                    Type: FilterType.Contain,
                    Value: value,
                    ItemType: ItemType.String,
                })
            }
            else if (item.searchingEnabled !== false && item.dataType == "number" && !isNaN(Number(value))) {

                result.push({
                    Column: item.name,
                    ItemType: ItemType.Int32,
                    Not: false,
                    Type: FilterType.Equal,
                    Value: Number(value),
                });
            }

            return result;
        }, []);

        if (searchItems.length > 0) {
            filters.push({
                Operator: GroupOperator.Or,
                Filters: searchItems
            })
        }
    }

    if (filterItems.length > 0) {
        filters.push({
            Operator: GroupOperator.And,
            Filters: filterItems
        })
    }

    return filters;
}

function getFilterType(operation: BCExtremeGridEntities.FilterOperations): FilterType {
    switch (operation) {
        case "contains":
            return FilterType.Contain;
        case "endsWith":
            return FilterType.EndsWith;
        case "equal":
            return FilterType.Equal;
        case "graterThenOrEqual":
            return FilterType.GreaterOrEqual;
        case "greaterThan":
            return FilterType.Greater;
        case "lessThan":
            return FilterType.Less;
        case "lessThanOrEqual":
            return FilterType.LessOrEqual;
        case "notContains":
            return FilterType.Contain;
        case "notEqual":
            return FilterType.Equal;
        case "startsWith":
            return FilterType.StartsWith;
        default:
            return FilterType.Contain;
    }
}

function getItemType(dataType: BCExtremeGridEntities.DataTypes): ItemType {
    switch (dataType) {
        case "boolean":
            return ItemType.Boolean;
        case "date":
            return ItemType.DateTime;
        case "datetime":
            return ItemType.DateTime;
        case "datetimeOffset":
            return ItemType.DateTimeOffset;
        case "number":
            return ItemType.Decimal;
        case "string":
            return ItemType.String;
        case "time":
            return ItemType.DateTime;
        default:
            return ItemType.String;
    }
}