import { IItem } from '@bimser/core';
import { CSSProperties } from 'react';

export interface IBarcodeProps extends IItem {
    codeType: EBarcodeTypes,
    hideValueText?: boolean,
    style?: CSSProperties
}

export enum EBarcodeTypes {
    QR = 'QR',
    Codebar = 'Codebar',
    code11 = 'code11',
    code39standard = 'code39standard',
    code39extended = 'code39extended',
    code93standard = 'code93standard',
    code93extended = 'code93extended',
    code128 = 'code128',
    gs1code128 = 'gs1code128',
    pharmacode = 'pharmacode',
    datamatrix = 'datamatrix',
    actec = 'actec',
    pdf417 = 'pdf417',
    macropdf417 = 'macropdf417',
    australipost = 'australipost',
    postnet = 'postnet',
    EAN8 = 'EAN8',
    EAN13 = 'EAN13',
    EAN14 = 'EAN14',
    SCC14 = 'SCC14',
    SSCC18 = 'SSCC18',
    UPCA = 'UPCA',
    UPCE = 'UPCE',
    ISBN = 'ISBN',
    ISSN = 'ISSN',
    ISMN = 'ISMN',
    Standard2of5 = 'Standard2of5',
    Interleaved2of5 = 'Interleaved2of5',
    Matrix2of5 = 'Matrix2of5',
    ItalianPost25 = 'ItalianPost25',
    IATA2of5 = 'IATA2of5',
    ITF14 = 'ITF14',
    ITF6 = 'ITF6',
    MSI = 'MSI',
    VIN = 'VIN',
    DeutschePostIdentcode = 'DeutschePostIdentcode',
    DeutschePostLeitcode = 'DeutschePostLeitcode',
    OPC = 'OPC',
    PZN = 'PZN',
    Code16K = 'Code16K',
    Planet = 'Planet',
    OneCode = 'OneCode',
    RM4SCC = 'RM4SCC',
    DatabarOmniDirectional = 'DatabarOmniDirectional',
    DatabarTruncated = 'DatabarTruncated',
    DatabarLimited = 'DatabarLimited',
    DatabarExpanded = 'DatabarExpanded',
    SingaporePost = 'SingaporePost',
    GS1DataMatrix = 'GS1DataMatrix',
    AustralianPosteParcel = 'AustralianPosteParcel',
    SwissPostParcel = 'SwissPostParcel'
}