import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { OSID } from "../enums";

export interface IBaseCompanyObject {
    id: number,
    companyId: number,
    objectId: number,
    osid: OSID,
    importStatus: number
}

export interface ICompanyObject extends IBaseCompanyObject { }

export interface IReadonlyCompanyObject extends IBaseCompanyObject { }

export type CompanyObject = ReadonlyRecord<IReadonlyCompanyObject>;

export const CompanyObjectFactory = Record<IReadonlyCompanyObject>({
    id: 0,
    companyId: 0,
    objectId: 0,
    osid: OSID.Null,
    importStatus: 0
});

export const CompanyObjectFactoryFromJS = function (data: ICompanyObject) {
    return CompanyObjectFactory(data);
}