import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { SignatureFormat, SignMethodType } from '@bimser/common';
import { DocumentSignatureOption } from '../enums';

interface IBaseFlowDocumentSignatureOptions {
    signatureFormat?: SignatureFormat;
    useTimeStamp?: boolean;
    cAdESSignatureType?: SignMethodType;
    signatureOption?: DocumentSignatureOption;
}

export interface IFlowDocumentSignatureOptions extends IBaseFlowDocumentSignatureOptions { }

export interface IReadonlyFlowDocumentSignatureOptions extends IBaseFlowDocumentSignatureOptions { }

export type FlowDocumentSignatureOptions = ReadonlyRecord<IReadonlyFlowDocumentSignatureOptions>;

export const FlowDocumentSignatureOptionsFactory = Record<IReadonlyFlowDocumentSignatureOptions>({
    signatureFormat: SignatureFormat.CAdES,
    useTimeStamp: false,
    cAdESSignatureType: SignMethodType.Both,
    signatureOption: DocumentSignatureOption.NoSign
});

export const FlowDocumentSignatureOptionsFactoryFromJS = function (data: IFlowDocumentSignatureOptions) {
    return FlowDocumentSignatureOptionsFactory(data);
}