import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IManagerKeyFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';


const ManagerKeyFormContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function ManagerKeyFormContainer(props: React.PropsWithChildren<IManagerKeyFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ManagerKeyFormContainerLazy {...props} />
        </Suspense>
    );
}
ManagerKeyFormContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default ManagerKeyFormContainer;