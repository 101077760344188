import { isNullOrUndefined } from '@bimser/core';
import Input, { InputRef } from 'antd/lib/input';
import * as React from "react";
import IInputChangeEventArgs from '../BCInput/entities/IInputChangeEventArgs';
import * as Styles from './assets/styles.scss';
import { IInputSearchProps, InputSizes } from './entities';
let classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

const Search = Input.Search;

const BCInputSearch = React.memo(React.forwardRef<BCInputSearchRef, IInputSearchProps>((props, ref,) => {
    const [value, setValue] = React.useState<string>(props.value);
    const inputSearchRef = React.useRef<InputRef>();

    React.useImperativeHandle(ref, () => ({
        _clearValue,
        inputRef: inputSearchRef.current
    }))

    React.useEffect(() => {
        if (!isNullOrUndefined(props.value)) setValue(props.value)
    }, [props.value])

    const _clearValue = () => {
        (inputSearchRef?.current?.input as any)?.handleChange?.({ currentTarget: { value: "" }, target: { value: "" } });
        (inputSearchRef?.current?.input.parentElement.getElementsByClassName("ant-input-clear-icon")?.[0] as any).click();

        inputSearchRef?.current?.blur();
    }

    const onSearch = (value: string) => props.onSearch?.(value)

    const triggerOnChange = (args: React.ChangeEvent<HTMLInputElement>) => {
        if (value !== args.currentTarget.value) {
            setValue(args.currentTarget.value);
            props.onChange?.({ senderArgs: args, data: args.currentTarget.value });
        }
    }

    const className = React.useMemo(() => cx({
        inputSearchStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center"
    }), [props.textAlign])

    return (
        <Search
            ref={inputSearchRef}
            size={props.size}
            style={props.style}
            placeholder={props.placeHolder}
            defaultValue={value}
            onSearch={onSearch}
            enterButton={props.enterButton}
            onChange={triggerOnChange}
            allowClear={props.allowClear === undefined ? true : props.allowClear}
            disabled={props.disabled}
            readOnly={props.readOnly}
            className={[className, props.className].join(' ')}
            onPressEnter={props.onPressEnter}
            maxLength={props.maxLength}
            value={value}
        />
    );
}));

export default BCInputSearch;
export { IInputSearchProps, InputSizes, IInputChangeEventArgs };
export type BCInputSearchRef = { _clearValue: () => void; inputRef: InputRef}
