enum EChartTypes {
    AreaSeries = "area",
    BarSeries = "bar",
    BubbleSeries = "bubble",
    CandleStickSeries = "candlestick",
    LineSeries = "line",
    ScatterSeries = "scatter",
    SplineSeries = "spline",
    SplineAreaSeries = "splinearea",
    StockSeries = "stock",

    StackedAreaSeries = "stackedbar",
    StackedLineSeries = "stackedline",
    StackedSplineSeries = "stackedspline",
    StackedBarSeries = "stackedbar",
    StackedSplineAreaSeries = "stackedsplinearea",

    FullStackedAreaSeries = "fullstackedbar",
    FullStackedLineSeries = "fullstackedline",
    FullStackedSplineSeries = "fullstackedspline",
    FullStackedBarSeries = "fullstackedbar",
    FullStackedSplineAreaSeries = "fullstackedsplinearea",

    RangeAreaSeries = "rangearea",
    RangeBarSeries = "rangebar",

    StepAreaSeries = "steparea",
    StepLineSeries = "stepline"
}
export default EChartTypes;