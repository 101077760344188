import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent } from ".";
import { ICompany, Company, CompanyFactory, CompanyFactoryFromJS, IPropertyValue, PropertyValue, PropertyValueFactoryFromJS } from "..";


export interface ICompanyFormContent extends IBaseFormContent {
    showingCompany?: ICompany,
    customProperties?: Array<IPropertyValue>,
}

export interface IReadonlyCompanyFormContent extends IBaseFormContent {
    showingCompany?: Company,
    customProperties?: List<PropertyValue>,
}

export type CompanyFormContent = ReadonlyRecord<IReadonlyCompanyFormContent>;

export const CompanyFormContentFactory = Record<IReadonlyCompanyFormContent>({
    panelId: "",
    showingCompany: CompanyFactory(),
    isModified: false,
    customProperties: List<PropertyValue>(),
    initialHash: "",
    showLoading : false
});

export const CompanyFormContentFactoryFromJS = function (data: ICompanyFormContent) {
    let _customProperties: Array<PropertyValue> = data.customProperties && data.customProperties.length ? data.customProperties.map((value: IPropertyValue) => {
        return PropertyValueFactoryFromJS(value);
    }) : [];

    return CompanyFormContentFactory({
        panelId: data.panelId,
        showingCompany: CompanyFactoryFromJS(data.showingCompany),
        isModified: data.isModified,
        initialHash: data.initialHash,
        customProperties: List(_customProperties),
        showLoading : data.showLoading
    });
}