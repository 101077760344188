import { BCContextMenu, BCLoading, BCProgress, findMessage, IContextMenuClickEventArgs, IProgressStatus, IProgressType } from '@bimser/components';
import { IItem } from "@bimser/core";
import IconContinue from "@bimser/icons/16/continue";
import IconDelete from "@bimser/icons/16/delete";
import IconDocument from "@bimser/icons/16/document";
import IconImportant from "@bimser/icons/16/mail-important";
import IconMore from "@bimser/icons/16/more";
import IconNodeLinking from "@bimser/icons/16/node-linking";
import IconPause from "@bimser/icons/16/pause";
import * as React from 'react';
import { UploadManagerStatus } from '../../../utilities/ResumableUploadManager';
import * as Style from '../assets/Style.scss';
import IUploadFileItemProps from '../entities/IUploadFileItemProps';

let classNames = require('classnames/bind');
let cx = classNames.bind(Style);

const UploadFileItem = (props: IUploadFileItemProps) => {

    const [isMenuVisible, setMenuVisible] = React.useState(false);
    const [mouseEvent, setMouseEvent] = React.useState(null);

    let moreBtnClass = cx({ More: true, MoreOpened: isMenuVisible });

    const moreMenuItems: IItem[] = [
        {
            key: "copy_link",
            text: findMessage.get('101215'),
            icon: IconNodeLinking.info,
            disabled: props.item.info.status !== UploadManagerStatus.Done
        },
        {
            key: "dv1",
            isDivider: true
        },
        {
            key: "pause",
            text: findMessage.get('101216'),
            disabled: props.item.info.status == UploadManagerStatus.Done || props.item.info.status == UploadManagerStatus.Failed || props.item.info.status == UploadManagerStatus.Paused,
            icon: IconPause.info
        },
        {
            key: "continue",
            text: findMessage.get('101217'),
            disabled: props.item.info.status == UploadManagerStatus.Done || props.item.info.status == UploadManagerStatus.Failed || props.item.info.status != UploadManagerStatus.Paused,
            icon: IconContinue.info
        },
        {
            key: "dv2",
            isDivider: true
        },
        {
            key: "remove",
            text: findMessage.get('101218'),
            icon: IconDelete.info
        }
    ]

    const getUploadStatus = () => {
        let status = props.item.info.status;
        switch (status) {
            case UploadManagerStatus.Done:
                return IProgressStatus.Success;

            case UploadManagerStatus.Failed:
                return IProgressStatus.Exception;

            case UploadManagerStatus.Uploading:
            case UploadManagerStatus.Preparing:
            case UploadManagerStatus.Standby:
                return IProgressStatus.Active
            default:
                return IProgressStatus.Normal;
        }
    }

    const getProgressPercent = () => {
        return props.item.info.uploadedSize;
    }

    const onContinueClick = () => {
        props.onContextMenuClicked(props.item.id, "continue")
    }

    const onPauseClick = () => {
        props.onContextMenuClicked(props.item.id, "pause")
    }

    const getButtons = () => {
        //if (Object.keys(props.item.threads).length == 0) return <BCLoading size="small" show={ true } />
        if (props.item.info.status == UploadManagerStatus.Paused) return <IconContinue width="24px" height="24px" onClick={onContinueClick} />
        else if (props.item.info.status == UploadManagerStatus.Done) return null;
        else if (props.item.info.status == UploadManagerStatus.Failed) return <IconImportant width="24px" height="24px" />
        else return <IconPause width="24px" height="24px" onClick={onPauseClick} />
    }

    const handleOnMoreClicked = (ev: any) => {
        ev.persist();
        setMouseEvent(isMenuVisible ? null : ev);
        setMenuVisible(true);
    }

    const handleContextMenuClose = () => {
        setMenuVisible(false);
        setMouseEvent(null);
    }

    const handleContextMenuClicked = (args: IContextMenuClickEventArgs) => {
        if (props.onContextMenuClicked) {
            props.onContextMenuClicked(props.item.id, args.data.key)
        }
    }

    const renderProgress = () => {
        if (props.item.info.status == UploadManagerStatus.Done) {
            return (
                <span>{findMessage.get('101219')}</span>
            )
        } else if (props.item.info.status == UploadManagerStatus.Preparing) {
            return (
                <div className={Style.Preparing}>
                    <span>{findMessage.get('101265')}</span>
                    <BCLoading size="small" show={true} />
                </div>
            )
        } else {
            return (
                <div className={Style.Done}>
                    <BCProgress type={IProgressType.Line} percent={getProgressPercent()} status={getUploadStatus()} strokeWidth={5} />
                    {getButtons()}
                </div>
            )
        }
    }

    const generateMoreMenu = () => {
        return (
            <BCContextMenu
                visible={isMenuVisible}
                items={moreMenuItems}
                mouseEvent={mouseEvent}
                onClose={handleContextMenuClose}
                onClick={handleContextMenuClicked}
            />
        )
    }

    return (
        <div className={Style.UploadFileItem}>
            <IconDocument height="32px" width="15%" />
            <div className={Style.Progress}>

                <span title={props.item.info.name} className={Style.FileName}>{props.item.info.name}</span>
                <span title={props.item.info.path} className={Style.UploadPath}>{props.item.info.path}</span>
                {renderProgress()}
            </div>

            <div className={Style.Buttons}>
                <IconMore className={moreBtnClass} height="20px" width="20px" onClick={handleOnMoreClicked} />
                {/* <OutsideClickAlerter outsideclicked={handleContextMenuClose}> */}
                {generateMoreMenu()}
                {/* </OutsideClickAlerter> */}
            </div>
        </div>
    )
}

export default UploadFileItem