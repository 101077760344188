import React from 'react';
import { RxEventEmitter } from "@bimser/core";
import { BCDivider, BCRibbonBar, IRibbonBarProps } from '@bimser/components';
import { useMountedState } from '@bimser/common';
import IconClosePanel from "@bimser/icons/16/close-panel";
import { Subscription } from 'rxjs';
import Utils from '../../../utils';
import components from '../../../panelComponents';
import IPanelComponentProps from '../entities/IPanelComponentProps';
import * as Style from '../assets/style.scss';

const usePanelComponent = (props: IPanelComponentProps) => {
    const [rxSubscription, setRxSubscription] = React.useState<Subscription>(null);

    const [ribbonProps, setRibbonProps] = React.useState<IRibbonBarProps>(null);

    const [customHeader, setCustomHeader] = React.useState<JSX.Element>(null);

    const [title, setTitle] = React.useState(props.title);

    const didMount = useMountedState();

    React.useEffect(() => {
        if (didMount) {
            RxEventEmitter.listen(props.modalId, {
                next: rxEventHandler
            }).then((_rxSubscription) => {
                setRxSubscription(_rxSubscription);
            });

        }
        return () => {
            if (rxSubscription) {
                rxSubscription.unsubscribe();
            }
        }
    }, []);

    const rxEventHandler = React.useCallback((event: any) => {
        if (event.type == "FLEX_PANEL.SET_HEADER_EVENTS") {
            setRibbonProps(event.data);
        } else if (event.type == "FLEX_PANEL.SET_PANEL_TITLE") {
            setTitle(event.data);
        } else if (event.type == "FLEX_PANEL.SET_CUSTOM_HEADER") {
            setCustomHeader(event.data);
        }
    }, []);

    const onCancelClick = React.useCallback(() => {
        props.onCancel(props.modalId, props.panelType);
    }, [setRibbonProps, setTitle, setCustomHeader]);


    const Component = React.useMemo(() => {
        const componentKey = Utils.GetComponentKeyFromPanelType(props.panelType);
        return components[componentKey]
    }, [])

    const Content = React.useMemo(() => {
        let _props = {
            panelGuid: props.modalId,
            parentPanel: parent,
            panelType: props.panelType,
            actionRole: props.actionRole,
            isPanelActive: true,
            windowType: props.type
        }
        return (
            <Component {...props.subProps} {..._props} />
        )
    }, []);

    const renderTitle = React.useCallback((_title: string) => {
        return (
            <div className={Style.Header}>
                <span className={Style.Title} title={_title}>{_title}</span>
                {customHeader}
                <BCRibbonBar customClassName={Style.Ribbon} {...ribbonProps} renderIconInOverflowItems={true} />
                {ribbonProps?.items ? <BCDivider type={"vertical"} className={Style.eventRibbonDivider} /> : <></>}
                {<div className={Style.CloseBtn}><IconClosePanel onClick={onCancelClick} /></div>}
            </div>
        )
    }, [customHeader, ribbonProps, onCancelClick]);


    return {
        Content,
        renderTitle,
        title,
        onCancelClick
    }
}
export default usePanelComponent;