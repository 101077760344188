import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseFlow {
    id?: string,
    name?: string
}

export interface IFlowContent extends IBaseFlow {
}

export interface IReadonlyFlow extends IBaseFlow {
}

export type FlowContent = ReadonlyRecord<IReadonlyFlow>;

export const FlowFactory = Record<IReadonlyFlow>({
    id: "",
    name: ""
});

export const FlowContentFactoryFromJS = function (data: IFlowContent) {
    return FlowFactory(data);
}