import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IDelegation, Delegation, DelegationFactory, DelegationFactoryFromJS } from "./IDelegation";

interface IBaseDelegationFormData {
    initialHash: string,
    currentHash: string,
    isLoading: boolean,
}

export interface IDelegationFormData extends IBaseDelegationFormData {
    showingData: IDelegation
}

export interface IReadonlyDelegationFormData extends IBaseDelegationFormData {
    showingData: Delegation
}

export type DelegationFormData = ReadonlyRecord<IReadonlyDelegationFormData>;

export const DelegationFormDataFactory = Record<IReadonlyDelegationFormData>({
    showingData: DelegationFactory(),
    initialHash: null,
    currentHash: null,
    isLoading: true
});

export const DelegationFormDataFactoryFromJS = function (data: IDelegationFormData) {
    return DelegationFormDataFactory({
        ...data,
        showingData: DelegationFactoryFromJS(data.showingData)
    });
}