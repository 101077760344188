import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { IModalFrameState, ModalFrameState } from '../entities';

const getModalFrameSelector = (state: BaseState) => state.modals && state.modals.modalFrame ? state.modals.modalFrame : null;

const getModalFrameUrl = createSelector(
    [
        getModalFrameSelector
    ],
    (data: ModalFrameState): string => {
        return data && data.url ? data.url : null;
    }
);

export { getModalFrameUrl };
