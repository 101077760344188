import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IAnnouncement } from "./IAnnouncement";

export interface IBaseAnnouncementFormPanelContent {
    panelId: string,
    initialHash?: string,
    showingData?: any,
    isModified?: boolean,
    showLoading?: boolean
}
export interface IAnnouncementFormPanelContent extends IBaseAnnouncementFormPanelContent { }

export interface IReadonlyAnnouncementFormPanelContent extends IBaseAnnouncementFormPanelContent { }

export type AnnouncementFormPanelContent = ReadonlyRecord<IReadonlyAnnouncementFormPanelContent>;

export const AnnouncementFormPanelContentFactory = Record<IReadonlyAnnouncementFormPanelContent>({
    panelId: '',
    initialHash: null,
    showingData: null,
    isModified: false,
    showLoading: false
});

export const AnnouncementFormPanelContentFactoryFromJS = function (data: IAnnouncementFormPanelContent) {
    return AnnouncementFormPanelContentFactory(data);
}