import { getPopupContainer, IItem } from '@bimser/core';
import { GetIconComponent } from "@bimser/icons";
import Select from 'antd/lib/select';
import classNames from 'classnames/bind';
import * as React from "react";
import { findMessage } from '../BCIntl';
import * as Style from './assets/style.scss';
import { IMultipleComboboxProps } from './entities';
import { BaseSelectRef } from 'rc-select';

const cx = classNames.bind(Style);

const BCMultipleCombobox = React.forwardRef((props: IMultipleComboboxProps, ref: React.ForwardedRef<BaseSelectRef>) => {
    const [currentItem, _setCurrentItem] = React.useState(props.value || []);
    const [options, setOptions] = React.useState(props.options || []);
    const [isSelectedAll, setIsSelectedAll] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');

    React.useEffect(() => {

        setOptions(props.options)
    }, [props.options])

    React.useEffect(() => _setCurrentItem(props.value), [props.value])

    const selectedChanged = (selected: string[], args?: any) => {
        if (!props.readonly && options && Array.isArray(options)) {
            setCurrentItem(selected, args);
            setSearchValue("");
        }
    }

    const setCurrentItem = (selected: string[], args: any) => {
        if (options && Array.isArray(options)) {
            let currentItems: Array<string> = [];

            if (Array.isArray(selected)) {

                if (selected.findIndex(q => q === "all") > -1) {
                    options.forEach((item: IItem) => {
                        if (item.value != "all") {
                            if (isSelectedAll) {
                                currentItems = [];
                                setIsSelectedAll(false)
                            } else {
                                currentItems.push(item.value);
                                setIsSelectedAll(true)
                            }
                        }
                    })
                } else {
                    selected.forEach(item => {
                        if (options.findIndex(q => q.value == item) > -1) {
                            currentItems.push(options.find(q => q.value == item).value);
                        }
                    });

                    setIsSelectedAll(false)
                }
            }

            _setCurrentItem(currentItems)
            props.onSelectedChange?.({ senderArgs: [args], data: currentItems });
        }
    }

    const renderIcon = (item: IItem) => {
        const Icon = GetIconComponent(item.icon, {
            title: item.externalData && item.externalData.iconTitle ? item.externalData.iconTitle : undefined,
            className: item.externalData && item.externalData.iconClassName ? item.externalData.iconClassName : undefined,
        });

        return Icon;
    }

    const getOptions = (opts: Array<IItem>) => {
        const _options: Array<IItem> = [];

        if (props.showSelectAllItem) {
            _options.push({
                key: 'all',
                text: findMessage.get("100706"),
                value: "all"
            });
        }

        if (opts && opts.length > 0) _options.push(...opts);

        if (props.renderItem) {
            return (_options.map((item, i) => props.renderItem(item, i)));
        }

        return _options.map(item => (
            <Select.Option key={item.key} value={item.value} title={item.text} className={Style.optionContent}>
                {renderIcon(item)}
                {item.text}
            </Select.Option>
        ))
    }

    const onDropdownVisibleChange = (open: boolean) => {
        if (!open) onSearch("")
    }

    const onSearch = (text: string) => {
        setSearchValue(text);
        props.onSearch?.(text);
    }

    const onFilterOption = (inputValue: string, option: any) => {
        //https://github.com/ant-design/ant-design/issues/23418
        return option?.title?.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) >= 0 || false;
    }

    const bcMultipleComboboxWrapper = cx({
        bcMultipleComboboxWrapper: true,
        fromMobile: props.fromMobile
    });

    const style = {
        ...(props.size === "small" ? { fontSize: '12px' } : {}),
        ...(props.style || {})
    };

    return (
        <Select
            mode={props.mode as any}
            style={style}
            filterOption={onFilterOption}
            optionFilterProp={props.filterOptionProp || "children"}
            onChange={selectedChanged}
            value={currentItem}
            className={[bcMultipleComboboxWrapper, props.className].join(" ")}
            size={props.size}
            placeholder={props.placeholder}
            showSearch={props.showSearch}
            showArrow
            allowClear={props.allowClear && !props.readonly}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
            searchValue={searchValue}
            onSearch={props.showSearch ? onSearch : void 0}
            dropdownRender={props.dropdownRender}
            dropdownClassName={props.dropdownClassName}
            onDropdownVisibleChange={onDropdownVisibleChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            getPopupContainer={props.getPopupContainer || getPopupContainer}
            loading={props.loading}
            open={props.readonly ? false : undefined}
            maxTagCount={props.maxTagCount}
            optionLabelProp={props.optionLabelProp}
            ref={ref}
        >
            {getOptions(props.options)}
        </Select>
    );

})

export default BCMultipleCombobox;
export { IMultipleComboboxProps };

