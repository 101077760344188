import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseCloseWindowAfterSavePayload {
    windowId: string
}

export interface ICloseWindowAfterSavePayload extends IBaseCloseWindowAfterSavePayload {
}

export interface IReadonlyCloseWindowAfterSavePayload extends IBaseCloseWindowAfterSavePayload {
}

export type CloseWindowAfterSavePayload = ReadonlyRecord<IReadonlyCloseWindowAfterSavePayload>;

export const CloseWindowAfterSavePayloadFactory = Record<IReadonlyCloseWindowAfterSavePayload>({
    windowId: null
});

export const CloseWindowAfterSavePayloadFactoryFromJS = function (data: ICloseWindowAfterSavePayload) {
    return CloseWindowAfterSavePayloadFactory(data);
}