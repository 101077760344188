import { connect } from "react-redux";
import BCExtremeGridStateless from "../components";
import { IExtremeGridCProps, IExtremeGridStatelessProps, IExtremeGridProps } from "../entities";
import { BaseState } from "../../..";
import { changeExtremeGridState } from "../actions";
import { ExtremeGridAPI } from "..";
import { removeExtremeGrid } from "../actions/removeExtremeGrid";

// state to component properties
const mapStateToProps = function (mainState: BaseState, props: IExtremeGridProps): IExtremeGridStatelessProps {
    let gridProps = mainState.ExtremeGrid.items.get(props.id);

    if (gridProps) {
        let dataSource = gridProps.dataSource;
        if (gridProps.externalData) {
            let dataIds: Array<string | number> = [];
            let totalCount = undefined;
            let cacheId = gridProps?.dataRefs?.current || null;

            if (cacheId && gridProps.dataRefs?.caches[cacheId]) {
                let cache = gridProps.dataRefs.caches[cacheId];
                dataIds = cache.items;
                totalCount = cache.totalCount;
            }

            let dg = ExtremeGridAPI.get(props.id);
            let resolvedData = dg.resolveData(mainState, dataIds, cacheId);
            if (resolvedData) {
                dataSource = {
                    data: resolvedData,
                    totalCount: totalCount,
                }
            }
        }

        return {
            ...props,
            isInitialized: true,
            ...gridProps,
            dataSource: dataSource
        }
    }
    else {
        return {
            ...props,
            isInitialized: false,
            columns: []
        }
    }

};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IExtremeGridCProps {
    return {
        onStateChange: (payload) => {
            dispatch(changeExtremeGridState(payload));
        },
        removeExtremeGrid: (id: string) => {
            dispatch(removeExtremeGrid({ id }));
        }
    };
}

// react - redux connection
export default connect(mapStateToProps, mapDispatchToProps)(BCExtremeGridStateless as any);