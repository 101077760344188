import { Map } from "immutable";
import { handleDeleteItem, handleMoveGhostItemIntoRoot, handleMoveSidebarComponentIntoParent, handleMoveSidebarContainerIntoParent, handleMoveToDifferentParent, handleMoveWithinParent, handleAddStructureToParent } from ".";
import { LayoutDataItem, NormalizedLayoutStructureItems } from "../../../common/entities";
import { IHandleMoveResult, StructureChange } from "../entities";

// organize all move events
export const handleMove = (views: Map<string, NormalizedLayoutStructureItems>, data: Map<string, LayoutDataItem>, activeView: string, args: StructureChange): IHandleMoveResult => {

    const { method, splitItemPath, splitDropZonePath, item, dragItemType, shiftKey } = args;

    switch (method) {
        case "handleMoveSidebarComponentIntoParent": views = handleMoveSidebarComponentIntoParent(views, data, activeView, splitDropZonePath, item); break;
        case "handleMoveSidebarContainerIntoParent": views = handleMoveSidebarContainerIntoParent(views, data, activeView, splitDropZonePath, item); break;
        case "handleMoveToDifferentParent": views = handleMoveToDifferentParent(views, data, activeView, splitDropZonePath, item, dragItemType); break;
        case "handleMoveWithinParent": views = handleMoveWithinParent(views, activeView, splitDropZonePath, splitItemPath, item); break;
        case "handleMoveGhostItemIntoRoot": views = handleMoveGhostItemIntoRoot(views, data, activeView, item); break;

        case "handleAddStructureToParent": views = handleAddStructureToParent(views, activeView, splitDropZonePath, item); break;

        case "delete": {
            return handleDeleteItem(views, data, activeView, splitDropZonePath, shiftKey, item.id);
        }

        default: break;
    }

    return {
        views,
        data
    }
}