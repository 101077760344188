import { IDictionary, isNullOrUndefined, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";

export interface IBaseWhy {
    id?: number
}

export interface IWhyContent extends IBaseWhy {
    name: IDictionary<string>,
    child: IWhyContent
}

export interface IReadonlyWhy extends IBaseWhy {
    name: Map<string, string>,
    child: WhyContent
}

export type WhyContent = ReadonlyRecord<IReadonlyWhy>;

export const WhyFactory = Record<IReadonlyWhy>({
    id: null,
    name: Map(),
    child: null
});

export const WhyContentFactoryFromJS = function (data: IWhyContent): any {

    if (!isNullOrUndefined(data)) {
        return WhyFactory({
            id: data.id,
            name: Map(data.name),
            child: WhyContentFactoryFromJS(data.child)
        });
    }
    return null;
}