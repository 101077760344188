import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseStepAction {
    type: "CLICK" | "DELAY",
    data: string | number
}

export interface IStepAction extends IBaseStepAction {
    
}

export interface IReadonlyStepAction extends IBaseStepAction {
    
}

export type StepAction = ReadonlyRecord<IReadonlyStepAction>;

export const StepActionFactory = Record<IReadonlyStepAction>({
    type: null,
    data: null
});

export const StepActionFactoryFromJS = function (data: IStepAction) {
    return StepActionFactory(data);
}