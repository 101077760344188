import { FormItemTypes } from "@bimser/core";
import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { ActionMenu } from "./ActionMenu";
import * as Styles from "./assets/styles.scss";
import { IComponent } from "./entities";
import { ELayoutItemTypes } from "./entities/Constants";
import { intersectRect } from "./helpers";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const Component = (args: IComponent) => {
  const { data, path, onStructureChange, setContextMenuItems, onItemSelectionChanged, isParentLocked, setWillDragStart, willDragStart, selectionBounds, controlRenderManager, parentItemType } = args;
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    item: { type: ELayoutItemTypes.COMPONENT, path, data },
    canDrag: !data.designerProps.locked && !isParentLocked,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(ref);

  const handleContextMenuClick = (e: React.MouseEvent) => {
    setContextMenuItems(e, data, path);
    e.stopPropagation();
  }

  const componentClassNames: string = cx({
    draggable: !data.designerProps.locked,
    component: true,
    selected: data.designerProps.selected,
    locked: data.designerProps.locked || isParentLocked,
    isDragging,
    isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup
  });

  const onMouseDown = (e) => {
    const containerElem = document.getElementById(data.id);
    const insideActionBtn = containerElem?.contains(e.target);
    setWillDragStart(!insideActionBtn);
  }
  
  const onEnd = () => setWillDragStart(false);

  const onComponentSelect = (e: React.MouseEvent) => onItemSelectionChanged(e, data);

  useEffect(() => {
    if (selectionBounds) {
      let _selected = ref?.current ? intersectRect(selectionBounds, ref.current.getBoundingClientRect()) : false;
      onItemSelectionChanged(null, data, true, _selected);
    }
  });

  return (
    <div
      ref={ref}
      className={componentClassNames}
      onMouseDown={onMouseDown}
      onDragEnd={onEnd}
      onMouseUp={onEnd}
      onContextMenu={handleContextMenuClick}
      onClick={onComponentSelect}
    >
      {!willDragStart && parentItemType !== FormItemTypes.EControlTypes.InputGroup && <ActionMenu data={data} onStructureChange={onStructureChange} path={path} />}
      {controlRenderManager(data.id, selectionBounds)}
    </div>
  );
};

export default React.memo(Component);
