import { BCThemeChanger } from "@bimser/components"
import React from "react";
import { IThemeChangerProps } from "../entities/IThemeChangerProps";

const ThemeChanger = ({ primaryColor, backgroundColor, image, children, environment, loginStatus }: IThemeChangerProps) => {

    React.useEffect(() => {
        if (backgroundColor && image && primaryColor) {
            localStorage.setItem('theme', JSON.stringify({ backgroundColor, image, primaryColor }));
        }
    }, [backgroundColor, image, primaryColor])

    const getDefaultVariables = () => {
        return {
            backgroundColor: "#b2d8ff",
            image: "Theme1",
            primaryColor: "#0078d4",
            goldenColor: "#f5a623",
            fontFamily: "Segoe WP",
            secondaryIconColor: "#252421"
        }
    }

    const getVariables = () => {
        if (environment === "IDE" || !loginStatus) {
            return {
                ...getDefaultVariables(),
                backgroundColor: "#fff",
                image: "Theme4"
            }
        }

        const localStorageVariables = getLocalStorage();

        return {
            ...getDefaultVariables(),
            backgroundColor: backgroundColor || localStorageVariables.backgroundColor || getDefaultVariables().backgroundColor,
            image: image || localStorageVariables.image || getDefaultVariables().image,
            primaryColor: primaryColor || localStorageVariables.primaryColor || getDefaultVariables().primaryColor
        }
    }

    const getLocalStorage = () => {
        const theme = localStorage.getItem('theme');
        if (theme) {
            try {
                return JSON.parse(theme)
            } catch (error) {
                console.warn("Local storage theme cannot handled correctly");
                return {}
            }
        }

        return {}
    }

    const variables = getVariables();

    return (
        <BCThemeChanger
            primaryColor={variables.primaryColor}
            goldenColor={variables.goldenColor}
            fontFamily={variables.fontFamily}
            secondaryIconColor={variables.secondaryIconColor}
            backgroundColor={variables.backgroundColor}
            backgroundImage={variables.image}
        >
            {children}
        </BCThemeChanger>
    )

}

export default ThemeChanger