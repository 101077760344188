import { isNullOrUndefined } from "@bimser/core";
import { List } from 'immutable';
import { select } from "redux-saga/effects";
import { BaseState, getLanguages, Language } from '../..';

/**
 * Retrieves selected culture or default language value from ML property
 * @param dataSource IDictionary object that contains ML data
 * 
 * Ex => `let _name = yield call(getMLProperty, item.name);`
 */
export function* getMLProperty(dataSource: any) {
    if (isNullOrUndefined(dataSource)) return "";
    let selectedCulture = yield select((state: BaseState) => state.user.selectedCulture);
    let languages: List<Language> = yield select(getLanguages);
    let defLang = languages.find(l => l.isDefault);
    let str = dataSource[selectedCulture] ? dataSource[selectedCulture] : dataSource[defLang.culture];
    if (isNullOrUndefined(str)) {
        let anyLang = languages.find(l => dataSource[l.culture]);
        if (anyLang) {
            str = dataSource[anyLang.culture]
        } else {
            str = "";
        }
    }
    return str;
}
