import * as React from 'react';
import { BCModal, findMessage, BCAutoComplete, BCAvatar } from '@bimser/components';
import IUserSelectModalProps from '../entities/IUserSelectModalProps';
import * as Style from '../assets/style.scss';
import IconMenupopArrow from "@bimser/icons/16/menupop-arrow";

const UserSelectModal = (props: IUserSelectModalProps) => {
    const [searchText, setSearchText] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState(null);
    const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#8479DF', '#0079D5', '#9C0062'];
    const Strings = {
        BTN_OK: findMessage.get('100004'),
        BTN_CANCEL: findMessage.get('100034'),
        PLACEHOLDER: findMessage.get('101870')
    }

    const onSearchChange = (text: string) => {
        if (text && text.trim().length > 0) {
            props.onSearchChanged({
                id: props.modalId,
                value: text.trim()
            });
        }
        setSearchText(text);
        setSelectedUser(null);
    }

    const onUserSelect = (value: any, option: any) => {
        setSelectedUser(option.key);
    }

    const handleOkClick = () => {
        if (selectedUser) {
            let user = props.userData.find((u: any) => u.id.toString() == selectedUser);
            props.onResolve(user);
        } else {
            props.onReject(props.modalId);
        }
    }

    const handleCancelClick = () => {
        props.onReject(props.modalId);
    }

    const getDataSource = (): any[] => {
        let data: any[] = [];
        if (!props.userData) {
            if (props.initializedata) {
                let baseURL = (window as any).serviceConfig.services.dm.baseURL;
                data = props.initializedata.map((u: any) => {
                    return {
                        key: u.id.toString(),
                        value: u.id.toString(),
                        text: `${u.firstname} ${u.lastname} (${u.username})`,
                        imageUrl: u.profileImage ? (baseURL + u.profileImage) : null
                    }
                });
            }
        }else {
            data = props.userData.map((u: any) => {
                return {
                    key: u.id.toString(),
                    value: u.id.toString(),
                    text: `${u.firstname} ${u.lastname} (${u.username})`,
                    imageUrl: ""
                }
            });
        }

        data = data.filter(x => x.text.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        if(data.length > 0){
            return [{ label: (<span className={Style.groupTitle}>{findMessage.get("100925")}</span>), options: data.map(d => ({key: d.key, value: d.value, title: d.text, text: d.text, label: itemRender(d)})) }]
        }
        return [];
    }

    const itemRender = (item: { key: string, text: string, imageUrl: string }) => {
        const getAlt = () => {
            let words = item.text.split(" ");
            if (words.length == 0) return item.text;
            return (words[0][0] + (words[1] ? words[1][0] : "")).toLocaleUpperCase();
        }
        const avatarStyle = { background: colorList[(parseInt(item.key) % 10) % colorList.length] };
        return (
            <div className={Style.autoCompleteItem}>
                <BCAvatar alt={getAlt()} style={avatarStyle} src={item.imageUrl} />
                <span className={Style.itemText}>{item.text}</span>
            </div>
        )
    }

    const generateTemplate = () => {
        return (
            <div className={["ant-row", Style.userSelectContentWrapper].join(" ")}>
                <div className={["ant-col ant-col-8", Style.contentMessage].join(" ")}>{props.contentMessage}</div>
                <div className={"ant-col ant-col-16"}>
                <BCAutoComplete
                    dataSourceGroup={getDataSource()}
                    value={selectedUser ? selectedUser : searchText}
                    placeholder={Strings.PLACEHOLDER}
                    onSearch={onSearchChange}
                    onChange={onUserSelect}
                    suffix={<IconMenupopArrow />}
                    customItemRender={itemRender}
                    allowClear={true}
                    size={"middle"}
                    className={[Style.userAutoComplete, Style.loginAccountCombobox].join(" ")}
                    dropdownClassName={Style.autoCompleteDropdown}
                    disablePopupContainer
                />
                </div>
            </div>
        )
    }

    return (
        <BCModal
            id={props.modalId}
            title={props.title}
            width={"400px"}
            visible={true}
            handleOk={handleOkClick}
            handleCancel={handleCancelClick}
            okText={props.okButtonText || Strings.BTN_OK}
            cancelText={Strings.BTN_CANCEL}
            loading={props.isLoading}
            zIndex={props.zIndex}
        >
            {generateTemplate()}
        </BCModal>
    )
}

export default UserSelectModal;