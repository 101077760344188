import { List, Record } from "immutable";
import { ActionCastFactory, BaseAction, IBaseAction } from ".";
import { ReadonlyRecord } from "@bimser/core";
import { IStatement, Statement, StatementFactoryFromJS } from "./IStatement";

interface IBaseRule {
    id: string,
    name: string,
    enabled?: boolean,
    orderNo: number,
}

export interface IRule extends IBaseRule {
    statements: Array<IStatement>,
    actions: Array<IBaseAction>,
    triggerEvents: Array<string>
}

export interface ReadOnlyRule extends IBaseRule {
    statements: List<Statement>,
    actions: List<BaseAction>,
    triggerEvents: List<string>
}

export type Rule = ReadonlyRecord<ReadOnlyRule>;

export const RuleFactory = Record<ReadOnlyRule>({
    id: null,
    name: null,
    enabled: true,
    orderNo: 0,
    statements: List(),
    actions: List(),
    triggerEvents: List()
});

export const RuleFactoryFromJS = function (data: IRule) {

    let statements: Array<Statement> = data.statements && data.statements.length ? data.statements.map(i => {
        return StatementFactoryFromJS(i)
    }) : [];

    return RuleFactory({
        id: data.id,
        name: data.name,
        enabled: data.enabled,
        orderNo: data.orderNo,
        statements: List(statements),
        actions: List(ActionCastFactory(data.actions)),
        triggerEvents: List(data.triggerEvents)
    });
}
