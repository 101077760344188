import { IDictionary, toUpperCase } from '@bimser/core';
import { select } from 'redux-saga/effects';
import { getSelectedCultureName } from '../../../system/culture/selectors';
import { ILanguage, Language } from '../../../system/language/entities';
import { getDefaultLanguage } from '../../../system/language/selectors';


export function getMLValueSelector(data: IDictionary<string>, selectedCulture: string, defaultLanguage: ILanguage, isUpperCase = false) {

    let value = null;
    value = data[selectedCulture];
    if (isEmpty(value)) { //giriş yapılan dil karşılığı boşsa
        value = data[defaultLanguage.culture];

        if (isEmpty(value)) {//Varsayılan dil değeri boşsa
            var keys = Object.keys(data);

            for (let index = 0; index < keys.length; index++) {
                if (!isEmpty(data[keys[index]])) { //Boş olmayan herhangi bir dil değeri
                    value = data[keys[index]];
                    break; //ilk bulduğun yeğeri al ve çık
                }
            }
        }
    }

    if (value === undefined || value == null || typeof value !== "string") value = ""; //default

    if (isUpperCase) {
        return toUpperCase(value);
    }
    return value;
}
function isEmpty(text: string) {
    return (text === undefined || text == null || text == "");
}
export function* getMLValueGeneratorSelector(data: IDictionary<string>, isUpperCase = false) {
    let selectedCulture: string = yield select(getSelectedCultureName);
    let defaultLanguage: Language = yield select(getDefaultLanguage);
    return getMLValueSelector(data, selectedCulture, defaultLanguage, isUpperCase);
}