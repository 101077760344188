import { FormItemTypes } from "@bimser/core";
import { GetIconComponent } from "@bimser/icons";
import IconEvents from "@bimser/icons/16/events";
import * as React from "react";
import BCScrollbars from "../../../BCScrollbars";
import { LayoutStructureItem } from "../../common/entities";
import * as Styles from "./assets/styles.scss";
import { IBottomDockProps } from "./entities";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const BottomDock = React.memo(({ items, onItemSelectionChanged }: IBottomDockProps) => {

    const onClickBottomDock = (e) => e.stopPropagation();

    if (!items?.count()) return (null);

    const renderControl = (control: LayoutStructureItem): JSX.Element => {
        const itemClassNames: string = cx({
            itemContainer: true,
            selected: control.designerProps.selected
        });
        return (
            <div className={itemClassNames} key={control.id} onClick={(e) => { onItemSelectionChanged?.(e, control); e.stopPropagation(); e.preventDefault(); }}>
                {GetIconComponent(FormItemTypes.EGhostItemTypes[control.type] && IconEvents.info)}
                {control.id}
            </div>
        )
    }

    const renderItems = (): JSX.Element[] => {
        let itemElements: JSX.Element[] = [];
        items.forEach((item) => {
            itemElements.push(renderControl(item));
        });
        return itemElements;
    }

    return (
        <div className={Styles.bottomDock} onContextMenu={onClickBottomDock} onClick={onClickBottomDock}>
            <div className={Styles.formUnparentedItems}>
                <BCScrollbars autoHide>
                    <div className={Styles.horizontalContainer}>
                        {renderItems()}
                    </div>
                </BCScrollbars>
            </div>
        </div>
    )
})

export { BottomDock };

