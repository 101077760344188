import IconCollapseAll from "@bimser/icons/16/collapse-all";
import IconExpandAll from "@bimser/icons/16/expand-all";
import classNames from 'classnames/bind';
import * as React from "react";
import { BCAddonButton, BCCollapse, BCEmpty, BCInputSearch, BCInputSearchRef, findMessage, ICollapseItemProps } from "..";
import { IButtonBarItem } from '../BCButtonBar/entities';
import * as Styles from './assets/style.scss';
import ToolboxItemLayout from './components/ToolboxItem';
import { IReadonlyToolboxGroup, IReadonlyToolboxItem, IToolboxClickEventArgs, IToolboxDoubleClickEventArgs, IToolboxDragEndEventArgs, IToolboxDragEventArgs, IToolboxDragStartEventArgs, IToolboxEventArgs, IToolboxGroup, IToolboxItem, IToolboxItemProps, IToolboxProps, ToolboxGroup, ToolboxGroupFactory, ToolboxGroupFactoryFromJS, ToolboxItem, ToolboxItemFactory, ToolboxItemFactoryFromJS } from './entities';

const cx = classNames.bind(Styles);

const scrollBarStyle: React.CSSProperties = {
    height: 'calc(100% - 51px)'
};

const searchBoxContainerStyle: React.CSSProperties = {
    width: "calc(100% - 25px)",
    height: "40px",
    margin: "0px 11px 9px 16px",
    float: "left",
};

export default function BCToolbox(props: IToolboxProps) {
    const [expandedKeys, setExpandedKeys] = React.useState<Array<string> | string>(props.items ? props.items.map((i) => i.key) : []);
    const [searchKey, setSearchKey] = React.useState<string>('');

    const inputSearchRef = React.useRef<BCInputSearchRef>();

    React.useEffect(() => {
        inputSearchRef?.current?._clearValue?.();
        if (props.autoExpandAll)
            onChangeExpandedKeys(props.items.map((i) => i.key));
    }, [props.items]);

    const onSearch = (value: any) => {
        if (searchKey !== value) {
            setExpandedKeys(props.items.map((i) => i.key));
            setSearchKey(typeof value === "string" ? value : value.data);
        }
    }

    const onClickHeader = () => {
        setExpandedKeys(props.items.length === expandedKeys.length ? [] : props.items.map((i) => i.key));
    }

    const onChangeExpandedKeys = (expandedKeys?: Array<string> | string) => {
        setExpandedKeys(expandedKeys);
    }

    const onClickItem = (item?: IToolboxItem) => {
        props.onSelectToolboxItem?.(item);
    }

    const onDoubleClick = (item?: IToolboxItem) => {
        props.onDoubleClickToolboxItem?.(item);
    }

    const generateTemplate = (items: IToolboxGroup[]): ICollapseItemProps[] => {
        if (!items?.length)
            return [{
                key: '1',
                label: findMessage.get('100048'),
                expanded: true,
                content: renderEmptyTemplate()
            }];

        return items.filter(collapseItem => {
            return collapseItem.itemList.filter(item => item.caption.toLocaleLowerCase().indexOf(searchKey.toLocaleLowerCase()) >= 0).length;
        }).map(collapseItem => {
            return {
                key: collapseItem.key,
                label: collapseItem.caption,
                expanded: true,
                content: collapseItem.itemList
                    .filter(item => item.caption.toLocaleLowerCase().indexOf(searchKey.toLocaleLowerCase()) >= 0)
                    .map(item => renderToolboxItem(item))
            }
        });
    }

    const renderEmptyTemplate = () => {
        return <BCEmpty description={findMessage.get('101239')} />
    }

    const renderHeader = () => {

        let expandAll = props.items.length === expandedKeys.length;
        let title = expandAll ? findMessage.get('100707') : findMessage.get('100708');
        let items: IButtonBarItem[] = [
            {
                buttonProps: {
                    guid: props.guid,
                    icon: expandAll ? <IconCollapseAll /> : <IconExpandAll />,
                    title: title,
                    type: "link",
                    onClick: onClickHeader
                }
            }
        ]

        return (
            <BCAddonButton farItems={items}>
                <BCInputSearch
                    ref={inputSearchRef}
                    value={searchKey}
                    placeHolder={findMessage.get('100002')}
                    onSearch={onSearch}
                    onChange={onSearch}
                    size={'middle'}
                />
            </ BCAddonButton>
        )

    }

    const renderToolboxItem = (item: IToolboxItem) => {
        return (
            <ToolboxItemLayout
                onClick={onClickItem}
                onDoubleClick={onDoubleClick}
                key={item.key}
                data={item}
                contextMenu={props.contextMenu}
            />
        )
    }

    const classNames = cx({
        toolboxCollapse: true,
        small: props.size === 'small'
    });

    return (
        <div className={Styles.toolboxContainer + " " + props.className} >
            <div style={searchBoxContainerStyle}>
                {renderHeader()}
            </div>
            <BCCollapse
                items={generateTemplate(props.items)}
                classNames={classNames.split(' ')}
                scrollbarStyles={scrollBarStyle}
                hasScrollbar={true}
                expandedKeys={expandedKeys}
                headerClassnames={props.groupHeaderClassName}
                onChangeExpandedKeys={onChangeExpandedKeys}
            />
        </div>
    )
}

export { IToolboxProps, IToolboxGroup, IToolboxItem, IToolboxEventArgs, IToolboxClickEventArgs, IToolboxDoubleClickEventArgs, IToolboxDragEventArgs, IToolboxDragStartEventArgs, IToolboxDragEndEventArgs, ToolboxGroup, ToolboxGroupFactory, ToolboxGroupFactoryFromJS, IReadonlyToolboxGroup, ToolboxItem, IReadonlyToolboxItem, IToolboxItemProps, ToolboxItemFactory, ToolboxItemFactoryFromJS };

