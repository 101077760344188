import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Record, Map } from "immutable";

interface IBaseOrganization {
    id: string,
    parentId: string,
    parentDescription: string,
    code: string,
    companyId: string,
    status: boolean,
    company: string,
    hasChild: boolean,
}

export interface IOrganization extends IBaseOrganization {
    description: IDictionary<string>,
    managers: Array<IDictionary<string>>,
    plants: Array<IDictionary<string>>,
}

export interface IReadonlyOrganization extends IBaseOrganization {
    description: Map<string, string>,
    managers: List<Map<string, string>>,
    plants: List<Map<string, string>>
}

export type Organization = ReadonlyRecord<IReadonlyOrganization>;

export const OrganizationFactory = Record<IReadonlyOrganization>({
    id: null,
    parentId: null,
    parentDescription: null,
    code: null,
    status: false,
    companyId: null,
    company: null,
    description: Map(),
    managers: List<Map<string, string>>(),
    plants: List<Map<string, string>>(),
    hasChild: false
});

export const OrganizationFactoryFromJS = function (organization: IOrganization) {
    const managers = organization.managers?.map((manager: IDictionary<string>) => Map(manager));
    const plants = organization.plants?.map((plant: IDictionary<string>) => Map(plant));
    
    return OrganizationFactory({
        ...organization,
        managers: List(managers),
        description: Map(organization.description),
        plants: List(plants)
    })
}