import { BCButton, BCForm, BCFormItem, BCInput, BCNotification, findMessage, IColProps, IInputChangeEventArgs } from '@bimser/components';
import { debounce } from "@bimser/core";
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import * as Styles from '../assets/style.scss';
import IForgetMyPasswordComponentCProps from '../entities/IForgetMyPasswordComponentCProps';
import IForgetMyPasswordComponentProps from '../entities/IForgetMyPasswordComponentProps';

const itemLabelCol: IColProps = { span: 24 };
const itemWrapperCol: IColProps = { span: 24 };
const descLabelCol = { span: 24 };
const inputStyles = { width: '100%' };

const ForgetMyPassword = (props: IForgetMyPasswordComponentProps & IForgetMyPasswordComponentCProps) => {
    const [emailOrUsername, setEmailOrUsername] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const onClickForgetMyPassword = () => {
        if (props.onClickForgotMyPassword) {
            setLoading(true);
            props.onClickForgotMyPassword({
                EMailAddress: emailOrUsername,
                callBackResponse: getSendPasswordRecoveryActionResponse
            });
        }
    }

    const getSendPasswordRecoveryActionResponse = (success: boolean) => {
        if (success) {
            BCNotification.info({
                message: findMessage.get('101957'),
                description: findMessage.get('101958')
            });
            window.setTimeout(props.onClickLoginPanel, 3000)
        } else {
            BCNotification.warning({
                message: findMessage.get('101951'),
                duration: 3
            });
        }

        setLoading(false);
    }

    const onTxtEmailorUsernameChange = debounce((args: IInputChangeEventArgs) => {
        setEmailOrUsername(args.data)
    }, 500);

    return (
        <div className={Styles.loginContainer}>
            <BCForm className={Styles.loginContainer} >
                <BCFormItem
                    className={Styles.title}
                    wrapperCol={descLabelCol}
                >
                    <div className={Styles.title}>{findMessage.get("100746")}</div>
                </BCFormItem>
                <BCFormItem
                    className={Styles.description}
                    wrapperCol={descLabelCol}
                >
                    <div className={Styles.label}>{findMessage.get("100751")}</div>
                </BCFormItem>
                <BCFormItem
                    required={true}
                    labelCol={itemLabelCol}
                    wrapperCol={itemWrapperCol}
                    label={findMessage.get("102941")}
                >
                    <BCInput size={'middle'} value={emailOrUsername} onChange={onTxtEmailorUsernameChange} />
                </BCFormItem>
                <BCFormItem wrapperCol={descLabelCol}>
                    <BCButton loading={loading} type='primary' text={findMessage.get("100752")} style={inputStyles} onClick={onClickForgetMyPassword} />
                </BCFormItem>
                <BCFormItem wrapperCol={descLabelCol}>
                    <a className={Styles.linkBack} onClick={props.onClickLoginPanel}>
                        {findMessage.get("100753")}
                    </a>
                </BCFormItem>
            </BCForm>
        </div>
    )

}

export default ForgetMyPassword