import { IBaseAction } from "@bimser/core";
import { List } from "immutable";
import { DMExplorerActions } from '../actions';
import { DMExplorerState, FilterFactory, NavigationHistoryItem, NavigationHistoryItemFactoryFromJS, NavigationItemFactory } from "../entities";

export default function DMExplorerReducer(state: DMExplorerState, action: IBaseAction<any>) {
    switch (action.type) {
        case DMExplorerActions.SET_LOADED_DATA:
            return setLoadedData(state, action);

        case DMExplorerActions.UPDATE_LOADING_STATUS:
            return state.set("isLoading", action.payload);

        case DMExplorerActions.UPDATE_OPENED_STATUS:
            return state.set("isOpened", action.payload);

        case DMExplorerActions.UPDATE_CURRENT_ITEM:
            return state.set("currentLocation", action.payload);

        case DMExplorerActions.SET_SELECTION:
            return state.set("selectedItem", action.payload);

        case DMExplorerActions.PUSH_NAVIGATION_HISTORY:
            return state.set("navigationHistory", state.navigationHistory.push(action.payload));

        case DMExplorerActions.POP_NAVIGATION_HISTORY:
            let newState = state.navigationHistory.pop();
            return state.set("navigationHistory", newState);

        case DMExplorerActions.SET_NAVIGATION_PATH: {
            let item: NavigationHistoryItem = action.payload as any;
            let foundIndex = state.navigationHistory.findIndex(i => item.id === i.id);
            let newNavigationHistory = state.navigationHistory.splice(foundIndex + 1, state.navigationHistory.count());

            return state
                .set("navigationHistory", newNavigationHistory)
                .set('selectedItem', NavigationItemFactory(
                    {
                        extension: null, id: item.id, name: item.name, secretKey: item.secretKey, type: 0, path: newNavigationHistory.filter(t => t.id != '#Root').map(t => t.name).join('/')
                    }
                ))
                .set('currentLocation', item);
        }

        case DMExplorerActions.CLEAR_NAVIGATION_HISTORY:
            return state.set("navigationHistory", List([NavigationHistoryItemFactoryFromJS({ id: '#Root', secretKey: null, name: null })]));

        case DMExplorerActions.UPDATE_FILTER:
            return state.set("filter", action.payload);

        case DMExplorerActions.CLEAR_DATA:
            return clearData(state, action);

        default:
            return state;
    }
}

function setLoadedData(state: DMExplorerState, action: IBaseAction<any>) {
    return state.setIn(["itemsData", action.payload.id], action.payload.item);
}

function clearData(state: DMExplorerState, action: IBaseAction<any>) {
    return state.set("selectedItem", null).set("filter", FilterFactory()).set("navigationHistory", List([NavigationHistoryItemFactoryFromJS({ id: '#Root', secretKey: null, name: null })])).set('currentLocation', NavigationHistoryItemFactoryFromJS({ id: '#Root', secretKey: null, name: "" }));
}