import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseCulture {
    displayName: string,
    name: string,
}

export interface ICulture extends IBaseCulture { }

export interface IReadonlyCulture extends IBaseCulture { }

export type Culture = ReadonlyRecord<IReadonlyCulture>;

export const CultureFactory = Record<IReadonlyCulture>({
    displayName: null,
    name: null
});

export const CultureFactoryFromJS = function (data: ICulture) {
    return CultureFactory(data);
}