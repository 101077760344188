import { DiffEditor } from "@monaco-editor/react";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import * as React from "react";
import { IDiffEditorProps } from './entities';

const defaultOptions: monacoEditor.editor.IDiffEditorConstructionOptions = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: true,
    cursorStyle: 'line',
    automaticLayout: true,
    folding: true,
    fixedOverflowWidgets: false
};

export default function BCDiffEditor(props: IDiffEditorProps) {
    return (
        <DiffEditor
            // value={props.value}
            original={props.original}
            language={props.language}
            theme={props.theme}
            width={props.width}
            height={props.height}
            options={{ ...defaultOptions, ...props.options }}
            beforeMount={props.beforeMount}
            onMount={props.onMount}
        />
    )
}
