
export default function  (text:string):number {
    var counter = 0;
    var uppercase = 0;

    for (;counter < text.length; counter++) {
        if(isUpperCase(text.charAt(counter))) {uppercase ++} 
    }
    return uppercase;
}

function isUpperCase(character:string) {
    if (character.match(/[A-Z]+/g)) return true;
    else return false;
}