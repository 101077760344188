import React from "react";
import { connect } from "react-redux";
import { BaseState } from "../../..";
import { IMFAComponentProps, IMFAComponentCProps, IValidationMFARequest, ISelectMFATypeRequest } from "../entities";
import MultiFactorAuth from "../components/MultiFactorAuth";
import { onSelectionMFATypeTransaction, onValidationMFA } from "../actions";
import { getMFATypes, getCurrentMFAMethod } from "../selectors/LoginUserSelector";


// state to component properties
const mapStateToProps = function (state: BaseState, props: IMFAComponentProps): IMFAComponentProps {
    return {
        mfaTypes: getMFATypes(state),
        currentMFAMethod: getCurrentMFAMethod(state)
    };
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IMFAComponentCProps {
    return {
        onSelectMFAType: function (data: ISelectMFATypeRequest) {
            dispatch(onSelectionMFATypeTransaction(data));
        },
        onValidationMFA: function (data: IValidationMFARequest) {
            dispatch(onValidationMFA(data));
        },
    };
};

// react - redux connection
const TwoFactorAuthContainer = connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuth);

export default TwoFactorAuthContainer;

function getCurrentMfaMethod(state: BaseState) {
    throw new Error("Function not implemented.");
}
