import { BaseResponse } from "../../../..";
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";


export interface IValidateTOTPRegisterResponse extends BaseResponse {
    isRegistered: boolean;
    recoveryCodes: Array<string>;
}

export interface IReadonlyValidateTOTPRegisterResponse extends BaseResponse {
    isRegistered: boolean;
    recoveryCodes: List<string>;
}

export type ValidateTOTPRegisterResponse = ReadonlyRecord<IReadonlyValidateTOTPRegisterResponse>;

export const ValidateTOTPRegisterResponseFactory = Record<IReadonlyValidateTOTPRegisterResponse>({
    isRegistered: false,
    recoveryCodes: List()
});

export const ValidateTOTPRegisterResponseFactoryFromJS = function (data: IValidateTOTPRegisterResponse) {
    return ValidateTOTPRegisterResponseFactory({
        isRegistered: data.isRegistered,
        recoveryCodes: List(data.recoveryCodes || [])
    });
};

