import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { IInheritContent, InheritFactory, InheritContentFactoryFromJS, InheritContent } from './IInherit';
import { FlowContentFactoryFromJS, FlowContent, IFlowContent, FlowFactory } from './IFlow';
import { IProjectContent, ProjectFactory, ProjectContent, ProjectContentFactoryFromJS } from './IProject';

export interface IBaseNewFile {
    inherited?: boolean,
}

export interface INewFileContent extends IBaseNewFile {
    why: IInheritContent,
    project: IProjectContent,
    flow: IFlowContent,
    inheritedProject: IProjectContent,
    inheritedFlow: IFlowContent,
    flows: Array<IFlowContent>,
}

export interface IReadonlyNewFile extends IBaseNewFile {
    why: InheritContent,
    project: ProjectContent,
    flow: FlowContent,
    inheritedProject: ProjectContent,
    inheritedFlow: FlowContent,
    flows: List<FlowContent>
}

export type NewFileContent = ReadonlyRecord<IReadonlyNewFile>;

export const NewFileFactory = Record<IReadonlyNewFile>({
    inherited: false,
    project: ProjectFactory(),
    flow: FlowFactory(),
    inheritedProject: ProjectFactory(),
    inheritedFlow: FlowFactory(),
    why: InheritFactory(),
    flows: List()
});

export const NewFileContentFactoryFromJS = function (data: INewFileContent): any {

    let _flows: Array<FlowContent> = (data && data.flows) ? data.flows.map((d) => {
        return FlowContentFactoryFromJS(d);
    }) : [];

    return NewFileFactory({
        inherited: data.inherited,
        project: ProjectContentFactoryFromJS(data.project),
        flow: FlowContentFactoryFromJS(data.flow),
        inheritedProject: ProjectContentFactoryFromJS(data.project),
        inheritedFlow: FlowContentFactoryFromJS(data.flow),
        flows: List(_flows),
        why: InheritContentFactoryFromJS(data.why)
    });
}