import { BCAjax, findMessage } from "@bimser/components";
import { put, take, call } from "redux-saga/effects";
import generalInitializerTransactionAction from "../../../action/generalInitializerTransactionAction";
import { ActionTypes, changeLoginLoadingStatusAction, getUserStartupTransactionAction, initializeApplicationStartup, loginAction } from "../actions";
import { ILoginResponseWithMFA } from "../entities";
import { ServiceList, MessageBox, MessageBoxButtons } from '../../..';
import { createGuid } from '@bimser/core';
import { createElement } from 'react';
import ClarificationModal from '../components/ClarificationModal';
import * as Style from '../assets/style.scss';

export default function* commonLoginHandler(request: any, response: ILoginResponseWithMFA, loginMethod: string) {
    let isNormalAuth = !request.useAuthCode || loginMethod == "digitalSignature";
    if (isNormalAuth) {
        let headers = loginMethod == "digitalSignature" ? {
            ...BCAjax.defaultHeaders,
            'bimser-language': request.language,
            "Authorization": "Bearer " + response.data.token,
        } : {
            'bimser-encrypted-data': response.headers["bimser-encrypted-data"],
            'bimser-language': response.headers["bimser-language"],
            "Authorization": "Bearer " + response.data.token,
        };
        BCAjax.setHeaders(headers);
    }

    let clarificationResponse = yield call(controlClarification);
    if (!clarificationResponse) {
        yield put(changeLoginLoadingStatusAction(false));
        return;
    }
    yield put(loginAction(response.data));
    yield put(changeLoginLoadingStatusAction(false));
    localStorage.setItem("loginMethod", loginMethod);
    if (isNormalAuth) {
        sessionStorage.setItem('token', response.data.token);
        if (localStorage.getItem("rememberMe") == "1") {
            localStorage.setItem('token', response.data.token);
        }
        yield put(initializeApplicationStartup());
        yield take(ActionTypes.INITIALIZED_APPLICATION_STARTUP);
        yield put(getUserStartupTransactionAction());
        yield put(generalInitializerTransactionAction());
    } else {
        location.hash = "#/OAuthResult/" + response.data.authCode;
    }
}

function* controlClarification() {
    try {

        let clarificationResponse = yield ServiceList.workflow.WebInterfaceManagement.GetClarification.call(undefined, undefined, true);
        if (clarificationResponse?.enabled) {
            let res = yield call(openClarificationModal, clarificationResponse.text, clarificationResponse.version);
            if (res) {
                let acceptedResponse = yield ServiceList.workflow.WebInterfaceManagement.AcceptClarification.call({ version: clarificationResponse.version });
                if (!acceptedResponse) {
                    return false;
                }
            }
            return res;
        }
        return true;
    } catch (error) {
        return true;
    }
}

function openClarificationModal(text: string, version: string) {
    return new Promise(resolve => {
        let modalId = createGuid();
        const onResult = (res: boolean) => {
            resolve(res);
            MessageBox.closeModal(modalId);
        }
        MessageBox.show({
            id: modalId,
            title: findMessage.get("101010") + (version ?? ""),
            content: createElement(ClarificationModal, { text, onResult }),
            props: {
                closable: false,
                maskClosable: false,
                keyboard: false,
                footer: null,
                modalContentWrapperCssClass: Style.clarificationModalContent
            },
            width: "400px",
            buttons: MessageBoxButtons.OK,
            notInCloseIcon: true
        });


    })
}