import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseProfiles {
    projectId?: string,
    formId?: string,
    projectName?: string,
    formName?: string,
    isRequired?: boolean,
    type?: number
}

export interface IProfiles extends IBaseProfiles {

}

export interface IReadonlyProfiles extends IBaseProfiles {

}

export type Profiles = ReadonlyRecord<IReadonlyProfiles>;

export const ProfilesFactory = Record<IReadonlyProfiles>({
    projectId: "",
    formId: "",
    projectName: "",
    formName: "",
    isRequired: false,
    type: 0
});

export const ProfilesFactoryFromJS = function (data: IProfiles) {
    return ProfilesFactory(data);
}