import * as React from "react";
import BCSwitch from '../../../BCSwitch';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function SwitchProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (value: any) => {
        props.customChangeEvent ? props.customChangeEvent(value) : replaceValue(props.item, value)
    }

    return (
        <BCSwitch
            checked={props.value}
            onChange={onChange}
            checkedChildren={props.item.options && props.item.options.checkedChildren}
            unCheckedChildren={props.item.options && props.item.options.unCheckedChildren}
            disabled={props.item.disabled || props.item.readOnly}
            style={props.style}
        />
    )
}