import * as React from 'react';
import * as Style from './assets/toggleButtonStyle.scss';
import IToggleButtonProps from './entities/IToggleButtonProps';
import { GetIconComponent } from "@bimser/icons";

let classNames = require('classnames/bind');
let cx = classNames.bind(Style);

class BCToggleButton extends React.Component<IToggleButtonProps, any>{

    constructor(props: IToggleButtonProps) {
        super(props);
    }

    onBtnClick = () => {
        let active = !this.props.active;
        if (this.props.onChange) {
            this.props.onChange(active);
        }
    }

    render() {
        let { size, text, icon } = this.props;
        let classNames = cx({
            ToggleButtonContainer: true,
            ToggleOn: this.props.active,
            sizeMedium: size == "medium",
            sizeLarge: size == "large"
        })

        return (
            <div onClick={this.onBtnClick} className={classNames} >
                {
                    icon && GetIconComponent(icon)
                }
                {
                    text && <span>{text}</span>
                }
            </div>
        )
    }
}

export default BCToggleButton;
export { IToggleButtonProps }