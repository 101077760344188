import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';

interface IBasePermissionGroupMember {
    memberId?: number,
    memberOfId?: number,
    type?: number,
    secretKey?: string,
    name?: string,
    description?: string,
}

export interface IPermissionGroupMember extends IBasePermissionGroupMember {

}

export interface IReadonlyPermissionGroupMember extends IBasePermissionGroupMember {

}

export type PermissionGroupMember = ReadonlyRecord<IReadonlyPermissionGroupMember>;

export const PermissionGroupMemberFactory = Record<IReadonlyPermissionGroupMember>({
    memberId: null,
    memberOfId: null,
    type: null,
    secretKey: null,
    name: null,
    description: null,
});

export const PermissionGroupMemberFactoryFromJS = function (data: IPermissionGroupMember) {
    return PermissionGroupMemberFactory(data);
}