import { createSyncAction } from "@bimser/core";
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "../../../common/entities";
import * as ActionTypes from './actionTypes';

export default function openApproveDetailTransaction(data: IWebInterfaceAction) {

    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.OPEN_APPROVE_DETAIL_TRANSACTION, payload);

    return action;
}