import React from 'react';
import { BCEditor, BCCheckbox, BCButtonGroup, BCButton, findMessage } from '@bimser/components';
import * as Style from '../assets/style.scss';

const ClarificationModal = (props: { text: string, onResult: (res: boolean) => void }) => {
    const [isChecked, setIsChecked] = React.useState(false);

    const onClickCancel = React.useCallback(() => {
        props.onResult(false);
    }, []);

    const onClickAccept = React.useCallback(() => {
        props.onResult(true);
    }, []);

    const onChange = React.useCallback((e: any) => {
        setIsChecked(e);
    }, []);

    return (
        <div>
            <BCEditor viewerMode model={props.text} />
            <div className={Style.clarificationFooter}>
                <BCCheckbox label={findMessage.get("101011")} onChange={onChange} />
                <BCButtonGroup key="btnGroup">
                    <BCButton onClick={onClickCancel}>{findMessage.get("100034")}</BCButton>
                    <BCButton type="primary" disabled={!isChecked} onClick={onClickAccept}>{findMessage.get("101012")}</BCButton>
                </BCButtonGroup>
            </div>
        </div>
    )
}
export default ClarificationModal;