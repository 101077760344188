import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { NavigationHistoryItem, NavigationHistoryItemFactory } from '../entities/INavigationHistoryItem';

const getCurrentItem = (state: BaseState) => {
    return state.DMExplorer.currentLocation;
}

const getCurrentItemSelector = createSelector(
    [
        getCurrentItem
    ],
    (item: NavigationHistoryItem) => {

        return item ? item.toJS() : NavigationHistoryItemFactory({ id: '#Root', secretKey: null, name: "" }).toJS()
    }
)

export default getCurrentItemSelector;