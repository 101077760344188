import { BCButton, BCDigitalSignature, BCLoading, BCModal, findMessage, ICertificate, IFinalizeSigningData } from '@bimser/components';
import classNames from 'classnames/bind';
import * as React from 'react';
import { DigitalSignatureAPI } from '../../../utilities/DigitalSignatureAPI';
import { IFinalizeSigningPayload, ISignPayload, SignatureDataType, SignatureType } from '../../../utilities/DigitalSignatureAPI/entities';
import ILoginWithDigitalSignaturePayload from '../../Login/entities/ILoginWithDigitalSignaturePayload';
import Style from '../assets/style.scss';
import { IDigitalSignatureProps } from '../entities';

const cx = classNames.bind(Style);

const DigitalSignature = (props: IDigitalSignatureProps) => {
    const [DS] = React.useState(new DigitalSignatureAPI());
    const [compactMode, setCompactMode] = React.useState<boolean>(props.compactMode);

    function onPressEnter() {
        const event = new KeyboardEvent('keydown', {
            code: "Enter",
            key: "Enter",
            keyCode: 13,
            type: "keydown",
            which: 13,
            bubbles: true
        } as any);

        document.getElementsByTagName("digital-signature")[0]?.dispatchEvent(event);
    }

    function onInitializeSigning(certificate: ICertificate) {
        return new Promise<any>((resolve, reject) => {
            try {
                const response = DS.sign({
                    certificate: certificate as any,
                    signatureType: SignatureType.BES,
                    isParallel: false,
                    embeddedSignature: false,
                    secretKey: props.signatureOptions.parameters['secretKey'],
                    versionSecretKey: props.signatureOptions.parameters['versionSecretKey'],
                    signatureFormat: props.signatureOptions.signatureFormat,
                    dataType: props.signatureOptions.type === "dm" ? SignatureDataType.DmObject : SignatureDataType.Data
                } as ISignPayload);

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    function onFinalizeSigning(signingData: IFinalizeSigningData) {
        return new Promise<any>((resolve, reject) => {
            try {
                const response = DS.final({
                    signature: signingData.signatureData,
                    secretKey: signingData.secretKey,
                    transactionUUID: signingData.transactionUUID,
                    type: props.signatureOptions.type
                } as IFinalizeSigningPayload).then((response: ILoginWithDigitalSignaturePayload) => {
                    if (props.signatureOptions.type === "dm")
                        props.onCloseDigitalSignature(props.id, true);
                    else
                        props.onLogin(response);
                });

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    function onCompactModeChange(compactMode: boolean) {
        setCompactMode(compactMode);
    }

    function getSignatureComponent() {
        return (
            <div className={Style.DSContent}>
                <BCDigitalSignature
                    compactMode={compactMode}
                    hideCompactModeCheckBox={!compactMode}
                    showSignatureOther={props.signatureOptions.type === "dm"}
                    signatureFormatReadOnly={true}
                    defaultSignatureFormat={"cades"}
                    onInitializeSigning={onInitializeSigning}
                    onFinalizeSigning={onFinalizeSigning}
                    onCompactModeChange={onCompactModeChange}
                />
            </div>
        )
    }

    function handleOk() {
        props.onCloseDigitalSignature(props.id, false);
    }

    function handleCancel() {
        if (props.signatureOptions.type === "login")
            setCompactMode(true);
        else
            props.onCloseDigitalSignature(props.id, false);
    }

    function signatureComponent() {
        if (compactMode) {

            const classNames = cx({ CompactMode: props.compactMode, DigitalSignatureContainer: true });

            return (
                <BCLoading show={false}>
                    <div className={classNames}>
                        {getSignatureComponent()}
                        {props.signatureOptions.type == "login" && <BCButton block type='primary' text={findMessage.get("100747")} onClick={onPressEnter} />}
                    </div>
                </BCLoading>
            )
        }

        return (
            <BCModal
                disableScroll
                id={props.id}
                visible={true}
                loading={false}
                footer={[<></>]}
                title={findMessage.get('101187') + ` ${props.signatureOptions.type == "dm" ? props.signatureOptions.parameters.fileName : ""}`}
                handleOk={handleOk}
                handleCancel={handleCancel}
                okText={findMessage.get('100824')}
                wrapClassName={Style.SignatureModalContent}
                className={Style.digitalSignatureModalStyles}
                cancelButtonProps={{ style: { display: "none" } }}
                modalContentWrapperCssClass={Style.modalContentWrapperStyles}
            >
                <div className={cx({ CompactMode: props.compactMode, DigitalSignatureContainer: true })}>
                    {getSignatureComponent()}
                </div>
            </BCModal>
        )
    }

    return signatureComponent();
}

export default DigitalSignature;