import * as React from "react";
import * as Style from "./assets/style.scss";
import BCInputNumber from "../BCInputNumber";
import BCLabel from "../BCLabel";
import BCSlider from "../BCSlider";
import BCTooltip from "../BCTooltip";
import { findMessage } from "../BCIntl";
import { debounce, ValidationHelper } from "@bimser/core";
import { IProps } from "./entities";
import IconCollapse from "@bimser/icons/16/collapse";
import IconExpand from "@bimser/icons/16/expand";
import IconActualSize from "@bimser/icons/16/actual-size";
import IconFitToScreen from "@bimser/icons/16/fit-to-screen";

export default class DiagramStateViewer extends React.Component<IProps, any> {
    constructor(props: IProps) {
        super(props);
        this.onChangeSlider = this.onChangeSlider.bind(this);
        this.fitScreen = this.fitScreen.bind(this);
        this.defaultScreen = this.defaultScreen.bind(this);
        this.onDblClick = this.onDblClick.bind(this);
        this.decreaseZoomScale = this.decreaseZoomScale.bind(this);
        this.increaseZoomScale = this.increaseZoomScale.bind(this);
        this.onChangeZoomInputNumber = debounce(this.onChangeZoomInputNumber.bind(this), 250);

    }
    onChangeSlider(scale?: number) {
        if (this.props.setZoomScale) {
            this.props.setZoomScale(scale);
        }
    }
    fitScreen() {
        if (this.props.setFitScreen) {
            this.props.setFitScreen();
        }
    }
    defaultScreen() {
        this.onChangeSlider(this.props.defaultZoomScale);
    }
    onDblClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }
    decreaseZoomScale() {
        if (this.props.zoomScale - this.props.zoomScaleFactory >= this.props.minScale) {
            this.onChangeSlider(this.props.zoomScale - this.props.zoomScaleFactory);
        }
        else {
            this.onChangeSlider(this.props.minScale);
        }
    }
    increaseZoomScale() {
        if (this.props.zoomScale + this.props.zoomScaleFactory <= this.props.maxScale) {
            this.onChangeSlider(this.props.zoomScale + this.props.zoomScaleFactory);
        }
        else {
            this.onChangeSlider(this.props.maxScale);
        }
    }
    onChangeZoomInputNumber(value?: number) {
        if (ValidationHelper.Test(ValidationHelper.RegularExpressions.NumberDot, value, true)) {
            this.onChangeSlider(value);
        }
    }
    private scaleInputFormatter(value?: React.ReactText) {
        return `%${value}`;
    }
    private scaleInputParser(value: string) {
        return value.replace('%', '');
    }
    totalCountLabelStyle: React.CSSProperties = { marginLeft: "10px" }
    selectedLabelStyle: React.CSSProperties = { marginLeft: "50px" }

    render() {
        return (<div className={Style.diagramStateViewerContainer} onDoubleClick={this.onDblClick}>
            {!this.props.hideObjectCount &&
                <div className={Style.stateViewerContainer}>
                    <BCLabel className={Style.label} customStyles={this.totalCountLabelStyle}>{findMessage.get("100997").replace("$1", String(this.props.elementCount))} </BCLabel>
                </div>
            }
            <div className={Style.zoomPanelContainer}>
                <IconCollapse className={Style.changeScaleButtons} width="20px" onClick={this.decreaseZoomScale} />
                <div className={Style.sliderContainer}>
                    <BCSlider
                        step={this.props.zoomScaleFactory}
                        max={this.props.maxScale}
                        min={this.props.minScale}
                        defaultValue={this.props.zoomScale}
                        value={this.props.zoomScale}
                        onChange={this.onChangeSlider}
                        tipFormatter={this.scaleInputFormatter}
                    />
                </div>
                <IconExpand className={Style.changeScaleButtons} width="20px" onClick={this.increaseZoomScale} />
                <BCInputNumber
                    className={Style.scaleInput}
                    value={this.props.zoomScale}
                    onChange={this.onChangeZoomInputNumber}
                    precision={0}
                    max={this.props.maxScale}
                    min={this.props.minScale}
                    formatter={this.scaleInputFormatter}
                    parser={this.scaleInputParser}
                />

                <BCTooltip
                    title={findMessage.get("100888")}
                    placement={"top"}
                    trigger={"hover"}
                    key={"reset"}
                    mouseEnterDelay={1}>
                    <div key={"reset"} className={Style.iconContainer}>
                        <IconActualSize className={Style.icon} width="20px" style={{ marginLeft: '10px' }} onClick={this.defaultScreen} />
                    </div>
                </BCTooltip>
                {!this.props.hideFitScreen &&
                    <BCTooltip
                        title={findMessage.get("100999")}
                        placement={"top"}
                        trigger={"hover"}
                        key={"fitAllItem"}
                        mouseEnterDelay={1}>
                        <div key={"fitAllItem"} className={Style.iconContainer}>
                            <IconFitToScreen className={Style.icon} width="20px" style={{ marginLeft: '15px' }} onClick={this.fitScreen} />
                        </div>
                    </BCTooltip>
                }
            </div>
        </div>);
    }
}
