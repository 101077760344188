import React from 'react';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/ru.js';
import "./entities/lang/tr"

import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import Froalaeditor from 'froala-editor';

import Tribute from "tributejs";
import "tributejs/dist/tribute.css";
import { EditorToolbarType, IEditorProps } from '.';
import EditorToolbar from './entities/EditorToolbar';
import * as Hash from 'object-hash';
import _debounce from 'lodash/debounce';
import * as Styles from './assets/styles.scss';
import { findMessage } from '..';
import { LicenseKeys } from "@bimser/core";
import { GetEditorHelpSet } from './entities/EditorHelpSet';
import { enabledPlugins } from './entities/EnabledPlugins';
import classNames from 'classnames/bind';
const cx = classNames.bind(Styles);
const iconLanguage = require("./assets/img/language.svg");
import EditorMLModal from './EditorMLModal';
import IEditorToolbar from './entities/IEditorToolbar';

const Editor = React.forwardRef((props: IEditorProps, ref: React.ForwardedRef<{ focus: () => void, froalaRef: Froalaeditor }>) => {
    const [editor, setEditor] = React.useState<any>(null);
    const [mlVisible, setMlVisible] = React.useState<any>(false);
    const froalaRef = React.useRef<FroalaEditor>();

    const editorContainerRef = React.useRef<HTMLDivElement>(null);

    React.useImperativeHandle(ref, () => {
        return {
            focus: () => editor?.events.focus(),
            froalaRef: froalaRef.current
        }
    });

    const filterButtons = (toolbarButtons: IEditorToolbar) => {
        if (!props.mlModel) {
            toolbarButtons.moreMisc.buttons = toolbarButtons.moreMisc.buttons.filter(b => b != "ML");
        }

        if (props.noFullscreen) {
            toolbarButtons.moreMisc.buttons = toolbarButtons.moreMisc.buttons.filter(button => button != 'fullscreen');
        }
        return toolbarButtons
    }

    const getEditorToolbar = (toolbarType: EditorToolbarType) => {
        switch (toolbarType) {
            case "custom":
                return props.toolbarButtons || {};
            case "rich":
                return filterButtons(EditorToolbar.RichToolbar);
            case "standard":
                return filterButtons(EditorToolbar.StandardToolbar);
            case "basic":
            default:
                return filterButtons(EditorToolbar.BasicToolbar);
        }
    }

    function controlDuplicatedEditors() {
        if (editorContainerRef) {
            let frBoxList = Array.from(editorContainerRef.current.children).filter(child => Array.from(child.classList)?.includes("fr-box"));

            if (frBoxList?.length > 1) {
                frBoxList[0].remove();
            }
        }
    }

    const [config, setConfig] = React.useState({
        key: LicenseKeys.FROALA_LICENCE_KEY,
        attribution: false,
        events: {
            initialized: function () {
                var editor = this;

                if (props.tributeObjects?.length) {
                    props.tributeObjects.forEach(tribute => {
                        tribute.attach(editor.el);
                    })
                    editor.events.on('keydown', function (e: any) {
                        let anyActiveTribue = props.tributeObjects.findIndex(t => t.isActive) > -1;
                        if (e.which == 13 && (anyActiveTribue)) {
                            return false;
                        }
                    }, true);
                }
                setEditor(editor);
                if (props.readOnly) {
                    editor.edit.off();
                }
                controlDuplicatedEditors()
            }
        },
        placeholderText: typeof props.placeHolder === "string" ? props.placeHolder : null,
        heightMin: props.minHeight,
        heightMax: props.maxHeight || 100,
        height: props.height,
        charCounterCount: props.charCounterCount,
        toolbarButtons: getEditorToolbar(props.toolbarType),
        toolbarBottom: props.toolbarBottom,
        language: props.language?.slice(0, 2) || localStorage.getItem("locale")?.slice(0, 2),
        linkList: [],
        quickInsertButtons: ['table', 'ul', 'ol', 'hr'],
        charCounterMax: props.charCounterMax || -1,
        scrollableContainer: props.scrollableContainer || 'body',
        zIndex: props.zIndex || 1,
        linkStyles: {
            'fr-strong': findMessage.get('102423')
        },
        paragraphStyles: {
            'fr-text-bordered': findMessage.get('102416'),
            'fr-text-spaced': findMessage.get('102417'),
            'fr-text-uppercase': findMessage.get('102418')
        },
        imageStyles: {
            'fr-bordered': findMessage.get('102416'),
            'fr-shadow': findMessage.get('102419')
        },
        helpSets: GetEditorHelpSet(),
        pluginsEnabled: enabledPlugins,
        toolbarSticky: props.toolbarSticky == undefined ? true : props.toolbarSticky,
        linkAlwaysBlank: true
    })

    const updateConfig = React.useMemo(() => {
        return _debounce((_config: any, _props: IEditorProps) => {
            setConfig({
                ..._config,
                heightMin: _props.minHeight,
                heightMax: _props.maxHeight,
                height: _props.height,
                toolbarButtons: getEditorToolbar(_props.toolbarType),
                toolbarBottom: _props.toolbarBottom,
                language: _props.language?.slice(0, 2),
                charCounterMax: _props.charCounterMax || -1,
                charCounterCount: _props.charCounterCount
            })
        }, 500)
    }, []);

    React.useEffect(() => {
        updateConfig(config, props);
    }, [props.minHeight, props.maxHeight, props.height, props.toolbarBottom, props.toolbarType, props.language, props.charCounterMax, props.charCounterCount])

    React.useEffect(() => {
        if (editor) {
            editor.opts.placeholderText = props.placeHolder;
            editor.placeholder.refresh();
        }
    }, [props.placeHolder]);

    const [content, setContent] = React.useState<any>(props.mlModel ? props.mlModel[props.currentLanguage.culture] : props.model);

    const onModelChange = (_model: string) => {
        setContent(_model);
        if (props.mlModel && props.onMLModelChange) {
            props.onMLModelChange(props.currentLanguage.culture, _model);
        } else if (props.onModelChange) {
            props.onModelChange(_model);
        }
    }

    React.useEffect(() => {
        setContent(props.mlModel ? props.mlModel[props.currentLanguage.culture] : props.model)
    }, [props.model, props.mlModel])

    React.useEffect(() => {
        if (props.readOnly) {
            editor?.edit.off();
        } else {
            editor?.edit.on();
        }
    }, [props.readOnly])

    React.useEffect(() => {
        if (props.mlModel) {
            Froalaeditor.DefineIcon('iconLanguage', { NAME: "iconLanguage", SRC: `./${iconLanguage.default}`, template: 'image' });
            Froalaeditor.RegisterCommand('ML', {
                title: "ML",
                icon: "iconLanguage",
                refreshAfterCallback: false,
                callback: function () {
                    setMlVisible(true);
                }
            });
        }
    }, [])


    const onMLValuChange = (culture: string, value: string) => {
        if (props.onMLModelChange) props.onMLModelChange(culture, value);
    }

    const onModalClose = () => { setMlVisible(false) }

    const renderMLModal = () => {
        return <EditorMLModal
            visible={mlVisible}
            values={props.mlModel}
            onValueChange={onMLValuChange}
            closeModal={onModalClose}
            supportedLanguages={props.supportedLanguages}
            currentLanguage={props.currentLanguage}
            config={config}
        />
    }

    const renderEditor = () => {
        if (props.viewerMode || props.disabled) {
            return (
                <FroalaEditorView
                    model={content}
                />
            )
        } else {
            return (
                <FroalaEditor
                    ref={froalaRef}
                    key={Hash(config)}
                    tag='textarea'
                    config={config}
                    model={content}
                    onModelChange={onModelChange}
                />
            )
        }
    }

    let className = cx({
        htmlTextBoxStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center"
    })

    return (
        <div ref={editorContainerRef} className={[className, props.className].join(' ')} style={props.style}>
            {renderEditor()}
            {renderMLModal()}
        </div>
    )
})

type BCEditorRef = { focus: () => void, froalaRef: Froalaeditor }
export default Editor;
export { Tribute, BCEditorRef }