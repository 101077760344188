import { createMutableAction } from "@bimser/core";
import { ISignDocumentPayload, OpenSignatureModalPayload } from "../entities";
import { CLOSE_SIGNATURE_MODAL_ACTION, OPEN_SIGNATURE_MODAL_TRANSACTION, SIGN_DOCUMENT_TRANSACTION } from './actionTypes';

export const openSignaureModalTransaction = (payload: OpenSignatureModalPayload) => {
    return createMutableAction(OPEN_SIGNATURE_MODAL_TRANSACTION, payload);
}

export const closeSignatureModalAction = (result: boolean) => {
    return createMutableAction(CLOSE_SIGNATURE_MODAL_ACTION, result);
}

export const onSignDocumentTransaction = (payload: ISignDocumentPayload) => {
    return createMutableAction(SIGN_DOCUMENT_TRANSACTION, payload);
}