import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IPositionFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';


const PositionFormContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function PositionFormContainer(props: React.PropsWithChildren<IPositionFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <PositionFormContainerLazy {...props} />
        </Suspense>
    );
}
PositionFormContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default PositionFormContainer;