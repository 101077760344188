import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseDashboardFormData {
    projectName: string;
    formName: string;
    secretKey: string;
    deploymentUrl: string;
}

export interface IDashboardFormData extends IBaseDashboardFormData {
    
}

export interface IReadonlyDashboardFormData extends IBaseDashboardFormData {
    
}

export type DashboardFormData = ReadonlyRecord<IReadonlyDashboardFormData>;

export const DashboardFormDataFactory = Record<IReadonlyDashboardFormData>({
    projectName: "",
    formName: "",
    secretKey: "",
    deploymentUrl: ""
});

export const DashboardFormDataFactoryFromJS = function (data: IDashboardFormData) {
    return DashboardFormDataFactory(data);
}