import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseLoginWithTokenPayload {
    token: string,
    rememberMe: boolean,
    language: string
}

export interface ILoginWithTokenPayload extends IBaseLoginWithTokenPayload {}

export interface IReadonlyLoginWithTokenPayload extends IBaseLoginWithTokenPayload {}

export type LoginWithTokenPayload = ReadonlyRecord<IReadonlyLoginWithTokenPayload>;

export const LoginWithTokenPayloadFactory = Record<IReadonlyLoginWithTokenPayload>({
    token: null,
    rememberMe: false,
    language: 'en-US'
});

export const LoginWithTokenPayloadFactoryFromJS = function (data: ILoginWithTokenPayload) {
    return LoginWithTokenPayloadFactory(data);
}