import { connect } from "react-redux";
import PlantForm from "../components";
import { closeHRFormTransaction, saveHRFormTransaction } from "../../../../actions"
import IPlantFormProps from "../entities/IProps";
import { WebInterfaceBaseState } from "../../../../../../common/base/state";
import { IWebInterfaceAction } from "../../../../../../common/entities";
import { getCompanies } from "../../../../selectors/modulesSelector";

const mapStateToProps = function (state: WebInterfaceBaseState, props: IPlantFormProps): IPlantFormProps {
    return {
        companies: getCompanies(state)
    };
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IPlantFormProps {
    return {
        onClose: function(data: IWebInterfaceAction) {
            dispatch(closeHRFormTransaction(data));
        },
        onSave: function(data: IWebInterfaceAction) {
            dispatch(saveHRFormTransaction(data));
        }
    };
}

// react - redux connection
const PlantFormContainer = connect(mapStateToProps, mapDispatchToProps)(PlantForm);

export default PlantFormContainer;