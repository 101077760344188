import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { fromJS, Map, Record } from "immutable";
import { DMExplorerState, DMExplorerStateFactory, DMExplorerStateFactoryFromJS, IDMExplorerState } from "../components/DMExplorer/entities/DMExplorerState";
import { IViewerState, ViewerState, ViewerStateFactory, ViewerStateFactoryFromJS } from "../components/dmViewer/entities";
import { ExtremeGridsState, ExtremeGridsStateFactory, ExtremeGridsStateFactoryFromJS, IExtremeGridsState } from "../components/ExtremeGrid/entities";
import { FormsState, FormsStateFactoryFromJS, IFormsState } from "../components/Forms/entities/IFormsState";
import { ILoginState, LoginState, LoginStateFactory, LoginStateFactoryFromJS } from "../components/Login/entities/ILoginState";
import { IModalManagerState, ModalManagerState, ModalManagerStateFactory, ModalManagerStateFactoryFromJS } from "../components/ModalManager/entities/IModalManagerState";
import { IUITourState, UITourState, UITourStateFactory, UITourStateFactoryFromJS } from '../components/UITour/entities/UITourState';
import { IUploadManagerState, UploadManagerState, UploadManagerStateFactory, UploadManagerStateFactoryFromJS } from '../components/UploadManager/entities/UploadManagerState';
import { ISystemState, SystemState, SystemStateFactory, SystemStateFactoryFromJS } from "../system/entities";

export interface IBaseState {
    user: ILoginState,
    modals: IModalManagerState,
    i18n: IDictionary<IDictionary<string>>;
    DMExplorer: IDMExplorerState;
    forms?: IDictionary<IFormsState>;
    system: ISystemState;
    ExtremeGrid: IExtremeGridsState;
    UploadManager: IUploadManagerState;
    UITour: IUITourState;
    Viewers: IViewerState;
    environment: "IDE" | "WebInterface";
}

export interface IReadonlyBaseState {
    user: LoginState,
    modals: ModalManagerState,
    i18n: Map<string, Map<string, string>>;
    DMExplorer: DMExplorerState;
    forms?: Map<string, FormsState>;
    system: SystemState;
    ExtremeGrid: ExtremeGridsState;
    UploadManager: UploadManagerState;
    UITour: UITourState;
    Viewers: ViewerState;
    environment: "IDE" | "WebInterface";
}

export type BaseState = ReadonlyRecord<IReadonlyBaseState>;

export const BaseStateFactory = Record<IReadonlyBaseState>({
    user: LoginStateFactory(),
    modals: ModalManagerStateFactory(),
    i18n: Map(),
    DMExplorer: DMExplorerStateFactory(),
    forms: Map(),
    system: SystemStateFactory(),
    ExtremeGrid: ExtremeGridsStateFactory(),
    UploadManager: UploadManagerStateFactory(),
    UITour: UITourStateFactory(),
    Viewers: ViewerStateFactory(),
    environment: null
});

export const BaseStateFactoryFromJS = function (data: IBaseState) {

    let forms: IDictionary<FormsState> = {};

    for (let key in data.forms) {
        let item = data.forms[key];
        forms[key] = FormsStateFactoryFromJS(item);
    }

    return BaseStateFactory({
        user: LoginStateFactoryFromJS(data.user),
        modals: ModalManagerStateFactoryFromJS(data.modals),
        i18n: fromJS(data.i18n),
        DMExplorer: DMExplorerStateFactoryFromJS(data.DMExplorer),
        forms: Map(forms),
        system: SystemStateFactoryFromJS(data.system),
        ExtremeGrid: ExtremeGridsStateFactoryFromJS(data.ExtremeGrid),
        UploadManager: UploadManagerStateFactoryFromJS(data.UploadManager),
        UITour: UITourStateFactoryFromJS(data.UITour),
        Viewers: ViewerStateFactoryFromJS(data.Viewers),
        environment: data.environment
    });
}