import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record, List } from "immutable";
import { DmScene, DmSceneFactoryFromJS, IDmScene } from './IDmScene';
import { DmSceneHistory, DmSceneHistoryFactory, DmSceneHistoryFactoryFromJS, IDmSceneHistory } from './IDmSceneHistory';

export interface IBaseDmPanel {
    id: string,
    isLoading: boolean;
    isSidebarCollapsed: boolean;
}

export interface IDmPanel extends IBaseDmPanel {
    //context: IDmContext,
    scenes: IDictionary<IDmScene>,
    sceneHistory: IDmSceneHistory,
    dmViewerIsActive?: boolean,
    dmViewerPanelId?: string,
    sidebarExpandedKeys?: string[]
}

export interface IReadonlyDmPanel extends IBaseDmPanel {
    //context: DmContext,
    scenes: Map<string, DmScene>,
    sceneHistory: DmSceneHistory,
    dmViewerIsActive?: boolean,
    dmViewerPanelId?: string,
    sidebarExpandedKeys?: List<string>
}

export type DmPanel = ReadonlyRecord<IReadonlyDmPanel>;

export const DmPanelFactory = Record<IReadonlyDmPanel>({
    //context: DmContextFactory(),
    isLoading: false,
    scenes: Map<string, DmScene>(),
    sceneHistory: DmSceneHistoryFactory(),
    dmViewerIsActive: false,
    dmViewerPanelId: "",
    sidebarExpandedKeys: List(),
    id: null,
    isSidebarCollapsed: window.innerWidth <= 500
});

export const DmPanelFactoryFromJS = function (state: IDmPanel) {

    let _scenes: IDictionary<DmScene> = Object.keys(state.scenes).reduce((previous: IDictionary<DmScene>, current) => {
        previous[current] = DmSceneFactoryFromJS(state.scenes[current]);
        return previous;
    }, {});

    return DmPanelFactory({
        //context: DmContextFactoryFromJS(state.context),
        isLoading: state.isLoading,
        sceneHistory: DmSceneHistoryFactoryFromJS(state.sceneHistory),
        scenes: Map(_scenes),
        dmViewerIsActive: state.dmViewerIsActive,
        dmViewerPanelId: state.dmViewerPanelId,
        id: state.id,
        isSidebarCollapsed: state.isSidebarCollapsed,
        sidebarExpandedKeys: List(state.sidebarExpandedKeys)
    });
}