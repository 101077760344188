enum IHumanResourceModule {
    Holding = "Holding",
    Company = "Company",
    Plant = "Plant",
    Organization = "Organization",
    Title = "Title",
    Position = "Position",
    User = "User"
}

export { IHumanResourceModule };