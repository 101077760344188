import { createSyncAction } from "@bimser/core";
import { PasswordRequirements, PasswordRequirementsFactoryFromJS, IPasswordRequirements } from "../entities";
import * as ActionTypes from './actionTypes';

export default function setPasswordRequirementsAction(data: IPasswordRequirements) {
    let payload : PasswordRequirements = PasswordRequirementsFactoryFromJS(data)

    let action = createSyncAction(ActionTypes.SET_PASSWORD_REQUIREMENTS_ACTION, payload);
    
    return action;
}