import { IAddViewerFilePayload, IUpdateShowingViewerFilePayload, IChangeViewerFileDataPayload, ISetLoadingPayload, ISetSkeletonTypePayload, IChangeUserSelectedViewerTypePayload } from '../entities/Payloads';
import { createMutableAction, createSyncAction } from '@bimser/core';
import * as ActionTypes from './actionTypes';
import { IViewerItem, ViewerItemFactoryFromJS } from '../entities';
import { List } from 'immutable';
import { ViewerConfigItem } from '../entities/IViewerConfigItem';

export function addViewerFileTransaction(payload: IAddViewerFilePayload) {
    return createMutableAction(ActionTypes.ADD_NEW_VIEWER_FILE_TRANSACTION, payload);
}

export function addViewerFileAndLoadTransaction(payload: IAddViewerFilePayload & { panelId: string }) {
    return createMutableAction(ActionTypes.ADD_NEW_VIEWER_FILE_AND_LOAD_TRANSACTION, payload);
}

export function removeViewerFileAction(viewerId: string) {
    return createMutableAction(ActionTypes.REMOVE_VIEWER_FILE_ACTION, viewerId);
}

export function addViewerFileAction(viewerFile: IViewerItem) {
    return createSyncAction(ActionTypes.ADD_NEW_VIEWER_FILE_ACTION, ViewerItemFactoryFromJS(viewerFile));
}

export function updateShowingViewerFileAction(payload: IUpdateShowingViewerFilePayload) {
    return createMutableAction(ActionTypes.UPDATE_SHOWING_FILE_ACTION, payload);
}

export function onChangeViewerFileDataAction(payload: IChangeViewerFileDataPayload) {
    return createMutableAction(ActionTypes.ON_CHANGE_VIEWER_FILE_DATA_ACTION, payload);
}

export function onChangeViewerDataAction(payload: IChangeViewerFileDataPayload) {
    return createMutableAction(ActionTypes.ON_CHANGE_VIEWER_DATA_ACTION, payload);
}

export function setDmViewerLoadingAction(payload: ISetLoadingPayload) {
    return createMutableAction(ActionTypes.SET_LOADING_ACTION, payload);
}

export function setDmViewerSkeletonTypeAction(payload: ISetSkeletonTypePayload) {
    return createMutableAction(ActionTypes.SET_SKELETON_TYPE_ACTION, payload);
}

export function removeDmViewerInstanceAction(panelId: string) {
    return createMutableAction(ActionTypes.REMOVE_INSTANCE_ACTION, panelId);
}

export function createDmViewerInstanceAction(panelId: string) {
    return createMutableAction(ActionTypes.CREATE_NEW_INSTANCE_ACTION, panelId);
}

export function onChangeUserSelectedViewerType(payload: IChangeUserSelectedViewerTypePayload) {
    return createMutableAction(ActionTypes.ON_CHANGE_USER_SELECTED_VIEWER_TYPE_ACTION, payload);
}

export function setViewersConfigAction(payload: List<ViewerConfigItem>) {
    return createSyncAction(ActionTypes.SET_VIEWERS_CONFIG_ACTION, payload);
}