import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";

export interface IBaseFields {
    fieldId?: string
    fieldName?: string
}

export interface IFieldsContent extends IBaseFields {
}

export interface IReadonlyFields extends IBaseFields {
}

export type FieldsContent = ReadonlyRecord<IReadonlyFields>;

export const FieldsFactory = Record<IReadonlyFields>({
    fieldId: "",
    fieldName: "",
});

export const FieldsContentFactoryFromJS = function (data: IFieldsContent) {
    return FieldsFactory(data);
}