import { connect } from 'react-redux';
import DMUploadPopover from '../components';
import IDmUploadPopoverProps from '../entities/IDmUploadPopoverProps';
import { getUploadFiles } from '../selectors/getUploadFiles';
import { clearUploadListAction } from '../actions/clearUploadListAction';
import { resumeFileUploadAction } from '../actions/resumeFileUploadAction';
import { IUploadPausePayload } from '../entities/IUploadPausePayload';
import { pauseFileUploadAction } from '../actions/pauseFileUploadAction';
import { BaseState } from '../../..';
import { copyFileLinkAction } from '../actions/copyFileLinkAction';

const mapStateToProps = function (state: BaseState, props: IDmUploadPopoverProps): IDmUploadPopoverProps {
    return {
        files: getUploadFiles(state)
    }
}

const mapDispatchToProps = function (dispatch: Function): IDmUploadPopoverProps {
    return {
        clear: function (hash: string) {
            dispatch(clearUploadListAction(hash))
        },
        continue: function (payload: IUploadPausePayload) {
            dispatch(resumeFileUploadAction(payload))
        },
        pause: function (payload: IUploadPausePayload) {
            dispatch(pauseFileUploadAction(payload))
        },
        copyLink: function (payload: IUploadPausePayload) {
            dispatch(copyFileLinkAction(payload))
        }
    }
}


const UploadManagerContainer = connect(mapStateToProps, mapDispatchToProps)(DMUploadPopover);

export default UploadManagerContainer;