import { IDmData, panelType } from '@bimser/common';
import { BCCard, BCList, BCSkeleton, ContentType, findMessage } from '@bimser/components';
import React from 'react';
import Style from '../../assets/style.scss';
import { getIcon } from '../../../dm/utils';
import IRecentFilesProps from '../../entities/widgets/IRecentFilesProps';
import IconGo from "@bimser/icons/16/go";
import IconHover from "@bimser/icons/16/sub-menu-arrow";
import moment from 'moment';

const recentFilesEmpty = require("../../../../assets/recent-files-empty.svg");

export default (props: IRecentFilesProps) => {

    const onClickFile = (file: IDmData) => {
        props.onClickFile({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: file
        })
    }

    const onClickAllFiles = () => {
        props.onClickAllFiles({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: "#Recent"
        })
    }

    const renderIcon = (item: IDmData) => getIcon(item.type, null, item.name, item.existThumbnail ? item.secretKey : undefined);

    const renderCustomEmpty = () => (
        <div className={Style.emptyView}>
            <img src={recentFilesEmpty.default} className={Style.emptyViewImg} />
            <span className={Style.emptyViewTitle}>{findMessage.get("102975")}</span>
            <span className={Style.emptyViewDesc}>{findMessage.get("102976")}</span>
        </div>
    )

    const renderItem = (item: IDmData) => (
        <div className={Style.recentFileItemContainer} onClick={() => onClickFile(item)}>
            <div className={Style.recentFileItem}>
                <div className={Style.recentFileItemIcon}>
                    {renderIcon(item)}
                </div>
                <div className={Style.recentFileItemText}>
                    <span className={Style.recentFileItemName}>{item.name}</span>
                    <span className={Style.recentFileItemCreateInfo} title={moment(item.recentCreatedAt).format("L LTS")}>{findMessage.get("102978").replace("${0}", moment(item.recentCreatedAt).fromNow())}</span>
                </div>
            </div>
            <IconHover className={Style.iconHover}/>
        </div>
    )

    const renderHeader = (
        <div className={Style.recentFilesHeader}>
            <span className={Style.widgetHeader}>{findMessage.get("100861")}</span>
            {props.data && <span className={Style.recentFilesShowAll} onClick={onClickAllFiles} >{findMessage.get("102977")} <IconGo width="14px" height="14px" /></span>}
        </div>
    )
    
    
    return (
        <BCCard
            bordered={false}
            className={Style.recentFilesContainer}
            title={renderHeader}
        >
            <BCSkeleton
                loadingStatus={props.loading}
                contentType={ContentType.DashboardRecentFilesList}
            >
                <BCList
                    bordered={false}
                    dataSource={props.data}
                    customEmpty={renderCustomEmpty()}
                    renderItem={renderItem}
                    className={Style.recentFilesList}
                />
            </BCSkeleton>
        </BCCard>
    )
}