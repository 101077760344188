import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { List, Map, Record } from 'immutable';
import { IBaseFormContent } from "modules/hr/entities/Interfaces";
import IFlowDocumentFormData from './IFlowDocumentFormData';
import { FlowPropertiesItem, FlowPropertiesItemFactoryFromJS, IFlowPropertiesItem } from "./IFlowProperties";
import { FlowStartParametersResponse, FlowStartParametersResponseFactory, FlowStartParametersResponseFactoryFromJS, IFlowStartParametersResponse } from "./IFlowStartParametersResponse";
import { FLowViewerMenuItemsVisibility, FlowViewerMenuItemsVisibilityFactory, FlowViewerMenuItemsVisibilityFactoryFromJS, IFlowViewerMenuItemsVisibility } from "./IFlowViewerMenuItemsVisibility";
import { IFlowDocument, FlowDocument, FlowDocumentFactoryFromJS } from './models/IFlowDocument';
import { IFlowEvent, FlowEvent, FlowEventFactoryFromJS } from './models/IFlowEvent';
import { FormsEntities } from '@bimser/common';

export interface IBaseFlowViewerPanelContent extends IBaseFormContent {
    showLoading?: boolean
    loadingProperties?: boolean
    loadingDocuments?: boolean,
    loadingFlowHistory?: boolean,
    counterModal?: boolean,
    isSuccess?: boolean,
    hasCheckInObject?: boolean,
    expire?: string;
    projectPath?: string,
    clientUrl?: string,
    activeDocumentName?: string, //documents dizisindeki aktif doküman adı
    childPanelGuid?: string,
    childPanelResponse?: any,
    openedOn?: FormsEntities.ChildFormShowType;
}
export interface IFlowViewerPanelContent extends IBaseFlowViewerPanelContent {
    events?: Array<IFlowEvent>
    properties?: Array<IFlowPropertiesItem>,
    menuItemsVisibility?: IFlowViewerMenuItemsVisibility
    documents?: Array<IFlowDocument>,
    flowStartParameters?: IFlowStartParametersResponse,
    documentsForms?: IDictionary<IFlowDocumentFormData>
}

export interface IReadonlyFlowViewerPanelContent extends IBaseFlowViewerPanelContent {
    events?: List<FlowEvent>,
    properties?: List<IFlowPropertiesItem>,
    documents?: List<FlowDocument>,
    menuItemsVisibility?: FLowViewerMenuItemsVisibility,
    flowStartParameters?: FlowStartParametersResponse,
    documentsForms?: Map<string, IFlowDocumentFormData>
}

export type FlowViewerPanelContent = ReadonlyRecord<IReadonlyFlowViewerPanelContent>;
export const FlowViewerPanelContentFactory = Record<IReadonlyFlowViewerPanelContent>({
    panelId: '',
    events: List(),
    properties: null,
    documents: List(),
    showLoading: true,
    loadingProperties: true,
    loadingDocuments: false,
    loadingFlowHistory: true,
    counterModal: true,
    isSuccess: false,
    hasCheckInObject: false,
    menuItemsVisibility: FlowViewerMenuItemsVisibilityFactory(),
    flowStartParameters: FlowStartParametersResponseFactory(),
    expire: "",
    projectPath: "",
    clientUrl: "",
    documentsForms: Map<string, IFlowDocumentFormData>(),
    activeDocumentName: "",
    childPanelGuid: "",
    childPanelResponse: null,
    openedOn: "panel"
});

export const FlowViewerPanelContentFactoryFromJS = function (data: IFlowViewerPanelContent) {
    let _properties: Array<FlowPropertiesItem> = data && data.properties ? data.properties.map((item: IFlowPropertiesItem) => {
        return FlowPropertiesItemFactoryFromJS(item);
    }) : [];

    let _documents = data && data.documents ? data.documents.map(d => FlowDocumentFactoryFromJS(d)) : [];
    let _events = data && data.events ? data.events.map(e => FlowEventFactoryFromJS(e)) : [];

    return FlowViewerPanelContentFactory({
        panelId: data.panelId,
        events: List(_events),
        properties: List(_properties),
        showLoading: data.showLoading,
        documents: List(_documents),
        loadingDocuments: data.loadingDocuments,
        loadingProperties: data.loadingProperties,
        loadingFlowHistory: data.loadingFlowHistory,
        counterModal: data.counterModal,
        isSuccess: data.isSuccess,
        hasCheckInObject: data.hasCheckInObject,
        menuItemsVisibility: FlowViewerMenuItemsVisibilityFactoryFromJS(data.menuItemsVisibility),
        flowStartParameters: FlowStartParametersResponseFactoryFromJS(data.flowStartParameters),
        expire: data.expire,
        projectPath: data.projectPath,
        clientUrl: data.clientUrl,
        documentsForms: Map(data.documentsForms),
        activeDocumentName: data.activeDocumentName,
        childPanelGuid: data.childPanelGuid,
        childPanelResponse: data.childPanelResponse,
        openedOn: data.openedOn
    });
}
