import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';
import { UploadManagerStatus } from '../../../utilities/ResumableUploadManager';
import { IDmData, DmData, DmDataFactoryFromJS } from '../../../entities/IDmData';

interface IBaseUploadFileInfo{
    size?: number,
    type?: string,
    name?: string,
    lastModified?: number //Unix time
    status?: UploadManagerStatus,
    //folderId?: string,
    uploadedSize?: number,
    path?: string,
}

export interface IUploadFileInfo extends IBaseUploadFileInfo {
    dmData?: IDmData
}

export interface IReadonlyUploadFileInfo extends IBaseUploadFileInfo {
    dmData?: DmData   
}

export type UploadFileInfo = ReadonlyRecord<IReadonlyUploadFileInfo>;

export const  UploadFileInfoFactory = Record<IReadonlyUploadFileInfo>({
    size: 0,
    type: null,
    name: null,
    lastModified: 0,
    //folderId: null,
    status: UploadManagerStatus.Preparing,
    dmData: null,
    uploadedSize: 0,
    path: ""
});

export const UploadFileInfoFactoryFromJS = function (data: IUploadFileInfo) {
    return UploadFileInfoFactory({
        ...data,
       dmData: data.dmData ? DmDataFactoryFromJS(data.dmData) : null
    });
}