import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ILayoutDataItem, IReadonlyLayoutDataItem, LayoutDataItemFactoryObjectFromJS, LayoutDataItemRecord } from "./ILayoutDataItem";
import { ILayoutStructureItem, IReadonlyLayoutStructureItem, LayoutStructureItemFactoryObjectFromJS, LayoutStructureItemRecord } from "./ILayoutStructureItem";

export type ILayoutItem = ILayoutDataItem & ILayoutStructureItem;

export type IReadonlyLayoutItem = IReadonlyLayoutDataItem & IReadonlyLayoutStructureItem;

export type LayoutItem = ReadonlyRecord<IReadonlyLayoutItem>;

export const LayoutItemFactory = Record<IReadonlyLayoutItem>({
    ...LayoutDataItemRecord,
    ...LayoutStructureItemRecord
});

export const LayoutItemFactoryFromJS = function (data: ILayoutItem) {

    return LayoutItemFactory({
        ...LayoutDataItemFactoryObjectFromJS(data),
        ...LayoutStructureItemFactoryObjectFromJS(data)
    });

}