import React, { useEffect, useState } from 'react';
import { IconInfo } from '@bimser/icons';
import { Select } from 'antd';
import { BCIconFinder } from '../';
import { BCIconSelectProps } from './entities/BCIconSelectProps';
import Styles from "./assets/Styles.module.scss";

export default function BCIconSelect(props: BCIconSelectProps) {
    const [value, setValue] = useState(props.value);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    function onChange(icon: IconInfo) {
        setValue(icon.name);
        setOpen(false);
        props.onChange(icon.name);
    }

    function getIconPicker() {
        return (
            <div className={Styles.IconSelectContainer} >
                <BCIconFinder onChange={onChange} size={16} />
            </div>
        );
    }

    return (
        <Select
            className={[Styles.IconSelect, props.className].join(" ")}
            dropdownClassName={Styles.IconSelectDropdown}
            value={value}
            dropdownRender={getIconPicker}
            open={open}
            onDropdownVisibleChange={setOpen}
        />
    );
}

