import DatePicker from 'antd/lib/date-picker';
import * as moment from 'moment';
import * as React from "react";
import * as Styles from './assets/styles.scss';
import { IDatePickerProps } from './entities';
import { getPopupContainer } from "@bimser/core";
import { CommonPickerMethods } from 'antd/lib/date-picker/generatePicker/interface';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

type BCDatePickerRef = React.Component<PickerProps<moment.Moment> & { status?: "" | "warning" | "error"; }, unknown, any> & CommonPickerMethods;

const BCDatePicker = React.memo(React.forwardRef<BCDatePickerRef, IDatePickerProps>((props, ref) => {

    Styles;

    const onChange = (date: moment.Moment, dateString: string) => {
        if (props.onChange) {
            const shortISODate = moment(date).format().split("T")[0];
            const longISODate = moment(date).format().split("+")[0];
            const dateTimeOffsetString = moment(date).format();
            props.onChange(date, date && date.format(), dateString, shortISODate, longISODate, dateTimeOffsetString);
        }
    }

    const className = cx({
        datePickerStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center"
    });

    return (
        <DatePicker
            autoFocus={props.autoFocus}
            className={[className, props.className].join(' ')}
            defaultValue={props.defaultValue}
            onChange={onChange}
            placeholder={props.placeholder}
            size={props.size}
            disabled={props.disabled}
            disabledDate={props.disabledDate}
            showTime={props.showTime}
            showToday={props.showToday}
            format={props.format || moment().localeData().longDateFormat('L')}
            style={props.style}
            value={props.value}
            ref={ref}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onKeyDown={props.onKeyDown}
            allowClear={props.allowClear && !props.readOnly}
            getPopupContainer={props.getPopupContainer || getPopupContainer}
            open={props.readOnly ? false : undefined}
            inputReadOnly={props.readOnly}
            disabledTime={props.disabledTime}
            dropdownClassName={props.dropdownClassName}
        />
    )
}))

export default BCDatePicker
export { IDatePickerProps, BCDatePickerRef }