import { call } from 'redux-saga/effects';
import { ICreateExtremeGridPayload } from "../entities/payloads";
import { IExtremeGridCreateSettings, IExtremeGridInstances, Events, IExtremeGridLoadOptions } from "../entities/api";
import { createExtremeGrid, createExtremeGridOverride } from "../actions";
import { IBaseAction } from "@bimser/core";
import ExtremeGridInstance from "./ExtremeGridInstance";
import { BaseState } from '../../../entities/BaseState';

export class ExtremeGridAPI {
    static store: any;
    static instances: IExtremeGridInstances = {};

    static create(extremeGridOptions: ICreateExtremeGridPayload, settings?: IExtremeGridCreateSettings) {
        let action = null;
        if (settings?.override) {
            action = createExtremeGridOverride(extremeGridOptions);
        }
        else {
            action = createExtremeGrid(extremeGridOptions);
        }
        ExtremeGridAPI.dispatch(action);
    }

    static get(id: string) {
        if (!ExtremeGridAPI.instances[id]) {
            ExtremeGridAPI.instances[id] = new ExtremeGridInstance(id);
        }
        return ExtremeGridAPI.instances[id];
    }

    static getProperties(id: string): any {
        if (ExtremeGridAPI.instances[id]) {
            let state: BaseState = ExtremeGridAPI.store.getState();
            if (state.ExtremeGrid?.items.has(id)) {
                let extremeGrid: any = state.ExtremeGrid.items.get(id);
                return extremeGrid;
            }
        }
        else {
            return null;
        }
    }

    static toEndUser() {
        return {
            create: ExtremeGridAPI.create,
            get: ExtremeGridAPI.get,
            getProperties: ExtremeGridAPI.getProperties
        };
    }

    static emit(id: string, eventName: Events, data: any) {
        let ins = ExtremeGridAPI.instances[id];
        if (ins) {
            ins.emit(eventName, data);
        }
    }

    static *loadDataSource(id: string, loadOptions: IExtremeGridLoadOptions) {
        let ins = ExtremeGridAPI.instances[id];
        if (ins) {
            return yield call({ context: ins.dataSource, fn: ins.dataSource.load }, loadOptions);
        }
    }

    static getStore() {
        if (!ExtremeGridAPI.store) {
            throw new Error("Store cannot be null");
        }
        return ExtremeGridAPI.store;
    }
    static dispatch(action: IBaseAction<any>) {
        ExtremeGridAPI.getStore().dispatch(action);
    }
}

export default ExtremeGridAPI.toEndUser();