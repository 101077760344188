import { findMessage } from '@bimser/components';
import { base64Converter, createGuid } from '@bimser/core';
import { ServiceList } from '../../services/serviceList';
import * as DSEntities from './entities';
import { ICertificate, IFinalizeSigningPayload, ISignPayload, ITerminal, SignatureDataType, SignatureFormat } from './entities';

//@ts-ignore
const PWSigner = pwsigner;
export const DSLoginData = "Bu imza 5070 sayılı elektronik imza kanununa göre güvenli elektronik imzadır.";
class DigitalSignatureAPI {

    private isInitialized = false;
    private useOnlyQualifiedCertificates = true;

    ErrorCodes = {
        SIGNER_NOT_DEFINED: 10001,
        CARD_READER_NOT_SELECTED: 10002,
        CARD_READER_NOT_FOUND: 10003,
        CERTIFICATE_NOT_SELECTED: 10004,
        CERTIFICATE_NOT_FOUND: 10005,
        PASSWORD_NULL_OR_EMPTY: 10006,
        SIGN_FAILED: 10007,
        INVALID_PARAMETER: 10008,
        SIGNER_INITIALIZED_ERROR: 10009,
        SERVER_ERROR: 10010,
        SERVER_ERROR_DETAIL: 10011,
        AUTHENTICATION_FAILED: 10012,
        SIGNATURE_EXPIRED: 10013,
        SIGNER_NOT_INITIALIZED: 10014,
        SIGNER_INITIALIZE_TIMEOUT: 10015,
        PWSIGNER_DOMAIN_ERROR: 10016,
        INVALID_PIN: 10017,
        EXPIRED_PIN: 10018,
        BLOCKED_PIN: 10019,
        LICENCE_KEY_NOT_PROVIDED: 10020
    }

    static ErrorMessages: any = {
        10001: findMessage.get('102145'),
        10002: findMessage.get('102146'),
        10003: findMessage.get('102147'),
        10004: findMessage.get('102148'),
        10005: findMessage.get('102149'),
        10006: findMessage.get('102150'),
        10007: findMessage.get('102151'),
        10008: findMessage.get('102152'),
        10009: findMessage.get('102153'),
        10010: findMessage.get('102154'),
        10011: findMessage.get('102155'),
        10012: findMessage.get('102156'),
        10013: findMessage.get('102157'),
        10014: findMessage.get('102158'),
        10015: findMessage.get('102159'),
        10016: findMessage.get('102160'),
        10017: findMessage.get('102161'),
        10018: findMessage.get('102162'),
        10019: findMessage.get('102163'),
        10020: findMessage.get('102164')
    }

    initializeForLogin(licenceKey: string) {
        return new Promise<void>((resolve, reject) => {
            if (!licenceKey) {
                reject({ code: this.ErrorCodes.LICENCE_KEY_NOT_PROVIDED });
            }
            if (PWSigner) {
                if (!PWSigner.initializedSuccesfully) {
                    this.useOnlyQualifiedCertificates = false;

                    PWSigner.setLicenseKey(licenceKey);
                    let initializeTimeout = setTimeout(() => {
                        reject({ code: this.ErrorCodes.SIGNER_INITIALIZE_TIMEOUT });
                    }, 25000);
                    PWSigner.initialize((code: any, json: any) => {
                        clearTimeout(initializeTimeout);
                        if (code == 0) {
                            this.isInitialized = true;
                            resolve();
                        } else if (code == 26) {
                            reject({ code: this.ErrorCodes.PWSIGNER_DOMAIN_ERROR });
                        } else {
                            reject({ code: this.ErrorCodes.SIGNER_INITIALIZED_ERROR });
                        }
                    })

                } else {
                    resolve();
                }
            } else {
                reject({ code: this.ErrorCodes.SIGNER_NOT_DEFINED });
            }
        })
    }

    initialize(callback?: (params: any) => void) {
        return new Promise((resolve, reject) => {
            if (PWSigner) {
                if (!PWSigner.initializedSuccesfully) {
                    ServiceList.main.Signature.GetDigitalSignatureParameters.callPromise(true).then(res => {
                        this.useOnlyQualifiedCertificates = res.useOnlyQualifiedCertificates;
                        if (callback) callback(res);

                        PWSigner.setLicenseKey(res.signComponentLicense);
                        let initializeTimeout = setTimeout(() => {
                            reject({ code: this.ErrorCodes.SIGNER_INITIALIZE_TIMEOUT });
                        }, 25000);
                        PWSigner.initialize((code: any, json: any) => {
                            clearTimeout(initializeTimeout);
                            if (code == 0) {
                                this.isInitialized = true;
                                resolve(true);
                            } else if (code == 26) {
                                reject({ code: this.ErrorCodes.PWSIGNER_DOMAIN_ERROR });
                            } else {
                                reject({ code: this.ErrorCodes.SIGNER_INITIALIZED_ERROR });
                            }
                        })
                    }).catch(err => {
                        reject({ code: this.ErrorCodes.SIGNER_INITIALIZED_ERROR });
                    })

                } else {
                    resolve(true);
                }
            } else {
                reject({ code: this.ErrorCodes.SIGNER_NOT_DEFINED });
            }
        })
    }

    getTerminalList() {
        return new Promise<ITerminal[]>((resolve, reject) => {
            if (PWSigner) {
                if (this.isInitialized) {
                    PWSigner.smartCard.listTerminals((code: any, json: any) => {
                        let terminals = this._parseTerminals(code, json);
                        resolve(terminals);
                    }, {})
                } else {
                    reject({ code: this.ErrorCodes.SIGNER_NOT_INITIALIZED });
                }
            } else {
                reject({ code: this.ErrorCodes.SIGNER_NOT_DEFINED });
            }
        })
    }

    getCertificateList(terminal: ITerminal) {
        return new Promise<ICertificate[]>((resolve, reject) => {
            if (PWSigner) {
                if (this.isInitialized) {
                    PWSigner.smartCard.listCertificates((code: any, json: any) => {
                        let certs = this._parseCertificates(code, json);

                        if (!this.useOnlyQualifiedCertificates) {
                            resolve(certs);
                            return;
                        }

                        let requestPayload = {
                            certificateHexs: certs.map(c => c.certificateHex)
                        }

                        ServiceList.main.Certificate.GetQualifiedCertificates.callPromise(requestPayload, true).then((response) => {
                            let _certDetails: any[] = response.certificateDetails;
                            certs = certs.filter(c => _certDetails.findIndex(_d => _d.serialNumber == c.serialNumber) > -1).map((c) => {
                                let _c = _certDetails.find(d => d.serialNumber == c.serialNumber);
                                if (_c) {
                                    c.moneyLimit = _c.moneyLimit;
                                    c.certificateHex = hexToBase64(c.certificateHex);
                                }
                                return c;
                            });
                            resolve(certs);
                        }).catch(err => {
                            reject({ code: -1 });
                        })
                    }, { library: terminal.library, slotId: terminal.slotId });
                } else {
                    reject({ code: this.ErrorCodes.SIGNER_NOT_INITIALIZED });
                }
            } else {
                reject({ code: this.ErrorCodes.SIGNER_NOT_DEFINED });
            }
        })
    }

    private getSignatureRequestFromFormat(signatureFormat: SignatureFormat, dataType: SignatureDataType, request: any) {
        switch (signatureFormat) {
            case SignatureFormat.CAdES: {
                if (dataType == SignatureDataType.Data) {
                    return ServiceList.main.Signature.CAdES.InitializeSigningWithData.callPromise(request, true);
                } else {
                    return ServiceList.main.Signature.CAdES.InitializeSigningWithDMObject.callPromise(request, true);
                }
            }
            case SignatureFormat.PAdES: {
                if (dataType == SignatureDataType.Data) {
                    return ServiceList.main.Signature.PAdES.InitializeSigningWithData.callPromise(request, true);
                } else {
                    return ServiceList.main.Signature.PAdES.InitializeSigningWithDMObject.callPromise(request, true)
                }
            }
            case SignatureFormat.XAdES: {
                if (dataType == SignatureDataType.Data) {
                    return ServiceList.main.Signature.XAdES.InitializeSigningWithData.callPromise(request, true);
                } else {
                    return ServiceList.main.Signature.XAdES.InitializeSigningWithDMObject.callPromise(request, true)
                }
            }
        }
    }

    private getFinalizeRequestFromFormat(request: any) {
        return ServiceList.main.Signature.FinalizeSigning.callPromise(request, true);
    }

    sign(payload: ISignPayload) {
        return new Promise<any>((resolveSign, rejectSign) => {
            if (!payload) {
                rejectSign();
            }
            const requestData = {
                certificateHex: payload.certificate.certificateHex,
                signatureType: payload.signatureType,
                embeddedSignature: payload.embeddedSignature,
                [payload.dataType === SignatureDataType.DmObject ? 'secretKey' : 'data']: payload.dataType === SignatureDataType.DmObject ? payload.secretKey : base64Converter.StringToBase64(DSLoginData),
                isParallel: payload.isParallel
            }

            if (payload.dataType === SignatureDataType.DmObject) {
                requestData.versionSecretKey = payload.versionSecretKey;
            }

            this.getSignatureRequestFromFormat(payload.signatureFormat, payload.dataType, requestData).then((initializeResponse: any) => {
                return resolveSign({ ...initializeResponse, digestToBeSigned: initializeResponse.digestBase64 });
            }).catch(err => {
                rejectSign({ code: this.ErrorCodes.SIGN_FAILED, detail: err });
            })
        })
    }

    final(payload: IFinalizeSigningPayload) {
        return new Promise<any>((resolveFinal, rejectFinal) => {
            if (!payload) {
                rejectFinal();
            }
            const requestData = {
                signature: payload.signature,
                transactionUUID: payload.transactionUUID,
                secretKey: payload.secretKey
            }

            this.getFinalizeRequestFromFormat(requestData).then(finalizeResponse => {
                if (payload.type === "dm")
                    resolveFinal(null);
                else if (finalizeResponse.signed)
                    return this._validateAuthentication(finalizeResponse.signatureData, resolveFinal, rejectFinal);
            }).catch(err => {
                rejectFinal(err);
            })
        })
    }

    dowloadCertificate(cert: ICertificate) {
        if (!cert || !cert.certificateHex) {
            throw { code: this.ErrorCodes.CERTIFICATE_NOT_FOUND };
        }
        let certificate = new Blob([cert.certificateHex], { type: "application/x-x509-ca-cert" });
        let link = document.createElement('a');
        link.href = URL.createObjectURL(certificate);
        link.download = "certificate.crt";
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
        }, 100);
    }

    private _validateAuthentication(signedData: any, resolve: Function, reject: Function) {
        let requestData = {
            language: "tr-TR",
            signatureData: signedData
        };

        ServiceList.login.Login.LoginWithDigitalSignature.callPromise(requestData, true).then((res) => {
            if (res) {
                resolve({
                    token: res.token,
                    tempTokenKey: res.tempTokenKey,
                    mfaEnabled: res.mfaEnabled
                });
            }
        }).catch(err => {
            reject({ code: this.ErrorCodes.AUTHENTICATION_FAILED, detail: err });
        })
    }

    private _validateParameters(term: ITerminal, cert: ICertificate, password: string) {
        if (!cert) {
            return {
                code: this.ErrorCodes.CERTIFICATE_NOT_FOUND,
                message: findMessage.get('102166')
            }
        }
        return true;
    }

    private _parseTerminals(code: any, json: any) {
        let terminals: ITerminal[] = [];
        if (code == 0) {
            let _terminals = PWSigner.parseJSON(json);
            for (let _terminal of _terminals) {
                terminals.push({
                    name: base64Converter.Base64ToString(_terminal.terminal),
                    library: base64Converter.Base64ToString(_terminal.library),
                    slotId: base64Converter.Base64ToString(_terminal.slotId),
                    id: createGuid()
                })
            }
        }
        return terminals;
    }

    private _parseCertificates(code: any, json: any) {
        let certificates: ICertificate[] = [];
        if (code == 0) {
            let _certificates = PWSigner.parseJSON(json);
            for (let _cert of _certificates) {
                certificates.push({
                    id: createGuid(),
                    name: base64Converter.Base64ToString(_cert.commonName),
                    serialNumber: base64Converter.Base64ToString(_cert.serialNumber),
                    certificateHex: _cert.certificateHex,
                    issuer: base64Converter.Base64ToString(_cert.issuer),
                    isExpired: base64Converter.Base64ToString(_cert.isExpired) === "true",
                    ownerName: base64Converter.Base64ToString(_cert.subjectCommonName),
                    ownerNo: base64Converter.Base64ToString(_cert.subjectSerialNumber)
                })
            }
        }
        return certificates;
    }

}

function hexToBase64(str: string) {
    return btoa(String.fromCharCode.apply(null,
        str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
}

export { DigitalSignatureAPI, DSEntities };

