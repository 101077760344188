import { createSyncAction } from "@bimser/core";
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "common/entities";
import * as actionTypes from './actionTypes';

/**
 * payload.data.type : 'add' || 'remove' || 'update'
 *
 * add      =>  type='add'
 *              newItem: IPermissionGroup
 *
 * update   =>  type='update'
 *              name: ML
 *              description: ML
 *
 * delete   =>  type='remove'
 *              secretKey:string
 *
 * @param data
 */
const updateGroupAction = (data: IWebInterfaceAction) => {
    return createSyncAction(actionTypes.UPDATE_GROUP_ACTION, WebInterfaceActionFactoryFromJS(data));
}

export default updateGroupAction;