import { BCAntPagination, BCButton, BCDropdown, BCLabel, findMessage, IDropdownSelectedChangedEventArgs } from '@bimser/components';
import { IItem, createGuid } from "@bimser/core";
import React from 'react';
import { DMExplorer } from '../../..';
import * as ClassNames from '../assets/styles.scss';
import { ExplorerItemType, IFilter, INavigationHistoryItem, INavigationItem, IPagination } from '../entities';
import { Extensions } from '../entities/Extensions';
import ICancelArgs from '../entities/ICancelArgs';
/**Sayfalandırma eklenmiş dm dosya seçimi komponenti */
export default class DMFileSelector extends React.Component<IProps & IEventProps, IState>{
    pageSize: number[] = [10, 25, 50, 100];

    constructor(props: IProps & IEventProps) {
        super(props);

        this.onChangedExtension = this.onChangedExtension.bind(this);
        this.onOkClick = this.onOkClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onChangePagination = this.onChangePagination.bind(this);
        this.onUpdatePaginationPageCount = this.onUpdatePaginationPageCount.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        //DmExplorer default properties
        this.state = {
            pageSize: 0,
            filter: { name: '', extension: null },
            pageSizeOptions: this.pageSize.map(t => t.toString()),
            pagination: { skip: 1, take: 24 },
            currentSelection: null
        }

    }

    onChangedExtension(args: IDropdownSelectedChangedEventArgs) {
        this.setState({ filter: { name: this.state.filter.name, extension: args.data.value } });
    }

    onOkClick() {
        if (this.props.onOkClick) {
            this.props.onOkClick();
        }
    }

    onCloseClick() {
        if (this.props.onCloseClick) {
            this.props.onCloseClick();
        }
    }

    onSelectionChanged(item: INavigationItem, cancelArgs: ICancelArgs) {
        if (!item) {
            this.setState({ currentSelection: null })
        } else {
            let canSelect = (this.props.filter.extension == "" || this.props.filter.extension == null)
                || (this.props.filter.extension == "folder_only" && item.type == ExplorerItemType.Folder)
                || (this.props.filter.extension != "" && item.type != ExplorerItemType.Folder && item.path.endsWith(this.props.filter.extension));
            if (canSelect) {
                this.setState({ currentSelection: item });
                this.props.onSelectionChanged(item, cancelArgs);
            } else {
                this.setState({ currentSelection: null })
            }
        }
    }

    private getItemExtensions(extension: string): Array<IItem> {
        let result: Array<IItem> = [];

        result.push(
            {
                key: createGuid(),
                text: 'All (*)',
                value: Extensions.FOLDER_EXTENSION_1,
                selected: extension == Extensions.FOLDER_EXTENSION_1.toString()
            },
            {
                key: createGuid(),
                text: 'CSP Project (*.' + Extensions.PROJECT_EXTENSION + ')',
                value: Extensions.PROJECT_EXTENSION,
                selected: extension == Extensions.PROJECT_EXTENSION.toString()
            },
            {
                key: createGuid(),
                text: 'CSP Solution (*.' + Extensions.SOLUTION_EXTENSION + ')',
                value: Extensions.SOLUTION_EXTENSION,
                selected: extension == Extensions.SOLUTION_EXTENSION.toString()
            });

        return result;
    }

    onChangePagination(skip: number, take: number) {
        if (this.state.pagination.skip != skip || this.state.pagination.take != take) {
            this.setState({ pagination: { skip, take } })
        }
    }

    onUpdatePaginationPageCount(pageSize: number, pagination: IPagination, filter: IFilter) {
        if ((this.state.filter.extension != filter.extension || this.state.filter.name != filter.name || this.state.pageSize != pageSize || this.state.pagination.skip != pagination.skip || this.state.pagination.take != pagination.take) && pageSize > -1) {
            this.setState({ pagination, pageSize, filter });
        }
    }

    renderFooter() {
        let extension: string = this.state.filter.extension != null ? this.state.filter.extension : this.props.filter.extension;
        return (
            <div className={ClassNames.DmFileSelectorFooter}>
                <BCLabel
                    key={'label'}
                    className={ClassNames.ExtensionLabel}
                    title={findMessage.get('100636')}
                >
                    {findMessage.get('100636')}
                </BCLabel>
                <BCDropdown
                    key={'1'}
                    className={ClassNames.ExtensionDropdown}
                    disabledChoose={true}
                    items={this.getItemExtensions(extension)}
                    defaultValue={extension}
                    onSelected={this.onChangedExtension}
                />
                <BCAntPagination
                    key={'1.1'}
                    simple
                    className={ClassNames.PaginationContainer}
                    current={this.state.pagination.skip}
                    total={this.state.pageSize}
                    showSizeChanger
                    defaultCurrent={1}
                    pageSize={this.state.pagination.take}
                    pageSizeOptions={this.state.pageSizeOptions}
                    showLessItems={true}
                    onChange={this.onChangePagination}
                    onShowSizeChange={this.onChangePagination}
                />
                {this.renderActionButtons()}
            </div>
        )
    }
    renderActionButtons() {
        if (!this.props.disableActionButtons) {
            return (<React.Fragment>
                <BCButton key={'2'} cssClass={ClassNames.FooterButton} text={findMessage.get('100034')} type={'ghost'} onClick={this.onCloseClick} />
                <BCButton key={'3'} disabled={this.state.currentSelection == null} cssClass={ClassNames.FooterButton} text={findMessage.get('100004')} type={'primary'} onClick={this.onOkClick} />
            </React.Fragment>)

        }

    }
    render() {
        let currentItem = this.props.currentItem && this.props.currentItem.secretKey ? this.props.currentItem : { id: '#Root', secretKey: null, name: "" };
        return (
            <div className={ClassNames.DmFileSelectorContainer + " " + this.props.containerClassName || ""}>
                <DMExplorer.FileSelector
                    currentItem={currentItem}
                    useScrollbar={true}
                    propsFilter={this.props.filter}
                    propsPagination={this.state.pagination}
                    events={{
                        onSelectionChange: this.onSelectionChanged,
                        onUpdatePaginationPageCount: this.onUpdatePaginationPageCount,
                        //onOpenItem: (args: INavigationItem)=>void,
                    }}
                />
                {this.renderFooter()}
            </div>
        );
    }
}

interface IState { pageSizeOptions: string[]; pageSize: number; pagination: IPagination; filter: IFilter, currentSelection: DMExplorer.Entities.INavigationItem }
interface IProps { filter?: IFilter, currentItem?: INavigationHistoryItem, disableActionButtons?: boolean, containerClassName?: string }
interface IEventProps {
    onOkClick?: () => void;
    onCloseClick?: () => void;
    onSelectionChanged: (args: INavigationItem, cancelArgs?: ICancelArgs) => void;
}