import React, { useEffect, useState } from 'react';
import { BCModal, BCLoading, BCButton, IButtonClickEventArgs } from '@bimser/components';
import * as Styles from '../assets/style.scss';
import IconCloseWindow from "@bimser/icons/16/close-window";
import ILeftMenuEBA6IntegrationProps from '../entities/ILeftMenuEBA6IntegrationProps';
import IconWaffle from "@bimser/icons/16/waffle";
var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

const LeftMenuEBA6Integration = (props: ILeftMenuEBA6IntegrationProps) => {


    const onOkClick = () => {
        props.onOkClick({
            id: props.id
        });
    }

    const onCancelClick = () => {
        props.onCancelClick(props.id)
    }

    const iframeOnLoad = (e: any) => {
        props.setEBA6IntegrationLoading(false);
    }

    const onClickSynergyMenuButton = (args: IButtonClickEventArgs) => {
        props.toggleSider();
    }

    const getPanelHeader = () => {
        let WorkflowMenuHeader = cx({
            WorkflowMenuHeader: true,
            WorkflowMenuHeaderVisible: props.loading
        })

        return (
            <div className={WorkflowMenuHeader}>
                {!props.loading && <BCButton type={'link'} onClick={onClickSynergyMenuButton} size={'large'} icon={<IconWaffle />} />}
                <div className={Styles.WorkflowMenuTitle}>{props.header || "eBA6"}</div>
                <div className={Styles.WorkFlowMenuEvents}>
                    <div className={Styles.Actions}>
                        <div className={Styles.ButtonClose}> <IconCloseWindow onClick={onCancelClick} /></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BCModal
            className={Styles.eBA6IntegrationModalContainer}
            width={"100vw"}
            visible={true}
            handleOk={onOkClick}
            handleCancel={onCancelClick}
            okText={""}
            cancelText={""}
            footer={[<></>]}
        // loading={props.loading} BCModal-loading kullanılmamalı.
        >
            <BCLoading show={props.loading}>
                <div className={[Styles.iframeContainer].join(" ")}>
                    {getPanelHeader()}
                    {
                        props.url !== "" &&
                        <iframe className={Styles.iframeStyle} id="eBA6Iframe" src={props.url} width='100%' frameBorder='0' height='100%' onLoad={iframeOnLoad} />
                    }
                </div>
            </BCLoading>
        </BCModal>
    );
}

export default LeftMenuEBA6Integration