import * as React from "react";
import Scrollbars from 'react-custom-scrollbars';
import * as Style from './assets/style.scss';
import { IPositionValues, IScrollbarsProps } from './entities';

const classNames = require('classnames/bind');
const cx = classNames.bind(Style);

const BCScrollbars = React.memo(React.forwardRef((props: IScrollbarsProps, ref: React.ForwardedRef<Scrollbars>) => {

    const innerRef = React.useRef<Scrollbars>(null);
    React.useImperativeHandle(ref, () => innerRef.current);

    React.useEffect(() => {
        if (props.mountScrollPosition && innerRef.current) {
            switch (props.mountScrollPosition) {
                case "top":
                    innerRef.current.scrollToTop();
                    break;
                case "bottom":
                    innerRef.current.scrollToBottom();
                    break;
                default:
                    innerRef.current.scrollTop(props.mountScrollPosition);
                    break;
            }
        }
    }, [])

    const renderThumb = (args: { style: React.CSSProperties, props: any }, direction: 'horizontal' | 'vertical') => {
        return (
            <div
                style={{ ...args.style }}
                className={cx({ scrollbarThumb: true, [direction]: true })}
                {...args.props}
            />
        );
    }

    const renderTrack = (args: { style: React.CSSProperties, props: any }, direction: 'horizontal' | 'vertical') => {
        return (
            <div
                style={{ ...args.style }}
                className={Style.scrollbarTrack + ' ' + Style[direction] + ' scrollbarTrack_' + direction}
                {...args.props}
            />
        );
    }

    return (
        <Scrollbars
            ref={innerRef}
            id={props.id}
            style={props.styles}
            className={cx({ ["bcScrollableArea"]: true, [props.cssClass]: true })}
            autoHide={props.autoHide}
            renderView={props.renderView}
            renderThumbHorizontal={({ style, ...props }) => renderThumb({ style, ...props }, 'horizontal')}
            renderThumbVertical={({ style, ...props }) => renderThumb({ style, ...props }, 'vertical')}
            renderTrackHorizontal={({ style, ...props }) => renderTrack({ style, ...props }, 'horizontal')}
            renderTrackVertical={({ style, ...props }) => renderTrack({ style, ...props }, 'vertical')}
            hideTracksWhenNotNeeded
            autoHeightMin={props.autoHeightMin}
            autoHeightMax={props.autoHeightMax}
            autoHeight={props.autoHeight}
            onUpdate={props.onUpdate}
            onScroll={props.onScroll}
            onScrollStart={props.onScrollStart}
            onScrollStop={props.onScrollStop}
        >
            {props.children}
        </Scrollbars>
    );
}))

export default BCScrollbars

export { IScrollbarsProps, IPositionValues, Scrollbars as BCScrollbarRef  };

