import { connect } from "react-redux";
import OrganizationForm from "../components";
import { closeHRFormTransaction, saveHRFormTransaction, onSearchPlantsComboboxTransaction, onSearchOrganizationsComboboxTransaction } from "../../../../actions"
import IOrganizationFormProps from "../entities/IProps";
import { WebInterfaceBaseState } from "../../../../../../common/base/state";
import { IWebInterfaceAction } from "../../../../../../common/entities";
import { getCompanies } from "../../../../selectors/modulesSelector";
import { getHRMenu } from "../../../../selectors/menuSelector";

const mapStateToProps = function (state: WebInterfaceBaseState, props: IOrganizationFormProps): IOrganizationFormProps {
    return {
        companies: getCompanies(state),
        menus: getHRMenu(state)
    };
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IOrganizationFormProps {
    return {
        onClose: function(data: IWebInterfaceAction) {
            dispatch(closeHRFormTransaction(data));
        },
        onSave: function(data: IWebInterfaceAction) {
            dispatch(saveHRFormTransaction(data));
        },
        onSearchPlants: function(data: IWebInterfaceAction) {
            dispatch(onSearchPlantsComboboxTransaction(data));
        },
        onSearchOrganizations: function(data: IWebInterfaceAction) {
            dispatch(onSearchOrganizationsComboboxTransaction(data));
        },
    };
}

// react - redux connection
const OrganizationFormContainer = connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);

export default OrganizationFormContainer;