import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import 'devexpress-reporting/dx-webdocumentviewer';
import ko from 'knockout';
import React from 'react';
import { BCAjax } from '../../index';
import { IReportViewerProps } from '../entities';

const containerStyle: React.CSSProperties = {
    width: "100%",
    height: "100%"
}

export default function ReportViewer(props: IReportViewerProps) {

    const ref = React.useRef<HTMLDivElement>();
    const initiliazed = React.useRef<boolean>(false);
    const callbacks = React.useRef<any>();
    const rptUrl = React.useRef<ko.Observable<string>>();

    const requestOptions = {
        host: `${props.serviceUrl || ""}/`,
        invokeAction: "ReportViewer"
    }

    ajaxSetup.ajaxSettings = {
        headers: { ...BCAjax.defaultHeaders },
        async: true
    }

    const applyBindings = (props: IReportViewerProps) => {

        const encodedParametersKey = encodeURIComponent(props.parametersKey);
        const obsReportUrl = `parametersKey=${encodedParametersKey}&reportName=${props.reportName}&projectName=${props.projectName}`;
        rptUrl.current = ko.observable(obsReportUrl);
        initiliazed.current = true;

        const viewModel = {
            reportUrl: rptUrl.current,
            requestOptions: requestOptions,
            callbacks: callbacks.current,
        };

        try {
            ko.applyBindings(viewModel, ref.current);
        } catch (error) {
            console.log("Error occured during fetching Report Viewer model", error);
        }
    }

    React.useEffect(() => {

        const loadCondition = props.reportId && props.reportName && props.projectName && props.parametersKey && !props.preview;

        if (loadCondition && !initiliazed.current) {
            applyBindings(props);
        } else if (rptUrl.current) {
            rptUrl.current.valueHasMutated();
        }

    }, [props.reportId, props.reportName, props.projectName, props.parametersKey, props.parametersHash]);

    React.useEffect(() => {
        if (props.preview)
            applyBindings({ parametersKey: "", reportName: "", projectName: "" });

        return () => {
            ko.cleanNode(ref.current)
        }
    }, []);

    return <div style={containerStyle} ref={ref} data-bind="dxReportViewer: $data"></div>
}