import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as React from "react";
import { isNullOrUndefined } from "@bimser/core";
import Style from './assets/styles.scss';
import { ICheckboxProps } from './entities';

var classNames = require('classnames/bind');
let cx = classNames.bind(Style);

export { ICheckboxProps };

export default (props: ICheckboxProps) => {
    const { defaultChecked, checked: propsChecked } = props;
    const [checked, setChecked] = React.useState<boolean>(!!(defaultChecked || propsChecked));
    const checkboxRef = React.useRef<HTMLInputElement>()


    React.useEffect(() => {
        (checkboxRef.current as any)?.input?.addEventListener?.("mousedown", (event: Event) => event.preventDefault(), false);

        return () => {
            (checkboxRef.current as any)?.input?.removeEventListener?.("mousedown", (event: Event) => event.preventDefault(), false);
        }
    });
    
    React.useEffect(() => {
        if(!isNullOrUndefined(props.checked))
            setChecked(props.checked);
    }, [props.checked]);

    const onChange = React.useCallback((value: CheckboxChangeEvent) : void => {
        if(props.stopPropagationForChange)
            value.stopPropagation?.();

        let val = value.target.checked;

        if(isNullOrUndefined(props.indeterminate) && props.isThreeState) {
            if(props.checked == null)
                val = false;
            else if(props.checked && !val)
                val = null;
        }

        if(props.onChange) {
            props.onChange(val, props.value);
        }

        setChecked(val);
    }, [props.isThreeState, props.indeterminate, props.stopPropagationForChange, props.onChange])

    const indeterminate = React.useMemo(() => (
        props.indeterminate ?? (props.isThreeState ? isNullOrUndefined(props.checked) : false)
    ), [props.indeterminate, props.isThreeState, props.checked]);

    return (
        <Checkbox
            ref={checkboxRef}
            tabIndex={props.tabIndex}
            onChange={!props.readOnly && onChange}
            style={props.style}
            disabled={props.disabled}
            defaultChecked={props.defaultChecked}
            checked={indeterminate ? null : checked}
            className={[cx({ hasCustomColor: !!props.style?.color }), props.className].join(" ")}
            indeterminate={indeterminate}
            autoFocus={props.autoFocus}
            onKeyDown={props.onKeyDown}
        >
            {props.label}
        </Checkbox>
    );
}