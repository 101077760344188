import { IAddViewerFilePayload } from '../entities/Payloads';
import { addViewerFileTransaction, addViewerFileAndLoadTransaction, updateShowingViewerFileAction, removeViewerFileAction, setDmViewerLoadingAction, setDmViewerSkeletonTypeAction, removeDmViewerInstanceAction, createDmViewerInstanceAction } from '../actions';
import { DmViewerAPI } from '.';
import { ContentType } from '@bimser/components';

class DmViewerInstance {
    panelId: string;

    constructor(panelId: string) {
        this.panelId = panelId;
        let createAction = createDmViewerInstanceAction(panelId);
        DmViewerAPI.dispatch(createAction);
    }


    addNewFile(payload: IAddViewerFilePayload) {
        let action = addViewerFileTransaction(payload);
        DmViewerAPI.dispatch(action);
    }

    addNewFileAndLoad(payload: IAddViewerFilePayload) {
        let action = addViewerFileAndLoadTransaction({
            panelId: this.panelId,
            ...payload
        });
        DmViewerAPI.dispatch(action);
    }

    load(viewerId: string) {
        let action = updateShowingViewerFileAction({
            panelId: this.panelId,
            viewerId
        });
        DmViewerAPI.dispatch(action);
    }

    remove(viewerId: string) {
        let action = removeViewerFileAction(viewerId);
        DmViewerAPI.dispatch(action)
    }

    setLoading(loading: boolean) {
        let action = setDmViewerLoadingAction({
            panelId: this.panelId,
            loading
        });
        DmViewerAPI.dispatch(action);
    }

    setSkeletonType(contentType: ContentType) {
        let action = setDmViewerSkeletonTypeAction({
            panelId: this.panelId,
            contentType
        });
        DmViewerAPI.dispatch(action);
    }

    removeInstance(){
        let action = removeDmViewerInstanceAction(this.panelId);
        DmViewerAPI.dispatch(action);
    }
}

export default DmViewerInstance;