import { Record,List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import {
    IBaseFormContent, IDepartment, IUser, Department, User, DepartmentFactory,
    UserFactory, DepartmentFactoryFromJS, UserFactoryFromJS, PropertyValue, IPropertyValue, PropertyValueFactoryFromJS, ICompanyObject, CompanyObject, CompanyObjectFactoryFromJS
} from "..";

export interface IDepartmentFormContent extends IBaseFormContent {
    showingDepartment?: IDepartment,
    customProperties?: Array<IPropertyValue>,
    companyObjects?: Array<ICompanyObject>,
}

export interface IReadonlyDepartmentFormContent extends IBaseFormContent {
    customProperties?: List<PropertyValue>,
    showingDepartment?: Department,
    companyObjects?: List<CompanyObject>,
}

export type DepartmentFormContent = ReadonlyRecord<IReadonlyDepartmentFormContent>;

export const DepartmentFormContentFactory = Record<IReadonlyDepartmentFormContent>({
    panelId: "",
    showingDepartment: DepartmentFactory(),
    initialHash: "",
    isModified: false,
    customProperties: List<PropertyValue>(),
    selectableListPanelisActive : false,
    companyObjects: List<CompanyObject>(),
    showLoading : false,
});

export const DepartmentFormContentFactoryFromJS = function (data: IDepartmentFormContent) {
    let _customProperties: Array<PropertyValue> = data.customProperties && data.customProperties.length ? data.customProperties.map((value: IPropertyValue) => {
        return PropertyValueFactoryFromJS(value);
    }) : [];

    let _companyObjects: Array<CompanyObject> = data.companyObjects && data.companyObjects.length ? data.companyObjects.map((value: ICompanyObject) => {
        return CompanyObjectFactoryFromJS(value);
    }) : [];

    return DepartmentFormContentFactory({
        panelId: data.panelId,
        showingDepartment: DepartmentFactoryFromJS(data.showingDepartment),
        initialHash: data.initialHash,
        isModified: data.isModified,
        customProperties: List(_customProperties),
        selectableListPanelisActive : data.selectableListPanelisActive,
        showLoading :  data.showLoading,
        companyObjects: List(_companyObjects)

    })
}