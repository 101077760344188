import React, { lazy, Suspense } from 'react';
import { IDataTypesProps } from './components/entities/IDataTypesProps';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-data-security" */ "./containers"));

function Container(props: React.PropsWithChildren<IDataTypesProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
Container.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-data-security" */ "modules/dataSecurity/injector");
}

export default Container;