import { BCExtremeGrid, BCLoading, findMessage } from "@bimser/components";
import { IBaseMutableAction } from "@bimser/core";
import * as React from "react";
import { IExtremeGridCProps, IExtremeGridStatelessProps } from "../entities";

export default class BCExtremeGridStateless extends React.PureComponent<IExtremeGridStatelessProps & IExtremeGridCProps, {}> {

    constructor(props: IExtremeGridStatelessProps & IExtremeGridCProps) {
        super(props);
        this.onStateChange = this.onStateChange.bind(this);
        this.getProperties = this.getProperties.bind(this);
    }

    onStateChange<T>(action: IBaseMutableAction<T>) {
        this.props.onStateChange({
            id: this.props.id,
            action: action
        })
    }

    getProperties(): any {
        return this.props;
    }

    render() {
        if (!this.props.isInitialized) {
            return <span>{`DataGrid ${findMessage.get("100127")}...`} ({this.props.id})</span>
        }

        return (
            <BCLoading show={this.props.isLoading}>
                <BCExtremeGrid.Core
                    uid={this.props.id}
                    cacheKey={this.props.cacheKey}
                    uniqueDataField={this.props.uniqueDataField}
                    useStateForSelection={this.props.useStateForSelection}
                    columns={this.props.columns}
                    dataSource={this.props.dataSource}
                    sorting={this.props.sorting}
                    paging={this.props.paging}
                    pager={this.props.pager}
                    editing={this.props.editing}
                    filtering={this.props.filtering}
                    searching={this.props.searching}
                    summary={this.props.summary}
                    toolbarItems={this.props.toolbarItems}
                    selection={this.props.selection}
                    stateStoring={this.props.stateStoring}
                    groupPanel={this.props.groupPanel}
                    headerFiltering={this.props.headerFiltering}
                    grouping={this.props.grouping}
                    allowColumnResizing={this.props.allowColumnResizing}
                    columnResizingMode={this.props.columnResizingMode}
                    allowColumnReordering={this.props.allowColumnReordering}
                    columnAutoWidth={this.props.columnAutoWidth}
                    columnFixing={this.props.columnFixing}
                    columnChooser={this.props.columnChooser}
                    showColumnLines={this.props.showColumnLines}
                    showRowLines={this.props.showRowLines}
                    showBorders={this.props.showBorders}
                    rowAlternationEnabled={this.props.rowAlternationEnabled}
                    languageOptions={this.props.languageOptions}
                    height={this.props.height}
                    style={this.props.style}
                    exportToPDF={this.props.exportToPDF}
                    exportToExcel={this.props.exportToExcel}
                    isLoading={this.props.isLoading}
                    hoverStateEnabled={this.props.hoverStateEnabled}
                    remoteOperations={this.props.remoteOperations || this.props.externalData}
                    disableBatchSave={this.props.disableBatchSave}
                    name={this.props.name}
                    willReceivePropsTriggerEnabled={this.props.willReceivePropsTriggerEnabled}
                    scrolling={this.props.scrolling}
                    cellHintEnabled={this.props.cellHintEnabled}
                    columnHidingEnabled={this.props.columnHidingEnabled}
                    columnMinWidth={this.props.columnMinWidth}
                    noDataText={this.props.noDataText}
                    onEditorPreparing={this.props.onEditorPreparing}
                    onSelectionChange={this.props.onSelectionChange}
                    onPageIndexChange={this.props.onPageIndexChange}
                    onPageSizeChange={this.props.onPageSizeChange}
                    onStateChange={this.onStateChange}
                    getInputReference={this.props.getInputReference}
                    getRowId={this.props.getRowId}
                    onCellPrepared={this.props.onCellPrepared}
                    onEditorCreating={this.props.onEditorCreating}
                    onColumnResizeChange={this.props.onColumnResizeChange}
                    onColumnOrderChange={this.props.onColumnOrderChange}
                    onColumnVisibilityChange={this.props.onColumnVisibilityChange}
                    onGroupingChange={this.props.onGroupingChange}
                    onSortingChange={this.props.onSortingChange}
                    onSelectionChanging={this.props.onSelectionChanging}
                    onLoad={this.props.onLoad}
                    readOnly={this.props.readOnly}
                    onEditingStart={this.props.onEditingStart}
                    onInitNewRow={this.props.onInitNewRow}
                    onFilterClear={this.props.onFilterClear}
                    onRowInserted={this.props.onRowInserted}
                    onRowInserting={this.props.onRowInserting}
                    onRowUpdated={this.props.onRowUpdated}
                    onRowUpdating={this.props.onRowUpdating}
                    onRowRemoved={this.props.onRowRemoved}
                    onRowRemoving={this.props.onRowRemoving}
                    onEditingRowIdChange={this.props.onEditingRowIdChange}
                    onCommitChanges={this.props.onCommitChanges}
                    onRowChangesChange={this.props.onRowChangesChange}
                    onDoubleClickRow={this.props.onDoubleClickRow}
                    onContextMenuPreparing={this.props.onContextMenu}
                    onContentReady={this.props.onContentReady}
                    onLoadMoreRows={this.props.onLoadMoreRows}
                    onExportData={this.props.onExportData}
                    disabled={this.props.disabled}
                    wordWrapEnabled={this.props.wordWrapEnabled}
                    showClearAllButton={this.props.showClearAllButton}
                // onContextMenu={this.props.onContextMenu}
                // onRowsRemoving={this.props.onRowsRemoving}
                // onSummaryChanged={this.props.onSummaryChanged}
                // onLoadMoreRows={this.props.onLoadMoreRows}
                // onEditingRowIdsChange={this.props.onEditingRowIdsChange}
                // onFilteringChange={this.props.onFilteringChange}
                />
            </BCLoading>
        );
    }

    static Core = BCExtremeGrid.Core;
    static Stateful = BCExtremeGrid;
}