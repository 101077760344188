import { createSelector } from 'reselect';
import { List } from 'immutable';
import { BaseState } from '../../../entities/BaseState';
import { NavigationHistoryItem } from '../entities/INavigationHistoryItem';

const getPath = (state: BaseState) => {
    return state.DMExplorer.navigationHistory;
}

const getPathSelector = createSelector(
    [
        getPath
    ],
    (navHistory: List<NavigationHistoryItem>) => {
        if (navHistory.size == 0) return "";
        let _paths = navHistory.map(h => h.name).filter(p=> p != "");
        return _paths.join('/')
    }
)

export default getPathSelector;