import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";
import { ITour, Tour, TourFactoryFromJS } from './ITour';

interface IBaseUITourState {
    activeTourId: string
}

export interface IUITourState extends IBaseUITourState {
    tours: IDictionary<ITour>,
}

export interface IReadonlyUITourState extends IBaseUITourState {
    tours: Map<string, Tour>,
    
}

export type UITourState = ReadonlyRecord<IReadonlyUITourState>;

export const UITourStateFactory = Record<IReadonlyUITourState>({
    activeTourId: null,
    tours: Map(),
});

export const UITourStateFactoryFromJS = function (data: IUITourState) {
    let _tours: IDictionary<Tour> = Object.keys(data.tours).reduce(function (previous: IDictionary<Tour>, current) {
        previous[current] = TourFactoryFromJS(data.tours[current])
        return previous;
    }, {});

    return UITourStateFactory({
        activeTourId: data.activeTourId,
        tours: Map(_tours)
    });
}