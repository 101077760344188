import { Map } from "immutable";
import { LayoutStructureItem } from "../../../common/entities";
import { LayoutCreatorLockAction } from "../entities";

export function handleItemLock(payload: LayoutCreatorLockAction, state: Map<string, LayoutStructureItem> = Map()): Map<string, LayoutStructureItem> {

    if (!state.isEmpty() && payload.item?.id) {
        //Set true selected item property
        const selectedItem = state.get(payload.item.id);
        if (selectedItem) {
            state = state.setIn([payload.item.id, 'designerProps', 'locked'], !selectedItem.designerProps.locked);
        }
    }

    return state

}