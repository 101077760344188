import Layout from 'antd/lib/layout';
import * as React from "react";
import { IWrapperProps } from '../entities';

export default class BCFooter extends React.Component<IWrapperProps, {}> {

    constructor(props: IWrapperProps) {
        super(props);
    }

    render() {
        return (
            <Layout.Footer {...this.props} />
        );
    }
}