import { createGuid, extend, IDictionary, isNullOrUndefined, devtools } from "@bimser/core";
import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { BCAjaxCProps, BCAjaxRequestConfig, BCAjaxResponse, Canceler, ErrorInfo, InstanceList, ValidationErrorInfo, WrapResponse } from "./entities";
import * as ErrorCodes from './entities/errorCodes';

const loginEndpoints: string[] = ["Login", "LoginWithAccessToken", "LoginWithDigitalSignature", "LoginWithExternalUser", "CreateTokenFromAuthCode"]

export default class BCAjax {
    // #region [Properties]

    private static instances: IDictionary<BCAjax> = {};
    static defaultConfig: BCAjaxRequestConfig = {};
    static defaultHeaders: any = {};

    config: BCAjaxRequestConfig;

    onRefreshToken: (args: any) => Promise<any>;
    static refreshTokenPromise: Promise<any>;

    // #endregion
    // #region [Constructor]
    constructor(config?: BCAjaxRequestConfig, props?: BCAjaxCProps) {
        this.config = this.mergeConfig(config);
        if (props) {
            this.onRefreshToken = props.onRefreshToken;
        }
    }

    // #endregion
    // #region [Public Functions]
    head<T>(url: string, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let _url = url;
        let footprintGuid = createGuid();
        const executer = () => {
            let _config = this.mergeConfig(config);
            _url = _config.baseURL + url;
            _config = this.addHeaderConfig(_config, footprintGuid);

            let originConfig = this.getAxiosRequestConfig(_config);
            const source = axios.CancelToken.source()
            originConfig.cancelToken = source.token;

            if (config)
                (config as any).canceler = source.cancel;

            return axios.head(url, originConfig);
        }
        return this.executeAxios(executer, _url, footprintGuid);
    }

    get<T>(url: string, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let _url = url;
        let footprintGuid = createGuid();
        const executer = () => {
            let _config = this.mergeConfig(config);
            _url = _config.baseURL + url;
            _config = this.addHeaderConfig(_config, footprintGuid);

            let originConfig = this.getAxiosRequestConfig(_config);
            const source = axios.CancelToken.source()
            originConfig.cancelToken = source.token;

            if (config)
                (config as any).canceler = source.cancel;

            return axios.get<WrapResponse<T>>(url, originConfig);
        }
        let promise = this.executeAxios(executer, _url, footprintGuid);
        return this.executeXhr<T>(promise, _url, executer, footprintGuid);
    }

    post<T>(url: string, data?: any, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let _url = url;
        let footprintGuid = createGuid();

        const executer = () => {
            let _config = this.mergeConfig(config);
            _url = _config.baseURL + url;
            _config = this.addHeaderConfig(_config, footprintGuid);

            let originConfig = this.getAxiosRequestConfig(_config);
            const source = axios.CancelToken.source()
            originConfig.cancelToken = source.token;

            if (config)
                (config as any).canceler = source.cancel;


            return axios.post<WrapResponse<T>>(url, data, originConfig);
        }
        let promise = this.executeAxios(executer, _url, footprintGuid);
        return this.executeXhr<T>(promise, _url, executer, footprintGuid);
    }

    put<T>(url: string, data?: any, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let _url = url;
        let footprintGuid = createGuid();

        const executer = () => {
            let _config = this.mergeConfig(config);
            _url = _config.baseURL + url;
            _config = this.addHeaderConfig(_config, footprintGuid);

            let originConfig = this.getAxiosRequestConfig(_config);
            const source = axios.CancelToken.source()
            originConfig.cancelToken = source.token;

            if (config)
                (config as any).canceler = source.cancel;


            return axios.put<WrapResponse<T>>(url, data, originConfig);
        }
        let promise = this.executeAxios(executer, _url, footprintGuid);
        return this.executeXhr<T>(promise, _url, executer, footprintGuid);
    }

    all<T1, T2, T3>(promise1: Promise<BCAjaxResponse<T1>>, promise2: Promise<BCAjaxResponse<T2>>, promise3: Promise<BCAjaxResponse<T3>> = undefined): Promise<[BCAjaxResponse<T1>, BCAjaxResponse<T2>, BCAjaxResponse<T3>]> {
        return Promise.all([promise1, promise2, promise3]);
    }

    // #endregion
    // #region [Static Functions]
    static setHeaders(headers: any) {
        if (headers) {
            Object.keys(headers).forEach((key) => {
                BCAjax.defaultHeaders[key] = headers[key];
            });
        }
    }

    static get<T>(url: string, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let inst = new BCAjax(config);
        return inst.get(url);
    }

    static head<T>(url: string, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let inst = new BCAjax(config);
        return inst.head(url);
    }

    static post<T>(url: string, data?: any, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let inst = new BCAjax(config);
        return inst.post(url, data);
    }

    static put<T>(url: string, data?: any, config?: BCAjaxRequestConfig): Promise<BCAjaxResponse<T>> {
        let inst = new BCAjax(config);
        return inst.put(url, data);
    }

    static all<T1, T2, T3>(promise1: Promise<BCAjaxResponse<T1>>, promise2: Promise<BCAjaxResponse<T2>>, promise3: Promise<BCAjaxResponse<T3>> = undefined): Promise<[BCAjaxResponse<T1>, BCAjaxResponse<T2>, BCAjaxResponse<T3>]> {
        let inst = new BCAjax();
        return inst.all(promise1, promise2, promise3);
    }

    static createInstances<T extends IDictionary<BCAjaxRequestConfig>>(configs: T, props?: BCAjaxCProps): InstanceList<T> {
        for (let key in configs) {
            let inst = new BCAjax(configs[key], props);
            BCAjax.instances[key] = inst;
        }

        return <InstanceList<T>>BCAjax.instances;
    }

    static getInstances<T extends IDictionary<BCAjaxRequestConfig>>(): InstanceList<T> {
        return <InstanceList<T>>BCAjax.instances;
    }

    // #endregion
    // #region [Private Functions]
    private mergeConfig(config: BCAjaxRequestConfig) {
        return extend(true, {}, BCAjax.defaultConfig, this.config, config, {
            headers: BCAjax.defaultHeaders
        });
    }

    private addHeaderConfig(config: BCAjaxRequestConfig, footPrint: string): BCAjaxRequestConfig {
        return extend(true, {}, {
            headers: {
                ["Bimser-FootPrint"]: footPrint,
                ["Bimser-Geolocation"]: (window as any).geoLocation ? `{lat:${(window as any).geoLocation.lat},lon:${(window as any).geoLocation.lon}}` : undefined,
                ["Bimser-DevTools"]: devtools.isOpen
            }
        }, config);
    }

    private getAxiosRequestConfig(config: BCAjaxRequestConfig): AxiosRequestConfig {
        return {
            baseURL: config.baseURL,
            data: config.data,
            headers: config.headers,
            onDownloadProgress: config.onDownloadProgress,
            onUploadProgress: config.onUploadProgress,
            params: config.params
        };
    }

    private executeAxios(sendRequest: () => Promise<any>, url: string, footprintGuid: string): Promise<any> {
        if (BCAjax.refreshTokenPromise) {
            return new Promise((resolve, reject) => {
                BCAjax.refreshTokenPromise.then(() => {
                    sendRequest().then((response) => {
                        this.createResponse(response, resolve, reject, url, footprintGuid);
                    }).catch((error) => {
                        //refreshToken'dan sonra yine token expire hatası geldiyse logine yönlendirmesi için 994 kodu fırlatılıyor.
                        //let errCode = error.response.data.errorDetail.code === "FRMW_002" ? ErrorCodes.RefReshTokenSecurityError : error.response.data.errorDetail.code
                        let _error: ErrorInfo = this.getErrorInfo(url, error.response.data.errorDetail.code, error.response.data.errorDetail.message, error.response.data.errorDetail.internalMessage,
                            error.response.data.errorDetail.exception, "", error.response.data.errorDetail.validations && error.response.data.errorDetail.validations.length > 0 ? true : false, undefined, footprintGuid)
                        reject(_error);
                    });
                    BCAjax.refreshTokenPromise = undefined;
                }).catch((error) => {
                    reject(error);
                })
            });
        }
        else {
            return sendRequest();
        }
    }

    private createResponse<T>(response: any, resolve: any, reject: any, url: string, footprintGuid: string) {
        if (response.data) {
            let bcResponse: BCAjaxResponse<T> = {
                data: response.data.result ?? response.data as any,
                headers: response.headers
            }
            resolve(bcResponse);
            return;
            //TODO: Buraya ilerde bir çalışma yapılacağı için geçici olarak resolve edip fonksiyonu durduruyoruz.
            if (isNullOrUndefined(response.data.success)) {
                let bcResponse: BCAjaxResponse<T> = {
                    data: response.data as any,
                    headers: response.headers
                }
                resolve(bcResponse);
            } else {
                if (response.data.success === true) {
                    let bcResponse: BCAjaxResponse<T> = {
                        data: response.data.result,
                        headers: response.headers
                    }
                    //BCAjax.setHeaders(response.headers);
                    resolve(bcResponse);
                }
                else {
                    let error: ErrorInfo = this.getErrorInfo(url, response.data.errorDetail.code, response.data.errorDetail.message, response.data.errorDetail.internalMessage,
                        response.data.errorDetail.exception, "", response.data.errorDetail.validations && response.data.errorDetail.validations.length > 0 ? true : false, undefined, footprintGuid)

                    reject(error);
                }
            }
        }
        else {
            let error: ErrorInfo = this.getErrorInfo(url, ErrorCodes.ResponseIsNullError, "Response is null", "Response is null", undefined, undefined, undefined, undefined, footprintGuid)
            reject(error);
        }
    }

    private executeXhr<T>(promise: AxiosPromise<WrapResponse<T>>, url: string, sendRequest: () => Promise<any>, footprintGuid: string): Promise<BCAjaxResponse<T>> {
        return new Promise<BCAjaxResponse<T>>((resolve, reject) => {
            promise
                .then((response) => {
                    this.createResponse(response, resolve, reject, url, footprintGuid);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 401) {
                            if (loginEndpoints.findIndex(endpoint => url.endsWith(endpoint)) > -1) {
                                //Login endpointlerinden gelen 401 hataları için refresh token adımları çalıştırılmayacak.
                                let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.OutOf2xxError + " - " + error.response.status, error.message, error, error.response, undefined, undefined, undefined, footprintGuid);
                                reject(errorInfo);
                            } else if (error.response.data?.exception?.Code === "FRMW_ASPN_0006") {
                                reject(this.getErrorInfo(url, "FRMW_ASPN_0006", error.message, error, error.response, undefined, undefined, undefined, footprintGuid));
                            } else if (error.response.data?.errorDetail?.code === "FRMW_006") {
                                let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.RefReshTokenSecurityError, error.response.data.errorDetail.message, error.response.data.errorDetail.internalMessage, error.response.data.errorDetail.response, undefined, undefined, undefined, footprintGuid);
                                reject(errorInfo);
                            } else if (BCAjax.refreshTokenPromise) {
                                if (url.split("/").lastIndexOf("Refresh") === (url.split("/").length - 1)) {
                                    let errorDetail = error.response.data && error.response.data.errorDetail ? error.response.data.errorDetail : {};
                                    let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.RefReshTokenSecurityError, errorDetail.message, errorDetail.internalMessage, errorDetail.response, undefined, undefined, undefined, footprintGuid);
                                    reject(errorInfo);
                                } else {
                                    resolve(this.executeAxios(sendRequest, url, footprintGuid));
                                }
                            } else {
                                BCAjax.refreshTokenPromise = this.onRefreshToken(null);
                                BCAjax.refreshTokenPromise.then((response) => {
                                    BCAjax.setHeaders({
                                        "Authorization": "Bearer " + response.data.token,
                                    });
                                    sessionStorage.setItem('token', response.data.token);
                                    if (localStorage.getItem("rememberMe") == "1") {
                                        localStorage.setItem('token', response.data.token);
                                    }
                                    resolve(this.executeAxios(sendRequest, url, footprintGuid));
                                }).catch((response) => {
                                    if (response.code === "994") {
                                        reject(response);
                                    } else {
                                        let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.RefReshTokenError, response.message, response, response.response, undefined, undefined, undefined, footprintGuid);
                                        reject(errorInfo);
                                    }
                                });
                            }
                        } else {
                            let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.OutOf2xxError + " - " + error.response.status, error.message, error, error.response, undefined, undefined, undefined, footprintGuid);
                            reject(errorInfo);
                        }
                    }
                    else if (error.request) {
                        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                        let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.NoResponseError, error.message, error, error.request, undefined, undefined, undefined, footprintGuid);
                        reject(errorInfo);
                    }
                    else {
                        // Something happened in setting up the request that triggered an Error
                        let errorInfo: ErrorInfo = this.getErrorInfo(url, ErrorCodes.RequestSettingUpError, error.message, error, error, undefined, undefined, undefined, footprintGuid);
                        reject(errorInfo);
                    }
                });
        });
    }

    private getErrorInfo(_url: string, _code: string, _message: any, _internalMessage?: string, _exception?: string, _documentLink?: string, _hasValidation?: boolean, _validations?: ValidationErrorInfo[], _footPrint?: string) {
        return new ErrorInfo({
            code: _code,
            documentLink: _documentLink ? _documentLink : "",
            exception: _exception ? _exception : "",
            hasValidation: _hasValidation ? _hasValidation : false,
            internalMessage: _internalMessage ? _internalMessage : "",
            message: _message,
            validations: _validations,
            url: _url,
            footPrint: _footPrint
        })
    }
    // #endregion
}

export { BCAjaxRequestConfig, BCAjaxResponse, Canceler, ErrorInfo, ValidationErrorInfo, WrapResponse };