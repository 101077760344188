import React, { lazy, Suspense } from 'react';
import IAppFormContainerProps from "./entities/IProps"
import { injectSaga } from "../../../app-init"

const AppFormContainerLazy = lazy(() => import(/* webpackChunkName: "web-form-viewer" */ "./containers"));

function AppFormContainer(props: React.PropsWithChildren<IAppFormContainerProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <AppFormContainerLazy {...props} />
        </Suspense>
    );
}
AppFormContainer.onPanelOpening = () => {
    const formControlsPromise = import(/* webpackChunkName: "web-form-viewer" */ "@bimser/form-controls/dist/sagas").then(({ default: saga }) => {
        injectSaga("form-controls", saga);
    })

    const appFormPromise = import(/* webpackChunkName: "web-form-viewer" */ "./sagas").then(({ default: saga }) => {
        injectSaga("appForm", saga);
    })

    return Promise.all([formControlsPromise, appFormPromise]);
}

export default AppFormContainer;