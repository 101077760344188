import * as React from "react";
import * as Styles from './assets/style.scss';
import IIconProps from './entities/IIconProps';

export default function Icon(props: IIconProps) {
    const iconName = React.useMemo(() => {
        if (props.name) {
            let splittedIconID = props.name.split("_");
            splittedIconID.shift();
            splittedIconID = splittedIconID.join("").split(".");
            splittedIconID.pop();
            return splittedIconID.join("");
        }
        return null;
    }, [props.name]);

    React.useEffect(() => {
        if (props.size && iconName) {
            require(`@bimser/icons/${props.size}/${iconName}.js`);
        }
    }, [iconName, props.size]);

    const style: React.CSSProperties = {
        ...props.style,
        color: props.color,
        width: props.width,
        height: props.height
    };

    return (
        <div className={[Styles.bcIcon, 'bcIcon', props.className, Styles["i" + props.size]].join(' ')} style={style} onClick={props.onClick} title={props.title}>
            <div className={Styles.iconContainer}>
                <svg>
                    <use xlinkHref={`#${props.name}`} />
                </svg>
            </div>
        </div >
    );
}