import { IDictionary } from "@bimser/core";
import IconLanguage from "@bimser/icons/16/language";
import * as React from "react";
import { BCButton, BCInputGroup } from '../';
import BCInput, { BCInputRef, IInputChangeEventArgs } from "../BCInput";
import { findMessage } from "../BCIntl";
import BCLabel from "../BCLabel";
import BCPopover from "../BCPopover";
import BCScrollbars from "../BCScrollbars";
import * as Style from './assets/style.scss';
import { IProps } from './entities';

const inputStyle: React.CSSProperties = { width: 'calc(100% - 35px)' };
const inputFullStyle: React.CSSProperties = { width: '100%' };
const buttonStyle: React.CSSProperties = { width: '35px', fontWeight: 900, fontSize: '14px' };

export default class BCInputMultiLanguage extends React.Component<IProps, {
    isFocus: boolean;
    focusEvent: any;
    value: IDictionary<string>,
}>{

    scrollStyle: React.CSSProperties = { height: '100%', width: '100%' };
    iconStyle: React.CSSProperties = { cursor: 'pointer', marginLeft: 5 };
    overlayStyle: React.CSSProperties = { maxWidth: 425 };
    labelStyle: React.CSSProperties = { fontSize: 13 };
    input = React.createRef<BCInputRef>();
    inputML = React.createRef<BCInputRef>();
    wrapperRef: any;
    buttonRef: any

    constructor(props: IProps) {
        super(props);
        this.renderPopoverContent = this.renderPopoverContent.bind(this);
        this.onLanguageValueChange = this.onLanguageValueChange.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.onBlurMLInput = this.onBlurMLInput.bind(this);
        this.onFocusInput = this.onFocusInput.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.wrapperRef = React.createRef();
        this.buttonRef = React.createRef();

        this.state = {
            isFocus: false,
            focusEvent: null,
            value: props.values || {}
        }
    }

    onBlurMLInput(event: MouseEvent | Event, blurWithTab = false) {
        if (blurWithTab) return this.setState({ isFocus: false });

        const isEventInsideinputML = this.inputML?.current?.input?.contains(event.target as Node);
        const isEventInsideInput = this.input?.current?.input.contains(event.target as Node);
        const isEventInsideMLWrapper = this.wrapperRef?.current?.contains(event.target);
        const isEventInsideMLButton = this.buttonRef?.current?.contains(event.target);

        if (isEventInsideinputML || isEventInsideInput || isEventInsideMLWrapper || isEventInsideMLButton) {
            this.setState({ isFocus: true, focusEvent: event });
            if (isEventInsideMLWrapper) {
                event.stopPropagation()
            }
        }

        if (!isEventInsideinputML && !isEventInsideInput && !isEventInsideMLWrapper && !isEventInsideMLButton) {
            this.setState({ isFocus: false, focusEvent: event });
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onBlurMLInput, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onBlurMLInput, true);
        this.toggleScrollOnVisible(false);
    }

    componentDidUpdate(prevProps: IProps, prevState: { isFocus: boolean; }) {
        if (prevState.isFocus && !this.state.isFocus) {
            this.props.onBlur?.(this.state.focusEvent, this.state.value);
        }
    }

    renderPopoverContent() {

        return (<div className={Style.hrFormMLPopover} ref={this.wrapperRef}>
            <BCScrollbars styles={this.scrollStyle} >
                <div className={Style.pdR5}>
                    {
                        this.props.supportedLanguages.map(item => {
                            if (item.id != this.props.currentLanguage.id) {
                                return (<div className={Style.popoverRow} key={item.name}>
                                    <div className={"ant-col-7 ant-col-offset-1 ant-form-item-label"}>
                                        <label title={item.name}>{item.name}</label>
                                    </div>
                                    <div className={"ant-col-15 ant-col-offset-1 "}>
                                        <BCInput
                                            ref={this.inputML}
                                            itemKey={item.culture}
                                            onChange={this.onLanguageValueChange}
                                            value={this.props.values && this.props.values[item.culture]}
                                            regExp={this.props.regExp}
                                            size={'small'}
                                            maxLength={this.props.maxLength}
                                            {...(this.props.innerInputProps || {})}
                                        />
                                    </div>
                                </div>);
                            }
                        })
                    }
                </div>
            </BCScrollbars>
        </div>);
    }

    onLanguageValueChange(e: IInputChangeEventArgs) {
        this.setState(prev => ({ value: { ...prev.value, [e.itemKey]: e.data } }));
        this.props.onLanguageValueChange?.(e.itemKey, e.data);
    }

    disableScrollEvent(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    toggleScrollOnVisible(visible: boolean) {
        const allScrollableArea = document.querySelectorAll<HTMLDivElement>('div[class*="bcScrollableArea"]');

        allScrollableArea.forEach((scrollable) => {
            if (scrollable.contains(this.input.current?.input)) {
                ["wheel", "scroll"].forEach(eventKey => {
                    visible
                        ? scrollable.addEventListener(eventKey, this.disableScrollEvent, true)
                        : scrollable.removeEventListener(eventKey, this.disableScrollEvent, true)
                });

                scrollable.childNodes.forEach((child: HTMLDivElement) => {
                    if (child.className.includes("scrollbarTrack")) visible
                        ? child.style.pointerEvents = "none"
                        : child.style.pointerEvents = "";
                })
            }
        })
    }

    onVisibilityChange(visible: boolean) {
        this.toggleScrollOnVisible(visible);

        if (this.props.onVisibilityChange) {
            this.props.onVisibilityChange(visible);
        }
    }

    renderPopoverTitle() {
        return this.props.title || (<BCLabel customStyles={this.labelStyle}>{findMessage.get("100775")}</BCLabel>);
    }

    onFocusInput(args: IInputChangeEventArgs) {
        this.setState({ isFocus: true, focusEvent: args.senderArgs })
        this.props.onFocus?.();
    }

    onKeyDown(e: React.KeyboardEvent<Element>) {
        if (e.key === "Tab") this.onBlurMLInput(undefined, true)
    }

    renderInput() {

        //Custom input
        if (this.props.renderInput) {
            return (
                <div style={this.props.disableMultiLanguage ? inputFullStyle : inputStyle}>
                    {this.props.renderInput()}
                </div>
            )
        }

        return (
            <BCInput
                ref={this.input}
                autoFocus={this.props.autoFocus}
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocusInput}
                onPressEnter={this.props.onPressEnter}
                placeHolder={this.props.placeHolder}
                itemKey={this.props.currentLanguage.culture}
                onChange={this.onLanguageValueChange}
                value={this.props.values && this.props.values[this.props.currentLanguage.culture]}
                size={this.props.size}
                regExp={this.props.regExp}
                style={this.props.disableMultiLanguage ? inputFullStyle : inputStyle}
                maxLength={this.props.maxLength}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}
                {...(this.props.innerInputProps || {})}
            />
        )

    }

    render() {

        if (this.props.disableMultiLanguage) return this.renderInput();

        return (
            <>
                <BCInputGroup compact={true}>
                    {this.renderInput()}
                    <BCPopover
                        content={this.renderPopoverContent()}
                        title={this.renderPopoverTitle()}
                        trigger={this.props.trigger || "click"}
                        placement={this.props.placement || "leftTop"}
                        overlayStyle={{ ...this.overlayStyle, ...this.props.overlayStyle }}
                        onVisibleChange={this.onVisibilityChange}
                    >
                        <BCButton
                            buttonRef={this.buttonRef}
                            style={buttonStyle}
                            size={this.props.size}
                            icon={<IconLanguage />}
                            disabled={this.props.disabled || this.props.readOnly}
                            cssClass={this.props.buttonClassName}
                        />
                    </BCPopover>
                </BCInputGroup>

            </>
        )

    }

}
export { IProps as IBCInputMultiLanguageProps };
