import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Split from 'react-split-pane';
import * as Style from './assets/styles.scss';

import { ISplitPaneProps } from './entities';
import SplitPane from "react-split-pane";

export default class BCSplitPane extends React.Component<ISplitPaneProps, {}> {
    constructor(props: ISplitPaneProps) {
        super(props);
    }


    render() {
        return (
            <SplitPane
                allowResize={true}
                split={this.props.split}
                defaultSize={this.props.defaultSize}
                primary="first"
                style={this.props.panelStyle}
                onDragStarted={this.props.onDragStarted}
                onDragFinished={this.props.onDragFinished}
                minSize={this.props.minSize}
                maxSize={this.props.maxSize}
                paneStyle={this.props.paneStyle}
                pane1Style={this.props.pane1Style}
                pane2Style={this.props.pane2Style}
                onChange={this.props.onChange}
                resizerStyle={this.props.resizerStyle}
                //className={Style.SplitPane}
                //pane1Style={{display:'none'}}
                resizerClassName={Style.Resizer}>
                {this.props.children}

            </SplitPane>
        );
    }
}

export { ISplitPaneProps }