import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { Culture, CultureFactoryFromJS, ICulture } from "./ICulture";

export interface IBaseCultureState {
    
}

export interface ICultureState extends IBaseCultureState {
    items: Array<ICulture>
}

export interface IReadonlyCultureState extends IBaseCultureState {
    items: List<Culture>
}

export type CultureState = ReadonlyRecord<IReadonlyCultureState>;

export const CultureStateFactory = Record<IReadonlyCultureState>({
    items: List<Culture>()
});

export const CultureStateFactoryFromJS = function (data: ICultureState) {
    let _cultures: Array<Culture> = data.items && data.items.length ? data.items.map((value: ICulture) => {
        return CultureFactoryFromJS(value);
    }) : [];

    return CultureStateFactory({
        items: List(_cultures)
    });
}