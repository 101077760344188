import IconFunction3 from "@bimser/icons/16/function3";
import * as React from "react";
import { BCButton, BCInput, BCLabel } from "../..";
import { ConditionalStatementEntities } from "../../BCConditionalStatement";
import { findMessage } from "../../BCIntl";
import { IFieldType, ITreeData } from "../../BCTreeSelect/entities";
import { filterReadonlyFields } from '../../BCTreeSelect/helpers';
import * as Styles from '../assets/styles.scss';
import { IActionContentProps } from "../entities/IActionContentProps";
import { SelectedField } from "./SelectedField";

const conditionValueInputStyle = { width: "100%" };

const ValueAssignActionWithFormula = React.memo((props: IActionContentProps<ConditionalStatementEntities.IValueAssignActionWithFormula>) => {

    const onSelectedFieldChanged = (value: string, options: ITreeData) => {
        props.onActionChanged({
            ...props.action,
            field: {
                name: value.split('.').shift(),
                propertyName: value.split('.').slice(1, value.length).join('.'),
                type: options.externalData.type,
                enumMembers: options.externalData.enumMembers
            }
        });
    }

    const getWriteableFields = () => {
        return props.fields ? filterReadonlyFields(props.fields.toJS(), IFieldType.number) : []
    }

    const renderSelectedField = (): JSX.Element => {
        return (
            <SelectedField
                field={props.action.field}
                options={getWriteableFields()}
                onChange={onSelectedFieldChanged}
            />
        )
    }

    const onFormulaChange = (value: string) => {
        props.onActionChanged({
            ...props.action,
            value
        });
    }

    const formulaBtnClicked = () => {
        props.openFormulaManager?.(props.action.value, onFormulaChange);
    }

    const renderFormulaBtn = () => {
        return (
            <BCButton
                icon={<IconFunction3 />}
                type={'text'}
                onClick={formulaBtnClicked}
                title={findMessage.get('101943')}
            />
        )
    }

    return (
        <div className={'mb-2 mt-2 mr-2 ml-2'}>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100131')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <BCInput
                        value={props.action.name}
                        onChange={e => {
                            props.onActionChanged({
                                ...props.action,
                                name: e.data
                            })
                        }}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100168')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <div className={Styles.conditionValueInput}>
                        <BCInput
                            style={conditionValueInputStyle}
                            value={props.action.value}
                            readOnly
                        />
                    </div>
                    <div className={Styles.conditionValueFormulaBtn}>
                        {renderFormulaBtn()}
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100338')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    {renderSelectedField()}
                </div>
            </div>
        </div>
    )
})

export default ValueAssignActionWithFormula