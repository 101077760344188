const _isEqual = require("lodash/isEqual");

/**
 * Equality test between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return true/false
 */

export default function isEqual(object: any, base: any): boolean {
    return _isEqual(object, base)
}