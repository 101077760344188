import { createMutableAction, createSyncAction } from '@bimser/core';
import { Map } from 'immutable';
import { Tour } from "../entities/ITour";
import * as ActionTypes from './actionTypes';

export const loadTourAction = (tourId: string) => {
    return createMutableAction(ActionTypes.LOAD_TOUR_ACTION, tourId);
}

export const setToursAction = (tours: Map<string, Tour>) => {
    return createSyncAction(ActionTypes.SET_TOURS_ACTION, tours);
}

export const startTourAction = () => {
    return createMutableAction(ActionTypes.START_TOUR_ACTION, null);
}

export const stopTourAction = () => {
    return createMutableAction(ActionTypes.STOP_TOUR_ACTION, null);
}

export const stopTourTransaction = () => {
    return createMutableAction(ActionTypes.STOP_TOUR_TRANSACTION, null);
}
export const afterStopTourTransaction = () => {
    return createMutableAction(ActionTypes.AFTER_STOP_TOUR_TRANSACTION, null);
}

export const onTourNextAction = () => {
    return createMutableAction(ActionTypes.TOUR_NEXT_ACTION, null);
}

export const onTourPrevAction = () => {
    return createMutableAction(ActionTypes.TOUR_PREV_ACTION, null);
}

export const onTourNextTransaction = () => {
    return createMutableAction(ActionTypes.TOUR_NEXT_TRANSACTION, null);
}

export const onTourPrevTransaction = () => {
    return createMutableAction(ActionTypes.TOUR_PREV_TRANSACTION, null);
}

export const onPathOpenedAction = (payload: { path: string, panelId?: string, props?: any }) => {
    return createMutableAction(ActionTypes.ON_PATH_OPENED_ACTION, payload);
}

export const onChangeTourLoadingAction = (payload: boolean) => {
    return createSyncAction(ActionTypes.CHANGE_TOUR_LOADING_ACTION, payload);
}

export const initializeUITourManagerTransaction = () => {
    return createSyncAction(ActionTypes.INITIALIZE_UI_TOUR_MANAGER, null);
}