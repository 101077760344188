import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";

export interface IBaseOvertime {
    id: number,
    code: string,
    factor: number,
}

export interface IOvertime extends IBaseOvertime {
    name: IDictionary<any>,
}

export interface IReadonlyOvertime extends IBaseOvertime {
    name: Map<string, any>,
}

export type Overtime = ReadonlyRecord<IReadonlyOvertime>;

export const OvertimeFactory = Record<IReadonlyOvertime>({
    id: 0,
    code: "",
    name: Map(),
    factor: 0
});

export const OvertimeFactoryFromJS = function (data: IOvertime) {
    return OvertimeFactory({
        ...data,
        name: Map(data.name)
    });
}