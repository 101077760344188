import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseDataTypesState {

}

export interface IDataTypesState extends IBaseDataTypesState {
    
}

export interface IReadonlyDataTypesState extends IBaseDataTypesState {

}

export type DataTypesState = ReadonlyRecord<IReadonlyDataTypesState>;

export const DataTypesStateFactory = Record<IReadonlyDataTypesState>({

});

export const DataTypesStateFactoryFromJS = function (data: IDataTypesState) {
    return DataTypesStateFactory({
        ...data
    });
}