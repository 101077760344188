import * as React from 'react';

/**
 * Example Usage: 
 * ```jsx
 <DropZone
    dataKey="mData" //If you want to use onData you should define your data key which you set onDragStart here.
    onData={(data)=> {
        console.log(data)
    }} 
    onFiles={(files)=> {
        console.log(files)
    }}
 >
    {(props)=> (
        <div {...props.events} style={{border: props.over ? '1px dashed grey':'1px dashed blue'}}>
            <span> Drop Files Here </span>
        </div>
    )}
 </DropZone>
 * ```
 */
class DropZone extends React.PureComponent<DropZoneOptions, any>{

    constructor(props: any) {
        super(props);
        this.process = this.process.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.changeOver = this.changeOver.bind(this);

        this.state = {
            over: false,
            isMounted: false
        }
    }

    componentDidMount() {
        this.setState({ isMounted: true })
    }

    noop() { }

    process(dataTransfer: DataTransfer, event: React.DragEvent) {
        const uri = dataTransfer.getData('text/uri-list');

        if (this.props.dataKey && dataTransfer.getData(this.props.dataKey) && dataTransfer.getData(this.props.dataKey) != "") {
            let data: any = JSON.parse(dataTransfer.getData(this.props.dataKey));
            (this.props.onData || this.noop)(data, event);
            return;
        }

        if (uri) {
            (this.props.onUri || this.noop)(uri, event);
            return;
        }

        if (dataTransfer.files && dataTransfer.files.length) {
            (this.props.onFiles || this.noop)(Array.from(dataTransfer.files), event);
            return;
        }

        if (dataTransfer.items && dataTransfer.items.length) {
            dataTransfer.items[0].getAsString(text => {
                if (this.state.isMounted) {
                    (this.props.onText || this.noop)(text, event);
                }
            });
        }
    };

    changeOver(over: boolean) {
        this.setState({ over });
        if (this.props.onOverChange) {
            this.props.onOverChange(over);
        }
    }

    onDragOver(event: React.DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragEnter(event: React.DragEvent) {
        if (this.props.allowDrag !== false) {
            event.preventDefault();
            this.changeOver(true);
        }
    }

    onDragLeave(event: React.DragEvent) {
        if (event.currentTarget.contains(event.relatedTarget as any)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        this.changeOver(false);
    }

    onDrop(event: React.DragEvent) {
        event.persist();
        event.preventDefault();
        event.stopPropagation();
        if (this.props.allowDrop !== false) {
            this.changeOver(false);
            this.process(event.dataTransfer, event);
        }
    }

    render() {
        if (typeof this.props.children === "function") {
            return this.props.children({ events: { onDragEnter: this.onDragEnter, onDragOver: this.onDragOver, onDragLeave: this.onDragLeave, onDrop: this.onDrop }, over: this.state.over })
        }
    }
}

export default DropZone;

export interface DropZoneOptions {
    dataKey?: string;
    allowDrag?: boolean;
    allowDrop?: boolean;
    onFiles?: (files: File[], event?: React.DragEvent) => void;
    onData?: (data: any, event?: React.DragEvent) => void;
    onText?: (text: string, event?: React.DragEvent) => void;
    onUri?: (url: string, event?: React.DragEvent) => void;
    onOverChange?: (over: boolean) => void;
    children: (props: DropZoneChildrenProps) => React.ReactNode;
}

export interface DropZoneChildrenProps {
    events: {
        onDragEnter: (event: React.DragEvent) => void;
        onDragOver: (event: React.DragEvent) => void;
        onDragLeave: (event: React.DragEvent) => void;
        onDrop: (event: React.DragEvent) => void;
    },
    over: boolean;
}