import IconUpload from "@bimser/icons/16/upload";
import { UploadChangeParam } from 'antd/lib/upload';
import * as React from "react";
import { BCUpload, findMessage } from "../../..";
import BCButton from '../../../BCButton';
import { IPropertyComponentProps } from '../../entities';

export default function UploadProperty(props: IPropertyComponentProps) {

    const onChange = (info: UploadChangeParam) => {
        if (info.file.status === 'done') {
            props.onValueChanged(props.item, info.file.name);
        }
    }

    return (
        <BCUpload {...props.externalData && props.externalData.uploadOptions ? props.externalData.uploadOptions : undefined} shownFileListItemsCount={1} onChange={onChange}>
            <BCButton
                icon={<IconUpload />}
            >
                {findMessage.get('101803')}
            </BCButton>
        </BCUpload>
    )
}