import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Record, Map } from "immutable";
import IProcessStructure from "./types/IProcessStructure";

interface IBaseProcessDetailsState {
    loading: boolean,
    totalCount: number
}

export interface IProcessDetailsState extends IBaseProcessDetailsState {
    data: Array<IDictionary<any>>,
    structure: Array<IProcessStructure>
}

export interface IReadonlyProcessDetailsState extends IBaseProcessDetailsState {
    data: List<Map<string, any>>,
    structure: List<IProcessStructure>
}

export type ProcessDetailsState = ReadonlyRecord<IReadonlyProcessDetailsState>;

export const ProcessDetailsStateFactory = Record<IReadonlyProcessDetailsState>({
    loading: false,
    data: List<Map<string, any>>(),
    structure: List<IProcessStructure>(),
    totalCount: 0
});

export const ProcessDetailsStateFactoryFromJS = function (data: IProcessDetailsState) {
    const _data : Array<Map<string, any>> = data.data.map((item: IDictionary<any>) => Map(item));
    
    return ProcessDetailsStateFactory({
        ...data,
        data: List<Map<string, any>>(_data),
        structure: List<IProcessStructure>(data.structure)
    })
}