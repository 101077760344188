import { IExtremeGridState, IPagingOptions  } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changePagingReducer(state: IExtremeGridState, action: IBaseMutableAction<IPagingOptions>): IExtremeGridState {
    return Object.assign({}, state, {
        paging: {
            ...state.paging,
            pageSize: action.payload.pageSize,
            pageIndex: action.payload.pageIndex
        }
    })
}