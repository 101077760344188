import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

export interface IBaseLanguage {
    id: number
    name: string,
    culture: string,
    isDefault?: boolean,
    twoLetterCulture?: string,
}

export interface ILanguage extends IBaseLanguage { }

export interface IReadonlyLanguage extends IBaseLanguage { }

export type Language = ReadonlyRecord<IReadonlyLanguage>;

export const LanguageFactory = Record<IReadonlyLanguage>({
    id: 0,
    name: null,
    culture: null,
    isDefault: false,
    twoLetterCulture: null
});

export const LanguageFactoryFromJS = function (data: ILanguage) {
    return LanguageFactory(data);
}