import { ReadonlyRecord } from '@bimser/core';

import { Record } from 'immutable';

interface IBaseActionHistoryField {
    type: string;
    field: string;
    oldValue: any;
    newValue: any;
    oldValueText: any;
    newValueText: any;
}

export interface IActionHistoryField extends IBaseActionHistoryField {

}

export interface IReadonlyActionHistoryField extends IBaseActionHistoryField {

}

export type ActionHistoryField = ReadonlyRecord<IReadonlyActionHistoryField>;

export const ActionHistoryFieldFactory = Record<IReadonlyActionHistoryField>({
    type: null,
    field: null,
    oldValue: null,
    newValue: null,
    oldValueText: null,
    newValueText: null,
});

export const ActionHistoryFieldFactoryFromJS = function (data: IActionHistoryField) {
    return ActionHistoryFieldFactory({
        ...data,
    });
}
