import React, { lazy, Suspense } from 'react';
import * as MapEntities from './entities/IMapProps';

const BCMapLazy = lazy(
    () => import(/* webpackChunkName: "map" */ "./components")
);

const BCMap = React.forwardRef<MapEntities.BCMapRef, MapEntities.IMapProps>((props, ref) => {
    return (
        <Suspense fallback={<div></div>}>
            <BCMapLazy ref={ref} {...props} />
        </Suspense>
    );
});

export { BCMap, MapEntities as BCMapEntities };
