import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Record, Map } from "immutable";

interface IBasePlant {
    id: string,
    code: string,
    companyId: string,
    status: boolean,
    company: string,
}

export interface IPlant extends IBasePlant {
    description: IDictionary<string>
    managers: Array<string>
}

export interface IReadonlyPlant extends IBasePlant {
    description: Map<string, string>
    managers: List<string>
}

export type Plant = ReadonlyRecord<IReadonlyPlant>;

export const PlantFactory = Record<IReadonlyPlant>({
    id: null,
    code: null,
    description: Map(),
    managers: List(),
    status: false,
    companyId: null,
    company: null,
});

export const PlantFactoryFromJS = function (plant: IPlant) {
    return PlantFactory({
        ...plant,
        managers: List(plant.managers),
        description: Map(plant.description)
    })
}