export const OPEN_SECURITY_PANEL = 'SECURITY.OPEN_SECURITY_PANEL';
export const ON_ADD_GROUP_CLICK = 'SECURITY.ON_ADD_GROUP_CLICK';
export const ON_DELETE_GROUP_CLICK = 'SECURITY.ON_DELETE_GROUP_CLICK';
export const ON_EDIT_GROUP_CLICK = 'SECURITY.ON_EDIT_GROUP_CLICK';
export const GET_PERMISSIONS_BY_GROUP_SECRETKEY = 'SECURITY.GET_PERMISSIONS_BY_GROUP_ID';

export const ON_PERMISSION_SEARCH_CLICK_ACTION = "SECURITY.ON_PERMISSION_SEARCH_CLICK_ACTION";
export const ON_PERMISSION_SAVE_CHANGES_CLICK_ACTION = 'SECURITY.ON_PERMISSION_SAVE_CHANGES_CLICK_ACTION';
export const ON_PERMISSION_UNDO_CHANGES_CLICK_ACTION = 'SECURITY.ON_PERMISSION_UNDO_CHANGES_CLICK_ACTION';
export const ON_PERMISSION_RESET_CLICK = 'SECURITY.ON_PERMISSION_RESET_CLICK_ACTION';

export const ON_MEMBERS_ADD_CLICK_ACTION = 'SECURITY.ON_MEMBERS_ADD_CLICK_ACTION';
export const ON_MEMBERSOF_ADD_CLICK_ACTION = 'SECURITY.ON_MEMBERSOF_ADD_CLICK_ACTION';

export const ON_MEMBER_DELETE_CLICK_ACTION = 'SECURITY.ON_MEMBER_DELETE_CLICK_ACTION';
export const ON_PERMISSIONS_REFRESH_CLICK_ACTION = 'SECURITY.ON_PERMISSIONS_REFRESH_CLICK_ACTION';
export const REJECT_TRANSACTION = "SECURITY.REJECT_TRANSACTION";
export const RESOLVE_TRANSACTION = "SECURITY.RESOLVE_TRANSACTION";
export const OPEN_ADDMODAL_TRANSACTION_ACTION = "SECURITY.OPEN_ADDMODAL_TRANSACTION_ACTION";
export const GET_SEARCH_MEMBERS_ACTION = 'SECURITY.GET_SEARCH_MEMBERS_ACTION';
export const SET_PANEL_SEARCH_MEMBERS_ACTION = 'SECURITY.SET_PANEL_SEARCH_MEMBERS_ACTION';
export const DELETE_PANEL_SEARCH_MEMBERS_ACTION = 'SECURITY.DELETE_PANEL_SEARCH_MEMBERS_ACTION';
export const GET_MEMBER_INFO_ACTION = 'SECURITY.GET_MEMBER_INFO_ACTION';
export const UPDATE_PANEL_MEMBERS_ACTION = 'SECURITY.UPDATE_PANEL_MEMBERS_ACTION';
export const UPDATE_PERMISSION_ITEM_ACTION = 'SECURITY.UPDATE_PERMISSION_ITEM_ACTION';
export const UPDATE_PERMISSION_ITEMS_ACTION = 'SECURITY.UPDATE_PERMISSION_ITEMS_ACTION';
export const UPDATE_GROUP_ACTION = 'SECURITY.UPDATE_GROUP_ACTION';
export const REFRESH_GROUP_ACTION = 'SECURITY.REFRESH_GROUP_ACTION';
export const UPDATE_MEMBERS_INFO_DETAILS_ACTION = 'SECURITY.UPDATE_MEMBERS_INFO_DETAILS_ACTION';
export const LOADING_STATUS_CHANGE_ACTION = 'SECURITY.LOADING_STATUS_CHANGE_ACTION';
export const ON_CHANGE_PERMISSION_GROUP_ACTION = 'SECURITY.ON_CHANGE_PERMISSION_GROUP_ACTION';
export const UPDATE_FETCH_VALUE_ACTION = 'SECURITY.UPDATE_FETCH_VALUE_ACTION';