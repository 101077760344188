import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { ICompanyListFormProps } from './entities';
import { IBaseListFormProps } from '../../../common/base/components/listForm/entities';


const CompanyListContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function CompanyListContainer(props: React.PropsWithChildren<ICompanyListFormProps & IBaseListFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <CompanyListContainerLazy {...props} />
        </Suspense>
    );
}
CompanyListContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default CompanyListContainer;