import { DmData, DmDataFactoryFromJS, IDmData } from "@bimser/common";
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

interface IBaseRecentFilesState {
    loading: boolean
}

export interface IRecentFilesState extends IBaseRecentFilesState {
    data: Array<IDmData>
}

export interface IReadonlyRecentFilesState extends IBaseRecentFilesState {
    data: List<DmData>
}

export type RecentFilesState = ReadonlyRecord<IReadonlyRecentFilesState>;

export const RecentFilesStateFactory = Record<IReadonlyRecentFilesState>({
    loading: false,
    data: List<DmData>()
});

export const RecentFilesStateFactoryFromJS = function (data: IRecentFilesState) {
    const _data: Array<DmData> = data.data.map((item: IDmData) => {
        return DmDataFactoryFromJS(item);
    });
    
    return RecentFilesStateFactory({
        ...data,
        data: List<DmData>(_data)
    })
}