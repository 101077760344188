import * as React from "react";
import * as ReactDOM from "react-dom";
import Spin from 'antd/lib/spin';
import IProps from "./entities/IProps";
import * as Styles from './assets/style.scss';
export default class BCLoading extends React.Component<IProps, any>{

    constructor(props: IProps) {
        super(props);
        Styles;
    }
    render() {
        return (
            <div className={"loadingOverlay " + Styles.loadingOverlay + (this.props.show ? " " + Styles.show : "") + " " + (this.props.overlayClassName ?? "")}>
                <Spin
                    size={this.props.size}
                    delay={this.props.delay}
                    className={this.props.className}
                    spinning={(this.props.show == undefined || this.props.show == null) ? false : this.props.show}
                    style={this.props.style}
                >
                    {this.props.children}
                </Spin>
            </div>
        )
    }
}
export { IProps };