import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import * as Styles from '../assets/style.scss';
import { BCButton, findMessage, BCCard, BCPassword, BCInputRef } from '@bimser/components';
import { IMFAComponentCProps, IMFAComponentProps } from '../entities';
import { MFAType } from '../entities';
import { Col, Row } from 'antd';
import IconCodeAuthenticator from "@bimser/icons/32/code-authenticator";
import IconCodeSms from "@bimser/icons/32/code-sms";
import IconCodeEmail from "@bimser/icons/32/code-email";

type MultiFactorAuthProps = React.PropsWithChildren<IMFAComponentProps & IMFAComponentCProps>;

const Strings = {
    mfaTypes:
        [{
            type: MFAType.email,
            name: findMessage.get("102602"),
            description: findMessage.get("102603"),
            message: findMessage.get("102613"),
            placeholder: findMessage.get("102616"),
            icon: IconCodeEmail
        },
        {
            type: MFAType.sms,
            name: findMessage.get("102604"),
            description: findMessage.get("102605"),
            message: findMessage.get("102614"),
            placeholder: findMessage.get("102617"),
            icon: IconCodeSms
        },
        {
            type: MFAType.authenticator,
            name: findMessage.get("102606"),
            description: findMessage.get("102607"),
            message: findMessage.get("102615"),
            placeholder: findMessage.get("102618"),
            icon: IconCodeAuthenticator
        },]
};

export default function MultiFactorAuth(props: MultiFactorAuthProps) {
    const authPinInputRef = React.useRef<BCInputRef>(null);

    function handleMFAValidation() {
        if (authPinInputRef.current?.input.value.length != 0)
        props.onValidationMFA({
            mfaType: props.currentMFAMethod,
            language: props.selectedLang,
            pin: authPinInputRef.current?.input.value
        });
    }

    function onClickLoginPanel() {
        if (props.onClickLoginPanel) {
            props.onClickLoginPanel();
        }
        props.onResetMFAProperties();
        window.location.hash = "";
    }

    function onSelectMFAType(mfaType: MFAType) {
        props.onSelectMFAType({ mfaType: mfaType, language: props.selectedLang });
    }

    function renderMFAOptions() {
        return Strings.mfaTypes.filter(option => props.mfaTypes.includes(option.type)).map(option => {
            const Icon = option.icon;
            return (
                <Row key={option.type} className={Styles.MFAOption} onClick={() => onSelectMFAType(option.type)}>
                    <Col span={3}>
                        <Icon className={Styles.MFAOptionIcon} />
                    </Col>
                    <Col span={18} offset={1}>
                        <div className={Styles.MFAOptionTitle}>{option.name}</div>
                        <div className={Styles.MFAOptionDescription}>{option.description}</div>
                    </Col>
                </Row>
            );
        });
    }

    function renderMFATypeSelection() {
        return <>
            <span className={Styles.title}>{findMessage.get("102626")}</span>
            <BCCard className={Styles.MFATypeSelectionContainer} >
                {renderMFAOptions()}
            </BCCard>
            <a className={Styles.linkBack} onClick={onClickLoginPanel}>
                {findMessage.get("100753")}
            </a>
        </>;
    }

    function renderMFAValidation() {
        const validationType = Strings.mfaTypes.find(option => option.type == props.currentMFAMethod);
        const Icon = validationType.icon;
        return (
            <div className={Styles.MFAValidationContainer}>
                <span className={Styles.title}>{validationType.name}</span>
                <Icon className={Styles.MFALogo} />
                <span className={Styles.MFAMessage}>{validationType.message}</span>
                <span className={Styles.MFALabel}>{validationType.name}</span>

                <BCPassword
                    className={Styles.MFAInput}
                    ref={authPinInputRef}
                    placeHolder={validationType.placeholder}
                    onPressEnter={handleMFAValidation}
                    autoFocus
                    visibilityToggle
                    autoComplete="one-time-code"
                />
                <BCButton
                    className={Styles.MFAValidationButton}
                    type="primary"
                    text={findMessage.get("100004")}
                    onClick={handleMFAValidation}
                />

                <a className={Styles.linkBack} onClick={onClickLoginPanel}>
                    {findMessage.get("100753")}
                </a>
            </div>
        );
    }

    function generateContent() {
        return props.currentMFAMethod ? renderMFAValidation() : renderMFATypeSelection();
    }

    return (
        <div className={Styles.loginContainer}>
            {generateContent()}
        </div>
    );
}
