import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record, List } from "immutable";
import { IInheritContent, InheritContent, InheritContentFactoryFromJS, InheritFactory } from "./IInherit";
import { SettingsContentFactoryFromJS, ISettingsContent, SettingsContent } from "./ISettings";

export interface IBaseIndexSettings {
    inherited: boolean,
}

export interface IIndexSettingsContent extends IBaseIndexSettings {
    settings: Array<ISettingsContent>,
    processorConfigNames: Array<string>,
    why: IInheritContent
}

export interface IReadonlyIndexSettings extends IBaseIndexSettings {
    settings: List<SettingsContent>,
    processorConfigNames: List<string>,
    why: InheritContent
}

export type IndexSettingsContent = ReadonlyRecord<IReadonlyIndexSettings>;

export const IndexSettingsFactory = Record<IReadonlyIndexSettings>({
    inherited: false,
    settings: List(),
    processorConfigNames: List(),
    why: InheritFactory()
});

export const IndexSettingsContentFactoryFromJS = function (data: IIndexSettingsContent) {

    let _settings: Array<SettingsContent> = (data && data.settings) ? data.settings.map((d) => {
        return SettingsContentFactoryFromJS(d);
    }) : [];

    return IndexSettingsFactory({
        inherited: data.inherited,
        settings: List(_settings),
        processorConfigNames: List(data.processorConfigNames),
        why: InheritContentFactoryFromJS(data.why)
    });
}