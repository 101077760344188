import { debounce } from "@bimser/core";
import classNames from 'classnames/bind';
import * as _ from 'lodash';
import _at from "lodash/at";
import * as React from "react";
import BCCollapse, { ICollapseItem as BCCollapseItem } from '../../BCCollapse';
import { IInputChangeEventArgs } from '../../BCInput';
import * as Styles from "../assets/style.scss";
import PropertyItem from "../components/PropertyItem";
import PropertySearchBox from "../components/PropertySearchBox";
import { ICollapseItem, IPropertyCollapsePanelProps, IPropertyItem } from '../entities';

const cx = classNames.bind(Styles);

const PropertyCollapsePanel = React.memo((props: IPropertyCollapsePanelProps) => {
    const [searchKey, setSearchKey] = React.useState('');
    const [expandedKeys, setExpandedKeys] = React.useState<string[]>(props.collapseItems.map((i) => i.key));
    const onSearchChange = debounce((args: IInputChangeEventArgs) => setSearchKey(args.data.toLowerCase()), 300);

    React.useEffect(() => {
        setExpandedKeys(props.collapseItems.map(i => i.key))
    }, [props.guid]);

    const onChangeExpandedKeys = React.useCallback((expandedKeys: Array<string> | string) => {
        setExpandedKeys(expandedKeys as Array<string>)
    }, []);

    const getPropertyData = React.useCallback((key: string) => {
        if (!key.includes('.')) {
            if (Array.isArray(props.data[key])) {
                return [...props.data[key]]
            } else if (typeof props.data[key] === "object") {
                return props.data[key] ? { ...props.data[key] } : null
            } else {
                return props.data[key]
            }
        }
        return _at(props.data, key)[0];
    }, [props.data]);

    const expandToggle = () => {
        setExpandedKeys(expandedKeys.length === props.collapseItems.length ? [] : props.collapseItems.map((i) => i.key))
    }

    const renderProperties = (collapse: ICollapseItem, propertyItems: Array<IPropertyItem>) => {
        const mosUsedCollapseKey = "mostUsedPropertiesCollapse_" + props.tabKey;
        const _propertyItems = propertyItems ? propertyItems.filter((property: IPropertyItem) => (searchKey && collapse.key === mosUsedCollapseKey ? false : true) && property.label.toLowerCase().includes(searchKey)) : [];
        if (!_propertyItems.length) return false;

        const groupedItems = _.chain(_propertyItems).groupBy('groupName').value()
        const content: JSX.Element[] = [];

        Object.keys(groupedItems).forEach(group => {
            const classNames = cx({
                propertyInspector: true,
                propertyGroup: group !== "undefined" && group !== "null" ? true : false
            });

            const groupedContent = groupedItems[group].map((property: IPropertyItem) => <PropertyItem
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                propertyItem={property}
                key={property.key}
                onValueChanged={props.onValueChanged}
                reload={props.reload}
                fireAction={props.fireAction}
                value={props.data ? getPropertyData(property.key) : null}
                getOptionsPromise={props.getOptionsPromise}
                onRefresh={props.onRefresh}
                guid={props.data?.controlId ?? props.guid}
                commonPopoverItems={props.commonPopoverItems}
                showCustomBadgeOnLabel={props.showCustomBadgeOnLabel}
            />);

            content.push(<div className={classNames} data-title={group} key={group}>
                {groupedContent}
            </div>);
        });

        return content;
    }

    const renderSearch = () => {
        if (props.searchable !== false) {
            return (
                <PropertySearchBox
                    onChange={onSearchChange}
                    onToggleExpand={expandToggle}
                    expandAll={expandedKeys.length === props.collapseItems.length}
                />
            )
        }
    }

    const getPropertyCollapsePanels = () => {
        const emptyCollapsePanels: string[] = [];

        let content: BCCollapseItem[] = [...props.collapseItems]
            .sort((a, b) => a.orderNo - b.orderNo)
            .map((collapse: ICollapseItem) => {
                const properties = renderProperties(collapse, collapse.properties);

                if (properties === false) {
                    emptyCollapsePanels.push(collapse.key);
                }

                return {
                    key: collapse.key,
                    label: collapse.label,
                    orderNo: collapse.orderNo,
                    expanded: collapse.expanded,
                    collapsible: collapse.disabled ? 'disabled' : 'header',
                    content: properties
                }
            });

        if (!content.length) return null;
        content = content.filter((collapse: BCCollapseItem) => !emptyCollapsePanels.includes(collapse.key));
        return content
    };

    return (
        <>
            {renderSearch()}
            <BCCollapse
                items={getPropertyCollapsePanels()}
                scrollbarStyles={props.scrollbarStyles}
                expandedKeys={expandedKeys}
                onChangeExpandedKeys={onChangeExpandedKeys}
                hasScrollbar
            />
        </>
    )
})

export default PropertyCollapsePanel;