import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseContentListPanelContent {
    isLoading?: boolean;
    childPanelGuid?: string;
}

export interface IContentListPanelContent extends IBaseContentListPanelContent { }

export interface IReadonlyContentListPanelContent extends IBaseContentListPanelContent { }

export type ContentListPanelContent = ReadonlyRecord<IReadonlyContentListPanelContent>;

export const ContentListPanelContentFactory = Record<IReadonlyContentListPanelContent>({
    isLoading: false,
    childPanelGuid: null
});

export const ContentListPanelContentFactoryFromJS = function (data: IContentListPanelContent) {
    return ContentListPanelContentFactory(data);
}