import { getPopupContainer, isPromise } from "@bimser/core";
import TreeSelect from 'antd/lib/tree-select';
import * as React from "react";
import * as Styles from './assets/style.scss';
import * as BCTreeSelectEntities from './entities';
import { ITreeSelectProps } from './entities';
import { ITreeData, ITreeSelectLoadingArgs } from './entities/ITreeSelectProps';

Styles;
const treeSelectStyle: React.CSSProperties = { width: "100%" };

const BCTreeSelect = React.memo(React.forwardRef<BCTreeSelectRef, ITreeSelectProps>((props, ref) => {
    const [options, setOptions] = React.useState(props.treeData);

    React.useImperativeHandle(ref, () => ({ setOptions: _options => setOptions(_options),focus }))

    React.useEffect(() => {
        if (props.isRunOnLoadOptionsStartup) onLoadOptions(true);
    }, [])

    React.useEffect(() => {
        if (Array.isArray(props.treeData) && !props.onLoadOptions) setOptions(props.treeData)
        else if (props.isRunOnLoadOptionsStartup) onLoadOptions(true);
    }, [props.treeData])


    const onLoadOptions = (isInitialLoader?: boolean) => {
        if (props.onLoadOptions && (!props.isRunOnLoadOptionsStartup || isInitialLoader)) {

            const response = props.onLoadOptions();

            if (isPromise(response)) {

                const promise: Promise<ITreeSelectLoadingArgs> = response as Promise<ITreeSelectLoadingArgs>;

                if (promise && promise.then) promise.then(args => {

                    if (args && args.options && args.options.length > 0 && args.allow !== false) setItemsInState(args.options);

                    else setItemsInState(props.treeData);

                })

                else setItemsInState([]);

            } else setItemsInState(props.treeData);

        } else setItemsInState(props.treeData);

    }

    const setItemsInState = (items: Array<ITreeData>) => {
        if (items && Array.isArray(items) && items.length > 0) setOptions(items)
    }

    const onFocus = () => onLoadOptions(true);

    const onChange = (args: any[]) => {
        if (!props.readOnly && props.onChange) props.onChange(args);
    }

    const treeDefaultExpandedKeys = props.treeDefaultExpandAll ? {} : { treeDefaultExpandedKeys: props.treeDefaultExpandedKeys };
    const treeExpandedKeys = props.treeExpandedKeys ? {} : { treeExpandedKeys: props.treeExpandedKeys };

    return (
        <div title={props.title} style={props.style}>
            <TreeSelect
                treeData={options}
                value={props.value}
                onChange={onChange}
                treeCheckable={props.treeCheckable}
                showCheckedStrategy={props.showCheckedStrategy}
                placeholder={props.searchPlaceholder}
                allowClear={props.allowClear}
                treeDataSimpleMode={props.treeDataSimpleMode}
                size={props.size}
                showSearch={props.showSearch}
                dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                multiple={props.multiple}
                dropdownStyle={{ ...props.dropdownStyle, backgroundColor: props.style?.backgroundColor }}
                style={treeSelectStyle}
                treeDefaultExpandAll={props.treeDefaultExpandAll}
                filterTreeNode={props.filterTreeNode}
                treeNodeFilterProp={props.treeNodeFilterProp}
                treeNodeLabelProp={props.treeNodeLabelProp}
                loadData={props.loadData}
                labelInValue={props.labelInValue}
                treeCheckStrictly={props.treeCheckStrictly}
                getPopupContainer={props.getPopupContainer || getPopupContainer}
                onSelect={props.onSelect}
                onSearch={props.onSearch}
                className={props.className}
                dropdownClassName={props.dropdownClassName}
                maxTagCount={props.maxTagCount}
                maxTagPlaceholder={props.maxTagPlaceholder}
                maxTagTextLength={props.maxTagTextLength}
                onFocus={onFocus}
                suffixIcon={props.suffixIcon}
                showArrow={props.showArrow}
                dropdownRender={props.dropdownRender}
                notFoundContent={props.notFoundContent}
                onTreeExpand={props.onTreeExpand}
                {...treeDefaultExpandedKeys}
                {...treeExpandedKeys}
            />
        </div>
    )
}))
export default BCTreeSelect;
export { ITreeSelectProps, BCTreeSelectEntities };
export type BCTreeSelectRef = {
    setOptions: (options: BCTreeSelectEntities.ITreeData[]) => void;
    focus: () => void
}

