import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record, List } from "immutable";
import { FieldsContentFactoryFromJS, FieldsContent, FieldsFactory, IFieldsContent } from "./IFields";
import { ProjectContentFactoryFromJS, ProjectContent, ProjectFactory, IProjectContent } from "./IProject";
import { FormsContentFactoryFromJS, FormsContent, FormsFactory, IFormsContent } from "./IForms";
import { ProfilesContentFactoryFromJS, ProfilesContent, ProfilesFactory, IProfilesContent } from "./IProfiles";
import { IInheritContent, InheritContent, InheritContentFactoryFromJS, InheritFactory } from "./IInherit";


export interface IBaseMetaData {
    inherited: boolean
}

export interface IMetaDataContent extends IBaseMetaData {
    projects: Array<IProjectContent>,
    forms: Array<IFormsContent>,
    value: {
        fields: Array<string>,
        profiles: Array<IProfilesContent>
    },
    why: IInheritContent
}

export interface IReadonlyMetaData extends IBaseMetaData {
    projects: List<ProjectContent>,
    forms: List<FormsContent>,
    value: {
        fields: List<string>,
        profiles: List<ProfilesContent>,
    },
    why: InheritContent
}

export type MetaDataContent = ReadonlyRecord<IReadonlyMetaData>;

export const MetaDataFactory = Record<IReadonlyMetaData>({
    inherited: false,
    projects: List<ProjectContent>(),
    forms: List<FormsContent>(),
    value: {
        fields: List(),
        profiles: List<ProfilesContent>()
    },
    why: InheritFactory()
});

export const MetaDataContentFactoryFromJS = function (data: IMetaDataContent) {

    let _projects: Array<ProjectContent> = data?.projects.map((d) => {
        return ProjectContentFactoryFromJS(d);
    }) || [];

    let _forms: Array<FormsContent> = data?.forms.map((d) => {
        return FormsContentFactoryFromJS(d);
    }) || [];

    let _profiles: Array<ProfilesContent> = data?.value?.profiles.map((d) => {
        return ProfilesContentFactoryFromJS(d);
    }) || [];

    return MetaDataFactory({
        inherited: data.inherited,
        projects: List(_projects),
        forms: List(_forms),
        value: {
            fields: List<string>(data.value.fields),
            profiles: List(_profiles)
        },
        why: InheritContentFactoryFromJS(data.why)
    });
}
