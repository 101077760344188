export const LOGIN_TRANSACTION = "APP_COMMON.LOGIN_TRANSACTION";
export const LOGIN = "APP_COMMON.LOGIN";
export const LOGIN_LOADING_STATUS = "APP_COMMON.LOGIN_LOADING_STATUS";
export const LOGIN_PARAMETERS_TRANSACTION = "APP_COMMON.LOGIN_PARAMETERS_TRANSACTION";
export const LOGIN_PARAMETERS = "APP_COMMON.LOGIN_PARAMETERS";
export const CONTROL_TOKEN_TRANSACTION = "APP_COMMON.CONTROL_TOKEN_TRANSACTION";
export const CONTROL_TOKEN = "APP_COMMON.CONTROL_TOKEN";
export const GET_USER_STARTUP_TRANSACTION = "APP_COMMON.GET_USER_STARTUP_TRANSACTION";
export const GET_USER_DETAIL_ACTION = "APP_COMMON.GET_USER_DETAIL_ACTION";
export const SET_DASHBOARD_SETTINGS_ACTION = "APP_COMMON.SET_DASHBOARD_SETTINGS_ACTION";
export const SET_USER_PROFILE_IMAGE_ACTION = "APP_COMMON.SET_USER_PROFILE_IMAGE_ACTION";
export const EDIT_USER_PROFILE_IMAGE_TRANSACTION = "APP_COMMON.EDIT_USER_PROFILE_IMAGE_TRANSACTION";
export const GET_USER_SETTING_PERMISSION = "APP_COMMON.GET_USER_SETTING_PERMISSION";
export const GET_USER_SETTING_PARAMATERS = "APP_COMMON.GET_USER_SETTING_PARAMATERS";
export const CHANGE_TOKEN_CHECK_STATUS = "APP_COMMON.CHANGE_TOKEN_CHECK_STATUS";
export const CHANGE_SELECTED_CULTURE_ACTION_SAGA = "APP_COMMON.CHANGE_SELECTED_CULTURE_ACTION_SAGA";
export const CHANGE_SELECTED_CULTURE_ACTION = "APP_COMMON.CHANGE_SELECTED_CULTURE_ACTION";
export const CHANGE_SELECTED_CULTURE_AFTER_ACTION = "APP_COMMON.CHANGE_SELECTED_CULTURE_AFTER_ACTION";
export const ONCLICK_PASSWORD_RECOVERY_TRANSACTION = "APP_COMMON.ONCLICK_PASSWORD_RECOVERY_TRANSACTION";
export const ONCLICK_CHANGE_MY_PASSWORD_TRANSACTION = "APP_COMMON.ONCLICK_CHANGE_MY_PASSWORD_TRANSACTION";
export const LOGIN_AS_USER_SELECTED_ACTION = "APP_COMMON.LOGIN_AS_USER_SELECTED_ACTION";
export const LOGIN_WITH_TOKEN_TRANSACTION = "APP_COMMON.LOGIN_WITH_TOKEN_TRANSACTION";
export const LOGIN_WITH_DIGITAL_SIGNATURE = "APP_COMMON.LOGIN_WITH_DIGITAL_SIGNATURE";
export const LOGIN_WITH_MOBILE_SIGNATURE = "APP_COMMON.LOGIN_WITH_MOBILE_SIGNATURE";
export const LOGIN_WITH_EXTERNAL_ACCOUNT_TRANSACTION = "APP_COMMON.LOGIN_WITH_EXTERNAL_ACCOUNT_TRANSACTION";
export const SSO_CONTROL_TRANSACTION_ACTION = "APP_COMMON.SSO_CONTROL_TRANSACTION_ACTION";
export const ON_OAUTH_LOGIN_TRANSACTION = "APP_COMMON.ON_OAUTH_LOGIN_TRANSACTION";
export const SET_MOBILE_SIGNATURE_FINGERPRINT = "APP_COMMON.SET_MOBILE_SIGNATURE_FINGERPRINT";
export const SET_MFA_PROPERTIES_ACTION = "APP_COMMON.SET_MFA_PROPERTIES_ACTION";
export const SET_THEME_PROPERTIES = "APP_COMMON.SET_THEME_PROPERTIES";
export const SELECT_MFA_TYPE_TRANSACTION = "APP_COMMON.SELECT_MFA_TYPE_TRANSACTION";
export const VALIDATION_MFA = "APP_COMMON.VALIDATION_MFA";
export const SET_MFATYPES = "APP_COMMON.SET_MFATYPES"
export const SET_CURRENT_MFA_METHOD = "APP_COMMON.SET_CURRENT_MFA_METHOD"
export const GET_CHANGE_PASSWORD_REQUIREMENTS_TRANSACTION = "GET_CHANGE_PASSWORD_REQUIREMENTS_TRANSACTION"

export const INITIALIZE_APPLICATION_STARTUP = "APP_COMMON.INITIALIZE_APPLICATION_STARTUP";
export const INITIALIZED_APPLICATION_STARTUP = "APP_COMMON.INITIALIZED_APPLICATION_STARTUP";

export const SET_CAPTCHA_STATE_ACTION = "APP_COMMON.SET_CAPTCHA_STATE_ACTION";
export const REFRESH_CAPTCHA_ACTION = "APP_COMMON.REFRESH_CAPTCHA_ACTION";
export const CHANGE_ADMIN_TOOLS_MENU_VISIBILITY = "APP_COMMON.CHANGE_ADMIN_TOOLS_MENU_VISIBILITY";
export const CHANGE_USER_MENU_VISIBILITY = "APP_COMMON.CHANGE_USER_MENU_VISIBILITY";