import React, { lazy, Suspense } from 'react';
import { ISalaryFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';

const SalaryContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function SalaryContainer(props: React.PropsWithChildren<ISalaryFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <SalaryContainerLazy {...props} />
        </Suspense>
    );
}
SalaryContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default SalaryContainer;