import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IPositionMaintenanceListFormContent extends IBaseFormContent {
    // positionReplacements?: Array<IReplacement>,
    selectedReplacement?: IReplacement
}

export interface IReadonlyPositionMaintenanceListFormContent extends IBaseFormContent {
    // positionReplacements: List<Replacement>,
    selectedReplacement: Replacement
}

export type PositionMaintenanceListFormContent = ReadonlyRecord<IReadonlyPositionMaintenanceListFormContent>;

export const PositionMaintenanceListFormContentFactory = Record<IReadonlyPositionMaintenanceListFormContent>({
    panelId: "",
    // positionReplacements: List<Replacement>(),
    selectedReplacement: ReplacementFactory(),
    showLoading: false,
    editWindowGuid: "",
    editWindowisActive: false,
    selectedCompanyId: 0
});

export const PositionMaintenanceListFormContentFactoryFromJS = function (data: IPositionMaintenanceListFormContent) {
    // let _positionReplacements: Array<Replacement> = data.positionReplacements && data.positionReplacements.length ? data.positionReplacements.map((replacement) => {
    //     return ReplacementFactoryFromJS(replacement);
    // }) : [];

    return PositionMaintenanceListFormContentFactory({
        panelId: data.panelId,
        // positionReplacements: List(_positionReplacements),
        selectedReplacement: ReplacementFactoryFromJS(data.selectedReplacement),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        selectedCompanyId: data.selectedCompanyId
    });
}