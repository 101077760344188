import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "../../../common/entities";
import { createSyncAction, IDictionary } from "@bimser/core";
import * as actionTypes from "./actionTypes";
import { IHumanResourceModule, Modules } from "../entities";
import { List, Map } from "immutable";
import { HumanResourceMenu, HumanResourceMenuFactoryFromJS, IHumanResourceMenu } from "../entities/models";

export const setMenuConfigAction = (menu: Array<IHumanResourceMenu>) => {
    const _menu : Array<HumanResourceMenu> = menu.map((item: IHumanResourceMenu) => HumanResourceMenuFactoryFromJS(item));

    return createSyncAction(actionTypes.SET_MENU_CONFIG, List(_menu));
}

export const setMenuStatus = (key: IHumanResourceModule, value: boolean) => (
    createSyncAction(actionTypes.SET_MENU_STATUS, Map({key, value}))
);

export const changeMenuStatusTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.CHANGE_MENU_STATUS, WebInterfaceActionFactoryFromJS(data))
)

export const onChangeSelectedMenuItemTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.ON_CHANGE_SELECTED_MENU_ITEM, WebInterfaceActionFactoryFromJS(data))
);

export const openHRFormTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.OPEN_HR_FORM, WebInterfaceActionFactoryFromJS(data))
);

export const closeHRFormTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.CLOSE_HR_FORM, WebInterfaceActionFactoryFromJS(data))
);

export const saveHRFormTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.ON_SAVE_FORM, WebInterfaceActionFactoryFromJS(data))
);

export const onChangeSelectedMenuItemAction = (selected: IHumanResourceModule) => (
    createSyncAction(actionTypes.ON_CHANGE_SELECTED_MENU_ITEM_ACTION, selected)
);

export const setLoadingAction = (loading: boolean) => (
    createSyncAction(actionTypes.SET_MODULE_LOADING, loading)
);

export const setHoldingsAction = (holdings: Array<Modules.IHolding>) => {
    const _holdings : Array<Modules.Holding> = holdings.map((holding: Modules.IHolding) => Modules.HoldingFactoryFromJS(holding));

    return createSyncAction(actionTypes.SET_HOLDINGS, List<Modules.Holding>(_holdings));
}

export const setHoldingAction = (holding: Modules.IHolding) => (
    createSyncAction(actionTypes.SET_HOLDING, Modules.HoldingFactoryFromJS(holding))
)

export const setCompaniesAction = (companies: Array<Modules.ICompany>) => {
    const _companies : Array<Modules.Company> = companies.map((company: Modules.ICompany) => Modules.CompanyFactoryFromJS(company));

    return createSyncAction(actionTypes.SET_COMPANIES, List<Modules.Company>(_companies));
}

export const setCompanyAction = (company: Modules.ICompany) => (
    createSyncAction(actionTypes.SET_COMPANY, Modules.CompanyFactoryFromJS(company))
);

export const setPlantsAction = (plants: IDictionary<Modules.IPlant>) => {
    const _plants : IDictionary<Modules.Plant> = Object.keys(plants).reduce((previous: IDictionary<Modules.Plant>, key: string) => {
        previous[key] = Modules.PlantFactoryFromJS(plants[key]);
        return previous;
    }, {});

    return createSyncAction(actionTypes.SET_PLANTS, Map(_plants));
}

export const setPlantAction = (plant: Modules.IPlant) => (
    createSyncAction(actionTypes.SET_PLANT, Modules.PlantFactoryFromJS(plant))
);

export const setOrganizationsAction = (organizations: IDictionary<Modules.IOrganization>) => {
    const _organizations : IDictionary<Modules.Organization> = Object.keys(organizations).reduce((previous: IDictionary<Modules.Organization>, key: string) => {
        previous[key] = Modules.OrganizationFactoryFromJS(organizations[key]);
        return previous;
    }, {});

    return createSyncAction(actionTypes.SET_ORGANIZATIONS, Map(_organizations));
}

export const setOrganizationAction = (organization: Modules.IOrganization) => (
    createSyncAction(actionTypes.SET_ORGANIZATION, Modules.OrganizationFactoryFromJS(organization))
);


export const onSearchPlantsComboboxTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.ON_SEARCH_PLANTS_COMBOBOX, WebInterfaceActionFactoryFromJS(data))
);

export const onSearchOrganizationsComboboxTransaction = (data: IWebInterfaceAction) => (
    createSyncAction(actionTypes.ON_SEARCH_ORGANIZATONS_COMBOBOX, WebInterfaceActionFactoryFromJS(data))
); 