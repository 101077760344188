import * as React from "react";
import {
    injectIntl,
    IntlProvider,
    FormattedRelative,
    FormattedNumber,
    FormattedDate

} from 'react-intl';
const GetFormattedDate = injectIntl((props: any) => (
    <span onClick={props.onClick} title={props.intl.formatDate(props.value)}>
        <FormattedDate {...props} />
    </span>
));
export default GetFormattedDate;