import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ISearchMenuResult, SearchMenuResult, SearchMenuResultFactoryFromJS } from "./ISearchMenuResult"
import { ISearchFormMenuResult, SearchFormMenuResult, SearchFormMenuResultFactoryFromJS } from "./ISearchMenuFormResult";

export interface ISearchResult {
    menuResults: Array<ISearchMenuResult>;
    workflowFormResults: Array<ISearchFormMenuResult>;
}

export interface IReadOnlySearchResult {
    menuResults: List<SearchMenuResult>;
    workflowFormResults: List<SearchFormMenuResult>;
}

export type SearchResult = ReadonlyRecord<IReadOnlySearchResult>;

export const SearchResultFactory = Record({
    menuResults: List<SearchMenuResult>(),
    workflowFormResults: List<SearchFormMenuResult>(),
});

export const SearchResultFactoryFromJS = (data: ISearchResult) => {
    const _menuResults: Array<SearchMenuResult> = data.menuResults.length ? data.menuResults.map((item: ISearchMenuResult) => {
        return SearchMenuResultFactoryFromJS(item);
    }) : [];

    const _workflowFormResults = data.workflowFormResults.length ? data.workflowFormResults.map((item: ISearchFormMenuResult) => {
        return SearchFormMenuResultFactoryFromJS(item)
    }) : [];

    return SearchResultFactory({
        menuResults: List(_menuResults),
        workflowFormResults: List(_workflowFormResults),
    })
}