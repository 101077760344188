import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IStepAction, StepAction, StepActionFactoryFromJS } from './IStepAction';

interface IBaseTourStep {
    selector: string,
    stepInteraction?: boolean
    title: string,
    content: JSX.Element,
}

export interface ITourStep extends IBaseTourStep {
    onBeforeActions?: IStepAction[]
}

export interface IReadonlyTourStep extends IBaseTourStep {
    onBeforeActions?: List<StepAction>
}

export type TourStep = ReadonlyRecord<IReadonlyTourStep>;

export const TourStepFactory = Record<IReadonlyTourStep>({
    selector: "",
    stepInteraction: true,
    title: null,
    content: null,
    onBeforeActions: List()
});

export const TourStepFactoryFromJS = function (data: ITourStep) {
    let _onBeforeActions = data && data.onBeforeActions ? data.onBeforeActions.map(a => StepActionFactoryFromJS(a)) : [];
    return TourStepFactory({
        selector: data.selector,
        stepInteraction: data.stepInteraction,
        title: data.title,
        content: data.content,
        onBeforeActions: List(_onBeforeActions)
    });
}