import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseFlowDocumentCreateOptions {
    projectName?: string,
    formName: string,
    secretKey: string
}

export interface IFlowDocumentCreateOptions extends IBaseFlowDocumentCreateOptions {}

export interface IReadonlyFlowDocumentCreateOptions extends IBaseFlowDocumentCreateOptions {}

export type FlowDocumentCreateOptions = ReadonlyRecord<IReadonlyFlowDocumentCreateOptions>;

export const FlowDocumentCreateOptionsFactory = Record<IReadonlyFlowDocumentCreateOptions>({
    projectName: null,
    formName: null,
    secretKey: null
});

export const FlowDocumentCreateOptionsFactoryFromJS = function (data: IFlowDocumentCreateOptions) {
    return FlowDocumentCreateOptionsFactory(data);
}