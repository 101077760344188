enum EDeviceTypes {
    Responsive = 'Responsive',
    GalaxyS5 = 'GalaxyS5',
    GalaxyNote3 = 'GalaxyNote3',
    IPhone5SE = 'Iphone5SE',
    IPhone678 = 'IPhone678',
    IPhone678Plus = 'IPhone678Plus',
    IPhoneX = 'IPhoneX',
    IPad = 'IPad',
    IPadMini = 'IPadMini',
    IPadPro = 'IPadPro'
}

export { EDeviceTypes };