import { BCBadge, BCButton, findMessage } from '@bimser/components';
import * as React from 'react';
import Tour from 'reactour'
import UITourProps from '../entities/UITourProps';
import IconClose from "@bimser/icons/16/close-panel";
import * as Styles from '../assets/style.scss';

const UITour = (props: UITourProps) => {

    function _onRequestClose() {
        props.onRequestClose();
    }

    function _nextStep() {
        if (props.tour && !props.tour.isLoading) {
            props.onNext();
        }
    }

    function _prevStep() {
        if (props.tour && !props.tour.isLoading) {
            props.onPrev();
        }
    }

    function renderFinishTourButton() {
        return (
            <BCButton
                text={findMessage.get("100824")}
                type="primary"
            />
        )
    }

    function renderStepTitle() {
        if (!props.tour) return <></>;
        return (
            <span className={Styles.titleText} title={props.tour.steps[props.tour.currentStep]?.title}>{props.tour.steps[props.tour.currentStep]?.title}</span>
        )
    }

    if (props.tour) {
        return (
            <Tour
                key={props.tour.id}
                disableDotsNavigation
                isOpen={props.tour.isOpen}
                steps={props.tour.steps}
                goToStep={props.tour.currentStep}
                onRequestClose={_onRequestClose}
                nextStep={_nextStep}
                prevStep={_prevStep}
                showCloseButton={false}
                showNumber={false}
                closeWithMask={false}
                lastStepNextButton={renderFinishTourButton()}
                className={Styles.UITourContainer}
                rounded={5}
            >
                <div className={Styles.TourHeader}>
                    <div className={Styles.TourTitle}>
                        <BCBadge count={props.tour.currentStep + 1} />
                        {renderStepTitle()}
                    </div>
                    <IconClose height="24px" width="24px" onClick={_onRequestClose} />
                </div>
            </Tour>
        )
    } else {
        return (<></>)
    }
}

export default UITour;
