import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IOvertime, Overtime, OvertimeFactory, OvertimeFactoryFromJS } from "..";

export interface IOvertimeListFormContent extends IBaseFormContent {
    selectedOvertime?: IOvertime,
}

export interface IReadonlyOvertimeListFormContent extends IBaseFormContent {
    selectedOvertime?: Overtime
}

export type OvertimeListFormContent = ReadonlyRecord<IReadonlyOvertimeListFormContent>;

export const OvertimeListFormContentFactory = Record<IReadonlyOvertimeListFormContent>({
    panelId: "",
    selectedOvertime: OvertimeFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: true,
});

export const OvertimeListFormContentFactoryFromJS = function (data: IOvertimeListFormContent) {
    const _selectedOvertime : Overtime = data.selectedOvertime ? OvertimeFactoryFromJS(data.selectedOvertime) : OvertimeFactory()

    return OvertimeListFormContentFactory({
        panelId: data.panelId,
        selectedOvertime: _selectedOvertime,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
    });
}