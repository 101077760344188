import { fork, put, takeEvery } from 'redux-saga/effects';
import { initializeUITourManagerTransaction } from '../../..';
import { ServiceList } from "../../../services/serviceList";
import { getUserDetailAction, getUserSettingParametersAction, getUserSettingPermissionsAction, setMFAProperties, setThemeProperties, setUserProfileImageAction } from '../actions';
import * as ActionTypes from "../actions/actionTypes";
import { IGetUserDetailResponse } from '../entities/GetUserDetailResponse';
import { IGetUserSettingParametersResponse } from '../entities/GetUserSettingParametersResponse';
import { IGetUserSettingPermissionsResponse } from '../entities/GetUserSettingPermissionsResponse';

export default function* watchGetUserStartupTransaction() {
    yield takeEvery(ActionTypes.GET_USER_STARTUP_TRANSACTION, getUserStartupTransaction);
}

export function* getUserStartupTransaction(sagaAction: any) {
    yield put(initializeUITourManagerTransaction());
    yield fork(getUserDetailData);
    yield fork(getUserSettingPermissions);
}

function* getUserDetailData() {
    try {
        navigator.geolocation.getCurrentPosition(loc => {
            (window as any).geoLocation = { lat: loc.coords.latitude, lon: loc.coords.longitude }
        });

        let isRedirect = window.location.hash.includes("_redirect");
        if (isRedirect) return;
        let response: IGetUserDetailResponse = yield ServiceList.hr.Users.GetUserDetail.call();
        yield put(setThemeProperties({
            backgroundColor: response.info.themeData?.backgroundColor,
            primaryColor: response.info.themeData?.primaryColor,
            image: response.info.themeData?.image
        }));
        yield put(getUserDetailAction(response));

        if (logoutUnauthorizedIDEUser(response.canNavigateIDE)) {
            return null;
        }

        yield put(setMFAProperties({ mfaEnabled: response.info.mfaEnabled, authenticatorEnabled: response.info.authenticatorEnabled }));
        yield put(getUserSettingParametersAction({ notificationSoundStatus: response.notificationSoundStatus }));

        const data: string = (window as any).serviceConfig.services.login.baseURL + response.info.profileImage;
        yield put(setUserProfileImageAction(data));
    } catch (error) {
        console.log("error", error);
    }
}

function logoutUnauthorizedIDEUser(canNavigateIDE: boolean) {
    if (window.location.pathname == '/ide/' && !canNavigateIDE) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        window.location.pathname = '/';
        return true;
    }
    return false
}

function* getUserSettingPermissions() {
    try {
        let isRedirect = window.location.hash.includes("_redirect");
        if (isRedirect) return;
        let response: IGetUserSettingPermissionsResponse = yield ServiceList.hr.UserSettings.GetUserSettingPermissions.call();
        yield put(getUserSettingPermissionsAction(response));
    } catch (error) {
        console.log("error", error);
    }
}

export function* getUserSettingParameters() {
    try {
        let response: IGetUserSettingParametersResponse = yield ServiceList.hr.UserSettings.GetUserSettingParameters.call();
        yield put(getUserSettingParametersAction(response));
    } catch (error) {
        console.log("error", error);
    }
}

export function* GetUserProfileImageDownloadUrl() {
    try {
        let response: string = yield ServiceList.hr.Users.GetUserProfileImageDownloadUrl.call();

        let data: string = (window as any).serviceConfig.services.login.baseURL + response;

        yield put(setUserProfileImageAction(data));
    } catch (error) {
        console.log("error", error);
    }
}