import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from 'immutable';
import { EventItem, EventItemFactoryFromJS, IEventItem } from "../../../../modules/workflow/entities";
import { IBaseFormContent } from "../../../entities";
import { FormsEntities } from '@bimser/common';

export interface IBaseAppFormPanelContent extends IBaseFormContent {
    childPanelGuid?: string,
    childPanelResponse?: any,
    openedOn?: FormsEntities.ChildFormShowType;
}
export interface IAppFormPanelContent extends IBaseAppFormPanelContent {
    flowEvents?: Array<IEventItem>,
}

export interface IReadonlyAppFormPanelContent extends IBaseAppFormPanelContent {
    flowEvents?: List<EventItem>
}

export type AppFormPanelContent = ReadonlyRecord<IReadonlyAppFormPanelContent>;

export const AppFormPanelContentFactory = Record<IReadonlyAppFormPanelContent>({
    panelId: "",
    isModified: false,
    flowEvents: List(),
    childPanelGuid: "",
    childPanelResponse: null,
    openedOn: "panel"
});

export const AppFormPanelContentFactoryFromJS = function (data: IAppFormPanelContent) {
    let _events: Array<EventItem> = data && data.flowEvents ? data.flowEvents.map((event) => {
        return EventItemFactoryFromJS(event);
    }) : [];

    return AppFormPanelContentFactory({
        panelId: data.panelId,
        isModified: data.isModified,
        flowEvents: List(_events),
        childPanelGuid: data.childPanelGuid,
        childPanelResponse: data.childPanelResponse,
        openedOn: data.openedOn
    });
}
