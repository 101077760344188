import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent, IProfession, ProfessionFactory, Profession, ProfessionFactoryFromJS, IPropertyValue, PropertyValue, PropertyValueFactoryFromJS, ICompanyObject, CompanyObject, CompanyObjectFactoryFromJS } from "..";

export interface IProfessionFormContent extends IBaseFormContent {
    showingProfession?: IProfession,
    customProperties?: Array<IPropertyValue>,
    companyObjects?: Array<ICompanyObject>,
}

export interface IReadonlyProfessionFormContent extends IBaseFormContent {
    showingProfession?: Profession,
    customProperties?: List<PropertyValue>,
    companyObjects?: List<CompanyObject>,
}

export type ProfessionFormContent = ReadonlyRecord<IReadonlyProfessionFormContent>;

export const ProfessionFormContentFactory = Record<IReadonlyProfessionFormContent>({
    panelId: "",
    showingProfession: ProfessionFactory(),
    initialHash: "",
    customProperties: List<PropertyValue>(),
    isModified: false,
    showLoading : false,
    companyObjects: List<CompanyObject>(),
});

export const ProfessionFormContentFactoryFromJS = function (data: IProfessionFormContent) {
    let _customProperties: Array<PropertyValue> = data.customProperties && data.customProperties.length ? data.customProperties.map((value: IPropertyValue) => {
        return PropertyValueFactoryFromJS(value);
    }) : [];

    let _companyObjects: Array<CompanyObject> = data.companyObjects && data.companyObjects.length ? data.companyObjects.map((value: ICompanyObject) => {
        return CompanyObjectFactoryFromJS(value);
    }) : [];

    return ProfessionFormContentFactory({
        panelId: data.panelId,
        showingProfession: ProfessionFactoryFromJS(data.showingProfession),
        initialHash: data.initialHash,
        customProperties: List(_customProperties),
        showLoading : data.showLoading,
        companyObjects: List(_companyObjects),
    });
}