import { ReadonlyRecord } from "@bimser/core";
import { Record } from 'immutable'
import { DmObjectType } from "../../../../../entities";

interface IBaseObjectItem{
    path?:string,
    secretKey?:string,
    parentSecretKey?: string,
    name?:string,
    type?: DmObjectType,
    id: string,
}

export interface IObjectItem extends IBaseObjectItem {
    
}

export interface IReadonlyObjectItem extends IBaseObjectItem {
    
}

export type ObjectItem = ReadonlyRecord<IReadonlyObjectItem>;

export const  ObjectItemFactory = Record<IReadonlyObjectItem>({
    path: null,
    secretKey: null,
    name: null,
    type: null,
    parentSecretKey: null,
    id: null
});

export const ObjectItemFactoryFromJS = function (data: IObjectItem) {
    return ObjectItemFactory(data);
}