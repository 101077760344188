import { Record, Map, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core"
import { IPropertyItem, PropertyItem, PropertyItemFactoryFromJS } from '../entities';

export interface IBaseCollapseItem {
    key: string,
    label: string,
    orderNo?: number,
    disabled?: boolean,
    expanded: boolean,
    content?: React.ReactElement<any>
}

export interface ICollapseItem extends IBaseCollapseItem {
    properties?: Array<IPropertyItem>
}

export interface IReadonlyCollapseItem extends IBaseCollapseItem {
    properties?: List<PropertyItem>
}

export type CollapseItem = ReadonlyRecord<IReadonlyCollapseItem>;

export const CollapseItemFactory = Record<IReadonlyCollapseItem>({
    key: null,
    label: null,
    orderNo: null,
    disabled: false,
    expanded: true,
    content: null,
    properties: List()
});

export const CollapseItemFactoryFromJS = function (data: ICollapseItem) {
    let items: Array<PropertyItem> = data.properties.length ? data.properties.map(function (value) {
        return PropertyItemFactoryFromJS(value);
    }) : [];

    return CollapseItemFactory({
        key: data.key,
        label: data.label,
        orderNo: data.orderNo,
        disabled: data.disabled,
        //expanded: data.expanded,
        content: data.content,
        properties: List(items)
    });
}