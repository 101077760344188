import Button from 'antd/lib/button';
import * as React from "react";
import BCMenu, { IMenuItemClickEventArgs } from '../BCMenu';
import { IItem } from '@bimser/core';
import * as styles from './assets/AntDropdownButton.scss';
import { IDropdownButtonClickEventArgs, IDropdownButtonProps } from './entities';
import IconMore from "@bimser/icons/16/more2";

const ButtonGroup = Button.Group;

const BCDropdownButton = React.memo(React.forwardRef<BCDropdownButtonRef, IDropdownButtonProps>((props, ref) => {

    React.useImperativeHandle(ref, () => ({ isMenuVisible, handleOptionsClick }))

    const [selectedItem, setSelectedItem] = React.useState<IItem>({
        key: null,
        text: null,
        icon: null,
        name: null,
        actionKey: null,
        itemType: null,
        items: null,
        value: props.defaultValue
    });
    const [isMenuVisible, setIsMenuVisible] = React.useState(false);

    React.useEffect(() => {
        let firstItem: IItem = {
            key: null,
            text: null,
            icon: null,
            name: null,
            actionKey: null,
            itemType: null,
            items: null,
            value: props.defaultValue
        }

        if (props.items && props.items.length && props.items.find(i => i.value == props.defaultValue)) {
            firstItem = props.items.find(i => i.value == props.defaultValue);
        }

        setSelectedItem(firstItem);
    }, [props.defaultValue, props.items]);

    React.useEffect(() => {
        setIsMenuVisible(false);
    }, [selectedItem]);

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        let arg: IDropdownButtonClickEventArgs = { event: event, data: selectedItem }
        if (props.onClick) {
            props.onClick(arg);
        }
    }

    const onMenuItemClick = (event: IMenuItemClickEventArgs) => {
        setSelectedItem({ ...event.data, isMenuVisible: false });

        if (props.selectedChanged) {
            props.selectedChanged(event);
        }

        if (props.eventHandler) {
            props.eventHandler(event.data.key);
        }
    }

    const handleOptionsClick = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    const options = (options: Array<IItem>) => {
        if (!options || options.length === 0)
            return null;

        return (
            <BCMenu
                items={options}
                mode="vertical"
                onClick={onMenuItemClick}
            />
        );
    }

    let items = props.items.filter(i => i.value != props.defaultValue);

    return (
        <div className={styles.Container}>
            <ButtonGroup>
                <Button className={[styles.dropdown, props.className].join(' ')} key={selectedItem.key} onClick={onButtonClick}>
                    {selectedItem.text}
                </Button>
                {items.length ? <Button className={[styles.DownArrow, props.className].join(' ')} key={selectedItem.key + "_1"} onClick={handleOptionsClick} style={{ padding: '5px !important' }}>
                    <IconMore color="white" height="12px" width="12px" />
                </Button> : <></>}
            </ButtonGroup>
            {isMenuVisible && options(items)}
        </div>
    )
}))

export type BCDropdownButtonRef = {
    handleOptionsClick: () => void;
    isMenuVisible: boolean
}

export default BCDropdownButton;
export { IDropdownButtonProps };
