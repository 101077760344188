import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ISigner, Signer, SignerFactoryFromJS } from './ISigner';

interface IBaseSignatureTemplate {
    templateId: string;
    templateName: string;
}

export interface ISignatureTemplate extends IBaseSignatureTemplate {
    signers: Array<ISigner>;
}

export interface IReadonlySignatureTemplate extends IBaseSignatureTemplate {
    signers: List<Signer>;
}

export type SignatureTemplate = ReadonlyRecord<IReadonlySignatureTemplate>;

export const SignatureTemplateFactory = Record<IReadonlySignatureTemplate>({
    templateId: null,
    templateName: null,
    signers: List()
});

export const SignatureTemplateFactoryFromJS = function (data: ISignatureTemplate) {
    let _signers = data && data.signers ? data.signers.map(s=> SignerFactoryFromJS(s)): [];
    return SignatureTemplateFactory({
        templateId: data.templateId,
        templateName: data.templateName,
        signers: List(_signers)
    });
}