import { EDeviceTypes, FormItemTypes, isNullOrUndefined } from "@bimser/core";
import classNames from 'classnames/bind';
import React from "react";
import * as Styles from "./assets/styles.scss";
import Column from "./Column";
import { ISection } from "./entities";

const cx = classNames.bind(Styles);

const Section = (args: ISection) => {
  const { data, controlRenderManager, getItem, deviceType, paddingType, parentItemType } = args;

  const sectionClassNames: string = cx({
    base: true,
    draggable: true,
    section: true
  });

  const columnsClassNames: string = cx({
    columns: true,
    extraSmall: deviceType === EDeviceTypes.ExtraSmall,
    isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup
  });

  const padding = data.designerProps.layout.get("padding");
  const noPadding = isNullOrUndefined(padding) || padding === "";
  const columnsContainsStyles: React.CSSProperties = noPadding ? {} : {
    padding: padding
  };

  const renderColumns = () => {
    return (
      <div className={columnsClassNames} style={columnsContainsStyles}>
        {data.items.map((itemId) => {

          const item = getItem(itemId as string);

          if (item.type === FormItemTypes.EContainerTypes.Column) {
            const layout = item.designerProps.layout;
            const hidden = !(layout.get("visible") && layout.get("clientVisible"));
            if (!hidden) {
              return (
                <React.Fragment key={itemId as string}>
                  <Column
                    key={item.id}
                    data={item}
                    getItem={getItem}
                    controlRenderManager={controlRenderManager}
                    deviceType={deviceType}
                    paddingType={paddingType}
                    parentItemType={parentItemType}
                  />
                </React.Fragment>
              );
            }
          }

        })}
      </div>
    )
  };

  if (!data.items.count()) return <></>;

  return (
    <div
      className={sectionClassNames}
    >
      {renderColumns()}
    </div>
  );
};
export default React.memo(Section);
