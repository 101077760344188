import { IResumableChunk } from "../entities/IResumableChunk";
import ResumableUploadManager from "./ResumableUploadManager";
import ResumableFile from "./ResumableFile";

export default class ResumableChunk {
    constructor(data: IResumableChunk) {
        this.preprocessFinished = this.preprocessFinished.bind(this);
        this.progress = this.progress.bind(this);
        this.send = this.send.bind(this);
        this.status = this.status.bind(this);
        this.test = this.test.bind(this);
        this.abort = this.abort.bind(this);
        this.message = this.message.bind(this);


        this.resumableObj = data.resumableObj;
        this.getOpt = data.resumableObj.getOpt
        this.fileObj = data.fileObj;
        this.fileObjSize = data.fileObj.size;
        this.fileObjType = data.fileObj.file.type;
        this.offset = data.offset;
        this.callback = data.callback;
        this.chunkSize = this.getOpt('chunkSize')

        let thread = this.fileObj.threads.get((this.offset + 1).toString());
        this.endByte = thread.endByte
        this.startByte = thread.startByte
        this.uploadUrl = thread.uploadUrl;
    }
    resumableObj: ResumableUploadManager;
    opts = {};
    getOpt: Function;
    fileObj: ResumableFile;
    fileObjSize: number;
    fileObjType: string;
    offset: number;
    callback: Function;
    lastProgressCallback = (new Date);
    tested = false;
    retries = 0;
    pendingRetry = false;
    preprocessState = PreProcessState.unProcessed;
    xhr: XMLHttpRequest = null;
    chunkSize: number;
    loaded = 0;
    startByte: number;
    endByte: number;
    uploadUrl: string;


    test() {
        // Set up request and listen for event
        this.xhr = new XMLHttpRequest();

        var testHandler = (e: any) => {
            this.tested = true;
            var status = this.status();
            if (status == 'success') {
                this.callback(status, this.message());
                this.resumableObj.uploadNextChunk();
            } else {
                this.send();
            }
        };
        this.xhr.addEventListener('load', testHandler, false);
        this.xhr.addEventListener('error', testHandler, false);
        this.xhr.addEventListener('timeout', testHandler, false);

        // Add data from the query options
        var params: any[] = [];
        var parameterNamespace = this.getOpt('parameterNamespace');
        var customQuery = this.getOpt('query');
        if (typeof customQuery == 'function') customQuery = customQuery(this.fileObj, $);
        for (let k of Object.keys(customQuery)) {
            let v = customQuery[k];
            params.push([encodeURIComponent(parameterNamespace + k), encodeURIComponent(v)].join('='));
        };
        // Add extra data to identify chunk
        params = params.concat(
            [
                // define key/value pairs for additional parameters
                ['chunkNumberParameterName', this.offset + 1],
                ['chunkSizeParameterName', this.getOpt('chunkSize')],
                ['currentChunkSizeParameterName', this.endByte - this.startByte],
                ['totalSizeParameterName', this.fileObjSize],
                ['typeParameterName', this.fileObjType],
                ['identifierParameterName', this.fileObj.uuid],
                ['fileNameParameterName', this.fileObj.fileName],
                ['relativePathParameterName', this.fileObj.relativePath],
                ['totalChunksParameterName', this.fileObj.chunks.length]
            ].filter((pair) => {
                // include items that resolve to truthy values
                // i.e. exclude false, null, undefined and empty strings
                return this.getOpt(pair[0]);
            })
                .map((pair) => {
                    // map each key/value pair to its final form
                    return [
                        parameterNamespace + this.getOpt(pair[0]),
                        encodeURIComponent(pair[1])
                    ].join('=');
                })
        );
        // Append the relevant chunk and send it
        this.xhr.open(this.getOpt('testMethod'), this.resumableObj.Helpers.getTarget('test', params));
        this.xhr.timeout = this.getOpt('xhrTimeout');
        this.xhr.withCredentials = this.getOpt('withCredentials');
        // Add data from header options
        var customHeaders = this.getOpt('headers');
        if (typeof customHeaders === 'function') {
            customHeaders = customHeaders(this.fileObj, $);
        }

        for (let k of Object.keys(customHeaders)) {
            let v = customHeaders[k];
            this.xhr.setRequestHeader(k, v);
        }
        this.xhr.send(null);
    }

    send = () => {
        var preprocess = this.getOpt('preprocess');
        if (typeof preprocess === 'function') {
            switch (this.preprocessState) {
                case 0: this.preprocessState = 1; preprocess(this); return;
                case 1: return;
                case 2: break;
            }
        }
        if (this.getOpt('testChunks') && !this.tested) {
            this.test();
            return;
        }

        // Set up request and listen for event
        this.xhr = new XMLHttpRequest();

        // Progress
        this.xhr.upload.addEventListener('progress', (e: any) => {
            if ((new Date).getTime() - this.lastProgressCallback.getTime() > this.getOpt('throttleProgressCallbacks') * 1000) {
                this.callback('progress');
                this.lastProgressCallback = (new Date);
            }
            this.loaded = e.loaded || 0;
        }, false);

        this.loaded = 0;
        this.pendingRetry = false;
        this.callback('progress');

        // Done (either done, failed or retry)
        var doneHandler = (e: any) => {
            var status = this.status();
            if (status == 'success' || status == 'error') {
                this.callback(status, this.message());
                this.resumableObj.uploadNextChunk();
            } else {
                this.callback('retry', this.message());
                this.abort();
                this.retries++;
                var retryInterval = this.getOpt('chunkRetryInterval');
                if (retryInterval !== undefined) {
                    this.pendingRetry = true;
                    setTimeout(this.send, retryInterval);
                } else {
                    this.send();
                }
            }
        };
        this.xhr.addEventListener('load', doneHandler, false);
        this.xhr.addEventListener('error', doneHandler, false);
        this.xhr.addEventListener('timeout', doneHandler, false);

        // Set up the basic query data from Resumable
        var query: any = [
            ['chunkNumberParameterName', this.offset + 1],
            ['chunkSizeParameterName', this.getOpt('chunkSize')],
            ['currentChunkSizeParameterName', this.endByte - this.startByte],
            ['totalSizeParameterName', this.fileObjSize],
            ['typeParameterName', this.fileObjType],
            ['identifierParameterName', this.fileObj.uuid],
            ['fileNameParameterName', this.fileObj.fileName],
            ['relativePathParameterName', this.fileObj.relativePath],
            ['totalChunksParameterName', this.fileObj.chunks.length],
        ].filter((pair) => {
            return this.getOpt(pair[0]);
        }).reduce((query: any[], pair: any) => {
            query[this.getOpt(pair[0])] = pair[1];
            return query;
        }, []);
        // Mix in custom data
        var customQuery: any = this.getOpt('query');
        if (typeof customQuery == 'function') customQuery = customQuery(this.fileObj, this);

        for (let k of Object.keys(customQuery)) {
            query[k] = customQuery[k];
        }

        var bytes = this.fileObj.file.slice(this.startByte, this.endByte +1, this.getOpt('setChunkTypeFromFile') ? this.fileObj.file.type : "");
        var data: any = null;
        var params: any[] = [];

        var parameterNamespace = this.getOpt('parameterNamespace');
        if (this.getOpt('method') === 'octet') {
            data = bytes;
            for (let k of Object.keys(query)) {
                let v = query[k];
                params.push([encodeURIComponent(parameterNamespace + k), encodeURIComponent(v)].join('='));
            }

        } else {
            // Add data from the query options
            data = new FormData();
            /*for (let k of Object.keys(query)) {
                let v = query[k];
                data.append(parameterNamespace + k, v);
                params.push([encodeURIComponent(parameterNamespace + k), encodeURIComponent(v)].join('='));
            }*/

            if (this.getOpt('chunkFormat') == 'blob') {
                data.append(parameterNamespace + this.getOpt('fileParameterName'), bytes, this.fileObj.fileName);
            }
            else if (this.getOpt('chunkFormat') == 'base64') {
                var fr = new FileReader();
                fr.onload = (e: any) => {
                    data.append(parameterNamespace + this.getOpt('fileParameterName'), fr.result);
                    this.xhr.send(data);
                }
                fr.readAsDataURL(bytes);
            }
        }

        var target = this.resumableObj.Helpers.getTarget('upload', params);
        var method = this.getOpt('uploadMethod');

        this.xhr.open(method, this.uploadUrl);
        if (this.getOpt('method') === 'octet') {
            this.xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        }
        this.xhr.timeout = this.getOpt('xhrTimeout');
        this.xhr.withCredentials = this.getOpt('withCredentials');
        // Add data from header options
        var customHeaders = this.getOpt('headers');
        if (typeof customHeaders === 'function') {
            customHeaders = customHeaders(this.fileObj, this);
        }

        for (let k of Object.keys(customHeaders)) {
            let v = customHeaders[k];
            this.xhr.setRequestHeader(k, v);
        }


        if (this.getOpt('chunkFormat') == 'blob') {
            this.xhr.send(data);
        }
    }

    preprocessFinished = () => {
        this.preprocessState = PreProcessState.finished;
        this.send();
    }

    abort = () => {
        if (this.xhr) this.xhr.abort();
        this.xhr = null;
    }

    status = () => {
        // Returns: 'pending', 'uploading', 'success', 'error'
        if (this.pendingRetry) {
            // if pending retry then that's effectively the same as actively uploading,
            // there might just be a slight delay before the retry starts
            return ('uploading');
        } else if (!this.xhr) {
            return ('pending');
        } else if (this.xhr.readyState < 4) {
            // Status is really 'OPENED', 'HEADERS_RECEIVED' or 'LOADING' - meaning that stuff is happening
            return ('uploading');
        } else {
            if (this.xhr.status == 200 || this.xhr.status == 201) {
                // HTTP 200, 201 (created)
                return ('success');
            } else if (this.getOpt('permanentErrors').indexOf(this.xhr.status) || this.retries >= this.getOpt('maxChunkRetries')) {
                // HTTP 415/500/501, permanent error
                return ('error');
            } else {
                // this should never happen, but we'll reset and queue a retry
                // a likely case for this would be 503 service unavailable
                this.abort();
                return ('pending');
            }
        }
    };

    message = () => {
        return this.xhr ? this.xhr.responseText : '';
    }

    progress = (relative: boolean) => {
        if (typeof (relative) === 'undefined') relative = false;
        var factor = (relative ? (this.endByte - this.startByte) / this.fileObjSize : 1);
        if (this.pendingRetry) return (0);
        if (!this.xhr || !this.xhr.status) factor *= .95;
        var s = this.status();
        switch (s) {
            case 'success':
            case 'error':
                return (1 * factor);
            case 'pending':
                return (0 * factor);
            default:
                return (this.loaded / (this.endByte - this.startByte) * factor);
        }
    }
}

enum PreProcessState {
    unProcessed,
    proccessing,
    finished
}