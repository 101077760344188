import { Record, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import {
    IUserMaintenanceFormContent, IUserMaintenanceListFormContent, UserMaintenanceFormContent, UserMaintenanceListFormContent,
    UserMaintenanceFormContentFactoryFromJS, UserMaintenanceListFormContentFactoryFromJS, IDepartmentMaintenanceFormContent,
    IDepartmentMaintenanceListFormContent, DepartmentMaintenanceListFormContent, DepartmentMaintenanceFormContent,
    DepartmentMaintenanceFormContentFactoryFromJS, DepartmentMaintenanceListFormContentFactoryFromJS, IPositionMaintenanceFormContent,
    IPositionMaintenanceListFormContent, PositionMaintenanceListFormContent, PositionMaintenanceFormContent,
    PositionMaintenanceFormContentFactoryFromJS, PositionMaintenanceListFormContentFactoryFromJS, IProfessionMaintenanceListFormContent, ProfessionMaintenanceFormContent, IProfessionMaintenanceFormContent, ProfessionMaintenanceListFormContent, ProfessionMaintenanceListFormContentFactoryFromJS, ProfessionMaintenanceFormContentFactoryFromJS
} from ".";

export interface IMaintenanceFormContentList {
    userMaintenanceForms: IDictionary<IUserMaintenanceFormContent>,
    userMaintenanceListForms: IDictionary<IUserMaintenanceListFormContent>,
    departmentMaintenanceForms: IDictionary<IDepartmentMaintenanceFormContent>,
    departmentMaintenanceListForms: IDictionary<IDepartmentMaintenanceListFormContent>,
    positionMaintenanceForms: IDictionary<IPositionMaintenanceFormContent>,
    positionMaintenanceListForms: IDictionary<IPositionMaintenanceListFormContent>,
    professionMaintenanceListForms: IDictionary<IProfessionMaintenanceListFormContent>,
    professionMaintenanceForms: IDictionary<IProfessionMaintenanceFormContent>,
}

export interface IReadonlyMaintenanceFormContentList {
    userMaintenanceForms: Map<string, UserMaintenanceFormContent>,
    userMaintenanceListForms: Map<string, UserMaintenanceListFormContent>,
    departmentMaintenanceForms: Map<string, DepartmentMaintenanceFormContent>,
    departmentMaintenanceListForms: Map<string, DepartmentMaintenanceListFormContent>,
    positionMaintenanceForms: Map<string,PositionMaintenanceFormContent>,
    positionMaintenanceListForms: Map<string, PositionMaintenanceListFormContent>,
    professionMaintenanceListForms: Map<string, ProfessionMaintenanceListFormContent>,
    professionMaintenanceForms: Map<string, ProfessionMaintenanceFormContent>,
}

export type MaintenanceFormContentList = ReadonlyRecord<IReadonlyMaintenanceFormContentList>;

export const MaintenanceFormContentListFactory = Record<IReadonlyMaintenanceFormContentList>({
    userMaintenanceForms: Map<string, UserMaintenanceFormContent>(),
    userMaintenanceListForms: Map<string, UserMaintenanceListFormContent>(),
    departmentMaintenanceForms: Map<string, DepartmentMaintenanceFormContent>(),
    departmentMaintenanceListForms: Map<string, DepartmentMaintenanceListFormContent>(),
    positionMaintenanceForms: Map < string, PositionMaintenanceFormContent>(),
    positionMaintenanceListForms: Map<string, PositionMaintenanceListFormContent>(),
    professionMaintenanceForms: Map<string, ProfessionMaintenanceFormContent>(),
    professionMaintenanceListForms: Map<string, ProfessionMaintenanceListFormContent>(),
});

export const MaintenanceFormContentListFactoryFromJS = function (data: IMaintenanceFormContentList) {
    let _userForms: IDictionary<UserMaintenanceFormContent> = Object.keys(data.userMaintenanceForms).reduce(function (previous: IDictionary<UserMaintenanceFormContent>, current) {
        previous[current] = UserMaintenanceFormContentFactoryFromJS(data.userMaintenanceForms[current]);
        return previous;
    }, {});

    let _userListForms: IDictionary<UserMaintenanceListFormContent> = Object.keys(data.userMaintenanceListForms).reduce(function (previous: IDictionary<UserMaintenanceListFormContent>, current) {
        previous[current] = UserMaintenanceListFormContentFactoryFromJS(data.userMaintenanceListForms[current]);
        return previous;
    }, {});

    let _departmentForms: IDictionary<DepartmentMaintenanceFormContent> = Object.keys(data.departmentMaintenanceForms).reduce(function (previous: IDictionary<DepartmentMaintenanceFormContent>, current) {
        previous[current] = DepartmentMaintenanceFormContentFactoryFromJS(data.departmentMaintenanceForms[current]);
        return previous;
    }, {});

    let _departmentListForms: IDictionary<DepartmentMaintenanceListFormContent> = Object.keys(data.departmentMaintenanceListForms).reduce(function (previous: IDictionary<DepartmentMaintenanceListFormContent>, current) {
        previous[current] = DepartmentMaintenanceListFormContentFactoryFromJS(data.departmentMaintenanceListForms[current]);
        return previous;
    }, {});

    let _positionForms: IDictionary<PositionMaintenanceFormContent> = Object.keys(data.positionMaintenanceForms).reduce(function (previous: IDictionary<PositionMaintenanceFormContent>, current) {
        previous[current] = PositionMaintenanceFormContentFactoryFromJS(data.positionMaintenanceForms[current]);
        return previous;
    }, {});

    let _positionListForms: IDictionary<PositionMaintenanceListFormContent> = Object.keys(data.positionMaintenanceListForms).reduce(function (previous: IDictionary<PositionMaintenanceListFormContent>, current) {
        previous[current] = PositionMaintenanceListFormContentFactoryFromJS(data.positionMaintenanceListForms[current]);
        return previous;
    }, {});

    let _professionForms: IDictionary<ProfessionMaintenanceFormContent> = Object.keys(data.professionMaintenanceForms).reduce(function (previous: IDictionary<ProfessionMaintenanceFormContent>, current) {
        previous[current] = ProfessionMaintenanceFormContentFactoryFromJS(data.professionMaintenanceForms[current]);
        return previous;
    }, {});

    let _professionListForms: IDictionary<ProfessionMaintenanceListFormContent> = Object.keys(data.professionMaintenanceListForms).reduce(function (previous: IDictionary<ProfessionMaintenanceListFormContent>, current) {
        previous[current] = ProfessionMaintenanceListFormContentFactoryFromJS(data.professionMaintenanceListForms[current]);
        return previous;
    }, {});


    return MaintenanceFormContentListFactory({
        userMaintenanceForms: Map(_userForms),
        userMaintenanceListForms: Map(_userListForms),
        departmentMaintenanceForms: Map(_departmentForms),
        departmentMaintenanceListForms: Map(_departmentListForms),
        positionMaintenanceForms: Map(_positionForms),
        positionMaintenanceListForms: Map(_positionListForms),
        professionMaintenanceForms: Map(_professionForms),
        professionMaintenanceListForms: Map(_professionListForms)
    });
}