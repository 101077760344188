import { all } from 'redux-saga/effects';
import watchCloseModalTransaction from './closeModalTransaction';
import messageBoxSaga from "../modals/MessageBox/sagas";
import watchConfirmationBoxTransaction from '../modals/ConfirmationBox/sagas/confirmationBoxTransaction';
import { watchUserSelectSearchChange } from '../modals/UserSelectModal/sagas';

export default function* rootSaga() {
    yield all([
        watchCloseModalTransaction(),
        messageBoxSaga(),
        watchConfirmationBoxTransaction(),
        watchUserSelectSearchChange()
    ]);
}
