import { Record } from "immutable";
import { ReadonlyRecord } from '../../common/base/immutableTypes';

export interface IBaseBadge {
    count?: number | string | React.ReactNode,
    dot?: boolean,
    offset?: [number, number],
    overflowCount?: number,
    showZero?: boolean,
    status?: 'success' | 'processing' | 'default' | 'error' | 'warning',
    text?: string,
    title?: string,
    style?: React.CSSProperties
}

export default interface IBadge extends IBaseBadge {

}

export interface IReadonlyBadge extends IBaseBadge {

}

export type Badge = ReadonlyRecord<IReadonlyBadge>;

export const BadgeFactory = Record<IReadonlyBadge>({
    count: null,
    dot: false,
    offset: [0, 0],
    overflowCount: 0,
    showZero: false,
    status: null,
    text: null,
    title: null,
    style: null
});

export const BadgeFactoryFromJS = function (data: IBadge) {

    return BadgeFactory({
        count: data.count,
        dot: data.dot,
        offset: data.offset,
        overflowCount: data.overflowCount,
        showZero: data.showZero,
        status: data.status,
        text: data.text,
        title: data.title,
        style: data.style
    });

}