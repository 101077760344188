import { BCNotification, findMessage } from '@bimser/components';
import { IBaseAction } from "@bimser/core";
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { ServiceList } from '../../../services/serviceList';
import { SignatureFormat, SignatureType } from '../../../utilities/DigitalSignatureAPI/entities';
import { changeModalDataAction, closeModalTransactionAction, openModalAction } from '../../ModalManager/actions';
import { closeMobileSignModalAction } from '../actions';
import { CLOSE_MOBILE_SIGN_MODAL_ACTION, START_MOBILE_SIGNING_ACTION } from '../actions/actionTypes';
import { IMobileSignatureProps } from '../entities';
import IOpenMobileSignPayload from '../entities/IOpenMobileSignPayload';
import IStartMobileSigningPayload from '../entities/IStartMobileSigningPayload';

export default function* MobileSignatureSaga() {
    yield all([
        takeLatest(START_MOBILE_SIGNING_ACTION, handleStartMobileSigning)
    ])
}

export function* openMobileSignModal(payload: IOpenMobileSignPayload) {
    try {
        let resolvePromise: any = null;
        let rejectPromise: any = null;

        let promise = new Promise<any>((resolve, reject) => {
            resolvePromise = resolve;
            rejectPromise = reject;
        });

        let cAdESSignatureType = 0;
        let isTimeStampEnable = false;
        let parameters = yield call(getDigitalSignatureParameters, payload.id);
        if (parameters) {
            cAdESSignatureType = parameters.cAdESSignatureType;
            isTimeStampEnable = parameters.timestampEnabled;
        }

        if (payload.signType == "flow") {
            cAdESSignatureType = payload.cAdESSignatureType;
            if (isTimeStampEnable) {
                isTimeStampEnable = payload.useTimeStamp;
            }
        }

        let MobileSignModal = openModalAction({
            type: "MobileSign",
            id: payload.id,
            visible: true,
            props: {
                ...payload,
                cAdESSignatureType,
                isTimeStampEnable: isTimeStampEnable
            } as IMobileSignatureProps
        })

        yield put(MobileSignModal);
        yield fork(waitResponse, resolvePromise, rejectPromise, payload.id);

        return yield promise;
    } catch (error) {
        console.log("LOG: function*MobileSignModal -> error", error)
        return;
    }
}

function* getDigitalSignatureParameters(id: string) {
    try {
        yield call(changeModalLoading, id, true);
        let response = yield ServiceList.main.Signature.GetDigitalSignatureParameters.call(null, null, true);
        if (response) {
            yield call(changeModalLoading, id, false);
            return response;
        }
        yield call(changeModalLoading, id, false);
        return null;
    } catch (err) {
        console.log("🚀 -> function*getDigitalSignatureParameters -> error", err)
        yield call(changeModalLoading, id, false);
        return null;
    }
}

function* waitResponse(resolvePromise: any, rejectPromise: any, id: string) {
    let action: IBaseAction<any> = yield take([CLOSE_MOBILE_SIGN_MODAL_ACTION]);
    yield put(closeModalTransactionAction(id))
    if (action && action.type == CLOSE_MOBILE_SIGN_MODAL_ACTION && resolvePromise && action.payload.id == id) {
        resolvePromise(action.payload.result ?? true);
    } else {
        resolvePromise(false);
    }
}

function* handleStartMobileSigning(action: IBaseAction<any>) {
    let { id, fileName, isTimeStamp, secretKey, versionSecretKey, operator, phoneNumber, signatureFormat, cAdESSignatureType } = action.payload as IStartMobileSigningPayload;
    yield call(changeModalLoading, action.payload.id, true);

    let request: any = {
        secretKey: secretKey,
        versionSecretKey: versionSecretKey,
        phoneNumber: phoneNumber,
        signatureType: getSignatureType(isTimeStamp, signatureFormat),
        mobileOperator: operator,
        messageToDisplay: findMessage.get('102143').replace("$0", fileName)// burası kullanının telefonunda gözüken bir alan
    }

    if (signatureFormat == SignatureFormat.CAdES) {
        request.cAdESSignatureType = cAdESSignatureType;
    } else if (signatureFormat == SignatureFormat.PAdES) {
        request.useLogo = false
        request.logoDescription = ""
        request.pageNumber = 0
        request.topLeftX = 0
        request.topLeftY = 0
        request.bottomRightX = 0
        request.bottomRightY = 0
    }

    try {
        let response = yield getInitializeSignRequest(signatureFormat).call(request);
        if (response) {
            let { hex, transactionUUID, secretKey } = response;
            yield call(changeModalLoading, action.payload.id, true, hex);
            let finalizeResponse = yield getFinalizeSignRequest(signatureFormat).call({
                transactionUUID,
                secretKey
            })
            if (finalizeResponse && finalizeResponse.signed) {
                yield call(changeModalLoading, action.payload.id, false);
                yield put(closeMobileSignModalAction(id));
                BCNotification.success({ message: findMessage.get('102144') });
            } else {
                yield call(changeModalLoading, action.payload.id, false);
            }
        } else {
            yield call(changeModalLoading, action.payload.id, false);
        }
    } catch (error) {
        console.log("🚀 -> function*handleStartMobileSigning -> error", error)
        yield call(changeModalLoading, action.payload.id, false);
    }

}

function getInitializeSignRequest(signatureFormat: SignatureFormat) {
    switch (signatureFormat) {
        case SignatureFormat.PAdES:
            return ServiceList.main.Signature.PAdES.InitializeMobileSigningWithDMObject;
        case SignatureFormat.XAdES:
            return ServiceList.main.Signature.XAdES.InitializeMobileSigningWithDMObject;
        case SignatureFormat.CAdES:
        default:
            return ServiceList.main.Signature.CAdES.InitializeMobileSigningWithDMObject;
    }
}

function getFinalizeSignRequest(signatureFormat: SignatureFormat) {
    switch (signatureFormat) {
        case SignatureFormat.PAdES:
            return ServiceList.main.Signature.PAdES.FinalizeMobileSigning;
        case SignatureFormat.XAdES:
            return ServiceList.main.Signature.XAdES.FinalizeMobileSigning;
        case SignatureFormat.CAdES:
        default:
            return ServiceList.main.Signature.CAdES.FinalizeMobileSigning;
    }
}
function getSignatureType(isTimeStamp: boolean, signatureFormat: SignatureFormat) {
    if (!isTimeStamp) {
        return SignatureType.BES;
    } else {
        if (signatureFormat == SignatureFormat.PAdES) {
            return SignatureType.LTV;
        } else {
            return SignatureType.XL
        }
    }
}

function* changeModalLoading(id: string, loading: boolean, fingerPrint: string = null) {
    yield put(changeModalDataAction({
        modalId: id,
        data: {
            fingerPrint: fingerPrint,
            loading: loading
        }
    }));
}