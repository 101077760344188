import { connect } from "react-redux";
import { BaseState } from "../../../entities/BaseState";
import BCModalFrameManager from "../components";
import { IModalFrameManagerCProps, IModalFrameManagerProps } from '../entities';
import * as React from "react";
import { getModalFrameUrl } from "../selectors";
import { closeModalFrameAction } from "../actions";

// state to component properties
const mapStateToProps = function (state: BaseState, props: IModalFrameManagerProps): IModalFrameManagerProps {
    return {
        url: getModalFrameUrl(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IModalFrameManagerCProps {
    return {
        closeModal: function () {
            dispatch(closeModalFrameAction())
        }
    };
}

// react - redux connection

export default connect(mapStateToProps, mapDispatchToProps)(BCModalFrameManager);