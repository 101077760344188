import { findMessage } from '../..';

export const GetEditorHelpSet = () => [
    {
        title: 'Inline Editor',
        commands: [
            { val: 'OSkeyE', desc: 'Show the editor' }
        ]
    },
    {
        title: 'Common actions',
        commands: [
            { val: 'OSkeyC', desc: 'Copy' },
            { val: 'OSkeyX', desc: 'Cut' },
            { val: 'OSkeyV', desc: 'Paste' },
            { val: 'OSkeyZ', desc: 'Undo' },
            { val: 'OSkeyShift+Z', desc: 'Redo' },
            { val: 'OSkeyK', desc: 'Insert Link' },
            { val: 'OSkeyP', desc: 'Insert Image' }
        ]
    },
    {
        title: 'Basic Formatting',
        commands: [
            { val: 'OSkeyA', desc: 'Select All' },
            { val: 'OSkeyB', desc: 'Bold' },
            { val: 'OSkeyI', desc: 'Italic' },
            { val: 'OSkeyU', desc: 'Underline' },
            { val: 'OSkeyS', desc: 'Strikethrough' },
            { val: 'OSkey]', desc: 'Increase Indent' },
            { val: 'OSkey[', desc: 'Decrease Indent' }
        ]
    },
    {
        title: 'Quote',
        commands: [
            { val: 'OSkey\'', desc: 'Increase quote level' },
            { val: 'OSkeyShift+\'', desc: 'Decrease quote level' }
        ]
    },
    {
        title: 'Image / Video',
        commands: [
            { val: 'OSkey+', desc: 'Resize larger' },
            { val: 'OSkey-', desc: 'Resize smaller' }
        ]
    },
    {
        title: 'Table',
        commands: [
            { val: findMessage.get('102420'), desc: 'Select table cell' },
            { val: findMessage.get('102421'), desc: 'Extend selection one cell' },
            { val: findMessage.get('102422'), desc: 'Extend selection one row' }
        ]
    },
    {
        title: 'Navigation',
        commands: [
            { val: 'OSkey/', desc: 'Shortcuts' },
            { val: 'Alt+F10', desc: 'Focus popup / toolbar' },
            { val: 'Esc', desc: 'Return focus to previous position' }
        ]
    }
]