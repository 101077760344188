import { createSelector } from 'reselect';
import { BaseState } from "../../..";
import { IMobileSignatureProps } from "../entities";

const getMobileSignLoadingSelector = (state: BaseState, props: IMobileSignatureProps) => {
    if(props.loginMode) return props.loading;
    return state.modals.data.getIn([props.id, "loading"], null);
}

const getMobileSignFingerprintSelector = (state: BaseState, props: IMobileSignatureProps) => {
    if(props.loginMode) return props.fingerPrint;
    return state.modals.data.getIn([props.id, "fingerPrint"], null);
}

const getMobileSignFingerprint = createSelector(
    [
        getMobileSignFingerprintSelector
    ],
    (data: string) => {
        return data ? data : null;
    }
)


const getMobileSignLoading = createSelector(
    [
        getMobileSignLoadingSelector
    ],
    (data: boolean) => {
        return data ? data : false;
    }
)

export {
    getMobileSignFingerprint,
    getMobileSignLoading
};
