import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from 'immutable';

interface IBaseComparisonPanelContent {
    loading?: boolean,
}

export interface IComparisonPanelContent extends IBaseComparisonPanelContent {
    sourceData?: any,
    targetData?: any,
    panelId?: string,
}

export interface IReadonlyComparisonPanelContent extends IBaseComparisonPanelContent {
    sourceData?: any,
    targetData?: any,
    panelId?: string,
}

export type ComparisonPanelContent = ReadonlyRecord<IReadonlyComparisonPanelContent>;

export const ComparisonPanelContentFactory = Record<IReadonlyComparisonPanelContent>({
    loading: false,
    sourceData: null,
    targetData: null,
    panelId: "",
});

export const ComparisonPanelContentFactoryFromJS = function (data: IComparisonPanelContent) {
    return ComparisonPanelContentFactory({
        ...data,
        sourceData: data.sourceData,
        targetData: data.targetData,
        panelId: data.panelId,
    });
}
