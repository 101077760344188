import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { BaseResponse } from "../../../entities/BaseResponse";

export interface IControlTokenResponse extends BaseResponse {
    isValid: boolean,
    token: string
}


export interface IReadonlyControlTokenResponse extends BaseResponse {
    isValid: boolean,
    token: string
}

export type ControlTokenResponse = ReadonlyRecord<IReadonlyControlTokenResponse>;

export const ControlTokenResponseFactory = Record<IReadonlyControlTokenResponse>({
    isValid: false,
    token: null
});

export const ControlTokenResponseFactoryFromJS = function (data: IControlTokenResponse) {
    return ControlTokenResponseFactory({
        isValid: data.isValid,
        token: data.token
    });
}