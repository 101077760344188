import Layout from 'antd/lib/layout';
import * as React from "react";
import { ISiderProps } from '../entities';
import * as Styles from '../assets/layoutStyles.scss';

export default class BCSider extends React.Component<ISiderProps, {}> {

    constructor(props: ISiderProps) {
        super(props);
    }

    render() {
        return (
            <Layout.Sider {...this.props} className={[this.props.className, Styles.sider].join(' ')} />
        );
    }
}