import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface ILoginResponseData {
    token: string;
}


export interface IReadonlyLoginResponseData {
    token: string;
}

export type LoginResponseData = ReadonlyRecord<IReadonlyLoginResponseData>;

export const LoginResponseDataFactory = Record<IReadonlyLoginResponseData>({
    token: null
});

export const LoginResponseDataFactoryFromJS = function (data: ILoginResponseData) {
    return LoginResponseDataFactory({
        token: data.token
    });
};