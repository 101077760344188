import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";

interface IBaseProfileFormFile {
    id: string;
    isFormsComplete: boolean;
    hasValidation: boolean;
    fileData: File;
}

export interface IProfileFormFile extends IBaseProfileFormFile {
    fileName: IDictionary<string>;
    fileDescription: IDictionary<string>;
}

export interface IReadonlyProfileFormFile extends IBaseProfileFormFile {
    fileName: Map<string, string>;
    fileDescription: Map<string, string>;
}

export type ProfileFormFile = ReadonlyRecord<IReadonlyProfileFormFile>;

export const ProfileFormFileFactory = Record<IReadonlyProfileFormFile>({
    id: null,
    fileName: null,
    fileDescription: null,
    isFormsComplete: false,
    hasValidation: false,
    fileData: null
});

export const ProfileFormFileFactoryFromJS = function (data: IProfileFormFile) {
    return ProfileFormFileFactory({
        ...data,
        fileName: Map(data.fileName),
        fileDescription: Map(data.fileDescription)
    });
}