import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IDashboardFormData, DashboardFormData, DashboardFormDataFactoryFromJS } from './DashboardFormData';

export interface IBaseStartupState {
    panelId?: string,
    approveBoxShowLoading?: boolean,
    recentFilesLoading?: boolean,
    announcementBoxShowLoading?: boolean,
    delegationBoxShowLoading?: boolean,
    childModuleGuid?: string,
    childModuleIsActive?: boolean,
    approvesCount?: number
}

export interface IStartupState extends IBaseStartupState {
    dashboardFormData?: DashboardFormData,
}
export interface IReadonlyStartupState extends IBaseStartupState {
    dashboardFormData?: DashboardFormData,
}

export type StartupState = ReadonlyRecord<IReadonlyStartupState>;

export const StartupStateFactory = Record<IReadonlyStartupState>({
    panelId: '',
    approveBoxShowLoading: true,
    recentFilesLoading: true,
    announcementBoxShowLoading: true,
    delegationBoxShowLoading: true,
    childModuleGuid: "",
    childModuleIsActive: false,
    approvesCount: 0,
    dashboardFormData: null
});

export const StartupStateFactoryFromJS = function (data: IStartupState) {
    return StartupStateFactory({
        ...data,
        dashboardFormData: data.dashboardFormData ? DashboardFormDataFactoryFromJS(data.dashboardFormData) : null
    });
}