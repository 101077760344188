/** Response is Null */
export const ResponseIsNullError = "999"
/** The request was made and the server responded with a status code that falls out of the range of 2xx */
export const OutOf2xxError = "998"
/** The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js */
export const NoResponseError = "997"
/** Something happened in setting up the request that triggered an Error */
export const RequestSettingUpError = "996";
/** Something happened in get refresh token */
export const RefReshTokenError = "995"
/** Refresh token can be taken only once in 5 minutes. */
export const RefReshTokenSecurityError = "994"