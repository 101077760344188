import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import * as React from "react";
import { isNullOrUndefined } from "@bimser/core";
import { BCEmpty } from "../..";
import BCButton from "../../BCButton";
import BCCollapse, { ICollapseItem } from "../../BCCollapse";
import { ConditionalStatementEntities } from "../../BCConditionalStatement";
import { findMessage } from "../../BCIntl";
import * as Styles from '../assets/styles.scss';
import { IRuleActionsProps } from "../entities";
import ShowMessageAction from "./ShowMessageAction";
import ValueAssignActionWithEntry from './ValueAssignActionWithEntry';
import ValueAssignActionWithFormula from './ValueAssignActionWithFormula';
import ValueAssignActionWithSelection from "./ValueAssignActionWithSelection";
import IconAdd from "@bimser/icons/16/add";
import IconDelete from "@bimser/icons/16/delete";
import IconTabNavRight from "@bimser/icons/16/tab-nav-right";
import IconCopy from "@bimser/icons/16/copy";
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const addItems = [
    { key: "ShowMessageAction", label: findMessage.get('101301') },
    { key: "ValueAssignActionWithEntry", label: findMessage.get('101302') },
    { key: "ValueAssignActionWithFormula", label: findMessage.get('101944') },
    { key: "ValueAssignActionWithSelection", label: findMessage.get('101303') },
];

const dropdownTrigger: ("click" | "hover" | "contextMenu")[] = ['click'];

const RuleActions = React.memo((props: IRuleActionsProps) => {

    const renderActionItem = (action: ConditionalStatementEntities.IBaseAction) => {
        switch (action.typeName) {
            case 'ShowMessageAction':
            case 'ValidationFailureAction':
            case 'ConfirmationAction': {
                return renderMessageAction(action as ConditionalStatementEntities.IShowMessageAction)
            }
            case 'ValueAssignActionWithEntry': {
                return renderValueAssignActionWithEntry(action as ConditionalStatementEntities.IValueAssignActionWithEntry)
            }
            case 'ValueAssignActionWithFormula': {
                return renderValueAssignActionWithFormula(action as ConditionalStatementEntities.IValueAssignActionWithEntry)
            }
            case 'ValueAssignActionWithSelection': {
                return renderValueAssignActionWithSelection(action as ConditionalStatementEntities.IValueAssignActionWithSelection)
            }
            default: console.warn('renderActionItem content type not found');
        }
    }

    const onActionChanged = (action: ConditionalStatementEntities.IBaseAction) => {
        const editedActionName = action.name;
        const duplicateNameFound = props.rule.actions.find(a => action.id !== a.id && a.name === editedActionName);

        if (isNullOrUndefined(editedActionName) || editedActionName === "") {
            notification.warning({
                message: findMessage.get('101291'),
                description: findMessage.get('101516'),
                duration: 5
            });
        } else if (duplicateNameFound) {
            notification.warning({
                message: findMessage.get('101293'),
                description: findMessage.get('101513'),
                duration: 5
            });
        } else {
            props.onActionChanged(props.rule.actions.map(i => i.id === action.id ? action : i), 'update')
        }
    }

    const renderMessageAction = (action: ConditionalStatementEntities.IShowMessageAction) => {
        return (
            <ShowMessageAction
                action={action}
                fields={props.fields}
                onActionChanged={onActionChanged}
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                triggerEvents={props.rule.triggerEvents}
                actions={props.rule.actions}
                formItems={props.formItems}
            />
        )
    }

    const renderValueAssignActionWithSelection = (action: ConditionalStatementEntities.IValueAssignActionWithSelection) => {
        return (
            <ValueAssignActionWithSelection
                action={action}
                fields={props.fields}
                onActionChanged={onActionChanged}
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                triggerEvents={props.rule.triggerEvents}
            />
        )
    }

    const renderValueAssignActionWithEntry = (action: ConditionalStatementEntities.IValueAssignActionWithEntry) => {
        return (
            <ValueAssignActionWithEntry
                action={action}
                fields={props.fields}
                onActionChanged={onActionChanged}
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                triggerEvents={props.rule.triggerEvents}
            />
        )
    }

    const renderValueAssignActionWithFormula = (action: ConditionalStatementEntities.IValueAssignActionWithFormula) => {
        return (
            <ValueAssignActionWithFormula
                action={action}
                fields={props.fields}
                onActionChanged={action => {

                    const editedActionName = action.name;
                    const duplicateNameFound = props.rule.actions.find(a => action.id !== a.id && a.name === editedActionName);

                    if (isNullOrUndefined(editedActionName) || editedActionName === "") {
                        notification.warning({
                            message: findMessage.get('101291'),
                            description: findMessage.get('101516'),
                            duration: 5
                        });
                    } else if (duplicateNameFound) {
                        notification.warning({
                            message: findMessage.get('101293'),
                            description: findMessage.get('101513'),
                            duration: 5
                        });
                    } else {
                        props.onActionChanged(props.rule.actions.map(i => i.id === action.id ? action : i), 'update')
                    }

                }}
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                triggerEvents={props.rule.triggerEvents}
                openFormulaManager={props.openFormulaManager}
            />
        )
    }

    const getActionItems = (): ICollapseItem[] => {
        return props.rule.actions.map(i => {
            const item: ICollapseItem = {
                content: renderActionItem(i),
                expanded: true,
                key: i.id,
                label: i.name,
                collapsible: i.enabled ? 'header' : 'disabled'
            }
            return item
        })
    }

    const onActionDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => {
        const filteredActions = props.rule.actions.filter(i => i.id !== key);
        props.onActionChanged(filteredActions, "delete");
        e.stopPropagation();
    }

    const onActionCopy = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => {
        props.onActionCopy(key);
        e.stopPropagation();
    }

    const getActionTypeNameCaption = (typeName: ConditionalStatementEntities.ActionType) => {
        switch (typeName) {
            case 'ConfirmationAction': return findMessage.get('100124');
            case 'ShowMessageAction': return findMessage.get('101301');
            case 'ValidationFailureAction': return findMessage.get('101305');
            case 'ValueAssignActionWithEntry': return findMessage.get('101302');
            case 'ValueAssignActionWithFormula': return findMessage.get('101944');
            case 'ValueAssignActionWithSelection': return findMessage.get('101303');
            case 'MethodInvokeAction': return 'MethodInvokeAction';
            default: return '-';
        }
    }

    const renderActionCustomHeader = (item: ICollapseItem) => {

        const actionItem = props.rule.actions.find(i => i.id === item.key);
        const classNames = cx({
            actionListItem: true
        });

        return (
            <div className={classNames}>
                <IconTabNavRight className={Styles.collapseIcon} />
                <div className={Styles.actionHeader}>
                    <p title={item.label}>{item.label}</p>
                    <span>{getActionTypeNameCaption(actionItem ? actionItem.typeName : null)}</span>
                    <IconDelete className={[Styles.collapseDeleteIcon, 'collapseDeleteIcon'].join(' ')} onClick={e => onActionDelete(e, item.key)} />
                    <IconCopy className={[Styles.collapseDeleteIcon, 'collapseDeleteIcon'].join(' ')} onClick={e => onActionCopy(e, item.key)} />
                </div>
            </div>
        )
    }

    const renderAddActionButton = () => {
        return (
            <Dropdown trigger={dropdownTrigger} overlay={<Menu multiple={false} onClick={props.createNewAction} items={addItems} />}
            >
                <BCButton icon={<IconAdd />}
                    text={findMessage.get('101295')}
                    type={'ghost'}
                    size={'small'}
                />
            </Dropdown>
        )
    }

    const renderEmptyRuleTemplate = () => {
        return (
            <BCEmpty description={findMessage.get('101297')}>
                {renderAddActionButton()}
                <p className={Styles.emptyTemplateDesc}>{findMessage.get('101296')}</p>
            </BCEmpty>
        )
    }

    const renderActionsList = () => {
        if (!props.rule?.actions?.length) return renderEmptyRuleTemplate();
        return (
            <BCCollapse
                items={getActionItems()}
                classNames={[Styles.actionsCollapsePanel]}
                customHeader={renderActionCustomHeader}
            />
        )
    }

    return (
        <div className={Styles.editContainer}>
            {renderActionsList()}
        </div>
    )
})

export default RuleActions