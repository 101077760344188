import { BCButton, BCCombobox, BCInputMask, BCLoading, BCModal, BCRadioButtonGroup, BCSwitch, findMessage, IComboboxSelectedChangeEventArgs, IInputChangeEventArgs } from '@bimser/components';
import { IItem, isNullOrUndefined } from "@bimser/core";
import * as React from 'react';
import { SignatureFormat, SignMethodType } from '../../../utilities/DigitalSignatureAPI/entities';
import * as Style from '../assets/style.scss';
import { IMobileSignatureProps } from '../entities';
import OperatorEnum from '../entities/OperatorEnum';

const MobileSignature = (props: IMobileSignatureProps) => {

    const [selectedOperator, setSelectedOperator] = React.useState(OperatorEnum.Turkcell);

    const [phoneNumber, setPhoneNumber] = React.useState("");

    const [selectedSignatureFormat, setSelectedSignatureFormat] = React.useState(props.signatureFormat || SignatureFormat.CAdES);

    const [selectedcAdESSignatureType, setcAdESSignatureType] = React.useState(props.cAdESSignatureType == SignMethodType.Both ? SignMethodType.Counter : props.cAdESSignatureType);

    const [isTimeStamp, setTimeStamp] = React.useState(false);

    const operatorOptions: IItem[] = [
        {
            key: "turkcell",
            text: "Turkcell",
            value: OperatorEnum.Turkcell
        },
        {
            key: "vodafone",
            text: "Vodafone",
            value: OperatorEnum.Vodafone
        },
        {
            key: "turktelekom",
            text: "TurkTelekom",
            value: OperatorEnum.TurkTelekom
        }
    ]

    const cAdESSignatureTypes: IItem[] = [
        {
            key: "counter",
            text: findMessage.get("102132"),
            value: SignMethodType.Counter
        },
        {
            key: "parallel",
            text: findMessage.get("102133"),
            value: SignMethodType.Parallel
        }
    ]

    const getSignatureOptions = (): IItem[] => {
        let options = [
            {
                key: "cades",
                text: "CAdES",
                value: SignatureFormat.CAdES
            },
            {
                key: "xades",
                text: "XAdES",
                value: SignatureFormat.XAdES
            }
        ];
        if (props.isPDF) {
            options.push({
                key: "pades",
                text: "PAdES",
                value: SignatureFormat.PAdES
            });
        }
        return options;
    }

    const onSelectedSignatureTypeChange = (args: IComboboxSelectedChangeEventArgs) => {
        setSelectedSignatureFormat(+args.data);
    }

    const onSelectedOperatorChange = (args: IComboboxSelectedChangeEventArgs) => {
        setSelectedOperator(+args.data);
    }

    const onSelectedcAdESSignatureTypeChange = (args: IComboboxSelectedChangeEventArgs) => {
        setcAdESSignatureType(+args.data);
    }

    const onPhoneNumberChange = (args: IInputChangeEventArgs) => {
        setPhoneNumber(args.data);
    }

    const onTimeStampChange = (checked: boolean) => {
        setTimeStamp(checked);
    }

    const onClickConfirm = () => {
        if (isValidPhoneNumber()) {
            let _phoneNumber = phoneNumber.replace("(", "").replace(")", "").replace(" ", "");
            props.startMobileSigning({
                id: props.id,
                secretKey: props.secretKey,
                versionSecretKey: props.versionSecretKey,
                fileName: props.fileName,
                isTimeStamp: isTimeStamp,
                signatureFormat: selectedSignatureFormat,
                operator: selectedOperator,
                phoneNumber: _phoneNumber,
                cAdESSignatureType: selectedcAdESSignatureType
            })
        }
    }

    const simplifyPhoneNumber = (_phoneNumber: string) => {
        return _phoneNumber.replace("(", "").replace(")", "").replace(/\s/g, "").replace("+90", "");
    }

    const onLoginClick = () => {
        if (isValidPhoneNumber()) {
            props.onLogin({
                operator: selectedOperator,
                phoneNumber: simplifyPhoneNumber(phoneNumber)
            })
        }
    }

    const isValidPhoneNumber = () => {
        if (!phoneNumber) return false;
        return simplifyPhoneNumber(phoneNumber).length == 10;
    }

    const renderModalContent = () => {
        return (
            <div className={Style.MobileSignContainer}>
                {props.fingerPrint != null &&
                    <div className={Style.optionItem + " " + Style.fingerPrint}>
                        <span className={Style.optionLabel}>{findMessage.get('102139') + props.fingerPrint}</span>
                    </div>
                }
                <div className={Style.optionItem}>
                    <span className={Style.optionLabel}>{findMessage.get('102140')}</span>
                    <div className={Style.optionContent}>
                        <BCCombobox
                            showSearch={false}
                            options={operatorOptions}
                            value={selectedOperator}
                            disabled={props.fingerPrint != null}
                            onSelectedChange={onSelectedOperatorChange}
                        />
                    </div>
                </div>
                <div className={Style.optionItem}>
                    <span className={Style.optionLabel}>{findMessage.get('102141')}</span>
                    <div className={Style.optionContent}>
                        <BCInputMask
                            mask={"+\\90(599) 999 99 99"}
                            formatChars={{}}
                            value={phoneNumber}
                            size={'middle'}
                            onChange={onPhoneNumberChange}
                        />
                    </div>
                </div>
                {!props.loginMode &&
                    <div className={Style.optionItem}>
                        <span className={Style.optionLabel}>{findMessage.get("102134")}</span>
                        <div className={Style.optionContent}>
                            <BCCombobox
                                showSearch={false}
                                options={getSignatureOptions()}
                                value={selectedSignatureFormat}
                                disabled={props.fingerPrint != null || !isNullOrUndefined(props.signatureFormat)}
                                onSelectedChange={onSelectedSignatureTypeChange}
                            />
                        </div>
                    </div>
                }
                {!props.loginMode &&
                    <div className={Style.optionItem}>
                        <span className={Style.optionLabel}>{findMessage.get("102136")}</span>
                        <div className={Style.optionContent}>
                            <BCSwitch disabled={!props.isTimeStampEnable} checked={isTimeStamp} onChange={onTimeStampChange} />
                        </div>
                    </div>
                }
                {!props.loginMode && selectedSignatureFormat == SignatureFormat.CAdES && props.cAdESSignatureType == SignMethodType.Both &&
                    <div className={Style.optionItem}>
                        <span className={Style.optionLabel}>{findMessage.get("102135")}</span>
                        <div className={Style.optionContent}>
                            <BCRadioButtonGroup
                                options={cAdESSignatureTypes}
                                value={selectedcAdESSignatureType}
                                disabled={props.fingerPrint != null}
                                onSelectedChange={onSelectedcAdESSignatureTypeChange}
                                mode={"horizontal"}
                            />
                        </div>
                    </div>
                }
                {
                    props.loginMode &&
                    <BCButton cssClass={Style.mobileLoginBtn} disabled={!isValidPhoneNumber() || props.fingerPrint != null} type='primary' text={findMessage.get("100747")} onClick={onLoginClick} />
                }
            </div>
        )
    }

    const handleCancel = () => {
        props.onCloseModal(props.id);
    }

    if (props.loginMode) {
        return (
            <BCLoading show={props.loading}>
                {renderModalContent()}
            </BCLoading>
        )
    } else {
        return (
            <BCModal
                id={props.id}
                visible={true}
                loading={props.loading}
                title={findMessage.get("102137")}
                width={"400px"}
                handleOk={onClickConfirm}
                handleCancel={handleCancel}
                okText={findMessage.get('102142')}
                keyboard={false}
                maskClosable={false}
                okButtonProps={{ disabled: !isValidPhoneNumber() || props.fingerPrint != null || props.loading }}
                cancelButtonProps={{ disabled: props.loading }}
                modalContentWrapperCssClass={Style.modalContentWrapperMobilSignature}
            >
                {renderModalContent()}
            </BCModal>
        )
    }

}
export default MobileSignature;