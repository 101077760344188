import { Collection, List, Map, OrderedMap, OrderedSet, Record, Seq, Set, Stack } from "immutable";

export type PrimitiveTypes = string | number | boolean | null | undefined;
export type ImmutableIndexedTypes<T> = Record<T> | List<T> | Set<T> | OrderedSet<T> | Stack<T>;
export type ImmutableKeyedTypes<T, K> = Map<T, K> | Collection<T, K> | OrderedMap<T, K> | Seq<T, K>
export type ImmutableTypes = PrimitiveTypes | ImmutableIndexedTypes<any> | ImmutableKeyedTypes<any, any>;

export interface IBaseAction<T extends ImmutableTypes> {
    type: string,
    payload: T
}
export interface IBaseMutableAction<T> {
    type: string,
    payload: T
}

export function createSyncAction<T extends ImmutableTypes>(type: string, payload: T): IBaseAction<T> {
    return createMutableAction(type, payload)
}

export function createMutableAction<T>(type: string, payload: T): IBaseMutableAction<T> {
    return {
        type,
        payload
    };
}