
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseRecycleBinParameters {
    active: boolean;
    canAccessSettings: boolean;
    canDelete: boolean;
    canRestore: boolean;
}

export interface IRecycleBinParameters extends IBaseRecycleBinParameters {
    
}

export interface IReadonlyRecycleBinParameters extends IBaseRecycleBinParameters {
    
}

export type RecycleBinParameters = ReadonlyRecord<IReadonlyRecycleBinParameters>;

export const RecycleBinParametersFactory = Record<IReadonlyRecycleBinParameters>({
    active: false,
    canAccessSettings: false,
    canDelete: false,
    canRestore: false
});

export const RecycleBinParametersFactoryFromJS = function (data: IRecycleBinParameters) {
    return RecycleBinParametersFactory(data);
}