import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { FormContentList, FormContentListFactory } from ".";
import { MaintenanceFormContentList, MaintenanceFormContentListFactory } from "./maintenances";
import { PropertyDefinitionsFormContentList, PropertyDefinitionsFormContentListFactory } from "./propertyDefinitions";
import { PanelMenuPanelContent, PanelMenuPanelContentFactory } from "../../../../common/components/panelMenu/entities/IPanelMenuPanelContent";


export interface IPanels {
    forms?: FormContentList,
    maintenances?: MaintenanceFormContentList,
    propertyDefinitions?: PropertyDefinitionsFormContentList,
    menu?: PanelMenuPanelContent
}

export type Panels = ReadonlyRecord<IPanels>;

export const PanelsFactory = Record<IPanels>({
    forms: FormContentListFactory(),
    maintenances: MaintenanceFormContentListFactory(),
    propertyDefinitions: PropertyDefinitionsFormContentListFactory(),
    menu: PanelMenuPanelContentFactory()
});

export const PanelsFactoryFromJS = function (data: IPanels) {
    return PanelsFactory(data);
}