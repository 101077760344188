import { Record, List, Map } from 'immutable';
import { IDictionary, ReadonlyRecord } from '@bimser/core';

interface IBaseClipboardPanelContent{
}

export interface IClipboardPanelContent extends IBaseClipboardPanelContent {
    items?: IDictionary< Array<string>>
}

export interface IReadonlyClipboardPanelContent extends IBaseClipboardPanelContent {
    items?: Map<string, Array<string>>
}

export type ClipboardPanelContent = ReadonlyRecord<IReadonlyClipboardPanelContent>;

export const  ClipboardPanelContentFactory = Record<IReadonlyClipboardPanelContent>({
    items: null
});

export const ClipboardPanelContentFactoryFromJS = function (data: IClipboardPanelContent) {
    return ClipboardPanelContentFactory({
        ...data,
        items: Map(data.items)
    });
}