import { Map, Record, List } from "immutable";
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { AlertType, DialogType } from "../Enums";
import { BaseActionFactoryObject, BaseActionFactoryObjectFromJS, IBaseAction, BaseAction, ActionCastFactory } from "./IBaseAction";

interface IBaseShowMessageAction extends IBaseAction {
    alertType: AlertType,
    dialogType: DialogType
}

export interface IShowMessageAction extends IBaseShowMessageAction {
    messages: IDictionary<string>,
    highlightList: Array<string>
    approvalActions: Array<IBaseAction>,
    rejectionActions: Array<IBaseAction>
}

export interface ReadOnlyShowMessageAction extends IBaseShowMessageAction {
    messages: Map<string, string>,
    approvalActions: List<BaseAction>,
    rejectionActions: List<BaseAction>,
    highlightList: List<string>
}

export type ShowMessageAction = ReadonlyRecord<ReadOnlyShowMessageAction>;

export const ShowMessageActionFactory = Record<ReadOnlyShowMessageAction>({
    alertType: null,
    dialogType: null,
    messages: null,
    highlightList: List(),
    approvalActions: List(),
    rejectionActions: List(),
    ...BaseActionFactoryObject
});

export const ShowMessageActionFactoryFromJS = function (data: IShowMessageAction) {
    let approvalActions: Array<BaseAction> = data.approvalActions && data.approvalActions.length ? ActionCastFactory(data.approvalActions) : [];
    let rejectionActions: Array<BaseAction> = data.rejectionActions && data.rejectionActions.length ? ActionCastFactory(data.rejectionActions) : [];

    return ShowMessageActionFactory({
        ...BaseActionFactoryObjectFromJS(data),
        alertType: data.alertType,
        dialogType: data.dialogType,
        messages: Map(data.messages),
        highlightList: List(data.highlightList),
        approvalActions: List(approvalActions),
        rejectionActions: List(rejectionActions)
    });
}