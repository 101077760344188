import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { IFilter } from '../entities/IFilter';

const getCurrentFilterSelectorState = (state: BaseState) => state.DMExplorer.itemsData?.get(state.DMExplorer.currentLocation.id)?.filter;

const getCurrentFilterSelector = createSelector(
    [
        getCurrentFilterSelectorState,
    ],
    (filter): IFilter => {
        return filter ? filter.toJS() : { name: '', extension: '' };
    }
);


export default getCurrentFilterSelector;
