import { Record, List, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import {
    User, Company, IUser, ICompany, UserFactoryFromJS, CompanyFactoryFromJS, IPosition, Position, PositionFactoryFromJS,
    IDepartment, Department, DepartmentFactoryFromJS, IProfession, Profession, ProfessionFactoryFromJS, IReplacement,
    Replacement, ReplacementFactoryFromJS, IManager, Manager, ManagerFactoryFromJS, IManagerKey, ManagerKey, ManagerKeyFactoryFromJS,
    IGroup, Group, GroupFactoryFromJS, IGroupContent, GroupContent, GroupContentFactoryFromJS, ICompanyAdmin, CompanyAdmin, CompanyAdminFactoryFromJS,
    ICompanyObject, CompanyObject, CompanyObjectFactoryFromJS, IShift, Shift, ShiftFactoryFromJS, IOvertime, Overtime, OvertimeFactoryFromJS,
    ISalary, Salary, SalaryFactoryFromJS,
} from ".";

export interface IContent {
    users?: Array<IUser>,
    companies?: Array<ICompany>,
    companyAdmins?: Array<ICompanyAdmin>,
    detailCompanyAdmins?: IDictionary<ICompanyAdmin>,
    companyObjects?: Array<ICompanyObject>,
    positions?: Array<IPosition>,
    departments?: Array<IDepartment>,
    detailDepartments?: IDictionary<IDepartment>,
    professions?: Array<IProfession>,
    detailProfessions?: IDictionary<IProfession>,
    maintenances?: Array<IReplacement>,
    detailMaintenances?: IDictionary<IReplacement>,
    managers?: Array<IManager>,
    managerKeys?: Array<IManagerKey>,
    userGroups?: Array<IGroup>,
    detailUserGroups?: IDictionary<IGroup>,
    userGroupsContent?: Array<IGroupContent>,
    detailUsers?: IDictionary<IUser>,
    detailPositions?: IDictionary<IPosition>,
    shifts?: Array<IShift>,
    overtimes?: Array<IOvertime>,
    salaries?: IDictionary<ISalary>,
}

export interface IReadonlyContent {
    users?: List<User>,
    companies?: List<Company>,
    companyAdmins?: List<CompanyAdmin>,
    detailCompanyAdmins?: Map<string, CompanyAdmin>,
    companyObjects?: List<CompanyObject>,
    positions?: List<Position>,
    departments?: List<Department>,
    detailDepartments?: Map<string, Department>,
    professions?: List<Profession>,
    detailProfessions?: Map<string, Profession>,
    maintenances?: List<Replacement>,
    detailMaintenances?: Map<string, Replacement>,
    managers?: List<Manager>,
    managerKeys?: List<ManagerKey>,
    userGroups?: List<Group>,
    detailUserGroups?: Map<string, Group>,
    userGroupsContent?: List<GroupContent>,
    detailUsers?: Map<string, User>,
    detailPositions?: Map<string, Position>,
    shifts?: List<Shift>,
    overtimes?: List<Overtime>,
    salaries?: Map<string, Salary>,
}

export type Content = ReadonlyRecord<IReadonlyContent>;

export const ContentFactory = Record<IReadonlyContent>({
    users: List<User>(),
    companies: List<Company>(),
    positions: List<Position>(),
    departments: List<Department>(),
    detailDepartments: Map<string, Department>(),
    professions: List<Profession>(),
    detailProfessions: Map<string, Profession>(),
    maintenances: List<Replacement>(),
    detailMaintenances: Map<string, Replacement>(),
    managers: List<Manager>(),
    managerKeys: List<ManagerKey>(),
    userGroups: List<Group>(),
    detailUserGroups: Map<string, Group>(),
    userGroupsContent: List<GroupContent>(),
    companyAdmins: List<CompanyAdmin>(),
    detailCompanyAdmins: Map<string, CompanyAdmin>(),
    companyObjects: List<CompanyObject>(),
    detailUsers: Map<string, User>(),
    detailPositions: Map<string, Position>(),
    shifts: List<Shift>(),
    overtimes: List<Overtime>(),
    salaries: Map<string, Salary>(),
});

export const ContentFactoryFromJS = function (data: IContent) {
    let _users: Array<User> = data.users && data.users.length ? data.users.map((user) => {
        return UserFactoryFromJS(user);
    }) : [];
    let _companies: Array<Company> = data.companies && data.companies.length ? data.companies.map((company) => {
        return CompanyFactoryFromJS(company);
    }) : [];

    let _companyAdmins: Array<CompanyAdmin> = data.companyAdmins && data.companyAdmins.length ? data.companyAdmins.map((companyAdmin) => {
        return CompanyAdminFactoryFromJS(companyAdmin);
    }) : [];

    let _detailCompanyAdmins: IDictionary<CompanyAdmin> = Object.keys(data.detailCompanyAdmins).reduce(function (previous: IDictionary<CompanyAdmin>, current) {
        previous[current] = CompanyAdminFactoryFromJS(data.detailCompanyAdmins[current]);
        return previous;
    }, {});

    let _companyObjects: Array<CompanyObject> = data.companyObjects && data.companyObjects.length ? data.companyObjects.map((companyObject) => {
        return CompanyObjectFactoryFromJS(companyObject);
    }) : [];

    let _positions: Array<Position> = data.positions && data.positions.length ? data.positions.map((position) => {
        return PositionFactoryFromJS(position);
    }) : [];

    let _departments: Array<Department> = data.departments && data.departments.length ? data.departments.map((department) => {
        return DepartmentFactoryFromJS(department);
    }) : [];

    let _detailDepartments: IDictionary<Department> = Object.keys(data.detailDepartments).reduce(function (previous: IDictionary<Department>, current) {
        previous[current] = DepartmentFactoryFromJS(data.detailDepartments[current]);
        return previous;
    }, {});

    let _professions: Array<Profession> = data.professions && data.professions.length ? data.professions.map((profession) => {
        return ProfessionFactoryFromJS(profession);
    }) : [];

    let _detailProfessions: IDictionary<Profession> = Object.keys(data.detailProfessions).reduce(function (previous: IDictionary<Profession>, current) {
        previous[current] = ProfessionFactoryFromJS(data.detailProfessions[current]);
        return previous;
    }, {});

    let _maintenances: Array<Replacement> = data.maintenances && data.maintenances.length ? data.maintenances.map((replacement) => {
        return ReplacementFactoryFromJS(replacement);
    }) : [];

    let _detailMaintenances: IDictionary<Replacement> = Object.keys(data.detailMaintenances).reduce(function (previous: IDictionary<Replacement>, current) {
        previous[current] = ReplacementFactoryFromJS(data.detailMaintenances[current]);
        return previous;
    }, {});

    let _managers: Array<Manager> = data.managers && data.managers.length ? data.managers.map((manager) => {
        return ManagerFactoryFromJS(manager);
    }) : [];

    let _managerkeys: Array<ManagerKey> = data.managerKeys && data.managerKeys.length ? data.managerKeys.map((managerKey) => {
        return ManagerKeyFactoryFromJS(managerKey);
    }) : [];

    let _usergroups: Array<Group> = data.userGroups && data.userGroups.length ? data.userGroups.map((group) => {
        return GroupFactoryFromJS(group);
    }) : [];

    let _detailUserGroups: IDictionary<Group> = Object.keys(data.detailUserGroups).reduce(function (previous: IDictionary<Group>, current) {
        previous[current] = GroupFactoryFromJS(data.detailUserGroups[current]);
        return previous;
    }, {});

    let _usergroupscontent: Array<GroupContent> = data.userGroupsContent && data.userGroupsContent.length ? data.userGroupsContent.map((GroupContent) => {
        return GroupContentFactoryFromJS(GroupContent);
    }) : [];

    let _detailUsers: IDictionary<User> = Object.keys(data.detailUsers).reduce(function (previous: IDictionary<User>, current) {
        previous[current] = UserFactoryFromJS(data.detailUsers[current]);
        return previous;
    }, {});

    let _detailPositions: IDictionary<Position> = Object.keys(data.detailPositions).reduce(function (previous: IDictionary<Position>, current) {
        previous[current] = PositionFactoryFromJS(data.detailPositions[current]);
        return previous;
    }, {});

    let _shifts: Array<Shift> = data.shifts?.length ? data.shifts.map((Shift) => {
        return ShiftFactoryFromJS(Shift);
    }) : [];

    let _overtimes: Array<Overtime> = data.overtimes?.length ? data.overtimes.map((Overtime) => {
        return OvertimeFactoryFromJS(Overtime);
    }) : [];

    let _salaries: IDictionary<Salary> = Object.keys(data.salaries).reduce(function (previous: IDictionary<Salary>, current){
        previous[current] = SalaryFactoryFromJS(data.salaries[current])
        return previous
    }, {});

    return ContentFactory({
        users: List(_users),
        companies: List(_companies),
        companyAdmins: List(_companyAdmins),
        detailCompanyAdmins: Map(_detailCompanyAdmins),
        companyObjects: List(_companyObjects),
        positions: List(_positions),
        departments: List(_departments),
        detailDepartments: Map(_detailDepartments),
        professions: List(_professions),
        detailProfessions: Map(_detailProfessions),
        maintenances: List(_maintenances),
        detailMaintenances: Map(_detailMaintenances),
        managers: List(_managers),
        managerKeys: List(_managerkeys),
        userGroups: List(_usergroups),
        detailUserGroups: Map(_detailUserGroups),
        userGroupsContent: List(_usergroupscontent),
        detailUsers: Map(_detailUsers),
        detailPositions: Map(_detailPositions),
        shifts: List(_shifts),
        overtimes: List(_overtimes),
        salaries: Map(_salaries),
    });
}