import { ReadonlyRecord } from "@bimser/core";
import { IconInfo } from '@bimser/icons';
import { Record } from 'immutable';

export interface IRecentItemGroup {
    label: string,
    key: string
}

export interface IBaseRecentItem {
    id: string,
    title: string,
    description?: string,
    time?: Date,
    icon?: IconInfo,
    isPinned?: boolean,
    secretKey: string,
    _group?: IRecentItemGroup,
    externalData?: any
}

export interface IRecentItem extends IBaseRecentItem {
}

export interface IReadonlyRecentItem extends IBaseRecentItem {
}

export type RecentItem = ReadonlyRecord<IReadonlyRecentItem>;

export const RecentItemFactory = Record<IReadonlyRecentItem>({
    id: null,
    title: null,
    description: null,
    time: null,
    icon: null,
    isPinned: false,
    secretKey: null,
    externalData: null,
    _group: null
});

export const RecentItemFactoryFromJS = function (data: IRecentItem) {
    return RecentItemFactory(data);
}