import { takeEvery } from 'redux-saga/effects';
import { SYNERGY_LOG_ACTION } from '../action/actionTypes';

function* SynergyLogSaga() {
    yield takeEvery(SYNERGY_LOG_ACTION, SynergyLogTransaction);
}

export function SynergyLogTransaction(sagaAction: any) {
    try {
        var payload = sagaAction.payload;

        if (LongMessageControl(payload))
            return;

        var message = GetMessages(payload);

        switch (message.messageType) {
            case "warning":
                ConsoleWarn(message);
                break;
            case "error":
                ConsoleError(message);
                break;
            case "info":
            default:
                ConsoleInfo(message);
                break;
        }
    } catch (error) {
        console.log("🚀 ~ SynergyLogTransaction ~ error", error)
    }
}

function ConsoleInfo(message: any) {
    console.info("Synergy Log: ", GetMessage(message.userMessage, message.filePath, message.methodName, message.lineNumber));
}

function ConsoleWarn(message: any) {
    console.warn("Synergy Log: ", GetMessage(message.userMessage, message.filePath, message.methodName, message.lineNumber));
}

function ConsoleError(message: any) {
    console.error("Synergy Log: ", GetMessage(message.userMessage, message.filePath, message.methodName, message.lineNumber));
}

function LongMessageControl(payload: any): boolean {
    if (payload.total == 1 || ((window as any).MessageRepo && (window as any).MessageRepo[payload.id]?.length && (window as any).MessageRepo[payload.id].length == payload.total - 1)) {
        return false;
    } else {
        WriteMessageToRepo(payload);
        return true;
    }
}

function WriteMessageToRepo(payload: any) {
    if (!((window as any).MessageRepo))
        (window as any).MessageRepo = {};

    if (!((window as any).MessageRepo[payload.id]))
        (window as any).MessageRepo[payload.id] = [payload];
    else
        (window as any).MessageRepo[payload.id].push(payload);
}

function GetMessages(payload: any) {
    if (payload.total > 1) {
        var allMessage = (window as any).MessageRepo[payload.id];

        allMessage.push(payload);
        allMessage.sort((a: any, b: any) => a.index - b.index)

        var message: any = {};
        var userMessage = "";

        for (let index = 0; index < payload.total; index++) {
            userMessage += allMessage[index].message;
        }

        var lastMessage = allMessage.pop();
        message.filePath = lastMessage.filePath;
        message.methodName = lastMessage.methodName;
        message.lineNumber = lastMessage.lineNumber;
        message.messageType = lastMessage.messageType;
        message.userMessage = userMessage;

        delete (window as any).MessageRepo[payload.id];
        return message;
    } else {
        return {
            methodName: payload.methodName,
            filePath: payload.filePath,
            lineNumber: payload.lineNumber,
            messageType: payload.messageType,
            userMessage: payload.message,
        };
    }
}

function GetMessage(clientMessage: any, filePath: string, methodName: string, lineNumber: number) {
    return JSON.parse(`{
        \"UserMessage\": ${clientMessage},
        \"File Path\": \"${filePath}\",
        \"Method Name\": \"${methodName}\",
        \"Line Number\": \"${lineNumber}\"
    }`);
}

export default SynergyLogSaga;