import { LayoutItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const getItemPathById = (view: NormalizedLayoutStructureItems, id: string, subItem?: boolean) => {
    let indexes: string[] = [];
    if (!id) return []
    const args = getPathIndexOfItem(view, id);

    if (args) {
        indexes = [...indexes, args.index, ...getItemPathById(view, args.parentItem.id, true)]

    }

    return subItem ? indexes : indexes.reverse()

};

const getPathIndexOfItem = (view: NormalizedLayoutStructureItems, id: string): { index: string, parentItem: LayoutItem } => {
    const item = view.entities.items.get(id);
    if (!item) return null;
    const parentItem = getItem(view, item.parentId);
    if (parentItem) {
        return {
            index: parentItem.items.findIndex(itemId => itemId === id).toString(),
            parentItem: parentItem
        }
    }
};

const getItem = (view: NormalizedLayoutStructureItems, id: string) => view.entities.items.get(id);