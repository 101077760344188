import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { ContentLayoutEntities, ContentLayoutEntitiesFactory } from "./IContentLayoutEntities";
import { ContentLayoutResult, ContentLayoutResultFactory } from "./IContentLayoutResult";

export interface IContentLayoutState {
    entities: ContentLayoutEntities;
    result: ContentLayoutResult,
}

export type ContentLayoutState = ReadonlyRecord<IContentLayoutState>;

export const ContentLayoutStateFactory = Record<IContentLayoutState>({
    entities: ContentLayoutEntitiesFactory(),
    result: ContentLayoutResultFactory()
});

export const ContentLayoutStateFactoryFromJS = function (data: IContentLayoutState) {
    return ContentLayoutStateFactory(data);
}