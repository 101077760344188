import React, { useState, useEffect } from 'react'
import { Picker } from 'antd-mobile';
import IconClear from "@bimser/icons/16/close-panel";
import { IPickerProps } from './entities';
import BCButton from '../BCButton';
import { PickerColumn, PickerColumnItem } from 'antd-mobile/es/components/picker';
import BCInputSearch from '../BCInputSearch';
import { findMessage } from '../BCIntl';
import * as Styles from './assets/mobilePickerStyles.scss';
import BCButtonGroup from '../BCButtonGroup';

export default function BCMobilePicker(props: IPickerProps) {

    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState<(string | null)[]>([]);
    const [options, setOptions] = useState(props.options);

    function onConfirm(pickerValue: any) {
        setValue(pickerValue);
        props.onSelectedChange({
            senderArgs: null,
            data: pickerValue[0]
        });
    }

    useEffect(() => {
        setOptions(props.options);
    }, [props.options])

    function onClose() {
        setVisible(false);
    }

    function getItems(): PickerColumn {
        return options.map(option => ({
            key: option.key,
            value: option.value,
            label: option.text
        } as PickerColumnItem));
    }

    function onSearch(searchValue: string) {
        if (props.onSearch) {
            props.onSearch(searchValue);
        }
        else {
            setOptions(options.filter((option) => option.text.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) >= 0));
        }
    }

    function getSearchInput() {
        if (props.showSearch) {
            return <BCInputSearch className={Styles.mobilePickerStyles} onSearch={onSearch} />;
        }
        return "";
    }

    function onClickPickerButton() {
        setVisible(true)
    }

    const titleValue = React.useMemo(() => options.find((item) => item.selected)?.text, [options]);

    function clearValue() {
        onConfirm([])
    }

    return (
        <>
            <div>
                <BCButtonGroup key="btnGroup" className={Styles.buttonGroup}>
                    <BCButton
                        onClick={onClickPickerButton}
                        className={Styles.mobilePickerStyles}
                        size={props.size}
                        disabled={props.readonly || props.disabled}
                        style={props.style}
                    >
                        {titleValue || props.buttonText}
                    </BCButton>
                    <BCButton
                        className={Styles.suffixButton}
                        size={props.size}
                        disabled={props.readonly || props.disabled || !props.allowClear}
                        icon={<IconClear />}
                        onClick={() => clearValue()}
                    />
                </BCButtonGroup>
            </div>

            <Picker
                title={getSearchInput()}
                columns={[getItems()]}
                visible={visible}
                onClose={onClose}
                value={[props.value as any] || value}
                onConfirm={onConfirm}
                confirmText={findMessage.get("100004")}
                cancelText={findMessage.get("100034")}
            />
        </>
    );
}