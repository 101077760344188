import * as React from "react";
import { findMessage } from "../../BCIntl";
import BCTabs from '../../BCTabs';
import { IPropertyTabPanelProps, ITabItem } from '../entities';
import PropertyCollapsePanel from "./PropertyCollapsePanel";
import PropertyItemsPanel from "./PropertyItemsPanel";
import * as Styles from "../assets/style.scss";
const tabStyle: React.CSSProperties = { height: '100%' };
const propertyTabPanelScrollBarModalStyles: React.CSSProperties = { height: '405px' };
const propertyTabPanelScrollBarStyles: React.CSSProperties = { height: '100%' };
const emptyTabImg = require("../assets/img/tab-empty.svg").default;

const PropertyTabPanel = React.memo((props: IPropertyTabPanelProps) => {

    const renderEmptyTemplate = () => {
        return (
            <div className={Styles.emptyInspector} >
                <img src={emptyTabImg} />
                <span className={Styles.headerText}>{findMessage.get("101239")}</span>
                <span className={Styles.subText}>{props.emptyTemplateSubText}</span>
            </div>
        )
    }

    const renderTabContent = (tabItem: ITabItem) => {
        if (tabItem.properties?.length) {
            const propertyTabPanelScrollBarstyles: React.CSSProperties = props.isModal ? propertyTabPanelScrollBarModalStyles : propertyTabPanelScrollBarStyles
            return (
                <PropertyItemsPanel
                    currentLanguage={props.currentLanguage}
                    supportedLanguages={props.supportedLanguages}
                    propertyItems={tabItem.properties}
                    searchable={props.searchable}
                    onValueChanged={props.onValueChanged}
                    reload={props.reload}
                    fireAction={props.fireAction}
                    data={props.data}
                    scrollbarStyles={propertyTabPanelScrollBarstyles}
                    getOptionsPromise={props.getOptionsPromise}
                    onRefresh={props.onRefresh}
                    cssClass={props.cssClass}
                    guid={props.guid}
                    commonPopoverItems={props.commonPopoverItems}
                    showCustomBadgeOnLabel={props.showCustomBadgeOnLabel}
                />
            )
        }

        if (!tabItem.panels && !tabItem.properties) {
            return props.renderCustomEmpty?.() || renderEmptyTemplate()
        }

        let customContent: React.ReactNode;
        if (props.renderCustomTabContent) {
            customContent = props.renderCustomTabContent(tabItem);
        }

        return (
            customContent || <PropertyCollapsePanel
                currentLanguage={props.currentLanguage}
                supportedLanguages={props.supportedLanguages}
                searchable={props.searchable}
                collapseItems={tabItem.panels}
                tabKey={tabItem.key}
                onValueChanged={props.onValueChanged}
                reload={props.reload}
                fireAction={props.fireAction}
                data={props.data}
                guid={props.guid}
                scrollbarStyles={props.scrollbarStyles}
                getOptionsPromise={props.getOptionsPromise}
                onRefresh={props.onRefresh}
                commonPopoverItems={props.commonPopoverItems}
                showCustomBadgeOnLabel={props.showCustomBadgeOnLabel}
            />
        )
    }

    return (
        <BCTabs
            cssClass={props.cssClass}
            items={props.tabItems}
            style={tabStyle}
            renderTabContent={renderTabContent}
        />
    )
});

export default PropertyTabPanel