import * as React from "react";
import * as Styles from "../assets/style.scss";
import IVideoViewerProps from "../entities/VideoViewer/IVideoViewerProps";

export default class VideoViewer extends React.Component<IVideoViewerProps, any> {
    videoRef: any;

    constructor(props: IVideoViewerProps) {
        super(props);
        this.generateTemplate = this.generateTemplate.bind(this);
    }

    componentWillUnmount() {
        if (this.videoRef === (document as any).pictureInPictureElement) {
            (document as any).exitPictureInPicture();
        }
    }

    generateTemplate(props: IVideoViewerProps): JSX.Element {
        return (
            <video
                key={this.props.src}
                ref={ref => this.videoRef = ref}
                width={this.props.width}
                height={this.props.height}
                controls
            >
                <source
                    src={this.props.src}
                    type={this.props.mimeType}
                />
            </video>
        );
    }

    render() {
        return (
            <div className={Styles.videoViewerContent}>
                {
                    this.generateTemplate(this.props)
                }
            </div>
        );
    }

}