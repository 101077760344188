import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from 'immutable';
import { IProfileMenuNode } from "./IProfileMenuNode";


export interface IProfile {
    caption: string,
    id: string,
    menuNodes: IProfileMenuNode[]
}

export interface IReadonlyProfile {
    caption: string,
    id: string,
    menuNodes: List<IProfileMenuNode>
}

export type Profile = ReadonlyRecord<IReadonlyProfile>;

export const ProfileFactory = Record<IReadonlyProfile>({
    caption: "",
    id: "",
    menuNodes: List()
})


export const ProfileFactoryFromJS = function (data: IProfile) {
    return ProfileFactory({
        caption: data.caption,
        id: data.id,
        menuNodes: List(data.menuNodes)
    })
}