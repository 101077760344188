import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ISearchResult, SearchResult, SearchResultFactory, SearchResultFactoryFromJS } from "./ISearchResult";
import FilterItemType from './FilterItemTypes';

interface ISearchBaseState {
    loading: boolean,
    selectedType: FilterItemType,
}

export interface ISearchState extends ISearchBaseState {
    result: ISearchResult
}

export interface IReadOnlySearchState extends ISearchBaseState {
    result: SearchResult
}

export type SearchState = ReadonlyRecord<IReadOnlySearchState>;

export const SearchStateFactory = Record({
    loading: false,
    selectedType: FilterItemType.All,
    result: SearchResultFactory()
});

export const SearchStateFactoryFromJS = (data: ISearchState) => {
    return SearchStateFactory({
        ...data,
        result: SearchResultFactoryFromJS(data.result),
    })
}