import * as React from "react";
import * as Styles from "../assets/style.scss";
import ITiffViewerProps from "../entities/TiffViewer/ITiffViewerProps";
import Tiff from "tiff.js";

export default class TiffViewer extends React.Component<ITiffViewerProps, any> {
    constructor(props: ITiffViewerProps) {
        super(props);
        this.generateSrc = this.generateSrc.bind(this)
        
        Tiff.initialize({
            TOTAL_MEMORY: 16777216 * 10
        })
    }

    componentDidMount() {
        this.generateSrc();
    }

    generateSrc() {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        xhr.open('GET', this.props.src);
        xhr.onload = function (e) {
            var tiff = new Tiff({buffer: xhr.response});
            var url = tiff.toDataURL();
            (document.getElementById("tiffViewer") as HTMLImageElement).src = url;
        };
        xhr.send();
    }

    render() {
        return (
            <div className={Styles.tiffViewerContent}>
                <img id={"tiffViewer"} />
            </div>
        );
    }
}