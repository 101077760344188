
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";
import { IViewerPanelContent, ViewerPanelContent, ViewerPanelContentFactoryFromJS } from './IViewerPanelContent';
import { IContentState, ContentState, ContentStateFactory, ContentStateFactoryFromJS } from './IContentState';
import { IRelationListPanelContent, RelationListPanelContent, RelationListPanelContentFactoryFromJS } from '../components/RelationList/entities/IRelationListPanelContent';

interface IBaseViewerState {

}

export interface IViewerState extends IBaseViewerState {
    panels: IDictionary<IViewerPanelContent>;
    relations: IDictionary<IRelationListPanelContent>
    contents: IContentState;
}

export interface IReadonlyViewerState extends IBaseViewerState {
    panels: Map<string, ViewerPanelContent>;
    relations: Map<string, RelationListPanelContent>;
    contents: ContentState;
}

export type ViewerState = ReadonlyRecord<IReadonlyViewerState>;

export const ViewerStateFactory = Record<IReadonlyViewerState>({
    panels: Map(),
    relations: Map(),
    contents: ContentStateFactory()
});

export const ViewerStateFactoryFromJS = function (data: IViewerState) {
    let _panels: IDictionary<ViewerPanelContent> = Object.keys(data.panels).reduce(function (previous: IDictionary<ViewerPanelContent>, current) {
        previous[current] = ViewerPanelContentFactoryFromJS(data.panels[current]);
        return previous;
    }, {});

    let _relations: IDictionary<RelationListPanelContent> = Object.keys(data.relations).reduce(function (previous: IDictionary<RelationListPanelContent>, current) {
        previous[current] = RelationListPanelContentFactoryFromJS(data.relations[current]);
        return previous;
    }, {});

    return ViewerStateFactory({
        ...data,
        panels: Map(_panels),
        relations: Map(_relations),
        contents: ContentStateFactoryFromJS(data.contents)
    });
}