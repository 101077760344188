import { BCCarousel, BCSkeleton, ContentType, findMessage } from '@bimser/components';
import { createGuid, MLHelper } from "@bimser/core";
import { CarouselRef } from 'antd/lib/carousel';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import AppStoreRu from '../assets/img/appstore-ru.png';
import AppStoreTr from '../assets/img/appstore-tr.png';
import AppStore from '../assets/img/appstore.png';
import BimserSynergyLogo from '../assets/img/bimser-synergy-login-logo-with-sub-white.svg';
import GooglePlayRu from '../assets/img/googleplay-ru.png';
import GooglePlayTr from '../assets/img/googleplay-tr.png';
import GooglePlay from '../assets/img/googleplay.png';
import rightShadow from '../assets/img/rightShadow.png';
import warning from '../assets/img/warning.png';
import * as Styles from '../assets/style.scss';
import LoginContainer from '../containers/LoginContainer';
import ILoginMainCProps from '../entities/ILoginMainCProps';
import ILoginMainProps from '../entities/ILoginMainProps';
import ILoginMainState from '../entities/ILoginMainState';

const BimserSynergySvg = require("../assets/img/bimser-synergy-logo.svg");

const rightShadowStyle: React.CSSProperties = {
    height: '100%',
    width: '76px',
    backgroundImage: 'url(' + rightShadow + ')',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    right: '0',
    top: '0',
    content: '',
    zIndex: 10,
    opacity: .5
};

var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);
export default class LoginMain extends React.Component<ILoginMainProps & ILoginMainCProps, ILoginMainState> {

    imageSlider: CarouselRef;
    textSlider: CarouselRef;

    constructor(props: ILoginMainProps & ILoginMainCProps) {
        super(props);

        this.sliderBeforeChange = this.sliderBeforeChange.bind(this);
        this.renderMobileStoreLink = this.renderMobileStoreLink.bind(this);
        this.state = {
            showingPage: "login"
        };
    }

    private sliderBeforeChange(from: any, to: any) {
        this.imageSlider?.goTo(to);
    }

    private getImageforLang() {
        let lang = this.props.selectedCulture?.split("-")[0];
        switch (lang) {
            case "tr":
                return { Android: GooglePlayTr, Appstore: AppStoreTr }
            case "ru":
                return { Android: GooglePlayRu, Appstore: AppStoreRu }
            default:
                return { Android: GooglePlay, Appstore: AppStore }
        }
    }

    private renderMobileStoreLink(store: "Apple" | "Android") {
        switch (store) {
            case "Apple":
                return (
                    <a href="https://apps.apple.com/us/app/bimser-synergy/id1491902023?itsct=apps_box&amp;itscg=30200"
                        className={Styles.appStoreWrapper}>
                        <img src={this.getImageforLang().Appstore} alt={findMessage.get("101141")} className={Styles.appStoreImg} />
                    </a>
                )
            case "Android":
                return (
                    <a href="https://play.google.com/store/apps/details?id=com.bimser.synergy&utm_source=bimser-csp&utm_campaign=bimser-csp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img src={this.getImageforLang().Android} alt={findMessage.get("101132")} className={Styles.playStoreImg} />
                    </a>
                )
        }
    }

    private generalTemplate(): JSX.Element {
        return (
            <div className={Styles.container}>
                <div className={Styles.row + ' row no-gutters'}>
                    {!location.hash.includes("auth") && <>
                        <div className={Styles.left + ' col-md-7'}>
                            <div style={rightShadowStyle}></div>
                            {
                                this.generateTemplate2()
                            }
                        </div>
                        <div className={Styles.right + ' col-md-5'}>
                            {
                                this.getPage()
                            }
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    private generateTemplate2(): JSX.Element {
        return (
            <React.Fragment>
                <div className={"suitLogo"}>
                    <img src={this.props.tenant.logoMain ? this.props.tenant.logoMain : BimserSynergyLogo} />
                </div>
                <div className={Styles.carouselImage}>
                    <BCSkeleton secondaryColor={"#364D63"} loadingStatus={this.props.loginSliderItems.length == 0} contentType={ContentType.CarouselImg}>
                        <BCCarousel dots={false} getDomReference={(q: any) => this.imageSlider = q} pauseOnHover>
                            {this.props.loginSliderItems.map((item) => {
                                return (
                                    <div key={createGuid()}>
                                        <div className={"sliderItem"}>
                                            <div className={cx({ hasLink: item.url && item.url.length })} onClick={() => {
                                                if (item.url && item.url.length) {
                                                    window.open(item.url, "_blank");
                                                }
                                            }}>
                                                <img src={item.imageDownloadUrl.downloadUrl} />
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </BCCarousel>
                    </BCSkeleton>
                </div>
                <BCSkeleton secondaryColor={"#364D63"} loadingStatus={this.props.loginSliderItems.length == 0} contentType={ContentType.TitleAndDesc}>
                    <BCCarousel autoplay className={Styles.carouselText} effect={"fade"} beforeChange={this.sliderBeforeChange} getDomReference={(q: any) => this.textSlider = q}>
                        {
                            this.props.loginSliderItems.map((item) => {
                                return <div key={createGuid()}>
                                    <div className={Styles.sloganContainer}>
                                        <div className={Styles.title}>
                                            {MLHelper.getMLText(item.caption)}
                                        </div>
                                        <div className={Styles.description}>
                                            {MLHelper.getMLText(item.text)}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </BCCarousel>
                </BCSkeleton>
                <div className={Styles.storeLinks}>
                    <div className={Styles.appAnnouncement}>
                        {findMessage.get("102432")}
                    </div>
                    <div className={Styles.appImages}>
                        {this.renderMobileStoreLink("Android")}
                        {this.renderMobileStoreLink("Apple")}
                    </div>

                </div>
            </React.Fragment>
        )
    }

    private getTemplateBrowserBlock(): JSX.Element {
        const { customMessageHeader, customMessageBody } = this.props.browserProperties;

        return (
            <div className={Styles.container}>
                <div className={Styles.browserBlockContainer}>
                    <div className={Styles.BimserSynergyLogo}>
                        <img src={BimserSynergySvg.default} height="50" />
                    </div>
                    <div className={Styles.browserBlockContent}>
                        <div className={Styles.leftContent}>
                            <img src={warning} />
                        </div>
                        <div className={Styles.rightContent}>
                            <div className={Styles.blockedTitle}>
                                {MLHelper.getMLText(customMessageHeader) || findMessage.get('102818')}
                            </div>
                            <div className={Styles.blockedText}>
                                {MLHelper.getMLText(customMessageBody) || findMessage.get('102819')}
                            </div>
                        </div>
                        <div className={Styles.footerContent}>
                            <b>Copyright 2001-2020 Bimser A.Ş.</b>
                            <span>{findMessage.get('100740')} www.bimser.com</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private generateTemplate(): JSX.Element {
        if (this.props.browserProperties.isBlocked)
            return this.getTemplateBrowserBlock();

        return this.generalTemplate();
    }

    private getPage() {
        return <LoginContainer hash={this.props.hash} urlPath={this.props.urlPath} />;
    }

    public render() {
        return this.generateTemplate();
    }
}