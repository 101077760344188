import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from 'immutable';
import { IObjectItem,ObjectItem, ObjectItemFactoryFromJS } from "./IObjectItem";
import { IObjectSelectHistoryItem, ObjectSelectHistoryItem, ObjectSelectHistoryItemFactoryFromJS } from "./IObjectSelectHistoryItem";

interface IBaseObjectSelectPanelContent{
    loading?: boolean,
    isLoadingMore?: boolean,
    canLoadMore?:boolean
}

export interface IObjectSelectPanelContent extends IBaseObjectSelectPanelContent {
    history?: Array<IObjectSelectHistoryItem>
    objectList?: Array<IObjectItem>
}

export interface IReadonlyObjectSelectPanelContent extends IBaseObjectSelectPanelContent {
    history?: List<ObjectSelectHistoryItem>,
    objectList?: List<ObjectItem>
}

export type ObjectSelectPanelContent = ReadonlyRecord<IReadonlyObjectSelectPanelContent>;

export const  ObjectSelectPanelContentFactory = Record<IReadonlyObjectSelectPanelContent>({
    loading: false,
    isLoadingMore: false,
    canLoadMore: true,
    objectList : null,
    history: null
});

export const ObjectSelectPanelContentFactoryFromJS = function (data: IObjectSelectPanelContent) {
    let _objectList: Array<ObjectItem> = (data && data.objectList) ? data.objectList.map((object)=>{
        return ObjectItemFactoryFromJS(object);
    }): [];

    let _history: Array<ObjectSelectHistoryItem> = (data && data.history) ? data.history.map((d)=> {
        return ObjectSelectHistoryItemFactoryFromJS(d);
    }): [];

    return ObjectSelectPanelContentFactory({
        ...data,
        history: List(_history),
        objectList: List(_objectList)
    });
}
