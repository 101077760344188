import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from "../../../base/state";
import { DocumentSignature } from '../entities/IDocumentSignature';
import { SignatureParameters } from '../../../../modules/dm/entities/models/ISignatureParameters';


const getDocumentSignatureSelector = (state: WebInterfaceBaseState, modalId: string) => {
    let modalData = state.modals.data.get(modalId);
    if (modalData) {
        return modalData;
    }
    return null;
}

const getDocumentSignature = createSelector(
    [
        getDocumentSignatureSelector
    ],
    (data: DocumentSignature) => {
        return data ? data.toJS() : null;
    }
)

const getSignatureModalLoadingSelector = (state: WebInterfaceBaseState, modalId: string) => {
    let modalProps = state.modals.items.find(i => i.id === modalId);
    if (modalProps) {
        return modalProps.props.isLoading;
    }
    return false;
}

const getSignatureModalLoading = createSelector(
    [
        getSignatureModalLoadingSelector
    ],
    (isLoading: boolean) => isLoading
)

const getSignaturePermissionsSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.dmParameters.signatureParameters;
}

const getSignaturePermissions = createSelector(
    [
        getSignaturePermissionsSelector
    ],
    (parameters: SignatureParameters) => parameters.toJS()
)

export {
    getDocumentSignature,
    getSignatureModalLoading,
    getSignaturePermissions
}