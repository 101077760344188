import { IBaseFormContent } from "common/entities";
import { ReadonlyRecord } from '@bimser/core';
import { Record } from 'immutable';


export interface IBaseLegacyFramePanelContent extends IBaseFormContent {
    url: string,
    caption: string
}

export interface ILegacyFramePanelContent extends IBaseLegacyFramePanelContent { }

export interface IReadonlyLegacyFramePanelContent extends IBaseLegacyFramePanelContent { }

export type LegacyFramePanelContent = ReadonlyRecord<IReadonlyLegacyFramePanelContent>;

export const LegacyFramePanelContentFactory = Record<IReadonlyLegacyFramePanelContent>({
    panelId: "",
    showLoading: true,
    url: "",
    caption: ""
});

export const LegacyFramePanelContentFactoryFromJS = function (data: ILegacyFramePanelContent) {
    return LegacyFramePanelContentFactory(data);
}