import { ReadonlyRecord } from '@bimser/core';
import { List, Record } from "immutable";
import { IChangeEventArgs } from './IChangeEventArgs';

export interface IPropertyChangeEventArgs extends IChangeEventArgs {
    dataField: Array<string>
}

export interface IReadonlyBasePropertyChangeEventArgs extends IChangeEventArgs {
    dataField: List<string>
}

export type PropertyChangeEventArgs = ReadonlyRecord<IReadonlyBasePropertyChangeEventArgs>;

export const PropertyChangeEventArgsFactory = Record<IReadonlyBasePropertyChangeEventArgs>({
    dataField: List(),
    handled: false,
    id: null,
    value: null
});

export const PropertyChangeEventArgsFactoryFromJS = function (data: IPropertyChangeEventArgs) {
    return PropertyChangeEventArgsFactory({
        dataField: List(data.dataField),
        handled: data.handled,
        id: data.id,
        value: data.value
    });
}