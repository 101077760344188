import { createSelector, OutputSelector } from "reselect";
import { LanguageFactory, Language, IReadonlyLanguageState, ILanguage, IReadonlyLanguage } from "../entities";
import { BaseState, IReadonlyBaseState } from "../../../entities/BaseState";
import { Record } from 'immutable'

const defaultLanguage = (state: BaseState) => {
    if (state.system.language.items) {
        const defaultLanguage = state.system.language.items.find(q => q.isDefault);
        if (defaultLanguage) return defaultLanguage;
        return state.system.language.items.get(0);
    }
    return null;
}

const getDefaultLanguage = createSelector(
    [
        defaultLanguage
    ],
    (data: Language) => {
        if (data) {
            return data.toJS();
        }
        return LanguageFactory().toJS();
    }
);

export { getDefaultLanguage };
