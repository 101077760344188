import { ILanguage } from '@bimser/core';
import * as React from "react";
import BCInputMultiLanguage from '../../../BCInputMultiLanguage';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function MLInput(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const onChange = (culture: string, value?: string) => {
        let newValue = { ...props.value, [culture]: value };
        if (props.item.externalData?.disableMultiLanguage) culture = "system";

        props.customChangeEvent ? props.customChangeEvent(newValue) : replaceValue(props.item, { ...props.value, [culture]: value });
    }

    const getCurrentLanguage = () => {

        if (props.item.externalData?.disableMultiLanguage) {
            const systemCurrentLanguage: ILanguage = {
                id: 0,
                name: "system",
                culture: "system",
                isDefault: false,
                twoLetterCulture: "system"
            };
            return systemCurrentLanguage
        }

        return props.currentLanguage || props.item.options.currentLanguage
    }

    return (
        <BCInputMultiLanguage
            {...props.item.externalData && props.item.externalData.regExp ? { regExp: props.item.externalData.regExp } : null}
            key={props.item.key}
            values={props.value}
            currentLanguage={getCurrentLanguage()}
            supportedLanguages={props.supportedLanguages || props.item.options.supportedLanguages}
            onLanguageValueChange={onChange}
            disableMultiLanguage={props.item.externalData && props.item.externalData.disableMultiLanguage}
            maxLength={props.item.externalData?.maxLength}
            disabled={props.item.disabled}
        />
    )
}