import { IDictionary, isNullOrUndefined, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { FlowHistoryItem, FlowHistoryItemFactoryFromJS, IFlowHistoryItem } from "./IFlowHistoryItem";

export interface IBaseFlowHistory {
    processId?: number,
    processName?: string,
    statusIcon?: string
}

export enum FlowHistoryItemType {
    RequestHistory,
    FlowHistory,
    SubFlow
}

export enum FlowHistoryRequestType {
    None,
    Starter,
    Approver,
    Group,
    Pause,
    Timer,
    Notify,
    End,
    State
}

export enum ProcessRequestActionerUserType {
    Self = 0,
    Delegated = 1
}

export interface IFlowHistory extends IBaseFlowHistory {
    items?: IFlowHistoryItem[],
    subProcesses?: IFlowHistory[],
    processCaption?: IDictionary<string>,
    statusDescription?: IDictionary<string>
}

export interface IReadonlyFlowHistory extends IBaseFlowHistory {
    items?: List<FlowHistoryItem>,
    subProcesses?: List<FlowHistory>,
    processCaption?: Map<string, string>,
    statusDescription?: Map<string, string>
}

export type FlowHistory = ReadonlyRecord<IReadonlyFlowHistory>

export const FlowHistoryFactory = Record<IReadonlyFlowHistory>({
    processId: 0,
    processName: "",
    statusIcon: "",
    processCaption: Map<string, string>(),
    statusDescription: Map<string, string>(),
    items: List(),
    subProcesses: List()
})

export const FlowHistoryFactoryFromJS = function (data: IFlowHistory) {

    if (isNullOrUndefined(data)) return FlowHistoryFactory();

    let _items: Array<FlowHistoryItem> = data.items?.map((item: IFlowHistoryItem) => {
        return FlowHistoryItemFactoryFromJS(item);
    });

    let _subProcesses: Array<FlowHistory> = data.subProcesses?.map((item: IFlowHistory) => {
        return FlowHistoryFactoryFromJS(item);
    });

    return FlowHistoryFactory({
        processId: data.processId,
        processCaption: Map(data.processCaption),
        statusIcon: data.statusIcon,
        statusDescription: Map(data.statusDescription),
        processName: data.processName,
        items: List(_items),
        subProcesses: List(_subProcesses)
    });
}