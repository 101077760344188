import { List } from "immutable";
import { createSelector } from "reselect";
import { WebInterfaceBaseState } from "../../../common/base/state";
import { IHumanResourceModule, Modules } from "../entities";

const selectedModuleSelector = (state: WebInterfaceBaseState) => state.modules.humanResource.modules.selected;
const getHoldingsSelector = (state: WebInterfaceBaseState) => state.modules.humanResource.modules.holdings;
const getCompaniesSelector = (state: WebInterfaceBaseState) => state.modules.humanResource.modules.companies;
const moduleLoadingSelector = (state: WebInterfaceBaseState) => state.modules.humanResource.modules.loading;
const moduleDisabledSelector = (state: WebInterfaceBaseState) => (
    state.modules.humanResource.menu
        .find(m => m.menuKey == state.modules.humanResource.modules.selected)
        .get("switchOn")
);

const selectedModule = createSelector(
    [
        selectedModuleSelector
    ],
    (selected: IHumanResourceModule) => {
        return selected
    }
)
const moduleLoading = createSelector(
    [
        moduleLoadingSelector
    ],
    (loading: boolean) => {
        return loading
    }
)

const getHoldings = createSelector(
    [
        getHoldingsSelector
    ],
    (holdings: List<Modules.Holding>) => {
        return holdings.map((holding: Modules.Holding) => holding.toJS()).toJS();
    }
)

const getCompanies = createSelector(
    [
        getCompaniesSelector
    ],
    (companies: List<Modules.Company>) => {
        return companies.map((company: Modules.Company) => company.toJS()).toJS();
    }
)

const moduleDisabled = createSelector(
    [
        moduleDisabledSelector
    ],
    (switchOn: boolean) => {
        return !(switchOn ?? true);
    }
)

export { selectedModule, moduleLoading, getHoldings, getCompanies, moduleDisabled}