import { FormItemTypes } from "@bimser/core";
import { List, Map } from "immutable";
import { getItemByPath, removeItemWithRefs } from ".";
import { LayoutDataItem, NormalizedLayoutStructureItems } from "../../../common/entities";
import { IHandleMoveResult } from "../entities";

export const handleDeleteItem = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    shiftKey: boolean,
    id: string
): IHandleMoveResult => {

    let selectedItemKeys: string[] = [];
    let selectedGhostItemKeys: string[] = [];

    // Delete from active view

    if (!splitDropZonePath || !splitDropZonePath.count()) {
        // There are no selected items info
        const view = views.get(activeView);
        view?.entities.items.forEach(item => {
            if (item.designerProps.selected && item.type !== FormItemTypes.EContainerTypes.Form) selectedItemKeys.push(item.id);
        });
        view?.entities.ghostItems.forEach(item => {
            if (item.designerProps.selected) selectedGhostItemKeys.push(item.id);
        });
    } else {
        selectedItemKeys.push(getItemByPath(views, activeView, splitDropZonePath));
    }

    let nonLockedItems = selectedItemKeys.filter(selectedKey => {
        const isLocked = views.getIn([activeView, "entities", "items", selectedKey, "designerProps", "locked"], false);
        return !isLocked
    });

    let nonLockedGhostItems = selectedGhostItemKeys.filter(selectedKey => {
        const isLocked = views.getIn([activeView, "entities", "ghostItems", selectedKey, "designerProps", "locked"], false);
        return !isLocked
    });

    if (!nonLockedItems.length && !nonLockedGhostItems.length && !id) return { views, data };

    // Delete parmanently

    if (shiftKey) {
        const itemKeys = [...nonLockedItems, ...nonLockedGhostItems];
        if (!itemKeys.length && id) itemKeys.push(id);

        itemKeys.forEach(selectedKey => {
            if (data.has(selectedKey)) {
                data = data.remove(selectedKey);

                views.forEach((value, key) => {
                    if (value.entities.items.has(selectedKey)) {
                        const selectedItem = value.entities.items.get(selectedKey);

                        views = views.setIn(
                            [key, "entities", "items"],
                            value.entities.items.remove(selectedKey)
                        );

                        views = views.setIn(
                            [key, "entities", "items", selectedItem.parentId, "items"],
                            value.entities.items.get(selectedItem.parentId).items.filter(item => item !== selectedKey)
                        );
                    } else if (value.entities.ghostItems.has(selectedKey)) {
                        views = views.setIn(
                            [key, "entities", "ghostItems"],
                            value.entities.ghostItems.remove(selectedKey)
                        );
                    }
                });
            }
        });
    }

    if (nonLockedGhostItems.length) {
        nonLockedGhostItems.forEach(ghostItemId => {
            views = views.deleteIn([activeView, "entities", "ghostItems", ghostItemId]);
        });
    }

    return removeItemWithRefs(views, data, activeView, nonLockedItems, null, shiftKey)
};