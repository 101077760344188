import ConfigProvider from 'antd/lib/config-provider';
import List from 'antd/lib/list';
import * as React from "react";
import { DndProvider, DragSource, DragSourceConnector, DragSourceMonitor, DropTarget, DropTargetMonitor } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { BCEmpty, BCScrollbars, BCSkeleton } from '../';
import * as Styles from "./assets/listStyles.scss";
import IListGridType from './entities/IListGridType';
import IListPagination from './entities/IListPagination';
import IListProps from "./entities/IListProps";
import IListState from "./entities/IListState";
import { Skeleton } from "antd";
import { createGuid } from '@bimser/core';

export class ListItem extends React.Component<any, any> {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveItem, index, itemProps, isDragging, ...restProps } = this.props;
        let style = { opacity: isOver ? 0 : 1 };
        return connectDragSource(
            connectDropTarget(<div style={style}><List.Item key={index}>{this.props.renderItem(itemProps)}</List.Item></div>),
        );
    }
}
const rowSource = {
    beginDrag(props: any) {
        return {
            index: props.index,
        };
    },
};

const rowTarget: any = {
    drop: (props: any, monitor: any) => {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        // Don't replace items with themselves
        props.dropItem(dragIndex, hoverIndex);
    },
    hover: (props: any, monitor: DropTargetMonitor, component: any): any => {
        if (!component) {
            return;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        //// Determine rectangle on screen
        //const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect()
        ////) as any).getBoundingClientRect();

        //// Get vertical middle
        //const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        //// Determine mouse position
        //const clientOffset = monitor.getClientOffset();

        //// Get pixels to the top
        //// const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
        //const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        //// Only perform the move when the mouse has crossed half of the items height
        //// When dragging downwards, only move when the cursor is below 50%
        //// When dragging upwards, only move when the cursor is above 50%

        //// Dragging downwards
        //if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        //    return;
        //}

        //// Dragging upwards
        //if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        //    return;
        //}

        // Time to actually perform the action
        props.moveItem(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};
const DragableListItem = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),

}))(
    DragSource('row', rowSource, (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        //isOver: monitor.isOver(),
        isDragging: monitor.isDragging(),
    }))(ListItem),
);


export default class BCList extends React.Component<IListProps, IListState>{

    infinityScrollContainerId: string;

    constructor(props: IListProps) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.customizeRenderEmpty = this.customizeRenderEmpty.bind(this);
        this.moveItem = this.moveItem.bind(this);
        this.dropItem = this.dropItem.bind(this);
        this.getList = this.getList.bind(this);
        this.getInfinityScroll = this.getInfinityScroll.bind(this);
        this.renderView = this.renderView.bind(this);
        this.getLoadingSkeleton = this.getLoadingSkeleton.bind(this);

        this.infinityScrollContainerId = createGuid();

        Styles;
    }

    renderItem(itemProps: any, index: number) {
        let itemKey = index.toString();
        if (itemProps && itemProps.id !== null && itemProps.id !== undefined) {
            itemKey += itemProps.id;
        }
        if (this.props.sortable) {
            return (<DragableListItem key={itemKey} index={index} itemProps={itemProps} moveItem={this.moveItem} dropItem={this.dropItem} renderItem={this.props.renderItem} />);
        }
        else {
            return (<List.Item id={itemProps.id} key={itemKey} onClick={this.props.onClickListItem}>{this.props.renderItem(itemProps)}</List.Item>);
        }
    }

    customizeRenderEmpty() {
        if (this.props.customEmpty)
            return this.props.customEmpty;
        else
            return <BCEmpty />
    }
    moveItem(dragIndex: number, hoverIndex: number) {
        if (this.props.moveItem) {
            this.props.moveItem(dragIndex, hoverIndex);
        }
    }
    dropItem(dragIndex: number, hoverIndex: number) {
        if (this.props.dropItem) {
            this.props.dropItem(dragIndex, hoverIndex);
        }
    }

    getList() {
        return (
            <List
                pagination={...this.props.pagination as any}
                header={this.props.header}
                footer={this.props.footer}
                bordered={this.props.bordered}
                dataSource={this.props.dataSource}
                extra={this.props.extra}
                grid={this.props.grid}
                itemLayout={this.props.itemLayout}
                loading={this.props.loading}
                loadMore={this.props.loadMore}
                locale={this.props.locale}
                prefixCls={this.props.prefixCls}
                rowKey={this.props.rowKey}
                size={this.props.size}
                split={this.props.split}
                renderItem={this.renderItem}
                className={this.props.className}
            />
        );
    }

    renderView(args: any) {
        return <div id={`scrollableDiv_${this.infinityScrollContainerId}`} style={{ ...args.style }}></div>
    }

    getLoadingSkeleton() {
        if(this.props.dataSource.length !== this.props.totalCount) {
            if(this.props.loadingSkeletonContent) {
                return (
                    <BCSkeleton
                        contentType={this.props.loadingSkeletonContent}
                        height={this.props.loadingSkeletonHeight}
                        loadingStatus
                    />
                )
            }

            return <Skeleton paragraph={{ rows: 3 }} active />
        }
        
        return <></>
    }

    getInfinityScroll() {
        return (
            <BCScrollbars
                renderView={(args) => this.renderView(args)}
                styles={{ height: this.props.scrollHeight }}
                autoHide
                onScroll={this.props.onScrollInfinityScroll}
            >
                <List
                    pagination={...this.props.pagination as any}
                    header={this.props.header}
                    footer={this.props.footer}
                    bordered={this.props.bordered}
                    extra={this.props.extra}
                    grid={this.props.grid}
                    itemLayout={this.props.itemLayout}
                    loading={this.props.loading}
                    loadMore={this.props.loadMore}
                    locale={this.props.locale}
                    prefixCls={this.props.prefixCls}
                    rowKey={this.props.rowKey}
                    size={this.props.size}
                    split={this.props.split}
                    renderItem={this.renderItem}
                    className={this.props.className}
                >
                    {this.props.dataSource.map((item, index) => this.renderItem(item, index))}
                </List >
                {this.getLoadingSkeleton()}
            </BCScrollbars >
        );
    }

    renderList() {

        if (this.props.infinityScroll) {
            return (this.getInfinityScroll());
        }

        return (
            <ConfigProvider renderEmpty={this.customizeRenderEmpty}>

                {this.getList()}

            </ConfigProvider>
        );
    }

    render() {
        if (this.props.sortable) {
            return (
                <DndProvider backend={HTML5Backend}>
                    {this.renderList()}
                </DndProvider>
            );
        }
        else {
            return this.renderList();
        }
    }
}


export { IListProps, IListPagination, IListGridType };
