import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { IWorkflowItem, WorkflowItem, WorkflowItemFactoryFromJS } from '../../../entities';

interface IBaseWFListPanelContent {
    isLoading: boolean,
    activeItemKey: string,
    activeChildPanelGuid: string,
}

export interface IWFListPanelContent extends IBaseWFListPanelContent {
    items?: Array<IWorkflowItem>,
}

export interface IReadonlyWFListPanelContent extends IBaseWFListPanelContent {
    items?: List<WorkflowItem>,
}

export type WFListPanelContent = ReadonlyRecord<IReadonlyWFListPanelContent>;

export const WFListPanelContentFactory = Record<IReadonlyWFListPanelContent>({
    isLoading: false,
    activeItemKey: null,
    activeChildPanelGuid: null,
    items: List<WorkflowItem>(),
});

export const WFListPanelContentFactoryFromJS = function (data: IWFListPanelContent) {
    let _items: Array<WorkflowItem> = data?.items?.map((i) => {
        return WorkflowItemFactoryFromJS(i);
    }) || [];

    return WFListPanelContentFactory({
        ...data,
        items: List(_items)
    });
}