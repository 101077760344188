import { createSyncAction } from "@bimser/core";
import { List } from 'immutable';
import { ILocation, LocationFactoryFromJS,Location } from "../entities";
import * as ActionTypes from './actionTypes';


export default function setLocationAction(data: Array<ILocation>) {

    let _Locations: Array<Location> = data && data.length ? data.map((value: ILocation, index: number) => {
        return LocationFactoryFromJS(value);
    }) : [];

    let payload = List(_Locations)

    let action = createSyncAction(ActionTypes.SET_LOCATION_ACTION, payload);

    return action;
}