import { EDeviceTypes, FormItemTypes, getDeviceType, IItem, isNullOrUndefined } from "@bimser/core";
import { List } from "immutable";
import React, { MouseEventHandler, useCallback, useEffect, useRef } from "react";
import BCContextMenu, { IContextMenuClickEventArgs } from "../../../BCContextMenu";
import BCScrollbars from "../../../BCScrollbars";
import { LayoutItemFactory, LayoutStructureItem, LayoutStructureItemFactory } from "../../common/entities";
import { AddNewSectionButton } from "./AddSectionButton";
import * as Styles from "./assets/styles.scss";
import { BottomDock } from "./BottomDock";
import DropZone from "./DropZone";
import { IContainer, IDropData, IDropItemProps, IDropNewItemProps, LayoutCreatorLockActionFactory, StructureChangeFactory, StructureChangeFactoryFromJS } from "./entities";
import { ELayoutItemTypes } from "./entities/Constants";
import { LayoutCreatorSelectActionFactory } from "./entities/ILayoutCreatorSelectAction";
import { getZoneType } from "./helpers";
import { getContextMenuItems } from "./helpers/getContextMenuItems";
import RootDropZone from "./RootDropZone";
import Section from "./Section";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const contextMenuEventName = "formDesigner_hideContextMenu";

let downX: number;
let downY: number;
let contextMenuClickMove: boolean = false;

const Container = React.memo((props: IContainer) => {

    const containerDomNode = useRef<HTMLDivElement>(null);
    const selectionAreaNode = useRef<HTMLDivElement>(null);

    const rootId = props.isSubLayout ? props.parentItem?.id : props.view.result.get(0);
    const rootItem = props.isSubLayout ? props.parentItem : props.view.entities.items.get(rootId);

    const [contextMenuVisible, setContextMenuVisible] = React.useState(false);
    const [contextMenuTriggerEvent, setContextMenuTriggerEvent] = React.useState<React.MouseEvent>(null);
    const [contextMenuItems, setContextMenuItems] = React.useState<IItem[]>([]);

    const [deviceType, setDeviceType] = React.useState<EDeviceTypes>(EDeviceTypes.Medium);

    const parentItemType = props.parentItem?.type;

    const handleDrop = useCallback(
        (dropZone: IDropData, item: IDropNewItemProps | IDropItemProps) => {

            const splitDropZonePath = dropZone.path.split("-");
            const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

            const dropItemProps = item as IDropItemProps;
            const dropNewItemProps = item as IDropNewItemProps;

            const newItem: LayoutStructureItem = LayoutStructureItemFactory({ id: dropItemProps.data?.id, type: dropItemProps.data?.type });

            // sidebar into
            const dropTarget = dropNewItemProps.props?.data?.dropTarget;
            const isObjectExplorerItem = dropTarget === ELayoutItemTypes.OBJECT_EXPLORER_ITEM;
            if (dropTarget === ELayoutItemTypes.SIDEBAR_ITEM || isObjectExplorerItem) {
                // 1. Move sidebar item into page

                const id = isObjectExplorerItem ? dropNewItemProps.props.data?.key : null;

                if (dropNewItemProps.props.data.type === FormItemTypes.EContainerTypes.Column || dropNewItemProps.props.data.type === FormItemTypes.EContainerTypes.Section) {
                    props.onStructureChange(StructureChangeFactory({
                        splitDropZonePath: List(splitDropZonePath),
                        method: "handleMoveSidebarContainerIntoParent",
                        dragItemType: dropNewItemProps.props?.data?.dropTarget,
                        item: dropZone.parent
                    }));
                } else if (FormItemTypes.EGhostItemTypes[dropNewItemProps.props.data.type]) {
                    props.onStructureChange(StructureChangeFactory({
                        splitDropZonePath: List(["0"]),
                        item: LayoutStructureItemFactory({ id, type: dropNewItemProps.props.data.type }),
                        method: "handleMoveGhostItemIntoRoot",
                        dragItemType: dropNewItemProps.props?.data?.dropTarget
                    }));
                } else {
                    props.onStructureChange(StructureChangeFactory({
                        splitDropZonePath: List(splitDropZonePath),
                        item: LayoutStructureItemFactory({ id, type: dropNewItemProps.props.data.type, parentId: dropZone.parent.id }),
                        method: "handleMoveSidebarComponentIntoParent",
                        dragItemType: dropNewItemProps.props?.data?.dropTarget
                    }));
                }

                return;
            }

            // move down here since sidebar items dont have path
            const splitItemPath = dropItemProps.path.split("-");
            const pathToItem = splitItemPath.slice(0, -1).join("-");

            // 2. Pure move (no create)
            if (getZoneType(splitItemPath.length) === getZoneType(splitDropZonePath.length)) {
                // 2.a. move within parent
                if (pathToItem === pathToDropZone) {
                    props.onStructureChange(StructureChangeFactory({
                        splitDropZonePath: List(splitDropZonePath),
                        splitItemPath: List(splitItemPath),
                        method: "handleMoveWithinParent",
                        dragItemType: dropItemProps.type,
                        item: dropItemProps.data
                    }));
                    return;
                }

                // 2.b. OR move different parent
                props.onStructureChange(StructureChangeFactory({
                    splitDropZonePath: List(splitDropZonePath),
                    splitItemPath: List(splitItemPath),
                    item: newItem,
                    method: "handleMoveToDifferentParent",
                    dragItemType: dropItemProps.type
                }));
                return;
            }

            // 3. Move + Create
            props.onStructureChange(StructureChangeFactory({
                splitDropZonePath: List(splitDropZonePath),
                splitItemPath: List(splitItemPath),
                item: newItem,
                method: "handleMoveToDifferentParent",
                dragItemType: dropItemProps.type
            }));
        },
        [props.view]
    );


    // Context Menu

    const hideContextMenu = useCallback(() => {
        setContextMenuVisible(false);
        setContextMenuItems([]);
    }, []);

    const handleContextMenuClick = useCallback((e: React.MouseEvent, item: LayoutStructureItem, path: string) => {

        if (contextMenuClickMove) {
            hideContextMenu();
            contextMenuClickMove = false;
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        setContextMenuTriggerEvent(e);

        const items: IItem[] = getContextMenuItems(item, props.view, path);

        if (contextMenuVisible) {
            hideContextMenu();
        }

        const event = new Event(contextMenuEventName, { "bubbles": true, "cancelable": true });
        document.dispatchEvent(event);

        setTimeout(() => {
            setContextMenuVisible(true);
            setContextMenuItems(items);
        }, 1)

        e.persist();
        e.preventDefault();
    }, [props.view]);

    const handleContextMenuClose = useCallback(() => {
        hideContextMenu();
        setContextMenuTriggerEvent(null);
    }, []);

    const handleContextMenuItemClicked = useCallback((args: IContextMenuClickEventArgs) => {

        const { id, path } = args.data.externalData;
        const type = props.view.entities.items.get(id)?.type;

        switch (args.data.key) {
            case "lock":
            case "unlock": {
                props.onItemLockChanged?.(LayoutCreatorLockActionFactory({ item: LayoutItemFactory({ id }) }));
                break;
            }
            case "delete": {
                props.onStructureChange(StructureChangeFactoryFromJS({
                    method: "delete",
                    splitDropZonePath: path.split("-")
                }));
                break;
            }
            case "properties": {
                props.onItemSelectionChanged?.(LayoutCreatorSelectActionFactory({
                    item: LayoutItemFactory({ id: id, type }),
                    multiple: false,
                    selected: true
                }));
                setTimeout(() => {
                    props.showPropertyInspector?.();
                }, 500);
                break;
            }
            case "copy": break;
            default: {
                if (args.data.key.includes("select__form__item__")) {
                    props.onItemSelectionChanged?.(LayoutCreatorSelectActionFactory({ item: LayoutItemFactory({ id, type }), selected: true }));
                }
            }
        }

        hideContextMenu();
        setContextMenuTriggerEvent(null);
    }, [rootItem]);

    const renderContextMenu = () => {
        return (
            <BCContextMenu
                visible={contextMenuVisible}
                mouseEvent={contextMenuTriggerEvent}
                onClose={handleContextMenuClose}
                onClick={handleContextMenuItemClicked}
                items={contextMenuItems}
            />
        )
    }

    const onContainerContextMenuClick: MouseEventHandler<HTMLDivElement> = (e) => {
        if (contextMenuClickMove) {
            hideContextMenu();
        } else {
            handleContextMenuClick(e, rootItem, "");
        }
        e.preventDefault();
        e.stopPropagation();
        contextMenuClickMove = false;
    }

    // Context Menu : End

    const getItem = useCallback((id: string) => props.view.entities.items.get(id), [props.view]);

    const onItemSelectionChanged = useCallback((e: React.MouseEvent, item: LayoutStructureItem, multiple?: boolean, selected?: boolean) => {
        props.onItemSelectionChanged?.(LayoutCreatorSelectActionFactory({
            item: LayoutItemFactory({ id: item.id, type: item.type }),
            multiple: multiple || e.ctrlKey,
            selected: isNullOrUndefined(selected) ? true : selected
        }))
        e?.stopPropagation();
    }, []);

    const onDesignerSelect = (e: React.MouseEvent) => onItemSelectionChanged(e, rootItem);

    // Multiple Selection : Start

    const [selectionBounds, setSelectionBounds] = React.useState<DOMRect>(null);

    useEffect(() => {
        if (!props.isSubLayout && selectionBounds) {
            setSelectionBounds(null);
        }
        if (parentItemType !== FormItemTypes.EPanelBaseTypes.InputGroup) {
            setDeviceType(getDeviceType(containerDomNode.current.offsetWidth));
        }
    });

    useEffect(() => {
        setSelectionBounds(props.selectionBounds);
    }, [props.selectionBounds]);

    const hideContextMenuListener = (e) => {
        hideContextMenu();
    }

    useEffect(() => {
        // Hide context menu across sub designers
        window.addEventListener(contextMenuEventName, hideContextMenuListener, false);
        return () => {
            window.removeEventListener(contextMenuEventName, hideContextMenuListener);
        }
    }, []);

    const _onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!e.ctrlKey && !props.isSubLayout) {
            if (e.nativeEvent) {
                e.nativeEvent.stopPropagation();
            }

            setSelectionBounds(null);

            if (!((e.nativeEvent.button === 0 && e.ctrlKey) || e.nativeEvent.button === 2)) {
                return;
            }

            let actualBounds = containerDomNode.current.getBoundingClientRect();

            downX = actualBounds.left - e.clientX;
            downY = actualBounds.top - e.clientY;

            selectionAreaNode.current.style.top = Math.abs(downX) + 'px';
            selectionAreaNode.current.style.left = Math.abs(downY) + 'px';

            window.addEventListener('mousemove', _onMouseMove, false);
            window.addEventListener('mouseup', _onMouseUp, false);
        }
    }

    const _onMouseMove = (e: any) => {
        if (!e.ctrlKey && !props.isSubLayout) {
            let actualBounds = containerDomNode.current.getBoundingClientRect();

            let moveX = actualBounds.left - e.clientX;
            let moveY = actualBounds.top - e.clientY;

            if (removePx(selectionAreaNode.current.style.height) > 5 || removePx(selectionAreaNode.current.style.width) > 5) {
                selectionAreaNode.current.style.display = "block";
            }

            if (moveY < 0) {
                if (moveY > downY) {
                    selectionAreaNode.current.style.top = Math.abs(moveY) + 'px';
                } else {
                    selectionAreaNode.current.style.top = Math.abs(downY) + 'px';
                }
                if (Math.abs(moveY) < actualBounds.height) {
                    selectionAreaNode.current.style.height = Math.abs(moveY - downY) + 'px';
                } else {
                    selectionAreaNode.current.style.height = (actualBounds.height - Math.abs(downY)) + 'px';
                }
            } else {
                selectionAreaNode.current.style.top = '0px';
            }

            if (moveX < 0) {
                if (moveX > downX) {
                    selectionAreaNode.current.style.left = Math.abs(moveX) + 'px';
                } else {
                    selectionAreaNode.current.style.left = Math.abs(downX) + 'px';
                }
                if (Math.abs(moveX) < actualBounds.width) {
                    selectionAreaNode.current.style.width = Math.abs(moveX - downX) + 'px';
                } else {
                    selectionAreaNode.current.style.width = (actualBounds.width - Math.abs(downX)) + 'px';
                }
            } else {
                selectionAreaNode.current.style.left = '0px';
            }

            const { width, height } = selectionAreaNode.current.style;
            if (parseInt(width.replace("px", "")) > 5 || parseInt(height.replace("px", "")) > 5) {
                contextMenuClickMove = true;
            }
        }
    }

    const removePx = (p: string) => parseInt(p.replace("px", ""));

    const _onMouseUp = (e: any) => {
        if (!e.ctrlKey && !props.isSubLayout) {
            e.stopPropagation();

            let _selectionRect = selectionAreaNode.current.getBoundingClientRect();

            selectionAreaNode.current.style.top = '0px';
            selectionAreaNode.current.style.left = '0px';
            selectionAreaNode.current.style.width = '0px';
            selectionAreaNode.current.style.height = '0px';
            selectionAreaNode.current.style.display = 'none';

            window.removeEventListener('mousemove', _onMouseMove, false);
            window.removeEventListener('mouseup', _onMouseUp, false);

            setSelectionBounds(_selectionRect);
        }
    }

    // Multiple Selection : End

    const renderSection = (section: LayoutStructureItem, currentPath: string) => {
        return (
            <Section
                key={section.id}
                data={section}
                handleDrop={handleDrop}
                path={currentPath}
                getItem={getItem}
                onStructureChange={props.onStructureChange}
                setContextMenuItems={handleContextMenuClick}
                onItemSelectionChanged={onItemSelectionChanged}
                isParentLocked={rootItem.designerProps.locked}
                selectionBounds={selectionBounds}
                controlRenderManager={props.controlRenderManager}
                deviceType={deviceType}
                parentItemType={parentItemType as FormItemTypes.EControlTypes}
            />
        );
    };

    const ghostItems = props.view.entities.ghostItems;

    const scrollWrapper = (children?: React.ReactNode) => {
        let style: React.CSSProperties = {
            width: 'auto'
        };

        if (!props.isSubLayout) {
            style.height = '100%';
        }

        if (props.hasScrollbar) {
            const scrollableAreaClassNames: string = cx({
                hasBottomDock: ghostItems?.count() > 0 ? true : false
            });

            return (
                <BCScrollbars cssClass={scrollableAreaClassNames} styles={style}>
                    {children}
                </BCScrollbars>
            )
        } else {
            return children;
        }

    }

    const renderCreator = () => {

        const pageClassNames: string = cx({
            page: true,
            isInputGroup: parentItemType === FormItemTypes.EPanelBaseTypes.InputGroup,
            selected: rootItem?.designerProps.selected,
            locked: rootItem?.designerProps.locked
        });

        const pageContainerClassNames: string = cx({
            pageContainer: true,
            isSubLayout: props.isSubLayout,
            [props.containerClassName]: true
        });

        return (
            <>
                {renderContextMenu()}
                <RootDropZone onDrop={handleDrop} >
                    <div className={Styles.body} onContextMenu={onContainerContextMenuClick} onClick={onDesignerSelect} onMouseDown={_onMouseDown} ref={containerDomNode}>
                        <div ref={selectionAreaNode} className={Styles.selectionArea} />
                        <div className={pageContainerClassNames}>
                            <div className={pageClassNames} style={props.containerStyles}>
                                {rootItem?.items.map((itemId: string, index) => {
                                    const currentPath = props.isSubLayout ? `${props.parentPath}-${index}` : `${index}`;
                                    const item = props.view.entities.items.get(itemId);

                                    if (item.type === FormItemTypes.EContainerTypes.Section) {
                                        return (
                                            <React.Fragment key={itemId}>
                                                {
                                                    parentItemType !== FormItemTypes.EPanelBaseTypes.InputGroup &&
                                                    <DropZone
                                                        onDrop={handleDrop}
                                                        path={currentPath}
                                                        dropType={FormItemTypes.EContainerTypes.Section}
                                                        parent={rootItem}
                                                    />
                                                }
                                                {renderSection(item, currentPath)}
                                            </React.Fragment>
                                        )
                                    }

                                    return undefined;

                                })}
                                {
                                    parentItemType !== FormItemTypes.EPanelBaseTypes.InputGroup &&
                                    <>
                                        <DropZone
                                            path={props.isSubLayout ? `${props.parentPath}-${rootItem?.items.count() || 0}` : `${rootItem?.items.count() || 0}`}
                                            onDrop={handleDrop}
                                            dropType={FormItemTypes.EContainerTypes.Section}
                                            isLast
                                            parent={rootItem}
                                        />
                                        <AddNewSectionButton
                                            onStructureChange={props.onStructureChange}
                                            itemCount={rootItem?.items.count() || 0}
                                            panelId={props.parentItem?.id}
                                            isSubLayout={props.isSubLayout}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </RootDropZone >
            </>
        )
    }

    return (
        <>
            {props.isSubLayout ? renderCreator() : scrollWrapper(renderCreator())}
            {
                !parentItemType &&
                <BottomDock
                    items={ghostItems}
                    onItemSelectionChanged={onItemSelectionChanged}
                />
            }
        </>
    );
});

export { Container };

