import { ReadonlyRecord } from '@bimser/core';

import { List, Record } from 'immutable';
import { ActionHistoryField, ActionHistoryFieldFactoryFromJS, IActionHistoryField } from './IActionHistoryField';

interface IBaseActionHistory {
    date: string;
    historyId: number;
    type: string;
    creator: {
        id: number;
        fullName: string;
        userName: string;
    }
}

export interface IActionHistory extends IBaseActionHistory {
    fields: IActionHistoryField[];
}

export interface IReadonlyActionHistory extends IBaseActionHistory {
    fields: List<ActionHistoryField>;
}

export type ActionHistory = ReadonlyRecord<IReadonlyActionHistory>;

export const ActionHistoryFactory = Record<IReadonlyActionHistory>({
    date: null,
    historyId: null,
    type: null,
    creator: null,
    fields: List<ActionHistoryField>(),
});

export const ActionHistoryFactoryFromJS = function (data: IActionHistory) {
    return ActionHistoryFactory({
        ...data,
        fields: List(data?.fields.map(f => ActionHistoryFieldFactoryFromJS(f)) || []),
    });
}
