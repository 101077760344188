import { NormalizedLayoutStructureItems } from "../../../common/entities";
import { Map, List } from "immutable";

export const getItemByPath = (views: Map<string, NormalizedLayoutStructureItems>, activeView: string, path: List<string>) => {
    const view = views.get(activeView);
    let rootId = view.getIn(["result", 0]);

    let resultId: string;
    path.forEach((pathIndex) => {
        resultId = getItemChildByIndex(view, resultId ? resultId : rootId, pathIndex);
    });

    return resultId
};

const getItemChildByIndex = (view: NormalizedLayoutStructureItems, parentId: string, index: string): string => {
    return view.getIn(["entities", "items", parentId, "items", Number(index)]);
}