import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseProfession {
    id: number,
    professionCode: string,
    description: string,
    status: number,
    importStatus?: number,
    type: number,
    loadDetail: boolean
}

export interface IProfession extends IBaseProfession { }

export interface IReadonlyProfession extends IBaseProfession { }

export type Profession = ReadonlyRecord<IReadonlyProfession>;

export const ProfessionFactory = Record<IReadonlyProfession>({
    id: 0,
    professionCode: "",
    description: "",
    status: 0,
    importStatus: 0,
    type: 0,
    loadDetail: false
});

export const ProfessionFactoryFromJS = function (profession: IProfession) {
    return ProfessionFactory(profession);
}