import * as React from 'react';
import { BCInput, findMessage, IInputChangeEventArgs } from '@bimser/components';
import ISignerProps from "../entities/ISignerProps";
import * as Style from '../assets/style.scss';

const Signer = (props: ISignerProps) => {

    const onEmailChange = (args: IInputChangeEventArgs) => {
        props.onChangeData(props.signer.roleName, "email", args.data);
    }

    const onFullNameChange = (args: IInputChangeEventArgs) => {
        props.onChangeData(props.signer.roleName, "fullName", args.data);
    }

    return (
        <div className={Style.signerItem}>
            <span className={Style.signerLabel}>{props.signer.roleName}</span>
            <div className={Style.signerContent}>
                <BCInput placeHolder={findMessage.get("100625")} value={props.signer.email} onChange={onEmailChange} />
                <BCInput placeHolder={findMessage.get("100469")} value={props.signer.fullName} onChange={onFullNameChange} />
            </div>
        </div>
    )
}

export default Signer;