import Anchor from 'antd/lib/anchor';
import * as React from "react";
import * as Styles from './assets/style.scss';
import { IAnchorProps, IAnchorsItem, IAnchorState } from "./entities";
import classNames from 'classnames/bind';

const cx = classNames.bind(Styles);
export const Link = Anchor.Link;
export default class BCAnchor extends React.Component<IAnchorProps, IAnchorState> {
    constructor(props: IAnchorProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.state = { didClickedScroll: false }
        Styles;
    }

    onClick(e: React.MouseEvent<HTMLElement>, link: any): void {
        e.preventDefault();
        this.setState({ didClickedScroll: true });

        if (this.props.onClick)
            this.props.onClick(e, link);
    }

    getLinks(links: Array<IAnchorsItem>): any {
        if (!links || !links.length) return null;

        return links.map((link, i) => {
            let hasChildren = link.children && link.children.length > 0;
            if (hasChildren) {
                return (
                    <Link key={i} title={link.title} href={`#${link.href}`}>
                        {this.getLinks(link.children)}
                    </Link>
                )
            } else {
                return <Link key={i} title={link.title} href={`#${link.href}`} />;
            }
        })
    }

    render() {
        const { links, affix, showInkInFixed } = this.props;
        const anchorStyles = cx({
            activateBall: this.state.didClickedScroll,
            disabled: this.props.disabled
        });

        return (
            <Anchor
                ref={this.props.getRef}
                className={anchorStyles}
                affix={affix} showInkInFixed={showInkInFixed}
                onClick={this.onClick}
                getContainer={this.props.getContainer}
                getCurrentAnchor={this.props.getCurrentAnchor} >
                {this.getLinks(links)}
            </Anchor>
        );
    }
}

export { IAnchorsItem, IAnchorProps, IAnchorState };

