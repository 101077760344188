import { isNullOrUndefined, ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent } from "common/entities";
import { Record } from "immutable";
import { FlowHistory, FlowHistoryFactory, FlowHistoryFactoryFromJS, IFlowHistory } from "./IFlowHistory";

export interface IBaseFlowHistoryPanelContent extends IBaseFormContent {
    mode?: "left" | "right"
    showLoading?: boolean
}
export interface IFlowHistoryPanelContent extends IBaseFlowHistoryPanelContent {
    historyItem?: IFlowHistory,
    popoverItem?: IFlowHistory
}

export interface IReadonlyFlowHistoryPanelContent extends IBaseFlowHistoryPanelContent {
    historyItem?: FlowHistory,
    popoverItem?: FlowHistory
}

export type FlowHistoryPanelContent = ReadonlyRecord<IReadonlyFlowHistoryPanelContent>;

export const FlowHistoryPanelContentFactory = Record<IReadonlyFlowHistoryPanelContent>({
    panelId: '',
    mode: "left",
    historyItem: FlowHistoryFactory(),
    popoverItem: FlowHistoryFactory(),
    showLoading: true
});

export const FlowHistoryPanelContentFactoryFromJS = function (data: IFlowHistoryPanelContent) {
    if (isNullOrUndefined(data)) return FlowHistoryPanelContentFactory();

    return FlowHistoryPanelContentFactory({
        panelId: data.panelId,
        mode: data.mode,
        showLoading: data.showLoading,
        historyItem: data.historyItem && FlowHistoryFactoryFromJS(data.historyItem),
        popoverItem: data.popoverItem && FlowHistoryFactoryFromJS(data.popoverItem)
    });
}