import * as React from "react";
import { IBadgeProps } from '@bimser/core';
import Badge from 'antd/lib/badge';
import * as Styles from './assets/badgeStyles.scss';

export default class BCBadge extends React.Component<IBadgeProps, {}> {

    style: React.CSSProperties = {};

    constructor(props: IBadgeProps) {
        super(props);

        this.style = {
            ...this.props.style
        }
    }

    render() {
        const { title, count, dot, offset, overflowCount, showZero, status, text, children } = this.props;

        return (
            <Badge
                title={title}
                count={count}
                dot={dot}
                offset={offset}
                overflowCount={overflowCount}
                showZero={showZero}
                status={status}
                text={text}
                className={Styles.badge}
                style={this.style}
            >
                {children}
            </Badge >
        );
    }
}

export { IBadgeProps };
