import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { List, Map, Record } from 'immutable';
import { Filter, FilterFactory, FilterFactoryFromJS, IFilter } from './IFilter';
import { ILoadedItem, LoadedItem, LoadedItemFactoryFromJS } from './ILoadedItem';
import { INavigationHistoryItem, NavigationHistoryItem, NavigationHistoryItemFactory, NavigationHistoryItemFactoryFromJS } from './INavigationHistoryItem';
import { INavigationItem, NavigationItem, NavigationItemFactory, NavigationItemFactoryFromJS } from './INavigationItem';

interface IBaseDMExplorerState {
    isLoading: boolean,
    isOpened: boolean
}

export interface IDMExplorerState extends IBaseDMExplorerState {
    itemsData: IDictionary<ILoadedItem>
    currentLocation: INavigationHistoryItem,
    selectedItem: INavigationItem,
    filter: IFilter,
    navigationHistory: Array<INavigationHistoryItem>,
}

export interface IReadonlyDMExplorerState extends IBaseDMExplorerState {
    itemsData: Map<string, LoadedItem>,
    currentLocation: NavigationHistoryItem,
    selectedItem: NavigationItem,
    filter: Filter,
    navigationHistory: List<NavigationHistoryItem>,
}

export type DMExplorerState = ReadonlyRecord<IReadonlyDMExplorerState>;

export const DMExplorerStateFactory = Record<IReadonlyDMExplorerState>({
    itemsData: Map(),
    currentLocation: NavigationHistoryItemFactory({ id: '#Root', secretKey: null, name: "" }),
    selectedItem: NavigationItemFactory(),
    filter: FilterFactory(),
    navigationHistory: List([NavigationHistoryItemFactoryFromJS({ id: '#Root', secretKey: null, name: null })]),
    isLoading: true,
    isOpened: false
});

export const DMExplorerStateFactoryFromJS = function (data: IDMExplorerState) {
    let _itemsData: IDictionary<LoadedItem> = Object.keys(data.itemsData).reduce(function (previous: IDictionary<LoadedItem>, current) {
        previous[current] = LoadedItemFactoryFromJS(data.itemsData[current])
        return previous;
    }, {});

    let _navHis = data.navigationHistory && data.navigationHistory.map(n => NavigationHistoryItemFactoryFromJS(n));

    return DMExplorerStateFactory({
        isLoading: data.isLoading,
        itemsData: Map(_itemsData),
        selectedItem: NavigationItemFactoryFromJS(data.selectedItem),
        filter: FilterFactoryFromJS(data.filter),
        navigationHistory: List(_navHis)
    });
}