import { select } from 'redux-saga/effects';
import { IDictionary, ILanguage } from '@bimser/core';
import { BaseState } from '../../../..';

export default function* (filename: string) {
    let state: BaseState = yield select();
    let languages: ILanguage[] = state.system.language.items.toJS();
    let _name: IDictionary<string> = {};
    languages.forEach(c => {
        _name[c.culture] = filename.replace("\"", "").replace("'", "");
    });
    return _name;
}