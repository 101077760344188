import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IModelChangeAction {
    orginalActionKey: string,
    windowId?: string,
    payload: any
}

export interface IReadonlyBaseModelChangeAction {
    orginalActionKey: string,
    windowId?: string,
    payload: any
}

export type ModelChangeAction = ReadonlyRecord<IReadonlyBaseModelChangeAction>;

export const ModelChangeActionFactory = Record<IReadonlyBaseModelChangeAction>({
    orginalActionKey: null,
    windowId: null,
    payload: null
});

export const ModelChangeActionFactoryFromJS = function (data: IModelChangeAction) {
    return ModelChangeActionFactory({
        orginalActionKey: data.orginalActionKey,
        windowId: data.windowId,
        payload: data.payload
    });
}