import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, List, Map } from "immutable";
import { IModalState, ModalState, ModalStateFactoryFromJS } from "./IModalState";
import { IModalFrameState, ModalFrameState, ModalFrameStateFactoryFromJS, ModalFrameStateFactory } from "../../ModalFrameManager/entities";

export interface IModalManagerState {
    data: IDictionary<any>,
    items: Array<IModalState>,
    modalFrame: IModalFrameState
}

export interface IReadonlyModalManagerState {
    data: Map<string, any>,
    items: List<ModalState>,
    modalFrame: ModalFrameState,
}

export type ModalManagerState = ReadonlyRecord<IReadonlyModalManagerState>;

export const ModalManagerStateFactory = Record<IReadonlyModalManagerState>({
    data: Map(),
    items: List<ModalState>(),
    modalFrame: ModalFrameStateFactory()
});

export const ModalManagerStateFactoryFromJS = function (data: IModalManagerState) {
    let items: Array<ModalState> = data.items && data.items.length ? data.items.map((value: IModalState) => {
        return ModalStateFactoryFromJS(value);
    }) : [];

    return ModalManagerStateFactory({
        data: Map(data.data),
        items: List(items),
        modalFrame: ModalFrameStateFactoryFromJS(data.modalFrame),
    });
}