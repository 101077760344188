import { IBaseAction } from "@bimser/core";
import { SystemState, SystemStateFactory } from "../entities";
import LanguageReducer from "../language/reducers";
import CultureReducer from "../culture/reducers";
import LocationReducer from "../location/reducers";
import VersionReducer from "../version/reducers";
import TenantReducer from "../tenant/reducers"
import PasswordRequirementsReducer from "../passwordRequirements/reducers"
import LoginSliderReducer from '../loginSlider/reducer/loginSliderReducer';
import MobileAppReducers from "../mobile/reducers";
import eBA6Reducers from "../eba6/reducers";


export default function (state: SystemState = SystemStateFactory(), action: IBaseAction<any>): SystemState {
    return state.withMutations((state) => {
        state
            .set("language", LanguageReducer(state.language, action))
            .set("culture", CultureReducer(state.culture, action))
            .set("location", LocationReducer(state.location, action))
            .set("version", VersionReducer(state.version, action))
            .set("tenant", TenantReducer(state.tenant, action))
            .set("passwordRequirements", PasswordRequirementsReducer(state.passwordRequirements, action))
            .set("loginSliderItems", LoginSliderReducer(state.loginSliderItems, action))
            .set("mobileApp", MobileAppReducers(state.mobileApp, action))
            .set("eBA6Enabled", eBA6Reducers(state.eBA6Enabled, action))
    });
}
