import { GetIconComponent } from "@bimser/icons";
import { InputRef } from "antd";
import * as React from "react";
import { ColorResult, SketchPicker } from 'react-color';
import { fromRatio } from 'tinycolor2';
import BCInput from "../BCInput";
import * as Styles from './assets/colorPickerStyles.scss';
import ColorPickerPortal from "./components/ColorPickerPortal";
import { IClient, IColorPickerProps } from './entities';
import classNames from "classnames/bind";
const cx = classNames.bind(Styles);

const presetColors: string[] = ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'];

export default function BCColorPicker(props: IColorPickerProps) {

    const [color, setColor] = React.useState<string>(props.color || "rgb(255, 255, 255)");
    const [displayColorPicker, setDisplayColorPicker] = React.useState<boolean>(false);
    const [client, setClient] = React.useState<IClient>({ x: 0, y: 0 });

    const colorInputRef = React.useRef<InputRef>();
    const colorDivRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        setColor(props.color || "rgb(255, 255, 255)");
    }, [props.color]);

    function getPositionByClickedPosition(y: number): "bottom" | "top" {

        const windowHeight = window.innerHeight, horizontalSlice = windowHeight / 2;
        const topSide = y < horizontalSlice, bottomSide = y > horizontalSlice;

        if (topSide)
            return 'bottom';
        else if (bottomSide)
            return 'top';
    }

    function getPalettePosition(e: React.MouseEvent<HTMLDivElement, MouseEvent>, position: 'bottom' | 'top') {

        const pickerHeight = 32, paddingOffset = 10, paletteHeight = props.onlyPreset ? 160 : 307;
        const client = colorInputRef.current?.input.getBoundingClientRect() ?? colorDivRef.current?.getBoundingClientRect();

        switch (position) {
            case 'bottom': {
                return {
                    x: client.x - 32,
                    y: e.clientY - e.nativeEvent.offsetY + pickerHeight
                }
            }
            case 'top': {
                return {
                    x: client.x - 32,
                    y: e.clientY - e.nativeEvent.offsetY - paletteHeight - paddingOffset
                }
            }
            default: {
                return {
                    x: 0,
                    y: 0
                }
            }
        }
    }

    function getPaletteStyles(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const position = getPositionByClickedPosition(e.clientY);
        return getPalettePosition(e, position);
    }

    function onHandleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        setDisplayColorPicker(!props.disabled && !displayColorPicker);
        setClient(getPaletteStyles(e));

        if (props.onOpen) props.onOpen(fromRatio(color).toRgbString());
    }

    function onHandleClose() {
        setDisplayColorPicker(false);
        if (props.onClose) props.onClose(color);
    }

    function onHandleChange(color: ColorResult) {
        setColor(fromRatio(color.hsl).toRgbString());
    }

    function onChangeComplete(color: ColorResult) {
        if (props.onChange) props.onChange(fromRatio(color.hsl).toRgbString());
    }

    function renderPickerFragment() {
        if (displayColorPicker)
            return (
                <div className={cx({ "picker-container": true, "only-preset": props.onlyPreset })}>
                    <div className={Styles.cover} onClick={onHandleClose} />
                    <SketchPicker
                        color={color}
                        onChange={onHandleChange}
                        disableAlpha={props.disableAlpha}
                        onChangeComplete={onChangeComplete}
                        presetColors={props.presetColors || presetColors}
                    />
                </div>
            )
    }

    function renderPickerWithIcon() {
        return (
            <div ref={colorDivRef} className={Styles.colorPickerIcon} onClick={onHandleClick}>
                {GetIconComponent(props.icon)}
            </div>
        )
    }

    function getPickerPrefix() {
        return <div style={{ backgroundColor: color }} className={Styles.colorPickerPreview} onClick={onHandleClick} />
    }

    function renderPickerWithoutIcon() {
        return (
            <div className={Styles.colorPickerInput}>
                <BCInput
                    onClick={onHandleClick}
                    ref={colorInputRef}
                    size={props.size || 'middle'}
                    prefix={getPickerPrefix()}
                    value={props.displayColor || color}
                    style={props.style}
                    readOnly
                    disabled={props.disabled}
                />
            </div>
        )
    }

    function renderPicker() {
        if (props.icon) return renderPickerWithIcon();
        return renderPickerWithoutIcon();
    }

    return (
        <>
            {renderPicker()}
            <ColorPickerPortal client={client}>
                {renderPickerFragment()}
            </ColorPickerPortal>
        </>
    )
}

export { IColorPickerProps, fromRatio };