import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { BaseValueAssignActionFactoryObject, BaseValueAssignActionFactoryObjectFromJS, IBaseValueAssignAction, ReadOnlyBaseValueAssignAction } from "./IBaseValueAssignAction";

export interface IValueAssignActionWithFormula extends IBaseValueAssignAction {
    value: string
}

export interface ReadOnlyValueAssignActionWithFormula extends ReadOnlyBaseValueAssignAction {
    value: string
}

export type ValueAssignActionWithFormula = ReadonlyRecord<ReadOnlyValueAssignActionWithFormula>;

export const ValueAssignActionWithFormulaFactory = Record<ReadOnlyValueAssignActionWithFormula>({
    value: null,
    ...BaseValueAssignActionFactoryObject
});

export const ValueAssignActionWithFormulaFactoryFromJS = function (data: IValueAssignActionWithFormula) {
    return ValueAssignActionWithFormulaFactory({
        ...BaseValueAssignActionFactoryObjectFromJS(data),
        value: data.value,
    });
}