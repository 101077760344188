import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IRecentAppsState, RecentAppsState, RecentAppsStateFactory, RecentAppsStateFactoryFromJS } from "./RecentAppsState";
import { CountTilesState, CountTilesStateFactory, CountTilesStateFactoryFromJS, ICountTilesState } from "./CountTilesState";
import { IRecentFilesState, RecentFilesState, RecentFilesStateFactory, RecentFilesStateFactoryFromJS } from "./RecentFilesState";
import { IAnnouncementsWidgetState, AnnouncementsWidgetState, AnnouncementsWidgetStateFactory, AnnouncementsWidgetStateFactoryFromJS } from "./AnnouncementsState";
import { IProcessesState, ProcessesState, ProcessesStateFactory, ProcessesStateFactoryFromJS } from "./ProcessesState";
import { IProcessDetailsState, ProcessDetailsState, ProcessDetailsStateFactory, ProcessDetailsStateFactoryFromJS } from "./IProcessDetailsState";

export interface IWidgetState {
    recentApps: IRecentAppsState,
    countTiles: ICountTilesState,
    recentFiles: IRecentFilesState,
    announcements: IAnnouncementsWidgetState,
    processes: IProcessesState,
    processDetails: IProcessDetailsState,
    refreshDashboard: boolean
}

export interface IReadonlyWidgetState {
    recentApps: RecentAppsState,
    countTiles: CountTilesState,
    recentFiles: RecentFilesState,
    announcements: AnnouncementsWidgetState,
    processes: ProcessesState,
    processDetails: ProcessDetailsState,
    refreshDashboard: boolean
}

export type WidgetState = ReadonlyRecord<IReadonlyWidgetState>;

export const WidgetStateFactory = Record<IReadonlyWidgetState>({
    recentApps: RecentAppsStateFactory(),
    countTiles: CountTilesStateFactory(),
    recentFiles: RecentFilesStateFactory(),
    announcements: AnnouncementsWidgetStateFactory(),
    processes: ProcessesStateFactory(),
    processDetails: ProcessDetailsStateFactory(),
    refreshDashboard: false
});

export const WidgetStateFactoryFromJS = function (data: IWidgetState) {
    return WidgetStateFactory({
        ...data,
        recentApps: RecentAppsStateFactoryFromJS(data.recentApps),
        countTiles: CountTilesStateFactoryFromJS(data.countTiles),
        recentFiles: RecentFilesStateFactoryFromJS(data.recentFiles),
        announcements: AnnouncementsWidgetStateFactoryFromJS(data.announcements),
        processes: ProcessesStateFactoryFromJS(data.processes),
        processDetails: ProcessDetailsStateFactoryFromJS(data.processDetails),
    })
}