import { IGenerateStyles } from "../entities/IGenerateStyles";
import { ColorFormats, fromRatio } from "tinycolor2";

const hueStep: number = 2;
const saturationStep: number = 16;
const saturationStep2: number = 5;
const brightnessStep1: number = 5;
const brightnessStep2: number = 15;
const lightColorCount: number = 5;
const darkColorCount: number = 4;

const generateStyles = ({ primaryColor, fontFamily, goldenColor, secondaryIconColor, backgroundColor, backgroundImage }: IGenerateStyles) => {

    const getHue = (hsv: ColorFormats.HSVA, i: number, isLight: boolean): number => {
        let hue;
        if (hsv.h >= 60 && hsv.h <= 240) {
            hue = isLight ? hsv.h - hueStep * i : hsv.h + hueStep * i;
        } else {
            hue = isLight ? hsv.h + hueStep * i : hsv.h - hueStep * i;
        }
        if (hue < 0) {
            hue += 360;
        } else if (hue >= 360) {
            hue -= 360;
        }
        return Math.round(hue);
    }

    const getSaturation = (hsv: ColorFormats.HSVA, i: number, isLight: boolean): number => {
        let saturation;
        if (isLight) {
            saturation = Math.round(hsv.s * 100) - saturationStep * i;
        } else if (i == darkColorCount) {
            saturation = Math.round(hsv.s * 100) + saturationStep;
        } else {
            saturation = Math.round(hsv.s * 100) + saturationStep2 * i;
        }
        if (saturation > 100) {
            saturation = 100;
        }
        if (isLight && i === lightColorCount && saturation > 10) {
            saturation = 10;
        }
        if (saturation < 6) {
            saturation = 6;
        }
        return Math.round(saturation);
    }

    const getValue = (hsv: ColorFormats.HSVA, i: number, isLight: boolean): number => {
        if (isLight) {
            return Math.round(hsv.v * 100) + brightnessStep1 * i;
        }
        return Math.round(hsv.v * 100) - brightnessStep2 * i;
    }

    const colorPalette = (color: string, index: number): string => {
        let isLight = index <= 6;
        let hsv: ColorFormats.HSVA = fromRatio(color).toHsv();
        let i = isLight ? lightColorCount + 1 - index : index - lightColorCount - 1;
        return fromRatio({
            h: getHue(hsv, i, isLight),
            s: getSaturation(hsv, i, isLight),
            v: getValue(hsv, i, isLight)
        }).toHexString();
    }

    const colorTransparency = (color: string, index: number): string => {
        return fromRatio(color).setAlpha(index * 10 / 100).toRgbString();
    }

    const generatePrimaryColor = () => {
        let primaryColorString = '';
        for (let i = 1; i <= 10; i++) {
            let color = colorPalette(primaryColor, i);
            if (i === 6) color = primaryColor;
            primaryColorString += '--primary-color-' + i + ': ' + color + ';\n';
        }

        primaryColorString += '--primary-color: ' + primaryColor + ';\n';

        return primaryColorString
    }


    const generateBackground = () => {
        let backgroundString = '';
        backgroundString += '--theme-color: ' + backgroundColor + ';\n';
        backgroundString += '--theme-image: url(./images/' + backgroundImage + '.png);\n';
        return backgroundString
    }

    const generatePrimaryColorTransparency = () => {
        let primaryColorString = '';
        for (let i = 1; i <= 9; i++) {
            let color = colorTransparency(primaryColor, i);
            primaryColorString += '--primary-color-o-' + i + ': ' + color + ';\n';
        }

        return primaryColorString
    }

    const getGoldenActionColor = () => {
        let goldenActionColorString = '';
        goldenActionColorString += '--golden-color: ' + colorPalette(goldenColor, 6) + ';\n';
        goldenActionColorString += '--golden-color-l-10: ' + colorPalette(goldenColor, 5) + ';\n';
        return goldenActionColorString;
    }

    const getFontFamily = () => {
        return '--font-family: ' + fontFamily + ', sans-serif;\n';
    }

    const getSecondaryIconColor = () => {
        return '--icon-secondary-color: ' + secondaryIconColor + ';\n';
    }

    return ':root{\n' + generateBackground() + generatePrimaryColor() + generatePrimaryColorTransparency() + getGoldenActionColor() + getFontFamily() + getSecondaryIconColor() + '}';
}

export { generateStyles }