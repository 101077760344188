
import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { IDmVersion, DmVersion, DmVersionFactoryFromJS } from './IDmVersion';

interface IBaseViewerPanelContent {
    isLoading: boolean;
    isDisabled: boolean;
    selectedVersion: string;
    childPanelGuid?: string;
}

export interface IViewerPanelContent extends IBaseViewerPanelContent {
    versions: IDmVersion[];
}

export interface IReadonlyViewerPanelContent extends IBaseViewerPanelContent {
    versions: List<DmVersion>;
}

export type ViewerPanelContent = ReadonlyRecord<IReadonlyViewerPanelContent>;

export const ViewerPanelContentFactory = Record<IReadonlyViewerPanelContent>({
    isLoading: false,
    versions: List(),
    selectedVersion: null,
    childPanelGuid: null,
    isDisabled: false
});

export const ViewerPanelContentFactoryFromJS = function (data: IViewerPanelContent) {
    return ViewerPanelContentFactory({
        ...data,
        versions: List(data.versions?.map(v => DmVersionFactoryFromJS(v)) || [])
    });
}