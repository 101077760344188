import { FormItemTypes } from "@bimser/core";
import classNames from 'classnames/bind';
import React from "react";
import * as Styles from "./assets/styles.scss";
import { IComponent } from "./entities";

const cx = classNames.bind(Styles);

const Component = (args: IComponent) => {
  const { data, controlRenderManager, parentItemType } = args;

  const componentClassNames: string = cx({
    component: true,
    [args.paddingType]: true,
    isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup
  });

  return (
    <div className={componentClassNames}>
      {controlRenderManager(data.id)}
    </div>
  );
};

export default React.memo(Component);