import { createSyncAction } from "@bimser/core";
import { List } from 'immutable';
import { Culture, CultureFactoryFromJS, ICulture } from "../entities";
import * as ActionTypes from './actionTypes';

export default function setCultureAction(data: Array<ICulture>) {
    let _cultures : Array<Culture> = data && data.length ? data.map((value: ICulture, index: number) => {
        return CultureFactoryFromJS({
            name: value.name,
            displayName: value.displayName,
        });
    }) : [];

    let payload = List(_cultures)

    let action = createSyncAction(ActionTypes.SET_CULTURE_ACTION, payload);

    return action;
}