import Drawer from 'antd/lib/drawer';
import * as React from "react";
import { IDrawerProps } from './entities';
import * as Style from './assets/style.scss';
import { isNullOrUndefined } from "@bimser/core";

export default class BCDrawer extends React.Component<IDrawerProps, {}> {

    constructor(props: IDrawerProps) {
        super(props);
        this.renderTitle = this.renderTitle.bind(this);
    }

    renderTitle() {
        if (this.props.renderTitle) {
            return this.props.renderTitle(this.props.title);
        } else if (isNullOrUndefined(this.props.title)) {
            return;
        }
        return (
            <div className={Style.drawerTitle} title={this.props.title} >{this.props.title}</div>
        )
    }

    render() {
        const _props = { ...this.props }
        _props.renderTitle = undefined;

        return (
            <Drawer
                {..._props}
                className={[Style.BCDrawer, this.props.className].join(" ")}
                title={this.renderTitle()}
                contentWrapperStyle={this.props.contentWrapperStyle}
            />
        );
    }
}

export { IDrawerProps };
