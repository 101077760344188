import IconMore from "@bimser/icons/16/more2";
import * as React from "react";
import { AutoCompleteProperty } from ".";
import BCButton from '../../../BCButton';
import BCInput from '../../../BCInput';
import BCInputGroup from '../../../BCInputGroup';
import * as Styles from '../../assets/style.scss';
import { IPropertyComponentProps, IPropertyTypes } from '../../entities';

const inputStyle: React.CSSProperties = { width: 'calc(100% - 35px)' };
const buttonStyle: React.CSSProperties = { width: '35px', fontWeight: 900, fontSize: '14px' };

export default function CollectionProperty(props: IPropertyComponentProps) {
    
    const onButtonClick = () => {
        const collectionName = props.item.externalData && props.item.externalData.openCollectionName;
        props.fireAction?.(props.item, "PROPERTY_INSPECTOR.ON_COLLECTION_OPEN", collectionName);
    }

    const renderInput = () => {
        let type: IPropertyTypes = props.item.externalData?.inputType || null;
        if (type === IPropertyTypes.AutoComplete) {
            return (
                <div style={inputStyle}
                    className={Styles.groupCombobox}>
                    <AutoCompleteProperty
                        item={props.item}
                        onValueChanged={props.onValueChanged}
                        value={props.item.placeholder || props.value}
                    />
                </div>
            )
        } else {
            return (
                <BCInput
                    style={inputStyle}
                    readOnly
                    disabled={props.item.disabled}
                    value={props.item.placeholder != undefined ? props.item.placeholder : props.value}
                />
            )
        }
    }

    return (
        <BCInputGroup compact>
            {renderInput()}
            <BCButton
                disabled={props.item.disabled}
                style={buttonStyle}
                onClick={onButtonClick} icon={<IconMore />} />
        </BCInputGroup>
    )
}