import { createSyncAction } from "@bimser/core";
import * as ActionTypes from './actionTypes';
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "../entities/IWebInterfaceAction";

export default function panelWillUnMountTransaction(data: IWebInterfaceAction) {
    if (!data) return undefined;
    let payload = WebInterfaceActionFactoryFromJS(data);
    let action = createSyncAction(ActionTypes.PANEL_WILL_UNMOUNT_TRANSACTION, payload);

    return action;
}