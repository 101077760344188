import { IDictionary, isNullOrUndefined } from '@bimser/core';
import IconClosePanel from "@bimser/icons/16/close-panel";
import Modal from 'antd/lib/modal';
import * as React from "react";
import { BCButton, findMessage } from "..";
import BCInputSearch from '../BCInputSearch';
import BCLoading from "../BCLoading";
import BCScrollbars from "../BCScrollbars";
import * as Styles from './assets/styles.scss';
import { IModalProps, IModalStickyBar } from './entities';
import { BootstrapWidthType, default as ISplitModalProps } from "./entities/ISplitModalProps";
import BCSplitModal from "./SplitModal";

export default class BCModal extends React.Component<IModalProps, { windowWidth: number, windowHeight: number, bodyWidth?: string }> {

    stickyBarHeights: IDictionary<number> = {
        top: 0,
        bottom: 0
    };

    constructor(props: IModalProps) {
        super(props);
        this.calcModalWidth = this.calcModalWidth.bind(this);
        this.renderStickySearchBar = this.renderStickySearchBar.bind(this);

        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        };

        Styles;
    }

    updateDimensions = () => {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.setState({ bodyWidth: document.body.style.width.valueOf() }, () => {
            document.body.style.width = "100%";
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        if (this.state.bodyWidth?.valueOf?.()) {
            document.body.style.width = this.state.bodyWidth.valueOf();
        }
    }

    renderStickySearchBar(stickyBar: IModalStickyBar) {

        if (stickyBar.searchBar?.onSearch) {
            return (
                <BCInputSearch
                    placeHolder={findMessage.get('100002')}
                    size={'middle'}
                    onChange={(v) => { stickyBar.searchBar.onSearch(v.data); }}
                />
            )
        } else {
            return stickyBar.content;
        }
    }

    renderModalStickyBar(position: string) {

        if (!this.props.stickyBars) return null;
        let stickyBar = this.props.stickyBars[position];
        if (!stickyBar) return null;

        if (stickyBar.customStyles?.height) {
            this.stickyBarHeights[position] = (parseInt((stickyBar.customStyles.height as string).replace('px', '')) as number) + 10;
        } else {
            this.stickyBarHeights[position] = 50;
        }

        return (
            <div className={Styles.modalStickyBar} style={{ ...stickyBar.customStyles }} key={position}>
                {this.renderStickySearchBar(stickyBar)}
            </div>
        )

    }

    wrapContentWithScrollbars = () => {

        const headerHeight = 61, footerHeight = 62;
        const { top, bottom } = this.stickyBarHeights;
        const contentHeight = this.state.windowHeight - headerHeight - footerHeight - top - bottom;

        return (
            <BCScrollbars
                styles={{ width: 'auto' }}
                autoHide={true}
                autoHeightMin={0}
                autoHeightMax={Math.max(contentHeight, 200)}
                autoHeight={true}>
                <div className={Styles.modalContentWrapper + " modalContentWrapper" + " " + (this.props.modalContentWrapperCssClass || "")}>
                    {
                        this.props.children
                    }
                </div>
            </BCScrollbars>
        )
    }

    calcModalWidth(): string | number {

        let width = this.props.width;

        if (width) {
            if (width === 'small') {
                return 400
            } else if (width === 'default') {
                return 520
            } else if (width === 'large') {
                return '70%'
            } else {
                return width
            }
        }
    }

    renderHeader() {

        const DefaultTitle = <span className={'modal-title-text'} title={this.props.title}>{this.props.title}</span>;

        if (this.props.renderHeader) return this.props.renderHeader(DefaultTitle);

        return (
            <React.Fragment>
                {DefaultTitle}
                {this.props.headerButtons?.length ? <div className={Styles.modalHeaderControls}>
                    {this.props.headerButtons.map((buttonProps, index) => <BCButton {...buttonProps} key={index} />)}
                </div> : null}
            </React.Fragment>
        )

    }

    render() {

        let {
            disableScroll,
            visible,
            afterClose,
            footer,
            handleOk,
            zIndex,
            closable,
            wrapClassName,
            maskClosable = false,
            loading,
            children,
            className,
            okText,
            okType,
            cancelText,
            okButtonProps,
            cancelButtonProps,
            destroyOnClose,
            handleCancel,
            bodyStyle,
            keyboard
        } = this.props;
        let modalClassName = this.props.width ? '' : 'modalClass';
        return (
            <Modal
                okText={okText || findMessage.get('100004')}
                okType={okType || 'primary'}
                cancelText={cancelText || findMessage.get('100034')}
                okButtonProps={{ ...okButtonProps, size: 'middle' }}
                cancelButtonProps={{ ...cancelButtonProps, type: 'ghost', size: 'middle' }}
                destroyOnClose={destroyOnClose}
                transitionName={null}
                className={[className, 'modalId_' + this.props.id, modalClassName].join(' ')}
                centered
                visible={visible}
                title={this.renderHeader()}
                {...(afterClose ? { afterClose } : {})}
                {...(handleOk ? { onOk: handleOk } : {})}
                {...(handleCancel ? { onCancel: handleCancel } : {})}
                width={this.calcModalWidth()}
                zIndex={zIndex}
                closable={closable}
                wrapClassName={wrapClassName}
                maskClosable={maskClosable}
                mask={true}
                keyboard={isNullOrUndefined(keyboard) ? true : keyboard}
                footer={footer}
                closeIcon={<IconClosePanel />}
                bodyStyle={bodyStyle}
            >
                <BCLoading show={loading} delay={300}>
                    {this.renderModalStickyBar('top')}
                    {
                        disableScroll ? this.props.children : this.wrapContentWithScrollbars()
                    }
                    {this.renderModalStickyBar('bottom')}
                </BCLoading>
            </Modal>
        );
    }
}

export { IModalProps, IModalStickyBar, BCSplitModal, BootstrapWidthType, ISplitModalProps };

