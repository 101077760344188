import * as Moment from 'moment';
import * as React from "react";
import { isNullOrUndefined } from '@bimser/core';
import BCTimePicker from '../../../BCTimePicker';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defaultFormat = "HH:mm:ss";
const defaultStyle: React.CSSProperties = { width: '100%' };

export default function TimePickerProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (moment: any, timeString: any) => {
        props.customChangeEvent ? props.customChangeEvent(timeString) : replaceValue(props.item, timeString)
    }

    const calculateFormat = () => {
        const options = props.item.options;
        if (options) {
            if (typeof options === "string") {
                return options;
            }
            const { use12Hours, format } = options;
            let existFormat = format || defaultFormat;
            if (use12Hours === true && existFormat.includes("HH")) {
                return existFormat.replace("HH", "hh");
            }
            else if (use12Hours === false && existFormat.includes("hh")) {
                return existFormat.replace("hh", "HH");
            }
            return existFormat;
        }
        return defaultFormat;
    }

    const disableHours = () => {
        let disableHours: Array<number> = new Array();
        if (!isNullOrUndefined(props.item.options?.minHour)) {
            for (let i = 0; i <= Number(props.item.options.minHour); i++) {
                disableHours.push(i);
            }
        }
        return disableHours;
    }

    const disableMinutes = () => {
        let disableMinutes: Array<number> = new Array();
        if (!isNullOrUndefined(props.item.options?.minMinute)) {
            for (let i = 0; i <= Number(props.item.options.minHour); i++) {
                disableMinutes.push(i);
            }
        }
        return disableMinutes;
    }

    const currentFormat = calculateFormat();
    const allowClear = props.item.options?.allowClear;

    return (
        <BCTimePicker
            value={!isNullOrUndefined(props.value) && props.value != "" ? Moment(props.value) : null}
            placeholder={props.item.label}
            onChange={onChange}
            use12Hours={props.item.options ? props.item.options.use12Hours : false}
            style={props.style || defaultStyle}
            disabled={props.item.disabled || props.item.readOnly}
            allowClear={!isNullOrUndefined(allowClear) ? allowClear : true}
            format={currentFormat}
            disabledHours={disableHours}
            disabledMinutes={disableMinutes}
        />
    )
}