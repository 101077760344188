import React, { lazy, Suspense } from 'react';
import IAnnouncementDetailDialogProps from "./entities/props/IAnnouncementDetailDialogProps";

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-others" */ "./containers/AnnouncementDetailDialogContainer")
    .then(({ default: container }) => {
        import(/* webpackChunkName: "web-others" */ "./injector");
        return { default: container };
    }));

function Container(props: React.PropsWithChildren<IAnnouncementDetailDialogProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;