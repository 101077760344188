import React, { lazy, Suspense } from 'react';
import { ILogViewerProps } from "./entities"

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-management" */ "./containers").then(({ default: container }) => {
    import(/* webpackChunkName: "web-management" */ "./injector");
    return { default: container };
}));

function Container(props: React.PropsWithChildren<ILogViewerProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;