import IBreadCrumbNavigationProps from "./IBreadCrumbNavigationProps";
import IBreadCrumbBaseClickEventArgs from "./IBreadCrumbBaseClickEventArgs";

export enum NavigationType {
    Home,
    Left,
    Right,
}

export default interface IBreadCrumbNavigationClickEventArgs extends IBreadCrumbBaseClickEventArgs {
    navigationType: NavigationType,
}