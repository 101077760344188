import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import ActiveViewType from "./ActiveViewType";
import { Action, ActionFactoryFromJS, IAction } from "./IAction";
import { ActionWithState, ActionWithStateFactoryFromJS, IActionWithState } from "./IActionWithState";


interface IBaseActionManagementState {
    panelId: string,
    isLoading?: boolean
    activeView?: ActiveViewType
}

export interface IActionManagementState extends IBaseActionManagementState {
    items: IAction[],
    actionStates: IActionWithState[],
    collapsedBoards?: number[],
    hiddenBoards?: number[],
    filter?: IDictionary<any>
}

export interface IReadonlyActionManagementState extends IBaseActionManagementState {
    items: List<Action>,
    actionStates: List<ActionWithState>,
    collapsedBoards: List<number>,
    hiddenBoards: List<number>,
    filter: Map<string, any>
}

export type ActionManagementState = ReadonlyRecord<IReadonlyActionManagementState>;

export const ActionManagementStateFactory = Record<IReadonlyActionManagementState>({
    panelId: null,
    isLoading: false,
    items: List<Action>(),
    actionStates: List<ActionWithState>(),
    collapsedBoards: List<number>(),
    hiddenBoards: List<number>(),
    activeView: 'card',
    filter: Map<string, any>()
});

export const ActionManagementStateFactoryFromJS = function (data: IActionManagementState) {
    return ActionManagementStateFactory({
        ...data,
        items: List<Action>(data?.items.map(item => ActionFactoryFromJS(item)) || []),
        actionStates: List<ActionWithState>(data?.actionStates.map(item => ActionWithStateFactoryFromJS(item)) || []),
        collapsedBoards: List<number>(data?.collapsedBoards || []),
        hiddenBoards: List<number>(data?.hiddenBoards || []),
        filter: Map(data.filter || {})
    });
}