import Switch from 'antd/lib/switch';
import * as React from "react";
import * as Styles from './assets/styles.scss';
import { ISwitchProps } from './entities';

const BCSwitch = (props: ISwitchProps) => {
    const [checked, setChecked] = React.useState(props.checked);

    Styles;

    React.useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const onChange = (checked: boolean, event?: React.MouseEvent<HTMLButtonElement>) => {
        if (!props.readOnly) {
            setChecked(checked);
            if (props.onChange)
                props.onChange(checked, event);
        }
        else {
            setChecked(!checked);
        }
    }

    return (
        <Switch
            autoFocus={props.autoFocus}
            onChange={onChange}
            disabled={props.disabled}
            size={props.size}
            checked={checked}
            checkedChildren={props.checkedChildren || 'I'}
            unCheckedChildren={props.unCheckedChildren || 'O'}
            style={props.style}
            className={props.className}
        />
    );
};

export default BCSwitch

export { ISwitchProps };
