import * as React from "react";
import { BCButton, findMessage } from "..";
import * as Styles from './assets/splitModalStyles.scss';
import { ISplitModalProps } from './entities';

export default class BCSplitModal extends React.Component<ISplitModalProps, any> {
    public static defaultProps: Partial<ISplitModalProps> = {
        leftSideWidth: "6",
        rightSideWidth: "6",
    }
    constructor(props: ISplitModalProps) {
        super(props);
    }
    renderLeftSide() {
        if (this.props.renderLeftSide) {
            return this.props.renderLeftSide();
        }
    }
    renderRightSide() {
        if (this.props.renderRightSide) {
            return this.props.renderRightSide();
        }
    }
    renderHeader() {
        if (this.props.renderHeader) {
            return <div className={'row no-gutters'}>
                <div className={'col-md-12' + " " + Styles.leftSideHeader}>
                    <div className={Styles.modalHeader}>
                        {this.props.renderHeader()}
                    </div>
                </div>
            </div>
        }
        return null;
    }
    render() {
        return (
            <div className={'row' + " " + (this.props.cssClassName || "")}>
                <div className={'col-md-12'}>
                    {this.renderHeader()}
                    <div className={'row no-gutters'}>
                        <div className={'col-md-' + this.props.leftSideWidth + " " + Styles.leftSide + (this.props.leftSideClassName ? " " + this.props.leftSideClassName : "")}>
                            {this.renderLeftSide()}
                        </div>
                        <div className={'col-md-' + this.props.rightSideWidth + " " + Styles.rightSide + (this.props.rightSideClassName ? " " + this.props.rightSideClassName : "")} >
                            {this.renderRightSide()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

