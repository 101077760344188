import { debounce, isNullOrUndefined } from "@bimser/core";
import IconCompare from "@bimser/icons/16/compare";
import * as React from "react";
import { BCCodeEditor, findMessage, ICodeEditor, IMonacoEditor, IRibbonBarItemProps } from "../../index";
import * as Styles from '../assets/style.scss';
import { ITextViewerCProps, ITextViewerProps } from "../entities";
import DiffViewer from "./DiffViewer";

export default class TextViewerLayout extends React.Component<ITextViewerProps & ITextViewerCProps, any> {
    editor: ICodeEditor;
    container?: HTMLElement;
    differenceComponent: DiffViewer;

    constructor(props: ITextViewerProps & ITextViewerCProps) {
        super(props);
        this.generateTemplate = this.generateTemplate.bind(this);
        this.onWillMount = this.onWillMount.bind(this);
        this.onDidMount = this.onDidMount.bind(this);
        this.onClickCompare = this.onClickCompare.bind(this);
        this.onChange = this.onChange.bind(this);
        this.triggerCompareOperations = debounce(this.triggerCompareOperations, 200);

        this.state = {
            showDifference: false,
        }
    }

    componentDidUpdate(prevProps: ITextViewerProps & ITextViewerCProps) {
        if (this.props.panelSize !== prevProps.panelSize)
            setTimeout(() => {
                this.editor.layout()
            }, 550)
    }

    onWillMount(monaco: IMonacoEditor) {
        if (this.props.onWillMount)
            this.props.onWillMount(monaco);
    }

    onDidMount(editor: ICodeEditor, monaco: IMonacoEditor) {
        this.editor = editor;
        let value: string = "";

        if (typeof this.props.value === 'string') {
            value = this.props.value;
        }
        else if (!isNullOrUndefined(this.props.value)) {
            value = (this.props.value as any).toString();
        }

        editor.getModel().setValue(value);
        if (this.props.onDidMount) {
            this.props.onDidMount(editor, monaco);
        }

    }

    triggerCompareOperations(value: string) {
        if (this.state.showDifference) {
            this.differenceComponent.createDifference(this.props.value, value, this.props.language);
        }
    }

    onChange(value: string) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        if (this.props.value != value) {
            this.triggerCompareOperations(value);
        }
    }

    getEditorValue() {
        return this.editor ? this.editor.getModel().getValue() : '';
    }

    generateTemplate(props: ITextViewerProps): JSX.Element {
        const defaultOptions = {
            readOnly: false,
            automaticLayout: true,
            lineNumbers: 'off',
            minimap: {
                enabled: false,
            },
            wordWrap: 'on',
            tabCompletion: 'on',
        };

        const options = Object.assign({}, defaultOptions, this.props.options);

        return (
            <React.Fragment>
                <div
                    ref={(domNode) => {
                        this.container = domNode;
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        display: this.state.showDifference ? "none" : "block"
                    }}>

                    <BCCodeEditor
                        width={this.props.width}
                        height={this.props.height}
                        language={this.props.language}
                        theme={this.props.theme}
                        value={this.getEditorValue()}
                        options={options}
                        beforeMount={this.onWillMount}
                        onMount={this.onDidMount}
                        onChange={this.onChange}
                    />
                </div>
                <DiffViewer
                    ref={(diffrenceComp) => { this.differenceComponent = diffrenceComp; }}
                    isVisible={this.state.showDifference}
                />
            </React.Fragment>
        );

    }

    onClickCompare() {
        if (this.state.showDifference) {
            this.differenceComponent.removeDifference();
        } else {
            this.differenceComponent.createDifference(this.props.value, this.editor.getModel().getValue(), this.props.language);
        }
        this.setState({ showDifference: !this.state.showDifference });
    }

    getTextViewerHeaderRibbonItems() {
        let items: IRibbonBarItemProps[] = [
            {
                key: 'compare',
                name: findMessage.get("100171"),
                onClick: this.onClickCompare,
                icon: IconCompare.info
            }];

        if (this.props.getRibbonItems)
            this.props.getRibbonItems(items);

    }

    componentDidMount() {
        this.getTextViewerHeaderRibbonItems();
    }

    render() {
        return (
            <div className={Styles.textViewerContent}>
                {
                    this.generateTemplate(this.props)
                }
            </div>
        );
    }
}