import { IBreadCrumbProps } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { BreadCrumb, BreadCrumbFactory, BreadCrumbItem, BreadCrumbItemFactoryFromJS } from "../../../entities/BreadCrumbFactory";

export interface ITopMenuState {
    breadCrumb: IBreadCrumbProps
}

export interface IReadOnlyTopMenuState {
    breadCrumb: BreadCrumb
}

export type TopMenuState = ReadonlyRecord<IReadOnlyTopMenuState>;

export const TopMenuStateFactory = Record<IReadOnlyTopMenuState>({
    breadCrumb: BreadCrumbFactory(),
});

export const TopMenuStateFactoryFromJS = function (data: ITopMenuState) {

    let items: List<BreadCrumbItem> = List<BreadCrumbItem>();

    data.breadCrumb.items.forEach((v, i) => {
        items = items.push(BreadCrumbItemFactoryFromJS(v));
    });

    return TopMenuStateFactory({
        breadCrumb: BreadCrumbFactory({
            items: items,
            onCreate: null,
            onItemClick: null
        })
    });
}