import { BCBadge, BCButton, BCLabel, BCList, BCPopover, BCScrollbars, findMessage } from '@bimser/components';
import IconClose from "@bimser/icons/16/close-panel";
import IconUpload from "@bimser/icons/16/upload";
import * as React from 'react';
import * as Style from '../assets/Style.scss';
import { IUploadFileItem } from '../entities';
import IDmUploadPopoverProps from '../entities/IDmUploadPopoverProps';
import UploadFileItem from './uploadListItem';

const DMUploadPopover = (props: IDmUploadPopoverProps) => {

    const Strings = {
        UPLOADS: findMessage.get('101214'),
    }

    const [isMaximized, setMaximized] = React.useState(true);

    const onMaximizeClick = () => {
        setMaximized(true);
    }

    const onCloseClick = () => {
        setMaximized(false);
    }

    const handleDropdownButtonClick = () => {
        props.clear(null)
    }

    const generateFooter = () => {
        return (
            <div className={Style.Footer}>
                <BCButton
                    onClick={handleDropdownButtonClick}
                    text={findMessage.get("100913")}
                    type={"primary"}
                    size={"small"}
                />
            </div>
        )
    }

    const generateControlBar = (asHeader = false) => {
        if (asHeader) {
            return (
                <div className={Style.Header}>
                    <BCLabel>{Strings.UPLOADS}</BCLabel>
                    <div className={Style.actions}>
                        <div onClick={onCloseClick} className={[Style.button, Style.close].join(' ')} >
                            <IconClose />
                        </div>
                    </div>
                </div>
            )
        } else return (
            <div className={Style.actions}>
                <div onClick={onCloseClick} className={[Style.button, Style.close].join(' ')} >
                    <IconClose />
                </div>
            </div>
        )
    }

    const onItemContextMenuClick = (hash: string, key: string) => {
        switch (key) {
            case 'copy_link': {
                props.copyLink({ uuid: hash });
                break;
            }
            case 'pause': {
                props.pause({ uuid: hash })
                break;
            }
            case 'continue': {
                props.continue({ uuid: hash });
                break;
            }
            case 'remove': {
                props.clear(hash);
                break;
            }
        }

    }

    const renderFileItem = (item: IUploadFileItem) => {
        return <UploadFileItem
            item={item}
            onContextMenuClicked={onItemContextMenuClick}
            clear={props.clear}
            continue={props.continue}
            pause={props.pause}
        />
    }

    const generateMaximizedPopover = () => {
        return (
            <div className={Style.MaximizedView}>
                {generateControlBar(true)}
                <div className={Style.Content}>
                    <BCScrollbars autoHide>
                        <BCList
                            dataSource={props.files.sort((a, b) => {
                                var x = a.info.status;
                                var y = b.info.status;
                                if (x < y) { return 1; }
                                if (x > y) { return -1; }
                                return 0;
                            })}
                            renderItem={renderFileItem}
                            split={false}
                        />
                    </BCScrollbars>

                </div>
                {generateFooter()}
            </div>
        )
    }

    const genereteMinimizedPopover = () => {
        return (
            <span className={Style.MinimizedView} onClick={onMaximizeClick}>
                <BCBadge
                    count={props.files.length}
                >
                    <IconUpload height="32px" width="32px" />
                </BCBadge>

            </span>
        )
    }

    if (!props.files?.length) return null;

    return (
        <div className={Style.UploadHelperWrapperButton}>
            <BCPopover
                overlayClassName={Style.UploadHelperWrapper}
                content={generateMaximizedPopover()}
                trigger={"click"}
                visible={props.files.length > 0 ? isMaximized : false}
                onVisibleChange={(v) => setMaximized(v)}
                placement={"topRight"}
            >
                {props.files.length > 0 && genereteMinimizedPopover()}
            </BCPopover >
        </div>
    )
}

export default DMUploadPopover


