import { panelType } from '@bimser/common';
import { BCButton, BCCheckbox, BCList, findMessage } from '@bimser/components';
import * as React from 'react';
import { IWebInterfaceAction } from '../../../../../../../common/entities';
import * as Style from '../assets/style.scss';
import { IProfileFormFile } from '../entities/IProfileFormFile';
import OnChangeFilePropertyPayload from '../entities/OnChangeFilePropertyPayload';
import FileListItem from './FileListItem';
const EmptyImage = require("../assets/img/list-empty.svg");

interface FileListProps {
    panelGuid: string;
    fileList: IProfileFormFile[];
    selectedIds?: string[];
    disableFileAdd?: boolean;
    readOnly?: boolean;
    onSelectedChange?: (selectedIds: string[]) => void;
    onFilePropertyChange: (payload: OnChangeFilePropertyPayload) => void;
    onRemoveFile: (fileId: string) => void;
    onAddNewFiles?: (payload: IWebInterfaceAction) => void;

}

const FileList = (props: FileListProps) => {

    const [browseFileRef, setBrowseFileRef] = React.useState<HTMLInputElement>(null);

    React.useEffect(() => {
        setBrowseFileRef(buildFileSelector());
    }, [])

    const onChangeFileProperty = (payload: OnChangeFilePropertyPayload) => {
        props.onFilePropertyChange(payload);
    }

    const onChangeSelectAll = (checked: boolean) => {
        if (checked) {
            props.onSelectedChange(props.fileList.map(f => f.id));
        } else {
            props.onSelectedChange([]);
        }
    }

    const onSelectionChange = (id: string, selected: boolean, ctrlKey: boolean) => {
        if (selected) {
            if (ctrlKey) {
                props.onSelectedChange([...props.selectedIds, id]);
            } else {
                props.onSelectedChange([id]);
            }
        } else {
            props.onSelectedChange(props.selectedIds.filter(_id => _id != id));
        }
    }

    const renderItem = (item: IProfileFormFile) => {
        let isSelected = props.selectedIds.findIndex(id => id == item.id) > -1;
        return (
            <FileListItem
                item={item}
                onFilePropertyChange={onChangeFileProperty}
                onSelectionChange={onSelectionChange}
                onRemoveFile={props.onRemoveFile}
                isSelected={isSelected}
                readOnly={props.readOnly}
            />
        )
    }

    const renderListHeader = () => {
        return (
            <div className={Style.listHeader}>
                <span>{findMessage.get('102306')}</span>
                <BCCheckbox
                    label={findMessage.get('100706')}
                    checked={props.selectedIds.length == props.fileList.length}
                    indeterminate={props.selectedIds.length > 0 && props.selectedIds.length < props.fileList.length}
                    onChange={onChangeSelectAll}
                />
            </div>
        )
    }

    const buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');
        fileSelector.onchange = (ev: Event) => {
            let fileList: File[] = [];
            let files = (ev.target as HTMLInputElement).files;
            for (let i = 0; i < files.length; i++) {
                const file = files.item(i);
                fileList.push(file)
            }
            props.onAddNewFiles({
                panelId: props.panelGuid,
                panelType: panelType.profileForm,
                data: fileList
            })
        }
        return fileSelector;
    }

    const onClickBrowse = () => {
        if (browseFileRef) {
            browseFileRef.click()
        }
    }

    const renderCustomEmpty = () => {
        return (
            <div className={Style.EmptyWrapper} key={"fileListEmpty"}>
                <img src={EmptyImage.default} className={Style.emptyImage} />
                <span className={Style.emptyInfoTitle}>{findMessage.get('102709')}</span>
                <span className={Style.emptyInfo}>{findMessage.get('102710')}</span>
            </div>
        )
    }

    return (
        <div className={Style.FileListContainer}>
            {!props.disableFileAdd &&
                <div className={Style.fileBrowseSection}>
                    <span>{findMessage.get('102305')}</span>
                    <div>
                        <BCButton type="ghost" text={findMessage.get("100719")} onClick={onClickBrowse} />
                    </div>
                </div>
            }
            <div className={Style.fileListSection}>
                <BCList
                    header={renderListHeader()}
                    dataSource={props.fileList}
                    renderItem={renderItem}
                    customEmpty={renderCustomEmpty()}
                    split={false}
                />
            </div>
        </div>
    )
}

export default FileList