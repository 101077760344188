import { createGuid } from "@bimser/core";
import { ConjunctionType, IConditionGroup } from "../entities";

export function addConditionGroup(root: IConditionGroup, id: string): IConditionGroup {
    if (root) {

        const newGroup: IConditionGroup = {
            id: createGuid(),
            children: [],
            conditions: [],
            typeName: 'AndConditionGroup'
        };

        if (root.id === id) {
            return {
                ...root,
                children: [
                    ...root.children,
                    newGroup
                ]
            };
        } else {
            const children = root.children.map(child => {
                return addConditionGroup(child, id);
            });

            return {
                ...root,
                children
            };
        }
    } else {
        return null
    }
}

export function deleteConditionGroup(root: IConditionGroup, id: string): IConditionGroup {

    if (root.id === id) return null;

    const filtered = root.children.filter(child => child.id != id);
    if (filtered.length != root.children.length) {
        return {
            ...root,
            children: filtered
        };
    } else {
        const children = root.children.map(child => {
            return deleteConditionGroup(child, id);
        }).filter(x => x);

        return {
            ...root,
            children
        };
    }
}

export function updateConjunction(root: IConditionGroup, id: string, data: any): IConditionGroup {
    
    if (!root) return root;

    const conjunctionType: ConjunctionType = data;

    if (root.id === id) {
        return {
            ...root,
            typeName: conjunctionType
        };
    } else {
        const children = root.children.map(child => {
            return updateConjunction(child, id, data);
        });

        return {
            ...root,
            children
        };
    }
}

export function updateConditionGroupCollapse(root: IConditionGroup, id: string, collapsed: boolean): IConditionGroup {

    if (!root) return root;

    if (root.id === id) {
        return {
            ...root,
            collapsed
        };
    } else {
        const children = root.children.map(child => {
            return updateConditionGroupCollapse(child, id, collapsed);
        });

        return {
            ...root,
            children
        };
    }
}