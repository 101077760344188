export const FORM_ITEMS_LOADED_TRANSACTION = "FLOW_LAYOUT.FORM_ITEMS_LOADED_TRANSACTION";
export const FORM_ITEMS_LOADED = "FLOW_LAYOUT.FORM_ITEMS_LOADED";
export const MODEL_CHANGE_TRANSACTION = "FORM_DESIGNER.MODEL_CHANGE_TRANSACTION";
export const WINDOW_SIZE_CHANGED = "FORM_DESIGNER.WINDOW_SIZE_CHANGED";
export const FORM_WILL_CLOSE_ACTION = 'FORM_DESIGNER.FORM_WILL_CLOSE_ACTION';
export const FORM_SAVED_SAGA = 'FORM_DESIGNER.FORM_SAVED_SAGA';
export const FORM_SAVED_AS_SAGA = 'FORM_DESIGNER.FORM_SAVED_AS_SAGA';
export const ON_PROCESS_OPEN = 'FORM_DESIGNER.ON_PROCESS_OPEN';
export const LAST_BATCH_GUID_RESOLVED = "FORM_DESIGNER.LAST_BATCH_GUID_RESOLVED";
export const ON_BEFORE_FORM_SAVE_RESPONSE = "FORM_DESIGNER.ON_BEFORE_FORM_SAVE_RESPONSE";
export const SHOW_SUMMARY_MESSAGES = "FORM_DESIGNER.SHOW_SUMMARY_MESSAGES";
export const CLOSE_SUMMARY_MESSAGE = "FORM_DESIGNER.CLOSE_SUMMARY_MESSAGE";
export const CHANGE_PROPERTY = "FORM_DESIGNER.CHANGE_PROPERTY";
export const RELATED_ITEM_DATA_CHANGE = "FORM_DESIGNER.RELATED_ITEM_DATA_CHANGE";
export const CLOSE_WINDOW_AFTER_SAVE = "FORM_DESIGNER.CLOSE_WINDOW_AFTER_SAVE";
export const CHECK_RULES = "FORM_DESIGNER.CHECK_RULES";
export const FORM_CAPTION_TEXT_CHANGED = "FORM_DESIGNER.FORM_CAPTION_TEXT_CHANGED";
export const FORM_SAVE_TRANSACTION = "FORM_DESIGNER.FORM_SAVE_TRANSACTION";
export const OPEN_APPLICATION_ITEM = "FORM_DESIGNER.OPEN_APPLICATION_ITEM"
