import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseFormSaveRequestData {
    formName: string,
    projectName: string,
    documentId: number,
    secretKey: string
}

export interface IFormSaveRequestData extends IBaseFormSaveRequestData {
    formData: LayoutCreatorEntities.INormalizedLayoutItems,
}

export interface IReadonlyFormSaveRequestData extends IBaseFormSaveRequestData {
    formData: LayoutCreatorEntities.NormalizedLayoutItems
}

export type FormSaveRequestData = ReadonlyRecord<IReadonlyFormSaveRequestData>;

export const FormSaveRequestDataFactory = Record<IReadonlyFormSaveRequestData>({
    formName: null,
    projectName: null,
    documentId: null,
    formData: LayoutCreatorEntities.NormalizedLayoutItemsFactory(),
    secretKey: null
});

export const FormSaveRequestDataFactoryFromJS = function (data: IFormSaveRequestData) {
    return FormSaveRequestDataFactory({
        ...data,
        formData: LayoutCreatorEntities.NormalizedLayoutItemsFactoryFromJS(data.formData)
    });
}