import { IBaseAction } from "@bimser/core";
import { List } from 'immutable';
import * as actionTypes from '../actions/actionTypes';
import { Language, LanguageState, LanguageStateFactory } from "../entities";



export default function (state: LanguageState = LanguageStateFactory(), action: IBaseAction<any>): LanguageState {
    switch (action.type) {
        case actionTypes.SET_LANGUAGES_ACTION: {
            return uploadLanguagesToStore(state, action);
        }
        default:
            return state;
    }
}

function uploadLanguagesToStore(state: LanguageState, action: IBaseAction<any>): LanguageState {
    return state.set("items", action.payload.languages).set("canChangeLanguage", action.payload.userCanChangeLanguage);
}