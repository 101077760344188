import { Map } from "immutable";
import { removeItem } from ".";
import { LayoutDataItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const removeItemWithRefs = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    itemKeys: string[],
    subStructure?: boolean,
    shiftKey?: boolean) => {

    const view = views.get(activeView);
    if (view.result.count()) {
        itemKeys.forEach(id => {
            if (view.entities.items.has(id)) {
                const { newViews, newData } = removeItem(views, data, activeView, id, subStructure, shiftKey);
                views = newViews;
                data = newData;
            }
        })
    }

    return {
        views,
        data
    }
}