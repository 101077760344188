import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import * as Styles from '../assets/style.scss';
import { BCButton, findMessage, BCForm, BCFormItem, IColProps, ValidateStatus, BCPassword } from '@bimser/components';
import { IChangeMyPasswordComponentProps } from '../entities/IChangeMyPasswordComponentProps';
import { checkPasswordValidation } from '../../..';

export default class ChangeMyPassword extends React.Component<IChangeMyPasswordComponentProps, any> {

    constructor(props: IChangeMyPasswordComponentProps) {
        super(props);
        this.onClickLoginPanel = this.onClickLoginPanel.bind(this);
        this.onClickChangeMyPassword = this.onClickChangeMyPassword.bind(this);
        this.generateFormTemplate = this.generateFormTemplate.bind(this);
        this.getSendPasswordRecoveryActionResponse = this.getSendPasswordRecoveryActionResponse.bind(this);
        this.onChangeNewPasswordInputData = this.onChangeNewPasswordInputData.bind(this);
        this.onChangeConfirmPasswordInputData = this.onChangeConfirmPasswordInputData.bind(this);

        this.state = {
            NewPassword: '',
            ConfirmNewPassword: '',
            ButtonDisabled: false,
            PasswordValidation: {
                newPasswordValidationStatus: ValidateStatus.validating,
                newPasswordValidationMessage: "",
                confirmPasswordValidationStatus: ValidateStatus.validating,
            },
            loading: false,
        }
    }

    componentDidMount() {
        if (this.props.getChangePasswordRequirements) {
            this.props.getChangePasswordRequirements();
        }
    }

    onClickLoginPanel() {
        if (this.props.onClickLoginPanel) {
            this.props.onClickLoginPanel();
        }
        window.location.hash = "";
    }

    onClickChangeMyPassword() {
        if (this.props.onClickChangeMyPassword) {
            this.setState({
                loading: true,
            })
            this.props.onClickChangeMyPassword({
                newPass: this.state.NewPassword,
                confirmNewPass: this.state.ConfirmNewPassword,
                resetTokenId: this.props.tokenId,
                language: localStorage.getItem("locale") || "en-US",
                callBackResponse: this.getSendPasswordRecoveryActionResponse
            });
        }
    }

    private onChangeNewPasswordInputData(data: any) {
        let { rslt, msg } = checkPasswordValidation({ ...this.props.passwordRequirements, password: data });
        let valid = data == this.state.ConfirmNewPassword ? ValidateStatus.success : ValidateStatus.error;
        this.setState({
            ...this.state,
            NewPassword: data,
            ButtonDisabled: valid == ValidateStatus.success && rslt,
            PasswordValidation: {
                newPasswordValidationStatus: rslt ? ValidateStatus.success : ValidateStatus.error,
                newPasswordValidationMessage: msg,
                confirmPasswordValidationStatus: valid,
            },
        });
    }

    private onChangeConfirmPasswordInputData(data: any) {
        let valid = this.state.NewPassword === data && this.state.NewPassword ? ValidateStatus.success : ValidateStatus.error

        this.setState({
            ...this.state,
            ConfirmNewPassword: data,
            ButtonDisabled: this.state.PasswordValidation.newPasswordValidationStatus == ValidateStatus.success && valid == ValidateStatus.success,
            PasswordValidation: {
                ...this.state.PasswordValidation,
                confirmPasswordValidationStatus: valid,
            },
        });
    }

    getSendPasswordRecoveryActionResponse(success: boolean) {
        if (success) {
            window.setTimeout(() => {
                this.props.onClickLoginPanel();
                window.location.hash = "";
            }, 3000)
        }        
        this.setState({
            loading: false,
        });
    }

    generateFormTemplate() {

        let itemLabelCol: IColProps = { span: 24 }
        let itemWrapperCol: IColProps = { span: 24 }

        let descLabelCol = { span: 24 };

        let { PasswordValidation } = this.state;
        return (
            <BCForm className={[Styles.loginContainer, Styles.changePasswordContainer].join(" ")} >
                <BCFormItem
                    className={Styles.title}
                    wrapperCol={descLabelCol}
                >
                    <div className={Styles.title}>{findMessage.get("101947")}</div>
                </BCFormItem>
                <BCFormItem
                    className={Styles.description}
                    wrapperCol={descLabelCol}
                >
                    <div className={Styles.label}>{findMessage.get("101948")}</div>
                </BCFormItem>
                <BCFormItem
                    required={true}
                    labelCol={itemLabelCol}
                    wrapperCol={itemWrapperCol}
                    label={findMessage.get("101960")}
                    validateStatus={PasswordValidation.newPasswordValidationStatus}
                    help={PasswordValidation.newPasswordValidationMessage}
                >
                    <BCPassword size={'middle'} type={"text"} visibilityToggle value={this.state.NewPassword} onChange={(e) => { this.onChangeNewPasswordInputData(e.data); }} placeHolder={findMessage.get("101960")} />
                </BCFormItem>
                <BCFormItem
                    required={true}
                    labelCol={itemLabelCol}
                    wrapperCol={itemWrapperCol}
                    label={findMessage.get("101946")}
                    validateStatus={PasswordValidation.confirmPasswordValidationStatus}
                    help={findMessage.get("101953")}
                >
                    <BCPassword size={'middle'} type={"text"} visibilityToggle value={this.state.ConfirmNewPassword} onChange={(e) => { this.onChangeConfirmPasswordInputData(e.data); }} placeHolder={findMessage.get("101946")} />
                </BCFormItem>
                <BCFormItem wrapperCol={descLabelCol}>
                    <BCButton loading={this.state.loading} type='primary' text={findMessage.get("100752")} style={{ width: '100%' }} disabled={!this.state.ButtonDisabled} onClick={this.onClickChangeMyPassword} />
                </BCFormItem>
                <BCFormItem wrapperCol={descLabelCol}>
                    <a className={Styles.linkBack} onClick={this.onClickLoginPanel}>
                        {findMessage.get("100753")}
                    </a>
                </BCFormItem>
            </BCForm>
        )
    }

    public render() {
        return (
            <div className={Styles.loginContainer}>
                {this.generateFormTemplate()}
            </div>
        );
    }
}