﻿import Input, { InputRef } from 'antd/lib/input';
import * as React from "react";
import { IInputChangeEventArgs, InputSizes } from '../BCInput';
import { IInputPasswordProps } from './entities';
import IconEye from "@bimser/icons/16/eye";
import IconEyeNo from "@bimser/icons/16/eye-no";
import * as Styles from './assets/styles.scss';
const cx = require('classnames/bind').bind(Styles);

const BCPassword = React.forwardRef((props: IInputPasswordProps, ref: React.ForwardedRef<InputRef>) => {

    const [value, setValue] = React.useState(props.value?.valueOf() || '');
    const [passwordVisibility, setPasswordVisibility] = React.useState(false);
    
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let args: IInputChangeEventArgs = { senderArgs: e, data: e.currentTarget.value, itemKey: props.itemKey };
        setValue(args.data);
        props.onChange?.(args);
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        let args: IInputChangeEventArgs = { senderArgs: e, data: e.currentTarget.value };
        props.onFocus?.(args);
    }

    const togglePasswordVisibility = () => setPasswordVisibility(!passwordVisibility);

    const renderVisibilityToggleBtn = () => {
        if (props.visibilityToggle) {
            return (
                <div onClick={togglePasswordVisibility} className={Styles.togglePasswordVisibilityBtn}>
                    {passwordVisibility ? <IconEye width="15px" height="15px" /> : <IconEyeNo width="15px" height="15px" />}
                </div>
            )
        }
    }

    const classNames = cx({
        passwordStyles: true,
        textSecurity: !passwordVisibility,
        passwordStylesWithoutButton: !props.visibilityToggle,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center",
        [props.className]: true
    });

    return (
        <Input
            tabIndex={props.tabIndex}
            prefix={props.prefix}
            disabled={props.disabled}
            readOnly={props.readOnly}
            size={props.size}
            ref={ref}
            style={props.style}
            placeholder={props.placeHolder}
            value={value}
            maxLength={props.maxLength}
            className={[classNames, props.className].join(' ')}
            onPressEnter={props.onPressEnter}
            onChange={onChange}
            onFocus={onFocus}
            type={props.type}
            addonBefore={props.addonBefore}
            addonAfter={props.addonAfter}
            onClick={props.onClick}
            onBlur={props.onBlur}
            suffix={renderVisibilityToggleBtn()}
            allowClear={props.allowClear}
        />
    )

})

export default BCPassword
export { IInputPasswordProps, IInputChangeEventArgs, InputSizes };