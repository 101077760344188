export enum DataTypes {
    string = "string",
    text = "string",
    boolean = "boolean",
    actionButton = "object",
    date = "date",
    number = "number",
    decimal = "number",
    int = "number",
    dateTime = "datetime",
    time = "time",
    select = "object",
    multiLanguage = "object",
    object = "object",
    dateRange = "dateRange",
    none = "none",
}