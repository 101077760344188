import { IExtremeGridState, ISelectedItem } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeSelectionReducer(state: IExtremeGridState, action: IBaseMutableAction<ISelectedItem>): IExtremeGridState {
    return Object.assign({}, state, {
        selection: {
            ...state.selection,
            selectedIds: action.payload.selectedIds,
            selectedDatas: action.payload.items
        }
    })
}