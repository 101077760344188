import HoldingContainer from "./holding/containers";
import CompanyContainer from "./company/containers";
import PlantContainer from "./plant/containers";
import OrganizationContainer from "./organization/containers";

export default {
    "HoldingFormDrawer": HoldingContainer,
    "CompanyFormDrawer": CompanyContainer,
    "PlantFormDrawer": PlantContainer,
    "OrganizationFormDrawer": OrganizationContainer
}