import { ITreeData, IFieldType } from '../entities';
import { sortFieldsByType } from '../../BCConditionalStatement/helpers';

export function filterReadonlyFields(items: ITreeData[], valueType?: IFieldType): ITreeData[] {

    let _items = items.filter(i => {

        if (i.children && i.children.length) {
            i.children = filterReadonlyFields(i.children, valueType)
        }

        const isWritable = !i.externalData || !i.externalData.readOnly;
        const isProperty = i.externalData.type && IFieldType[i.externalData.type as IFieldType] ? true : false;
        const equalType = i.externalData.type === valueType;

        if (isWritable && (valueType ? (!isProperty || equalType) : true) && (isProperty ? true : i.children?.length)) {
            return true
        }

    })

    return sortFieldsByType(_items);
}