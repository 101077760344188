import * as React from "react";
import * as styles from './assets/style.scss';
import { ILabelProps } from './entities';

var classNames = require('classnames/bind');
let cx = classNames.bind(styles);

export default class BCLabel extends React.Component<ILabelProps, {}> {

    constructor(props: ILabelProps) {
        super(props);
    }

    render() {

        let { ellipsis, align, customStyles, children, title } = this.props;

        let classNames = cx({
            label: true,
            ellipsis,
            left: align === 'left',
            center: align === 'center',
            right: align === 'right'
        });

        return (
            <span
                onClick={this.props.onClick}
                className={[classNames, "label", this.props.className].join(' ')}
                style={customStyles}
                title={title}
            >
                {children}
            </span>
        );
    }

}

export { ILabelProps };
