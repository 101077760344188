import * as React from "react";
import BCInputGroup from '../../../BCInputGroup';
import * as Styles from "../../assets/style.scss";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import { InputNumberProperty } from '../propertyItems';
import { PLabel } from './helper';
import { findMessage } from '../../../BCIntl';

const inputStyle: React.CSSProperties = { width: '100%' };

export default function AspectProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const changeAspectValue = (align: string, value: number | string) => {
        let valueInstance = props.value || {};
        valueInstance[align] = value;
        if (props.customChangeEvent) {
            props.customChangeEvent(valueInstance);
        } else {
            replaceValue(props.item, valueInstance)
        }
    }

    const customChangeEvent = (value: any, props: IPropertyComponentProps) => {
        changeAspectValue(props.externalData, value);
    }

    return (
        <BCInputGroup key={props.item.key} size={'small'} >
            <div className={Styles.propertyColumn}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    externalData={"width"}
                    customChangeEvent={customChangeEvent}
                    value={props.value ? props.value.width : null}
                    minValue={0}
                />
                <PLabel label={findMessage.get('100238')} />
            </div>
            <div className={Styles.propertyColumn}>
                <InputNumberProperty
                    item={props.item}
                    style={inputStyle}
                    externalData={"height"}
                    customChangeEvent={customChangeEvent}
                    value={props.value ? props.value.height : null}
                    minValue={0}
                />
                <PLabel label={findMessage.get('100240')} />
            </div>
        </BCInputGroup>
    )

}