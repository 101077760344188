import { Map } from "immutable";

const takeFromViewIfExists: string[] = ["placeholder"];

export const mergeViewProperties = (properties: Map<string, any>, viewProperties: Map<string, any>) => {
    let newProperties = properties;

    if (viewProperties) {
        newProperties = properties.mergeDeep(viewProperties);

        takeFromViewIfExists.forEach(property => {
            if (viewProperties.has(property)) {
                newProperties = newProperties.set(property, viewProperties.get(property));
            }
        })
    }

    return newProperties;
}