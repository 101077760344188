import * as React from "react";
import BCCheckbox from "../../../BCCheckbox";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function CheckboxProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (values: any) => {
        props.customChangeEvent ? props.customChangeEvent(values) : replaceValue(props.item, values)
    }

    return (
        <BCCheckbox
            onChange={onChange}
            checked={props.value}
            value={props.value}
            disabled={props.item.disabled}
        />
    )
}