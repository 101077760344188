import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { UploadManagerState, UploadFileItem } from '../entities';
import { BaseState } from '../../..';

const panelData = (state: BaseState) => {
    let panelData: UploadManagerState = state.UploadManager;
    if(panelData.files) return panelData.files;
    else return null;
}

const getUploadFiles = createSelector(
    [
        panelData
    ],
    (files: Map<string, UploadFileItem>) => {
        if(files){
            return files.valueSeq().toJS()
        }
        return []
    }
);

export { getUploadFiles }