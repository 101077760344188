import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Record, Map } from "immutable";
import ActivityRelationType from "./ActivityRelationType";

interface IBaseActivityRelation {
    relationType: ActivityRelationType,
    relationObjectId?: number,
    inMessage: boolean,
    secretKey?: string
}

export interface IActivityRelation extends IBaseActivityRelation {
    relationObjectName?: IDictionary<string>
}

export interface IReadonlyActivityRelation extends IBaseActivityRelation {
    relationObjectName?: Map<string, string>
}

export type ActivityRelation = ReadonlyRecord<IReadonlyActivityRelation>;

export const ActivityRelationFactory = Record<IReadonlyActivityRelation>({
    relationType: ActivityRelationType.User,
    relationObjectId: null,
    inMessage: null,
    secretKey: null,
    relationObjectName: Map()
});

export const ActivityRelationFactoryFromJS = function (data: IActivityRelation) {
    return ActivityRelationFactory({
        ...data,
        relationObjectName: Map(data.relationObjectName)
    });
}