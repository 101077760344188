import { WebInterfaceBaseState } from "common/base/state";
import { createSelector } from 'reselect';
import { isNullOrUndefined } from "@bimser/core";
import { IPanelMenuProps } from '../entities';
import { PanelMenuPanelContent, PanelMenuPanelContentFactory } from "../entities/IPanelMenuPanelContent";

const menuItems = (state: WebInterfaceBaseState, props: IPanelMenuProps) => {
    return state.modules.menus.panelMenus.get(props.panelGuid);
}

const content = (state: WebInterfaceBaseState, props: IPanelMenuProps) => {
    return state.modules.menus.panelMenus.get(props.panelGuid);
}

const activeNodeId = (state: WebInterfaceBaseState, props: IPanelMenuProps) => {
    let panel = state.modules.menus.panelMenus.get(props.panelGuid)
    if (panel) {
        return panel.activeNodeId;
    }
    return "";
}

const getPanelMenuPanelContent = createSelector(
    [
        content
    ],
    (panel: PanelMenuPanelContent) => {
        if (isNullOrUndefined(panel)) {
            return PanelMenuPanelContentFactory()
        }
        return panel;
    }
)

const getPanelMenuItems = createSelector(
    [
        menuItems
    ],
    (panel: PanelMenuPanelContent) => {
        if (isNullOrUndefined(panel)) {
            return []
        } else {
            return panel.menuItems.toJS();
        }
    }
)

const getPanelActiveNodeId = createSelector(
    [
        activeNodeId
    ],
    (id: string) => {
        return id;
    }
)


export { getPanelMenuItems, getPanelMenuPanelContent, getPanelActiveNodeId };

