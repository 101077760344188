import * as React from "react";
import BCLookup from '../../../BCLookup';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function LookupProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onValueChanged = (value: string) => {
        if (props.customChangeEvent) {
            props.customChangeEvent(value)
        } else {
            replaceValue(props.item, value)
        }
    }

    return (
        <BCLookup
            title={props.item.options.title}
            value={props.value || props.item.placeholder}
            valueExpr={props.item.options.valueExpr}
            displayExpr={props.item.options.displayExpr}
            onValueChanged={onValueChanged}
            dataSource={props.item.options.dataSource}
            columns={props.item.options.columns}
            disabled={props.item.disabled || props.item.readOnly}
        />
    )
}