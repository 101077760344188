import { WebInterfaceBaseState } from 'common/base/state';
import { createSelector } from 'reselect';
import { isNullOrUndefined } from "@bimser/core";
import { ILegacyFrameProps, LegacyFramePanelContent } from '../entities';

const legacyFramePanel = (state: WebInterfaceBaseState, props : ILegacyFrameProps) => state.modules.LegacyFrame.panels.get(props.panelGuid);

const getLegacyFrameShowLoading = createSelector(
    [
        legacyFramePanel
    ],
    (panel: LegacyFramePanelContent) => {
        if (isNullOrUndefined(panel)) {
            return true;
        } else {
            return panel.showLoading;
        }
    }
);

const getLegacyFrameUrl = createSelector(
    [
        legacyFramePanel
    ],
    (panel: LegacyFramePanelContent) => {
        if (isNullOrUndefined(panel)) {
            return "";
        } else {
            return panel.url;
        }
    }
);

export { getLegacyFrameShowLoading, getLegacyFrameUrl };

