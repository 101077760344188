import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ITourStep, TourStep, TourStepFactoryFromJS } from './ITourStep';

interface IBaseTour {
    id: string,
    path: string,
    isOpen: boolean,
    currentStep: number,
    secretKey?: string,
    //tourTitle?: string,
    showTourTitle?: boolean,
    isLoading?: boolean
}

export interface ITour extends IBaseTour {
    steps: ITourStep[]
}

export interface IReadonlyTour extends IBaseTour {
    steps: List<TourStep>
}

export type Tour = ReadonlyRecord<IReadonlyTour>;

export const TourFactory = Record<IReadonlyTour>({
    id: null,
    path: null,
    //tourTitle: null,
    showTourTitle: false,
    isOpen: false,
    isLoading: false,
    currentStep: 0,
    secretKey: null,
    steps: List()
});

export const TourFactoryFromJS = function (data: ITour) {
    let _steps = data && data.steps ? data.steps.map(s => TourStepFactoryFromJS(s)) : [];
    return TourFactory({
        ...data,
        steps: List(_steps)
    });
}