import { IItem } from '@bimser/core';
import Menu from 'antd/lib/menu';
import React, { useEffect, useRef } from 'react';
import { IMenuInfo, IMenuItemClickEventArgs, IMenuProps } from './entities';
import { findItemByPath, generateMenuContent } from './helpers/generateMenuContent';

export default function BCMenu(props: React.PropsWithChildren<IMenuProps>) {
    const { mode, defaultOpenKeys, defaultSelectedKeys, items, selectedKeys, openKeys, triggerMode, className, style, selectable, forceSubMenuRender } = props;
    const menuItemList = useRef<Array<{ domItem: HTMLElement, data?: any; }>>([]);
    const renderMenuItem = generateMenuContent(props, addRefToMenuItemList);

    useEffect(() => {
        if (props.forceNativeClick) {
            menuItemList.current.forEach((elem) => {
                elem.domItem.onclick = (e) => {
                    onClick({ domEvent: e as any, key: "onClick", keyPath: [] })
                }
            });
        }

        const subMenuElement = document.getElementsByClassName(props.popupClassName);
        for (const element of subMenuElement) {
            element.querySelector("ul")?.style.setProperty('background-color', props.style?.backgroundColor, 'important')
        }
    }, []);

    function addRefToMenuItemList(domNode: HTMLDivElement, item: IItem) {
        menuItemList.current.push({ data: item, domItem: domNode });
    }

    function onClick(args: IMenuInfo) {
        if (props.onClick) {
            const data = findItemByPath(items, (args.keyPath && [...args.keyPath])?.reverse());
            props.onClick({ domEvent: args.domEvent, data });
        }
    }

    function onOpenChange(_openKeys: string[]) {
        if (props.onOpenChange) props.onOpenChange(_openKeys);
    }

    return (
        <div>
            <Menu
                onClick={onClick}
                mode={mode}
                className={className}
                selectable={selectable || false}
                defaultOpenKeys={defaultOpenKeys}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                style={style}
                defaultSelectedKeys={defaultSelectedKeys}
                triggerSubMenuAction={triggerMode || "hover"}
                onOpenChange={onOpenChange}
                forceSubMenuRender={forceSubMenuRender}
                items={renderMenuItem(items)}
            >
            </Menu>
        </div>
    );
}

export { IMenuProps, IMenuItemClickEventArgs };