import { ConditionalStatementEntities } from '@bimser/components/dist/BCConditionalStatement';
import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { Map, Record } from "immutable";
import { FormSaveRequestData, FormSaveRequestDataFactory, FormSaveRequestDataFactoryFromJS, IFormSaveRequestData } from './IFormSaveRequestData';
import { IFormStatus } from './IFormStatus';

export interface IBaseFormSavePayload {
    onAfterSave: (status: IFormStatus, secretKey: string) => void,
    validate?: boolean,
}

export interface IFormSavePayload extends IBaseFormSavePayload {
    items: IDictionary<LayoutCreatorEntities.ILayoutItem>,
    requestData: IFormSaveRequestData,
    rules: IDictionary<ConditionalStatementEntities.IRule>
}

export interface IReadonlyFormSavePayload extends IBaseFormSavePayload {
    items: Map<string, LayoutCreatorEntities.LayoutItem>,
    requestData: FormSaveRequestData,
    rules: Map<string, ConditionalStatementEntities.Rule>
}

export type FormSavePayload = ReadonlyRecord<IReadonlyFormSavePayload>;

export const FormSavePayloadFactory = Record<IReadonlyFormSavePayload>({
    items: Map(),
    requestData: FormSaveRequestDataFactory(),
    onAfterSave: null,
    rules: Map(),
    validate: true,
});

export const FormSavePayloadFactoryFromJS = function (data: IFormSavePayload) {

    let items: IDictionary<LayoutCreatorEntities.LayoutItem> = {};

    for (let key in data.items) {
        let item = data.items[key];
        items[key] = LayoutCreatorEntities.LayoutItemFactoryFromJS(item);
    }

    let rules: IDictionary<ConditionalStatementEntities.Rule> = {};

    for (let key in data.rules) {
        let item = data.rules[key];
        rules[key] = ConditionalStatementEntities.RuleFactoryFromJS(item);
    }

    return FormSavePayloadFactory({
        items: Map(items),
        rules: Map(rules),
        requestData: FormSaveRequestDataFactoryFromJS(data.requestData),
        onAfterSave: data.onAfterSave,
        validate: data.validate
    });
}