import { createStore, initializeDOM } from "@bimser/common";
import { IBaseAction, AsyncReducers } from "@bimser/core";
import * as React from "react";
import { Store } from 'redux';
import { Route } from "react-router-dom";
import DelegationModalsContainer from "modules/delegations";
import NewFolderModalContainer from "modules/dm/components/explorer/modals/NewFolderModal";
import NewFileModalContainer from "../modules/dm/components/explorer/modals/NewFileModal";
import DMSecurityModalContainer from '../modules/dm/components/explorer/modals/SecurityModal'
import PropertiesModalContainer from "modules/dm/components/explorer/modals/PropertiesModal";
import UserSettingsModalContainer from "modules/user/UserSettingsModal";
import AnnouncementDetailDialogContainer from "../modules/announcements/AnnouncementDetailDialog";
import AnnouncementFormModalContainer from "../modules/announcements/AnnouncementFormModal";
import SecurityAddGroupModalContainer from '../modules/security/modals/add';
import CreateGroupContainer from '../modules/security/containers/CreateGroupContainer';
import MainApp from "./MainApp";
import MainReducer from "./reducer";
import RootSaga from "./saga";
import SignatureModalContainer from '../common/components/SignatureModal/containers';
import ESignatureContainer from "../common/components/ESignature/containers";
import LeftMenuEBA6IntegrationContainer from "../app-main/layouts/left-menu/containers/LeftMenuEBA6IntegrationContainer";
import StartupAnnouncementsModalContainer from '../modules/announcements/StartupAnnouncementsModal'
import DMShareModalContainer from '../modules/dm/components/explorer/modals/ShareModal'
import CompleteBranchModal from '../modules/dm/components/viewer/CompleteBranchModalContainer';
import RelationModalContainer from '../modules/dm/components/viewer/components/RelationList/RelationModalContainer';
import DMSubscriptionModalContainer from '../modules/dm/components/explorer/modals/SubscriptionModal';
import HumanResourceFormDrawers from "../modules/humanResource/components/forms";
import DrawerPanelComponentContainer from "../common/components/panelComponent/containers";

type ReducerType = (state: any, action: IBaseAction<any>) => any;
type RootReducerType = ReducerType & { asyncReducers?: AsyncReducers<any> }

function getStoreFactory() {
    let store: Store<any> = null;

    return () => {
        if (!store) {
            store = createStore(MainReducer, RootSaga);
        }
        return store;
    }
}
const getStore = getStoreFactory();

export default function () {
    let store = getStore();
    let children = <Route path="/:hash?" component={MainApp} />;
    initializeDOM({
        store: store,
        children: children,
        modalComponents: {
            "AnnouncementDetailDialog": AnnouncementDetailDialogContainer,
            "AnnouncementFormModal": AnnouncementFormModalContainer,
            "UserSettingsModal": UserSettingsModalContainer,
            "DelegationModal": DelegationModalsContainer,
            "NewFolderModal": NewFolderModalContainer,
            "NewFileModal": NewFileModalContainer,
            "RelationModal": RelationModalContainer,
            "AddModal": SecurityAddGroupModalContainer,
            "DMSecurityModal": DMSecurityModalContainer,
            "CreateGroupModal": CreateGroupContainer,
            "SignatureModal": SignatureModalContainer,
            "ESignatureModal": ESignatureContainer,
            "PropertiesModal": PropertiesModalContainer,
            "EBA6IntegrationModal": LeftMenuEBA6IntegrationContainer,
            "StartupAnnouncementsModal": StartupAnnouncementsModalContainer,
            "DMShareModal": DMShareModalContainer,
            "CompleteBranchModal": CompleteBranchModal,
            "DMSubscriptionModal": DMSubscriptionModalContainer,
            "DrawerPanelComponent": DrawerPanelComponentContainer,
            ...HumanResourceFormDrawers
        },
        consoleUpdateLogEnable: false,
        SynergyLogonApp: "WebInterface",
    });
}


export function injectSaga(key: string, saga: any) {
    let store = getStore();

    (store as any).injectSaga(key, saga);
}

export function injectReducer(rootReducer: RootReducerType, key: string, reducer: ReducerType, override?: boolean) {
    if (!rootReducer.asyncReducers) {
        rootReducer.asyncReducers = {};
    }
    if (!override && rootReducer.asyncReducers[key]) return;

    rootReducer.asyncReducers[key] = reducer;
}

export function executeAsyncReducers(asyncReducers: AsyncReducers<any>, state: any, action: IBaseAction<any>) {
    for (let key in asyncReducers) {
        let reducer = asyncReducers[key];
        state.set(key, reducer(state.get(key), action));
    }
    return state;
}

