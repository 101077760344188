import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseDelegationPositionInfo {
    id: number,
    name: string,
    selected?: boolean,
}

export interface IDelegationPositionInfo extends IBaseDelegationPositionInfo {
    
}

export interface IReadonlyDelegationPositionInfo extends IBaseDelegationPositionInfo {
    
}

export type DelegationPositionInfo = ReadonlyRecord<IReadonlyDelegationPositionInfo>;

export const DelegationPositionInfoFactory = Record<IReadonlyDelegationPositionInfo>({
    id: null,
    name: null,
    selected: false
});

export const DelegationPositionInfoFactoryFromJS = function (data: IDelegationPositionInfo) {
    return DelegationPositionInfoFactory(data);
}