import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ConditionType, OperationType } from "./Enums";
import { Field, FieldFactory, FieldFactoryFromJS, IField } from "./IField";

interface IBaseCondition {
    id: string,
    operation: OperationType,
    typeName: ConditionType,
    value: any
}

export interface ICondition extends IBaseCondition {
    field: IField,
    selectedField: IField
}

export interface ReadOnlyCondition extends IBaseCondition {
    field: Field,
    selectedField: Field
}

export type Condition = ReadonlyRecord<ReadOnlyCondition>;

export const ConditionFactory = Record<ReadOnlyCondition>({
    id: null,
    operation: null,
    typeName: null,
    field: FieldFactory(),
    selectedField: FieldFactory(),
    value: null
});

export const ConditionFactoryFromJS = function (data: ICondition) {
    return ConditionFactory({
        ...data,
        field: FieldFactoryFromJS(data.field),
        selectedField: FieldFactoryFromJS(data.selectedField)
    });
}