import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { BaseResponse } from "../../../entities/BaseResponse";
import { ILoginUser, LoginUser, LoginUserFactory, LoginUserFactoryFromJS } from "./LoginUser";
import { IDashboardSettings, DashboardSettings, DashboardSettingsFactoryFromJS } from './DashboardSettings';


export interface IBaseGetUserDetailResponse extends BaseResponse {
    canNavigateIDE: boolean,
    canAccessConfigurationManager: boolean,
    canAccessProcessRequestManager: boolean,
    canAccessFlowManager: boolean,
    canAccessSecurity: boolean,
    canAccessMenuDesigner: boolean,
    notificationSoundStatus: boolean,
    canLoginAs?: boolean,
    canManageDelegations: boolean,
    canListDelegations: boolean,
    canViewLogs: boolean,
    canListAnnouncement: boolean,
    canCreateAnnouncement: boolean,
    canDeleteAnnouncement: boolean,
    canUpdateAnnouncement: boolean,
    canDocumentReport: boolean,
    canAccessActivities: boolean,
    canRecycleBin: boolean,
    canRecycleBinDelete: boolean,
    canRecycleBinRestore: boolean,
    canRecycleBinSettings: boolean,
}

export interface IGetUserDetailResponse extends IBaseGetUserDetailResponse {
    info: ILoginUser,
    dashboardSettings: IDashboardSettings
}

export interface IReadonlyGetUserDetailResponse extends IBaseGetUserDetailResponse {
    info: LoginUser,
    dashboardSettings: DashboardSettings
}

export type GetUserDetailResponse = ReadonlyRecord<IReadonlyGetUserDetailResponse>;

export const GetUserDetailResponseFactory = Record<IReadonlyGetUserDetailResponse>({
    info: LoginUserFactory(),
    dashboardSettings: null,
    canNavigateIDE: false,
    canAccessConfigurationManager: false,
    canAccessFlowManager: true,
    canAccessSecurity: false,
    canAccessMenuDesigner: false,
    notificationSoundStatus: false,
    canLoginAs: false,
    canListDelegations: false,
    canManageDelegations: false,
    canViewLogs: false,
    canListAnnouncement: false,
    canCreateAnnouncement: false,
    canDeleteAnnouncement: false,
    canUpdateAnnouncement: false,
    canDocumentReport: false,
    canAccessProcessRequestManager: false,
    canAccessActivities: false,
    canRecycleBin: false,
    canRecycleBinDelete: false,
    canRecycleBinRestore: false,
    canRecycleBinSettings: false,
});

export const GetUserDetailResponseFactoryFromJS = function (data: IGetUserDetailResponse) {
    return GetUserDetailResponseFactory({
        ...data,
        info: LoginUserFactoryFromJS(data.info),
        dashboardSettings: data.dashboardSettings ? DashboardSettingsFactoryFromJS(data.dashboardSettings) : null,
    });
}