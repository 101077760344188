import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IPropertyType, PropertyType, PropertyTypeFactoryFromJS, PropertyTypeFactory } from "..";
import { IBaseFormContent } from "../forms";

export interface IBasePropertyTypeFormContent extends IBaseFormContent {
}

export interface IPropertyTypeFormContent extends IBasePropertyTypeFormContent {
    showingPropertyType?: IPropertyType
}

export interface IReadonlyPropertyTypeFormContent extends IBasePropertyTypeFormContent {
    showingPropertyType?: PropertyType
}

export type PropertyTypeFormContent = ReadonlyRecord<IReadonlyPropertyTypeFormContent>;

export const PropertyTypeFormContentFactory = Record<IReadonlyPropertyTypeFormContent>({
    panelId: "",
    showingPropertyType: PropertyTypeFactory(),
    isModified: false,
    initialHash: "",
    showLoading : false
});

export const PropertyTypeFormContentFactoryFromJS = function (data: IPropertyTypeFormContent) {
    return PropertyTypeFormContentFactory({
        panelId: data.panelId,
        showingPropertyType: data.showingPropertyType ? PropertyTypeFactoryFromJS(data.showingPropertyType) : PropertyTypeFactory(), 
        isModified: data.isModified,
        initialHash: data.initialHash,
        showLoading : data.showLoading
    });
}