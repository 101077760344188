import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { DashboardFormData, IDashboardFormData } from '../entities/IDashboardFormData';

const getDashboardFormDataSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.dashboardFormData;
}

export const getDashboardFormData = createSelector(
    [
        getDashboardFormDataSelector
    ],
    (formData: DashboardFormData): IDashboardFormData => formData.toJS()
);


const getHasDashboardSelector = (state: WebInterfaceBaseState) => {
    return state.user.hasDashboardForm;
}

export const getHasDashboard = createSelector(
    [getHasDashboardSelector],
    (hasDashboard: boolean): boolean => hasDashboard
);