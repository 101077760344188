import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";
import { IPropertyChangeTransaction, PropertyChangeTransaction } from '.';
import { PropertyChangeTransactionFactory, PropertyChangeTransactionFactoryFromJS } from './IPropertyChangeTransaction';

export interface IBasePropertyChangeAction {
    windowId: string
}

export interface IPropertyChangeAction extends IBasePropertyChangeAction {
    data: IPropertyChangeTransaction
}

export interface IReadonlyBasePropertyChangeAction extends IBasePropertyChangeAction {
    data: PropertyChangeTransaction
}

export type PropertyChangeAction = ReadonlyRecord<IReadonlyBasePropertyChangeAction>;

export const PropertyChangeActionFactory = Record<IReadonlyBasePropertyChangeAction>({
    data: PropertyChangeTransactionFactory(),
    windowId: null
});

export const PropertyChangeActionFactoryFromJS = function (data: IPropertyChangeAction) {
    return PropertyChangeActionFactory({
        data: PropertyChangeTransactionFactoryFromJS(data.data),
        windowId: data.windowId
    });
}