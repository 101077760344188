import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseOAuthLoginRequest {
    id: string,
    language: string,
    useAuthCode?: boolean
}

export interface IOAuthLoginRequest extends IBaseOAuthLoginRequest {

}

export interface IReadonlyOAuthLoginRequest extends IBaseOAuthLoginRequest {

}

export type OAuthLoginRequest = ReadonlyRecord<IReadonlyOAuthLoginRequest>;

export const OAuthLoginRequestFactory = Record<IReadonlyOAuthLoginRequest>({
    id: "",
    language: "",
    useAuthCode: false
});

export const OAuthLoginRequestFactoryFromJS = function (data: IOAuthLoginRequest) {
    return OAuthLoginRequestFactory(data);
}