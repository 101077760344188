import Bowser, { BROWSER_MAP, OS_MAP, Parser } from "bowser";
import { IBrowserProperties } from "../../components/Login/entities/IBrowserProperties";

class BowserHelper {

    private static basicBlocking(browser: Parser.Parser, responseData: IBrowserProperties) {
        return responseData.browserList.includes(browser.getBrowserName());
    }

    private static advancedBlocking(browser: Parser.Parser, responseData: IBrowserProperties) {
        const ALL_PLATFORM = { ...BROWSER_MAP, ...OS_MAP };
        const operator = { equal: "=", greaterEqual: ">=", lessEqual: "<=", greater: ">", less: "<", dash: "-", empty: " " };
        const blockedList = responseData.customBrowserQuery.split(',');

        let isBlocked = false;

        for (const block of blockedList) {
            const b = block.trim();

            Object.keys(ALL_PLATFORM).forEach((m) => {
                const val = ALL_PLATFORM[m];

                if (b.indexOf(val) !== -1) {

                    if (b.indexOf(operator.dash) !== -1) {
                        const emptySplit = b.split(operator.empty);
                        const dashSplit = emptySplit[emptySplit.length - 1].split("-");

                        const version1 = parseFloat(dashSplit[0]);
                        const version2 = parseFloat(dashSplit[1]);

                        if (browser.satisfies({ [m]: `${operator.greater}${version1}` }) && browser.satisfies({ [m]: `${operator.less}${version2}` })) {
                            isBlocked = true;
                        }
                    } else {
                        Object.keys(operator).forEach((o) => {
                            const op = operator[o];

                            if (b.indexOf(op) !== -1) {
                                const splitOperator = b.split(op);

                                if (browser.satisfies({ [m]: `${op}${splitOperator[splitOperator.length - 1]}` })) {
                                    isBlocked = true;
                                }
                            }
                        });
                    }

                }
            });
        }

        return isBlocked;
    }

    public static checkBrowserType(browser: Parser.Parser, responseData: IBrowserProperties) {
        const isBlockedBrowser: boolean = responseData.blockingType == 1 ? this.basicBlocking(browser, responseData) : this.advancedBlocking(browser, responseData);
        return isBlockedBrowser;
    }
}

export { Bowser, BowserHelper };

