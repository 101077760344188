import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { FieldValueType } from ".";

interface IBaseField {
    name: string,
    propertyName: string,
    type: FieldValueType
}

export interface IField extends IBaseField {
    enumMembers: Array<string>
}

export interface ReadOnlyField extends IBaseField {
    enumMembers: List<string>
}

export type Field = ReadonlyRecord<ReadOnlyField>;

export const FieldFactory = Record<ReadOnlyField>({
    name: null,
    propertyName: null,
    type: null,
    enumMembers: List()
});

export const FieldFactoryFromJS = function (data: IField) {

    if (!data) return null;

    return FieldFactory({
        ...data,
        enumMembers: List(data.enumMembers)
    });
}