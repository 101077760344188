import * as React from "react";
import { findMessage } from '..';
import * as Styles from './assets/style.scss';
import { IEmpty } from './entities';
import EmptyIcon from "./components/EmptyIcon";

export default class BCEmpty extends React.Component<IEmpty, {}> {

    render() {
        return (
            <div className={[Styles.empty, this.props.className].join(' ')} style={this.props.style}>
                <div className={'darkEmptyBox'}><EmptyIcon theme={'dark'} /></div>
                <div className={'lightEmptyBox'}><EmptyIcon theme={'light'} /></div>
                <p>{this.props.description || findMessage.get('100702')}</p>
                {this.props.children}
            </div>
        )
    }

}

export { IEmpty };
