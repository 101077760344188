import { List, Record } from 'immutable';
import { IItem, Item, ItemFactoryFromJS, ReadonlyRecord } from '@bimser/core';
import IToolboxClickEventArgs from './IToolboxClickEventArgs';
import IToolboxDoubleClickEventArgs from './IToolboxDoubleClickEventArgs';
import IToolboxDragEndEventArgs from './IToolboxDragEndEventArgs';
import IToolboxDragEventArgs from './IToolboxDragEventArgs';
import IToolboxDragStartEventArgs from './IToolboxDragStartEventArgs';
import { IconInfo } from '@bimser/icons';

interface ICToolboxItem {
    onDrag?: (arg: IToolboxDragEventArgs) => any,
    onDragStart?: (arg: IToolboxDragStartEventArgs) => any,
    onDragEnd?: (arg: IToolboxDragEndEventArgs) => any,
    onClick?: (arg: IToolboxClickEventArgs) => any,
    onDoubleClick?: (arg: IToolboxDoubleClickEventArgs) => any
}

export interface IBaseToolboxItem extends ICToolboxItem {
    key: string | number,
    icon?: IconInfo,
    caption: string,
    dataRef?: any,
    draggable: boolean,
    dropTarget?: string,
    type?: any,
    iconColor?: string,
    additionalLabel?: string,
    passive?: boolean,
    selected?: boolean
}

export interface IToolboxItem extends IBaseToolboxItem {
    contextMenuItems?: IItem[]
}
export interface IReadonlyToolboxItem extends IBaseToolboxItem {
    contextMenuItems?: List<Item>
}

export type ToolboxItem = ReadonlyRecord<IReadonlyToolboxItem>;

export const ToolboxItemFactory = Record<IReadonlyToolboxItem>({
    key: '',
    caption: '',
    dataRef: null,
    draggable: false,
    dropTarget: '',
    icon: null,
    type: null,
    onClick: null,
    onDrag: null,
    onDoubleClick: null,
    onDragEnd: null,
    onDragStart: null,
    iconColor: '',
    additionalLabel: null,
    passive: null,
    selected: null,
    contextMenuItems: List()
});

export const ToolboxItemFactoryFromJS = function (data: IToolboxItem) {

    let contextMenuItems = data && data.contextMenuItems ? data.contextMenuItems.map(i => {
        return ItemFactoryFromJS(i)
    }) : [];

    return ToolboxItemFactory({
        ...data,
        contextMenuItems: List(contextMenuItems)
    });
}