import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';

interface IBaseNavigationHistoryItem{
    id: string;
    name: string;
    secretKey: string;
}

export interface INavigationHistoryItem extends IBaseNavigationHistoryItem {
    
}

export interface IReadonlyNavigationHistoryItem extends IBaseNavigationHistoryItem {
    
}

export type NavigationHistoryItem = ReadonlyRecord<IReadonlyNavigationHistoryItem>;

export const  NavigationHistoryItemFactory = Record<IReadonlyNavigationHistoryItem>({
    name: null,
    secretKey: null,
    id: null
});

export const NavigationHistoryItemFactoryFromJS = function (data: INavigationHistoryItem) {
    return NavigationHistoryItemFactory(data);
}