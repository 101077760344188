import { Record } from 'immutable';
import { ReadonlyRecord } from "@bimser/core";
import { IconInfo } from '@bimser/icons';

export interface IBaseButtonListItem {
    id: string,
    title: string,
    icon?: IconInfo,
    externalData: any
}

export interface IButtonListItem extends IBaseButtonListItem {
}

export interface IReadonlyButtonListItem extends IBaseButtonListItem {
}

export type ButtonListItem = ReadonlyRecord<IReadonlyButtonListItem>;

export const ButtonListItemFactory = Record<IReadonlyButtonListItem>({
    id: null,
    title: null,
    icon: null,
    externalData: null
});

export const ButtonListItemFactoryFromJS = function (data: IButtonListItem) {
    return ButtonListItemFactory(data);
}