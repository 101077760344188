import { isNullOrUndefined, ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import * as moment from 'moment';
import ContentStatus from './ContentStatus';

export interface IBaseDmContent {
    name?: string,
    description?: string,
    secretKey?: string,
    deletedBy?: string,
    deletedAt?: string,
    createdBy?: string,
    createdAt?: string,
    modifiedBy?: string,
    modifiedAt?: string,
    type?: number,
    id: number,
    isUploaded?: boolean,
    isBranch?: boolean,
    isDefault?: boolean,
    version?: string;
    versionId?: number;
    versionSecretKey?: string;
    length?: number;
    commitId?: number;
    mimeType?: string;
    status?: ContentStatus;
}

export interface IDmContent extends IBaseDmContent {
}

export interface IReadonlyDmContent extends IBaseDmContent {

}

export type DmContent = ReadonlyRecord<IReadonlyDmContent>;

export const DmContentFactory = Record<IReadonlyDmContent>({
    secretKey: null,
    name: null,
    description: null,
    deletedBy: null,
    deletedAt: moment().format(),
    createdBy: null,
    createdAt: null,
    modifiedBy: null,
    modifiedAt: moment().format(),
    type: 0,
    id: 0,
    isUploaded: true,
    isBranch: false,
    isDefault: false,
    version: null,
    versionId: null,
    versionSecretKey: null,
    length: null,
    commitId: null,
    mimeType: null,
    status: ContentStatus.NotChanged
});

export const DmContentFactoryFromJS = function (data: IDmContent) {
    if (isNullOrUndefined(data)) return DmContentFactory();

    return DmContentFactory({
        secretKey: data.secretKey,
        name: data.name,
        description: data.description,
        deletedBy: data.deletedBy,
        deletedAt: data.deletedAt,
        createdBy: data.createdBy,
        createdAt: data.createdAt,
        modifiedBy: data.modifiedBy,
        modifiedAt: data.modifiedAt,
        type: data.type,
        id: data.id,
        isUploaded: data.isUploaded,
        isBranch: data.isBranch,
        isDefault: data.isDefault,
        version: data.version,
        versionId: data.versionId,
        versionSecretKey: data.versionSecretKey,
        commitId: data.commitId,
        length: data.length,
        mimeType: data.mimeType,
        status: data.status
    });
}
