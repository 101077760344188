import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IReadonlyLoginResponseWithMFAData, ILoginResponseWithMFAData } from "./LoginResponseWithMFAData";


export interface ISetMFAPropertiesPayload extends ILoginResponseWithMFAData {
    currentLoginProcedure?: string;
}

export interface IReadonlySetMFAPropertiesPayload extends IReadonlyLoginResponseWithMFAData {
    currentLoginProcedure?: string;
}

export type SetMFAPropertiesPayload = ReadonlyRecord<IReadonlySetMFAPropertiesPayload>;

export const SetMFAPropertiesPayloadFactory = Record<IReadonlySetMFAPropertiesPayload>({
    token: null,
    authCode: null,
    mfaEnabled: false,
    tempTokenKey: null,
    currentLoginProcedure: null
});

export const SetMFAPropertiesPayloadFactoryFromJS = function (data: ISetMFAPropertiesPayload) {
    return SetMFAPropertiesPayloadFactory({
        token: data.token,
        authCode: data.authCode,
        mfaEnabled: data.mfaEnabled,
        tempTokenKey: data.tempTokenKey,
        currentLoginProcedure: data.currentLoginProcedure
    });
};