import * as React from "react";

export default class EmptyIcon extends React.Component<{ theme: 'dark' | 'light' }, {}> {

    render() {
        if (this.props.theme === 'dark') {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 41">
                    <g>
                        <path fill="#4C5663" d="M64,34c0,-3.87 -14.3,-7 -32,-7c-17.7,0 -32,3.13 -32,7c0,3.87 14.3,7 32,7c17.7,0 32,-3.13 32,-7z" />
                        <g>
                            <path fill="transparent" stroke="#606974" d="M55,13.76l-10.15,-11.5c-0.49,-0.78 -1.2,-1.26 -1.95,-1.26h-21.81c-0.75,0 -1.46,0.47 -1.95,1.26l-10.15,11.5v9.24h46z" />
                            <path fill="#384352" stroke="#606974" d="M41.61,16.93v0.01c0,1.61 -1.01,2.91 -2.24,2.91h-14.75c-1.23,0 -2.24,-1.3 -2.24,-2.9v-0.02c0,-1.61 -0.99,-2.93 -2.23,-2.93h-11.16v18.14c0,2.12 1.32,3.86 2.95,3.86h40.1c1.63,0 2.95,-1.74 2.95,-3.86v-18.14h-11.16c-1.23,0 -2.23,1.33 -2.23,2.93z" />
                        </g>
                    </g>
                </svg>
            )
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 41">
                    <g>
                        <path fill="#f5f5f5" d="M64,34c0,-3.87 -14.3,-7 -32,-7c-17.7,0 -32,3.13 -32,7c0,3.87 14.3,7 32,7c17.7,0 32,-3.13 32,-7z" />
                        <g>
                            <path fill="transparent" stroke="#d3d3d3" d="M55,13.76l-10.15,-11.5c-0.49,-0.78 -1.2,-1.26 -1.95,-1.26h-21.81c-0.75,0 -1.46,0.47 -1.95,1.26l-10.15,11.5v9.24h46z" />
                            <path fill="#fafafa" stroke="#d3d3d3" d="M41.61,16.93v0.01c0,1.61 -1.01,2.91 -2.24,2.91h-14.75c-1.23,0 -2.24,-1.3 -2.24,-2.9v-0.02c0,-1.61 -0.99,-2.93 -2.23,-2.93h-11.16v18.14c0,2.12 1.32,3.86 2.95,3.86h40.1c1.63,0 2.95,-1.74 2.95,-3.86v-18.14h-11.16c-1.23,0 -2.23,1.33 -2.23,2.93z" />
                        </g>
                    </g>
                </svg>
            )
        }
    }

}