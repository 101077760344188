import * as React from "react";
import { BCButton, BCTextArea, BCInputGroup } from "..";
import { findMessage } from "../BCIntl";
import BCLabel from "../BCLabel";
import BCPopover from "../BCPopover";
import BCScrollbars from "../BCScrollbars";
import { ITextAreaChangeEventArgs } from "../BCTextArea/entities";
import * as Style from './assets/style.scss';
import { IProps } from './entities';
import IconLanguage from "@bimser/icons/16/language";

const inputStyle: React.CSSProperties = { width: 'calc(100% - 35px)' };
const buttonStyle: React.CSSProperties = { width: '35px', fontWeight: 900, fontSize: '14px' };

export default class BCTextAreaMultiLanguage extends React.Component<IProps, {}>{
    scrollStyle: React.CSSProperties = { height: '100%', width: '100%' };
    iconStyle: React.CSSProperties = { cursor: 'pointer', marginLeft: 5 };
    overlayStyle: React.CSSProperties = { maxWidth: 425 };
    labelStyle: React.CSSProperties = { fontSize: 13 };

    constructor(props: IProps) {
        super(props);
        this.renderPopoverContent = this.renderPopoverContent.bind(this);
        this.onLanguageValueChange = this.onLanguageValueChange.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
    }

    renderPopoverContent() {
        return (<div className={Style.hrFormMLPopover}>
            <BCScrollbars styles={this.scrollStyle} >
                {
                    this.props.supportedLanguages.map((item: any) => {
                        if (item.id != this.props.currentLanguage.id) {
                            return (<div
                                className={Style.popoverRow}
                                key={item.name}>
                                <div className={"ant-col-7 ant-col-offset-1 ant-form-item-label"}>
                                    <label title={item.name}>{item.name}</label>
                                </div>
                                <div className={"ant-col-15 ant-col-offset-1 "}>
                                    <BCTextArea
                                        itemKey={item.culture}
                                        onChange={this.onLanguageValueChange}
                                        value={this.props.values && this.props.values[item.culture]}
                                        autosize={{ minRows: 3, maxRows: 5 }}
                                        regExp={this.props.regExp}
                                        {...(this.props.innerInputProps || {})}
                                    />
                                </div>
                            </div>);
                        }
                    })
                }
            </BCScrollbars>
        </div>);
    }

    onLanguageValueChange(e: ITextAreaChangeEventArgs) {
        if (this.props.onLanguageValueChange) {
            this.props.onLanguageValueChange(e.itemKey, e.data);
        }
    }

    onVisibilityChange(visible: boolean) {
        if (this.props.onVisibilityChange) {
            this.props.onVisibilityChange(visible);
        }
    }

    renderPopoverTitle() {
        return this.props.title || (<BCLabel customStyles={this.labelStyle}>{findMessage.get("100775")}</BCLabel>);
    }

    renderInput() {

        //Custom input
        if (this.props.renderInput) {
            return (
                <div style={inputStyle}>
                    {this.props.renderInput()}
                </div>
            )
        }

        return (
            <BCTextArea
                placeHolder={this.props.placeHolder}
                itemKey={this.props.currentLanguage.culture}
                onChange={this.onLanguageValueChange}
                value={this.props.values && this.props.values[this.props.currentLanguage.culture]}
                regExp={this.props.regExp}
                style={inputStyle}
                rows={this.props.rows}
            />
        )
    }

    render() {
        return (
            <BCInputGroup compact={true}>
                {this.renderInput()}
                <BCPopover
                    content={this.renderPopoverContent()}
                    title={this.renderPopoverTitle()}
                    trigger={this.props.trigger || "click"}
                    placement={this.props.placement || "leftTop"}
                    overlayStyle={this.overlayStyle}
                    onVisibleChange={this.onVisibilityChange}>
                    <BCButton
                        style={buttonStyle}
                        size={this.props.size} icon={<IconLanguage />} />
                </BCPopover>
            </BCInputGroup>
        );

    }
}
export { IProps as ITextAreaMultiLanguageProps };

