import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IProfessionMaintenanceListFormContent extends IBaseFormContent {
    // ProfessionReplacements?: Array<IReplacement>,
    selectedReplacement?: IReplacement
}

export interface IReadonlyProfessionMaintenanceListFormContent extends IBaseFormContent {
    // ProfessionReplacements: List<Replacement>,
    selectedReplacement: Replacement
}

export type ProfessionMaintenanceListFormContent = ReadonlyRecord<IReadonlyProfessionMaintenanceListFormContent>;

export const ProfessionMaintenanceListFormContentFactory = Record<IReadonlyProfessionMaintenanceListFormContent>({
    panelId: "",
    // ProfessionReplacements: List<Replacement>(),
    selectedReplacement: ReplacementFactory(),
    showLoading: false,
    editWindowGuid: "",
    editWindowisActive: false,
    selectedCompanyId: 0
});

export const ProfessionMaintenanceListFormContentFactoryFromJS = function (data: IProfessionMaintenanceListFormContent) {
    // let _ProfessionReplacements: Array<Replacement> = data.ProfessionReplacements && data.ProfessionReplacements.length ? data.ProfessionReplacements.map((replacement) => {
    //     return ReplacementFactoryFromJS(replacement);
    // }) : [];

    return ProfessionMaintenanceListFormContentFactory({
        panelId: data.panelId,
        // ProfessionReplacements: List(_ProfessionReplacements),
        selectedReplacement: ReplacementFactoryFromJS(data.selectedReplacement),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        selectedCompanyId: data.selectedCompanyId
    });
}