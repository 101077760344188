/**
 * Bir array iersinde x indexinden y indexine veriyi kaydırmak için kullanılır.
 * @param arr
 * @param old_index
 * @param new_index
 */
export default function (arr: Array<any>, old_index: number, new_index: number) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
}