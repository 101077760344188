import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { BaseResponse } from "../../../entities/BaseResponse";

export interface IGetUserSettingPermissionsResponse extends BaseResponse {
    canChangePersonelSetting: boolean,
    canChangeGlobalizationUser: boolean,
    canChangeLocation: boolean,
    canChangePassword: boolean
}

export interface IReadonlyGetUserSettingPermissionsResponse extends BaseResponse {
    canChangePersonelSetting: boolean,
    canChangeGlobalizationUser: boolean,
    canChangeLocation: boolean,
    canChangePassword: boolean
}

export type GetUserSettingPermissionsResponse = ReadonlyRecord<IReadonlyGetUserSettingPermissionsResponse>;

export const GetUserSettingPermissionsResponseFactory = Record<IReadonlyGetUserSettingPermissionsResponse>({
    canChangePersonelSetting: false,
    canChangeGlobalizationUser: false,
    canChangeLocation: false,
    canChangePassword: false
});

export const GetUserSettingPermissionsResponseFactoryFromJS = function (data: IGetUserSettingPermissionsResponse) {
    return GetUserSettingPermissionsResponseFactory({
        canChangePersonelSetting: data.canChangePersonelSetting,
        canChangeGlobalizationUser: data.canChangeGlobalizationUser,
        canChangeLocation: data.canChangeLocation,
        canChangePassword: data.canChangePassword
    });
}