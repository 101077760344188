import { Map } from "immutable";
import { NormalizedLayoutStructureItems } from "../../../common/entities";

// a little function to help us with reordering the result
export const reorder = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    parentItemId: string,
    startIndex: number,
    endIndex: number
) => {
    const view = views.get(activeView);
    const parentItem = view.entities.items.get(parentItemId);
    const removed = parentItem.items.get(startIndex);
    let newItems = parentItem.items.remove(startIndex);
    if (startIndex < endIndex) endIndex--;
    newItems = newItems.splice(endIndex, 0, removed); // inserting task in new index

    return views.setIn([activeView, 'entities', 'items', parentItemId, 'items'], newItems);
};