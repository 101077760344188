import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { FormItemTypes, IBaseAction } from "@bimser/core";
import { Map } from 'immutable';
import { CHANGE_PROPERTY, CLOSE_SUMMARY_MESSAGE, FORM_ITEMS_LOADED, FORM_WILL_CLOSE_ACTION, SHOW_SUMMARY_MESSAGES } from '../actionTypes';
import { PropertyChangeAction, ShowSummaryMessageClosePayload, ShowSummaryMessagePayload } from "../entities";
import { FormsState, FormsStateFactory } from "../entities/IFormsState";
import { overridePayload, setOtherChangeRequirements } from './helpers';

export default function (state: Map<string, FormsState> = Map(), action: IBaseAction<any>): Map<string, FormsState> {
    switch (action.type) {
        case FORM_ITEMS_LOADED: {
            return formItemsLoaded(state, action);
        }
        case CHANGE_PROPERTY: {
            return itemPropertyChanged(state, action);
        }
        case FORM_WILL_CLOSE_ACTION: {
            return state.delete(action.payload.data)
        }
        case SHOW_SUMMARY_MESSAGES: {
            return showSummaryMessages(state, action);
        }
        case CLOSE_SUMMARY_MESSAGE: {
            return removeSummaryMessages(state, action);
        }
        default:
            return state;
    }
}

export function formItemsLoaded(state: Map<string, FormsState>, action: IBaseAction<LayoutCreatorEntities.FormItemsLoadAction>): Map<string, FormsState> {

    if (!action.payload.isFirstLoad) {
        const isParentFormViewer = action.payload.formId.includes(formViewerKey);
        const isParentRepeater = action.payload.formId.includes(repeaterKey);

        //Increase parent FormViewer's or Repeater's commitId

        if (isParentFormViewer || isParentRepeater) {
            const idArray = action.payload.formId.split(isParentFormViewer ? formViewerKey : repeaterKey);
            const parentWindowId = state.findKey((_, key) => key.startsWith(idArray[0]));
            const parentId = idArray[1].split("_")[0];

            if (parentWindowId) {
                const currentCommitId = state.getIn([parentWindowId, "items", "entities", "items", parentId, "properties", "commitId"]);
                const nextCommitId = Number.isInteger(currentCommitId) ? (currentCommitId + 1) : 0;
                state = state.setIn([parentWindowId, "items", "entities", "items", parentId, "properties", "commitId"], nextCommitId);
                state = state.setIn([parentWindowId, "dataItems", parentId, "properties", "commitId"], nextCommitId);
            }
        }
    }

    return state.set(action.payload.formId, FormsStateFactory({
        items: action.payload.items,
        dataItems: action.payload.dataItems,
        structureItems: action.payload.structureItems,
        documentId: action.payload.documentId,
        lastBatchGuid: action.payload.lastBatchGuid,
        overrideClientUrl: action.payload.clientUrl,
        runSecretKey: action.payload.runSecretKey
    }));
}

export function showSummaryMessages(state: Map<string, FormsState>, action: IBaseAction<ShowSummaryMessagePayload>): Map<string, FormsState> {

    let window = state.get(action.payload.windowId);
    let newMessages = window.summaryMessages;

    action.payload.messages.forEach(message => {
        const existAlready = newMessages.find(m => m.title === message.title);
        if (!existAlready) {
            newMessages = newMessages.set(newMessages.count(), message);
        }
    });

    return state.setIn([action.payload.windowId, 'summaryMessages'], newMessages);
}

export function removeSummaryMessages(state: Map<string, FormsState>, action: IBaseAction<ShowSummaryMessageClosePayload>): Map<string, FormsState> {

    let newMessages = state.get(action.payload.windowId).summaryMessages.filter(m => m.title !== action.payload.messageKey);
    return state.setIn([action.payload.windowId, 'summaryMessages'], newMessages);

}

const formViewerKey = `_${FormItemTypes.EControlTypes.FormViewer}_`;
const repeaterKey = `_${FormItemTypes.EControlTypes.Repeater}_`;

const itemPropertyChanged = (state: Map<string, FormsState>, action: IBaseAction<PropertyChangeAction>): any => {

    if (state.has(action.payload.windowId)) {

        const isParentFormViewer = action.payload.windowId.includes(formViewerKey);
        const isParentRepeater = action.payload.windowId.includes(repeaterKey);

        const window = state.get(action.payload.windowId);

        if (window.items.entities.items) {

            action.payload.data.data.forEach(changeData => {

                let items = state.get(action.payload.windowId).items.entities.items;
                const ghostItems = state.get(action.payload.windowId).items.entities.ghostItems;

                let targetGhostItems = false;
                if (ghostItems?.count()) {
                    const item = ghostItems.get(changeData.id)
                    if (item && FormItemTypes.EGhostItemTypes[item.type]) {
                        items = ghostItems;
                        targetGhostItems = true;
                    }
                }

                if (items.has(changeData.id)) {
                    const item = items.get(changeData.id);
                    let path = changeData.dataField.toJS();

                    if (FormItemTypes.EControlTypes[item.type] || item.type === FormItemTypes.EContainerTypes.Form) {

                        let controlProperties = items.get(changeData.id).properties;
                        controlProperties = controlProperties.setIn(path, changeData.value);
                        controlProperties = overridePayload(controlProperties, items.get(changeData.id).type, path.join());

                        //Increase commitId
                        let commitId = controlProperties.get("commitId");
                        if (Number.isInteger(commitId)) {
                            commitId++;

                            if (isParentFormViewer || isParentRepeater) {
                                //Increase parent FormViewer's or Repeater's commitId
                                const idArray = action.payload.windowId.split(isParentFormViewer ? formViewerKey : repeaterKey);
                                const parentWindowId = state.findKey((_, key) => key.startsWith(idArray[0]));
                                const parentId = idArray[1].split("_")[0];

                                if (parentWindowId) {
                                    const currentCommitId = state.getIn([parentWindowId, "items", "entities", "items", parentId, "properties", "commitId"]);
                                    const nextCommitId = Number.isInteger(currentCommitId) ? (currentCommitId + 1) : 0;
                                    state = state.setIn([parentWindowId, "items", "entities", "items", parentId, "properties", "commitId"], nextCommitId);
                                    state = state.setIn([parentWindowId, "dataItems", parentId, "properties", "commitId"], nextCommitId);
                                }
                            }
                        } else {
                            commitId = 0;
                        }

                        const dataField = changeData.get("dataField").first() as string;
                        const mode = controlProperties.getIn(["editing", "mode"]) as string;
                        if (item.type === FormItemTypes.EControlTypes.DataGrid && dataField === "rows" && mode === "cell") commitId--;

                        controlProperties = controlProperties.set("commitId", commitId);

                        const isEffectiveProperty = ["readOnly", "clientEnabled", "enabled"].includes(dataField);
                        if (isEffectiveProperty) {

                            const mustIncreaseList: string[] = Object.values({ ...FormItemTypes.EControlTypes, ...FormItemTypes.EPanelBaseTypes });

                            const isForm = items.get(changeData.id).type === FormItemTypes.EContainerTypes.Form;
                            if (isForm) {
                                const increaseCommitId = (control: LayoutCreatorEntities.LayoutItem) => {
                                    if (mustIncreaseList.includes(control.type)) {
                                        return control.setIn(["properties", "commitId"], control.properties.get("commitId", -1) + 1)
                                    }

                                    return control;
                                }

                                state = state.setIn([action.payload.windowId, "items", "entities", "items"], state.get(action.payload.windowId).items.entities.items.map(increaseCommitId))
                                    .setIn([action.payload.windowId, "items", "entities", "ghostItems"], state.get(action.payload.windowId).items.entities.ghostItems.map(increaseCommitId))
                                    .setIn([action.payload.windowId, "dataItems"], state.get(action.payload.windowId).dataItems.map(increaseCommitId));

                            }

                            const isPanelBase = (Object.values(FormItemTypes.EPanelBaseTypes) as string[]).includes(items.get(changeData.id).type);
                            if (isPanelBase) {
                                const panelChildList: string[] = [];

                                const getPanelChilds = (controlName: string) => {
                                    const control = state.get(action.payload.windowId).structureItems.entities.items.get(controlName);

                                    if (mustIncreaseList.includes(control.type)) {
                                        panelChildList.push(controlName);
                                    }

                                    control.items.forEach(getPanelChilds);
                                }

                                state.get(action.payload.windowId).structureItems.entities.items.get(changeData.id).items.forEach(getPanelChilds)

                                panelChildList.forEach(controlName => {
                                    const nextCommitId = state.getIn([action.payload.windowId, "items", "entities", "items", controlName, "properties", "commitId"], -1) + 1;

                                    state = state.setIn([action.payload.windowId, "items", "entities", "items", controlName, "properties", "commitId"], nextCommitId);
                                    state = state.setIn([action.payload.windowId, "dataItems", controlName, "properties", "commitId"], nextCommitId);
                                })

                            }

                        }

                        state = state.setIn([action.payload.windowId, "items", "entities", (targetGhostItems ? "ghostItems" : "items"), changeData.id, "properties"], controlProperties);

                        state = state.setIn([action.payload.windowId, "dataItems", changeData.id, "properties"], controlProperties);
                        state = state.setIn([action.payload.windowId, "endTransactionGuid"], action.payload.data.transactionId);

                        if (!targetGhostItems) state = setOtherChangeRequirements(state, changeData, action);

                    } else if (item.type === FormItemTypes.EContainerTypes.Section || item.type === FormItemTypes.EContainerTypes.Column) {

                        let viewItemProperties = items.get(changeData.id).designerProps;
                        viewItemProperties = viewItemProperties.setIn(path, changeData.value);

                        state = state.setIn([action.payload.windowId, "items", "entities", "items", changeData.id, "designerProps"], viewItemProperties);
                        state = state.setIn([action.payload.windowId, "structureItems", "entities", "items", changeData.id, "designerProps"], viewItemProperties);
                        state = state.setIn([action.payload.windowId, "endTransactionGuid"], action.payload.data.transactionId);
                    }

                }

            })

            return state


        }

    }

    return state

}