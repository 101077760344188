import React, { lazy, Suspense } from 'react';
import IWFManagementProps from "./entities/IWFManagementProps"
import { injectSaga } from "app-init"

const WorkflowManagementContainerLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./containers"));

function WFManagementContainer(props: React.PropsWithChildren<IWFManagementProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <WorkflowManagementContainerLazy {...props} />
        </Suspense>
    );
}
WFManagementContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-workflow" */ "./sagas").then(({ default: saga }) => {
        injectSaga("workflowManagement", saga);
    })
}

export default WFManagementContainer;