import React, { lazy, Suspense } from 'react';
import BCErrorBoundary from "../BCErrorBoundary"
import * as BCTreeListEntities from "./entities";

const ReactiveTreeListStateful = lazy(
    () => import(/* webpackChunkName: "grid" */ "./Stateful").then(module => ({ default: module.default }))
);
const ReactiveTreeListStateless = lazy(
    () => import(/* webpackChunkName: "grid" */ "./BCTreeListWithSelectors").then((module: any) => ({ default: module.default }))
);

function BCTreeListStateful(props: BCTreeListEntities.IStatefulTreeListProps) {
    return (
        <BCErrorBoundary>
            <Suspense fallback={<div></div>}>
                <ReactiveTreeListStateful {...props} />
            </Suspense>
        </BCErrorBoundary>
    );
}
function BCTreeListStateless(props: BCTreeListEntities.IBCTreeListProps) {
    return (
        <BCErrorBoundary>
            <Suspense fallback={<div></div>}>
                <ReactiveTreeListStateless {...props} />
            </Suspense>
        </BCErrorBoundary>
    );
}
BCTreeListStateful.Core = BCTreeListStateless;

export default BCTreeListStateful;


export { BCTreeListEntities }
