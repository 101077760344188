import * as React from "react";
import BCConditionalStatement from "../../BCConditionalStatement";
import { IRuleDetail } from "../entities";

const RuleDetail = React.memo((props: IRuleDetail) => {
    return (
        <BCConditionalStatement
            rule={props.rule}
            fields={props.fields}
            onDataChanged={props.onDataChanged}
            readonly
        />
    )
})

export default RuleDetail