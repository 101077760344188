export enum SignMethodType {
    Both = 0,
    Counter = 1,
    Parallel = 2
}

export enum SignatureFormat {
    CAdES = 0,
    PAdES = 1,
    XAdES = 2
}

export enum SignatureType {
    BES,
    EPES,
    EST,
    C,
    XL,
    Av2,
    LTV,
    TIMESTAMP
}

export enum SignatureDataType {
    Data,
    DmObject
}

export interface ICertificate {
    id: string,
    ownerNo: string,
    ownerName: string,
    isExpired: boolean,
    issuer: string,
    certificateHex: any,
    serialNumber: string,
    name: string,
    moneyLimit?: number
}

export interface ITerminal {
    name: string,
    library: string,
    slotId: string,
    id: string
}

export interface ISignPayload {
    signatureFormat: SignatureFormat,
    signatureType: SignatureType,
    cAdESSignatureType?: SignMethodType
    terminal: ITerminal,
    certificate: ICertificate,
    password: string,
    addTimeStamp: any,
    embeddedSignature: any,
    dataType: SignatureDataType,
    data?: any,
    secretKey?: string;
    versionSecretKey?: string;
    isParallel?: boolean;
}

export interface IFinalizeSigningPayload {
    signature: string,
    secretKey: string
    transactionUUID: string,
    type: SignType,
    resolve: Function,
    reject: Function
}

export interface IDSAuthenticatePayload {
    term: ITerminal,
    cert: ICertificate,
    password: string,
    language: string
}

export type SignType = "login" | "dm" | "flow";