import { createSelector, OutputSelector } from "reselect";
import { LanguageState, IReadonlyLanguageState } from "../entities";
import { BaseState, IReadonlyBaseState } from "../../../entities/BaseState";
import { Record } from 'immutable'
import { ILanguage } from "@bimser/core";


const languageParameters = (state: BaseState) => state.system.language;

const getLanguages = createSelector(
    [
        languageParameters
    ],
    (parameters: LanguageState): ILanguage[] => {
        return parameters.items.toJS();
    }
);

export {
    getLanguages
}