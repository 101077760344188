import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseFlowDocumentViewOptions {
    projectName: string,
    formName: string,
    viewName: string,
    formCaption: string,
    allowEdit: boolean,
    showEvents: boolean,
    existingDocumentPath: string,
    panelSize?: 1 | 2 | 3,
}

export interface IFlowDocumentViewOptions extends IBaseFlowDocumentViewOptions {}

export interface IReadonlyFlowDocumentViewOptions extends IBaseFlowDocumentViewOptions {}

export type FlowDocumentViewOptions = ReadonlyRecord<IReadonlyFlowDocumentViewOptions>;

export const FlowDocumentViewOptionsFactory = Record<IReadonlyFlowDocumentViewOptions>({
   projectName: null,
   formName: null,
   viewName: "default",
   formCaption: null,
   allowEdit: true,
   showEvents: true,
   existingDocumentPath: null,
   panelSize: 2
});

export const FlowDocumentViewOptionsFactoryFromJS = function (data: IFlowDocumentViewOptions) {
    return FlowDocumentViewOptionsFactory(data);
}