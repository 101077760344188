import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { BaseState } from "../../../entities/BaseState";
import { IGetLoginParametersRequest } from "../../Login/entities/GetLoginParametersRequest";
import Router from "../components/Router";
import IRouterCProps from "../entities/IRouterCProps";
import IRouterProps from "../entities/IRouterProps";
import { setDelegationInformationAction } from "../../../action/setDelegationInformationAction";
import { IOAuthLoginRequest } from "../../Login/entities/IOAuthLoginRequest";
import { loginParametersTransactionAction, controlTokenTransactionAction, onSSOControlAction, onOAuthLoginTransaction } from '../../Login/actions';

// state to component properties
const mapStateToProps = function (state: BaseState, props: IRouterProps): IRouterProps {
    return {
        isAuthenticated: state.user.token ? true : false,
        checkAuthenticationFinished: state.user.chekAuthenticationFinished,
        SynergyLogonApp: props.SynergyLogonApp,
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IRouterCProps {
    return {
        getLoginParameters: function (data: IGetLoginParametersRequest) {
            dispatch(loginParametersTransactionAction(data));
        },
        controlToken: function () {
            dispatch(controlTokenTransactionAction());
        },
        setDelegateInformation: function(data: any){
            dispatch(setDelegationInformationAction(data));
        },
        onOAuthLogin: function(data: IOAuthLoginRequest){
            dispatch(onOAuthLoginTransaction(data));
        },
        onSSOControl: function(){
            dispatch(onSSOControlAction());
        }
    };
}

// react - redux connection
const RouterContainer = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Router);

export default RouterContainer;