import { FormItemTypes } from "@bimser/core";
import { List, Map } from "immutable";
import { generateId, getItemByPath, insertToView } from ".";
import { LayoutDataItem, LayoutItem, LayoutStructureItemFactory, NormalizedLayoutStructureItems } from "../../../common/entities";

export const handleMoveSidebarContainerIntoParent = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    item: LayoutItem
) => {

    const rootId = item.id || views.getIn([activeView, "result", 0]);

    switch (splitDropZonePath.count()) {
        case 1: { // Item dropped to root
            views = createNewSection(views, data, activeView, splitDropZonePath, rootId);
            break;
        }
        case 2: { // Item dropped inside section
            const parentSectionId = getItemByPath(views, activeView, splitDropZonePath.slice(0, 1));
            views = createNewColumn(views, data, activeView, splitDropZonePath, parentSectionId);
            break;
        }
        default: {
            // Path size is bigger than expected. It must be a panel child
            switch (item.type) {
                case FormItemTypes.EContainerTypes.Section: {
                    views = createNewColumn(views, data, activeView, splitDropZonePath, rootId);
                    break;
                }
                case FormItemTypes.EControlTypes.Panel:
                case FormItemTypes.EControlTypes.InputGroup: {
                    views = createNewSection(views, data, activeView, splitDropZonePath, rootId);
                    break;
                }
            }
        }
    }

    return views
};

const createNewColumn = (views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    targetId: string
) => {

    // Create new column
    const newColumnId = generateId(views, data, FormItemTypes.EContainerTypes.Column);
    let newColumn = LayoutStructureItemFactory({
        id: newColumnId,
        type: FormItemTypes.EContainerTypes.Column
    });

    // Link items
    newColumn = newColumn.set("parentId", targetId);

    // Insert new items to view
    views = insertToView(views, activeView, [newColumn]);

    // Insert item to parent's items
    const newItems = views.getIn([activeView, "entities", "items", targetId, "items"]).insert(Number(splitDropZonePath.last()), newColumnId);
    views = views.setIn([activeView, "entities", "items", targetId, "items"], newItems);

    return views

}

const createNewSection = (views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    targetId: string
) => {
    // Create new section
    const newSectionId = generateId(views, data, FormItemTypes.EContainerTypes.Section);
    let newSection = LayoutStructureItemFactory({
        id: newSectionId,
        parentId: targetId,
        type: FormItemTypes.EContainerTypes.Section
    });

    // Insert new items to view
    views = insertToView(views, activeView, [newSection]);

    // Insert item to parent's items
    const dropZoneIndex = Number(splitDropZonePath.last());
    const rootItems = views.getIn([activeView, "entities", "items", targetId, "items"]).insert(dropZoneIndex, newSectionId);
    views = views.setIn([activeView, "entities", "items", targetId, "items"], rootItems);
    return views
}