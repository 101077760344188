import classNames from 'classnames/bind';
import * as React from "react";
import * as Styles from "./assets/style.scss";
import { IHTMLRadioButtonProps, IHTMLRadioButtonSelectedChangeEventArgs } from './entities';

const cx = classNames.bind(Styles);
const BCHTMLRadioButton: React.FunctionComponent<IHTMLRadioButtonProps> = props => {

    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onSelectedChanged = (e: any) => {

        const args: IHTMLRadioButtonSelectedChangeEventArgs = { senderArgs: e, data: e.target.checked }
        setValue(args.data);

        if (props.onSelectedChange) props.onSelectedChange({ senderArgs: args, data: args.data });
    }

    const classname = cx({
        radioWrapper: true,
        [props.className]: true
    });

    const radioClassname = cx({
        radio: true,
        disabled: props.disabled
    });

    return (
        <label className={classname} >
            <span className={radioClassname}>
                <input
                    type='radio'
                    id={props.key}
                    name={props.groupName}
                    key={props.key}
                    className={Styles.radioInput}
                    value={value}
                    checked={value}
                    onChange={onSelectedChanged}
                    tabIndex={props.tabIndex}
                    disabled={props.disabled}
                />
                <span className={Styles.radioInner} style={props.style}></span>
            </span>
            <span>{props.text}</span>
        </label>
    )
}

export { IHTMLRadioButtonProps, IHTMLRadioButtonSelectedChangeEventArgs };
export default BCHTMLRadioButton;