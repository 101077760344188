import * as React from "react";
import { ISkeletonProps } from '../entities';
export enum ContentType {
    textFile,
    imageFile,
    dm_card_item,
    security_PermissionGroup,
    security_Permission,
    Sample,
    BulletList,
    AuthorsList,
    Article,
    Amazon,
    BigBulletList,
    BigBulletSingle,
    OneRowWithAvatar,
    OneRow,
    TwoRow,
    startupAnnouncement,
    Title,
    TitleAndDesc,
    CarouselImg,
    DashboardAnnouncementsList,
    DashboardRecentFilesList,
    DashboardProcessDetailsList,
    DashboardProcessesGrid,
    DashboardTilesList,
    DashboardRecentAppsList,
    DashboardProcessDetailsListItem
};

const getRandomDmCardItem = () => {
    switch (Math.round(Math.random() * 5)) {
        case 0:
            return dm_card_item1;
        case 1:
            return dm_card_item2;
        case 2:
            return dm_card_item3;
        case 3:
            return dm_card_item4;
        case 4:
        default:
            return dm_card_item5;
    }
}

export default function getContent(props: ISkeletonProps) {
    switch (props.contentType) {
        case ContentType.Sample:
            return sampleContent;
        case ContentType.Article:
            return articleContent;
        case ContentType.AuthorsList:
            return authorsListContent;
        case ContentType.BulletList:
            return bulletListContent;
        case ContentType.Amazon:
            return amazonContent
        case ContentType.BigBulletList:
            return bigBulletListContent;
        case ContentType.BigBulletSingle:
            return bigBulletSingleContent(props);
        case ContentType.OneRowWithAvatar:
            return oneRowWithAvatar;
        case ContentType.OneRow:
            return OneRow;
        case ContentType.TwoRow:
            return TwoRow;
        case ContentType.security_PermissionGroup:
            return security_PermissionGroup;
        case ContentType.security_Permission:
            return security_Permission;
        case ContentType.startupAnnouncement:
            return startup_Announcement;
        case ContentType.dm_card_item:
            return getRandomDmCardItem();
        case ContentType.textFile:
            return getTextSkeleton(props.size.height, props.size.width);
        case ContentType.imageFile:
            return getImageSkeleton(props.size.height, props.size.width);
        case ContentType.Title:
            return Title;
        case ContentType.TitleAndDesc:
            return TitleAndDesc;
        case ContentType.CarouselImg:
            return CarouselImg;
        case ContentType.DashboardAnnouncementsList:
            return dashboardAnnouncementsList;
        case ContentType.DashboardRecentFilesList:
            return dashboardRecentFilesList;
        case ContentType.DashboardProcessDetailsList:
            return dashboardProcessDetailsList;
        case ContentType.DashboardProcessesGrid:
            return dashboardProcessesGrid;
        case ContentType.DashboardTilesList:
            return dashboardTilesList;
        case ContentType.DashboardRecentAppsList:
            return dashboardRecentAppsList;
        case ContentType.DashboardProcessDetailsListItem:
            return dashboardProcessDetailsListItem;
        default:
            return sampleContent;
    }
}
//#region Content Variables

const getImageSkeleton = (height: number, width: number) => {
    return (
        <>
            <rect x="0" y="0" rx="0" ry="0" width={width.toString()} height="5" />
            <rect x="0" y={(height - 5).toString()} rx="0" ry="0" width={width.toString()} height="5" />
            <rect x="0" y="5" rx="0" ry="0" width="5" height={(height - 10).toString()} />
            <rect x={(width - 5).toString()} y="5" rx="0" ry="0" width="5" height={(height - 10).toString()} />
            <rect x="20" y="20" rx="0" ry="0" width={(width - 40).toString()} height={(height - 40).toString()} />
        </>
    )
}

const getTextSkeleton = (height: number, width: number) => {
    const rowX = width * 0.1;
    const rowPadding = 5;
    const rowHeight = 10;
    const rowWidth = width * 0.8;
    const rowCount = Math.floor((height - (rowX * 2)) / (rowHeight + rowPadding));
    let rows: any[] = [];
    for (let i = 1; i <= rowCount; i++) {
        rows.push(
            <rect
                x={rowX}
                y={i * (rowPadding + rowHeight) + rowX}
                rx="0"
                ry="0"
                width={rowWidth}
                height={rowHeight}
            />
        );
    }
    rows.push(<rect x="0" y="0" rx="0" ry="0" width="5" height={height} />);
    rows.push(<rect x={width - 5} y="0" rx="0" ry="0" width="5" height={height} />);
    rows.push(<rect x={5} y="0" rx="0" ry="0" width={width - 10} height="5" />);
    rows.push(<rect x={5} y={height - 5} rx="0" ry="0" width={width - 10} height="5" />);
    return rows;
}

const dashboardAnnouncementsList = (
    <>
        <circle cx="17" cy="27" r="15" />
        <rect x="40" y="17" rx="5" ry="5" width="650" height="18" />
        <rect x="700" y="17" rx="5" ry="5" width="100" height="18" />
        <circle cx="17" cy="87" r="15" />
        <rect x="40" y="78" rx="5" ry="5" width="650" height="18" />
        <rect x="700" y="78" rx="5" ry="5" width="100" height="18" />
        <circle cx="17" cy="147" r="15" />
        <rect x="40" y="138" rx="5" ry="5" width="650" height="18" />
        <rect x="700" y="138" rx="5" ry="5" width="100" height="18" />
        <circle cx="17" cy="207" r="15" />
        <rect x="40" y="198" rx="5" ry="5" width="650" height="18" />
        <rect x="700" y="198" rx="5" ry="5" width="100" height="18" />
        <circle cx="17" cy="267" r="15" />
        <rect x="40" y="258" rx="5" ry="5" width="650" height="18" />
        <rect x="700" y="258" rx="5" ry="5" width="100" height="18" />
    </>
)

const dashboardRecentFilesList = (
    <>
        <circle cx="17" cy="27" r="15" />
        <rect x="40" y="13" rx="5" ry="5" width="750" height="13" />
        <rect x="40" y="28" rx="5" ry="5" width="250" height="11" />
        <circle cx="17" cy="87" r="15" />
        <rect x="40" y="74" rx="5" ry="5" width="750" height="13" />
        <rect x="40" y="89" rx="5" ry="5" width="250" height="11" />
        <circle cx="17" cy="147" r="15" />
        <rect x="40" y="134" rx="5" ry="5" width="750" height="13" />
        <rect x="40" y="149" rx="5" ry="5" width="250" height="11" />
        <circle cx="17" cy="207" r="15" />
        <rect x="40" y="194" rx="5" ry="5" width="750" height="13" />
        <rect x="40" y="209" rx="5" ry="5" width="250" height="11" />
        <circle cx="17" cy="267" r="15" />
        <rect x="40" y="254" rx="5" ry="5" width="750" height="13" />
        <rect x="40" y="269" rx="5" ry="5" width="250" height="11" />
    </>
)

const dashboardProcessDetailsList = (
    <>
        <circle cx="17" cy="27" r="15" />
        <rect x="40" y="6" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="20" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="34" rx="5" ry="5" width="600" height="12" />
        <circle cx="750" cy="24" r="8" />
        <circle cx="17" cy="87" r="15" />
        <rect x="40" y="67" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="81" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="95" rx="5" ry="5" width="600" height="12" />
        <circle cx="750" cy="85" r="8" />
        <circle cx="17" cy="147" r="15" />
        <rect x="40" y="127" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="141" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="155" rx="5" ry="5" width="600" height="12" />
        <circle cx="750" cy="145" r="8" />
        <circle cx="17" cy="207" r="15" />
        <rect x="40" y="187" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="201" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="215" rx="5" ry="5" width="600" height="12" />
        <circle cx="750" cy="205" r="8" />
        <circle cx="17" cy="267" r="15" />
        <rect x="40" y="247" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="261" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="275" rx="5" ry="5" width="600" height="12" />
        <circle cx="750" cy="265" r="8" />
    </>
)

const dashboardProcessesGrid = (
    <>
        <rect x="20" y="25" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="25" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="25" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="60" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="60" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="60" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="95" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="95" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="95" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="130" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="130" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="130" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="165" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="165" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="165" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="200" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="200" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="200" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="235" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="235" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="235" rx="5" ry="5" width="400" height="16" />

        <rect x="20" y="270" rx="5" ry="5" width="350" height="16" />
        <rect x="375" y="270" rx="5" ry="5" width="25" height="16" />
        <rect x="420" y="270" rx="5" ry="5" width="400" height="16" />
    </>
)

const dashboardTilesList = (
    <>
      <rect x="5" y="5" rx="5" ry="5" width="230" height="110" />
      <rect x="255" y="5" rx="5" ry="5" width="230" height="110" />
      <rect x="505" y="5" rx="5" ry="5" width="230" height="110" />
      <rect x="755" y="5" rx="5" ry="5" width="230" height="110" />
    </>
)

const dashboardRecentAppsList = (
    <>
      <rect x="5" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="131" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="262" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="393" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="524" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="655" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="786" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="917" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="1048" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="1179" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="1310" y="5" rx="5" ry="5" width="106" height="106" />
      <rect x="1441" y="5" rx="5" ry="5" width="106" height="106" />
    </>
)

const dashboardProcessDetailsListItem = (
    <>
        <circle cx="17" cy="27" r="15" />
        <rect x="40" y="6" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="20" rx="5" ry="5" width="600" height="12" />
        <rect x="40" y="34" rx="5" ry="5" width="600" height="12" />
        <circle cx="750" cy="24" r="8" />
    </>
)

let security_PermissionGroup_0 =
    <>
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="80" r="8" />
        <rect style={{ "fill": "#F4F4F4" }} x="22.5" y="78" width="69.5" height="4.6" rx="2.5" ry="3.5" />

        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="8" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,10.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,9.272,91.28,10.313,90.008,10.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="32" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,34.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,33.272,91.28,34.313,90.008,34.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="56" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,58.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,57.272,91.28,58.313,90.008,58.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="106" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,108.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,107.272,91.28,108.313,90.008,108.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="22" cy="132" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M104.008,134.313H38.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C106.32,133.272,105.28,134.313,104.008,134.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="22" cy="161" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M104.008,163.313H38.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C106.32,162.272,105.28,163.313,104.008,163.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="22" cy="189" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M104.008,191.313H38.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C106.32,190.272,105.28,191.313,104.008,191.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="22" cy="218" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M104.008,220.313H38.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C106.32,219.272,105.28,220.313,104.008,220.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="248" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,250.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,249.272,91.28,250.313,90.008,250.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="277" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,279.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,278.272,91.28,279.313,90.008,279.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="305" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,307.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,306.272,91.28,307.313,90.008,307.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="334" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,336.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,335.272,91.28,336.313,90.008,336.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="362" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,364.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,363.272,91.28,364.313,90.008,364.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="391" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,393.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,392.272,91.28,393.313,90.008,393.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="419" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,421.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,420.272,91.28,421.313,90.008,421.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="448" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,450.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,449.272,91.28,450.313,90.008,450.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="476" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,478.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,477.272,91.28,478.313,90.008,478.313z" />
        <circle style={{ "fill": "#F4F4F4" }} cx="8" cy="505" r="8" />
        <path style={{ "fill": "#F4F4F4" }} d="M90.008,507.313H24.953c-1.272,0-2.312-1.041-2.312-2.312l0,0c0-1.272,1.041-2.312,2.312-2.312		h65.055c1.272,0,2.312,1.041,2.312,2.312l0,0C92.32,506.272,91.28,507.313,90.008,507.313z" />
    </>

let security_PermissionGroup =
    <>
        <circle cx="13" cy="13" r="13" /><rect x="40" y="10" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="63" r="13" /><rect x="59" y="61" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="114" r="13" /><rect x="59" y="111" rx="3" ry="4" width="200" height="9" />
        <circle cx="49" cy="163" r="13" /><rect x="76" y="160" rx="3" ry="4" width="200" height="9" />
        <circle cx="49" cy="215" r="13" /><rect x="76" y="212" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="269" r="13" /><rect x="59" y="266" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="320" r="13" /><rect x="59" y="317" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="373" r="13" /><rect x="59" y="370" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="424" r="13" /><rect x="59" y="421" rx="3" ry="4" width="200" height="9" />
        <circle cx="31" cy="479" r="13" /><rect x="59" y="476" rx="3" ry="4" width="200" height="9" />
        <circle cx="13" cy="530" r="13" /><rect x="40" y="527" rx="3" ry="4" width="200" height="9" />
        <circle cx="13" cy="582" r="13" /><rect x="40" y="579" rx="3" ry="4" width="200" height="9" />
    </>

let security_Permission =
    <>
        <rect x="1" y="1" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="30" rx="3" ry="4" width="200" height="9" />
        <rect x="21" y="60" rx="3" ry="4" width="200" height="9" />
        <rect x="21" y="88" rx="3" ry="4" width="200" height="9" />
        <rect x="38" y="115" rx="3" ry="4" width="200" height="9" />
        <rect x="38" y="146" rx="3" ry="4" width="200" height="9" />
        <rect x="38" y="174" rx="3" ry="4" width="200" height="9" />
        <rect x="38" y="202" rx="3" ry="4" width="200" height="9" />
        <rect x="21" y="233" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="261" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="292" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="320" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="347" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="378" rx="3" ry="4" width="200" height="9" />
        <rect x="1" y="406" rx="3" ry="4" width="200" height="9" />
        <rect x="467" y="2" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="32" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="61" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="89" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="118" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="146" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="177" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="205" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="234" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="262" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="292" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="321" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="348" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="379" rx="3" ry="4" width="52" height="9" />
        <rect x="467" y="407" rx="3" ry="4" width="52" height="9" />
        <rect x="598" y="62" rx="3" ry="4" width="40" height="9" />
        <rect x="598" y="230" rx="3" ry="4" width="40" height="9" />
        <rect x="598" y="405" rx="3" ry="4" width="40" height="9" />
    </>

let oneRowWithAvatar =
    <>
        <circle cx="14" cy="15" r="10" />
        <rect x="31" y="11" rx="5" ry="5" width="253" height="10" />
    </>

let OneRow =
    <>
        <rect x="14" y="11" rx="5" ry="5" width="253" height="10" />
    </>

let TwoRow =
    <>
        <rect x="10" y="11" rx="5" ry="5" width="378" height="18" />
        <rect x="453" y="11" rx="5" ry="5" width="314" height="18" />
    </>

let bigBulletListContent =
    <>
        <circle cx="17" cy="27" r="15" />         <rect x="77" y="17" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="17" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="81" r="15" />         <rect x="77" y="72" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="72" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="136" r="15" />        <rect x="77" y="127" rx="5" ry="5" width="400" height="18" /><rect x="603" y="127" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="191" r="15" />        <rect x="77" y="182" rx="5" ry="5" width="400" height="18" />  <rect x="603" y="182" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="240" r="15" />        <rect x="77" y="231" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="231" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="295" r="15" />        <rect x="77" y="285" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="285" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="349" r="15" />        <rect x="77" y="340" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="340" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="404" r="15" />        <rect x="77" y="395" rx="5" ry="5" width="400" height="18" /><rect x="603" y="395" rx="5" ry="5" width="96" height="18" />

        <circle cx="17" cy="459" r="15" />        <rect x="77" y="450" rx="5" ry="5" width="400" height="18" />  <rect x="603" y="450" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="514" r="15" />        <rect x="77" y="505" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="505" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="569" r="15" />        <rect x="77" y="560" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="560" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="624" r="15" />        <rect x="77" y="615" rx="5" ry="5" width="400" height="18" /> <rect x="603" y="615" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="679" r="15" />        <rect x="77" y="670" rx="5" ry="5" width="400" height="18" /><rect x="603" y="670" rx="5" ry="5" width="96" height="18" />
    </>

let bigBulletSingleContent = (props: ISkeletonProps) => {
    let componentWidth = props.width || props.size.width;
    let componentHeight = props.height || props.size.height;

    let usableHeight = componentHeight * 0.9;
    let usableWidth = componentWidth * 0.95
    let rowPadding = 55;
    let rowHeight = 5;
    let rowCount = props.rowCount != -1 ? props.rowCount : Math.floor(usableHeight / (rowPadding - rowHeight));
    let rows: any[] = [];
    let lastItemWidth = 96;
    for (let i = 0; i < rowCount; i++) {
        rows.push(
            <div key={"bigBulletSingle_" + i}>
                <circle cx="17" cy={27 + (i * rowPadding)} r="15" />
                <rect x="77" y={17 + (i * rowPadding)} rx="5" ry="5" width="400" height="18" />
                <rect x={usableWidth - lastItemWidth} y={17 + (i * rowPadding)} rx="5" ry="5" width={lastItemWidth} height="18" />
            </div>
        );
    }
    return rows;
}

let bulletListContent =
    <>
        <circle cx="10" cy="20" r="8" />
        <rect x="43" y="15" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="43" y="45" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="80" r="8" />
        <rect x="43" y="75" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="110" r="8" />
        <rect x="43" y="105" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="137" r="8" />
        <rect x="43" y="132" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="167" r="8" />
        <rect x="43" y="162" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="197" r="8" />
        <rect x="43" y="192" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="227" r="8" />
        <rect x="43" y="222" rx="5" ry="5" width="220" height="10" />
        <rect x="361" y="15" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="45" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="75" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="105" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="132" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="162" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="192" rx="5" ry="5" width="53" height="10" />
        <rect x="361" y="222" rx="5" ry="5" width="53" height="10" />
    </>

let authorsListContent =
    <>
        <circle cx="70.2" cy="73.2" r="41.3" />
        <rect x="129.9" y="29.5" width="125.5" height="17" />
        <rect x="129.9" y="64.7" width="296" height="17" />
        <rect x="129.9" y="97.8" width="253.5" height="17" />
        <rect x="129.9" y="132.3" width="212.5" height="17" />

        <circle cx="70.7" cy="243.5" r="41.3" />
        <rect x="130.4" y="199.9" width="125.5" height="17" />
        <rect x="130.4" y="235" width="296" height="17" />
        <rect x="130.4" y="268.2" width="253.5" height="17" />
        <rect x="130.4" y="302.6" width="212.5" height="17" />

        <circle cx="70.7" cy="412.7" r="41.3" />
        <rect x="130.4" y="369" width="125.5" height="17" />
        <rect x="130.4" y="404.2" width="296" height="17" />
        <rect x="130.4" y="437.3" width="253.5" height="17" />
        <rect x="130.4" y="471.8" width="212.5" height="17" />
    </>

let articleContent =
    <>
        <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
        <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
        <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
        <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
        <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
        <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
    </>

let sampleContent =
    <>
        <circle cx="82" cy="96" r="19" />
        <rect x="104" y="84" rx="0" ry="0" width="100" height="22" />
        <rect x="120" y="136" rx="0" ry="0" width="300" height="22" />
        <rect x="137" y="176" rx="0" ry="0" width="269" height="22" />
        <rect x="136" y="207" rx="0" ry="0" width="269" height="22" />
        <rect x="137" y="239" rx="0" ry="0" width="267" height="22" />
        <circle cx="60" cy="300" r="22" />
        <rect x="89" y="289" rx="0" ry="0" width="357" height="22" />
        <circle cx="72" cy="347" r="19" />
        <rect x="104" y="84" rx="0" ry="0" width="329" height="22" />
        <rect x="95" y="336" rx="0" ry="0" width="332" height="22" />
        <rect x="110" y="373" rx="0" ry="0" width="296" height="22" />
        <rect x="110" y="404" rx="0" ry="0" width="295" height="22" />
        <circle cx="95" cy="145" r="19" />
        <circle cx="60" cy="48" r="22" />
        <rect x="86" y="34" rx="0" ry="0" width="362" height="22" />
    </>;

let amazonContent =
    <>
        <circle cx="472" cy="159" r="7" />
        <rect x="487" y="154" rx="5" ry="5" width="220" height="10" />
        <circle cx="472" cy="190" r="7" />
        <rect x="487" y="184" rx="5" ry="5" width="220" height="10" />
        <circle cx="472" cy="219" r="7" />
        <rect x="487" y="214" rx="5" ry="5" width="220" height="10" />
        <circle cx="472" cy="249" r="7" />
        <rect x="487" y="244" rx="5" ry="5" width="220" height="10" />
        <rect x="64" y="18" rx="0" ry="0" width="346" height="300" />
        <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
        <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
        <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
        <rect x="10" y="20" rx="0" ry="0" width="40" height="44" />
        <rect x="10" y="80" rx="0" ry="0" width="40" height="44" />
        <rect x="10" y="140" rx="0" ry="0" width="40" height="44" />
        <rect x="194" y="329" rx="0" ry="0" width="0" height="0" />
        <rect x="192" y="323" rx="0" ry="0" width="0" height="0" />
        <rect x="185" y="323" rx="0" ry="0" width="0" height="0" />
        <rect x="10" y="200" rx="0" ry="0" width="40" height="44" />
        <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
        <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
        <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
        <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
        <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
        <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
        <rect x="470" y="99" rx="0" ry="0" width="70" height="30" />
        <rect x="560" y="99" rx="0" ry="0" width="70" height="30" />
    </>

let startup_Announcement =
    <>
        <circle cx="17" cy="27" r="15" />        <rect x="77" y="17" rx="5" ry="5" width="300" height="18" /> <rect x="503" y="17" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="81" r="15" />        <rect x="77" y="72" rx="5" ry="5" width="300" height="18" /> <rect x="503" y="72" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="136" r="15" />        <rect x="77" y="127" rx="5" ry="5" width="300" height="18" /> <rect x="503" y="127" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="191" r="15" />       <rect x="77" y="182" rx="5" ry="5" width="300" height="18" /><rect x="503" y="182" rx="5" ry="5" width="96" height="18" />
        <circle cx="17" cy="240" r="15" />        <rect x="77" y="231" rx="5" ry="5" width="300" height="18" /> <rect x="503" y="231" rx="5" ry="5" width="96" height="18" />




    </>

const dm_card_item1 =
    <>
        <rect x="0" y="12" rx="0" ry="0" width="32" height="32" />
        <rect x="42" y="17" rx="0" ry="0" width="148" height="22" />
    </>

const dm_card_item2 =
    <>
        <rect x="0" y="12" rx="0" ry="0" width="32" height="32" />
        <rect x="42" y="17" rx="0" ry="0" width="188" height="22" />
    </>

const dm_card_item3 =
    <>
        <rect x="0" y="12" rx="0" ry="0" width="32" height="32" />
        <rect x="42" y="17" rx="0" ry="0" width="228" height="22" />
    </>

const dm_card_item4 =
    <>
        <rect x="0" y="12" rx="0" ry="0" width="32" height="32" />
        <rect x="42" y="17" rx="0" ry="0" width="108" height="22" />
    </>

const dm_card_item5 =
    <>
        <rect x="0" y="12" rx="0" ry="0" width="32" height="32" />
        <rect x="42" y="17" rx="0" ry="0" width="248" height="22" />
    </>

const Title = <><rect x="0" y="0" rx="3" ry="3" width="250" height="20" /></>;

const TitleAndDesc = <>
    <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
    <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
    <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
    <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
    <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
</>

const CarouselImg = <>
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
</>
//#endregion