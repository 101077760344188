import { connect } from 'react-redux';
import { BaseState } from '../../..';
import ThemeChanger from '../components/ThemeChanger';
import { IThemeChangerProps } from '../entities/IThemeChangerProps';
import { IThemeChangerCProps } from '../entities/IThemeChangerCProps';

const mapStateToProps = function (state: BaseState, props: IThemeChangerProps): IThemeChangerProps {
    return {
        backgroundColor: state.user.theme.backgroundColor,
        image: state.user.theme.image,
        primaryColor: state.user.theme.primaryColor,
        environment: state.environment,
        loginStatus: !!state.user.token
    }
}

const mapDispatchToProps = function (dispatch: Function): IThemeChangerCProps {
    return {

    }
}


const ThemeChangerContainer = connect(mapStateToProps, mapDispatchToProps)(ThemeChanger);

export default ThemeChangerContainer;