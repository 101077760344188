import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { Map, Record } from "immutable";

export interface IBaseSummaryMessage {
    title: string,
    type: 'Success' | 'Error' | 'Warning' | 'Info' | 'SevereWarning' | 'Blocked'
}

export interface ISummaryMessage extends IBaseSummaryMessage {
    message: IDictionary<string>
}

export interface IReadonlyBaseSummaryMessage extends IBaseSummaryMessage {
    message: Map<string, string>
}

export type SummaryMessage = ReadonlyRecord<IReadonlyBaseSummaryMessage>;

export const SummaryMessageFactory = Record<IReadonlyBaseSummaryMessage>({
    title: null,
    message: Map(),
    type: null
});

export const SummaryMessageFactoryFromJS = function (data: ISummaryMessage) {
    return SummaryMessageFactory({
        ...data,
        message: Map(data.message)
    });
}