import { BCList, findMessage, BCSkeleton, ContentType } from "@bimser/components";
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import * as Styles from "../assets/styles.scss";
import LastVisitedDocumentProps from "../entities/ILastVisitedDocumentProps";
import LastVisitedDocumentCProps from "../entities/ILastVisitedDocumentCProps";
const recentFilesBoxEmpty = require("../../../assets/recent-files-empty.svg");
import { panelType } from "../../../common/entities/enums";
import * as moment from 'moment'
import { IDmData } from "../../dm/entities";
import { isNullOrUndefined } from "@bimser/core";
import { getIcon } from '../../dm/utils';
import IconGo from "@bimser/icons/16/go";
const sizeme = require('react-sizeme');

class LastVisitedDocumentsBox extends React.Component<LastVisitedDocumentProps & LastVisitedDocumentCProps, any> {
    contentRef: HTMLDivElement = null;

    constructor(props: LastVisitedDocumentProps & LastVisitedDocumentCProps) {
        super(props);

        this.onClickItem = this.onClickItem.bind(this);
        this.getRef = this.getRef.bind(this);
        this.calculateItems = this.calculateItems.bind(this);
        this.onClickAllRecents = this.onClickAllRecents.bind(this);

        this.state = {
            contentRef: null
        }

    }

    scrollbarStyle = { width: '100%', height: '100%' };

    strings = {
        LAST_VISITED_DOCUMENTS: findMessage.get("100861"),
        SEE_ALL_RECENTS: findMessage.get("100875"),
        EMPTY_TITLE: findMessage.get('100750'),
        EMPTY_DESC: findMessage.get('101709')
    }

    onClickItem(item: IDmData) {
        this.props.onItemClicked({
            panelId: this.props.startupPanelId,
            panelType: panelType.deprecatedStartupLayout,
            data: {
                secretKey: item.secretKey,
                fileName: item.name,
                itemId: item.id,
                fromRecentOrFavorites: "recent"
            }
        })
    }

    private renderItem(itemProps: IDmData): React.ReactNode {

        return (
            <React.Fragment>
                <div onClick={(e) => { e.stopPropagation(); this.onClickItem(itemProps); }} className={Styles.boxItem}>
                    {getIcon(itemProps.type, null, itemProps.name, itemProps.existThumbnail ? itemProps.secretKey : undefined)}
                    <div className={Styles.itemInfo}>
                        <div className={Styles.infoTitle}>{itemProps.name}</div>
                    </div>
                    <div className={Styles.itemDate}>
                        {moment(itemProps.recentCreatedAt).format("L")}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private onClickAllRecents() {
        this.props.onClickAllRecents({
            panelId: this.props.startupPanelId,
            panelType: panelType.deprecatedStartupLayout,
            data: "#Recent"
        });
    }

    renderCustomEmpty() {
        return (
            <div className={Styles.startupBoxEmptyWrapper}>
                <img src={recentFilesBoxEmpty.default} className={Styles.emptyImage} />
                <span className={Styles.emptyInfoTitle}>{this.strings.EMPTY_TITLE}</span>
                <span className={Styles.emptyInfo}>{this.strings.EMPTY_DESC}</span>
            </div>
        )
    }


    getRef(node: HTMLDivElement) {
        this.setState({ contentRef: node });
    }

    calculateItems() {
        if (isNullOrUndefined(this.state.contentRef)) {
            return this.props.items.slice(0, 5);
        } else {
            let height = this.state.contentRef.clientHeight;
            let itemCount = Math.floor(height / 56);
            return this.props.items.slice(0, itemCount);
        }
    }

    private generateTemplate(): JSX.Element {

        return (
            <div className={Styles.BoxContainer}>
                <div className={Styles.header}>
                    <span className={Styles.title}>{this.strings.LAST_VISITED_DOCUMENTS}</span>
                </div>
                <div className={[Styles.content, Styles.withHeader, Styles.withFooter].join(' ')} ref={this.getRef}>
                    <BCSkeleton
                        contentType={ContentType.startupAnnouncement}
                        loadingStatus={this.props.loading}
                        className={"skeleton"}
                    >
                        <BCList dataSource={this.calculateItems()}
                            className={Styles.lastDocumentsBoxContainer}
                            bordered={false}
                            split={false}
                            customEmpty={this.renderCustomEmpty()}
                            renderItem={this.renderItem.bind(this)} />
                    </BCSkeleton>
                </div>
                {this.props.items && this.props.items.length > 0 && (
                    <div className={Styles.footer}>
                        <a className={Styles.link} onClick={this.onClickAllRecents}>
                            {this.strings.SEE_ALL_RECENTS}
                            <span className={Styles.icon}><IconGo /></span>
                        </a>
                    </div>
                )}
            </div>
        );
    }

    public render() {
        return (
            this.generateTemplate()
        );
    }
}

export default sizeme({ refreshRate: 1000, monitorHeight: true })(LastVisitedDocumentsBox);