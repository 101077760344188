export default function createGuid(noSeperator?: boolean): string {
    return _createGuid(false, noSeperator) + _createGuid(true, noSeperator) + _createGuid(true, noSeperator) + _createGuid(false, noSeperator);
}

function _createGuid(s: boolean, noSeperator?: boolean): string {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);

    if (noSeperator) {
        return s ? p.substr(0, 4) + p.substr(4, 4) : p;
    } else {
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }

}