import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseSignatureParameters {
    digitalSignatureEnabled: boolean;
    docuSignEnabled: boolean;
    mobileSignatureEnabled: boolean;
    timeStampEnabled: boolean;
} 

export interface ISignatureParameters extends IBaseSignatureParameters {
    
}

export interface IReadonlySignatureParameters extends IBaseSignatureParameters {
    
}

export type SignatureParameters = ReadonlyRecord<IReadonlySignatureParameters>;

export const SignatureParametersFactory = Record<IReadonlySignatureParameters>({
    digitalSignatureEnabled: false,
    docuSignEnabled: false,
    mobileSignatureEnabled: false,
    timeStampEnabled: false
});

export const SignatureParametersFactoryFromJS = function (data: ISignatureParameters) {
    return SignatureParametersFactory(data);
}