import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

export interface IBaseDmShared { }

export interface IDmShared extends IBaseDmShared {
    to: Array<string>,
    from: Array<string>
}

export interface IReadonlyDmShared extends IBaseDmShared {
    to: List<string>,
    from: List<string>
}

export type DmShared = ReadonlyRecord<IReadonlyDmShared>;

export const DmSharedFactory = Record<IReadonlyDmShared>({
    to: List<string>(),
    from: List<string>()
});

export const DmSharedFactoryFromJS = function (shared: IDmShared) {
    return DmSharedFactory({
        to: List<string>(shared.to),
        from: List<string>(shared.from)
    });
}