import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";


export interface IUserCustomProperties {
    mobileApp: boolean;
}

export interface IReadonlyUserCustomProperties {
    mobileApp: boolean
}

export type UserCustomProperties = ReadonlyRecord<IReadonlyUserCustomProperties>;

export const UserCustomPropertiesFactory = Record<IReadonlyUserCustomProperties>({
    mobileApp: false
});

export const UserCustomPropertiesFactoryFromJS = function (data: IUserCustomProperties) {
    return UserCustomPropertiesFactory(data);
}