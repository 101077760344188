import { FormItemTypes } from "@bimser/core";
import { List, Map } from "immutable";
import { generateId, handleAddStructureToParent, insertToView } from ".";
import { LayoutDataItem, LayoutItem, LayoutStructureItemFactory, NormalizedLayoutStructureItems } from "../../../common/entities";

export const handleMoveSidebarComponentIntoParent = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    item: LayoutItem
) => {

    const viewAlreadyHaveItem = views.getIn([activeView, "entities", "items", item.id]);
    if (viewAlreadyHaveItem) return views;

    const isPanelBase = Object.values(FormItemTypes.EPanelBaseTypes).includes(item.type as FormItemTypes.EPanelBaseTypes);
    const sourceView = views.findKey(view => view.entities.items.has(item.id));
    const fromObjectExplorer = isPanelBase && !!sourceView;

    if (fromObjectExplorer) {
        const sourceItem = views.get(sourceView).entities.items.get(item.id);

        item = item.setIn(["items"], sourceItem.items);
    }

    const rootId = item.parentId || views.getIn([activeView, "result", 0]);

    switch (splitDropZonePath.count()) {
        case 1: { // Item dropped to root

            // Create new control
            const newControlId = item.id || generateId(views, data, item.type);
            let newControl = LayoutStructureItemFactory({
                id: newControlId,
                items: fromObjectExplorer ? item.items : List(),
                type: item.type
            });

            // Create new column
            const newColumnId = generateId(views, data, FormItemTypes.EContainerTypes.Column);
            let newColumn = LayoutStructureItemFactory({
                id: newColumnId,
                items: List([newControlId]),
                type: FormItemTypes.EContainerTypes.Column
            });

            // Create new section
            const newSectionId = generateId(views, data, FormItemTypes.EContainerTypes.Section);
            let newSection = LayoutStructureItemFactory({
                id: newSectionId,
                items: List([newColumnId]),
                parentId: rootId,
                type: FormItemTypes.EContainerTypes.Section
            });

            // Link items
            newColumn = newColumn.set("parentId", newSectionId);
            newControl = newControl.set("parentId", newColumnId);

            // Insert new items to view
            views = insertToView(views, activeView, [newControl, newColumn, newSection]);

            // Insert item to parent's items
            const rootItems = views.getIn([activeView, "entities", "items", rootId, "items"]).insert(Number(splitDropZonePath.last()), newSectionId);
            views = views.setIn([activeView, "entities", "items", rootId, "items"], rootItems);

            break;
        }
        case 2: { // Item dropped inside section
            views = addNewControlTo(views, data, activeView, splitDropZonePath, item, FormItemTypes.EContainerTypes.Section, fromObjectExplorer);
            break;
        }
        case 3: { // Item dropped inside column
            views = addNewControlTo(views, data, activeView, splitDropZonePath, item, FormItemTypes.EContainerTypes.Column, fromObjectExplorer);
            break;

        }
        default: {
            // Path size is bigger than expected. It must be a panel child
            const parentItemType = views.get(activeView).entities.items.get(item.parentId).type;
            views = addNewControlTo(views, data, activeView, splitDropZonePath, item, parentItemType as FormItemTypes.EContainerTypes, fromObjectExplorer);
        }
    }

    if (fromObjectExplorer) {
        const setItemsToTargetView = (itemKey: string) => {
            const sourceItem = views.get(sourceView).entities.items.get(itemKey);
            sourceItem.items.forEach(setItemsToTargetView);
            views = views.setIn([activeView, "entities", "items", itemKey], sourceItem);
        }

        item.items.forEach(setItemsToTargetView)
    }

    return views
};

const addNewControlTo = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    splitDropZonePath: List<string>,
    item: LayoutItem,
    to: FormItemTypes.EContainerTypes,
    fromObjectExplorer: boolean
) => {

    // Create new control
    const newControlId = item.id || generateId(views, data, item.type);
    let newControl = LayoutStructureItemFactory({
        id: newControlId,
        items: fromObjectExplorer ? item.items : List(),
        type: item.type
    });

    switch (to) {
        case FormItemTypes.EContainerTypes.Section: {

            // Create new column
            const newColumnId = generateId(views, data, FormItemTypes.EContainerTypes.Column);
            let newColumn = LayoutStructureItemFactory({
                id: newColumnId,
                items: List([newControlId]),
                type: FormItemTypes.EContainerTypes.Column
            });

            // Link items
            newColumn = newColumn.set("parentId", item.parentId);
            newControl = newControl.set("parentId", newColumnId);

            // Insert new items to view
            views = insertToView(views, activeView, [newControl, newColumn]);

            // Insert item to parent's items
            const newItems = views.getIn([activeView, "entities", "items", item.parentId, "items"]).insert(Number(splitDropZonePath.last()), newColumnId);
            views = views.setIn([activeView, "entities", "items", item.parentId, "items"], newItems);

            break;
        }
        default: {

            // Link items
            newControl = newControl.set("parentId", item.parentId);

            // Insert new items to view
            views = insertToView(views, activeView, [newControl]);

            // Insert item to parent's items
            const newItems = views.getIn([activeView, "entities", "items", item.parentId, "items"]).insert(Number(splitDropZonePath.last()), newControlId);
            views = views.setIn([activeView, "entities", "items", item.parentId, "items"], newItems);

            break;
        }
    }



    if (item.type === FormItemTypes.EPanelBaseTypes.InputGroup) {
        views = addDefaultItemsToPanel(views, data, activeView, newControlId);
    }

    return views

}

const addDefaultItemsToPanel = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    parentId: string
) => {

    // Add a section
    const sectionId = generateId(views, data, FormItemTypes.EContainerTypes.Section);
    const section = LayoutStructureItemFactory({
        id: sectionId,
        type: FormItemTypes.EContainerTypes.Section,
        parentId
    });
    views = handleAddStructureToParent(views, activeView, List(["0"]), section);

    // Add two columns
    const column1Id = generateId(views, data, FormItemTypes.EContainerTypes.Column);
    const column1 = LayoutStructureItemFactory({
        id: column1Id,
        type: FormItemTypes.EContainerTypes.Column,
        parentId: sectionId
    });
    views = handleAddStructureToParent(views, activeView, List(["0"]), column1);

    // Add a column
    const column2Id = generateId(views, data, FormItemTypes.EContainerTypes.Column);
    const column2 = LayoutStructureItemFactory({
        id: column2Id,
        type: FormItemTypes.EContainerTypes.Column,
        parentId: sectionId
    });
    views = handleAddStructureToParent(views, activeView, List(["0"]), column2);

    return views

}