import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";
import { Announcement, AnnouncementFactoryFromJS, IAnnouncement } from "./IAnnouncement";
import { AnnouncementPanelsFactoryFromJS, IAnnouncementPanels, AnnouncementPanels, AnnouncementPanelsFactory } from "./IAnnouncementPanels";

export interface IAnnouncementState {
    items: IDictionary<IAnnouncement>,
    totalCount: number,
    listPanelIsActive: boolean,
    listPanelGuid: string,
    panels: IAnnouncementPanels
}

export interface IReadonlyAnnouncementState {
    items: Map<string, Announcement>,
    totalCount: number,
    listPanelIsActive: boolean,
    listPanelGuid: string,
    panels: AnnouncementPanels
}

export type AnnouncementState = ReadonlyRecord<IReadonlyAnnouncementState>;

export const AnnouncementStateFactory = Record<IReadonlyAnnouncementState>({
    items: Map<string, Announcement>(),
    totalCount: null,
    listPanelIsActive: false,
    listPanelGuid: '',
    panels: AnnouncementPanelsFactory()
});

export const AnnouncementStateFactoryFromJS = function (data: IAnnouncementState) {
    let _items: IDictionary<Announcement> = Object.keys(data.items).reduce(function (previous: IDictionary<Announcement>, current) {
        previous[current] = AnnouncementFactoryFromJS(data.items[current]);
        return previous;
    }, {});

    return AnnouncementStateFactory({
        items: Map(_items),
        totalCount: data.totalCount,
        listPanelGuid: data.listPanelGuid,
        listPanelIsActive: data.listPanelIsActive,
        panels: AnnouncementPanelsFactoryFromJS(data.panels),
    });
}