import { BCAjax, BCPopover } from "@bimser/components";
import { getIconByExtension, getMimeTypeFromExtension, isNullOrUndefined } from "@bimser/core";
import IconShortcut from "@bimser/icons/16/shortcut";
import IconDocument from "@bimser/icons/32/document";
import IconDirectory from "@bimser/icons/32/folder2";
import IconRepositories from "@bimser/icons/32/reporsitories2";
import * as React from 'react';
import { DmObjectType } from '../../entities/DmObjectType';



export function getDmIcon(type: DmObjectType, className?: string, fileName?: string, secretKey?: string) {
    let wh = 32 + "px";

    switch (type) {
        case DmObjectType.Content:
        case DmObjectType.File: {
            if (fileName) {
                let extensionArr = fileName.split(".");
                if (extensionArr.length > 1) {
                    let extension = extensionArr[extensionArr.length - 1];
                    let mimeType = getMimeTypeFromExtension(extension);
                    let isImage = mimeType?.startsWith("image/") || false;
                    let defaultIcon = getIconByExtension(extension, className);
                    if (isImage && !isNullOrUndefined(secretKey)) {
                        let imgUrl = (window as any).serviceConfig.services.dm.baseURL + "/DocumentManagement/Objects/Thumbnail?secretKey=" + encodeURIComponent(secretKey);
                        return <DmThumbnail key={`thumbnail_${secretKey}`} className={className} defaultIcon={defaultIcon} imgUrl={imgUrl} />
                    }
                    return defaultIcon;
                }
            }
            return <IconDocument width={wh} height={wh} className={className} />
        }
        case DmObjectType.Folder:
            return <IconDirectory width={wh} height={wh} className={className} />
        case DmObjectType.Repository:
            return <IconRepositories width={wh} height={wh} className={className} />
        case DmObjectType.Shortcut:
            return <IconShortcut width={wh} height={wh} className={className} />
    }
}

const DmThumbnail = (props: { defaultIcon: JSX.Element, imgUrl: string, className?: string }) => {
    const popoverContentStyle = { maxWidth: "250px", maxHeight: "250px" };
    const thumbnailStyle = { width: "32px", height: "32px" };

    const [hasThumbnail, setHasThumbnail] = React.useState(false);
    const [imgSrc, setImgSrc] = React.useState(null);

    React.useEffect(() => {
        BCAjax.head(props.imgUrl, { cache: true }).then(() => {
            fetch(props.imgUrl, { headers: BCAjax.defaultHeaders }).then(response => {
                return response.blob();
            }).then(blobData => {
                setImgSrc(URL.createObjectURL(blobData));
                setHasThumbnail(true);
            })
        }).catch(() => { return; });
    }, []);

    const noOpFunc = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return React.useMemo(() => {

        if (hasThumbnail) {
            return (
                <BCPopover
                    trigger="hover"
                    content={<img src={imgSrc} onMouseDown={noOpFunc} onClick={noOpFunc} style={popoverContentStyle} />}
                >
                    <img src={imgSrc} className={props.className} onMouseDown={noOpFunc} onClick={noOpFunc} style={thumbnailStyle} />
                </BCPopover>
            )
        }
        return props.defaultIcon;
    }, [hasThumbnail]);
}