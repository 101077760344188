import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';

const getDashboardWillRefreshSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.refreshDashboard;
}

export const getDashboardWillRefresh = createSelector(
    [
        getDashboardWillRefreshSelector
    ],
    (willRefresh: boolean) : boolean => willRefresh
);