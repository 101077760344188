import React, { useCallback, useMemo } from "react";
import { BCCascader, BCCheckbox, BCDivider, findMessage } from "../..";
import { ConditionalStatementEntities } from "BCConditionalStatement";
import * as Styles from '../assets/styles.scss';

const cascaderStyle = { width: "100%" };

interface IOption {
    value?: string | number;
    label?: React.ReactNode;
    disabled?: boolean;
    children?: IOption[];
    isLeaf?: boolean;
}

interface IProps {
    onChange: (value: string, options: IOption) => void;
    field: ConditionalStatementEntities.IField;
    options: IOption[];
    disabled?: boolean;
}

const dropdownMenuColumnStyle = { minWidth: "150px" };
const switchFavoritesStyle = { padding: "8px 8px 4px 8px", cursor: "pointer" };
const checkboxStyles = { marginRight: "10px" };

export const SelectedField: React.FC<IProps> = (props) => {
    const [value, setValue] = React.useState([]);
    const [favorites, setFavorites] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (props.field?.name && props.field?.propertyName) {
            // "DataGrid.client.columns.data"'yı ["DataGrid", "DataGrid.client", "DataGrid.client.columns", "DataGrid.client.columns.data"]'a dönüştürüyor
            return setValue(`${props.field.name}.${props.field.propertyName}`.split(".").map((_, index, self) => self.slice(0, index + 1).join(".")).flat())
        }
    }, [props.field]);

    const memoizedOnSelectedChange = useCallback((_value: string[], options: IOption[]) => props.onChange(_value[_value.length - 1], options[options.length - 1]), [props.onChange]);

    const extractOptions = (_options: any[] = [], bypass: boolean = true): IOption[] => {
        const extracted = _options.map((option) => ({
            value: option.value,
            label: option.value?.split?.(".")?.pop?.() || option.value,
            externalData: option.externalData || {},
            children: option.children?.length
                ? extractOptions(option.children, option.externalData?.common === true || option.children.some(c => c.value === value.at(-1)))
                : null
        }));

        if (favorites && !bypass) return extracted.filter(option => option.externalData.common);
        return extracted;
    }

    const memoizedExtractOptions = useMemo(() => extractOptions(props.options).filter(opt => opt.children?.length), [favorites, props.options, value])

    const memoizedFilter = useCallback((search: string, options: IOption[]) => options.some(
        option => (option.value as string).toLowerCase().indexOf(search.toLowerCase()) > -1,
    ), []);

    const memoizedDropdownRender = useCallback((menus: React.ReactNode) => (
        <div>
            {menus}
            <BCDivider className={Styles.selectedFieldDivider} />
            <div onClick={() => setFavorites(!favorites)} style={switchFavoritesStyle}>
                <BCCheckbox checked={favorites} style={checkboxStyles} />
                <span>{findMessage.get("102798")}</span>
            </div>
        </div>
    ), [favorites])


    const memoizedDisplayRender = useCallback((labels: string[], selectedOptions: IOption[]) => {
        if (selectedOptions.some(opt => !opt)) return <span className={Styles.selectedTagSpan}>{labels.at(-1)}</span>

        return labels.map((label, i) => {
            const option = selectedOptions[i];
            return <span className={Styles.selectedTagSpan} key={option?.value || label + i}>{i === labels.length - 1 ? label : `${label}.`}</span>;
        })
    }, [])

    return (
        <BCCascader<IOption>
            showSearch={{ filter: memoizedFilter }}
            disabled={props.disabled}
            allowClear={false}
            style={cascaderStyle}
            options={memoizedExtractOptions}
            value={value}
            onChange={memoizedOnSelectedChange}
            dropdownRender={memoizedDropdownRender}
            displayRender={memoizedDisplayRender}
            dropdownMenuColumnStyle={dropdownMenuColumnStyle}
        />
    )
}