import { take, cancel, takeLatest, call, fork, put, select } from 'redux-saga/effects';
import { IOpenConfirmationBoxPayload, OpenConfirmationBoxPayload } from '../entities/IOpenConfirmationBoxPayload';
import { IBaseAction } from "@bimser/core";
import * as ActionTypes from '../actions/actionTypes';
import { openModalAction, closeModalTransactionAction, closeModalAction } from '../../../actions';
import IConfirmationBoxProps from '../entities/IConfirmationBoxProps';

export default function* watchConfirmationBoxTransaction() {
    yield takeLatest(ActionTypes.OPEN_CONFIRMATION_BOX_MODAL, ConfirmationBoxTransaction)
}

function* ConfirmationBoxTransaction(action: IBaseAction<OpenConfirmationBoxPayload>) {
    yield call(ConfirmationBoxManager, action.payload.toJS());
}

export function* ConfirmationBoxManager(payload: IOpenConfirmationBoxPayload) {
    try {
        let resolvePromise: any = null;
        let rejectPromise: any = null;

        let promise = new Promise<any>((resolve, reject) => {
            resolvePromise = resolve;
            rejectPromise = reject;
        });

        let confirmationBoxModal = openModalAction({
            type: "ConfirmationBoxModal",
            id: payload.id,
            visible: true,
            props: {
                id: payload.id,
                title: payload.title,
                confirmationText: payload.confirmationText,
                okButtonText: payload.okButtonText,
                cancelButtonText: payload.cancelButtonText,
                contentMessage: payload.contentMessage
            } as IConfirmationBoxProps
        })

        yield put(confirmationBoxModal);

        yield fork(waitUserResponse, resolvePromise, rejectPromise, payload.id);

        return yield promise;
    } catch (error) {
        console.log("LOG: function*ConfirmationBoxManager -> error", error)

    }

}


function* waitUserResponse(resolvePromise: any, rejectPromise: any, id: string) {
    let action = yield take([ActionTypes.RESOLVE_CONFIRMATION_MODAL, ActionTypes.REJECT_CONFIRMATION_MODAL]);
    if (action && action.type == ActionTypes.RESOLVE_CONFIRMATION_MODAL && resolvePromise) {
        yield put(closeModalAction(id))
        resolvePromise(true);
    } else if (action && action.type == ActionTypes.REJECT_CONFIRMATION_MODAL && rejectPromise) {
        yield put(closeModalAction(id))
        resolvePromise(false);
    }
}