import * as React from "react";
import BCInput, { InputSizes } from '../../../BCInput';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import * as Styles from "../../assets/style.scss";

export default class PasswordProperty extends React.Component<IPropertyComponentProps, {}>{
    defaultStyle: React.CSSProperties = { width: '100%' };

    constructor(props: IPropertyComponentProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    replaceValue(item: IPropertyItem, newValue: any) {
        this.props.onValueChanged(item, newValue)
    }

    onChange(e: any) {
        this.props.customChangeEvent ? this.props.customChangeEvent(e.data) : this.replaceValue(this.props.item, e.data)
    }

    render() {
        return (
            <BCInput
                value={this.props.value || this.props.item.placeholder}
                onChange={this.onChange}
                disabled={this.props.item.disabled}
                readOnly={this.props.item.readOnly}
                style={this.props.style || this.defaultStyle}
                type={"text"}
                className={Styles.inputSecurity}
            />
        );
    }
}