import { ReadonlyRecord } from "@bimser/core";
import { Record } from 'immutable'
import { DmObjectType } from "../../../../../entities";

interface IBaseFolderItem{
    path?:string,
    secretKey?:string,
    parentSecretKey?: string,
    folderName?:string,
    type?: DmObjectType,
    id: string,
}

export interface IFolderItem extends IBaseFolderItem {
    
}

export interface IReadonlyFolderItem extends IBaseFolderItem {
    
}

export type FolderItem = ReadonlyRecord<IReadonlyFolderItem>;

export const  FolderItemFactory = Record<IReadonlyFolderItem>({
    path: null,
    secretKey: null,
    folderName: null,
    type: null,
    parentSecretKey: null,
    id: null
});

export const FolderItemFactoryFromJS = function (data: IFolderItem) {
    return FolderItemFactory(data);
}