import { findMessage } from '../../BCIntl';
import { BCNotification } from '../..';
import { ConditionalStatementEntities } from '../../BCConditionalStatement';

export function validateRule(rule: ConditionalStatementEntities.IRule) {

    let statementError = false;
    let actionError = false;

    rule.statements.forEach(statement => {
        statementError = validateStatement(statement);
        if (statementError) return false;
    });

    rule.actions.forEach(action => {
        actionError = validateAction(action);
        if (actionError) return false;
    });

    const actionsCount = rule.actions.length;
    const triggerEventCount = rule.triggerEvents.length;

    if (!actionsCount) {
        BCNotification.warning({ message: findMessage.get('101962'), duration: 5 });
    }

    if (!triggerEventCount) {
        BCNotification.warning({ message: findMessage.get('101963'), duration: 5 });
    }

    return statementError || actionError

}

function validateStatement(statement: ConditionalStatementEntities.IStatement) {

    let error = false;

    const actions = statement.actions;
    if (!actions || !actions.length) {
        BCNotification.warning({ message: findMessage.get('101964'), duration: 5 });
    }
    if (statement.typeName === "ElseStatement") return;
    if (statement.conditionGroup) {
        error = validateConditionGroups(statement.conditionGroup);
    } else {
        error = true;
        BCNotification.error({ message: findMessage.get('101965'), duration: 5 });
    }

    return error

}

function validateAction(action: ConditionalStatementEntities.IBaseAction) {

    let error = false;

    const typeName = action.typeName;

    switch (typeName) {
        case 'ValueAssignActionWithEntry': {
            const castedArgs = (action as ConditionalStatementEntities.IValueAssignActionWithEntry);

            if (!castedArgs.field || !castedArgs.field.name) {
                BCNotification.error({ message: findMessage.get('101966'), duration: 5 });
                error = true;
            }
            break;
        }
        case 'ValueAssignActionWithSelection': {
            const castedArgs = (action as ConditionalStatementEntities.IValueAssignActionWithSelection);

            if (!castedArgs.field || !castedArgs.field.name || !castedArgs.selectedField || !castedArgs.selectedField.name) {
                BCNotification.error({ message: findMessage.get('101967'), duration: 5 });
                error = true;
            }
            break;
        }
        case 'ValueAssignActionWithFormula': {
            const castedArgs = (action as ConditionalStatementEntities.IValueAssignActionWithFormula);

            if (!castedArgs.field || !castedArgs.field.name) {
                BCNotification.error({ message: findMessage.get('101968'), duration: 5 });
                error = true;
            }

            if (!castedArgs.value) {
                BCNotification.warning({ message: findMessage.get('101969'), duration: 5 });
            }

            break;
        }
    }

    return error
}

function validateConditionGroups(conditionGroup: ConditionalStatementEntities.IConditionGroup) {

    let error = false;

    if (conditionGroup) {
        if (conditionGroup.children && conditionGroup.children.length) {
            conditionGroup.children.forEach(cg => {
                error = validateConditionGroups(cg);
                if (error) return false;
            });
        }

        if (conditionGroup.conditions && conditionGroup.conditions.length) {
            error = validateConditions(conditionGroup.conditions);
        }
    }

    return error

}

function validateConditions(conditions: ConditionalStatementEntities.ICondition[]): boolean {

    let error = false;

    conditions.forEach(condition => {

        if (!condition.field || !condition.field.name) {
            BCNotification.error({ message: findMessage.get('101970'), duration: 5 });
            error = true;
        }
        if (condition.typeName === 'FieldSelectionCondition' && condition.operation !== "Null" &&
            condition.operation !== "NotNull" && (!condition.selectedField || !condition.selectedField.name)) {
            BCNotification.error({ message: findMessage.get('101971'), duration: 5 });
            error = true;
        }
    })

    return error

}