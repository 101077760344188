import Upload from 'antd/lib/upload';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile } from 'antd/lib/upload/interface';
import * as React from 'react';
import { IUploadProps } from './entities';

export default class BCUpload extends React.Component<IUploadProps, IUploadProps>{
    constructor(props: IUploadProps) {
        super(props);
        this.state = { fileList: this.props.fileList }
        this.onChange = this.onChange.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
    }

    componentWillReceiveProps(newProps: IUploadProps) {
        if (newProps.fileList && newProps.fileList.length > 0) {
            this.setState({ fileList: newProps.fileList })
        }
    }

    beforeUpload(file: RcFile, fileMaxSize: number): boolean {
        let _fileMaxSize: boolean = true;
        let typeControl: boolean = true;
        //typeControl = file.type === 'image/jpeg' || file.type === 'image/png';
        //if (!typeControl) {
        //    console.log("You can only upload JPG/PNG file!", `${file.name}`)
        //}
        if (fileMaxSize && fileMaxSize > 0) {
            _fileMaxSize = file.size / 1024 / 1024 < this.props.fileMaxSize;
            if (!fileMaxSize) {
                console.log(`Image must smaller than ${this.props.fileMaxSize}MB!`, `${file.name}`)
            }
        }
        return _fileMaxSize;//&& isJpgOrPng
    }

    onChange(info: UploadChangeParam) {
        let fileList = [...info.fileList];
        if (this.props.shownFileListItemsCount && this.props.shownFileListItemsCount > 0) {
            fileList = fileList.slice(-this.props.shownFileListItemsCount);
            fileList = fileList.map(file => {
                if (file.response) {
                    file.url = file.response.url;
                }
                return file;
            });

            this.setState({ fileList: fileList });
        }
        let _state: UploadChangeParam = { event: info.event, file: info.file, fileList: fileList }
        if (this.props && this.props.onChange) {
            this.props.onChange(_state);
        }
    }

    render() {
        return (
            <Upload beforeUpload={(file: RcFile, FileList: RcFile[]): boolean => { return this.beforeUpload(file, this.props.fileMaxSize); }}  {...this.props} {...this.state} onChange={this.onChange} />
        )
    }
}

export { IUploadProps };

