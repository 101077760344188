enum HROptionTypes {
    Text = 0,
    Integer = 1,
    Boolean = 2,
    Date = 3,
    List = 4,
    Float = 5,
    File = 6,
    Image = 7,
    FromTable = 8
}

export { HROptionTypes }