import { Record,List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent, IPosition, IUser, User, Position, PositionFactory, UserFactory, PositionFactoryFromJS, UserFactoryFromJS, IPropertyValue, PropertyValue, PropertyValueFactoryFromJS, ICompanyObject, CompanyObject, CompanyObjectFactoryFromJS } from "..";

export interface IPositionFormContent extends IBaseFormContent {
    showingPosition?: IPosition,
    customProperties?: Array<IPropertyValue>,
    companyObjects?: Array<ICompanyObject>,
}

export interface IReadonlyPositionFormContent extends IBaseFormContent {
    showingPosition?: Position,
    customProperties?: List<PropertyValue>,
    companyObjects?: List<CompanyObject>,
}

export type PositionFormContent = ReadonlyRecord<IReadonlyPositionFormContent>;

export const PositionFormContentFactory = Record<IReadonlyPositionFormContent>({
    panelId: "",
    showingPosition: PositionFactory(),
    initialHash: "",
    isModified:false,
    selectableListPanelisActive : false,
    customProperties: List<PropertyValue>(),
    showLoading : false,
    companyObjects: List<CompanyObject>()
});

export const PositionFormContentFactoryFromJS = function (data: IPositionFormContent) {
    let _customProperties: Array<PropertyValue> = data.customProperties && data.customProperties.length ? data.customProperties.map((value: IPropertyValue) => {
        return PropertyValueFactoryFromJS(value);
    }) : [];

    let _companyObjects: Array<CompanyObject> = data.companyObjects && data.companyObjects.length ? data.companyObjects.map((value: ICompanyObject) => {
        return CompanyObjectFactoryFromJS(value);
    }) : [];

    return PositionFormContentFactory({
        panelId: data.panelId,
        initialHash: data.initialHash,
        showingPosition: PositionFactoryFromJS(data.showingPosition),
        isModified : data.isModified,
        selectableListPanelisActive : data.selectableListPanelisActive,
        customProperties: List(_customProperties),
        companyObjects: List(_companyObjects),
        showLoading : data.showLoading,
    });
}