import React, { lazy, Suspense } from 'react';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-others" */ "./containers/AnnouncementFormModalContainer")
    .then(({ default: container }) => {
        import(/* webpackChunkName: "web-others" */ "./injector");
        return { default: container };
    }));

function Container(props: React.PropsWithChildren<any>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;