const prefix = "UPLOAD_MANAGER.";
export const START_UPLOAD_MANAGER = prefix + 'START_UPLOAD_MANAGER';
export const ADD_FILE_TO_UPLOAD = prefix + 'ADD_FILE_TO_UPLOAD';
export const REMOVE_FILE_DATA_ACTION = prefix + 'REMOVE_FILE_DATA_ACTION';
export const CLEAR_UPLOAD_LIST_ACTION = prefix + 'CLEAR_UPLOAD_LIST_ACTION';
export const REMOVE_FILE_FROM_UPLOAD = prefix + 'REMOVE_FILE_FROM_UPLOAD';
export const PAUSE_FILE_UPLOAD = prefix + 'PAUSE_FILE_UPLOAD';
export const RESUME_FILE_UPLOAD = prefix + 'RESUME_FILE_UPLOAD';
export const START_FILE_UPLOAD = prefix + 'START_FILE_UPLOAD';
export const PREPARE_FILE_TO_UPLOAD = prefix + 'PREPARE_FILE_TO_UPLOAD';
export const UPDATE_FILE_UPLOAD_STATUS = prefix + 'UPDATE_FILE_UPLOAD_STATUS';
export const CREATE_FILE_UPLOAD_THREADS = prefix + 'CREATE_FILE_UPLOAD_THREADS';
export const UPDATE_FILE_THREAD_STATUS = prefix + 'UPDATE_FILE_THREAD_STATUS';
export const UPDATE_FILE_PROGRESS_ACTION = prefix + 'UPDATE_FILE_PROGRESS_ACTION';
//export const UPDATE_UPLOAD_QUEUE = prefix + 'UPDATE_UPLOAD_QUEUE';
export const FILE_UPLOAD_DONE_ACTION = prefix + 'FILE_UPLOAD_DONE_ACTION';
export const UPLOAD_RESULT_ACTION = prefix + 'UPLOAD_RESULT_ACTION';
export const COPY_FILE_LINK = prefix + 'COPY_FILE_LINK';