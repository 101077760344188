import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { BaseStatement, BaseStatementFactoryFromJS, BaseStatementObject, IBaseStatement, ReadOnlyBaseStatement } from "./IBaseStatement";
import { ConditionGroup, ConditionGroupFactory, ConditionGroupFactoryFromJS, IConditionGroup } from "./IConditionGroup";
import { BaseActionFactoryFromJS, BaseAction, IBaseAction, ActionCastFactory } from ".";

export interface IStatement extends IBaseStatement {
    conditionGroup: IConditionGroup
}

export interface ReadOnlyStatement extends ReadOnlyBaseStatement {
    conditionGroup: ConditionGroup
}

export type Statement = ReadonlyRecord<ReadOnlyStatement>;

export const StatementFactory = Record<ReadOnlyStatement>({
    ...BaseStatementObject,
    conditionGroup: ConditionGroupFactory()
});

export const StatementFactoryFromJS = function (data: IStatement) {
    return StatementFactory({
        ...data,
        conditionGroup: ConditionGroupFactoryFromJS(data.conditionGroup),
        actions: List(ActionCastFactory(data.actions))
    });
}