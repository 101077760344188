import { useMemo, useState } from 'react';
import useMountedState from './useMountedState';

export interface DropAreaState {
    over: boolean;
}

export interface DropAreaBond {
    onDragOver: React.DragEventHandler;
    onDragEnter: React.DragEventHandler;
    onDragLeave: React.DragEventHandler;
    onDrop: React.DragEventHandler;
    onPaste: React.ClipboardEventHandler;
}

export interface DropAreaOptions {
    onFiles?: (files: File[], event?: React.DragEventHandler) => void;
    onText?: (text: string, event?: React.DragEventHandler) => void;
    onUri?: (url: string, event?: React.DragEventHandler) => void;
}

const noop = () => { };
/*
const defaultState: DropAreaState = {
  over: false,
};
*/

const createProcess = (options: DropAreaOptions, mounted: boolean) => (dataTransfer: DataTransfer, event: React.DragEventHandler) => {
    const uri = dataTransfer.getData('text/uri-list');

    if (uri) {
        (options.onUri || noop)(uri, event);
        return;
    }

    if (dataTransfer.files && dataTransfer.files.length) {
        (options.onFiles || noop)(Array.from(dataTransfer.files), event);
        return;
    }

    if (dataTransfer.items && dataTransfer.items.length) {
        dataTransfer.items[0].getAsString(text => {
            if (mounted) {
                (options.onText || noop)(text, event);
            }
        });
    }
};

const createBond = (process: Function, setOver: Function): DropAreaBond => ({
    onDragOver: event => {
        event.preventDefault();
        event.stopPropagation();
    },
    onDragEnter: event => {
        event.preventDefault();
        setOver(true);
    },
    onDragLeave: () => {
        event.preventDefault();
        event.stopPropagation();
        setOver(false);
    },
    onDrop: event => {
        event.persist();
        event.preventDefault();
        event.stopPropagation();
        setOver(false);
        process(event.dataTransfer, event);
    },
    onPaste: event => {
        event.persist();
        process(event.clipboardData, event);
    },
});

const useDropArea = (options: DropAreaOptions = {}): [DropAreaBond, DropAreaState] => {
    const { onFiles, onText, onUri } = options;
    const isMounted = useMountedState();
    const [over, setOver] = useState<boolean>(false);
    const process = useMemo(() => createProcess(options, isMounted()), [onFiles, onText, onUri]);
    const bond: DropAreaBond = useMemo(() => createBond(process, setOver), [process, setOver]);

    return [bond, { over }];
};

export default useDropArea;