import { BaseRequest } from "../../../entities/BaseRequest";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface ILoginRequest extends BaseRequest {
    username: string,
    password: string,
    language: string,
    rememberMe: boolean,
    captcha?: string,
    captchaId?: string
}


export interface IReadonlyLoginRequest extends BaseRequest {
    username: string,
    password: string,
    language: string,
    rememberMe: boolean,
    captcha?: string,
    captchaId?: string
}

export type LoginRequest = ReadonlyRecord<IReadonlyLoginRequest>;

export const LoginRequestFactory = Record<IReadonlyLoginRequest>({
    language: null,
    username: null,
    password: null,
    rememberMe: null,
    captcha: null,
    captchaId: null
});

export const LoginRequestFactoryFromJS = function (data: ILoginRequest) {
    return LoginRequestFactory(data);
}