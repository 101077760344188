import IconAdd2 from "@bimser/icons/16/add2";
import React from "react";
import * as Styles from "./assets/styles.scss";
import { ELayoutItemTypes, IAddNewSectionButton, StructureChangeFactoryFromJS } from "./entities";
import { LayoutStructureItemFactory } from "../../common/entities";
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const AddNewSectionButton = React.memo((props: IAddNewSectionButton) => {

    let sectionClassNames = cx({
        addSectionBtn: true
    });

    const addNewSection = () => {
        props.onStructureChange(StructureChangeFactoryFromJS({
            splitDropZonePath: [props.itemCount.toString()],
            method: "handleMoveSidebarContainerIntoParent",
            dragItemType: ELayoutItemTypes.SIDEBAR_ITEM,
            item: LayoutStructureItemFactory({
                id: props.panelId // Target id
            }).toJS()
        }));
    }

    return (
        <div className={sectionClassNames} onClick={addNewSection}>
            <div className="col">
                <IconAdd2 />
            </div>
        </div>
    )
})

export { AddNewSectionButton };

