import { Record, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { ITreeListState } from ".";

export interface IBaseTreeListsState {

}

export interface ITreeListsState extends IBaseTreeListsState {
    items: IDictionary<ITreeListState>;
}

export interface IReadonlyTreeListsState extends IBaseTreeListsState {
    items: Map<string, ITreeListState>;
}

export type TreeListsState = ReadonlyRecord<IReadonlyTreeListsState>;

export const TreeListsStateFactory = Record<IReadonlyTreeListsState>({
    items: Map(),
});

export const TreeListsStateFactoryFromJS = function (data: ITreeListsState) {
    return TreeListsStateFactory({
        items: Map(data.items),
    });
}


