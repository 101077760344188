import * as React from "react";
import BCButton from "../../BCButton";
import * as Styles from '../assets/styles.scss';
import { IActionGroup, IActionItem, IActionPropertyItem } from "../entities";
import { findMessage } from "../../BCIntl";
import IconAdd from "@bimser/icons/16/add";
import IconBack from "@bimser/icons/16/back";
import IconDelete from "@bimser/icons/16/delete";
const ActionGroup = React.memo((props: IActionGroup) => {
    const renderItems = () => {
        if (props.items?.length) {
            return props.items.map(i => {
                return renderControlItem(i)
            })
        }
    }

    const renderRootItem = () => {
        return (
            <div className={Styles.actionTreeView}>
                {renderItems()}
            </div>
        )
    }

    const deleteProperty = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, property: IActionPropertyItem, control: IActionItem) => {
        props.deleteProperty(property, control)
        e.stopPropagation();
    }

    const convertPropertyData = (data: any) => {
        if (typeof data === 'boolean') {
            return (data as boolean).toString();
        }
        return data
    }

    const renderPropertyItem = (property: IActionPropertyItem, control: IActionItem) => {

        let propertyText = property.text;
        let propertyElem;
        if (propertyText && propertyText.split('.').length > 1) {
            let props = propertyText.split('.');
            let changedProperty = props.pop();
            propertyElem = (
                <span>
                    {props.splice(0, propertyText.length - 1).join('.')}
                    <b>
                        {'.' + changedProperty}
                    </b>
                </span>
            )
        } else {
            propertyElem = (
                <span>
                    <b>
                        {propertyText}
                    </b>
                </span>
            )
        }

        return (
            <div className={Styles.actionItemContent} key={property.key}>
                <div className={Styles.property}>
                    {propertyElem}
                    <IconBack className={Styles.arrowIcon} />
                    <b>{convertPropertyData(property.data)}</b>
                    {renderDeletePropertyButton(property, control)}
                </div>
            </div>
        )
    }

    const renderDeletePropertyButton = (property: IActionPropertyItem, control: IActionItem) => {
        if (!props.readOnly) {
            return (
                <div onClick={(e) => { deleteProperty(e, property, control) }} className={Styles.deleteIcon}>
                    <IconDelete />
                </div>
            )
        }
    }

    const renderControlItem = (item: IActionItem) => {
        return (
            <div className={Styles.actionItem} key={item.key}>
                <div className={Styles.actionItemContent}>
                    <div className={Styles.control}>
                        {item.text}
                    </div>
                </div>
                <div className={Styles.actionItem}>
                    {item.property.map(p => renderPropertyItem(p, item))}
                </div>
            </div>
        )
    }

    const startRecording = React.useCallback(() => props?.startActionRecord(), []);

    const renderAddActionButton = () => {
        if (!props.readOnly) {
            return (
                <div className={Styles.addActionButton}>
                    <BCButton
                        icon={<IconAdd />}
                        text={findMessage.get('101154')}
                        onClick={startRecording}
                        type={'ghost'}
                        size={'small'}
                    />
                </div>
            )
        }
    }

    return (
        <>
            {renderRootItem()}
            {renderAddActionButton()}
        </>
    )

})

export default ActionGroup