import { IExtremeGridState, IExtremeGridColumn } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeSortingReducer(state: IExtremeGridState, action: IBaseMutableAction<any>): IExtremeGridState {
    let newColumns: Array<IExtremeGridColumn> = state.columns.map((item) => {
        let foundColumnIndex = action.payload.findIndex((c => c.columnName == item.name));
        let foundColumn = action.payload[foundColumnIndex];
        if (foundColumn) {
            if (!item.sorting || (item?.sorting?.enabled !== false && (item.sorting.index !== foundColumn.index || item.sorting.order !== foundColumn.direction))) {
                // changed
                let sorting = item.sorting || {};

                return Object.assign({}, item, {
                    sorting: {
                        ...sorting,
                        index: foundColumn.index || foundColumnIndex,
                        order: foundColumn.direction
                    }
                });
            }
            else {
                // unmodified sorted column
                return item;
            }
        } else {
            if (item.sorting?.index !== null || item.sorting?.order !== null) {
                // clear sorting props
                return Object.assign({}, item, {
                    sorting: null,
                });
            }
            else {
                // unmodified
                return item
            }
        }
    });

    return {
        ...state,
        columns: newColumns
    };
}