import { LayoutStructureItems } from "../../../common/entities";
import { LayoutCreatorSelectAction } from "../entities";

export function handleItemSelection(payload: LayoutCreatorSelectAction, state: LayoutStructureItems): LayoutStructureItems {

    if (!state.items.isEmpty() && payload.item?.id) {

        const selectItem = item => {
            const isSelected = item.getIn(['designerProps', 'selected']);
            if (!payload.multiple && item.id !== payload.item.id && isSelected) {
                item = item.setIn(['designerProps', 'selected'], false);
            }

            if (item.id === payload.item.id && isSelected !== payload.selected) {
                item = item.setIn(['designerProps', 'selected'], payload.selected); // Set selected item's selected property to defined value
            }

            return item
        }

        state = state.set("items", state.items.map(selectItem));
        state = state.set("ghostItems", state.ghostItems.map(selectItem));

    }

    return state

}