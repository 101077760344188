import { BCExtremeGridReducer } from "@bimser/components";
import { IBaseAction, IBaseMutableAction, extend, isNullOrUndefined } from "@bimser/core";
import { ExtremeGridsState, IExtremeGridState, IExtremeGridDataReferences } from "../entities";
import * as ActionTypes from "../actionTypes";
import { ICreateExtremeGridPayload, IChangeExtremeGridStatePayload, ISetDataPayload, ISetLoadingPayload, ISetDataRefPayload, ISetCurrentCacheIdPayload, IClearCachePayload, ISetColumnsPayload, ISetSelectionIdsPayload, IUpdateCacheKeyPayload } from "../entities/payloads";
import * as Hash from 'object-hash';
import { IUpdateVirtualScrollingOptions } from '../entities/payloads/IUpdateVirtualScrollingOptions';

export default function (state: ExtremeGridsState, action: IBaseAction<any>): ExtremeGridsState {
    switch (action.type) {
        case ActionTypes.CREATE_EXTREMEGRID: {
            return createExtremeGrid(state, action);
        }
        case ActionTypes.CREATE_EXTREMEGRID_OVERRIDE: {
            return createExtremeGridOverride(state, action);
        }
        case ActionTypes.CHANGE_STATE: {
            return changeState(state, action);
        }
        case ActionTypes.SET_DATA: {
            return setData(state, action);
        }
        case ActionTypes.SET_LOADING: {
            return setLoading(state, action);
        }
        case ActionTypes.SET_DATAREF: {
            return setDataRef(state, action);
        }
        case ActionTypes.SET_CURRENT_CACHEID: {
            return setCurrentCacheId(state, action);
        }
        case ActionTypes.CLEAR_CACHE: {
            return clearCache(state, action);
        }
        case ActionTypes.SET_COLUMNS: {
            return setColumns(state, action);
        }
        case ActionTypes.SET_SELECTIONIDS: {
            return setSelectionIds(state, action);
        }
        case ActionTypes.REMOVE_EXTREMEGRID: {
            return removeDatagrid(state, action);
        }
        case ActionTypes.UPDATE_CACHE_KEY: {
            return updateCacheKey(state, action);
        }
        case ActionTypes.UPDATE_VIRTUAL_SCROLLING_OPTIONS: {
            return updateVirtualScrollingOptions(state, action);
        }
        default:
            return state;
    }
}

function updateVirtualScrollingOptions(state: ExtremeGridsState, action: IBaseMutableAction<IUpdateVirtualScrollingOptions>): ExtremeGridsState {
    let { loading, pageSize, skip, totalRowCount } = action.payload;
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            ...gridState,
            virtualScrollingOptions: {
                ...gridState.virtualScrollingOptions,
                loading: isNullOrUndefined(loading) ? gridState.virtualScrollingOptions.loading : loading,
                pageSize: isNullOrUndefined(pageSize) ? gridState.virtualScrollingOptions.pageSize : pageSize,
                skip: isNullOrUndefined(skip) ? gridState.virtualScrollingOptions.skip : skip,
                totalRowCount: isNullOrUndefined(totalRowCount) ? gridState.virtualScrollingOptions.totalRowCount : totalRowCount
            }
        });
    });
}

function updateCacheKey(state: ExtremeGridsState, action: IBaseMutableAction<IUpdateCacheKeyPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            ...gridState,
            cacheKey: action.payload.cacheKey
        });
    });
}

function removeDatagrid(state: ExtremeGridsState, action: IBaseMutableAction<ISetColumnsPayload>): ExtremeGridsState {
    if (action.payload?.id) {
        let items = state.items;
        if (items.has(action.payload.id)) {
            return state.removeIn(["items", action.payload.id]);
        }
    }
    return state;
}

function setSelectionIds(state: ExtremeGridsState, action: IBaseMutableAction<ISetSelectionIdsPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            ...gridState,
            selection: { ...gridState.selection, selectedIds: action.payload.selectionIds }
        });
    });
}

function setColumns(state: ExtremeGridsState, action: IBaseMutableAction<ISetColumnsPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            columns: action.payload.columns
        });
    });
}

function createExtremeGrid(state: ExtremeGridsState, action: IBaseMutableAction<ICreateExtremeGridPayload>): ExtremeGridsState {
    if (action.payload?.id) {
        if (!state.items.has(action.payload.id)) {
            let items = state.items.set(action.payload.id, extend(true, {}, action.payload));
            return state.set("items", items);
        }
    }

    return state;
}

function createExtremeGridOverride(state: ExtremeGridsState, action: IBaseMutableAction<ICreateExtremeGridPayload>): ExtremeGridsState {
    if (action.payload?.id) {
        let items = state.items;

        if (items.has(action.payload.id)) {
            items = items.remove(action.payload.id);
        }

        items = items.set(action.payload.id, extend(true, {}, action.payload));
        return state.set("items", items);
    }
}

function changeState(state: ExtremeGridsState, action: IBaseMutableAction<IChangeExtremeGridStatePayload<any>>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({},gridState , BCExtremeGridReducer(gridState, action.payload.action));
    });
}

function setData(state: ExtremeGridsState, action: IBaseMutableAction<ISetDataPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            dataSource: {
                ...gridState.dataSource ? gridState.dataSource : {},
                data: action.payload.dataSource.data,
                totalCount: action.payload.dataSource.totalCount
            }
        });
    });
}

function setLoading(state: ExtremeGridsState, action: IBaseMutableAction<ISetLoadingPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, { isLoading: action.payload.state });
    });
}

function setDataRef(state: ExtremeGridsState, action: IBaseMutableAction<ISetDataRefPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            dataRefs: setDataRefs(action, gridState.dataRefs)
        });
    });
}

function setCurrentCacheId(state: ExtremeGridsState, action: IBaseMutableAction<ISetCurrentCacheIdPayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            dataRefs: {
                ...gridState.dataRefs,
                current: action.payload.hash
            }
        });
    });
}

function clearCache(state: ExtremeGridsState, action: IBaseMutableAction<IClearCachePayload>): ExtremeGridsState {
    return setGridProperty(state, action, (gridState) => {
        return Object.assign({}, gridState, {
            dataRefs: undefined
        });
    });
}



////////////////////////

const initialDataRefs: IExtremeGridDataReferences = {
    caches: {},
    current: null
}
function setDataRefs(action: IBaseMutableAction<ISetDataRefPayload>, state: IExtremeGridDataReferences = initialDataRefs): IExtremeGridDataReferences {
    if (action.payload) {
        let hash = null;
        if (action.payload.requestParameters) {
            hash = Hash(action.payload.requestParameters);
        }

        if (hash) {
            return {
                caches: {
                    ...state.caches,
                    [hash]: {
                        requestParameters: action.payload.requestParameters,
                        items: action.payload.items,
                        totalCount: action.payload.totalCount
                    }
                },
                current: action.payload.setCurrent ? hash : state.current
            }
        }
    }

    return state;
}

function setGridProperty(state: ExtremeGridsState, action: IBaseMutableAction<any>, callback: (gridState: IExtremeGridState) => IExtremeGridState): ExtremeGridsState {
    if (action.payload?.id) {
        let gridState = state.items.get(action.payload.id);
        if (gridState) {
            gridState = callback(gridState);

            let items = state.items.set(action.payload.id, gridState);
            return state.set("items", items);
        }
    }

    return state;
}