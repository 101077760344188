import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBasePosition {
    id: number,
    positionCode: string,
    status: number,
    type: number,
    importStatus?: number,
    description: string,
    userId: number,
    importedPosCode: string,
    loadDetail: boolean,
    username: string,
    userFullname: string
}

export interface IPosition extends IBasePosition { }

export interface IReadonlyPosition extends IBasePosition { }

export type Position = ReadonlyRecord<IReadonlyPosition>;

export const PositionFactory = Record<IReadonlyPosition>({
    id: 0,
    positionCode: '',
    status: 0,
    type: 0,
    importStatus: 0,
    description: '',
    userId: 0,
    importedPosCode: '',
    loadDetail: false,
    username: null,
    userFullname: null
});

export const PositionFactoryFromJS = function (data: IPosition) {
    return PositionFactory(data);
}