import * as Styles from './assets/styles.scss';
import notification, { ArgsProps } from 'antd/lib/notification';
import * as React from 'react';
import SeveralWarnIcon from '@bimser/icons/16/toaster-severe-warning';
import InfoWarningIcon from '@bimser/icons/16/toaster-info-warning';
import SuccessIcon from '@bimser/icons/16/toaster-success';
import ErrorIcon from '@bimser/icons/16/toaster-error';
import BlockedIcon from '@bimser/icons/16/toaster-blocked';

Styles;
notification.config({
    duration: 2,
})

const BCNotification = {
    success: (args: ArgsProps) => {
        notification.success({
            ...args,
            icon: React.createElement(SuccessIcon),
            className: "successNotification"
        });
    },
    warn: (args: ArgsProps) => {
        notification.warning({
            ...args,
            icon: React.createElement(InfoWarningIcon),
            className: "warnNotification"
        });
    },
    warning: (args: ArgsProps) => {
        notification.warning({
            ...args,
            icon: React.createElement(InfoWarningIcon),
            className: "warningNotification"
        });
    },
    error: (args: ArgsProps) => {
        notification.error({
            ...args,
            icon: React.createElement(ErrorIcon),
            className: "errorNotification"
        });
    },
    blocked: (args: ArgsProps) => {
        notification.error({
            ...args,
            icon: React.createElement(BlockedIcon),
            className: "blockedNotification"
        });
    },
    info: (args: ArgsProps) => {
        notification.info({
            ...args,
            icon: React.createElement(InfoWarningIcon),
            className: "infoNotification"
        });
    },
    severeWarning: (args: ArgsProps) => {
        notification.warning({
            ...args,
            icon: React.createElement(SeveralWarnIcon),
            className: "severeWarningNotification"
        });
    },
    open: (args: ArgsProps) => {
        notification.open(args);
    },
    close: (notificationKey: string) => {
        notification.close(notificationKey);
    }
}

export default BCNotification;