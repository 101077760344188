import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { ContainerLayout, ContainerLayoutFactory, ContainerLayoutFactoryFromJS, IContainerLayout } from "./IContainerLayout";


export interface IBaseLayoutItemEngineProps {
    selected?: boolean,
    locked?: boolean
}

export interface ILayoutItemEngineProps extends IBaseLayoutItemEngineProps {
    layout: IContainerLayout
}

export interface IReadonlyLayoutItemEngineProps extends IBaseLayoutItemEngineProps {
    layout: ContainerLayout
}

export type LayoutItemEngineProps = ReadonlyRecord<IReadonlyLayoutItemEngineProps>;

export const LayoutItemEnginePropsRecord: IReadonlyLayoutItemEngineProps = {
    selected: false,
    locked: false,
    layout: ContainerLayoutFactory()
};

export const LayoutItemEnginePropsFactory = Record<IReadonlyLayoutItemEngineProps>(LayoutItemEnginePropsRecord);

export const LayoutItemEnginePropsFactoryFromJS = function (data: ILayoutItemEngineProps) {
    return LayoutItemEnginePropsFactory(LayoutItemEnginePropsFactoryObjectFromJS(data));
}

export const LayoutItemEnginePropsFactoryObjectFromJS = function (data: ILayoutItemEngineProps) {

    if (!data) {
        return {
            layout: ContainerLayoutFactory(),
            selected: false,
            locked: false
        }
    }

    return {
        layout: ContainerLayoutFactoryFromJS(data.layout),
        selected: data.selected,
        locked: data.locked
    };
}