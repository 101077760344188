import IconAdd from "@bimser/icons/16/add";
import * as React from "react";
import BCButton from "../../../BCButton";
import BCCombobox from '../../../BCCombobox';
import BCDivider from "../../../BCDivider";
import { findMessage } from "../../../BCIntl";
import * as Styles from "../../assets/style.scss";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defaultStyle: React.CSSProperties = { width: '100%' };

export default function EventTriggerProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged({ ...item, value: newValue }, newValue);
    }

    const onClick = () => {
        props.fireAction?.(props.item, "PROPERTY_INSPECTOR.ON_EVENT_TRIGGER")
    }

    const onChange = (e: any) => {
        props.customChangeEvent ? props.customChangeEvent(e.data) : replaceValue(props.item, e.data);
    }

    const dropdownRender = (menus: React.ReactNode) => {
        return (
            <div>
                {menus}
                <BCDivider className={Styles.selectedFieldDivider} />
                <BCButton type={"link"} onClick={onClick} icon={<IconAdd className={Styles.addIcon} />} text={findMessage.get("102857")} />
            </div>
        )
    }

    return (
        <BCCombobox
            dropdownRender={dropdownRender}
            onSelectedChange={onChange}
            options={props.item.options}
            showSearch
            style={props.style || defaultStyle}
            disabled={props.item.disabled || props.item.readOnly}
            allowClear
            value={props.value}
        />
    )
}