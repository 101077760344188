import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IIndexReportItem, IndexReportItem, IndexReportItemFactoryFromJS } from "./IIndexReportItem";

interface IBaseIndexReportDetailFileList {
    isAnalizing: boolean
}

export interface IIndexReportDetailFileList extends IBaseIndexReportDetailFileList {
    items: Array<IIndexReportItem>
}

export interface IReadonlyIndexReportDetailFileList extends IBaseIndexReportDetailFileList {
    items: List<IndexReportItem>
}

export type IndexReportDetailFileList = ReadonlyRecord<IReadonlyIndexReportDetailFileList>;

export const IndexReportDetailFileListFactory = Record<IReadonlyIndexReportDetailFileList>({
    isAnalizing: false,
    items: List()
});

export const IndexReportDetailFileListFactoryFromJS = function (data: IIndexReportDetailFileList) {
    return IndexReportDetailFileListFactory({
        ...data,
        items: List(data?.items?.map(i => IndexReportItemFactoryFromJS(i)) || [])
    });
}