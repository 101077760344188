import Input, { InputRef } from 'antd/lib/input';
import throttle from 'lodash/throttle';
import * as React from "react";
import { findMessage, notification } from "..";
import * as Styles from './assets/styles.scss';
import { IInputChangeEventArgs, IInputProps, InputSizes } from './entities';
import IRegExp from "./entities/IRegExp";
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const BCInput = React.forwardRef((props: IInputProps, ref: React.ForwardedRef<InputRef>) => {

    const [value, setValue] = React.useState(props.value?.valueOf() || '');

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    Styles;

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => props.onBlur?.(e);
    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => props.onFocus?.({ senderArgs: e, data: e.currentTarget.value });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let validControl = false;
        if (props.regExp?.regExpString) {
            validControl = validationControl(e.currentTarget.value);
        }

        if (!validControl) {
            let args: IInputChangeEventArgs = { senderArgs: e, data: e.currentTarget.value, itemKey: props.itemKey };
            if (props.isMaskInput) {
                setValue(props.value);
            } else {
                setValue(args.data);
            }
            triggerOnChange(args);
        }
    }

    const showValidationError = React.useCallback(throttle(() => {
        notification.error({
            message: findMessage.get("101291"),
            description: props.regExp.validationFailedMessage || findMessage.get("101313"),
            placement: "topRight"
        });
    }, 500), [props.regExp]);

    const validationControl = (value?: string): boolean => {
        let validFormat = value.match(props.regExp.regExpString);
        if (value && !validFormat) {
            showValidationError();
            setValue(props.value);
            return true;
        }
        else if (!value && value != "") {
            setValue(value);
            return true;
        }
        return false;
    }

    const triggerOnChange = (args: IInputChangeEventArgs) => props.onChange?.(args);

    const className = cx({
        inputStyles: true,
        textalignRight: props.textAlign === "right",
        textalignLeft: props.textAlign === "left",
        textalignCenter: props.textAlign === "center",
        [props.className]: true
    });

    return (
        <Input
            tabIndex={props.tabIndex}
            prefix={props.prefix}
            disabled={props.disabled}
            readOnly={props.readOnly}
            size={props.size}
            style={props.style}
            placeholder={props.placeHolder}
            value={value}
            maxLength={props.maxLength}
            className={className}
            onPressEnter={props.onPressEnter}
            onKeyDown={props.onKeyDown}
            type={props.type}
            addonBefore={props.addonBefore}
            addonAfter={props.addonAfter}
            onClick={props.onClick}
            suffix={props.suffix}
            allowClear={props.allowClear}
            autoFocus={props.autoFocus}
            formNoValidate
            autoComplete={props.autoComplete}
            showCount={props.showCount}
            ref={ref}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
})

export default BCInput
export { IInputProps, IInputChangeEventArgs, InputSizes, IRegExp, InputRef as BCInputRef };
