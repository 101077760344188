import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { BaseStatementFactoryFromJS, BaseStatementObject, IBaseStatement, ReadOnlyBaseStatement } from "./IBaseStatement";

export interface IElseStatement extends IBaseStatement {

}

export interface ReadOnlyElseStatement extends ReadOnlyBaseStatement {

}

export type ElseStatement = ReadonlyRecord<ReadOnlyElseStatement>;

export const ElseStatementFactory = Record<ReadOnlyElseStatement>(BaseStatementObject);

export const ElseStatementFactoryFromJS = function (data: IElseStatement) {
    return ElseStatementFactory(BaseStatementFactoryFromJS(data));
}