import * as React from "react";
import { IPropertyComponentProps } from '../../entities';
import BCImageUpload from '../../../BCImageUpload';

export default function ImageUploadProperty(props: IPropertyComponentProps) {

    const onValueChange = (value: any) => {
        props.onValueChanged(props.item, value);
    }

    return (
        <BCImageUpload
            name={props.item?.externalData?.name}
            accept={"image/*"}
            listType={"picture-card"}
            maxCount={1}
            showRemoveIcon={true}
            showPreviewIcon={true}
            value={props.value}
            multiple={false}
            onValueChange={onValueChange}
        />
    )
}