import * as React from 'react';
import IConfirmationBoxProps from '../entities/IConfirmationBoxProps';
import { BCModal, BCInput, IInputChangeEventArgs, findMessage } from '@bimser/components';
import * as Style from '../assets/confirmationBoxStyle.scss';

const ConfirmationBox = (props: IConfirmationBoxProps) => {

    const [text, setText] = React.useState("");

    const Strings = {
        BTN_OK: props.okButtonText ? props.okButtonText : findMessage.get('100013'),
        BTN_CANCEL: props.cancelButtonText ? props.cancelButtonText : findMessage.get('100034'),
        WARNING_TEXT: findMessage.get('101355') 
    }

    function onTextChange(args: IInputChangeEventArgs) {
        setText(args.data);
    }

    function generateTemplate() {
        return (
            <>
                <span className={Style.ContentMessage}>{props.contentMessage}</span>
                <span className={Style.WarningMessage}>
                    {Strings.WARNING_TEXT}
                    <span className={Style.ConfirmationText}>{props.confirmationText}</span>
                </span>
                <BCInput value={text} onChange={onTextChange} />
            </>
        )
    }

    function handleOkClick() {
        props.onResolve();
    }

    function handleCancelClick() {
        props.onReject(props.id);
    }

    return (
        <BCModal
            id={props.id}
            className={Style.ConfirmationBoxModal}
            modalContentWrapperCssClass={Style.ConfirmationBoxContainer}
            title={props.title}
            width={"500px"}
            visible={true}
            handleOk={handleOkClick}
            handleCancel={handleCancelClick}
            okText={Strings.BTN_OK}
            cancelText={Strings.BTN_CANCEL}
            okButtonProps={{ disabled: props.confirmationText !== text }}
        >
            {generateTemplate()}
        </BCModal>
    )
}

export default ConfirmationBox