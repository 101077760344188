import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Record, Map } from "immutable";

interface IBaseIndexReportDetail {
    isAnalizing: boolean,
    sumSize: number,
    fileCount: number,
    folderCount: number,
    repositoryCount: number
}

export interface IIndexReportDetail extends IBaseIndexReportDetail {
    mimeTypes: IDictionary<number>,
    indexStatus: IDictionary<number>,
    tags: IDictionary<number>
}

export interface IReadonlyIndexReportDetail extends IBaseIndexReportDetail {
    mimeTypes: Map<string, number>,
    indexStatus: Map<string, number>,
    tags: Map<string, number>,
}

export type IndexReportDetail = ReadonlyRecord<IReadonlyIndexReportDetail>;

export const IndexReportDetailFactory = Record<IReadonlyIndexReportDetail>({
    isAnalizing: false,
    sumSize: 0,
    fileCount: 0,
    folderCount: 0,
    repositoryCount: 0,
    mimeTypes: Map(),
    indexStatus: Map(),
    tags: Map()
});

export const IndexReportDetailFactoryFromJS = function (data: IIndexReportDetail) {

    return IndexReportDetailFactory({
        ...data,
        mimeTypes: Map(data.mimeTypes),
        indexStatus: Map(data.indexStatus),
        tags: Map(data.tags)
    });
}