import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IEventItem {
    id: number,
    description: string,
    icon: string,
    confirm: boolean,
    processId: number,
    requestId: number,
    digitalSignatureRequired?: boolean,
    form?: string,
    eventIcon?: string,
    eventText?: string,
    eventValidate?: boolean,
    orderNo?: number,
    processRequestId?: number,
    reason?: boolean
}

export interface IReadonlyEventItem {
    id: number,
    description: string,
    icon: string,
    confirm: boolean,
    processId: number,
    requestId: number,
    digitalSignatureRequired?: boolean,
    form?: string,
    eventIcon?: string,
    eventText?: string,
    eventValidate?: boolean,
    orderNo?: number,
    processRequestId?: number,
    reason?: boolean
}

export type EventItem = ReadonlyRecord<IReadonlyEventItem>;

export const EventItemFactory = Record<IReadonlyEventItem>({
    id: null,
    description: null,
    icon: null,
    confirm: false,
    processId: 0,
    requestId: 0,
    digitalSignatureRequired: false,
    form: "",
    eventIcon: "",
    eventText: "",
    eventValidate: false,
    orderNo: 0,
    processRequestId: 0,
    reason: false
});

export const EventItemFactoryFromJS = function (data: IEventItem) {
    return EventItemFactory(data);
}