import { createMutableAction, createSyncAction } from "@bimser/core";
import { List } from "immutable";
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from '../../../../../../../common/entities';
import OnChangeFilePropertyPayload from "../entities/OnChangeFilePropertyPayload";
import { ADD_NEW_FILES_ACTION, ON_FILE_PROPERTY_CHANGE_ACTION, ON_FOLDER_PROP_CHANGE_ACTION, ON_FORM_PROPERTY_CHANGE_ACTION, PROFILE_FORM_RESPONSE_TRANSACTION, REMOVE_FILE_FROM_LIST_ACTION, SAVE_PROFILE_FORMS, SET_SELECTED_FORM_TAB_ACTION, SET_SELECTED_IDS_ACTION } from "./actionTypes";


export const onChangeProfileFormFilePropertyAction = (payload: OnChangeFilePropertyPayload) => {
    return createMutableAction(ON_FILE_PROPERTY_CHANGE_ACTION, payload);
}

export const onRemoveFileFromListAction = (fileId: string) => {
    return createMutableAction(REMOVE_FILE_FROM_LIST_ACTION, fileId);
}

export const onSaveAllProfileFormsTransaction = (payload: IWebInterfaceAction) => {
    return createSyncAction(SAVE_PROFILE_FORMS, WebInterfaceActionFactoryFromJS(payload))
}

export const onChangeProfileFormPropertyAction = (payload: OnChangeFilePropertyPayload) => {
    return createMutableAction(ON_FORM_PROPERTY_CHANGE_ACTION, payload);
}

export const onSetSelectedIdsAction = (payload: string[]) => {
    return createSyncAction(SET_SELECTED_IDS_ACTION, List(payload));
}

export const onSetSelectedFormTabAction = (payload: string) => {
    return createMutableAction(SET_SELECTED_FORM_TAB_ACTION, payload);
}

export const onAddNewFilesAction = (payload: IWebInterfaceAction) => {
    return createSyncAction(ADD_NEW_FILES_ACTION, WebInterfaceActionFactoryFromJS(payload));
}

export const onProfileFormResponseTransaction = (payload: IWebInterfaceAction) => {
    return createSyncAction(PROFILE_FORM_RESPONSE_TRANSACTION, WebInterfaceActionFactoryFromJS(payload));
}

export const onFolderPropChangeAction = (payload: OnChangeFilePropertyPayload) => {
    return createMutableAction(ON_FOLDER_PROP_CHANGE_ACTION, payload);
}
