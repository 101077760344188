import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { Mobile } from '../../..';
import { INotificationMessage } from "../entities";
import { showPopupMessage, showToasterMessage } from "./";

export function showServerSideMessages(data: LayoutCreatorEntities.INormalizedLayoutItems, windowId?: string): LayoutCreatorEntities.INormalizedLayoutItems {

    if (data?.result?.length) {

        const formItemId = data.result[0];
        const items = data.entities.items;
        const formItem = items[formItemId];

        if (formItem?.properties?.messages) {

            const messages: INotificationMessage[] = formItem.properties.messages;
            const notificationMessages: INotificationMessage[] = messages.filter(i => i.alertType !== 'Validation');
            const validationMessages: INotificationMessage[] = messages.filter(i => i.alertType === 'Validation');

            showNotificationMessages(notificationMessages);
            showValidationMessages(validationMessages, windowId);

            notificationMessages.length && Mobile.MobileView.mobileServerSideMessages("notificationMessages", notificationMessages);
            validationMessages.length && Mobile.MobileView.mobileServerSideMessages("validationMessages", validationMessages);

            // Remove messages
            data.entities.items[formItemId].properties.messages = [];

        }
    }

    return data;

}

function showNotificationMessages(notificationMessages: INotificationMessage[]) {
    // Show notification messages one by one
    notificationMessages.forEach(message => {

        switch (message.dialogType) {
            case 'Toaster': {
                showToasterMessage(message.title, message.alertType, message.text);
                break;
            }
            case 'Popup': {
                showPopupMessage(message.title, message.alertType, message.text);
                break;
            }
            case 'Summary': {

                break;
            }
            default: console.warn('The notification type that you trying to run is not found!');
        }

    });
}

function showValidationMessages(validationMessages: INotificationMessage[], windowId: string) {
    // Show validation messages on signle notification
    const popupMessages: INotificationMessage[] = validationMessages.filter(i => i.dialogType === 'Popup');
    const toasterMessages: INotificationMessage[] = validationMessages.filter(i => i.dialogType === 'Toaster');

    if (toasterMessages?.length) {

        let highlightList: string[] = [];

        toasterMessages.forEach(message => { highlightList = [...highlightList, ...(message?.highlightList || [])] });

        highlightList = highlightList.filter((value, index, self) => { return self.indexOf(value) === index; });

        toasterMessages.forEach((m) => {
            showToasterMessage(m?.title, 'Validation', m?.text, highlightList, windowId);
        });
    }

    if (popupMessages?.length) {

        let highlightList: string[] = [];

        popupMessages.forEach(message => { highlightList = [...highlightList, ...(message?.highlightList || [])] });

        highlightList = highlightList?.filter((value, index, self) => { return self.indexOf(value) === index; });

        popupMessages.forEach((m) => {
            showPopupMessage(m?.title, 'Validation', m?.text, highlightList, windowId);
        });
    }
}