import * as React from "react";
import * as ReactDOM from "react-dom";

import { DropTarget, DropTargetSpec, DropTargetCollector, DndOptions, DndComponentClass, DropTargetMonitor } from 'react-dnd';
import { IDropTargetProps } from '../entities';

export default function DropTargetWrapper(args: IDropTargetProps): DndComponentClass<any, any> {

    const types: string | string[] | ((props: any) => string | string[]) = args.options.types;

    const spec: DropTargetSpec<any> = {
        ...args.options.spec
    }

    const collect = (connect: any, monitor: DropTargetMonitor) => {
        return args.options.collect(connect, monitor, {});
    }

    const options: DndOptions<any> = Object.assign({}, args.options.options);

    return DropTarget(types, spec, collect, options)(args.componentClass);
}