enum DataTypes {
    text = "string",
    boolean = "boolean",
    actionButton = "object",
    date = "date",
    number = "number",
    dateTime = "datetime",
    time = "time",
    select = "object",
    multiLanguage = "multiLanguage",
    dateRange = "dateRange",
} 

export default DataTypes;