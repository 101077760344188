import React, { lazy, Suspense } from 'react';
import IIndexReportProps from './entities/IIndexReportProps';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-index-report" */ "./containers"));

function IndexReportContainer(props: React.PropsWithChildren<IIndexReportProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
IndexReportContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-index-report" */ "./injector");
}

export default IndexReportContainer;