import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { BaseValueAssignActionFactoryObject, BaseValueAssignActionFactoryObjectFromJS, IBaseValueAssignAction, ReadOnlyBaseValueAssignAction } from "./IBaseValueAssignAction";

export interface IValueAssignActionWithEntry extends IBaseValueAssignAction {
    value: any
}

export interface ReadOnlyValueAssignActionWithEntry extends ReadOnlyBaseValueAssignAction {
    value: any
}

export type ValueAssignActionWithEntry = ReadonlyRecord<ReadOnlyValueAssignActionWithEntry>;

export const ValueAssignActionWithEntryFactory = Record<ReadOnlyValueAssignActionWithEntry>({
    value: null,
    ...BaseValueAssignActionFactoryObject
});

export const ValueAssignActionWithEntryFactoryFromJS = function (data: IValueAssignActionWithEntry) {
    return ValueAssignActionWithEntryFactory({
        ...BaseValueAssignActionFactoryObjectFromJS(data),
        value: data.value
    });
}