import * as React from "react";
import * as ReactDOM from "react-dom";

import { DragSource, DndOptions, DndComponentClass, DragSourceSpec, DragSourceCollector, DragSourceMonitor } from 'react-dnd';
import { IDragSourceProps } from '../entities';

export default function DragSourceWrapper(args: IDragSourceProps): DndComponentClass<any, any> {

    const type: string | ((props: any) => string) = args.options.type;

    const spec: DragSourceSpec<any, any> = {
        ...args.options.spec
    };

    const collect = (connect: any, monitor: DragSourceMonitor) => {
        return args.options.collect(connect, monitor, {});
    }

    const options: DndOptions<any> = Object.assign({}, args.options.options);

    return DragSource(type, spec, collect, options)(args.componentClass);
}