import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IContainerLayout {
    width?: string;
    visible?: boolean;
    clientVisible?: boolean;
    padding?: string;
}

export type ContainerLayout = ReadonlyRecord<IContainerLayout>;

export const ContainerLayoutFactory = Record<IContainerLayout>({
    width: null,
    visible: true,
    clientVisible: true,
    padding: null
});

export const ContainerLayoutFactoryFromJS = function (data: IContainerLayout) {
    return ContainerLayoutFactory(data);
}