import { ConditionalStatementEntities } from '@bimser/components/dist/BCConditionalStatement';
import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { Map, Record } from "immutable";
import { FormSaveRequestData, FormSaveRequestDataFactory, FormSaveRequestDataFactoryFromJS, IFormSaveRequestData } from './IFormSaveRequestData';
import { IFormStatus } from './IFormStatus';

export interface IBaseFormSaveAsPayload {
    onAfterSaveAs: (status: IFormStatus, secretKey: string) => void,
    validate?: boolean,
}

export interface IFormSaveAsPayload extends IBaseFormSaveAsPayload {
    items: IDictionary<LayoutCreatorEntities.ILayoutItem>,
    requestData: IFormSaveRequestData,
    rules: IDictionary<ConditionalStatementEntities.IRule>
}

export interface IReadonlyFormSaveAsPayload extends IBaseFormSaveAsPayload {
    items: Map<string, LayoutCreatorEntities.LayoutItem>,
    requestData: FormSaveRequestData,
    rules: Map<string, ConditionalStatementEntities.Rule>
}

export type FormSaveAsPayload = ReadonlyRecord<IReadonlyFormSaveAsPayload>;

export const FormSaveAsPayloadFactory = Record<IReadonlyFormSaveAsPayload>({
    items: Map(),
    requestData: FormSaveRequestDataFactory(),
    onAfterSaveAs: null,
    rules: Map(),
    validate: true,
});

export const FormSaveAsPayloadFactoryFromJS = function (data: IFormSaveAsPayload) {

    let items: IDictionary<LayoutCreatorEntities.LayoutItem> = {};

    for (let key in data.items) {
        let item = data.items[key];
        items[key] = LayoutCreatorEntities.LayoutItemFactoryFromJS(item);
    }

    let rules: IDictionary<ConditionalStatementEntities.Rule> = {};

    for (let key in data.rules) {
        let item = data.rules[key];
        rules[key] = ConditionalStatementEntities.RuleFactoryFromJS(item);
    }

    return FormSaveAsPayloadFactory({
        items: Map(items),
        rules: Map(rules),
        requestData: FormSaveRequestDataFactoryFromJS(data.requestData),
        onAfterSaveAs: data.onAfterSaveAs,
        validate: data.validate
    });
}