import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IProcess, Process, ProcessFactoryFromJS } from "./types/IProcess";

interface IBaseProcessesState {
    loading: boolean,
    totalCount: number
}

export interface IProcessesState extends IBaseProcessesState {
    data: Array<IProcess>,
    selected?: IProcess
}

export interface IReadonlyProcessesState extends IBaseProcessesState {
    data: List<Process>,
    selected?: Process
}

export type ProcessesState = ReadonlyRecord<IReadonlyProcessesState>;

export const ProcessesStateFactory = Record<IReadonlyProcessesState>({
    loading: false,
    data: List<Process>(),
    totalCount: 0,
    selected: null
});

export const ProcessesStateFactoryFromJS = function (data: IProcessesState) {
    const _data: Array<Process> = data.data.map((item: IProcess) => ProcessFactoryFromJS(item));

    return ProcessesStateFactory({
        ...data,
        data: List<Process>(_data),
        selected: data.selected ? ProcessFactoryFromJS(data.selected) : null
    })
}