import React, { lazy, Suspense } from 'react';
import IWorkflowFrameProps from "./entities/IWorkflowFrameProps"
import IWorkflowFrameCProps from "./entities/IWorkflowFrameCProps"

const WorkflowFrameContainerLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./components"));

function WorkflowFrameContainer(props: React.PropsWithChildren<IWorkflowFrameProps & IWorkflowFrameCProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <WorkflowFrameContainerLazy {...props} />
        </Suspense>
    );
}

export default WorkflowFrameContainer;