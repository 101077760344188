import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";
import { ConfigurationManagerNodeItem, ConfigurationManagerNodeItemFactoryFromJS, IConfigurationManagerNodeItem } from "../components/nodes/entities/interfaces/IConfigurationManagerNodeItem";

export interface IConfigurationManagerState {
    nodes : IDictionary<IConfigurationManagerNodeItem>,
    items : IDictionary<any>,
    initialData : IDictionary<any>,
    changedData ?: IDictionary<any>,
    reasonData ?: IDictionary<any>,
    selectedNode : string,
    panelId : string
    showLoading? : boolean,
}

export interface IReadonlyConfigurationManagerState {
    nodes : Map<string,ConfigurationManagerNodeItem>,
    items : Map<string,any>,
    initialData : Map<string,any>,
    changedData ?: Map<string,any>,
    reasonData ?: Map<string,any>,
    selectedNode : string,
    panelId : string
    showLoading? : boolean,
}

export type ConfigurationManagerState = ReadonlyRecord<IReadonlyConfigurationManagerState>;

export const ConfigurationManagerStateFactory = Record<IReadonlyConfigurationManagerState>({
    nodes : Map<string,ConfigurationManagerNodeItem>(),
    items : Map<string,any>(),
    initialData : Map<string,any>(),
    changedData : Map<string,any>(),
    reasonData : Map<string,any>(),
    selectedNode : null,
    panelId : null,
    showLoading : false,
});

export const ConfigurationManagerStateFactoryFromJS = function (data : IConfigurationManagerState) {
    let _nodes: IDictionary<ConfigurationManagerNodeItem> = Object.keys(data.nodes).reduce(function (previous: IDictionary<ConfigurationManagerNodeItem>, current) {
        previous[current] = ConfigurationManagerNodeItemFactoryFromJS(data.nodes[current]);
        return previous;
    }, {});

    return ConfigurationManagerStateFactory({
        nodes : Map(_nodes),
        items : Map(data.items),
        initialData : Map(data.initialData),
        changedData : Map(data.changedData),
        reasonData : Map(data.reasonData),
        selectedNode : data.selectedNode,
        panelId: data.panelId,
        showLoading: data.showLoading
    });
};

