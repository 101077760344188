import { ErrorCodes } from '@bimser/common';
import { BCNotification, ErrorInfo, findMessage } from '@bimser/components';
import { IDictionary, isNullOrUndefined, MLHelper } from "@bimser/core";
import * as Hash from 'object-hash';
import { panelType } from './entities/enums';

export default class Utils {
    public static DateFormat: string = "DD.MM.YYYY";
    public static DateTimeFormat: string = "L LTS";

    public static GetComponentKeyFromPanelType(type: panelType): string {
        switch (type) {
            case panelType.startupLayout:
                return "STARTUP";
            case panelType.deprecatedStartupLayout: 
                return "DEPRECATED_STARTUP"
            case panelType.announcementList:
                return "ANNOUNCEMENTS";
            case panelType.announcementForm:
                return "ANNOUNCEMENT_FORM";
            case panelType.WorkflowFrame:
                return "WORKFLOW_FRAME";
            case panelType.flowViewer:
                return "FLOW_VIEWER";
            case panelType.flowSchema:
                return "FLOW_SCHEMA";
            case panelType.flowHistory:
                return "FLOW_HISTORY";
            case panelType.HrMenuForm:
                return "HR0";
            case panelType.userListForm:
                return "HR1";
            case panelType.userForm:
                return "HR2";
            case panelType.companyListForm:
                return "HR3";
            case panelType.companyForm:
                return "HR4";
            case panelType.positionListForm:
                return "HR5";
            case panelType.departmentListForm:
                return "HR6";
            case panelType.professionListForm:
                return "HR7";
            case panelType.positionForm:
                return "HR8";
            case panelType.departmentForm:
                return "HR9";
            case panelType.professionForm:
                return "HR10";
            case panelType.userMaintenanceListForm:
                return "HR11";
            case panelType.userMaintenanceForm:
                return "HR12";
            case panelType.departmentMaintenanceListForm:
                return "HR13";
            case panelType.departmentMaintenanceForm:
                return "HR14";
            case panelType.positionMaintenanceListForm:
                return "HR15";
            case panelType.positionMaintenanceForm:
                return "HR16";
            case panelType.professionMaintenanceListForm:
                return "HR17";
            case panelType.professionMaintenanceForm:
                return "HR18";
            case panelType.propertiesForm:
                return "HR19";
            case panelType.userPropertiesForm:
                return "HR20";
            case panelType.positionPropertiesForm:
                return "HR21";
            case panelType.professionPropertiesForm:
                return "HR22";
            case panelType.departmentPropertiesForm:
                return "HR23";
            case panelType.userGroupPropertiesForm:
                return "HR24";
            case panelType.propertyTypeForm:
                return "HR25";
            case panelType.userGroupForm:
                return "HR26";
            case panelType.userGroupListForm:
                return "HR27";
            case panelType.companyAdminListForm:
                return "HR28";
            case panelType.managerKeyListForm:
                return "HR29";
            case panelType.managerKeyForm:
                return "HR30";
            case panelType.shiftListForm:
                return "HR31";
            case panelType.shiftForm:
                return "HR32";
            case panelType.overtimeForm:
                return "HR33";
            case panelType.overtimeListForm:
                return "HR34";
            case panelType.salaryForm:
                return "HR35";
            case panelType.salaryListForm:
                return "HR36";

            case panelType.legacyFrame:
                return "LEGACY_FRAME";
            case panelType.panelMenu:
                return "PANEL_MENU";
            case panelType.dmExplorer:
                return "DM_EXPLORER";
            case panelType.configurationManagerPanel:
                return "CONFIGURATION_MANAGER_PANEL";
            case panelType.profileFormList:
                return "PROFILE_FORM_LIST";
            case panelType.eBAApplicationForm:
                return "EBA_APPLICATION_FORM";
            case panelType.dmDashboard:
                return "DM_DASHBOARD";
            case panelType.dmComparison:
                return "DM_COMPARISON";
            case panelType.menuDesignerPanel:
                return "MENU_DESIGNER_PANEL"
            case panelType.openSelectionForm:
                return "OPEN_SELECTION_FORM";
            case panelType.Security:
                return "SECURITY";
            case panelType.AllApps:
                return "ALL_APPS";
            case panelType.profileForm:
                return "PROFILE_FORM";
            case panelType.LogViewer:
                return "LOG_VIEWER"
            case panelType.wfManagement:
                return "WF_MANAGEMENT";
            case panelType.wfList:
                return "WF_List";
            case panelType.wfProcessList:
                return "WF_ProcessList";
            case panelType.dmViewer:
                return "DM_Viewer";
            case panelType.dmContentList:
                return "DM_ContentList";
            case panelType.dmRelationList:
                return "DM_RelationList";
            case panelType.HumanResource:
                return "HR_MAIN";
            case panelType.indexReport:
                return "Index_Report";
            case panelType.activities:
                return "Activities";
            case panelType.taskManagement:
                return "Task_Management";
            case panelType.dataSecurity:
                return "DS_MAIN";
            case panelType.dsDataTypes:
                return "DS_DataTypes";
            case panelType.ActionManagement:
                return "Action_Management";
            case panelType.ActionMenu:
                return "Action_Menu";
            case panelType.ActionViewer:
                return "Action_Viewer";
            default:
                return null;
        }
    }

    public static GetPanelTypeFromComponentKey(key: string): panelType {
        switch (key) {
            case "STARTUP":
                return panelType.startupLayout;
            case "DEPRECATED_STARTUP":
                return panelType.deprecatedStartupLayout;
            case "ANNOUNCEMENTS":
                return panelType.announcementList;
            case "ANNOUNCEMENT_FORM":
                return panelType.announcementForm;
            case "FLOW_VIEWER":
                return panelType.flowViewer;
            case "FLOW_SCHEMA":
                return panelType.flowSchema;
            case "FLOW_HISTORY":
                return panelType.flowHistory;
            case "HR0":
                return panelType.HrMenuForm;
            case "HR1":
                return panelType.userListForm;
            case "HR2":
                return panelType.userForm;
            case "HR3":
                return panelType.companyListForm;
            case "HR4":
                return panelType.companyForm;
            case "HR5":
                return panelType.positionListForm;
            case "HR6":
                return panelType.departmentListForm;
            case "HR7":
                return panelType.professionListForm;
            case "HR8":
                return panelType.positionForm;
            case "HR9":
                return panelType.departmentForm;
            case "HR10":
                return panelType.professionForm;
            case "HR11":
                return panelType.userMaintenanceListForm;
            case "HR12":
                return panelType.userMaintenanceForm;
            case "HR13":
                return panelType.departmentMaintenanceListForm;
            case "HR14":
                return panelType.departmentMaintenanceForm;
            case "HR15":
                return panelType.positionMaintenanceListForm;
            case "HR16":
                return panelType.positionMaintenanceForm;
            case "HR17":
                return panelType.professionMaintenanceListForm;
            case "HR18":
                return panelType.professionMaintenanceForm;
            case "HR19":
                return panelType.propertiesForm;
            case "HR20":
                return panelType.userPropertiesForm;
            case "HR21":
                return panelType.positionPropertiesForm;
            case "HR22":
                return panelType.professionPropertiesForm;
            case "HR23":
                return panelType.departmentPropertiesForm;
            case "HR24":
                return panelType.userGroupPropertiesForm;
            case "HR25":
                return panelType.propertyTypeForm;
            case "HR26":
                return panelType.userGroupForm;
            case "HR27":
                return panelType.userGroupListForm;
            case "HR28":
                return panelType.companyAdminListForm;
            case "HR29":
                return panelType.managerKeyListForm;
            case "HR30":
                return panelType.managerKeyForm;
            case "HR31":
                return panelType.shiftListForm;
            case "HR32":
                return panelType.shiftForm;
            case "HR33":
                return panelType.overtimeForm;
            case "HR34":
                return panelType.overtimeListForm;
            case "HR35":
                return panelType.salaryForm;
            case "HR36":
                return panelType.salaryListForm;

            case "LEGACY_FRAME":
                return panelType.legacyFrame;
            case "DM_EXPLORER":
                return panelType.dmExplorer;
            case "CONFIGURATION_MANAGER_PANEL":
                return panelType.configurationManagerPanel;
            case "PANEL_MENU":
                return panelType.panelMenu;
            case "PROFILE_FORM_LIST":
                return panelType.profileFormList;
            case "EBA_APPLICATION_FORM":
                return panelType.eBAApplicationForm;
            case "DM_DASHBOARD":
                return panelType.dmDashboard;
            case "DM_COMPARISON":
                return panelType.dmComparison;
            case "MENU_DESIGNER_PANEL":
                return panelType.menuDesignerPanel;
            case "SECURITY":
                return panelType.Security;
            case "OPEN_SELECTION_FORM":
                return panelType.openSelectionForm;
            case "ALL_APPS":
                return panelType.AllApps;
            case "PROFILE_FORM":
                return panelType.profileForm;
            case "LOG_VIEWER":
                return panelType.LogViewer;
            case "WF_MANAGEMENT":
                return panelType.wfManagement;
            case "WF_List":
                return panelType.wfList;
            case "WF_ProcessList":
                return panelType.wfProcessList;
            case "DM_Viewer":
                return panelType.dmViewer;
            case "DM_ContentList":
                return panelType.dmContentList;
            case "DM_RelationList":
                return panelType.dmRelationList;
            case "HR_MAIN":
                return panelType.HumanResource;
            case "Index_Report":
                return panelType.indexReport;
            case "Activities":
                return panelType.activities;
            case "Task_Management":
                return panelType.taskManagement;
            case "DS_MAIN":
                return panelType.dataSecurity;
            case "DS_DataTypes":
                return panelType.dsDataTypes;
            case "Action_Management":
                return panelType.ActionManagement;
            case "Action_Menu":
                return panelType.ActionMenu;
            case "Action_Viewer":
                return panelType.ActionViewer;
            default:
                return panelType.none;
        }
    }

    public static compareHashedObjects(obj1: any, obj2: any): boolean {
        return (obj1 === obj2)
    }

    public static hashObject(data: any): string {
        return Hash(data);
    }

    public static getUsersInitials(fullName: string) {
        if (isNullOrUndefined(fullName)) return "";
        let splitted = fullName.split(" ");
        if (splitted.length > 0) {
            if (splitted.length == 1) {
                return fullName.slice(0, 2);
            } else {
                return splitted[0].slice(0, 1) + splitted[splitted.length - 1].slice(0, 1);
            }
        }
        return fullName.slice(0, 2);
    }

    public static blobToFile(blob: Blob, fileName: string) {
        let file: any = blob;
        file.lastModified = new Date().getTime();
        file.name = fileName;
        return file as File;
    }

    public static getTaleInnerException(e: any): string {
        if (e && e["InnerException"])
            return this.getTaleInnerException(e["InnerException"])
        return e["Code"]
    }

    public static customErrorHandle(e: ErrorInfo, defaultMessage?: string, notificationType: "warning" | "error" = "error", duration = 4) {
        return function* () {
            var errorCode: string = Utils.getTaleInnerException((e.exception as any).data.exception)
            var message = ErrorCodes[errorCode] || defaultMessage || findMessage.get("100704");
            BCNotification[notificationType]({ message, duration })
        };
    }

    public static getWorkflowText(projectName: string, projectCaption: IDictionary<string>, flowName: string, flowCaption: IDictionary<string>, projectVersion?: number): string {
        let text = "";
        text += MLHelper.getMLText(projectCaption) || projectName;
        text += " - ";
        text += MLHelper.getMLText(flowCaption) || flowName
        if (projectVersion) {
            text += ` (v${projectVersion})`;
        }
        return text;
    }

    public static panelSizeToWidth(size: number = 1): string {
        return Math.min(Math.min(Math.max(size, 0), 3) * 33, 80) + "%";
    }
}