import { all, takeLatest, select, call, put, takeEvery } from "redux-saga/effects";
import * as ActionTypes from '../actions/actionTypes';
import { IAddViewerFilePayload, IViewerOperationPayload, IChangeUserSelectedViewerTypePayload } from '../entities/Payloads';
import { ViewerItem, ViewerType } from '../entities';
import ViewerUtils from './ViewerUtils';
import { BCAjax, ContentType, findMessage } from '@bimser/components';
import { addViewerFileAction, updateShowingViewerFileAction } from '../actions';
import moment from 'moment';
import { getMimeTypeFromExtension, IBaseAction } from '@bimser/core';
import { BaseState } from "../../../entities/BaseState";
import { ViewerConfigItem } from "../entities/IViewerConfigItem";
import { ServiceList } from "../../../services/serviceList";
import { DmData } from "../../../entities/IDmData";
import { DmViewerAPI } from "../../..";

function* DmViewerRootSaga() {
    yield all([
        takeEvery(ActionTypes.ADD_NEW_VIEWER_FILE_TRANSACTION, handleAddNewViewerFile),
        takeEvery(ActionTypes.ADD_NEW_VIEWER_FILE_AND_LOAD_TRANSACTION, handleAddNewViewerFileAndLoad),
        takeLatest(ActionTypes.ON_CHANGE_USER_SELECTED_VIEWER_TYPE_ACTION, handleChangeUserSelectedViewerType)
    ])
}

export default DmViewerRootSaga;

function* handleAddNewViewerFile(action: IBaseAction<any>) {
    yield call(requestViewerItem, action.payload);
}

function* handleChangeUserSelectedViewerType(action: IBaseAction<any>) {
    let { userSelectedViewerType, viewerId }: IChangeUserSelectedViewerTypePayload = action.payload;
    let item: ViewerItem = yield select((state: BaseState) => state.Viewers.items.get(viewerId, null));

    yield call(requestViewerItem, {
        secretKey: item.secretKey,
        versionSecretKey: item.versionSecretKey,
        userSelectedViewerType,
        viewerId,
        panelId: item.panelId,
        viewMode: item.mode,
        fileName: item.fileName
    })
}

function* handleAddNewViewerFileAndLoad(action: IBaseAction<any>) {
    let { panelId, viewerId }: IAddViewerFilePayload = action.payload;
    DmViewerAPI.get(panelId)
    const itemRequested = yield call(requestViewerItem, action.payload);
    if (itemRequested) {
        yield put(updateShowingViewerFileAction({
            panelId,
            viewerId
        }))
    }

}

function* requestViewerItem(payload: IAddViewerFilePayload) {
    try {
        let request: any = {
            secretKey: payload.secretKey,
            versionSecretKey: payload.versionSecretKey,
            addRecent: payload.addRecent
        }
        DmViewerAPI.get(payload.panelId).setLoading(true);
        if (!payload.data && payload.viewerType !== ViewerType.NotificationViewer) {
            let response: any = yield ServiceList.dm.DocumentManagement.Objects.GetDownloadUrl.callPromise(request, true);
            if (response) {
                let _viewerType = yield call(GetViewerTypeFromMimeType, response.mimeType, payload.fileName.split(".").pop());
                let skeletonType = [ViewerType.ImageViewer, ViewerType.VideoViewer].indexOf(_viewerType) > -1 ? ContentType.imageFile : ContentType.textFile;
                DmViewerAPI.get(payload.panelId).setSkeletonType(skeletonType)

                response.downloadUrl = (window as any).serviceConfig.services.dm.baseURL + "/" + response.downloadUrl;
                let data = response.downloadUrl ? response.downloadUrl : "Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir.<br/> Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır.<br/> Beşyüz yıl boyunca varlığını sürdürmekle kalmamış, aynı zamanda pek değişmeden elektronik dizgiye de sıçramıştır. 1960'larda Lorem Ipsum pasajları da içeren Letraset yapraklarının yayınlanması ile ve yakın zamanda Aldus PageMaker gibi Lorem Ipsum sürümleri içeren masaüstü yayıncılık yazılımları ile popüler olmuştur.";
                let language = "";
                let _payload = yield call(viewerOperations, {
                    data,
                    language,
                    fileName: payload.fileName,
                    downloadUrl: response.downloadUrl,
                    mimeType: response.mimeType,
                    secretKey: payload.secretKey,
                    viewerId: payload.viewerId,
                    viewerType: payload.userSelectedViewerType ?? _viewerType,
                    mode: payload.viewMode,
                    versionSecretKey: payload.versionSecretKey,
                });
                data = _payload.data;
                language = _payload.language;

                if (data == undefined) {
                    yield put(addViewerFileAction({
                        id: payload.viewerId,
                        panelId: payload.panelId,
                        data: findMessage.get("102581"),
                        viewerType: ViewerType.NotificationViewer,
                        isLoading: false,
                    }));
                } else {
                    yield put(addViewerFileAction({
                        id: payload.viewerId,
                        panelId: payload.panelId,
                        mode: payload.viewMode,
                        secretKey: payload.secretKey,
                        versionSecretKey: payload.versionSecretKey,
                        data: data,
                        viewerType: _viewerType,
                        userSelectedViewerType: payload.userSelectedViewerType,
                        fileName: payload.fileName,
                        mimeType: response.mimeType,
                        language: language,
                        isLoading: false,
                    }));
                }
            }
        } else {
            yield put(addViewerFileAction({
                id: payload.viewerId,
                panelId: payload.panelId,
                data: payload.data,
                viewerType: payload.viewerType,
                isLoading: false,
            }));
        }
        DmViewerAPI.get(payload.panelId).setLoading(false);
        return true;
    } catch (error) {
        yield put(addViewerFileAction({
            id: payload.viewerId,
            panelId: payload.panelId,
            data: JSON.stringify(error).toLocaleLowerCase().indexOf("permission") > -1 ? findMessage.get("102392") : findMessage.get("100704"),
            viewerType: ViewerType.NotificationViewer,
            isLoading: false,
        }));
        DmViewerAPI.get(payload.panelId).setLoading(false);
        return false;
    }
}

function* viewerOperations(payload: IViewerOperationPayload) {
    if (!payload.downloadUrl) return payload;

    switch (payload.viewerType) {
        case ViewerType.TextViewer: {
            payload = yield call(TextViewerOperations, payload);
            break;
        }
        case ViewerType.OfficeViewer: {
            payload = yield call(OfficeViewerOperations, payload);
            break;
        }
        case ViewerType.GroupDocsViewer: {
            payload = yield call(GroupDocsViewerOperations, payload);
            break;
        }
        case ViewerType.Unsupported: {
            payload = UnsupportedOperations(payload);
            break;
        }
        default: {
            return payload;
        }
    }
    return payload;
}

function UnsupportedOperations(payload: IViewerOperationPayload) {
    payload.data = findMessage.get('102696')
    return payload;
}

function* TextViewerOperations(payload: IViewerOperationPayload) {
    payload.data = yield call(documentDownloadData, payload.downloadUrl);
    payload.language = ViewerUtils.GetMonacoLanguageFromMimeType(payload.mimeType);
    if (payload.language == "json") {
        payload.data = payload.data != null ? JSON.stringify(payload.data) : "";
    }
    if (typeof payload.data == "object") {
        payload.language = "json";
        payload.data = payload.data != null ? JSON.stringify(payload.data) : "";
    }
    return payload;
}


function* OfficeViewerOperations(payload: IViewerOperationPayload) {
    let _actionType = payload.mode === "view" ? "embedview" : "edit";
    let officeData: any = yield ServiceList.dm.DocumentManagement.Objects.GetOfficeViewerUrl.call({ secretKey: payload.secretKey, versionSecretKey: payload.versionSecretKey, action: _actionType });
    if (officeData) {
        let token = sessionStorage.getItem("token") || localStorage.getItem("token") || BCAjax.defaultHeaders["Authorization"]?.replace("Bearer ", "");
        payload.data = {
            url: officeData.url,
            token: token,
            timeToLive: officeData.timeToLive ? officeData.timeToLive : 0,
            favIcon: officeData.favIcon
        };
    }
    return payload;
}

function* GroupDocsViewerOperations(payload: IViewerOperationPayload) {
    let html5ViewerUrl = ((window as any).serviceConfig.DomainAddress || (window as any).DomainAddress) + "/html5viewer";
    let dmData: DmData = yield select((state: any) => state.modules?.dm?.data?.get(payload.viewerId.split("_")[0]));
    let groupDocsData = yield BCAjax.post("/viewer-api/GetViewerLink", {
        baseAddress: html5ViewerUrl,
        fileName: dmData?.name || payload.fileName,
        downloadHandlerUrl: payload.downloadUrl,
        downloadButtonVisible: dmData?.permissions.get("download", false) || false,
        printButtonVisible: dmData?.permissions.get("print", false) || false
    }, {
        baseURL: html5ViewerUrl
    });
    if (groupDocsData?.data) {
        payload.data = {
            url: html5ViewerUrl + groupDocsData.data
        }
    }
    return payload;
}


function* GetViewerTypeFromMimeType(mimeType: string, extension: string) {
    let _mimeType = mimeType == "application/octet-stream" ? getMimeTypeFromExtension(extension) : mimeType;
    let viewerConfig: ViewerConfigItem = yield select((state: BaseState) => state.Viewers.viewersConfig.find(c => c.mimeTypes.contains(_mimeType)));
    if (viewerConfig) {
        return viewerConfig.viewerType;
    } else {
        return ViewerType.Unsupported;
    }
}

function documentDownloadData(filePath: string) {
    return BCAjax.get(filePath).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
    });
}
