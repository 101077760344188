import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBasePasswordRequirements {
    minimumLength?: number,
    minimumLowerCaseCharacterCount?: number,
    minimumNonAlphaNumericCharacterCount?: number,
    minimumNumericCharacterCount?: number,
    minimumUpperCaseCharacterCount?: number,
}

export interface IPasswordRequirements extends IBasePasswordRequirements { }

export interface IReadonlyPasswordRequirements extends IBasePasswordRequirements { }

export type PasswordRequirements = ReadonlyRecord<IReadonlyPasswordRequirements>;

export const PasswordRequirementsFactory = Record<IReadonlyPasswordRequirements>({
    minimumLength: null,
    minimumLowerCaseCharacterCount: null,
    minimumNonAlphaNumericCharacterCount: null,
    minimumNumericCharacterCount: null,
    minimumUpperCaseCharacterCount: null,
});

export const PasswordRequirementsFactoryFromJS = function (data: IPasswordRequirements) {
    return PasswordRequirementsFactory(data);
}