import { Map } from "immutable";
import { NormalizedLayoutStructureItems } from "../../../common/entities";

export const removeItemFromView = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    id: string
) => {
    const item = views.getIn([activeView, "entities", "items", id]);
    const parent = views.getIn([activeView, "entities", "items", item.parentId]);

    const index = parent.items.findIndex((i) => i === id);
    const newItems = parent.items.remove(index);

    views = views.setIn([activeView, "entities", "items", item.parentId, "items"], newItems)

    return views.removeIn([activeView, "entities", "items", id]);
}