export enum MessageBoxButton {
    OK = "OK",
    Cancel = "Cancel",
    Abort = "Abort",
    Retry = "Retry",
    Ignore = "Ignore",
    Yes = "Yes",
    No = "No",
    CloseButton = "CloseButton",
    Clear ="Clear"
}