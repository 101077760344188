import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import IActionFormData from "./IActionFormData";
import { ActionHistory, ActionHistoryFactoryFromJS, IActionHistory } from "./IActionHistory";

interface IBaseActionViewerState {
    panelId?: string,
    isLoading?: boolean
    activeItemKey?: string,
    activePanelId?: string,
    actionFormData?: IActionFormData,
}

export interface IActionViewerState extends IBaseActionViewerState {
    historyItems?: IActionHistory[],
}

export interface IReadonlyActionViewerState extends IBaseActionViewerState {
    historyItems?: List<ActionHistory>,
}

export type ActionViewerState = ReadonlyRecord<IReadonlyActionViewerState>;

export const ActionViewerStateFactory = Record<IReadonlyActionViewerState>({
    panelId: null,
    isLoading: false,
    activeItemKey: null,
    activePanelId: null,
    historyItems: List<ActionHistory>(),
    actionFormData: null,
});

export const ActionViewerStateFactoryFromJS = function (data: IActionViewerState) {
    return ActionViewerStateFactory({
        ...data,
        historyItems: List(data.historyItems?.map(item => ActionHistoryFactoryFromJS(item)) || []),
    });
}