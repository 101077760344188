import { IDictionary, MLHelper } from '@bimser/core';
import { IFindMessagePub } from '../entities';
import { getMessages } from '../helper/getMessages';

const findMessage = (function () {
    const messages: IDictionary<IDictionary<string>> = getMessages();
    const pub: IFindMessagePub = {};

    pub.get = (id, culture) => {
        const locale = localStorage.locale?.replace(/-/g, "_") || localStorage.defaultLanguage;
        const defaultCulture = localStorage.defaultLanguage?.replace(/-/g, "_") || "en_US";

        if (!culture) culture = locale;
        if (culture) culture = culture.replace(/-/g, "_");
        if (!id) return "0";

        if (messages?.[culture] && Object.keys(messages[culture]).length) {
            //Requested culture's message
            const word = messages[culture][id];
            if (!word) {
                const defaultCulturesWord = messages[defaultCulture][id];
                if (!defaultCulturesWord) {
                    const firstAvailableCulture = Object.keys(messages)?.[0];
                    if (firstAvailableCulture) {
                        const firstAvailableWord = messages[firstAvailableCulture][id];
                        if (!firstAvailableWord) return id;
                        return firstAvailableWord
                    } else {
                        return id
                    }
                }
                return defaultCulturesWord
            }
            return word
        } else if (messages?.[defaultCulture] && Object.keys(messages[defaultCulture]).length) {
            const word = messages[defaultCulture][id];
            if (word) return word;
        }

        if (messages?.[Object.keys(messages)?.[0]])
            return messages[Object.keys(messages)[0]][id];
        return id;
    }

    /**ML kodu bilinen bir ml yi obje olarak elde oluşturmak için */
    pub.getAsMultiLanguageObject = (id: string, replacer?: (mlText: string) => string) => {
        let result: IDictionary<string> = {};
        MLHelper.supportedLanguages?.forEach((i) => {
            let mlText = findMessage.get(id, i.culture)
            result[i.culture] = replacer ? replacer(mlText) : mlText;
        });
        return result;
    }

    return pub;
}());

export default findMessage;