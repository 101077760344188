import { IConditionGroup, IRule } from "../entities";
import { ITreeData } from '../../BCTreeSelect/entities';

export function collapseConditionGroups(conditionGroup: IConditionGroup): IConditionGroup {

    if (!conditionGroup) return conditionGroup;

    if (conditionGroup.children && conditionGroup.children.length) {
        conditionGroup.children = conditionGroup.children.map(cg => {
            return collapseConditionGroups(cg);
        });
    }

    conditionGroup.collapsed = true;
    return conditionGroup

}

export function collapseStatements(rule: IRule) {
    rule.statements = rule.statements.map(rs => {
        rs.conditionGroup = collapseConditionGroups(rs.conditionGroup);
        rs.collapsed = true;
        return rs
    });

    return rule
}

export function sortFieldsByType(fields: ITreeData[]) {  // controlType -> alphabetic
    let existTypes: string[] = [];

    fields.forEach(i => {
        if (!existTypes.includes(i.externalData.type)) existTypes.push(i.externalData.type);
    });

    let sortedFields: ITreeData[] = [];
    existTypes.sort();

    existTypes.forEach(type => {
        let fieldsByType: ITreeData[] = [];
        fields.forEach(field => {
            if (field.externalData.type === type) fieldsByType.push(field);
        });
        fieldsByType.sort((a, b) => a.key.localeCompare(b.key));
        sortedFields = [...sortedFields, ...fieldsByType];
    });

    return sortedFields;
}