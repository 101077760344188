import { IItem } from "@bimser/core";
import IconReset from "@bimser/icons/16/reset";
import GetIcon16ByName from "@bimser/icons/lib/getIcon16ByName";
import * as React from "react";
import { BCCheckbox, BCMenu, BCPopover } from '../..';
import { findMessage } from '../../BCIntl';
import BCTooltip from '../../BCTooltip';
import * as Styles from "../assets/style.scss";
import { IPropertyItem, IPropertyItemProps, IPropertyTypes } from '../entities';
import { AllDayProperty, AspectProperty, AutoCompleteProperty, BorderProperty, ButtonProperty, CheckboxGroupProperty, CheckboxProperty, CollectionProperty, ColorPickerProperty, DatePickerProperty, DateRangePickerProperty, DayOfWeekProperty, EventTriggerProperty, FontProperty, ImageUploadProperty, InputNumberAddOnProperty, InputNumberProperty, InputProperty, LookupProperty, MarginProperty, MLInputProperty, MultipleSelectProperty, PasswordProperty, RadioProperty, SelectProperty, SliderProperty, SwitchProperty, TextAreaProperty, TimePickerProperty, TimeRangePickerProperty, TimeZoneProperty, TreeSelectProperty, UploadProperty } from './propertyItems';
import IconEventInfo from "@bimser/icons/16/event-info";
const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const PropertyItem = React.memo((props: IPropertyItemProps) => {

    const [popoverVisible, setPopoverVisible] = React.useState(false);
    const [openKeys, setOpenKeys] = React.useState([]);

    const getItemByType = (): JSX.Element => {

        const { type } = props.propertyItem;
        const { value, propertyItem, currentLanguage, supportedLanguages, getOptionsPromise, onRefresh, fireAction } = props;
        const key = `${props.guid}.${propertyItem.key}`;

        switch (type) {
            case IPropertyTypes.Input: {
                return (
                    <InputProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.InputNumber: {
                return (
                    <InputNumberProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                        currentLanguage={currentLanguage}
                        supportedLanguages={supportedLanguages}
                    />
                )
            }
            case IPropertyTypes.InputNumberAddOn: {
                return (
                    <InputNumberAddOnProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.CheckboxGroup: {
                return (
                    <CheckboxGroupProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Checkbox: {
                return (
                    <CheckboxProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Radio: {
                return (
                    <RadioProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Select: {
                return (
                    <SelectProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                        getOptionsPromise={getOptionsPromise}
                        onRefresh={onRefresh}
                    />
                )
            }
            case IPropertyTypes.Switch: {
                return (
                    <SwitchProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Slider: {
                return (
                    <SliderProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.ColorPicker: {
                return (
                    <ColorPickerProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Button: {
                return (
                    <ButtonProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.DatePicker: {
                return (
                    <DatePickerProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Margin: {
                return (
                    <MarginProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Border: {
                return (
                    <BorderProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Font: {
                return (
                    <FontProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Aspect: {
                return (
                    <AspectProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Lookup: {
                return (
                    <LookupProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Collection: {
                return (
                    <CollectionProperty
                        key={key}
                        item={propertyItem}
                        fireAction={fireAction}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.TextArea: {
                return (
                    <TextAreaProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.EventTrigger: {
                return (
                    <EventTriggerProperty
                        key={key}
                        item={propertyItem}
                        fireAction={fireAction}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.AutoComplete: {
                return (
                    <AutoCompleteProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.MultipleSelect: {
                return (
                    <MultipleSelectProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                        getOptionsPromise={getOptionsPromise}
                        onRefresh={onRefresh}
                    />
                )
            }
            case IPropertyTypes.Password: {
                return (
                    <PasswordProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}

                    />
                )
            }
            case IPropertyTypes.TimePicker: {
                return (
                    <TimePickerProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.Upload: {
                return (
                    <UploadProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                        externalData={propertyItem.externalData}
                    />
                )
            }
            case IPropertyTypes.MLInput: {
                return (
                    <MLInputProperty
                        key={key}
                        currentLanguage={currentLanguage}
                        supportedLanguages={supportedLanguages}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.TreeSelectProperty: {
                return (
                    <TreeSelectProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                        getOptionsPromise={getOptionsPromise}
                        onRefresh={onRefresh}
                    />
                )
            }
            case IPropertyTypes.DateRangePicker: {
                return (
                    <DateRangePickerProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.TimeZone: {
                return (
                    <TimeZoneProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.TimeRangePickerProperty: {
                return (
                    <TimeRangePickerProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.DayOfWeek: {
                return (
                    <DayOfWeekProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.AllDays: {
                return (
                    <AllDayProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
            case IPropertyTypes.ImageUpload: {
                return (
                    <ImageUploadProperty
                        key={key}
                        item={propertyItem}
                        onValueChanged={onValueChange}
                        value={value}
                    />
                )
            }
        }

    }

    const onValueChange = (item: IPropertyItem, value: any) => {
        props.onValueChanged(item, value);
    }

    const getItemInfoTooltip = () => {
        return (
            <p className={Styles.propertyTooltip}>
                <b>{props.propertyItem.label}</b>
                <i>
                    {` (${props.propertyItem.key}):`}
                </i>
                {props.propertyItem.info}
            </p>
        )
    }

    const getItemCustomIconTooltip = () => {
        return (
            <p className={Styles.propertyTooltip}>
                <i>
                    {findMessage.get("103013")}
                </i>
            </p>
        )
    }

    const renderLabelBadge = () => {
        if (props.showCustomBadgeOnLabel && !props.propertyItem.externalData?.disableViewBasedCustomization) {
            return (
                <BCTooltip
                    placement={'right'}
                    renderCustomTitle={getItemCustomIconTooltip}
                    arrowPointAtCenter={true}
                    overlayClassName={'propertyInpectorTooltip'}
                    mouseEnterDelay={1}
                >
                    <label>
                        <IconEventInfo />
                    </label>
                </BCTooltip>
            )
        }

        return null
    }

    const getItemLabel = () => {
        return (
            <>
                <BCTooltip
                    placement={'right'}
                    renderCustomTitle={getItemInfoTooltip}
                    arrowPointAtCenter={true}
                    overlayClassName={'propertyInpectorTooltip'}
                    mouseEnterDelay={1}
                >
                    <label>
                        {props.propertyItem.label}
                    </label>
                </BCTooltip>
                {renderLabelBadge()}
            </>
        )
    }

    const popoverItemClick = (data: IItem, openCollectionName: string) => {
        switch (data.key) {
            case 'resetBtn': {
                props.onValueChanged(props.propertyItem, props.propertyItem.reset.value);
                props.propertyItem.reset?.onReset?.(props.value);
                break;
            }
            default: {

                if (props.fireAction) {
                    if (openCollectionName) {
                        props.fireAction(props.propertyItem, "PROPERTY_INSPECTOR.ON_COLLECTION_OPEN", openCollectionName);
                    } else {
                        props.fireAction(props.propertyItem, "PROPERTY_INSPECTOR.ON_POPOVER_ITEM_CLICK", data);
                    }
                }

            }
        }

        hidePopover();
    }

    const popoverCheckboxChange = (data: IItem) => {
        if (props.fireAction) props.fireAction(props.propertyItem, "PROPERTY_INSPECTOR.ON_POPOVER_ITEM_CLICK", data);
        if (props.reload) props.reload();
    }

    const booleanCustomTemplate = (item: IItem) => {
        return (
            <BCCheckbox
                checked={item.externalData?.value}
                label={item.text}
                onChange={() => popoverCheckboxChange(item)}
            />
        )
    }

    const setItemTemplate = (items: IItem[]) => {
        return items.map(i => {
            if (i.externalData?.type === "boolean") {
                return {
                    ...i,
                    customTemplate: booleanCustomTemplate
                }
            }
            return i
        })
    }

    const onOpenKeysChange = (openKeys: string[]) => {
        setOpenKeys(openKeys);
    }

    const onClickPopoverMenuItem = (args: any) => {
        const openCollectionName = args.data?.externalData?.openCollectionName;
        popoverItemClick(args.data, openCollectionName);
    }

    const renderPopoverItem = (items: IItem[]) => {
        return (
            <BCMenu
                isLoading={false}
                triggerMode={"hover"}
                mode="vertical"
                items={setItemTemplate(items)}
                openKeys={openKeys}
                className={Styles.menu}
                onOpenChange={onOpenKeysChange}
                onClick={onClickPopoverMenuItem}
            />
        )
    }

    const sortPopoverItems = (items: Array<IItem>): Array<IItem> => {
        if (items?.length) {
            return items.sort((a, b) => {
                var textA = a.text.toUpperCase();
                var textB = b.text.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }).map(i => {
                if (i.items?.length) {
                    i.items = sortPopoverItems(i.items);
                }

                return i
            });
        }

        return []
    }

    const getPopoverItems = (): Array<IItem> => {

        let customItems = props.propertyItem.popoverItems || [];
        const showResetButton = props.propertyItem.reset;

        if (customItems?.length) {
            customItems = sortPopoverItems(customItems);
        }

        let commonPopoverItems: Array<IItem> = [];
        if (props.commonPopoverItems?.count()) {
            commonPopoverItems = props.commonPopoverItems.toJS().filter(i => {
                if (i.externalData) {
                    return i.externalData.isKeyValid(props.propertyItem.externalData)
                }
                return true
            });
        }

        let resetItems: Array<IItem> = [
            {
                key: 'resetBtn',
                text: findMessage.get('100888'),
                icon: IconReset.info
            }
        ];

        if (customItems?.length) {
            resetItems = [
                {
                    key: 'resetDivider',
                    isDivider: true
                },
                ...resetItems
            ];
        }

        return [
            ...customItems,
            ...(showResetButton ? resetItems : []),
            ...commonPopoverItems
        ]

    }

    const handleVisibleChange = (visible: boolean) => {
        setPopoverVisible(visible);
    }

    const hidePopover = () => {
        setPopoverVisible(false);
    }

    const getPopover = (items: Array<IItem>) => {
        if (items && items.length) {
            const mainIconItem = items.find(i => i.externalData?.isMainIcon);
            const Icon = mainIconItem && mainIconItem.icon && GetIcon16ByName(mainIconItem.icon.name);

            return (
                <div className={Styles.itemDropdown}>
                    <BCPopover
                        content={renderPopoverItem(items)}
                        title={props.propertyItem.label}
                        overlayClassName={Styles.dropdownOverlay}
                        placement={'bottomLeft'}
                        trigger={'click'}
                        onVisibleChange={handleVisibleChange}
                        visible={popoverVisible}
                    >
                        {Icon ? <Icon /> : <div className={Styles.dropdownTrigger} />}
                    </BCPopover>
                </div>
            )
        }
    }

    const popoverItems = getPopoverItems();
    const { itemCssClass, key, labelCssClass, inputCssClass } = props.propertyItem;

    const classNames = cx({
        listItem: true,
        ['bc-property-inspector-item']: true,
        itemCssClass: itemCssClass ? true : false
    });

    const labelClassNames = cx({
        itemLabel: true,
        [labelCssClass]: true
    });

    const itemControlClassNames = cx({
        itemControl: true,
        [inputCssClass]: true,
        hasPopover: popoverItems.length ? true : false
    });

    return (
        <div className={classNames} key={key}>
            <div className={labelClassNames}>
                {getItemLabel()}
            </div>
            <div className={itemControlClassNames}>
                {getItemByType()}
            </div>
            {getPopover(popoverItems)}
        </div>
    )

})

export default PropertyItem