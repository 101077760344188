import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from 'immutable';
import { IPanelMenuPanelContent, PanelMenuPanelContent, PanelMenuPanelContentFactoryFromJS } from "./IPanelMenuPanelContent";


export interface IBasePanelMenusState { }

export interface IPanelMenusState {
    panelMenus?: IDictionary<IPanelMenuPanelContent>
}

export interface IReadonlyPanelMenusState {
    panelMenus?: Map<string, PanelMenuPanelContent>
}

export type PanelMenusState = ReadonlyRecord<IReadonlyPanelMenusState>;

export const PanelMenusStateFactory = Record<IReadonlyPanelMenusState>({
    panelMenus: Map<string, PanelMenuPanelContent>()
})

export const PanelMenusStateFactoryFromJS = function (data: IPanelMenusState) {
    let _panelMenus: IDictionary<PanelMenuPanelContent> = Object.keys(data.panelMenus).reduce(function (previous: IDictionary<PanelMenuPanelContent>, current) {
        previous[current] = PanelMenuPanelContentFactoryFromJS(data.panelMenus[current]);
        return previous;
    }, {});

    return PanelMenusStateFactory({
        panelMenus: Map(_panelMenus)
    });
}