import { ConditionalStatementEntities } from '@bimser/components/dist/BCConditionalStatement';
import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { Map, Record } from "immutable";

export interface IFormRuleTriggerPayloadBase {
    windowId: string,
    validate?: boolean,
}

export interface IFormRuleTriggerPayload extends IFormRuleTriggerPayloadBase {
    rules: IDictionary<ConditionalStatementEntities.IRule>
}

export interface IReadonlyBaseFormRuleTriggerPayload extends IFormRuleTriggerPayloadBase {
    rules: Map<string, ConditionalStatementEntities.Rule>
}

export type FormRuleTriggerPayload = ReadonlyRecord<IReadonlyBaseFormRuleTriggerPayload>;

export const FormRuleTriggerPayloadFactory = Record<IReadonlyBaseFormRuleTriggerPayload>({
    windowId: null,
    validate: true,
    rules: Map()
});

export const FormRuleTriggerPayloadFactoryFromJS = function (data: IFormRuleTriggerPayload) {

    let rules: IDictionary<ConditionalStatementEntities.Rule> = Object.keys(data.rules).reduce(function (previous: IDictionary<ConditionalStatementEntities.Rule>, current) {
        previous[current] = ConditionalStatementEntities.RuleFactoryFromJS(data.rules[current]);
        return previous;
    }, {});

    return FormRuleTriggerPayloadFactory({
        windowId: data.windowId,
        rules: Map(rules),
        validate: data.validate
    });
}