export const enabledPlugins = [
    "align",
    "charCounter",
    "codeBeautifier",
    "codeView",
    "colors",
    "draggable",
    "embedly",
    "emoticons",
    //  "entities",
    "file",
    "fontAwesome",
    "fontFamily",
    "fontSize",
    "fullscreen",
    "image",
    "imageTUI",
    "imageManager",
    "inlineStyle",
    "inlineClass",
    "lineBreaker",
    "lineHeight",
    "link",
    "lists",
    "paragraphFormat",
    "paragraphStyle",
    "quickInsert",
    "quote",
    "save",
    "table",
    "url",
    "video",
    "wordPaste"
]