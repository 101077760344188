import { getPopupContainer, IItem, isPromise } from '@bimser/core';
import { GetIconComponent } from "@bimser/icons";
import IconEmpty from "@bimser/icons/16/empty-icon";
import IconMenupopArrow from "@bimser/icons/16/menupop-arrow";
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import classNames from 'classnames/bind';
import * as React from "react";
import { findMessage } from '../BCIntl';
import BCMenu from '../BCMenu';
import * as Style from './assets/style.scss';
import { IDropdownProps, IDropdownSelectedChangedEventArgs } from './entities';
import IDropdownOptionArgs from './entities/IDropdownOptionArgs';

const cx = classNames.bind(Style)
const dropDownButtonStyle: React.CSSProperties = { marginLeft: 3, marginTop: 1 };

const BCDropdown = (props: IDropdownProps) => {

    Style;

    const currentItem = React.useRef<IItem>({
        key: 'dropdownChoose',
        text: props.placeholder || findMessage.get('100637'),
        value: props.defaultValue || null,
    });

    const [options, setOptions] = React.useState<Array<IItem>>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.items?.length) {
            const item = props.items.find((i) => i.value === props.defaultValue);
            if (item) {
                currentItem.current = item;
            } else {
                currentItem.current = {
                    ...currentItem.current,
                    text: props.placeholder || findMessage.get('100637'),
                    value: null
                }
            }
            setOptions(props.items);
        }
        setLoading(props.loading);
    }, [props.defaultValue, props.items, props.loading, props.placeholder]);

    React.useEffect(() => {
        if (currentItem.current.key === "dropdownChoose") {
            currentItem.current = {
                ...currentItem.current,
                text: props.placeholder || findMessage.get('100637'),
                value: null
            }
        }
    }, [props.placeholder]);

    const selectedChanged = (args: any) => {
        let arg: IDropdownSelectedChangedEventArgs = { domEvent: args.domEvent, data: args.data };
        currentItem.current = args.data;
        props.onSelected?.(arg);
    }

    const getOptions = (options: Array<IItem>) => {

        let _options: Array<IItem> = [];

        if (!props.isHiddenDefaultItem) {
            if (!props.disabledChoose) {
                _options.push({
                    key: 'dropdownChoose',
                    text: props.placeholder || findMessage.get('100637'),
                    value: null,
                    icon: (props.items && props.items.length && props.items.find(i => i.icon ? true : false)) ? IconEmpty.info : undefined
                });
            }
        }

        if (options && options.length > 0) {
            _options.push(...options);
        } else {
            if (props.items && props.items.length > 0) {
                _options.push(...props.items);
            }
        }

        return (
            <BCMenu
                items={_options}
                mode="vertical"
                className={props.optionClassName}
                style={props.optionStyle}
                onClick={selectedChanged}
            >
            </BCMenu>
        );
    }

    const onLoadOption = () => {
        if (props.onLoadOption) {
            let response = props.onLoadOption();
            if (isPromise(response)) {
                let promise: Promise<IDropdownOptionArgs> = response as Promise<IDropdownOptionArgs>;
                if (promise && promise.then) {
                    promise.then((args) => {
                        if (args && args.options && args.options.length > 0 && args.allow !== false) {
                            setOptions(args.options);
                        } else {
                            setOptions(props.items);
                        }
                    });
                } else {
                    setOptions([]);
                }
            } else {
                setOptions(props.items);
            }
        } else {
            setOptions(props.items);
        }
    }

    const renderSelectedItemInner = () => {
        switch (props.selectedItemShowType) {
            case 'icon': {
                if (currentItem.current?.value)
                    return GetIconComponent(currentItem.current.icon || props.defaultIcon);
                else
                    return <span title={currentItem.current.text}>{currentItem.current.text}</span>;
            }
            case 'icontext': {
                return (
                    <span className={Style.iconWithText}>
                        {GetIconComponent(currentItem.current.icon || props.defaultIcon)}
                        <span title={currentItem.current.text}>{currentItem.current.text}</span>
                    </span>
                )
            }
            default: {
                return <span title={currentItem.current.text}>{currentItem.current.text}</span>;
            }
        }
    }

    const renderSelectedItem = () => {
        return (
            <Button
                tabIndex={props.tabIndex}
                key={currentItem.current.key}
                onClick={onLoadOption}
                style={{ textAlign: 'left', ...props.style }}
                loading={isLoading ? { delay: 100 } : false}
                type={props.buttonType}
                size={props.size}
                disabled={props.disabled || props.readonly}
                className={cx({ readonlyCursor: props.readonly })}
            >
                {renderSelectedItemInner()}
                <IconMenupopArrow style={dropDownButtonStyle} height="30px" width="8px" className={"tableheader-sort-arrow"} />
            </Button>
        )
    }

    return (
        <Dropdown
            overlay={getOptions(options)}
            disabled={props.disabled || props.readonly}
            className={props.className}
            trigger={['click']}
            getPopupContainer={getPopupContainer}
            overlayClassName={[Style.dropdownStyle, props.overlayClassName].join(' ')}
        >
            {renderSelectedItem()}
        </Dropdown>
    )
}

export default BCDropdown
export { IDropdownProps, IDropdownSelectedChangedEventArgs, IDropdownOptionArgs };