import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { FlowHistory, FlowHistoryFactoryFromJS, IFlowHistory } from "../components/flowViewer/components/flowHistory/entities/IFlowHistory";
import { IProcessItem, ProcessItem, ProcessItemFactoryFromJS } from "./IProcessItem";
import { IDeployAgent, DeployAgent, DeployAgentFactoryFromJS } from './IDeployAgent';
import { IWorkflowItem, WorkflowItem, WorkflowItemFactoryFromJS } from '.';

export interface IWorkflowContent {
    approvals?: Array<IProcessItem>,
    flowHistory?: IDictionary<IFlowHistory>,
    wfList?: Array<IWorkflowItem>,
    deployAgents?: Array<IDeployAgent>
}

export interface IReadonlyWorkflowContent {
    approvals?: List<ProcessItem>,
    flowHistory?: Map<string, FlowHistory>,
    wfList?: List<WorkflowItem>,
    deployAgents?: List<DeployAgent>
}

export type WorkflowContent = ReadonlyRecord<IReadonlyWorkflowContent>;

export const WorkflowContentFactory = Record<IReadonlyWorkflowContent>({
    approvals: List<ProcessItem>(),
    flowHistory: Map(),
    wfList: List<WorkflowItem>(),
    deployAgents: List()
});

export const WorkflowContentFactoryFromJS = function (data: IWorkflowContent) {

    let _approvals: Array<ProcessItem> = data?.approvals?.map((approval) => {
        return ProcessItemFactoryFromJS(approval);
    }) || [];

    let _flowHistory: IDictionary<FlowHistory> = Object.keys(data.flowHistory).reduce(function (previous: IDictionary<FlowHistory>, current) {
        previous[current] = FlowHistoryFactoryFromJS(data.flowHistory[current])
        return previous;
    }, {});

    let _wfList: Array<WorkflowItem> = data?.wfList?.map((i) => {
        return WorkflowItemFactoryFromJS(i);
    }) || [];

    let _deployAgents = data?.deployAgents ? data.deployAgents.map(d => DeployAgentFactoryFromJS(d)) : [];

    return WorkflowContentFactory({
        approvals: List(_approvals),
        flowHistory: Map(_flowHistory),
        wfList: List(_wfList),
        deployAgents: List(_deployAgents)
    });
}