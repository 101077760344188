import { ExtremeGridEntities, DMExplorer, IReadonlyBaseState, LoginStateFactory, ModalManagerStateFactory, SystemStateFactory, UITourStateFactory, UploadManagerEntities, DmViewerEntities } from "@bimser/common";
import { Map, Record } from "immutable";
import { RouterState } from 'react-router-redux';
import { LayoutsState, LayoutsStateFactory } from '../../../app-main/entities';
import { ESignatureState, ESignatureStateFactory } from '../../components/ESignature/entities/IESignatureState';
import { ModulesState, ModulesStateFactory } from "../../entities/IModulesState";

export interface IWebInterfaceBaseState extends IReadonlyBaseState {
    layouts: LayoutsState,
    modules: ModulesState,
    routing: RouterState,
    Viewers: DmViewerEntities.ViewerState,
    ESignature: ESignatureState
}

export type WebInterfaceBaseState = Record<IWebInterfaceBaseState> & Readonly<IWebInterfaceBaseState>;

export const WebInterfaceBaseStateFactory = Record<IWebInterfaceBaseState>({
    modules: ModulesStateFactory(),
    layouts: LayoutsStateFactory(),
    system: SystemStateFactory(),
    user: LoginStateFactory(),
    modals: ModalManagerStateFactory(),
    i18n: Map(),
    DMExplorer: DMExplorer.Entities.DMExplorerStateFactory(),
    routing: undefined,
    Viewers: DmViewerEntities.ViewerStateFactory(),
    forms: Map(),
    ExtremeGrid: ExtremeGridEntities.ExtremeGridsStateFactory(),
    UploadManager: UploadManagerEntities.UploadManagerStateFactory(),
    UITour: UITourStateFactory(),
    ESignature: ESignatureStateFactory(),
    environment: "WebInterface"
});