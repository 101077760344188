import { createSelector, OutputSelector } from "reselect";
import { BaseState, IReadonlyBaseState } from "../../../entities/BaseState";
import {Record} from 'immutable'

const LocationParameters = (state: BaseState) => {
    if(state.user.data){
        return state.user.data.location;
    }
    return "";
}

const getSelectedLocationName = createSelector(
    [
        LocationParameters
    ],
    (data: string) => {
        return data;
    }
);

export { getSelectedLocationName };

