import * as React from "react";
import { BCList } from "../..";
import { debounce } from "@bimser/core";
import * as Styles from '../assets/flexStartupStyles.scss';
import { IButtonList, IButtonListItem } from '../entities';
import { GetIconComponent } from "@bimser/icons";

type IButtonListProps = IButtonList & { onClick: (item: IButtonListItem) => void };

export default class ButtonList extends React.Component<IButtonListProps, {}> {

    constructor(props: IButtonListProps) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.onClickListItem = debounce(this.onClickListItem, 400, true).bind(this);
    }

    onClickListItem(item: IButtonListItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.props.onClick) this.props.onClick(item);
        e.stopPropagation();
    }

    renderItem(itemProps: IButtonListItem): React.ReactNode {
        const Icon = GetIconComponent(itemProps.icon);

        return (
            <div onClick={(e) => { this.onClickListItem(itemProps, e) }} className={Styles.listItem}>
                <div className={Styles.listItemIcon}>
                    {Icon}
                </div>
                <div className={Styles.listItemContent}>
                    <h3>
                        {itemProps.title}
                    </h3>
                </div>
            </div>
        )

    }

    render() {
        return (
            <BCList
                dataSource={this.props.items}
                bordered={false}
                split={false}
                renderItem={this.renderItem}
                className={Styles.buttonList}
            />
        )
    }

}
