import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";
import { FormSaveAsPayload, FormSaveAsPayloadFactory, FormSaveAsPayloadFactoryFromJS, IFormSaveAsPayload } from './IFormSaveAsPayload';

export interface IBaseFormSaveAsAction {
    windowId: string
}

export interface IFormSaveAsAction extends IBaseFormSaveAsAction {
    payload: IFormSaveAsPayload
}

export interface IReadonlyFormSaveAsAction extends IBaseFormSaveAsAction {
    payload: FormSaveAsPayload
}

export type FormSaveAsAction = ReadonlyRecord<IReadonlyFormSaveAsAction>;

export const FormSaveAsActionFactory = Record<IReadonlyFormSaveAsAction>({
    windowId: null,
    payload: FormSaveAsPayloadFactory()
});

export const FormSaveAsActionFactoryFromJS = function (data: IFormSaveAsAction) {
    return FormSaveAsActionFactory({
        windowId: data.windowId,
        payload: FormSaveAsPayloadFactoryFromJS(data.payload)
    });
}