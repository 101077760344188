import { connect } from "react-redux";
import { BaseState } from '../../..';
import { onTourNextTransaction, onTourPrevTransaction, stopTourTransaction } from '../actions';
import UITour from '../components';
import UITourProps from '../entities/UITourProps';
import { getActiveUITour } from '../selectors';

const mapStateToProps = function (state: BaseState, props: UITourProps): UITourProps {
    return {
        tour: getActiveUITour(state)
    };
}
const mapDispatchToProps = function (dispatch: Function): UITourProps {
    return {
        onNext: function(){
            dispatch(onTourNextTransaction());
        },
        onPrev: function(){
            dispatch(onTourPrevTransaction());
        },
        onRequestClose: function(){
            dispatch(stopTourTransaction());
        }
    };
}

const UITourContainer = connect(mapStateToProps, mapDispatchToProps)(UITour);
export default UITourContainer;