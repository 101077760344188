import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseShowSummaryMessageClosePayload {
    windowId: string,
    messageKey: string
}

export interface IShowSummaryMessageClosePayload extends IBaseShowSummaryMessageClosePayload {
}

export interface IReadonlyBaseShowSummaryMessageClosePayload extends IBaseShowSummaryMessageClosePayload {
}

export type ShowSummaryMessageClosePayload = ReadonlyRecord<IReadonlyBaseShowSummaryMessageClosePayload>;

export const ShowSummaryMessageClosePayloadFactory = Record<IReadonlyBaseShowSummaryMessageClosePayload>({
    messageKey: null,
    windowId: null
});

export const ShowSummaryMessageClosePayloadFactoryFromJS = function (data: IShowSummaryMessageClosePayload) {
    return ShowSummaryMessageClosePayloadFactory(data);
}