import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ILayoutItem, LayoutItem, LayoutItemFactory, LayoutItemFactoryFromJS } from '../../../common/entities';

export interface IBaseLayoutCreatorLockAction {
    
}

export interface ILayoutCreatorLockAction extends IBaseLayoutCreatorLockAction {
    item: ILayoutItem
}

export interface IReadonlyLayoutCreatorLockAction extends IBaseLayoutCreatorLockAction {
    item: LayoutItem
}

export type LayoutCreatorLockAction = ReadonlyRecord<IReadonlyLayoutCreatorLockAction>;

export const LayoutCreatorLockActionFactory = Record<IReadonlyLayoutCreatorLockAction>({
    item: LayoutItemFactory()
});

export const LayoutCreatorLockActionFactoryFromJS = function (data: ILayoutCreatorLockAction) {
    return LayoutCreatorLockActionFactory({
        item: LayoutItemFactoryFromJS(data.item)
    });
}