import React, { lazy, Suspense } from 'react';
import { BCEditorRef, Tribute } from './Editor';
import EditorToolbarType from './entities/EditorToolbarType';
import { IEditorProps } from './entities/IEditorProps';

const EditorLazy = lazy(() => import(/* webpackChunkName: "text-editor" */ "./Editor"));

const BCEditor = React.forwardRef((props: IEditorProps, ref: React.ForwardedRef<BCEditorRef>) => {
    return (
        <Suspense fallback={<div></div>}>
            <EditorLazy {...props} ref={ref} />
        </Suspense>
    );
})

export default BCEditor;

export { IEditorProps, EditorToolbarType, Tribute, BCEditorRef };