import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record, List } from "immutable";

export interface IBaseForms {
    id: string,
    name: string,
    projectId: string
}

export interface IFormsContent extends IBaseForms {
}

export interface IReadonlyForms extends IBaseForms {
}

export type FormsContent = ReadonlyRecord<IReadonlyForms>;

export const FormsFactory = Record<IReadonlyForms>({
    id: "",
    name: "",
    projectId: ""
});

export const FormsContentFactoryFromJS = function (data: IFormsContent) {
    return FormsFactory(data);
}