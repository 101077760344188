import { isNullOrUndefined, quote, toLowerCase } from "@bimser/core";
import { GetIconComponent, IconInfo } from '@bimser/icons';
import IconCollapseAll from "@bimser/icons/16/collapse-all";
import IconExpandAll from "@bimser/icons/16/expand-all";
import IconMenupopArrow from "@bimser/icons/16/menupop-arrow";
import IconRefresh from "@bimser/icons/16/refresh";
import Tree from 'antd/lib/tree';
import classNames from 'classnames/bind';
import * as React from "react";
import { BCAddonButton, BCContextMenu, BCEmpty, BCInputSearch, BCLoading, BCScrollbars, findMessage, IContextMenuClickEventArgs, IInputChangeEventArgs } from '..';
import { IButtonBarItem } from '../BCButtonBar/entities';
import * as Styles from './assets/styles.scss';
import { ITreeviewContextMenu, ITreeviewDoubleClickEventArgs, ITreeviewDragEndEventArgs, ITreeviewDragEnterEventArgs, ITreeviewDragLeaveEventArgs, ITreeviewDragOverEventArgs, ITreeviewDragStartEventArgs, ITreeviewDropEventArgs, ITreeviewExpandEventArgs, ITreeviewItem, ITreeviewLoadDataEventArgs, ITreeviewProps, ITreeviewRibbonClickEvent, ITreeviewRightClickEventArgs, ITreeviewSelectEventArgs } from './entities/';
import { generatePlainItems } from "./helpers/generatePlainItems";

const cx = classNames.bind(Styles);

const BCTreeview = React.memo<ITreeviewProps>(props => {
    const __clicks = React.useRef<number>(0)
    const __lastExpandedKeys = React.useRef<string[]>([])

    const plainItems = React.useMemo(() => generatePlainItems(props.items), [props.items])
    const treeViewStyle: React.CSSProperties = React.useMemo(() => ({ height: "100%", ...props.style }), [props.style])
    const treeViewClassNames = React.useMemo(() => cx({
        treeViewContainer: true,
        hasActionButtons: props.buttonBarItems?.length,
        hasSearchbar: props.searchable
    }), [props.buttonBarItems, props.searchable])

    const [expandedKeys, setExpandedKeys] = React.useState([...props.expandedKeys || []])
    const [searchValue, setSearchValue] = React.useState("")
    const [contextMenuVisible, setContextMenuVisible] = React.useState(false)
    const [contextMenuEvent, setContextMenuEvent] = React.useState(null)
    const [contextMenuParentNode, setContextMenuParentNode] = React.useState(null)
    const [$contextMenu, $setContextMenu] = React.useState(props.contextMenu ? { isActive: props.contextMenu.isActive, contextMenuProps: props.contextMenu.contextMenuProps } : { isActive: false, contextMenuProps: null })
    const [contextMenuItems, setContextMenuItems] = React.useState([])
    const [autoExpandParent, setAutoExpandParent] = React.useState(true)
    const [expandedState, setExpandedState] = React.useState(false)

    React.useEffect(() => {
        if (props.contextMenu?.contextMenuProps && !props.contextMenu.contextMenuProps.onContextMenuOpening) {
            setContextMenuVisible(props.contextMenu.contextMenuProps.visible);
            setContextMenuItems(props.contextMenu.contextMenuProps.items);
        }
        $setContextMenu(props.contextMenu ? { isActive: props.contextMenu.isActive, contextMenuProps: props.contextMenu.contextMenuProps } : { isActive: false, contextMenuProps: null })
    }, [props.contextMenu])

    React.useEffect(() => {
        setExpandedKeys(props.expandedKeys || expandedKeys)
    }, [props.expandedKeys])

    const getParentKey = (key: string, tree: ITreeviewItem<any>[]): string => {
        let parentKey!: string;

        for (const node of tree) if (node.children) {
            if (node.children.some(item => item.key === key)) parentKey = node.key;
            else if (getParentKey(key, node.children)) parentKey = getParentKey(key, node.children);
        }

        return parentKey;
    }


    const getItemKey = (item: ITreeviewItem<any>): string => {
        const expr = props.keyExpr || "key";
        return Object.getOwnPropertyDescriptor(item, expr).value;
    }

    const loop = (data: Array<ITreeviewItem<any>>, key: string, callback: Function) => {
        data.forEach((item: ITreeviewItem<any>, index: number, arr: Array<ITreeviewItem<any>>) => {
            if (getItemKey(item) === key) return callback(item, index, arr);
            if (item.children) return loop(item.children, key, callback);
        });
    };

    const onItemSearch = (searchString: string | IInputChangeEventArgs) => {
        const value = typeof searchString === "string" ? searchString : searchString.data;

        if (!value) {

            setExpandedKeys(__lastExpandedKeys.current);
            setSearchValue(value);
            setAutoExpandParent(true);


            return props.onSearch?.(value, __lastExpandedKeys.current);
        }

        __lastExpandedKeys.current = [...expandedKeys];

        const _expandedKeys = plainItems.map(item => {
            if (toLowerCase(item.text).indexOf(toLowerCase(value)) > -1) return getParentKey(item.key, props.items);
            return null;
        }).filter((item, i, self) => item && self.indexOf(item) === i);

        setExpandedKeys(_expandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);

        props.onSearch?.(value, _expandedKeys);
    }

    const onLoadData = (e: any): any => {
        if (props.onLoadData) {
            const args: ITreeviewLoadDataEventArgs = { item: e, data: e.dataRef }

            if (props.canHandleOnLoad) return props.onLoadData(args);

            props.onLoadData(args);

            return Promise.resolve();
        }

        return Promise.resolve();
    }

    const onSelect = (selectedKeys: Array<string>, e: any) => {
        if (!props.readOnly) {

            const args: ITreeviewSelectEventArgs = {
                isSelected: e.selected,
                selectedKeys: selectedKeys,
                selectedItems: e.selectedNodes,
                item: e.node,
                data: e.node.dataRef
            }
            if (props.onSelect) props.onSelect(args);

            __clicks.current = __clicks.current + 1;

            if (__clicks.current === 2) props.onDoubleClick?.({ senderArgs: args.item, data: args.data });

            window.setTimeout(() => { __clicks.current = 0; }, 300);
        }
    }

    const onDrop = (info: any) => {
        const args: ITreeviewDropEventArgs = {
            event: info.event,
            node: info.node,
            dragNode: info.dragNode,
            dragNodeKeys: info.dragNodesKeys,
            dropPosition: info.dropPosition,
            dropToGap: info.dropToGap
        }
        //If you want to access the event properties in an asynchronous way, you should call event.persist() on the event, which will remove the synthetic event from the pool and allow references to the event to be retained by user code.
        args.event.persist();

        if (props.isTreeLeave) {
            const dropKey = args.node.props.eventKey;
            const dragKey = args.dragNode.props.eventKey;
            const dropPos = args.node.props.pos.split("-");
            const dropPosition = args.dropPosition - Number(dropPos[dropPos.length - 1]);

            const data = [...props.items];
            let dragObj: any;

            loop(data, dragKey, (item: ITreeviewItem<any>, index: number, arr: Array<ITreeviewItem<any>>) => {
                arr.splice(index, 1);
                dragObj = item;
            });

            if (args.dropToGap) {
                let ar: Array<ITreeviewItem<any>>;
                let i: number;
                loop(data, dropKey, (item: ITreeviewItem<any>, index: number, arr: Array<ITreeviewItem<any>>) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) ar.splice(i, 0, dragObj);
                else ar.splice(i + 1, 0, dragObj);

            } else {
                loop(data, dropKey, (item: ITreeviewItem<any>) => {
                    item.children = item.children || [];
                    item.children.push(dragObj);
                });
            }


            props.onDrop?.({ ...args, nodeItems: data });
        }

    }

    const onDragOver = (e: any) => {
        const args: ITreeviewDragOverEventArgs = { node: e.node, senderArgs: e.event };
        console.log("ITreeviewDragOverEventArgs=====", args, args.node.props);
        args.senderArgs.persist();
        props.onDragOver?.(args);
    }

    const onDragLeave = (e: any) => {
        const args: ITreeviewDragLeaveEventArgs = { node: e.node, senderArgs: e.event };
        console.log("ITreeviewDragLeaveEventArgs=====", args, args.node.props);
        args.senderArgs.persist();
        props.onDragLeave(args);
    }

    const onDragStart = (e: any) => {
        const args: ITreeviewDragStartEventArgs = { node: e.node, senderArgs: e.event };
        console.log("ITreeviewDragStartEventArgs=====", args, args.node.props);
        args.senderArgs.persist();
        props.onDragStart?.(args);
    }

    const getCheckedKeysWithChildren = (node: any) => {
        const keys = [node.key]

        if (node.children?.length) {
            for (const n of node.children) {
                keys.push(...getCheckedKeysWithChildren(n))
            }
        }

        return keys
    }

    const getAllParentKeys = (key: string) => {
        const parentKey = getParentKey(key, props.items);

        if (parentKey) return [parentKey, ...getAllParentKeys(parentKey)]

        return [parentKey]
    }

    const onCheck = (checkedKeys: string[], info: any) => {
        let _checkedKeys: (string | number)[] = checkedKeys;

        if (info.checked) {
            if (props.checkedKeys) {
                _checkedKeys.push(...props.checkedKeys.filter((key: string) => !_checkedKeys.includes(key)));
            }
        } else {
            const parentKeys = getAllParentKeys(info.node.key);
            const keys = getCheckedKeysWithChildren(info.node);
            _checkedKeys = props.checkedKeys.filter((x) => ![...keys, ...parentKeys].includes(x));
        }

        props.onCheck?.(_checkedKeys, info);
    }

    const onRightClick = (e: any) => {
        const args = { item: e.event, data: e.node.dataRef }
        //If you want to access the event properties in an asynchronous way, you should call event.persist() on the event, which will remove the synthetic event from the pool and allow references to the event to be retained by user code.
        args.item.persist();

        const _event: React.MouseEvent<MouseEvent> = args.item as React.MouseEvent<MouseEvent>;
        const _data = args.data

        setContextMenuParentNode(_data);

        if (props.contextMenu && props.contextMenu.contextMenuProps) {
            if (props.contextMenu.contextMenuProps.onContextMenuOpening) {
                const promise = props.contextMenu.contextMenuProps.onContextMenuOpening({ item: args.item, data: args.data });
                if (promise && promise.then && typeof promise.then == 'function') {
                    promise.then((openArgs) => {
                        if (openArgs && openArgs.items && openArgs.items.length > 0 && openArgs.allow !== false) {
                            setContextMenuVisible(true)
                            setContextMenuItems(openArgs.items)
                            setContextMenuEvent(_event)
                        }
                    })
                }
            } else {
                setContextMenuVisible(props.contextMenu.contextMenuProps.visible)
                setContextMenuItems(props.contextMenu.contextMenuProps.items)
                setContextMenuEvent(_event)
            }
        }

        props.onRightClick?.(args);
    }

    const onExpand = (_expandedKeys: Array<string>, e: any) => {
        const args: ITreeviewExpandEventArgs = {
            isExpanded: e.expanded,
            expandedKeys: _expandedKeys,
            item: e.node,
            data: e.node.dataRef
        }

        setExpandedKeys(args.expandedKeys)
        setAutoExpandParent(false)

        __lastExpandedKeys.current = args.expandedKeys;

        props.onExpand?.(args);
    }

    const renderTreeNodeIcon = (icon: IconInfo) => GetIconComponent(icon, {
        className: Styles.TreeviewTreeNodeIcon
    });

    const findSubItemsInSearchText = (items: ITreeviewItem<any>[]): boolean => {
        for (const node of items) {
            if (toLowerCase(node.text).indexOf(toLowerCase(searchValue)) != -1) return true;

            else if (node?.children) {
                const result = findSubItemsInSearchText(node.children);
                if (result) return result;
            }
        }

        return false
    }

    const isRenderingTreeNode = (item: ITreeviewItem<any>) => {
        return (
            toLowerCase(item.text).indexOf(toLowerCase(searchValue)) != -1
            ||
            (item.children && item.children.length > 0 && findSubItemsInSearchText(item.children))
        )
    }

    const onMouseDown = (e: any) => {
        if (props.readOnly && props.fromMobile) {
            const getProperParent = element => {
                if (element?.nodeName === "BODY") return;

                else if (element?.parentElement?.className?.includes('ant-tree-treenode')) {
                    element.parentElement.style.backgroundColor = 'unset';
                    return;
                }
                return getProperParent(element.parentElement);
            }
            getProperParent(e.target);
        }
    }

    const getTreeDataTitle = (item: ITreeviewItem<unknown>) => {
        const searchRegExp = new RegExp(quote(searchValue), "gi");
        const splittedText = item.text?.split(searchRegExp);
        const foundText = item.text?.match(searchRegExp);
        let result: JSX.Element[];

        if (foundText) {
            result = splittedText.map((value: string, index: number) => {
                const found = index < splittedText.length - 1 ? <span>{foundText[index]}</span> : ""
                return <React.Fragment key={index.toString()}>{value}{found}</React.Fragment>;
            })
        }

        return (
            <span onMouseDown={onMouseDown} title={item.title || item.text}>
                {renderTreeNodeIcon(item.icon)}
                <span className={Styles.SearchedValueHighlight}>{result || item.text}</span>
            </span>
        )
    }

    const renderTreeData = (items: ITreeviewItem<any>[], shouldRenderNode: boolean = false): any => {

        if (!items || items.length === 0) return null;
        return items
            .map((item) => {

                if (shouldRenderNode || isRenderingTreeNode(item)) {
                    const title = getTreeDataTitle(item);

                    return (
                        {
                            className: item.className,
                            disabled: item.disabled,
                            key: item.key,
                            title: props.customItemComponent ? props.customItemComponent(item) : title,
                            dataRef: item,
                            isLeaf: isNullOrUndefined(item.isLeaf) && item?.children?.length ? false : getLeafStatus(item.isLeaf),
                            children: renderTreeData(item?.children, true),
                            checkable: item.checkable,
                        }
                    );
                }
            })
            .filter(node => node);
    }

    const onRibbonItemClick = (args: ITreeviewRibbonClickEvent) => {
        props.onRibbonItemClick?.(args);
    }

    const renderEmptyTemplate = () => {
        if (props.customEmptyComponent) return props.customEmptyComponent;
        return <BCEmpty description={findMessage.get('100702')} />;
    }

    const renderTreeView = () => {

        if (!props.items || !props.items.length) return renderEmptyTemplate();

        const themeClass = props.themeClass !== undefined && props.themeClass !== null
            ? props.themeClass
            : "ide";

        let switcherIcon = {};

        if (props.icon !== undefined) {
            if (props.icon === null) switcherIcon = {};
            else switcherIcon = { switcherIcon: props.icon };
        }
        else switcherIcon = { switcherIcon: <IconMenupopArrow className={Styles.TreeviewRowArrowIcon} /> }



        return (
            <div className={[Styles.treeViewNodes, themeClass].join(" ")}>
                <Tree
                    className={props.className}
                    style={treeViewStyle}
                    loadData={onLoadData}
                    onSelect={onSelect}
                    onRightClick={onRightClick}
                    onExpand={onExpand}
                    showLine={props.showLine ? props.showLine : false}
                    autoExpandParent={autoExpandParent}
                    expandedKeys={expandedKeys}
                    defaultExpandedKeys={props.expandedKeys || []}
                    draggable={props.draggable}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDragStart={onDragStart}
                    filterTreeNode={props.filterTreeNode}
                    multiple={props.multiple}
                    selectedKeys={props.selectedKeys ? props.selectedKeys : null}
                    defaultExpandAll={props.defaultExpandAll}
                    checkable={props.checkable}
                    onCheck={onCheck}
                    checkStrictly={props.checkStrictly}
                    checkedKeys={props.checkedKeys}
                    defaultCheckedKeys={props.defaultCheckedKeys}
                    treeData={renderTreeData(props.items)}
                    disabled={props.disabled}
                    {...switcherIcon}
                />
            </div>
        )
    }

    const BCContextMenu_ItemClickEvent = (args: IContextMenuClickEventArgs) => {
        props.contextMenu.contextMenuProps.onClick?.(args);
    }

    const BCContextMenu_CloseEvent = (visible: boolean) => {
        if (props.contextMenu?.contextMenuProps?.onClose)
            props.contextMenu.contextMenuProps.onClose(visible);
        else setContextMenuVisible(visible)

    }

    const renderContextMenu = () => (
        $contextMenu.isActive && < BCContextMenu
            visible={contextMenuVisible}
            items={contextMenuItems}
            onClick={BCContextMenu_ItemClickEvent}
            onClose={BCContextMenu_CloseEvent}
            mouseEvent={contextMenuEvent}
            parentNode={contextMenuParentNode}
            onContextMenuOpening={props.contextMenu.contextMenuProps.onContextMenuOpening}
            useStateForOpenKeys
        />
    )

    const wrapperWithScrollbars = (children: JSX.Element) => {
        const scrollbarContainerHeight = props.searchable || props.buttonBarItems?.length > 0 ?
            'calc(100% - 60px)' :
            "100%";

        return (
            <BCScrollbars cssClass={props.scrollbarCssClass} styles={{ width: '100%', height: scrollbarContainerHeight, ...props.scrollbarStyles }} autoHide>
                {children}
            </BCScrollbars>
        )
    }

    const collapseTree = () => {
        setExpandedState(!expandedState);

        if (props.onCollapseTree) props.onCollapseTree(!expandedState)
        else setExpandedKeys(getExpandedKeys())
    }

    const getExpandedKeys = () => {
        if (expandedState) {
            if (props.expandedKeys?.length > 0) {
                if (!props.expandedKeys.find((i) => props.items.find((x) => x.key === i))) {
                    const unExpandedRootItems = props.items.filter((i) => props.expandedKeys.find((x) => x === i.key) ? false : true)
                    const newExpandedKeys = [...unExpandedRootItems.map((i) => i.key), ...props.expandedKeys];
                    props.onExpand({ expandedKeys: newExpandedKeys });

                    return newExpandedKeys;
                }

                return props.expandedKeys;
            }

            return props.items ? props.items.map((i) => i.key) : []; // root dizindeki bütün itemları açması için
        }

        return [];
    }

    const onClickRefreshButton = () => {
        props.onClickRefreshButton?.()
    }

    const renderHeader = () => {
        if (props.searchable && !props.disableToolbar) {
            let items: IButtonBarItem[];

            if (props.onRibbonItemClick) {
                const buttonBarItems = props.buttonBarItems || [];

                items = buttonBarItems.map((item) => {
                    return {
                        ...item,
                        buttonProps: {
                            ...item.buttonProps,
                            onClick: (e: any) => onRibbonItemClick({ e, data: item }),
                        }
                    }
                });
            }

            const farItems: IButtonBarItem[] = [
                {
                    buttonProps: {
                        text: expandedState ? findMessage.get('100708') : findMessage.get('101540'),
                        title: expandedState ? findMessage.get('100708') : findMessage.get('101540'),
                        id: findMessage.get('101540'),
                        icon: expandedState ? <IconExpandAll /> : <IconCollapseAll />,
                        onClick: collapseTree
                    }
                }
            ]

            if (props.showRefreshButton) farItems.unshift({
                buttonProps: {
                    text: findMessage.get("102660"),
                    title: findMessage.get("102660"),
                    id: "refreshButton",
                    icon: <IconRefresh />,
                    onClick: onClickRefreshButton
                }
            });


            return (
                <div className={Styles.treeViewToolbar}>
                    <BCAddonButton
                        items={items}
                        farItems={farItems}
                        elementsCountToShow={2}
                    >
                        <BCInputSearch
                            value={searchValue}
                            ref={props.getSearchInputRef}
                            placeHolder={findMessage.get('100002')}
                            size={'middle'}
                            onSearch={onItemSearch}
                            onChange={onItemSearch}
                        />
                    </ BCAddonButton>
                </div>
            )
        }
        else if (props.showRefreshButton) {
            return (
                <div className={cx({ treeViewToolbar: true, refreshButtonWithoutSearch: true })}>
                    <a className={Styles.refreshButtonStyle} onClick={props.onClickRefreshButton}>
                        <IconRefresh className={Styles.refreshIconStyle} />
                        {findMessage.get("102660")}
                    </a>
                </div>
            )
        }

    }

    const getLeafStatus = (leaf?: boolean): boolean => {
        if (isNullOrUndefined(leaf)) return true;
        return leaf;
    }

    const getTreeView = () => props.hasSrollbar ? wrapperWithScrollbars(renderTreeView()) : renderTreeView()

    return (
        <BCLoading show={props.showLoading} delay={500} >
            <div className={[treeViewClassNames, "treeViewContainer"].join(" ")}>
                {renderHeader()}
                {getTreeView()}
                {renderContextMenu()}
            </div>
        </BCLoading>
    )
})

export default BCTreeview;
export { ITreeviewProps, ITreeviewItem, ITreeviewLoadDataEventArgs, ITreeviewSelectEventArgs, ITreeviewRightClickEventArgs, ITreeviewExpandEventArgs, ITreeviewDropEventArgs, ITreeviewContextMenu, ITreeviewDoubleClickEventArgs, ITreeviewDragOverEventArgs, ITreeviewDragEnterEventArgs, ITreeviewDragEndEventArgs, ITreeviewDragLeaveEventArgs, ITreeviewDragStartEventArgs, ITreeviewRibbonClickEvent };

