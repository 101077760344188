export enum MessageBoxIcon {
    None,
    Error,
    Info,
    Warning,
    Success,
    SevereWarning,
    Blocked,
    Confirm
}
