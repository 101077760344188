import * as React from "react";
import BCBadge from '../../BCBadge';
import { IItem } from '@bimser/core';
import * as Styles from '../assets/style.scss';
import { GetIconComponent } from "@bimser/icons";
let classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

export default class MenuItemInline extends React.Component<{ item: IItem }, {}> {

    badgeStyle: React.CSSProperties = {
        marginLeft: "5px",
    }

    bcIconStyles: React.CSSProperties = {
        verticalAlign: 'inherit',
        lineHeight: '100%'
    }

    constructor(props: { item: IItem }) {
        super(props);
    }

    getMenuNameWord = (text: string) => text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/  +/g, ' ').trim().split(' ').slice(0, 2).map((each) => each.charAt(0).toUpperCase()).join("");

    renderIcon() {
        if (this.props.item.icon) {
            return GetIconComponent(this.props.item.icon, { style: this.bcIconStyles });
        } else {
            return <div className="bcIcon" style={this.bcIconStyles}><div className={'nameWord'}>{this.getMenuNameWord(this.props.item.text)}</div></div>;
        }
    }

    renderText() {
        return (
            <span title={this.props.item.text} className={'ant-menu-text-custom'}>{this.props.item.text}</span>
        )
    }

    renderBadge() {
        return (
            <BCBadge {...this.props.item.badge} style={this.badgeStyle} />
        )
    }

    render() {
        let classNames = cx({
            height100: true
        });

        return (
            <div className={[classNames, this.props.item.className].join(' ')} style={this.props.item.style}>
                {this.renderIcon()}
                {this.renderText()}
                {this.renderBadge()}
            </div>
        )

    }
}