import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseGroup {
    id: number,
    groupCode: string,
    description: string,
    status: number,
    importStatus: number
}

export interface IGroup extends IBaseGroup { }

export interface IReadonlyGroup extends IBaseGroup { }

export type Group = ReadonlyRecord<IReadonlyGroup>;

export const GroupFactory = Record<IReadonlyGroup>({
    id: 0,
    groupCode: '',
    description: '',
    status: 0,
    importStatus: 0
});

export const GroupFactoryFromJS = function (data: IGroup) {
    return GroupFactory(data);
}