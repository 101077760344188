import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { BaseRequest } from "../../../entities/BaseRequest";
import { ISynergyLogonApp } from "../../../entities/synergyLogonApp";

export interface IGetLoginParametersRequest extends BaseRequest {
    DomainAddress: string,
    synergyLogonApp?: ISynergyLogonApp
}

export interface IReadonlyGetLoginParametersRequest extends BaseRequest {
    DomainAddress: string,
    synergyLogonApp?: ISynergyLogonApp
}

export type GetLoginParametersRequest = ReadonlyRecord<IReadonlyGetLoginParametersRequest>;

export const GetLoginParametersRequestFactory = Record<IReadonlyGetLoginParametersRequest>({
    DomainAddress: '',
    synergyLogonApp: "WebInterface"
});

export const GetLoginParametersRequestFromJS = function (data: IGetLoginParametersRequest) {
    return GetLoginParametersRequestFactory(data);
}