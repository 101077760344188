import IconAdd from "@bimser/icons/16/add";
import IconDelete from "@bimser/icons/16/delete";
import IconEmpty from "@bimser/icons/16/empty-icon";
import IconTabNavRight from "@bimser/icons/16/tab-nav-right";
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import * as React from "react";
import { BCButton, findMessage, IComboboxSelectedChangeEventArgs } from "../..";
import { IItem } from "@bimser/core";
import { IButtonClickEventArgs } from '../../BCButton';
import BCMultipleCombobox from "../../BCMultipleCombobox";
import * as Styles from "../assets/conditionalStatementStyles.scss";
import { ICRule, IStatement, IStatementComponentProps } from "../entities";
import ConditionGroup from "./ConditionGroup";

var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

const actionTagBoxStyles = { width: '277px' };
type IStatementProps = IStatement & ICRule & IStatementComponentProps;

export default class Statement extends React.Component<IStatementProps, {}> {

    constructor(props: IStatementProps) {
        super(props);
        this.onConjunctionAddClicked = this.onConjunctionAddClicked.bind(this);
        this.collapsedClick = this.collapsedClick.bind(this);
        this.onActionValueChanged = this.onActionValueChanged.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    getAddButtonItems() {
        let items: IItem[] = [];

        let { nextStatement, typeName, conditionGroup } = this.props;

        if (!conditionGroup && typeName !== 'ElseStatement') {
            items.push(...[
                { key: 'AddGroup', text: findMessage.get('100611') }, //Add group
                { key: 'Divider1', isDivider: true }
            ]);
        }

        switch (this.props.typeName) {
            case 'IfStatement': {
                items.push(...[
                    { key: 'AddIf', text: findMessage.get('102261') }, //Add If
                    { key: 'AddElseIf', text: findMessage.get("102263") } // Else If Ekle
                ]);

                if (!nextStatement || nextStatement.typeName === 'IfStatement') {
                    items.push({ key: 'AddElse', text: findMessage.get("102262") }); //Else Ekle
                }

                break;
            }
            case 'ElseIfStatement': {
                items.push(...[
                    { key: 'AddIf', text: findMessage.get('102261') }, //Add If
                    { key: 'AddElseIf', text: findMessage.get("102263") } // Else If Ekle
                ]);

                if (!nextStatement || nextStatement.typeName === "IfStatement") {
                    items.push({ key: 'AddElse', text: findMessage.get("102262") }); // Else Ekle
                }

                break;
            }
            case 'ElseStatement': {
                items.push({ key: 'AddIf', text: findMessage.get("102261") }); // If Ekle
                break;
            }
        }

        return items
    }

    renderAddButton() {

        if (this.props.readonly) return undefined;

        return <Dropdown trigger={['click']} overlay={
            <Menu
                multiple={false}
                onClick={this.onConjunctionAddClicked}
                items={this.getAddButtonItems().map(item => {
                    if (item.isDivider) return { type: "divider", key: item.key }
                    return { key: item.key, label: item.text }
                })}
            />
        }>
            <BCButton cssClass={Styles.addButton} type={'link'} icon={<IconAdd />} />
        </Dropdown>;

    }

    onConjunctionAddClicked(e: any) {
        switch (e.key) {
            case "AddGroup": {
                if (this.props.addConditionGroupTo) this.props.addConditionGroupTo(this.props.id, 'IfStatement');
                break;
            }
            case "AddIf": {
                if (this.props.addConditionGroupTo) this.props.addStatementTo(this.props.id, 'IfStatement');
                break;
            }
            case "AddElseIf": {
                if (this.props.addConditionGroupTo) this.props.addStatementTo(this.props.id, 'ElseIfStatement');
                break;
            }
            case "AddElse": {
                if (this.props.addConditionGroupTo) this.props.addStatementTo(this.props.id, 'ElseStatement');
                break;
            }
            default: console.warn("Invalid operation exception");
        }
    }

    onActionValueChanged(args: IComboboxSelectedChangeEventArgs) {
        let selectedItems = args.data as string[];
        let actions = this.props.selectableActions.filter(action => {
            return selectedItems.findIndex(i => action.id === i) > -1
        });

        this.props.updateStatementActions(this.props.id, this.props.statementType, actions)
    }

    renderActionSelectBox() {

        let classNames = cx({
            actionSelectBox: true,
            disabled: this.props.readonly
        });

        return (
            <BCMultipleCombobox
                value={this.props.actions.map(i => i.id)}
                maxTagCount={2}
                options={this.props.selectableActions.map(i => { return { key: i.id, text: i.name, value: i.id, disabled: !i.enabled } })}
                showSearch={true}
                style={actionTagBoxStyles}
                onSelectedChange={this.onActionValueChanged}
                placeholder={this.props.readonly ? '' : findMessage.get('101519') + ' +'}
                className={classNames}
                disabled={this.props.readonly}
                mode={"tags"}
            />
        )
    }

    collapsedClick() {
        if (this.props.updateStatementCollapse) this.props.updateStatementCollapse(this.props.id, this.props.statementType, !this.props.collapsed);
    }

    renderCollapseIcon() {


        let classNames = cx({
            levelCollapseIcon: true,
            collapsed: this.props.collapsed && this.props.typeName !== 'ElseStatement'
        });

        if (this.props.typeName === 'ElseStatement') {
            return (
                <div className={classNames}>
                    <IconEmpty />
                </div>
            )
        } else {
            return (
                <div className={classNames} onClick={this.collapsedClick}>
                    {this.props.conditionGroup ? <IconTabNavRight /> : undefined}
                </div>
            )
        }

    }

    getConjuctionTypeText() {
        switch (this.props.typeName) {
            case 'IfStatement': return 'IF';
            case 'ElseIfStatement': return 'ELSE IF';
            case 'ElseStatement': return 'ELSE';
        }
    }

    onDeleteClick(e: IButtonClickEventArgs) {
        this.props.deleteStatement(this.props.id);
    }

    canStatementDelete() {
        let canDelete = true;
        let { nextStatement, typeName, statementIndex } = this.props;

        if (typeName === 'IfStatement') {
            if ((nextStatement && nextStatement.typeName !== 'IfStatement' && statementIndex === 0)) {
                canDelete = false;
            }
        }
        return canDelete
    }

    renderDeleteButton(): JSX.Element {

        if (this.props.readonly || !this.canStatementDelete()) return undefined;

        return (
            <BCButton type={'link'} onClick={this.onDeleteClick} cssClass={Styles.deleteButton} icon={<IconDelete />} />
        )

    }

    renderConjunction(): JSX.Element {

        let classNames = cx({
            coloredBox: true,
            purple: true,
            conjunctionBox: true
        });

        return (
            <div className={Styles.qb_condition_group_conjunction}>
                <div className={Styles.conditionItemContent}>
                    {this.renderCollapseIcon()}
                    <div className={classNames}>
                        {this.getConjuctionTypeText()}
                    </div>
                    {this.renderActionSelectBox()}
                    {this.renderAddButton()}
                    {this.renderDeleteButton()}
                </div>
            </div>
        )
    }

    renderConditionGroup() {
        if (this.props.conditionGroup && !this.props.collapsed) {
            return (
                <div className={Styles.statementContent}>
                    <ConditionGroup
                        conditionGroup={this.props.conditionGroup}
                        addConditionGroupTo={this.props.addConditionGroupTo}
                        deleteConditionGroupFrom={this.props.deleteConditionGroupFrom}
                        statementType={this.props.statementType}
                        conjunctionChanged={this.props.conjunctionChanged}
                        addConditionTo={this.props.addConditionTo}
                        updateCondition={this.props.updateCondition}
                        deleteConditionFrom={this.props.deleteConditionFrom}
                        fields={this.props.fields}
                        updateConditionGroupCollapse={this.props.updateConditionGroupCollapse}
                        readonly={this.props.readonly}
                    />
                </div>
            )
        }
    }

    renderStatement(): JSX.Element {
        return (
            <React.Fragment>
                {this.renderConjunction()}
                {this.renderConditionGroup()}
            </React.Fragment>
        );
    }

    render() {
        return this.renderStatement()
    }

}