import getBrowser from "./getBrowser";

const commonFonts = [
  { key: 'Arial, sans-serif', text: 'Arial, sans-serif', value: 'Arial, sans-serif' },
  { key: 'Times New Roman, serif', text: 'Times New Roman, serif', value: 'Times New Roman, serif' },
  { key: 'Courier New, monospace', text: 'Courier New, monospace', value: 'Courier New, monospace' },
  { key: 'Verdana, sans-serif', text: 'Verdana, sans-serif', value: 'Verdana, sans-serif' },
  { key: 'Georgia, serif', text: 'Georgia, serif', value: 'Georgia, serif' },
  { key: 'Trebuchet MS, sans-serif', text: 'Trebuchet MS, sans-serif', value: 'Trebuchet MS, sans-serif' },
  { key: 'Arial Black, sans-serif', text: 'Arial Black, sans-serif', value: 'Arial Black, sans-serif' },
  { key: 'Impact, fantasy', text: 'Impact, fantasy', value: 'Impact, fantasy' },
];

function getSupportedFontsByBrowser(browser: any) {
  switch (browser) {
    case 'Chrome':
    case 'Edge':
    case 'Firefox':
      return commonFonts.concat([
        { key: 'Arial Narrow, sans-serif', text: 'Arial Narrow, sans-serif', value: 'Arial Narrow, sans-serif' },
        { key: 'Book Antiqua, serif', text: 'Book Antiqua, serif', value: 'Book Antiqua, serif' },
        { key: 'Brush Script MT, cursive', text: 'Brush Script MT, cursive', value: 'Brush Script MT, cursive' },
        { key: 'Century Gothic, sans-serif', text: 'Century Gothic, sans-serif', value: 'Century Gothic, sans-serif' },
        { key: 'Comic Sans MS, cursive', text: 'Comic Sans MS, cursive', value: 'Comic Sans MS, cursive' },
        { key: 'Lucida Console, monospace', text: 'Lucida Console, monospace', value: 'Lucida Console, monospace' },
        { key: 'Lucida Sans Unicode, sans-serif', text: 'Lucida Sans Unicode, sans-serif', value: 'Lucida Sans Unicode, sans-serif' },
        { key: 'Monaco, monospace', text: 'Monaco, monospace', value: 'Monaco, monospace' },
        { key: 'Tahoma, sans-serif', text: 'Tahoma, sans-serif', value: 'Tahoma, sans-serif' },
        { key: 'Monotype Corsiva, cursive', text: 'Monotype Corsiva, cursive', value: 'Monotype Corsiva, cursive' }
      ]);
    case 'Safari':
      return commonFonts.concat([
        { key: 'Helvetica, sans-serif', text: 'Helvetica, sans-serif', value: 'Helvetica, sans-serif' },
        { key: 'Times, serif', text: 'Times, serif', value: 'Times, serif' },
        { key: 'Courier, monospace', text: 'Courier, monospace', value: 'Courier, monospace' },
        { key: 'Palatino, serif', text: 'Palatino, serif', value: 'Palatino, serif' },
        { key: 'Garamond, serif', text: 'Garamond, serif', value: 'Garamond, serif' },
        { key: 'Bookman, serif', text: 'Bookman, serif', value: 'Bookman, serif' },
        { key: 'Apple Chancery, cursive', text: 'Apple Chancery, cursive', value: 'Apple Chancery, cursive' }
      ]);
    default:
      return commonFonts;
  }
}

function getSupportedFonts() {
  const browser = getBrowser();
  return getSupportedFontsByBrowser(browser);
}

const systemFontFamilies = getSupportedFonts();

export default systemFontFamilies;
