import { createGuid, isNullOrUndefined, MLHelper } from '@bimser/core';
import { IconInfo } from '@bimser/icons';
import IconAdd from "@bimser/icons/16/add";
import IconCancel from "@bimser/icons/16/cancel";
import IconCaution from "@bimser/icons/16/mail-important";
import IconMore from "@bimser/icons/16/more";
import GetIconByName from '@bimser/icons/lib/getIcon16ByName';
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import React from 'react';
import BCCombobox, { IComboboxSelectedChangeEventArgs } from '../BCCombobox';
import BCDropdownWrapper from '../BCDropdownWrapper';
import BCInputMultiLanguage from '../BCInputMultiLanguage';
import { findMessage } from '../BCIntl';
import { IMenuItemClickEventArgs } from '../BCMenu';
import Styles from "./assets/styles.scss";
import FishBoneGrid from './components/FishBoneGrid';

export interface IMainReason {
    key: string,
    icon?: IconInfo,
    text: Record<string, string>,
    selectedIndex: number;
    subReasons: ISubReason[]
}

export interface ISubReason {
    key: string;
    icon?: IconInfo;
    text: Record<string, string>;
    selectedIndex: number;
    realReason: boolean;
}

export interface IFishBoneProps {
    mainReasons: IMainReason[];
    maxMainReason?: number;
    maxSubReason?: number;
    problem?: Record<string, string>;
    customSubReason?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    backgroundColor?: string;
    onChangeRealReason?: (args: { mainReasonKey: string; subReason: ISubReason; }) => void;
    onRemoveSubReason?: (args: { mainReasonKey: string; subReason: ISubReason; }) => void;
    onRemoveMainReason?: (args: { mainReasonKey: string; }) => void;
    onChangeMainReason?: (args: { oldMainReasonKey: string; newMainReasonKey: string; }) => void;
    onSelectMainReason?: (args: { newMainReasonKey: string; }) => void;
    addSubReason?: (args: any) => void;
    changeSubReason?: (args: any) => void;
    onNewCustomSubReason?: (args: { data: Record<string, string>, mainReasonKey: string }) => void;
    onChangeCustomSubReason?: (args: { data: Record<string, string>, mainReasonKey: string, subReasonKey: string }) => void;
    onRemoveCustomSubReason?: (args: any) => void;
    onChangeProblem?: (culture?: string, val?: string) => void;
}

const color = "gray";

const BCFishBone: React.FC<IFishBoneProps> = ({
    mainReasons,
    problem,
    maxMainReason = 6,
    maxSubReason = 10,
    customSubReason = false,
    readonly,
    disabled,
    backgroundColor,
    ...props
}) => {
    const [topMainReasons, setTopMainReasons] = React.useState<IMainReason[]>([]);
    const [bottomMainReasons, setBottomMainReasons] = React.useState<IMainReason[]>([]);

    const reasonCount = React.useMemo(() => topMainReasons.length + bottomMainReasons.length, [topMainReasons, bottomMainReasons])

    React.useEffect(() => {
        const topReasons: IMainReason[] = [];
        const bottomReasons: IMainReason[] = [];

        mainReasons
            .filter(({ selectedIndex }) => !isNullOrUndefined(selectedIndex))
            .sort((a, b) => a.selectedIndex - b.selectedIndex)
            .slice(0, maxMainReason)
            .forEach((value, i) => i % 2 ? bottomReasons.push(value) : topReasons.push(value));

        setTopMainReasons(cloneDeep(topReasons).reverse());
        setBottomMainReasons(cloneDeep(bottomReasons).reverse());
    }, [mainReasons, maxMainReason])

    const onClickSubReasonMenu = (args: IMenuItemClickEventArgs) => {
        switch (args.data.key) {
            case "changeSubReason": {
                props.changeSubReason?.(args.data.value)
                break;
            }
            case "addSubReason": {
                props.addSubReason?.(args.data.value)
                break;
            }
            case "remove": {
                props.onRemoveSubReason?.(args.data.value)
                break;
            }
            case "makeRealReason": {
                props.onChangeRealReason?.(args.data.value);
                break;
            }
        }
    }

    const renderEffect = () => (
        <div className={[Styles.effect, Styles.left, Styles[`${color}_`], Styles[`${color}Border`]].join(" ")}>
            <div className={Styles.effectValue}>
                <BCInputMultiLanguage
                    disabled={disabled}
                    readOnly={readonly}
                    currentLanguage={MLHelper.currentLanguage}
                    supportedLanguages={MLHelper.supportedLanguages}
                    values={problem}
                    onLanguageValueChange={(culture, val) => props.onChangeProblem?.(culture, val)}
                />
            </div>
        </div>
    )


    const renderSubReasons = (mainReason: IMainReason) => {
        const causes = mainReason.subReasons
            .filter(({ selectedIndex }) => !isNullOrUndefined(selectedIndex))
            .sort((a, b) => a.selectedIndex - b.selectedIndex)
            .map(subReason => (
                <div className={Styles.subReason} key={subReason.key}>
                    {
                        subReason.realReason && <div>
                            <IconCaution />
                        </div>
                    }
                    <span style={{ borderBottom: customSubReason ? "unset" : "1px dashed gray" }} title={subReason.text[MLHelper.currentLanguage.culture]}>
                        {
                            customSubReason
                                ? <BCInputMultiLanguage
                                    disabled={disabled}
                                    readOnly={readonly}
                                    currentLanguage={MLHelper.currentLanguage}
                                    supportedLanguages={MLHelper.supportedLanguages}
                                    values={subReason.text}
                                    onBlur={(_, val) => {
                                        props.onChangeCustomSubReason?.({
                                            data: val,
                                            mainReasonKey: mainReason.key,
                                            subReasonKey: subReason.key
                                        })
                                    }}
                                />
                                : subReason.text[MLHelper.currentLanguage.culture]
                        }
                    </span>
                    <div
                        style={{ paddingLeft: "15px", display: "inline-block", alignSelf: "center" }}
                    >
                        <BCDropdownWrapper
                            trigger={["hover"]}
                            placement={"bottomRight"}
                            disabled={disabled || readonly}
                            menuProps={{
                                onClick: onClickSubReasonMenu,
                                items: customSubReason
                                    ? [
                                        {
                                            key: "makeRealReason",
                                            text: findMessage.get("102855"),
                                            value: { mainReasonKey: mainReason.key, subReason },
                                            icon: IconCaution.info
                                        },
                                        {
                                            key: "remove",
                                            text: findMessage.get("101218"),
                                            value: { mainReasonKey: mainReason.key, subReason },
                                            icon: IconCancel.info
                                        }
                                    ]
                                    : [
                                        {
                                            key: "changeSubReason",
                                            text: findMessage.get("100042"),
                                            value: { mainReasonKey: mainReason.key, subReason },
                                            icon: IconAdd.info,
                                        },
                                        {
                                            key: "makeRealReason",
                                            text: findMessage.get("102855"),
                                            value: { mainReasonKey: mainReason.key, subReason },
                                            icon: IconCaution.info
                                        },
                                        {
                                            key: "remove",
                                            text: findMessage.get("101218"),
                                            value: { mainReasonKey: mainReason.key, subReason },
                                            icon: IconCancel.info
                                        }
                                    ],
                            }}
                        >
                            <div>
                                <IconMore color='var(--primary-color-6)' />
                            </div>
                        </BCDropdownWrapper>
                    </div>
                </div>
            ))

        const filledCausesLength = causes.length.valueOf();

        while (true) {
            if (causes.length >= maxSubReason) break;

            causes.push(
                <div className={Styles.subReason} key={createGuid()}>
                    <span style={{ borderBottom: customSubReason ? "unset" : "1px dashed gray" }}>
                        {
                            customSubReason
                                ? <BCInputMultiLanguage
                                    currentLanguage={MLHelper.currentLanguage}
                                    supportedLanguages={MLHelper.supportedLanguages}
                                    disabled={disabled || !mainReason.key || filledCausesLength !== causes.length}
                                    readOnly={readonly}
                                    onBlur={(_, val) => {
                                        if (isEmpty(val) || Object.values(val).every(v => v == "")) return;
                                        props.onNewCustomSubReason?.({
                                            data: val,
                                            mainReasonKey: mainReason.key
                                        })
                                    }}
                                />
                                : <React.Fragment />
                        }
                    </span>
                    <div
                        style={{ paddingLeft: "15px", display: "inline-block", alignSelf: "center" }}
                    >
                        <BCDropdownWrapper
                            trigger={["hover"]}
                            placement={"bottomRight"}
                            disabled={disabled || customSubReason || readonly}
                            menuProps={{
                                onClick: onClickSubReasonMenu,
                                items: !customSubReason && filledCausesLength === causes.length
                                    ? [
                                        {
                                            key: "addSubReason",
                                            text: findMessage.get("100016"),
                                            value: mainReason,
                                            icon: IconAdd.info,
                                        }
                                    ]
                                    : [],
                            }}
                        >
                            <div>
                                <IconMore color='var(--primary-color-6)' />
                            </div>
                        </BCDropdownWrapper>
                    </div>
                </div>
            )
        }


        return <div className={Styles.rootCauses}>{causes}</div>
    }

    const renderTopMainReasons = () => {
        const topCauses = topMainReasons.map(mainReason => {
            return (
                <div key={mainReason.key} className={Styles.causeContent}>
                    <div className={[Styles.cause, Styles.top, Styles[`${color}Border`]].join(" ")}>
                        <BCCombobox
                            defaultValue={mainReason.key}
                            allowClear
                            onSelectedChange={(args: IComboboxSelectedChangeEventArgs<string>) => {
                                // onRemove
                                if (typeof args.data === "undefined") return props.onRemoveMainReason?.({ mainReasonKey: mainReason.key });
                                // onChange
                                if (args.data !== mainReason.key) return props.onChangeMainReason?.({
                                    oldMainReasonKey: mainReason.key,
                                    newMainReasonKey: args.data
                                })
                            }}
                            options={
                                mainReasons
                                    .filter(({ key }: IMainReason) => !([topMainReasons.map(e => e.key), bottomMainReasons.map(e => e.key)].flat().includes(key)) || key === mainReason.key)
                                    .map(({ key, text, icon }) => {
                                        const title = text[MLHelper.currentLanguage.culture];
                                        const info = GetIconByName(icon?.name)?.info;
                                        return { key, value: key, text: title, title, icon: info }
                                    })
                            }
                            style={{ maxWidth: "150px", width: "100%" }}
                            disabled={disabled}
                            readonly={readonly}
                        />
                    </div>
                    <div className={Styles.causeAndLine}>
                        {renderSubReasons(mainReason)}
                        <div className={[Styles.diagonalLine, Styles[`${color}TopBottom`]].join(" ")} />
                    </div>
                </div>
            )
        });

        const filledCausesLength = topCauses.length.valueOf();

        while (topCauses.length < Math.round(maxMainReason / 2)) topCauses.unshift(
            <div key={createGuid()} className={Styles.causeContent}>
                <div className={[Styles.cause, Styles.top, Styles[`${color}Border`]].join(" ")}>
                    <BCCombobox
                        onSelectedChange={(args: IComboboxSelectedChangeEventArgs<string>) => {
                            // onAddNew
                            props.onSelectMainReason?.({ newMainReasonKey: args.data })
                        }}
                        options={
                            mainReasons
                                .filter(({ key }: IMainReason) => !([topMainReasons.map(e => e.key), bottomMainReasons.map(e => e.key)].flat().includes(key)))
                                .map(({ key, text, icon }) => {
                                    const title = text[MLHelper.currentLanguage.culture];
                                    const info = GetIconByName(icon?.name)?.info;
                                    return { key, value: key, text: title, title, icon: info }
                                })
                        }
                        style={{ maxWidth: "150px", width: "100%" }}
                        disabled={disabled || reasonCount % 2 === 1 || filledCausesLength !== topCauses.length}
                        readonly={readonly}
                    />
                </div>
                <div className={Styles.causeAndLine}>
                    {renderSubReasons({ subReasons: [] } as IMainReason)}
                    <div className={[Styles.diagonalLine, Styles[`${color}TopBottom`]].join(" ")} />
                </div>
            </div>
        )

        return <div className={Styles.causesGroup}>{topCauses}</div>
    }

    const renderBottomMainReasons = () => {
        const bottomCauses = bottomMainReasons.map(mainReason => {
            return (
                <div key={mainReason.key} className={Styles.causeContent}>
                    <div className={Styles.causeAndLine}>
                        {renderSubReasons(mainReason)}
                        <div className={[Styles.diagonalLine, Styles[`${color}BottomTop`]].join(" ")} />
                    </div>
                    <div className={[Styles.cause, Styles.bottom, Styles[`${color}Border`]].join(" ")}>
                        <BCCombobox
                            disabled={disabled}
                            readonly={readonly}
                            defaultValue={mainReason.key}
                            allowClear
                            onSelectedChange={(args: IComboboxSelectedChangeEventArgs<string>) => {
                                // onRemove
                                if (typeof args.data === "undefined") return props.onRemoveMainReason?.({ mainReasonKey: mainReason.key });
                                // onChange
                                if (args.data !== mainReason.key) return props.onChangeMainReason?.({
                                    oldMainReasonKey: mainReason.key,
                                    newMainReasonKey: args.data
                                })
                            }}
                            options={
                                mainReasons
                                    .filter(({ key }: IMainReason) => !([topMainReasons.map(e => e.key), bottomMainReasons.map(e => e.key)].flat().includes(key)) || key === mainReason.key)
                                    .map(({ key, text, icon }) => {
                                        const title = text[MLHelper.currentLanguage.culture];
                                        const info = GetIconByName(icon?.name)?.info;
                                        return { key, value: key, text: title, title, icon: info }
                                    })
                            }
                            style={{ maxWidth: "150px", width: "100%" }}
                        />
                    </div>
                </div>
            )
        });

        const filledCausesLength = bottomCauses.length.valueOf()

        while (bottomCauses.length < (maxMainReason % 2 === 1 ? (Math.round(maxMainReason / 2)) - 1 : Math.round(maxMainReason / 2))) bottomCauses.unshift(
            <div key={createGuid()} className={Styles.causeContent}>
                <div className={Styles.causeAndLine}>
                    {renderSubReasons({ subReasons: [] } as IMainReason)}
                    <div className={[Styles.diagonalLine, Styles[`${color}BottomTop`]].join(" ")} />
                </div>
                <div className={[Styles.cause, Styles.bottom, Styles[`${color}Border`]].join(" ")}>
                    <BCCombobox
                        onSelectedChange={(args: IComboboxSelectedChangeEventArgs<string>) => {
                            // onAddNew
                            props.onSelectMainReason?.({ newMainReasonKey: args.data })
                        }}
                        options={
                            mainReasons
                                .filter(({ key }: IMainReason) => !([topMainReasons.map(e => e.key), bottomMainReasons.map(e => e.key)].flat().includes(key)))
                                .map(({ key, text, icon }) => {
                                    const title = text[MLHelper.currentLanguage.culture];
                                    const info = GetIconByName(icon?.name)?.info;
                                    return { key, value: key, text: title, title, icon: info }
                                })
                        }
                        style={{ maxWidth: "150px", width: "100%" }}
                        disabled={disabled || reasonCount % 2 === 0 || filledCausesLength !== bottomCauses.length}
                        readonly={readonly}
                    />
                </div>
            </div>
        );

        return <div className={Styles.causesGroup}>{bottomCauses}</div>;
    }

    const renderFishBone = () => (
        <div className={Styles.causes}>
            {renderTopMainReasons()}
            <div className={[Styles.lineEffect, Styles[`${color}Border`]].join(" ")} />
            {renderBottomMainReasons()}
        </div>
    )

    if (!mainReasons) return <React.Fragment></React.Fragment>

    return (
        <FishBoneGrid>
            <div style={{ backgroundColor }} className={Styles.fishboneChart}>
                {renderFishBone()}
                {renderEffect()}
            </div>
        </FishBoneGrid>
    )
}

export default BCFishBone;