import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map, List } from "immutable";

interface IBasePermissionScope {
    code: string
}

export interface IPermissionScope extends IBasePermissionScope {
    name: IDictionary<string>,
    description: IDictionary<string>,
    children: IPermissionScope[]
}

export interface IReadonlyPermissionScope extends IBasePermissionScope {
    name: Map<string, string>,
    description: Map<string, string>,
    children: List<PermissionScope>
}

export type PermissionScope = ReadonlyRecord<IReadonlyPermissionScope>;

export const PermissionScopeFactory = Record<IReadonlyPermissionScope>({
    code: "",
    name: null,
    description: null,
    children: List()
});

export const PermissionScopeFactoryFromJS = function (data: IPermissionScope): PermissionScope {
    let _children = data && data.children ? data.children.map(d => PermissionScopeFactoryFromJS(d)) : [];
    return PermissionScopeFactory({
        code: data.code,
        name: Map(data.name),
        description: Map(data.description),
        children: List(_children)
    });
}