import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, ISalary, Salary, SalaryFactory, SalaryFactoryFromJS } from "..";

export interface ISalaryFormContent extends IBaseFormContent {
    showingSalary?: ISalary,
}

export interface IReadonlySalaryFormContent extends IBaseFormContent {
    showingSalary?: Salary
}

export type SalaryFormContent = ReadonlyRecord<IReadonlySalaryFormContent>;

export const SalaryFormContentFactory = Record<IReadonlySalaryFormContent>({
    panelId: "",
    showingSalary: SalaryFactory(),
    isModified: false,
    initialHash: "",
    showLoading: false,
    selectableListPanelisActive: false,
});

export const SalaryFormContentFactoryFromJS = function (data: ISalaryFormContent) {
    const _showingSalary : Salary = data.showingSalary ? SalaryFactoryFromJS(data.showingSalary) : SalaryFactory()

    return SalaryFormContentFactory({
        panelId: data.panelId,
        showingSalary: _showingSalary,
        editWindowisActive: data.editWindowisActive,
        selectableListPanelisActive: data.selectableListPanelisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        initialHash: data.initialHash
    });
}