import { Record } from "immutable";
import { ContentLayoutState, ContentLayoutStateFactory } from '../layouts/content-layout/entities';
import { LeftMenuState, LeftMenuStateFactory } from '../layouts/left-menu/entities';
import { TopMenuState, TopMenuStateFactory } from '../layouts/top-menu/entities';
import { RedirectState, RedirectStateFactory } from "./IRedirectState";

export interface ILayoutsState {
    contentLayout: ContentLayoutState,
    leftMenu: LeftMenuState,
    topMenu: TopMenuState,
    isMobile?: boolean,
    redirect?: RedirectState,
}

export type LayoutsState = Record<ILayoutsState> & Readonly<ILayoutsState>;

export const LayoutsStateFactory = Record<ILayoutsState>({
    contentLayout: ContentLayoutStateFactory(),
    leftMenu: LeftMenuStateFactory(),
    topMenu: TopMenuStateFactory(),
    isMobile: false,
    redirect: RedirectStateFactory()
});

export const LayoutsStateFactoryFromJS = function (data: ILayoutsState) {
    return LayoutsStateFactory(data);
}