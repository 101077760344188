import { connect } from "react-redux";
import IDmViewerProps from '../entities/IDmViewerProps';
import DmViewer from '../components';
import { getViewerItem, getViewerLoading, getViewerSkeletonType, getViewerPanelSize, getUserSelectableViewerType } from '../selectors';
import { onChangeViewerFileDataAction, onChangeViewerDataAction, onChangeUserSelectedViewerType } from '../actions';
import { BaseState } from "../../../entities/BaseState";

const mapStateToProps = function (state: BaseState, props: IDmViewerProps): IDmViewerProps {
    return {
        item: getViewerItem(state, props),
        loading: getViewerLoading(state, props),
        skeletonType: getViewerSkeletonType(state, props),
        panelSize: getViewerPanelSize(state, props),
        selectableViewerTypes: getUserSelectableViewerType(state, props)
    };
}
const mapDispatchToProps = function (dispatch: Function): IDmViewerProps {
    return {
        onChangeFile: function (payload) {
            dispatch(onChangeViewerFileDataAction(payload));
        },
        onChangeData: function (payload) {
            dispatch(onChangeViewerDataAction(payload));
        },
        onChangeUserSelectedViewerType: function(payload){
            dispatch(onChangeUserSelectedViewerType(payload));
        }
    };
}

const DmViewerContainer = connect(mapStateToProps, mapDispatchToProps)(DmViewer);
export default DmViewerContainer;