import { call } from 'redux-saga/effects';
import { IExtremeGridLoadOptions } from "../entities/api";

export default class ExtremeGridDataSource {
    loadHandler: (loadOptions: IExtremeGridLoadOptions) => void;

    *load(loadOptions: IExtremeGridLoadOptions) {
        if (this.loadHandler) {
            return yield call(this.loadHandler, loadOptions);
        }
    }

    onLoad(handler: (loadOptions: IExtremeGridLoadOptions) => void) {
        this.loadHandler = handler;
    }
}