import { all } from 'redux-saga/effects';
import watchHubConnection from './HubConnection';
import deployAgentManager from './DeployAgentListener';

export default function* rootSaga() {
    yield all([
        watchHubConnection(),
        deployAgentManager()
    ]);
}
