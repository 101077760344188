import React from "react";
import { BCButton, BCDivider, BCPopover, IButtonClickEventArgs } from '..';
import * as Styles from './assets/styles.scss';
import { IButtonBarItem, IButtonBarProps, IButtonBarState } from './entities';
import IOverflowButtonProps from './entities/IOverflowButtonProps';
import IconMore from "@bimser/icons/16/more";
import classNames from 'classnames/bind';
const cx = classNames.bind(Styles);

const ButtonBarItemWidth = 32;

const BCButtonBar: React.FC<IButtonBarProps> = (props: IButtonBarProps) => {
    const parentRef = React.useRef<HTMLDivElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const [leftItems, setLeftItems] = React.useState<IButtonBarItem[]>([]);
    const [overflowItems, setOverflowItems] = React.useState<IButtonBarItem[]>([]);
    const [popoverVisible, setPopoverVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        alignElements();
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    React.useEffect(() => {
        alignElements();
    }, [props]);

    const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setPopoverVisible(false);
        }
    }

    const alignElements = (isCtor?: boolean): IButtonBarState => {
        let _remainingArea = parentRef && parentRef.current && parentRef.current.clientWidth < props.maxWidthInPX ? parentRef.current.clientWidth : props.maxWidthInPX;
        let _lastViewedElement = (props.elementsCountToShow || props.items.length) - 1;
        let _items: IButtonBarItem[] = [];

        if (props.sortedSeparationList && props.sortedSeparationList.length > 0) {
            [...new Set(props.sortedSeparationList)].forEach(element => {
                let filteredItems = props.items.filter((e, i) => e.separationId === element);
                _items = _items.concat(filteredItems);
            });
        } else {
            _items = _items.concat(props.items);
        }

        if (_items.length != props.items.length) {
            props.items.forEach(element => {
                if (_items.findIndex(t => t == element) != -1) {
                    _items.push(element);
                }
            });
        }

        if (props.maxWidthInPX && parentRef && parentRef.current) {
            for (let index = parentRef.current.children.length - 1; index >= 0; index--) {
                const children = parentRef.current.children[index];
                if (children.className.includes("ButtonBarFarItems") && children.children.length > 0) {
                    for(const i of children.children){
                        _remainingArea -= i.clientWidth;
                    }
                    _lastViewedElement = -1;
                }
                else if (children.className.includes("ButtonBarItems") && children.children.length > 0) {
                    for (let i = 0; i < children.children.length; i++) {
                        if (_remainingArea - children.children[i].clientWidth > ButtonBarItemWidth * 2) {
                            _remainingArea -= children.children[i].clientWidth;
                            _lastViewedElement = i;
                        } else {
                            break;
                        }
                    };
                }
            }
        }

        let _leftItems = _items.filter((e, i) => i <= _lastViewedElement);
        let _overflowItems = _items.filter((e, i) => i > _lastViewedElement);

        if (!isCtor) {
            setLeftItems(_leftItems);
            setOverflowItems(_overflowItems);
        }

        return { leftItems: _leftItems, overflowItems: _overflowItems }
    }

    const renderDivider = (items: IButtonBarItem[], i: number, isVertical: boolean) => {
        if (i != 0 && items.findIndex(t => t.separationId == items[i].separationId) === i) {
            return <BCDivider key={(items[i].buttonProps.id || items[i].buttonProps.guid) + "_divider"} className={[Styles.ButtonBarDivider, isVertical ? Styles.ButtonBarVerticalDivider : ""].join(' ')} type={isVertical ? "vertical" : "horizontal"} />
        }
    }

    const renderItems = () => {
        let items = (leftItems.map((item, i) => {
            let ButtonBarIcon = cx({
                ButtonBarIcon: true,
                TextExist: item.externalData?.showText
            });
            return (
                <React.Fragment key={"renderItems" + i}>
                    {renderDivider(leftItems, i, true)}
                    <BCButton
                        {...item.buttonProps}
                        key={(item.buttonProps.id || item.buttonProps.guid) + "_buttonBar"}
                        text={item.externalData?.showText ? item.buttonProps.text : ""}
                        title={item.buttonProps.title || item.buttonProps.text}
                        className={[item.buttonProps.className, item.buttonProps.cssClass, ButtonBarIcon].join(' ')}
                        type={item.buttonProps.type || 'link'}
                    />
                </React.Fragment>
            )
        }
        ))

        if (items.length < 1) return <></>

        return (
            <div className={Styles.ButtonBarItems}>
                {items}
            </div>
        )
    }

    const onClickOverflowItem = () => {
        if (props.isPopoverAutoClose && popoverVisible) {
            setPopoverVisible(false);
        }
    }

    const renderOverflowItems = () => {
        return (<div ref={wrapperRef}>{overflowItems.map((item, i) =>
            <React.Fragment key={"renderOverflowItems" + i}>
                {renderDivider(overflowItems, i, false)}
                <div onClick={onClickOverflowItem}>
                    <BCButton
                        {...item.buttonProps}
                        key={(item.buttonProps.id || item.buttonProps.guid) + "_buttonBar"}
                        text={item.buttonProps.text || item.buttonProps.title}
                        title={item.buttonProps.title || item.buttonProps.text}
                        className={[item.buttonProps.className, item.buttonProps.cssClass].join(' ')}
                        type={item.buttonProps.type || 'link'}
                    />
                </div>
            </React.Fragment>
        )}</div>)
    }

    const renderFarItems = () => {
        let items = (props.farItems || []).map((item, i) => {
            let ButtonBarIcon = cx({
                ButtonBarIcon: true,
                TextExist: item.externalData?.showText
            });
            return (
                <React.Fragment key={"renderFarItems" + i}>
                    {renderDivider(props.farItems, i, true)}
                    <BCButton
                        {...item.buttonProps}
                        key={(item.buttonProps.id || item.buttonProps.guid) + "_buttonBar"}
                        text={item.externalData?.showText ? item.buttonProps.text : ""}
                        title={item.buttonProps.title || item.buttonProps.text}
                        className={[item.buttonProps.className, item.buttonProps.cssClass, ButtonBarIcon].join(' ')}
                        type={item.buttonProps.type || 'link'}
                    />
                </React.Fragment>
            );
        })

        if (items.length < 1) return <div className={Styles.ButtonBarFarItems} />

        return (
            <div className={Styles.ButtonBarFarItems}>
                <BCDivider key={"FarItems_divider"} className={[Styles.ButtonBarDivider, Styles.ButtonBarVerticalDivider].join(' ')} type={"vertical"} />
                {items}
            </div>
        )
    }

    const calculateWidth = () => {
        let returnWidth: number = 2000;
        if (parentRef && parentRef.current) {
            returnWidth = 0;
            for(const item of parentRef.current.children){
                returnWidth += item.clientWidth;
            }
        }
        return returnWidth;
    }

    const onClickMoreButton = (args: IButtonClickEventArgs) => {
        if (props.overflowButtonProps) {
            props.overflowButtonProps.onClick()
        }
        if (props.isPopoverAutoClose) {
            setPopoverVisible(true);
        }
    }


    let style: React.CSSProperties = {
        ...{
            '--addonButtonWidth': calculateWidth(),
            width: "var(--addonButtonWidth)"
        } as any,
        ...props.style
    }

    const visibleProps = props.isPopoverAutoClose ? { visible: popoverVisible } : {}

    return (
        <div ref={parentRef} style={style} className={props.className} >
            {renderItems()}
            {
                overflowItems.length != 0 ?
                    <BCPopover key={"Popover"}
                        content={renderOverflowItems()}
                        title={props.overflowMenuProps ? props.overflowMenuProps.title : undefined}
                        overlayClassName={[Styles.ButtonBarPopoverMenu, props.overflowMenuProps ? props.overflowMenuProps.className : ""].join(' ')}
                        style={props.overflowMenuProps ? props.overflowMenuProps.style : undefined}
                        placement={props.overflowMenuProps ? props.overflowMenuProps.placement : 'bottom'}
                        trigger={props.overflowMenuProps ? props.overflowMenuProps.trigger : 'click'}
                        {...visibleProps}
                    >
                        <BCButton key={"More_Button"}
                            cssClass={[Styles.OverflowButton, Styles.ButtonBarIcon, props.overflowButtonProps ? props.overflowButtonProps.className : ''].join(' ')}
                            style={props.overflowButtonProps ? props.overflowButtonProps.style : undefined}
                            icon={props.overflowButtonProps && props.overflowButtonProps.iconName ? props.overflowButtonProps.iconName : <IconMore />}
                            type={props.overflowButtonProps && props.overflowButtonProps.buttonType ? props.overflowButtonProps.buttonType : 'link'}
                            onClick={onClickMoreButton}
                        />
                    </BCPopover>
                    :
                    <></>
            }
            {renderFarItems()}
        </div >
    );
}

export default BCButtonBar

export { IButtonBarProps, IOverflowButtonProps };

