import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseMessageBoxButtonPayload {
    id: string,
    text: string,
}

export interface IMessageBoxButtonPayload extends IBaseMessageBoxButtonPayload {

}

export interface IReadonlyMessageBoxButtonPayload extends IBaseMessageBoxButtonPayload {
}

export type MessageBoxButtonPayload = ReadonlyRecord<IReadonlyMessageBoxButtonPayload>;

export const MessageBoxButtonPayloadFactory = Record<IReadonlyMessageBoxButtonPayload>({
    id: null,
    text: null
});

export const MessageBoxButtonPayloadFactoryFromJS = function (data: IMessageBoxButtonPayload) {
    return MessageBoxButtonPayloadFactory(data);
}