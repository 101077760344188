import { List, Record } from 'immutable';
import { ReadonlyRecord } from "@bimser/core"
import { IReadonlyToolboxItem, IToolboxItem, ToolboxItem, ToolboxItemFactoryFromJS } from './IToolboxItem';
import { IconInfo } from '@bimser/icons';

export interface IBaseToolboxGroup {
    key: string,
    name?: string,
    caption: string,
    icon?: IconInfo
}

export interface IToolboxGroup extends IBaseToolboxGroup {
    itemList?: Array<IToolboxItem>
}


export interface IReadonlyToolboxGroup extends IBaseToolboxGroup {
    itemList?: List<IReadonlyToolboxItem>
}

export type ToolboxGroup = ReadonlyRecord<IReadonlyToolboxGroup>;

export const ToolboxGroupFactory = Record<IReadonlyToolboxGroup>({
    key: null,
    icon: null,
    caption: null,
    itemList: List()
});

export const ToolboxGroupFactoryFromJS = function (data: IToolboxGroup) {
    let items: Array<ToolboxItem> = data.itemList && data.itemList.length ? data.itemList.map(function (value) {
        return ToolboxItemFactoryFromJS(value);
    }) : [];

    return ToolboxGroupFactory({
        ...data,
        itemList: List(items)
    });
} 