import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { FlowHistoryItemType, FlowHistoryRequestType, ProcessRequestActionerUserType } from "./IFlowHistory";

export interface IBaseFlowHistoryItem {
    statusIcon?: string,
    eventIcon?: string,
    approverDescription?: string,
    actionerUserType?: ProcessRequestActionerUserType,
    actionerUserDescription?: string,
    requestDate?: string,
    responseDate?: string,
    geoLocation?: string,
    itemType?: FlowHistoryItemType,
    requestType?: FlowHistoryRequestType,
    isLoadingItem?: boolean
}

export enum FlowStatus {
    start = "Başlat",
    assinged = "Atandı",
    underDevelopment = "Geliştirme Aşamasında",
    underTest = "Test Aşamasında",
    done = "Tamamlandı",
    cancelled = "İş İptal Edildi",
    finish = "İş Tamamlandı"
}

export interface IFlowHistoryItem extends IBaseFlowHistoryItem {
    description?: IDictionary<string>,
    reason?: IDictionary<string>,
    statusDescription?: IDictionary<string>;
    eventText?: IDictionary<string>;
}

export interface IReadonlyFlowHistoryItem extends IBaseFlowHistoryItem {
    description?: Map<string, string>
    reason?: Map<string, string>
    statusDescription?: Map<string, string>;
    eventText?: Map<string, string>;
}

export type FlowHistoryItem = ReadonlyRecord<IReadonlyFlowHistoryItem>;

export const FlowHistoryItemFactory = Record<IReadonlyFlowHistoryItem>({
    approverDescription: null,
    actionerUserType: null,
    actionerUserDescription: null,
    description: null,
    statusDescription: null,
    eventText: null,
    requestDate: null,
    responseDate: null,
    geoLocation: null,
    itemType: null,
    requestType: null,
    reason: null,
    statusIcon: null,
    eventIcon: null,
    isLoadingItem: false
});

export const FlowHistoryItemFactoryFromJS = function (data: IFlowHistoryItem) {
    return FlowHistoryItemFactory({
        approverDescription: data.approverDescription,
        actionerUserType: data.actionerUserType,
        actionerUserDescription: data.actionerUserDescription,
        statusIcon: data.statusIcon,
        eventIcon: data.eventIcon,
        description: Map(data.description),
        statusDescription: Map(data.statusDescription),
        eventText: Map(data.eventText),
        requestDate: data.requestDate,
        responseDate: data.responseDate,
        geoLocation: data.geoLocation,
        reason: Map(data.reason),
        itemType: data.itemType,
        requestType: data.requestType,
        isLoadingItem: data.isLoadingItem,
    })
}

export const FlowHistoryItemArrayToList = function (data: Array<IFlowHistoryItem>) {
    let _items: Array<FlowHistoryItem> = data.map((item: IFlowHistoryItem) => {
        return FlowHistoryItemFactoryFromJS(item);
    });

    return List(_items);
}