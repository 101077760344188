import React, { lazy, Suspense } from 'react';
import IChartLayoutProps from './entities/IProps';


const BCChartLazy = lazy(
    () => import(/* webpackChunkName: "chart" */ "./components")
);

function BCChart(props: IChartLayoutProps) {
    return (
        <Suspense fallback={<div></div>}>
            <BCChartLazy {...props} />
        </Suspense>
    );
}


export { BCChart, IChartLayoutProps };
export { EChartTypes, IAnimation, IChartItem, IEventTarcker, ILegend, IRawAxis, IStack, ITitle, ITooltip } from "./entities";