export default class EventEmitter {
    events: { [key: string]: Array<Function> };

    constructor() {
        this.events = {};
    }

    emit(eventName: string, data: any) {
        let promises: Array<Promise<any>> = [];
        const event = this.events[eventName];

        if (event) {
            event.forEach(fn => {
                let result = fn.call(null, data);
                if (result === false) {
                    promises.push(Promise.reject(result));
                }
                else if (result instanceof Promise) {
                    promises.push(result);
                }
                else {
                    promises.push(Promise.resolve(result));
                }
            });
        }

        return Promise.all(promises);
    }

    off(eventName?: string) {
        if (eventName) {
            delete this.events[eventName];
        } else {
            this.events = {};
        }
    }

    subscribe(eventName: string, func: Function) {
        if (!this.events[eventName]) {
            this.events[eventName] = new Array();
        }

        this.events[eventName].push(func);
        return () => {
            this.events[eventName] = this.events[eventName].filter(eventFn => func !== eventFn);
        }
    }

    hasSubscriber(eventName: string) {
        return this.events[eventName] ? this.events[eventName].length > 0 : false;
    }

    clearEvents() {
        this.events = {};
    }

}