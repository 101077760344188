import { getViewHideInfo } from "app-main/selectors/TopMenuSelector";
import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { IWebInterfaceAction } from "../../../common/entities";
import { refreshDataTransaction } from "../actions";
import StartupLayout from "../components/StartupLayout";
import IStartupLayoutCProps from "../entities/IStartupLayoutCProps";
import IStartupLayoutProps from "../entities/IStartupLayoutProps";
import getDashboardFormData from '../selectors/getDashboardFormData';
import getHasDashboard from '../selectors/getHasDashboard';
import getStartupPanelActiveStatus from "../selectors/getStartupPanelActiveStatus";


// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IStartupLayoutProps): IStartupLayoutProps {
    return {
        dashboardFormData: getDashboardFormData(state),
        hasDashboard: getHasDashboard(state),
        isPanelActive: getStartupPanelActiveStatus(state, props),
        viewHideInfo: getViewHideInfo(state),
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IStartupLayoutCProps {
    return {
        refreshData: function(data: IWebInterfaceAction) {
            dispatch(refreshDataTransaction(data));
        }
    };
}

// react - redux connection
const StartupLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(StartupLayout);

export default StartupLayoutContainer;