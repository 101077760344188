import React, { useEffect, useState } from 'react';
import * as scriptJs from 'scriptjs';
import { BCCheckbox, BCRadioButtonGroup, BCSwitch, findMessage, IRadioButtonGroupSelectedChangeEventArgs } from '../';
import BCScrollbars from "../BCScrollbars";
import * as Styles from './assets/Styles.scss';
import { IBCDigitalSignatureProps, ISetupSigningArgs } from './entities';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["digital-signature"]: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & any;
        }
    }
}

export { ICertificate, IFinalizeSigningData } from './entities';
export default function BCDigitalSignature(props: IBCDigitalSignatureProps) {

    const [compactMode, setCompactMode] = useState<boolean>(props.compactMode || false);
    const [formatSignature, setFormatSignature] = useState<number>(1);
    const [fileSignature, setFileSignature] = useState<number>(2);

    useEffect(() => {

        document.getElementsByTagName("digital-signature")[0].setAttribute("compact", (props.compactMode || false) as any);
        scriptJs.get("libs/digitalSignature/index.js", () => {
            const setupSigningArgs: ISetupSigningArgs = {
                items: [
                    { index: 1 }
                ],
                DSClientDownloadLinks: [
                    { index: 1, name: 'Windows', url: 'https://docs.bimser.net/docs/DIGITALSIGNATURE/Kurulum/Windows_installation' },
                    { index: 2, name: 'Macos', url: 'https://docs.bimser.net/docs/DIGITALSIGNATURE/Kurulum/Macos_installation' },
                    { index: 3, name: 'Linux', url: 'https://docs.bimser.net/docs/DIGITALSIGNATURE/Kurulum/Linux_ubuntu_installation' },
                    { index: 4, name: 'Debian', url: 'https://docs.bimser.net/docs/DIGITALSIGNATURE/Kurulum/Linux_debian_installation' },
                ],
                onInitializeSigning: function (cert, item) {
                    return props.onInitializeSigning(cert, item);
                },
                onFinalizeSigning: function (signingData, item) {
                    return props.onFinalizeSigning(signingData, item);
                }
            };

            (document.getElementsByTagName("digital-signature")[0] as any)?.setupSigning(setupSigningArgs);
        });

    }, []);

    function onSelectedSignatureFormatChange(args: IRadioButtonGroupSelectedChangeEventArgs) {
        setFormatSignature(args.data);
    }

    function onSelectedSignatureFileChange(args: IRadioButtonGroupSelectedChangeEventArgs) {
        setFileSignature(args.data);
    }

    function toggleCompact(args: any, value?: string | number) {
        setCompactMode(args);
        props.onCompactModeChange?.(args);
        document.getElementsByTagName("digital-signature")[0].setAttribute("compact", args);
    }

    function getDigitalSignature(element) {
        if (props.scrollbar) {
            return (
                <BCScrollbars autoHeight autoHide >
                    {element}
                </BCScrollbars>
            )
        }
        return element;
    }

    function getSignatureFormatOptions() {
        return [
            { key: "serial", value: 1, text: findMessage.get('102132') },
            { key: "parallel", value: 2, text: findMessage.get('102133') }
        ]
    }

    function getSignatureFileOptions() {
        return [
            { key: "integrated", value: 1, text: findMessage.get('102902') },
            { key: "seperate", value: 2, text: findMessage.get('102903') }
        ]
    }

    function getSignatureFormatSelect() {
        if (props.hideSignatureFormatSelect) {
            return <React.Fragment></React.Fragment>
        }

        return (
            <div slot="bottom-template">
                <div className={Styles.InputArea}>
                    <label>
                        <span>Signature Format : </span>
                        <select disabled={props.signatureFormatReadOnly}>
                            <option value="cades">CAdES</option>
                            <option value="pades">PAdES</option>
                            <option value="xades">XAdES</option>
                        </select>
                    </label>
                </div>
                {props.showSignatureOther && (
                    <>
                        <div className={Styles.InputArea}>
                            <label>
                                <span>{findMessage.get('102136')}</span>
                                <BCSwitch />
                            </label>
                        </div>
                        <div className={Styles.InputArea}>
                            <label>
                                <span>{findMessage.get('102135')}</span>
                                <BCRadioButtonGroup
                                    value={formatSignature}
                                    options={getSignatureFormatOptions()}
                                    onSelectedChange={onSelectedSignatureFormatChange}
                                    mode={"horizontal"}
                                />
                            </label>
                        </div>
                        <div className={Styles.InputArea}>
                            <label>
                                <span>{findMessage.get('102904')}</span>
                                <BCRadioButtonGroup
                                    value={fileSignature}
                                    options={getSignatureFileOptions()}
                                    onSelectedChange={onSelectedSignatureFileChange}
                                    mode={"horizontal"}
                                />
                            </label>
                        </div>
                    </>
                )}
            </div>
        )
    }

    function getCompactModeCheckbox() {
        if (props.hideCompactModeCheckBox)
            return <React.Fragment></React.Fragment>
        return <BCCheckbox label="Compact Mode" className={Styles.compactModeCheckbox} checked={compactMode} onChange={toggleCompact} />
    }

    return (
        getDigitalSignature(
            <React.Fragment>
                <digital-signature onlyQualifiedCertificates={"false"} download-link="https://www.google.com">
                    {getSignatureFormatSelect()}
                </digital-signature>
                {getCompactModeCheckbox()}
            </React.Fragment>
        )
    )
}