export default function base64ToBlobData(base64: string, content_type?: string): Blob {
    let atobString = atob(base64);
    // Use typed arrays to convert the binary data to a Blob
    let arraybuffer = new ArrayBuffer(atobString.length);
    let view = new Uint8Array(arraybuffer);
    let blob: Blob = null;

    for (var i = 0; i < atobString.length; i++) {
        view[i] = atobString.charCodeAt(i) & 0xff;
    }
    try {
        // This is the recommended method:
        blob = new Blob([arraybuffer], { type: 'application/octet-stream' });
        return blob;
    } catch (e) {
        // The BlobBuilder API has been deprecated in favour of Blob, but older
        // browsers don't know about the Blob constructor
        // IE10 also supports BlobBuilder, but since the `Blob` constructor
        //  also works, there's no need to add `MSBlobBuilder`.
        let bb = new ((window as any).WebKitBlobBuilder || (window as any).MozBlobBuilder);
        bb.append(arraybuffer);
        blob = bb.getBlob(content_type || 'application/octet-stream'); // <-- Here's the Blob
        return blob;
    }
}