import { ReadonlyRecord } from '@bimser/core';
import { List, Record } from "immutable";
import { ISummaryMessage, SummaryMessage, SummaryMessageFactoryFromJS } from './ISummaryMessage';

export interface IBaseShowSummaryMessagePayload {
    windowId: string
}

export interface IShowSummaryMessagePayload extends IBaseShowSummaryMessagePayload {
    messages: ISummaryMessage[]
}

export interface IReadonlyBaseShowSummaryMessagePayload extends IBaseShowSummaryMessagePayload {
    messages: List<SummaryMessage>
}

export type ShowSummaryMessagePayload = ReadonlyRecord<IReadonlyBaseShowSummaryMessagePayload>;

export const ShowSummaryMessagePayloadFactory = Record<IReadonlyBaseShowSummaryMessagePayload>({
    messages: List(),
    windowId: null
});

export const ShowSummaryMessagePayloadFactoryFromJS = function (data: IShowSummaryMessagePayload) {

    let messages: Array<SummaryMessage> = data.messages && data.messages.length ? data.messages.map((value) => {
        return SummaryMessageFactoryFromJS(value);
    }) : [];

    return ShowSummaryMessagePayloadFactory({
        messages: List(messages),
        windowId: data.windowId
    });
}