export const OPEN_MODAL = "MODALMANAGER.OPEN_MODAL";
export const CLOSE_MODAL_TRANSACTION = "MODALMANAGER.CLOSE_MODAL_TRANSACTION";


export const PANEL_DATA_CHANGE_REQUEST_TRANSACTION = "COMMON.PANEL_DATA_CHANGE_REQUEST_TRANSACTION";
export const PANEL_DATA_CHANGE_RESPONSE_TRANSACTION = "COMMON.PANEL_DATA_CHANGE_RESPONSE_TRANSACTION";
export const PANEL_ONCHANGE_MODIFY_STATE_ACTION = "COMMON.PANEL_ONCHANGE_MODIFY_STATE_ACTION";
export const PANEL_CLOSE_REQUEST_TRANSACTION = "COMMON.PANEL_CLOSE_REQUEST_TRANSACTION";
export const PANEL_CLOSE_RESPONSE_TRANSACTION = "COMMON.PANEL_CLOSE_RESPONSE_TRANSACTION";
export const PANEL_WILL_UNMOUNT_TRANSACTION = "COMMON.PANEL_WILL_UNMOUNT_TRANSACTION";
export const WILL_NOT_CLOSE_PINNED_FLEX_PANEL_TRANSACTION = "APP_MAIN.WILL_NOT_CLOSE_PINNED_FLEX_PANEL_TRANSACTION";
export const PANEL_ON_CHANGE_LOADING_STATUS_TRANSACTION = "COMMON.PANEL_ON_CHANGE_LOADING_STATUS_TRANSACTION";
export const PANEL_REMOVE_FROM_STORE_ACTION = "COMMON.PANEL_REMOVE_FROM_STORE_ACTION";
export const PANEL_ADD_NEW_TO_STORE_ACTION = "COMMON.PANEL_ADD_NEW_TO_STORE_ACTION";
export const PANEL_TELL_CLOSED_TO_PARENT_PANEL_ACTION = "COMMON.PANEL_TELL_CLOSED_TO_PARENT_PANEL_ACTION";
export const INITIALIZE_PANEL_MANAGER = "COMMON.INITIALIZE_PANEL_MANAGER";
export const PANEL_DATA_ONCHANGE_TRANSACTION = "COMMON.PANEL_DATA_ONCHANGE_TRANSACTION";
export const PANEL_DATA_ONCHANGE_ACTION = "COMMON.PANEL_DATA_ONCHANGE_ACTION";
export const PANEL_DATA_SAVE_TRANSACTION = "COMMON.PANEL_DATA_SAVE_TRANSACTION";
export const PANEL_LIST_SELECTED_DATA_ONCHANGE_TRANSACTION = "COMMON.PANEL_LIST_SELECTED_DATA_ONCHANGE_TRANSACTION";
export const RELATED_PANEL_DATA_TRANSFER_TRANSACTION = "COMMON.RELATED_PANEL_DATA_TRANSFER_TRANSACTION";
export const SET_CONTENT_ACTION = "COMMON.SET_CONTENT_ACTION";
export const SET_PANEL_CONTENT_ACTION = "COMMON.SET_PANEL_CONTENT_ACTION";
export const PANEL_MENU_ITEM_CLICKED = "COMMON.PANEL_MENU_ITEM_CLICKED";
export const PANEL_HARD_CLOSED_TRANSACTION = "COMMON.PANEL_HARD_CLOSED_TRANSACTION"
export const SELECTABLE_LIST_PANEL_STATUS_CHANGE = "COMMON.SELECTABLE_LIST_PANEL_STATUS_CHANGE"
export const INPUT_CLEAR_BUTTON_CLICKED_TRANSACTION = "COMMON.INPUT_CLEAR_BUTTON_CLICKED_TRANSACTION"
export const PANEL_CLOSE_NOTIFICATION_ACTION = "COMMON.PANEL_CLOSE_NOTIFICATION_ACTION";

export const OPEN_ACTIVITIES_PANEL_ACTION = "COMMON.OPEN_ACTIVITIES_PANEL_ACTION";