import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { GetTOTPRegisterResponse, BaseState, MFAType, LoginUserFactory } from '@bimser/common';
import { List } from 'immutable';


const getUserTokenSelector = (state: WebInterfaceBaseState) => state.user.token;

const getUserToken = createSelector(
    [
        getUserTokenSelector
    ],
    (token: string) => {
        return token;
    }
);


const adminToolsPermissionsSelector = (state: WebInterfaceBaseState) => state.user.adminToolsPermissions;

const getAdminToolsPermissions = createSelector(
    [
        adminToolsPermissionsSelector
    ],
    (data) => {
        return data;
    }
);

const getCanLoginAsSelector = (state: WebInterfaceBaseState) => state.user.userPermissions.canLoginAs;

const getCanLoginAs = createSelector(
    [
        getCanLoginAsSelector
    ],
    (canLoginAs: boolean) => {
        return canLoginAs;
    }
);


const _getNotificationSoundStatus = (state: WebInterfaceBaseState) => {
    let notificationSoundStatus: boolean = false;

    let user = state.user;
    if (user) {
        if (user.settingParameters) {
            if (user.settingParameters.notificationSoundStatus) {
                notificationSoundStatus = user.settingParameters.notificationSoundStatus;
            }
        }
    }

    return notificationSoundStatus
};

const getNotificationSoundStatus = createSelector(
    [
        _getNotificationSoundStatus
    ],
    (data) => {
        return data;
    }
);

const userSettingPermissionsSelector = (state: WebInterfaceBaseState) => state.user.settingPermissions;

const userPermissionsSelector = (state: WebInterfaceBaseState) => state.system.language.canChangeLanguage;

const canChangeLocation = createSelector(
    [
        userSettingPermissionsSelector
    ],
    (data) => {
        return data.canChangeLocation;
    }
);

const canChangeLanguage = createSelector(
    [
        userPermissionsSelector
    ],
    (data: boolean) => {
        return data;
    }
);


const isDelegationSelector = (state: WebInterfaceBaseState) => state.user.isDelegation

const getIsDelegation = createSelector(
    [
        isDelegationSelector
    ],
    (isDelegation: boolean) => {
        return isDelegation;
    }
);

const getMFATypesSelector = (state: BaseState) => state.user.MFAProperties.mfaTypes;

const getMFAEnabledSelector = (state: BaseState) => state.user.MFAProperties.mfaEnabled;

const getMFALoadingSelector = (state: BaseState) => state.user.MFAProperties.mfaLoading;

const getAuthenticatorEnabledSelector = (state: BaseState) => state.user.MFAProperties.authenticatorEnabled;

const getTOTPRegisterPropertiesSelector = (state: BaseState) => state.user.MFAProperties.totpRegisterProperties;

const getRecoveryCodesSelector = (state: BaseState) => state.user.MFAProperties.recoveryCodes;

const getMFATpyes = createSelector(
    [
        getMFATypesSelector
    ],
    (mfaTypes: List<MFAType>) => mfaTypes.toJS()
);

const getMFAEnabled = createSelector(
    [
        getMFAEnabledSelector
    ],
    (mfaEnabled: boolean) => mfaEnabled
);

const getMFALoading = createSelector(
    [
        getMFALoadingSelector
    ],
    (mfaLoading: boolean) => mfaLoading
);

const getAuthenticatorEnabled = createSelector(
    [
        getAuthenticatorEnabledSelector
    ],
    (authenticatorEnabled: boolean) => authenticatorEnabled
);

const getTOTPRegisterProperties = createSelector(
    [
        getTOTPRegisterPropertiesSelector
    ],
    (TOTPRegisterProperties: GetTOTPRegisterResponse) => TOTPRegisterProperties.toJS()
);

const getRecoveryCodes = createSelector(
    [
        getRecoveryCodesSelector
    ],
    (recoveryCodes: List<string>) => recoveryCodes.toJS()
);

const getUserDataSelector = (state: WebInterfaceBaseState) => state.user.data;

const getUserData = createSelector(
    [
        getUserDataSelector
    ],
    (data) => {
        return data ? data.toJS() : LoginUserFactory().toJS();
    }
);

const getUserProfileImageSelector = (state: WebInterfaceBaseState) => state.user.data ? state.user.data.profileImage : "";

const getUserProfileImage = createSelector(
    [
        getUserProfileImageSelector
    ],
    (image) => {
        return image;
    }
);


const getUserMenuVisibilitySelector = (state: WebInterfaceBaseState) => state.user.userMenuVisibility;

const getUserMenuVisibility = createSelector(
    [
        getUserMenuVisibilitySelector
    ],
    (value) => {
        return value;
    }
);


const getAdminToolsVisibilitySelector = (state: WebInterfaceBaseState) => state.user.adminToolsVisibility;

const getAdminToolsVisibility = createSelector(
    [
        getAdminToolsVisibilitySelector
    ],
    (value) => {
        return value;
    }
);


const getLoginAsUserSelector = (state: WebInterfaceBaseState) => state.user.loginAsUser;

const getLoginAsUser = createSelector(
    [
        getLoginAsUserSelector
    ],
    (loginAsUser: any) => {
        return loginAsUser;
    }
);


export {
    getUserToken, getIsDelegation, getAdminToolsPermissions, getNotificationSoundStatus,
    canChangeLanguage, canChangeLocation, getTOTPRegisterProperties, getMFAEnabled, getMFATpyes,
    getAuthenticatorEnabled, getRecoveryCodes, getMFALoading, getUserData, getUserProfileImage,
    getCanLoginAs, getLoginAsUser, getAdminToolsVisibility, getUserMenuVisibility
};