import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IDepartmentListFormProps } from './entities';
import { IBaseListFormProps } from '../../../common/base/components/listForm/entities';


const DepartmentListContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function DepartmentListContainer(props: React.PropsWithChildren<IDepartmentListFormProps & IBaseListFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <DepartmentListContainerLazy {...props} />
        </Suspense>
    );
}
DepartmentListContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default DepartmentListContainer;