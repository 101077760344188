import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";

export interface IBaseWorkflowItem {
    key?: string,
    projectId: string,
    projectName: string,
    count: number,
    flowName: string,
    flowId?: string,
    approverForm: string,
}

export interface IWorkflowItem extends IBaseWorkflowItem {
    projectCaption: IDictionary<string>,
    flowCaption: IDictionary<string>,
    parameters?: IDictionary<any>
}

export interface IReadonlyWorkflowItem extends IBaseWorkflowItem {
    projectCaption: Map<string, string>,
    flowCaption: Map<string, string>,
    parameters?: Map<string, any>
}

export type WorkflowItem = ReadonlyRecord<IReadonlyWorkflowItem>;

export const WorkflowItemFactory = Record<IReadonlyWorkflowItem>({
    key: null,
    projectId: null,
    projectName: null,
    count: 0,
    flowName: null,
    flowId: null,
    approverForm: null,
    projectCaption: Map(),
    flowCaption: Map(),
    parameters: Map()
});

export const WorkflowItemFactoryFromJS = function (data: IWorkflowItem) {
    return WorkflowItemFactory({
        key: `${data.projectId}_${data.flowName || data.parameters["formName"]}`,
        projectId: data.projectId,
        projectName: data.projectName,
        count: data.count,
        flowName: data.flowName,
        flowId: data.flowId,
        approverForm: data.approverForm,
        projectCaption: Map(data.projectCaption),
        flowCaption: Map(data.flowCaption),
        parameters: Map(data.parameters)
    });
}