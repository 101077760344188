import React, { useEffect, useState } from 'react';

function useClickAndDoubleClick(args: IClickEventArgs) {
    const [click, setClick] = useState(0);
    const _timeOutDuration = args.delay ? args.delay : 300
    useEffect(() => {
        const timer = setTimeout(() => {
            if (click === 1 && args.clickF && typeof args.clickF === "function"){
                args.clickF(args.params);
            }
            setClick(0);
        }, _timeOutDuration);

        if (click === 2 && args.doubleClickF && typeof args.doubleClickF === "function" ){
            args.doubleClickF(args.params);
        }

        return () => {
            clearTimeout(timer);
        }
    }, [click]);

    return (e: React.MouseEvent) => {
        e.stopPropagation();
        setClick(prev => prev + 1);
    };
}

export default useClickAndDoubleClick;

interface IClickEventArgs {
    params: any,
    clickF?: Function,
    doubleClickF?: Function,
    delay?: number
}