import { IBaseAction } from "@bimser/core";
import { fromJS, Map } from "immutable";
import * as ActionTypes from "../action/actionTypes";

export default function (state: Map<string, Map<string, string>>, action: IBaseAction<any>): Map<string, Map<string, string>> {
    if (!action.payload)
        return state;

    if (action.type === ActionTypes.ON_LOAD_MESSAGES) {
        return fromJS(action.payload)
    } else {
        return state
    }
}