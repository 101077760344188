import { panelType } from '@bimser/common';
import { BCCard, BCScrollbars, BCSkeleton, ContentType, findMessage } from '@bimser/components';
import React from 'react';
import Style from '../../assets/style.scss';
import IRecentAppsProps from '../../entities/widgets/IRecentAppsProps';
import { IMenuItem } from '../../../../common/entities/IMenuItem';
import { MLHelper } from '@bimser/core';
import ColorHash from 'color-hash';
import GetIcon32ByName from "@bimser/icons/lib/getIcon32ByName";
import IconEmpty from "@bimser/icons/24/empty-icon";

const cx = require('classnames/bind').bind(Style);
const ch = new ColorHash();

export default (props: IRecentAppsProps) => {

    const onClickApp = (recentApp: IMenuItem) => {
        props.onClickApp({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: recentApp
        })
    }

    const renderIcon = (item: IMenuItem) => {
        const icon = item.icon || item.parameters?.icon;
        
        if(icon) {
            const Icon = GetIcon32ByName(icon, IconEmpty);

            return <Icon width={"32px"} height={"32px"} />
        }

        const color = item?.parameters?.itemColor || ch.hex(item.id);
        const caption = MLHelper.getMLText(item.caption) || findMessage.get("100017");
        const words = (
            caption
                .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
                .replace(/  +/g, ' ')
                .trim()
                .split(' ')
                .slice(0, 2)
                .map((each) => each.charAt(0).toUpperCase())
                .join("")
        );

        return <span className={cx({ recentAppIcon: true, customAvatar: true })} style={{ backgroundColor: color }}>{words}</span>
    }
    
    return (props.data?.length || (!props.data.length && props.loading)) ? (
        <div className={Style.recentAppsContainer}>
            <BCScrollbars mountScrollPosition={'bottom'} autoHide>
                <span className={Style.widgetHeader}>{findMessage.get("102974")}</span>
                <BCSkeleton
                    loadingStatus={props.loading}
                    contentType={ContentType.DashboardRecentAppsList}
                >
                    <div className={Style.recentAppCardList}>
                        {Object.entries(props.data).map(([id, value]) => (
                            <span onClick={() => onClickApp(value)} key={id}>
                                <BCCard
                                    key={id}
                                    bordered={false}
                                    className={Style.recentAppCard}
                                >
                                    {renderIcon(value)}
                                    <span className={Style.recentAppCaption}>{MLHelper.getMLText(value.caption)}</span>
                                </BCCard>
                            </span>
                        ))}
                    </div>
                </BCSkeleton>
            </BCScrollbars>
        </div>
    ) : <></>
}