import { IExtremeGridState, IFilterItem } from "../entities";
import { IBaseMutableAction } from "@bimser/core";
const _isEqual = require("lodash/isEqual");

export default function changeFilteringReducer(state: IExtremeGridState, action: IBaseMutableAction<IFilterItem>): IExtremeGridState {

    return {
        ...state,
        columns: state.columns.map(column => {
            if (column.name == action.payload.columnName
                && (column.filtering?.value != action.payload?.value || !_isEqual(column.filtering?.values, action.payload.values)
                    || column.filtering?.selectedOperation != action.payload?.operation)) {
                return {
                    ...column,
                    filtering: {
                        ...column.filtering,
                        value: action.payload.value,
                        selectedOperation: action.payload.operation,
                        values: action.payload.values,
                        filterType:action.payload.filterType
                    }
                };
            }
            return column;
        })
    };
}