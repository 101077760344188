import { EDeviceTypes, FormItemTypes } from "@bimser/core";
import { LayoutStructureItem } from "BCLayoutCreator/common/entities";
import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { ActionMenu } from "./ActionMenu";
import * as Styles from "./assets/styles.scss";
import Column from "./Column";
import DropZone from "./DropZone";
import { ISection } from "./entities";
import { ELayoutItemTypes } from "./entities/Constants";
import { intersectRect } from "./helpers";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const Section = (args: ISection) => {
  const { data, handleDrop, path, getItem, onStructureChange, setContextMenuItems, onItemSelectionChanged, isParentLocked, selectionBounds, controlRenderManager, deviceType, parentItemType } = args;
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ELayoutItemTypes.SECTION,
      children: data.items,
      path,
      data
    },
    canDrag: !data.designerProps.locked && !isParentLocked,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(ref);

  const renderColumn = (column: LayoutStructureItem, currentPath: string) => {
    return (
      <Column
        key={column.id}
        data={column}
        handleDrop={handleDrop}
        path={currentPath}
        getItem={getItem}
        onStructureChange={onStructureChange}
        setContextMenuItems={setContextMenuItems}
        onItemSelectionChanged={onItemSelectionChanged}
        isParentLocked={data.designerProps.locked || isParentLocked}
        setWillDragStart={setWillDragStart}
        willDragStart={willDragStart}
        selectionBounds={selectionBounds}
        controlRenderManager={controlRenderManager}
        deviceType={deviceType}
        parentItemType={parentItemType}
      />
    );
  };

  const { layout, selected, locked } = data.designerProps;

  const sectionClassNames: string = cx({
    base: true,
    draggable: true,
    section: true,
    isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup,
    selected,
    locked: locked || isParentLocked,
    isDragging,
    hidden: !(layout.get("visible") && layout.get("clientVisible")),
    empty: !data.items.count()
  });

  const renderEmptySectionContent = () => {
    return (
      <DropZone
        path={`${path}-${data.items.count()}`}
        onDrop={handleDrop}
        className={Styles.fullHeight}
        dropType={FormItemTypes.EContainerTypes.Column}
        parent={data}
      />
    )
  };

  const columnsClassNames: string = cx({
    columns: true,
    removePadding: parentItemType === FormItemTypes.EControlTypes.InputGroup,
    extraSmall: deviceType === EDeviceTypes.ExtraSmall
  });

  const renderColumns = () => {

    const dropZoneClassNames: string = cx({
      horizontalDrag: deviceType !== EDeviceTypes.ExtraSmall
    });

    return (
      <div className={columnsClassNames}>
        {data.items.map((itemId, index) => {

          const item = getItem(itemId as string);
          const currentPath = `${path}-${index}`;

          if (item.type === FormItemTypes.EContainerTypes.Column) {
            return (
              <React.Fragment key={itemId as string}>
                <DropZone
                  path={currentPath}
                  onDrop={handleDrop}
                  className={dropZoneClassNames}
                  dropType={FormItemTypes.EContainerTypes.Column}
                  parent={data}
                />
                {renderColumn(item, currentPath)}
              </React.Fragment>
            );
          }

        })}
        <DropZone
          path={`${path}-${data.items.count()}`}
          onDrop={handleDrop}
          className={dropZoneClassNames}
          dropType={FormItemTypes.EContainerTypes.Column}
          parent={data}
          isLast
        />
      </div>
    )
  };

  const onSectionSelect = (e: React.MouseEvent) => onItemSelectionChanged(e, data);

  useEffect(() => {
    if (selectionBounds) {
      let _selected = ref?.current ? intersectRect(selectionBounds, ref.current.getBoundingClientRect()) : false;
      onItemSelectionChanged(null, data, true, _selected);
    }
  });

  const handleContextMenuClick = (e: React.MouseEvent) => {
    setContextMenuItems(e, data, path);
    e.stopPropagation();
  }

  const [willDragStart, setWillDragStart] = React.useState<boolean>(false);
  const onMouseDown = (e) => {
    const containerElem = document.getElementById(data.id);
    const insideActionBtn = containerElem?.contains(e.target);
    setWillDragStart(!insideActionBtn);
  };
  const onEnd = () => setWillDragStart(false);

  return (
    <section
      ref={ref}
      className={sectionClassNames}
      onMouseDown={onMouseDown}
      onDragEnd={onEnd}
      onMouseUp={onEnd}
      onContextMenu={handleContextMenuClick}
      onClick={onSectionSelect}
    >
      {!willDragStart && parentItemType !== FormItemTypes.EControlTypes.InputGroup && <ActionMenu data={data} onStructureChange={onStructureChange} path={path} />}
      {data.items.count() ? renderColumns() : renderEmptySectionContent()}
    </section>
  );
};
export default React.memo(Section);
