import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { panelType } from './enums';


export interface IBaseWebInterfaceAction<T = any> {
    panelId?: string,
    panelType?: panelType,
    data?: T
}

export interface IWebInterfaceAction<T = any> extends IBaseWebInterfaceAction<T> { }

export interface IReadonlyWebInterfaceAction<T = any> extends IBaseWebInterfaceAction<T> { }

export type WebInterfaceAction<T = any> = ReadonlyRecord<IReadonlyWebInterfaceAction<T>>;

export const WebInterfaceActionFactory = Record<IReadonlyWebInterfaceAction>({
    panelId: '',
    panelType: null,
    data: null
});

export const WebInterfaceActionFactoryFromJS = function <T = any,>(data: IWebInterfaceAction<T>) {
    return WebInterfaceActionFactory(data) as Record<IReadonlyWebInterfaceAction<T>> & Readonly<IReadonlyWebInterfaceAction<T>>;
}