import AllAppsContainer from "app-main/layouts/left-menu/AllApps";
import AppFormContainer from 'common/components/appForm';
import PanelMenuContainer from 'common/components/panelMenu/containers';
import SelectionListContainer from 'common/components/selectionList/containers';
import AnnouncementFormContainer from "modules/announcements/AnnouncementForm";
import AnnouncementsListContainer from "modules/announcements/AnnouncementsList";
import ConfigurationManagerPanelContainer from 'modules/configurationManager/components/panel';
import DMComparisonContainer from "modules/dm/components/comparison";
import DMDashboardContainer from "modules/dm/components/dashboard";
import DMExplorerContainer from "modules/dm/components/explorer";
import {
    companyAdminListComponent, companyFormComponent, companyListComponent, departmentFormComponent,
    departmentListComponent, departmentMaintenanceFormComponent, departmentMaintenanceListComponent,
    departmentPropertiesFormComponent, HrMenuComponent, managerKeyFormComponent, managerKeyListComponent, overtimeComponent, overtimeListComponent, positionFormComponent, positionListComponent, positionMaintenanceFormComponent, positionMaintenanceListComponent,
    positionPropertiesFormComponent, professionFormComponent, professionListComponent, professionMaintenanceFormComponent,
    professionMaintenanceListComponent, professionPropertiesFormComponent, propertiesFormComponent, propertyTypeFormComponent, salaryComponent, salaryListComponent, shiftComponent, shiftListComponent, userFormComponent, userGroupFormComponent, userGroupListComponent, userGroupPropertiesFormComponent, userListComponent,
    userMaintenanceFormComponent, userMaintenanceListComponent, userPropertiesFormComponent
} from 'modules/hr/containers';
import LegacyFrameContaier from "modules/legacyFrame/containers";
import LogViewerContainer from "modules/logviewer";
import SecurityContainer from "modules/security";
import StartupLayoutContainer from "modules/startup/containers/StartupLayoutContainer";
import flowViewerContainer from 'modules/workflow/components/flowViewer';
import FlowHistoryContainer from "modules/workflow/components/flowViewer/components/flowHistory";
import FlowSchemaContainer from "modules/workflow/components/flowViewer/components/FlowSchema";
import WorkflowFrame from "modules/workflow/components/workflowFrame";
import ProfileFormContainer from '../modules/dm/components/explorer/components/profileForm/containers';
import WFListContainer from '../modules/workflow/components/wfList';
import WFManagementContainer from '../modules/workflow/components/wfManagement';
import WFProcessListContainer from '../modules/workflow/components/wfProcessList';
import IPanelComponentList from './entities/IPanelComponentList';
import ViewerContainer from '../modules/dm/components/viewer';
import ContentListContainer from '../modules/dm/components/viewer/components/ContentList';
import RelationListContainer from '../modules/dm/components/viewer/components/RelationList';
import HumanResourceMainContainer from "../modules/humanResource/components/main";
import IndexReportContainer from "../modules/indexReport";
import ActivitiesContainer from "../modules/activities";
import TaskManagementContainer from "modules/taskManagement";
import DataSecurityContainer from "modules/dataSecurity";
import DataTypesContainer from "modules/dataSecurity/dataTypes";

import ActionManagementContainer from "../modules/action/actionManagement";
import ActionMenuContainer from "../modules/action/actionMenu";
import ActionViewerContainer from "../modules/action/actionViewer";
import DashboardContainer from "../modules/dashboard/containers";

const components: IPanelComponentList = {
    HR0: HrMenuComponent,
    HR1: userListComponent,
    HR2: userFormComponent,
    HR3: companyListComponent,
    HR4: companyFormComponent,
    HR5: positionListComponent,
    HR6: departmentListComponent,
    HR7: professionListComponent,
    HR8: positionFormComponent,
    HR9: departmentFormComponent,
    HR10: professionFormComponent,
    HR11: userMaintenanceListComponent,
    HR12: userMaintenanceFormComponent,
    HR13: departmentMaintenanceListComponent,
    HR14: departmentMaintenanceFormComponent,
    HR15: positionMaintenanceListComponent,
    HR16: positionMaintenanceFormComponent,
    HR17: professionMaintenanceListComponent,
    HR18: professionMaintenanceFormComponent,
    HR19: propertiesFormComponent,
    HR20: userPropertiesFormComponent,
    HR21: positionPropertiesFormComponent,
    HR22: professionPropertiesFormComponent,
    HR23: departmentPropertiesFormComponent,
    HR24: userGroupPropertiesFormComponent,
    HR25: propertyTypeFormComponent,
    HR26: userGroupFormComponent,
    HR27: userGroupListComponent,
    HR28: companyAdminListComponent,
    HR29: managerKeyListComponent,
    HR30: managerKeyFormComponent,
    HR31: shiftListComponent,
    HR32: shiftComponent,
    HR33: overtimeComponent,
    HR34: overtimeListComponent,
    HR35: salaryComponent,
    HR36: salaryListComponent,
    STARTUP: DashboardContainer,
    DEPRECATED_STARTUP: StartupLayoutContainer,
    ANNOUNCEMENTS: AnnouncementsListContainer,
    ANNOUNCEMENT_FORM: AnnouncementFormContainer,
    WORKFLOW_FRAME: WorkflowFrame,
    FLOW_VIEWER: flowViewerContainer,
    FLOW_SCHEMA: FlowSchemaContainer,
    FLOW_HISTORY: FlowHistoryContainer,
    LEGACY_FRAME: LegacyFrameContaier,
    DM_EXPLORER: DMExplorerContainer,
    CONFIGURATION_MANAGER_PANEL: ConfigurationManagerPanelContainer,
    PANEL_MENU: PanelMenuContainer,
    EBA_APPLICATION_FORM: AppFormContainer,
    OPEN_SELECTION_FORM: SelectionListContainer,
    DM_DASHBOARD: DMDashboardContainer,
    DM_COMPARISON: DMComparisonContainer,
    SECURITY: SecurityContainer,
    ALL_APPS: AllAppsContainer,
    PROFILE_FORM: ProfileFormContainer,
    LOG_VIEWER: LogViewerContainer,
    WF_MANAGEMENT: WFManagementContainer,
    WF_List: WFListContainer,
    WF_ProcessList: WFProcessListContainer,
    DM_Viewer: ViewerContainer,
    DM_ContentList: ContentListContainer,
    DM_RelationList: RelationListContainer,
    HR_MAIN: HumanResourceMainContainer,
    Index_Report: IndexReportContainer,
    Activities: ActivitiesContainer,
    Task_Management: TaskManagementContainer,
    DS_MAIN: DataSecurityContainer,
    DS_DataTypes: DataTypesContainer,
    Action_Management: ActionManagementContainer,
    Action_Menu: ActionMenuContainer,
    Action_Viewer: ActionViewerContainer
};

export default components;