import * as React from 'react';
import { IPanelMenuItemCProps, IPanelMenuItemProps, IPanelMenuItemState } from '../entities';
import { BCBadge, BCButton } from '@bimser/components';
import { createGuid } from "@bimser/core";
import * as Styles from '../assets/style.scss';
import { childrenDisplayMode } from '../entities/enums';
import { IMenuItemEvent } from '../../../entities/IMenuItemEvent';
import { MLSelectorContainer } from '@bimser/common';
import IconEmpty from "@bimser/icons/24/empty-icon";
import IconMenupopArrow from "@bimser/icons/16/menupop-arrow";
import IconSubPanel from "@bimser/icons/16/sub-panel";
import GetIcon24ByName from "@bimser/icons/lib/getIcon24ByName";
import GetIconByName from '@bimser/icons/lib/getIcon16ByName';
let classNames = require('classnames/bind');
let cx = classNames.bind(Styles);


export default class PanelMenuItem extends React.Component<IPanelMenuItemCProps & IPanelMenuItemProps, IPanelMenuItemState>{
    IconStyles: React.CSSProperties = {
        verticalAlign: 'inherit',
        lineHeight: '100%'
    }
    badgeStyle: React.CSSProperties = {
        marginLeft: "5px",
    }
    constructor(props: IPanelMenuItemCProps & IPanelMenuItemProps) {
        super(props);
    }

    private onClickEventButton(button: IMenuItemEvent) {
        this.props.fireMenuItemEvent({
            data: button
        });
    }

    renderEvents(): React.ReactNode {
        if (!this.props.item.events) return null;

        let buttons: Array<IMenuItemEvent> = this.props.item.events;

        if (buttons && buttons.length > 0) {
            if (buttons.length > 2) {
                // TODO: dropdown buttons olcak.
            } else {
                return (
                    <ul>
                        {
                            buttons.map((button, index) => {
                                return (
                                    <li key={createGuid()}>
                                        <BCButton text={button.text} cssClass={button.classNames} type={'primary'} onClick={(e) => { e.event.stopPropagation(); this.onClickEventButton(button); }} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                )
            }
        }
    }

    renderIcon() {
        let { item } = this.props;
        if (item.icon) {
            const Icon = GetIcon24ByName(item.icon, IconEmpty);

            return (
                <Icon style={this.IconStyles} />
            )
        } else if (item.parameters && item.parameters.icon) {
            const Icon = GetIconByName(item.parameters.icon, IconEmpty);

            return (
                <Icon style={this.IconStyles} />
            )
        }
    }

    renderBadge() {
        if (!this.props.item.badge) return null;

        return (
            <BCBadge {...this.props.item.badge} style={this.badgeStyle} />
        )
    }

    renderText() {
        let textClassNames = cx({
            text: true
        })
        if (this.props.item.text) {
            return (
                <div className={[textClassNames, "text"].join(" ")}>
                    {this.props.item.text}
                </div>
            )
        }

        return (
            <div className={[textClassNames, "text"].join(" ")}>
                <MLSelectorContainer data={this.props.item.caption} />
            </div>
        )
    }

    renderSubChildIcon() {
        let hasChildren: boolean = this.props.item.children && this.props.item.children.length > 0;
        let willDisplay: boolean = this.props.item.childrenDisplayMode === childrenDisplayMode.SubChild || this.props.item.childrenDisplayMode === childrenDisplayMode.Collapse;

        if (willDisplay && hasChildren) {
            let subChildIconClassNames = cx({
                subChildIcon: this.props.item.childrenDisplayMode === childrenDisplayMode.SubChild,
                expanded: this.props.item.isActive,
                arrow: true
            });

            const Icon = this.props.item.isActive ? IconMenupopArrow : IconSubPanel;
            return <div className={[subChildIconClassNames, "subChildIconArea"].join(" ")}>
                <Icon className="subChildFontIcon" />
            </div>
        }

        return <div className={"subChildIconArea"}></div>
    }

    generateForm() {
        return (
            <React.Fragment>
                {this.renderSubChildIcon()}
                {this.renderIcon()}
                {this.renderText()}
                {this.renderBadge()}
                {this.renderEvents()}
            </React.Fragment>
        )
    }

    generateTemplate() {
        return (
            <React.Fragment>
                {
                    (this.generateForm())
                }
            </React.Fragment>
        )
    }

    render() {
        return this.generateTemplate();
    }
}