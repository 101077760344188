import { connect } from 'react-redux';
import { BaseState } from '../../../entities/BaseState';
import { clearData, goUp, LoadAction, onSearchTextChanged, PathClickAction, setSelection, UpdateOpenedStatus } from '../actions';
import { DMExplorer, DMExplorerProps } from '../components';
import { NavigationHistoryItem } from '../entities';
import { INavigationItem } from '../entities/INavigationItem';
import { ILoadPayload } from '../entities/LoadPayload';
import getCurrentFilterSelector from '../selectors/getCurrentFilterSelector';
import getCurrentItemSelector from '../selectors/getCurrentItem';
import getHistoryItems from '../selectors/getHistoryItems';
import { getItemsCount } from '../selectors/getItemsCountSelector';
import { getDMExplorerItems } from '../selectors/getItemsSelector';
import getPaginationSelector from '../selectors/getPaginationSelector';
import getPathSelector from '../selectors/getPathSelector';
import getPropsFilterSelector from '../selectors/getPropsFilterSelector';
const mapStateToProps = function (state: BaseState, props: DMExplorerProps): DMExplorerProps {
    return {
        path: getPathSelector(state),
        historyItems: getHistoryItems(state),
        items: getDMExplorerItems(state),
        pageTotal: getItemsCount(state),
        currentItem: getCurrentItemSelector(state),
        currentPagination: getPaginationSelector(state),
        currentFilter: getCurrentFilterSelector(state),
        propsPagination: props.propsPagination,
        propsFilter: getPropsFilterSelector(state, props),
        isLoading: state.DMExplorer.isLoading,
        isOpened: state.DMExplorer.isOpened,
        selectedItem: state.DMExplorer.selectedItem,
        canGoBack: state.DMExplorer.navigationHistory.size > 0
    }
}

const mapDispatchToProps = function (dispatch: Function): DMExplorerProps {
    return {
        Load: (data: ILoadPayload) => {
            dispatch(LoadAction(data));
        },
        Opened: (data: boolean) => {
            dispatch(UpdateOpenedStatus(data));
        },
        setSelection: (data: INavigationItem) => {
            dispatch(setSelection(data));
        },
        goUp: () => {
            dispatch(goUp());
        },
        onSearchTextChanged: (text: string) => {
            dispatch(onSearchTextChanged(text));
        },
        clearData: () => {
            dispatch(clearData());
        },
        onPathClick: (data: NavigationHistoryItem) => {
            dispatch(PathClickAction(data));
        }
    }
}

const DMExplorerContainer = connect(mapStateToProps, mapDispatchToProps)(DMExplorer);
export default DMExplorerContainer;