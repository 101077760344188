import { IMenuItem } from "../../../../common/entities/IMenuItem";
import { IMenuDesignerItem } from "../../../../common/entities/IMenuDesignerItem";
import { findMessage } from "@bimser/components";
import { IDictionary, toLowerCase, createGuid, MLHelper } from "@bimser/core";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

export function findMenuItemWithParameter(items: IMenuItem[], parameter: string, value: string): IMenuItem {
    let item = null;
    items.forEach(i => {
        if (_get(i, ["parameters", parameter]) == value) {
            item = i;
        }
        if (!item && i.children) {
            item = findMenuItemWithParameter(i.children, parameter, value);
        }
    });
    return item;
}

export function menuItemToDesigner(item: IMenuItem): IMenuDesignerItem {
    let _children: IDictionary<IMenuDesignerItem> = {}
    if (item.children.length) {
        item.children.forEach(_item => {
            _children[_item.id] = menuItemToDesigner(_item)
        })
    }
    return {
        ...item,
        children: _children
    }
}

export function menuDesignerItemToMenuItem(item: IMenuDesignerItem): IMenuItem {
    let _children: IMenuItem[] = [];
    if (Object.keys(item.children).length) {
        _children = Object.values(item.children).map(w => {
            return menuDesignerItemToMenuItem(w);
        });
    }
    return {
        ...item,
        children: _children
    }
}

export function menuItemArrayToDesignerDictinary(items: IMenuItem[]): IDictionary<IMenuDesignerItem> {
    let _result: IDictionary<IMenuDesignerItem> = {};
    items.forEach(_item => {
        _result[_item.id] = menuItemToDesigner(_item);
    });
    return _result;
}

export function menuItemDesignerDictinaryToArray(items: IDictionary<IMenuDesignerItem>): IMenuItem[] {
    return Object.values(items).map(item => {
        return menuDesignerItemToMenuItem(item);
    })
}

export function mapArrToMap(arr: IMenuItem[]) {
    const map: Map<string, IMenuItem[]> = new Map();
    var Regx = /^[A-Za-z_ğüşıöçĞÜŞİÖÇ]$/;
    arr.forEach((item) => {
        let mlCaption = MLHelper.getMLText(item.caption).trim()
        let firstChar = mlCaption[0];
        if (!Regx.test(toLowerCase(firstChar))) {
            firstChar = '#'
        } else {
            firstChar = firstChar.toLocaleUpperCase();
        }
        if (map.get(firstChar) == null) {
            map.set(firstChar, [item]);
        } else {
            const arr = map.get(firstChar);
            arr.push(item);
            map.set(firstChar, arr)
        }
    });
    let mapAsc = new Map([...map.entries()].sort((a, b) => a[0].localeCompare(b[0])));
    const mapToList = (map: Map<string, IMenuItem[]>) => {
        let result: IMenuItem[] = [];
        map.forEach((value: IMenuItem[], key: string) => {
            result.push({
                text: key,
                data: "isDisableTitle",
            });
            result.push(...value);
        });
        return result;
    }
    return mapToList(mapAsc);
}

export function filterDesignerItems(items: IMenuItem[], depthIndex: number = 0, parentItem?: IMenuItem) {
    let _items = [...items];
    _items = _items.map((item: any) => {
        item = updateDesignerItemsParams(item, depthIndex, parentItem);
        if (item.children && item.children.length) {
            item.children == filterDesignerItems(item.children, depthIndex + 1, item);
        }
        return item;
    });
    return _items;
}

export function updateDesignerItemsParams(item: IMenuItem, depthIndex: number, parentItem: IMenuItem) {
    let guid = item.id || createGuid();
    item.isBaseItem = false;
    item.id = guid;
    if (depthIndex == 0) {
        item.level = 0;
        item.path = [guid];
    } else {
        item.level = parentItem.level + 1;
        item.path = [...parentItem.path, guid];
    }
    return item;
}

export function sortItemChildren(items: IMenuItem[], includeOrderIndex: boolean = true) {
    let orderIndexMethod = (item: IMenuItem) => item.parameters?.orderIndex ? parseInt(item.parameters.orderIndex) : undefined
    let alphabeticMethod = (item: IMenuItem) => {
        let key = findMessage.get("100017");

        if (!_isEmpty(item.caption))
            key = MLHelper.getMLText(item.caption);
        else if (item.text)
            key = item.text;

        return key.trim().toLocaleUpperCase();
    }

    let sortBy = (_items: IMenuItem[]) => {
        return _items.sort((first: IMenuItem, after: IMenuItem) => {
            let firstKey = alphabeticMethod(first);
            let afterKey = alphabeticMethod(after);

            if (includeOrderIndex) {
                let firstOrder = orderIndexMethod(first);
                let afterOrder = orderIndexMethod(after);

                return firstOrder - afterOrder || firstKey.localeCompare(afterKey);
            }

            return firstKey.localeCompare(afterKey);
        })
    }

    let _items = items.map(item => {
        if (item.children?.length) {
            item.children = sortItemChildren(item.children);
        }

        return item;
    })

    return sortBy(_items);
}