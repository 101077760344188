import ICodeEditorBuiltinLanguage from './ICodeEditorBuiltinLanguage';
import CodeEditorBuiltinLanguage from './CodeEditorBuiltinLanguage';

const CodeEditorBuiltinLanguages: Array<CodeEditorBuiltinLanguage> = [
    {
        id: "plaintext",
        extensions: [".txt", ".gitignore"]
    },
    {
        id: "typescript",
        extensions: [".ts", ".tsx"]
    },
    {
        id: "javascript",
        extensions: [".js", ".es6", ".jsx"]
    },
    {
        id: "json",
        extensions: [".json", ".bowerrc", ".jshintrc", ".jscsrc", ".eslintrc", ".babelrc"]
    },
    {
        id: "bat",
        extensions: [".bat", ".cmd"]
    },
    {
        id: "coffeescript",
        extensions: [".coffee"]
    },
    {
        id: "c",
        extensions: [".c", ".h"]
    },
    {
        id: "cpp",
        extensions: [".cpp", ".cc", ".cxx", ".hpp", ".hh", ".hxx"]
    },
    {
        id: "csharp",
        extensions: [".cs", ".csx"]
    },
    {
        id: "dockerfile",
        extensions: [".dockerfile"]
    },
    {
        id: "fsharp",
        extensions: [".fs", ".fsi", ".ml", ".mli", ".fsx", ".fsscript"]
    },
    {
        id: "go",
        extensions: [".go"]
    },
    {
        id: "handlebars",
        extensions: [".handlebars", ".hbs"]
    },
    {
        id: "html",
        extensions: [".html", ".htm", ".shtml", ".xhtml", ".mdoc", ".jsp", ".asp", ".aspx", ".jshtm"]
    },
    {
        id: "ini",
        extensions: [".ini", ".properties", ".gitconfig"]
    },
    {
        id: "pug",
        extensions: [".jade", ".pug"]
    },
    {
        id: "java",
        extensions: [".java", ".jav"]
    },
    {
        id: "lua",
        extensions: [".lua"]
    },
    {
        id: "markdown",
        extensions: [".md", ".markdown", ".mdown", ".mkdn", ".mkd", ".mdwn", ".mdtxt", ".mdtext"]
    },
    {
        id: "msdax",
        extensions: [".dax", ".msdax"]
    },
    {
        id: "objective-c",
        extensions: [".m"]
    },
    {
        id: "postiats",
        extensions: [".dats", ".sats", ".hats"]
    },
    {
        id: "php",
        extensions: [".php", ".php4", ".php5", ".phtml", ".ctp"]
    },
    {
        id: "powershell",
        extensions: [".ps1", ".psm1", ".psd1"]
    },
    {
        id: "python",
        extensions: [".py", ".rpy", ".pyw", ".cpy", ".gyp", ".gypi"]
    },
    {
        id: "r",
        extensions: [".r", ".rhistory", ".rprofile", ".rt"]
    },
    {
        id: "razor",
        extensions: [".cshtml"]
    },
    {
        id: "ruby",
        extensions: [".rb", ".rbx", ".rjs", ".gemspec", ".pp"]
    },
    {
        id: "swift",
        extensions: [".swift"]
    },
    {
        id: "sql",
        extensions: [".sql"]
    },
    {
        id: "vb",
        extensions: [".vb"]
    },
    {
        id: "xml",
        extensions: [".xml", ".dtd", ".ascx", ".csproj", ".config", ".wxi", ".wxl", ".wxs", ".xaml", ".svg", ".svgz"]
    },
    {
        id: "less",
        extensions: [".less"]
    },
    {
        id: "scss",
        extensions: [".scss"]
    },
    {
        id: "scss",
        extensions: [".scss"]
    },
    {
        id: "css",
        extensions: [".css"]
    },
    {
        id: "yaml",
        extensions: [".yaml", ".yml"]
    },
    {
        id: "sol",
        extensions: [".sol"]
    },
    {
        id: "sb",
        extensions: [".sb"]
    }
]

export default CodeEditorBuiltinLanguages;