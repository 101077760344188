import Select from 'antd/lib/select';
import * as React from "react";
import { isNullOrUndefined } from "@bimser/core";
import { BCCombobox, BCInputMask, IComboboxSelectedChangeEventArgs, IInputChangeEventArgs } from "../index";
import * as Styles from "./assets/style.scss";
import { countries } from "./Contents/countries";
import { IPhoneInputProps } from './entities';


var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);
export default class BCPhoneInput extends React.Component<IPhoneInputProps, { focus: boolean }> {
    wrapperRef: React.RefObject<HTMLDivElement>;
    constructor(props: IPhoneInputProps) {
        super(props);
        this.state = {
            focus: false,
        }
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChange = this.onChange.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
    }

    onChangeCountry(args: IComboboxSelectedChangeEventArgs) {
        let item = args.senderArgs?.option;
        let data = countries.find((i: any) => i.key === item.key).name;
        let country = {
            key: item.key,
            text: item.children,
            value: item.value,
            name: data
        };
        if (!isNullOrUndefined(this.props.onChangeCountry)) this.props.onChangeCountry(country);
    }

    onChange(args: IInputChangeEventArgs) {
        if (!isNullOrUndefined(this.props.onChange)) this.props.onChange(args);
    }

    renderItem(args: any) {
        let optionContent: string = args.text + " " + args.key;
        if (this.props.country !== null && this.props.country?.key === args.key) optionContent = args.key;

        return <Select.Option value={args.value} key={args.key} title={args.text}>{optionContent}</Select.Option>
    }

    onMouseDown() {
        this.setState({ focus: true });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ focus: false });
        }
    }

    render() {
        let classNames = cx({
            Wrapper: true,
            onFocus: this.state.focus
        });

        let mask = "";
        let country = null;

        if (this.props.country) {
            country = this.props.country;
            mask = this.props.country.name;
        }
        else if (this.props.defaultCountry) {
            let defaultCountry = countries.find(i => i.value === this.props.defaultCountry);
            country = defaultCountry;
            mask = defaultCountry.name;
        }

        return (
            <div ref={this.wrapperRef} className={classNames} onMouseDown={this.onMouseDown}>
                <BCCombobox
                    showSearch={true}
                    options={countries}
                    value={country}
                    disabled={false}
                    onSelectedChange={this.onChangeCountry}
                    className={[this.props.dropDownClassName, Styles.phoneDropdown].join(' ')}
                    defaultValue={this.props.defaultCountry ? this.props.defaultCountry : ''}
                    renderItem={this.renderItem}
                />
                <BCInputMask
                    mask={mask}
                    formatChars={this.props.formatChars}
                    value={this.props.value}
                    size={this.props.size as 'small' | 'middle' | 'large'}
                    disabled={this.props.disabled}
                    onChange={this.onChange}
                    className={[this.props.inputClassName, Styles.maskInput].join(' ')}
                />
            </div >
        );
    }
}