import * as React from "react";
import * as Styles from "../assets/style.scss";
import { default as IPDFViewerProps, IPDFViewerState } from "../entities/PDFViewer/IPDFViewerProps";
import { Props as DocumentProps } from 'react-pdf/dist/Document';
import { Props as PageProps } from 'react-pdf/dist/Page';
import { BCButton, BCDiagramStateViewer, BCFormItem, BCInput, BCLabel, BCScrollbars, IInputChangeEventArgs, ValidateStatus } from '../..';
import { findMessage } from '../../BCIntl';
import { debounce } from "@bimser/core";
import objectHash from "object-hash";
import PasswordResponses from "../entities/PDFViewer/PasswordResponses";

const PdfDocument = React.lazy(() => import(/* webpackChunkName: "pdfjs" */ './pdf/Document'));
const PdfPage = React.lazy(() => import(/* webpackChunkName: "pdfjs" */ './pdf/Page'));

const classNames = require('classnames/bind');
let cx = classNames.bind(Styles);
const renderMode = "svg"

function Document(props: DocumentProps & { children: React.ReactElement }) {
    return (
        <React.Suspense fallback={<div></div>}>
            <PdfDocument {...props } renderMode={renderMode} />
        </React.Suspense>
    );
}
function Page(props: PageProps & { children: React.ReactElement }) {
    return (
        <React.Suspense fallback={<div></div>}>
            <PdfPage {...props} renderMode={renderMode} />
        </React.Suspense>
    );
}

export default class PDFViewer extends React.Component<IPDFViewerProps, IPDFViewerState> {
    sesameKey = objectHash({ key: "openSesamePDF" });
    constructor(props: IPDFViewerProps) {
        super(props);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.onScaleChange = this.onScaleChange.bind(this);
        this.onPasswordRequired = this.onPasswordRequired.bind(this);
        this.renderPasswordForm = this.renderPasswordForm.bind(this);
        this.onPasswordChange = debounce(this.onPasswordChange.bind(this), 300);

        this.state = {
            numPages: null,
            pageNumber: 1,
            scale: 100,
            passwordCallback: null,
            passwordReason: null,
            sesame: window[this.sesameKey]?.[objectHash({ url: this.props.data })] ?? null
        }
    }

    onDocumentLoadSuccess = (args: any) => {
        let numPages: any = args.numPages;
        this.setState({ numPages, passwordCallback: null, passwordReason: null });
        if (this.state.sesame) {
            if (!window[this.sesameKey]) window[this.sesameKey] = {};
            window[this.sesameKey][objectHash({ url: this.props.data })] = this.state.sesame;
        }
    }

    getPages() {
        let pages = [];
        for (let i = 0; i < this.state.numPages; i++) {
            pages.push(<Page key={i + 1} pageNumber={i + 1} className={Styles.pdfDocument} scale={this.state.scale / 100}>
                <span className={Styles.pdfPageNumber}>{i + 1} / {this.state.numPages}</span>
            </Page>)
        }
        return <> {pages.map(page => page)}</>
    }

    onScaleChange(scale: number) {
        this.setState({ scale });
    }

    onPasswordRequired(callback: (password: string) => void, reason: any) {
        if (this.state.sesame && this.state.passwordCallback === null && reason === PasswordResponses.NEED_PASSWORD) {
            callback(this.state.sesame);
        } else {
            this.setState({
                passwordCallback: callback,
                passwordReason: reason,
                sesame: null
            });
        }
    }

    onPasswordChange(args: IInputChangeEventArgs) {
        this.setState({ sesame: args.data, passwordReason: null });
    }

    renderPasswordForm() {
        return (
            <div className={Styles.pdfPasswordForm}>
                <div className={Styles.pdfPasswordFormInner}>
                    <BCLabel className={Styles.pdfPasswordLabel}>{findMessage.get("101025")}</BCLabel>
                    <BCFormItem
                        validateStatus={this.state.passwordReason === PasswordResponses.INCORRECT_PASSWORD ? ValidateStatus.error : ValidateStatus.validating}
                        help={this.state.passwordReason === PasswordResponses.INCORRECT_PASSWORD ? findMessage.get("101026") : null}
                    >
                        <BCInput
                            onChange={this.onPasswordChange}
                            value={this.state.sesame}
                            type="password"
                        />
                    </BCFormItem>
                    <BCButton text={findMessage.get("101878")} type="primary" disabled={!this.state.sesame?.length} className={Styles.pdfPasswordButton} onClick={() => {
                        this.state.passwordCallback(this.state.sesame);
                    }} />
                </div>
            </div>
        )
    }

    render() {
        const { numPages, passwordCallback } = this.state;

        return (
            <div className={Styles.pdfViewerContainer}>
                <div className={cx({
                    pdfViewerContent: true,
                    hideDocument: passwordCallback !== null
                })}>
                    <BCScrollbars>
                        {passwordCallback ? this.renderPasswordForm() : null}
                        <Document
                            inputRef={this.props.printRef}
                            file={this.props.data}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            loading={findMessage.get("100127")}
                            noData={findMessage.get("100705")}
                            onPassword={this.onPasswordRequired as any}
                        >
                            {
                                numPages ? this.getPages() : null
                            }
                        </Document>
                    </BCScrollbars>
                </div>
                <BCDiagramStateViewer
                    maxScale={200}
                    minScale={50}
                    zoomScaleFactory={10}
                    defaultZoomScale={100}
                    zoomScale={this.state.scale}
                    setZoomScale={this.onScaleChange}
                    hideFitScreen
                    hideObjectCount
                />

            </div>
        );
    }

}