import React from 'react';
import { BCTabs, BCModal, ITabsItem, BCButton, findMessage } from '..';
import * as Styles from './assets/styles.scss';
import BCEditor from '.';
import { ITabItem } from 'BCTabs';
import { IEditorMLProps } from './entities/IEditorMLProps';

const EditorMLModal = (props: IEditorMLProps) => {

    const onLanguageValueChange = (culture: string) => (value: string) => {
        if (props.onValueChange) props.onValueChange(culture, value);
    }

    const renderTabStepContent = (item: ITabItem) => {

        return (<div key={item.key}>
            <BCEditor
                onModelChange={onLanguageValueChange(item.key)}
                model={props.values && props.values[item.key]}
                language={props.currentLanguage.culture}
                charCounterMax={props.config.charCounterMax}
                charCounterCount={props.config.showCharacterCounter}
            />
        </div>);
    }

    const getItems = (): ITabsItem[] => {
        return props.supportedLanguages?.filter(lang => lang.culture != props.currentLanguage.culture).map(lang => {
            return { key: lang.culture, label: lang.name };
        }) || []
    }

    const languageModalTabs = () => {
        return (
            <BCTabs
                key="stepdetail_tabs"
                items={getItems()}
                renderTabContent={renderTabStepContent}
            />
        );
    }

    const onCloseModal = () => {
        props.closeModal();
    }

    const renderFooter = () => {
        return [<BCButton
            type={"primary"}
            size="middle"
            text={findMessage.get("100824")}
            onClick={onCloseModal}
        />]
    }

    return (
        <BCModal
            title={findMessage.get("101138")}
            width={'800px'}
            disableScroll={false}
            maskClosable={true}
            closable={true}
            wrapClassName={Styles.editorMLModalsContentContainer}
            visible={props.visible}
            footer={renderFooter()}
            handleCancel={onCloseModal}
        >
            {
                <div className={Styles.editorMLModalsContent}>{languageModalTabs()}</div>
            }
        </BCModal>
    )

}

export default EditorMLModal;
