import * as React from "react";
import * as ReactDOM from "react-dom";

import Calendar from 'antd/lib/calendar';
import { ICalendarProps, ICalendarMode } from './entities';

import * as Styles from './assets/styles.scss';

export default class BCCalendar extends React.Component<ICalendarProps<any>, {}> {
    constructor(props: ICalendarProps<any>) {
        super(props);
        Styles;
    }

    render() {
        const {
            className, value, defaultValue, mode, fullscreen, dateCellRender, monthCellRender, dateFullCellRender,
            monthFullCellRender, locale, style, onPanelChange, onSelect, onChange, disabledDate, validRange
        } = this.props;

        return (
            <React.Fragment>
                <Calendar
                    className={className}
                    value={value}
                    defaultValue={defaultValue}
                    mode={mode}
                    fullscreen={fullscreen}
                    dateCellRender={dateCellRender}
                    monthCellRender={monthCellRender}
                    dateFullCellRender={dateFullCellRender}
                    monthFullCellRender={monthFullCellRender}
                    locale={locale}
                    style={style}
                    onPanelChange={onPanelChange}
                    onSelect={onSelect}
                    onChange={onChange}
                    disabledDate={disabledDate}
                    validRange={validRange}
                />
            </React.Fragment>
        );
    }
}

export { ICalendarProps, ICalendarMode }
