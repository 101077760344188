import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from 'immutable';


export interface IBaseEBA6Integration {
    loading: boolean,
    url: string,
    eBAIntegrationHeader: string
}

export interface IEBA6Integration extends IBaseEBA6Integration {
}

export interface IReadonlyEBA6Integration extends IBaseEBA6Integration {
}

export type EBA6Integration = ReadonlyRecord<IReadonlyEBA6Integration>;

export const EBA6IntegrationFactory = Record<IReadonlyEBA6Integration>({
    loading: false,
    url: "",
    eBAIntegrationHeader: "",
})


export const EBA6IntegrationFactoryFromJS = function (data: IEBA6Integration) {
    return EBA6IntegrationFactory({
        loading: data?.loading,
        url: data?.url,
        eBAIntegrationHeader: data?.eBAIntegrationHeader
    })
}