import { createMutableAction } from "@bimser/core";
import IStartMobileSigningPayload from "../entities/IStartMobileSigningPayload";
import { CLOSE_MOBILE_SIGN_MODAL_ACTION, START_MOBILE_SIGNING_ACTION } from "./actionTypes";


export const closeMobileSignModalAction = (id: string, result = true) => {
    return createMutableAction(CLOSE_MOBILE_SIGN_MODAL_ACTION, { id, result });
}

export const startMobileSigningAction = (data: IStartMobileSigningPayload) => {
    return createMutableAction(START_MOBILE_SIGNING_ACTION, data);
}