import * as React from "react";
import { connect } from "react-redux";
import { BaseState } from "../../../entities/BaseState";
import LoginMain from "../components/LoginMain";
import ILoginMainCProps from "../entities/ILoginMainCProps";
import ILoginMainProps from "../entities/ILoginMainProps";
import { getTenantCustom } from "../../../system/tenant/selectors";
import { getSelectedCulture, getLoginFormProperties , getBrowserProperties} from "../selectors/LoginUserSelector";
import { getLoginSliderItems } from '../../../system/loginSlider/selectors/getLoginSliderItemsSelector';

// state to component properties
const mapStateToProps = function (state: BaseState, props: ILoginMainProps): ILoginMainProps {
    return {
        hash: props.hash,
        tenant: getTenantCustom(state),
        selectedCulture: getSelectedCulture(state),
        loginFormProperties: getLoginFormProperties(state),
        loginSliderItems: getLoginSliderItems(state),
        browserProperties: getBrowserProperties(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): ILoginMainCProps {
    return {

    };
}

// react - redux connection
const LoginMainContainer = connect(mapStateToProps, mapDispatchToProps)(LoginMain);

export default LoginMainContainer;