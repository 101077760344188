const _at = require("lodash/at");
import { IDictionary } from "@bimser/core";
import * as React from "react";
import BCScrollbars from '../../BCScrollbars';
import * as Styles from "../assets/style.scss";
import PropertyItem from "../components/PropertyItem";
import { IPropertyItem, IPropertyItemsPanelProps } from '../entities';

const PropertyItemsPanel = React.memo((props: IPropertyItemsPanelProps) => {

    const getPropertyData = React.useCallback((data: IDictionary<any>, key: string) => {
        if (!key.includes('.')) return data[key];
        return _at(data, key)[0];
    }, [props.data]);

    const renderProperties = () => {
        return (
            <div className={Styles.propertyInspector + " " + props.cssClass}>
                {props.propertyItems.map((property: IPropertyItem) => <PropertyItem
                    currentLanguage={props.currentLanguage}
                    supportedLanguages={props.supportedLanguages}
                    propertyItem={property}
                    key={property.key}
                    onValueChanged={props.onValueChanged}
                    reload={props.reload}
                    fireAction={props.fireAction}
                    value={props.data ? getPropertyData(props.data, property.key) : null}
                    getOptionsPromise={props.getOptionsPromise}
                    onRefresh={props.onRefresh}
                    guid={props.data?.controlId ?? props.guid}
                    commonPopoverItems={props.commonPopoverItems}
                    showCustomBadgeOnLabel={props.showCustomBadgeOnLabel}
                />)}
            </div>
        )
    }

    return (
        <BCScrollbars autoHide={true}>
            {renderProperties()}
        </BCScrollbars>
    )

})

export default PropertyItemsPanel