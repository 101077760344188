import { isNullOrUndefined } from "@bimser/core";
import * as React from "react";
import * as Styles from "../assets/style.scss";
import IGroupDocsViewerProps from "../entities/GroupDocsViewer/IGroupDocsViewerProps";

export default class GroupDocsViewer extends React.Component<IGroupDocsViewerProps, any> {
    constructor(props: IGroupDocsViewerProps) {
        super(props);
        this.generateTemplate = this.generateTemplate.bind(this);
    }

    generateTemplate(): JSX.Element {
        if (isNullOrUndefined(this.props.data.url))
            return (
                <div className={Styles.groupNoDocsViewerContent}>
                    {this.props.noContentTitle}
                </div>
            )

        return <iframe width='100%' frameBorder='0' height='100%' src={this.props.data.url}></iframe>
    }

    render() {
        return (
            <div className={Styles.groupDocsViewerContent}>
                {this.generateTemplate()}
            </div>
        )
    }
}