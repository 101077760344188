import React, { lazy, Suspense } from 'react';
import IActionManagementProps from './entities/IActionManagementProps';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-actions" */ "./containers"));

function ActionManagementContainer(props: React.PropsWithChildren<IActionManagementProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
ActionManagementContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-actions" */ "./injector");
}

export default ActionManagementContainer;
