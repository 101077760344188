import { IExtremeGridState, IExtremeGridColumn } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeGroupingReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<{ columnName: string; }>>): IExtremeGridState {
    let newColumns: Array<IExtremeGridColumn> = state.columns.map((item) => {
        let grouping = action.payload?.some((c => c.columnName == item.name));

        return item.grouping !== grouping
            ? Object.assign({}, item, { grouping })
            : item;
    });

    return {
        ...state,
        columns: newColumns
    };
}