import { List } from 'immutable';
import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from "../../../base/state"
import { SignatureTemplate } from '../entities/ISignatureTemplate';


const getESignatureTemplatesSelector = (state: WebInterfaceBaseState) => {
    return state.ESignature.templates;
}

export const getESignatureTemplates = createSelector(
    [
        getESignatureTemplatesSelector
    ],
    (templates: List<SignatureTemplate>) => {
        return templates ? templates.toJS() : [];
    }
)

const getESignatureModalLoadingSelector = (state: WebInterfaceBaseState, modalId: string) => {
    let modalProps = state.modals.items.find(i => i.id === modalId);
    if (modalProps) {
        return modalProps.props.isLoading;
    }
    return false;
}

export const getESignatureModalLoading = createSelector(
    [
        getESignatureModalLoadingSelector
    ],
    (isLoading: boolean) => isLoading
)

const getSelectedTemplateIdSelector = (state:WebInterfaceBaseState) => {
    return state.ESignature.selectedTemplateId;
}

export const getSelectedTemplateId = createSelector(
    [
        getSelectedTemplateIdSelector
    ],
    (selectedTemplateId: string) => selectedTemplateId
)