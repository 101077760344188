import { stringToColor } from '@bimser/core';
import Avatar from 'antd/lib/avatar';
import * as React from "react";
import { IAvatarProps, ShapeTypes, SizeTypes } from './entities';

const colorsPalette = [
    "760315", "A52128", "D23135", "DB3800",
    "8F552A", "CB4F07", "FFAB42", "FCE200",
    "997003", "C29D00", "8DBE11", "036A03",
    "478301", "00AE55", "005B70", "008488",
    "00B8C4", "004D8D", "0079D5", "4E6BEE",
    "5C2A92", "8864B9", "8479DF", "891099",
    "C336B4", "9C0062", "E4008D", "363636",
    "7B7675", "697A7F", "A1AFB3"
];

export default class BCAvatar extends React.Component<IAvatarProps, {}> {

    constructor(props: IAvatarProps) {
        super(props);
    }

    getRndColor() {
        let index = Math.floor(Math.random() * (colorsPalette.length - 0));
        const color = '#' + colorsPalette[index];
        return color;
    }

    getColor() {
        const text = this.props.names?.join(" ") || "";
        return stringToColor(text)
    }

    getTestAbbr() {
        let { names, maxCharacter } = this.props;
        let text = '';
        if (this.props.names) {
            if (maxCharacter)
                maxCharacter = names.length > maxCharacter ? maxCharacter : names.length;
            else
                maxCharacter = names.length;

            for (let index = 0; index < maxCharacter; index++) {
                if (names[index] && names[index] != '.') {

                    text += names[index].substring(0, 1).toUpperCase();
                }
            }
        }
        return text;
    }

    render() {
        if (this.props.src || this.props.names) {
            return <Avatar {...this.props} style={{ backgroundColor: this.getColor() }} >{this.getTestAbbr()}</Avatar>
        }
        else return <Avatar  {...this.props}>{this.props.alt}</Avatar>
    }
}

export { IAvatarProps, SizeTypes, ShapeTypes };

