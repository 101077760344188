import React, { lazy, Suspense } from 'react';
import { IDelegationModalProps } from './components';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-others" */ "./containers").then(({ default: container }) => {
    import(/* webpackChunkName: "web-others" */ "modules/delegations/injector");
    return { default: container };
}));

function Container(props: React.PropsWithChildren<IDelegationModalProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;