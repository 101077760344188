import { IExtremeGridState } from "../entities"
import { IBaseAction } from "@bimser/core";
import * as ActionTypes from "../actionTypes";
import changeSortingReducer from "./changeSortingReducer";
import changePageIndexReducer from "./changePageIndexReducer";
import changePageSizeReducer from "./changePageSizeReducer";
import changeFilteringReducer from "./changeFilteringReducer";
import changeSearchingReducer from "./changeSearchingReducer";
import { changeAddedRowsReducer, changeEditingRowIdsReducer, changeChangesRowReducer, commitChangesReducer } from "./editingReducer";
import changeSelectionReducer from "./changeSelectionReducer";
import changeColumnOrderReducer from "./changeColumnOrderReducer";
import changeColumnResizeReducer from "./changeColumnResizeReducer";
import changeHiddenColumnReducer from "./changeHiddenColumnReducer";
import changeGroupingReducer from "./changeGroupingReducer";
import changeSummaryReducer from "./changeSummaryReducer";
import changeFilterItemsReducer from "./changeFilterItemsReducer";
import changePagingReducer from "./changePagingReducer";
import changeToolbarButtonsReducer from './changeToolbarButtonsReducer';

export default function (state: IExtremeGridState, action: IBaseAction<any>): IExtremeGridState {
    switch (action.type) {
        case ActionTypes.CHANGE_SORTING: {
            return changeSortingReducer(state, action);
        }
        case ActionTypes.CHANGE_PAGE_INDEX: {
            return changePageIndexReducer(state, action);
        }
        case ActionTypes.CHANGE_PAGE_SIZE: {
            return changePageSizeReducer(state, action);
        }
        case ActionTypes.CHANGE_PAGING: {
            return changePagingReducer(state, action);
        }
        case ActionTypes.CHANGE_FILTERING: {
            return changeFilteringReducer(state, action);
        }
        case ActionTypes.CHANGE_SEARCHING: {
            return changeSearchingReducer(state, action);
        }
        case ActionTypes.CHANGE_EDITING_ADDED_ROWS: {
            return changeAddedRowsReducer(state, action);
        }
        case ActionTypes.CHANGE_EDITING_EDITING_ROW_IDS: {
            return changeEditingRowIdsReducer(state, action);
        }
        case ActionTypes.CHANGE_EDITING_CHANGES_ROWS: {
            return changeChangesRowReducer(state, action);
        }
        case ActionTypes.COMMIT_EDITING_CHANGES: {
            return commitChangesReducer(state, action);
        }
        case ActionTypes.CHANGE_SELECTION: {
            return changeSelectionReducer(state, action);
        }
        case ActionTypes.CHANGE_COLUMN_ORDER: {
            return changeColumnOrderReducer(state, action);
        }
        case ActionTypes.CHANGE_COLUMN_RESIZE: {
            return changeColumnResizeReducer(state, action);
        }
        case ActionTypes.CHANGE_HIDDEN_COLUMN: {
            return changeHiddenColumnReducer(state, action);
        }
        case ActionTypes.CHANGE_GROUPING: {
            return changeGroupingReducer(state, action);
        }
        case ActionTypes.CHANGE_SUMMARY: {
            return changeSummaryReducer(state, action);
        }
        case ActionTypes.CHANGE_FILTER_ITEMS: {
            return changeFilterItemsReducer(state, action);
        }
        case ActionTypes.CHANGE_TOOLBARBUTTONS: {
            return changeToolbarButtonsReducer(state, action);
        }
        default:
            return state;
    }
}