import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ILayoutItem, LayoutItem, LayoutItemFactory, LayoutItemFactoryFromJS } from '../../../common/entities';

export interface IBaseLayoutCreatorSelectAction {
    multiple?: boolean,
    selected?: boolean
}

export interface ILayoutCreatorSelectAction extends IBaseLayoutCreatorSelectAction {
    item: ILayoutItem
}

export interface IReadonlyLayoutCreatorSelectAction extends IBaseLayoutCreatorSelectAction {
    item: LayoutItem
}

export type LayoutCreatorSelectAction = ReadonlyRecord<IReadonlyLayoutCreatorSelectAction>;

export const LayoutCreatorSelectActionFactory = Record<IReadonlyLayoutCreatorSelectAction>({
    multiple: false,
    selected: false,
    item: LayoutItemFactory()
});

export const LayoutCreatorSelectActionFactoryFromJS = function (data: ILayoutCreatorSelectAction) {
    return LayoutCreatorSelectActionFactory({
        multiple: data.multiple,
        selected: data.selected,
        item: LayoutItemFactoryFromJS(data.item)
    });
}