import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';

interface IBaseObjectSelectHistoryItem{
    id: string,
    folderName: string,
    secretKey: string,
    canLoadMore: boolean
}

export interface IObjectSelectHistoryItem extends IBaseObjectSelectHistoryItem {
    
}

export interface IReadonlyObjectSelectHistoryItem extends IBaseObjectSelectHistoryItem {
    
}

export type ObjectSelectHistoryItem = ReadonlyRecord<IReadonlyObjectSelectHistoryItem>;

export const  ObjectSelectHistoryItemFactory = Record<IReadonlyObjectSelectHistoryItem>({
    folderName: null,
    secretKey: null,
    id: null,
    canLoadMore: true
});

export const ObjectSelectHistoryItemFactoryFromJS = function (data: IObjectSelectHistoryItem) {
    return ObjectSelectHistoryItemFactory(data);
}