import { createGuid, IDictionary } from "@bimser/core";
import IconAdd from "@bimser/icons/16/add";
import IconCopy from "@bimser/icons/16/copy";
import IconDelete from "@bimser/icons/16/delete";
import IconEdit from "@bimser/icons/16/edit";
import { List, Map } from "immutable";
import * as React from "react";
import { BCSwitch } from "../..";
import BCButton from "../../BCButton";
import BCCollapse, { ICollapseItem } from "../../BCCollapse";
import { ConditionalStatementEntities, Helpers } from "../../BCConditionalStatement";
import BCEmpty from "../../BCEmpty";
import { findMessage } from "../../BCIntl";
import BCScrollbars from "../../BCScrollbars";
import * as Styles from '../assets/styles.scss';
import { IRuleManager } from "../entities";
import RuleDetail from "./RuleDetail";
import classNames from "classnames/bind";

const cx = classNames.bind(Styles);
const scrollbarStyles = { width: '100%', height: 'calc(100% - 50px)' };

const RuleManager = React.memo((props: IRuleManager) => {

    const convertDetailRule = (rules: Map<string, ConditionalStatementEntities.Rule>): IDictionary<ConditionalStatementEntities.IRule> => {
        const ruleDetailData: IDictionary<ConditionalStatementEntities.IRule> = {};
        rules.forEach(r => {
            ruleDetailData[r.id] = Helpers.collapseStatements(r.toJS());
        });
        return ruleDetailData
    }

    const prevRulesRef = React.useRef(props.rules); // ComponentWillRecieveProps Simulation
    const [expandedKeys, setExpandedKeys] = React.useState<Array<string> | string>([]);
    const [ruleDetailData, setRuleDetailData] = React.useState<IDictionary<ConditionalStatementEntities.IRule>>(convertDetailRule(props.rules));

    React.useEffect(() => {
        if (!prevRulesRef.current.equals(props.rules)) {
            props.onDataChanged?.(props.rules);
            setRuleDetailData(convertDetailRule(props.rules));

            prevRulesRef.current = props.rules;
        }
    }, [props.rules]);

    const renderCollapseContent = (rule: ConditionalStatementEntities.Rule) => {
        return (
            <RuleDetail
                rule={ruleDetailData[rule.id]}
                fields={props.fields}
                onDataChanged={updatedRule => {
                    setRuleDetailData({
                        ...ruleDetailData,
                        [rule.id]: updatedRule
                    })
                }}
            />
        )
    }

    const getRule = (id: string): ConditionalStatementEntities.Rule => props.rules.get(id);

    const onRuleSelected = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        props.onRuleUpdated?.(getRule(id));
        e.stopPropagation();
    }

    const onRuleDisabled = (checked: boolean, id: string) => {
        let clickedRule = getRule(id);
        clickedRule = clickedRule.set('enabled', checked);
        props.onRuleUpdated?.(clickedRule, true);
    }

    const onRuleDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        const deletedRule = getRule(id);
        props.onRuleDeleted?.(deletedRule);
        e.stopPropagation();
    }

    const onRuleClone = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        props.onRuleClone?.(getRule(id));
        e.stopPropagation();
    }

    const renderRuleItem = (item: ICollapseItem) => {

        const ruleItem = getRule(item.key);
        const classNames = cx({
            ruleItem: true,
            selected: props.selectedRule?.id === ruleItem.id
        });

        return (
            <div className={classNames}>
                <div className={Styles.ruleHeader}>
                    <BCSwitch checked={ruleItem.enabled} size={'small'} className={Styles.ruleSwitch} onChange={(checked, event) => { onRuleDisabled(checked, item.key); event.stopPropagation(); }} />
                    <p>{item.label}</p>
                    <IconDelete className={Styles.collapseDeleteIcon} onClick={(e) => { onRuleDelete(e, item.key) }} />
                    <IconCopy className={Styles.collapseDeleteIcon} onClick={(e) => { onRuleClone(e, item.key) }} />
                    <IconEdit className={Styles.collapseDeleteIcon} onClick={(e) => { onRuleSelected(e, item.key) }} />
                </div>
            </div>
        )
    }

    const generateNewRuleName = (): string => {

        const allNames: string[] = props.rules.map(r => r.name).valueSeq().toArray(), newRuleString = findMessage.get('101149') + ' ';
        let keyFound = false, index: number = 0;

        while (!keyFound) {
            index++;
            keyFound = allNames.findIndex(n => n === newRuleString + index) === -1;
        }

        return newRuleString + index

    }

    const createNewRule = () => {
        const ruleCounter = props.rules.count();
        const newData = ConditionalStatementEntities.RuleFactory({
            id: createGuid(),
            name: generateNewRuleName(),
            actions: List(),
            statements: List(),
            enabled: true,
            triggerEvents: List(),
            orderNo: ruleCounter
        });

        props.onRuleAdded?.(newData);
    }

    const convertRulesToCollapse = (): ICollapseItem[] => {

        if (!props.rules || !props.rules.size) return [];

        const collapseItems: ICollapseItem[] = [];

        props.rules.sort((a, b) => a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0)
            .forEach(rule => {
                collapseItems.push({
                    content: renderCollapseContent(rule),
                    key: rule.id,
                    label: rule.name,
                    expanded: false
                });
            });

        return collapseItems;
    }

    const renderAddButton = () => {
        return (
            <BCButton
                icon={<IconAdd />}
                text={findMessage.get('101149')}
                onClick={createNewRule}
                type={'link'}
                size={'small'}
                disabled={props.disabled}
            />
        )
    }

    const renderEmptyRuleTemplate = () => {
        return (
            <BCEmpty description={findMessage.get('101150')}>
                {renderAddButton()}
                <p className={Styles.emptyTemplateDesc}>{findMessage.get('101151')}</p>
            </BCEmpty>
        )
    }


    const renderRules = () => {
        if (!props.rules?.size) return renderEmptyRuleTemplate();

        return (
            <BCCollapse
                customHeader={renderRuleItem}
                items={convertRulesToCollapse()}
                classNames={[Styles.rulesCollapsePanel]}
                expandedKeys={expandedKeys}
                onChangeExpandedKeys={setExpandedKeys}
            />
        )
    }

    const renderHeaderAddButton = () => {
        if (props.rules?.size) return renderAddButton()
    }

    return (
        <>
            <div className={Styles.newRuleButton}>
                <h3>
                    {findMessage.get('101152')}
                </h3>
                {renderHeaderAddButton()}
            </div>
            <BCScrollbars styles={scrollbarStyles} autoHide>
                {renderRules()}
            </BCScrollbars>
        </>
    )
})

export default RuleManager