import * as React from "react";
import BCCheckbox from '../../../BCCheckbox';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import * as Moment from "moment";
import * as Styles from "../../assets/style.scss";

export default function DayOfWeekProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const onChange = (checked: boolean, value: number) => {
        let values = props.value && Array.isArray(props.value) ? props.value : [];
        let newValue = checked ? [...values, value] : values.filter((x) => x != value);
        props.customChangeEvent ? props.customChangeEvent(checked) : replaceValue(props.item, newValue)
    }
    const isCheckDay = (day: number) => {
        return props.value ? (props.value as Array<number>).includes(day) : false;
    }
    const getDayText = (day: number) => {
        const dayShortText = Moment().day(day).format("ddd");
        return dayShortText[0] + dayShortText[1];
    }
    const renderDay = (day: number) => {
        return (
            <div key={"key_" + day} className={Styles.dayContainer}>
                <span >{getDayText(day)}</span>
                <BCCheckbox
                    checked={isCheckDay(day)}
                    value={day}
                    disabled={props.item.disabled}
                    onChange={(onChange)}
                />
            </div>
        )
    }

    return (
        <>
            {[0, 1, 2, 3, 4, 5, 6].map((i) => renderDay(i))}
        </>
    )
}