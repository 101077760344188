import { BCInputSearchRef } from "BCInputSearch";
import * as React from "react";
import BCCodeEditor, { ICodeEditor, IMonacoEditor } from '../BCCodeEditor';
import { IFieldType } from "../BCTreeSelect/entities";
import BCTreeview, { ITreeviewItem, ITreeviewSelectEventArgs } from '../BCTreeview';
import * as Styles from './assets/style.scss';
import { IFormulaEditorProps } from './entities';
import { getConstants, getMenuItems, getOperators, getStatistics, getTrigonometry } from './helpers/items';
import FormulaEditorModal from './helpers/modal/FormulaEditorModal';

export class BCFormulaEditor extends React.Component<IFormulaEditorProps, { selectedKeys: string[] }> {
    monaco: IMonacoEditor;
    editor: ICodeEditor;
    searchRef: BCInputSearchRef;
    mainMenuScrollbarStyles: React.CSSProperties = { height: 290 };
    scrollbarStyles: React.CSSProperties = { height: 250 };

    constructor(props: IFormulaEditorProps) {
        super(props);
        this.onFormulaItemSelect = this.onFormulaItemSelect.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.renderLeftSide = this.renderLeftSide.bind(this);
        this.onCodeChange = this.onCodeChange.bind(this);
        this.onCodeEditorMount = this.onCodeEditorMount.bind(this);
        this.getEditorValue = this.getEditorValue.bind(this);
        this.filterFields = this.filterFields.bind(this);

        this.state = {
            selectedKeys: []
        };
    }

    onFormulaItemSelect(args: ITreeviewSelectEventArgs) {
        if (args.item.isLeaf) {
            this.setState({ selectedKeys: [args.item.key] });
            this.searchRef._clearValue();
        }
    }

    onItemSelect(args: ITreeviewSelectEventArgs) {
        if (args.item.isLeaf) {
            this.insertIntoEditor(args.data.text);
        }
    }

    insertIntoEditor(text: string) {
        const position = this.editor.getPosition();
        const range = this.monaco.Range.fromPositions(position);
        const model = this.editor.getModel();

        const prevChar = model.getValueInRange({ ...range, startColumn: range.startColumn - 1 });
        const nextChar = model.getValueInRange({ ...range, endColumn: range.endColumn + 1 });

        const prevCharNotWhiteSpace = prevChar !== " " && prevChar !== "";
        const nextCharNotWhiteSpace = nextChar !== " " && nextChar !== "";

        if (prevCharNotWhiteSpace) text = ` ${text}`;
        if (nextCharNotWhiteSpace) text = `${text} `;

        this.editor.getModel().applyEdits([{ range, text }]);
    }

    filterFields(items: ITreeviewItem<any>[], valueType?: IFieldType) {
        return items.filter(i => {
            if (i.children && i.children.length) {
                i.children = this.filterFields(i.children, valueType)
                return true;
            }

            const isProperty = i.dataRef && i.dataRef.type && IFieldType[i.dataRef.type as IFieldType] ? true : false;
            const equalType = i.dataRef && i.dataRef.type === valueType;

            if ((valueType ? (!isProperty || equalType) : true) && (isProperty ? true : i.children?.length)) {
                return true
            }
        })
    }

    getDetailItemsBySelectedCategory(): ITreeviewItem<any>[] {
        if (this.state.selectedKeys && this.state.selectedKeys.length) {
            const selectedKey = this.state.selectedKeys[0];
            switch (selectedKey) {
                case 'Fields': {
                    return this.filterFields(this.props.fields, IFieldType.number)
                }
                case 'Constants': {
                    return getConstants()
                }
                case 'Statistics': {
                    return getStatistics()
                }
                case 'Trigonometry': {
                    return getTrigonometry()
                }
                case 'Operators': {
                    return getOperators()
                }
                default: return []
            }
        }

        return []
    }

    renderLeftSide() {
        return (
            <BCTreeview
                items={getMenuItems()}
                disableToolbar
                searchable={false}
                draggable={false}
                isTreeLeave={false}
                scrollbarStyles={this.mainMenuScrollbarStyles}
                hasSrollbar={true}
                className={Styles.treeView}
                onSelect={this.onFormulaItemSelect}
                selectedKeys={this.state.selectedKeys}
            />
        )
    }

    renderRightSide() {
        return (
            <BCTreeview
                getSearchInputRef={ref => this.searchRef = ref}
                items={this.getDetailItemsBySelectedCategory()}
                searchable={true}
                draggable={false}
                isTreeLeave={false}
                scrollbarStyles={this.scrollbarStyles}
                hasSrollbar={true}
                className={Styles.treeView}
                onSelect={this.onItemSelect}
                selectedKeys={this.state.selectedKeys}
            />
        )
    }

    onCodeChange(value: string) {
        if (this.props.onValueChanged) this.props.onValueChanged(value);
    }

    onCodeEditorMount(editor: ICodeEditor, monaco: IMonacoEditor) {
        this.editor = editor;
        this.monaco = monaco;
    }

    getEditorValue() {
        return this.props.value;
    }

    render() {
        return (
            <div className={'container-fluid'}>
                <div className={'row mb-2'}>
                    <div className={Styles.codeEditorContainer}>
                        <BCCodeEditor
                            value={this.getEditorValue()}
                            language={'js'}
                            width={"100%"}
                            height={"100%"}
                            onChange={this.onCodeChange}
                            onMount={this.onCodeEditorMount}
                            options={{
                                minimap: {
                                    enabled: false
                                },
                                contextmenu: false,
                                autoIndent: "none"
                            }}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-3 mt-2 mb-2'}>
                        {this.renderLeftSide()}
                    </div>
                    <div className={'col-md-9 mt-2 mb-2'}>
                        {this.renderRightSide()}
                    </div>
                </div>
            </div>
        );
    }
}

export { FormulaEditorModal };

