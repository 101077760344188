import React, { lazy, Suspense } from 'react';
import { IShiftListFormProps } from './entities';
import { IBaseListFormProps } from '../../../common/base/components/listForm/entities';


const ShiftListContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function ShiftListContainer(props: React.PropsWithChildren<IShiftListFormProps & IBaseListFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ShiftListContainerLazy {...props} />
        </Suspense>
    );
}
ShiftListContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default ShiftListContainer;