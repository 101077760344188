import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Record, Map } from "immutable";
import ActivityReactionType from "./ActivityReactionType";

interface IBaseActivityReaction {
    type: ActivityReactionType,
    secretKey: string,
    createDat: string,
    createdBy: number,
    createdByName: string
}

export interface IActivityReaction extends IBaseActivityReaction {

}

export interface IReadonlyActivityReaction extends IBaseActivityReaction {

}

export type ActivityReaction = ReadonlyRecord<IReadonlyActivityReaction>;

export const ActivityReactionFactory = Record<IReadonlyActivityReaction>({
    type: ActivityReactionType.Heart,
    secretKey: null,
    createDat: null,
    createdBy: null,
    createdByName: null
});

export const ActivityReactionFactoryFromJS = function (data: IActivityReaction) {
    return ActivityReactionFactory(data);
}