const RichToolbar = {
    moreText: {
        buttons: ['bold', 'italic', 'underline',
            'strikeThrough', 'subscript', 'superscript',
            'fontFamily', 'fontSize', 'textColor',
            'backgroundColor', 'inlineClass', 'inlineStyle',
            'clearFormatting']
    },
    moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple',
            'alignRight', 'alignJustify', 'formatOL',
            'formatUL', 'paragraphFormat', 'paragraphStyle',
            'lineHeight', 'outdent', 'indent',
            'quote']
    },
    moreRich: {
        buttons: ['insertLink', /*'insertImage', 'insertVideo','insertFile',*/
            'insertTable', 'emoticons', 'fontAwesome',
            'specialCharacters', 'embedly', 'insertHR']
    },
    moreMisc: {
        buttons: ['ML','undo', 'redo', 'fullscreen',
            'print', 'selectAll', 'html', 'help']
    }
}

const StandardToolbar = {
    moreText: {
        buttons: ['bold', 'italic', 'underline',
            'strikeThrough', 'subscript', 'superscript',
            'fontFamily', 'fontSize', 'textColor',
            'backgroundColor', 'inlineClass', 'inlineStyle',
            'clearFormatting']
    },
    moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple',
            'alignRight', 'alignJustify', 'formatOL',
            'formatUL', 'paragraphFormat', 'paragraphStyle',
            'lineHeight', 'outdent', 'indent',
            'quote']
    },
    moreRich: {
        buttons: ['insertLink', 'insertHR']
    },
    moreMisc: {
        buttons: ['ML','undo', 'redo', 'fullscreen', 'selectAll', 'help']
    }
}

const BasicToolbar = {
    moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize']
    },
    moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple',
            'alignRight', 'alignJustify', 'formatOL',
            'formatUL', 'paragraphFormat',
            'outdent', 'indent',]
    },
    moreMisc: {
        buttons: ['ML','undo', 'redo', 'help']
    }
}

export default {
    RichToolbar,
    StandardToolbar,
    BasicToolbar
}