import { IBaseAction } from "@bimser/core";
import { BaseState } from "../../../entities/BaseState";
import DmViewerInstance from './DmViewerInstance';

export class DmViewerAPI {
    static store: any;
    static instances: IDmViewerInstances = {};

    static get(id: string) {
        if (!DmViewerAPI.instances[id]) {
            DmViewerAPI.instances[id] = new DmViewerInstance(id);
        }
        return DmViewerAPI.instances[id];
    }

    static getProperties(id: string): any {
        if (DmViewerAPI.instances[id]) {
            let state: BaseState = DmViewerAPI.store.getState();
            if (state.Viewers && state.Viewers.items.has(id)) {
                let viewer: any = state.Viewers.items.get(id);
                return viewer;
            }
        }
        else {
            return null;
        }
    }

    static toEndUser() {
        return {
            get: DmViewerAPI.get,
            getProperties: DmViewerAPI.getProperties
        };
    }

    static getStore() {
        if (!DmViewerAPI.store) {
            throw new Error("Store cannot be null");
        }
        return DmViewerAPI.store;
    }

    static setStore(store: any) {
        DmViewerAPI.store = store;
    }

    static dispatch(action: IBaseAction<any>) {
        DmViewerAPI.getStore().dispatch(action);
    }
}

interface IDmViewerInstances {
    [index: string]: DmViewerInstance
}

export default DmViewerAPI.toEndUser();

