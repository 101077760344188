
export const CHANGE_SORTING = "EXTREMEGRID.CHANGE_SORTING";
export const CHANGE_PAGE_INDEX = "EXTREMEGRID.CHANGE_PAGE_INDEX";
export const CHANGE_PAGE_SIZE = "EXTREMEGRID.CHANGE_PAGE_SIZE";
export const CHANGE_PAGING = "EXTREMEGRID.CHANGE_PAGING";
export const CHANGE_FILTERING = "EXTREMEGRID.CHANGE_FILTERING";
export const CHANGE_SEARCHING = "EXTREMEGRID.CHANGE_SEARCHING";
export const CHANGE_SELECTION = "EXTREMEGRID.CHANGE_SELECTION";
export const CHANGE_COLUMN_ORDER = "EXTREMEGRID.CHANGE_COLUMN_ORDER";
export const CHANGE_COLUMN_RESIZE = "EXTREMEGRID.CHANGE_COLUMN_RESIZE";
export const CHANGE_HIDDEN_COLUMN = "EXTREMEGRID.CHANGE_HIDDEN_COLUMN";
export const CHANGE_GROUPING = "EXTREMEGRID.CHANGE_GROUPING";
export const CHANGE_EDITING_EDITING_ROW_IDS = "EXTREMEGRID.CHANGE_EDITING_EDITING_ROW_IDS";
export const CHANGE_EDITING_DELETED_ROW_IDS = "EXTREMEGRID.CHANGE_EDITING_DELETED_ROW_IDS";
export const CHANGE_EDITING_CHANGES_ROWS = "EXTREMEGRID.CHANGE_EDITING_CHANGES_ROWS";
export const CHANGE_EDITING_ADDED_ROWS = "EXTREMEGRID.CHANGE_EDITING_ADDED_ROWS";
export const COMMIT_EDITING_CHANGES = "EXTREMEGRID.COMMIT_EDITING_CHANGES";
export const CHANGE_SUMMARY = "EXTREMEGRID.CHANGE_SUMMARY";
export const CHANGE_DATA = "EXTREMEGRID.CHANGE_DATA";
export const CHANGE_FILTER_ITEMS = "EXTREMEGRID.CHANGE_FILTER_ITEMS";
export const CHANGE_TOOLBARBUTTONS = "EXTREMEGRID.CHANGE_TOOLBARBUTTONS";
