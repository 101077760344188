import { List, Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { ILayoutItems, INormalizedObjExtension, LayoutItems, LayoutItemsFactory, LayoutItemsFactoryFromJS } from '../entities';

export type INormalizedLayoutItems = INormalizedObjExtension<ILayoutItems, Array<string>>

export type IReadonlyNormalizedLayoutItems = INormalizedObjExtension<LayoutItems, List<string>>

export type NormalizedLayoutItems = ReadonlyRecord<IReadonlyNormalizedLayoutItems>;

export const NormalizedLayoutItemsFactory = Record<IReadonlyNormalizedLayoutItems>({
    entities: LayoutItemsFactory(),
    result: List(),
    description: null
});

export const NormalizedLayoutItemsFactoryFromJS = function (data: INormalizedLayoutItems) {
    return NormalizedLayoutItemsFactory({
        entities: LayoutItemsFactoryFromJS(data.entities),
        result: List(data.result),
        description: data.description
    });
}