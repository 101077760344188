import { LayoutDataItem, NormalizedLayoutStructureItems } from "../../../common/entities";
import { List, Map } from "immutable";
import { FormItemTypes } from "@bimser/core";

export const removeItem = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    id: string,
    subStructure?: boolean,
    shiftKey?: boolean) => {

    let view = views.get(activeView);

    let item = view.entities.items.get(id);
    let childs = view.entities.items.get(id)?.items;
    let parentId = view.entities.items.get(id)?.parentId;
    let isRootItem = view.result.contains(id);

    if (parentId) {
        let parentItem = view.entities.items.get(parentId);
        if (parentItem) {

            //Remove item id from parent's items
            let index = parentItem.items.indexOf(id);
            parentItem = parentItem.set('items', parentItem.items.delete(index));
            view = view.setIn(['entities', 'items', parentId], parentItem);

        }
    } else if (isRootItem) {
        //Remove from root if root item
        let index = view.result.indexOf(id);
        let result = view.result.delete(index);
        view = view.set('result', result);
    }

    //Remove item
    const isControl = Boolean(FormItemTypes.EControlTypes[item?.type]);
    if (isControl && shiftKey && data.has(id)) {
        data = data.remove(id);
    }

    let entityItems = view.entities.items.delete(id);
    view = view.setIn(['entities', 'items'], entityItems);

    //If item is root item replace the parent ids
    if (isRootItem && !subStructure) {
        let newRootItems = item.items as List<string>;
        view = view.set('result', newRootItems);
        newRootItems.forEach((rootId) => {
            view = view.setIn(['entities', 'items', rootId, 'parentId'], null);
        });
    }

    views = views.set(activeView, view)

    //Remove childs recursively
    if (childs && !childs.isEmpty()) {
        (childs as List<string>).forEach(i => {
            const { newViews: childNewViews, newData: childNewData } = removeItem(views, data, activeView, i, subStructure, shiftKey);
            views = childNewViews;
            data = childNewData;
        })
    }

    return {
        newViews: views,
        newData: data
    }

}