import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { List, Map, Record } from 'immutable';
import { DmData, DmDataFactoryFromJS, DmObjectType, IDmData } from '../../../../../entities';
import SearchOptionsShowType from './SearchOptionsShowType';
import SearchSourceType from './SearchSourceTypes';
import IncludeTypes from './IncludeTypes';

interface IBaseSearchOptions {
    query?: string,
    inRepositories?: boolean,
    inFolders?: boolean,
    inFiles?: boolean,
    inContents?: boolean,
    inShorcuts?: boolean
    quickType?: IncludeTypes,
    owner?: number,
    ownerName?: string,
    targetLocation?: string,
    targetLocationName?: string,
    searchRecycleBin?: boolean,
    includeSubFolders?: boolean,
    pagination?: {
        skip: number,
        take: number
    },
    showType?: SearchOptionsShowType,
    isLoading?: boolean,
    modifiedAfterDate?: Date,
    searchIn?: SearchSourceType,
}

export interface ISearchOptions extends IBaseSearchOptions {
    results?: IDmData[],
    resultPaths?: IDictionary<string>
}

export interface IReadonlySearchOptions extends IBaseSearchOptions {
    results?: List<DmData>,
    resultPaths?: Map<string, string>
}

export type SearchOptions = ReadonlyRecord<IReadonlySearchOptions>;

export const SearchOptionsFactory = Record<IReadonlySearchOptions>({
    query: "",
    quickType: 0,
    owner: null,
    ownerName: null,
    targetLocation: null,
    targetLocationName: null,
    searchRecycleBin: false,
    includeSubFolders: false,
    inRepositories: false,
    inFolders: true,
    inFiles: true,
    inContents: false,
    inShorcuts: false,
    modifiedAfterDate: null,
    pagination: {
        skip: 0,
        take: 10
    },
    showType: SearchOptionsShowType.Closed,
    isLoading: false,
    results: List(),
    resultPaths: Map(),
    searchIn: SearchSourceType.ObjectMetadata,
});

export const SearchOptionsFactoryFromJS = function (data: ISearchOptions) {
    let _results = data.results ? data.results.map(d => DmDataFactoryFromJS(d)) : [];
    return SearchOptionsFactory({
        ...data,
        results: List(_results),
        resultPaths: Map(data.resultPaths)
    });
}