import { Record } from "immutable";
import { FormItemTypes, ReadonlyRecord } from "@bimser/core";

export interface IBaseLayoutBaseItem {
    id: string,
    type?: FormItemTypes.EContainerTypes | string
}

export interface ILayoutBaseItem extends IBaseLayoutBaseItem {

}

export interface IReadonlyLayoutBaseItem extends IBaseLayoutBaseItem {

}

export type LayoutBaseItem = ReadonlyRecord<IReadonlyLayoutBaseItem>;

export const LayoutBaseItemRecord: IReadonlyLayoutBaseItem = {
    id: null,
    type: null
};

export const LayoutBaseItemFactory = Record<IReadonlyLayoutBaseItem>(LayoutBaseItemRecord);

export const LayoutBaseItemFactoryFromJS = function (data: ILayoutBaseItem) {

    return LayoutBaseItemFactory(LayoutBaseItemFactoryObjectFromJS(data));

}

export const LayoutBaseItemFactoryObjectFromJS = function (data: ILayoutBaseItem) {
    return {
        id: data.id,
        type: data.type
    };
}