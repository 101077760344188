import { IMessageBoxButtonPayload, MessageBoxButtonPayload, MessageBoxButtonPayloadFactory, MessageBoxButtonPayloadFactoryFromJS } from ".";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { MessageBoxButtons } from "../../../enums";

export interface IBaseMessageBoxResolvePayload {
    id: string | MessageBoxButtons,
    notClose?: boolean;
}

export interface IMessageBoxResolvePayload extends IBaseMessageBoxResolvePayload {
    button: IMessageBoxButtonPayload
}

export interface IReadonlyMessageBoxResolvePayload extends IBaseMessageBoxResolvePayload {
    button: MessageBoxButtonPayload
}

export type MessageBoxResolvePayload = ReadonlyRecord<IReadonlyMessageBoxResolvePayload>;

export const MessageBoxResolvePayloadFactory = Record<IReadonlyMessageBoxResolvePayload>({
    id: null,
    button: MessageBoxButtonPayloadFactory(),
    notClose: false
});

export const MessageBoxResolvePayloadFactoryFromJS = function (data: IMessageBoxResolvePayload) {
    return MessageBoxResolvePayloadFactory({
        id: data.id,
        button: MessageBoxButtonPayloadFactoryFromJS(data.button),
        notClose: data.notClose
    });
}