import { BCAlert, BCCombobox, BCRibbonBar, BCSkeleton, BCViewer, findMessage, IAlertTypes, IComboboxSelectedChangeEventArgs, IRibbonBarItemProps } from '@bimser/components';
import { IItem, isNullOrUndefined, selectFile } from "@bimser/core";
import IconPrint from "@bimser/icons/16/print";
import IconUpload from "@bimser/icons/16/upload";
import classNames from 'classnames/bind';
import * as React from 'react';
import { useReactToPrint } from "react-to-print";
import { DropZone } from '../../../utilities/helpers';
import { useMountedState } from '../../../utilities/hooks';
import Style from '../assets/style.scss';
import { isViewerDropType, ViewerType } from '../entities';
import IDmViewerProps from "../entities/IDmViewerProps";

const cx = classNames.bind(Style);
const DmViewer = (props: IDmViewerProps) => {
    const isMounted = useMountedState();
    const printRef = React.useRef<any>();

    const [editData, setEditData] = React.useState<any>(null);

    React.useEffect(() => {
        if (isMounted) {
            setEditData(null);
        }

        if (props.item && props.onHideActions)
            props.onHideActions(props.item.viewerType === ViewerType.NotificationViewer)

        return () => {
            if (editData != null) URL.revokeObjectURL(editData)
        }
    }, [props.item && props.item.id])

    function onChangeData(value: string) {
        props.onChangeData({ id: props.item.id, data: value });
    }

    function getViewerData() {
        if (props.item && isViewerDropType(props.item.viewerType) && props.item.mode == "edit" && editData !== null)
            return editData;
        return props.item.data;
    }

    function isDropAllowed() {
        if (props.item && props.item.mode == "edit" && isViewerDropType(props.item.viewerType))
            return true;
        return false;
    }

    function onFileDrop(files: File[], ev: React.DragEvent) {
        if (files && files.length) {
            setEditFile(files[0]);
        }
    }

    function setEditFile(file: File) {
        if (editData != null) URL.revokeObjectURL(editData)
        setEditData(props.item.viewerType == ViewerType.PDFViewer || props.item.viewerType == ViewerType.GroupDocsViewer ? file : URL.createObjectURL(file))
        props.onChangeFile({ id: props.item.id, data: file })
    }

    function onBrowseClick() {
        selectFile(props.item?.mimeType || "*", (ev: Event) => {
            let files = (ev.target as HTMLInputElement).files;
            for (let file of files) {
                setEditFile(file);
            }
        })
    }
    
    const onRenderPrint = () => <IconPrint />
    const onRenderUpload = () => <IconUpload />

    function getRibbonFarItems() {
        let items: IRibbonBarItemProps[] = [];
        if (props.ribbonBarProps && props.ribbonBarProps.farItems) {
            items = props.ribbonBarProps.farItems;
        }
        if (props.item && isViewerDropType(props.item.viewerType) && props.item.mode == "edit") {
            items.push({
                key: "browse",
                text: findMessage.get("100719"),
                onClick: onBrowseClick,
                icon: IconUpload.info,
                onRenderIcon: onRenderUpload
            })
        }
        return items;
    }

    const getViewerTypeText = (type: ViewerType) => {
        switch (type) {
            case ViewerType.TextViewer:
                return findMessage.get("100204");
            case ViewerType.VideoViewer:
                return "Video";
            case ViewerType.ImageViewer:
                return findMessage.get("1010044");
            case ViewerType.PDFViewer:
                return "PDF";
            case ViewerType.TiffViewer:
                return "TIFF";
            case ViewerType.GroupDocsViewer:
                return "GroupDocs";
            case ViewerType.OfficeViewer:
                return "Office";
        }
    }

    const viewerOptions = React.useMemo(() => {
        return props.selectableViewerTypes.map(type => {
            return {
                key: type.toString(),
                value: type,
                text: getViewerTypeText(type)
            } as IItem
        });
    }, [props.selectableViewerTypes]);

    const onChangeUserSelectedViewerType = (args: IComboboxSelectedChangeEventArgs) => {
        if ((+args.data) != props.item.userSelectedViewerType) {
            props.onChangeUserSelectedViewerType({
                viewerId: props.item.id,
                userSelectedViewerType: args.data ? +args.data : null
            })
        }
    }

    const isRibbonDisabled = () => (props.disableRibbon || isNullOrUndefined(props.ribbonBarProps) || props.ribbonBarProps.items?.length == 0)

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const ribbonProps = React.useMemo(() => {
        let _props = { ...props.ribbonBarProps }

        if (isNullOrUndefined(_props?.items)) _props.items = [];
        if ([ViewerType.ImageViewer, ViewerType.TiffViewer, ViewerType.PDFViewer, ViewerType.TextViewer].includes(props.item?.viewerType) && props.canPrint) {
            _props.items.push(
                {
                    key: "print",
                    text: findMessage.get('101166'),
                    disabled: props.loading,
                    onClick: handlePrint,
                    icon: IconPrint.info,
                    onRenderIcon: onRenderPrint
                }
            )
        }

        return _props;
    }, [props.ribbonBarProps, props.item?.viewerType]);


    function renderViewerHeader() {
        if (isRibbonDisabled())
            return <></>

        return (
            <div key="viewerHeader" className={Style.viewerHeader}>
                <BCRibbonBar {...ribbonProps} farItems={getRibbonFarItems()} />
            </div>
        )
    }

    return (
        <div
            className={
                cx({
                    DmViewerContainer: true,
                    disabledHeader: isRibbonDisabled()
                }) + " " + (props.className ? props.className : "")
            }
        >
            {renderViewerHeader()}
            <BCSkeleton
                className={Style.viewerSkeleton}
                contentType={props.skeletonType}
                loadingStatus={isNullOrUndefined(props.item) || props.loading}
            >
                <DropZone
                    allowDrag={isDropAllowed()}
                    allowDrop={isDropAllowed()}
                    onFiles={onFileDrop}
                >
                    {(_props) => (
                        <div className={cx({ viewerContent: true, dragOver: _props.over })} style={{ padding: 5 }} {..._props.events}>
                            {props.item.viewerType == ViewerType.Unsupported &&
                                <BCAlert
                                    message={<div className={Style.viewerTypeSelection}>
                                        <span>{findMessage.get("102755")}</span>
                                        <BCCombobox
                                            className={Style.viewerTypeCombobox}
                                            showSearch={false}
                                            allowClear
                                            defaultValue={props.item.userSelectedViewerType}
                                            options={viewerOptions}
                                            onSelectedChange={onChangeUserSelectedViewerType}
                                        />
                                    </div>}
                                    type={IAlertTypes.info}
                                    showIcon
                                />
                            }
                            <div className={cx({ bcViewerWrapper: true, hasViewerTypeSelection: props.item.viewerType == ViewerType.Unsupported })}>
                                <BCViewer
                                    printRef={printRef}
                                    key={props.item.id}
                                    mode={props.item.mode}
                                    readOnly={(props.item.mode != "edit")}
                                    type={(props.item.viewerType == ViewerType.Unsupported && props.item.userSelectedViewerType) ? props.item.userSelectedViewerType : props.item.viewerType}
                                    mimeType={props.item.mimeType}
                                    language={props.item.language}
                                    data={getViewerData()}
                                    onChange={onChangeData}
                                    panelSize={props.panelSize}
                                    noContentTitle={findMessage.get("101392")}
                                />
                            </div>
                        </div>
                    )}
                </DropZone>
            </BCSkeleton>
        </div>
    )
}

export default DmViewer;