import * as React from 'react';
import { IOverflowSetProps, IOverflowSetItemProps, IOverflowSetState } from './entities';
import IComponentAs from '../fabric-ui-components/interfaces/IComponentAs';
import IComponentAsProps from '../fabric-ui-components/interfaces/IComponentAsProps';
import { OverflowSet, CommandBarButton } from '../fabric-ui-components';



export default class BCOverflowSet extends React.Component<IOverflowSetProps, IOverflowSetState>{
    constructor(props: IOverflowSetProps) {
        super(props);

    }

    render() {
        return (
            <OverflowSet {...this.props} />
        );
    }
}


export {
    CommandBarButton,
    IComponentAs,
    IComponentAsProps,
    IOverflowSetProps,
    IOverflowSetItemProps
}