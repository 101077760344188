import { BCCard, BCLoading, BCSkeleton, ContentType, findMessage } from '@bimser/components';
import React from 'react';
import Style from '../../assets/style.scss';
import IProcessesProps from '../../entities/widgets/IProcessesProps';
import { ExtremeGrid, ExtremeGridAPI, panelType } from '@bimser/common';
import { tileTypeAndKeys, tiles } from '../../utils';
import { MLHelper, isNullOrUndefined } from '@bimser/core';
import { IExtremeGridColumn } from '@bimser/components/src/BCExtremeGrid/entities';

const emptyView = require("../../../../assets/report-empty.svg");
const cx = require('classnames/bind').bind(Style);

const GRID_ID = "PROCESSES";
const pageSize = 8;


export default (props: IProcessesProps) => {

    React.useEffect(() => {
        ExtremeGridAPI.create({
            id: GRID_ID,
            columns: columns,
            uniqueDataField: "id",
            selection: {
                enabled: true,
                mode: "single"
            },
            pager: { visible: false, showPageSizeSelector: false, showNavigationButtons: false },
            paging: { enabled: false, pageSize: props.totalCount },
            filtering: { visible: false },
            scrolling: {
                mode: "infinite",
                rowRenderingMode: "virtual",
                columnRenderingMode: "virtual",
            },
            languageOptions: {
                currentLanguage: MLHelper.currentLanguage,
                supportedLanguages: MLHelper.supportedLanguages
            }
        });

        return () => ExtremeGridAPI.get(GRID_ID).removeDataGrid();
    }, []);

    const columns = React.useMemo((): IExtremeGridColumn[] => [
        {
            name: "id",
            visible: false,
            dataType: "string"
        },
        {
            name: "projectCaption",
            caption: "",
            dataType: "object",
            visible: true,
            columnTitleEnabled: false,
            cellTemplate: (args) => {
                const isSelected = props.selectedData?.id == args.row.data.id;

                return (
                    <div onClick={() => handleSelectionChange({ items: [args.row.data] })} className={cx({ processCaptionCell: true, selected: props.selectedData ? isSelected : false })}>
                        <span className={Style.processCaptionCellLabel}>{MLHelper.getMLText(args.value)} - {MLHelper.getMLText(args.row.data.flowCaption)}</span>
                        <span className={Style.processCaptionCellCount}> ({args.row.data.count})</span>
                    </div>
                )
            }
        },
        {
            name: "count",
            caption: "",
            dataType: "number",
            visible: true,
            filtering: { enabled: false },
            columnTitleEnabled: false,
            cellTemplate: (args) => {
                if (!isNullOrUndefined(props?.selectedTile) && props?.tileCounts) {
                    const isSelected = props.selectedData?.id == args.row.data.id;
                    const selectedTileKey = Object.keys(tileTypeAndKeys).find(key => tileTypeAndKeys[key] == props.selectedTile);
                    const size = Math.log10(args.value) || 0.1;
                    const value = (size * 100) / Math.log10(props.tileCounts?.[selectedTileKey]);
                    return (
                        <div className={cx({ processCountCell: true, selected: props.selectedData ? isSelected : false })} onClick={() => handleSelectionChange({ items: [args.row.data] })}>
                            <div className={Style.processCountCellProgress} style={{ width: `${value}%` }}></div>
                        </div>
                    )
                }

                return <span></span>
            }
        }
    ], [props.tileCounts, props.selectedTile, props.selectedData]);

    React.useEffect(() => {
        ExtremeGridAPI.get(GRID_ID).setColumns(columns);
    }, columns);

    React.useEffect(() => {
        ExtremeGridAPI.get(GRID_ID).setData({
            data: props.data,
            totalCount: props.totalCount
        });
        ExtremeGridAPI.get(GRID_ID).changePageSize(props.totalCount);
    }, [props.data]);

    React.useEffect(() => {
        if (props.loading)
            ExtremeGridAPI.get(GRID_ID).showLoading();
        else
            ExtremeGridAPI.get(GRID_ID).hideLoading();

    }, [props.loading]);

    React.useEffect(() => {
        ExtremeGridAPI.get(GRID_ID).setSelectionIds(props.selectedData ? [props.selectedData.id] : []);
    }, [props.selectedData])


    const onLoadMore = () => {
        const limit = { skip: props.data?.length, take: pageSize }

        if (props.totalCount > limit.skip && !props.loading) {
            props.onLoadMore({
                panelId: props.panelId,
                panelType: panelType.startupLayout,
                data: {
                    limit
                }
            })
        }
    }


    const handleSelectionChange = (args) => {
        const isSelectedSame = props.selectedData?.id == args.items[0].id;

        if (args?.items?.length && !isSelectedSame) {
            props.onClickProcess({
                panelId: props.panelId,
                panelType: panelType.startupLayout,
                data: args?.items[0]
            });
        }
    }

    const renderCustomEmpty = () => (
        <BCLoading show={props.loading}>
            <div className={cx({ emptyView: true, inCard: true, hasDefaultPadding: true })}>
                <img src={emptyView.default} className={Style.emptyViewImg} />
                <span className={Style.emptyViewTitle}>{findMessage.get("102969")}</span>
                <span className={Style.emptyViewDesc}>{findMessage.get("102970")}</span>
            </div>
        </BCLoading>
    )

    return (
        <BCCard
            bordered={false}
            title={<span className={Style.widgetHeader}>{findMessage.get("102316")}</span>}
            className={cx({ processesContainer: true, isEmpty: !props.data?.length })}
        >
            {props.data?.length || (!props.data?.length && props.loading) ? (
                <BCSkeleton
                    loadingStatus={!props.data?.length && props.loading}
                    contentType={ContentType.DashboardProcessesGrid}
                >
                    <ExtremeGrid
                        key={GRID_ID}
                        id={GRID_ID}
                        onLoadMoreRows={onLoadMore}
                        onSelectionChanging={handleSelectionChange}
                        height="100%"
                    />
                </BCSkeleton>
            ) : renderCustomEmpty()}

        </BCCard>
    )
}