import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { List, Map, Record } from 'immutable';

interface IBasePermission {
    id: number,
    code?: string,
    parentId?: number,
    value?: number,
    showingValue?: number,
    secretKey?: string,
    why?: number
}

export interface IPermission extends IBasePermission {
    name?: IDictionary<string>,
    description?: IDictionary<string>,
    children?: IPermission[],
    parentInfo?: string[]
    allParentIds?: number[]
}

export interface IReadonlyPermission extends IBasePermission {
    name?: Map<string, string>,
    description?: Map<string, string>,
    children?: List<Permission>
    parentInfo?: List<string>
    allParentIds?: List<number>
}

export type Permission = ReadonlyRecord<IReadonlyPermission>;

export const PermissionFactory = Record<IReadonlyPermission>({
    id: null,
    code: null,
    parentId: null,
    value: null,
    showingValue: null,
    secretKey: null,
    why: null,
    name: null,
    description: null,
    children: null,
    parentInfo: null,
    allParentIds: null
});

export const PermissionFactoryFromJS = function (data: IPermission) {

    let _children: Permission[] = data && data.children && data.children.map(child => {
        return PermissionFactoryFromJS(child);
    })

    let _parentInfo: string[] = data && data.parentInfo && data.parentInfo.map(w => {
        return w;
    })

    let _allParentIds: number[] = data && data.allParentIds && data.allParentIds.map(w => {
        return w;
    })

    return PermissionFactory({
        ...data,
        name: Map(data.name),
        description: Map(data.description),
        children: List(_children),
        parentInfo: List(_parentInfo),
        allParentIds: List(_allParentIds)
    });
}
