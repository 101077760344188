import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";
import { TileTypes } from "../TileTypes";

interface IBaseCountTilesState {
    selectedTile: TileTypes,
    loading: boolean
}

export interface ICountTilesState extends IBaseCountTilesState {
    tileCounts: IDictionary<number>
}

export interface IReadonlyCountTilesState extends IBaseCountTilesState {
    tileCounts: Map<string, number>
}

export type CountTilesState = ReadonlyRecord<IReadonlyCountTilesState>;

export const CountTilesStateFactory = Record<IReadonlyCountTilesState>({
    tileCounts: Map<string, number>(),
    selectedTile: TileTypes.Started,
    loading: false
});

export const CountTilesStateFactoryFromJS = function (data: ICountTilesState) {
    return CountTilesStateFactory({
        ...data,
        tileCounts: Map(data.tileCounts)
    })
}