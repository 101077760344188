import Carousel, { CarouselRef } from 'antd/lib/carousel';
import * as React from 'react';
import { ICarouselProps } from './entities';

export default class BCCarousel extends React.Component<ICarouselProps, {}>{
    constructor(props: ICarouselProps) {
        super(props);

        this.getDomRef = this.getDomRef.bind(this);
    }

    private getDomRef(ref: CarouselRef) {
        if (this.props.getDomReference) {
            this.props.getDomReference(ref)
        }
    }

    render() {
        return <Carousel {...this.props} ref={_ref => this.getDomRef(_ref)}>{this.props.children}</Carousel>
    }
}

export { ICarouselProps }