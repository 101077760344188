import { connect } from "react-redux";
import { BaseState } from "../../../entities/BaseState";
import ChangeMyPassword from "../components/ChangeMyPassword";
import { IChangeMyPasswordComponentProps } from "../entities/IChangeMyPasswordComponentProps";
import { getChangePasswordRequirementsTransaction, onClickChangeMyPasswordTransaction } from '../actions';
import { getPasswordRequirements } from '../../../system/passwordRequirements/selectors';


// state to component properties
const mapStateToProps = function (state: BaseState, props: IChangeMyPasswordComponentProps): IChangeMyPasswordComponentProps {
    let _passwordRequirements = getPasswordRequirements(state as any)
    return {
        passwordRequirements: _passwordRequirements
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IChangeMyPasswordComponentProps {
    return {
        onClickChangeMyPassword: function (data: any) {
            dispatch(onClickChangeMyPasswordTransaction(data));
        },
        getChangePasswordRequirements: function () {
            dispatch(getChangePasswordRequirementsTransaction())
        }
    };
}

// react - redux connection
const ChangeMyPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ChangeMyPassword);

export default ChangeMyPasswordContainer;