import IconLanguage from "@bimser/icons/16/language";
import * as React from "react";
import { BCButton, BCEditor, BCEditorRef, BCInputGroup } from "..";
import { findMessage } from "../BCIntl";
import BCLabel from "../BCLabel";
import BCPopover from "../BCPopover";
import BCScrollbars from "../BCScrollbars";
import * as Style from './assets/style.scss';
import { IProps } from './entities';

const inputStyle: React.CSSProperties = { width: 'calc(100% - 35px)' };
const buttonStyle: React.CSSProperties = { width: '35px', fontWeight: 900, fontSize: '14px' };
const scrollStyle: React.CSSProperties = { height: '100%', width: '100%' };
const overlayStyle: React.CSSProperties = { maxWidth: 425 };
const labelStyle: React.CSSProperties = { fontSize: 13 };

const BCHTMLTextBoxMultiLanguage = React.memo(React.forwardRef((props: IProps, ref: React.ForwardedRef<BCEditorRef>) => {

    const renderPopoverContent = () => {
        return (<div className={Style.hrFormMLPopover}>
            <BCScrollbars styles={scrollStyle} >
                {
                    props.supportedLanguages.map((item: any) => {
                        if (item.id != props.currentLanguage.id) {
                            let model = props.values[item.culture];
                            return (<div
                                className={Style.popoverRow}
                                key={item.name}>
                                <div className={"ant-col-7 ant-col-offset-1 ant-form-item-label"}>
                                    <label title={item.name}>{item.name}</label>
                                </div>
                                <div className={"ant-col-15 ant-col-offset-1 "}>
                                    <BCEditor
                                        model={model}
                                        onModelChange={(model: string) => { onLanguageValueChange(model, item.culture) }}
                                        language={props.currentLanguage.twoLetterCulture}
                                        textAlign={props.textAlign}
                                        ref={ref}
                                        {...props.editorProps}
                                        readOnly={props.readOnly}
                                    />
                                </div>
                            </div>);
                        }
                    })
                }
            </BCScrollbars>
        </div>);
    }

    const onLanguageValueChange = (model: string, culture: string) => {
        props?.onLanguageValueChange(culture, model);
    }

    const onVisibilityChange = (visible: boolean) => {
        props.onVisibilityChange?.(visible);
    }

    const renderPopoverTitle = () => {
        return props.title || (<BCLabel customStyles={labelStyle}>{findMessage.get("100775")}</BCLabel>);
    }

    const renderInput = () => {

        //Custom input
        if (props.renderInput) {
            return (
                <div style={inputStyle}>
                    {props.renderInput()}
                </div>
            )
        }

        return (
            <BCEditor
                model={props.values && props.values[props.currentLanguage.culture]}
                onModelChange={(model: string) => { onLanguageValueChange(model, props.currentLanguage.culture) }}
                language={props.currentLanguage.culture}
                textAlign={props.textAlign}
                readOnly={props.readOnly}
                ref={ref}
                {...props.editorProps}
            />
        )
    }

    return (
        <BCInputGroup compact={true}>
            {renderInput()}
            <BCPopover
                content={renderPopoverContent()}
                title={renderPopoverTitle()}
                trigger={props.trigger || "click"}
                placement={props.placement || "leftTop"}
                overlayStyle={overlayStyle}
                onVisibleChange={onVisibilityChange}>
                <BCButton
                    style={buttonStyle}
                    size={props.size} icon={<IconLanguage />}
                />
            </BCPopover>
        </BCInputGroup>
    );

}))

export default BCHTMLTextBoxMultiLanguage
export { IProps as IHTMLTextBoxMultiLanguageProps };