import React from 'react';
import Style from "../assets/style.scss";
import CountTilesContainer from '../containers/widgets/CountTilesContainer';
import { BCLoading, Col, Row, findMessage } from '@bimser/components';
import RecentAppsContainer from '../containers/widgets/RecentAppsContainer';
import RecentFilesContainer from '../containers/widgets/RecentFilesContainer';
import AnnouncementsContainer from '../containers/widgets/AnnouncementsContainer';
import ProcessesContainer from '../containers/widgets/ProcessesContainer';
import IconRefresh from "@bimser/icons/16/refresh2";
import ProcessDetailsContainer from '../containers/widgets/ProcessDetailsContainer';
import IProps from '../entities/IProps';
import { panelType } from '@bimser/common';
import AppFormContainer from '../../../common/components/appForm';

export default (props: IProps) => {
    const handleRefresh = (type: "justProcessBased" | "all") => {
        props.handleRefresh({
            panelId: props.panelGuid,
            panelType: panelType.startupLayout,
            data: type
        })
    }

    const today = new Date().getHours()

    const checkTime = (hour: number) => {
        if (0 <= hour && hour < 5) {
            return "103019"
        }
        else if (5 <= hour && hour < 12) {
            return "103016"
        }
        else if (12 <= hour && hour < 20) {
            return "103017"
        }
        else if (20 <= hour && hour <= 23) {
            return "103018"
        }
    }

    React.useEffect(() => {
        if (props.isActive && props.willRefresh) {
            handleRefresh("justProcessBased")
        }
    }, [props.isActive])

    const renderForm = React.useMemo(() => {
        if (!props.dashboardFormData.projectName) return <></>;

        return (
            <AppFormContainer
                childFormData={{ ...props.dashboardFormData, loading: false, childFormDataLoaded: true }}
                panelGuid={props.panelGuid + "_form"}
                isPanelActive
                windowType={"panel"}
                hideFormToolbar
            />
        )
    }, [props.dashboardFormData])

    if (props.hasDashboard) return (
        <BCLoading show={!props.dashboardFormData.projectName}>{renderForm}</BCLoading>
    );

    return (
        <div className={Style.dashboardContainer}>
            <>
                <Row gutter={[21, 25]}>
                    <Col span={24}>
                        <IconRefresh className={Style.refreshIcon} onClick={() => handleRefresh("all")} />
                        <span className={Style.greetingTitle}>{findMessage.get(checkTime(today))}, {props.user.firstname}</span>
                    </Col>
                    <Col span={24}>
                        <RecentAppsContainer panelId={props.panelGuid} />
                    </Col>
                    <Col span={24}>
                        <CountTilesContainer panelId={props.panelGuid} dashboardType="user" />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <ProcessesContainer panelId={props.panelGuid} dashboardType="user" />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <ProcessDetailsContainer panelId={props.panelGuid} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <AnnouncementsContainer panelId={props.panelGuid} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <RecentFilesContainer panelId={props.panelGuid} />
                    </Col>
                </Row>
            </>
        </div>
    )
}