import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { DataTypesState, DataTypesStateFactory, DataTypesStateFactoryFromJS, IDataTypesState } from "../dataTypes/entities/IDataTypesState";

interface IBaseDataSecurityState {
    activeChildPanelGuid: string | null;
    activeNodeId: string | null;
    loading: boolean;
}

export interface IDataSecurityState extends IBaseDataSecurityState {
    dataTypes: IDataTypesState;
}

export interface IReadonlyDataSecurityState extends IBaseDataSecurityState {
    dataTypes: DataTypesState;
}

export type DataSecurityState = ReadonlyRecord<IReadonlyDataSecurityState>;

export const DataSecurityStateFactory = Record<IReadonlyDataSecurityState>({
    activeChildPanelGuid: null,
    activeNodeId: null,
    loading: false,
    dataTypes: DataTypesStateFactory()
});

export const DataSecurityStateFactoryFromJS = function (data: IDataSecurityState) {
    return DataSecurityStateFactory({ 
        ...data,
        dataTypes: DataTypesStateFactoryFromJS(data.dataTypes)
     });
}