export enum ViewerType {
    Unsupported = 0,
    TextViewer = 1,
    VideoViewer = 2,
    PDFViewer = 3,
    eBA7Form = 4,
    TiffViewer = 5,
    ImageViewer = 6,
    OfficeViewer = 7,
    GroupDocsViewer = 8,
    WebCadViewer = 9,
    NotificationViewer = 10
}

export default ViewerType;