import * as React from "react";
import { IItem } from '@bimser/core';
import BCBadge from '../../BCBadge';
import * as Styles from '../assets/style.scss';
import { GetIconComponent } from "@bimser/icons";
let classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

export default class MenuItem extends React.Component<{ item: IItem, showEmptyIconSpace?: boolean, showShortcutSpace?: boolean }, {}> {
    badgeStyle: React.CSSProperties = {
        marginLeft: "5px",
    }
    bcIconStyles: React.CSSProperties = {
        verticalAlign: 'inherit',
        lineHeight: '100%'
    }
    constructor(props: { item: IItem }) {
        super(props);
    }


    renderIcon() {
        if (this.props.item.icon) {
            return GetIconComponent(this.props.item.icon, { style: this.bcIconStyles });
        } else {
            if (this.props.showEmptyIconSpace) {
                return <div className="bcIcon" style={this.bcIconStyles} />;
            }
        }
    }

    renderText() {
        if (!this.props.item.text) return null;

        return (
            <span  className={'ant-menu-text-custom'}>{this.props.item.text}</span>
        )
    }

    renderShortcut() {

        if (this.props.item.shortCut || this.props.showShortcutSpace) {

            let classNames = cx({
                menuItemShortcutText: true,
                shortCutPlaceholder: !this.props.item.shortCut
            });

            return (
                <span className={classNames + ' menuItemShortcutText'}>
                    {this.props.item.shortCut ? "(" + this.props.item.shortCut + ")" : null}
                </span >
            )

        }

    }

    renderBadge() {
        if (!this.props.item.badge) return null;

        return (
            <BCBadge {...this.props.item.badge} style={this.badgeStyle} />
        )
    }

    render() {

        return (
            <React.Fragment>
                {this.renderIcon()}
                {this.renderText()}
                {this.renderShortcut()}
                {this.renderBadge()}
            </React.Fragment>
        )
    }
}
