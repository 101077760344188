import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseGroupContent {
    id?: number,
    groupId: number,
    userId: number
}

export interface IGroupContent extends IBaseGroupContent { }

export interface IReadonlyGroupContent extends IBaseGroupContent { }

export type GroupContent = ReadonlyRecord<IReadonlyGroupContent>;

export const GroupContentFactory = Record<IReadonlyGroupContent>({
    id: 0,
    groupId: 0,
    userId: 0
});

export const GroupContentFactoryFromJS = function (data: IGroupContent) {
    return GroupContentFactory(data);
}