import { BaseState } from "../../../../../entities/BaseState";
import IUserSelectModalProps from "../entities/IUserSelectModalProps";
import { createSelector } from 'reselect';

const getUserSelectModalDataSelector = (state: BaseState, props: IUserSelectModalProps) => {
    return state.modals.data.get(props.modalId, null);
}

const getUserSelectModalData = createSelector(
    [
        getUserSelectModalDataSelector
    ],
    (data: any | null) => {
        return data;
    }
)

export {
    getUserSelectModalData,
    getUserSelectModalDataSelector
}