
export enum SortOrder {
    Asc = 1,
    Desc = 2
}

export interface ILoadSortOptions {
    Column: string;
    Type: SortOrder;
}
