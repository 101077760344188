import React, { lazy, Suspense } from 'react';
import { IUserSettingsModalProps } from './entities';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-others" */ "./containers/UserSettingsModalContainer"));

function Container(props: React.PropsWithChildren<IUserSettingsModalProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;