import * as React from "react";
import ContentLoader from 'react-content-loader';
import sizeme from 'react-sizeme';
import findMessage from '../BCIntl/helper/findMessage';
import getContent, { ContentType } from './Contents';
import { ISkeletonProps } from './entities';
import IContentLoaderProps from './entities/ContentLoaderProps';

interface IState { parentWidth: number, parentHeight: number }
class BCSkeleton extends React.Component<ISkeletonProps, IState> {

    constructor(props: ISkeletonProps) {
        super(props);
    }

    contentLoader: IContentLoaderProps = {
        rtl: false,
        ariaLabel: findMessage.get("100127"),
        speed: 2
        // primaryColor: this.props.primaryColor,
        // primaryOpacity: this.props.primaryOpacity,
        // secondaryColor: this.props.secondaryColor,
        // secondaryOpacity: this.props.secondaryOpacity,
        // uniquekey: this.props.uniquekey
    }

    render() {
        if (this.props.loadingStatus) {
            return (
                <ContentLoader
                    {...this.contentLoader}
                    animate={this.props.animate || true}
                    style={this.props.style}
                    className={this.props.className}
                    height={this.props.height || this.props.size.height || 800}
                    width={this.props.width || this.props.size.width || 480}
                    {...(this.props.primaryColor ? { primaryColor: this.props.primaryColor } : {})}
                    {...(this.props.secondaryColor ? { secondaryColor: this.props.secondaryColor } : {})}
                >
                    {getContent(this.props)}
                </ContentLoader >
            )
        } else {
            return (
                <>
                    {this.props.children}
                </>
            )
        }
    }
}

export default sizeme({ refreshRate: 1000, monitorHeight: true })<ISkeletonProps>(BCSkeleton);
export { ISkeletonProps, ContentType };
