import { all } from 'redux-saga/effects';
import DigitalSignatureSaga from '../components/DigitalSignature/sagas';
import DMExplorerSagas from "../components/DMExplorer/sagas";
import DmViewerRootSaga from '../components/dmViewer/sagas';
import ExtremeGridSaga from "../components/ExtremeGrid/sagas";
import FormSagas from "../components/Forms/sagas";
import LoginSaga from "../components/Login/sagas";
import MobileSignatureSaga from '../components/MobileSignature/sagas';
import ModalFrameManager from "../components/ModalFrameManager/sagas";
import ModalManagerSaga from "../components/ModalManager/sagas";
import UITourSaga from '../components/UITour/sagas';
import { UploadManagerSaga } from '../components/UploadManager/';
import cultureSagas from '../system/culture/sagas';
import languageSagas from '../system/language/sagas';
import locationSagas from '../system/location/sagas';
import HubConnectionSaga from "./HubConnection";
import SynergyLogSaga from './SynergyLogSaga'

export default function* rootSaga(): IterableIterator<any> {
    yield all([
        LoginSaga(),
        ModalManagerSaga(),
        ModalFrameManager(),
        DMExplorerSagas(),
        FormSagas(),
        cultureSagas(),
        languageSagas(),
        locationSagas(),
        ExtremeGridSaga(),
        HubConnectionSaga(),
        UploadManagerSaga(),
        UITourSaga(),
        DigitalSignatureSaga(),
        MobileSignatureSaga(),
        SynergyLogSaga(),
        DmViewerRootSaga()
    ]);
}

