import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, ISalary, Salary, SalaryFactory, SalaryFactoryFromJS } from "..";

export interface ISalaryListFormContent extends IBaseFormContent {
    selectedSalary?: ISalary,
}

export interface IReadonlySalaryListFormContent extends IBaseFormContent {
    selectedSalary?: Salary
}

export type SalaryListFormContent = ReadonlyRecord<IReadonlySalaryListFormContent>;

export const SalaryListFormContentFactory = Record<IReadonlySalaryListFormContent>({
    panelId: "",
    selectedSalary: SalaryFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: true,
});

export const SalaryListFormContentFactoryFromJS = function (data: ISalaryListFormContent) {
    const _selectedSalary : Salary = data.selectedSalary ? SalaryFactoryFromJS(data.selectedSalary) : SalaryFactory()

    return SalaryListFormContentFactory({
        panelId: data.panelId,
        selectedSalary: _selectedSalary,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
    });
}