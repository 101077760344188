import * as moment from 'moment';
import * as React from "react";
import BCDatePicker from '../../../BCDatePicker';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defaultStyle: React.CSSProperties = { width: '100%' };

export default function DatePickerProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, isoZoneString: string) => {
        if (item && item.key && item.key === 'disabledDates') {
            props.onValueChanged(item, isoZoneString ? [isoZoneString] : null);
        } else {
            props.onValueChanged(item, isoZoneString || null);
        }
    }

    const getDateValue = (value?: string) => {
        if (value && typeof value === "string") {
            return moment(new Date(value), props.item.options || null);
        } else if (value && typeof value === "object") {
            return moment(new Date((value as string[])[0]), props.item.options || null);
        }
        return null;
    }

    const onChange = (date: moment.Moment, isoZoneString: string, dateString: string) => {
        props.customChangeEvent ? props.customChangeEvent(dateString) : replaceValue(props.item, isoZoneString)
    }

    return (
        <BCDatePicker
            value={getDateValue(props.value)}
            placeholder={props.item.placeholder}
            onChange={onChange}
            style={props.style || defaultStyle}
            disabled={props.item.disabled || props.item.readOnly}
            allowClear
            format={props.item.options?.format}
            showTime={props.item.options?.showTime}
            showToday={props.item.options?.showToday}
        />
    )
}