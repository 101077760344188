import { IExtremeGridState, IExtremeGridColumn, IFilterItem } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeFilterItemsReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<IFilterItem>>): IExtremeGridState {
    let newColumns: Array<IExtremeGridColumn> = state.columns.map((item) => {
        let foundColumnIndex = action.payload.findIndex((c => c.columnName == item.name));
        let foundColumn = action.payload[foundColumnIndex];
        if (foundColumn) {
            if (!item.filtering || (item.filtering?.enabled !== false && (item.filtering.value !== foundColumn.value || item.filtering.selectedOperation !== foundColumn.operation))) {
                // changed
                let filtering = item.filtering || {};

                return Object.assign({}, item, {
                    filtering: {
                        ...filtering,
                        value: foundColumn.value,
                        selectedOperation: foundColumn.operation
                    }
                });

            }
            else {
                // unmodified filtered column
                return item;
            }
        } else {
            if (item.filtering && item.filtering.value !== undefined) {
                // clear filtering props
                return Object.assign({}, item, {
                    filtering: {
                        ...item.filtering,
                        value: null,
                    }
                });
            }
            else {
                // unmodified
                return item;
            }
        }
    });

    return {
        ...state,
        columns: newColumns
    };
}