import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

export interface ILayoutItemDesignerProps {
    selected?: boolean
}

export type LayoutItemDesignerProps = ReadonlyRecord<ILayoutItemDesignerProps>;

export const LayoutItemDesignerPropsFactory = Record<ILayoutItemDesignerProps>({
    selected: false
});

export const LayoutItemDesignerPropsFactoryFromJS = function (data: ILayoutItemDesignerProps) {
    return LayoutItemDesignerPropsFactory(data);
}