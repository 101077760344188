import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { ILoadFilterGroup, LoadFilterGroup, LoadFilterGroupFactoryFromJS } from "./ILoadFilterGroup";
import { ILoadFilterItem, LoadFilterItem, LoadFilterItemFactoryFromJS } from "./ILoadFilterItem";
import { IPagination, Pagination, PaginationFactory, PaginationFactoryFromJS } from './IPagination';
import { ISort, Sort, SortFactoryFromJS } from './ISort';


export interface IBaseLoadOptions { }

export interface ILoadOptions extends IBaseLoadOptions {
    filters: Array<ILoadFilterItem | ILoadFilterGroup>,
    sorts: Array<ISort>,
    pagination: IPagination
}

export interface IReadonlyLoadOptions extends IBaseLoadOptions {
    filters: List<LoadFilterItem | LoadFilterGroup>,
    pagination: Pagination,
    sorts: List<Sort>
}

export type LoadOptions = ReadonlyRecord<IReadonlyLoadOptions>;

export const LoadOptionsFactory = Record<IReadonlyLoadOptions>({
    filters: List(),
    pagination: PaginationFactory(),
    sorts: List(),
});


export const LoadOptionsFactoryFromJS = function (data: ILoadOptions) {
    return LoadOptionsFactory({
        filters: data.filters?.map((d: ILoadFilterItem | ILoadFilterGroup) => (d as ILoadFilterItem)?.Column ?
            LoadFilterItemFactoryFromJS(d as ILoadFilterItem) : LoadFilterGroupFactoryFromJS(d as ILoadFilterGroup)) as any,
        pagination: PaginationFactoryFromJS(data.pagination),
        sorts: List(data?.sorts?.map(i => SortFactoryFromJS(i)))
    });
}



// "LoadOptions": {
//     "Filters": [
//                     {
//                                     "Column": "OuterFilter",
//                                     "Not": false,
//                                     "Type": 1,
//                                     "Value": "out",
//                                     "ItemType": 1
//                     },
//                     {
//                                     "Operator": 1,
//                                     "Filters": [
//                                                     {
//                                                                     "Column": "InnerGroupedFilter1",
//                                                                     "Not": false,
//                                                                     "Type": 1,
//                                                                     "Value": "inner1",
//                                                                     "ItemType": 1
//                                                     },
//                                                     {
//                                                                     "Column": "InnerGroupedFilter2",
//                                                                     "Not": false,
//                                                                     "Type": 1,
//                                                                     "Value": "inner2",
//                                                                     "ItemType": 3
//                                                     },
//                                                     {
//                                                                     "Operator": 1,
//                                                                     "Filters": [
//                                                                                     {
//                                                                                                     "Column": "InnerGroupedFilter1",
//                                                                                                     "Not": false,
//                                                                                                     "Type": 1,
//                                                                                                     "Value": "inner1",
//                                                                                                     "ItemType": 1
//                                                                                     },
//                                                                                     {
//                                                                                                     "Column": "InnerGroupedFilter2",
//                                                                                                     "Not": false,
//                                                                                                     "Type": 1,
//                                                                                                     "Value": "inner2",
//                                                                                                     "ItemType": 3
//                                                                                     }
//                                                                     ]
//                                                     }
//                                     ]
//                     }
//     ],
//     "Sorts": [
//                     {
//                                     "column": "SortAsc",
//                                     "type": 1
//                     },
//                     {
//                                     "column": "SortDesc",
//                                     "type": 2
//                     }
//     ],
//     "Pagination": {
//                     "Skip": 10,
//                     "Take": 20
//     }
// }
