import React, { lazy, Suspense } from 'react';
import IWFListProps from "./entities/IWFListProps"
import { injectSaga } from "app-init"

const WorkflowManagementContainerLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./containers"));

function WFListContainer(props: React.PropsWithChildren<IWFListProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <WorkflowManagementContainerLazy {...props} />
        </Suspense>
    );
}
WFListContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-workflow" */ "./sagas").then(({ default: saga }) => {
        injectSaga("workflowList", saga);
    })
}

export default WFListContainer;