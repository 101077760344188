import Tooltip from 'antd/lib/tooltip';
import * as React from "react";
import * as Styles from './assets/style.scss';
import { IAdjustOverflow, IRenderFunction, ITooltipPlacement, ITooltipProps } from './entities';

const BCTooltip: React.FunctionComponent<ITooltipProps> = props => {
    const [visible, setVisible] = React.useState(false);
    Styles;

    const onToggleVisible = () => {
        setVisible(!visible);
    }

    const renderCustomTitle = () => props.renderCustomTitle?.() || props.title;

    return (
        <Tooltip
            className={props.className}
            arrowPointAtCenter={props.arrowPointAtCenter}
            autoAdjustOverflow={props.autoAdjustOverflow}
            builtinPlacements={props.builtinPlacements}
            defaultVisible={props.defaultVisible}
            getPopupContainer={props.getPopupContainer}
            getTooltipContainer={props.getTooltipContainer}
            mouseEnterDelay={props.mouseEnterDelay}
            mouseLeaveDelay={props.mouseLeaveDelay}
            onVisibleChange={onToggleVisible}
            openClassName={props.openClassName}
            overlay={props.overlay}
            overlayClassName={props.overlayClassName}
            overlayStyle={props.overlayStyle}
            placement={props.placement}
            prefixCls={props.prefixCls}
            style={props.style}
            title={renderCustomTitle()}
            transitionName={props.transitionName}
            trigger={props.trigger}
            visible={visible}
        >
            {props.children}
        </Tooltip>
    )

};

export default BCTooltip

export { ITooltipProps, IAdjustOverflow, IRenderFunction, ITooltipPlacement };
