import { Record, Map, List } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Mobile } from "@bimser/common";
import { nodeActionType, childrenDisplayMode } from '../../../common/components/panelMenu/entities/enums';

interface IBaseMenuResult {
    id: string,
    displayOrderNo: number,
    nodeKey: string,
    mobileFormView: Mobile.MobileFormView,
    nodeActionType: nodeActionType,
    childrenDisplayMode: childrenDisplayMode,
}
export interface ISearchMenuResult extends IBaseMenuResult {
    caption: IDictionary<string>,
    children: Array<ISearchMenuResult>
    fullCaption: IDictionary<string>,
    parameters: IDictionary<any>,
    parents: Array<string>,
}

export interface IReadOnlySearchMenuResult extends IBaseMenuResult {
    caption: Map<string, string>,
    children: List<SearchMenuResult>,
    fullCaption: Map<string, string>,
    parameters: Map<string, any>,
    parents: List<string>,
}

export type SearchMenuResult = ReadonlyRecord<IReadOnlySearchMenuResult>;

export const SearchMenuResultFactory = Record({
    id: "",
    caption: Map<string, string>(),
    children: List<SearchMenuResult>(),
    childrenDisplayMode: childrenDisplayMode.None,
    nodeKey: "",
    parameters: Map<string, any>(),
    parents: List<string>(),
    fullCaption: Map<string, string>(),
    mobileFormView: Mobile.MobileFormView.WebView,
    nodeActionType: nodeActionType.None,
});

export const SearchMenuResultFactoryFromJS = (data: ISearchMenuResult) => {
    let _fullCaption: IDictionary<string> = data.fullCaption ? Object.keys(data.fullCaption).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.fullCaption[current];
        return previous;
    }, {}) : {};

    let _caption: IDictionary<string> = data.caption ? Object.keys(data.caption).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.caption[current];
        return previous;
    }, {}) : {};

    let _parameters: IDictionary<string> = data.parameters ? Object.keys(data.parameters).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.parameters[current];
        return previous;
    }, {}) : {};

    let _children = data.children.map((child: ISearchMenuResult) => SearchMenuResultFactoryFromJS(child))


    return SearchMenuResultFactory({
        ...data,
        parents: List(data.parents),
        caption: Map(_caption),
        fullCaption: Map(_fullCaption),
        parameters: Map(_parameters),
        children: List(_children)
    })
}