import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { BrowserProperties } from "./BrowserProperties";
import { DashboardSettings, DashboardSettingsFactoryFromJS, IDashboardSettings } from './DashboardSettings';
import { DomainProperties, DomainPropertiesFactory, DomainPropertiesFactoryFromJS, IDomainProperties } from "./DomainProperties";
import { GetUserSettingParametersResponse, GetUserSettingParametersResponseFactory, GetUserSettingParametersResponseFactoryFromJS, IGetUserSettingParametersResponse } from "./GetUserSettingParametersResponse";
import { GetUserSettingPermissionsResponse, GetUserSettingPermissionsResponseFactoryFromJS, IGetUserSettingPermissionsResponse } from "./GetUserSettingPermissionsResponse";
import IAdminToolsPermissions from "./IAdminToolsPermissions";
import { AdminToolsPermissionsStateFactory, AdminToolsPermissionsStateFactoryFromJS } from './IAdminToolsPermissionsState';
import { BrowserPropertiesFactory, BrowserPropertiesFactoryFromJS, IBrowserProperties } from "./IBrowserProperties";
import { CaptchaState, CaptchaStateFactory, CaptchaStateFactoryFromJS, ICaptchaState } from './ICaptchaState';
import { IUserPermissionsState, UserPermissionsState, UserPermissionsStateFactory, UserPermissionsStateFactoryFromJS } from './IUserPermissionsState';
import { ILoginFormProperties, LoginFormProperties, LoginFormPropertiesFactory, LoginFormPropertiesFactoryFromJS } from './LoginFormProperties';
import { ILoginUser, LoginUser, LoginUserFactoryFromJS } from "./LoginUser";
import { IMFAPropertiesState, MFAPropertiesState, MFAPropertiesStateFactory, MFAPropertiesStateFactoryFromJS } from "./MultiFactorAuth";
import { ITheme, Theme, ThemeFactory, ThemeFactoryFromJS } from "./ITheme";

interface ILoginBaseState {
    hasDashboardForm: boolean;
    adminToolsVisibility: boolean;
    userMenuVisibility: boolean;
    selectedCulture: string;
    token?: string;
    chekAuthenticationFinished?: boolean;
    loginAsUser?: any;
    indefiniteAccessToken: boolean;
    isLoading: boolean;
    isDelegation: boolean;
    fingerPrint: string;
}

export interface ILoginState extends ILoginBaseState {
    data?: ILoginUser,
    settingPermissions: IGetUserSettingPermissionsResponse,
    settingParameters: IGetUserSettingParametersResponse,
    adminToolsPermissions: IAdminToolsPermissions,
    loginFormProperties: ILoginFormProperties,
    userPermissions: IUserPermissionsState,
    captcha: ICaptchaState;
    MFAProperties: IMFAPropertiesState;
    dashboardSettings: IDashboardSettings;
    domainProperties: IDomainProperties;
    browserProperties: IBrowserProperties;
    theme: ITheme;
}

export interface IReadonlyLoginState extends ILoginBaseState {
    data?: LoginUser,
    settingPermissions: GetUserSettingPermissionsResponse,
    settingParameters: GetUserSettingParametersResponse,
    adminToolsPermissions: IAdminToolsPermissions,
    loginFormProperties: LoginFormProperties,
    userPermissions: UserPermissionsState,
    captcha: CaptchaState;
    MFAProperties: MFAPropertiesState;
    dashboardSettings: DashboardSettings;
    domainProperties: DomainProperties;
    browserProperties: BrowserProperties;
    theme: Theme;
}

export type LoginState = ReadonlyRecord<IReadonlyLoginState>;

export const LoginStateFactory = Record<IReadonlyLoginState>({
    data: null,
    token: null,
    isLoading: null,
    settingPermissions: null,
    isDelegation: false,
    fingerPrint: null,
    loginAsUser: null,
    settingParameters: GetUserSettingParametersResponseFactory(),
    chekAuthenticationFinished: null,
    selectedCulture: 'en-US',
    adminToolsPermissions: AdminToolsPermissionsStateFactory(),
    loginFormProperties: LoginFormPropertiesFactory(),
    userPermissions: UserPermissionsStateFactory(),
    captcha: CaptchaStateFactory(),
    MFAProperties: MFAPropertiesStateFactory(),
    dashboardSettings: null,
    indefiniteAccessToken: false,
    hasDashboardForm: true,
    adminToolsVisibility: true,
    userMenuVisibility: true,
    domainProperties: DomainPropertiesFactory(),
    browserProperties: BrowserPropertiesFactory(),
    theme: ThemeFactory()
});

export const LoginStateFactoryFromJS = function (data: ILoginState) {
    return LoginStateFactory({
        data: LoginUserFactoryFromJS(data.data),
        token: data.token,
        loginAsUser: data.loginAsUser,
        isLoading: data.isLoading,
        isDelegation: data.isDelegation,
        fingerPrint: data.fingerPrint,
        indefiniteAccessToken: data.indefiniteAccessToken,
        settingPermissions: GetUserSettingPermissionsResponseFactoryFromJS(data.settingPermissions),
        settingParameters: GetUserSettingParametersResponseFactoryFromJS(data.settingParameters),
        loginFormProperties: LoginFormPropertiesFactoryFromJS(data.loginFormProperties),
        chekAuthenticationFinished: data.chekAuthenticationFinished,
        selectedCulture: data.selectedCulture,
        adminToolsPermissions: AdminToolsPermissionsStateFactoryFromJS(data.adminToolsPermissions),
        userPermissions: UserPermissionsStateFactoryFromJS(data.userPermissions),
        captcha: CaptchaStateFactoryFromJS(data.captcha),
        MFAProperties: MFAPropertiesStateFactoryFromJS(data.MFAProperties),
        dashboardSettings: data.dashboardSettings ? DashboardSettingsFactoryFromJS(data.dashboardSettings) : null,
        hasDashboardForm: data.hasDashboardForm,
        adminToolsVisibility: data.adminToolsVisibility,
        userMenuVisibility: data.userMenuVisibility,
        domainProperties: DomainPropertiesFactoryFromJS(data.domainProperties),
        browserProperties: BrowserPropertiesFactoryFromJS(data.browserProperties),
        theme: ThemeFactoryFromJS(data.theme)
    });
};