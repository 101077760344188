import { BCAjax, BCAjaxRequestConfig } from "@bimser/components";
import { IDictionary } from "@bimser/core";

export interface IBaseServices extends IDictionary<BCAjaxRequestConfig> {
    login: BCAjaxRequestConfig;
    IntegrationManager: BCAjaxRequestConfig;
    hr: BCAjaxRequestConfig,
    socket: BCAjaxRequestConfig
}

const Services = BCAjax.getInstances<IBaseServices>();

export default Services;