import { IBaseAction, isNullOrUndefined } from "@bimser/core";
import { call, debounce, fork, put, select, take } from 'redux-saga/effects';
import { ServiceList } from '../../../../..';
import { BaseState } from '../../../../../entities/BaseState';
import { changeModalProps, closeModalAction, openModalAction } from '../../../actions';
import { changeModalDataAction } from '../../../actions/changeModalDataAction';
import { onSearchData } from '../actions';
import * as ActionTypes from '../actions/actionTypes';
import IUserSelectModalPayload from '../entities/IUserSelectModalPayload';
import { getUserSelectModalDataSelector } from '../selectors';
const _unionBy = require("lodash/unionBy");

export function* watchUserSelectSearchChange() {
    yield debounce(500, ActionTypes.ON_SEARCH_CHANGE, handleOnSearchChange);
}

function* handleOnSearchChange(action: IBaseAction<any>) {
    try {
        yield call(updateModalLoading, action.payload.id, true);
        let userData: any[] = yield select((state: BaseState) => getUserSelectModalDataSelector(state, { modalId: action.payload.id }));
        if (isNullOrUndefined(userData)) userData = [];
        let response = yield ServiceList.hr.Users.GetAutocompleteUsers.call({ searchText: action.payload.value });
        if (response) {
            if (response.length > 0) {
                userData = _unionBy(response, userData, "id")
                yield put(changeModalDataAction({
                    modalId: action.payload.id,
                    data: userData
                }));

                //modules>hr>contnet>detailUsers
                yield put(onSearchData(userData));
            }
        }


        yield call(updateModalLoading, action.payload.id, false);
    } catch (error) {
        console.log("function*handleOnSearchChange -> error", error)
        yield call(updateModalLoading, action.payload.id, false);
    }
}

export function* openUserSelectModal(payload: IUserSelectModalPayload) {
    try {
        let resolvePromise: any = null;
        let rejectPromise: any = null;

        let promise = new Promise<any>((resolve, reject) => {
            resolvePromise = resolve;
            rejectPromise = reject;
        });

        let UserSelectModal = openModalAction({
            type: "UserSelectModal",
            id: payload.modalId,
            visible: true,
            props: {
                modalId: payload.modalId,
                title: payload.title,
                contentMessage: payload.contentMessage,
                isLoading: false,
                initializedata: payload.initializedata,
                okButtonText: payload.okButtonText,
                zIndex: payload.zIndex
            }
        })

        yield put(UserSelectModal);
        yield put(changeModalDataAction({
            modalId: payload.modalId,
            data: payload.initializedata
        }));
        yield fork(waitUserResponse, resolvePromise, rejectPromise, payload.modalId, payload.doNotCloseAfterResponse ? payload.doNotCloseAfterResponse : false);

        return yield promise;
    } catch (error) {
        console.log("LOG: function*ConfirmationBoxManager -> error", error)

    }
}

function* waitUserResponse(resolvePromise: any, rejectPromise: any, id: string, dontCloseOption: boolean) {
    let action: IBaseAction<any> = yield take([ActionTypes.RESOLVE_USER_SELECT_MODAL, ActionTypes.REJECT_USER_SELECT_MODAL]);
    if (action && action.type == ActionTypes.RESOLVE_USER_SELECT_MODAL && resolvePromise) {
        if (!dontCloseOption) yield put(closeModalAction(id))
        resolvePromise(action.payload);
    } else if (action && action.type == ActionTypes.REJECT_USER_SELECT_MODAL && rejectPromise) {
        yield put(closeModalAction(id))
        resolvePromise(null);
    }
}

export function* updateModalLoading(id: string, data: boolean) {
    yield put(changeModalProps({ id: id, data: { isLoading: data } }))
}