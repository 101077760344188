import * as React from "react";
import BCSlider from '../../../BCSlider';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function SliderProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (value: any) => {
        props.customChangeEvent ? props.customChangeEvent(value) : replaceValue(props.item, value)
    }

    return (
        <BCSlider
            min={props.item.options?.min}
            max={props.item.options?.max}
            defaultValue={props.value}
            onChange={onChange}
            disabled={props.item.disabled || props.item.readOnly}
            value={props.value}
        />
    )
}