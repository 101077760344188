import { createMutableAction, createSyncAction } from '@bimser/core';
import { List } from 'immutable';
import IOnChangeTemplateDataPayload from '../entities/IOnChangeTemplateDataPayload';
import ISendTemplatePayload from '../entities/ISendTemplatePayload';
import { SignatureTemplate } from '../entities/ISignatureTemplate';
import OpenESignaturePayload from "../entities/OpenESignaturePayload";
import { ON_CLICK_SEND_TEMPLATE_ACTION, ON_SELECTED_TEMPLATE_CHANGE_ACTION, ON_TEMPLATE_DATA_CHANGE_ACTION, OPEN_ESIGNATURE_TRANSACTION, RESOLVE_ESIGNATURE_TRANSACTION, SET_ESIGNATURE_DATA_ACTION } from './actionTypes';

export const openESignatureTransaction = (payload: OpenESignaturePayload) => {
    return createMutableAction(OPEN_ESIGNATURE_TRANSACTION, payload);
}

export const resolveESignatureTransaction = (modalId: string) => {
    return createMutableAction(RESOLVE_ESIGNATURE_TRANSACTION, modalId);
}

export const setESignatureDataAction = (templates: List<SignatureTemplate>) => {
    return createSyncAction(SET_ESIGNATURE_DATA_ACTION, templates);
}

export const onChangeTemplateDataAction = (payload: IOnChangeTemplateDataPayload) => {
    return createMutableAction(ON_TEMPLATE_DATA_CHANGE_ACTION, payload);
}

export const onChangeSelectedTemplateAction = (templateId: string) => {
    return createMutableAction(ON_SELECTED_TEMPLATE_CHANGE_ACTION, templateId);
}

export const onClickSendTemplateAction = (payload: ISendTemplatePayload) => {
    return createMutableAction(ON_CLICK_SEND_TEMPLATE_ACTION, payload);
}