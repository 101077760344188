import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent, IProfession, Profession, ProfessionFactoryFromJS, ProfessionFactory } from "..";

export interface IProfessionListFormContent extends IBaseFormContent {
    selectedProfession?: IProfession
}

export interface IReadonlyProfessionListFormContent extends IBaseFormContent {
    selectedProfession: Profession
}

export type ProfessionListFormContent = ReadonlyRecord<IReadonlyProfessionListFormContent>;

export const ProfessionListFormContentFactory = Record<IReadonlyProfessionListFormContent>({
    panelId: "",
    selectedProfession: ProfessionFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: false,
    selectedCompanyId: 0,
});

export const ProfessionListFormContentFactoryFromJS = function (data: IProfessionListFormContent) {

    return ProfessionListFormContentFactory({
        panelId: data.panelId,
        selectedProfession: ProfessionFactoryFromJS(data.selectedProfession),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectedCompanyId : data.selectedCompanyId,
    });
}