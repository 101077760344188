import { Configuration, ConfigurationKeys } from "../entities/IConfiguration";
import ResumableFile from "./ResumableFile";
import { Map } from 'immutable';
import { UploadFileThread } from "../entities/IUploadFileThread";

export default class ResumableUploadManager {
    constructor(opts: Configuration) {
        if (!this.support) return null;
        this.opts = opts || {};

        this.getOpt = this.getOpt.bind(this);
        this.addFile = this.addFile.bind(this);
        this.addFiles = this.addFiles.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fire = this.fire.bind(this);
        this.getFromUUID = this.getFromUUID.bind(this);
        this.getSize = this.getSize.bind(this);
        this.isUploading = this.isUploading.bind(this);
        this.on = this.on.bind(this);
        this.pause = this.pause.bind(this);
        this.progress = this.progress.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.upload = this.upload.bind(this);
        this.uploadNextChunk = this.uploadNextChunk.bind(this);
        this.processCallbacks = this.processCallbacks.bind(this);
        this.processDirectory = this.processDirectory.bind(this);
        this.processItem = this.processItem.bind(this);
        this.loadFiles = this.loadFiles.bind(this);
        this.appendFilesFromFileList = this.appendFilesFromFileList.bind(this);
        this.getFromUniqueIdentifier = this.getFromUniqueIdentifier.bind(this);
    }
    support = (
        (typeof (File) !== 'undefined')
        &&
        (typeof (Blob) !== 'undefined')
        &&
        (typeof (FileList) !== 'undefined')
        &&
        (!!Blob.prototype.slice || false)
    );
    files: Array<ResumableFile> = [];
    opts: Configuration;
    events: Array<any> = [];
    defaults: Configuration = {
        chunkSize: 1 * 1024 * 1024,
        forceChunkSize: true,
        simultaneousUploads: 3,
        fileParameterName: 'file',
        chunkNumberParameterName: 'resumableChunkNumber',
        chunkSizeParameterName: 'resumableChunkSize',
        currentChunkSizeParameterName: 'resumableCurrentChunkSize',
        totalSizeParameterName: 'resumableTotalSize',
        typeParameterName: 'resumableType',
        identifierParameterName: 'resumableIdentifier',
        fileNameParameterName: 'resumableFilename',
        relativePathParameterName: 'resumableRelativePath',
        totalChunksParameterName: 'resumableTotalChunks',
        throttleProgressCallbacks: 0.4,
        query: {},
        headers: {},
        preprocess: null,
        method: 'multipart',
        uploadMethod: 'POST',
        testMethod: 'GET',
        prioritizeFirstAndLastChunk: false,
        target: '/',
        testTarget: null,
        parameterNamespace: '',
        testChunks: false,
        generateUniqueIdentifier: null,
        maxChunkRetries: 100,
        chunkRetryInterval: undefined,
        permanentErrors: [400, 404, 415, 500, 501],
        maxFiles: undefined,
        withCredentials: false,
        xhrTimeout: 0,
        chunkFormat: 'blob',
        setChunkTypeFromFile: false,
        maxFilesErrorCallback: (files: File[], errorCount: number) => {
            var maxFiles = this.getOpt('maxFiles');
            let msg = ('Please upload no more than ' + maxFiles + ' file' + (maxFiles === 1 ? '' : 's') + ' at a time.');
            this.fire('fileError', { message: msg})
        },
        minFileSize: 1,
        minFileSizeErrorCallback: (file: File, errorCount: number) => {
            let msg = (file.name + ' is too small, please upload files larger than ' + this.Helpers.formatSize(this.getOpt('minFileSize')) + '.');
            this.fire('fileError', { file, message: msg})
        },
        maxFileSize: undefined,
        maxFileSizeErrorCallback: (file: File, errorCount: number) => {
            let msg = (file.name + ' is too large, please upload files less than ' + this.Helpers.formatSize(this.getOpt('maxFileSize')) + '.');
            this.fire('fileError', { file, message: msg})
        },
        fileType: [],
        fileTypeErrorCallback: (file: File, errorCount: number) => {
            let msg = (file.name + ' has type not allowed, please upload files of type ' + this.getOpt('fileType') + '.');
            this.fire('fileError', { file, message: msg})
        }
    };

    upload(): void {
        // if (this.isUploading()) return;
        this.fire('uploadStart');
        for (let i = 0; i < this.getOpt('simultaneousUploads'); i++) {
            this.uploadNextChunk();
        }
    }

    uploadNextChunk() {
        var found = false;
        // In some cases (such as videos) it's really handy to upload the first
        // and last chunk of a file quickly; this let's the server check the file's
        // metadata and determine if there's even a point in continuing.
        if (this.getOpt('prioritizeFirstAndLastChunk')) {
            //  $h.each($.files, function(file){
            this.files.forEach(file => {
                if (file.chunks.length && file.chunks[0].status() == 'pending' && file.chunks[0].preprocessState === 0) {
                    file.chunks[0].send();
                    found = true;
                    return (false);
                }
                if (file.chunks.length > 1 && file.chunks[file.chunks.length - 1].status() == 'pending' && file.chunks[file.chunks.length - 1].preprocessState === 0) {
                    file.chunks[file.chunks.length - 1].send();
                    found = true;
                    return (false);
                }
            });
            if (found) return (true);
        }

        // Now, simply look for the next, best thing to upload
        this.files.forEach(file => {
            if (file.isPaused() === false) {
                file.chunks.forEach(chunk => {
                    if (chunk.status() == 'pending' && chunk.preprocessState === 0 && !found) {
                        chunk.send();
                        found = true;
                        return (false);
                    }
                });
            }
            if (found) return (false);
        });
        if (found) return (true);

        // The are no more outstanding chunks to upload, check is everything is done
        var outstanding = false;
        this.files.forEach(file => {
            if (!file.isComplete()) {
                outstanding = true;
                return (false);
            }
        });
        if (!outstanding) {
            // All chunks have been uploaded, complete
            this.fire('complete');
        }
        return (false);
    }

    pause(_pause: boolean, uuid?: string): void {
        if (uuid) {
            let file = this.files.find(f => f.uuid == uuid);
            if (file) {
                file.pause(_pause)
                this.fire('filePause', file)
            }
        } else {
            this.files.forEach(f => f.pause(_pause));
            this.fire('pause');
        }
    }

    cancel(): void {
        this.fire('beforeCancel');
        this.files.forEach(f => f.cancel());
        this.fire('cancel');
    }

    fire(...args: any[]): void {
        let event = args[0].toLowerCase();
        for (var i = 0; i <= this.events.length; i += 2) {
            if (this.events[i] == event) this.events[i + 1].apply(this, args.slice(1));
            if (this.events[i] == 'catchall') this.events[i + 1].apply(null, args);
        }
        if (event == 'fileerror') this.fire('error', args[2], args[1]);
        if (event == 'fileprogress') this.fire('progress');
    }

    progress(): number {
        let totalDone = 0;
        let totalSize = 0;
        // Resume all chunks currently being uploaded
        this.files.forEach(file => {
            totalDone += file.progress() * file.size;
            totalSize += file.size;
        });
        return (totalSize > 0 ? totalDone / totalSize : 0);
    }

    isUploading(): boolean {
        return this.files.findIndex(f => f.isUploading) > -1;
    }

    addFile(file: File, identifier: any, threads: Map<string, UploadFileThread>): void {
        this.appendFilesFromFileList([file], identifier, threads);
    }

    addFiles(files: File[]): void {
        //this.appendFilesFromFileList(files, null, null);
    }

    removeFile(file: ResumableFile): void {
        this.files = this.files.filter(f => f !== file)
    }

    getFromUUID(uuid: string): ResumableFile {
        var ret;
        ret = this.files.find((file: ResumableFile) => {
            return file.uuid == uuid;
        })
        return (ret);
    }

    getFromUniqueIdentifier(uuid: string) {
        return this.files.find(f => f.uuid == uuid);
    };

    getSize(): number {
        var totalSize = 0;
        this.files.forEach(f => {
            totalSize += f.size;
        });
        return (totalSize);
    }

    getOpt(o: ConfigurationKeys | ConfigurationKeys[]): any {
        if (o instanceof Array) {
            var options: any = {};
            o.forEach(option => {
                options[option] = this.getOpt(option);
            });
            return options;
        }
        if (this.opts[o] !== undefined) return this.opts[o];
        else return this.defaults[o];
    }

    updateQuery(query: any) {
        this.opts.query = query;
    }

    on(event: string, cb: Function): void {
        this.events.push(event.toLowerCase(), cb)
    }

    Helpers = {
        stopEvent: function (e: Event) {
            e.stopPropagation();
            e.preventDefault();
        },
        generateUniqueIdentifier: (file: File, event: Event) => {
            var custom = this.getOpt('generateUniqueIdentifier');
            if (typeof custom === 'function') {
                return custom(file, event);
            }
            var relativePath = file.name; // Some confusion in different versions of Firefox
            var size = file.size;
            return (size + '-' + relativePath.replace(/[^0-9a-zA-Z_-]/img, ''));
        },
        formatSize: function (size: number) {
            if (size < 1024) {
                return size + ' bytes';
            } else if (size < 1024 * 1024) {
                return (size / 1024.0).toFixed(0) + ' KB';
            } else if (size < 1024 * 1024 * 1024) {
                return (size / 1024.0 / 1024.0).toFixed(1) + ' MB';
            } else {
                return (size / 1024.0 / 1024.0 / 1024.0).toFixed(1) + ' GB';
            }
        },
        getTarget: (request: any, params: any) => {
            var target = this.getOpt('target');

            if (request === 'test' && this.getOpt('testTarget')) {
                target = this.getOpt('testTarget') === '/' ? this.getOpt('target') : this.getOpt('testTarget');
            }

            if (typeof target === 'function') {
                return target(params);
            }

            var separator = target.indexOf('?') < 0 ? '?' : '&';
            var joinedParams = params.join('&');

            return target + separator + joinedParams;
        }
    }

    appendFilesFromFileList = function (fileList: File[], event: any, threads: Map<string, UploadFileThread>) {
        // check for uploading too many files
        var errorCount = 0;
        var o = this.getOpt(['maxFiles', 'minFileSize', 'maxFileSize', 'maxFilesErrorCallback', 'minFileSizeErrorCallback', 'maxFileSizeErrorCallback', 'fileType', 'fileTypeErrorCallback']);
        if (typeof (o.maxFiles) !== 'undefined' && o.maxFiles < (fileList.length + this.files.length)) {
            // if single-file upload, file is already added, and trying to add 1 new file, simply replace the already-added file
            if (o.maxFiles === 1 && this.files.length === 1 && fileList.length === 1) {
                this.removeFile(this.files[0]);
            } else {
                o.maxFilesErrorCallback(fileList, errorCount++);
                return false;
            }
        }
        var files: ResumableFile[] = [], filesSkipped: any[] = [], remaining = fileList.length;
        var decreaseReamining = () => {
            if (!--remaining) {
                // all files processed, trigger event
                if (!files.length && !filesSkipped.length) {
                    // no succeeded files, just skip
                    return;
                }
                window.setTimeout(() => {
                    this.fire('filesAdded', files, filesSkipped);
                }, 0);
            }
        };
        fileList.forEach(file => {
            var fileName = file.name;
            if (o.fileType.length > 0) {
                var fileTypeFound = false;
                for (var fileType of o.fileType) {
                    var extension = '.' + fileType;
                    if (fileName.toLowerCase().indexOf(extension.toLowerCase(), fileName.length - extension.length) !== -1) {
                        fileTypeFound = true;
                        break;
                    }
                }
                if (!fileTypeFound) {
                    o.fileTypeErrorCallback(file, errorCount++);
                    return false;
                }
            }

            if (typeof (o.minFileSize) !== 'undefined' && file.size < o.minFileSize) {
                o.minFileSizeErrorCallback(file, errorCount++);
                return false;
            }
            if (typeof (o.maxFileSize) !== 'undefined' && file.size > o.maxFileSize) {
                o.maxFileSizeErrorCallback(file, errorCount++);
                return false;
            }

            const addFile = (uuid: string) => {
                if (!this.getFromUniqueIdentifier(uuid)) {
                    (() => {
                        //file.uuid = uuid;
                        var f = new ResumableFile({ resumableObj: this, file: file, uniqueIdentifier: uuid, threads: threads });
                        this.files.push(f);
                        files.push(f);
                        //f.container = (typeof event != 'undefined' ? event.srcElement : null);
                        window.setTimeout(() => {
                            this.fire('fileAdded', f, event)
                        }, 0);
                    })()
                } else {
                    filesSkipped.push(file);
                };
                decreaseReamining();
            }
            // directories have size == 0
            var uniqueIdentifier = this.Helpers.generateUniqueIdentifier(file, event);
            if (uniqueIdentifier && typeof uniqueIdentifier.then === 'function') {
                // Promise or Promise-like object provided as unique identifier
                uniqueIdentifier
                    .then(
                        function (uniqueIdentifier: string) {
                            // unique identifier generation succeeded
                            addFile(uniqueIdentifier);
                        },
                        function () {
                            // unique identifier generation failed
                            // skip further processing, only decrease file count
                            decreaseReamining();
                        }
                    );
            } else {
                // non-Promise provided as unique identifier, process synchronously
                addFile(uniqueIdentifier);
            }
        });
    };

    processCallbacks(items: Function[], cb: Function) {
        if (!items || items.length == 0) return cb();
        items[0](() => {
            this.processCallbacks(items.slice(1), cb);
        })
    }

    processDirectory(directory: any, path: string, items: File[], cb: Function) {
        let dirReader = directory.createReader();
        dirReader.readEntries((entries: any[]) => {
            if (!entries.length) return cb();
            this.processCallbacks(entries.map((e: any) => {
                return this.processItem.bind(null, e, path, items)
            }), cb)
        })
    }

    processItem(item: any, path: string, items: File[], cb: Function) {
        var entry;
        if (item.isFile) {
            // file provided
            return item.file(function (file: any) {
                file.relativePath = path + file.name;
                items.push(file);
                cb();
            });
        } else if (item.isDirectory) {
            // item is already a directory entry, just assign
            entry = item;
        } else if (item instanceof File) {
            items.push(item);
        }
        if ('function' === typeof item.webkitGetAsEntry) {
            // get entry from file object
            entry = item.webkitGetAsEntry();
        }
        if (entry && entry.isDirectory) {
            // directory provided, process it
            return this.processDirectory(entry, path + entry.name + '/', items, cb);
        }
        if ('function' === typeof item.getAsFile) {
            // item represents a File object, convert it
            item = item.getAsFile();
            if (item instanceof File) {
                //item.relativePath = path + item.name;
                items.push(item);
            }
        }
        cb(); // indicate processing is done
    }

    loadFiles(items: File[], event: Event) {
        if (!items.length) return;

        this.fire('beforeAdd');
        var files: File[] = [];
        this.processCallbacks(
            items.map((item) => {
                // bind all properties except for callback
                return this.processItem.bind(null, item, "", files);
            }),
            function () {
                if (files.length) {
                    // at least one file found
                    this.appendFilesFromFileList(files, event);
                }
            }
        );
    }
}
