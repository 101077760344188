import { IItem } from "@bimser/core";

export const countries: IItem[] = [
    {
        "key": "+93",
        "text": "Afghanistan",
        "value": "AF",

        "name": "99 999 9999"
    },
    {
        "key": "+355",
        "text": "Albania",
        "value": "AL",

        "name": "(999)999 999"
    },
    {
        "key": "+213",
        "text": "Algeria",
        "value": "DZ",

        "name": "99 999 9999"
    },
    {
        "key": "+1 684",
        "text": "American Samoa",
        "value": "AS",

        "name": "+1(684)999 9999"
    },
    {
        "key": "+244",
        "text": "Angola",
        "value": "AO",

        "name": "(999)999 999"
    },
    {
        "key": "+1 264",
        "text": "Anguilla",
        "value": "AI",

        "name": "+1(264)999 9999"
    },
    {
        "key": "+1 268",
        "text": "Antigua and Barbuda",
        "value": "AG",

        "name": "+1(268)999 9999"
    },
    {
        "key": "+54",
        "text": "Argentina",
        "value": "AR",

        "name": "(999)999 9999"
    },
    {
        "key": "+374",
        "text": "Armenia",
        "value": "AM",

        "name": "99 999 999"
    },
    {
        "key": "+297",
        "text": "Aruba",
        "value": "AW",

        "name": "999 9999"
    },
    {
        "key": "+61",
        "text": "Australia",
        "value": "AU",

        "name": "9 9999 9999"
    },
    {
        "key": "+43",
        "text": "Austria",
        "value": "AT",

        "name": "(999)999 9999"
    },
    {
        "key": "+994",
        "text": "Azerbaijan",
        "value": "AZ",

        "name": "99 999 99 99"
    },
    {
        "key": "+1 242",
        "text": "Bahamas",
        "value": "BS",

        "name": "+1(242)999 9999"
    },
    {
        "key": "+973",
        "text": "Bahrain",
        "value": "BH",

        "name": "9999 9999"
    },
    {
        "key": "+880",
        "text": "Bangladesh",
        "value": "BD",

        "name": "99 999 999"
    },
    {
        "key": "+1 246",
        "text": "Barbados",
        "value": "BB",

        "name": "+1(246)999 9999"
    },
    {
        "key": "+375",
        "text": "Belarus",
        "value": "BY",

        "name": "(99)999 99 99"
    },
    {
        "key": "+32",
        "text": "Belgium",
        "value": "BE",

        "name": "(999)999 999"
    },
    {
        "key": "+501",
        "text": "Belize",
        "value": "BZ",

        "name": "999 9999"
    },
    {
        "key": "+229",
        "text": "Benin",
        "value": "BJ",

        "name": "99 99 9999"
    },
    {
        "key": "+1 441",
        "text": "Bermuda",
        "value": "BM",

        "name": "+1(441)999 9999"
    },
    {
        "key": "+975",
        "text": "Bhutan",
        "value": "BT",

        "name": "-17-999 999"
    },
    {
        "key": "+591",
        "text": "Bolivia",
        "value": "BO",

        "name": "9 999 9999"
    },
    {
        "key": "+387",
        "text": "Bosnia and Herzegovina",
        "value": "BA",

        "name": "99 99999"
    },
    {
        "key": "+267",
        "text": "Botswana",
        "value": "BW",

        "name": "99 999 999"
    },
    {
        "key": "+55",
        "text": "Brazil",
        "value": "BR",

        "name": "(99)9999 9999"
    },
    {
        "key": "+246",
        "text": "British Indian Ocean Territory",
        "value": "IO",

        "name": "999 9999"
    },
    {
        "key": "+359",
        "text": "Bulgaria",
        "value": "BG",

        "name": "(999)999 999"
    },
    {
        "key": "+226",
        "text": "Burkina Faso",
        "value": "BF",

        "name": "99 99 9999"
    },
    {
        "key": "+257",
        "text": "Burundi",
        "value": "BI",

        "name": "99 99 9999"
    },
    {
        "key": "+855",
        "text": "Cambodia",
        "value": "KH",

        "name": "99 999 999"
    },
    {
        "key": "+237",
        "text": "Cameroon",
        "value": "CM",

        "name": "9999 9999"
    },
    {
        "key": "+1",
        "text": "Canada",
        "value": "CA",

        "name": "(999)999 9999"
    },
    {
        "key": "+238",
        "text": "Cape Verde",
        "value": "CV",

        "name": "(999)99 99"
    },
    {
        "key": "+ 345",
        "text": "Cayman Islands",
        "value": "KY",

        "name": "+1(345)999 9999"
    },
    {
        "key": "+236",
        "text": "Central African Republic",
        "value": "CF",

        "name": "99 99 9999"
    },
    {
        "key": "+235",
        "text": "Chad",
        "value": "TD",

        "name": "99 99 99 99"
    },
    {
        "key": "+56",
        "text": "Chile",
        "value": "CL",

        "name": "9 9999 9999"
    },
    {
        "key": "+86",
        "text": "China",
        "value": "CN",

        "name": "(999)9999 9999"
    },
    {
        "key": "+61",
        "text": "Christmas Island",
        "value": "CX",

        "name": "+61"
    },
    {
        "key": "+57",
        "text": "Colombia",
        "value": "CO",

        "name": "(999)999 9999"
    },
    {
        "key": "+269",
        "text": "Comoros",
        "value": "KM",

        "name": "99 99999"
    },
    {
        "key": "+242",
        "text": "Congo",
        "value": "CG",

        "name": "99 999 9999"
    },
    {
        "key": "+682",
        "text": "Cook Islands",
        "value": "CK",

        "name": "99 999"
    },
    {
        "key": "+506",
        "text": "Costa Rica",
        "value": "CR",

        "name": "9999 9999"
    },
    {
        "key": "+385",
        "text": "Croatia",
        "value": "HR",

        "name": "99 999 999"
    },
    {
        "key": "+53",
        "text": "Cuba",
        "value": "CU",

        "name": "9 999 9999"
    },
    {
        "key": "+537",
        "text": "Cyprus",
        "value": "CY",

        "name": "+357-99 999 999"
    },
    {
        "key": "+420",
        "text": "Czech Republic",
        "value": "CZ",

        "name": "(999)999 999"
    },
    {
        "key": "+45",
        "text": "Denmark",
        "value": "DK",

        "name": "99 99 99 99"
    },
    {
        "key": "+253",
        "text": "Djibouti",
        "value": "DJ",

        "name": "99 99 99 99"
    },
    {
        "key": "+1 767",
        "text": "Dominica",
        "value": "DM",

        "name": "+1(767)999 9999"
    },
    {
        "key": "+1 809",
        "text": "Dominican Republic",
        "value": "DO",

        "name": "+1(809)999 9999"
    },
    {
        "key": "+593",
        "text": "Ecuador",
        "value": "EC",

        "name": "99 999 9999"
    },
    {
        "key": "+20",
        "text": "Egypt",
        "value": "EG",

        "name": "(999)999 9999"
    },
    {
        "key": "+503",
        "text": "El Salvador",
        "value": "SV",

        "name": "99 99 9999"
    },
    {
        "key": "+240",
        "text": "Equatorial Guinea",
        "value": "GQ",

        "name": "99 999 9999"
    },
    {
        "key": "+291",
        "text": "Eritrea",
        "value": "ER",

        "name": "9 999 999"
    },
    {
        "key": "+372",
        "text": "Estonia",
        "value": "EE",

        "name": "9999 9999"
    },
    {
        "key": "+251",
        "text": "Ethiopia",
        "value": "ET",

        "name": "99 999 9999"
    },
    {
        "key": "+298",
        "text": "Faroe Islands",
        "value": "FO",

        "name": "999 999"
    },
    {
        "key": "+679",
        "text": "Fiji",
        "value": "FJ",

        "name": "99 99999"
    },
    {
        "key": "+358",
        "text": "Finland",
        "value": "FI",

        "name": "(999)999 99 99"
    },
    {
        "key": "+33",
        "text": "France",
        "value": "FR",

        "name": "(999)999 999"
    },
    {
        "key": "+594",
        "text": "French Guiana",
        "value": "GF",

        "name": "99999 9999"
    },
    {
        "key": "+689",
        "text": "French Polynesia",
        "value": "PF",

        "name": "99 99 99"
    },
    {
        "key": "+241",
        "text": "Gabon",
        "value": "GA",

        "name": "9 99 99 99"
    },
    {
        "key": "+220",
        "text": "Gambia",
        "value": "GM",

        "name": "(999)99 99"
    },
    {
        "key": "+995",
        "text": "Georgia",
        "value": "GE",

        "name": "(999)999 999"
    },
    {
        "key": "+49",
        "text": "Germany",
        "value": "DE",

        "name": "(9999)999 9999"
    },
    {
        "key": "+233",
        "text": "Ghana",
        "value": "GH",

        "name": "(999)999 999"
    },
    {
        "key": "+350",
        "text": "Gibraltar",
        "value": "GI",

        "name": "999 99999"
    },
    {
        "key": "+30",
        "text": "Greece",
        "value": "GR",

        "name": "(999)999 9999"
    },
    {
        "key": "+299",
        "text": "Greenland",
        "value": "GL",

        "name": "99 99 99"
    },
    {
        "key": "+1 473",
        "text": "Grenada",
        "value": "GD",

        "name": "+1(473)999 9999"
    },
    {
        "key": "+590",
        "text": "Guadeloupe",
        "value": "GP",

        "name": "+590"
    },
    {
        "key": "+1 671",
        "text": "Guam",
        "value": "GU",

        "name": "+1(671)999 9999"
    },
    {
        "key": "+502",
        "text": "Guatemala",
        "value": "GT",

        "name": "9 999 9999"
    },
    {
        "key": "+224",
        "text": "Guinea",
        "value": "GN",

        "name": "99 999 999"
    },
    {
        "key": "+245",
        "text": "Guinea-Bissau",
        "value": "GW",

        "name": "9 999999"
    },
    {
        "key": "+595",
        "text": "Guyana",
        "value": "GY",

        "name": "+592-999 9999"
    },
    {
        "key": "+509",
        "text": "Haiti",
        "value": "HT",

        "name": "99 99 9999"
    },
    {
        "key": "+504",
        "text": "Honduras",
        "value": "HN",

        "name": "9999 9999"
    },
    {
        "key": "+36",
        "text": "Hungary",
        "value": "HU",

        "name": "(999)999 999"
    },
    {
        "key": "+354",
        "text": "Iceland",
        "value": "IS",

        "name": "999 9999"
    },
    {
        "key": "+91",
        "text": "India",
        "value": "IN",

        "name": "(9999)999 999"
    },
    {
        "key": "+62",
        "text": "Indonesia",
        "value": "ID",

        "name": "(899)999 9999"
    },
    {
        "key": "+964",
        "text": "Iraq",
        "value": "IQ",

        "name": "(999)999 9999"
    },
    {
        "key": "+353",
        "text": "Ireland",
        "value": "IE",

        "name": "(999)999 999"
    },
    {
        "key": "+972",
        "text": "Israel",
        "value": "IL",

        "name": "-59 999 9999"
    },
    {
        "key": "+39",
        "text": "Italy",
        "value": "IT",

        "name": "(999)9999 999"
    },
    {
        "key": "+1 876",
        "text": "Jamaica",
        "value": "JM",

        "name": "+1(876)999 9999"
    },
    {
        "key": "+81",
        "text": "Japan",
        "value": "JP",

        "name": "99 9999 9999"
    },
    {
        "key": "+962",
        "text": "Jordan",
        "value": "JO",

        "name": "9 9999 9999"
    },
    {
        "key": "+7 7",
        "text": "Kazakhstan",
        "value": "KZ",

        "name": "+7(699)999 99 99"
    },
    {
        "key": "+254",
        "text": "Kenya",
        "value": "KE",

        "name": "999 999999"
    },
    {
        "key": "+686",
        "text": "Kiribati",
        "value": "KI",

        "name": "99 999"
    },
    {
        "key": "+965",
        "text": "Kuwait",
        "value": "KW",

        "name": "9999 9999"
    },
    {
        "key": "+996",
        "text": "Kyrgyzstan",
        "value": "KG",

        "name": "(999)999 999"
    },
    {
        "key": "+371",
        "text": "Latvia",
        "value": "LV",

        "name": "99 999 999"
    },
    {
        "key": "+961",
        "text": "Lebanon",
        "value": "LB",

        "name": "99 999 999"
    },
    {
        "key": "+266",
        "text": "Lesotho",
        "value": "LS",

        "name": "9 999 9999"
    },
    {
        "key": "+231",
        "text": "Liberia",
        "value": "LR",

        "name": "99 999 999"
    },
    {
        "key": "+423",
        "text": "Liechtenstein",
        "value": "LI",

        "name": "(999)999 9999"
    },
    {
        "key": "+370",
        "text": "Lithuania",
        "value": "LT",

        "name": "(999)99 999"
    },
    {
        "key": "+352",
        "text": "Luxembourg",
        "value": "LU",

        "name": "(999)999 999"
    },
    {
        "key": "+261",
        "text": "Madagascar",
        "value": "MG",

        "name": "99 99 99999"
    },
    {
        "key": "+265",
        "text": "Malawi",
        "value": "MW",

        "name": "-1-999 999"
    },
    {
        "key": "+60",
        "text": "Malaysia",
        "value": "MY",

        "name": "(999)999 999"
    },
    {
        "key": "+960",
        "text": "Maldives",
        "value": "MV",

        "name": "999 9999"
    },
    {
        "key": "+223",
        "text": "Mali",
        "value": "ML",

        "name": "99 99 9999"
    },
    {
        "key": "+356",
        "text": "Malta",
        "value": "MT",

        "name": "9999 9999"
    },
    {
        "key": "+692",
        "text": "Marshall Islands",
        "value": "MH",

        "name": "999 9999"
    },
    {
        "key": "+596",
        "text": "Martinique",
        "value": "MQ",

        "name": "(999)99 99 99"
    },
    {
        "key": "+222",
        "text": "Mauritania",
        "value": "MR",

        "name": "99 99 9999"
    },
    {
        "key": "+230",
        "text": "Mauritius",
        "value": "MU",

        "name": "999 9999"
    },
    {
        "key": "+262",
        "text": "Mayotte",
        "value": "YT",

        "name": "+262"
    },
    {
        "key": "+52",
        "text": "Mexico",
        "value": "MX",

        "name": "(999)999 9999"
    },
    {
        "key": "+377",
        "text": "Monaco",
        "value": "MC",

        "name": "(999)999 999"
    },
    {
        "key": "+976",
        "text": "Mongolia",
        "value": "MN",

        "name": "99 99 9999"
    },
    {
        "key": "+382",
        "text": "Montenegro",
        "value": "ME",

        "name": "99 999 999"
    },
    {
        "key": "+1664",
        "text": "Montserrat",
        "value": "MS",

        "name": "+1(664)999 9999"
    },
    {
        "key": "+212",
        "text": "Morocco",
        "value": "MA",

        "name": "99 9999 999"
    },
    {
        "key": "+95",
        "text": "Myanmar",
        "value": "MM",

        "name": "99 999 999"
    },
    {
        "key": "+264",
        "text": "Namibia",
        "value": "NA",

        "name": "99 999 9999"
    },
    {
        "key": "+674",
        "text": "Nauru",
        "value": "NR",

        "name": "999 9999"
    },
    {
        "key": "+977",
        "text": "Nepal",
        "value": "NP",

        "name": "99 999 999"
    },
    {
        "key": "+31",
        "text": "Netherlands",
        "value": "NL",

        "name": "99 999 9999"
    },
    {
        "key": "+599",
        "text": "Netherlands Antilles",
        "value": "AN",

        "name": "-9999 9999"
    },
    {
        "key": "+687",
        "text": "New Caledonia",
        "value": "NC",

        "name": "99 9999"
    },
    {
        "key": "+64",
        "text": "New Zealand",
        "value": "NZ",

        "name": "(999)999 999"
    },
    {
        "key": "+505",
        "text": "Nicaragua",
        "value": "NI",

        "name": "9999 9999"
    },
    {
        "key": "+227",
        "text": "Niger",
        "value": "NE",

        "name": "99 99 9999"
    },
    {
        "key": "+234",
        "text": "Nigeria",
        "value": "NG",

        "name": "(999)999 9999"
    },
    {
        "key": "+683",
        "text": "Niue",
        "value": "NU",

        "name": "9999"
    },
    {
        "key": "+672",
        "text": "Norfolk Island",
        "value": "NF",

        "name": "-399 999"
    },
    {
        "key": "+1 670",
        "text": "Northern Mariana Islands",
        "value": "MP",

        "name": "+1(670)999 9999"
    },
    {
        "key": "+47",
        "text": "Norway",
        "value": "NO",

        "name": "(999)99 999"
    },
    {
        "key": "+968",
        "text": "Oman",
        "value": "OM",

        "name": "99 999 999"
    },
    {
        "key": "+92",
        "text": "Pakistan",
        "value": "PK",

        "name": "(999)999 9999"
    },
    {
        "key": "+680",
        "text": "Palau",
        "value": "PW",

        "name": "999 9999"
    },
    {
        "key": "+507",
        "text": "Panama",
        "value": "PA",

        "name": "999 9999"
    },
    {
        "key": "+675",
        "text": "Papua New Guinea",
        "value": "PG",

        "name": "(999)99 999"
    },
    {
        "key": "+595",
        "text": "Paraguay",
        "value": "PY",

        "name": "(999)999 999"
    },
    {
        "key": "+51",
        "text": "Peru",
        "value": "PE",

        "name": "(999)999 999"
    },
    {
        "key": "+63",
        "text": "Philippines",
        "value": "PH",

        "name": "(999)999 9999"
    },
    {
        "key": "+48",
        "text": "Poland",
        "value": "PL",

        "name": "(999)999 999"
    },
    {
        "key": "+351",
        "text": "Portugal",
        "value": "PT",

        "name": "99 999 9999"
    },
    {
        "key": "+1 787",
        "text": "Puerto Rico",
        "value": "PR",

        "name": "+1 787"
    },
    {
        "key": "+974",
        "text": "Qatar",
        "value": "QA",

        "name": "9999 9999"
    },
    {
        "key": "+262",
        "text": "Reunion",
        "value": "RE",

        "name": "99999 9999"
    },
    {
        "key": "+40",
        "text": "Romania",
        "value": "RO",

        "name": "99 999 9999"
    },
    {
        "key": "+685",
        "text": "Samoa",
        "value": "WS",

        "name": "99 9999"
    },
    {
        "key": "+378",
        "text": "San Marino",
        "value": "SM",

        "name": "9999 999999"
    },
    {
        "key": "+966",
        "text": "Saudi Arabia",
        "value": "SA",

        "name": "-5-9999 9999"
    },
    {
        "key": "+221",
        "text": "Senegal",
        "value": "SN",

        "name": "99 999 9999"
    },
    {
        "key": "+381",
        "text": "Serbia",
        "value": "RS",

        "name": "99 999 9999"
    },
    {
        "key": "+248",
        "text": "Seychelles",
        "value": "SC",

        "name": "9 999 999"
    },
    {
        "key": "+232",
        "text": "Sierra Leone",
        "value": "SL",

        "name": "99 999999"
    },
    {
        "key": "+65",
        "text": "Singapore",
        "value": "SG",

        "name": "9999 9999"
    },
    {
        "key": "+421",
        "text": "Slovakia",
        "value": "SK",

        "name": "(999)999 999"
    },
    {
        "key": "+386",
        "text": "Slovenia",
        "value": "SI",

        "name": "99 999 999"
    },
    {
        "key": "+677",
        "text": "Solomon Islands",
        "value": "SB",

        "name": "999 9999"
    },
    {
        "key": "+27",
        "text": "South Africa",
        "value": "ZA",

        "name": "99 999 9999"
    },
    {
        "key": "+500",
        "text": "South Georgia and the South Sandwich Islands",
        "value": "GS",

        "name": "+500"
    },
    {
        "key": "+34",
        "text": "Spain",
        "value": "ES",

        "name": "(999)999 999"
    },
    {
        "key": "+94",
        "text": "Sri Lanka",
        "value": "LK",

        "name": "99 999 9999"
    },
    {
        "key": "+249",
        "text": "Sudan",
        "value": "SD",

        "name": "99 999 9999"
    },
    {
        "key": "+268",
        "text": "Swaziland",
        "value": "SZ",

        "name": "99 99 9999"
    },
    {
        "key": "+46",
        "text": "Sweden",
        "value": "SE",

        "name": "99 999 9999"
    },
    {
        "key": "+41",
        "text": "Switzerland",
        "value": "CH",

        "name": "99 999 9999"
    },
    {
        "key": "+992",
        "text": "Tajikistan",
        "value": "TJ",

        "name": "99 999 9999"
    },
    {
        "key": "+66",
        "text": "Thailand",
        "value": "TH",

        "name": "99 999 9999"
    },
    {
        "key": "+228",
        "text": "Togo",
        "value": "TG",

        "name": "99 999 999"
    },
    {
        "key": "+690",
        "text": "Tokelau",
        "value": "TK",

        "name": "9999"
    },
    {
        "key": "+676",
        "text": "Tonga",
        "value": "TO",

        "name": "99999"
    },
    {
        "key": "+1 868",
        "text": "Trinidad and Tobago",
        "value": "TT",

        "name": "+1(868)999 9999"
    },
    {
        "key": "+216",
        "text": "Tunisia",
        "value": "TN",

        "name": "99 999 999"
    },
    {
        "key": "+90",
        "text": "Turkey",
        "value": "TR",

        "name": "(599)999 9999"
    },
    {
        "key": "+993",
        "text": "Turkmenistan",
        "value": "TM",

        "name": "9 999 9999"
    },
    {
        "key": "+1 649",
        "text": "Turks and Caicos Islands",
        "value": "TC",

        "name": "+1(649)999 9999"
    },
    {
        "key": "+688",
        "text": "Tuvalu",
        "value": "TV",

        "name": "-909999"
    },
    {
        "key": "+256",
        "text": "Uganda",
        "value": "UG",

        "name": "(999)999 999"
    },
    {
        "key": "+380",
        "text": "Ukraine",
        "value": "UA",

        "name": "(99)999 99 99"
    },
    {
        "key": "+971",
        "text": "United Arab Emirates",
        "value": "AE",

        "name": "-59 999 9999"
    },
    {
        "key": "+44",
        "text": "United Kingdom",
        "value": "GB",

        "name": " 7999 999999"
    },
    {
        "key": "+1",
        "text": "United States",
        "value": "US",

        "name": "(999)999 9999"
    },
    {
        "key": "+598",
        "text": "Uruguay",
        "value": "UY",

        "name": "9 999 99 99"
    },
    {
        "key": "+998",
        "text": "Uzbekistan",
        "value": "UZ",

        "name": "99 999 9999"
    },
    {
        "key": "+678",
        "text": "Vanuatu",
        "value": "VU",

        "name": "99 99999"
    },
    {
        "key": "+58",
        "text": "Venezuela",
        "value": "VE",

        "name": "(999)999 9999"
    },
    {
        "key": "+681",
        "text": "Wallis and Futuna",
        "value": "WF",

        "name": "99 9999"
    },
    {
        "key": "+967",
        "text": "Yemen",
        "value": "YE",

        "name": "999 999 999"
    },
    {
        "key": "+260",
        "text": "Zambia",
        "value": "ZM",

        "name": "99 999 9999"
    },
    {
        "key": "+263",
        "text": "Zimbabwe",
        "value": "ZW",

        "name": "9 999999"
    }
] 