import { Record, Map } from 'immutable';
import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { IUploadFileInfo, UploadFileInfo, UploadFileInfoFactory, UploadFileInfoFactoryFromJS } from '.';
import { IUploadFileThread, UploadFileThread, UploadFileThreadFactoryFromJS } from '../../..';

interface IBaseUploadFileItem {
    id: string
}

export interface IUploadFileItem extends IBaseUploadFileItem {
    info?: IUploadFileInfo,
    threads?: IDictionary<IUploadFileThread>
}

export interface IReadonlyUploadFileItem extends IBaseUploadFileItem {
    info?: UploadFileInfo,
    threads?: Map<string, UploadFileThread>
}

export type UploadFileItem = ReadonlyRecord<IReadonlyUploadFileItem>;

export const UploadFileItemFactory = Record<IReadonlyUploadFileItem>({
    id: "",
    info: UploadFileInfoFactory(),
    threads: Map<string, UploadFileThread>()
});

export const UploadFileItemFactoryFromJS = function (data: IUploadFileItem) {
    let _threads: IDictionary<UploadFileThread> = data && data.threads && Object.keys(data.threads).reduce((previous: unknown, current) => {
        return UploadFileThreadFactoryFromJS(data.threads[current]);
    }, {});

    return UploadFileItemFactory({
        id: data.id,
        info: UploadFileInfoFactoryFromJS(data.info),
        threads: Map(_threads)
    });
}