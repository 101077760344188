import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { SearchMember, ISearchMember, SearchMemberFactoryFromJS } from '../../../../../../security/entities/SearchMembers';
import { IPermissionGroup, PermissionGroup, PermissionGroupFactoryFromJS } from '../../../../../../security/entities/PermissionGroup';

interface IBaseDMSecurityState {
    id: string,
    object: {
        name: string,
        secretKey: string
    },
    searchText: string,
    searchLoading: boolean,
    permissionLoading: boolean,
    memberInfoLoading: boolean,
    permissionGroupsLoading: boolean
}

export interface IDMSecurityState extends IBaseDMSecurityState {
    searchResults: ISearchMember[],
    selectedMembers: ISearchMember[],
    selectedPermissionGroup: IPermissionGroup
    permissionGroups: IPermissionGroup[],
    suggestedGroups: IPermissionGroup[]
}

export interface IReadonlyDMSecurityState extends IBaseDMSecurityState {
    searchResults: List<SearchMember>,
    selectedMembers: List<SearchMember>,
    selectedPermissionGroup: PermissionGroup,
    permissionGroups: List<PermissionGroup>,
    suggestedGroups: List<PermissionGroup>,

}

export type DMSecurityState = ReadonlyRecord<IReadonlyDMSecurityState>;

export const DMSecurityStateFactory = Record<IReadonlyDMSecurityState>({
    id: null,
    object: null,
    searchText: null,
    searchResults: List(),
    searchLoading: false,
    permissionLoading: false,
    memberInfoLoading: false,
    permissionGroupsLoading: false,
    selectedPermissionGroup: null,
    suggestedGroups: List(),
    permissionGroups: List(),
    selectedMembers: List(),
});

export const DMSecurityStateFactoryFromJS = function (data: IDMSecurityState) {
    let _searchResults = data && data.searchResults ? data.searchResults.map(r => SearchMemberFactoryFromJS(r)) : [];
    let _selectedMembers = data && data.selectedMembers ? data.selectedMembers.map(r => SearchMemberFactoryFromJS(r)) : [];
    let _suggestedGroups = data && data.suggestedGroups ? data.suggestedGroups.map(g => PermissionGroupFactoryFromJS(g)) : [];
    let _permissionGroups = data && data.permissionGroups ? data.permissionGroups.map(g => PermissionGroupFactoryFromJS(g)) : [];

    return DMSecurityStateFactory({
        ...data,
        selectedPermissionGroup: data.selectedPermissionGroup ? PermissionGroupFactoryFromJS(data.selectedPermissionGroup) : null,
        searchResults: List(_searchResults),
        suggestedGroups: List(_suggestedGroups),
        permissionGroups: List(_permissionGroups),
        selectedMembers: List(_selectedMembers)
    });
}