import { List } from 'immutable';
import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { NavigationHistoryItem } from '../entities/INavigationHistoryItem';

const getItems = (state: BaseState) => {
    return state.DMExplorer.navigationHistory;
}

const getHistoryItems = createSelector(
    [
        getItems
    ],
    (navHistory: List<NavigationHistoryItem>) => {
        return navHistory
    }
)

export default getHistoryItems;