import * as React from "react";
import BCPagination from "../BCPagination";
import * as Style from "./assets/css/style.scss";
import BCNavigator from "./components/BCNavigator";
import { IBreadCrumbItemClickEventArgs, IBreadCrumbItemProps, IBreadCrumbNavigationClickEventArgs, IBreadCrumbNavigationProps, IBreadCrumbProps, IBreadCrumbState } from './entities';

export default class BCBreadcrumb extends React.Component<IBreadCrumbProps, IBreadCrumbState> {
    readonly state: IBreadCrumbState;

    constructor(props: IBreadCrumbProps) {
        super(props);
        this.getBreadcrumbItems = this.getBreadcrumbItems.bind(this);
        this.renderNavigator = this.renderNavigator.bind(this);

        this.state = {
            overflowIndex: 0
        }
    }

    componentDidMount() {
        this.props.onCreate?.();
    }

    getBreadcrumbItems(): any[] {
        let returnValue: any[] = [];
        if (this.props.items?.length) {
            this.props.items.forEach((item: IBreadCrumbItemProps, index: number) => {
                if (!item.isHidden) {
                    returnValue.push({
                        onClickCloseIcon: (ev: React.MouseEvent<HTMLElement>, item: any) => this.onItemCloseClick({ data: item, senderArgs: ev, overflowIndex: index }),
                        onClick: (ev: React.MouseEvent<HTMLElement>, item: any) => this.onItemClick({ data: item, senderArgs: ev, overflowIndex: index }),
                        ...item
                    });
                }
            })
        }
        return returnValue;
    }

    renderPagination() {
        if (!this.getBreadcrumbItems().length) return
        return (
            <BCPagination
                className={Style.breadcrumb}
                items={this.getBreadcrumbItems()}
            />
        )
    }

    renderNavigator() {
        if (this.props.disableNavigator) return;

        let isLeftNavigatorDisable = false;
        let isRightNavigatorDisable = false;

        if (this.props.items?.length > 1) {
            isLeftNavigatorDisable = this.props.items[0].isActive;
            isRightNavigatorDisable = this.props.items[this.props.items.length - 1].isActive;
        } else {
            isLeftNavigatorDisable = true;
            isRightNavigatorDisable = true;
        }

        return (
            <BCNavigator
                isLeftNavigatorDisable={isLeftNavigatorDisable}
                isRightNavigatorDisable={isRightNavigatorDisable}
                onClick={this.props.onNavigationItemClick}
                visible={true}
                navigatorStyle={this.props.navigatorStyle}
            />
        )
    }

    onItemCloseClick(item: IBreadCrumbItemClickEventArgs) {
        item.senderArgs.persist();
        item.senderArgs.stopPropagation();
        if (this.props.onItemCloseClick)
            this.props.onItemCloseClick(item);
    }

    onItemClick(item: IBreadCrumbItemClickEventArgs) {
        item.senderArgs.persist();
        item.senderArgs.stopPropagation();
        if (this.props.onItemClick)
            this.props.onItemClick(item);

        let index = 0;
        if (this.props.items.length < (item.overflowIndex + 3)) {
            index = this.props.items.length - 1;
        }
        else {
            index = item.overflowIndex + 3;
        }

        this.setState({
            overflowIndex: index
        });
    }


    onNavigationItemClick(args: IBreadCrumbNavigationClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onNavigationItemClick)
            this.props.onNavigationItemClick(args);
    }

    render() {
        return (
            <>
                {!this.props.hideNav && this.renderNavigator()}
                <div className={[Style.breadcrumbContainer, "breadcrumbContainer"].join(" ")}>
                    {!this.props.hideItems && this.renderPagination()}
                </div>
            </>
        )
    }
}

export { IBreadCrumbProps, IBreadCrumbState, IBreadCrumbItemProps, IBreadCrumbNavigationProps, IBreadCrumbItemClickEventArgs, IBreadCrumbNavigationClickEventArgs };
