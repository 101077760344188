import { BCList, BCScrollbars } from '@bimser/components';
import * as React from 'react';
import { IMenuItem } from '../../../entities/IMenuItem';
import * as Style from '../assets/style.scss';
import { IPanelMenuCProps, IPanelMenuProps } from '../entities';
import PanelMenuItem from './panelMenuItem';
let classNames = require('classnames/bind');
let cx = classNames.bind(Style);

const containerClassNames = cx({
    panelMenuContainer: true
});
const scrollbarsStyles = { width: '100%', height: '100%' };

const PanelMenu: React.FC<IPanelMenuCProps & IPanelMenuProps> = ({
    items,
    activeNodeId,
    showLoading,
    panelGuid,
    onItemClicked,
    firePanelItemEvent
}) => {

    const onClickMenuItem = (item: IMenuItem) => {
        onItemClicked?.({
            data: item,
            panelId: panelGuid || ''
        });
    };

    const hasMarginCss = (item: IMenuItem) => {
        if (item?.children?.length) {
            const _children = Object.values(item.children);
            const lastChild = _children[_children.length - 1];
            if (lastChild && lastChild.isActive && lastChild.children?.length) {
                return false;
            }
            return true;
        }
    };

    const renderListItem = (item: IMenuItem): React.ReactNode => {
        const _children = item.children && Object.values(item.children);
        const itemClassNames = cx({
            panelMenuItem: true,
            active: !(_children?.length) && (item.id == activeNodeId || item.isActive),
            hasChildren: _children?.length,
            expanded: item.isExpanded,
        });

        const subListClassNames = cx({
            subListContainer: true,
            hasMargin: hasMarginCss(item)
        });

        return (
            <>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickMenuItem(item);
                    }}
                    className={itemClassNames}
                >
                    {<PanelMenuItem item={item} fireMenuItemEvent={firePanelItemEvent} />}
                </div>
                {item.isActive && _children?.length ? (
                    <div className={subListClassNames}>
                        <BCList dataSource={_children} bordered={false} split={false} renderItem={renderListItem} />
                    </div>
                ) : null}
            </>
        );
    };

    const renderMenu = () => {
        return (
            <div className={containerClassNames}>
                <BCScrollbars styles={scrollbarsStyles} autoHide>
                    <BCList
                        dataSource={items}
                        bordered={false}
                        split={false}
                        loading={showLoading}
                        renderItem={renderListItem}
                    />
                </BCScrollbars>
            </div>
        );
    };

    return <>{renderMenu()}</>;
};

export default React.memo(PanelMenu);
