import { createSelector } from 'reselect';
import { DmMainState, DmData, IDmData } from '../../../entities';
import { DmRecentItem, IDmRecentItem } from '../../../entities/models/IDmRecentItem';
import { List } from 'immutable';
import { WebInterfaceBaseState } from '../../../../../common/base/state';

const data = (state: WebInterfaceBaseState) => {
    let recentIds = state.modules.dm.recentFiles;
    return recentIds.map(r=> state.modules.dm.data.get(r));
}
const getRecentFiles = createSelector(
    [
        data
    ],
    (files: List<DmData>): IDmData[] => {
        return files.toJS();
    }
);

export { getRecentFiles }