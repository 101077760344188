import React, { lazy, Suspense } from 'react';
import { IOvertimeFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';

const OvertimeContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function OvertimeContainer(props: React.PropsWithChildren<IOvertimeFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <OvertimeContainerLazy {...props} />
        </Suspense>
    );
}
OvertimeContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default OvertimeContainer;