import { Mobile } from '@bimser/common';
import { IBadgeProps } from "@bimser/components";
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { childrenDisplayMode, nodeActionType } from "../components/panelMenu/entities/enums";
import { panelType } from "./enums";
import { IMenuItemEvent } from "./IMenuItemEvent";


export interface IBaseMenuItem {
    id?: string,
    isActive?: boolean,
    icon?: string,
    text?: string,
    style?: React.CSSProperties,
    info?: string,
    badge?: IBadgeProps,
    isSubMenu?: boolean,
    isSubItem?: boolean,
    isExpanded?: boolean,
    childrenDisplayMode?: childrenDisplayMode,
    isPinned?: boolean,
    panelType?: panelType,
    enableActiveBorder?: boolean,
    data?: any,
    displayOrderNo?: number,
    nodeKey?: string,
    nodeActionType?: nodeActionType,
    isBaseItem?: boolean,
    isMovingItem?: boolean,
    isFirstItem?: boolean,
    isMovedItem?: boolean,
    // rowId?: string,
    level?: number,
    // caption?: string,
    key?: string,
    name?: string,
    mobileFormView?: Mobile.MobileFormView
}

export interface IMenuItem extends IBaseMenuItem {
    children?: Array<IMenuItem>,
    events?: Array<IMenuItemEvent>,
    path?: Array<string>,
    caption?: IDictionary<string>,
    parameters?: IDictionary<string>,
    profileIds?: Array<string>,
}

export interface IReadonlyMenuItem extends IBaseMenuItem {
    children?: List<MenuItem>,
    events?: List<IMenuItemEvent>,
    path?: List<string>,
    caption?: Map<string, string>,
    parameters?: Map<string, string>,
    profileIds?: List<string>,
}

export type MenuItem = ReadonlyRecord<IReadonlyMenuItem>;

export const MenuItemFactory = Record<IReadonlyMenuItem>({
    badge: null,
    childrenDisplayMode: childrenDisplayMode.None,
    children: List<MenuItem>(),
    enableActiveBorder: false,
    events: List<IMenuItemEvent>(),
    icon: "",
    info: "",
    isActive: false,
    isExpanded: false,
    isPinned: false,
    isSubItem: false,
    isSubMenu: false,
    id: "",
    panelType: panelType.none,
    path: List<string>(),
    style: null,
    text: "",
    data: null,
    caption: Map<string, string>(),
    parameters: Map<string, string>(),
    displayOrderNo: 0,
    nodeActionType: nodeActionType.None,
    nodeKey: "",
    isBaseItem: false,
    isMovingItem: false,
    isFirstItem: false,
    isMovedItem: false,
    // rowId: "",
    level: 0,
    key: "",
    // caption: ""
    profileIds: List<string>(),
    name: null,
    mobileFormView: 3
});

export const MenuItemFactoryFromJS = function (data: IMenuItem) {
    let _children: Array<MenuItem> = data.children && data.children.length ? data.children.map((item: IMenuItem) => {
        return MenuItemFactoryFromJS(item);
    }) : [];

    let _caption: IDictionary<string> = data.caption ? Object.keys(data.caption).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.caption[current];
        return previous;
    }, {}) : {};

    let _parameters: IDictionary<string> = data.parameters ? Object.keys(data.parameters).reduce(function (previous: IDictionary<string>, current) {
        previous[current] = data.parameters[current];
        return previous;
    }, {}) : {};

    return MenuItemFactory({
        isActive: data.isActive,
        text: data.text,
        panelType: data.panelType,
        info: data.info,
        children: List(_children),
        events: List(data.events),
        badge: data.badge,
        childrenDisplayMode: data.childrenDisplayMode,
        enableActiveBorder: data.enableActiveBorder,
        icon: data.icon,
        isExpanded: data.isExpanded,
        isPinned: data.isPinned,
        isSubItem: data.isSubItem,
        isSubMenu: data.isSubMenu,
        id: data.id,
        path: List(data.path),
        style: data.style,
        data: data.data,
        caption: Map(_caption),
        parameters: Map(_parameters),
        displayOrderNo: data.displayOrderNo,
        isBaseItem: data.isBaseItem,
        isFirstItem: data.isFirstItem,
        isMovingItem: data.isMovingItem,
        nodeActionType: data.nodeActionType,
        nodeKey: data.nodeKey,
        isMovedItem: data.isMovedItem,
        // rowId: data.rowId,
        level: data.level,
        key: data.key,
        // caption: data.caption
        profileIds: List(data.profileIds),
        name: data.name,
        mobileFormView: data.mobileFormView
    });
}