import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import * as React from "react";
import { BCButton, BCDropdown, findMessage } from "../..";
import { IButtonClickEventArgs } from '../../BCButton';
import * as Styles from '../assets/conditionalStatementStyles.scss';
import { IConditionGroup, ICRule } from "../entities";
import Condition from "./Condition";
import IconDelete from "@bimser/icons/16/delete";
import IconAdd from "@bimser/icons/16/add";
import IconTabNavRight from "@bimser/icons/16/tab-nav-right";

var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);


const addItems = [
    {
        key: "AddGroup",
        label: findMessage.get('100611'),
    },
    {
        key: "AddCondition",
        label: findMessage.get('101824'),
    }
];


export default class ConditionGroup extends React.Component<{ conditionGroup: IConditionGroup } & ICRule, {}> {

    private styles: any;

    constructor(props: { conditionGroup: IConditionGroup } & ICRule) {
        super(props);

        this.onConjunctionAddClicked = this.onConjunctionAddClicked.bind(this);
        this.renderConjunction = this.renderConjunction.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.collapsedClick = this.collapsedClick.bind(this);

        this.styles = {
            clearFix: { clear: "both", height: "18px" },
            deleteButton: { width: "24px", height: "24px", background: "transparent", border: "transparent", padding: "0px", marginLeft: "4px", float: "left", opacity: 0.25 },
            addButton: { width: "24px", height: "24px", background: "transparent", border: "transparent", padding: "0px", marginLeft: "4px", opacity: 0.25 },
            conjunction: { width: "100px", marginLeft: "6px", float: "left" }
        };
    }

    onDeleteClick(e: IButtonClickEventArgs) {
        this.props.deleteConditionGroupFrom(this.props.conditionGroup.id, this.props.statementType);
    }

    renderDeleteConditionGroupButton(): JSX.Element {

        if (this.props.readonly) return undefined;

        return (
            <BCButton type={'link'} onClick={this.onDeleteClick} cssClass={Styles.deleteButton} icon={<IconDelete />} />
        );
    }

    renderAddButton() {

        if (this.props.readonly) return undefined;

        return (
            <Dropdown trigger={['click']} overlay={
                <Menu
                    multiple={false}
                    onClick={this.onConjunctionAddClicked}
                    items={addItems}
                />
            }>
                <BCButton cssClass={Styles.addButton} type={'link'} icon={<IconAdd />} />
            </Dropdown>
        )
    }

    getConjuctionTypes() {
        return [
            { key: 'AndConditionGroup', text: 'AND', name: 'AndConditionGroup', value: 'AndConditionGroup' },
            { key: 'OrConditionGroup', text: 'OR', name: 'OrConditionGroup', value: 'OrConditionGroup' }
        ]
    }

    collapsedClick() {
        if (this.props.updateConditionGroupCollapse) this.props.updateConditionGroupCollapse(this.props.conditionGroup.id, this.props.statementType, !this.props.conditionGroup.collapsed);
    }

    renderCollapseIcon() {
        let classNames = cx({ levelCollapseIcon: true, collapsed: this.props.conditionGroup.collapsed });
        const hasChilds = (this.props.conditionGroup.children && this.props.conditionGroup.children.length) || (this.props.conditionGroup.conditions && this.props.conditionGroup.conditions.length);
        return (
            <div className={classNames} onClick={this.collapsedClick}>
                {hasChilds ? <IconTabNavRight /> : undefined}
            </div>
        )
    }

    renderConjunction(): JSX.Element {
        return (
            <div className={Styles.qb_condition_group_conjunction}>
                <div className={[Styles.conditionItemContent, Styles.autoWidth].join(" ")}>
                    {this.renderCollapseIcon()}
                    <BCDropdown
                        items={this.getConjuctionTypes()}
                        onSelected={(e) => { this.props.conjunctionChanged(this.props.conditionGroup.id, e.data.value, this.props.statementType) }}
                        defaultValue={this.props.conditionGroup.typeName}
                        className={[Styles.selectionBox, Styles.conjunction].join(' ')}
                        optionClassName={Styles.selectionBoxOptions}
                        disabled={this.props.readonly}
                    />
                    {this.renderAddButton()}
                    {this.renderDeleteConditionGroupButton()}
                </div>
            </div>
        )
    }

    onConjunctionAddClicked(e: any) {
        switch (e.key) {
            case "AddCondition": {
                if (this.props.addConditionTo) this.props.addConditionTo(this.props.conditionGroup.id, this.props.statementType);
                break;
            }
            case "AddGroup": {
                if (this.props.addConditionGroupTo) this.props.addConditionGroupTo(this.props.conditionGroup.id, this.props.statementType);
                break;
            }
            default: console.warn("Invalid operation exception");
        }
    }

    renderConditions(): JSX.Element {
        if (!this.props.conditionGroup.collapsed) {
            return (
                <div key={this.props.conditionGroup.id} className={Styles.conditionItem}>
                    {
                        this.props.conditionGroup.conditions && this.props.conditionGroup.conditions.map(condition =>
                        (
                            <Condition
                                key={condition.id}
                                {...condition}
                                fields={this.props.fields}
                                updateCondition={this.props.updateCondition}
                                statementType={this.props.statementType}
                                deleteConditionFrom={this.props.deleteConditionFrom}
                                readonly={this.props.readonly}
                            />
                        )
                        )
                    }
                </div>
            );
        }
    }

    renderChildren(): JSX.Element {
        if (!this.props.conditionGroup.collapsed) {
            return (
                <React.Fragment>
                    {
                        this.props.conditionGroup.children && this.props.conditionGroup.children.map(child =>
                        (
                            <div key={child.id} className={Styles.conditionItem}>
                                <ConditionGroup
                                    conditionGroup={child}
                                    addConditionGroupTo={this.props.addConditionGroupTo}
                                    deleteConditionGroupFrom={this.props.deleteConditionGroupFrom}
                                    statementType={this.props.statementType}
                                    conjunctionChanged={this.props.conjunctionChanged}
                                    addConditionTo={this.props.addConditionTo}
                                    fields={this.props.fields}
                                    updateCondition={this.props.updateCondition}
                                    deleteConditionFrom={this.props.deleteConditionFrom}
                                    updateConditionGroupCollapse={this.props.updateConditionGroupCollapse}
                                    readonly={this.props.readonly}
                                />
                            </div>
                        )
                        )
                    }
                </React.Fragment>
            );
        }
    }

    renderConditionGroup(): JSX.Element {
        return (
            <React.Fragment>
                {this.renderConjunction()}
                {this.renderConditions()}
                {this.renderChildren()}
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.renderConditionGroup()
                }
            </React.Fragment>
        );
    }

}