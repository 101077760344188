import * as React from "react";
import * as Style from "./assets/style.scss";
import { IFileExplorerItemClickEventArgs, IFileExplorerItemProps } from './entities';
import { GetIconComponent } from '@bimser/icons';

var classNames = require('classnames/bind');
let cx = classNames.bind(Style);

const itemStlyes: React.CSSProperties = { marginLeft: 'auto', marginRight: 'auto' }

export default class BCFileExplorerItem extends React.Component<IFileExplorerItemProps, {}>{

    constructor(props: IFileExplorerItemProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
    }

    private onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let clickEventArgs: IFileExplorerItemClickEventArgs = {
            senderArgs: e,
            data: this.props
        };

        this.props.onClick(clickEventArgs);
        e.stopPropagation();
    }

    private onDoubleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let clickEventArgs: IFileExplorerItemClickEventArgs = {
            senderArgs: e,
            data: this.props
        };

        this.props.onDoubleClick(clickEventArgs);
        e.stopPropagation();
    }

    render() {

        let classNames = cx({
            fileExplorerItem: true,
            selected: this.props.selected
        });
        const Icon = GetIconComponent(this.props.icon, {
            width: "38px",
            height: "50px",
            style: itemStlyes
        });

        return (
            <div className={classNames} onClick={this.onClick} onDoubleClick={this.onDoubleClick}>
                <div className={Style.fileIcon}>
                    {Icon}
                </div>
                <span className={Style.fileName} title={this.props.text}>
                    {this.props.text}
                </span>
            </div>
        )

    }
}