import { fileSizeConverter, FileSizeType, formatBytes, isNullOrUndefined } from "@bimser/core";
import * as React from "react";
import BCCombobox, { IComboboxSelectedChangeEventArgs } from '../../../BCCombobox';
import BCInputNumber from '../../../BCInputNumber';
import * as ClassNames from '../../assets/style.scss';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

interface IState { inputValue: number, comboboxValue: FileSizeType; }
export default class InputNumberAddOnProperty extends React.Component<IPropertyComponentProps, IState>{
    constructor(props: IPropertyComponentProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormat = this.onFormat.bind(this);
        this.onParser = this.onParser.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
        this.onComboboxChange = this.onComboboxChange.bind(this);
        this.state = {
            comboboxValue: this.props.value ? formatBytes(this.props.value).sizeType : this.props.item.externalData.addonAfterDefaultValue,
            inputValue: this.props.value ? formatBytes(this.props.value).value : null
        }
    }

    replaceValue(item: IPropertyItem, newValue: any) {
        if (this.props.value || !isNullOrUndefined(newValue)) {
            this.props.onValueChanged(item, newValue);
        }
    }

    onChange(value: any) {
        this.props.customChangeEvent ? this.props.customChangeEvent(value, this.props) : this.replaceValue(this.props.item, value)
    }

    onInputNumberChange(inputValue: any) {
        this.setState({ inputValue })
        this.onChange(fileSizeConverter(inputValue, this.state.comboboxValue, FileSizeType.B));
    }

    onComboboxChange(args: IComboboxSelectedChangeEventArgs) {
        if (this.state.inputValue) {
            this.setState({ inputValue: fileSizeConverter(this.props.value, FileSizeType.B, args.data as FileSizeType) });
        }
        this.setState({ comboboxValue: args.data as FileSizeType })
    }

    onFormat(value: any) {
        return this.props.item.externalData?.formatter ? this.props.item.externalData.formatter(value) : value
    }

    onParser(value: any) {
        return this.props.item.externalData?.parser ? this.props.item.externalData.parser(value) : value
    }

    render() {
        return (
            <div className={ClassNames.InputNumberAddOnContainer}>
                <BCInputNumber
                    id={this.props.item.key}
                    value={this.state.inputValue === undefined || this.props.value === null ? this.props.item.placeholder : this.state.inputValue as any}
                    style={this.props.style}
                    className={ClassNames.inputNumber}
                    disabled={this.props.item.disabled}
                    readOnly={this.props.item.readOnly}
                    min={this.props.minValue}
                    formatter={this.onFormat}
                    parser={this.onParser}
                    onChange={this.onInputNumberChange}
                />
                <BCCombobox
                    defaultValue={this.state.comboboxValue}
                    options={this.props.item.externalData.addonAfterOptions}
                    showSearch={false}
                    className={ClassNames.comboBox}
                    onSelectedChange={this.onComboboxChange}
                />
            </div>
        );
    }
}