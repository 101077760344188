import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { ViewerType, ViewerMode } from '.';

interface IBaseViewerItem {
    id?: string,
    panelId?: string,
    secretKey?: string,
    versionSecretKey?: string,
    isLoading?: boolean,
    mode?: ViewerMode,
    data?: any,
    viewerType?: ViewerType,
    userSelectedViewerType?: ViewerType,
    fileName?: string,
    mimeType?: string,
    language?: string,
    commitFile?: File,
    requestTime?: string
}

export interface IViewerItem extends IBaseViewerItem {
    
}

export interface IReadonlyViewerItem extends IBaseViewerItem {
    
}

export type ViewerItem = ReadonlyRecord<IReadonlyViewerItem>;

export const ViewerItemFactory = Record<IReadonlyViewerItem>({
    id: "",
    panelId: "",
    secretKey: "",
    versionSecretKey: null,
    isLoading: true,
    mode: "view",
    data: null,
    viewerType: ViewerType.Unsupported,
    userSelectedViewerType: null,
    fileName: "",
    mimeType: "",
    language: "",
    commitFile: null,
    requestTime: null
});

export const ViewerItemFactoryFromJS = function (data: IViewerItem) {
    return ViewerItemFactory(data);
}