import { Record, Map, List } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";

export interface IBaseConfigurationManagerNodeItem {
	id: string,
	items: Array<string>,
	nodes: Array<string>,
}

export interface IConfigurationManagerNodeItemMenuType extends IBaseConfigurationManagerNodeItem {
	text: string,
	description: string,
	showWarningIcon: boolean,
	selected: boolean
}

export interface IConfigurationManagerNodeItem extends IBaseConfigurationManagerNodeItem {
	text: IDictionary<string>,
	description: IDictionary<string>,
}

export interface IReadonlyConfigurationManagerNodeItem {
	id: string,
	text: Map<string, string>,
	description: Map<string, string>,
	items: List<string>,
	nodes: List<string>,
}

export type ConfigurationManagerNodeItem = ReadonlyRecord<IReadonlyConfigurationManagerNodeItem>;

export const ConfigurationManagerNodeItemFactory = Record<IReadonlyConfigurationManagerNodeItem>({
	id: null,
	text: Map<string, string>(),
	description: Map<string, string>(),
	items: List<string>(),
	nodes: List<string>(),
});

export const ConfigurationManagerNodeItemFactoryFromJS = function (data: IConfigurationManagerNodeItem) {
	let _text: IDictionary<string> = Object.keys(data.text).reduce(function (previous: IDictionary<string>, current) {
		previous[current] = data.text[current];
		return previous;
	}, {});

	let _description: IDictionary<string> = Object.keys(data.description).reduce(function (previous: IDictionary<string>, current) {
		previous[current] = data.description[current];
		return previous;
	}, {});

	let _items: Array<string> = data.items && data.items.length ? data.items.map((value: string) => {
		return value;
	}) : [];

	let _nodes: Array<string> = data.nodes && data.nodes.length ? data.nodes.map((value: string) => {
		return value;
	}) : [];

	return ConfigurationManagerNodeItemFactory({
		id: data.id,
		text: Map(_text),
		description: Map(_description),
		items: List(_items),
		nodes: List(_nodes),
	});
};