import { BCErrorBoundary, DragDropContextProviderContainer } from "@bimser/components";
import * as React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import ModalFrameManagerContainer from "../ModalFrameManager/containers";
import ModalManagerContainer from "../ModalManager/containers";
import RouterContainer from "../Router/containers/RouterContainer";
import ThemeChangerContainer from "../ThemeChanger/containers/ThemeChangerContainer";
import BCIntlProviderContainer from './BCIntlProviderContainer';
import IProviderProps from "./entities/IProviderProps";
import { Mobile } from '../../utilities/MobileView'

export default class BCProvider extends React.Component<IProviderProps, {}> {
    render() {
        return (
            <HashRouter>
                <Provider store={this.props.store}>
                    <BCIntlProviderContainer>
                        <ThemeChangerContainer>
                            <DragDropContextProviderContainer>
                                <BCErrorBoundary mobile={Mobile}>
                                    <>
                                        <ModalManagerContainer components={this.props.modalComponents} />
                                        <ModalFrameManagerContainer />
                                        <RouterContainer {...{ SynergyLogonApp: this.props.SynergyLogonApp }}>
                                            {this.props.children}
                                        </RouterContainer>
                                    </>
                                </BCErrorBoundary>
                            </DragDropContextProviderContainer>
                        </ThemeChangerContainer>
                    </BCIntlProviderContainer>
                </Provider>
            </HashRouter>
        );
    }
}