import * as React from "react";
import BCMenu, { IMenuItemClickEventArgs } from '../BCMenu';
import { IArgumentAboutRightClickEvent, IContextMenuClickEventArgs, IContextMenuOpenArgs, IContextMenuProps, } from './entities';
import * as Styles from './assets/contextMenuStyles.scss';
import { debounce, IItem, isNullOrUndefined } from "@bimser/core"
export default class BCContextMenu extends React.Component<IContextMenuProps, { openKeys?: Array<string> }> {

    lastClicktedItem: IItem;

    constructor(props: IContextMenuProps) {
        super(props);
        this._handleClick = debounce(this._handleClick.bind(this), 30);
        this.onClick = this.onClick.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
        this.state = {
            openKeys: []
        }
    }

    contextMenuRefDom: HTMLElement;

    componentDidMount() {
        this.triggerContextMenuPosition();
    }

    componentDidUpdate() {
        this.triggerContextMenuPosition();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this._handleClick, true);
        document.removeEventListener('scroll', this._handleClick, true);
    }

    triggerContextMenuPosition() {
        if (this.props.mouseEvent || (this.props.clientX && this.props.clientY)) {
            this.contextMenuPosition();
        }
    }

    clearScroll() {
        this.contextMenuRefDom.style.maxHeight = "unset";
        this.contextMenuRefDom.style.overflowY = "unset";
    }

    private contextMenuPosition() {
        let _event: React.MouseEvent = this.props.mouseEvent;

        let clickX = _event ? _event.clientX : this.props.clientX;
        let clickY = _event ? _event.clientY : this.props.clientY;
        clickX = clickX + (this.props.marginX || 0);
        clickY = clickY + (this.props.marginY || 0);


        let screenW = window.innerWidth;//_event.screenX;
        let screenH = window.innerHeight;//_event.screenY;
        let rootW = this.contextMenuRefDom.offsetWidth;
        let rootH = this.contextMenuRefDom.offsetHeight;

        let right = (screenW - clickX) > rootW;
        let left = !right;
        let top = (screenH - clickY) > rootH;
        let bottom = !top;

        this.contextMenuRefDom.style.position = `fixed`;
        this.contextMenuRefDom.style.zIndex = `999`;

        if (right) {
            this.contextMenuRefDom.style.left = `${clickX + 5}px`;
            this.clearScroll();
        }

        if (left) {
            this.contextMenuRefDom.style.left = `${clickX - rootW - 5}px`;
            this.clearScroll();
        }

        if (top) {
            this.contextMenuRefDom.style.top = `${clickY + 5}px`;
            this.clearScroll();
        }

        if (bottom) {
            this.contextMenuRefDom.style.top = `${clickY - rootH - 5}px`;
            if (clickY - rootH < 0) {
                this.contextMenuRefDom.style.top = `5px`;
                this.contextMenuRefDom.style.maxHeight = `${clickY - 5}px`;
                this.contextMenuRefDom.style.overflowY = "scroll";
            } else {
                this.clearScroll();
            }
        }

        document.addEventListener('click', this._handleClick, true);
        document.addEventListener('scroll', this._handleClick, true);
    }

    _handleClick = (event: any) => {
        const isEventOutOfMenu = !this.contextMenuRefDom?.contains?.(event.target);
        const isEventScroll = event.type == "scroll";
        const isClickEvent = event.type == "click";
        const isScrollAndOutOfMenu = (isEventOutOfMenu && (isEventScroll && this.props.closeWhenAnyScroll))
        const shouldCloseContextMenu = isEventOutOfMenu || (isNullOrUndefined(this.props.keepOpenParents) || !this.props.keepOpenParents || !this.lastClicktedItem?.items?.length);

        if (isClickEvent || (isScrollAndOutOfMenu && this.props.visible)) {
            if (this.props.onClose && (shouldCloseContextMenu || isScrollAndOutOfMenu)) {
                this.props.onClose(false);
            }
            document.removeEventListener('click', this._handleClick, true);
            document.removeEventListener('scroll', this._handleClick, true);
            if (this.props.useStateForOpenKeys && shouldCloseContextMenu) {
                this.setState({
                    openKeys: [],
                });
            }
        }
    }

    onClick(args: IMenuItemClickEventArgs) {
        args.domEvent?.stopPropagation();
        args.domEvent?.preventDefault();
        this.lastClicktedItem = args.data;
        const shouldCloseContextMenu = isNullOrUndefined(this.props.keepOpenParents) || !this.props.keepOpenParents || !args.data?.items?.length;

        if (this.props.onClick) {
            this.props.onClick({ sender: args.domEvent, data: args.data, parentNode: this.props.parentNode });
        }
        if (this.props.useStateForOpenKeys && shouldCloseContextMenu) {
            this.setState({
                openKeys: [],
            });
        }
        if (this.props.onClose && shouldCloseContextMenu) {
            this.props.onClose(false);
        }
    }

    onOpenChange(openKeys: string[]) {
        if (this.props.useStateForOpenKeys) {
            this.setState({
                openKeys: openKeys,
            });
        }
        if (this.props.onOpenChange) {
            this.props.onOpenChange(openKeys);
        }
    }

    render() {
        return (
            <div
                style={this.props.style}
                className={Styles.contextMenu + " " + (this.props.className ? this.props.className : "")}
                ref={(contextMenuRefDom) => { this.contextMenuRefDom = contextMenuRefDom }}>
                {
                    this.props.visible &&
                    <BCMenu
                        style={this.props.menuStyle}
                        isLoading={this.props.isLoading ? this.props.isLoading : false}
                        forceNativeClick={this.props.forceNativeClick}
                        forceSubMenuRender={this.props.forceSubMenuRender}
                        mode="vertical"
                        items={this.props.items}
                        key="ContextMenu"
                        onClick={this.onClick}
                        popupClassName={Styles.subMenuContextMenu}
                        onOpenChange={this.onOpenChange}
                        openKeys={this.props.useStateForOpenKeys ? this.state.openKeys : this.props.openKeys}
                    />
                }
            </div>
        );
    }
}

export { IContextMenuProps, IContextMenuClickEventArgs, IContextMenuOpenArgs, IArgumentAboutRightClickEvent };
