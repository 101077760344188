import * as React from "react";
import BCRadioButtonGroup from '../../../BCRadioButtonGroup';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function RadioProperty(props: IPropertyComponentProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    return (
        <BCRadioButtonGroup
            onSelectedChange={(selectedRadio) => { props.customChangeEvent ? props.customChangeEvent(selectedRadio) : replaceValue(props.item, selectedRadio.data) }}
            mode={'horizontal'}
            value={props.value}
            options={props.item.options}
            disabled={props.item.disabled || props.item.readOnly}
            style={props.style}
        />
    )
}