import Form from 'antd/lib/form';
import * as React from "react";
import { IFormProps, FormLayout } from "../entities";
import BCLoading from '../../BCLoading';
import _omit from "lodash/omit";

//validation helper => Utils/validationHelper in components

export default class BCForm extends React.Component<IFormProps, {}> {

    renderForm(): JSX.Element {
        return (
            <Form layout={this.props.layout} className={this.props.className} id={this.props.id} {..._omit(this.props, ["showLoading"])}>
                {this.props.children}
            </Form>
        )
    }

    render() {
        return (
            <>
                {
                    this.props.showLoading
                        ?
                        <BCLoading show={this.props.showLoading} delay={500} >
                            {this.renderForm()}
                        </BCLoading>
                        :
                        this.renderForm()
                }
            </>
        );
    }
}

export { IFormProps, FormLayout };