import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from 'immutable';
import { Filter, FilterFactory, FilterFactoryFromJS, IFilter } from './IFilter';
import { INavigationItem, NavigationItem, NavigationItemFactoryFromJS } from './INavigationItem';
import { IPagination, Pagination, PaginationFactory, PaginationFactoryFromJS } from './IPagination';


interface IBaseLoadedItem {
    itemTotal: number;
}

export interface ILoadedItem extends IBaseLoadedItem {
    filter?: IFilter;
    items?: Array<INavigationItem>;
    pagination: IPagination;
}

export interface IReadonlyLoadedItem extends IBaseLoadedItem {
    filter?: Filter,
    items: List<NavigationItem>,
    pagination: Pagination
}

export type LoadedItem = ReadonlyRecord<IReadonlyLoadedItem>;

export const LoadedItemFactory = Record<IReadonlyLoadedItem>({
    itemTotal: -1,
    filter: FilterFactory(),
    items: List(),
    pagination: PaginationFactory()
});

export const LoadedItemFactoryFromJS = function (data: ILoadedItem) {
    let _items = data.items ? data.items.map(i => NavigationItemFactoryFromJS(i)) : [];

    return LoadedItemFactory({
        itemTotal: data.itemTotal,
        filter: FilterFactoryFromJS(data.filter),
        items: List(_items),
        pagination: PaginationFactoryFromJS(data.pagination)
    });
}