import { DataGridEditType } from "../operations/DataGridEditType";

export enum DataGridCellType {
    text = "text",
    boolean = "boolean",
    actionButton = "actionButton",
    date = "date",
    number = "number",
    dateTime = "dateTime",
    time = "time",
    select = "select",
    multiLanguage = "multiLanguage",
    dateRange = "dateRange",
    numberBox= "numberBox"
}
export interface IDataGridCell {
    value: any,
    name: string,
    text?: string,
    index: number,
    editType: DataGridEditType,
    entityPath?: string,
}

export type DataGridCellCollection = Array<IDataGridCell>;