import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { List, Map, Record } from "immutable";
import * as moment from 'moment';
import { EventItem, EventItemFactoryFromJS, IEventItem } from "./IEventItem";
import { Mobile } from '@bimser/common';

interface IBaseProcessItem {
    projectName: string,
    projectCaption: string,
    processId: number,
    requestId: number,
    flagColor: string,
    requestDate: string,
    secretKey: string,
    isSeen: boolean,
    openerType?: number,
    flowName?: string,
    starterUser?: string,
    startDate?: string,
    packageVersion?: number,
    mobileFormView?: Mobile.MobileFormView
}

export interface IProcessItem extends IBaseProcessItem {
    projectCaptionML: IDictionary<string>,
    flowCaptionML: IDictionary<string>,
    events: Array<IEventItem>,
}

export interface IReadonlyProcessItem extends IBaseProcessItem {
    projectCaptionML: Map<string, string>,
    flowCaptionML: Map<string, string>,
    events: List<EventItem>,
}

export type ProcessItem = ReadonlyRecord<IReadonlyProcessItem>;

export const ProcessItemFactory = Record<IReadonlyProcessItem>({
    projectName: null,
    projectCaption: null,
    projectCaptionML: Map(),
    flowCaptionML: Map(),
    processId: null,
    requestId: null,
    flagColor: null,
    requestDate: moment().format(),
    secretKey: null,
    isSeen: false,
    events: List<EventItem>(),
    openerType: 0,
    flowName: null,
    starterUser: null,
    startDate: null,
    packageVersion: 0,
    mobileFormView: Mobile.MobileFormView.WebView
});

export const ProcessItemFactoryFromJS = function (data: IProcessItem) {

    let _events: Array<EventItem> = data.events && data.events.length ? data.events.map((event) => {
        return EventItemFactoryFromJS(event);
    }) : [];

    return ProcessItemFactory({
        projectName: data.projectName,
        projectCaption: data.projectCaption,
        projectCaptionML: Map(data.projectCaptionML),
        flowCaptionML: Map(data.flowCaptionML),
        processId: data.processId,
        requestId: data.requestId,
        flagColor: data.flagColor,
        requestDate: data.requestDate,
        secretKey: data.secretKey,
        isSeen: data.isSeen,
        events: List(_events),
        openerType: data.openerType,
        flowName: data.flowName,
        starterUser: data.starterUser,
        startDate: data.startDate,
        packageVersion: data.packageVersion,
        mobileFormView: data.mobileFormView
    });
}