import { createGuid, isNullOrUndefined } from "@bimser/core";
import { closeModalAction, openModalAction } from "../components/ModalManager/actions";
import { IModalState } from "../components/ModalManager/entities";
import { MessageBoxIcon } from "../components/ModalManager/enums";
import { openMessageBoxTransactionAction, resolveTransactionAction } from "../components/ModalManager/modals/MessageBox/actions";
import { IMessageBoxBaseRequest, IMessageBoxRequest, IMessageBoxResolvePayload } from "../components/ModalManager/modals/MessageBox/entities";

function show(request: IMessageBoxRequest) {
    return new Promise<IMessageBoxResolvePayload>((resolve, reject) => {
        if (!(MessageBoxManager as any).store) {
            throw "Store cannot be null";
        }

        let _id = isNullOrUndefined(request.id) ? createGuid() : request.id;

        let action = openMessageBoxTransactionAction({
            id: _id,
            title: request.title,
            content: request.content,
            type: isNullOrUndefined(request.messageType) ? MessageBoxIcon.None : request.messageType,
            buttons: request.buttons,
            width: request.width,
            errorDetail: request.errorDetail,
            errorCode: request.errorCode,
            error: request.error,
            showErrorDetail: request.showErrorDetail,
            onReturn: (promise: Promise<IMessageBoxResolvePayload>) => {
                promise.then((response) => {
                    resolve(response);
                });
            },
            props: request.props,
            notInCloseIcon: request.notInCloseIcon,
        });

        (MessageBoxManager as any).store.dispatch(action);
    });
}

export function MessageBoxManager(store?: any) {
    if (store) {
        (MessageBoxManager as any).store = store;
    }

    return {
        show: (request: IMessageBoxRequest) => {
            return show({
                id: request.id,
                title: request.title,
                width: request.width,
                content: request.content,
                buttons: request.buttons,
                messageType: request.messageType,
                props: request.props,
                notInCloseIcon: request.notInCloseIcon,
            });
        },
        info: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.Info,
                props: request.props,
                width: 'default',
                notInCloseIcon: request.notInCloseIcon,

            });
        },
        error: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.Error,
                props: request.props,
                errorDetail: request.errorDetail,
                showErrorDetail: request.showErrorDetail,
                width: 'default',
                notInCloseIcon: request.notInCloseIcon,
                errorCode: request.errorCode,
                error: request.error
            });
        },
        warning: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.Warning,
                errorDetail: request.errorDetail,
                showErrorDetail: request.showErrorDetail,
                props: request.props,
                width: request.width || 'default',
                notInCloseIcon: request.notInCloseIcon,

            });
        },
        success: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.Success,
                props: request.props,
                width: 'default',
                notInCloseIcon: request.notInCloseIcon,

            });
        },
        severeWarning: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.SevereWarning,
                props: request.props,
                width: 'default',
                notInCloseIcon: request.notInCloseIcon,

            });
        },
        Blocked: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.Blocked,
                props: request.props,
                width: 'default',
                notInCloseIcon: request.notInCloseIcon,

            });
        },
        custom: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.None,
                props: request.props,
                notInCloseIcon: request.notInCloseIcon,
                width: request.width
            });
        },
        confirm: (request: IMessageBoxBaseRequest) => {
            return show({
                id: null,
                title: request.title,
                content: request.content,
                buttons: request.buttons,
                messageType: MessageBoxIcon.Confirm,
                props: request.props,
                width: 'default',
                notInCloseIcon: request.notInCloseIcon,

            });
        },
        closeModal: (windowId: string) => {
            (MessageBoxManager as any).store.dispatch(closeModalAction(windowId))
        },
        openModal: (data: IModalState) => {
            (MessageBoxManager as any).store.dispatch(openModalAction(data))
        },
        resolveModal: (data: IMessageBoxResolvePayload) => {
            (MessageBoxManager as any).store.dispatch(resolveTransactionAction(data));
        }
    }
}

export default MessageBoxManager();