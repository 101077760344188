import IconCloseWindow from "@bimser/icons/16/close-window";
import { findMessage } from "../..";
import * as React from "react";
import BCCombobox, { IComboboxSelectedChangeEventArgs } from "../../BCCombobox";
import BCInputNumber from "../../BCInputNumber";
import BCLabel from "../../BCLabel";
import { IItem } from "@bimser/core";
import * as Styles from "../assets/deviceViewerStyles.scss";
import { EDeviceTypes, IDeviceViewerToolbarProps, ISize } from "../entities";

const deviceItems: IItem[] = [
    { key: '1', text: 'Responsive', value: EDeviceTypes.Responsive, externalData: { width: null, height: null } },
    { key: '2', text: 'Galaxy S5', value: EDeviceTypes.GalaxyS5, externalData: { width: 360, height: 640 } },
    { key: '3', text: 'Galaxy Note 3', value: EDeviceTypes.GalaxyNote3, externalData: { width: 360, height: 640 } },
    { key: '4', text: 'iPhone 5/SE', value: EDeviceTypes.IPhone5SE, externalData: { width: 320, height: 568 } },
    { key: '5', text: 'iPhone 6/7/8', value: EDeviceTypes.IPhone678, externalData: { width: 375, height: 667 } },
    { key: '6', text: 'iPhone 6/7/8 Plus', value: EDeviceTypes.IPhone678Plus, externalData: { width: 414, height: 736 } },
    { key: '7', text: 'iPhone X', value: EDeviceTypes.IPhoneX, externalData: { width: 375, height: 812 } },
    { key: '8', text: 'iPad', value: EDeviceTypes.IPad, externalData: { width: 768, height: 1024 } },
    { key: '9', text: 'iPad Mini', value: EDeviceTypes.IPadMini, externalData: { width: 768, height: 1024 } },
    { key: '10', text: 'iPad Pro', value: EDeviceTypes.IPadPro, externalData: { width: 1024, height: 1366 } }
];

export default (props: IDeviceViewerToolbarProps) => {
    const onDeviceTypeChanged = (args: IComboboxSelectedChangeEventArgs) => {
        const selectedDevice: IItem = deviceItems.find(item => item.value == args.data);

        props.onDimensionChanged?.(selectedDevice.externalData || { width: null, height: null }, selectedDevice.value);
    }

    const onZoomValueChanged = (value: number) => {
        props.onZoomValueChanged(value / 100);
    };

    const renderDeviceCombobox = () => (
        <>
            <BCLabel>{findMessage.get("102754")}</BCLabel>
            <BCCombobox
                className={Styles.comboboxItem}
                options={deviceItems}
                showSearch
                size={'small'}
                allowClear={false}
                value={props.selectedDevice}
                onSelectedChange={onDeviceTypeChanged}
            />
        </>
    );

    const renderZoomCombobox = () => (
        <>
            <BCLabel>{findMessage.get("100519")}</BCLabel>
            <BCInputNumber
                size={'small'}
                value={Math.round(props.selectedZoomValue * 100)}
                onChange={onZoomValueChanged}
                min={10}
                max={150}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                className={Styles.dimensionInput}
            />
        </>
    );

    const notResponsive = props.selectedDevice !== EDeviceTypes.Responsive;

    const renderDimensionInputs = () => (
        <>
            <BCLabel>{`${findMessage.get("100238")}/${findMessage.get("100240")}`}</BCLabel>
            <BCInputNumber
                size={'small'}
                value={props.dimension.width}
                onChange={(val) => { props.onDimensionChanged({ width: val as number }) }}
                min={250}
                max={2560}
                className={Styles.dimensionInput}
                disabled={notResponsive}
            />
            <BCInputNumber
                size={'small'}
                value={props.dimension.height}
                onChange={(val) => { props.onDimensionChanged({ height: val as number }) }}
                min={250}
                max={1440}
                className={Styles.dimensionInput}
                disabled={notResponsive}

            />
        </>
    );

    const renderCloseButton = () => (
        <div className={Styles.closeButton} onClick={props.onClose}>
            <IconCloseWindow />
        </div>
    );

    return (
        <div className={Styles.toolbar}>
            {renderDeviceCombobox()}
            {renderDimensionInputs()}
            {renderZoomCombobox()}
            {renderCloseButton()}
        </div>
    )
}