import { connect } from "react-redux";
import UserSelectModal from "../components";
import { BaseState } from "../../../../../entities/BaseState";
import IUserSelectModalProps from "../entities/IUserSelectModalProps";
import { resolveUserSelectModal, rejectUserSelectModal, onSearchChanged } from "../actions";
import { getUserSelectModalData } from "../selectors";

const mapStateToProps = function (state: BaseState, props: IUserSelectModalProps): IUserSelectModalProps {
    return {
        userData: getUserSelectModalData(state, props),
        initializedata: props.initializedata
    };
}
const mapDispatchToProps = function (dispatch: Function): IUserSelectModalProps {
    return {
        onResolve: function (data: any) {
            dispatch(resolveUserSelectModal(data));
        },
        onReject: function (modalId: string) {
            dispatch(rejectUserSelectModal(modalId))
        },
        onSearchChanged: function (data: { id: string, value: string }) {
            dispatch(onSearchChanged(data));
        }
    };
}

const UserSelectModalContainer = connect(mapStateToProps, mapDispatchToProps)(UserSelectModal);
export default UserSelectModalContainer;