import IconCollapseAll from "@bimser/icons/16/collapse-all";
import IconExpandAll from "@bimser/icons/16/expand-all";
import * as React from "react";
import { BCAddonButton, BCInputSearch, findMessage } from "../..";
import { IButtonBarItem } from '../../BCButtonBar/entities';
import * as Styles from "../assets/style.scss";
import { ISearchBoxProps } from '../entities';

const PropertySearchBox = React.memo((props: ISearchBoxProps) => {

    const items: IButtonBarItem[] = React.useMemo(() => {
        return [
            {
                buttonProps: {
                    guid: "property_inspector_buttonBarItem",
                    icon: props.expandAll ? <IconCollapseAll /> : <IconExpandAll />,
                    title: props.expandAll ? findMessage.get('100707') : findMessage.get('100708'),
                    type: "link",
                    onClick: props.onToggleExpand
                }
            }
        ]
    }, [props.expandAll])

    return (
        <div className={Styles.propertySearchBox} style={{ height: "40px" }}>
            <BCAddonButton farItems={items} className={Styles.propertySearchButton}>
                <BCInputSearch
                    placeHolder={findMessage.get('100002')}
                    onSearch={props.onSearch}
                    onChange={props.onChange}
                    size={'middle'}
                />
            </ BCAddonButton>
        </div>
    )
});

export default PropertySearchBox