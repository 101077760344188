import * as React from "react";
import BCToggleButtonGroup from '../../../../BCToggleButtonGroup';
import { createGuid } from "@bimser/core";
import { IToggleButtonProps } from '../../../entities';

export default class ToggleButtonGroupProperty extends React.Component<IToggleButtonProps, any> {
    constructor(props: IToggleButtonProps) {
        super(props);
    }

    render() {
        return (
            <BCToggleButtonGroup
                key={createGuid()}
                options={this.props.items}
                style={this.props.style}
                onChange={(changedValue) => { this.props.customChangeEvent(changedValue) }}
                singleSelect={this.props.singleSelect}
            />
        );
    }
}