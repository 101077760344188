import { IExtremeGridState, ISummaryItem } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeSummaryReducer(state: IExtremeGridState, action: IBaseMutableAction<ISummaryItem>): IExtremeGridState {
    let newItems: Array<ISummaryItem> = state.summary.totalItems.map((item) =>
        item.type === action.payload.type && item.columnName === action.payload.columnName
            ? Object.assign({}, item, { value: action.payload.value })
            : item);

    return {
        ...state,
        summary: {
            ...state.summary,
            totalItems: newItems
        }
    };
}