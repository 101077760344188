export const ADD_NEW_ITEM = "LEFT_MENU.ADD_NEW_ITEM";
export const TOGGLE_ACTIVE = "LEFT_MENU.TOGGLE_ACTIVE";
export const SET_ACTIVE_NODEKEY = "LEFT_MENU.SET_ACTIVE_NODEKEY";

export const ITEM_CLICKED = "LEFT_MENU.ITEM_CLICKED";
export const LEFT_MENU_REMOVE_ITEM = "LEFT_MENU.LEFT_MENU_REMOVE_ITEM";
export const LEFT_MENU_SAVE_ITEMS = "LEFT_MENU.SAVE_ITEMS";
export const LEFT_MENU_SET_ACTIVE_PANEL = "LEFT_MENU.LEFT_MENU_SET_ACTIVE_PANEL";
/* Designer */

export const CHANGE_VISIBILITY = "MENU_DESIGNER.CHANGE_VISIBILITY";
export const PIN_TO_LAUNCHER = "MENU_DESIGNER.PIN_TO_LAUNCHER";
export const APPLY_TO_SUB_NODES_PROFILE = "MENU_DESIGNER.APPLY_TO_SUB_NODES_PROFILE";
export const SET_FLOWS = "MENU_DESIGNER.SET_FLOWS";
export const SET_FORMS = "MENU_DESIGNER.SET_FORMS";
export const SET_DEPLOYMENTS = "MENU_DESIGNER.SET_DEPLOYMENTS";
export const CHANGE_SELECTED_PROJECT = "MENU_DESIGNER.CHANGE_SELECTED_PROJECT";
export const CHANGE_SELECTED_FLOW = "MENU_DESIGNER.CHANGE_SELECTED_FLOW";
export const CHANGE_SELECTED_FORM = "MENU_DESIGNER.CHANGE_SELECTED_FORM";
export const SET_PROJECTS = "MENU_DESIGNER.SET_PROJECTS";
export const CHANGE_SELECTED_PROFILE = "MENU_DESIGNER.CHANGE_SELECTED_PROFILE";
export const ONCHANGE_ITEM_DATA = "MENU_DESIGNER.ONCHANGE_ITEM_DATA";
export const ONCHANGE_ITEM_DATA_TRANSACTION_ACTION = "MENU_DESIGNER.ONCHANGE_ITEM_DATA_TRANSACTION_ACTION";
export const EDIT_MENU_PROFILE = "MENU_DESIGNER.EDIT_MENU_PROFILE";
export const ADD_NEW_MENU_PROFILE_ITEM = "MENU_DESIGNER.ADD_NEW_MENU_PROFILE_ITEM";
export const DELETE_MENU_PROFILE_ITEM = "MENU_DESIGNER.DELETE_MENU_PROFILE_ITEM";
export const SAVE_ALL = "MENU_DESIGNER.SAVE_ALL";
export const SET_MENU_STRUCTURE_DATA = "MENU_DESIGNER.SET_MENU_STRUCTURE_DATA";
export const SET_EBA6_INTEGRATION_DATA = "MENU_DESIGNER.SET_EBA6_INTEGRATION_DATA";
export const SET_EBA6_INTEGRATION_LOADING = "MENU_DESIGNER.SET_EBA6_INTEGRATION_LOADING";
export const RESOLVE_EBA6_INTEGRATION_TRANSACTION = "MENU_DESIGNER.RESOLVE_EBA6_INTEGRATION_TRANSACTION";
export const REJECT_EBA6_INTEGRATION_TRANSACTION = "MENU_DESIGNER.REJECT_EBA6_INTEGRATION_TRANSACTION";
