export enum FileSizeType {
    B = "B",
    KB = "KB",
    MB = "MB",
    GB = "GB",
    TB = "TB"
}

export default function fileSizeConverter(fileSize: number, from: FileSizeType, to: FileSizeType): number {
    //İhtiyaç halinde ekleme çıkartmalar yapılabilir. Iconlar geldikçe eklenebilir.
    let byteValue: number = 0;

    switch (from) {
        case FileSizeType.B:
            byteValue = fileSize;
            break;
        case FileSizeType.KB:
            byteValue = fileSize * 1024;
            break;
        case FileSizeType.MB:
            byteValue = fileSize * 1024 * 1024;
            break;
        case FileSizeType.GB:
            byteValue = fileSize * 1024 * 1024 * 1024;
            break;
        case FileSizeType.TB:
            byteValue = fileSize * 1024 * 1024 * 1024 * 1024;
            break;
    }

    switch (to) {
        case FileSizeType.B:
            return byteValue;
        case FileSizeType.KB:
            return byteValue / 1024;
        case FileSizeType.MB:
            return byteValue / 1024 / 1024;
        case FileSizeType.GB:
            return byteValue / 1024 / 1024 / 1024;
        case FileSizeType.TB:
            return byteValue / 1024 / 1024 / 1024 / 1024;
    }
}

export function formatBytes(bytes: number) {
    var l = 0;
    while (bytes >= 1024 && ++l) {
        bytes = bytes / 1024;
    }
    return { value: +bytes.toFixed(2), sizeType: Object.values(FileSizeType)[l] };
}