import { IItem } from "@bimser/core";
import IconDelete from "@bimser/icons/16/delete";
import IconLock from "@bimser/icons/16/lock";
import IconPropertyInspector from "@bimser/icons/16/property-inspector";
import IconUnlock from "@bimser/icons/16/unlock";
import { getFirstXParent } from ".";
import { LayoutStructureItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const getContextMenuItems = (data: LayoutStructureItem, view: NormalizedLayoutStructureItems, path: string): IItem[] => {

    const pathArr = path ? path.split("-").reverse().slice(0, 3) : [];
    const idChain = getFirstXParent(view, 3, data.id);
    const isLocked = data.designerProps.get("locked");

    const isRootItem = pathArr.length === 0;

    let contextMenuItems: IItem[] = [];

    let conteMenuNonRootItems: IItem[] = [
        {
            key: "delete",
            text: "Delete",
            icon: IconDelete.info,
            externalData: { id: data.id, path },
            disabled: isLocked
        },
        // {
        //     key: "copy",
        //     text: "Copy",
        //     icon: IconCopy.info,
        //     externalData: { id: data.id, path }
        // },
        {
            key: "divider3",
            isDivider: true
        }
    ];

    let lockItems: IItem[] = [isLocked ? {
        key: "unlock",
        text: "Unlock",
        icon: IconUnlock.info,
        externalData: { id: data.id, path }
    } : {
        key: "lock",
        text: "Lock",
        icon: IconLock.info,
        externalData: { id: data.id, path }
    }];

    let commonItems: IItem[] = [
        ...lockItems,
        {
            key: "divider2",
            isDivider: true
        },
        ...(isRootItem ? [] : conteMenuNonRootItems),
        {
            key: "properties",
            text: "Properties",
            icon: IconPropertyInspector.info,
            externalData: { id: data.id, path }
        }
    ];

    if (idChain?.length > 1) {
        idChain.forEach(chainId => {
            if (data.id === chainId) return;
            contextMenuItems.push({
                key: `select__form__item__${chainId}`,
                text: `Select '${chainId}'`,
                externalData: { id: chainId, path }
            })
        });

        contextMenuItems.push({
            key: "divider1",
            isDivider: true
        });
    }

    return [
        ...contextMenuItems,
        ...commonItems
    ]

}