import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBasePagination {
    skip: number,
    take: number
}

export interface IPagination extends IBasePagination { }

export interface IReadonlyPagination extends IBasePagination { }

export type Pagination = ReadonlyRecord<IReadonlyPagination>;

export const PaginationFactory = Record<IReadonlyPagination>({
    skip: 0,
    take: 50
});

export const PaginationFactoryFromJS = function (pagination: IPagination) {
    return PaginationFactory({
        skip: pagination.skip,
        take: pagination.take
    });
}