enum IPropertyTypes {
    Input = 1,
    InputNumber,
    Radio,
    Select,
    Switch,
    Slider,
    ColorPicker,
    Button,
    DatePicker,
    TimePicker,
    TextArea,
    Margin,
    Border,
    Font,
    Aspect,
    Lookup,
    Collection,
    EventTrigger,
    Checkbox,
    CheckboxGroup,
    AutoComplete,
    MultipleSelect,
    Password,
    Upload,
    MLInput,
    TreeSelectProperty,
    DateRangePicker,
    InputNumberAddOn,
    TimeZone,
    TimeRangePickerProperty,
    DayOfWeek,
    AllDays,
    ImageUpload

}

export default IPropertyTypes;