import { createSelector,OutputSelector } from "reselect";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import {Record} from 'immutable'

const selectedCulture = (state: BaseState) => state.user.selectedCulture;

const getSelectedCultureName = createSelector(
    [
        selectedCulture
    ],
    (data: string) => {
        return data;
    }
);

export { getSelectedCultureName };

