import { GetIconComponent } from "@bimser/icons";
import Button from 'antd/lib/button';
import cloneDeep from "lodash/cloneDeep";
import * as React from "react";
import BCDivider from '../BCDivider';
import { ITButton, ITButtonGroupProps } from './entities';

const ButtonGroup = Button.Group;

const BCToggleButtonGroup = React.memo<ITButtonGroupProps>(props => {

    const [items, setItems] = React.useState<Array<ITButton>>(props.options);

    React.useEffect(() => {
        setItems(props.options)
    }, [props.options]);

    const onClick = (itemKey: string) => {
        let newItemState = cloneDeep(items);

        for (let b of newItemState) {
            if (b.key === itemKey) {
                b.value = !b.value;
                if (props.onChange) props.onChange(b);
                if (!props.singleSelect) break;
            } else if (props.singleSelect) {
                b.value = false;
            }
        }

        setItems(newItemState);
    }

    return (
        <React.Fragment>
            {props.holder ? <span>#</span> : null}
            <ButtonGroup style={props.style}>
                {items.map((item: ITButton) => {
                    return (
                        <Button
                            key={item.key}
                            size={props.size}
                            onClick={() => onClick(item.key)}
                            type={item.value ? 'primary' : null}
                            style={item.style}
                        >
                            {item.icon ? GetIconComponent(item.icon, { width: "16px", height: "100%" }) : ''}
                            {item.label}
                        </Button>
                    )
                })}
            </ButtonGroup>
            {props.divider ? <BCDivider type="vertical" /> : null}
        </React.Fragment>
    )
});

export { ITButtonGroupProps };
export default BCToggleButtonGroup;