import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ActionMenuItem, ActionMenuItemFactoryFromJS, IActionMenuItem } from "./IActionMenuItem";


interface IBaseActionMenuState {
    panelId: string,
    isLoading?: boolean
    activeItemKey?: string,
    activePanelId?: string,
}

export interface IActionMenuState extends IBaseActionMenuState {
    items: IActionMenuItem[]
}

export interface IReadonlyActionMenuState extends IBaseActionMenuState {
    items: List<ActionMenuItem>
}

export type ActionMenuState = ReadonlyRecord<IReadonlyActionMenuState>;

export const ActionMenuStateFactory = Record<IReadonlyActionMenuState>({
    panelId: null,
    isLoading: false,
    activeItemKey: null,
    activePanelId: null,
    items: List<ActionMenuItem>(),
});

export const ActionMenuStateFactoryFromJS = function (data: IActionMenuState) {
    return ActionMenuStateFactory({
        ...data,
        items: List(data.items?.map(item => ActionMenuItemFactoryFromJS(item)) || [])
    });
}