import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";
import { FormSavePayload, FormSavePayloadFactory, FormSavePayloadFactoryFromJS, IFormSavePayload } from './IFormSavePayload';

export interface IBaseFormSaveAction {
    windowId: string
}

export interface IFormSaveAction extends IBaseFormSaveAction {
    payload: IFormSavePayload
}

export interface IReadonlyFormSaveAction extends IBaseFormSaveAction {
    payload: FormSavePayload
}

export type FormSaveAction = ReadonlyRecord<IReadonlyFormSaveAction>;

export const FormSaveActionFactory = Record<IReadonlyFormSaveAction>({
    windowId: null,
    payload: FormSavePayloadFactory()
});

export const FormSaveActionFactoryFromJS = function (data: IFormSaveAction) {
    return FormSaveActionFactory({
        windowId: data.windowId,
        payload: FormSavePayloadFactoryFromJS(data.payload)
    });
}