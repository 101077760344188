import { BimserCommonRootSaga } from "@bimser/common";
import { INITIALIZE_APPLICATION_STARTUP } from '@bimser/common/dist/components/Login/actions/actionTypes';
import { initializedApplicationStartup } from '@bimser/common/dist/components/Login/actions';
import { all, put, take } from 'redux-saga/effects';
import { injectReducer, injectSaga } from ".";
import RootReducer from "./reducer";

export default function* rootSaga() {
    yield all([
        initializer(),
        BimserCommonRootSaga()
    ]);
}

function* initializer() {
    yield take(INITIALIZE_APPLICATION_STARTUP);

    yield import(/* webpackChunkName: "web-startup" */ "../app-main/reducers/layoutsReducer").then(({ default: LayoutsReducer }) => {
        injectReducer(RootReducer, "layouts", LayoutsReducer);
    })
    yield import(/* webpackChunkName: "web-startup" */ "../common/reducers/modulesReducer").then(({ default: ModulesReducer }) => {
        injectReducer(RootReducer, "modules", ModulesReducer);
    })
    yield import(/* webpackChunkName: "web-startup" */ "../modules/user/reducers/UserReducer").then(({ default: UserReducer }) => {
        injectReducer(RootReducer, "user", UserReducer);
    })

    yield import(/* webpackChunkName: "web-startup" */ "app-main/sagas").then(({ default: AppMainSaga }) => {
        injectSaga("AppMain", AppMainSaga);
    })

    yield put(initializedApplicationStartup());
}