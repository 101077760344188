import * as React from "react";
import BCButton from '../../../BCButton';
import { IComboboxLoadingArgs, IComboboxSelectedChangeEventArgs } from '../../../BCCombobox';
import BCInputGroup from '../../../BCInputGroup';
import BCMultipleCombobox from "../../../BCMultipleCombobox";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import IconRefresh from "@bimser/icons/16/refresh";
import * as Styles from "../../assets/style.scss";

const buttonStyle: React.CSSProperties = { width: '35px', border: "1px solid #d9d9d9" };

export default function MultipleSelectProperty(props: IPropertyComponentProps) {
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        setOptionsPromise();
    }, []);

    React.useEffect(() => {
        setOptionsPromise();
    });

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const getOptionsPromise = (): Promise<any> => {
        if (props.item.options?.isPromise && props.getOptionsPromise) {
            return props.getOptionsPromise(props.item)
        }
    }

    const setOptionsPromise = () => {
        if (props.item.options?.isPromise) {
            let promise = getOptionsPromise();
            if (promise) {
                promise.then((result: IComboboxLoadingArgs) => {
                    setOptions(result.options)
                });
            }
        }
    }

    const onRefresh = () => {
        if (props.onRefresh) {
            let awaitedPromise = props.onRefresh(props.item);
            if (awaitedPromise) {
                awaitedPromise.then((result: IComboboxLoadingArgs) => {
                    setOptions(result ? result.options : []);
                });
            }
        }
    }

    const onSelectedChange = (e: IComboboxSelectedChangeEventArgs) => {
        props.customChangeEvent ? props.customChangeEvent(e.data) : replaceValue(props.item, e.data)
    }

    const getCombobox = (isPromise?: boolean) => {
        return (
            <BCMultipleCombobox
                onFocus={setOptionsPromise}
                className={isPromise ? Styles.controlWithPromise : Styles.control}
                onSelectedChange={onSelectedChange}
                options={isPromise ? options : props.item.options}
                showSearch={true}
                style={props.style}
                disabled={props.item.disabled || props.item.readOnly}
                allowClear={true}
                value={props.value}
                mode={"tags"}
            />
        )
    }

    const renderSelect = () => {
        if (!Array.isArray(props.item.options) && props.item.options && props.item.options.isPromise) {
            return (
                <BCInputGroup size={'small'} compact={true}>
                    {getCombobox(true)}
                    <BCButton
                        style={buttonStyle}
                        icon={<IconRefresh />}
                        onClick={onRefresh}
                    />
                </BCInputGroup>
            )
        } else {
            return getCombobox();
        }
    }

    return renderSelect();

}