import Slider from 'antd/lib/slider';
import * as React from "react";
import { isNullOrUndefined } from "@bimser/core";
import * as Styles from './assets/styles.scss';
import { ISliderMarks, ISliderProps } from './entities';

export default class BCSlider extends React.Component<ISliderProps, {}> {

    constructor(props: ISliderProps) {
        super(props);
        Styles;
        this.tipFormatter = this.tipFormatter.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    tipFormatter(value: number) {
        if (this.props.tipFormatter) {
            return this.props.tipFormatter(value)
        } else {
            return value || '0'
        }
    }

    onChange(value: number | [number, number]) {
        if (!this.props.readOnly && this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <Slider
                prefixCls={this.props.prefixCls}
                tooltipPrefixCls={this.props.tooltipPrefixCls}
                range={this.props.range as any}
                min={this.props.min}
                max={this.props.max}
                step={!isNullOrUndefined(this.props.step) && this.props.step != 0 ? this.props.step : 1}
                marks={this.props.marks}
                dots={this.props.dots}
                value={this.props.value as any}
                included={this.props.included}
                disabled={this.props.disabled}
                vertical={this.props.vertical}
                defaultValue={this.props.defaultValue as any}
                onChange={this.onChange}
                onAfterChange={this.props.onAfterChange}
                tipFormatter={this.tipFormatter}
                className={this.props.className}
                id={this.props.id}
                style={this.props.style}
            />
        )
    }
}

export { ISliderProps, ISliderMarks };
