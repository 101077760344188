import { BCAjax, BCNotification, findMessage } from "@bimser/components";
import { createGuid, IBaseAction } from "@bimser/core";
import { createElement } from 'react';
import { all, put, take, takeLatest } from "redux-saga/effects";
import { closeModalTransactionAction, MessageBox } from '../../..';
import generalInitializerTransactionAction from '../../../action/generalInitializerTransactionAction';
import { ServiceList } from '../../../services/serviceList';
import * as ActionTypes from "../actions/actionTypes";
import { LOGIN_WITH_EXTERNAL_ACCOUNT_TRANSACTION, ON_OAUTH_LOGIN_TRANSACTION } from "../actions/actionTypes";
import { LoadingModalContent } from '../components/LoadingModalContent';
import { LoginMethod } from "../entities/ILoginMethod";
import { OAuthLoginRequest } from '../entities/IOAuthLoginRequest';
import { loginAction, initializeApplicationStartup, getUserStartupTransactionAction, changeAdminToolsVisibilityAction, changeUserMenuVisibilityAction } from '../actions';

function* watchLoginWithExternalAccountTransaction() {
    yield all([
        takeLatest(LOGIN_WITH_EXTERNAL_ACCOUNT_TRANSACTION, handleLoginWithExternalAccount),
        takeLatest(ON_OAUTH_LOGIN_TRANSACTION, handleOnOAuthLogin)
    ]);
}

export default watchLoginWithExternalAccountTransaction;

function* handleLoginWithExternalAccount(action: IBaseAction<LoginMethod>) {
    localStorage.setItem("loginMethod", "externalAccounts");
    localStorage.setItem("externalAccountType", action.payload.name);
    if (location.hash.includes("/redirect")) {
        sessionStorage.setItem("ssoRedirectHash", location.hash);
    }
    let redirectUrl = action.payload.url;
    if (location.hash.includes("/OAuth")) {
        redirectUrl += "&useAuthCode=true";
    }
    location.href = redirectUrl;
}

function* handleOnOAuthLogin(action: IBaseAction<OAuthLoginRequest>) {
    let { id, language, useAuthCode } = action.payload;
    let loadingModalId = createGuid();

    try {
        MessageBox.show({
            id: loadingModalId,
            content: createElement(LoadingModalContent, { description: findMessage.get('102237') }),
            props: {
                closable: false,
                maskClosable: false,
                keyboard: false,
                footer: null,
                title: null,
                renderHeader: () => null
            },
            width: "400px",
            buttons: null,
            notInCloseIcon: true
        });
        yield take(ActionTypes.LOGIN_PARAMETERS);

        let response = yield ServiceList.login.Login.LoginWithExternalUser.call({
            id,
            language,
            useAuthCode
        }, null, null, true);

        if (response) {
            if (useAuthCode) {
                location.hash = "#/OAuthResult/" + response.authCode;
            } else {

                const queryString = "?" + window.location.href.split("?")[1];
                const urlParams = new URLSearchParams(queryString);

                const hideProfileMenu = urlParams.has("hideProfileMenu");
                const hideAdminTools = urlParams.has("hideAdminTools");
                
                location.hash = "";

                yield put(loginAction({ token: response.token }));

                let headers = {
                    ...BCAjax.defaultHeaders,
                    'bimser-language': language,
                    "Authorization": "Bearer " + response.token,
                }

                BCAjax.setHeaders(headers);
                sessionStorage.setItem('token', response.token);
                if (localStorage.getItem("rememberMe") == "1") {
                    localStorage.setItem('token', response.token);
                }

                // redirect üzerinden yönlendirilmişse redirectHash alınıp location.hash'e yazılıyor
                let redirectHash = sessionStorage.getItem("ssoRedirectHash");
                if (redirectHash) {
                    sessionStorage.removeItem("ssoRedirectHash");
                    location.hash = redirectHash;
                }

                yield put(initializeApplicationStartup());
                yield take(ActionTypes.INITIALIZED_APPLICATION_STARTUP);

                yield put(getUserStartupTransactionAction());
                yield put(generalInitializerTransactionAction());

                if (hideAdminTools) yield put(changeAdminToolsVisibilityAction(false));
                if (hideProfileMenu) yield put(changeUserMenuVisibilityAction(false));
            }
        } else {
            if (useAuthCode) {
                location.hash = "#/OAuth/Failed";
            } else {
                location.hash = "";
            }
            BCNotification.error({
                message: findMessage.get('102238')
            });
        }
    } catch (error) {
        console.log("function*handleOnOAuthLogin -> error", error)
        BCNotification.error({
            message: findMessage.get('102238')
        });
    } finally {
        yield put(closeModalTransactionAction(loadingModalId));
    }
}