import { panelType } from '@bimser/common';
import { BCCard, BCScrollbars, BCSkeleton, ContentType } from '@bimser/components';
import React from 'react';
import Style from '../../assets/style.scss';
import ICountTileProps from '../../entities/widgets/ICountTileProps';
import { tiles, tileTypeAndKeys } from "../../utils";
import { TileTypes } from '../../entities/widgets/TileTypes';

const cx = require('classnames/bind').bind(Style);

export default (props: ICountTileProps) => {

    const setSelectedTile = (tile: TileTypes) => {
        props.onChangeSelected({
            panelId: props.panelId,
            panelType: panelType.startupLayout,
            data: tile
        })
    }
    
    return (
        <div className={Style.tileCardListContainer}>
            <BCScrollbars mountScrollPosition={'bottom'} autoHide>
                <div className={Style.tileCardList}>
                    <BCSkeleton
                        loadingStatus={props.loading}
                        contentType={ContentType.DashboardTilesList}
                    >
                        {Object.values(tiles[props.dashboardType]()).map((tileObject) => {
                            const id = Object.keys(tileTypeAndKeys).find(key => tileTypeAndKeys[key] == tileObject.key);
                            const tileBadgeStyle : React.CSSProperties = { backgroundColor: tileObject?.badgeColor };

                            return tileObject ? (
                                <span onClick={() => setSelectedTile(tileObject.key)} key={id}>
                                    <BCCard
                                        key={id}
                                        className={cx({ tileCard: true, [id]: true, selected: props.selectedTile == tileObject.key })}
                                        bordered={false}
                                        style={tileBadgeStyle}
                                    >
                                        <span className={Style.tileHeader}>{tileObject.caption}</span>
                                        <span className={Style.tileCount}>{props.counts[id]}</span>
                                    </BCCard>
                                </span>
                            ) : ""
                        })}
                    </BCSkeleton>
                </div>
            </BCScrollbars>
        </div>
    )
}