import { MLHelper } from '@bimser/core';
import { Map } from 'immutable';
import { DmDataFactoryFromJS } from '../../entities/IDmData';
import { getDmIcon } from './getDmIcon';

export function* GenerateDmData(data: any) {
    return GenerateDmModel(data);
}

export function GenerateDmModel(data: any) {
    let name = MLHelper.getMLText(data.name)
    let description = MLHelper.getMLText(data.description);
    let meta = Map<string, any>()
        .set("NAME", name)
        .set("CREATEDAT", data.createdAt)
        .set("ID", data.id.toString())
        .set("SECRETKEY", data.secretKey)
        .set("OWNER", data.createdBy?.name)
        .set("DESCRIPTION", description || "")
        .set("VERSION", 1)
        .set("MODIFIEDAT", data.modifiedAt || "")
        .set("ICON", getDmIcon(data.type, null, name))
        .set("VERSIONDATE", "")
        .set("TYPE", data.type)
        .set("TAGS", data.tags)
        .set("CATEGORY", data.category);

    if (data.metaDataFieldsValues?.length) {
        data.metaDataFieldsValues.forEach((field: any) => {
            meta = meta.set(`${field.formId}_${field.fieldName}`, field.fieldValue.text);
        })
    }

    return DmDataFactoryFromJS({
        id: data.id.toString(),
        metadata: meta,
        name: name,
        secretKey: data.secretKey,
        type: data.type,
        path: description,
        createdBy: data.createdBy,
        modifiedBy: data.modifiedBy,
        deletedBy: data.deletedBy,
        originType: data.originType ? data.originType : data.type,
        version: data.version,
        versionId: data.versionId,
        versionSecretKey: data.versionSecretKey,
        existThumbnail: data.existThumbnail,
        recentCreatedAt: data.recentCreatedAt,
        tags: data.tags,
        category: data.category
    })
}