import { List } from 'immutable';
import * as moment from 'moment';
import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { ProcessItem } from '../../workflow/entities/IProcessItem';
import IApprovesBoxProps from '../entities/IApprovesBoxProps';

const getApprovesBoxSelector = (state: WebInterfaceBaseState, props: IApprovesBoxProps) => {
    return state.modules.workflow.content.approvals;
}

const getApprovesBoxItems = createSelector(
    [
        getApprovesBoxSelector
    ],
    (Approves: List<ProcessItem>) => {
        Approves = Approves.sort((a, b) => {
            let result = moment(a.requestDate).isBefore(b.requestDate) ? 1 : -1;
            return result;
        });
        return Approves.toJS();
    }
);

export { getApprovesBoxItems };

