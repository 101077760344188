import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseRelatedDataChangeAction {
    windowId: string
    documentId: number,
    relatedControlId: string,
    parentFormId: string,
    executeActionType: string
}

export interface IRelatedDataChangeAction extends IBaseRelatedDataChangeAction {
}

export interface IReadonlyRelatedDataChangeAction extends IBaseRelatedDataChangeAction {
}

export type RelatedDataChangeAction = ReadonlyRecord<IReadonlyRelatedDataChangeAction>;

export const RelatedDataChangeActionFactory = Record<IReadonlyRelatedDataChangeAction>({
    windowId: null,
    documentId: 0,
    relatedControlId: null,
    parentFormId: null,
    executeActionType: null
});

export const RelatedDataChangeActionFactoryFromJS = function (data: IRelatedDataChangeAction) {
    return RelatedDataChangeActionFactory(data);
}