const IconSets = {
    8: {
        id: 8,
        sourceFolder: "8",
        usageSizes: [
            {
                size: 8,
                destinationFolder: "8"
            }
        ]
    },
    16: {
        id: 16,
        sourceFolder: "16",
        usageSizes: [
            {
                size: 16,
                destinationFolder: "16"
            }
        ]
    },
    24: {
        id: 24,
        sourceFolder: "24",
        usageSizes: [
            {
                size: 24,
                destinationFolder: "24"
            },
            {
                size: 48,
                destinationFolder: "48"
            }
        ]
    },
    32: {
        id: 32,
        sourceFolder: "32",
        usageSizes: [
            {
                size: 32,
                destinationFolder: "32"
            },
            {
                size: 64,
                destinationFolder: "64"
            }
        ]
    }
}

module.exports = IconSets;
