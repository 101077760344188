import { BCAjax } from '@bimser/components';
import { createSyncAction, IBaseAction, RxEventEmitter } from '@bimser/core';
import { HubConnection } from '@microsoft/signalr';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../action/actionTypes';
const _get = require("lodash/get");
type GetCreateType = () => HubConnection;

const SendMessageMethod = "ReceiveMessages";//RealtimeInteractionWebAPI\WebInterfaceWebAPIParameters => SendMessageMethod { get { return "ReceiveMessages"; } }
enum HubConnectionState {
    Disconnected = "Disconnected",
    Connecting = "Connecting",
    Connected = "Connected",
    Disconnecting = "Disconnecting",
    Reconnecting = "Reconnecting"
}
let url: string = _get((window as any), "serviceConfig.urls.socket", window.origin + "/realtimeinteractionhub");

export default function* watchGetAllHubConnection() {
    if (url) {
        yield takeEvery(ActionTypes.GENERAL_INITIALIZER_TRANSACTION_ACTION, handleGetHubConnection);
    }
}

function* handleGetHubConnection() {
    try {
        let token = BCAjax.defaultHeaders.Authorization.indexOf("Bearer") != -1 ? BCAjax.defaultHeaders.Authorization.substring(7) : BCAjax.defaultHeaders.Authorization;
        let encryptedData = BCAjax.defaultHeaders['bimser-encrypted-data'];
        let language = BCAjax.defaultHeaders['bimser-language'];

        const connection = yield hubConnection();
        const socket = connection.get();

        window.addEventListener('beforeunload', function (event) {
            LogoutMessage(socket, token, encryptedData, language)
        }, false);

        while (true) {
            if (socket.state == HubConnectionState.Disconnected) {
                yield socket.start()

                socket.onreconnecting((connectionId: string) => {
                    OnReconnecting(connectionId, token, encryptedData, language)
                });

                socket.onreconnected((connectionId: string) => {
                    LoginMessage(socket, token, encryptedData, language)
                });

                if (socket.state == "Connected") {
                    LoginMessage(socket, token, encryptedData, language)
                };
            }
            const response: IBaseAction<any> = yield call(connect, socket);
            RxEventEmitter.emit("SYSTEM_SOCKET", response);
            yield put(createSyncAction(response.type, response.payload));
        }
    } catch (e) {
        writeLog(e);
    }
}

function connect(socket: any) {
    return new Promise(resolve => {
        socket.on(SendMessageMethod, (action: string) => {
            let _action: IBaseAction<any> = JSON.parse(action);
            resolve(_action)
        });
    })
}

const hubConnection = () => {
    return import(/* webpackChunkName: "signalr" */ "@microsoft/signalr").then(({ HubConnectionBuilder, LogLevel }) => {
        let state: HubConnection, pub: { get: GetCreateType, create: GetCreateType } = { get: null, create: null };
        pub.get = () => {
            if (!state) {
                state = pub.create();
            }
            return state;
        }

        pub.create = () => new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        return pub;
    });
}

function OnReconnecting(arg: any, token: string, encryptedData: string, language: string) {
    console.log("Socket reconnecting...");
}

function LoginMessage(socket: HubConnection, token: string, encryptedData: string, language: string) {
    socket.invoke("LoginMessageFromClient", token, encryptedData, language);
}

function LogoutMessage(socket: HubConnection, token: string, encryptedData: string, language: string) {
    socket.invoke("LogoutMessageFromClient", token, encryptedData, language);
}

function writeLog(e: any) {
    console.log(`HubConnection Error : ${e}`);
}
