import Transfer from 'antd/lib/transfer';
import * as React from "react";
import { ITransferProps, TransferItem } from './entities';
import * as Styles from './assets/transferStyles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(Styles)
export default class BCTransfer extends React.Component<ITransferProps, {}> {
    constructor(props: ITransferProps) {
        super(props);
    }

    filterOption(val: string, opt: TransferItem) {
        return opt.title.toLocaleUpperCase().indexOf(val.toLocaleUpperCase()) > -1
    }

    render() {
        const transferClassnames = cx({
            readOnlyCursor: this.props.readOnly,
            [this.props.className ?? ""]: true
        })

        return (
            <Transfer
                dataSource={this.props.dataSource}
                onChange={this.props.onChange}
                onSelectChange={this.props.onSelectChange}
                selectedKeys={this.props.selectedKeys}
                targetKeys={this.props.targetKeys}
                operations={this.props.operations}
                titles={this.props.titles}
                render={this.props.render}
                showSearch={this.props.showSearch}
                disabled={this.props.disabled || this.props.readOnly}
                showSelectAll={this.props.showSelectAll}
                style={this.props.style}
                className={transferClassnames}
                filterOption={this.filterOption}
            />
        );
    }
}

export { ITransferProps, TransferItem };
