import GetIconByName from '@bimser/icons/lib/getIcon16ByName';
import * as React from "react";
import BCButton from '../../../BCButton';
import { IPropertyComponentProps } from '../../entities';

export default function ButtonProperty(props: IPropertyComponentProps) {

    const getIcon = () => {
        const Icon = GetIconByName(props.item.options?.icon)
        return <Icon />
    }

    return (
        <BCButton
            onClick={props.item.options?.onClick}
            text={props.item.options?.text}
            icon={getIcon()}
        >
            {props.item.label}
        </BCButton>
    );
}