import React from 'react';
import { BCDrawer, BCModal } from '@bimser/components';
import * as Style from '../assets/style.scss';
import IPanelComponentProps from '../entities/IPanelComponentProps';
import usePanelComponent from './usePanelComponent';


const PanelComponent = (props: IPanelComponentProps) => {

    const { Content, title, onCancelClick, renderTitle } = usePanelComponent(props);

    return React.useMemo(() => {
        if (props.type == "drawer") {
            return (
                <BCDrawer
                    className={Style.ComponentContainer + ` ${props.className || ""}`}
                    visible={true}
                    width={props.width}
                    maskClosable={true}
                    onClose={onCancelClick}
                    title={title}
                    renderTitle={renderTitle}
                >
                    <div className={Style.ComponentContent}>
                        {Content}
                    </div>
                </BCDrawer>
            )
        } else {
            return (
                <BCModal
                    id={props.modalId}
                    width={props.width}
                    visible={true}
                    handleCancel={onCancelClick}
                    maskClosable={false}
                    closable={false}
                    title={title}
                    className={Style.ComponentContainer + ` ${props.className || ""}`}
                    keyboard={false}
                    renderHeader={renderTitle}
                    footer={null}
                    disableScroll
                >
                    <div className={[Style.ComponentContent, Style.isModal].join(" ")}>
                        {Content}
                    </div>
                </BCModal>
            )
        }
    }, [Content, title, onCancelClick, renderTitle]);
}

export default PanelComponent;