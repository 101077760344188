
import { connect } from 'react-redux';
import { ProjectDeploymentProgressCProps, ProjectDeploymentProgressProps } from '../entities/ProjectDeploymentProgressProps';
import ProjectDeploymentProgress from '../components';
import { BaseState, closeModalTransactionAction } from '../../..';
import deployAgentMessageAction from '../../../action/deployAgentMessageAction';
import { IDeployAgentMessage } from '../../../entities/DeployAgentEntities';


const mapStateToProps = function (state: BaseState, props: ProjectDeploymentProgressProps) {
    return {

    }
}

const mapDispatchToProps = function (dispatch: Function): ProjectDeploymentProgressCProps {
    return {
        onClose: (modalId: string) => {
            dispatch(closeModalTransactionAction(modalId));
        },
        onDeployAgentMessage: (message: IDeployAgentMessage) => {
            dispatch(deployAgentMessageAction(message));
        }
    }
}

const ProjectDeploymentProgressContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectDeploymentProgress);

export default ProjectDeploymentProgressContainer;
