import { put, takeEvery, delay } from 'redux-saga/effects';
import { changeModalVisibilityAction, closeModalAction } from '../actions';
import * as ActionTypes from '../actions/actionTypes';

export default function* watchCloseModalTransaction() {
    yield takeEvery(ActionTypes.CLOSE_MODAL_TRANSACTION, closeModal);
}

export function* closeModal(action: any) {
    try {
        yield put(changeModalVisibilityAction(action.payload));
        yield delay(250);
        yield put(closeModalAction(action.payload));
    } catch (e) {
        throw e;
    }
}