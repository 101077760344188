import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Map, Record } from "immutable";

interface IBaseProcess {
    projectId: string,
    projectName: string,
    count: number,
    flowName: string,
    id: string
}

export interface IProcess extends IBaseProcess {
    projectCaption: IDictionary<string>,
    flowCaption: IDictionary<string>
}

export interface IReadonlyProcess extends IBaseProcess {
    projectCaption: Map<string, string>,
    flowCaption: Map<string, string>
}

export type Process = ReadonlyRecord<IReadonlyProcess>;

export const ProcessFactory = Record<IReadonlyProcess>({
    id: null,
    projectId: null,
    projectName: null,
    flowName: null,
    count: 0,
    projectCaption: Map<string, string>(),
    flowCaption: Map<string, string>()
});

export const ProcessFactoryFromJS = function (data: IProcess) {
    return ProcessFactory({
        ...data,
        projectCaption: Map(data.projectCaption),
        flowCaption: Map(data.flowCaption)
    })
}