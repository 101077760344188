import React, { lazy, Suspense } from 'react';
import IAnnouncementFormProps from "./entities/props/IAnnouncementFormProps";

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-others" */ "./containers/AnnouncementFormContainer"));

function Container(props: React.PropsWithChildren<IAnnouncementFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
Container.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-others" */ "./injector");
}

export default Container;