import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseMessageBoxButton {
    id: string,
    text?: string,
    icon?: React.ReactElement,
    showType?: 'onlyIcon' | 'onlyText' | 'iconAndText' | 'custom',
    type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'text',
    danger?: boolean,
    disableAutoClose?: boolean,
    callback?: (data?: any) => any
}

export interface IMessageBoxButton extends IBaseMessageBoxButton {

}

export interface IReadonlyMessageBoxButton extends IBaseMessageBoxButton {
}

export type MessageBoxButton = ReadonlyRecord<IReadonlyMessageBoxButton>;

export const MessageBoxButtonFactory = Record<IReadonlyMessageBoxButton>({
    id: null,
    text: null,
    icon: null,
    showType: null,
    type: null,
    danger: false,
    disableAutoClose: false,
    callback: null
});

export const MessageBoxButtonFactoryFromJS = function (data: IMessageBoxButton) {
    return MessageBoxButtonFactory(data);
}