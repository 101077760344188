import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { OSID } from "../enums";

export interface IBaseProperty {
    id: number,
    osid: OSID,
    propertyTypeId: number,
    order: number,
    isRequired: number
}

export interface IProperty extends IBaseProperty { }

export interface IReadonlyProperty extends IBaseProperty { }

export type Property = ReadonlyRecord<IReadonlyProperty>;

export const PropertyFactory = Record<IReadonlyProperty>({
    id: 0,
    osid: OSID.Null,
    propertyTypeId: 0,
    order: 0,
    isRequired: 0
});

export const PropertyFactoryFromJS = function (property: IProperty) {
    return PropertyFactory(property);
}