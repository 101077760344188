import { createMutableAction } from "@bimser/core";
import * as ActionTypes from './actionTypes';
import { ILanguage, Language, LanguageFactoryFromJS } from "../entities";
import { List } from 'immutable';


export default function setLanguagesAction(data: Array<ILanguage>, userCanChangeLanguage = true) {
    let _languages: Array<Language> = data && data.length ? data.map((value: ILanguage, index: number) => {
        return LanguageFactoryFromJS(value);
    }) : [];

    let payload = { languages: List(_languages), userCanChangeLanguage };

    let action = createMutableAction(ActionTypes.SET_LANGUAGES_ACTION, payload);

    return action;
}