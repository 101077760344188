import { Map, Record } from "immutable";
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { ILayoutItem, LayoutItem, LayoutItemFactoryFromJS } from '../entities';

export interface ILayoutItems {
    items: IDictionary<ILayoutItem>,
    ghostItems: IDictionary<ILayoutItem>
}

export interface IReadonlyLayoutItems {
    items: Map<string, LayoutItem>,
    ghostItems: Map<string, LayoutItem>
}

export type LayoutItems = ReadonlyRecord<IReadonlyLayoutItems>;

export const LayoutItemsFactory = Record<IReadonlyLayoutItems>({
    items: Map(),
    ghostItems: Map()
});

export const LayoutItemsFactoryFromJS = function (data: ILayoutItems) {
    let items: IDictionary<LayoutItem> = {};
    let ghostItems: IDictionary<LayoutItem> = {};

    for (let key in data.items) {
        let item = data.items[key];
        items[key] = LayoutItemFactoryFromJS(item);
    }

    for (let key in data.ghostItems) {
        let ghostItem = data.ghostItems[key];
        ghostItems[key] = LayoutItemFactoryFromJS(ghostItem);
    }

    return LayoutItemsFactory({
        items: Map(items),
        ghostItems: Map(ghostItems)
    });
}