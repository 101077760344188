import { ViewerState, ViewerStateFactory, ViewerItem, ViewerPanelFactoryFromJS } from '../entities';
import * as ActionTypes from '../actions/actionTypes';
import { IUpdateShowingViewerFilePayload, ISetLoadingPayload, ISetSkeletonTypePayload } from '../entities/Payloads';
import moment from 'moment';
import { IBaseAction } from '@bimser/core';

export default function (state: ViewerState = ViewerStateFactory(), action: IBaseAction<any>): ViewerState {
    switch (action.type) {
        case ActionTypes.ADD_NEW_VIEWER_FILE_ACTION: {
            return addViewerFile(state, action.payload);
        }
        case ActionTypes.REMOVE_VIEWER_FILE_ACTION: {
            return removeViewerFile(state, action.payload);
        }
        case ActionTypes.UPDATE_SHOWING_FILE_ACTION: {
            return updateShowingViewerFile(state, action.payload);
        }
        case ActionTypes.ON_CHANGE_VIEWER_FILE_DATA_ACTION: {
            return onChangeViewerFileData(state, action.payload);
        }
        case ActionTypes.ON_CHANGE_VIEWER_DATA_ACTION: {
            return onChangeViewerData(state, action.payload);
        }
        case ActionTypes.SET_LOADING_ACTION: {
            return setLoading(state, action.payload);
        }
        case ActionTypes.SET_SKELETON_TYPE_ACTION: {
            return setSkeletonType(state, action.payload);
        }
        case ActionTypes.REMOVE_INSTANCE_ACTION: {
            return removeViewerInstance(state, action.payload);
        }
        case ActionTypes.CREATE_NEW_INSTANCE_ACTION: {
            return createInstance(state, action.payload);
        }
        case ActionTypes.SET_VIEWERS_CONFIG_ACTION: {
            return setViewersConfig(state, action.payload);
        }
        default:
            return state;
    }
}

function setViewersConfig(state: ViewerState, payload: any) {
    return state.set("viewersConfig", payload);
}

function addViewerFile(state: ViewerState, payload: ViewerItem) {
    payload = payload.set("requestTime", moment().toISOString());
    return state.setIn(["items", payload.id], payload);
}

function removeViewerFile(state: ViewerState, payload: string) {
    return state.removeIn(["items", payload]);
}

function updateShowingViewerFile(state: ViewerState, payload: IUpdateShowingViewerFilePayload) {
    return state.setIn(["panels", payload.panelId, "itemId"], payload.viewerId);
}

function onChangeViewerFileData(state: ViewerState, payload: ViewerItem) {
    return state.setIn(["items", payload.id, "commitFile"], payload.data);
}

function onChangeViewerData(state: ViewerState, payload: ViewerItem) {
    return state.setIn(["items", payload.id, "data"], payload.data);
}

function setLoading(state: ViewerState, payload: ISetLoadingPayload) {
    return state.setIn(["panels", payload.panelId, "loading"], payload.loading);
}

function setSkeletonType(state: ViewerState, payload: ISetSkeletonTypePayload) {
    return state.setIn(["panels", payload.panelId, "skeletonType"], payload.contentType);
}

function removeViewerInstance(state: ViewerState, payload: string) {
    return state.removeIn(["panels", payload]);
}

function createInstance(state: ViewerState, payload: string) {
    return state.setIn(["panels", payload], ViewerPanelFactoryFromJS({ panelId: payload, itemId: "" }));
}