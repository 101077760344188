import { DmData, DmDataFactoryFromJS, IDmData } from "@bimser/common";
import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { List, Record, Map } from "immutable";
import { IProfileFormData, ProfileFormData, ProfileFormDataFactoryFromJS } from "./IProfileFormData";
import { IProfileFormFile, ProfileFormFile, ProfileFormFileFactoryFromJS } from "./IProfileFormFile";
import { IProfileFormFolder, ProfileFormFolder, ProfileFormFolderFactoryFromJS } from './IProfileFormFolder';

interface IBaseProfileFormState {
    objectId: string;
    isLoading: boolean;
    activeTabKey: string;
}

export interface IProfileFormState extends IBaseProfileFormState {
    dmObject: IDmData;
    fileList: IProfileFormFile[];
    formList: IProfileFormData[];
    folderProps: IProfileFormFolder;
    selectedIds: string[];
    initializedForms: IDictionary<boolean>;
}

export interface IReadonlyProfileFormState extends IBaseProfileFormState {
    dmObject: DmData;
    fileList: List<ProfileFormFile>;
    formList: List<ProfileFormData>;
    folderProps: ProfileFormFolder;
    selectedIds: List<string>;
    initializedForms: Map<string, boolean>;
}

export type ProfileFormState = ReadonlyRecord<IReadonlyProfileFormState>;

export const ProfileFormStateFactory = Record<IReadonlyProfileFormState>({
    activeTabKey: null,
    objectId: null,
    isLoading: false,
    dmObject: null,
    fileList: List(),
    formList: List(),
    folderProps: null,
    selectedIds: List(),
    initializedForms: Map()
});

export const ProfileFormStateFactoryFromJS = function (data: IProfileFormState) {
    let _fileList = data?.fileList ? data.fileList.map(f => ProfileFormFileFactoryFromJS(f)) : [];
    let _formList = data?.formList ? data.formList.map(f => ProfileFormDataFactoryFromJS(f)) : [];

    return ProfileFormStateFactory({
        ...data,
        dmObject: DmDataFactoryFromJS(data.dmObject),
        folderProps: ProfileFormFolderFactoryFromJS(data.folderProps),
        fileList: List(_fileList),
        formList: List(_formList),
        selectedIds: List(data.selectedIds),
        initializedForms: Map(data.initializedForms)
    });
}