import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IDepartmentFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';


const DepartmentFormContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function DepartmentFormContainer(props: React.PropsWithChildren<IDepartmentFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <DepartmentFormContainerLazy {...props} />
        </Suspense>
    );
}
DepartmentFormContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default DepartmentFormContainer;