import { IExtremeGridState } from "../entities";
import { IBaseMutableAction } from "@bimser/core";

export default function changeSearchingReducer(state: IExtremeGridState, action: IBaseMutableAction<string>): IExtremeGridState {
    return Object.assign({}, state, {
        searching: {
            ...state.searching,
            value: action.payload
        }
    })
}