import { IBaseAction } from "@bimser/core";
import * as modalFrameActionTypes from "../../ModalFrameManager/actions/actionTypes";
import { ModalFrameState } from "../../ModalFrameManager/entities";
import * as ActionTypes from "../actions/actionTypes";
import { CLOSE_ALL_MESSAGEBOXES_ACTION } from "../modals/MessageBox/actions/actionTypes";
import { ModalManagerState, ModalManagerStateFactory, ModalState, IChangeModalDataPayload } from "../entities";

export default function (state: ModalManagerState = ModalManagerStateFactory(), action: IBaseAction<any>): ModalManagerState {

    let result: ModalManagerState;

    switch (action.type) {
        case ActionTypes.OPEN_MODAL:
            result = openModal(state, action);
            toggleModalBlur(result);
            break;
        case ActionTypes.CLOSE_MODAL:
            result = closeModal(state, action);
            toggleModalBlur(result);
            break;
        case ActionTypes.CHANGE_MODAL_VISIBILITY:
            result = changeModalVisibility(state, action);
            toggleModalBlur(result);
            break;
        case modalFrameActionTypes.OPEN_MODAL_FRAME:
            result = openModalFrame(state, action);
            toggleModalBlur(result);
            break;
        case modalFrameActionTypes.CLOSE_MODAL_FRAME:
            result = closeModalFrame(state, action);
            toggleModalBlur(result);
            break;
        case ActionTypes.CHANGE_MODAL_PROPS_ACTION:
            result = changeModalProps(state, action);
            toggleModalBlur(result);
            break;
        case CLOSE_ALL_MESSAGEBOXES_ACTION: {
            result = closeAllMessageboxes(state, action);
            toggleModalBlur(result);
            break;
        }
        case ActionTypes.CHANGE_MODAL_DATA: {
            result = changeModalData(state, action);
            toggleModalBlur(result);
            break;
        }
        default:
            result = state;
    }

    return result
}

function changeModalData(state: ModalManagerState, action: IBaseAction<any>): ModalManagerState {
    let payload: IChangeModalDataPayload = action.payload;
    return state.setIn(["data", payload.modalId], payload.data);
}

function closeAllMessageboxes(state: ModalManagerState, action: IBaseAction<null>): ModalManagerState {
    let items = state.items.filter(q => q.type != "MESSAGEBOX");
    return state.set("items", items);
}

function closeModalFrame(state: ModalManagerState, action: IBaseAction<string>): ModalManagerState {
    let frame: ModalFrameState = state.modalFrame.set("url", "");
    return state.set("modalFrame", frame);
}

function openModalFrame(state: ModalManagerState, action: IBaseAction<string>): ModalManagerState {
    let frame: ModalFrameState = state.modalFrame.set("url", action.payload);
    return state.set("modalFrame", frame);
}

function openModal(state: ModalManagerState, action: IBaseAction<ModalState>): ModalManagerState {
    let itemIndex = state.items.findIndex(q => q.id == action.payload.id);
    let items = state.items;

    if (itemIndex > -1) {
        let modal = state.items.get(itemIndex);
        //modal = modal.set("visible", true);
        modal = modal.mergeDeep(action.payload);
        modal = modal.setIn(["props", "buttons"], action.payload.props.buttons);
        items = state.items.set(itemIndex, modal);
    } else {
        items = state.items.push(action.payload);
    }
    return state.set("items", items);
}

function changeModalProps(state: ModalManagerState, action: IBaseAction<any>): ModalManagerState {
    let itemIndex = state.items.findIndex(q => q.id == action.payload.id);
    let items = state.items;

    if (itemIndex > -1) {
        let modal = state.items.get(itemIndex);
        modal = modal.set("props", { ...modal.props, ...action.payload.data });
        items = items.set(itemIndex, modal);
    }
    return state.set("items", items);

}

function changeModalVisibility(state: ModalManagerState, action: IBaseAction<string>): ModalManagerState {
    let itemIndex = state.items.findIndex(q => q.id == action.payload);
    let items = state.items;
    if (itemIndex > -1) {
        let modal = state.items.get(itemIndex);
        modal = modal.set("visible", !modal.visible);
        items = state.items.set(itemIndex, modal);
    }
    return state.set("items", items);
}

function closeModal(state: ModalManagerState, action: IBaseAction<string>): ModalManagerState {
    let itemIndex = state.items.findIndex(q => q.id == action.payload);
    let isExistData = state.data.get(action.payload)
    let items = state.items;
    let data = state.data;

    if (itemIndex > -1) {
        items = state.items.delete(itemIndex);
    }
    if(isExistData) {
        data = state.data.delete(action.payload)
    }

    return state.set("items", items).set("data", data);
}

function toggleModalBlur(state: ModalManagerState) {

    let modals = state.items;
    let modalCount = modals.count();
    let mainDiv: any = document.getElementsByTagName("main")[0];

    // Add/Remove blur on background
    if (mainDiv) mainDiv.style.filter = (modalCount > 0 ? 'blur(4px)' : '');

    // Set zIndex of modals and visibilities of masks
    modals.forEach((modal, index) => {
        let modalElem: any = document.getElementsByClassName("modalId_" + modal.id)[0];
        if (modalElem) modalElem = modalElem.parentElement;

        if (modalElem) {
            if (index === modalCount - 1) {
                //Latest modal
                modalElem.parentElement.style.zIndex = '1001';
            } else {
                //Previous Modals
                modalElem.parentElement.style.zIndex = '1000';
            }
        }

    });

}