import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import { createSelector,OutputSelector } from "reselect";
import {Record} from 'immutable'

const languageParameters = (state: BaseState) => state.user.data.language;

const getSelectedLanguageName = createSelector(
    [
        languageParameters
    ],
    (data: string) => {
        return data;
    }
);

export { getSelectedLanguageName };

