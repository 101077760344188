import * as LayoutCreatorEntities from '@bimser/components/dist/BCLayoutCreator/common/entities';
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Record, Map } from "immutable";

export interface IBaseFormViewerItem {
    clientUrl: string,
    endTransactionGuid: string,
    runSecretKey?: string,
    lastBatchGuid?: string,
}

export interface IFormViewerItem extends IBaseFormViewerItem {
    structureItems: LayoutCreatorEntities.INormalizedLayoutStructureItems,
    dataItems: IDictionary<LayoutCreatorEntities.ILayoutDataItem>
}


export interface IReadonlyFormViewerItem extends IBaseFormViewerItem {
    structureItems: LayoutCreatorEntities.NormalizedLayoutStructureItems,
    dataItems: Map<string, LayoutCreatorEntities.LayoutDataItem>
}

export type FormViewerItem = ReadonlyRecord<IReadonlyFormViewerItem>;

export const FormViewerItemFactory = Record<IReadonlyFormViewerItem>({
    structureItems: LayoutCreatorEntities.NormalizedLayoutStructureItemsFactory(),
    dataItems: Map(),
    clientUrl: null,
    runSecretKey: null,
    lastBatchGuid: null,
    endTransactionGuid: null
});

export const FormViewerItemFactoryFromJS = function (data: IFormViewerItem) {

    let dataItems: IDictionary<LayoutCreatorEntities.LayoutDataItem> = {};

    for (let key in data.dataItems) {
        let item = data.dataItems[key];
        dataItems[key] = LayoutCreatorEntities.LayoutDataItemFactoryFromJS(item);
    }

    return FormViewerItemFactory({
        structureItems: LayoutCreatorEntities.NormalizedLayoutStructureItemsFactoryFromJS(data.structureItems),
        dataItems: Map(dataItems),
        clientUrl: data.clientUrl,
        endTransactionGuid: data.endTransactionGuid,
        runSecretKey: data.runSecretKey,
        lastBatchGuid: data.lastBatchGuid
    });
}