import { List } from "immutable";

export default function mergeRecursiveList(source: List<any>, news: List<any>, updateKeys: Array<string>, recursiveKey?: string, optionalUpdater?: Function) {
    let newSource: List<any> = source;
    news.forEach((item) => {

        let index = source.findIndex((stateItem) => { return stateItem.key === item.key });
        let newItem = newSource.get(index);
        if (index != -1) {
            updateKeys.forEach((updateKey) => {
                if (item.get(updateKey) != undefined) {
                    newItem = newItem.set(updateKey, item.get(updateKey));
                }
            });
            if (optionalUpdater) {
                newItem = optionalUpdater(item, newItem);
            }
            if (recursiveKey && item.get(recursiveKey) && !item.get(recursiveKey).isEmpty()) {
                newItem = newItem.set(recursiveKey, mergeRecursiveList(newItem.get(recursiveKey), item.get(recursiveKey), updateKeys, recursiveKey, optionalUpdater));
            }
            newSource = newSource.set(index, newItem);
        }
        else {
            newSource = newSource.push(item);
        }

    });
    return newSource;
}
