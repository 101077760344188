import { BCNotification, findMessage } from "@bimser/components";
import { takeEvery, call } from 'redux-saga/effects';
import * as ActionTypes from "../actions/actionTypes";
import { ServiceList, handleGetPasswordRequirements } from '../../..';
import { getInnerExceptionErrorCode } from "@bimser/core";

export default function* watchChangeMyPasswordTransaction() {
    yield takeEvery(ActionTypes.ONCLICK_CHANGE_MY_PASSWORD_TRANSACTION, changeMyPasswordTransaction);
    yield takeEvery(ActionTypes.GET_CHANGE_PASSWORD_REQUIREMENTS_TRANSACTION, getChangePasswordRequirements);
}
export function* changeMyPasswordTransaction(sagaAction: any) {
    let actionPayload: { newPass: string, confirmNewPass: string, resetTokenId: string, callBackResponse: (args: any) => void } = sagaAction.payload;
    try {
        let response = yield ServiceList.main.Password.ResetPassword.handleError((err) => handleError(err)).call(actionPayload, undefined, undefined, true);
        if (response) {
            actionPayload.callBackResponse(response);
            BCNotification.info({
                message: findMessage.get('101949'),
                description: findMessage.get('101950'),
            });
        } else {
            actionPayload.callBackResponse(false);
        }
    } catch (error) {
        BCNotification.error({
            message: findMessage.get('100592'),
        });
    }
}

function* getChangePasswordRequirements(action: any) {
    yield call(handleGetPasswordRequirements);
}

function handleError(error: any) {
    return function* () {
        let errorCode = getInnerExceptionErrorCode(error?.exception?.data?.exception);
        if (errorCode == "AUTH_SERV_0099") {
            BCNotification.warning({
                message: findMessage.get("102645"),
                duration: 5
            });
        }
        else {
            BCNotification.warning({
                message: findMessage.get("101959"),
            });
        }
        yield 0;
    };
}