import Rate from 'antd/lib/rate';
import * as React from "react";
import { IRateProps } from './entities';
import * as Styles from './assets/styles.scss';

export default class BCRate extends React.Component<IRateProps, {}> {
    constructor(props: IRateProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onHoverChange = this.onHoverChange.bind(this);
        Styles;
    }

    onChange(value: number) {
        if (this.props.onChange){
            this.props.onChange(value || null);
        }
    }

    onHoverChange(value: number) {
        if (this.props.onHoverChange)
            this.props.onHoverChange(value);
    }

    render() {
        return (
            <Rate
                allowClear={this.props.allowClear}
                allowHalf={this.props.allowHalf}
                character={this.props.character}
                className={this.props.className}
                count={this.props.count}
                defaultValue={this.props.defaultValue}
                disabled={this.props.disabled || this.props.readOnly}
                style={this.props.style}
                tooltips={this.props.tooltips}
                value={this.props.value}
                onChange={this.onChange}
                onHoverChange={this.onHoverChange}
            />
        );
    }
}

export { BCRate, IRateProps };
