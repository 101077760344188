import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent, IDepartment, Department, DepartmentFactoryFromJS, DepartmentFactory } from "..";

export interface IDepartmentListFormContent extends IBaseFormContent {
    selectedDepartment?: IDepartment
}

export interface IReadonlyDepartmentListFormContent extends IBaseFormContent {
    selectedDepartment?: Department
}

export type DepartmentListFormContent = ReadonlyRecord<IReadonlyDepartmentListFormContent>;

export const DepartmentListFormContentFactory = Record<IReadonlyDepartmentListFormContent>({
    panelId: "",
    selectedDepartment: DepartmentFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: false,
    selectedCompanyId: 0,
});

export const DepartmentListFormContentFactoryFromJS = function (data: IDepartmentListFormContent) {
    return DepartmentListFormContentFactory({
        panelId: data.panelId,
        selectedDepartment: DepartmentFactoryFromJS(data.selectedDepartment),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectedCompanyId: data.selectedCompanyId
    });
}