import * as React from "react";

export interface IOutsideClickAlerterProps {
    style?: React.CSSProperties,
    className?: string,
    children?: any,
    outsideclicked?: () => void
}

const useOutsideAlerter = (ref: React.RefObject<HTMLDivElement>, onOutsideClick: Function) => {
    const handleClickOutside = React.useCallback((event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onOutsideClick()
        }
    }, [ref, onOutsideClick])

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);
}

const OutsideClickAlerter: React.FC<IOutsideClickAlerterProps> = ({ outsideclicked, children, ...props }) => {
    const wrapperRef = React.useRef<HTMLDivElement>();
    useOutsideAlerter(wrapperRef, outsideclicked);
    return <div ref={wrapperRef} {...props}>{children}</div>;
}

export default React.memo(OutsideClickAlerter)