import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";


interface IBaseActionMenuItem {
    key: string,
    projectName: string,
    actionName: string,
    itemCount: number,
}

export interface IActionMenuItem extends IBaseActionMenuItem {

}

export interface IReadonlyActionMenuItem extends IBaseActionMenuItem {

}

export type ActionMenuItem = ReadonlyRecord<IReadonlyActionMenuItem>;

export const ActionMenuItemFactory = Record<IReadonlyActionMenuItem>({
    key: null,
    projectName: null,
    actionName: null,
    itemCount: null,
});

export const ActionMenuItemFactoryFromJS = function (data: IActionMenuItem) {
    return ActionMenuItemFactory(data);
}