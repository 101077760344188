export enum EditorTypes {
    textBox = "TextBox",
    checkBox = "Checkbox",
    numberBox = "NumberBox",
    datePicker = "DatePicker",
    dateRangePicker = "DateRangePicker",
    dateTimePicker = "DateTimePicker",
    timePicker = "TimePicker",
    comboBox = "SelectBox",
    actionButton = "Button",
    multiLanguageTextBox = "MultiLanguage",
    custom = "custom",
    none = "None",
    fileSelect = "FileSelect",
}
