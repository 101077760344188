import { List } from 'immutable';
import { LoginSliderItem } from '../entities/ILoginSliderItem';
import { IBaseAction } from '../../..';
import { SET_LOGIN_SLIDER_ITEMS_ACTION } from '../actions/actionTypes';


const LoginSliderReducer = (state: List<LoginSliderItem>, action: IBaseAction<List<LoginSliderItem>>): List<LoginSliderItem> => {
    switch (action.type) {
        case SET_LOGIN_SLIDER_ITEMS_ACTION: {
            return action.payload;
        }
        default:
            return state;
    }
}

export default LoginSliderReducer;