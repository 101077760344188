import React, { lazy, Suspense } from 'react';
import ICommandBarProps from '../interfaces/ICommandBarProps';

const FabricCommandBar = lazy(
    () => import(/* webpackChunkName: "fabric-ui" */ "office-ui-fabric-react/lib/CommandBar").then(module => {
        import(/* webpackChunkName: "fabric-ui" */ "office-ui-fabric-react/lib/Icons").then(({ initializeIcons }) => {
            initializeIcons();
        });

        import(/* webpackChunkName: "fabric-ui" */ "office-ui-fabric-react/lib/Styling").then(({ setIconOptions }) => {
            // Suppress icon warnings.
            setIconOptions({
                disableWarnings: true
            });
        });

        return { default: module.CommandBar };
    })
);

function CommandBar(props: ICommandBarProps) {
    return (
        <Suspense fallback={<div></div>}>
            <FabricCommandBar {...props} />
        </Suspense>
    );
}

export default CommandBar;