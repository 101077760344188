import { BCExtremeGridEntities } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IIndexReportDetail, IndexReportDetail, IndexReportDetailFactoryFromJS } from "./IIndexReportDetail";
import { IIndexReportDetailFileList, IndexReportDetailFileList, IndexReportDetailFileListFactoryFromJS } from "./IIndexReportDetailFileList";

interface IBaseIndexReportDetailData {
    key: string
    hasMoreFiles?: boolean,
    searchStr?: string
}

export interface IIndexReportDetailData extends IBaseIndexReportDetailData {
    detailData?: IIndexReportDetail,
    fileListData?: IIndexReportDetailFileList,
    loadOptions?: BCExtremeGridEntities.ILoadOptions
}

export interface IReadonlyIndexReportDetailData extends IBaseIndexReportDetailData {
    detailData: IndexReportDetail,
    fileListData: IndexReportDetailFileList,
    loadOptions: BCExtremeGridEntities.LoadOptions
}

export type IndexReportDetailData = ReadonlyRecord<IReadonlyIndexReportDetailData>;

export const IndexReportDetailDataFactory = Record<IReadonlyIndexReportDetailData>({
    key: null,
    detailData: null,
    fileListData: null,
    searchStr: null,
    hasMoreFiles: false,
    loadOptions: BCExtremeGridEntities.LoadOptionsFactory()

});

export const IndexReportDetailDataFactoryFromJS = function (data: IIndexReportDetailData) {
    return IndexReportDetailDataFactory({
        ...data,
        detailData: data.detailData ? IndexReportDetailFactoryFromJS(data.detailData) : null,
        fileListData: data.fileListData ? IndexReportDetailFileListFactoryFromJS(data.fileListData) : null,
        loadOptions: data.loadOptions ? BCExtremeGridEntities.LoadOptionsFactoryFromJS(data.loadOptions) : null
    });
}