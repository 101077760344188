import { put, select } from 'redux-saga/effects';
import { lowerCaseCounter, upperCaseCounter, stringFormat } from '@bimser/core';
import { ServiceList } from '../../../services/serviceList';
import {setPasswordRequirementsAction} from '../actions';
import { getDefaultLanguage } from './../../language/selectors'
import { BaseState } from '../../../entities/BaseState'
import { findMessage } from '@bimser/components';

export function* handleGetPasswordRequirements() {
    try {
        let selectedCulture = yield select((state: BaseState) => state.user.selectedCulture || getDefaultLanguage(state).culture)
        let checkIsLoaded = yield select((state: BaseState) => state.system.passwordRequirements.minimumLength)

        if(checkIsLoaded == null){
            let response = yield ServiceList.main.Password.GetPasswordRequirements.call({ language: selectedCulture });

            if (response) {
                yield put(setPasswordRequirementsAction(response.rule));
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export function checkPasswordValidation(data: any) {
    let { minimumLength = 1, minimumLowerCaseCharacterCount = 0, minimumNonAlphaNumericCharacterCount = 0, minimumNumericCharacterCount = 0, minimumUpperCaseCharacterCount = 0, password } = data;
        
    if (password.length < minimumLength) {
        return { rslt: false, msg: stringFormat(findMessage.get("100812"), minimumLength) };
    }
    if (password && password.replace(/[^0-9]/g, "").length < minimumNumericCharacterCount) {
        return { rslt: false, msg: stringFormat(findMessage.get("100813"), minimumNumericCharacterCount) };
    }
    if (password && lowerCaseCounter(password) < minimumLowerCaseCharacterCount) {
        return { rslt: false, msg: stringFormat(findMessage.get("100814"), minimumLowerCaseCharacterCount) };
    }
    if (password && upperCaseCounter(password) < minimumUpperCaseCharacterCount) {
        return { rslt: false, msg: stringFormat(findMessage.get("100815"), minimumUpperCaseCharacterCount) };
    }
    if (password && countNon(password) < minimumNonAlphaNumericCharacterCount) {
        return { rslt: false, msg: stringFormat(findMessage.get("100816"), minimumNonAlphaNumericCharacterCount) };
    }

    return { rslt: true, msg: "" };
}

function countNon(text: string): number {
    var regex = /[^a-zA-Z\d\s:]/; //^[a-zA-z]+$/
    let count = 0;
    for (let index = 0; index < text.length; index++) {
        if (regex.test(text[index])) count++;
    }
    return count;
}