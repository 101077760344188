import { List, Map } from "immutable";
import { createSelector } from "reselect";
import { WebInterfaceBaseState } from "../../../../../../../common/base/state"
import { ProfileFormData } from "../entities/IProfileFormData";
import { ProfileFormFile } from "../entities/IProfileFormFile";
import { ProfileFormFolder } from '../entities/IProfileFormFolder';


const getProfileFormPanelLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.isLoading;
}

const getProfileFormPanelFileListSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.fileList;
}

const getProfileFormPanelFormListSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.formList;
}


const getProfileFormSelectedFilesSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.selectedIds;
}

const getInitializedFormsSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.initializedForms;
}

const getActiveFormTabKeySelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.activeTabKey;
}

const getProfileFormPanelFolderPropsSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dm.profileForm.folderProps;
}

export const getProfileFormPanelLoading = createSelector(
    [
        getProfileFormPanelLoadingSelector
    ],
    (loading: boolean) => loading
)

export const getProfileFormPanelFileList = createSelector(
    [
        getProfileFormPanelFileListSelector
    ],
    (fileList: List<ProfileFormFile>) => fileList.toJS()
)

export const getProfileFormPanelFormList = createSelector(
    [
        getProfileFormPanelFormListSelector
    ],
    (formList: List<ProfileFormData>) => formList.toJS()
)

export const getProfileFormSelectedFiles = createSelector(
    [
        getProfileFormSelectedFilesSelector
    ],
    (selectedIds: List<string>) => selectedIds.toJS()
)

export const getInitializedForms = createSelector(
    [
        getInitializedFormsSelector
    ],
    (initializedForms: Map<string, boolean>) => initializedForms.toJS()
)

export const getActiveFormTabKey = createSelector(
    [
        getActiveFormTabKeySelector
    ],
    (activeKey: string) => activeKey
)

export const getProfileFormPanelFolderProps = createSelector(
    [
        getProfileFormPanelFolderPropsSelector
    ],
    (folderProps: ProfileFormFolder) => folderProps?.toJS()
)