import { createGuid, IItem } from "@bimser/core";
import { GetIconComponent } from "@bimser/icons";
import IconGripperDots from "@bimser/icons/16/gripper-dots";
import IconMore from "@bimser/icons/16/more";
import * as React from "react";
import { BCDropdownWrapper } from "../..";
import { DragSourceWrapper } from '../../BCDragDrop';
import { IMenuItemClickEventArgs } from "../../BCMenu";
import * as Styles from "../assets/style.scss";
import { IToolboxItemProps } from '../entities';

var diff = require('deep-diff').diff;

var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

const dragIconStyle = { marginRight: '10px', cursor: "move" };

class ToolboxItem extends React.Component<IToolboxItemProps, {}>{

    readonly state: any;

    constructor(props: IToolboxItemProps) {
        super(props);

        this.state = {
            contextVisible: false
        };

        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);
    }

    shouldComponentUpdate(newProps: IToolboxItemProps) {
        return diff({
            data: newProps.data,
            isDragging: newProps.isDragging,
            selected: newProps.selected,
            type: newProps.type,
            isDropped: newProps.isDropped,
            contextMenu: newProps.contextMenu
        }, {
            data: this.props.data,
            isDragging: this.props.isDragging,
            selected: this.props.selected,
            type: this.props.type,
            isDropped: this.props.isDropped,
            contextMenu: this.props.contextMenu
        }) ? true : false
    }

    showContextMenu(args: React.MouseEvent<any>) {
        if (this.props.onRightClick) {
            this.props.onRightClick(this.props.data);
        }
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick(this.props.data);
        }
    }

    onDoubleClick() {
        if (this.props.onDoubleClick) {
            this.props.onDoubleClick(this.props.data);
        }
    }

    actionBtnClick(args: IMenuItemClickEventArgs) {
        if (this.props.contextMenu && this.props.contextMenu.onClickContextMenuItem) {
            this.props.contextMenu.onClickContextMenuItem(args.data.key, this.props.data.key as string);
        }
    }

    renderIcon() {
        if (this.props.data.icon) {
            const Icon = GetIconComponent(this.props.data.icon, { color: this.props.data.iconColor });

            return (
                <div className={Styles.icon}>
                    {Icon}
                </div>
            )
        }
    }

    renderDragIcon() {
        if (this.props.data.icon && this.props.data.draggable) {
            return (
                <div className={Styles.icon} style={dragIconStyle}>
                    <IconGripperDots width="18px" />
                </div>
            )
        }
        else {
            return <div className={Styles.dragIconEmpty}></div>
        }
    }

    renderAdditionalLabel() {
        if (this.props.data.additionalLabel) {
            return (
                <small>{this.props.data.additionalLabel}</small>
            )
        }
    }

    renderText() {
        return (
            <div className={Styles.text}>
                {this.props.data.caption}
                {this.renderAdditionalLabel()}
            </div>
        )
    }

    renderItemActionButton(contextMenuItems: IItem[]) {

        if (contextMenuItems && contextMenuItems.length) {

            const moreIconClassNames = cx({
                moreIcon: true
            });

            return (
                <BCDropdownWrapper
                    menuProps={{
                        items: contextMenuItems,
                        onClick: (args) => { this.actionBtnClick(args) }
                    }}
                    trigger={['click']}
                    placement={'bottomRight'}
                >
                    <IconMore className={moreIconClassNames} />
                </BCDropdownWrapper >
            )

        }

    }

    wrapDragSource(children: JSX.Element) {
        return this.props.connectDragSource(children)
    }

    renderToolboxItem() {

        const { isDragging, data } = this.props;

        let toolboxItemClassNames = cx({
            toolboxItem: true,
            selected: data.selected,
            passive: data.passive,
            isDragging
        });

        return (
            <div className={toolboxItemClassNames}
                onContextMenu={this.showContextMenu.bind(this)}
                onClick={this.onClick}
                onDoubleClick={this.onDoubleClick}>
                <div className={Styles.lineContent}>
                    {this.renderDragIcon()}
                    {this.renderIcon()}
                    {this.renderText()}
                    {this.renderItemActionButton(data.contextMenuItems)}
                </div>
            </div>
        )

    }

    render() {
        return this.props.data.draggable ? this.wrapDragSource(this.renderToolboxItem()) : this.renderToolboxItem();
    }
}

export default DragSourceWrapper({
    componentClass: ToolboxItem,
    options: {
        type: (props: IToolboxItemProps) => props.data.dropTarget,
        collect: (connect, monitor) => {
            return {
                connectDragSource: connect.dragSource(),
                isDragging: monitor.isDragging()
            }
        },
        spec: {
            beginDrag: (props, monitor, component) => {
                return {
                    props,
                    guid: createGuid()
                }
            }
        }
    }
});