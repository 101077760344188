import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

export interface IWindowSize {
    width?: number,
    height?: number
}

export type IReadonlyWindowSize = IWindowSize;

export type WindowSize = ReadonlyRecord<IReadonlyWindowSize>;

export const WindowSizeFactory = Record<IReadonlyWindowSize>({
    height: null,
    width: null
});

export const WindowSizeFactoryFromJS = function (data: IWindowSize) {
    return WindowSizeFactory(data);
}