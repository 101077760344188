import { findMessage } from '@bimser/components';

export const ErrorCodes: any = {
    "STD.ERROR_000": findMessage.get("101454"),
    "STD.ERROR_001": findMessage.get("101455"),
    "STD.ERROR_002": findMessage.get("101456"),
    "STD.ERROR_003": findMessage.get("101457"),
    "STD.ERROR_004": findMessage.get("101458"),
    "STD.ERROR_005": findMessage.get("101459"),
    "STD.ERROR_006": findMessage.get("101460"),
    "STD.ERROR_007": findMessage.get("101461"),
    "STD.ERROR_008": findMessage.get("101462"),
    "STD.ERROR_009": findMessage.get("101463"),
    "STD.ERROR_010": findMessage.get("101464"),
    "STD.ERROR_011": findMessage.get("101465"),
    "STD.ERROR_012": findMessage.get("101466"),
    "STD.ERROR_013": findMessage.get("101467"),
    "STD.ERROR_014": findMessage.get("101468"),
    "STD.ERROR_015": findMessage.get("101469"),
    "STD.ERROR_016": findMessage.get("101470"),
    "STD.ERROR_017": findMessage.get("101471"),
    "STD.ERROR_018": findMessage.get("101472"),
    "STD.ERROR_019": findMessage.get("101473"),
    "STD.ERROR_020": findMessage.get("101474"),
    "STD.ERROR_023": findMessage.get("102190"),
    "STD.ERROR_025": findMessage.get("1010007"),//Bu eylem için yetkiniz bulunmamaktadır
    "STD.ERROR_036": findMessage.get("101203"),
    "STD.ERROR_033": findMessage.get("101252"),
    "STD.ERROR_042": findMessage.get("102809"),
    "DSM.ERROR_000": findMessage.get("101454"),
    "DSM.ERROR_001": findMessage.get("101475"),
    "DSM.ERROR_002": findMessage.get("101476"),
    "DSM.ERROR_003": findMessage.get("101477"),
    "DSM.ERROR_005": findMessage.get("101478"),
    "DSM.ERROR_006": findMessage.get("101479"),
    "DSM.ERROR_007": findMessage.get("101480"),
    "DSM.ERROR_008": findMessage.get("101481"),
    "DSM.ERROR_009": findMessage.get("101482"),
    "DSM.ERROR_010": findMessage.get("101483"),
    "DSM.ERROR_011": findMessage.get("101484"),
    "DSM.ERROR_012": findMessage.get("101485"),
    "DSM.ERROR_013": findMessage.get("101486"),
    "DSM.ERROR_014": findMessage.get("101487"),
    "DSM.ERROR_015": findMessage.get("101488"),
    "DSM.ERROR_016": findMessage.get("101489"),
    "DSM.ERROR_017": findMessage.get("101490"),
    "DSM.ERROR_018": findMessage.get("101491"),
    "DSM.ERROR_019": findMessage.get("101492"),
    "DSM.ERROR_020": findMessage.get("101493"),
    "DSM.ERROR_021": findMessage.get("101494"),
    "DSM.ERROR_022": findMessage.get("101495"),
    "DSM.ERROR_023": findMessage.get("101496"),
    "DSM.ERROR_024": findMessage.get("101497"),
    "DSM.ERROR_025": findMessage.get("101498"),
    "DSM.ERROR_026": findMessage.get("101499"),
    "DS_001": findMessage.get("101659"),
    "DS_002": findMessage.get("101660"),
    "DSP_001": findMessage.get("101661"),
    "DSP_002": findMessage.get("101662"),
    "DSP_003": findMessage.get("101663"),
    "DSP_004": findMessage.get("101663"),
    "DSP_005": findMessage.get("101664"),
    "DSP_006": findMessage.get("101665"),
    "DSP_007": findMessage.get("101666"),
    "DSP_008": findMessage.get("101667"),
    "DSP_009": findMessage.get("101668"),
    "DSP_010": findMessage.get("101669"),
    "DSP_011": findMessage.get("101670"),
    "DSP_012": findMessage.get("101668"),
    "DSP_013": findMessage.get("101671"),
    "DSP_014": findMessage.get("101672"),
    "DSP_015": findMessage.get("101673"),
    "DSP_016": findMessage.get("101674"),
    "DSP_017": findMessage.get("101675"),
    "DSP_018": findMessage.get("101676"),
    "DSP_019": findMessage.get("101475"),
    "DSP_020": findMessage.get("101678"),
    "FRM.025": findMessage.get("102225"),
    "FRM.026": findMessage.get("102226"),
    "FRM.034": findMessage.get("100628"),
    "FRM.035": findMessage.get("100628"),
    "FRM.039": findMessage.get("100628"),
    "FRM.040": findMessage.get("100629"),
    "FRM.041": findMessage.get("100669"),
    "FRM.042": findMessage.get("100678"),
    "FRM.043": findMessage.get("101945"),
    "FRM.044": findMessage.get("1010017"),
    "FRM.045": findMessage.get("1010018"),
    "FRM.074": findMessage.get("103031"),

    // #BEGIN: Human Resource Error and Validation Codes;
    "HRES_SERV_0001": findMessage.get("102405"),
    "HRES_SERV_0002": findMessage.get('101925').replace('{0}', findMessage.get("101928")),
    "HRES_SERV_0003": findMessage.get('101927').replace('{0}', findMessage.get("101928")),
    "HRES_SERV_0004": findMessage.get('101925').replace('{0}', findMessage.get("101929")),
    "HRES_SERV_0005": findMessage.get('101927').replace('{0}', findMessage.get("101929")),
    "HRES_SERV_0006": findMessage.get('102044'),
    "HRES_SERV_0007": findMessage.get('101925').replace('{0}', findMessage.get("100465").toLocaleLowerCase()),
    "HRES_SERV_0008": findMessage.get('101927').replace('{0}', findMessage.get("100465").toLocaleLowerCase()),
    "HRES_SERV_0009": findMessage.get('101925').replace('{0}', findMessage.get("100722").toLocaleLowerCase()),
    "HRES_SERV_0010": findMessage.get('102406').replace('{0}', findMessage.get("100276").toLocaleLowerCase()),
    "HRES_SERV_0011": findMessage.get('102407').replace('{0}', findMessage.get("100722").toLocaleLowerCase()),
    "HRES_SERV_0012": findMessage.get('101927').replace('{0}', findMessage.get("100722").toLocaleLowerCase()),
    "HRES_SERV_0013": findMessage.get('102408').replace('{0}', findMessage.get("100276").toLocaleLowerCase()),
    "HRES_SERV_00015": findMessage.get('102381'),
    "HRES_SERV_00016": findMessage.get('102399'),
    "HRES_SERV_00017 ": findMessage.get("102790"), //Shift already exists
    "HRES_SERV_00018": findMessage.get("102791"), //Shift not found
    "HRES_SERV_00019": findMessage.get("102790"), //Shift already exists
    "HRES_SERV_00034": findMessage.get("102792"), //Shift is used
    "HRES_SERV_00020": findMessage.get("102793"), //OverTime already exists
    "HRES_SERV_00021": findMessage.get("102794"), //OverTime not found
    "HRES_SERV_00022": findMessage.get("102793"), //OverTime already exists
    "HRES_SERV_0029": findMessage.get('102593').replace('{0}', findMessage.get("102595")).replace('{1}', '50'), // Departman kodu en fazla 50 karakter olabilir.
    "HRES_SERV_0030": findMessage.get('102593').replace('{0}', findMessage.get("102596")).replace('{1}', '250'), //Departman açıklaması en fazla 250 karakter olabilir.
    "HRES_SERV_0031": findMessage.get('102594').replace('{0}', findMessage.get("102595")), //Departman kodu gereklidir.
    "HRES_SERV_0032": findMessage.get('102594').replace('{0}', findMessage.get("102596")), //Departman açıklaması gereklidir.
    "HRES_SERV_0034": findMessage.get('102642'), // Silmek istediğiz vardiya kullanılmaktadır.
    "HRES_SERV_0035": findMessage.get('102593').replace('{0}', findMessage.get("102667")).replace('{1}', '50'), //Pozisyon kodu maximum 50 karakter olmalıdır 
    "HRES_SERV_0036": findMessage.get('102594').replace('{0}', findMessage.get("102667")), //Poziyon kodu gereklidir 
    "HRES_SERV_0037": findMessage.get('102593').replace('{0}', findMessage.get("102668")).replace('{1}', '200'), //Pozisyon açıklaması maximum 200 karater olmalıdır 
    "HRES_SERV_0038": findMessage.get('102594').replace('{0}', findMessage.get("102668")), //Poziyon açıklaması gereklidir 
    "HRES_SERV_0039": findMessage.get("102669"), //Pozisyon kullanıcı sı gereklidir
    "HRES_SERV_0040": findMessage.get('102593').replace('{0}', findMessage.get("100729")).replace('{1}', '50'),//Aktarılmış Pozisyon kodu en fazla 250 karakter olabilir 
    "HRES_SERV_0041": findMessage.get("102670"), //Pozisyon kodu geçersiz
    "HRES_SERV_0042": findMessage.get("102671"), // Aktarılmış pozisyon kodu geçersiz
    "HRES_SERV_0043": findMessage.get('102686').replace('{0}', findMessage.get("101375")),//Şirketler düzenleme yetkiniz yoktur
    "HRES_SERV_0044": findMessage.get('102686').replace('{0}', findMessage.get("101374")),//Şirket Yöneticileri düzenleme yetkiniz yoktur
    "HRES_SERV_0045": findMessage.get('102686').replace('{0}', findMessage.get("100728")),//Kullanıcılar düzenleme yetkiniz yoktur
    "HRES_SERV_0046": findMessage.get('102686').replace('{0}', findMessage.get("100736")),//Departmanlar düzenleme yetkiniz yoktur
    "HRES_SERV_0047": findMessage.get('102686').replace('{0}', findMessage.get("100735")),//Unvanlar düzenleme yetkiniz yoktur
    "HRES_SERV_0048": findMessage.get('102686').replace('{0}', findMessage.get("100970")),//Pozisyonlar düzenleme yetkiniz yoktur
    "HRES_SERV_0049": findMessage.get('102686').replace('{0}', findMessage.get("100973")),//Kullanıcı gurupları düzenleme yetkiniz yoktur
    "HRES_SERV_0050": findMessage.get('102686').replace('{0}', findMessage.get("102476")),//Vardiya düzenleme yetkiniz yoktur
    "HRES_SERV_0051": findMessage.get('102686').replace('{0}', findMessage.get("102592")),//Mesai düzenleme yetkiniz yoktur
    "HRES_SERV_0052": findMessage.get('102686').replace('{0}', findMessage.get("101327")),//Organizasyon bakımı düzenleme yetkiniz yoktur
    "HRES_SERV_0053": findMessage.get('102686').replace('{0}', findMessage.get("101421")),//Yönetici anahtarları düzenleme yetkiniz yoktur
    "HRES_SERV_0054": findMessage.get('102686').replace('{0}', findMessage.get("101328")),//Özellik tanımlamaları düzenleme yetkiniz yoktur
    "HRES_SERV_0055": findMessage.get('102686').replace('{0}', findMessage.get("102631")),//Maaşlar düzenleme yetkiniz yoktur
    "HRES_SERV_0058": findMessage.get('102593').replace('{0}', findMessage.get("100722")).replace('{1}', '40'), //Username maximum 40 karakter olmalı.
    "HRES_SERV_0059": findMessage.get('102594').replace('{0}', findMessage.get("100722")), // Kullanıcı adı zorunludur.
    "HRES_SERV_0060": findMessage.get('102740').replace('{0}', findMessage.get("100722")), // Kullanıcı adı geçersizdir.
    "HRES_SERV_0061": findMessage.get('102594').replace('{0}', findMessage.get("100131")), // Ad zorunludur.
    "HRES_SERV_0062": findMessage.get('102593').replace('{0}', findMessage.get("100131")).replace('{1}', '40'), // Ad maximum 40 karakter olmalı.
    "HRES_SERV_0063": findMessage.get('102594').replace('{0}', findMessage.get("100132")), // Soyad zorunludur.
    "HRES_SERV_0064": findMessage.get('102593').replace('{0}', findMessage.get("100132")).replace('{1}', '40'), // Soyad maximum 40 karakter olmalı.
    "HRES_SERV_0065": findMessage.get('102594').replace('{0}', findMessage.get("100627")), // Şifre zorunludur.
    "HRES_SERV_0066": findMessage.get('102593').replace('{0}', findMessage.get("100627")).replace('{1}', '200'), // Şifre maximum 200 karakter olmalı.
    "HRES_SERV_0067": findMessage.get('102594').replace('{0}', findMessage.get("100509")), // E-posta zorunludur.
    "HRES_SERV_0068": findMessage.get('102593').replace('{0}', findMessage.get("100509")).replace('{1}', '200'), // E-posta maximum 200 karakter olmalı.
    "HRES_SERV_0070": findMessage.get('102740').replace('{0}', findMessage.get("100727")), // Cinsiyet geçersizdir.
    "HRES_SERV_0069": findMessage.get('102740').replace('{0}', findMessage.get("100724")), // Doğum tarihi geçersizdir.
    "HRES_SERV_0071": findMessage.get('102594').replace('{0}', findMessage.get("100472")), // Departman zorunludur.
    "HRES_SERV_0072": findMessage.get('102594').replace('{0}', findMessage.get("100474")), // Meslek zorunludur.
    "HRES_SERV_0073": findMessage.get('102593').replace('{0}', findMessage.get("102497")).replace('{1}', '250'),//Telefon maximum 250 karakter olmalı.
    "HRES_SERV_0074": findMessage.get('102593').replace('{0}', findMessage.get("102498")).replace('{1}', '250'),//Cep telefonu maximum 250 karakter olmalı.
    "HRES_SERV_0075": findMessage.get('102593').replace('{0}', findMessage.get("102504")).replace('{1}', '250'),//Sicil Numarası maximum 250 karakter olmalı.
    "HRES_SERV_0076": findMessage.get('102593').replace('{0}', findMessage.get("102495")).replace('{1}', '250'),//Çalışma şekli maximum 250 karakter olmalı.
    "HRES_SERV_0077": findMessage.get('102593').replace('{0}', findMessage.get("102493")).replace('{1}', '250'),//Doğum yeri maximum 250 karakter olmalı.
    "HRES_SERV_0078": findMessage.get('102593').replace('{0}', findMessage.get("102494")).replace('{1}', '250'),//Medeni hali maximum 250 karakter olmalı.
    "HRES_SERV_0079": findMessage.get('102593').replace('{0}', findMessage.get("102480")).replace('{1}', '250'),//Vatandaşlık Numarası maximum 250 karakter olmalı.
    "HRES_SERV_0080": findMessage.get('102593').replace('{0}', findMessage.get("102501")).replace('{1}', '250'),//Kişisel E-Posta maximum 250 karakter olmalı.
    "HRES_SERV_0081": findMessage.get('102593').replace('{0}', findMessage.get("102499")).replace('{1}', '250'),//Kişisel telefon maximum 250 karakter olmalı.
    "HRES_SERV_0082": findMessage.get('102593').replace('{0}', findMessage.get("102500")).replace('{1}', '250'),//Kişisel cep telefonu maximum 250 karakter olmalı.
    "HRES_SERV_0083": findMessage.get('102593').replace('{0}', findMessage.get("102502")).replace('{1}', '250'),//Adres maximum 250 karakter olmalı.
    "HRES_SERV_0084": findMessage.get('102593').replace('{0}', findMessage.get("102496")).replace('{1}', '250'),//Eğitim durumu maximum 250 karakter olmalı.
    "HRES_SERV_0085": findMessage.get('102740').replace('{0}', findMessage.get("102476")), // Vardiya geçersizdir.
    "HRES_SERV_0093": findMessage.get('102740').replace('{0}', findMessage.get("102763")), // Bu pozisyon kodu zaten kullanılmış => This position code has been already used. HumanResourcesServicPositionCodeDuplicate.
    "HRES_SERV_0094": findMessage.get("102801"), // Girilen grup kodu başka bir grupta kullanılıyor.
    "FRMW_CORE_0004": findMessage.get('102739'), //Lütfen alanları kontrol ediniz.
    // #END: Human Resource Error and Validation Codes;

    "DMS__OBJ__0006": findMessage.get('101699').replace('{0}', findMessage.get("101688")),//Nesne bulunamadı
    "DMS__OBJ__0010": findMessage.get('102455'),
    "FRM.067": findMessage.get("102549"),
    "WF.FLOW_PERMISSION": findMessage.get("102550"),
    "DMS__API__0001": findMessage.get("102552"),//Döküman yönetim sistemi genel hata.
    "DMS__API__0002": findMessage.get("102553"),//Token uyuşmuyor.
    "DMS__SERV_0001": findMessage.get("102552"),//Döküman yönetim sistemi genel hata.
    "DMS__CORE_0000": findMessage.get("102554"),//Döküman yönetim sistemi bilinmeyen hata.
    "DMS__FILE_0001": findMessage.get("102556"),//Hedef kısayol klasör bulunamadı.
    "DMS__FILE_0002": findMessage.get("102557"),//Hedef kısayolun gösterdiği klasör bulunamadı.
    "DMS__FILE_0003": findMessage.get("102558"),//Metadata bilgileri hatalı.
    "DMS__FILE_0004": findMessage.get("102559"),//Hedef klasör bulunamadı.
    "DMS__FILE_0005": findMessage.get("102556"),//Hedef kısayol klasör bulunamadı.
    "DMS__FILE_0006": findMessage.get("102557"),//Hedef kısayolun gösterdiği klasör bulunamadı.
    "DMS__FILE_0007": findMessage.get("102558"),//Metadata bilgileri hatalı.    
    "DMS__FILE_0008": findMessage.get("102559"),//Hedef klasör bulunamadı.
    "DMS__EXPL_0001": findMessage.get("102553"),//Token uyuşmuyor.
    "DMS__EXPL_0002": findMessage.get("102553"),//Token uyuşmuyor.
    "DMS__EXPL_0003": findMessage.get("102560"),//İstek nesneleri boş olamaz.
    "DMS__EXPL_0004": findMessage.get("102560"),//İstek nesneleri boş olamaz.
    "DMS__EXPL_0005": findMessage.get("102561"),//To kısmı boş olamaz.
    "DMS__EXPL_0006": findMessage.get("102561"),//To kısmı boş olamaz.
    "DMS__EXPL_0007": findMessage.get("102562"),//Eposta adresleri boş olamaz.
    "DMS__EXPL_0008": findMessage.get("102563"),//Action bilgisi 0 yada 1 olmalıdır.
    "DMS__EXPL_0009": findMessage.get("102564"),//Nesne görüntüleme yetkisi bulunamadı.
    "DMS__EXPL_0010": findMessage.get("102565"),//DmAccess yetkisi bulunamadı.
    "DMS__EXPL_0011": findMessage.get("102566"),//İçerik görüntüleme yetkisi bulunamadı.
    "DMS__EXPL_0012": findMessage.get("102567"),//Dosya görüntüleme yetkisi bulunamadı.    
    "DMS__EXPL_0013": findMessage.get("102568"),//Klasör görüntüleme yetkisi bulunamadı.
    "DMS__EXPL_0014": findMessage.get("102569"),//Yetki bulunamadı
    "DMS__FOLD_0001": findMessage.get("102570"),//Kısayol bulunamadı.
    "DMS__FOLD_0002": findMessage.get("102571"),//Kısayolun ilişkili olduğu nesne bulunamadı.
    "DMS__FOLD_0003": findMessage.get("102558"),//Metadata bilgileri hatalı.
    "DMS__FOLD_0004": findMessage.get("102559"),//Hedef klasör bulunamadı.
    "DMS__FOLD_0005": findMessage.get("102572"),//Nesnenin dil bilgileri bulunamadı.
    "DMS__OBJ__0001": findMessage.get("102570"),//Kısayol bulunamadı.
    "DMS__OBJ__0002": findMessage.get("102573"),//İçerik nesnesinin üst nesnesi bulunamadı.
    "DMS__OBJ__0003": findMessage.get("102573"),//İçerik nesnesinin üst nesnesi bulunamadı.
    "DMS__OBJ__0004": findMessage.get("102562"),//Kısayol bulunamadı.
    "DMS__OBJ__0005": findMessage.get("102574"),//Nesne tipi hatalı.
    "DMS__SCUT_0001": findMessage.get("102559"),//Hedef klasör bulunamadı.
    "DMS__SCUT_0003": findMessage.get("102744"),//Kısayol oluşturulamadı.
    "DMS__CONT_0001": findMessage.get("102562"),//Kısayol bulunamadı.
    "DMS__CONT_0002": findMessage.get("102575"),//İçerikler sadece dosyaların altında olabilir.
    "DMS__CONT_0003": findMessage.get("102576"),//Seçilen versiyon yanlış.
    "OOSW_WAPI_0001": findMessage.get("102552"),//Döküman yönetim sistemi genel hata.
    "OOSW_WAPI_0002": findMessage.get("102553"),//Token uyuşmuyor.
    "DMS__OBJ__0007": findMessage.get("102553"),//Token uyuşmuyor.
    "DMS__OBJ__0008": findMessage.get("102577"),//Branş bulunamadı.
    "DMS__OBJ__0009": findMessage.get("102578"),//Bu branş daha önceden tamamlanmış.
    "DMS__OBJ__0011": findMessage.get("102579"),//Tüm commitler upload edilmelidir.
    "DMS__OBJ__0012": findMessage.get("102580"),//İçerikte herhangi bir commit bulunmuyor.
    "DMS__OBJ__0013": findMessage.get("102581"),//İçerik bulunamadı.
    "DMS__OBJ__0014": findMessage.get("102582"),//İçerik blgisi bulunamadı.
    "DMS__OBJ__0015": findMessage.get("102582"),//İçerik blgisi bulunamadı.
    "DMS__OBJ__0213": findMessage.get("102745"),//Taşıma yetkiniz yoktur.
    "DMS__OBJ__0214": findMessage.get("102746"),//Kopyalama yetkiniz yoktur.
    "DMS__OBJ__0067": findMessage.get("101183"),//Öğeyi kendi içerisine taşıyamazsınız!
    "DMS__EXPL_0077": findMessage.get("102760"),//Seçilen kısayolun oluşturulduğu obje silinmiştir.
    "DMS__EXPL_0078": findMessage.get("102875"),//Dosya kendisiyle ilişkili olamaz.
    "AUTH_SERV_0004": findMessage.get("102588"),//Parola uygun değil.
    "AUTH_SERV_0005": findMessage.get("102585"),//Güncel parola yanlış.
    "AUTH_SERV_0006": findMessage.get("102586"),//Parola değiştirme yetkisi bulunmuyor.,
    "AUTH_SERV_0007": findMessage.get("102587"),//Kullanıcı bulunamadı.,
    "AUTH_SERV_0008": findMessage.get("102553"),//Token uyuşmuyor
    "AUTH_SERV_0043": findMessage.get("103027"),//Giriş yapmak istenen kullanıcı sistemde aktif değildir
    "AUTH_SERV_0082": findMessage.get("102619"),//Kullanıcının telefon numarası boş
    "AUTH_SERV_0083": findMessage.get("102620"),//Kullanıcı bulunamadı
    "AUTH_SERV_0084": findMessage.get("102621"),//Bu kullanıcı için MFA doğrulama yapılamaz.
    "AUTH_SERV_0085": findMessage.get("102622"),//Seçilen MFA yöntemi için konfigurasyon bilgisi bulunamadı
    "AUTH_SERV_0086": findMessage.get("102620"),//Kullanıcı bulunamadı
    "AUTH_SERV_0087": findMessage.get("102621"),//Bu kullanıcı için MFA doğrulama yapılamaz.
    "AUTH_SERV_0088": findMessage.get("102622"),//Seçilen MFA yöntemi için konfigurasyon bilgisi bulunamadı
    "AUTH_SERV_0089": findMessage.get("102623"),//Girilen pin kodu yanlış
    "AUTH_SERV_0090": findMessage.get("102624"),//Kullanıcı bilgisi bulunamadı.
    "AUTH_SERV_0091": findMessage.get("102621"),//Bu kullanıcı için MFA doğrulama yapılamaz.
    "AUTH_SERV_0092": findMessage.get("102626"),//TOTPMFA yöntemi için konfigurasyon bilgisi bulunamadı.
    "AUTH_SERV_0099": findMessage.get("102645"),//Şifreniz eski şifreniz ile aynı olamaz.
    "AUTH_SERV_0101": findMessage.get("102690"),//Çok faktörlü kimlik doğrulaması için düzenlenen kullanıcının etkin e-posta adresi boş. 
    "WEBI_SERV_0106": findMessage.get("103014"),//Bu aktiviteye cevap veremezsiniz.
    "WEBI_SERV_0107": findMessage.get("102906"),//Duyuru eklerken başlık alanı doldurulmalıdır.
    "WEBI_SERV_0108": findMessage.get("102907"),//Duyuru eklerken başlık 1024 karakteri geçemez.
    "WEBI_SERV_0109": findMessage.get("102908"),//Duyuru eklerken mesaj alanı doldurulmalıdır.
    "WEBI_SERV_0110": findMessage.get("102909"),//Duyuru eklerken başlangıç tarihi doldurulmalıdır.
    "WEBI_SERV_0111": findMessage.get("102910"),//Duyuru eklerken tarih alanı doldurulmalıdır.
    "WEBI_SERV_0112": findMessage.get("102911"),//Pasif vekalet düzenlenemez.
    "HRES_SERV_0057": findMessage.get("102691"),//Çok faktörlü kimlik doğrulaması güncellenmesi için etkin e-posta adresi boş. 
    "WEBI_SERV_0036": findMessage.get("102714"),//Kendinize vekalet veremezsiniz!
    "WEBI_SERV_0018": findMessage.get("102843"),//Bağlantının süresi doldu
    "WEBI_SERV_0017": findMessage.get("102844"),//Bağlantı sınırına ulaşıldı
    "WEBI_SERV_0019": findMessage.get("102845"),//Bağlantı bulunamadı
    "AUTZ_SERV_0013": findMessage.get("102761"),//"Yetki grubunu kendisine üye olarak ekleyemezsiniz" - You cant add self as a member
    "AUTZ_SERV_0062": findMessage.get("102762"),//"Yetki grubunun üyesi olarak kendisini ekleyemezsiniz" - You cant add self as a member of
    "DMS__BASE_0024": findMessage.get("102769"),//Klasör oluştururken metadata formu doldurulması gerekiyor. Klasörü taşıma işleminden önce oluşturup taşıma işlemini sonra gerçekleştirmelisiniz..
};
