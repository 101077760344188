import { isNullOrUndefined, ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseDmRelation {
    id?: number,
    name?: string,
    description?: string,
    originType?: number,
    isDelete?: boolean,
    deleteUserId?: string,
    deletionTime?: string,
    creatorUserId?: string,
    creationTime?: string,
    lastModificationTime?: string,
    lastModifierUserId?: string,
    type?: number,
    secretKey?: string,
}

export interface IDmRelation extends IBaseDmRelation {
}

export interface IReadonlyDmRelation extends IBaseDmRelation {

}

export type DmRelation = ReadonlyRecord<IReadonlyDmRelation>;

export const DmRelationFactory = Record<IReadonlyDmRelation>({
    id: null,
    name: '',
    description: '',
    isDelete: false,
    originType: null,
    deleteUserId: '',
    deletionTime: null,
    creatorUserId: '',
    creationTime: null,
    lastModificationTime: null,
    lastModifierUserId: '',
    type: null,
    secretKey: '',
});

export const DmRelationFactoryFromJS = function (data: IDmRelation) {
    if (isNullOrUndefined(data)) return DmRelationFactory();

    return DmRelationFactory({
        id: data.id,
        name: data.name,
        description: data.description,
        isDelete: data.isDelete,
        originType: data.originType,
        deleteUserId: data.deleteUserId,
        deletionTime: data.deletionTime,
        creatorUserId: data.creatorUserId,
        creationTime: data.creationTime,
        lastModificationTime: data.lastModificationTime,
        lastModifierUserId: data.lastModifierUserId,
        type: data.type,
        secretKey: data.secretKey,
    });
}
