import { put, takeEvery } from 'redux-saga/effects';
import { ServiceList } from '../../../services/serviceList';
import * as ActionTypes from '../actions/actionTypes';
import {setLocationAction} from '../actions';

export default function* watchGetAllLocations() {
    yield takeEvery(ActionTypes.GET_LOCATION_TRANSACTION, handleGetLocations);
}

export function* handleGetLocations() {
    try {
        let response = yield ServiceList.main.Configuration.GetLocationList.call();

        if (response) {
            
            yield put(setLocationAction(response));
        }
    } catch (error) {
        console.log(error);
    }
}
