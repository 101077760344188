export default function (file: any, onSucces: any) {
    var start = 0;
    var stop = file.size - 1;
    var reader = new FileReader();
    // If we use onloadend, we need to check the readyState.

    reader.onloadend = function (evt: any) {
        if (evt.target.readyState == (FileReader as any).DONE) { // DONE == 2
            if (onSucces) {
                onSucces(evt.target.result);
            }
        }
    };
    var blob = file.slice(start, stop + 1);
    reader.readAsText(blob, "UTF-8");

}
