import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from 'immutable'

interface IBaseFlowPropertiesItem{
    value?: any,
    originalValue?:any,
    name?: string,
    originalName?: string,
    fieldName?:string,
    displayFormat?:string,
    fieldType?:string,
    orderNo?:number
}

export interface IFlowPropertiesItem extends IBaseFlowPropertiesItem { }

export interface IReadonlyFlowPropertiesItem extends IBaseFlowPropertiesItem { }

export type FlowPropertiesItem = ReadonlyRecord<IReadonlyFlowPropertiesItem>;

export const FlowPropertiesItemFactory = Record<IReadonlyFlowPropertiesItem>({
    value:null,
    originalValue:null,
    name:"",
    originalName:"",
    fieldName:"",
    fieldType:"",
    displayFormat:"",
    orderNo:null
})

export const FlowPropertiesItemFactoryFromJS = function (data: IFlowPropertiesItem){
    return FlowPropertiesItemFactory(data);
}