import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';

interface IBaseSearchMember {
    name?: string,
    description?: string,
    type?: number,
    id?: number,
    secretKey?: string,
}

export interface ISearchMember extends IBaseSearchMember {

}

export interface IReadonlySearchMember extends IBaseSearchMember {

}

export type SearchMember = ReadonlyRecord<IReadonlySearchMember>;

export const SearchMemberFactory = Record<IReadonlySearchMember>({
    name: null,
    description: null,
    id: null,
    type: null,
    secretKey: null
});

export const SearchMemberFactoryFromJS = function (data: ISearchMember) {
    return SearchMemberFactory(data);
}