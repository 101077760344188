import { isImmutable, List } from "immutable";
/**
 * use as only with immutable.js
 * @param source güncellenicek kaynak
 * @param setData yeni data
 * @param comparator nesneleri eşleştirmek için
 */
export default function deepMerger(source: any, setData: any, comparator?: (sourceItem: any, newItem: any) => boolean, updateKeys?: Array<string>): any {
    if (source && setData) {
        let sourceType = getDataType(source);
        let setDataType = getDataType(setData);
        if (sourceType === setDataType) {
            if (sourceType === "list") {
                let newSource: List<any> = source;
                (setData as List<any>).forEach((item, listKey) => {
                    let listItemType = getDataType(item);
                    let indexOf = comparator ? source.findIndex((sourceItem: any) => { return comparator(sourceItem, item) }) : -1;
                    if (indexOf === -1) {
                        newSource = newSource.push(item);
                    }
                    else {
                        if (listItemType === "object" || "list") {
                            newSource.update(indexOf, (sourceItem) => {
                                return deepMerger(sourceItem, item, comparator)
                            });
                        }
                        else {//prmitive types
                            newSource = newSource.update(indexOf, () => item);
                        }
                    }
                });
                //clean old values
                //let indexOf = comparator ? (setData as List<any>).findIndex((sourceItem: any) => { return comparator(sourceItem, item) }) : -1;
                //if (indexof) {

                //}
                return newSource;
            }
            else if (sourceType === "object") {
                let newSource: any = source;
                let fieldList = Object.keys(newSource.toObject());
                fieldList.forEach((fieldKey) => {
                    if (getDataType(newSource.get(fieldKey)) != "primitive") {
                        newSource = newSource.set(fieldKey, deepMerger(newSource.get(fieldKey), setData.get(fieldKey), comparator));
                    }
                    else {
                        newSource = newSource.set(fieldKey, setData.get(fieldKey));
                    }
                });
                return newSource;
            }
            return setData;
        }
        else {
            console.warn("Type equal error");
            return source;
        }
    }
    return setData;
}
export function getDataType(data: any): "list" | "object" | "primitive" {
    if (List.isList(data)) {
        return "list"
    } else {
        if (isImmutable(data) && typeof data.toJS() === "object") {
            return "object"
        } else {
            return "primitive"
        }
    }
}

