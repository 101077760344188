import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { List, Map, Record } from 'immutable';
import { IMenuDesignerItem, MenuDesignerItem, MenuDesignerItemFactoryFromJS } from '../../../../common/entities/IMenuDesignerItem';
import { IProfile, Profile, ProfileFactoryFromJS } from './IProfile';

export interface IBaseDesignMenuStructure {
}

export interface IDesignMenuStructure extends IBaseDesignMenuStructure {
    nodes: IDictionary<IMenuDesignerItem>,
    profiles: IProfile[]
}

export interface IReadonlyDesignMenuStructure extends IBaseDesignMenuStructure {
    nodes: Map<string, MenuDesignerItem>,
    profiles: List<Profile>
}

export type DesignMenuStructure = ReadonlyRecord<IReadonlyDesignMenuStructure>;

export const DesignMenuStructureFactory = Record<IReadonlyDesignMenuStructure>({
    nodes: Map<string, MenuDesignerItem>(),
    profiles: List()
});

export const DesignMenuStructureFactoryFromJS = function (data: IDesignMenuStructure) {
    let profiles: Array<Profile> = data.profiles ? data.profiles.map(function (value) {
        return ProfileFactoryFromJS(value);
    }) : [];

    let nodes: IDictionary<MenuDesignerItem> = Object.keys(data.nodes).reduce(function (previous: IDictionary<MenuDesignerItem>, current) {
        previous[current] = MenuDesignerItemFactoryFromJS(data.nodes[current]);
        return previous;
    }, {});

    return DesignMenuStructureFactory({
        nodes: Map(nodes),
        profiles: List(profiles)
    });
}