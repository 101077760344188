import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IBaseFormContent, User, IPosition, IUser, Position, PositionFactory, PositionFactoryFromJS, UserFactoryFromJS } from "..";


export interface IPositionListFormContent extends IBaseFormContent {
    positions?: Array<IPosition>,
    selectedPosition?: IPosition,
    users?: Array<IUser>
}

export interface IReadonlyPositionListFormContent extends IBaseFormContent {
    positions?: List<Position>,
    selectedPosition?: Position,
    users?: List<User>
}

export type PositionListFormContent = ReadonlyRecord<IReadonlyPositionListFormContent>;

export const PositionListFormContentFactory = Record<IReadonlyPositionListFormContent>({
    panelId: "",
    positions: List<Position>(),
    users: List<User>(),
    selectedPosition: PositionFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: false,
    selectedCompanyId: 0,
});

export const PositionListFormContentFactoryFromJS = function (data: IPositionListFormContent) {
    let _positions: Array<Position> = data.positions && data.positions.length ? data.positions.map((position) => {
        return PositionFactoryFromJS(position);
    }) : [];

    let _users: Array<User> = data.users && data.users.length ? data.users.map((user) => {
        return UserFactoryFromJS(user);
    }) : [];

    return PositionListFormContentFactory({
        positions: List(_positions),
        users: List(_users),
        panelId: data.panelId,
        selectedPosition: PositionFactoryFromJS(data.selectedPosition),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectedCompanyId : data.selectedCompanyId,
    });
}