import { takeEvery, select, call } from 'redux-saga/effects';
import { ServiceList } from "../../../services/serviceList";
import { ILoginResponseWithMFA } from "../entities";
import { ActionTypes } from '../actions';
import commonLoginHandler from './commonLoginHandler';
import { getMFAState } from '../selectors/LoginUserSelector';
import { customErrorHandler } from '../../..';

export default function* watchValidationMFATransaction() {
    yield takeEvery(ActionTypes.VALIDATION_MFA, validationMFATransaction);
}

export function* validationMFATransaction(sagaAction: any) {
    try {
        const { tempTokenKey, currentLoginProcedure } = yield select(getMFAState);

        let request: any = {
            ...sagaAction.payload.toJS(),
            tempTokenKey
        };

        if (window.location.hash.includes("/OAuth")) {
            request.useAuthCode = true;
        }

        let response: ILoginResponseWithMFA = yield ServiceList.login.Login.ValidationMfa.handleError(customErrorHandler).call(request, true, null, true);

        if (response) {
            yield call(commonLoginHandler, request, response, currentLoginProcedure);
        }
    } catch (error) {
        console.log(error);
    }
}
