import { List, Map } from "immutable";
import { generateId } from ".";
import { LayoutDataItem, LayoutItem, LayoutStructureItemFactory, NormalizedLayoutStructureItems } from "../../../common/entities";

export const handleMoveGhostItemIntoRoot = (
    views: Map<string, NormalizedLayoutStructureItems>,
    data: Map<string, LayoutDataItem>,
    activeView: string,
    item: LayoutItem
) => {

    const rootId = views.getIn([activeView, "result", 0]);

    // Create new control
    const newControlId = item.id || generateId(views, data, item.type);
    let newControl = LayoutStructureItemFactory({
        id: newControlId,
        items: List(),
        type: item.type,
        parentId: rootId
    });

    views = views.setIn([activeView, 'entities', 'ghostItems', newControl.id], newControl);

    return views
};