import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import * as Styles from "../assets/styles.scss";
import ApprovesBoxContainer from '../containers/ApprovesBoxContainer';
import IStartupLayoutCProps from "../entities/IStartupLayoutCProps";
import IStartupLayoutProps from "../entities/IStartupLayoutProps";
import AnnouncementBoxContainer from '../containers/AnnouncementBoxContainer';
import LastVisitedDocumentsContainer from '../containers/LastVisitedDocumentsContainer';
import AppFormContainer from '../../../common/components/appForm';
import { BCLoading } from '@bimser/components';
import $ from 'jquery';
import classNames from 'classnames/bind';

const cx = classNames.bind(Styles);

export default (props: IStartupLayoutProps & IStartupLayoutCProps) => {

    React.useEffect(() => {
        if (props.hasDashboard) {
            $("[class*='contentWith']").has(".hasDashboard").css("padding", "0");
        } else {
            $("[class*='contentWith']").css("padding", "0 8%");
        }
    }, [props.hasDashboard]);

    React.useEffect(() => {
        if (props.isPanelActive && !props.hasDashboard) {
            refreshStartupData();
        }
    }, [props.isPanelActive, props.hasDashboard])

    const refreshStartupData = React.useCallback(() => {
        props.refreshData({
            panelId: props.panelGuid
        });
    }, [props.panelGuid]);

    const renderForm = React.useMemo(() => {
        if (!props.dashboardFormData)
            return <></>;

        return (
            <AppFormContainer
                childFormData={{ ...props.dashboardFormData, loading: false, childFormDataLoaded: true }}
                panelGuid={props.panelGuid + "_form"}
                isPanelActive
                windowType={"panel"}
                hideFormToolbar
            />
        )
    }, [props.dashboardFormData])

    const renderStartupBoxes = React.useMemo(() => {
        return (
            <div className={Styles.DashboardContainer}>
                <div className={Styles.FullSizeBox}>
                    {
                        !props.viewHideInfo.hideApprovals && <ApprovesBoxContainer startupPanelId={props.panelGuid} />
                    }
                </div>
                <div className={Styles.FullSizeBox}>
                    <div className={cx({ HalfSizeBox: true, LastVisitedDocuments: true })}>
                        {
                            !props.viewHideInfo.hideLastVisitedDocuments && <LastVisitedDocumentsContainer startupPanelId={props.panelGuid} />
                        }
                    </div>
                    <div className={cx({ HalfSizeBox: true, AnnouncementBox: true })}>
                        {
                            !props.viewHideInfo.hideAnnouncements && <AnnouncementBoxContainer startupPanelId={props.panelGuid} />
                        }
                    </div>
                </div>
            </div>
        )
    }, [])

    return (
        props.hasDashboard ? (
            <BCLoading show={!props.dashboardFormData} overlayClassName={"hasDashboard"}>
                {renderForm}
            </BCLoading>
        ) : renderStartupBoxes
    )
}