import Radio from 'antd/lib/radio';
import * as React from "react";
import * as Styles from "./assets/radioStyles.scss";
import { IRadioButtonProps, IRadioButtonSelectedChangeEventArgs } from './entities';

Styles;

const BCRadioButton: React.FC<IRadioButtonProps> = props => {
    const selectedChanged = e => props.onSelectedChange?.({ senderArgs: e, data: e.target.checked });

    return (
        <Radio
            tabIndex={props.tabIndex}
            name={props.key}
            className={props.className}
            style={props.style}
            checked={props.selected}
            value={props.value}
            onChange={selectedChanged}
        >
            {props.text}
        </Radio>
    )
}


export { IRadioButtonProps, IRadioButtonSelectedChangeEventArgs };
export default BCRadioButton;
