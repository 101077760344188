import { EDeviceTypes, FormItemTypes, isNullOrUndefined } from "@bimser/core";
import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { ActionMenu } from "./ActionMenu";
import * as Styles from "./assets/styles.scss";
import Component from "./Component";
import DropZone from "./DropZone";
import { IColumn } from "./entities";
import { ELayoutItemTypes } from "./entities/Constants";
import { intersectRect } from "./helpers";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const Column = (args: IColumn) => {
  const { data, handleDrop, path, getItem, onStructureChange, setContextMenuItems, onItemSelectionChanged, isParentLocked, setWillDragStart, willDragStart, selectionBounds, controlRenderManager, deviceType, parentItemType } = args;
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ELayoutItemTypes.COLUMN,
      children: data.items,
      path,
      data
    },
    canDrag: !data.designerProps.locked && !isParentLocked,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(ref);

  const renderComponent = (component, currentPath) => {
    return (
      <Component
        key={component.id}
        data={component}
        path={currentPath}
        onStructureChange={onStructureChange}
        setContextMenuItems={setContextMenuItems}
        onItemSelectionChanged={onItemSelectionChanged}
        isParentLocked={data.designerProps.locked || isParentLocked}
        setWillDragStart={setWillDragStart}
        willDragStart={willDragStart}
        selectionBounds={selectionBounds}
        controlRenderManager={controlRenderManager}
        deviceType={deviceType}
        parentItemType={parentItemType}
      />
    );
  };

  const handleContextMenuClick = (e: React.MouseEvent) => {
    setContextMenuItems(e, data, path);
    e.stopPropagation();
  }

  const { layout, selected, locked } = data.designerProps;

  const columnClassNames: string = cx({
    base: true,
    draggable: true,
    column: true,
    isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup,
    selected,
    locked: locked || isParentLocked,
    isDragging,
    hidden: !(layout.get("visible") && layout.get("clientVisible"))
  });

  const onMouseDown = (e) => {
    const containerElem = document.getElementById(data.id);
    const insideActionBtn = containerElem?.contains(e.target);
    setWillDragStart(!insideActionBtn);
  };
  const onEnd = () => setWillDragStart(false);

  const onColumnSelect = (e) => onItemSelectionChanged(e, data);

  useEffect(() => {
    if (selectionBounds) {
      let _selected = ref?.current ? intersectRect(selectionBounds, ref.current.getBoundingClientRect()) : false;
      onItemSelectionChanged(null, data, true, _selected);
    }
  });

  const width = data.designerProps.layout.get("width");
  const noWidth = isNullOrUndefined(width) || width === "";
  const columnLayoutStyles: React.CSSProperties = noWidth ? {} : {
    flex: "0 0 " + width
  };

  return (
    <div
      ref={ref}
      className={columnClassNames}
      onMouseDown={onMouseDown}
      onDragEnd={onEnd}
      onMouseUp={onEnd}
      onContextMenu={handleContextMenuClick}
      onClick={onColumnSelect}
      style={columnLayoutStyles}
    >
      {!willDragStart && parentItemType !== FormItemTypes.EControlTypes.InputGroup && <ActionMenu data={data} onStructureChange={onStructureChange} path={path} />}
      {data.items.map((componentId, index) => {
        const component = getItem(componentId as string);
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={componentId as string}>
            {
              parentItemType !== FormItemTypes.EControlTypes.InputGroup &&
              <DropZone
                path={currentPath}
                childrenCount={data.items.count()}
                onDrop={handleDrop}
                dropType={ELayoutItemTypes.COMPONENT}
                parent={data}
                disableMagnet={deviceType === EDeviceTypes.ExtraSmall}
              />
            }

            {renderComponent(component, currentPath)}
          </React.Fragment>
        );
      })}
      {
        (parentItemType !== FormItemTypes.EControlTypes.InputGroup || !data.items.count()) &&
        <DropZone
          path={`${path}-${data.items.count()}`}
          childrenCount={data.items.count()}
          onDrop={handleDrop}
          dropType={ELayoutItemTypes.COMPONENT}
          parent={data}
          disableMagnet={deviceType === EDeviceTypes.ExtraSmall}
          parentItemType={parentItemType}
          isLast
        />
      }
    </div>
  );
};
export default React.memo(Column);
