import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from ".";
import { Group, GroupFactory, GroupFactoryFromJS, IGroup } from "..";

export interface IUserGroupListFormContent extends IBaseFormContent {
    selectedUserGroup?: IGroup
}

export interface IReadonlyUserGroupListFormContent extends IBaseFormContent {
    selectedUserGroup?: Group
}

export type UserGroupListFormContent = ReadonlyRecord<IReadonlyUserGroupListFormContent>;

export const UserGroupListFormContentFactory = Record<IReadonlyUserGroupListFormContent>({
    panelId: "",
    selectedUserGroup: GroupFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: false,
    selectedCompanyId: 0,
});

export const UserGroupListFormContentFactoryFromJS = function (data: IUserGroupListFormContent) {
    return UserGroupListFormContentFactory({
        panelId: data.panelId,
        selectedUserGroup: GroupFactoryFromJS(data.selectedUserGroup),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectedCompanyId : data.selectedCompanyId,
    });
}