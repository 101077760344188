import React, { lazy, Suspense } from 'react';
import { IActivitiesProps } from './entities';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-activities" */ "./containers"));

function ActivitiesContainer(props: React.PropsWithChildren<IActivitiesProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
ActivitiesContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-activities" */ "./injector");
}

export default ActivitiesContainer;

export { default as OpenActivitiesContainer } from './containers/OpenActivitiesContainer';