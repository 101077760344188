import { createSelector ,OutputSelector} from "reselect";
import { BaseState,IReadonlyBaseState } from "../../../entities/BaseState";
import { CultureFactory, CultureState, ICulture,IReadonlyCultureState } from "../entities";
import {Record} from 'immutable'

const cultureParameters = (state: BaseState) => state.user.data.culture;
const cultures = (state: BaseState) => state.system.culture;

const getUserCulture = createSelector(
    [
        cultureParameters, cultures
    ],
    (data: string, culture: CultureState) => {
        let index = culture.items.findIndex(q => q.name === data);
        let Culture: ICulture = index > -1 ? culture.items.get(index).toJS() : CultureFactory().toJS();
        return Culture;
    }
);

export { getUserCulture };

