import Progress from 'antd/lib/progress';
import * as React from "react";
import { IProgressGapPosition, IProgressProps, IProgressStatus, IProgressStrokeLinecap, IProgressType } from './entities';

const BCProgress: React.FC<IProgressProps> = React.memo(props =>
    <Progress
        type={props.type}
        percent={props.percent}
        showInfo={props.showInfo}
        status={props.status}
        width={props.width}
        success={{ percent: props.successPercent }}
        strokeLinecap={props.strokeLinecap}
        strokeColor={props.strokeColor}
        strokeWidth={props.strokeWidth}
        gapDegree={props.gapDegree}
        gapPosition={props.gapPosition}
        format={props.format}
    />
);

export { IProgressProps, IProgressType, IProgressStatus, IProgressGapPosition, IProgressStrokeLinecap };
export default BCProgress;