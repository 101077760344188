import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface ILogviewerDetailPanelState {
    panelId: string,
    showLoading?: boolean,
    selectedLog: string
}

export type LogviewerDetailPanelState = ReadonlyRecord<ILogviewerDetailPanelState>;

export const LogviewerDetailPanelStateFactory = Record({
    panelId: null,
    showLoading: true,
    selectedLog: ""
});

export const LogviewerDetailPanelStateFactoryFromJS = function (data: ILogviewerDetailPanelState) {
    return LogviewerDetailPanelStateFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedLog: data.selectedLog
    });
};
