import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { List, Map } from 'immutable';
import { IDictionary } from '@bimser/core';
import IProcessStructure from '../entities/widgets/model/types/IProcessStructure';

const getProcessDetailDataSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processDetails.data;
}

const getProcessDetailsTotalCountSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processDetails.totalCount;
}

const getLoadingProcessDetailsSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processDetails.loading;
}

const getProcessDetailsStructureSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processDetails.structure;
}

export const getProcessDetailData = createSelector(
    [
        getProcessDetailDataSelector
    ],
    (processDetails: List<Map<string, any>>) : Array<IDictionary<any>> => {
        return processDetails?.map(process => process.toJS()).toJS() || [];
    }
);

export const getProcessDetailsStructure = createSelector(
    [
        getProcessDetailsStructureSelector
    ],
    (structure: List<IProcessStructure>) : Array<IProcessStructure> => {
        return structure?.toJS() || [];
    }
);

export const getProcessDetailsTotalCount = createSelector(
    [
        getProcessDetailsTotalCountSelector
    ],
    (counts: number) : number => {
        return counts;
    }
);

export const getLoadingProcessDetails = createSelector(
    [
        getLoadingProcessDetailsSelector
    ],
    (loading: boolean) : boolean => loading
);