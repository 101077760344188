import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";

interface IBaseProfileFormFolder {
    isFormsComplete: boolean;
    hasValidation: boolean;
}

export interface IProfileFormFolder extends IBaseProfileFormFolder {
    folderName: IDictionary<string>;
    folderDescription: IDictionary<string>;
}

export interface IReadonlyProfileFormFolder extends IBaseProfileFormFolder {
    folderName: Map<string, string>;
    folderDescription: Map<string, string>;
}

export type ProfileFormFolder = ReadonlyRecord<IReadonlyProfileFormFolder>;

export const ProfileFormFolderFactory = Record<IReadonlyProfileFormFolder>({
    folderName: null,
    folderDescription: null,
    isFormsComplete: false,
    hasValidation: false,
});

export const ProfileFormFolderFactoryFromJS = function (data: IProfileFormFolder) {
    return ProfileFormFolderFactory({
        ...data,
        folderName: Map(data.folderName),
        folderDescription: Map(data.folderDescription)
    });
}