import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { Language, ILanguage, LanguageFactoryFromJS } from "./ILanguage";

export interface IBaseLanguageState {
    canChangeLanguage: boolean;
}

export interface ILanguageState extends IBaseLanguageState {
    items: Array<ILanguage>
}

export interface IReadonlyLanguageState extends IBaseLanguageState {
    items: List<Language>
}

export type LanguageState = ReadonlyRecord<IReadonlyLanguageState>;

export const LanguageStateFactory = Record<IReadonlyLanguageState>({
    items: List<Language>(),
    canChangeLanguage: true
});

export const LanguageStateFactoryFromJS = function (data: ILanguageState) {
    let _languages: Array<Language> = data.items && data.items.length ? data.items.map((value: ILanguage) => {
        return LanguageFactoryFromJS(value);
    }) : [];

    return LanguageStateFactory({
        items: List(_languages),
        canChangeLanguage: data.canChangeLanguage
    });
}