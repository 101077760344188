import { connect } from 'react-redux';
import { closeModalTransactionAction } from '@bimser/common';
import { updateGroupAction } from '../actions';
import { IWebInterfaceAction } from '../../../common/entities';
import CreateGroup from '../components/Contents/CreateGroup';
import { WebInterfaceBaseState } from '../../../common/base/state';

const mapStateToProps = function (state: WebInterfaceBaseState, props: any): any {
    return {
        type: props.type,
        permissionGroup: props.permissionGroup
    }
}

const mapDispatchToProps = function (dispatch: Function): any {
    return {
        onCloseModal: function (id: string) {
            dispatch(closeModalTransactionAction(id));
        },
        updateGroupAction: function (data: IWebInterfaceAction) {
            dispatch(updateGroupAction(data));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);

