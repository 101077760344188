import * as React from "react";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';
import { ColorPickerProperty, MarginProperty, SelectProperty } from '../propertyItems';

const borderTypes = [
    { key: 'none', value: 'none', text: 'none' },
    { key: 'dotted', value: 'dotted', text: 'dotted' },
    { key: 'dashed', value: 'dashed', text: 'dashed' },
    { key: 'solid', value: 'solid', text: 'solid' },
    { key: 'double', value: 'double', text: 'double' }
];

const marginContainerStyle: React.CSSProperties = { width: 'calc(50% - 22px - 10px)', minWidth: '50px', float: 'left', margin: '0 10px 5px 0' };
const colorPropertyContainerStyle: React.CSSProperties = { width: '44px', float: 'left', margin: '0 0 5px 0' };

export default function BorderProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const changeMarginValue = (value: Array<number | string>) => {
        let valueInstance = props.value;
        valueInstance.thickness = value;
        replaceValue(props.item, valueInstance)
    }

    const changeBorderStyle = (value: any) => {
        let valueInstance = props.value;
        valueInstance.type = value;
        replaceValue(props.item, valueInstance)
    }

    const changeBorderColor = (value: any) => {
        let valueInstance = props.value;
        valueInstance.color = value;
        replaceValue(props.item, valueInstance)
    }

    if (props.value) {
        return (
            <React.Fragment>
                <div style={marginContainerStyle}>
                    <MarginProperty
                        item={props.item}
                        customChangeEvent={changeMarginValue}
                        value={props.value.thickness}
                    />
                </div>
                <div style={marginContainerStyle}>
                    <SelectProperty
                        item={Object.assign({}, props.item, { options: borderTypes })}
                        customChangeEvent={changeBorderStyle}
                        value={props.value.type}
                    />
                </div>
                <div style={colorPropertyContainerStyle}>
                    <ColorPickerProperty
                        item={props.item}
                        customChangeEvent={changeBorderColor}
                        value={props.value.color}
                    />
                </div>
            </React.Fragment>
        )
    } else {
        return null;
    }
}