import { List, Map } from "immutable";
import { reorderChildren } from ".";
import { LayoutItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const handleMoveWithinParent = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    splitDropZonePath: List<string>,
    splitItemPath: List<string>,
    item: LayoutItem
) => {
    return reorderChildren(views, activeView, splitDropZonePath, splitItemPath, item);
};