import { ReadonlyRecord } from "@bimser/core";
import { Record } from 'immutable';
import { Filter, FilterFactoryFromJS, IFilter } from './IFilter';
import { INavigationHistoryItem, NavigationHistoryItem, NavigationHistoryItemFactoryFromJS } from './INavigationHistoryItem';
import { IPagination, Pagination, PaginationFactory, PaginationFactoryFromJS } from './IPagination';


interface IBaseLoadPayload {
    isReload: boolean,
    pageTotal: number,
}

export interface ILoadPayload extends IBaseLoadPayload {
    data: INavigationHistoryItem,
    filter: IFilter,
    pagination: IPagination
}

export interface IReadonlyLoadPayload extends IBaseLoadPayload {
    data: NavigationHistoryItem,
    filter: Filter,
    pagination: Pagination
}

export type LoadPayload = ReadonlyRecord<IReadonlyLoadPayload>;

export const LoadPayloadFactory = Record<IReadonlyLoadPayload>({
    isReload: false,
    pageTotal: -1,
    data: null,
    filter: null,
    pagination: PaginationFactory(),
});

export const LoadPayloadFactoryFromJS = function (data: ILoadPayload) {
    return LoadPayloadFactory({
        isReload: data.isReload,
        pageTotal: data.pageTotal,
        data: NavigationHistoryItemFactoryFromJS(data.data),
        filter: FilterFactoryFromJS(data.filter),
        pagination: PaginationFactoryFromJS(data.pagination),
    });
}