let _class2type: any = {};
let hasOwn = _class2type.hasOwnProperty;
let fnToString = hasOwn.toString;

let _type = function (obj: any) {
    return obj == null ?
        String(obj) :
        _class2type[toString.call(obj)] || "object";
};

let _isWindow = function (obj: any) {
    return obj != null && obj == obj.window;
};

let _isFunction = function (target: any) {
    return toString.call(target) === "[object Function]";
};

let _isArray = Array.isArray || function (obj) {
    return _type(obj) === "array";
};

let _isPlainObject = function (obj: any) {
    // Must be an Object.
    // Because of IE, we also have to check the presence of the constructor property.
    // Make sure that DOM nodes and window objects don't pass through, as well
    if (!obj || _type(obj) !== "object" || obj.nodeType || _isWindow(obj)) {
        return false;
    }

    try {
        // Not own constructor property must be Object
        if (obj.constructor &&
            !hasOwn.call(obj, "constructor") &&
            !hasOwn.call(obj.constructor.prototype, "isPrototypeOf")) {
            return false;
        }
    } catch (e) {
        // IE8,9 Will throw exceptions on certain host objects #9897
        return false;
    }

    // Own properties are enumerated firstly, so to speed up,
    // if last one is own, then all properties are own.

    let key;

    return key === undefined || hasOwn.call(obj, key);
};

/**
 * Merge two objects (deepCopy, doNotMergeArrays, object, object...)
 */
export default function extend(...args: any[]) {
    let options, name, src, copy, copyIsArray, clone,
        doNotMergeArrays = false,
        target = arguments[0] || {},
        i = 1,
        length = arguments.length,
        deep = false;

    // Handle a deep copy situation
    if (typeof target === "boolean") {
        deep = target;
        target = arguments[1] || {};
        // skip the boolean and the target
        i = 2;
    }

    if (typeof arguments[1] === "boolean") {
        doNotMergeArrays = arguments[1];
        target = arguments[2] || {};
        // skip the boolean and the target
        i = 3;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !_isFunction(target)) {
        target = {};
    }

    if (length === i) {
        target = this;
        --i;
    }

    for (; i < length; i++) {
        // Only deal with non-null/undefined values
        if ((options = arguments[i]) != null) {
            // Extend the base object
            if (Object.keys(options).length > 0) {
                for (name in options) {
                    src = target[name];
                    copy = options[name];

                    // Prevent never-ending loop
                    if (target === copy) {
                        continue;
                    }

                    // Recurse if we're merging plain objects or arrays
                    if (deep && copy && (_isPlainObject(copy) || (copyIsArray = _isArray(copy)))) {
                        if (copyIsArray) {
                            copyIsArray = false;
                            if (doNotMergeArrays) {
                                clone = copy;
                            } else {
                                clone = src && _isArray(src) ? src : [];
                            }

                        } else {
                            clone = src && _isPlainObject(src) ? src : {};
                        }

                        // Never move original objects, clone them
                        target[name] = extend(deep, clone, copy);

                        // Don't bring in undefined values
                    } else if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }
            else if (typeof options === "object") {
                target = options;
            }

        }
    }
    // Return the modified object
    return target;
}