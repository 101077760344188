import { connect } from "react-redux";
import CompanyForm from "../components";
import { closeHRFormTransaction, saveHRFormTransaction } from "../../../../actions"
import ICompanyFormProps from "../entities/IProps";
import { WebInterfaceBaseState } from "../../../../../../common/base/state";
import { IWebInterfaceAction } from "../../../../../../common/entities";

const mapStateToProps = function (state: WebInterfaceBaseState, props: ICompanyFormProps): ICompanyFormProps {
    return {
    };
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): ICompanyFormProps {
    return {
        onClose: function(data: IWebInterfaceAction) {
            dispatch(closeHRFormTransaction(data));
        },
        onSave: function(data: IWebInterfaceAction) {
            dispatch(saveHRFormTransaction(data));
        }
    };
}

// react - redux connection
const CompanyFormContainer = connect(mapStateToProps, mapDispatchToProps)(CompanyForm);

export default CompanyFormContainer;