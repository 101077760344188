import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';

const getAnnouncementBoxLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.startup.announcementBoxShowLoading;
}

const getAnnouncementBoxLoading = createSelector(
    [
        getAnnouncementBoxLoadingSelector
    ],
    (loading: boolean) => {
        return loading;
    }
);

export { getAnnouncementBoxLoading };
