export const ADD_NEW_VIEWER_FILE_TRANSACTION = "DMVIEWER.ADD_NEW_VIEWER_FILE_TRANSACTION";
export const ADD_NEW_VIEWER_FILE_AND_LOAD_TRANSACTION = "DMVIEWER.ADD_NEW_VIEWER_FILE_AND_LOAD_TRANSACTION";
export const UPDATE_SHOWING_FILE_ACTION = "DMVIEWER.UPDATE_SHOWING_FILE_ACTION";
export const ADD_NEW_VIEWER_FILE_ACTION = "DMVIEWER.ADD_NEW_VIEWER_FILE_ACTION";
export const REMOVE_VIEWER_FILE_ACTION = "DMVIEWER.REMOVE_VIEWER_FILE_ACTION";
export const ON_CHANGE_VIEWER_FILE_DATA_ACTION = "DMVIEWER.ON_CHANGE_VIEWER_FILE_DATA_ACTION";
export const ON_CHANGE_VIEWER_DATA_ACTION = "DMVIEWER.ON_CHANGE_VIEWER_DATA_ACTION";
export const SET_LOADING_ACTION = "DMVIEWER.SET_LOADING_ACTION";
export const SET_SKELETON_TYPE_ACTION = "DMVIEWER.SET_SKELETON_TYPE_ACTION";
export const REMOVE_INSTANCE_ACTION = "DMVIEWER.REMOVE_INSTANCE_ACTION";
export const CREATE_NEW_INSTANCE_ACTION = "DMVIEWER.CREATE_NEW_INSTANCE_ACTION";
export const ON_CHANGE_USER_SELECTED_VIEWER_TYPE_ACTION = "DMVIEWER.ON_CHANGE_USER_SELECTED_VIEWER_TYPE_ACTION";
export const SET_VIEWERS_CONFIG_ACTION = "DMVIEWER.SET_VIEWERS_CONFIG_ACTION";