import {
    IProperty, IPropertyValue, Property, PropertyValue, PropertyFactoryFromJS, PropertyValueFactoryFromJS, IPropertyType,
    PropertyType, PropertyTypeFactoryFromJS
} from ".";
import { List, Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";


export interface IParameters {
    properties?: Array<IProperty>
    propertyValues?: Array<IPropertyValue>,
    propertyTypes?: Array<IPropertyType>
}

export interface IReadonlyParameters {
    properties?: List<Property>,
    propertyValues?: List<PropertyValue>,
    propertyTypes?: List<PropertyType>
}

export type Parameters = ReadonlyRecord<IReadonlyParameters>;

export const ParametersFactory = Record<IReadonlyParameters>({
    properties: List<Property>(),
    propertyValues: List<PropertyValue>(),
    propertyTypes: List<PropertyType>()
});

export const ParametersFactoryFromJS = function (data: IParameters) {
    let _properties: Array<Property> = data.properties && data.properties.length ? data.properties.map((property) => {
        return PropertyFactoryFromJS(property);
    }) : [];

    let _propertyValues: Array<PropertyValue> = data.propertyValues && data.propertyValues.length ? data.propertyValues.map((propertyValue) => {
        return PropertyValueFactoryFromJS(propertyValue);
    }) : [];

    let _propertyTypes: Array<PropertyType> = data.propertyTypes && data.propertyTypes.length ? data.propertyTypes.map((propertyType) => {
        return PropertyTypeFactoryFromJS(propertyType);
    }) : [];

    return ParametersFactory({
        properties: List(_properties),
        propertyValues: List(_propertyValues),
        propertyTypes: List(_propertyTypes)
    });
}