import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { OSID } from "../enums";

export interface IBaseReplacement {
    id: number,
    osid: OSID,
    oldId: number,
    replaceId: number,
    oldCode: string,
    oldName: string,
    replaceCode: string,
    replaceName: string,
}

export interface IReplacement extends IBaseReplacement { }

export interface IReadonlyReplacement extends IBaseReplacement { }

export type Replacement = ReadonlyRecord<IReadonlyReplacement>;

export const ReplacementFactory = Record<IReadonlyReplacement>({
    id: 0,
    osid: OSID.Null,
    oldId: 0,
    replaceId: 0,
    oldCode: "",
    oldName: "",
    replaceCode: "",
    replaceName: ""
});

export const ReplacementFactoryFromJS = function (replacement: IReplacement) {
    return ReplacementFactory(replacement);
}