import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { DmObjectType } from './DmObjectType';
import { DmObjectProperties, DmObjectPropertiesFactory, DmObjectPropertiesFactoryFromJS, IDmObjectProperties } from './IDmObjectProperties';
import { IDmDataPermissions, DmDataPermissions, DmDataPermissionsFactory, DmDataPermissionsFactoryFromJS } from './IDmDataPermissions';
import { IUserInfo, UserInfo, UserInfoFactoryFromJS } from './IUserInfo';

interface IReadonlyContextLoaded {
    permissions: boolean,
    properties: boolean,
    parentTrees: boolean
}

type ContextLoaded = ReadonlyRecord<IReadonlyContextLoaded>

const ContextLoadedFactory = Record<IReadonlyContextLoaded>({
    permissions: false,
    properties: false,
    parentTrees: false
})

export interface IBaseDmData {
    id: string,
    path?: string,
    name?: string,
    type?: DmObjectType,
    originType?: DmObjectType,
    secretKey?: string,
    contextLoaded?: ContextLoaded,
    childsFetched?: boolean,
    version?: string,
    versionId?: number,
    versionSecretKey?: string,
    recentCreatedAt?: string,
    existThumbnail?: boolean,
    category?: string
}

export interface IDmData extends IBaseDmData {
    children?: Array<string>,
    permissions?: IDmDataPermissions,
    properties?: IDmObjectProperties,
    metadata?: IDictionary<any>,
    parents?: Array<IDmData>,
    createdBy?: IUserInfo,
    modifiedBy?: IUserInfo,
    deletedBy?: IUserInfo,
    tags?: Array<string>
}

export interface IReadonlyDmData extends IBaseDmData {
    children?: List<string>,
    permissions?: DmDataPermissions,
    properties?: DmObjectProperties,
    metadata?: Map<string, any>,
    parents?: List<DmData>,
    createdBy?: UserInfo,
    modifiedBy?: UserInfo,
    deletedBy?: UserInfo,
    tags?: List<string>
}

export type DmData = ReadonlyRecord<IReadonlyDmData>;

export const DmDataFactory = Record<IReadonlyDmData>({
    id: "",
    secretKey: "",
    name: "",
    parents: null,
    path: "",
    permissions: DmDataPermissionsFactory(),
    properties: DmObjectPropertiesFactory(),
    type: null,
    originType: null,
    createdBy: null,
    modifiedBy: null,
    deletedBy: null,
    //fromeBA6: false,
    metadata: Map(),
    children: List<string>(),
    contextLoaded: ContextLoadedFactory(),
    childsFetched: false,
    version: null,
    versionId: null,
    versionSecretKey: null,
    recentCreatedAt: null,
    existThumbnail: false,
    tags: List(),
    category: ""
});

export const DmDataFactoryFromJS = function (data: IDmData) {
    let _parents: DmData[] = data.parents ? data.parents.map(d => DmDataFactoryFromJS(d)) : [];

    return DmDataFactory({
        id: data.id,
        secretKey: data.secretKey,
        name: data.name,
        parents: List(_parents),
        path: data.path,
        permissions: DmDataPermissionsFactoryFromJS(data.permissions),
        properties: DmObjectPropertiesFactoryFromJS(data.properties),
        type: data.type,
        createdBy: UserInfoFactoryFromJS(data.createdBy),
        modifiedBy: UserInfoFactoryFromJS(data.modifiedBy),
        deletedBy: UserInfoFactoryFromJS(data.deletedBy),
        metadata: Map(data.metadata),
        children: List<string>(data.children),
        contextLoaded: ContextLoadedFactory(data.contextLoaded),
        childsFetched: data.childsFetched,
        recentCreatedAt: data.recentCreatedAt,
        existThumbnail: data.existThumbnail,
        originType: data.originType,
        version: data.version,
        versionId: data.versionId,
        versionSecretKey: data.versionSecretKey,
        tags: List(data.tags),
        category: data.category
    });
}