import React, { lazy, Suspense } from 'react';
import IFlowViewerProps from "./entities/IFlowViewerProps"
import { injectSaga } from "app-init"

const FlowViewerContainerLazy = lazy(() => import(/* webpackChunkName: "web-workflow" */ "./containers"));

function FlowViewerContainer(props: React.PropsWithChildren<IFlowViewerProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <FlowViewerContainerLazy {...props} />
        </Suspense>
    );
}
FlowViewerContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-workflow" */ "./sagas").then(({ default: saga }) => {
        injectSaga("flowViewer", saga);
    })
}

export default FlowViewerContainer;