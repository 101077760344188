import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { MessageBoxIcon, MessageBoxButtons } from "../../../enums";
import { IMessageBoxButtonPayload, IMessageBoxResolvePayload, IMessageBoxButton } from ".";

export interface IBaseOpenMessageBoxPayload {
    id: string,
    title: string,
    content: any,
    width?: string,
    type: MessageBoxIcon,
    buttons?: MessageBoxButtons | Array<IMessageBoxButton>,
    onReturn: (promise: Promise<IMessageBoxResolvePayload>) => void,
    props?: any,
    errorDetail?: string,
    showErrorDetail?: boolean,
    notInCloseIcon?: boolean,
    errorCode?: string,
    error?: any
}

export interface IOpenMessageBoxPayload extends IBaseOpenMessageBoxPayload {
}

export interface IReadonlyOpenMessageBoxPayload extends IBaseOpenMessageBoxPayload {
}

export type OpenMessageBoxPayload = ReadonlyRecord<IReadonlyOpenMessageBoxPayload>;

export const OpenMessageBoxPayloadFactory = Record<IReadonlyOpenMessageBoxPayload>({
    buttons: undefined,
    type: MessageBoxIcon.None,
    content: null,
    title: null,
    onReturn: null,
    id: null,
    props: null,
    width: null,
    errorDetail: "",
    showErrorDetail: false,
    notInCloseIcon: null,
    errorCode: "",
    error: null
});

export const OpenMessageBoxPayloadFactoryFromJS = function (data: IOpenMessageBoxPayload) {
    return OpenMessageBoxPayloadFactory(data);
}