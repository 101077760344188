import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { IWebInterfaceAction } from "../../../../common/entities";
import Processes from "../../components/widgets/Processes";
import IProcessesProps from "../../entities/widgets/IProcessesProps";
import { getLoadingProcesses, getProcessData, getProcessTotalCount, getSelectedProcess } from "../../selectors/ProcessesSelectors";
import { onClickProcessTransaction, onLoadProcessesTransaction } from "../../actions";
import { getSelectedTile, getTilesCounts } from "../../selectors/CountTileSelectors";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IProcessesProps): IProcessesProps {
    return {
        data: getProcessData(state),
        totalCount: getProcessTotalCount(state),
        selectedTile: getSelectedTile(state),
        selectedData: getSelectedProcess(state),
        tileCounts: getTilesCounts(state),
        loading: getLoadingProcesses(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IProcessesProps {
    return {
        onClickProcess: function(data: IWebInterfaceAction) {
            dispatch(onClickProcessTransaction(data));
        },
        onLoadMore: function(data: IWebInterfaceAction) {
            dispatch(onLoadProcessesTransaction(data));
        },
    };
}

// react - redux connection
const ProcessesContainer = connect(mapStateToProps, mapDispatchToProps)(Processes);

export default ProcessesContainer;