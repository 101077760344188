import { getTimeZoneData, isNullOrUndefined } from "@bimser/core";
import * as React from "react";
import BCCombobox, { IComboboxSelectedChangeEventArgs } from '../../../BCCombobox';
import { IPropertyComponentProps, IPropertyItem, ISelectPropertyProps } from '../../entities';
var moment = require('moment-timezone');
const inputStyle: React.CSSProperties = { width: '100%' };

const timeZones = getTimeZoneData();
const localArea = timeZones.filter(t => t.displayName.indexOf(moment().tz(moment.tz.guess()).format("z")) != -1);
const keyWords = moment.tz.guess().split('/').reverse();
let localTimeZone = keyWords.map((t: string) => { return localArea.find(c => c.displayName.indexOf(t) != -1 || c.id.indexOf(t) != -1) }).filter((t: any) => !isNullOrUndefined(t));
localTimeZone = localTimeZone[0] ?? localArea[0] ?? { id: null, displayName: null };

const timeZoneOptions: Array<any> = [
    { key: localTimeZone.id, text: `Local (${localTimeZone.displayName})`, value: localTimeZone.id, externalData: {} },
    ...timeZones
        .map(data => ({ key: data.id, text: data.displayName, value: data.id }))
        .filter(x => x.key != localTimeZone.id)]
    .filter(x => x != undefined);

export default function TimeZoneProperty(props: IPropertyComponentProps & ISelectPropertyProps) {
    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onSelectedChange = (e: IComboboxSelectedChangeEventArgs) => {
        props.customChangeEvent ? props.customChangeEvent(e.data) : replaceValue(props.item, e.data)
    }

    const allowClear = props.item.externalData?.allowClear !== undefined ? props.item.externalData.allowClear : true;

    return (
        <BCCombobox
            onSelectedChange={onSelectedChange}
            options={timeZoneOptions}
            showSearch
            style={props.style || inputStyle}
            disabled={props.item.disabled || props.item.readOnly}
            allowClear={allowClear}
            value={props.value}
            dropdownStyle={props.dropdownStyle}
        />
    );
}