import { BCExtremeGridEntities, findMessage } from "@bimser/components";
import { IDictionary, MLHelper } from "@bimser/core";
import { IHumanResourceModule } from "../../entities";
import React from "react";

const statusOptions = [
    {
        value: true,
        text: findMessage.get("100392")
    },
    {
        value: false,
        text: findMessage.get("101256")
    }
]

const holdingColumns = () : BCExtremeGridEntities.IExtremeGridColumn[] => (
    [
        {
            name: "code",
            caption: findMessage.get("100129"),
            dataType: "string"
        },
        {
            name: "description",
            caption: findMessage.get("100022"),
            dataType: "string",
            cellTemplate: (args) => {
                return <span>{MLHelper.getMLText(args.row.data.description)}</span>
            }
        },
        {
            name: "status",
            caption: findMessage.get("100199"),
            dataType: "boolean",
            cellTemplate: (args) => {
                return <span>{statusOptions.find((status) => status.value == args.row.data.status).text}</span>;
            },
            align: "left"
        }
    ]
)

const companyColumns = () : BCExtremeGridEntities.IExtremeGridColumn[] => (
    [
        {
            name: "code",
            caption: findMessage.get("100129"),
            dataType: "string"
        },
        {
            name: "description",
            caption: findMessage.get("100022"),
            dataType: "string",
            cellTemplate: (args) => {
                return <span>{MLHelper.getMLText(args.row.data.description)}</span>
            }
        },
        {
            name: "status",
            caption: findMessage.get("100199"),
            dataType: "boolean",
            lookup: {
                key: "companyStatusLookup",
                dataSource: statusOptions,
                valueExpr: "value",
                displayExpr: "text",
                allowClearing: true,
            },
            cellTemplate: (args) => {
                return <span>{statusOptions.find((status) => status.value == args.row.data.status).text}</span>;
            },
            align: "left"
        }
    ]
)

const plantColumns = () : BCExtremeGridEntities.IExtremeGridColumn[] => (
    [
        {
            name: "description",
            caption: findMessage.get("100022"),
            dataType: "string",
            cellTemplate: (args) => {
                return <span>{MLHelper.getMLText(args.row.data.description)}</span>
            }
        },
        {
            name: "company",
            caption: findMessage.get("101375"),
            dataType: "string"
        },
        {
            name: "status",
            caption: findMessage.get("100199"),
            dataType: "boolean",
            lookup: {
                key: "plantStatusLookup",
                dataSource: statusOptions,
                valueExpr: "value",
                displayExpr: "text",
                allowClearing: true,
            },
            cellTemplate: (args) => {
                return <span>{statusOptions.find((status) => status.value == args.row.data.status).text}</span>;
            },
            align: "left"
        }
    ]
)

const organizationColumns = (options: any) : BCExtremeGridEntities.IExtremeGridColumn[] => (
    [
        {
            name: "description",
            caption: findMessage.get("100022"),
            dataType: "string",
            cellTemplate: (args) => {
                return <span>{MLHelper.getMLText(args.row.data.description)}</span>
            }
        },
        {
            name: "parentDescription",
            caption: findMessage.get("102799"),
            dataType: "string",
            filtering: { enabled: false },
            cellTemplate: (args) => {
                return <span>{args.row.data.parentDescription || "-"}</span>
            }
        },
        {
            name: "company",
            caption: findMessage.get("101375"),
            dataType: "string"
        },
        {
            name: "plants",
            caption: findMessage.get("102785"),
            visible: options.plantVisible,
            dataType: "object",
            filtering: { enabled: false },
            cellTemplate: (args) => {
                return <span>{args.row.data.plants.map(plant => plant.description).join(",")}</span>
            }
        },
        {
            name: "status",
            caption: findMessage.get("100199"),
            dataType: "boolean",
            lookup: {
                key: "plantStatusLookup",
                dataSource: statusOptions,
                valueExpr: "value",
                displayExpr: "text",
                allowClearing: true,
            },
            cellTemplate: (args) => {
                return <span>{statusOptions.find((status) => status.value == args.row.data.status).text}</span>;
            },
            align: "left"
        }
    ]
)

export const columns = {
    [IHumanResourceModule.Holding]: holdingColumns(),
    [IHumanResourceModule.Company]: companyColumns(),
    [IHumanResourceModule.Plant]: plantColumns(),
    [IHumanResourceModule.Organization]: organizationColumns,
    [IHumanResourceModule.Title]: [],
    [IHumanResourceModule.Position]: [],
    [IHumanResourceModule.User]: [],
}

export const headerTitles = {
    [IHumanResourceModule.Holding]: findMessage.get("102786"),
    [IHumanResourceModule.Company]: findMessage.get("101375"),
    [IHumanResourceModule.Plant]: findMessage.get("102785"),
    [IHumanResourceModule.Organization]: findMessage.get("100580"),
    [IHumanResourceModule.Title]: findMessage.get("102787"),
    [IHumanResourceModule.Position]: findMessage.get("100202"),
    [IHumanResourceModule.User]: findMessage.get("100728"),
}

export const formRegExp = {
    [IHumanResourceModule.Holding]: { code: /^[a-zA-Z0-9]+$/ },
    [IHumanResourceModule.Company]: { code: /^[a-zA-Z0-9]+$/ },
    [IHumanResourceModule.Plant]: { code: /^[a-zA-Z0-9]+$/ },
    [IHumanResourceModule.Organization]: { code: /^[a-zA-Z0-9]+$/ },
}

export const menuIcons = {
    [IHumanResourceModule.Holding]: "hr-holding",
    [IHumanResourceModule.Company]: "hr-company",
    [IHumanResourceModule.Plant]: "hr-facility",
    [IHumanResourceModule.Organization]: "hr-organization",
    [IHumanResourceModule.Title]: "hr-title",
    [IHumanResourceModule.Position]: "hr-position",
    [IHumanResourceModule.User]: "hr-title",
}