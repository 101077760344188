import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseFlowDocumentProperty {
    field: string,
    caption: string,
    visible: boolean,
    displayFormat: string,
    value: any,
    text: string
}

export interface IFlowDocumentProperty extends IBaseFlowDocumentProperty {

}

export interface IReadonlyFlowDocumentProperty extends IBaseFlowDocumentProperty {

}

export type FlowDocumentProperty = ReadonlyRecord<IReadonlyFlowDocumentProperty>;

export const FlowDocumentPropertyFactory = Record<IReadonlyFlowDocumentProperty>({
    field: null,
    caption: null,
    visible: false,
    displayFormat: null,
    value: null,
    text: null
});

export const FlowDocumentPropertyFactoryFromJS = function (data: IFlowDocumentProperty) {
    return FlowDocumentPropertyFactory(data);
}