import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";
import { FlowHistoryPanelContent, FlowHistoryPanelContentFactoryFromJS, IFlowHistoryPanelContent } from "../components/flowViewer/components/flowHistory/entities/FlowHistoryPanelContent";
import { FlowViewerPanelContent, IFlowViewerPanelContent, FlowViewerPanelContentFactoryFromJS } from "../components/flowViewer/entities/IFlowViewerPanelContent";
import { IWFManagementPanelContent, WFManagementPanelContent, WFManagementPanelContentFactoryFromJS } from '../components/wfManagement/entities/WFManagementPanelContent';
import { IWFListPanelContent, WFListPanelContent, WFListPanelContentFactoryFromJS } from '../components/wfList/entities/WFListPanelContent';
import { IWFProcessListPanelContent, WFProcessListPanelContent, WFProcessListPanelContentFactoryFromJS } from '../components/wfProcessList/entities/IWFProcessListPanelContent';

export interface IWorkflowPanels {
    flowHistoryForms?: IDictionary<IFlowHistoryPanelContent>,
    management?: IDictionary<IWFManagementPanelContent>,
    wfList?: IDictionary<IWFListPanelContent>,
    wfProcessList?: IDictionary<IWFProcessListPanelContent>,
    flowViewerForms?: IDictionary<IFlowViewerPanelContent>
}

export interface IReadonlyWorkflowPanels {
    flowHistoryForms?: Map<string, FlowHistoryPanelContent>,
    management?: Map<string, WFManagementPanelContent>,
    wfList?: Map<string, WFListPanelContent>,
    wfProcessList?: Map<string, WFProcessListPanelContent>,
    flowViewerForms?: Map<string, FlowViewerPanelContent>
}

export type WorkflowPanels = ReadonlyRecord<IReadonlyWorkflowPanels>;

export const WorkflowPanelsFactory = Record<IReadonlyWorkflowPanels>({
    flowHistoryForms: Map<string, FlowHistoryPanelContent>(),
    management: Map<string, WFManagementPanelContent>(),
    wfList: Map<string, WFListPanelContent>(),
    wfProcessList: Map<string, WFProcessListPanelContent>(),
    flowViewerForms: Map<string, FlowViewerPanelContent>()
});

export const WorkflowPanelsFactoryFromJS = function (data: IWorkflowPanels) {

    let _flowHistoryForms: IDictionary<FlowHistoryPanelContent> = Object.keys(data.flowHistoryForms).reduce(function (previous: IDictionary<FlowHistoryPanelContent>, current) {
        previous[current] = FlowHistoryPanelContentFactoryFromJS(data.flowHistoryForms[current]);
        return previous;
    }, {});


    let _flowViewerForms: IDictionary<FlowViewerPanelContent> = Object.keys(data.flowViewerForms).reduce(function (previous: IDictionary<FlowViewerPanelContent>, current) {
        previous[current] = FlowViewerPanelContentFactoryFromJS(data.flowViewerForms[current]);
        return previous;
    }, {});

    let _managementForms: IDictionary<WFManagementPanelContent> = Object.keys(data.management).reduce(function (previous: IDictionary<WFManagementPanelContent>, current) {
        previous[current] = WFManagementPanelContentFactoryFromJS(data.management[current]);
        return previous;
    }, {});

    let _wfListForms: IDictionary<WFListPanelContent> = Object.keys(data.wfList).reduce(function (previous: IDictionary<WFListPanelContent>, current) {
        previous[current] = WFListPanelContentFactoryFromJS(data.wfList[current]);
        return previous;
    }, {});

    let _wfProcessListForms: IDictionary<WFProcessListPanelContent> = Object.keys(data.wfProcessList).reduce(function (previous: IDictionary<WFProcessListPanelContent>, current) {
        previous[current] = WFProcessListPanelContentFactoryFromJS(data.wfProcessList[current]);
        return previous;
    }, {});

    return WorkflowPanelsFactory({
        management: Map(_managementForms),
        wfList: Map(_wfListForms),
        wfProcessList: Map(_wfProcessListForms),
        flowHistoryForms: Map(_flowHistoryForms),
        flowViewerForms: Map(_flowViewerForms)
    });
}