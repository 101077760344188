import { createSelector } from "reselect";
import { PasswordRequirements } from "../entities";
import { BaseState } from "../../../entities/BaseState";

const passwordRequirementsSelector = (state: BaseState) => state.system.passwordRequirements;

const getPasswordRequirements = createSelector(
    [
        passwordRequirementsSelector
    ],
    (passwordRequirements: PasswordRequirements) => {
        return passwordRequirements.toJS()
    }
);

export { getPasswordRequirements };
