import { ReadonlyRecord } from '@bimser/core';
import { List, Record } from 'immutable';
import { LoadingStatus, LoadingStatusFactory } from './LoadingStatus';
import { IPermissionGroup, PermissionGroup, PermissionGroupFactoryFromJS } from './PermissionGroup';
import { ISearchMember, SearchMember, SearchMemberFactoryFromJS } from './SearchMembers';

interface IBaseSecurityPanelContent {
    id: string,
    showingPermissionGroupId?: number,
}

export interface ISecurityPanelContent extends IBaseSecurityPanelContent {
    items?: IPermissionGroup[],
    searchMembers?: ISearchMember[],
    loadingsInfo: LoadingStatus
}

export interface IReadonlySecurityPanelContent extends IBaseSecurityPanelContent {
    items?: List<PermissionGroup>,
    searchMembers?: List<SearchMember>,
    loadingsInfo: LoadingStatus
}

export type SecurityPanelContent = ReadonlyRecord<IReadonlySecurityPanelContent>;

export const SecurityPanelContentFactory = Record<IReadonlySecurityPanelContent>({
    id: null,
    showingPermissionGroupId: null,
    loadingsInfo: LoadingStatusFactory(),
    items: List(),
    searchMembers: List()
});

export const SecurityPanelContentFactoryFromJS = function (data: ISecurityPanelContent) {
    let _items: PermissionGroup[] = data && data.items && data.items.map(item => {
        return PermissionGroupFactoryFromJS(item);
    })

    let _searchMembers: SearchMember[] = data && data.searchMembers && data.searchMembers.map(member => {
        return SearchMemberFactoryFromJS(member);
    })

    return SecurityPanelContentFactory({
        ...data,
        items: List(_items),
        searchMembers: List(_searchMembers),
        loadingsInfo: LoadingStatusFactory()
    });
}