import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";
import { optionTypes } from "../enums";

export interface IBasePropertyType {
    id?: number,
    type: optionTypes,
    params: string,
    isMLSupport: boolean,
    propertyName: string
}

export interface IPropertyType extends IBasePropertyType {
    description?: IDictionary<any>,
    caption?: IDictionary<any>,
    defaultValue?: IDictionary<any>,
}

export interface IReadonlyPropertyType extends IBasePropertyType {
    description?: Map<string, any>,
    caption?: Map<string, any>,
    defaultValue?: Map<string, any>,
}

export type PropertyType = ReadonlyRecord<IReadonlyPropertyType>;

export const PropertyTypeFactory = Record<IReadonlyPropertyType>({
    id: 0,
    type: optionTypes.Text,
    params: "",
    isMLSupport: false,
    propertyName: "",
    description: Map<string, any>(),
    caption: Map<string, any>(),
    defaultValue: Map<string, any>(),
});

export const PropertyTypeFactoryFromJS = function (propertyType: IPropertyType) {    
    let _description: IDictionary<any> = propertyType.description ? Object.keys(propertyType.description).reduce(function (previous: IDictionary<any>, current) {
        previous[current] = propertyType.description[current];
        return previous;
    }, {}) : {};

    let _caption: IDictionary<any> = propertyType.caption ? Object.keys(propertyType.caption).reduce(function (previous: IDictionary<any>, current) {
        previous[current] = propertyType.caption[current];
        return previous;
    }, {}) : {};

    let _defaultValue: IDictionary<any> = propertyType.defaultValue ? Object.keys(propertyType.defaultValue).reduce(function (previous: IDictionary<any>, current) {
        previous[current] = propertyType.defaultValue[current];
        return previous;
    }, {}) : {};

    return PropertyTypeFactory({
        id: propertyType.id,
        isMLSupport: propertyType.isMLSupport,
        params: propertyType.params,
        type: propertyType.type,
        propertyName: propertyType.propertyName,
        description: Map(_description),
        caption: Map(_caption),
        defaultValue: Map(_defaultValue),
    });
}

