const fs = require('fs');
const path = require('path');

const rootIconsPath = path.resolve(__dirname, "../dist/svg");
const rootComponentsPath = path.resolve(__dirname, "..");
const IconComponentPath = path.resolve(__dirname, "../lib/components/Icon");
const IconPropsInterfacePath = path.resolve(__dirname, "../lib/entities/IIconProps");
const IconSets = require('./iconSets');
const jsTemplate = require('./jsTemplate');
const dtsTemplate = require('./dtsTemplate');


function processIconSets() {
    Object.keys(IconSets).forEach(set => {
        generateComponents("", IconSets[set]);
    })
}

function generateComponents(relativePath, iconSet) {
    const dirPath = path.resolve(rootIconsPath, iconSet.sourceFolder, relativePath);

    if (!fs.existsSync(dirPath)) return;

    const dirents = fs.readdirSync(dirPath, { withFileTypes: true });

    dirents.forEach(item => {
        if (item.isDirectory()) {
            const subPath = path.join(relativePath, item.name);
            generateComponents(subPath, iconSet);
        }
        else if (item.isFile()) {
            iconSet.usageSizes.forEach(usageSize => {
                const destFileRelativePath = path.join(relativePath, item.name);
                const filePath = path.resolve(rootComponentsPath, usageSize.destinationFolder, destFileRelativePath);
                const fileDirPath = path.dirname(filePath);
                const fileName = path.parse(filePath).name;
                const jsPath = path.resolve(fileDirPath, fileName + ".js");
                const dtsPath = path.resolve(fileDirPath, fileName + ".d.ts");
                const requireSvgPath = path.relative(fileDirPath, path.resolve(dirPath, item.name));
                const requireIconCompPath = path.relative(fileDirPath, IconComponentPath);
                const requireIconPropsInterfacePath = path.relative(fileDirPath, IconPropsInterfacePath);

                const jsFileStr = jsTemplate({
                    SVGPath: requireSvgPath.split("\\").join("/"),
                    ComponentPath: requireIconCompPath.split("\\").join("/"),
                    Size: usageSize.size,
                    Name: fileName
                });
                const dtsFileStr = dtsTemplate({
                    IconProsInterfacePath: requireIconPropsInterfacePath.split("\\").join("/"),
                });

                fs.mkdirSync(fileDirPath, { recursive: true });
                fs.writeFileSync(jsPath, jsFileStr);
                fs.writeFileSync(dtsPath, dtsFileStr);
            });
        }
    });
}


module.exports = processIconSets;
