import { UploadManagerState, UploadFileInfo, UploadFileItemFactory, UploadFileItem } from "../entities";
import * as actions from '../actions/actionTypes';
import { Map } from 'immutable';
import IUpdateThreadStatusPayload from "../entities/IUpdateThreadStatusPayload";
import IUpdateFileStatusPayload from "../entities/IUpdateFileStatusPayload";
import IUpdateProgress from "../entities/IUpdateProgress";
import { IBaseAction, UploadFileThread, UploadManagerStatus } from '../../..';

export default (state: UploadManagerState, action: IBaseAction<any>) => {
    switch (action.type) {
        case actions.PREPARE_FILE_TO_UPLOAD: {
            return prepareFileToUpload(state, action.payload);
        }
        case actions.CREATE_FILE_UPLOAD_THREADS: {
            return createFileUploadThreads(state, action.payload);
        }
        case actions.UPDATE_FILE_THREAD_STATUS: {
            return updateThreadStatus(state, action.payload);
        }
        case actions.UPDATE_FILE_UPLOAD_STATUS: {
            return updateFileStatus(state, action.payload);
        }
        case actions.CLEAR_UPLOAD_LIST_ACTION: {
            return clearUploadList(state, action.payload);
        }
        case actions.UPDATE_FILE_PROGRESS_ACTION: {
            return updateProgress(state, action.payload);
        }
        default:
            return state;
    }
};

function prepareFileToUpload(state: UploadManagerState, payload: any) {
    let info: UploadFileInfo = payload.info;
    let parentHash: string = payload.parentHash;

    let File = state.files.get(parentHash, UploadFileItemFactory());
    File = File.set("info", info).set("id", parentHash);
    return state.set("files", state.files.set(parentHash, File));
}

function createFileUploadThreads(state: UploadManagerState, payload: any) {
    let threads: Map<string, UploadFileThread> = payload.threads;
    let parentHash: string = payload.parentHash;

    let File = state.files.get(parentHash, UploadFileItemFactory());
    File = File.set("threads", threads);
    return state.set("files", state.files.set(parentHash, File));
}

function updateThreadStatus(state: UploadManagerState, payload: IUpdateThreadStatusPayload) {
    let files = state.files;
    let file: UploadFileItem = files.get(payload.hash);
    let threads: Map<string, UploadFileThread> = file.threads;
    let thread: UploadFileThread = threads.get(payload.threadId);

    if (!thread) return state;
    thread = thread.set("status", payload.status);
    threads = threads.set(payload.threadId, thread);
    file = file.set("threads", threads);
    files = files.set(payload.hash, file);
    return state.set("files", files);
}

function updateFileStatus(state: UploadManagerState, payload: IUpdateFileStatusPayload) {
    let files = state.files;
    let file = files.get(payload.hash);
    file = file.set("info", file.info.set("status", payload.status));
    files = files.set(payload.hash, file);
    return state.set("files", files);
}

function clearUploadList(state: UploadManagerState, hash: string) {
    if (hash) {
        return state.set("files", state.files.remove(hash));
    } else {
        return state.set("files", state.files.filter(f => [UploadManagerStatus.Done, UploadManagerStatus.Failed].indexOf(f.info.status) == -1))
    }
}

function updateProgress(state: UploadManagerState, payload: IUpdateProgress) {
    let files = state.files;
    let file = files.get(payload.hash);
    if (file) {
        file = file.set("info", file.info.set("uploadedSize", payload.uploadedSize))
        files = files.set(payload.hash, file);
        return state.set("files", files)
    } else return state;
}