import { Map } from "immutable";
import { reorder } from ".";
import { LayoutItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const reorderOnParentView = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    startIndex: number,
    endIndex: number,
    item: LayoutItem
) => {
    return reorder(views, activeView, item.parentId, startIndex, endIndex);
};