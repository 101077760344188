import { ITreeviewItem } from '../../BCTreeview';

export function getMenuItems(): ITreeviewItem<any>[] {
    return [
        {
            key: 'Fields',
            text: 'Fields',
        },
        {
            key: 'Constants',
            text: 'Constants'
        },
        {
            key: 'Functions',
            text: 'Functions',
            isLeaf: false,
            children: [
                {
                    key: 'Statistics',
                    text: 'Statistics'
                },
                {
                    key: 'Trigonometry',
                    text: 'Trigonometry'
                },
                // {
                //     key: 'DataGrid',
                //     text: 'DataGrid'
                // }
            ]
        },
        {
            key: 'Operators',
            text: 'Operators'
        }
    ]
}

// export function getEnumTypeKeys(type: any): string[] {
//     return Object.keys(type).filter(e => Number.isNaN(Number(e)));
// }

export function getOperators(): ITreeviewItem<any>[] {
    return [
        { key: 'Plus', text: '+', title: 'Adds the value of one numeric expression to another, or concatenates two strings' },
        { key: 'Minus', text: '-', title: 'Finds the difference between two numbers.' },
        { key: 'Multiple', text: '*', title: 'Multiplies the value of two expressions.' },
        { key: 'Divide', text: '/', title: 'Divides the first operand by the second.' },
        { key: 'Module', text: '%', title: 'Returns the remainder (modulus) obtained by dividing one numeric expression into another.' },
        { key: 'Exponent', text: '^', title: 'An exponent refers to the number of times a number is multiplied by itself.' },
        // { key: 'Equals', text: '==', title: 'Returns true if both operands have the same value; otherwise, it returns false.' },
        // { key: 'NotEquals', text: '!=', title: 'Returns true if the operands do not have the same value; otherwise, it returns false.' },
        // { key: 'LessThan', text: '<', title: 'Less than operator. Used to compare expressions.' },
        // { key: 'LessThanAndEquals', text: '<=', title: 'Less than or equal to operator. Used to compare expressions.' },
        // { key: 'GreaterThanAndEquals', text: '>=', title: 'Greater than or equal to operator. Used to compare expressions.' },
        // { key: 'GreaterThan', text: '>', title: 'Greater than operator. Used to compare expressions.' }
    ]
}

export function getConstants(): ITreeviewItem<any>[] {
    return [
        { key: 'Null', text: 'null', title: 'Represents a null reference, one that does not refer to any object.' },
        { key: 'True', text: 'true', title: 'Represents the Boolean False value.' },
        { key: 'False', text: 'false', title: 'Represents the Boolean True value.' }
    ]
}

export function getStatistics(): ITreeviewItem<any>[] {
    return [
        { key: 'Mad', text: 'mad(, )', title: 'Compute the median absolute deviation of a matrix or a list with values.' },
        { key: 'Max', text: 'max(, )', title: 'Compute the maximum value of a matrix or a list with values.' },
        { key: 'Mean', text: 'mean(, )', title: 'Compute the mean value of matrix or a list with values.' },
        { key: 'Median', text: 'median(, )', title: 'Compute the median of a matrix or a list with values.' },
        { key: 'Min', text: 'min(, )', title: 'Compute the minimum value of a matrix or a list of values.' },
        { key: 'Prod', text: 'prod(, )', title: 'Compute the product of a matrix or a list with values.' },
        { key: 'Std', text: 'std(, )', title: 'Compute the standard deviation of a matrix or a list with values.' },
        { key: 'Sum', text: 'sum(, )', title: 'Compute the sum of a matrix or a list with values.' },
        { key: 'Variance', text: 'variance(, )', title: 'Compute the variance of a matrix or a list with values.' }
    ]
}

export function getDataGrid(): ITreeviewItem<any>[] {
    return [
        { key: 'getRowValues', text: "getRowValues(,'Column1', 'Column2')", title: "Finds the values of selected column values on a row. Defined as getRowValues([DataGrid1.name], 'Column1', 'Column2')" }
    ]
}

// export function getDateTime(): ITreeviewItem<any>[] {
//     return getEnumTypeKeys(DateTime).map(type => {
//         return {
//             key: type,
//             text: (DateTime as any)[type]
//         }
//     });
// }

// export function getLogical(): ITreeviewItem<any>[] {
//     return getEnumTypeKeys(Logical).map(type => {
//         return {
//             key: type,
//             text: (Logical as any)[type]
//         }
//     });
// }

export function getTrigonometry(): ITreeviewItem<any>[] {
    return [
        { key: 'acos', text: 'acos()', title: 'Calculate the inverse cosine of a value.' },
        { key: 'acosh', text: 'acosh()', title: 'Calculate the hyperbolic arccos of a value, defined as acosh(x) = ln(sqrt(x^2 - 1) + x)' },
        { key: 'acot', text: 'acot()', title: 'Calculate the inverse cotangent of a value, defined as acot(x) = atan(1/x)' },
        { key: 'acoth', text: 'acoth()', title: 'Calculate the hyperbolic arccotangent of a value, defined as acoth(x) = atanh(1/x) = (ln((x+1)/x) + ln(x/(x-1))) / 2' },
        { key: 'acsc', text: 'acsc()', title: 'Calculate the inverse cosecant of a value, defined as acsc(x) = asin(1/x)' },
        { key: 'acsch', text: 'acsch()', title: 'Calculate the hyperbolic arccosecant of a value, defined as acsch(x) = asinh(1/x) = ln(1/x + sqrt(1/x^2 + 1))' },
        { key: 'asec', text: 'asec()', title: 'Calculate the inverse secant of a value.' },
        { key: 'asech', text: 'asech()', title: 'Calculate the hyperbolic arcsecant of a value, defined as asech(x) = acosh(1/x) = ln(sqrt(1/x^2 - 1) + 1/x)' },
        { key: 'asin', text: 'asin()', title: 'Calculate the inverse sine of a value.' },
        { key: 'asinh', text: 'asinh()', title: 'Calculate the hyperbolic arcsine of a value, defined as asinh(x) = ln(x + sqrt(x^2 + 1))' },
        { key: 'atan', text: 'atan()', title: 'Calculate the inverse tangent of a value.' },
        { key: 'atan2', text: 'atan2(, )', title: 'Calculate the inverse tangent function with two arguments, y/x.' },
        { key: 'atanh', text: 'atanh()', title: 'Calculate the hyperbolic arctangent of a value, defined as atanh(x) = ln((1 + x)/(1 - x)) / 2' },
        { key: 'cos', text: 'cos()', title: 'Calculate the cosine of a value.' },
        { key: 'cosh', text: 'cosh()', title: 'Calculate the hyperbolic cosine of a value, defined as cosh(x) = 1/2 * (exp(x) + exp(-x))' },
        { key: 'cot', text: 'cot()', title: 'Calculate the cotangent of a value.' },
        { key: 'coth', text: 'coth()', title: 'Calculate the hyperbolic cotangent of a value, defined as coth(x) = 1 / tanh(x)' },
        { key: 'csc', text: 'csc()', title: 'Calculate the cosecant of a value, defined as csc(x) = 1/sin(x)' },
        { key: 'csch', text: 'csch()', title: 'Calculate the hyperbolic cosecant of a value, defined as csch(x) = 1 / sinh(x)' },
        { key: 'sec', text: 'sec()', title: 'Calculate the secant of a value, defined as sec(x) = 1/cos(x)' },
        { key: 'sech', text: 'sech()', title: 'Calculate the hyperbolic secant of a value, defined as sech(x) = 1 / cosh(x)' },
        { key: 'sin', text: 'sin()', title: 'Calculate the sine of a value.' },
        { key: 'sinh', text: 'sinh()', title: 'Calculate the hyperbolic sine of a value, defined as sinh(x) = 1/2 * (exp(x) - exp(-x))' },
        { key: 'tan', text: 'tan()', title: 'Calculate the tangent of a value' },
        { key: 'tanh', text: 'tanh()', title: 'Calculate the hyperbolic tangent of a value, defined as tanh(x) = (exp(2 * x) - 1) / (exp(2 * x) + 1)' }
    ]
}

// export function getString(): ITreeviewItem<any>[] {
//     return getEnumTypeKeys(String).map(type => {
//         return {
//             key: type,
//             text: (String as any)[type]
//         }
//     });
// }