import * as React from "react";
import BCColorPicker from '../../../BCColorPicker';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function ColorPickerProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue);
    }

    const onChange = (color: string) => {
        props.customChangeEvent ? props.customChangeEvent(color) : replaceValue(props.item, color);
    }

    return (
        <BCColorPicker
            color={props.value}
            onChange={onChange}
            style={props.style}
            disabled={props.item.disabled || props.item.readOnly}
            disableAlpha={props.item.externalData?.disableAlpha}
            presetColors={props.item.externalData?.presetColors}
        />
    )
}