
export default function (params: IRequestParams) {  
    var xhr = new XMLHttpRequest();
    xhr.open(params.method, params.url);
    xhr.onload = function () {
        params.onSuccess(xhr.response);
    };
    xhr.onerror = function () {
        params.onError(xhr.response);
    };
    xhr.send(params.parameters);
 
    if (params.onSuccess) {
        window.setTimeout(function () {
            params.onSuccess();
        }, 1000);
    }
}


export interface IRequestParams {
    onSuccess?: (result?: any) => void;
    onError?: (err: any) => void;
    promise?: Promise<any>;
    url: string;
    parameters?: any;
    method: "POST" | "GET";
}