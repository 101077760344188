import { Record, List, Map } from "immutable";
import { ReadonlyRecord, IDictionary } from "@bimser/core";

export interface IBaseTenantCustom {
    pageFavicon: string;
    logoBottom: string;
    logoMain: string;
    logoMenu: string;
}

export interface ITenantCustom extends IBaseTenantCustom {
    productName: IDictionary<string>;
    pageTitle: IDictionary<string>;
    headerText: IDictionary<string>;
}

export interface IReadonlyTenantCustom extends IBaseTenantCustom {
    productName: Map<string, string>;
    pageTitle: Map<string, string>;
    headerText: Map<string, string>;
}

export type TenantCustom = ReadonlyRecord<IReadonlyTenantCustom>;

export const TenantCustomFactory = Record<IReadonlyTenantCustom>({
    headerText: Map<string,string>(),
    pageFavicon: null,
    logoBottom: null,
    logoMain: null,
    logoMenu: null,
    productName: Map<string,string>(),
    pageTitle: Map<string,string>()
});

export const TenantCustomFactoryFromJS = function (data: ITenantCustom) {
    return TenantCustomFactory({
        headerText: Map(data.headerText),
        pageFavicon: data.pageFavicon,
        logoBottom: data.logoBottom,
        logoMain: data.logoMain,
        logoMenu: data.logoMenu,
        productName: Map(data.productName),
        pageTitle: Map(data.pageTitle)
    });
}