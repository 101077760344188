import React, { useState } from 'react';
import { DatePicker } from 'antd-mobile';
import { findMessage, BCInput, BCInputRef } from '../';
import { IBCMobileDateTimePickerProps } from './entities';
import * as Moment from "moment";
import { isNullOrUndefined } from '@bimser/core';


export default function BCMobileDateTimePicker(props: IBCMobileDateTimePickerProps) {

    const [visible, setVisible] = useState(false);
    const inputRef = React.useRef<BCInputRef>();

    React.useEffect(() => {
        if (inputRef.current) inputRef.current.input.readOnly = true;
    }, [inputRef.current])

    function onConfirmValue(value: Date) {
        const date = Moment(value.toString());
        const shortISODate = date.format().split("T")[0];
        const longISODate = date.format().split("+")[0];
        const dateTimeOffsetString = date.format();
        props?.onChange(date, date && dateTimeOffsetString, dateTimeOffsetString, shortISODate, longISODate, dateTimeOffsetString);
    }

    function getPrecision() {
        if (props.showTime || isNullOrUndefined(props.format)) {
            return "minute";
        }
        else if (props.format.includes("ss")) {
            return "second";
        }
        else if (props.format.includes("mm")) {
            return "minute";
        }
        else if (props.format.includes("HH")) {
            return "hour";
        }
        return "day"
    }

    function onChangeVisible(pickerVisible: boolean) {
        setVisible(pickerVisible)
    }

    function filterDates(val: number, extend: { date: Date }, type: string) {

        const disabledAfter = props.disableAfter.every((value) => {
            if (value?.enabled && val > value?.[type]) {
                return false;
            }
            return true;
        });

        const disableBefore = props.disableBefore?.every((value) => {
            if (value?.enabled && val < value?.[type]) {
                return false;
            }
            return true;
        });

        return disabledAfter && disableBefore;
    }

    function filterDatesByIntervalfilterDates(val: number, extend: { date: Date }, type: string) {
        let processVariable: string = "";
        let disabledDates: string[] = [];

        switch (type) {
            case "minute":
                processVariable = "disableMinutes";
                break;
            case "hour":
                processVariable = "disableHours";
                break;
            case "seconds":
                processVariable = "disableSeconds";
                break;
            default:
                break;
        }

        if (!props[processVariable]) {
            return true;
        }

        if (props[processVariable].includes("-")) {
            disabledDates = props[processVariable].split("-");
        }
        if (props[processVariable].includes(",")) {
            disabledDates = props[processVariable].split(",");
        }

        if (disabledDates.includes(val.toString())) {
            return false;
        }

        return true;
    }

    function filter() {
        return {
            year: (val: number, extend: { date: Date }) => filterDates(val, extend, "years"),
            month: (val: number, extend: { date: Date }) => filterDates(val, extend, "months"),
            day: (val: number, extend: { date: Date }) => filterDates(val, extend, "days"),
            minute: (val: number, extend: { date: Date }) => filterDatesByIntervalfilterDates(val, extend, "minute"),
            second: (val: number, extend: { date: Date }) => filterDatesByIntervalfilterDates(val, extend, "second"),
            hour: (val: number, extend: { date: Date }) => filterDatesByIntervalfilterDates(val, extend, "hour"),
        }
    }

    return (
        <>
            <BCInput
                ref={inputRef}
                onClick={() => onChangeVisible(true)}
                value={props.value?.format(props.format) || ""}
                disabled={props.disabled || props.readOnly}
                placeHolder={props.placeholder}
                allowClear={props.allowClear}
                style={props.style}
                textAlign={props.textAlign}
            />
            <DatePicker
                filter={filter()}
                visible={visible}
                onClose={() => onChangeVisible(false)}
                precision={getPrecision()}
                onConfirm={onConfirmValue}
                confirmText={findMessage.get("100004")}
                cancelText={findMessage.get("100034")}
            />
        </>
    )
}
