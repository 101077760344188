import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { BaseResponse } from "../../../entities/BaseResponse";

export interface IGetUserSettingParametersResponse extends BaseResponse {
    notificationSoundStatus: boolean,
}

export interface IReadonlyGetUserSettingParametersResponse extends BaseResponse {
    notificationSoundStatus: boolean,
}

export type GetUserSettingParametersResponse = ReadonlyRecord<IReadonlyGetUserSettingParametersResponse>;

export const GetUserSettingParametersResponseFactory = Record<IReadonlyGetUserSettingParametersResponse>({
    notificationSoundStatus: false,
});

export const GetUserSettingParametersResponseFactoryFromJS = function (data: IGetUserSettingParametersResponse) {
    return GetUserSettingParametersResponseFactory(data);
}