import { WebInterfaceBaseState } from "../../../common/base/state";
import { createSelector } from 'reselect';

const getCanCreateAnnouncementSelector = (state: WebInterfaceBaseState) => {
    return state.user.userPermissions.canCreateAnnouncement;
}

const getCanDeleteAnnouncementSelector = (state: WebInterfaceBaseState) => {
    return state.user.userPermissions.canDeleteAnnouncement;
}

const getCanUpdateAnnouncementSelector = (state: WebInterfaceBaseState) => {
    return state.user.userPermissions.canUpdateAnnouncement;
}

const getAnnouncementPermissions = createSelector(
    [
        getCanCreateAnnouncementSelector,
        getCanDeleteAnnouncementSelector,
        getCanUpdateAnnouncementSelector
    ],
    (canCreateAnnouncement: boolean, canDeleteAnnouncement: boolean, canUpdateAnnouncement: boolean) => {
        return {
            canCreateAnnouncement,
            canDeleteAnnouncement,
            canUpdateAnnouncement
        }
    }
)

export default getAnnouncementPermissions;