import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";

interface IBaseCompany {
    id: string,
    code: string,
    status: boolean,
}

export interface ICompany extends IBaseCompany {
    description: IDictionary<string>,
    managers: Array<string>
}

export interface IReadonlyCompany extends IBaseCompany {
    description: Map<string, string>
    managers: List<string>
}

export type Company = ReadonlyRecord<IReadonlyCompany>;

export const CompanyFactory = Record<IReadonlyCompany>({
    id: null,
    code: '',
    description: Map(),
    managers: List(),
    status: false
});

export const CompanyFactoryFromJS = function (company: ICompany) {
    return CompanyFactory({
        ...company,
        description: Map(company.description),
        managers: List(company.managers)
    })
}