import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from ".";


interface ICompanyAdminListBaseFormContent extends IBaseFormContent {
}

export interface ICompanyAdminListFormContent extends ICompanyAdminListBaseFormContent {
}

export interface IReadonlyCompanyAdminListFormContent extends ICompanyAdminListBaseFormContent {
}

export type CompanyAdminListFormContent = ReadonlyRecord<IReadonlyCompanyAdminListFormContent>;

export const CompanyAdminListFormContentFactory = Record<IReadonlyCompanyAdminListFormContent>({
    panelId: "",
    showLoading: false,
    selectedCompanyId: 0,
    selectableListPanelisActive: false
});

export const CompanyAdminListFormContentFactoryFromJS = function (data: ICompanyAdminListFormContent) {
    return CompanyAdminListFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedCompanyId: data.selectedCompanyId,
        selectableListPanelisActive: data.selectableListPanelisActive
    });
}