import IconClosePanel from "@bimser/icons/16/close-panel";
import IconSave from "@bimser/icons/16/save";
import * as React from "react";
import * as Styles from '../../assets/style.scss';
import { IFormulaEditorModalProps } from '../../entities';
import { IButtonProps, findMessage, BCFormulaEditor } from '../../..';
import IconSubMenuArrow from "@bimser/icons/16/sub-menu-arrow";

export default class FormulaEditorModal extends React.Component<IFormulaEditorModalProps, { newValue?: string; name?: string }>{

    constructor(props: any) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onOkClick = this.onOkClick.bind(this);
        this.state = {
            newValue: this.props.value
        }
    }

    onChange(newValue: string) {
        this.setState({ newValue });
    }

    onOkClick() {
        this.props.onOkClick(this.state.newValue);
    }

    renderHeaderButtons(): IButtonProps[] {
        return [
            {
                id: 'save',
                text: 'Save',
                size: 'small',
                type: 'link'
            }
        ]
    }

    renderSubTitle() {
        return (
            <>
                <span className="modal-title-text" title="Formula Editor">Formula Editor</span><br/>
                <div className={Styles.subTitle}>
                    <span>{this.props.name}</span>
                    <span><IconSubMenuArrow width="16px" height="16px" /></span>
                    <span>Formula Editor</span>
                </div>
            </>
        )
    }

    renderHeader() {

        return (
            <React.Fragment>
                {this.renderSubTitle()}
                <div className={Styles.headerButtons}>
                    <div className={Styles.closeButton} onClick={this.props.onCancelClick}>
                        <IconClosePanel />
                    </div>
                    <div className={Styles.saveButton} onClick={this.onOkClick}>
                        <IconSave width={"16px"} height={"16px"} />
                        <span>{findMessage.get("100620")}</span>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    render() {
        return (
            <>
                <div className={'row no-gutters'}>
                    <div className={'col-md-12'}>
                        <div className={Styles.modalHeader}>
                            {this.renderHeader()}
                        </div>
                    </div>
                </div>
                <BCFormulaEditor
                    onValueChanged={this.onChange}
                    value={this.state.newValue}
                    fields={this.props.fields}
                />
            </>
        )
    }

}