import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseProject {
    id?: string,
    name?: string
}

export interface IProjectContent extends IBaseProject {
}

export interface IReadonlyProject extends IBaseProject {
}

export type ProjectContent = ReadonlyRecord<IReadonlyProject>;

export const ProjectFactory = Record<IReadonlyProject>({
    id: "",
    name: ""
});

export const ProjectContentFactoryFromJS = function (data: IProjectContent) {
    return ProjectFactory(data);
}