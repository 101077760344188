import { ReadonlyRecord } from "@bimser/core";
import { Record, List } from "immutable";
import { CultureState, CultureStateFactory, CultureStateFactoryFromJS, ICultureState, IReadonlyCultureState } from "../culture/entities";
import { ILanguageState, LanguageState, LanguageStateFactory, LanguageStateFactoryFromJS, IReadonlyLanguageState } from "../language/entities";
import { ILocationState, LocationState, LocationStateFactory, LocationStateFactoryFromJS, IReadonlyLocationState } from "../location/entities";
import { IPasswordRequirements, PasswordRequirements, PasswordRequirementsFactory, PasswordRequirementsFactoryFromJS } from "../passwordRequirements/entities";
import { ITenantCustom, TenantCustom, TenantCustomFactory, TenantCustomFactoryFromJS } from "../tenant/entities/ITenantCustom";
import { ILoginSliderItem, LoginSliderItem, LoginSliderItemFactoryFromJS } from '../loginSlider/entities/ILoginSliderItem';

export interface ISystemState {
    language: ILanguageState,
    culture: ICultureState,
    location: ILocationState,
    version: string,
    tenant: ITenantCustom,
    passwordRequirements: IPasswordRequirements,
    loginSliderItems: ILoginSliderItem[],
    mobileApp: boolean,
    eBA6Enabled: boolean
}

export interface IReadonlySystemState {
    language: LanguageState,
    culture: CultureState,
    location: LocationState,
    version: string,
    tenant: TenantCustom,
    passwordRequirements: PasswordRequirements,
    loginSliderItems: List<LoginSliderItem>,
    mobileApp: boolean,
    eBA6Enabled: boolean
}

export type SystemState = ReadonlyRecord<IReadonlySystemState>;

export const SystemStateFactory = Record<IReadonlySystemState>({
    language: LanguageStateFactory(),
    culture: CultureStateFactory(),
    location: LocationStateFactory(),
    version: null,
    tenant: TenantCustomFactory(),
    passwordRequirements: PasswordRequirementsFactory(),
    loginSliderItems: List(),
    mobileApp: false,
    eBA6Enabled: false
});

export const SystemStateFactoryFromJS = function (data: ISystemState) {
    let _sliderItems = data && data.loginSliderItems ? data.loginSliderItems.map(i => LoginSliderItemFactoryFromJS(i)) : [];
    return SystemStateFactory({
        language: LanguageStateFactoryFromJS(data.language),
        culture: CultureStateFactoryFromJS(data.culture),
        location: LocationStateFactoryFromJS(data.location),
        version: data.version,
        tenant: TenantCustomFactoryFromJS(data.tenant),
        passwordRequirements: PasswordRequirementsFactoryFromJS(data.passwordRequirements),
        loginSliderItems: List(_sliderItems),
        mobileApp: data.mobileApp,
        eBA6Enabled: data.eBA6Enabled
    });
}
