import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import ProcessDetails from "../../components/widgets/ProcessDetails";
import IProcessDetailsProps from "../../entities/widgets/ProcessDetailsProps";
import { getLoadingProcessDetails, getProcessDetailData, getProcessDetailsStructure, getProcessDetailsTotalCount } from "../../selectors/ProcessDetailsSelector";
import { getSelectedProcess } from "../../selectors/ProcessesSelectors";
import { IWebInterfaceAction } from "../../../../common/entities";
import { onClickProcessDetailTransaction, onLoadProcessDetailsTransaction } from "../../actions";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IProcessDetailsProps): IProcessDetailsProps {
    return {
        data: getProcessDetailData(state),
        selectedProcess: getSelectedProcess(state),
        loading: getLoadingProcessDetails(state),
        totalCount: getProcessDetailsTotalCount(state),
        structure: getProcessDetailsStructure(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IProcessDetailsProps {
    return {
        onClickItem: function(data: IWebInterfaceAction) {
            dispatch(onClickProcessDetailTransaction(data))
        },
        onLoadDetails: function(data: IWebInterfaceAction) {
            dispatch(onLoadProcessDetailsTransaction(data))
        }
    };
}

// react - redux connection
const ProcessDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ProcessDetails);

export default ProcessDetailsContainer;