import React, { lazy, Suspense } from 'react';
import { IAddModalProps } from './entities';

const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-management" */ "./containers").then(({ default: container }) => {
    import(/* webpackChunkName: "web-management" */ "modules/security/injector");
    return { default: container };
}));

function Container(props: React.PropsWithChildren<IAddModalProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}

export default Container;