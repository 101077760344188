import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IShift, Shift, ShiftFactory, ShiftFactoryFromJS } from "..";

export interface IShiftFormContent extends IBaseFormContent {
    showingShift?: IShift,
}

export interface IReadonlyShiftFormContent extends IBaseFormContent {
    showingShift?: Shift
}

export type ShiftFormContent = ReadonlyRecord<IReadonlyShiftFormContent>;

export const ShiftFormContentFactory = Record<IReadonlyShiftFormContent>({
    panelId: "",
    showingShift: ShiftFactory(),
    isModified: false,
    initialHash: "",
    showLoading: false
});

export const ShiftFormContentFactoryFromJS = function (data: IShiftFormContent) {
    const _showingShift : Shift = data.showingShift ? ShiftFactoryFromJS(data.showingShift) : ShiftFactory()

    return ShiftFormContentFactory({
        panelId: data.panelId,
        showingShift: _showingShift,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        initialHash: data.initialHash
    });
}