import { createGuid, generateUniqueName } from "@bimser/core";
import IconAdd from "@bimser/icons/16/add";
import IconCloseWindow from "@bimser/icons/16/close-window";
import IconCollapse from "@bimser/icons/16/collapse";
import IconExpand from "@bimser/icons/16/expand";
import IconSave from "@bimser/icons/16/save";
import Dropdown from 'antd/lib/dropdown/dropdown';
import Menu from 'antd/lib/menu';
import Tag from "antd/lib/tag";
import { ITreeData } from "BCTreeSelect/entities";
import classNames from 'classnames/bind';
import isEqual from "lodash/isEqual";
import * as React from "react";
import { BCCascader, BCNotification } from "../..";
import BCButton, { IButtonClickEventArgs } from "../../BCButton";
import BCCollapse, { ICollapseItem } from "../../BCCollapse";
import BCConditionalStatement, { ConditionalStatementEntities } from "../../BCConditionalStatement";
import { ActionType } from '../../BCConditionalStatement/entities/Enums';
import BCInput, { IInputChangeEventArgs } from "../../BCInput";
import { findMessage } from "../../BCIntl";
import BCLabel from "../../BCLabel";
import { validateRule } from '../../BCRuleManager/helpers';
import BCScrollbars from "../../BCScrollbars";
import * as Styles from '../assets/styles.scss';
import { IRuleEditProps } from "../entities";
import RuleActions from "./RuleActions";

const cx = classNames.bind(Styles);
const scrollbarStyle: React.CSSProperties = { width: '100%', height: '100%', overflow: 'unset' };
const cascaderStyle: React.CSSProperties = { width: "100%" };
const collapseButtonItems = [
    { key: "ShowMessageAction", label: findMessage.get('101301') },
    { key: "ValueAssignActionWithEntry", label: findMessage.get('101302') },
    { key: "ValueAssignActionWithFormula", label: findMessage.get('101944') },
    { key: "ValueAssignActionWithSelection", label: findMessage.get('101303') },
];

const clearStyle: React.CSSProperties = { clear: "both" }

const RuleEdit = React.memo((props: IRuleEditProps) => {
    const [rule, setRule] = React.useState(props.data);
    const [expandedKeys, setExpandedKeys] = React.useState<string | string[]>(["conditions", "actions"]);

    React.useEffect(() => {
        setRule(props.data)
    }, [props.data]);

    if (!props.data) return null;

    const updateLocalData = (data: ConditionalStatementEntities.IRule) => {
        if (!isEqual(data, rule)) {
            setRule(data);
        }
    }

    const renderConditionsGroup = () => {
        return (
            <BCScrollbars styles={scrollbarStyle} autoHide>
                <div className={Styles.editContainer}>
                    <BCConditionalStatement
                        rule={rule}
                        fields={props.fields}
                        onDataChanged={updateLocalData}
                    />
                    <div style={clearStyle} />
                </div>
            </BCScrollbars>
        )
    }

    const renderActionsGroup = () => {
        return (
            <BCScrollbars styles={scrollbarStyle} autoHide>
                <RuleActions
                    rule={rule}
                    startActionRecord={props.startActionRecord}
                    onActionChanged={updateActions}
                    fields={props.fields}
                    currentLanguage={props.currentLanguage}
                    supportedLanguages={props.supportedLanguages}
                    createNewAction={createNewAction}
                    onActionCopy={onActionCopy}
                    openFormulaManager={props.openFormulaManager}
                    formItems={props.formItems}
                />
            </BCScrollbars>
        )
    }

    const generateNewActionName = (): string => {

        const allNames: string[] = rule.actions.map(r => r.name),
            newRuleString = findMessage.get('101298') + ' ';
        let keyFound = false,
            index: number = 0;

        while (!keyFound) {
            index++;
            keyFound = allNames.findIndex(n => n === newRuleString + index) === -1;
        }

        return newRuleString + index

    }

    const generateAction = (actionType: ActionType) => {

        let newAction: ConditionalStatementEntities.IBaseAction;

        switch (actionType) {
            case 'ShowMessageAction': {
                newAction = {
                    id: createGuid(),
                    alertType: 'Info',
                    dialogType: 'Toaster',
                    enabled: true,
                    messages: {},
                    name: generateNewActionName(),
                    typeName: 'ShowMessageAction',
                    approvalActions: [],
                    rejectionActions: []
                } as ConditionalStatementEntities.IShowMessageAction;
                break;
            }
            case 'ValueAssignActionWithEntry': {
                newAction = {
                    id: createGuid(),
                    name: generateNewActionName(),
                    enabled: true,
                    field: null,
                    value: null,
                    typeName: 'ValueAssignActionWithEntry'
                } as ConditionalStatementEntities.IValueAssignActionWithEntry;
                break;
            }
            case 'ValueAssignActionWithFormula': {
                newAction = {
                    id: createGuid(),
                    name: generateNewActionName(),
                    enabled: true,
                    field: null,
                    value: null,
                    typeName: 'ValueAssignActionWithFormula'
                } as ConditionalStatementEntities.IValueAssignActionWithFormula;
                break;
            }
            case 'ValueAssignActionWithSelection': {
                newAction = {
                    id: createGuid(),
                    name: generateNewActionName(),
                    enabled: true,
                    selectedField: null,
                    typeName: 'ValueAssignActionWithSelection'
                } as ConditionalStatementEntities.IValueAssignActionWithSelection;
                break;
            }
        }

        updateActions([
            ...rule.actions,
            newAction
        ], 'update');
    }

    const createNewAction = (e: any) => {
        switch (e.key) {
            case 'ShowMessageAction': {
                generateAction('ShowMessageAction');
                break;
            }
            case 'ValueAssignActionWithEntry': {
                generateAction('ValueAssignActionWithEntry');
                break;
            }
            case 'ValueAssignActionWithFormula': {
                generateAction('ValueAssignActionWithFormula');
                break;
            }
            case 'ValueAssignActionWithSelection': {
                generateAction('ValueAssignActionWithSelection');
                break;
            }
            default: console.warn('Action type not found');
        }

        e.domEvent.stopPropagation();
    }

    const getCollapseItems = (): ICollapseItem[] => {
        return [
            {
                content: renderActionsGroup(),
                expanded: true,
                key: 'actions',
                label: findMessage.get('100372')
            },
            {
                content: renderConditionsGroup(),
                expanded: true,
                key: 'conditions',
                label: findMessage.get('100304')
            }
        ];
    }

    const updateActions = (actions: ConditionalStatementEntities.IBaseAction[], type: 'update' | 'delete') => {

        let newRule = { ...rule };
        if (type === 'delete') {
            rule.statements.map(s => {
                s.actions = s.actions.filter(sa => {
                    return actions.find(a => a.id === sa.id) ? true : false;
                });
                return s
            });
            newRule.actions.map(ra => {

                // Delete approval and rejection actions
                if (ra.typeName === 'ConfirmationAction') {
                    (ra as ConditionalStatementEntities.IShowMessageAction).approvalActions = (ra as ConditionalStatementEntities.IShowMessageAction).approvalActions.filter(aa => actions.find(a => a.id === aa.id) ? true : false);
                    (ra as ConditionalStatementEntities.IShowMessageAction).rejectionActions = (ra as ConditionalStatementEntities.IShowMessageAction).rejectionActions.filter(aa => actions.find(a => a.id === aa.id) ? true : false);
                }

                return ra
            });
        }

        actions = actions.map(a => {

            // Update approval and rejection actions
            if (a.typeName === 'ConfirmationAction') {
                (a as ConditionalStatementEntities.IShowMessageAction).approvalActions = (a as ConditionalStatementEntities.IShowMessageAction).approvalActions.map(aa => actions.find(ra => ra.id === aa.id));
                (a as ConditionalStatementEntities.IShowMessageAction).rejectionActions = (a as ConditionalStatementEntities.IShowMessageAction).rejectionActions.map(aa => actions.find(ra => ra.id === aa.id));
            }

            return a

        });

        setRule({
            ...newRule,
            actions: actions,
            statements: newRule.statements.map(s => {
                s.actions = s.actions.map(a => {
                    return actions.find(found => found.id === a.id) || a;
                });
                return s
            })
        });
    }

    const onActionCopy = (id?: string) => {
        let cloneAction = rule.actions.find((x) => x.id === id);
        if (cloneAction) {
            cloneAction = { ...cloneAction, name: generateUniqueName(rule.actions.map(i => i.name), cloneAction.name + "_"), id: createGuid() };
            setRule({
                ...rule,
                actions: [...rule.actions, cloneAction]
            })
        }
    }

    const submitDetails = (e: IButtonClickEventArgs) => {
        if (validateRule(rule)) return;

        BCNotification.success({ message: findMessage.get('101972'), duration: 5 });
        props.onDataChanged?.(rule)

        e.event.stopPropagation();
    }

    const onPanelClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.onPanelClose?.();
        e.stopPropagation();
    }

    const onTitleChange = (args: IInputChangeEventArgs) => {
        setRule({
            ...rule,
            name: args.data
        })
    }

    const onTriggerEventsChanged = (_value: string[][], triggerOptions: any[]) => {
        const triggerEvents: string[] = [];

        for (const event of triggerOptions) {
            switch (event.length) {
                case 1: event[0].children.forEach(({ children }) => children.forEach(({ value }) => triggerEvents.push(value))); break;
                case 2: event[1].children.forEach(({ value }) => triggerEvents.push(value)); break;
                case 3: triggerEvents.push(event[2].value); break;
            }
        }

        setRule({
            ...rule,
            triggerEvents
        })
    }

    const renderHeader = () => {
        return (
            <div className={Styles.stackBarContentViewerHeader}>
                <BCInput
                    value={rule.name}
                    onChange={onTitleChange}
                    className={Styles.contentEditableTitle}
                />
                <BCButton
                    type={'link'}
                    icon={<IconSave />}
                    text={findMessage.get('100620')}
                    cssClass={Styles.doneButton}
                    onClick={submitDetails}
                />
                <div className={Styles.stackBarHeaderCommandButtonsContainer}>
                    <div className={Styles.stackBarButton} onClick={onPanelClose}>
                        <IconCloseWindow />
                    </div>
                </div>
            </div>
        )
    }

    const renderCollapseHeaderButtons = (key: string) => {
        switch (key) {
            case 'actions': {
                if (rule.actions && rule.actions.length) {
                    return (
                        <Dropdown trigger={['click']} overlay={
                            <Menu multiple={false} onClick={createNewAction} items={collapseButtonItems} />}
                        >
                            <BCButton
                                icon={<IconAdd />}
                                text={findMessage.get('101295')}
                                type={'link'}
                                size={'small'}
                                onClick={(e) => e.event.stopPropagation()}
                            />
                        </Dropdown>
                    )
                }
            }
        }
    }

    const renderCustomHeader = (item: ICollapseItem) => {
        return (
            <div className={Styles.collapseHeader}>
                <IconExpand className={"expandBtn"} />
                <IconCollapse className={"collapseBtn"} />
                <p>{item.label}</p>
                {renderCollapseHeaderButtons(item.key)}
            </div>
        )
    }

    const renderWhen = () => {
        const controlEventList: ITreeData[] = props.controlEventList?.toJS()?.sort((a, b) => a.value.localeCompare(b.value)) || [];

        const extractOptions = (_options: any[]) => _options.map(option => ({
            value: option.value,
            label: option.value?.split?.(".")?.pop?.() || option.value,
            externalData: option.externalData,
            children: option.children?.length ? extractOptions(option.children) : null
        }))

        const options = extractOptions(controlEventList)

        const tagRender = (props: any) => {
            return (
                <Tag
                    onMouseDown={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    closable={props.closable}
                    onClose={props.onClose}
                    visible
                    title={props.label}
                    style={{ fontSize: "14px" }}
                >
                    {props.value.split("__RC_CASCADER_SPLIT__").pop()}
                </Tag>
            );
        };

        return (
            <div className={Styles.editPanelFooter}>
                <BCLabel className={Styles.label}>{findMessage.get('101517')}</BCLabel>
                <div className={Styles.cascader}>
                    <BCCascader
                        style={cascaderStyle}
                        showSearch
                        options={options}
                        value={rule.triggerEvents?.map(e => e.split(".").map((_, index, self) => self.slice(0, index + 1).join(".")))}
                        onChange={onTriggerEventsChanged}
                        multiple
                        maxTagCount={"responsive"}
                        tagRender={tagRender}
                    />
                </div>
            </div>
        )

    }

    const collapsePanelClassNames = cx({
        editPanelCollapse: true,
        conditionsCollapsed: expandedKeys.length === 1 && expandedKeys.indexOf('actions') === -1,
        actionsCollapsed: expandedKeys.length === 1 && expandedKeys.indexOf('conditions') === -1,
        bothExpanded: expandedKeys.length === 2
    });

    return (
        <React.Fragment>
            {renderHeader()}
            {renderWhen()}
            <div className={Styles.editPanelContent}>
                <BCCollapse
                    items={getCollapseItems()}
                    classNames={collapsePanelClassNames.split(' ')}
                    expandedKeys={expandedKeys}
                    onChangeExpandedKeys={setExpandedKeys}
                    customHeader={renderCustomHeader}
                />
            </div>
        </React.Fragment>
    )



})

export default RuleEdit