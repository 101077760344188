// # RECENT APPS WIDGET ACTIONS/TRANSACTIONS
export const SET_RECENT_APPS_DATA_ACTION = "DASHBOARD.SET_RECENT_APPS_DATA_ACTION";
export const SET_RECENT_APPS_LOADING_ACTION = "DASHBOARD.SET_RECENT_APPS_LOADING_ACTION";
export const ON_CLICK_RECENT_APP_TRANSACTION = "DASHBOARD.ON_CLICK_RECENT_APP_TRANSACTION";

// # COUNT TILES WIDGET ACTIONS/TRANSACTIONS
export const SET_TILES_COUNTS_ACTION = "DASHBOARD.SET_TILES_COUNTS_ACTION";
export const SET_TILES_LOADING_ACTION = "DASHBOARD.SET_TILES_LOADING_ACTION";
export const SET_SELECTED_TILE_ACTION = "DASHBOARD.SET_SELECTED_TILE_ACTION";
export const ON_CHANGE_SELECTED_TILE_TRANSACTION = "DASHBOARD.ON_CHANGE_SELECTED_TILE_TRANSACTION";

// # RECENT APPS WIDGET ACTIONS/TRANSACTIONS
export const SET_RECENT_FILES_DATA_ACTION = "DASHBOARD.SET_RECENT_FILES_DATA_ACTION";
export const SET_RECENT_FILES_LOADING_ACTION = "DASHBOARD.SET_RECENT_FILES_LOADING_ACTION";
export const ON_CLICK_RECENT_FILE_TRANSACTION = "DASHBOARD.ON_CLICK_RECENT_FILE_TRANSACTION";
export const ON_CLICK_ALL_RECENT_FILES_TRANSACTION = "DASHBOARD.ON_CLICK_ALL_RECENT_FILES_TRANSACTION";

// # ANNOUNCEMENTS WIDGET ACTIONS/TRANSACTIONS
export const SET_ANNOUNCEMENTS_DATA_ACTION = "DASHBOARD.SET_ANNOUNCEMENTS_DATA_ACTION";
export const SET_ANNOUNCEMENTS_LOADING_ACTION = "DASHBOARD.SET_ANNOUNCEMENTS_LOADING_ACTION";
export const ON_CLICK_ANNOUNCEMENT_TRANSACTION = "DASHBOARD.ON_CLICK_ANNOUNCEMENT_TRANSACTION";
export const ON_CLICK_ALL_ANNOUNCEMENTS_TRANSACTION = "DASHBOARD.ON_CLICK_ALL_ANNOUNCEMENTS_TRANSACTION";

// # PROCESSES WIDGET ACTIONS/TRANSACTIONS
export const INSERT_PROCESSES_DATA_ACTION = "DASHBOARD.INSERT_PROCESSES_DATA_ACTION";
export const RESET_PROCESSES_DATA_ACTION = "DASHBOARD.RESET_PROCESSES_DATA_ACTION";
export const SET_SELECTED_PROCESS_ACTION = "DASHBOARD.SET_SELECTED_PROCESS_ACTION";
export const RESET_SELECTED_PROCESS_ACTION = "DASHBOARD.RESET_SELECTED_PROCESS_ACTION";
export const ON_CLICK_PROCESS_TRANSACTION = "DASHBOARD.ON_CLICK_PROCESS_TRANSACTION";
export const ON_LOAD_PROCESSES_TRANSACTION = "DASHBOARD.ON_LOAD_PROCESSES_TRANSACTION";
export const SET_PROCESSES_LOADING_ACTION = "DASHBOARD.SET_PROCESSES_LOADING_ACTION";

// # PROCESS DETAILS WIDGET ACTIONS/TRANSACTIONS
export const INSERT_PROCESS_DETAILS_DATA_ACTION = "DASHBOARD.INSERT_PROCESS_DETAILS_DATA_ACTION";
export const SET_PROCESS_DETAILS_STRUCTURE_ACTION = "DASHBOARD.SET_PROCESS_DETAILS_STRUCTURE_ACTION";
export const RESET_PROCESS_DETAILS_DATA_ACTION = "DASHBOARD.RESET_PROCESS_DETAILS_DATA_ACTION";
export const ON_CLICK_PROCESS_DETAIL_TRANSACTION = "DASHBOARD.ON_CLICK_PROCESS_DETAIL_TRANSACTION";
export const ON_LOAD_PROCESS_DETAILS_TRANSACTION = "DASHBOARD.ON_LOAD_PROCESS_DETAILS_TRANSACTION";
export const SET_PROCESS_DETAILS_LOADING_ACTION = "DASHBOARD.SET_PROCESS_DETAILS_LOADING_ACTION";

export const REFRESH_DASHBOARD_DATA_TRANSACTION = "DASHBOARD.REFRESH_DASHBOARD_DATA_TRANSACTION";
export const SET_DASHBOARD_WILL_REFRESH_DATA_ACTION = "DASHBOARD.SET_DASHBOARD_WILL_REFRESH_DATA_ACTION";
export const SET_DASHBOARD_FORM_DATA_ACTION = "DASHBOARD.SET_DASHBOARD_FORM_DATA_ACTION";