import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { IMethodInvokeActionField, MethodInvokeActionField, MethodInvokeActionFieldFactory, MethodInvokeActionFieldFactoryFromJS } from "../IMethodInvokeActionField";
import { BaseActionFactoryObject, BaseActionFactoryObjectFromJS, IBaseAction } from "./IBaseAction";

interface IBaseMethodInvokeAction extends IBaseAction {
}

export interface IMethodInvokeAction extends IBaseMethodInvokeAction {
    field: IMethodInvokeActionField
}

export interface ReadOnlyMethodInvokeAction extends IBaseMethodInvokeAction {
    field: MethodInvokeActionField
}

export type MethodInvokeAction = ReadonlyRecord<ReadOnlyMethodInvokeAction>;

export const MethodInvokeActionFactory = Record<ReadOnlyMethodInvokeAction>({
    field: MethodInvokeActionFieldFactory(),
    ...BaseActionFactoryObject
});

export const MethodInvokeActionFactoryFromJS = function (data: IMethodInvokeAction) {
    return MethodInvokeActionFactory({
        ...BaseActionFactoryObjectFromJS(data),
        field: MethodInvokeActionFieldFactoryFromJS(data.field)
    });
}