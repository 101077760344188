import { BCNotification, findMessage } from "@bimser/components";
import { IBaseAction } from "@bimser/core";
import { put, takeEvery, call } from 'redux-saga/effects';
import { ServiceList } from "../../../services/serviceList";
import { ActionTypes, changeLoginLoadingStatusAction, setMFAProperties } from '../actions';
import { ILoginResponseWithMFA, LoginWithTokenPayload } from "../entities";
import commonLoginHandler from "./commonLoginHandler";


export default function* watchLoginWithTokenTransaction() {
    yield takeEvery(ActionTypes.LOGIN_WITH_TOKEN_TRANSACTION, loginWithTokenTransaction);
}

export function* loginWithTokenTransaction(action: IBaseAction<LoginWithTokenPayload>) {
    try {
        let request: any = {
            accessToken: action.payload.token,
            language: action.payload.language
        }
        if (window.location.hash.includes("/OAuth")) {
            request.useAuthCode = true;
        }
        let response: ILoginResponseWithMFA = yield ServiceList.login.Login.LoginWithAccessToken.call(request, true, null, true);

        if (response?.data?.mfaEnabled) {
            yield put(setMFAProperties({ ...response.data, currentLoginProcedure: "token" }));
        } else {
            yield call(commonLoginHandler, request, response, "token")
        }

    } catch (error) {
        BCNotification.error({
            message: findMessage.get('100595')
        });
        yield put(changeLoginLoadingStatusAction(false));
    }
}