import { BCButton, BCCombobox, BCDropdownWrapper, BCInput, BCInputRef, BCScrollbars, BCSwitch, BCTextArea, findMessage, IComboboxSelectedChangeEventArgs, IInputChangeEventArgs, IMenuItemClickEventArgs, IMenuProps, ITextAreaChangeEventArgs } from '@bimser/components';
import { createGuid, IItem, MLHelper } from '@bimser/core';
import { GetIconComponent } from "@bimser/icons";
import DigitalSignatureIcon from "@bimser/icons/16/digital-signed";
import IconPasswordField from "@bimser/icons/16/password";
import IconPositionGroup from "@bimser/icons/16/position-group";
import IconQrCode from "@bimser/icons/16/qr-code";
import RefreshIcon from '@bimser/icons/16/refresh';
import IconToken from "@bimser/icons/16/tag";
import IconTreeArrowDown from "@bimser/icons/16/tree-arrow-down";
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import { Mobile, SignatureFormat } from '../../..';
import { ILanguage } from '../../../system/language/entities';
import { default as MessageBox } from "../../../utilities/messageBoxManager";
import DigitalSignatureContainer from '../../DigitalSignature/containers';
import MLSelectorContainer from '../../MLSelector';
import MobileSignatureContainer from '../../MobileSignature/containers';
import IStartMobileLoginPayload from '../../MobileSignature/entities/IStartMobileLoginPayload';
import { MessageBoxIcon } from "../../ModalManager/enums/MessageBoxIcon";
import BimserLogo from '../assets/img/bimser-logo.svg';
import GoogleLogo from '../assets/img/google-logo.svg';
import GoogleLoginLogo from '../assets/img/google.png';
import LinkedInLogo from '../assets/img/linkedin-logo.svg';
import LinkedInLoginLogo from '../assets/img/linkedin.png';
import MicrosoftLogo from '../assets/img/microsoft-logo.svg';
import MicrosoftLoginLogo from '../assets/img/microsoft.png';
import * as Styles from '../assets/style.scss';
import ChangePasswordContainer from '../containers/ChangeMyPasswordContainer';
import ForgetMyPasswordContainer from '../containers/ForgetMyPasswordContainer';
import MultiFactorAuthContainer from "../containers/MultiFactorAuthContainer";
import { ILoginComponentCProps, ILoginComponentProps, ILoginComponentState, ILoginMethod, LoginType } from '../entities';
import ILoginWithDigitalSignaturePayload from '../entities/ILoginWithDigitalSignaturePayload';
import getURLParams from '../sagas/getURLParams';
import ServerInfoWithQr from './ServerInfoWithQr';

export default class Login extends React.Component<ILoginComponentProps & ILoginComponentCProps, ILoginComponentState> {

    txtEmail = React.createRef<BCInputRef>();
    txtPassword = React.createRef<BCInputRef>();

    langMenuProps: { items: IItem[]; } = {
        items: []
    };

    constructor(props: ILoginComponentProps & ILoginComponentCProps) {
        super(props);
        this.onRememberMeClick = this.onRememberMeClick.bind(this);
        this.onTxtEmailChange = this.onTxtEmailChange.bind(this);
        this.onTxtPasswordChange = this.onTxtPasswordChange.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onLanguageSelected = this.onLanguageSelected.bind(this);
        this.onClickForgetMyPassword = this.onClickForgetMyPassword.bind(this);
        this.onClickServerInfoWithQr = this.onClickServerInfoWithQr.bind(this);
        this.renderLanguageMenuItem = this.renderLanguageMenuItem.bind(this);
        this.onTokenInputChange = this.onTokenInputChange.bind(this);
        this.renderLoginType = this.renderLoginType.bind(this);
        this.renderLoginTypeItem = this.renderLoginTypeItem.bind(this);
        this.onLoginWithDigitalSignatureClick = this.onLoginWithDigitalSignatureClick.bind(this);
        this.onLoginWithMobileSignature = this.onLoginWithMobileSignature.bind(this);
        this.getLoginType = this.getLoginType.bind(this);
        this.getForgetPasswordLinkElement = this.getForgetPasswordLinkElement.bind(this);
        this.renderQrCode = this.renderQrCode.bind(this);
        this.getRememberMeLine = this.getRememberMeLine.bind(this);
        this.getLoginButton = this.getLoginButton.bind(this);
        this.getLanguageDropdown = this.getLanguageDropdown.bind(this);
        this.renderCaptcha = this.renderCaptcha.bind(this);
        this.onClickCaptchaRefresh = this.onClickCaptchaRefresh.bind(this);
        this.onCaptchaTextChanged = this.onCaptchaTextChanged.bind(this);
        this.getloginTypeMenuItems = this.getloginTypeMenuItems.bind(this);
        this.onClickLoginType = this.onClickLoginType.bind(this);
        this.renderDomainSelection = this.renderDomainSelection.bind(this);
        this.onDomainSelected = this.onDomainSelected.bind(this);


        this.langMenuProps.items = this.props.supportedLanguages.map((i) => {
            return { key: i.culture, text: i.name, value: i.culture, customTemplate: this.renderLanguageMenuItem } as IItem;
        });

        let remember = localStorage.getItem("rememberMe");
        let externalAccountType = localStorage.getItem("externalAccountType");

        this.state = {
            rememberMe: remember == "1",
            email: '',
            password: '',
            width: window.innerWidth,
            height: window.innerHeight,
            showErrorMessageBox: false,
            id: createGuid(),
            token: '',
            loginType: this.getLoginType(),
            externalAccountType: externalAccountType || "Azure AD",
            showingContentType: this.props.hash == "resetPassword" ? "ChangePassword" : "login",
            captchaText: "",
            selectedDomain: this.props.domainProperties.domainVisible ? this.props.domainProperties.defaultDomain : null,
        };
    }

    getloginTypeMenuItems() {

        let items: IItem[] = [
            {
                key: "usernamePassword",
                text: findMessage.get("101973"),
                value: "usernamePassword",
                icon: IconPasswordField.info,
                customTemplate: this.renderLoginTypeItem
            },
            {
                key: "token",
                text: findMessage.get("100626"),
                value: "token",
                icon: IconToken.info,
                customTemplate: this.renderLoginTypeItem
            }
        ];

        if (this.props.loginFormProperties.eSignVisible) {
            items.push(
                {
                    key: "digitalSignature",
                    text: findMessage.get("101187"),
                    value: "digitalSignature",
                    icon: DigitalSignatureIcon.info,
                    customTemplate: this.renderLoginTypeItem
                }
            );
        }
        if (this.props.loginFormProperties.mobileSignVisible) {
            items.push(
                {
                    key: "mobileSignature",
                    text: findMessage.get('102137'),
                    value: "mobileSignature",
                    icon: DigitalSignatureIcon.info,
                    customTemplate: this.renderLoginTypeItem
                }
            );
        }
        if (this.props.loginFormProperties.additionalLoginMethods.filter(loginMethod => loginMethod.enabled)?.length) {
            items.push(
                {
                    key: "externalAccounts",
                    text: findMessage.get('102239'),
                    value: "externalAccounts",
                    icon: IconPositionGroup.info,
                    customTemplate: this.renderLoginTypeItem
                }
            );
        }

        return { items, onClick: this.onClickLoginType } as IMenuProps;
    }

    onClickLoginType(args: IMenuItemClickEventArgs) {
        if (args.data.key != this.state.loginType) {
            this.setState({
                loginType: args.data.key
            });
        }
    }

    getLoginType(): LoginType {
        let type = localStorage.getItem("loginMethod");
        if (!type) {
            type = this.props.loginFormProperties.defaultLoginMethod;
            if (!type || type?.length == 0) {
                type = "usernamePassword";
            }
        }
        switch (type) {
            case "Google":
            case "LinkedId":
            case "Azure AD": {
                return "externalAccounts";
            }
            default: {
                return type as LoginType;
            }
        }
    }

    showErrorMessageBox() {
        if (this.state.width < 768 && !this.state.showErrorMessageBox) {
            MessageBox.show({ id: this.state.id, title: findMessage.get("100652"), messageType: MessageBoxIcon.Warning, content: findMessage.get("101324"), buttons: [], props: { closable: false, maskClosable: false } });
            this.setState({ showErrorMessageBox: true });
        }
        else if (this.state.width > 767) {
            this.setState({ showErrorMessageBox: false });
            MessageBox.closeModal(this.state.id);
        }
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.showErrorMessageBox();
    };

    componentDidMount() {
        let queryData = getURLParams();
        if (queryData) {
            this.setState({
                email: queryData.username,
            });
            this.txtPassword?.current?.focus();
            window.location.hash = "#/";
        } else this.txtEmail?.current?.focus();

        if (location && location.pathname && location.pathname.toLowerCase().indexOf("ide") > -1) {
            window.addEventListener('resize', this.updateDimensions);
            this.showErrorMessageBox();
        }
        Mobile.MobileView.setRememberMe(Boolean(JSON.parse(localStorage.getItem('rememberMe'))));
        Mobile.MobileView.setSelectedCulture(localStorage.getItem('locale'));
        Mobile.MobileView.closeLoginLoading();
    }

    componentDidUpdate(prevProps: ILoginComponentProps) {
        if (this.props.captcha.showCaptcha && this.props.captcha.captchaId != prevProps.captcha.captchaId) {
            this.setState({ captchaText: "" });
        }
        if (!prevProps.MFAStatus && this.props.MFAStatus) {
            this.setState({ showingContentType: "MultiFactorAuth" });
        }
    }

    onClickForgetMyPassword() {
        this.setState({ showingContentType: "ForgetMyPassword" });
    }

    onClickServerInfoWithQr() {
        this.setState({ showingContentType: "ServerInfoQR" });
        this.props.onResetMFAProperties()
    }

    private onLoginClick() {
        let lang = this.langMenuProps.items.find((i) => i.key == this.props.selectedCulture);
        let captcha: any = {};
        if (this.props.captcha.showCaptcha) {
            if (this.state.captchaText.length > 0) {
                captcha = {
                    captchaId: this.props.captcha.captchaId,
                    captcha: this.state.captchaText
                };
            } else {
                return;
            }
        }
        if (this.state.loginType == "usernamePassword") {
            if (this.props.onChangeLoadingStatus && this.props.onLoginClick && this.txtEmail.current?.input.value.length > 0 && this.txtPassword.current?.input.value.length > 0) {
                this.props.onChangeLoadingStatus(true);
                let domain = this.state.selectedDomain;
                let _username = this.props.domainProperties.domainVisible ? domain + "\\" + this.txtEmail.current?.input.value : this.txtEmail.current?.input.value
                if (domain === "nodomain") {
                    _username = this.txtEmail.current?.input.value;
                }

                this.props.onLoginClick({
                    language: (lang?.value || "en-US"),
                    username: _username,
                    password: this.txtPassword.current?.input.value,
                    rememberMe: this.state.rememberMe,
                    ...captcha
                });
            }
        } else if (this.state.loginType == "token") {
            this.props.onChangeLoadingStatus(true);
            this.props.onLoginWithToken({
                language: lang?.value || "en-US",
                token: this.state.token,
                rememberMe: this.state.rememberMe,
                ...captcha
            });
        }
    }

    onLoginWithDigitalSignatureClick(response: ILoginWithDigitalSignaturePayload) {
        this.props.onLoginWithDigitalSignature({ ...response, bimserLanguage: this.props.selectedCulture });
    }

    onLoginWithMobileSignature(data: IStartMobileLoginPayload) {
        this.props.onLoginWithMobileSignature({ ...data, bimserLanguage: this.props.selectedCulture });
    }

    private onRememberMeClick(value: boolean) {
        this.setState({ rememberMe: value });
        let rememberMe = value ? "1" : "0";
        localStorage.setItem("rememberMe", rememberMe);
        Mobile.MobileView.setRememberMe(value);
    }

    private onTxtEmailChange(args: IInputChangeEventArgs) {
        this.setState({ email: args.data });
    }

    private onTxtPasswordChange(args: IInputChangeEventArgs) {
        this.setState({ password: args.data });
    }

    private onLanguageSelected(args: IMenuItemClickEventArgs) {
        if (args && args.data && args.data.value) {
            this.props.onChangeSelectedCulture(args.data.value);
        }
    }

    renderLanguageMenuItem(item: IItem) {
        const onItemClick = () => {
            Mobile.MobileView.setSelectedCulture(item.value);
            this.props.onChangeSelectedCulture(item.value);
        };
        return (
            <div onClick={onItemClick} className={Styles.langItem}>
                <div className={Styles.checkMarkContainer}>
                    {this.props.selectedCulture == item.value && <div className={Styles.checkMark} />}
                </div>
                <b style={{ marginRight: "5px" }}>{item.value.split("-")[1]}</b> {item.text}
            </div>
        );
    }

    renderLoginTypeItem(item: IItem) {
        return (
            <div className={Styles.loginTypeItem}>
                {GetIconComponent(item.icon)}
                <span className={Styles.itemText}>{item.text}</span>
            </div>
        );
    }

    onTokenInputChange(args: ITextAreaChangeEventArgs) {
        this.setState({ token: args.data });
    }

    onClickAdditionalLoginButton(method: ILoginMethod) {
        if (this.props.onLoginwithSocialMedia) {
            this.props.onLoginwithSocialMedia(method);
        }
    }

    onClickCaptchaRefresh() {
        if (this.props.onClickRefreshCaptcha) {
            this.props.onClickRefreshCaptcha(this.props.selectedCulture);
            this.setState({ captchaText: "" });
        }
    }

    onCaptchaTextChanged(args: IInputChangeEventArgs) {
        this.setState({ captchaText: args.data });
    }

    renderCaptcha() {
        if (this.props.captcha.showCaptcha) {
            return (
                <div className={Styles.line}>
                    <div className={Styles.captchaImageContainer}>
                        <img src={"data:image/png;base64," + this.props.captcha.captchaImage} />
                    </div>
                    <div className={Styles.label}>{findMessage.get('102540')}</div>
                    <div>
                        <BCInput
                            value={this.state.captchaText}
                            onChange={this.onCaptchaTextChanged}
                            addonAfter={<RefreshIcon height="30px" onClick={this.onClickCaptchaRefresh} />}
                        />
                    </div>
                </div>
            );
        }
        return <></>;
    }

    onDomainSelected(args: IComboboxSelectedChangeEventArgs) {
        this.setState({ selectedDomain: args.data.toString() });
    }

    renderDomainSelection() {
        if (!this.props.domainProperties.domainVisible) return;
        let options = Object.keys(this.props.domainProperties.domains).map(key => {
            return {
                key: key,
                text: MLHelper.getMLText(this.props.domainProperties.domains[key] || key),
                value: key
            }
        })

        return (
            <BCCombobox
                className={Styles.domainSelection}
                value={this.state.selectedDomain}
                defaultValue={this.props.domainProperties.defaultDomain || options[0]?.value}
                showSearch={false}
                options={options}
                onSelectedChange={this.onDomainSelected}
            />
        )
    }

    renderLoginType() {
        switch (this.state.loginType) {
            case "usernamePassword": {
                return (
                    <>
                        <div className={Styles.line}>
                            <div className={Styles.label}>{findMessage.get("100722")}</div>
                            <BCInput
                                addonBefore={this.renderDomainSelection()}
                                ref={this.txtEmail}
                                value={this.state.email}
                                placeHolder={findMessage.get("100722")}
                                disabled={this.props.domainProperties.domainVisible && !this.state.selectedDomain}
                                onChange={this.onTxtEmailChange} />

                        </div>
                        <div className={Styles.line}>
                            <div className={Styles.label}>{findMessage.get("100627")}</div>
                            <BCInput
                                ref={this.txtPassword}
                                onPressEnter={this.onLoginClick}
                                type={'password'}
                                value={this.state.password}
                                placeHolder={findMessage.get("100627")}
                                onChange={this.onTxtPasswordChange}
                                autoComplete="current-password"
                            />
                        </div>
                        {this.renderCaptcha()}
                    </>
                );
            }
            case "token": {
                return (
                    <>
                        <div className={Styles.line}>
                            <div className={Styles.label}>{findMessage.get('100626')}</div>
                            <BCTextArea
                                rows={3}
                                autosize={{ minRows: 4, maxRows: 4 }}
                                value={this.state.token}
                                onChange={this.onTokenInputChange}
                                placeHolder={findMessage.get('100626')}
                            />
                        </div>
                        {this.renderCaptcha()}
                    </>
                );
            }
            case "digitalSignature": {
                return (
                    <div>
                        <DigitalSignatureContainer
                            id="login"
                            signatureOptions={{
                                type: "login",
                                parameters: {
                                    licenceKey: this.props.loginFormProperties.signatureComponentLicense,
                                    language: this.props.selectedCulture
                                },
                                signatureFormat: SignatureFormat.CAdES
                            }}
                            compactMode
                            onLogin={this.onLoginWithDigitalSignatureClick} />
                    </div>
                );
            }
            case "mobileSignature": {
                return (
                    <div>
                        <MobileSignatureContainer
                            id={"login"}
                            loginMode
                            onLogin={this.onLoginWithMobileSignature}
                            loading={this.props.loadingStatus}
                            fingerPrint={this.props.mobileSignatureFingerprint}
                        />
                    </div>
                );
            }
            case "externalAccounts": {
                return (
                    <div className={Styles.externalAccountContainer}>
                        <img className={Styles.providerLogo} src={this.getExternalLoginLogo()} />
                        <span className={Styles.providerName} >{findMessage.get('102235').replace("$1", this.state.externalAccountType)}</span>
                        <BCButton type='primary' disabled={this.props.loginFormProperties.additionalLoginMethods.length == 0} text={findMessage.get("100747")} onClick={() => {
                            let method = this.props.loginFormProperties.additionalLoginMethods.find(i => i.name == this.state.externalAccountType);
                            if (method) {
                                this.onClickAdditionalLoginButton(method);
                            }
                        }} />
                        <span className={Styles.providerRedirectWarning}>{findMessage.get('102236').replace("$1", this.state.externalAccountType)}</span>
                    </div>
                );
            }
        }
    }

    getMiniExternalLoginLogo(name: string) {
        switch (name) {
            case "Azure AD": {
                return (MicrosoftLogo);
            }
            case "Google": {
                return (GoogleLogo);
            }
            case "LinkedIn": {
                return (LinkedInLogo);
            }
            default:
                return ("");
        }
    }

    getExternalLoginLogo() {
        switch (this.state.externalAccountType) {
            case "Azure AD": {
                return MicrosoftLoginLogo;
            }
            case "Google": {
                return GoogleLoginLogo;
            }
            case "LinkedIn": {
                return LinkedInLoginLogo;
            }
        }
    }

    getForgetPasswordLinkElement(clasName: string) {
        if (this.props.loginFormProperties?.forgottenPasswordVisible) {
            return (
                <a className={clasName} onClick={this.onClickForgetMyPassword}>
                    {findMessage.get("100746")}
                </a>
            );
        }
    }

    getRememberMeLine() {
        if (this.state.loginType != "externalAccounts") {
            return (
                <div className={Styles.rememberMeline}>
                    {this.props.loginFormProperties.rememberMeVisible &&
                        <>
                            <span className={Styles.rememberMeLabel}>{findMessage.get("100745")}</span>
                            <BCSwitch onChange={this.onRememberMeClick} checked={this.state.rememberMe} />
                        </>
                    }
                    {this.getForgetPasswordLinkElement(Styles.link)}
                </div>
            );
        }
    }

    getLoginButton() {
        if (["digitalSignature", "mobileSignature", "externalAccounts"].indexOf(this.state.loginType) == -1) {
            let disabled = false;
            if (this.state.loginType == "token") {
                disabled = this.state.token.length == 0;
            } else if (this.state.loginType == "usernamePassword") {
                disabled = this.txtPassword?.current?.input.value.length == 0 || this.txtEmail?.current?.input.value.length == 0 || (this.props.captcha.showCaptcha && this.state.captchaText.length == 0);
            }
            return (
                <div className={Styles.line}>
                    <BCButton
                        disabled={disabled}
                        loading={this.props.loadingStatus ? { delay: 100 } : false}
                        type='primary'
                        text={findMessage.get("100747")}
                        onClick={this.onLoginClick}
                    />
                </div>
            );
        }
    }

    renderContent() {
        let additionalLoginMethods = this.props.loginFormProperties.additionalLoginMethods.filter(loginMethod => this.state.loginType == "externalAccounts" ? loginMethod.name != this.state.externalAccountType : true);
        let lang = this.langMenuProps.items.find((i) => i.key == this.props.selectedCulture);

        switch (this.state.showingContentType) {
            case "ServerInfoQR":
                return (<>
                    <ServerInfoWithQr onClickLoginPanel={() => {
                        this.setState({ showingContentType: "login" });
                    }} />
                </>
                );
            case "ForgetMyPassword":
                return (<>
                    <ForgetMyPasswordContainer onClickLoginPanel={() => {
                        this.setState({ showingContentType: "login" });
                    }} />
                </>
                );
            case "ChangePassword":
                let tokenId = this.props.urlPath && this.props.urlPath.includes("resetPassword") ? location.hash.split("/resetPassword/")[1] : null;
                return (<>
                    <ChangePasswordContainer onClickLoginPanel={() => {
                        this.setState({ showingContentType: "login" });
                    }} tokenId={tokenId} />
                </>
                );
            case "MultiFactorAuth":
                return (
                    <MultiFactorAuthContainer
                        onClickLoginPanel={() => { this.setState({ showingContentType: "login" }); }}
                        selectedLang={lang?.value || "en-US"}
                        onResetMFAProperties={this.props.onResetMFAProperties}
                    />
                );
            case "login":
            default:
                return (
                    <div className={Styles.loginTypeContent}>
                        <BCScrollbars autoHide>
                            <div className={Styles.scrollableArea}>
                                <div className={Styles.loginTypeSelectorWrapper}>
                                    <div className={Styles.title}>{findMessage.get("100741")}</div>
                                    <BCDropdownWrapper
                                        overlayClassName={Styles.loginTypeOverlay}
                                        trigger={this.dropdownWrapperTrigger}
                                        placement={"bottomRight"}
                                        menuProps={this.getloginTypeMenuItems()}
                                        className={Styles.langDropContainer}
                                    >
                                        <div className={Styles.loginTypeSelector}>
                                            <IconTreeArrowDown className={Styles.IconTreeArrowDown} />
                                        </div>
                                    </BCDropdownWrapper>
                                </div>
                                {this.renderLoginType()}
                                {this.getRememberMeLine()}
                                {this.getLoginButton()}
                                {additionalLoginMethods.length > 0 &&
                                    <div className={Styles.externalAccountLoginContainer}>
                                        <span className={Styles.externalAccountLoginTitle}>{findMessage.get('102234')}</span>
                                        <div className={Styles.externalAccountLoginIcons}>
                                            {additionalLoginMethods.map(loginMethod => {
                                                return (
                                                    <img key={loginMethod.name} className={Styles.externalAccountLoginIcon} src={this.getMiniExternalLoginLogo(loginMethod.name)} height={24} width={24} onClick={() => {
                                                        this.onClickAdditionalLoginButton(loginMethod);
                                                    }} />
                                                );
                                            })}
                                        </div>
                                    </div>
                                }
                                {this.getForgetPasswordLinkElement(Styles.linkMobile)}
                            </div>
                        </BCScrollbars>
                    </div>
                );
        }
    }

    dropdownWrapperTrigger: ('click' | 'hover' | 'contextMenu')[] = ['click'];

    renderQrCode() {
        return (<div className={Styles.qrLinkRow}>
            <div className={Styles.qrCol}>
                <IconQrCode height="24px" width="24px" />
                <a className={Styles.qrLink} onClick={this.onClickServerInfoWithQr}>
                    {findMessage.get('101931')}
                </a>
            </div>
        </div>);
    }

    getLanguageDropdown() {
        let lang = this.langMenuProps.items.find((i) => i.value == this.props.selectedCulture);
        let langCode = this.props.selectedCulture.split("-");
        if (!lang) {
            let defaultLang: ILanguage = this.props.supportedLanguages.find(q => q.isDefault);
            lang = this.langMenuProps.items.find((i) => i.value == defaultLang.culture);
            langCode = defaultLang.culture.split("-");
        }
        if (this.props.canChangeLanguage) {
            return (
                <div className={Styles.languageSelection}>
                    <BCDropdownWrapper
                        trigger={this.dropdownWrapperTrigger}
                        placement={"bottomRight"}
                        menuProps={this.langMenuProps}
                    >
                        <div className={Styles.langBtn}>
                            <div className={"loginMLIconDropdown"} />
                            <div className={Styles.selectedLang}>
                                <b style={{ marginRight: "5px" }}>{langCode[1]}</b> {lang.text}
                            </div>
                            <div className={"arrowDown"} />
                        </div>
                    </BCDropdownWrapper>
                </div>
            );
        }
    }

    private generateTemplate(): JSX.Element {
        return (
            <React.Fragment>
                <div className={Styles.loginContainer}>
                    <div className={Styles.rightHeader}>
                        {this.renderQrCode()}
                        {this.getLanguageDropdown()}
                    </div>
                    {this.renderContent()}
                    <div className={Styles.loginFooter}>
                        <img src={this.props.tenant.logoBottom ? this.props.tenant.logoBottom : BimserLogo} className={Styles.logo} />
                        <div>© Copyright - {this.props.tenant?.productName && Object.keys(this.props.tenant.productName).length > 0 ? <MLSelectorContainer data={this.props.tenant.productName} /> : `${new Date().getFullYear()} Bimser Çözüm - `} {findMessage.get("100740")}</div>
                        <div>V{this.props.version}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    public render() {
        return (
            this.generateTemplate()
        );
    }
}
