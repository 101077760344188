import { BCAjax, findMessage } from "@bimser/components";
import { createGuid, IBaseAction } from "@bimser/core";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { SSO_CONTROL_TRANSACTION_ACTION } from "../actions/actionTypes";
import * as ActionTypes from "../actions/actionTypes";
import { MessageBox, ServiceList } from "../../..";

import { createElement } from 'react';
import { LoadingModalContent } from '../components/LoadingModalContent';
import { closeModalTransactionAction } from "../../ModalManager/actions";
import generalInitializerTransactionAction from "../../../action/generalInitializerTransactionAction";
import { BaseState } from "../../../entities/BaseState";
import { LoginFormProperties } from "../entities/LoginFormProperties";
import { loginAction, initializeApplicationStartup, getUserStartupTransactionAction } from '../actions';

function* watchSSOControlTransaction() {
    yield takeLatest(SSO_CONTROL_TRANSACTION_ACTION, handleOnSSOControl)
}

export default watchSSOControlTransaction;

function* handleOnSSOControl(action: IBaseAction<any>) {
    let waitLoginParameters = !location.hash.includes("_redirect");

    if (window.location.hash.includes("/OAuth")) {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
    }

    if (window.location.hash.includes("/_redirect")) {
        //_redirect mevcutsa önceki oturum silinecek.
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");

        yield take(ActionTypes.LOGIN_PARAMETERS);
        let loadingModalId = createGuid();

        showRedirectLoadingModal(loadingModalId, findMessage.get("102006"));
        try {
            let splittedHash = window.location.hash.split("/");
            let redirectIndex = splittedHash.findIndex(h => h == "_redirect");
            let shortlinkIndex = redirectIndex + 1;
            if (splittedHash[shortlinkIndex] && ["ignoreSSO", "autoLogin", "#"].indexOf(splittedHash[shortlinkIndex]) == -1) {
                let shortLinkId = splittedHash[shortlinkIndex];
                let linkResponse = yield ServiceList.main.Shared.ExpandLink.call({
                    linkId: shortLinkId
                }, true, undefined, true);
                if (linkResponse && linkResponse.data) {
                    if (linkResponse.headers["authorization"]) {
                        let token = linkResponse.headers["authorization"].split("Bearer ")[1];
                        if (token && token.length) {
                            yield put(loginAction({ token }));
                            let headers = {
                                'bimser-encrypted-data': linkResponse.headers["bimser-encrypted-data"],
                                'bimser-language': linkResponse.headers["bimser-language"],
                                "Authorization": "Bearer " + token,
                            }

                            BCAjax.setHeaders(headers);
                            sessionStorage.setItem("redirectData", JSON.stringify(linkResponse.data));

                            yield put(closeModalTransactionAction(loadingModalId));

                            yield put(initializeApplicationStartup());
                            yield take(ActionTypes.INITIALIZED_APPLICATION_STARTUP);

                            yield put(getUserStartupTransactionAction());
                            yield put(generalInitializerTransactionAction());

                            return;
                        }
                    } else {
                        // _redirect'de token alınamadıysa link manipüle edilmiş olabilir.
                        location.hash = location.hash.replace("_redirect", "redirect");

                    }
                } else {
                    // ExpandLink error. Link manipüle edilmiş, süresi dolmuş veya request limitine takılmış olabilir
                    location.hash = location.hash.replace("_redirect", "redirect");

                }
            }
            yield put(closeModalTransactionAction(loadingModalId));
        } catch (error) {
            console.log("🚀 -> function*handleOnSSOControl -> error", error)
            yield put(closeModalTransactionAction(loadingModalId));
        }
    }


    if (window.location.hash.includes("/autoLogin")) {
        yield call(runAutoLogin, waitLoginParameters);
    } else {
        if (waitLoginParameters) yield take(ActionTypes.LOGIN_PARAMETERS);

        let loginFormProperties: LoginFormProperties = yield select((state: BaseState) => state.user.loginFormProperties);
        if (loginFormProperties.autoLoginWithMainOAuthProvider && !location.hash.includes("/ignoreSSO")) {
            yield call(runAutoLogin);
        }
    }
}

function* runAutoLogin(waitLoginParameters = false) {
    let redirectHash = window.location.hash.replace("/autoLogin", "");
    sessionStorage.setItem("ssoRedirectHash", redirectHash);

    let _token = sessionStorage.getItem("token") || localStorage.getItem("token");
    if (_token) {
        // zaten mevcut oturum var ise SSO yönlendirmelerine gerek yok.
        location.hash = redirectHash;
    } else {
        let loadingModalId = createGuid();
        showRedirectLoadingModal(loadingModalId, findMessage.get("102006"));

        if (waitLoginParameters) {
            yield take(ActionTypes.LOGIN_PARAMETERS);
        }

        let loginFormProperties: LoginFormProperties = yield select((state: BaseState) => state.user.loginFormProperties);
        if (loginFormProperties.additionalLoginMethods.size == 0) {
            // externalLoginMethod yoksa autoLogin iptal edilip normal login akışına devam edilecek.
            location.hash = redirectHash;
        }

        let ssoOption = localStorage.getItem("externalAccountType");
        if (!ssoOption) {
            ssoOption = loginFormProperties.defaultLoginMethod;
        }

        let defaultSSO = loginFormProperties.additionalLoginMethods.find(m => m.name == ssoOption);

        if (defaultSSO) {
            // SSO'ya yönlendirme yapılıyor
            location.href = defaultSSO.url;
        } else {
            // defaultSSO'u bulamazsa autoLogin iptal edilip normal login akışına devam edilecek.
            location.hash = redirectHash;
        }
    }
}

function showRedirectLoadingModal(modalId: string, description: string) {
    MessageBox.show({
        id: modalId,
        content: createElement(LoadingModalContent, { description }),
        props: {
            closable: false,
            maskClosable: false,
            keyboard: false,
            footer: null,
            title: null,
            renderHeader: () => null
        },
        width: "400px",
        buttons: null,
        notInCloseIcon: true
    });
}