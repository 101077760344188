import { Map } from "immutable";
import { reorder } from ".";
import { NormalizedLayoutStructureItems } from "../../../common/entities";

export const reorderOnView = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    startIndex: number,
    endIndex: number
) => {
    const view = views.get(activeView);
    const rootItemId = view.result.get(0);

    return reorder(views, activeView, rootItemId, startIndex, endIndex);
};