import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseModalState {
    type: string,
    id: string,
    visible: boolean,
    width?: string,
    props?: any
}

export interface IModalState extends IBaseModalState {

}

export interface IReadonlyModalState extends IBaseModalState {
}

export type ModalState = ReadonlyRecord<IReadonlyModalState>;

export const ModalStateFactory = Record<IReadonlyModalState>({
    type: null,
    id: null,
    visible: true,
    props: null,
    width: null
});

export const ModalStateFactoryFromJS = function (data: IModalState) {
    return ModalStateFactory(data);
}