import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IReplacement, Replacement, ReplacementFactory, ReplacementFactoryFromJS } from "..";

export interface IProfessionMaintenanceFormContent extends IBaseFormContent {
    showingReplacement?: IReplacement,
}

export interface IReadonlyProfessionMaintenanceFormContent extends IBaseFormContent {
    showingReplacement?: Replacement,
}

export type ProfessionMaintenanceFormContent = ReadonlyRecord<IReadonlyProfessionMaintenanceFormContent>;

export const ProfessionMaintenanceFormContentFactory = Record<IReadonlyProfessionMaintenanceFormContent>({
    panelId: "",
    showingReplacement: ReplacementFactory(),
    initialHash: "",
    isModified: false,
    selectableListPanelisActive: false,
    selectedCompanyId: 0
});

export const ProfessionMaintenanceFormContentFactoryFromJS = function (data: IProfessionMaintenanceFormContent) {
    return ProfessionMaintenanceFormContentFactory({
        panelId: data.panelId,
        showingReplacement: ReplacementFactoryFromJS(data.showingReplacement),
        initialHash: data.initialHash,
        isModified: data.isModified,
        selectableListPanelisActive: data.selectableListPanelisActive,
        selectedCompanyId: data.selectedCompanyId
    });
}