import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IBaseFormContent } from ".";
import { Group, GroupFactory, GroupFactoryFromJS, IGroup, IPropertyValue, PropertyValue, PropertyValueFactoryFromJS, ICompanyObject, CompanyObject, CompanyObjectFactoryFromJS } from "..";


export interface IUserGroupFormContent extends IBaseFormContent {
    showingUserGroup?: IGroup,
    customProperties?: Array<IPropertyValue>,
    companyObjects?: Array<ICompanyObject>,
    groupUsers?: Array<number>,
}

export interface IReadonlyUserGroupFormContent extends IBaseFormContent {
    showingUserGroup?: Group,
    customProperties?: List<PropertyValue>,
    groupUsers?: List<number>,
    companyObjects?: List<CompanyObject>,
}

export type UserGroupFormContent = ReadonlyRecord<IReadonlyUserGroupFormContent>;

export const UserGroupFormContentFactory = Record<IReadonlyUserGroupFormContent>({
    panelId: "",
    showingUserGroup: GroupFactory(),
    isModified: false,
    customProperties: List<PropertyValue>(),
    initialHash: "",
    showLoading: false,
    groupUsers: List<number>(),
    selectableListPanelisActive: false,
    companyObjects: List<CompanyObject>(),

});

export const UserGroupFormContentFactoryFromJS = function (data: IUserGroupFormContent) {
    let _customProperties: Array<PropertyValue> = data.customProperties && data.customProperties.length ? data.customProperties.map((value: IPropertyValue) => {
        return PropertyValueFactoryFromJS(value);
    }) : [];

    let _companyObjects: Array<CompanyObject> = data.companyObjects && data.companyObjects.length ? data.companyObjects.map((value: ICompanyObject) => {
        return CompanyObjectFactoryFromJS(value);
    }) : [];

    return UserGroupFormContentFactory({
        panelId: data.panelId,
        showingUserGroup: GroupFactoryFromJS(data.showingUserGroup),
        isModified: data.isModified,
        initialHash: data.initialHash,
        customProperties: List(_customProperties),
        showLoading: data.showLoading,
        groupUsers: List(data.groupUsers),
        selectableListPanelisActive: data.selectableListPanelisActive,
        companyObjects: List(_companyObjects),
    });
}