import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from "common/base/state";

const getPanelStatus = (state: WebInterfaceBaseState, panelId: string): boolean => {
    let panel = state.layouts.contentLayout.entities.panels.get(panelId);
    if (!panel) {
        return false;
    }
    return panel.isActive;
};

const getPanelActiveStatus = createSelector(
    [
        getPanelStatus
    ],
    (status: boolean): boolean => {
        return status;
    }
);

export { getPanelActiveStatus }