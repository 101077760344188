import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from "../forms";

export interface IBasePositionPropertiesFormContent extends IBaseFormContent {
    selectedPropertyId?: number
}

export interface IPositionPropertiesFormContent extends IBasePositionPropertiesFormContent {
}

export interface IReadonlyPositionPropertiesFormContent extends IBasePositionPropertiesFormContent {
}

export type PositionPropertiesFormContent = ReadonlyRecord<IReadonlyPositionPropertiesFormContent>;

export const PositionPropertiesFormContentFactory = Record<IReadonlyPositionPropertiesFormContent>({
    panelId: "",
    showLoading: true,
    selectedPropertyId: -1,
    editWindowGuid: "",
    editWindowisActive: false,
    selectableListPanelisActive: false,
    isEventButtonsActive: true
});

export const PositionPropertiesFormContentFactoryFromJS = function (data: IPositionPropertiesFormContent) {
    return PositionPropertiesFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedPropertyId: data.selectedPropertyId,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectableListPanelisActive: data.selectableListPanelisActive,
        isEventButtonsActive: data.isEventButtonsActive
    });
}