import { IDictionary, ReadonlyRecord } from '@bimser/core';
import { Map, Record } from 'immutable';
import { ILegacyFramePanelContent, LegacyFramePanelContent, LegacyFramePanelContentFactoryFromJS } from 'modules/legacyFrame/entities';


export interface ILegacyFrameState {
    panels: IDictionary<ILegacyFramePanelContent>
}

interface IReadonlyLegacyFrameState {
    panels: Map<string, LegacyFramePanelContent>
}

export type LegacyFrameState = ReadonlyRecord<IReadonlyLegacyFrameState>;

export const LegacyFrameStateFactory = Record<IReadonlyLegacyFrameState>({
    panels : Map<string, LegacyFramePanelContent>()
});

export const LegacyFrameStateFactoryFromJS = function (data: ILegacyFrameState) {
    let _panels: IDictionary<LegacyFramePanelContent> = Object.keys(data.panels).reduce(function (previous: IDictionary<LegacyFramePanelContent>, current) {
        previous[current] = LegacyFramePanelContentFactoryFromJS(data.panels[current]);
        return previous;
    }, {});

    return LegacyFrameStateFactory({
        panels : Map(_panels)
    });
}