import * as React from "react";
import * as Styles from '../assets/resizerStyles.scss';
import IResizerProps from "../entities/IResizerProps";
import IUpdateSizeEvent from "../entities/IUpdateSizeEvent";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const BCResizer: React.FunctionComponent<IResizerProps> = props => {

    const resizeBoxDomElement = React.useRef<HTMLDivElement>(null);
    const resizerDomElement = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
        window.removeEventListener('mousemove', onMouseMove, false);
        window.removeEventListener('mouseup', onMouseUp, false);
    }, []);

    const onMouseDown = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.nativeEvent?.stopPropagation();
        props.onDragStart?.();
        window.addEventListener('mousemove', onMouseMove, false);
        window.addEventListener('mouseup', onMouseUp, false);
    }

    const onMouseMove = (e: MouseEvent) => {
        if (resizeBoxDomElement.current) {
            const newSize = calculateNewSize(e);
            if (canUpdateSize(newSize)) {
                updateSize(newSize);
                props.onDragging?.(newSize.newWidth);
            }
        }
    }

    const onMouseUp = (e: MouseEvent) => {
        e.stopPropagation();
        const newSize = calculateNewSize(e);
        if (props.onDragStop) {
            if (canUpdateSize(newSize)) {
                updateSize(newSize);
            } else {
                newSize.newWidth = newSize.newWidth >= props.maxSize - 4 ? props.maxSize - 4 : props.minSize - 4;
            }
            props.onDragStop(newSize.newWidth);
        }
        window.removeEventListener('mousemove', onMouseMove, false);
        window.removeEventListener('mouseup', onMouseUp, false);
        if (resizerDomElement.current) resizerDomElement.current.style.transform = "translate(0,0)";
    }

    const canUpdateSize = (newSize: IUpdateSizeEvent) => newSize.newWidth < props.maxSize - 4 && newSize.newWidth > props.minSize - 4;

    const updateSize = (newSize: IUpdateSizeEvent) => {
        if (resizerDomElement.current) {
            if (props.type === "left") {
                resizerDomElement.current.style.transform = "translate(" + (newSize.diff * -1) + "px,0)";
            } else if (props.type === "right") {
                resizerDomElement.current.style.transform = "translate(" + newSize.diff + "px,0)";
            } else {
                resizerDomElement.current.style.transform = "translate(0," + newSize.diff + "px)";
            }
        }
    }

    const calculateNewSize = (e: MouseEvent): IUpdateSizeEvent => {
        if (!resizeBoxDomElement.current) return { diff: 0, newWidth: 0 };

        const actualBounds = resizeBoxDomElement.current.getBoundingClientRect();
        let diff = 0, newWidth = 0;

        if (props.type === "left") {
            diff = actualBounds.left - e.clientX;
            newWidth = actualBounds.width + diff;
        } else if (props.type === "right") {
            diff = e.clientX - actualBounds.right;
            newWidth = actualBounds.width + diff;
        } else if (props.type === "top") {
            diff = e.clientY - actualBounds.top;
            newWidth = actualBounds.height - diff;
        }

        return {
            diff,
            newWidth: newWidth - 4
        }
    }

    const commonClassNames = cx({
        left: props.type === 'left',
        right: props.type === 'right',
        top: props.type === 'top',
        bottom: props.type === 'bottom'
    });

    const renderResizer = () => {
        const classNames = cx({
            holder: true,
            [commonClassNames]: true,
            [props.resizerClassNames]: true
        });
        return (
            <span
                ref={resizerDomElement}
                className={classNames}
                style={props.resizerCss}
                onMouseDown={onMouseDown}>
            </span>
        )
    }

    const classNames = cx({
        content: true,
        [commonClassNames]: true,
        [props.containerClassNames]: true
    });

    return (
        <>
            {renderResizer()}
            <div className={classNames} ref={resizeBoxDomElement} >
                {props.children}
            </div>
        </>
    )

}
export default BCResizer
