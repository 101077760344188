import * as React from "react";
import { BCEmpty, BCButton } from "..";
import { createGuid } from "@bimser/core";
import Statement from './components/Statement';
import * as ConditionalStatementEntities from "./entities";
import { IBaseAction, ICondition, IConditionalStatement, IStatement, StatementType, IShowMessageAction, AlertType } from "./entities";
import * as Helpers from "./helpers";
import { findMessage } from '../BCIntl';
import IconAdd from "@bimser/icons/16/add";

export default class BCConditionalStatement extends React.Component<IConditionalStatement, {}> {

    constructor(props: IConditionalStatement) {
        super(props);

        this.addStatementTo = this.addStatementTo.bind(this);
        this.deleteStatement = this.deleteStatement.bind(this);

        this.addConditionGroupTo = this.addConditionGroupTo.bind(this);
        this.deleteConditionGroupFrom = this.deleteConditionGroupFrom.bind(this);

        this.conjunctionChanged = this.conjunctionChanged.bind(this);
        this.addConditionTo = this.addConditionTo.bind(this);

        this.updateCondition = this.updateCondition.bind(this);
        this.deleteConditionFrom = this.deleteConditionFrom.bind(this);

        this.updateAction = this.updateAction.bind(this);

        this.updateStatementCollapse = this.updateStatementCollapse.bind(this);
        this.updateStatementActions = this.updateStatementActions.bind(this);

        this.updateConditionGroupCollapse = this.updateConditionGroupCollapse.bind(this);

    }

    addConditionGroupTo(id: string, type: StatementType) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {

                const isRoot = statement.id === id;
                let newConditionGroup = Helpers.addConditionGroup(statement.conditionGroup, id);

                if (!newConditionGroup && isRoot) {

                    return {
                        ...statement,
                        conditionGroup: {
                            id: createGuid(),
                            children: [],
                            conditions: [],
                            typeName: 'AndConditionGroup'
                        }
                    }

                } else if (newConditionGroup) {
                    return {
                        ...statement,
                        conditionGroup: newConditionGroup
                    }
                } else {
                    return statement
                }

            })
        })
    }

    deleteConditionGroupFrom(id: string, type: StatementType) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                if (statement.conditionGroup) {
                    return {
                        ...statement,
                        conditionGroup: Helpers.deleteConditionGroup(statement.conditionGroup, id)
                    }
                } else {
                    return statement
                }

            })
        })
    }

    addStatementTo(id: string, typeName: StatementType) {
        let newStatement: IStatement = {
            id: createGuid(),
            actions: [],
            conditionGroup: null,
            typeName
        };

        let insertIndex = this.props.rule.statements.findIndex(i => i.id === id);
        let newStatements = [...this.props.rule.statements];
        newStatements.splice(insertIndex + 1, 0, newStatement)

        this.props.onDataChanged({
            ...this.props.rule,
            statements: newStatements
        })
    }

    deleteStatement(id: string) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.filter(i => i.id !== id)
        })
    }

    conjunctionChanged(id: string, data: any, type: StatementType) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                return {
                    ...statement,
                    conditionGroup: Helpers.updateConjunction(statement.conditionGroup, id, data)
                }
            })
        })
    }

    deleteConditionFrom(id: string, type: StatementType) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {

                if (statement.conditionGroup) {
                    return {
                        ...statement,
                        conditionGroup: Helpers.deleteCondition(statement.conditionGroup, id)
                    }
                } else {
                    return statement
                }

            })
        })
    }

    addConditionTo(id: string, type: StatementType) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {

                if (statement.conditionGroup) {
                    return {
                        ...statement,
                        conditionGroup: Helpers.addCondition(statement.conditionGroup, id)
                    }
                } else {
                    return statement
                }

            })
        });
    }

    updateCondition(condition: ICondition, type: StatementType) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                return {
                    ...statement,
                    conditionGroup: Helpers.updateCondition(statement.conditionGroup, condition)
                }
            })
        })
    }

    updateAction(id: string, type: StatementType, actions: IBaseAction[]) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                if (statement.id === id) {
                    return {
                        ...statement,
                        actions
                    }
                }
                return statement
            })
        })
    }

    updateStatementCollapse(id: string, type: StatementType, collapsed: boolean) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                if (statement.id === id) {
                    return {
                        ...statement,
                        collapsed
                    }
                }
                return statement
            })
        })
    }

    updateStatementActions(id: string, type: StatementType, actions: IBaseAction[]) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                if (statement.id === id) {
                    return {
                        ...statement,
                        actions
                    }
                }
                return statement
            })
        })
    }

    updateConditionGroupCollapse(id: string, type: StatementType, collapsed: boolean) {
        this.props.onDataChanged({
            ...this.props.rule,
            statements: this.props.rule.statements.map(statement => {
                return {
                    ...statement,
                    conditionGroup: Helpers.updateConditionGroupCollapse(statement.conditionGroup, id, collapsed)
                }
            })
        })
    }

    renderEmptyStatements() {
        return <BCEmpty
            description={findMessage.get('101926')}
        >
            <BCButton type={'ghost'} size={'small'} text={findMessage.get('101824')} icon={<IconAdd />} onClick={() => this.addStatementTo(null, 'IfStatement')} />
        </BCEmpty>
    }

    renderStatements() {
        const statements = this.props.rule.statements;

        if ((!statements || !statements.length) && !this.props.readonly) return this.renderEmptyStatements();

        return statements.map((statement, index) =>
            <Statement
                key={statement.id}
                {...statement}

                addStatementTo={this.addStatementTo}
                addConditionGroupTo={this.addConditionGroupTo}
                addConditionTo={this.addConditionTo}

                deleteConditionGroupFrom={this.deleteConditionGroupFrom}
                deleteStatement={this.deleteStatement}
                deleteConditionFrom={this.deleteConditionFrom}

                updateCondition={this.updateCondition}
                updateAction={this.updateAction}
                updateStatementCollapse={this.updateStatementCollapse}
                updateStatementActions={this.updateStatementActions}
                updateConditionGroupCollapse={this.updateConditionGroupCollapse}

                conjunctionChanged={this.conjunctionChanged}
                fields={this.props.fields}
                statementType={statement.typeName}
                selectableActions={this.props.rule.actions}
                readonly={this.props.readonly}

                statementIndex={index}
                nextStatement={statements[index + 1]}
            />
        )
    }

    render() {
        return (
            <>
                {this.renderStatements()}
            </>
        )
    }
}

export { ConditionalStatementEntities, Helpers, IShowMessageAction, AlertType };
