import React, { lazy, Suspense } from 'react';
import BCErrorBoundary from "../BCErrorBoundary"
import * as BCExtremeGridEntities from "./entities";
import * as ActionTypes from "./actionTypes";
import * as BCExtremeGridActionCreators from "./actions";
import getSortingColumns from "./helpers/getSortingColumns";
import getFilters from "./helpers/getFilters";
import Reducer from "./reducers";

const ReactiveGridStateful = lazy(
    () => import(/* webpackChunkName: "grid" */ "./Stateful").then(module => ({ default: module.default }))
);
const ReactiveGridStateless = lazy(
    () => import(/* webpackChunkName: "grid" */ "./BCExtremeGridWithSelectors").then(module => ({ default: module.default }))
);

function BCExtremeGridStateful(props: BCExtremeGridEntities.IStatefulExtremeGridProps) {
    return (
        <BCErrorBoundary>
            <Suspense fallback={<div></div>}>
                <ReactiveGridStateful {...props} />
            </Suspense>
        </BCErrorBoundary>
    );
}
function BCExtremeGridStateless(props: BCExtremeGridEntities.IExtremeGridProps) {
    return (
        <BCErrorBoundary>
            <Suspense fallback={<div></div>}>
                <ReactiveGridStateless {...props} />
            </Suspense>
        </BCErrorBoundary>
    );
}
BCExtremeGridStateful.Core = BCExtremeGridStateless;

export default BCExtremeGridStateful;


const BCExtremeGridHelpers = {
    getSortingColumns: getSortingColumns,
    getFilters: getFilters,
}

const BCExtremeGridConsts = {
    defaultPageSize: 10
}

export {
    BCExtremeGridEntities,
    Reducer as BCExtremeGridReducer,
    ActionTypes as BCExtremeGridActionTypes,
    BCExtremeGridHelpers,
    BCExtremeGridConsts,
    BCExtremeGridActionCreators
}
