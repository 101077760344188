import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";

export interface IBaseGeneralData {
    maxFileSize: number;
    category: string;
}

export interface IGeneralContent extends IBaseGeneralData {
    tags: Array<string>;
    allowedMimeTypes: Array<string>;
}

export interface IReadonlyGeneral extends IBaseGeneralData {
    tags: List<string>;
    allowedMimeTypes: List<string>;
}

export type GeneralContent = ReadonlyRecord<IReadonlyGeneral>;


export const GeneralFactory = Record<IReadonlyGeneral>({
    tags: List(),
    allowedMimeTypes: List(),
    maxFileSize: -1,
    category: ""
});

export function GeneralFactoryFromJS(data: IGeneralContent) {
    return GeneralFactory(
        {
            ...data,
            tags: List<string>(data.tags),
            allowedMimeTypes: List(data.allowedMimeTypes)
        }
    );
};
