import { List } from "immutable";
import { createSelector } from "reselect";
import { WebInterfaceBaseState } from "../../../common/base/state";
import { HumanResourceMenu } from "../entities/models";

const getMenuSelector = (state: WebInterfaceBaseState) => state.modules.humanResource.menu;

const getHRMenu = createSelector(
    [
        getMenuSelector
    ],
    (menu: List<HumanResourceMenu>) => {
        return menu.map((item: HumanResourceMenu) => item.toJS()).toJS();
    }
)

export { getHRMenu }