import { ExtremeGrid, ExtremeGridAPI, Mobile, ProjectHelper } from "@bimser/common";
import { BCAjaxResponse, BCExtremeGridEntities, findMessage, IRibbonBarItemProps } from "@bimser/components";
import { compareMatchWithCollection, createGuid, IDictionary, isNullOrUndefined, MLHelper, toUpperCase } from "@bimser/core";
import { getResultData } from "@bimser/form-controls/dist/helper/getResultData";
import { EditorTypes } from "@bimser/form-controls/dist/environments/runtime/components/DataGrid/entities";
import IconOk from "@bimser/icons/16/ok";
import GetIcon16ByName from "@bimser/icons/lib/getIcon16ByName";
import { Plugins } from "@bimser/studio-core";
import * as React from "react";
import * as Styles from "../assets/Styles.scss";
import { ISelectionListCProps, ISelectionListProps, ISelectionListState } from "../entities";
import DataTypes from "../entities/DataTypes";
const _get = require("lodash/get");
import _merge from 'lodash/merge';

export default class SelectionList extends React.Component<ISelectionListCProps & ISelectionListProps, ISelectionListState>{
    gridId = null;
    dataGridRef: any;
    constructor(props: ISelectionListCProps & ISelectionListProps) {
        super(props);

        this.onSelectedChanged = this.onSelectedChanged.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.btnOkClicked = this.btnOkClicked.bind(this)
        this.getGridRef = this.getGridRef.bind(this);
        this.getCellTemplate = this.getCellTemplate.bind(this);

        this.state = {
            selectedRows: this.props.childFormData?.extendedArgs?.rows?.map(row => row.cells.reduce((result, cell) => ({ ...result, [cell.name]: cell.value }), {})),
            rows: [],
            rowsLoaded: false,
            primaryKeyColumns: this.props.childFormData.args.options.columns
        }
        this.loadRows();

        this.gridId = "selection_list_" + createGuid();

        Mobile.MobileView.setFunctionForMobile(Mobile.MobileFunctions.OnSelectedChangedSelectionForm, (rows: any) => {
            const rowValue = Mobile.MobileView.isAndroidAvaliable ? rows.replaceAll("\n", "\\n") : rows;
            this.onSelectedChanged(JSON.parse(rowValue))
        });
        Mobile.MobileView.setFunctionForMobile(Mobile.MobileFunctions.BtnOkClickedSelectionForm, () => this.btnOkClicked());
    }

    componentWillUnmount() {
        ExtremeGridAPI.get(this.gridId).removeDataGrid();
    }

    onSelectedChanged(args: any) {
        let selectData: any[] = [];
        selectData = args;

        this.setState({
            selectedRows: selectData || []
        })
        let primaryKeyColumn = this.state.primaryKeyColumns.find(i => i.isPrimaryKey);
        let selectedIds = this.state.selectedRows.map(selectedRow => selectedRow[primaryKeyColumn?.name]);
        let message: any = {
            typeName: "ShowMessageAction",
            approvalActions: [],
            rejectionActions: [],
            dialogType: "Summary",
            alertType: "Info",
            id: createGuid(),
            messages: this.state.selectedRows.length ? selectedIds?.toString() : findMessage.get("101967"),
            name: findMessage.get('100647'),
            enabled: true,
        }
        Mobile.MobileView.mobileClientSideMessages([message]);
    }

    componentWillReceiveProps(nextProps: ISelectionListProps) {
        if (this.props.childFormData.extendedArgs.rows !== nextProps.childFormData.extendedArgs.rows) {
            let primaryKeyColumn = this.state.primaryKeyColumns.find(i => i.isPrimaryKey);
            let selectedIds = this.state.selectedRows.map(selectedRow => selectedRow[primaryKeyColumn?.name]);
            let propsSelectedRows = this.state.selectedRows.filter(_selectedRow => !selectedIds.includes(_selectedRow[primaryKeyColumn?.name]));
            let newSelectedRows = _merge(this.state.selectedRows, propsSelectedRows);
            this.setState({
                selectedRows: newSelectedRows,
            });
        }
    }

    btnOkClicked() {
        this.props.onSelectedChange({
            panelId: this.props.panelGuid,
            panelType: this.props.panelType,
            data: {
                rows: this.convertRowsToCollection(),
                args: this.props.childFormData,
                updatePanelId: this.props.childFormData.updatePanelId
            }
        })
    }

    loadRows() {
        this.getDataSourceData().then(response => {
            const data = response ? getResultData(response.data) : null;
            const primaryColumnName = this.state.primaryKeyColumns?.find(i => i.isPrimaryKey)?.name || "";
            const convertSelectedRows = this.props.childFormData?.extendedArgs?.rows?.map(row => row.cells.reduce((result, cell) => ({ ...result, [cell.name]: cell.value }), {})) || [];

            if (data?.length) {
                this.setState({
                    rows: data,
                    rowsLoaded: true
                })
            }

            Mobile.MobileView.setSelectionFormRows({ rows: data, primaryColumnName, selectedRows: convertSelectedRows });

            const dg = ExtremeGridAPI.get(this.gridId);
            if (dg) {
                dg.hideLoading();
                dg.setData({ data: data?.length > 0 ? data : [], totalCount: data?.length || 0, })
                let defaultSelection = this.getDefaultSelection();
                this.dataGridRef?.instance?.selectRows(defaultSelection, false);
            } else {
                this.createGrid();
            }

        }).catch(() => {
            ExtremeGridAPI.get(this.gridId).hideLoading();
            this.setState({
                rowsLoaded: true
            })
        });
    }

    getDefaultSelection() {
        const primaryColumn = this.state.primaryKeyColumns.find(i => i.isPrimaryKey);
        let selectionList = [];
        if (primaryColumn?.name && this.props.childFormData.extendedArgs.rows?.length) {
            let columnMapping = this.props.childFormData.args.options.columnMapping;
            selectionList = this.props.childFormData.extendedArgs.rows.map((r: any) => {
                return r.cells.find((c: any) => {
                    let foundColumnMapItem = columnMapping.find(cm => cm.target === c.name);
                    let mappedPrimaryColumnName = foundColumnMapItem && foundColumnMapItem.source ? foundColumnMapItem.source : c.name;
                    return mappedPrimaryColumnName === primaryColumn.name
                })?.value
            })
        }
        return selectionList;
    }

    getDataSourceData() {
        return new Promise<BCAjaxResponse<Plugins.QueryDesigner.Entities.Bases.IDataSourceResponse>>((resolve, reject) => {
            let { dataSource } = this.props.childFormData.args;
            if (!dataSource?.runAtServer && dataSource?.name && this.props.childFormData.endPoint) {
                let paramArgs = this.getDataSourceParams({});
                if (paramArgs.isValid) {
                    ProjectHelper.RunPromiseFormService({
                        projectName: this.props.childFormData.projectName,
                        formName: "DataSource",
                        serviceName: dataSource.name,
                        requestParameters: { ...paramArgs.requestPayload },
                        requestConfig: {
                            headers: {
                                'Content-Type': "application/json; charset=utf-8"
                            },
                            cache: true
                        }
                    }).then(response => {
                        resolve(response);
                    }).catch(e => {
                        console.log("getDataSourceData: ", e);
                        reject();
                    })
                } else {
                    reject();
                }
            } else {
                reject();
            }
        })
    }

    getDataSourceParams(fieldParams: IDictionary<any>): { requestPayload: any, isValid: boolean } {
        let { dataSource } = this.props.childFormData.args;
        let requestPayload: any = {};
        let isValid = true;

        if (dataSource.connectionType === Plugins.Misc.Enums.EConnectionTypes.REST) {
            requestPayload.parameters = dataSource.parameters.map((i) => {
                const param = this.getParametersValueWithValid(i, fieldParams);
                isValid = param.isValid;
                return {
                    key: i.name,
                    value: param.value,
                }
            });
        } else {
            dataSource.parameters.forEach(i => {
                const param = this.getParametersValueWithValid(i, fieldParams);
                requestPayload[i.name] = param.value;
                isValid = param.isValid
            });
        }

        return {
            requestPayload,
            isValid
        }
    }

    getParametersValueWithValid(parameter: any, fieldParams: IDictionary<any>): { isValid?: boolean, value: any } {
        let value: any = null;
        let isValid: boolean = true;
        if (parameter.valueModifier === 'valueEntry') {
            value = parameter.value;
            if (parameter.isRequired && isNullOrUndefined(parameter.value)) {
                isValid = false;
            }
        } else if (parameter.valueModifier === 'fieldSelection') {
            value = (isNullOrUndefined(fieldParams?.[parameter.field]) || fieldParams?.[parameter.field] === "") ? null : fieldParams[parameter.field];
        }
        return {
            value,
            isValid
        }
    }

    convertRowsToCollection() {
        let rawData = this.state.selectedRows;
        let rows: any[] = [];
        let currentColumnNames = this.state.primaryKeyColumns?.map((i: any) => i.name) || [];
        let columnMapping = this.props.childFormData.args.options.columnMapping;

        rawData.forEach((item, index) => {
            if (Object.keys(item).length) {
                rows.push({
                    cells: Object.keys(item).filter(key => {
                        return currentColumnNames.indexOf(key) > -1
                    }).map(columnName => {
                        let foundColumn = this.props.childFormData.args.targetDataGridColumns?.find((i: any) => i.name === columnName);
                        let foundColumnMapItem = columnMapping.find((c: any) => c.source === columnName);
                        let value = item[foundColumnMapItem?.source || columnName];

                        let columnText = value;
                        if (!isNullOrUndefined(value)) {
                            columnText = foundColumn?.editType === "multiLanguageTextBox"
                                ? MLHelper.getMLText(value)
                                : value;
                        }

                        return {
                            editType: foundColumn?.editType,
                            entityPath: (foundColumn?.editControl as any)?.entityPath,
                            name: foundColumnMapItem?.target || columnName,
                            text: columnText,
                            value,
                        }

                    }),
                    index
                })
            }
        });

        return rows;
    }

    ribbonItems() {
        let items: IRibbonBarItemProps[] = [];
        if (this.props.childFormData.args.okButton) {
            items.push({
                key: 'ok',
                text: this.props.childFormData.args.okButton.text ? MLHelper.getMLText(this.props.childFormData.args.okButton.text) : null,
                title: this.props.childFormData.args.okButton.title ? MLHelper.getMLText(this.props.childFormData.args.okButton.title) : null,
                onClick: this.btnOkClicked,
                onRenderIcon: icon => {
                    if (this.props.childFormData.args.okButton.icon) {
                        const Icon = GetIcon16ByName(this.props.childFormData.args.okButton.icon, IconOk);
                        return <Icon />
                    }
                },
            });
        }
        else {
            items.push({
                key: 'ok',
                text: findMessage.get("100004"),
                icon: IconOk.info,
                onClick: this.btnOkClicked,
            });
        }
        return items;
    }

    componentDidMount() {
        this.createGrid();
    }

    getColumns(): Array<BCExtremeGridEntities.IExtremeGridColumn> {
        let columns: Array<BCExtremeGridEntities.IExtremeGridColumn> = this.state.primaryKeyColumns?.map((column, index) => {
            return {
                name: column.name,
                caption: MLHelper.getMLText(column.caption) || toUpperCase(column.name),
                align: column.align,
                width: column.width,
                dataType: DataTypes[column.cellType] as any,
                editorType: EditorTypes[column?.editType] as any,
                formatType: column?.formatType?.type as any,
                order: index,
                visible: column.visible,
                iconMatchers: column.iconMatchers,
                iconSourceField: column.iconSourceField,
                cellTemplate: this.getCellTemplate
            }
        }) || [];

        return columns;
    }

    getCellTemplate(args: any) {
        if (args.column?.iconMatchers?.length && args.column?.iconSourceField) {
            const cellValue = _get(args.row.data, args.column.iconSourceField);
            const icon = args.column.iconMatchers
                .find(matcher => compareMatchWithCollection({ value: cellValue, compareValue: matcher.value, dataType: args.column.dataType, editType: args.column?.editorType }))
                ?.icon;

            if (icon) {
                const Icon = GetIcon16ByName(icon);
                return (
                    <div className={Styles.CellWithIcon}>
                        <Icon className={Styles.Icon} />
                        {args.displayText}
                    </div>
                );
            }
        }
    }

    createGrid() {
        const columns = this.state.primaryKeyColumns;
        const primaryColumn = columns.find(i => i.isPrimaryKey);
        const totalItems = this.state.primaryKeyColumns?.reduce((result, column) =>
            column.summaryTypePrefixes
                ? [...result, ...column.summaryTypePrefixes?.map(s => ({ columnName: column.name, type: s.type, suffix: s.suffix || null }))]
                : result,
            []);

        ExtremeGridAPI.create({
            id: this.gridId,
            uniqueDataField: primaryColumn && primaryColumn.name,
            dataSource: { data: this.state.rows, totalCount: this.state.rows.length },
            columns: this.getColumns(),
            selection: {
                enabled: this.props.childFormData.args.options.selection.enabled,
                allowSelectAll: this.props.childFormData.args.options.selection.showSelectAll,
                mode: this.props.childFormData.args.options.selection.mode,
                selectedIds: [],
                showCheckBoxesMode: this.props.childFormData.args.options.selection.showCheckBoxesMode,
                selectAllMode: this.props.childFormData.args.options.selection.selectAllMode,
            },
            stateStoring: {
                enabled: false
            },
            summary: {
                totalItems: totalItems || [],
            },
            filtering: {
                visible: this.props.childFormData.args.options.filtering.enabled,
                showOperationChooser: this.props.childFormData.args.options.filtering.enabled,
            },
            searching: { visible: this.props.childFormData.args.options.searching.enabled },
            paging: {
                enabled: this.props.childFormData.args.options.paging.enabled,
                pageSize: this.props.childFormData.args.options.paging.pageSize,
                pageIndex: this.props.childFormData.args.options.paging.currentPage - 1
            },
            pager: {
                visible: this.props.childFormData.args.options.paging.enabled,
                allowedPageSizes: this.props.childFormData.args.options.paging.pagingSizes,
                showPageSizeSelector: this.props.childFormData.args.options.paging.enabled,
            },
            toolbarItems: this.ribbonItems(),
            isLoading: !this.state.rowsLoaded,
            allowColumnResizing: this.props.childFormData.args.options.columnSettings?.resizeable,
            columnResizingMode: this.props.childFormData.args.options.columnSettings?.resizingMode,
            allowColumnReordering: this.props.childFormData.args.options.columnSettings?.orderable,
            columnChooser: {
                enabled: this.props.childFormData.args.options.columnSettings?.showColumnChooser,
            },
            languageOptions: {
                currentLanguage: MLHelper.currentLanguage,
                supportedLanguages: MLHelper.supportedLanguages
            },
            height: "100%",
        }, { override: true })
    }

    getGridRef(ref: any) {
        this.dataGridRef = ref;
    }

    render() {
        return (
            <div className={Styles.openSelectionList}>
                <ExtremeGrid
                    id={this.gridId}
                    useStateForSelection={true}
                    key={this.gridId}
                    onSelectionChange={this.onSelectedChanged}
                    getInputReference={this.getGridRef}
                />
            </div>
        )
    }
}