import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IAnnouncement } from "./IAnnouncement";

export interface IBaseAnnouncementListPanelContent {
    panelId: string,
    selectedId?: number,
    showLoading?: boolean,
    editPanelIsActive?: boolean,
    editPanelGuid?: string
}
export interface IAnnouncementListPanelContent extends IBaseAnnouncementListPanelContent { }

export interface IReadonlyAnnouncementListPanelContent extends IBaseAnnouncementListPanelContent { }

export type AnnouncementListPanelContent = ReadonlyRecord<IReadonlyAnnouncementListPanelContent>;

export const AnnouncementListPanelContentFactory = Record<IReadonlyAnnouncementListPanelContent>({
    panelId: '',
    editPanelGuid: '',
    editPanelIsActive: false,
    selectedId: null,
    showLoading: true
});

export const AnnouncementListPanelContentFactoryFromJS = function (data: IAnnouncementListPanelContent) {
    return AnnouncementListPanelContentFactory(data);
}