import { EDeviceTypes } from "../common/entities"

export function getDeviceType(width: number): EDeviceTypes {
    let deviceType: EDeviceTypes = EDeviceTypes.Medium;
    if (width) {
        if (width <= 576) {
            deviceType = EDeviceTypes.ExtraSmall;
        } else if (width >= 576 && width <= 768) {
            deviceType = EDeviceTypes.Small;
        } else if (width >= 768 && width <= 992) {
            deviceType = EDeviceTypes.Medium;
        } else if (width >= 992) {
            deviceType = EDeviceTypes.Large;
        }
    }
    return deviceType;
}