import React, { lazy, Suspense } from 'react';
import IExtremeChartLayoutProps from './entities/IProps';


const BCChartLazy = lazy(
    () => import(/* webpackChunkName: "chart" */ "./components")
);

function BCExtremeChart(props: IExtremeChartLayoutProps) {
    return (
        <Suspense fallback={<div></div>}>
            <BCChartLazy {...props} />
        </Suspense>
    );
}


export { BCExtremeChart, IExtremeChartLayoutProps };
export * from "./entities";