import * as React from "react";
import { connect } from "react-redux";
import { BaseState } from "../../../entities/BaseState";
import Login from "../components/Login";
import ILoginComponentCProps from "../entities/ILoginComponentCProps";
import ILoginComponentProps from "../entities/ILoginComponentProps";
import { ILoginRequest } from "../entities/LoginRequest";
import { getLoadingStatus, getSelectedCulture, getLoginFormProperties, getCanChangeLanguage, getMobileSignFingerprint, getCaptchaState, getMFAStatus, getDomainProperties } from "../selectors/LoginUserSelector";
import { getTenantCustom } from "../../../system/tenant/selectors";
import { ILoginWithTokenPayload } from "../entities/ILoginWithTokenPayload";
import ILoginWithDigitalSignaturePayload from "../entities/ILoginWithDigitalSignaturePayload";
import IStartMobileLoginPayload from "../../MobileSignature/entities/IStartMobileLoginPayload";
import { ILoginMethod } from "../entities/ILoginMethod";
import {
    setMFAProperties, loginWithDigitalSignatureAction, loginWithTokenTransaction, changeSelectedCultureActionSaga,
    changeLoginLoadingStatusAction, loginTransactionAction, loginWithMobileSignatureAction,
    refreshCaptchaAction, loginWithExternalAccountTransactionAction
} from "../actions";


// state to component properties
const mapStateToProps = function (state: BaseState, props: ILoginComponentProps): ILoginComponentProps {
    return {
        loadingStatus: getLoadingStatus(state),
        selectedCulture: getSelectedCulture(state),
        version: state.system.version,
        supportedLanguages: state.system.language.items.toJS(),
        tenant: getTenantCustom(state),
        loginFormProperties: getLoginFormProperties(state),
        canChangeLanguage: getCanChangeLanguage(state),
        mobileSignatureFingerprint: getMobileSignFingerprint(state),
        hash: props.hash,
        urlPath: props.urlPath,
        captcha: getCaptchaState(state),
        MFAStatus: getMFAStatus(state),
        domainProperties: getDomainProperties(state)
    };
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): ILoginComponentCProps {
    return {
        onLoginClick: function (data: ILoginRequest) {
            dispatch(loginTransactionAction(data));
        },
        onChangeLoadingStatus: function (data: boolean) {
            dispatch(changeLoginLoadingStatusAction(data));
        },
        onChangeSelectedCulture: function (data: string) {
            dispatch(changeSelectedCultureActionSaga(data));
        },
        onLoginWithToken: function (data: ILoginWithTokenPayload) {
            dispatch(loginWithTokenTransaction(data));
        },
        onLoginWithDigitalSignature: function (data: ILoginWithDigitalSignaturePayload) {
            dispatch(loginWithDigitalSignatureAction(data));
        },
        onLoginWithMobileSignature: function (data: IStartMobileLoginPayload) {
            dispatch(loginWithMobileSignatureAction(data));
        },
        onLoginwithSocialMedia: function (data: ILoginMethod) {
            dispatch(loginWithExternalAccountTransactionAction(data));
        },
        onClickRefreshCaptcha: function (language: string) {
            dispatch(refreshCaptchaAction(language));
        },
        onResetMFAProperties: function () {
            dispatch(setMFAProperties({ mfaEnabled: false, currentLoginProcedure: null, currentMFAMethod: null }));
        },
    };
};

// react - redux connection
const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
