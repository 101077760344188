import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { DMExplorerProps } from '../components';
import { IFilter } from '../entities/IFilter';

const getPropsFilterSelectorState = (state: BaseState, props: DMExplorerProps) => {
    let filter = state.DMExplorer.filter;
    if (props.propsFilter && (filter.extension === null || filter.extension === undefined)) filter = filter.set("extension", props.propsFilter.extension);
    return filter;
}

const getPropsFilterSelector = createSelector(
    [
        getPropsFilterSelectorState,
    ],
    (filter): IFilter => {
        return filter ? filter.toJS() : { name: '', extension: '' };
    }
);


export default getPropsFilterSelector;
