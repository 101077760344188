import { Map } from "immutable";
import { ILayoutDataItem, INormalizedLayoutItems, INormalizedLayoutStructureItems, LayoutDataItem, LayoutDataItemFactoryFromJS, NormalizedLayoutStructureItemsFactoryFromJS } from "../../../common/entities";

const splitDataAndStructureItems = (items: INormalizedLayoutItems) => {
    const structureItems = NormalizedLayoutStructureItemsFactoryFromJS(items as INormalizedLayoutStructureItems);
    const rawItems = items.entities.items;
    const rawGhostItems = items.entities.ghostItems;
    let dataItems: Map<string, LayoutDataItem> = Map();
    rawItems && Object.keys(rawItems).forEach(key => {
        const item: ILayoutDataItem = rawItems[key];
        dataItems = dataItems.set(key, LayoutDataItemFactoryFromJS(item))
    });
    rawGhostItems && Object.keys(rawGhostItems).forEach(key => {
        const item: ILayoutDataItem = rawGhostItems[key];
        dataItems = dataItems.set(key, LayoutDataItemFactoryFromJS(item))
    });

    return { structureItems, dataItems }
}

export { splitDataAndStructureItems };
