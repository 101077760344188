import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IReadonlySelectMFATypeRequest, ISelectMFATypeRequest, MFAType } from "./SelectMFATypeRequest";

export interface IValidationMFARequest extends ISelectMFATypeRequest {
    pin: string;
}


export interface IReadonlyValidationMFARequest extends IReadonlySelectMFATypeRequest {
    pin: string;
}

export type ValidationMFARequest = ReadonlyRecord<IReadonlyValidationMFARequest>;

export const ValidationMFARequestFactory = Record<IReadonlyValidationMFARequest>({
    mfaType: MFAType.email,
    tempTokenKey: null,
    language: null,
    pin: null
});

export const ValidationMFARequestFactoryFromJS = function (data: IValidationMFARequest) {
    return ValidationMFARequestFactory({
        mfaType: data.mfaType,
        tempTokenKey: data.tempTokenKey,
        language: data.language,
        pin: data.pin
    });
};
