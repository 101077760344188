import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseMethodInvokeActionField {
    arguments: any,
    methodName: string
}

export interface IMethodInvokeActionField extends IBaseMethodInvokeActionField {

}

export interface ReadOnlyMethodInvokeActionField extends IBaseMethodInvokeActionField {

}

export type MethodInvokeActionField = ReadonlyRecord<ReadOnlyMethodInvokeActionField>;

export const MethodInvokeActionFieldFactory = Record<ReadOnlyMethodInvokeActionField>({
    arguments: null,
    methodName: null
});

export const MethodInvokeActionFieldFactoryFromJS = function (data: IMethodInvokeActionField) {
    return MethodInvokeActionFieldFactory(data);
}