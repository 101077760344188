import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from "../forms";

export interface IBaseProfessionPropertiesFormContent extends IBaseFormContent {
    selectedPropertyId?: number
}

export interface IProfessionPropertiesFormContent extends IBaseProfessionPropertiesFormContent {
}

export interface IReadonlyProfessionPropertiesFormContent extends IBaseProfessionPropertiesFormContent {
}

export type ProfessionPropertiesFormContent = ReadonlyRecord<IReadonlyProfessionPropertiesFormContent>;

export const ProfessionPropertiesFormContentFactory = Record<IReadonlyProfessionPropertiesFormContent>({
    panelId: "",
    showLoading: true,
    selectedPropertyId: -1,
    editWindowGuid: "",
    editWindowisActive: false,
    selectableListPanelisActive: false,
    isEventButtonsActive: true
});

export const ProfessionPropertiesFormContentFactoryFromJS = function (data: IProfessionPropertiesFormContent) {
    return ProfessionPropertiesFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedPropertyId: data.selectedPropertyId,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectableListPanelisActive: data.selectableListPanelisActive,
        isEventButtonsActive: data.isEventButtonsActive
    });
}