import { connect } from "react-redux";
import { closeFlexPanelByIdTransactionAction } from "../../../../../../../app-main/actions";
import { initializePanelManagerTransaction } from "../../../../../../../common/actions";
import { WebInterfaceBaseState } from "../../../../../../../common/base/state";
import { IWebInterfaceAction } from "../../../../../../../common/entities";
import { onAddNewFilesAction, onChangeProfileFormFilePropertyAction, onRemoveFileFromListAction, onSaveAllProfileFormsTransaction, onSetSelectedFormTabAction, onSetSelectedIdsAction, onFolderPropChangeAction } from "../actions";
import ProfileForm from "../components";
import { IProfileFormProps } from '../entities';
import OnChangeFilePropertyPayload from "../entities/OnChangeFilePropertyPayload";
import { getActiveFormTabKey, getInitializedForms, getProfileFormPanelFileList, getProfileFormPanelFormList, getProfileFormPanelLoading, getProfileFormSelectedFiles, getProfileFormPanelFolderProps } from "../selectors";

const mapStateToProps = function (state: WebInterfaceBaseState, props: IProfileFormProps): IProfileFormProps {
    return {
        isLoading: getProfileFormPanelLoading(state),
        fileList: getProfileFormPanelFileList(state),
        formList: getProfileFormPanelFormList(state),
        folderProps: getProfileFormPanelFolderProps(state),
        selectedIds: getProfileFormSelectedFiles(state),
        initializedForms: getInitializedForms(state),
        activeTabKey: getActiveFormTabKey(state)
    };
}
const mapDispatchToProps = function (dispatch: Function): IProfileFormProps {
    return {
        onChangeFileProperty: (payload: OnChangeFilePropertyPayload) => {
            dispatch(onChangeProfileFormFilePropertyAction(payload));
        },
        onRemoveFile: (fileId: string) => {
            dispatch(onRemoveFileFromListAction(fileId));
        },
        initializePanelManager: (action: IWebInterfaceAction) => {
            dispatch(initializePanelManagerTransaction(action));
        },
        onSaveAllProfileForms: (payload: IWebInterfaceAction) => {
            dispatch(onSaveAllProfileFormsTransaction(payload));
        },
        onSetSelectedIds: (payload: string[]) => {
            dispatch(onSetSelectedIdsAction(payload));
        },
        onSetSelectedFormTab: (payload: string) => {
            dispatch(onSetSelectedFormTabAction(payload));
        },
        onAddNewFiles: (payload: IWebInterfaceAction) => {
            dispatch(onAddNewFilesAction(payload));
        },
        onCancel: (payload: IWebInterfaceAction) => {
            dispatch(closeFlexPanelByIdTransactionAction(payload.panelId));
        },
        onFolderPropertyChange: (payload: OnChangeFilePropertyPayload) => {
            dispatch(onFolderPropChangeAction(payload));
        }
    };
}

const ProfileFormContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
export default ProfileFormContainer;