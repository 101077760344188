import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Map, Record } from "immutable";
import {
    CompanyAdminListFormContent, CompanyAdminListFormContentFactoryFromJS, CompanyFormContent, CompanyFormContentFactoryFromJS, CompanyListFormContent,
    CompanyListFormContentFactoryFromJS, DepartmentFormContent, DepartmentFormContentFactoryFromJS, DepartmentListFormContent, DepartmentListFormContentFactoryFromJS,
    ICompanyAdminListFormContent, ICompanyFormContent, ICompanyListFormContent, IDepartmentFormContent, IDepartmentListFormContent, IPositionFormContent,
    IPositionListFormContent, IProfessionFormContent, IProfessionListFormContent, IUserFormContent, IUserGroupFormContent, IUserGroupListFormContent,
    IUserListFormContent, PositionFormContent, PositionFormContentFactoryFromJS, PositionListFormContent, PositionListFormContentFactoryFromJS,
    ProfessionFormContent, ProfessionFormContentFactoryFromJS, ProfessionListFormContent, ProfessionListFormContentFactoryFromJS, UserFormContent,
    UserFormContentFactoryFromJS, UserGroupFormContent, UserGroupListFormContent, UserListFormContent, UserListFormContentFactoryFromJS, IManagerKeyListFormContent,
    ManagerKeyListFormContent, ManagerKeyListFormContentFactoryFromJS, IManagerKeyFormContent, ManagerKeyFormContent, ManagerKeyFormContentFactoryFromJS,
    IShiftListFormContent, ShiftListFormContent, ShiftListFormContentFactoryFromJS, UserGroupFormContentFactoryFromJS, UserGroupListFormContentFactoryFromJS,
    IShiftFormContent, ShiftFormContent, ShiftFormContentFactoryFromJS, IOvertimeListFormContent, OvertimeListFormContent, OvertimeListFormContentFactoryFromJS,
    IOvertimeFormContent, OvertimeFormContent, OvertimeFormContentFactoryFromJS, ISalaryFormContent, SalaryFormContent, SalaryFormContentFactoryFromJS,
    SalaryListFormContent, ISalaryListFormContent, SalaryListFormContentFactoryFromJS
} from ".";

export interface IFormContentList {
    userForms?: IDictionary<IUserFormContent>,
    userListForms?: IDictionary<IUserListFormContent>,
    departmentForms?: IDictionary<IDepartmentFormContent>,
    departmentListForms?: IDictionary<IDepartmentListFormContent>,
    positionForms?: IDictionary<IPositionFormContent>,
    positionListForms?: IDictionary<IPositionListFormContent>,
    professionForms?: IDictionary<IProfessionFormContent>,
    professionListForms?: IDictionary<IProfessionListFormContent>,
    companyForms?: IDictionary<ICompanyFormContent>,
    companyListForms?: IDictionary<ICompanyListFormContent>,
    companyAdminListForms?: IDictionary<ICompanyAdminListFormContent>,
    userGroupForms?: IDictionary<IUserGroupFormContent>,
    userGroupListForms?: IDictionary<IUserGroupListFormContent>,
    managerKeyForms?: IDictionary<IManagerKeyFormContent>,
    managerKeyListForms?: IDictionary<IManagerKeyListFormContent>,
    shiftListForms?: IDictionary<IShiftListFormContent>,
    shiftForms?: IDictionary<IShiftFormContent>,
    overtimeListForms?: IDictionary<IOvertimeListFormContent>,
    overtimeForms?: IDictionary<IOvertimeFormContent>,
    salaryListForms?: IDictionary<ISalaryListFormContent>,
    salaryForms?: IDictionary<ISalaryFormContent>,
    //maintenanceForms?: IDictionary<IMaintenanceFormContentList>,
}

export interface IReadonlyFormContentList {
    userForms?: Map<string, UserFormContent>,
    userListForms?: Map<string, UserListFormContent>,
    departmentForms?: Map<string, DepartmentFormContent>,
    departmentListForms?: Map<string, DepartmentListFormContent>,
    positionForms?: Map<string, PositionFormContent>,
    positionListForms?: Map<string, PositionListFormContent>,
    professionForms?: Map<string, ProfessionFormContent>,
    professionListForms?: Map<string, ProfessionListFormContent>,
    companyForms?: Map<string, CompanyFormContent>,
    companyListForms?: Map<string, CompanyListFormContent>,
    companyAdminListForms?: Map<string, CompanyAdminListFormContent>,
    userGroupForms?: Map<string, UserGroupFormContent>,
    userGroupListForms?: Map<string, UserGroupListFormContent>,
    managerKeyForms?: Map<string, ManagerKeyFormContent>,
    managerKeyListForms?: Map<string, ManagerKeyListFormContent>,
    shiftListForms?: Map<string, ShiftListFormContent>,
    shiftForms?: Map<string, ShiftFormContent>,
    overtimeListForms?: Map<string, OvertimeListFormContent>,
    overtimeForms?: Map<string, OvertimeFormContent>,
    salaryListForms?: Map<string, SalaryListFormContent>,
    salaryForms?: Map<string, SalaryFormContent>,
    //maintenanceForms?: Map<string, MaintenanceFormContentList>,
}

export type FormContentList = ReadonlyRecord<IReadonlyFormContentList>;

export const FormContentListFactory = Record<IReadonlyFormContentList>({
    userForms: Map<string, UserFormContent>(),
    userListForms: Map<string, UserListFormContent>(),
    departmentForms: Map<string, DepartmentFormContent>(),
    departmentListForms: Map<string, DepartmentListFormContent>(),
    positionForms: Map<string, PositionFormContent>(),
    positionListForms: Map<string, PositionListFormContent>(),
    professionForms: Map<string, ProfessionFormContent>(),
    professionListForms: Map<string, ProfessionListFormContent>(),
    companyForms: Map<string, CompanyFormContent>(),
    companyListForms: Map<string, CompanyListFormContent>(),
    companyAdminListForms: Map<string, CompanyAdminListFormContent>(),
    userGroupForms: Map<string, UserGroupFormContent>(),
    userGroupListForms: Map<string, UserGroupListFormContent>(),
    managerKeyForms: Map<string, ManagerKeyFormContent>(),
    managerKeyListForms: Map<string, ManagerKeyListFormContent>(),
    shiftListForms: Map<string, ShiftListFormContent>(),
    shiftForms: Map<string, ShiftFormContent>(),
    overtimeListForms: Map<string, OvertimeListFormContent>(),
    overtimeForms: Map<string, OvertimeFormContent>(),
    salaryListForms: Map<string, SalaryListFormContent>(),
    salaryForms: Map<string, SalaryFormContent>(),
    //maintenanceForms: Map<string, MaintenanceFormContentList>(),
});

export const FormContentListFactoryFromJS = function (data: IFormContentList) {
    let _userForms: IDictionary<UserFormContent> = Object.keys(data.userForms).reduce(function (previous: IDictionary<UserFormContent>, current) {
        previous[current] = UserFormContentFactoryFromJS(data.userForms[current]);
        return previous;
    }, {});

    let _userListForms: IDictionary<UserListFormContent> = Object.keys(data.userListForms).reduce(function (previous: IDictionary<UserListFormContent>, current) {
        previous[current] = UserListFormContentFactoryFromJS(data.userListForms[current]);
        return previous;
    }, {});

    let _departmentForms: IDictionary<DepartmentFormContent> = Object.keys(data.departmentForms).reduce(function (previous: IDictionary<DepartmentFormContent>, current) {
        previous[current] = DepartmentFormContentFactoryFromJS(data.departmentForms[current]);
        return previous;
    }, {});

    let _departmentListForms: IDictionary<DepartmentListFormContent> = Object.keys(data.departmentListForms).reduce(function (previous: IDictionary<DepartmentListFormContent>, current) {
        previous[current] = DepartmentListFormContentFactoryFromJS(data.departmentListForms[current]);
        return previous;
    }, {});

    let _positionForms: IDictionary<PositionFormContent> = Object.keys(data.positionForms).reduce(function (previous: IDictionary<PositionFormContent>, current) {
        previous[current] = PositionFormContentFactoryFromJS(data.positionForms[current]);
        return previous;
    }, {});

    let _positionListForms: IDictionary<PositionListFormContent> = Object.keys(data.positionListForms).reduce(function (previous: IDictionary<PositionListFormContent>, current) {
        previous[current] = PositionListFormContentFactoryFromJS(data.positionListForms[current]);
        return previous;
    }, {});

    let _professionForms: IDictionary<ProfessionFormContent> = Object.keys(data.professionForms).reduce(function (previous: IDictionary<ProfessionFormContent>, current) {
        previous[current] = ProfessionFormContentFactoryFromJS(data.professionForms[current]);
        return previous;
    }, {});

    let _professionListForms: IDictionary<ProfessionListFormContent> = Object.keys(data.professionListForms).reduce(function (previous: IDictionary<ProfessionListFormContent>, current) {
        previous[current] = ProfessionListFormContentFactoryFromJS(data.professionListForms[current]);
        return previous;
    }, {});

    let _companyForms: IDictionary<CompanyFormContent> = Object.keys(data.companyForms).reduce(function (previous: IDictionary<CompanyFormContent>, current) {
        previous[current] = CompanyFormContentFactoryFromJS(data.companyForms[current]);
        return previous;
    }, {});

    let _companyListForms: IDictionary<CompanyListFormContent> = Object.keys(data.companyListForms).reduce(function (previous: IDictionary<CompanyListFormContent>, current) {
        previous[current] = CompanyListFormContentFactoryFromJS(data.companyListForms[current]);
        return previous;
    }, {});

    let _companyAdminListForms: IDictionary<CompanyAdminListFormContent> = Object.keys(data.companyAdminListForms).reduce(function (previous: IDictionary<CompanyAdminListFormContent>, current) {
        previous[current] = CompanyAdminListFormContentFactoryFromJS(data.companyAdminListForms[current]);
        return previous;
    }, {});

    let _userGroupForms: IDictionary<UserGroupFormContent> = Object.keys(data.companyForms).reduce(function (previous: IDictionary<UserGroupFormContent>, current) {
        previous[current] = UserGroupFormContentFactoryFromJS(data.companyForms[current]);
        return previous;
    }, {});

    let _userGroupListForms: IDictionary<UserGroupListFormContent> = Object.keys(data.companyListForms).reduce(function (previous: IDictionary<UserGroupListFormContent>, current) {
        previous[current] = UserGroupListFormContentFactoryFromJS(data.companyListForms[current]);
        return previous;
    }, {});

    let _managerKeyForms: IDictionary<ManagerKeyFormContent> = Object.keys(data.managerKeyForms).reduce(function (previous: IDictionary<ManagerKeyFormContent>, current) {
        previous[current] = ManagerKeyFormContentFactoryFromJS(data.managerKeyForms[current]);
        return previous;
    }, {});

    let _managerKeyListForms: IDictionary<ManagerKeyListFormContent> = Object.keys(data.managerKeyListForms).reduce(function (previous: IDictionary<ManagerKeyListFormContent>, current) {
        previous[current] = ManagerKeyListFormContentFactoryFromJS(data.managerKeyListForms[current]);
        return previous;
    }, {});

    let _shiftListForms: IDictionary<ShiftListFormContent> = Object.keys(data.shiftListForms).reduce(function (previous: IDictionary<ShiftListFormContent>, current) {
        previous[current] = ShiftListFormContentFactoryFromJS(data.shiftListForms[current]);
        return previous;
    }, {})

    let _shiftForms: IDictionary<ShiftFormContent> = Object.keys(data.shiftForms).reduce(function (previous: IDictionary<ShiftFormContent>, current) {
        previous[current] = ShiftFormContentFactoryFromJS(data.shiftForms[current]);
        return previous;
    }, {})

    let _overtimeListForms: IDictionary<OvertimeListFormContent> = Object.keys(data.overtimeListForms).reduce(function (previous: IDictionary<OvertimeListFormContent>, current) {
        previous[current] = OvertimeListFormContentFactoryFromJS(data.overtimeListForms[current]);
        return previous;
    }, {})

    let _overtimeForms: IDictionary<OvertimeFormContent> = Object.keys(data.overtimeForms).reduce(function (previous: IDictionary<OvertimeFormContent>, current) {
        previous[current] = OvertimeFormContentFactoryFromJS(data.overtimeForms[current]);
        return previous;
    }, {})

    let _salaryListForms: IDictionary<SalaryListFormContent> = Object.keys(data.salaryListForms).reduce(function (previous: IDictionary<SalaryListFormContent>, current) {
        previous[current] = SalaryListFormContentFactoryFromJS(data.salaryListForms[current]);
        return previous;
    }, {})

    let _salaryForms: IDictionary<SalaryFormContent> = Object.keys(data.salaryForms).reduce(function (previous: IDictionary<SalaryFormContent>, current) {
        previous[current] = SalaryFormContentFactoryFromJS(data.salaryForms[current]);
        return previous;
    }, {})

    return FormContentListFactory({
        userForms: Map(_userForms),
        userListForms: Map(_userListForms),
        departmentForms: Map(_departmentForms),
        departmentListForms: Map(_departmentListForms),
        positionForms: Map(_positionForms),
        positionListForms: Map(_positionListForms),
        professionForms: Map(_professionForms),
        professionListForms: Map(_professionListForms),
        companyForms: Map(_companyForms),
        companyListForms: Map(_companyListForms),
        companyAdminListForms: Map(_companyAdminListForms),
        userGroupForms: Map(_userGroupForms),
        userGroupListForms: Map(_userGroupListForms),
        managerKeyForms: Map(_managerKeyForms),
        managerKeyListForms: Map(_managerKeyListForms),
        shiftListForms: Map(_shiftListForms),
        shiftForms: Map(_shiftForms),
        overtimeListForms: Map(_overtimeListForms),
        overtimeForms: Map(_overtimeForms),
        salaryListForms: Map(_salaryListForms),
        salaryForms: Map(_salaryForms),

    });
}