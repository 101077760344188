import { IBaseAction } from '@bimser/core';
import { all, fork, put, take } from 'redux-saga/effects';
import { SignatureFormat } from '../../../utilities/DigitalSignatureAPI/entities';
import { closeModalTransactionAction, openModalAction } from '../../ModalManager/actions';
import { CLOSE_DIGITALSIGNATURE } from '../actions/actionTypes';
import { IDigitalSignatureProps } from '../entities';

export default function* DigitalSignatureSaga() {
    yield all([])
}

export function* openDigitalSignModal(payload: IDigitalSignatureProps) {
    try {
        let resolvePromise: any = null;
        let rejectPromise: any = null;

        let promise = new Promise<any>((resolve, reject) => {
            resolvePromise = resolve;
            rejectPromise = reject;
        });

        let DigitalSignModal = openModalAction({
            type: "DigitalSign",
            id: payload.id,
            visible: true,
            props: {
                ...payload,
                signatureOptions: {
                    ...payload.signatureOptions,
                    signatureFormat: SignatureFormat.CAdES
                }
            } as IDigitalSignatureProps
        });

        yield put(DigitalSignModal);
        yield fork(waitResponse, resolvePromise, rejectPromise, payload.id);

        return yield promise;
    } catch (error) {
        console.log("LOG: function*ConfirmationBoxManager -> error", error)
        return;
    }
}

function* waitResponse(resolvePromise: any, rejectPromise: any, id: string) {
    let action: IBaseAction<any> = yield take([CLOSE_DIGITALSIGNATURE]);
    yield put(closeModalTransactionAction(id));
    if (action?.type == CLOSE_DIGITALSIGNATURE && resolvePromise && action.payload.id == id) {
        resolvePromise(action.payload.result);
    }
}