interface IRegularExpressions {
    EmailRegex: RegExp,
    EmailJustNumberAndString: RegExp,
    Hex: RegExp,
    Number: RegExp,
    NumberDot: RegExp,
    Variable: RegExp,
    CodeRegex: RegExp,
    InputNumberRegex: RegExp,
    DmFolderNameRegex: RegExp,
    PhoneNumber: RegExp,
    nameRegex: RegExp,
    lastNameRegex: RegExp,
    shiftRegex: RegExp,
    UrlRegex: RegExp,
    HttpRegex: RegExp,
    JSONStringRegex: RegExp,
    CustomWord: (word: string) => RegExp,
}
export class ValidationHelper {

    /**
     * Every Regular expression that using with 
     * ValidationHelper unquestionable gonna add 
     * to this object. And should use from there.
     * Do not add special method for every regexp
     * Only create common utilities.
     */
    static RegularExpressions: IRegularExpressions = {
        EmailRegex: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        EmailJustNumberAndString: /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/,
        Hex: /^#?([a-f0-9]{6}|[a-f0-9]{3})$/,
        Number: /^\d+$/,
        NumberDot: /^(\d+|\d{1,3},\d{3}|\d{1,3},\d{3},\d{3}|\d{1,3}(,\d{3})*|\d{1,3}(,\d{3})*\.\d+)$/,
        Variable: new RegExp("^[a-zA-Z_][a-zA-Z0-9_]*$"),
        CodeRegex: new RegExp("^[a-zA-Z0-9_@.#-]+$"),
        InputNumberRegex: /^-?[0-9]{0,14}(\.[0-9]{0,15})?$/,
        DmFolderNameRegex: /^[A-Za-zşŞıİüÜçÇöÖ][a-zA-Z0-9()~@#$şŞıİüÜğĞçÇöÖəƏ\-_ .]*$/g,
        PhoneNumber: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
        nameRegex: /^([A-Za-zşŞıİüÜçÇöÖğĞəƏ]+[,.']?\s*)+$/,
        lastNameRegex: /^([A-Za-zşŞıİüÜçÇöÖğĞəƏ]+[,.']?\s*)+$/,
        shiftRegex: /^[A-Za-zşŞıİüÜçÇöÖğĞəƏ0-9_@.#-]+$/,
        UrlRegex: /^(http\:\/\/|https\:\/\/|ftp\:\/\/|)[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\S*)?$/,
        HttpRegex: /^(f|ht)tps?:\/\//i,
        CustomWord: (word: string) => new RegExp(`^((?!(word)).)*$`.replace("word", word)),
        JSONStringRegex: /^[\],:{}\s]*$/
    }

    static Test(regexp: RegExp, value: any, toLowerCase?: boolean, toUpperCase?: boolean) {
        return regexp.test(ValidationHelper.refiner(value, toLowerCase, toUpperCase));
    }

    static Match(regexp: RegExp, value: any, toLowerCase?: boolean, toUpperCase?: boolean) {
        return ValidationHelper.refiner(value, toLowerCase, toUpperCase).match(regexp);
    }

    static refiner(value: any, toLowerCase?: boolean, toUpperCase?: boolean) {

        let refinedValue = value?.toString();
        if (toLowerCase) {
            return refinedValue.toLowerCase();
        }
        else if (toUpperCase) {
            return refinedValue.toUpperCase();
        }
        return refinedValue;
    }

    /**
     * 
     * @param value Value That check for proper as a JSON.
     * Because of the process for mutating value,
     * this function added exceptionally.
     */
    static CheckJSONString(value: any) {

        let refinedValue = ValidationHelper.refiner(value);
        refinedValue = refinedValue
            .replace(/\\["\\\/bfnrtu]/g, '@').
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, '');

        return ValidationHelper.RegularExpressions.JSONStringRegex.test(refinedValue);
    }

}
