import { createSyncAction } from "@bimser/core";
import { IFlexPanelClickEventArgs } from "@bimser/components";
import * as ActionTypesMain from "./actionTypes";
import { FlexPanelFactoryFromJS } from "../entities/FlexPanelFactory";
import { WebInterfaceAction, WebInterfaceActionFactoryFromJS } from "../../common/entities";

export default function closeFlexPanelByIdTransactionAction(id: string, data?: any) {
    let payload: WebInterfaceAction = WebInterfaceActionFactoryFromJS({
        panelId: id,
        data: data
    })
    let action = createSyncAction(ActionTypesMain.CLOSE_FLEX_PANEL_BY_ID_TRANSACTION, payload);

    return action;
}
