import { Record } from "immutable"
import { IFlexPanel } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";

export type FlexPanel = ReadonlyRecord<IFlexPanel>;

export const FlexPanelFactory = Record<IFlexPanel>({
    id: '',
    title: '',
    className: '',
    size: 1,
    initialSize: 1,
    prevSize: 1,
    isActive: false,
    isOnScreen: false,
    isPinned: false,
    isVisible: false,
    isCloseButtonHide: false,
    isPinButtonHide: false,
    isHeaderAreaHide: false,
    isMaximizeButtonHide: false,
    isTitleHide: false,
    isMaximized: false,
    isStartupPanel: false,
    animationCssClass: '',
    componentKeys: null,
    onCloseClick: null,
    onMaximizeClick: null,
    onPanelClick: null,
    onPinClick: null,
    componentProps: null,
    isModified: false,
    canHandleCloseEvent: false,
    isHeaderActionsHide: false,
    fromMobile: false,
    disableResize: false,
    isTransparentBackground: false,
})


export const FlexPanelFactoryFromJS = function (data: IFlexPanel) {
    return FlexPanelFactory(data);
}