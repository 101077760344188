import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IOvertime, Overtime, OvertimeFactory, OvertimeFactoryFromJS } from "..";

export interface IOvertimeFormContent extends IBaseFormContent {
    showingOvertime?: IOvertime,
}

export interface IReadonlyOvertimeFormContent extends IBaseFormContent {
    showingOvertime?: Overtime
}

export type OvertimeFormContent = ReadonlyRecord<IReadonlyOvertimeFormContent>;

export const OvertimeFormContentFactory = Record<IReadonlyOvertimeFormContent>({
    panelId: "",
    showingOvertime: OvertimeFactory(),
    isModified: false,
    initialHash: "",
    showLoading: false
});

export const OvertimeFormContentFactoryFromJS = function (data: IOvertimeFormContent) {
    const _showingOvertime : Overtime = data.showingOvertime ? OvertimeFactoryFromJS(data.showingOvertime) : OvertimeFactory()

    return OvertimeFormContentFactory({
        panelId: data.panelId,
        showingOvertime: _showingOvertime,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
        initialHash: data.initialHash
    });
}