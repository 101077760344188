import { IDictionary } from '..';

const _reduce = require("lodash/reduce");
const _map = require("lodash/map");
const _isObject = require("lodash/isObject");

export function getObjectPaths(dataObj: IDictionary<any>): string[] {
    const reducer = (aggregator: string[], val: string, key: string) => {
        let _paths = [key];
        if (_isObject(val)) {
            _paths = _reduce(val, reducer, []);
            _paths = _map(_paths, (path: string) => key + '.' + path);
        }
        aggregator.push(..._paths);
        return aggregator;
    };
    const arrayIndexRegEx = /\.(\d+)/gi;
    let paths = _reduce(dataObj, reducer, []);
    paths = _map(paths, (path: string) => path.replace(arrayIndexRegEx, '[$1]'));

    return paths;
}