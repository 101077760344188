import * as React from "react";
import BCCheckbox from '../../../BCCheckbox';
import * as Styles from "../../assets/style.scss";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default function AllDayProperty(props: IPropertyComponentProps) {

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const onChange = (checked: boolean, value: number) => {
        let values = props.value && Array.isArray(props.value) ? props.value : [];
        let newValue = checked ? [...values, value] : values.filter((x) => x != value);
        props.customChangeEvent ? props.customChangeEvent(checked) : replaceValue(props.item, newValue)
    }

    const isCheckDay = (day: number) => {
        return props.value ? (props.value as Array<number>).includes(day) : false;
    }

    const renderDay = (day: number) => {
        return <div key={"key_" + day} className={Styles.dayContainer}>
            <span>{day}</span>
            <BCCheckbox
                checked={isCheckDay(day)}
                value={day}
                disabled={props.item.disabled}
                onChange={(onChange)}
            />
        </div>
    }

    const getDays = () => {
        let result: Array<number> = new Array();
        for (let index = 1; index <= 31; index++) {
            result.push(index);
        }
        return result;
    }

    return (
        <>
            {getDays().map((i) => renderDay(i))}
        </>
    )

}