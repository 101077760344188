import * as React from "react";
import * as ReactDOM from "react-dom";

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

export default class DragDropContextProviderContainer extends React.Component<any, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <DndProvider backend={HTML5Backend}>
                {this.props.children}
            </DndProvider>
        );
    }
}