import { createSyncAction } from "@bimser/core";
import * as ActionTypes from './actionTypes';
import { IWebInterfaceAction, WebInterfaceActionFactoryFromJS } from "common/entities/IWebInterfaceAction";

export function onChangeTotalApprovesCountAction(data: IWebInterfaceAction) {

    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.ONCHANGE_TOTAL_APPROVES_COUNT_ACTION, payload);

    return action;
}

export function onQuickEvent(data: IWebInterfaceAction) {
    let payload = WebInterfaceActionFactoryFromJS(data);

    let action = createSyncAction(ActionTypes.ONCLICK_MYTASKS_QUICKEVENT_TRANSACTION, payload);

    return action;
}

export function refreshApprovesListTransaction(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.REFRESH_APPROVES_LIST_TRANSACTION, WebInterfaceActionFactoryFromJS(data));
}

export function openAnnouncementFormTransaction(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.OPEN_ANNOUNCEMENT_FORM, WebInterfaceActionFactoryFromJS(data));
}

export function openAllRecentsAction(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.OPEN_ALL_RECENTS, WebInterfaceActionFactoryFromJS(data));
}

export function openDmViewerAction(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.OPEN_DM_VIEWER, WebInterfaceActionFactoryFromJS(data));
}

export function refreshDataTransaction(data: IWebInterfaceAction) {
    return createSyncAction(ActionTypes.REFRESH_DATA_TRANSACTION, WebInterfaceActionFactoryFromJS(data));
}