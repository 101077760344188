import { Record } from 'immutable';
import { ReadonlyRecord } from '@bimser/core';

interface IBaseFileMoveHistoryItem{
    id: string,
    folderName: string,
    secretKey: string,
    canLoadMore: boolean
}

export interface IFileMoveHistoryItem extends IBaseFileMoveHistoryItem {
    
}

export interface IReadonlyFileMoveHistoryItem extends IBaseFileMoveHistoryItem {
    
}

export type FileMoveHistoryItem = ReadonlyRecord<IReadonlyFileMoveHistoryItem>;

export const  FileMoveHistoryItemFactory = Record<IReadonlyFileMoveHistoryItem>({
    folderName: null,
    secretKey: null,
    id: null,
    canLoadMore: true
});

export const FileMoveHistoryItemFactoryFromJS = function (data: IFileMoveHistoryItem) {
    return FileMoveHistoryItemFactory(data);
}