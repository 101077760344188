import { connect } from 'react-redux';
import ConfirmationBox from '../components';
import { BaseState } from '../../../../../entities/BaseState';
import IConfirmationBoxProps from '../entities/IConfirmationBoxProps';
import { onConfirmationResolveAction } from '../actions/onConfirmationResolveAction';
import { onConfirmationRejectAction } from '../actions/onConfirmationRejectAction';

const mapStateToProps = function (state: BaseState, props: IConfirmationBoxProps): IConfirmationBoxProps {
    return {
        
    }
}



const mapDispatchToProps = function (dispatch: Function): IConfirmationBoxProps {
    return {
        onReject: function (id: string) {
            dispatch(onConfirmationRejectAction(id));
        },
        onResolve: function () {
            dispatch(onConfirmationResolveAction());
        }
    }
}


export const ConfirmationBoxContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmationBox);