import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IWorkflowContent, WorkflowContent, WorkflowContentFactory, WorkflowContentFactoryFromJS } from "./IWorkflowContent";
import { IWorkflowPanels, WorkflowPanels, WorkflowPanelsFactory, WorkflowPanelsFactoryFromJS } from "./IWorkflowPanels";

export interface IWorkflowState {
    content: IWorkflowContent,
    panels: IWorkflowPanels
}

export interface IReadonlyWorkflowState {
    content: WorkflowContent,
    panels: WorkflowPanels
}

export type WorkflowState = ReadonlyRecord<IReadonlyWorkflowState>;

export const WorkflowStateFactory = Record<IReadonlyWorkflowState>({
    content: WorkflowContentFactory(),
    panels: WorkflowPanelsFactory()
});

export const WorkflowStateFactoryFromJS = function (data: IWorkflowState) {
    return WorkflowStateFactory({
        content: WorkflowContentFactoryFromJS(data.content),
        panels: WorkflowPanelsFactoryFromJS(data.panels)
    });
}