import { IDictionary } from "..";
import * as en_US_messages from "./languages/en_US.json";
import * as ru_RU_messages from "./languages/ru_RU.json";
import * as tr_TR_messages from "./languages/tr_TR.json";
import * as zh_TW_messages from "./languages/zh_TW.json";
import * as az_AZ_messages from "./languages/az_AZ.json";

const tr_TR: IDictionary<string> = tr_TR_messages;
const en_US: IDictionary<string> = en_US_messages;
const ru_RU: IDictionary<string> = ru_RU_messages;
const zh_TW: IDictionary<string> = zh_TW_messages;
const az_Latn_AZ: IDictionary<string> = az_AZ_messages;

export { tr_TR, en_US, ru_RU, zh_TW, az_Latn_AZ };