import * as moxios from "moxios";
import { WrapResponse } from "./entities";

const BCMocker = {
    install: function () {
        moxios.install();
    },
    uninstall: function () {
        moxios.uninstall();
    },
    stubRequest: function <T>(urlOrRegExp: string | RegExp, response: WrapResponse<T>) {
        moxios.stubRequest(urlOrRegExp, {
            status: 200,
            response: response
        })
    }
}

export default BCMocker;