import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { List } from 'immutable';
import { DmData, IDmData } from '@bimser/common';

const getRecentFilesDataSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.recentFiles.data || List<DmData>([]);
}

export const getRecentFilesData = createSelector(
    [
        getRecentFilesDataSelector
    ],
    (recentFiles: List<DmData>) : Array<IDmData> => {
        return recentFiles.map(file => file.toJS()).toJS();
    }
);

const getRecentFilesLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.recentFiles.loading || false;
}

export const getRecentFilesLoading = createSelector(
    [
        getRecentFilesLoadingSelector
    ],
    (loading: boolean) : boolean => loading
);
