import { Record, List } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { Location, ILocation, LocationFactoryFromJS } from "./ILocation";

export interface IBaseLocationState {
    
}

export interface ILocationState extends IBaseLocationState {
    items: Array<ILocation>
}

export interface IReadonlyLocationState extends IBaseLocationState {
    items: List<Location>
}

export type LocationState = ReadonlyRecord<IReadonlyLocationState>;

export const LocationStateFactory = Record<IReadonlyLocationState>({
    items: List<Location>()
});

export const LocationStateFactoryFromJS = function (data: ILocationState) {
    let _locations: Array<Location> = data.items && data.items.length ? data.items.map((value: ILocation) => {
        return LocationFactoryFromJS(value);
    }) : [];

    return LocationStateFactory({
        items: List(_locations)
    });
}