import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IDomainProperties {
    domainVisible: boolean,
    defaultDomain: string,
    domains: {
    }
}

export interface IReadonlyDomainProperties {
    domainVisible: boolean,
    defaultDomain: string,
    domains: {
    }
}

export type DomainProperties = ReadonlyRecord<IReadonlyDomainProperties>;

export const DomainPropertiesFactory = Record<IReadonlyDomainProperties>({
    domainVisible: false,
    defaultDomain: '',
    domains: {
    }
});

export const DomainPropertiesFactoryFromJS = function (data: IDomainProperties) {
    return DomainPropertiesFactory(data);
}