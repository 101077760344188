import { FormItemTypes } from "@bimser/core";
import React from "react";
import { useDrop } from "react-dnd";
import * as Styles from "./assets/styles.scss";
import { IDropData, IDropZone } from "./entities";
import { ELayoutItemTypes } from "./entities/Constants";
import { getZoneType } from "./helpers";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const ACCEPTS = [ELayoutItemTypes.SIDEBAR_ITEM, ELayoutItemTypes.OBJECT_EXPLORER_ITEM, ELayoutItemTypes.COMPONENT, ELayoutItemTypes.SECTION, ELayoutItemTypes.COLUMN];

const DropZone = (args: IDropZone) => {
    const { path, childrenCount, parent, onDrop, isLast, className, dropType, disableMagnet, parentItemType } = args;
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ACCEPTS,
        drop: (item) => {
            onDrop({ path, childrenCount, parent } as IDropData, item);
        },
        canDrop: (item: any) => {
            const dropZonePath = path;
            const splitDropZonePath = dropZonePath.split("-");
            const itemPath: string = item.path;

            const type = item.data?.type || item.props?.data.type;
            if (FormItemTypes.EGhostItemTypes[type]) return false;

            if (!itemPath) {
                // sidebar items;

                const containerTypesEqual = type === dropType;
                const componentTypesEqual = FormItemTypes.EControlTypes[type] && dropType === ELayoutItemTypes.COMPONENT;
                const componentsCanDropOnColumn = FormItemTypes.EControlTypes[type] && dropType === FormItemTypes.EContainerTypes.Column;

                return containerTypesEqual || componentTypesEqual || componentsCanDropOnColumn

            }

            const splitItemPath = itemPath.split("-");

            // Current item can't possible move to it's own location
            if (itemPath === dropZonePath) return false;

            // Current area

            const itemZone = getZoneType(splitItemPath.length);
            const dropZone = getZoneType(splitDropZonePath.length);

            const allowDropItemOnlyItsLevel = itemZone === dropZone; // Current item can only move to it's own level
            const allowDropControlInsideSection = itemZone === "Control" && dropZone === "Column"; // The control item can move to the section item

            if (allowDropItemOnlyItsLevel || allowDropControlInsideSection) {
                const pathToItem = splitItemPath.slice(0, -1).join("-");
                const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

                const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
                const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

                if (pathToItem === pathToDropZone) {
                    const nextDropZoneIndex = currentItemIndex + 1;
                    if (nextDropZoneIndex === currentDropZoneIndex) return false;
                }

                return true
            }

            return false

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const active = isOver && canDrop;

    const dropZoneClassNames = cx({
        dropZone: true,
        active,
        canDrop: !active && canDrop,
        isLast,
        [className]: className ? true : false,
        fullHeight: childrenCount === 0,
        isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup,
        magnet: !disableMagnet && canDrop && parent.type === FormItemTypes.EContainerTypes.Column
    });

    return (
        <div
            className={dropZoneClassNames}
            ref={drop}
        />
    );
};
export default DropZone;
