import { IUserInfo, UserInfo, UserInfoFactoryFromJS } from '@bimser/common';
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { List, Map, Record } from "immutable";
import * as moment from 'moment';
import { DelegationType } from "./DelegationType";
import { DelegationPositionInfo, DelegationPositionInfoFactoryFromJS, IDelegationPositionInfo } from "./IDelegationPositionInfo";

interface IBaseDelegation {
    id: number,
    startDate: string,
    endDate: string,
    createdAt?: string,
    modifiedAt?: string,
    deletedAt?: string,
    secretKey?: string,
    isActive?: boolean,
    type?: DelegationType,
    accessTokenId?: string,
}

export interface IDelegation extends IBaseDelegation {
    name?: IDictionary<string>,
    from?: IUserInfo,
    to?: IUserInfo,
    scope?: Array<string>,
    createdBy?: IUserInfo,
    modifiedBy?: IUserInfo,
    deletedBy?: IUserInfo,
    positions?: Array<IDelegationPositionInfo>,
    positionIds?: number[];
}

export interface IReadonlyDelegation extends IBaseDelegation {
    name?: Map<string, string>,
    from?: UserInfo,
    to?: UserInfo,
    scope?: List<string>,
    createdBy?: UserInfo,
    modifiedBy?: UserInfo,
    deletedBy?: UserInfo,
    positions?: List<DelegationPositionInfo>,
    positionIds?: List<number>;
}

export type Delegation = ReadonlyRecord<IReadonlyDelegation>;

export const DelegationFactory = Record<IReadonlyDelegation>({
    id: 0,
    name: Map(),
    startDate: moment().format(),
    endDate: moment().add(1, "day").format(),
    createdAt: null,
    modifiedAt: null,
    deletedAt: null,
    createdBy: null,
    modifiedBy: null,
    deletedBy: null,
    from: null,
    to: null,
    scope: List(),
    positions: List(),
    secretKey: null,
    isActive: false,
    type: 1,
    accessTokenId: null,
    positionIds: List(),
});

export const DelegationFactoryFromJS = function (data: IDelegation) {
    const _positions = data.positions ? data.positions.map(p => DelegationPositionInfoFactoryFromJS(p)) : [];

    return DelegationFactory({
        name: Map(data.name),
        startDate: data.startDate,
        endDate: data.endDate,
        createdAt: data.createdAt,
        modifiedAt: data.modifiedAt,
        deletedAt: data.deletedAt,
        secretKey: data.secretKey,
        id: data.id,
        isActive: data.isActive,
        type: data.type,
        positions: List(_positions),
        scope: List(data.scope),
        from: UserInfoFactoryFromJS(data.from),
        to: UserInfoFactoryFromJS(data.to),
        createdBy: UserInfoFactoryFromJS(data.createdBy),
        modifiedBy: UserInfoFactoryFromJS(data.modifiedBy),
        deletedBy: UserInfoFactoryFromJS(data.deletedBy),
        accessTokenId: data.accessTokenId,
        positionIds: List(data.positionIds)
    });
}