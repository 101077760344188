import { IBaseAction } from "@bimser/core";
import * as actionTypes from '../actions/actionTypes';
import { PasswordRequirements } from "../entities";


export default function (state: PasswordRequirements, action: IBaseAction<any>): PasswordRequirements {
    switch (action.type) {
        case actionTypes.SET_PASSWORD_REQUIREMENTS_ACTION: {
            return setPasswordRequirements(state, action);
        }
        default:
            return state;
    }
}

function setPasswordRequirements(state: PasswordRequirements, action: IBaseAction<PasswordRequirements>): PasswordRequirements {
    return action.payload;
}