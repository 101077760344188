import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface ILanguageProperties {
    userCanChangeLanguage: boolean,
    languages: {
        
    }
}


export interface IReadonlyLanguageProperties  {
    userCanChangeLanguage: boolean,
    languages: {

    }
}

export type LanguageProperties = ReadonlyRecord<IReadonlyLanguageProperties>;

export const LanguagePropertiesFactory = Record<IReadonlyLanguageProperties>({
    userCanChangeLanguage: false,
    languages: {

    }
});

export const LanguagePropertiesFactoryFromJS = function (data: ILanguageProperties) {
    return LanguagePropertiesFactory(data);
}