import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';

const RecentFilesLoadingSelector = (state: WebInterfaceBaseState) => {
    return state.modules.startup.recentFilesLoading;
}

const getRecentFilesLoading = createSelector(
    [
        RecentFilesLoadingSelector
    ],
    (loading: boolean) => {
        return loading;
    }
);

export { getRecentFilesLoading };
