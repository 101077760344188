import { IDictionary } from "@bimser/core";
import { GetIconComponent } from "@bimser/icons";
import IconPinWindow from "@bimser/icons/16/pin-window";
import IconUnpinWindow from "@bimser/icons/16/unpin-window";
import classNames from 'classnames/bind';
import * as moment from 'moment';
import 'moment/locale/az';
import 'moment/locale/ru';
import * as React from "react";
import { BCList } from "../..";
import { IRecentItemGroup } from '../../BCFlexStartup/entities/IRecentItem';
import { findMessage } from '../../BCIntl';
import * as Styles from '../assets/flexStartupStyles.scss';
import { IRecentItem, IRecentItems } from '../entities';

const cx = classNames.bind(Styles);
type IRecentItemsProps = IRecentItems & { onClick: (item: IRecentItem) => void };

export default class RecentItems extends React.Component<IRecentItemsProps, {}> {

    constructor(props: IRecentItemsProps) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.onClickListItem = this.onClickListItem.bind(this);
    }

    onClickListItem(item: IRecentItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (this.props.onClick) this.props.onClick(item);
        e.stopPropagation();
    }

    pinToggle(item: IRecentItem, e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (this.props.onTogglePin) this.props.onTogglePin(item);
        e.stopPropagation();
    }

    renderItem(itemProps: IRecentItem): React.ReactNode {

        const classNames = cx({ listItem: true, pinable: true });
        const Icon = GetIconComponent(itemProps.icon);

        return (
            <div onClick={(e) => { this.onClickListItem(itemProps, e) }} className={classNames}>
                <i className={cx({ pinButton: true, pinned: itemProps.isPinned })} onClick={(e) => { this.pinToggle(itemProps, e) }}>{itemProps.isPinned ? <IconUnpinWindow /> : <IconPinWindow />}</i>
                <div className={Styles.listItemIcon}>
                    {Icon}
                </div>
                <div className={Styles.listItemContent}>
                    <h3>
                        {itemProps.title}
                    </h3>
                    {itemProps.description ? <p>{itemProps.description}</p> : null}
                </div>
                <div className={Styles.listItemTime}>
                    {moment(itemProps.time).fromNow()}
                </div>
            </div>
        )
    }

    renderHeader(groupName?: string) {
        if (!groupName) return undefined;

        return (
            <h2 className={Styles.listItemHeader}>{groupName}</h2>
        )
    }

    createList(groupName?: string, items?: IRecentItem[]) {
        return (
            <BCList
                header={this.renderHeader(groupName)}
                dataSource={items.sort((a, b) => a.time > b.time ? -1 : a.time < b.time ? 1 : 0)}
                bordered={false}
                split={false}
                loading={this.props.loading}
                renderItem={this.renderItem}
                className={Styles.recentItemList}
            />
        )
    }

    setItemGroup(item: IRecentItem) {
        const d = moment(new Date()).diff(item.time, 'days');
        let group: IRecentItemGroup;

        if (item.isPinned) {
            // pinned
            group = {
                key: 'pinned',
                label: findMessage.get('100667')
            }
        } else if (d < 1) {
            // today
            group = {
                key: 'today',
                label: findMessage.get('100660')
            }
        } else if (d < 2) {
            // yesterday
            group = {
                key: 'yesterday',
                label: findMessage.get('100661')
            }
        } else if (d < 7) {
            // thisWeek
            group = {
                key: 'thisWeek',
                label: findMessage.get('100662')
            }
        } else if (d < 14) {
            // lastWeek
            group = {
                key: 'lastWeek',
                label: findMessage.get('100663')
            }
        } else if (d < 30) {
            // thisMonth
            group = {
                key: 'thisMonth',
                label: findMessage.get('100664')
            }
        } else if (d < 60) {
            // lastMonth
            group = {
                key: 'lastMonth',
                label: findMessage.get('100665')
            }
        } else {
            // older
            group = {
                key: 'older',
                label: findMessage.get('100666')
            }
        }

        return {
            ...item,
            _group: group
        }
    }

    groupItemsByType() {

        let renderItems: JSX.Element[] = [];
        let arr: IDictionary<IRecentItem[]> = {
            pinned: [],
            today: [],
            yesterday: [],
            thisWeek: [],
            lastWeek: [],
            thisMonth: [],
            lastMonth: [],
            older: []
        };

        this.props.items.forEach(i => {
            let itemGroupInfo = this.setItemGroup(i);
            arr[itemGroupInfo._group.key].push(itemGroupInfo);
        });

        Object.keys(arr).forEach((groupName => {
            if (arr[groupName].length) {
                renderItems.push(
                    <div key={groupName}>
                        {this.createList(arr[groupName][0]._group.label, arr[groupName])}
                    </div>
                );
            }
        }));

        return renderItems;
    }

    render() {
        if (!this.props.items || !this.props.items.length) {
            return this.createList(undefined, this.props.items); // Renders empty list for no data label
        }
        return this.groupItemsByType()
    }
}
