import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { Record, Map } from "immutable";

export interface IBaseUser {
    id: number,
    username: string,
    firstname: string,
    lastname: string,
    password: string,
    status: number,
    eMail?: string,
    importStatus?: number,
    birthDate?: string,
    employementStart?: string,
    employementEnd?: string,
    category?: number,
    sex?: number,
    type?: number,
    department: number,
    profession: number,
    departmentName?: string,
    professionName?: string,
    loadDetail?: boolean,
    profileImage?: string,
    usedCount?: number,
    phone?: string,
    mobilePhone?: string,
    registrationNumber?: string,
    wayOfWork?: string,
    placeOfBirth?: string,
    maritalStatus?: string,
    identificationNumber?: string,
    personalEMail?: string,
    personalMobilePhone?: string,
    personalPhone?: string,
    address?: string,
    educationalStatus?: string,
    shiftId?: number,
    defaultCulture?: string,

}

export interface IUser extends IBaseUser {
    shiftName?: IDictionary<string>,
}

export interface IReadonlyUser extends IBaseUser {
    shiftName?: Map<string, string>,
}

export type User = ReadonlyRecord<IReadonlyUser>;

export const UserFactory = Record<IReadonlyUser>({
    id: 0,
    username: "",
    firstname: "",
    lastname: "",
    password: "",
    eMail: "",
    status: 0,
    importStatus: 0,
    birthDate: null,
    employementStart: null,
    employementEnd: null,
    category: 0,
    sex: 0,
    type: 0,
    department: 0,
    profession: 0,
    departmentName: "",
    professionName: "",
    shiftName: Map(),
    loadDetail: false,
    profileImage: null,
    usedCount: 0,
    phone: null,
    mobilePhone: null,
    registrationNumber: null,
    wayOfWork: undefined,
    placeOfBirth: null,
    maritalStatus: undefined,
    identificationNumber: null,
    personalEMail: null,
    personalMobilePhone: null,
    personalPhone: null,
    address: null,
    educationalStatus: undefined,
    shiftId: null,
    defaultCulture: null,
});

export const UserFactoryFromJS = function (user: IUser) {
    return UserFactory({
            ...user,
            shiftName: Map(user?.shiftName)
        })
}