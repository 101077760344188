import * as React from "react";
import * as Styles from "../assets/deviceViewerStyles.scss";
import { EDeviceTypes, IDeviceProps, IDeviceTypeSelectorProps } from "../entities";

const devices: IDeviceProps[] = [
    {
        name: '4K', breakpoint: 2560
    },
    {
        name: 'Laptop L', breakpoint: 1440
    },
    {
        name: 'Laptop', breakpoint: 1024
    },
    {
        name: 'Tablet', breakpoint: 768
    },
    {
        name: 'Mobile L', breakpoint: 425
    },
    {
        name: 'Mobile M', breakpoint: 375
    },
    {
        name: 'Mobile S', breakpoint: 320
    }
];

export default (props: IDeviceTypeSelectorProps) => {
    const [hoveredDeviceText, setHoveredDeviceText] = React.useState<string>("");

    const onDeviceTypeSelected = (device: IDeviceProps) => {
        props.onDimensionChanged?.({ width: device.breakpoint }, EDeviceTypes.Responsive);
    }

    const renderDevices = () => (
        devices.map(device => {
            return (
                <div
                    className={Styles.deviceTypeButton + ' ' + Styles['deviceType' + device.breakpoint]}
                    key={device.breakpoint}
                    onClick={() => { onDeviceTypeSelected(device) }}
                    onMouseEnter={() => setHoveredDeviceText(`${device.name} - ${device.breakpoint}px`)}
                    onMouseLeave={() => setHoveredDeviceText("")}
                />
            )
        })
    );

    return (
        <div className={Styles.deviceTypeSelector}>
        <div className={Styles.deviceTypeSelectorButtons} style={props.zoomStyle}>
            {renderDevices()}
        </div>
        <span className={Styles.zoomValue}>{hoveredDeviceText}</span>
    </div>
    );
}