import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { GetTOTPRegisterResponse, GetTOTPRegisterResponseFactory, GetTOTPRegisterResponseFactoryFromJS, IGetTOTPRegisterResponse, MFAType } from ".";

export interface IMFAPropertiesState {
    mfaEnabled?: boolean,
    mfaLoading?: boolean,
    authenticatorEnabled?: boolean,
    tempTokenKey?: string,
    currentLoginProcedure?: string,
    currentMFAMethod?: MFAType,
    mfaTypes?: Array<MFAType>,
    totpRegisterProperties?: IGetTOTPRegisterResponse,
    recoveryCodes?: Array<string>
}

export interface IReadonlyMFAPropertiesState {
    mfaEnabled?: boolean,
    mfaLoading?: boolean,
    authenticatorEnabled?: boolean,
    tempTokenKey?: string,
    currentLoginProcedure?: string,
    currentMFAMethod?: MFAType,
    mfaTypes?: List<MFAType>,
    totpRegisterProperties?: GetTOTPRegisterResponse,
    recoveryCodes?: List<string>,
}

export type MFAPropertiesState = ReadonlyRecord<IReadonlyMFAPropertiesState>;

export const MFAPropertiesStateFactory = Record<IReadonlyMFAPropertiesState>({
    mfaEnabled: false,
    mfaLoading: false,
    authenticatorEnabled: false,
    tempTokenKey: null,
    currentLoginProcedure: null,
    currentMFAMethod: null,
    mfaTypes: List(),
    totpRegisterProperties: GetTOTPRegisterResponseFactory(),
    recoveryCodes: List(),
});

export const MFAPropertiesStateFactoryFromJS = function (data: IMFAPropertiesState) {
    return MFAPropertiesStateFactory({
        mfaEnabled: data?.mfaEnabled,
        mfaLoading: data?.mfaLoading,
        authenticatorEnabled: data?.authenticatorEnabled,
        tempTokenKey: data?.tempTokenKey,
        currentLoginProcedure: data?.currentLoginProcedure,
        currentMFAMethod: data.currentMFAMethod,
        mfaTypes: List(data?.mfaTypes),
        totpRegisterProperties: GetTOTPRegisterResponseFactoryFromJS(data.totpRegisterProperties),
        recoveryCodes: List(data?.recoveryCodes || [])
    });
};
