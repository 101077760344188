enum EContainerTypes {
    Section = 'Section',
    Column = 'Column',
    Form = 'Form'
}

enum EControlTypes {
    Button = "Button",
    CheckBox = "CheckBox",
    ComboBox = "ComboBox",
    Label = "Label",
    Panel = "Panel",
    ListBox = "ListBox",
    Radio = "Radio",
    RadioList = "RadioList",
    DataGrid = "DataGrid",
    Barcode = "Barcode",
    TextBox = "TextBox",
    DocumentViewer = "DocumentViewer",
    HTMLTextBox = "HTMLTextBox",
    UserMetadata = "UserMetadata",
    DocumentMetadata = "DocumentMetadata",
    FormMetadata = "FormMetadata",
    Image = "Image",
    RelatedDocuments = "RelatedDocuments",
    Transfer = "Transfer",
    Header = "Header",
    Divider = "Divider",
    TextArea = "TextArea",
    NumberBox = "NumberBox",
    DateTimePicker = "DateTimePicker",
    TimePicker = "TimePicker",
    Rate = "Rate",
    Switch = "Switch",
    AutoComplete = "AutoComplete", // Deprecated
    Slider = "Slider",
    Tabs = "Tabs",
    Tab = "Tab",
    TagBox = "TagBox",
    Password = "Password",
    MaskInput = "MaskInput",
    Chart = "Chart",
    LineChart = "LineChart",
    SplineChart = "SplineChart",
    BarChart = "BarChart",
    ScatterChart = "ScatterChart",
    AreaChart = "AreaChart",
    PieChart = "PieChart",
    Lookup = "Lookup",
    TreeSelect = "TreeSelect",
    DateRangePicker = "DateRangePicker",
    Scheduler = "Scheduler",
    SearchBox = "SearchBox",
    TabMenu = "TabMenu",
    Dropdown = "Dropdown",
    Ribbon = "Ribbon",
    TreeView = "TreeView",
    Collapse = "Collapse",
    DOMElement = "DOMElement",
    ScrollView = "ScrollView",
    Iframe = "Iframe",
    ColorPicker = "ColorPicker",
    Mirror = "Mirror",
    ContextMenu = "ContextMenu",
    Card = "Card",
    TreeList = "TreeList",
    FormViewer = "FormViewer",
    InputGroup = "InputGroup",
    Repeater = "Repeater",
    ReportViewer = "ReportViewer",
    FileSelector = "FileSelector",
    FishBone = "FishBone",
    ProtectedTextBox = "ProtectedTextBox",
    Map = "Map",
    VectorMap= "VectorMap"
}



enum EPanelBaseTypes {
    Panel = "Panel",
    Tabs = "Tabs",
    Tab = "Tab",
    Collapse = "Collapse",
    ScrollView = "ScrollView",
    InputGroup = "InputGroup"
}

enum EGhostItemTypes {
    ContextMenu = EControlTypes.ContextMenu
}

export { EContainerTypes, EControlTypes, EGhostItemTypes, EPanelBaseTypes };
