import * as React from "react";
import { IThemeChanger } from './entities';
import { generateStyles } from "./helpers/generateStyles";

const BCThemeChanger = ({ children, fontFamily, goldenColor, primaryColor, secondaryIconColor, backgroundColor, backgroundImage }: IThemeChanger) => {

    const styles = React.useMemo(() => generateStyles({
        fontFamily,
        goldenColor,
        primaryColor,
        secondaryIconColor,
        backgroundImage,
        backgroundColor
    }), [fontFamily, goldenColor, primaryColor, secondaryIconColor, backgroundImage, backgroundColor]);

    return (
        <>
            <style>{styles}</style>
            {children}
        </>
    )

}

export default BCThemeChanger
export { IThemeChanger };

