import { IBaseAction } from '@bimser/common';
import { List } from 'immutable';
import { ON_SELECTED_TEMPLATE_CHANGE_ACTION, ON_TEMPLATE_DATA_CHANGE_ACTION, SET_ESIGNATURE_DATA_ACTION } from '../actions/actionTypes';
import { ESignatureState } from "../entities/IESignatureState";
import IOnChangeTemplateDataPayload from '../entities/IOnChangeTemplateDataPayload';
import { SignatureTemplate } from '../entities/ISignatureTemplate';


const ESignatureReducer = (state: ESignatureState, action: IBaseAction<any>): ESignatureState => {
    switch (action.type) {
        case SET_ESIGNATURE_DATA_ACTION: {
            return setESignatureData(state, action.payload);
        }
        case ON_TEMPLATE_DATA_CHANGE_ACTION: {
            return onChangeTemplateData(state, action.payload);
        }
        case ON_SELECTED_TEMPLATE_CHANGE_ACTION: {
            return onChangeSelectedTemplateId(state, action.payload);
        }
        default:
            return state;
    }
}
function onChangeSelectedTemplateId(state: ESignatureState, payload: string) {
    let currentTemplateId = state.selectedTemplateId;
    state.templates.forEach((t, index) => {
        if (t.templateId == currentTemplateId) {
            let signers = t.signers.map((s) => {
                s = s.set("fullName", "").set("email", "");
                return s;
            })
            state = state.setIn(["templates", index, "signers"], signers);
        }
    })
    state = state.set("selectedTemplateId", payload);

    return state;
}

function setESignatureData(state: ESignatureState, payload: List<SignatureTemplate>) {
    return state.set("templates", payload);
}

function onChangeTemplateData(state: ESignatureState, payload: IOnChangeTemplateDataPayload) {
    let templateIndex = state.templates.findIndex(t => t.templateId == payload.templateId);
    if (templateIndex > -1) {
        let signerIndex = state.templates.get(templateIndex).signers.findIndex(s => s.roleName == payload.roleName);
        if (signerIndex > -1) {
            state = state.setIn(["templates", templateIndex, "signers", signerIndex, payload.field], payload.data);
        }
    }
    return state;
}

export default ESignatureReducer;