import { List, Record } from "immutable"
import { IBreadCrumbItemProps, IBreadCrumbProps } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { IBreadCrumbItemClickEventArgs } from "@bimser/components";


export interface IReadOnlyBreadCrumb {
    items: List<BreadCrumbItem>,
    onCreate?: () => any;
    onItemClick?: (args: IBreadCrumbItemClickEventArgs) => any;
}

export type BreadCrumb = ReadonlyRecord<IReadOnlyBreadCrumb>;

export const BreadCrumbFactory = Record<IReadOnlyBreadCrumb>({
    items: List<BreadCrumbItem>(),
    onCreate: null,
    onItemClick: null
});

export const BreadCrumbFactoryFromJS = function (data: IBreadCrumbProps) {
    let items = data.items.map(item => {
        return BreadCrumbItemFactoryFromJS(item);
    });

    return BreadCrumbFactory({
        onCreate: data.onCreate,
        onItemClick: data.onItemClick,
        items: List<BreadCrumbItem>(items)
    });
}



export type BreadCrumbItem = ReadonlyRecord<IBreadCrumbItemProps>;

export const BreadCrumbItemFactory = Record<IBreadCrumbItemProps>({
    id: '',
    title: '',
    description: '',
    isActive: false,
    isOnTheScreen: false,
    isHidden: false,
    isPinned: false,
    onClick: null
});

export const BreadCrumbItemFactoryFromJS = function (data: IBreadCrumbItemProps) {
    return BreadCrumbItemFactory(data);
}



