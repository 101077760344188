import * as React from "react";
import IImageUploadProps from "./entities/IImageUploadProps";
import { Modal, Upload, UploadFile } from "antd";
import IconClosePanel from "@bimser/icons/16/close-panel";

const modalStyle: React.CSSProperties = { maxWidth: "600px" };
const modalImageStyle: React.CSSProperties = { width: "100%",padding:"10px" };

export default function BCImageUpload(props: IImageUploadProps) {
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState('');
    const [fileList, setFileList] = React.useState<UploadFile[]>(props.value ? [
        {
            uid: '-1',
            name: 'temp',
            status: 'done',
            url: props.value,
        },
    ] : []);

    const getBase64 = (img: any, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result as string));
        reader.readAsDataURL(img);
    }

    const handleChange = (info: any) => {
        if (info.file.status === "removed") {
            setFileList([]);
            props.onValueChange?.(null);
        }
        else {
            info.fileList.forEach(file => {
                getBase64(file.originFileObj, (url) => {
                    let list = fileList;
                    list.push({
                        uid: (fileList.length + 1).toString(),
                        name: file.name,
                        status: 'done',
                        url: url,
                    });
                    setFileList(list);
                    props.onValueChange?.(url);
                });
            });
        }

    };

    const handlePreview = async (file: any) => {
        setPreviewImage(file.url);
        setPreviewOpen(true);
    };

    const handleCancel = () => setPreviewOpen(false);

    const uploadButton = (
        <div>
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const customRequest = (options) => {
        setTimeout(() => {
            options.onSuccess("done");
        }, 0);
    };

    return (
        <>
            <Upload
                name={props.name}
                listType={props.listType}
                accept={props.accept}
                showUploadList={{ showRemoveIcon: props.showRemoveIcon, showPreviewIcon: props.showPreviewIcon }}
                onChange={handleChange}
                maxCount={props.maxCount}
                customRequest={customRequest}
                onPreview={handlePreview}
                disabled={props.disabled}
                style={props.style}
                fileList={fileList}
            >
                {fileList.length ? null : uploadButton}
            </Upload>
            <Modal visible={previewOpen} style={modalStyle} closeIcon={<IconClosePanel />} centered footer={null} onCancel={handleCancel}>
                <img alt="example" style={modalImageStyle} src={previewImage} />
            </Modal>
        </>
    )
}