import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseUserInfo {
    id: number,
    name: string,
    username: string
}

export interface IUserInfo extends IBaseUserInfo {
    behalfOf?: IUserInfo
}

export interface IReadonlyUserInfo extends IBaseUserInfo {
    behalfOf?: IReadonlyUserInfo
}

export type UserInfo = ReadonlyRecord<IReadonlyUserInfo>;

export const UserInfoFactory = Record<IReadonlyUserInfo>({
    id: null,
    name: null,
    username: null,
    behalfOf: null
});

export const UserInfoFactoryFromJS = function (data: IUserInfo) {
    if (!data) return null;
    return UserInfoFactory({
        id: data.id,
        name: data.name,
        username: data.username,
        behalfOf: UserInfoFactory(data.behalfOf)
    });
}