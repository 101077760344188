import { IExtremeGridState } from "../entities";
import { IBaseMutableAction, IDictionary } from "@bimser/core";

export function changeAddedRowsReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<any>>): IExtremeGridState {
    return Object.assign({}, state, {
        editing: {
            ...(state.editing || {}),
            changes: {
                ...(state.editing.changes || {}),
                addedRows: action.payload
            }
        },
    })
}

export function changeEditingRowIdsReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<number | string>>): IExtremeGridState {
    return Object.assign({}, state, {
        editing: {
            ...(state.editing || {}),
            changes: {
                ...(state.editing.changes || {}),
                editingRowIds: action.payload
            }
        },
    })
}

export function changeChangesRowReducer(state: IExtremeGridState, action: IBaseMutableAction<IDictionary<any>>): IExtremeGridState {
    return Object.assign({}, state, {
        editing: {
            ...(state.editing || {}),
            changes: {
                ...(state.editing.changes || {}),
                rowChanges: action.payload
            }
        },
    })
}

export function commitChangesReducer(state: IExtremeGridState, action: IBaseMutableAction<Array<any>>): IExtremeGridState {
    return Object.assign({}, state, {
        dataSource: {
            ...(state.dataSource ? state.dataSource : {}),
            data: action.payload
        }
    })
}