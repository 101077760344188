import * as React from "react";
import * as Style from "./assets/css/style.scss";
import {
    IComponentList, IFlexLayoutBaseClickEventArgs, IFlexLayoutProps,
    IFlexLayoutState,
    IFlexPanel,
    IFlexPanelAnimationEndEvent, IFlexPanelClickEventArgs,
    IFlexPanelContentComponent,
    IFlexPanelProps, IFlexPanelState
} from './entities';
import IFlexPanelComponent from "./entities/IFlexPanelComponent";
import FlexPanel from "./FlexPanel";

export default class BCFlexLayout extends React.Component<IFlexLayoutProps, {}> {
    staticHeight: React.CSSProperties;

    constructor(props: IFlexLayoutProps) {
        super(props);

        this.onFlexPanelClick = this.onFlexPanelClick.bind(this);
        this.onFlexPanelMaximizeClick = this.onFlexPanelMaximizeClick.bind(this);
        this.onFlexPanelPinClick = this.onFlexPanelPinClick.bind(this);
        this.onFlexPanelCloseClick = this.onFlexPanelCloseClick.bind(this);
        this.onAnimationEnd = this.onAnimationEnd.bind(this);
    }

    componentDidMount() {
        if (this.props.onCreate) {
            this.props.onCreate();
        }
    }

    generateTemplate(props: IFlexLayoutProps): JSX.Element {

        let { items, components, breakpoint } = this.props;

        this.staticHeight = this.props.fromMobile ? { height: window.innerHeight } : null;

        return (

            <div className={[Style.flexPanelContainer, this.props.fromMobile && Style.fromMobile].join(" ")} style={this.staticHeight}>

                {
                    items != null && items.length > 0 ? (

                        this.props.items.map((item: IFlexPanel, index: number) => {
                            if (!item.isVisible || !item.isOnScreen) {
                                return;
                            }

                            let list: Array<IFlexPanelComponent> = item.componentKeys.map((componentKey: string) => {
                                let componentProps: any;
                                if (item.componentProps && Array.isArray(item.componentProps)) {
                                    item.componentProps.forEach((currentCompProps) => {
                                        if (currentCompProps.componentKey === componentKey) {
                                            componentProps = { ...currentCompProps.props, key: item.id };
                                        }
                                    });
                                }
                                return {
                                    component: components[componentKey], props: componentProps ? { ...componentProps } : { key: index }
                                };
                            })

                            return (
                                <FlexPanel
                                    key={item.id}
                                    id={item.id}
                                    title={item.title}
                                    isActive={item.isActive}
                                    isOnScreen={item.isOnScreen}
                                    isPinned={breakpoint ? false : item.isPinned}
                                    isVisible={item.isVisible}
                                    isMaximized={breakpoint ? false : item.isMaximized}
                                    isCloseButtonHide={item.isCloseButtonHide}
                                    isHeaderAreaHide={item.isHeaderAreaHide}
                                    isMaximizeButtonHide={breakpoint ? true : item.isMaximizeButtonHide}
                                    isPinButtonHide={breakpoint ? true : item.isPinButtonHide}
                                    isTitleHide={item.isTitleHide}
                                    animationCssClass={item.animationCssClass}
                                    size={item.size}
                                    isStartupPanel={item.isStartupPanel}
                                    initialSize={item.initialSize}
                                    prevSize={item.prevSize}
                                    onPanelClick={this.onFlexPanelClick}
                                    onCloseClick={this.onFlexPanelCloseClick}
                                    onMaximizeClick={this.onFlexPanelMaximizeClick}
                                    onPinClick={this.onFlexPanelPinClick}
                                    onAnimationEnd={this.onAnimationEnd}
                                    components={list}
                                    isHeaderActionsHide={item.isHeaderActionsHide}
                                    fromMobile={item.fromMobile}
                                    isTransparentBackground={item.isTransparentBackground}
                                />
                            )

                        }))
                        : null
                }

            </div>

        );
    }

    onFlexPanelClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onFlexPanelClick)
            this.props.onFlexPanelClick(args);
    }

    onFlexPanelMaximizeClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onFlexPanelMaximizeClick)
            this.props.onFlexPanelMaximizeClick(args);
    }

    onFlexPanelPinClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onFlexPanelPinClick)
            this.props.onFlexPanelPinClick(args);
    }

    onFlexPanelCloseClick(args: IFlexPanelClickEventArgs) {
        args.senderArgs.persist();
        args.senderArgs.stopPropagation();
        if (this.props.onFlexPanelCloseClick)
            this.props.onFlexPanelCloseClick(args);
    }

    onAnimationEnd(args: IFlexPanelAnimationEndEvent) {
        if (this.props.onAnimationEnd) {
            this.props.onAnimationEnd(args);
        }
    }

    render() {
        return (
            this.generateTemplate(this.props)
        );
    };
}

export {
    IFlexLayoutProps,
    IFlexLayoutState,
    IFlexPanel,
    IFlexPanelState,
    IFlexPanelClickEventArgs,
    IFlexLayoutBaseClickEventArgs,
    IComponentList,
    IFlexPanelContentComponent,
    IFlexPanelAnimationEndEvent,
    IFlexPanelProps
};

