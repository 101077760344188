const fs = require('fs');
const path = require('path');
const SVGO = require("svgo");

const svgo = new SVGO({});

const svgRootPath = path.resolve(__dirname, "../svg");
const destRootPath = path.resolve(__dirname, "../dist/svg");

async function optimizer(relativePath) {
    const dirPath = path.resolve(svgRootPath, relativePath);

    const dirents = fs.readdirSync(dirPath, { withFileTypes: true });

    for (let i = 0; i < dirents.length; i++) {
        let item = dirents[i];

        if (item.isDirectory()) {
            const subPath = path.join(relativePath, item.name);
            await optimizer(subPath);
        }
        else if (item.isFile()) {
            const destFileRelativePath = path.join(relativePath, item.name);
            const destFilePath = path.resolve(destRootPath, destFileRelativePath);
            const srcFilePath = path.resolve(dirPath, item.name);
            const destFileDirPath = path.dirname(destFilePath);

            const svgStr = fs.readFileSync(srcFilePath, "utf8");
            const optimizedSvg = await svgo.optimize(svgStr, { path: srcFilePath });

            fs.mkdirSync(destFileDirPath, { recursive: true });
            fs.writeFileSync(destFilePath, optimizedSvg.data);
        }
    };
}

async function optimize() {
    await optimizer("");
}


module.exports = optimize;