import { MessageBox, MessageBoxButtons } from '@bimser/common';
import { BCAvatar, BCDropdownWrapper, BCModal, BCRibbonBar, BCScrollbars, BCTreeview, findMessage, IMenuProps, IRibbonBarItemProps, ITreeviewItem } from '@bimser/components';
import IconApprove from "@bimser/icons/16/approve";
import IconDownload from "@bimser/icons/16/download";
import IconInfo from '@bimser/icons/16/info';
import IconMore from "@bimser/icons/16/more2";
import IconSignature from "@bimser/icons/16/signature";
import IconToasterSevereWarning from "@bimser/icons/16/toaster-severe-warning";
import IconInfoBig from "@bimser/icons/64/info";
import IconSevereWarning from "@bimser/icons/64/severe-warning";
import * as moment from 'moment';
import * as React from 'react';
import * as Style from '../assets/style.scss';
import { ISignatureModalProps } from '../entities';
import { ISignature } from '../entities/ISignature';

const emptyImg = require('../assets/img/signature-empty.svg');

const SignatureModal = (props: ISignatureModalProps) => {

    const onToolbarItemClick = (event: React.MouseEvent, item: IRibbonBarItemProps) => {
        props.onSignDocument({
            modalId: props.modalId,
            secretKey: props.secretKey,
            versionSecretKey: props.versionSecretKey,
            fileName: props.fileName,
            mimeType: props.mimeType,
            signMethod: item.key as any,
            signType: props.signType,
            parameters: props.parameters
        })
    }

    const getToolbarItems = () => {
        return [
            {
                key: "digitalSign",
                text: findMessage.get("101187"),
                onClick: onToolbarItemClick,
                disabled: !props.permissions.digitalSignatureEnabled,
                icon: IconSignature.info,
                onRenderIcon: () => <IconSignature />,
            },
            {
                key: "docuSign",
                text: findMessage.get("102168"),
                onClick: onToolbarItemClick,
                disabled: props.signType == "flow" || !props.permissions.docuSignEnabled || !props.fileName.endsWith("pdf"), // TODO MimeType gelince mimeType üzerinden kontrol edilecek.
                icon: IconSignature.info,
                onRenderIcon: () => <IconSignature />,
            },
            {
                key: "mobileSign",
                text: findMessage.get("102137"),
                onClick: onToolbarItemClick,
                disabled: !props.permissions.mobileSignatureEnabled,
                icon: IconSignature.info,
                onRenderIcon: () => <IconSignature />,
            }
        ] as IRibbonBarItemProps[]
    }

    const onClickDownloadCertificate = (dataRef: ISignature) => {
        let data = atob(dataRef.certificate?.data);
        let certificate = new Blob([data], { type: "application/x-x509-ca-cert" });
        let link = document.createElement('a');
        link.href = URL.createObjectURL(certificate);
        link.download = dataRef.signOwner + "_certificate.crt";
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
        }, 100);
    }

    const renderPopoverContentForItem = (dataRef: ISignature) => {
        return (
            <div className={Style.SignatureValidationContainer} >
                <div className={Style.validationIcon}>
                    <IconInfoBig />
                </div>
                <div className={Style.validationData}>
                    <span className={Style.validationDescription}>{findMessage.get('102177')}</span>
                    <div className={Style.SignatureValidationContent}>
                        <BCScrollbars>
                            <div className={Style.SignatureDetailsContent}>
                                <span>{findMessage.get('102178') + dataRef.signerLocation}</span>
                                <span>{findMessage.get('102179') + dataRef.identifierNo}</span>
                                <span>{findMessage.get('102180') + dataRef.signType}</span>
                                <span>{findMessage.get('102181') + dataRef.digestAlgorithm}</span>
                                <span>{findMessage.get('102182') + dataRef.signatureAlgorithm}</span>
                                <span>{findMessage.get('102183') + dataRef.signOwnerDescription}</span>
                                <span>{findMessage.get('102184') + (dataRef.timeStamp ? dataRef.timeStamp.owner : "")}</span>
                                <span>{findMessage.get('102185') + (dataRef.timeStamp ? moment(dataRef.timeStamp.date).format("L LTS") : "")}</span>
                                <span>{findMessage.get('102186') + (dataRef.timeStamp ? dataRef.timeStamp.digestAlgorithm : "")}</span>
                                <span>{findMessage.get('102187') + dataRef.moneyLimit}</span>
                                <span>{findMessage.get('102188') + dataRef.policyOID}</span>
                                <span>{findMessage.get('102189') + dataRef.policyAddress}</span>
                            </div>
                        </BCScrollbars>
                    </div>
                </div>
            </div>
        )
    }

    const renderTreeItem = (item: ITreeviewItem<any>) => {
        const signatureDetailTemplate = (_item: any) => {
            return (
                <div onClick={() => {
                    if (_item.key == "details") {
                        MessageBox.show({
                            title: findMessage.get('102177'),
                            content: renderPopoverContentForItem(item.dataRef),
                            width: "600px",
                            buttons: MessageBoxButtons.OK
                        })
                    } else {
                        onClickDownloadCertificate(item.dataRef)
                    }
                }} className={Style.DropdownMenuItem}>
                    {_item.key == "details" ? <IconInfo className={Style.SignatureIcons} /> : <IconDownload className={Style.SignatureIcons} />}
                    <span>{_item.text}</span>
                </div>
            )
        }
        const signatureDetailItems: IMenuProps = {
            items: [
                { key: "details", text: findMessage.get('102175'), value: "details", customTemplate: signatureDetailTemplate },
                { key: "download", text: findMessage.get('102176'), value: "download", customTemplate: signatureDetailTemplate }
            ]
        }

        return (
            <div className={Style.signatureCard} key={item.key}>
                <div className={Style.signatureInfo}>
                    <span className={Style.infoColumnName}><BCAvatar names={item.dataRef.signOwner.split(" ")} alt={item.dataRef.signOwner} size={"small"} /> {item.dataRef.signOwner}</span>
                    <div className={Style.infoColumnDate}>
                        <span className={Style.infoDate}>{moment(item.dataRef.signDate).format("L LTS")}</span>
                        <span className={Style.infoStatus}>{item.dataRef.isValid ? <IconApprove className={Style.SignatureIcons} /> : <IconToasterSevereWarning className={Style.SignatureIcons} />}</span>
                        <div className={Style.signatureDetails}>
                            <BCDropdownWrapper trigger={["click"]} placement={"bottomRight"} menuProps={signatureDetailItems}>
                                <IconMore className={Style.detailIcon} />
                            </BCDropdownWrapper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderValidationDetail = (validationDetail: string) => {
        let rows = validationDetail.split("\n");
        return (
            <div className={Style.SignatureValidationContainer} >
                <div className={Style.validationIcon}>
                    <IconSevereWarning />
                </div>
                <div className={Style.validationData}>
                    <span className={Style.validationDescription}>{props.documentSignature.validationDescription}</span>
                    <div className={Style.SignatureValidationContent}>
                        <BCScrollbars>
                            <div className={Style.validationContent}>
                                {rows.map((r, index) => {
                                    if (r.length == 0) return;
                                    if (r.startsWith("###INVALID###")) {
                                        return <span key={index.toString()} className={Style.invalidItem}>{r.replace("###INVALID###", "").replace(":", ":\n")}</span>
                                    }
                                    return <span key={index.toString()}>{r.replace(":", ":\n")}</span>
                                })}
                            </div>
                        </BCScrollbars>
                    </div>
                </div>
            </div >
        )
    }

    const onClickValidationDetail = () => {
        MessageBox.show({
            title: findMessage.get("100652"),
            content: renderValidationDetail(props.documentSignature.validationDetail),
            width: "600px",
            buttons: MessageBoxButtons.OK
        })
    }

    const renderEmpty = (
        <div className={Style.customEmpty}>
            <img src={emptyImg.default} className={Style.emptyImg} />
            <div className={Style.emptyText}>
                <span className={Style.emptyTitle}>{findMessage.get('102396')}</span>
                <span className={Style.emptyDesc}>{findMessage.get('102397')}</span>
            </div>
        </div>
    )

    const renderModalContent = () => {
        return (
            <div className={Style.signatureContainer}>
                <div className={Style.signatureHeader}>
                    <BCRibbonBar
                        items={getToolbarItems()}
                        customClassName={Style.SignatureRibbon}
                    />
                </div>
                {props.documentSignature?.validationDescription &&
                    <div className={Style.signatureDescription}>
                        <div className={Style.signatureDescriptionText}>{props.documentSignature.validationDescription}</div>
                        <div className={Style.signatureDescriptionMore}>
                            {props.documentSignature?.validationDetail &&
                                <IconInfo className={Style.SignatureIcons} onClick={onClickValidationDetail} />
                            }
                        </div>
                    </div>
                }
                <div className={Style.signatureBody}>
                    <BCScrollbars>
                        <div className={Style.treeHeader}>
                            <span className={Style.headerSigner}>{findMessage.get('102173')}</span>
                            <span className={Style.headerDate}>{findMessage.get('102174')}</span>
                            <span className={Style.headerStatus}>{findMessage.get('100199')}</span>
                        </div>
                        <div className={Style.treeBody}>
                            <BCTreeview
                                disableToolbar
                                items={props.documentSignature?.treeData || []}
                                className={Style.signatureTreeContainer}
                                customItemComponent={renderTreeItem}
                                customEmptyComponent={renderEmpty}
                                expandedKeys={props.documentSignature?.treeKeys || []}
                            />
                        </div>
                    </BCScrollbars>
                </div>
            </div>
        )
    }

    const renderModalTitle = () => {
        return (
            <div>
                <span>{findMessage.get('102172')}</span>
            </div>
        )
    }

    const handleOk = () => {
        props.onCloseModal(false);
    }

    return (
        <BCModal
            id={props.modalId}
            visible={true}
            loading={props.isLoading}
            renderHeader={renderModalTitle}
            width={"850px"}
            handleOk={handleOk}
            handleCancel={handleOk}
            okText={findMessage.get("100824")}
            wrapClassName={Style.SignatureModalContent}
            cancelButtonProps={{ style: { display: "none" } }}
            modalContentWrapperCssClass={Style.smModalContent}
        >
            {renderModalContent()}
        </BCModal>
    )
}
export default SignatureModal;