import * as Moment from "moment";
import * as React from "react";
import { BCCombobox, BCDatePicker, BCInput, BCInputNumber, BCLabel, BCTimePicker } from "../..";
import { ConditionalStatementEntities } from "../../BCConditionalStatement";
import { findMessage } from "../../BCIntl";
import { filterReadonlyFields } from '../../BCTreeSelect/helpers';
import { IActionContentProps } from "../entities/IActionContentProps";
import { SelectedField } from "./SelectedField";

const conditionValueInputStyle = { width: "100%" };
const booleanOptions = [
    { key: 'true', text: 'true', value: 'true' },
    { key: 'false', text: 'false', value: 'false' }
];

const ValueAssignActionWithEntry = React.memo((props: IActionContentProps<ConditionalStatementEntities.IValueAssignActionWithEntry>) => {

    const onSelectedFieldChanged = (value: string, options: any) => {
        const fieldData = options.externalData;
        const setDefaultValueByType = fieldData.type === 'boolean' ? false : props.action.value;
        const action = { ...props.action, value: setDefaultValueByType };

        props.onActionChanged({
            ...action,
            field: {
                name: value.split('.').shift(),
                propertyName: value.split('.').slice(1, value.length).join('.'),
                type: fieldData.type,
                enumMembers: fieldData.enumMembers
            }
        });

    }

    const onFieldChanged = (value: any) => {
        props.onActionChanged({
            ...props.action,
            value
        });
    }

    const getWriteableFields = () => {
        return props.fields ? filterReadonlyFields(props.fields.toJS()) : []
    }

    const renderSelectedField = () => {
        return (
            <SelectedField
                options={getWriteableFields()}
                onChange={onSelectedFieldChanged}
                field={props.action.field}
            />
        )
    }

    const renderConditionValueByType = () => {

        const type = props.action.field ? props.action.field.type : null;

        switch (type) {
            case 'boolean': {
                return (
                    <BCCombobox
                        style={conditionValueInputStyle}
                        value={props.action.value ? 'true' : 'false'}
                        onSelectedChange={(args) => { onFieldChanged(args.data === 'true' ? true : false) }}
                        options={booleanOptions}
                        showSearch={false}
                    />
                )
            }
            case 'number': {
                return (
                    <BCInputNumber
                        style={conditionValueInputStyle}
                        value={props.action.value}
                        onChange={(value) => { onFieldChanged(value) }}
                    />
                )
            }
            case 'date': {
                return (
                    <BCDatePicker
                        style={conditionValueInputStyle}
                        value={props.action.value ? Moment(new Date(props.action.value)) : undefined}
                        onChange={(value) => {
                            onFieldChanged(value ? value.startOf('day').valueOf() : value)
                        }}
                    />
                )
            }
            case 'time': {
                return (
                    <BCTimePicker
                        style={conditionValueInputStyle}
                        value={props.action.value ? Moment(props.action.value, 'X') : undefined}
                        onChange={(value) => {

                            let timeInSeconds = value ? value.unix() : undefined;
                            onFieldChanged(timeInSeconds);

                        }}
                    />
                )
            }
            case 'enum': {
                return (
                    <BCCombobox
                        style={conditionValueInputStyle}
                        value={props.action.value}
                        onSelectedChange={(args) => { onFieldChanged(args.data) }}
                        options={props.action.field.enumMembers.map(i => {
                            return { key: i, text: i, value: i }
                        })}
                        showSearch={false}
                    />
                )
            }
            default: {
                return (
                    <BCInput
                        style={conditionValueInputStyle}
                        value={props.action.value}
                        onChange={(args) => { onFieldChanged(args.data) }}
                    />
                )
            }
        }
    }

    return (
        <div className={'mb-2 mt-2 mr-2 ml-2'}>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100131')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    <BCInput
                        value={props.action.name}
                        onChange={e => {
                            props.onActionChanged({
                                ...props.action,
                                name: e.data
                            })
                        }}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100168')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    {renderConditionValueByType()}
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-sm-4 mb-2'}>
                    <BCLabel>
                        {findMessage.get('100338')}
                    </BCLabel>
                </div>
                <div className={'col-sm-8 mb-2'}>
                    {renderSelectedField()}
                </div>
            </div>
        </div>
    )

})

export default ValueAssignActionWithEntry