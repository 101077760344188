import { FormItemTypes, isNullOrUndefined } from "@bimser/core";
import classNames from 'classnames/bind';
import React from "react";
import * as Styles from "./assets/styles.scss";
import Component from "./Component";
import { IColumn } from "./entities";

const cx = classNames.bind(Styles);

const Column = (args: IColumn) => {
  const { data, getItem, controlRenderManager, deviceType, paddingType, parentItemType } = args;

  const columnClassNames: string = cx({
    base: true,
    isInputGroup: parentItemType === FormItemTypes.EControlTypes.InputGroup,
    column: true
  });

  const width = data.designerProps.layout.get("width");
  const padding = data.designerProps.layout.get("padding");
  const noWidth = isNullOrUndefined(width) || width === "";
  let columnLayoutStyles: React.CSSProperties = noWidth ? {} : {
    flex: "0 0 " + width
  };

  if (Object.keys(columnLayoutStyles).length) {
    columnLayoutStyles.padding = padding;
  } else {
    const noPadding = isNullOrUndefined(padding) || padding === "";
    columnLayoutStyles = noPadding ? {} : {
      padding: padding
    };
  }

  return (
    <div
      className={columnClassNames}
      style={columnLayoutStyles}
    >
      {data.items.map((componentId) => {
        const component = getItem(componentId as string);

        return (
          <React.Fragment key={componentId as string}>
            <Component
              key={component.id}
              data={component}
              controlRenderManager={controlRenderManager}
              deviceType={deviceType}
              paddingType={paddingType}
              parentItemType={parentItemType}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default React.memo(Column);
