import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Record, List, Map } from "immutable";
import { IHumanResourceModule } from "../IHumanResourceModule";
import { Modules } from ".."

interface IBaseHumanResourceModules {
    selected: IHumanResourceModule,
    loading: boolean
}

export interface IHumanResourceModules extends IBaseHumanResourceModules {
    holdings: Modules.IHolding[],
    companies: Modules.ICompany[],
    plants: IDictionary<Modules.IPlant>,
    organizations: IDictionary<Modules.IOrganization>,
}

export interface IReadonlyHumanResourceModules extends IBaseHumanResourceModules {
    holdings: List<Modules.Holding>,
    companies: List<Modules.Company>,
    plants: Map<string, Modules.Plant>,
    organizations: Map<string, Modules.Organization>
}

export type HumanResourceModules = ReadonlyRecord<IReadonlyHumanResourceModules>;

export const HumanResourceModulesFactory = Record<IReadonlyHumanResourceModules>({
    selected: null,
    holdings: List<Modules.Holding>(),
    companies: List<Modules.Company>(),
    plants: Map<string, Modules.Plant>(),
    organizations: Map<string, Modules.Organization>(),
    loading: false
});

export const HumanResourceModulesFactoryFromJS = function (data: IHumanResourceModules) {
    const holdings : List<Modules.Holding> = List(data.holdings.map((holding: Modules.IHolding) => Modules.HoldingFactoryFromJS(holding)));
    const companies : List<Modules.Company> = List(data.companies.map((company: Modules.ICompany) => Modules.CompanyFactoryFromJS(company)));

    const plants : Map<string, Modules.Plant> = Map(Object.keys(data.plants).reduce((previous: IDictionary<Modules.Plant>, key: string) => {
        previous[key] = Modules.PlantFactoryFromJS(data.plants[key]);
        return previous;
    }, {}));

    const organizations : Map<string, Modules.Organization> = Map(Object.keys(data.organizations).reduce((previous: IDictionary<Modules.Organization>, key: string) => {
        previous[key] = Modules.OrganizationFactoryFromJS(data.organizations[key]);
        return previous;
    }, {}));

    return HumanResourceModulesFactory({
        ...data,
        holdings,
        companies,
        plants,
        organizations
    });
}