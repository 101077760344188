import * as Moment from 'moment';
import * as React from "react";
import BCDateRangePicker, { RangePickerValue } from '../../../BCDateRangePicker';
import { findMessage } from "../../../BCIntl";
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

export default class DateRangePickerProperty extends React.Component<IPropertyComponentProps, any>{

    defaultStyle: React.CSSProperties = { width: '100%' };
    placeholder: [string, string] = [findMessage.get("100995"), findMessage.get("100996")];

    constructor(props: IPropertyComponentProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    replaceValue(item: IPropertyItem, newValue: any) {
        this.props.onValueChanged(item, newValue);
    }

    onChange(dates: RangePickerValue) {

        let startDate = null;
        let endDate = null;

        if (dates) {
            startDate = dates[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();
            endDate = dates[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();
        }

        this.props.customChangeEvent ? this.props.customChangeEvent([startDate, endDate]) : this.replaceValue(this.props.item, [startDate, endDate])

    }

    getDateValue(value?: string) {
        return value ? Moment(new Date(value), this.props.item.options || null) : null;
    }

    getValue(): RangePickerValue {
        if (this.props.value && Array.isArray(this.props.value)) {
            let startDate: string = this.props.value[0];
            let endDate: string = this.props.value[1];

            return [this.getDateValue(startDate), this.getDateValue(endDate)];
        }
        else {
            return [null, null];
        }
    }

    render() {
        return (
            <BCDateRangePicker
                value={this.getValue()}
                placeholder={this.placeholder}
                onChange={this.onChange}
                {...(this.props.item.options ? { format: [this.props.item.options, this.props.item.options] } : {})}
                style={this.props.style || this.defaultStyle}
                disabled={this.props.item.disabled || this.props.item.readOnly}
                allowClear={true}
            />
        )
    }
}