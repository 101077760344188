const colorsPalette = [
    "760315", "A52128", "D23135", "DB3800",
    "8F552A", "CB4F07", "FFAB42", "FCE200",
    "997003", "C29D00", "8DBE11", "036A03",
    "478301", "00AE55", "005B70", "008488",
    "00B8C4", "004D8D", "0079D5", "4E6BEE",
    "5C2A92", "8864B9", "8479DF", "891099",
    "C336B4", "9C0062", "E4008D", "363636",
    "7B7675", "697A7F", "A1AFB3"
];

const stringToColor = (text: string) => {
    let hash = 0, i, chr;
    for (i = 0; i < text.length; i++) {
        chr = text.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    if (hash < 0) hash = hash * (-1);

    return "#" + colorsPalette[hash % 31];
}
export default stringToColor;