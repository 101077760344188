import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent, IShift, Shift, ShiftFactory, ShiftFactoryFromJS } from "..";

export interface IShiftListFormContent extends IBaseFormContent {
    selectedShift?: IShift,
}

export interface IReadonlyShiftListFormContent extends IBaseFormContent {
    selectedShift?: Shift
}

export type ShiftListFormContent = ReadonlyRecord<IReadonlyShiftListFormContent>;

export const ShiftListFormContentFactory = Record<IReadonlyShiftListFormContent>({
    panelId: "",
    selectedShift: ShiftFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: true,
});

export const ShiftListFormContentFactoryFromJS = function (data: IShiftListFormContent) {
    const _selectedShift : Shift = data.selectedShift ? ShiftFactoryFromJS(data.selectedShift) : ShiftFactory()

    return ShiftListFormContentFactory({
        panelId: data.panelId,
        selectedShift: _selectedShift,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        showLoading: data.showLoading,
    });
}