import { BCAjax } from '@bimser/components';
import { IBaseAction } from "@bimser/core";
import { call, takeEvery } from 'redux-saga/effects';
import { ServiceList } from "../../../services/serviceList";
import * as ActionTypes from '../actions/actionTypes';
import { GetUserProfileImageDownloadUrl } from './getUserStartupTransaction';

export default function* watchChangeProfileImageTransaction() {
    yield takeEvery(ActionTypes.EDIT_USER_PROFILE_IMAGE_TRANSACTION, handleChangeProfileImage);
}


function* handleChangeProfileImage(action: IBaseAction<any>) {
    try {
        let file: File = action.payload;

        let uploadURLResponse = yield ServiceList.hr.Users.GetUserProfileImageUploadUrl.call();

        if (uploadURLResponse) {
            let formData = new FormData();
            formData.append("file", file.slice(0), file.name);

            let response = yield BCAjax.put((window as any).serviceConfig.services.login.baseURL + uploadURLResponse, formData);

            if (response) {
                yield call(GetUserProfileImageDownloadUrl)
            }
        }
    } catch (error) {
        console.log(error);
    }
}
