

import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { FlowDocumentType } from '../enums';
import { FlowDocumentCreateOptions, IFlowDocumentCreateOptions, FlowDocumentCreateOptionsFactoryFromJS } from './IFlowDocumentCreateOptions';

interface IBaseFlowEventForm {
    documentObjectKey: string;
    documentObjectName: string;
    panelSize: number;
    type: FlowDocumentType;
}

export interface IFlowEventForm extends IBaseFlowEventForm {
    createOptions: IFlowDocumentCreateOptions;
}

export interface IReadonlyFlowEventForm extends IBaseFlowEventForm {
    createOptions: FlowDocumentCreateOptions;
}

export type FlowEventForm = ReadonlyRecord<IReadonlyFlowEventForm>;

export const FlowEventFormFactory = Record<IReadonlyFlowEventForm>({
    documentObjectKey: null,
    documentObjectName: null,
    panelSize: 1,
    type: FlowDocumentType.CreateForm,
    createOptions: null
});

export const FlowEventFormFactoryFromJS = function (data: IFlowEventForm) {
    return FlowEventFormFactory({
        ...data,
        createOptions: FlowDocumentCreateOptionsFactoryFromJS(data.createOptions)
    });
}