import { createSyncAction, jwtDecode } from "@bimser/core";
import { put, takeEvery } from 'redux-saga/effects';
import { ServiceList } from "../../../services/serviceList";
import * as ActionTypes from "../actions/actionTypes";
import { IControlTokenResponse } from '../entities/ControlTokenResponse';
import { setDelegationInformationAction } from '../../../action/setDelegationInformationAction'
import { controlTokenAction } from '../actions';

export default function* watchControlTokenTransaction() {
    yield takeEvery(ActionTypes.CONTROL_TOKEN_TRANSACTION, controlTokenTransaction);
}

function removeToken() {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token")
}

export function* controlTokenTransaction(sagaAction?: any) {
    let status: boolean = false;
    try {
        let response = yield ServiceList.login.Login.ControlToken.call(true);
        let responseData: IControlTokenResponse = response.data;
        let headerLanguage = response.headers["bimser-language"];
        localStorage.setItem("locale", headerLanguage)
        if (responseData.isValid) {
            status = true;
            let decodedToken = jwtDecode(responseData.token);
            
            yield put(controlTokenAction(responseData));
            yield put(setDelegationInformationAction(decodedToken.AuthenticationType == 2));
            yield put(createSyncAction(ActionTypes.CHANGE_TOKEN_CHECK_STATUS, true));
        }
        else {
            removeToken();
        }
    } catch (error) {
        removeToken();
    } finally {
        return status;
    }
}