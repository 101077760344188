import { RxEventEmitter } from '@bimser/core';
import { call, put } from 'redux-saga/effects';
import { Subscription } from 'rxjs';
import { DeployAgentEntities } from '../..';
import deployAgentMessageAction from '../../action/deployAgentMessageAction';

function* deployAgentManager() {
    while (true) {
        let message = yield listenDeployAgentMessages();
        yield call(sendDeployAgentMessageAction, message);
    }
}

export default deployAgentManager;

function* sendDeployAgentMessageAction(message: DeployAgentEntities.IDeployAgentMessage) {
    yield put(deployAgentMessageAction(message));
    RxEventEmitter.emit(DeployAgentEntities.DeployAgentEventKey, message);
}

function listenDeployAgentMessages() {
    return new Promise((resolve) => {
        let sub: Subscription = null;
        RxEventEmitter.listen("SYSTEM_SOCKET", {
            next: (message: any) => {
                if (message.type == "DeployAgentMessage") {
                    resolve(JSON.parse(message.payload.message));
                    if (sub) sub.unsubscribe();
                }
            }
        }).then(subscription => {
            sub = subscription;
        })
    })
}