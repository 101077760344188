import { ContentType } from "@bimser/components";
import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseViewerPanel {
    panelId: string;
    itemId: string;
    loading?: boolean;
    skeletonType?: ContentType;
}

export interface IViewerPanel extends IBaseViewerPanel {
    
}

export interface IReadonlyViewerPanel extends IBaseViewerPanel {
    
}

export type ViewerPanel = ReadonlyRecord<IReadonlyViewerPanel>;

export const ViewerPanelFactory = Record<IReadonlyViewerPanel>({
    panelId: "",
    itemId: "",
    loading: false,
    skeletonType: ContentType.textFile
});

export const ViewerPanelFactoryFromJS = function (data: IViewerPanel) {
    return ViewerPanelFactory(data);
}