import * as React from "react";
import { connect } from "react-redux";
import { BaseState } from "../../../entities/BaseState";
import ForgetMyPassword from "../components/ForgetMyPassword";
import IForgetMyPasswordComponentCProps from "../entities/IForgetMyPasswordComponentCProps";
import IForgetMyPasswordComponentProps from "../entities/IForgetMyPasswordComponentProps";
import { onClickPasswordRecoveryTransaction } from '../actions';


// state to component properties
const mapStateToProps = function (state: BaseState, props: IForgetMyPasswordComponentProps): IForgetMyPasswordComponentProps {
    return {

    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IForgetMyPasswordComponentCProps {
    return {
        onClickForgotMyPassword: function (data: any) {
            dispatch(onClickPasswordRecoveryTransaction(data));
        }
    };
}

// react - redux connection
const ForgetMyPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ForgetMyPassword);

export default ForgetMyPasswordContainer;