import IconTaskApprove from "@bimser/icons/32/task-approve";
import Utils from 'common/utils';
import * as moment from 'moment';
import * as React from 'react';
import * as Styles from "../../assets/styles.scss";
import { ApproveItemProps } from "../../entities/ApproveItemProps";

const classNames = require('classnames/bind');
const cx = classNames.bind(Styles);

const ApproveItem = (props: ApproveItemProps) => {

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.item);
        }
    }

    const className = cx({
        approveItem: true
    });

    return (
        <div className={className}>
            <IconTaskApprove height="32" width="32px" />
            <div className={Styles.approveInfo} onClick={onClick}>
                <div className={Styles.projectCaption} title={Utils.getWorkflowText(props.item.projectName, props.item.projectCaptionML, props.item.flowName, props.item.flowCaptionML)}>
                    {Utils.getWorkflowText(props.item.projectName, props.item.projectCaptionML, props.item.flowName, props.item.flowCaptionML)}
                </div>
                <div className={Styles.approveDescription} title={`#${props.item.processId}`}>{`#${props.item.processId}`}</div>
            </div>
            <p className={Styles.approveDate} title={moment(props.item.requestDate).format("L LTS")}>{moment(props.item.requestDate).fromNow()}</p>
        </div>
    )
}

export default ApproveItem;