import * as React from "react";
import BCModal from "../BCModal";
import IModalFrameProps from "./entitites/IModalFrameProps";
import * as Styles from './assets/styles.scss';

export default class BCModalFrame extends React.Component<IModalFrameProps, {}> {

    constructor(props: IModalFrameProps) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleAfterClose = this.handleAfterClose.bind(this);
    }

    handleCancel = () => {
        if (this.props.handleCancel) {
            this.props.handleCancel();
        }
    }

    handleAfterClose = () => {
        if (this.props.afterClose) {
            this.props.afterClose();
        }
    }

    render() {
        return (
            <BCModal
                footer={null}
                key={this.props.key}
                visible={this.props.visible}
                loading={this.props.loading}
                title={null}
                className={Styles.ModalFrameContainer}
                width={this.props.width}
                zIndex={100}
                afterClose={this.handleAfterClose}
                handleCancel={this.handleCancel}>
                <iframe src={this.props.url} width='100%' frameBorder='0' height='100%'></iframe>
            </BCModal>
        );
    }
}
export { IModalFrameProps };
