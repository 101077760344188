import React, { lazy, Suspense } from 'react';


const ContainerLazy = lazy(() => import(/* webpackChunkName: "web-human-resource" */ "./containers"));

function Container(props: React.PropsWithChildren<any>) {
    return (
        <Suspense fallback={<div></div>}>
            <ContainerLazy {...props} />
        </Suspense>
    );
}
Container.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-human-resource" */ "modules/humanResource/injector");
}

export default Container;