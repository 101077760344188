import { connect } from "react-redux";
import { WebInterfaceBaseState } from '../../../base/state';
import { onChangeSelectedTemplateAction, onChangeTemplateDataAction, onClickSendTemplateAction, resolveESignatureTransaction } from '../actions';
import ESignature from "../components";
import { IESignatureProps } from '../entities';
import IOnChangeTemplateDataPayload from '../entities/IOnChangeTemplateDataPayload';
import ISendTemplatePayload from '../entities/ISendTemplatePayload';
import { getESignatureModalLoading, getESignatureTemplates, getSelectedTemplateId } from '../selectors';

const mapStateToProps = function (state: WebInterfaceBaseState, props: IESignatureProps): IESignatureProps {
    return {
        templates: getESignatureTemplates(state),
        isLoading: getESignatureModalLoading(state, props.id),
        selectedTemplateId: getSelectedTemplateId(state)
    };
}
const mapDispatchToProps = function (dispatch: Function): IESignatureProps {
    return {
        onChangeTemplateData: (payload: IOnChangeTemplateDataPayload) => {
            dispatch(onChangeTemplateDataAction(payload));
        },
        onChangeSelectedTemplate: (templateId: string) => {
            dispatch(onChangeSelectedTemplateAction(templateId));
        },
        onClickSend: (payload: ISendTemplatePayload) => {
            dispatch(onClickSendTemplateAction(payload));
        },
        onCancel: (modalId: string) => {
            dispatch(resolveESignatureTransaction(modalId));
        }
    };
}

const ESignatureContainer = connect(mapStateToProps, mapDispatchToProps)(ESignature);
export default ESignatureContainer;