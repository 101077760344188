import { IDictionary } from "@bimser/core";
import { ThemeEntities } from "..";
import { IControlTokenResponse, IGetLoginParametersRequest, IGetLoginParametersResponse, IGetTOTPRegisterResponse, IGetUserDetailResponse, IGetUserSettingParametersResponse, IGetUserSettingPermissionsResponse, ILoginRequest, ILoginResponseWithMFA, ISelectMFATypeRequest, IValidateTOTPRegisterResponse, IValidationMFARequest } from "../components/Login/entities";
import { ICulture } from "../system/culture/entities";
import { ILanguage } from "../system/language/entities";
import { ILocation } from "../system/location/entities";
import { IPasswordRequirements } from "../system/passwordRequirements/entities";
import { GetMethod, PostMethod } from "./serviceHelper";

export const ServiceList = {
    login: {
        Login: {
            ControlToken: new GetMethod<IControlTokenResponse, null>(),
            GetLoginParameters: new PostMethod<IGetLoginParametersResponse, IGetLoginParametersRequest>(),
            Login: new PostMethod<ILoginResponseWithMFA, ILoginRequest>(),
            Delegate: new PostMethod<any, any>(),
            LoginAs: new PostMethod<any, any>(),
            LoginWithAccessToken: new PostMethod<any, any>(),
            LoginWithDigitalSignature: new PostMethod<any, any>(),
            LoginWithExternalUser: new PostMethod<any, any>(),
            GetCaptcha: new PostMethod<any, any>(),
            SelectMfaType: new PostMethod<boolean, ISelectMFATypeRequest>(),
            ValidationMfa: new PostMethod<ILoginResponseWithMFA, IValidationMFARequest>(),
            GetTotpRegister: new GetMethod<IGetTOTPRegisterResponse, null>(),
            ValidationTotpRegister: new PostMethod<IValidateTOTPRegisterResponse, { pin: string; }>(),
            RecoveryTotpRegister: new PostMethod<boolean, { pin: string; }>(),
        },
        UserStartup: {
            GetAccountPermissionSetting: new GetMethod<IGetUserSettingPermissionsResponse, null>(),
            GetUserProfileImageDownloadUrl: new GetMethod<string, null>(),
            GetUserSettingParameters: new GetMethod<IGetUserSettingParametersResponse, null>(),
            GetUserStartupInfo: new GetMethod<IGetUserDetailResponse, null>(),
            GetUserProfileImageUploadUrl: new GetMethod<any, any>(),
        },
        WebInterfaceManagement: {
            GetLocations: new GetMethod<Array<ILocation>, null>(),
            GetLanguages: new GetMethod<Array<ILanguage>, null>(),
            GetCultures: new GetMethod<Array<ICulture>, null>(),
            GetLoginSliderItems: new GetMethod<any, any>(),
            Search: new PostMethod<any, any>(),
        },
        UiTour: {
            GetAllTours: new GetMethod<any, any>(),
            SetUserUITourChoice: new PostMethod<any, any>()
        }
    },
    IntegrationManager: {
        IntegrationManager: {
            Connection: {
                Get: new PostMethod<string, any>(),
                Create: new PostMethod<string, any>(),
                Update: new PostMethod<string, any>(),
                Delete: new PostMethod<boolean, any>(),
                Compile: new PostMethod<boolean, any>(),
                GetMetaData: new PostMethod<string, any>(),
                GetDataBases: new PostMethod<string, any>(),
                GetStoredProcedures: new PostMethod<Array<any>, any>(),
                GetParametersOfStoredProcedure: new PostMethod<Array<any>, any>(),
                GetReturnTablesOfStoredProcedure: new PostMethod<Array<any>, any>(),
                GetClasses: new PostMethod<Array<string>, any>(),
                GetMethodsOfClass: new PostMethod<Array<any>, any>(),
                GetReturnTablesOfMethod: new PostMethod<Array<any>, any>(),
            },
            Explorer: {
                GetExplorerData: new GetMethod<Array<any>, null>(),
                GetConnectionElementData: new PostMethod<string, any>(),
                GetQueryElementData: new PostMethod<string, any>(),
                GetExplorerElementData: new PostMethod<string, any>(),
                UpdateGroup: new PostMethod<string, any>(),
                CreateGroup: new PostMethod<string, any>(),
                DeleteGroup: new PostMethod<boolean, any>(),
            },
            Query: {
                GetMetaData: new PostMethod<string, any>(),
                Get: new PostMethod<string, any>(),
                Create: new PostMethod<string, any>(),
                Update: new PostMethod<string, any>(),
                Delete: new PostMethod<boolean, any>(),
                Compile: new PostMethod<boolean, any>(),
                Execute: new PostMethod<boolean, any>(),
            }
        }
    },
    dm: {
        DocumentManagement: {
            Objects: {
                GetDownloadUrl: new PostMethod<any, any>(),
                CreateRepository: new PostMethod<any, any>(),
                CreateFolder: new PostMethod<any, any>(),
                GetRepositories: new PostMethod<any, any>(),
                GetFolderItems: new PostMethod<any, any>(),
                CopyObjects: new PostMethod<any, any>(),
                MoveObjects: new PostMethod<any, any>(),
                GetContents: new PostMethod<any, any>(),
                CreateContent: new PostMethod<any, any>(),
                DeleteContent: new PostMethod<any, any>(),
                UnsetAsDefaultContent: new PostMethod<any, any>(),
                SetAsDefaultContent: new PostMethod<any, any>(),
                CreateShortcut: new PostMethod<any, any>(),
                DeleteShortcut: new PostMethod<any, any>(),
                DeleteRepository: new PostMethod<any, any>(),
                DeleteFolder: new PostMethod<any, any>(),
                DeleteFile: new PostMethod<any, any>(),
                CreateFile: new PostMethod<any, any>(),
                GetUploadParts: new PostMethod<any, any>(),
                GetObjectML: new PostMethod<any, any>(),
                RenameObject: new PostMethod<any, any>(),
                GetOfficeViewerUrl: new PostMethod<any, any>(),
                GetVersionList: new PostMethod<any, any>(),
                CreateBranch: new PostMethod<any, any>(),
                CreateCommit: new PostMethod<any, any>(),
                CompleteBranch: new PostMethod<any, any>(),
                GetVersionSuggestion: new PostMethod<any, any>(),
                GetCommitList: new PostMethod<any, any>(),
                UpdateFolderProperties: new PostMethod<any, any>(),
                GetProperties: new PostMethod<any, any>(),
                GetFile: new PostMethod<any, any>(),
                GetFolder: new PostMethod<any, any>(),
                UpdateTags: new PostMethod<any, any>(),
                UpdateCategory: new PostMethod<any, any>(),
                GetTags: new PostMethod<any, any>(),
                GetCategory: new PostMethod<any, any>(),
                Subscribe: new PostMethod<any, any>(),
                Unsubscribe: new PostMethod<any, any>(),
                Subscriptions: new PostMethod<any, any>(),
                Subscription: new PostMethod<any, any>(),
            },
            Explorer: {
                GetObjectsContext: new PostMethod<any, any>(),
                GetExplorerData: new PostMethod<any, any>(),
                GetGridContext: new PostMethod<any, any>(),
                AddObjectsToFavorites: new PostMethod<any, any>(),
                DeleteObjectFromFavorites: new PostMethod<any, any>(),
                GetRecentObjects: new GetMethod<any, any>(),
                GetFavorites: new GetMethod<any, any>(),
                GetLegacyUrl: new PostMethod<any, any>(),
                DeleteRelationFromFile: new PostMethod<any, any>(),
                GetRelationFilesOfTheFile: new PostMethod<any, any>(),
                AddRelationToFile: new PostMethod<any, any>(),
                Search: new PostMethod<any, any>(),
                GetPaths: new PostMethod<any, any>(),
                CheckDocumentExistAndAuthorize: new PostMethod<any, any>(),
                DeleteObjectFromRecents: new PostMethod<any, any>(),
                GetParameters: new GetMethod<any, any>(),
                GetDMObjectsFromPath: new PostMethod<any, any>(),
                Share: new PostMethod<any, any>(),
                ExcelSearch: new PostMethod<any, any>(),
                Profile: new PostMethod<any, any>()
            },
            Startup: {
                GetStartupContext: new PostMethod<any, any>()
            },
            RecycleBin: {
                GetSettings: new GetMethod<any, any>(),
                UpdateSettings: new PostMethod<any, any>(),
                GetItems: new PostMethod<any, any>(),
                RestoreItem: new PostMethod<any, any>(),
                RestoreItems: new PostMethod<any, any>(),
                DeleteItems: new PostMethod<any, any>(),
                DeleteItem: new PostMethod<any, any>(),
                DeleteAll: new PostMethod<any, any>()
            },
            Enrichment: {
                GetProcessorConfigurations: new GetMethod<any, any>(),
                CreateReport: new PostMethod<any, any>(),
                GetReport: new PostMethod<any, any>(),
                GetReportDetail: new PostMethod<any, any>(),
                GetReportDetailFileList: new PostMethod<any, any>()
            }
        },
        DocuSign: {
            GetTemplates: new GetMethod<any, any>(),
            CreateEnvelope: new PostMethod<any, any>()
        }
    },
    workflow: {
        workflowManagement: {
            GetProcessRequestParameters: new PostMethod<any, any>(),
            GetApprovalCount: new PostMethod<any, any>(),
            GetApprovalCounts: new GetMethod<any, null>(),
            GetApprovals: new PostMethod<Array<any>, any>(),
            GetDrafts: new PostMethod<any, any>(),
            GetDraftCounts: new GetMethod<any, null>(),
            GetDraftCount: new PostMethod<any, any>(),
            GetNotifications: new PostMethod<any, any>(),
            GetNotificationCounts: new GetMethod<any, null>(),
            GetNotificationCount: new PostMethod<any, any>(),
            GetSents: new PostMethod<any, any>(),
            GetSentCounts: new GetMethod<any, null>(),
            GetSentCount: new PostMethod<any, any>(),
            SendMessage: new PostMethod<string, any>(),
            GetInboxMessages: new GetMethod<any[], null>(),
            GetDeletedMessages: new GetMethod<any[], null>(),
            GetMessagesCounts: new GetMethod<any, null>(),
            GetSentMessages: new GetMethod<any, null>(),
            MarkMessageAsRead: new PostMethod<string, any>(),
            GetNoteItems: new GetMethod<any, any>(),
            GetProjects: new GetMethod<any, any>(),
            GetProjectCount: new PostMethod<any, any>(),
            DeleteMessage: new PostMethod<any, any>(),
            GetHistoryCounts: new PostMethod<any, any>(),
            GetHistory: new PostMethod<any, any>(),
            GetWorkflowItems: new PostMethod<any, any>(),
            GetDraftDocuments: new PostMethod<any, any>(),
            GetCurrentProcessRequest: new PostMethod<any, any>(),
            GetProcessByProcIdAndReqId: new PostMethod<any, any>(),
            DeleteDraftDocument: new PostMethod<any, any>()
        },
        Workflow: {
            ContinueProcess: new PostMethod<any, any>(),
            GetFlowInfo: new PostMethod<null, any>(),
            GetFlowHistory: new PostMethod<string, any>(),
            GetFlowDocuments: new PostMethod<string, any>(),
            GetFlowProperties: new PostMethod<string, any>(),
            GetFlowRequestEvents: new PostMethod<string, any>(),
            GetProcessRequestEvents: new PostMethod<any, any>(),
            GetProcessRequestDocuments: new PostMethod<any, any>(),
        },
        WebInterfaceManagement: {
            GetLocations: new GetMethod<Array<any>, null>(),
            GetLanguages: new GetMethod<Array<any>, null>(),
            GetCultures: new GetMethod<Array<any>, null>(),
            GetUsers: new PostMethod<Array<any>, any>(),
            GetClarification: new GetMethod<any, null>(),
            AcceptClarification: new PostMethod<any, any>()
        },
        UserStartup: {
            EditLanguage: new PostMethod<any, any>(),
            EditNotificationSoundStatus: new PostMethod<any, any>(),
            EditLocation: new PostMethod<any, any>(),
            EditCulture: new PostMethod<any, any>()
        },
        Delegation: {
            Disable: new PostMethod<any, any>(),
            Edit: new PostMethod<any, any>(),
            Create: new PostMethod<any, any>(),
            GetTakenDelegations: new PostMethod<any, any>(),
            GetGivenDelegations: new PostMethod<any, any>(),
            Regenerate: new PostMethod<any, any>(),
            Get: new PostMethod<any, { secretKey: string }>()
        },
        MenuManager: {
            GetActionParameters: new PostMethod<any, any>(),
            GetUserMenuStructure: new GetMethod<any, null>(),
            GetDesignMenuStructure: new GetMethod<any, null>(),
            SaveDesignMenuStructure: new PostMethod<any, any>(),
            GeteBA6approvalsUrl: new PostMethod<any, any>(),
            GeteBA6MenuItemUrl: new PostMethod<any, any>(),
            RecentlyMenuItems: new GetMethod<any, any>(),
            OpenMenuItem: new PostMethod<any, any>(),
        },
        gql: {
            HR: new PostMethod<any, any>()
        }
    },
    HumanResource: {
        Holdings: {
            Insert: new PostMethod<any, any>(),
            GetAll: new GetMethod<Array<any>, null>(),
            Update: new PostMethod<any, any>(),
        },
        Companies: {
            Insert: new PostMethod<any, any>(),
            GetAll: new GetMethod<Array<any>, null>(),
            Update: new PostMethod<any, any>(),
        },
        Menus: {
            GetMenus: new GetMethod<any, any>(),
            SetMenuItemStatus: new PostMethod<any, any>(),
        },
        Plants: {
            GetAllPlantsByLoadOption: new PostMethod<any, any>(),
            GetPlantsByCompanyId: new PostMethod<any, any>(),
            GetPlantById: new PostMethod<any, any>(),
            Insert: new PostMethod<any, any>(),
            Update: new PostMethod<any, any>(),
        },
        Organizations: {
            GetAllOrganizationsByLoadOption: new PostMethod<any, any>(),
            GetOrganizationById: new PostMethod<any, any>(),
            Insert: new PostMethod<any, any>(),
            Update: new PostMethod<any, any>(),
        },
    },
    hr: {
        Properties: {
            GetAll: new GetMethod<Array<any>, null>(),
            Update: new PostMethod<boolean, any>(),
            Insert: new PostMethod<number, any>(),
            Delete: new PostMethod<boolean, any>(),
            GetPropertiesWithRelation: new PostMethod<any, any>(),
            SetOrder: new PostMethod<any, any>(),
        },
        PropertyTypes: {
            GetAll: new GetMethod<Array<any>, null>(),
            Insert: new PostMethod<number, any>(),
            Update: new PostMethod<number, any>(),
            Delete: new PostMethod<boolean, any>(),
        },
        PropertyTypesML: {
            Insert: new PostMethod<number, any>(),
            Update: new PostMethod<number, any>(),
        },
        Maintenance: {
            Insert: new PostMethod<string, any>(),
            Update: new PostMethod<boolean, any>(),
            Delete: new PostMethod<boolean, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
        },
        Users: {
            Insert: new PostMethod<any, any>(),
            Update: new PostMethod<boolean, any>(),
            GetUserProfileImageDownloadUrl: new GetMethod<string, null>(),
            GetUserDetail: new GetMethod<IGetUserDetailResponse, null>(),
            Get: new PostMethod<any, null>(),
            GetUserProfileImageUploadUrl: new GetMethod<any, any>(),
            GetAutocompleteUsers: new PostMethod<any, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            GetUsersProfileImage: new PostMethod<any, any>(),
            UpdateUserMfaEnabled: new PostMethod<boolean, { value: boolean; }>(),
            UpdateUserTheme: new PostMethod<{ theme: ThemeEntities.ITheme }, { theme: ThemeEntities.ITheme }>()
        },
        UserSettings: {
            GetUserSettingPermissions: new GetMethod<IGetUserSettingPermissionsResponse, null>(),
            GetUserSettingParameters: new GetMethod<IGetUserSettingParametersResponse, null>(),
            UpdateUserCulture: new PostMethod<any, any>(),
            UpdateUserLanguage: new PostMethod<any, any>(),
            UpdateUserNetworkLocation: new PostMethod<any, any>(),
            UpdateNotificationSoundStatus: new PostMethod<any, any>()
        },
        Positions: {
            Update: new PostMethod<boolean, any>(),
            Insert: new PostMethod<string, any>(),
            GetActivesByUserId: new PostMethod<any, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            Get: new PostMethod<any, null>(),
        },
        Professions: {
            Update: new PostMethod<boolean, any>(),
            Insert: new PostMethod<string, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            Get: new PostMethod<any, null>()
        },
        PropertyValues: {
            Insert: new PostMethod<any, any>(),
            Update: new PostMethod<boolean, any>(),
            Delete: new PostMethod<boolean, any>(),
            BulkModifies: new PostMethod<Array<any>, any>(),
            GetPropertyValuesByObjectId: new PostMethod<Array<any>, null>(),
        },
        Department: {
            Update: new PostMethod<boolean, any>(),
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            Get: new PostMethod<any, null>()
        },
        Companies: {
            Insert: new PostMethod<string, any>(),
            GetAll: new GetMethod<Array<any>, null>(),
            Update: new PostMethod<boolean, any>(),
        },
        CompanyAdmins: {
            Insert: new PostMethod<string, any>(),
            Update: new PostMethod<boolean, any>(),
            Delete: new PostMethod<boolean, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            Get: new PostMethod<any, null>()
        },
        CompanyObjects: {
            Insert: new PostMethod<string, any>(),
            Update: new PostMethod<boolean, any>(),
            Delete: new PostMethod<boolean, any>(),
            DeleteByCompany: new PostMethod<boolean, any>(),
            GetCompanyObjectsbyObjectId: new PostMethod<Array<any>, null>(),
        },
        Groups: {
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            Update: new PostMethod<boolean, any>(),
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            Get: new PostMethod<any, null>()
        },
        GroupContents: {
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            Update: new PostMethod<boolean, any>(),
            DeleteByGroupId: new PostMethod<boolean, any>(),
            DeleteByUserId: new PostMethod<boolean, any>(),
            DeleteByUserId_GroupId: new PostMethod<boolean, any>(),
            GetGroupContents: new PostMethod<Array<any>, null>(),
            GetGroupContentsByGroupId: new PostMethod<Array<any>, null>()
        },
        UserManagers: {
            GetAll: new GetMethod<Array<any>, null>(),
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            Update: new PostMethod<boolean, any>()
        },
        ManagerKeys: {
            GetAll: new GetMethod<Array<any>, null>(),
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            Update: new PostMethod<boolean, any>()
        },
        Shifts: {
            GetAll: new GetMethod<Array<any>, null>(),
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            Update: new PostMethod<boolean, any>(),
            Get: new PostMethod<any, null>(),
        },
        Overtimes: {
            GetAll: new GetMethod<Array<any>, null>(),
            Insert: new PostMethod<string, any>(),
            Delete: new PostMethod<boolean, any>(),
            Update: new PostMethod<boolean, any>(),
            Get: new PostMethod<any, null>(),
        },
        Salaries: {
            GetAllWithLoadOptions: new PostMethod<any, any>(),
            Insert: new PostMethod<string, any>(),
            Update: new PostMethod<boolean, any>(),
        }
    },
    main: {
        DataSecurity: {
            GetSecurityDataTypes: new PostMethod<any, any>(),
            CreateSecurityDataType: new PostMethod<any, any>(),
            DeleteSecurityDataType: new PostMethod<any, any>(),
            UpdateSecurityDataType: new PostMethod<any, any>(),
        },
        Actions: {
            GetActions: new PostMethod<any, any>(),
            CreateRelation: new PostMethod<any, any>(),
            UpdateRelation: new PostMethod<any, any>(),
            DeleteRelation: new PostMethod<any, any>(),
            GetRelations: new PostMethod<any, any>(),
            GetHistories: new PostMethod<any, any>(),
            GetActionItems: new PostMethod<any, any>(),
            GetActionFormStates: new PostMethod<any, any>(),
            GetActionMainItems: new PostMethod<any, any>(),
            UpdateState: new PostMethod<any, any>(),
        },
        Activity: {
            Create: new PostMethod<any, any>(),
            CreateDm: new PostMethod<any, any>(),
            CreateWf: new PostMethod<any, any>(),
            CreateForm: new PostMethod<any, any>(),
            Update: new PostMethod<any, any>(),
            Delete: new PostMethod<any, any>(),
            Reply: new PostMethod<any, any>(),
            GetAll: new PostMethod<any, any>(),
            GetAllByRelationDm: new PostMethod<any, any>(),
            GetAllByRelationWf: new PostMethod<any, any>(),
            GetAllByRelationForm: new PostMethod<any, any>(),
            Get: new PostMethod<any, any>(),
            GetReplyActivities: new PostMethod<any, any>(),
            SetReaction: new PostMethod<any, any>(),
            UnsetReaction: new PostMethod<any, any>(),
            SearchUser: new PostMethod<any, any>(),
            SearchDm: new PostMethod<any, any>(),
            SearchWf: new PostMethod<any, any>()
        },
        Signature: {
            GetDigitalSignatureParameters: new GetMethod<any, any>(),
            GetDocumentSignatures: new PostMethod<any, any>(),
            GetDocumentTimestamps: new PostMethod<any, any>(),
            CreateArchiveTimestamp: new PostMethod<any, any>(),
            DownloadSignature: new PostMethod<any, any>(),
            CheckSignatureAuthentication: new PostMethod<any, any>(),
            FinalizeSigning: new PostMethod<any, any>(),
            CAdES: {
                InitializeMobileSigningWithDMObject: new PostMethod<any, any>(),
                InitializeMobileSigningWithData: new PostMethod<any, any>(),
                InitializeSigningWithDMObject: new PostMethod<any, any>(),
                InitializeSigningWithData: new PostMethod<any, any>(),
                InitializeMobileSigningForAuthentication: new PostMethod<any, any>(),
                FinalizeMobileSigning: new PostMethod<any, any>(),
                FinalizeMobileSigningForAuthentication: new PostMethod<any, any>()
            },
            PAdES: {
                InitializeMobileSigningWithDMObject: new PostMethod<any, any>(),
                InitializeMobileSigningWithData: new PostMethod<any, any>(),
                InitializeSigningWithDMObject: new PostMethod<any, any>(),
                InitializeSigningWithData: new PostMethod<any, any>(),
                FinalizeMobileSigning: new PostMethod<any, any>()
            },
            XAdES: {
                InitializeMobileSigningWithDMObject: new PostMethod<any, any>(),
                InitializeMobileSigningWithData: new PostMethod<any, any>(),
                InitializeSigningWithDMObject: new PostMethod<any, any>(),
                InitializeSigningWithData: new PostMethod<any, any>(),
                FinalizeMobileSigning: new PostMethod<any, any>()
            }
        },
        Certificate: {
            GetQualifiedCertificates: new PostMethod<any, any>(),
            GetCertificateDetails: new PostMethod<any, any>()
        },
        Password: {
            ChangePassword: new PostMethod<any, any>(),
            SendResetPasswordMail: new PostMethod<any, any>(),
            ResetPassword: new PostMethod<any, any>(),
            GetPasswordRequirements: new PostMethod<IPasswordRequirements, any>(),
        },
        Configuration: {
            GetLocationList: new GetMethod<Array<ILocation>, null>(),
            GetLanguageList: new GetMethod<Array<ILanguage>, null>(),
            GetCultureList: new GetMethod<Array<ICulture>, null>()
        },
        Permission: {
            Get: new PostMethod<any, any>(),
            Update: new PostMethod<any, any>(),
            GetUserPermissionScope: new GetMethod<any, any>(),
            GetObjectPermissions: new PostMethod<any, any>(),
            UpdateObjectPermissions: new PostMethod<any, any>(),
            ControlUserPermissions: new PostMethod<any, any>()
        },
        Shared: {
            ExpandLink: new PostMethod<any, any>(),
            ShortLink: new PostMethod<any, any>(),
            GetAllLogs: new PostMethod<any, any>(),
            GetLogNames: new PostMethod<any, any>(),
        },
        PermissionGroup: {
            GetAll: new GetMethod<any, any>(),
            Create: new PostMethod<any, any>(),
            Delete: new PostMethod<any, any>(),
            Rename: new PostMethod<any, any>(),
            AddMembers: new PostMethod<any, any>(),
            AddMemberOfs: new PostMethod<any, any>(),
            RemoveMember: new PostMethod<any, any>(),
            SearchMembers: new PostMethod<any, any>(),
            GetMemberInfoDetails: new PostMethod<any, any>(),
            GetAllOfObject: new PostMethod<any, any>(),
            GetSuggestions: new PostMethod<any, any>(),
            BindObject: new PostMethod<any, any>(),
            UnbindObject: new PostMethod<any, any>()
        },
        Announcement: {
            UpdateAnnouncement: new PostMethod<any, any>(),
            MarkAnnouncementAsRead: new PostMethod<boolean, any>(),
            AddAnnouncement: new PostMethod<any, any>(),
            DeleteAnnouncement: new PostMethod<any, any>(),
            GetAllAnnouncements: new PostMethod<any, any>(),
            GetAllAnnouncementsStartup: new PostMethod<any, any>(),
            GetAnnouncement: new PostMethod<any, any>(),
            GetActiveAnnouncementsByUser: new PostMethod<any, any>(),
            GetAllAnnouncementsByUser: new PostMethod<any, any>(),
        },
        WebObject: {
            CheckInForm: new PostMethod<any, any>(),
            CheckOutForm: new PostMethod<any, any>()
        },
        UserStartup: {
            GetAccountPermissionSetting: new GetMethod<any, null>(),
        },
        Flow1: {
            StartParameters: new PostMethod<any, any>(),
            Start: new PostMethod<any, any>()
        },
        Dashboard: {
            GetCounts: new PostMethod<any, any>(),
            GetProcessesUserBased: new PostMethod<any, any>(),
            GetDetailByUser: new PostMethod<any, any>(),
            ReportFlowsRequests: new PostMethod<any, any>(),
            GetWorkflowItemsRequests: new PostMethod<any, any>()
        },
    },
    BuildManager: {
        BuildManager: {
            BuildProject: new PostMethod<any, any>(),
            BuildServer: new PostMethod<any, any>(),
            BuildClient: new PostMethod<any, any>(),
            BuildSolution: new PostMethod<any, any>(),
            DeployProject: new PostMethod<any, any>(),
            DeployProjects: new PostMethod<any, any>(),
            PublishProject: new PostMethod<any, any>(),
            Cancel: new PostMethod<any, any>(),
            GetDeployAgents: new GetMethod<any, any>(),
            GetBuildAgents: new GetMethod<any, any>()
        }
    },
    SolutionExplorer: {
        SolutionExplorer: {
            CreateItem: new PostMethod<any, any>(),
            CreateAssetItem: new PostMethod<any, any>(),
            DeleteItem: new PostMethod<any, any>(),
            GetItemContent: new PostMethod<any, any>(),
            HasItem: new PostMethod<any, any>(),
            RenameItem: new PostMethod<any, any>(),
            UpdateItemContent: new PostMethod<any, any>(),
            GetNavigationItems: new PostMethod<any, any>(),
            OpenNavigationItem: new PostMethod<any, any>(),
            CreateProject: new PostMethod<any, any>(),
            GetRecentItems: new PostMethod<any[], null>(),
            UpdateRecentItem: new PostMethod<any, any>(),
            DeleteRecentItem: new PostMethod<any, any>(),
            CreateSolution: new PostMethod<any, any>(),
            AddNewProject: new PostMethod<any, any>(),
            RemoveProject: new PostMethod<any, any>(),
            AddExistingProject: new PostMethod<any, any>(),
            UpdateProject: new PostMethod<any, any>(),
            CreateArchiveForm: new PostMethod<any, any>(),
            ExportProject: new PostMethod<any, any>(),
            ImportProject: new PostMethod<any, any>(),
            ValidateProjects: new PostMethod<any, any>(),
            CreateActionForm: new PostMethod<any, any>(),
            ControlProjectFromDMBackup: new PostMethod<any, any>(),
            ControlProjectFromLocalBackup: new PostMethod<any, any>(),
            AnalyseImportProjectFromDMBackup: new PostMethod<any, any>(),
            AnalyseImportProjectFromLocalBackup: new PostMethod<any, any>(),
            ImportProjectFromLocalBackup: new PostMethod<any, any>(),
            ImportProjectFromDMBackup: new PostMethod<any, any>(),
        }
    },
    Studio: {
        DataSource: {
            Connection: {
                List: new PostMethod<any, any>(),
                Get: new PostMethod<any, any>(),
                Create: new PostMethod<any, any>(),
                Delete: new PostMethod<any, any>(),
                Test: new PostMethod<any, any>(),
                Update: new PostMethod<any, any>(),
            },
            Query: {
                Execute: new PostMethod<any, any>(),
                GetProjectQueries: new PostMethod<any, any>(),
                GetVariables: new PostMethod<any, any>(),

            },
            SAP: {
                GetFunctionInterface: new PostMethod<any, any>(),
                GetFunctionDocu: new PostMethod<any, any>(),
                GetTableStructureDefinition: new PostMethod<any, any>(),
                InvokeRfc: new PostMethod<any, any>(),
                SearchFunction: new PostMethod<any, any>(),
                SearchFunctionGroup: new PostMethod<any, any>(),
                GetFunctionGroupDocu: new PostMethod<any, any>(),
                GetTreeInit: new PostMethod<any, any>(),
                GetObjInterfaces: new PostMethod<any, any>(),
                GetObjMethods: new PostMethod<any, any>(),
                GetObjKeyFields: new PostMethod<any, any>(),
                GetObjAttributes: new PostMethod<any, any>(),
                GetObjEvents: new PostMethod<any, any>(),
                GetObjDocuLines: new PostMethod<any, any>(),
                GetMethodDocuLines: new PostMethod<any, any>(),
                GetParameterDocu: new PostMethod<any, any>(),
                GetMethodParameters: new PostMethod<any, any>(),
                GetRfcList: new PostMethod<any, any>(),
                GetFunctionGroups: new PostMethod<any, any>(),
                GetFunctions: new PostMethod<any, any>(),

            }
        },
        Settings: {
            Get: new PostMethod<any, any>(),
            Update: new PostMethod<any, any>(),
        },
        ProjectManager: {
            GetProjects: new PostMethod<any, any>(),
            GetForms: new PostMethod<any, any>(),
            GetFlows: new PostMethod<any, any>(),
            GetViews: new PostMethod<any, any>(),
            GetFormFields: new PostMethod<any, any>(),
            GetUniqueIdentities: new PostMethod<any, any>(),
            GetDeployments: new PostMethod<any, any>(),
            GetProjectFormStatuses: new PostMethod<any, any>(),
            GetFormFieldsByControlType: new PostMethod<any, any>(),
            GetControlColumns: new PostMethod<any, any>(),
            GetFlowDocuments: new PostMethod<any, any>(),
            GetFormFieldsForArchive: new PostMethod<any, any>(),
            DeleteProject: new PostMethod<any, any>(),
            OpenProject: new PostMethod<any, any>(),
            GetDeletedProjects: new PostMethod<any, any>(),
            RestoreProject: new PostMethod<any, any>(),
            GetRelationalControls: new PostMethod<any, any>(),
            GetDeployAgentAllPublishments: new PostMethod<any, any>(),
            UpdateFormControlSearchIndex: new PostMethod<any, any>(),
            GetProjectReports: new PostMethod<any, any>(),
            GetOngoingProcessIds: new PostMethod<any, any>()
        },
        IntegrationManager: {
            GetConnections: new PostMethod<any, any>(),
            GetQueries: new PostMethod<any, any>(),
            GetParameters: new PostMethod<any, any>(),
            GetFields: new PostMethod<any, any>(),
        },
        UniqueIdCreator: {
            InsertUniqueIdTemplate: new PostMethod<any, any>(),
            GetUniqueIdTemplates: new PostMethod<any, any>(),
        },
        DocumentManager: {
            GetMailTemplate: new PostMethod<any, any>(),
            GetFormInfoByName: new PostMethod<any, any>()
        },
        Action: {
            AddState: new PostMethod<any, any>(),
            UpdateState: new PostMethod<any, any>(),
            GetStateTree: new GetMethod<any, any>(),
            GetTags: new PostMethod<any, any>(),
            UpdateTags: new PostMethod<any, any>(),
            CreateAction: new PostMethod<any, any>(),

        }
    },
    FlowManager: {
        ProjectManager: {
            GetProcessesLean: new PostMethod<any, any>(),
            GetProcessSteps: new PostMethod<any, any>(),
            GetProcessStepsWithStatistics: new PostMethod<any, any>(),
            GetFlowFileContent: new PostMethod<any, any>(),
            GetWorkflowDataByProcessId: new PostMethod<any, any>(),
            DeleteProcess: new PostMethod<any, any>(),
            UpdateWorkflowData: new PostMethod<any, any>()
        }
    }
};