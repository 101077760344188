import { IFlexPanel } from "@bimser/components";
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { OrderedMap, Record } from "immutable";
import { FlexPanel, FlexPanelFactoryFromJS } from "../../../entities/FlexPanelFactory";


export interface IContentLayoutEntities {
    panels: IDictionary<IFlexPanel>
}

export interface IReadOnlyContentLayoutEntities {
    panels: OrderedMap<string, FlexPanel>
}

export type ContentLayoutEntities = ReadonlyRecord<IReadOnlyContentLayoutEntities>;

export const ContentLayoutEntitiesFactory = Record<IReadOnlyContentLayoutEntities>({
    panels: OrderedMap<string, FlexPanel>()
});

export const ContentLayoutEntitiesFactoryFromJS = function (data: IContentLayoutEntities) {

    let items: IDictionary<FlexPanel> = Object.keys(data.panels).reduce(function (previous: IDictionary<FlexPanel>, current) {
        previous[current] = FlexPanelFactoryFromJS(data.panels[current]);
        return previous;
    }, {});

    return ContentLayoutEntitiesFactory({
        panels: OrderedMap(items)
    });
}