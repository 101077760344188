import * as React from "react";
import * as ReactDOM from "react-dom";

import * as Styles from "./assets/style.scss";

import BCDropdownBox from '../BCDropdownBox';

import { ILookup } from './entities';

export default class BCLookup extends React.Component<ILookup, any>{

    constructor(props: ILookup) {
        super(props);
    }

    getGridComponent() {

        //let configs = {
        //    rows: this.props.dataSource,
        //    columns: this.props.columns
        //};

        //return (
        //    <div style={{ width: '500px' }}>
        //        <BCGrid
        //            grid={{
        //                columns: this.props.columns,
        //                rows: this.props.dataSource,
        //                tableEnabled: true,
        //                tableHeaderEnabled: true
        //            }}
        //        />
        //    </div>
        //)

        return <div></div> // Proje build olmadığı için kaldırıldı
    }

    render() {
        return (
            <BCDropdownBox
                size={this.props.size}
                title={this.props.title}
                value={this.props.value}
                content={this.getGridComponent()}
            />
        )
    }
}