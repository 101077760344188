import { i18nReducer, LoginReducer, ModalManagerReducer, FormsReducer, SystemReducer, UploadManagerReducer, UITourReducer, ExtremeGridReducer, DmViewerReducer } from '@bimser/common';
import { IBaseAction, AsyncReducers } from "@bimser/core";
import { WebInterfaceBaseState, WebInterfaceBaseStateFactory } from "../common/base/state";
import { routerReducer } from 'react-router-redux';
import { executeAsyncReducers } from 'app-init';
import ESignatureReducer from '../common/components/ESignature/reducers';

const initialState = WebInterfaceBaseStateFactory();

function RootReducer(state: WebInterfaceBaseState = initialState, action: IBaseAction<any>): WebInterfaceBaseState {
    return state.withMutations((state) => {
        state
            // .set("layouts", LayoutsReducer(state.layouts, action))
            // .set("modules", ModulesReducer(state.modules, action))
            .set("system", SystemReducer(state.system, action))
            .set("user", LoginReducer(state.user, action))
            .set("modals", ModalManagerReducer(state.modals, action))
            .set("i18n", i18nReducer(state.i18n, action))
            // .set("user", UserReducer(state.user, action))
            // .set("shared", SharedReducer(state.shared, action))
            .set("routing", routerReducer(state.routing, action))
            .set("Viewers", DmViewerReducer(state.Viewers, action))
            .set("forms", FormsReducer(state.forms, action))
            .set("ExtremeGrid", ExtremeGridReducer(state.ExtremeGrid, action))
            .set("UploadManager", UploadManagerReducer(state.UploadManager, action))
            .set("UITour", UITourReducer(state.UITour, action))
            .set("ESignature", ESignatureReducer(state.ESignature, action));

        executeAsyncReducers(RootReducer.asyncReducers, state, action);
    });
}

namespace RootReducer {
    export let asyncReducers: AsyncReducers<"layouts" | "modules" | "user" | "shared" | "Viewers"> = {}
}

export default RootReducer;