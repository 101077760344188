import React, { lazy, Suspense } from 'react';
import IOverflowSetProps from '../interfaces/IOverflowSetProps';

const FabricOverflowSet = lazy(
    () => import(/* webpackChunkName: "fabric-ui" */ "office-ui-fabric-react/lib/OverflowSet").then(module => ({ default: module.OverflowSet }))
);

function OverflowSet(props: Omit<IOverflowSetProps, "ref">) {
    return (
        <Suspense fallback={<div></div>}>
            <FabricOverflowSet {...props} />
        </Suspense>
    );
}

export default OverflowSet;