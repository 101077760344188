import { IBaseAction } from "@bimser/core";
import * as ActionTypes from "../actionTypes";

export default function (state: boolean, action: IBaseAction<any>): boolean {
    switch (action.type) {
        case ActionTypes.SET_EBA6_ENABLED: {
            return action.payload
        }
        default:
            return state;
    }
}