import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

interface IBaseDashboardSettings {
    projectName: string;
    formName: string;
}

export interface IDashboardSettings extends IBaseDashboardSettings {
    
}

export interface IReadonlyDashboardSettings extends IBaseDashboardSettings {
    
}

export type DashboardSettings = ReadonlyRecord<IReadonlyDashboardSettings>;

export const DashboardSettingsFactory = Record<IReadonlyDashboardSettings>({
    projectName: "",
    formName: ""
});

export const DashboardSettingsFactoryFromJS = function (data: IDashboardSettings) {
    return DashboardSettingsFactory(data);
}