import { IDictionary } from "../common/base/interfaces";

export function toLowerCase(str: string) {
    var letters: IDictionary<string> = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
    if (!str) return "";
    if (typeof str != "string") {
        console.warn("type error toLowerCase:", str);
        return "";
    }
    str = str.replace(/(([İIŞĞÜÇÖ]))/g, function (letter: string) { return letters[letter]; })
    return str.toLowerCase();
}	