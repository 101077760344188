import { BCLoading, findMessage } from '@bimser/components';
import * as React from 'react';
import Style from '../assets/style.scss';
const LoadingModalImg = require("../assets/img/loadingModal.svg").default;

export const LoadingModalContent = (props: {description?: string, loadingMessage?: string}) => {
    return (
        <div className={Style.loadingModal}>
            <img className={Style.loadingImage} src={LoadingModalImg} />
            <span>{props.description || findMessage.get("101354")}</span>
            <span className={Style.loadingText}>{props.loadingMessage || (findMessage.get("100127") + "...")}</span>
            <BCLoading show={true} />
        </div>
    )
}
