import { ITreeviewItem } from "../entities";

export const generatePlainItems = (items: ITreeviewItem<any>[]) => {
    const plainItems: ITreeviewItem<any>[] = [];

    if (items) getPlainItems(items, (item: ITreeviewItem<any>) => {
        plainItems.push(item);
    });

    return plainItems
}

const getPlainItems = (data: ITreeviewItem<any>[], callback: (item: ITreeviewItem<any>) => void) => {
    if (data) for (const node of data) {

        callback({ key: node.key, text: node.text });
        if (node.children?.length) getPlainItems(node.children, callback);
    }
}