import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { ILayoutBaseItem, ILayoutItemEngineProps, LayoutBaseItemRecord, LayoutItemEngineProps, LayoutItemEnginePropsFactory, LayoutItemEnginePropsFactoryFromJS } from '.';
import { LayoutBaseItemFactoryObjectFromJS } from "./ILayoutBaseItem";

export interface IBaseLayoutStructureItem extends ILayoutBaseItem {
    parentId?: string
}

export interface ILayoutStructureItem extends IBaseLayoutStructureItem {
    designerProps?: ILayoutItemEngineProps,
    items?: Array<ILayoutStructureItem> | Array<string>
}

export interface IReadonlyLayoutStructureItem extends IBaseLayoutStructureItem {
    designerProps?: LayoutItemEngineProps,
    items?: List<LayoutStructureItem | string>
}

export type LayoutStructureItem = ReadonlyRecord<IReadonlyLayoutStructureItem>;

export const LayoutStructureItemRecord: IReadonlyLayoutStructureItem = {
    designerProps: LayoutItemEnginePropsFactory(),
    parentId: null,
    items: List(),
    ...LayoutBaseItemRecord
};

export const LayoutStructureItemFactory = Record<IReadonlyLayoutStructureItem>(LayoutStructureItemRecord);

export const LayoutStructureItemFactoryFromJS = function (data: ILayoutStructureItem) {
    return LayoutStructureItemFactory(LayoutStructureItemFactoryObjectFromJS(data));
}

export const LayoutStructureItemFactoryObjectFromJS = function (data: ILayoutStructureItem) {

    let items: Array<LayoutStructureItem | string> = [];

    if (data.items?.length && typeof data.items[0] !== 'string') {
        items = (data.items as Array<ILayoutStructureItem>).map((value: ILayoutStructureItem) => LayoutStructureItemFactoryFromJS(value));
    } else {

        items = (data.items as Array<string>);

    }

    return {
        parentId: data.parentId,
        items: List(items),
        designerProps: LayoutItemEnginePropsFactoryFromJS(data.designerProps),
        ...LayoutBaseItemFactoryObjectFromJS(data)
    };

}