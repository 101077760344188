import { createGuid, isNullOrUndefined } from "@bimser/core";
import { notification } from 'antd';
import * as React from "react";
import { findMessage } from '../../..';
import BCInput from '../../../BCInput';
import { IPropertyComponentProps, IPropertyItem } from '../../entities';

const defaultStyle: React.CSSProperties = { width: '100%' };

export default function InputProperty(props: IPropertyComponentProps) {
    
    const triggerChange = (value: string) => {
        if (props.item.externalData?.allowNull === false && (value === "" || isNullOrUndefined(value))) {
            value = props.value;
            notification.error({
                message: findMessage.get("100652"),
                description: findMessage.get("100079").replace("$0", props.item.key)
            })
        }
        props.customChangeEvent ? props.customChangeEvent(value) : replaceValue(props.item, value)
    }

    const replaceValue = (item: IPropertyItem, newValue: any) => {
        props.onValueChanged(item, newValue)
    }

    const onChange = (e: any) => {
        const value = e.data;

        let result = checkReservedValues(value);

        if (result) {
            if (!props.item.externalData || !props.item.externalData.triggerChangeOnBlur) {
                triggerChange(value);
            }
        } else {
            if (props.item?.externalData?.reservedValues?.warningMessage) notification.error(props.item.externalData.reservedValues.warningMessage);
        }

    }

    const checkReservedValues = (value: string) => {
        if (props.item?.externalData?.reservedValues?.keys) {
            const keys: string[] = props.item.externalData.reservedValues.keys;

            return keys.find(i => i.toLowerCase() === value.toLowerCase()) ? false : true
        }

        return true
    }

    const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
        let val: string = ev.target.value;
        if (props.item.externalData?.isLiquid) {
            let newString = checkLiquidRemake(val);
            if (val != newString) {
                triggerChange(newString);
            }
        }

        if (props.item.externalData?.triggerChangeOnBlur && val != props.value) {
            triggerChange(val);
        }
    }

    const checkLiquidRemake = (str: string) => {
        let dotGuid = createGuid(true);
        str = (str as any).replaceAll(".", dotGuid);
        let allMachted = (str as any).matchAll(/{( |)+\w+( |)+}/gm);
        let current = allMachted.next();
        let newString: string = str;
        while (!current.done) {
            let startIndex: number = current.value.index;
            let matchedText: string = current.value[0];
            let hasOpenScope: boolean = startIndex - 1 >= 0 && str[startIndex - 1] === "{";
            let hasOutScope: boolean = str.length > matchedText.length + startIndex && str[matchedText.length + startIndex] === "}";
            if ((hasOpenScope && !hasOutScope) || (!hasOpenScope && hasOutScope)) {
                if (hasOpenScope) {//add out
                    newString = insertString(newString, "}", matchedText.length + startIndex);
                }
                if (hasOutScope) {
                    newString = insertString(newString, "{", startIndex);
                }
            }
            current = allMachted.next();
        }
        let _clone = newString.valueOf();
        let noHaveAnyOutScope = (_clone as any).matchAll(/{{( |)+\w+/gm);
        current = noHaveAnyOutScope.next();
        while (!current.done) {
            let startIndex: number = current.value.index;
            let matchedText: string = current.value[0];
            let remainingText = _clone.slice(startIndex + matchedText.length).trim();
            if (!(remainingText[0] == "}" && remainingText[1] == "}")) {
                let insterText = matchedText.split(" ")[0] == "{{" ? " }} " : "}} ";
                newString = insertString(newString, insterText, startIndex + matchedText.length);
            }
            current = noHaveAnyOutScope.next();
        }
        return (newString as any).replaceAll(dotGuid, ".");
    }


    const insertString = (str: string, insert: string, index: number) => {
        return str.slice(0, index) + insert + str.slice(index);
    }

    return (
        <BCInput
            value={props.value || props.item.placeholder}
            type={props.item.externalData?.type}
            disabled={props.item.disabled}
            readOnly={props.item.readOnly}
            onBlur={onBlur}
            style={props.style || defaultStyle}
            regExp={props.item.externalData?.regExp}
            addonBefore={props.item.externalData?.addonBefore}
            addonAfter={props.item.externalData?.addonAfter}
            onChange={onChange}
        />
    )
}