import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseDmDataPermissions {
    activities?: boolean,
    addContent?: boolean,
    addDigitalSignature?: boolean,
    addFile?: boolean,
    addFolder?: boolean,
    addRelatedFile?: boolean,
    addTimestamp?: boolean,
    copy?: boolean,
    deleteContent?: boolean,
    deleteFile?: boolean,
    deleteFolder?: boolean,
    deleteRelatedFile?: boolean,
    deleteShortcut?: boolean,
    deleteVersion?: boolean,
    download?: boolean,
    editContent?: boolean,
    editDescription?: boolean,
    editName?: boolean,
    editPermission?: boolean,
    editProfile?: boolean,
    editProperty?: boolean,
    editVersion?: boolean,
    move?: boolean,
    print?: boolean,
    refreshIndex?: boolean,
    sendAsMail?: boolean,
    shareLink?: boolean,
    shortcut?: boolean,
    viewContent?: boolean,
    viewDigitalSignature?: boolean,
    viewFile?: boolean,
    viewFolder?: boolean,
    viewProfile?: boolean,
    viewRelatedFile?: boolean,
    viewTimestamp?: boolean,
    viewVersion?: boolean,
}

export interface IDmDataPermissions extends IBaseDmDataPermissions { }
export interface IReadonlyDmDataPermissions extends IBaseDmDataPermissions { }
export type DmDataPermissions = ReadonlyRecord<IReadonlyDmDataPermissions>;

export const DmDataPermissionsFactory = Record<IReadonlyDmDataPermissions>({
    activities: false,
    addContent: false,
    addDigitalSignature: false,
    addFile: false,
    addFolder: false,
    addRelatedFile: false,
    addTimestamp: false,
    copy: false,
    deleteContent: false,
    deleteFile: false,
    deleteFolder: false,
    deleteRelatedFile: false,
    deleteShortcut: false,
    deleteVersion: false,
    download: false,
    editContent: false,
    editDescription: false,
    editName: false,
    editPermission: false,
    editProfile: false,
    editProperty: false,
    editVersion: false,
    move: false,
    print: false,
    refreshIndex: false,
    sendAsMail: false,
    shareLink: false,
    shortcut: false,
    viewContent: false,
    viewDigitalSignature: false,
    viewFile: false,
    viewFolder: false,
    viewProfile: false,
    viewRelatedFile: false,
    viewTimestamp: false,
    viewVersion: false,
});

export const DmDataPermissionsFactoryFromJS = function (permissions: IDmDataPermissions) {
    return DmDataPermissionsFactory(permissions);
}