import { createSelector } from 'reselect';
import { WebInterfaceBaseState } from '../../../common/base/state';
import { List } from 'immutable';
import { IProcess, Process } from '../entities/widgets/model/types/IProcess';

const getProcessDataSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processes.data;
}

const getProcessTotalCountSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processes.totalCount;
}

const getSelectedProcessSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processes.selected;
}

const getLoadingProcessesSelector = (state: WebInterfaceBaseState) => {
    return state.modules.dashboard.widgets.processes.loading;
}

export const getProcessData = createSelector(
    [
        getProcessDataSelector
    ],
    (processes: List<Process>) : Array<IProcess> => {
        return processes?.map(process => process.toJS()).toJS() || [];
    }
);

export const getProcessTotalCount = createSelector(
    [
        getProcessTotalCountSelector
    ],
    (counts: number) : number => counts
);


export const getSelectedProcess = createSelector(
    [
        getSelectedProcessSelector
    ],
    (selected: Process) : IProcess => selected?.toJS()
);

export const getLoadingProcesses = createSelector(
    [
        getLoadingProcessesSelector
    ],
    (loading: boolean) : boolean => loading
);