import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import { IWebInterfaceAction } from "../../../../common/entities";
import { onChangeSelectedTileTransaction } from "../../actions";
import CountTiles from "../../components/widgets/CountTiles";
import ICountTileProps from "../../entities/widgets/ICountTileProps";
import { getSelectedTile, getTilesCounts, getTilesLoading } from "../../selectors/CountTileSelectors";

// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: ICountTileProps): ICountTileProps {
    return {
        counts: getTilesCounts(state),
        selectedTile: getSelectedTile(state),
        loading: getTilesLoading(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): ICountTileProps {
    return {
        onChangeSelected: function(data: IWebInterfaceAction) {
            dispatch(onChangeSelectedTileTransaction(data))
        }
    };
}

// react - redux connection
const CountTilesContainer = connect(mapStateToProps, mapDispatchToProps)(CountTiles);

export default CountTilesContainer;