
import { connect } from 'react-redux';
import { WebInterfaceBaseState } from '../../../base/state';
import PanelComponent from '../components';
import { panelWillUnMountTransaction } from '../../../actions';
import { panelType } from '../../../entities';


const mapStateToProps = function (state: WebInterfaceBaseState, props: any) {
    return {

    }
}

const mapDispatchToProps = function (dispatch: Function) {
    return {
        onCancel: (modalId: string, panelType: panelType) => {
            dispatch(panelWillUnMountTransaction({ panelId: modalId, panelType }));
        }
    }
}

const PanelComponentContainer = connect(mapStateToProps, mapDispatchToProps)(PanelComponent);

export default PanelComponentContainer;
