import * as React from "react";
import * as Styles from "../assets/style.scss";
import IOfficeViewerProps from "../entities/OfficeViewer/IOfficeViewerProps";
import { createGuid } from '@bimser/core';

export default class OfficeViewer extends React.Component<IOfficeViewerProps, any> {
    officeForm: HTMLFormElement;
    iframeId: string;
    constructor(props: IOfficeViewerProps) {
        super(props);
        this.generateTemplate = this.generateTemplate.bind(this);
        this.iframeId = createGuid();
    }
    componentDidMount() {
        this.officeForm.submit();
    }

    generateTemplate(props: IOfficeViewerProps): JSX.Element {
        return (
            <>
                <form ref={(domNode) => {
                    this.officeForm = domNode;
                }} target={this.iframeId} action={this.props.data.url} method="post">
                    <input name="access_token" value={this.props.data.token} type="hidden" />
                    <input name="access_token_ttl" value={this.props.data.timeToLive} type="hidden" />
                </form>
                <iframe id={this.iframeId} name={this.iframeId} width='100%' frameBorder='0' height='100%'></iframe>
            </>
        );
    }

    render() {
        return (
            <div className={Styles.officeViewerContent}>
                {
                    this.generateTemplate(this.props)
                }
            </div>
        );
    }

}