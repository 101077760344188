import { createSelector } from 'reselect';
import { BaseState } from '../../../entities/BaseState';
import { DMExplorerState } from '../entities/DMExplorerState';
import { INavigationItem } from '../entities/INavigationItem';

const getDMExplorerItemsSelector = (state: BaseState) => state.DMExplorer;
const getDMExplorerItems = createSelector(
    [
        getDMExplorerItemsSelector
    ],
    (data: DMExplorerState): Array<INavigationItem> => {
        let loadedItemData = data.itemsData.find((item, key) => data.currentLocation.id === key);
        if (loadedItemData) {

            if (data && data.itemsData && data.itemsData.has(data.currentLocation.id) && data.itemsData.get(data.currentLocation.id).items) {
                return data.itemsData.get(data.currentLocation.id).items.toJS();
            }
            else {
                return [];
            }
        }

        else {
            return [];
        }
    }
);


export {
    getDMExplorerItems
};

export function nullCaster(data: any): any {
    return data == "" || data == null || data == undefined ? null : data;
}