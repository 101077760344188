import { BCExtremeGridEntities } from "@bimser/components";
import { IDictionary, ReadonlyRecord } from "@bimser/core";
import { Record, Map } from "immutable";
import { IIndexReport, IndexReport, IndexReportFactoryFromJS } from "./IIndexReport";
import { IIndexReportDetailData, IndexReportDetailData, IndexReportDetailDataFactoryFromJS } from "./IIndexReportDetailData";

interface IBaseIndexReportState {
    selectedReportItem: string,
    isLoading: boolean,
    hasMoreReportItems: boolean,
    reportItemsSearchStr: string,

}

export interface IIndexReportState extends IBaseIndexReportState {
    reportData: IIndexReport,
    reportDetails: IDictionary<IIndexReportDetailData>,
    reportItemsLoadOptions: BCExtremeGridEntities.ILoadOptions,

}

export interface IReadonlyIndexReportState extends IBaseIndexReportState {
    reportData: IndexReport,
    reportDetails: Map<string, IndexReportDetailData>,
    reportItemsLoadOptions: BCExtremeGridEntities.LoadOptions,
}

export type IndexReportState = ReadonlyRecord<IReadonlyIndexReportState>;

export const IndexReportStateFactory = Record<IReadonlyIndexReportState>({
    reportData: null,
    reportDetails: Map(),
    selectedReportItem: "general",
    isLoading: false,
    hasMoreReportItems: false,
    reportItemsSearchStr: null,
    reportItemsLoadOptions: BCExtremeGridEntities.LoadOptionsFactory(),
});

export const IndexReportStateFactoryFromJS = function (data: IIndexReportState) {
    let _reportDetails: IDictionary<IndexReportDetailData> = Object.keys(data.reportDetails).reduce(function (previous: IDictionary<IndexReportDetailData>, current) {
        previous[current] = IndexReportDetailDataFactoryFromJS(data.reportDetails[current]);
        return previous;
    }, {});

    return IndexReportStateFactory({
        ...data,
        reportData: IndexReportFactoryFromJS(data.reportData),
        reportDetails: Map(_reportDetails),
        reportItemsLoadOptions: BCExtremeGridEntities.LoadOptionsFactoryFromJS(data.reportItemsLoadOptions),
    });
}