import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";

export interface IBaseDmSceneHistory {
    current: string,
}

export interface IDmSceneHistory extends IBaseDmSceneHistory {
    backStack: Array<string>,
    forwardStack: Array<string>
}

export interface IReadonlyDmSceneHistory extends IBaseDmSceneHistory {
    backStack: List<string>,
    forwardStack: List<string>
}

export type DmSceneHistory = ReadonlyRecord<IReadonlyDmSceneHistory>;

export const DmSceneHistoryFactory = Record<IReadonlyDmSceneHistory>({
    current: "",
    backStack: List(),
    forwardStack: List()
});


export const DmSceneHistoryFactoryFromJS = function (state: IDmSceneHistory) {
    return DmSceneHistoryFactory({
        current: state.current,
        backStack: List(state.backStack),
        forwardStack: List(state.forwardStack)
    });
}