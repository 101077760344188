import { List, Map } from "immutable";
import { insertToView } from ".";
import { LayoutStructureItem, NormalizedLayoutStructureItems } from "../../../common/entities";

export const handleAddStructureToParent = (
    views: Map<string, NormalizedLayoutStructureItems>,
    activeView: string,
    splitDropZonePath: List<string>,
    item: LayoutStructureItem
) => {

    // Link items
    item = item.set("parentId", item.parentId);

    // Insert new items to view
    views = insertToView(views, activeView, [item]);

    // Insert item to parent's items
    const newItems = views.getIn([activeView, "entities", "items", item.parentId, "items"]).insert(Number(splitDropZonePath.last()), item.id);
    views = views.setIn([activeView, "entities", "items", item.parentId, "items"], newItems);

    return views
};