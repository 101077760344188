import React, { lazy, Suspense } from 'react';
import * as VectorMapEntities from './entities/IVectorMapProps';

const BCVectorMapLazy = lazy(
    () => import(/* webpackChunkName: "vectorMap" */ "./components")
);

function BCVectorMap(props: VectorMapEntities.IVectorMapProps) {
    return (
        <Suspense fallback={<div></div>}>
            <BCVectorMapLazy {...props} />
        </Suspense>
    );
}

export { BCVectorMap, VectorMapEntities as BCVectorMapEntities };
