import { IDictionary, MLHelper } from "@bimser/core";
import { MessageBox, MessageBoxButton } from '../../..';

export function showConfirm(title: IDictionary<string> | string, text: IDictionary<string> | string) {

    return new Promise<void>((resolve, reject) => {

        MessageBox.confirm({
            title: MLHelper.getMLText(title),
            content: MLHelper.getMLText(text)
        }).then(result => {
            if (result.button.id === MessageBoxButton.OK) {
                resolve();
            } else {
                reject();
            }
        })

    })

}