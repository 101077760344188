import { WebInterfaceBaseState } from "common/base/state";
import { connect } from "react-redux";
import AnnouncementsBox from "../components/AnnouncementsBox";
import IAnnouncementBoxCProps from "../entities/IAnnouncementBoxCProps";
import IAnnouncementBoxProps from "../entities/IAnnouncementBoxProps";
import { getAnnouncementsBoxItems } from "../selectors/AnnouncementsBoxSelector";
import { getAnnouncementBoxLoading } from "../selectors/AnnouncementBoxLoadingSelector";
import { IWebInterfaceAction } from "common/entities/IWebInterfaceAction";
import { openAnnouncementFormTransaction } from "../actions";
import getAnnouncementPermissions from 'modules/announcements/selectors/getAnnouncementPermissionsSelector';


// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: IAnnouncementBoxProps): IAnnouncementBoxProps {
    return {
        items: getAnnouncementsBoxItems(state, props),
        showLoading: getAnnouncementBoxLoading(state),
        startupPanelId: props.startupPanelId,
        announcementPermissions: getAnnouncementPermissions(state)
    }
};

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): IAnnouncementBoxCProps {
    return {
        onClickAllAnnouncement: function (data: IWebInterfaceAction) {
            import(/* webpackChunkName: "web-others" */ "../../announcements/actions/openAllAnnouncementsPanelTransaction")
                .then(({ default: openAllAnnouncementsPanelTransaction }) => {
                    import(/* webpackChunkName: "web-others" */ "modules/announcements/injector").then(() => {
                        dispatch(openAllAnnouncementsPanelTransaction(data));
                    });
                })
        },
        openAnnouncementDetail: function (data: IWebInterfaceAction) {
            import(/* webpackChunkName: "web-others" */ "../../announcements/actions/openAnnouncementDetailTransaction")
                .then(({ default: openAnnouncementDetailTransaction }) => {
                    import(/* webpackChunkName: "web-others" */ "modules/announcements/injector").then(() => {
                        dispatch(openAnnouncementDetailTransaction(data));
                    });
                })
        },
        onOpenAnnouncementForm: function (data: IWebInterfaceAction) {
            dispatch(openAnnouncementFormTransaction(data));
        }
    };
}

// react - redux connection
const AnnouncementBoxContainer = connect(mapStateToProps, mapDispatchToProps)(AnnouncementsBox);

export default AnnouncementBoxContainer;