import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";

export interface IBaseModalFrameState {
    url: string
}

export interface IModalFrameState extends IBaseModalFrameState {

}

export interface IReadonlyModalFrameState extends IBaseModalFrameState {
}

export type ModalFrameState = ReadonlyRecord<IReadonlyModalFrameState>;

export const ModalFrameStateFactory = Record<IReadonlyModalFrameState>({
    url: null
});

export const ModalFrameStateFactoryFromJS = function (data: IModalFrameState) {
    return ModalFrameStateFactory(data);
}