import { Record } from "immutable";
import { ReadonlyRecord } from "@bimser/core";
import { Field, FieldFactory, FieldFactoryFromJS, IField, ReadOnlyField } from "../IField";
import { BaseActionFactoryObject, BaseActionFactoryObjectFromJS, IBaseAction, ReadOnlyBaseAction } from './IBaseAction';
import { ActionType } from '..';

export interface IBaseValueAssignAction extends IBaseAction {
    field: IField
}

export interface ReadOnlyBaseValueAssignAction extends ReadOnlyBaseAction {
    field: Field
}

export type BaseValueAssignAction = ReadonlyRecord<ReadOnlyBaseValueAssignAction>;

export const BaseValueAssignActionFactoryObject: ReadOnlyBaseValueAssignAction = {
    field: FieldFactory(),
    ...BaseActionFactoryObject
};

export const BaseValueAssignActionFactory = Record<ReadOnlyBaseValueAssignAction>(BaseValueAssignActionFactoryObject);

export const BaseValueAssignActionFactoryObjectFromJS = function (data: IBaseValueAssignAction) {
    return {
        ...BaseActionFactoryObjectFromJS(data),
        field: FieldFactoryFromJS(data.field)
    };
}

export const BaseValueAssignActionFactoryFromJS = function (data: IBaseValueAssignAction) {
    return BaseValueAssignActionFactory(BaseValueAssignActionFactoryObjectFromJS(data));
}