import { ReadonlyRecord, IDictionary } from "@bimser/core";
import { List, Map, Record } from "immutable";
import { DmData, DmDataFactoryFromJS, IDmData } from './IDmData';
import { DmPanel, DmPanelFactoryFromJS, IDmPanel } from './IDmPanel';
import { DmShared, DmSharedFactory, DmSharedFactoryFromJS, IDmShared } from './IDMShared';
import { IFileMovePanelContent, FileMovePanelContentFactoryFromJS, FileMovePanelContent, FileMovePanelContentFactory } from "../../components/explorer/components/fileMovePopover/entities/IFileMovePanelContent";
import { IDmDashboardLoadingStatus, DmDashboardLoadingStatus, DmDashboardLoadingStatusFactory, DmDashboardLoadingStatusFactoryFromJS } from "./IDmDashboardLoadingStatus";
import { IClipboardPanelContent, ClipboardPanelContent, ClipboardPanelContentFactory, ClipboardPanelContentFactoryFromJS } from "../../components/explorer/components/clipboard/entities/IClipboardPanelContent";
import { IObjectSelectPanelContent, ObjectSelectPanelContent, ObjectSelectPanelContentFactory, ObjectSelectPanelContentFactoryFromJS } from "../../components/explorer/components/objectSelectPopover/entities/IObjectSelectPanelContent";
import { IComparisonPanelContent, ComparisonPanelContent, ComparisonPanelContentFactoryFromJS } from "../../components/comparison/entities/IComparisonPanelContent";
import { ISearchOptions, SearchOptions, SearchOptionsFactoryFromJS, SearchOptionsFactory } from "../../components/dashboard/components/advancedSearch/entities/SearchOptions";
import { IDMSecurityState, DMSecurityState, DMSecurityStateFactoryFromJS, DMSecurityStateFactory } from '../../components/explorer/modals/SecurityModal/entities/DmSecurityState';
import { IPropertiesContent, PropertiesContentFactoryFromJS, PropertiesContent, PropertiesFactory } from "../../components/explorer/modals/PropertiesModal/entities/models/IPropertiesContent";
import { DmParameters, DmParametersFactory, DmParametersFactoryFromJS, IDmParameters } from './IDmParameters';
import { IProfileFormState, ProfileFormState, ProfileFormStateFactory, ProfileFormStateFactoryFromJS } from "../../components/explorer/components/profileForm/entities/ProfileFormState";
import { IViewerState, ViewerState, ViewerStateFactory, ViewerStateFactoryFromJS } from '../../components/viewer/entities/IViewerState';
import { IDmRecycleBinSettings, DmRecycleBinSettingsFactory, DmRecycleBinSettings, DmRecycleBinSettingsFactoryFromJS } from './IDmRecycleBinSettings';

export interface IBaseDmMainState {
    dashboardChildPanelGuid?: string,
}

export interface IDmMainState extends IBaseDmMainState {
    panels: IDictionary<IDmPanel>,
    profileForm: IProfileFormState,
    security: IDMSecurityState,
    favorites: Array<string>,
    recentFiles: Array<string>,
    shared: IDmShared,
    data: IDictionary<IDmData>
    fileMove: IDictionary<IFileMovePanelContent>,
    dashboardLoadingStatus: IDmDashboardLoadingStatus,
    clipboard: IClipboardPanelContent,
    objectSelect: IDictionary<IObjectSelectPanelContent>,
    comparison: IDictionary<IComparisonPanelContent>,
    searchOptions: ISearchOptions,
    dmParameters: IDmParameters,
    properties: IPropertiesContent,
    dmViewer: IViewerState,
    recycleBinSettings: IDmRecycleBinSettings
}

export interface IReadonlyDmMainState extends IBaseDmMainState {
    panels: Map<string, DmPanel>,
    profileForm: ProfileFormState,
    security: DMSecurityState,
    shared: DmShared,
    favorites: List<string>,
    recentFiles: List<string>,
    data: Map<string, DmData>,
    fileMove: FileMovePanelContent,
    dashboardLoadingStatus: DmDashboardLoadingStatus,
    clipboard: ClipboardPanelContent,
    objectSelect: ObjectSelectPanelContent,
    comparison: Map<string, ComparisonPanelContent>,
    searchOptions: SearchOptions,
    dmParameters: DmParameters,
    properties: PropertiesContent,
    dmViewer: ViewerState,
    recycleBinSettings: DmRecycleBinSettings
}

export type DmMainState = ReadonlyRecord<IReadonlyDmMainState>;

export const DmMainStateFactory = Record<IReadonlyDmMainState>({
    panels: Map<string, DmPanel>(),
    profileForm: ProfileFormStateFactory(),
    security: DMSecurityStateFactory(),
    shared: DmSharedFactory(),
    favorites: List<string>(),
    recentFiles: List<string>(),
    data: Map<string, DmData>(),
    fileMove: FileMovePanelContentFactory(),
    dashboardLoadingStatus: DmDashboardLoadingStatusFactory(),
    clipboard: ClipboardPanelContentFactory(),
    objectSelect: ObjectSelectPanelContentFactory(),
    comparison: Map<string, ComparisonPanelContent>(),
    searchOptions: SearchOptionsFactory(),
    dashboardChildPanelGuid: "",
    dmParameters: DmParametersFactory(),
    properties: PropertiesFactory(),
    dmViewer: ViewerStateFactory(),
    recycleBinSettings: DmRecycleBinSettingsFactory()
});

export const DmMainStateFactoryFromJS = function (state: IDmMainState) {
    let _panels: IDictionary<DmPanel> = Object.keys(state.panels).reduce(function (previous: IDictionary<DmPanel>, current) {
        previous[current] = DmPanelFactoryFromJS(state.panels[current]);
        return previous;
    }, {});

    let _data: IDictionary<DmData> = Object.keys(state.data).reduce(function (previous: IDictionary<DmData>, current) {
        previous[current] = DmDataFactoryFromJS(state.data[current]);
        return previous;
    }, {});

    let _fileMove: IDictionary<FileMovePanelContent> = Object.keys(state.fileMove).reduce(function (previous: IDictionary<FileMovePanelContent>, current) {
        previous[current] = FileMovePanelContentFactoryFromJS(state.fileMove[current]);
        return previous;
    }, {})

    let _comparison: IDictionary<ComparisonPanelContent> = Object.keys(state.comparison).reduce(function (previous: IDictionary<ComparisonPanelContent>, current) {
        previous[current] = ComparisonPanelContentFactoryFromJS(state.comparison[current]);
        return previous;
    }, {})

    let _objectSelect: IDictionary<ObjectSelectPanelContent> = Object.keys(state.objectSelect).reduce(function (previous: IDictionary<ObjectSelectPanelContent>, current) {
        previous[current] = ObjectSelectPanelContentFactoryFromJS(state.objectSelect[current]);
        return previous;
    }, {})

    return DmMainStateFactory({
        panels: Map(_panels),
        profileForm: ProfileFormStateFactoryFromJS(state.profileForm),
        security: DMSecurityStateFactoryFromJS(state.security),
        shared: DmSharedFactoryFromJS(state.shared),
        favorites: List<string>(state.favorites),
        recentFiles: List<string>(state.recentFiles),
        data: Map(_data),
        fileMove: FileMovePanelContentFactoryFromJS(_fileMove),
        dashboardLoadingStatus: DmDashboardLoadingStatusFactoryFromJS(state.dashboardLoadingStatus),
        clipboard: ClipboardPanelContentFactoryFromJS(state.clipboard),
        objectSelect: ObjectSelectPanelContentFactoryFromJS(_objectSelect),
        comparison: Map(_comparison),
        searchOptions: SearchOptionsFactoryFromJS(state.searchOptions),
        dashboardChildPanelGuid: state.dashboardChildPanelGuid,
        dmParameters: DmParametersFactoryFromJS(state.dmParameters),
        properties: PropertiesContentFactoryFromJS(state.properties),
        dmViewer: ViewerStateFactoryFromJS(state.dmViewer),
        recycleBinSettings: DmRecycleBinSettingsFactoryFromJS(state.recycleBinSettings)
    });
}