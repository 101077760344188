import React, { lazy, Suspense } from 'react';
import { injectSaga } from "app-init"
import { IProfessionFormProps } from './entities';
import { IBaseEditFormProps } from '../../../common/base/components/editForm/entities';


const ProfessionFormContainerLazy = lazy(() => import(/* webpackChunkName: "web-hr" */ "./containers"));

function ProfessionFormContainer(props: React.PropsWithChildren<IProfessionFormProps & IBaseEditFormProps>) {
    return (
        <Suspense fallback={<div></div>}>
            <ProfessionFormContainerLazy {...props} />
        </Suspense>
    );
}
ProfessionFormContainer.onPanelOpening = () => {
    return import(/* webpackChunkName: "web-hr" */ "modules/hr/injector");
}

export default ProfessionFormContainer;