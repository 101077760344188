import { ReadonlyRecord } from '@bimser/core';
import { Record } from "immutable";

export interface IBaseIFormLoadActionPayload {
    windowId: string
}

export interface IFormLoadActionPayload extends IBaseIFormLoadActionPayload {
}

export interface IReadonlyFormLoadActionPayload extends IBaseIFormLoadActionPayload {
}

export type FormLoadActionPayload = ReadonlyRecord<IReadonlyFormLoadActionPayload>;

export const FormLoadActionPayloadFactory = Record<IReadonlyFormLoadActionPayload>({
    windowId: null
});

export const FormLoadActionPayloadFactoryFromJS = function (data: IFormLoadActionPayload) {
    return FormLoadActionPayloadFactory({
        windowId: data.windowId
    })
}