import { ReadonlyRecord } from "@bimser/core";
import { List, Record } from "immutable";
import { IPosition, IUser } from "modules/hr/entities/Interfaces";
import { IWorkflowItem, WorkflowItem, WorkflowItemFactoryFromJS } from "modules/workflow/entities";
import { TransferType } from "./TransferType";

interface IBaseFilteringData {
    fromTransferType: TransferType;
    whoWillDoTheTask: IUser;
    selectedTask: IWorkflowItem;
    loading: boolean;
    toTransferType: TransferType;
    whichPosWillDoTheTask: IPosition;
}

export interface IFilteringData extends IBaseFilteringData {
    whoDoesTheTask: IUser[];
    tasks: IWorkflowItem[];
    whichPosDoTheTask: IPosition[];
}

export interface IReadonlyFilteringData extends IBaseFilteringData {
    whoDoesTheTask: List<IUser>;
    tasks: List<WorkflowItem>;
    whichPosDoTheTask: List<IPosition>;
}

export type FilteringData = ReadonlyRecord<IReadonlyFilteringData>;

export const FilteringDataFactory = Record<IReadonlyFilteringData>({
    fromTransferType: TransferType.USER,
    whoDoesTheTask: List(),
    whoWillDoTheTask: null,
    selectedTask: null,
    tasks: List(),
    loading: false,
    toTransferType: TransferType.USER,
    whichPosDoTheTask: List(),
    whichPosWillDoTheTask: null,
});

export const FilteringDataFactoryFromJS = function (data: IFilteringData) {

    return FilteringDataFactory({
        ...data,
        whoDoesTheTask: List(data.whoDoesTheTask),
        tasks: List(data.tasks.map(WorkflowItemFactoryFromJS)),
        whichPosDoTheTask: List(data.whichPosDoTheTask),
    });
}