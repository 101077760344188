import IIconProps, { IconInfo, IconSet } from '../entities/IIconProps';

type IconFunctionComponent = (props: IIconProps) => JSX.Element;
type IconComponentType = IconFunctionComponent & { info: IconInfo }

export default function LoadIconByName(name: string, set: IconSet, loader: (name: string) => IconComponentType, userDefault: IconComponentType) {
    if (name) {
        try {
            return loader(name);
        } catch (error) {
            console.warn(`'${name}' named icon not found in set ${set}`);
        }
    }

    return userDefault ? userDefault : undefined;
}

export { IconComponentType };