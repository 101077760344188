import { EDeviceTypes, FormItemTypes, getDeviceType } from "@bimser/core";
import classNames from 'classnames/bind';
import React, { useCallback } from "react";
import BCScrollbars, { BCScrollbarRef } from "../../../BCScrollbars";
import * as Styles from "./assets/styles.scss";
import { IContainer } from "./entities";
import Section from "./Section";

const cx = classNames.bind(Styles);

const Container = React.memo(React.forwardRef((props: IContainer, ref: React.ForwardedRef<{ container: HTMLDivElement, scrollView: BCScrollbarRef }>) => {

    const containerDomNode = React.useRef<HTMLDivElement>(null);
    const scrollRef = React.useRef<BCScrollbarRef>(null);
    const [deviceType, setDeviceType] = React.useState<EDeviceTypes>(EDeviceTypes.Medium);

    const parentItemType = props.parentItem?.type;

    React.useImperativeHandle(ref, () => ({
        get container() {
            return containerDomNode.current;
        },
        get scrollView() {
            return scrollRef.current;
        }
    }));

    React.useEffect(() => {
        // Component did mount
        props.onLayoutDidUpdate?.();
    }, []);

    React.useEffect(() => {
        // Component did update
        props.onLayoutDidUpdate?.();
        if (parentItemType !== FormItemTypes.EPanelBaseTypes.InputGroup && containerDomNode?.current) {
            setDeviceType(getDeviceType(containerDomNode.current.offsetWidth));
        }
    });

    const getItem = useCallback((id: string) => props.view?.entities.items.get(id), [props.view]);

    if (!props.view) return <></>;

    const rootId = props.isSubLayout ? props.parentItem?.id : props.view.result.get(0);
    const rootItem = props.isSubLayout ? props.parentItem : props.view.entities.items.get(rootId);

    const scrollWrapper = (children?: React.ReactNode) => {
        const style: React.CSSProperties = {
            width: 'auto'
        };

        if (!props.isSubLayout) {
            style.height = '100%';
        } else {
            style.height = props.containerStyles.height;
            style.minHeight = '150px';
        }

        if (props.hasScrollbar) {
            return (
                <BCScrollbars styles={style} ref={scrollRef}>
                    {children}
                </BCScrollbars>
            )
        } else {
            return children;
        }
    }

    const renderCreator = () => {

        const pageClassNames: string = cx({
            page: true,
            isInputGroup: parentItemType === FormItemTypes.EPanelBaseTypes.InputGroup
        });

        const pageContainerClassNames: string = cx({
            pageContainer: true,
            isSubLayout: props.isSubLayout,
            [props.containerClassName]: true
        });

        return (
            <div className={Styles.body} ref={containerDomNode}>
                <div className={pageContainerClassNames}>
                    <div className={pageClassNames} style={props.containerStyles}>
                        {rootItem?.items.map((itemId: string) => {
                            const item = props.view.entities.items.get(itemId);

                            if (item.type === FormItemTypes.EContainerTypes.Section) {
                                const layout = item.designerProps.layout;
                                const hidden = !(layout.get("visible") && layout.get("clientVisible"));
                                if (!hidden) {
                                    return (
                                        <React.Fragment key={itemId}>
                                            <Section
                                                key={item.id}
                                                data={item}
                                                getItem={getItem}
                                                controlRenderManager={props.controlRenderManager}
                                                deviceType={deviceType}
                                                paddingType={props.paddingType}
                                                parentItemType={parentItemType as FormItemTypes.EControlTypes}
                                            />
                                        </React.Fragment>
                                    )
                                }
                            }

                            return undefined;

                        })}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {scrollWrapper(renderCreator())}
        </>
    );
}));

export { Container };

