import * as React from 'react';
import * as Style from '../assets/style.scss';
import { BCForm, BCFormItem, ValidateStatus, findMessage, BCInputMultiLanguage, IColProps } from '@bimser/components';
import { toLowerCase, MLHelper, ValidationHelper } from "@bimser/core";
import OnChangeFilePropertyPayload from '../entities/OnChangeFilePropertyPayload';
import IconOk from "@bimser/icons/16/ok";
import IconImportant from "@bimser/icons/16/mail-important";
import { IProfileFormFolder } from '../entities/IProfileFormFolder';


interface FolderOptionsProps {
    folderProps: IProfileFormFolder;
    onFolderPropChange: (payload: OnChangeFilePropertyPayload) => void;
}

const FolderOptions = (props: FolderOptionsProps) => {

    const regExp = {
        regExpString: new RegExp(ValidationHelper.RegularExpressions.DmFolderNameRegex),
        validationFailedMessage: findMessage.get("101682").replace('{0}', toLowerCase(findMessage.get('100362'))),
        allowNull: true,
    }

    const onNameChanged = (culture: string, value: string) => {
        if (value.match(regExp.regExpString) || value === "") {
            let name = {
                ...props.folderProps.folderName,
                [culture]: value.trim()
            };
            props.onFolderPropChange({
                propertyName: "folderName",
                value: name
            })
        }

    }

    const onDescChanged = (culture: string, value: string) => {
        let description = {
            ...props.folderProps.folderDescription,
            [culture]: value.trim()
        };
        props.onFolderPropChange({
            propertyName: "folderDescription",
            value: description
        })
    }

    const renderFolderStatus = () => {
        if (props.folderProps.hasValidation) {
            return (
                <div className={Style.folderStatusIcon}>
                    <IconImportant />
                </div>
            )
        } else if (props.folderProps.isFormsComplete) {
            return (
                <div className={Style.folderStatusIcon}>
                    <IconOk />
                </div>
            )
        } else {
            return (<div className={Style.folderStatusIcon}></div>)
        }
    }

    const itemLabelCol: IColProps = { span: 2 }
    const itemWrapperCol: IColProps = { span: 15, offset: 1 }

    return (
        <div className={Style.folderOptions}>
            <BCForm>
                <BCFormItem
                    validateStatus={ValidateStatus.validating}
                    label={findMessage.get("100770")}
                    required={true}
                    labelCol={itemLabelCol}
                    wrapperCol={itemWrapperCol}
                    style={{ textAlign: 'left' }}>
                    <BCInputMultiLanguage
                        regExp={regExp}
                        //overlayStyle={overlayStle}
                        supportedLanguages={MLHelper.supportedLanguages}
                        currentLanguage={MLHelper.currentLanguage}
                        onLanguageValueChange={onNameChanged}
                        values={props.folderProps.folderName}
                        size={'small'}
                        maxLength={255}
                    />
                </BCFormItem>


                <BCFormItem
                    validateStatus={ValidateStatus.validating}
                    label={findMessage.get("100772")}
                    required={false}
                    labelCol={itemLabelCol}
                    wrapperCol={itemWrapperCol}
                    style={{ textAlign: 'left' }}>
                    <BCInputMultiLanguage
                        regExp={regExp}
                        //overlayStyle={overlayStle}
                        supportedLanguages={MLHelper.supportedLanguages}
                        currentLanguage={MLHelper.currentLanguage}
                        onLanguageValueChange={onDescChanged}
                        values={props.folderProps.folderDescription}
                        size={'small'}
                        maxLength={1024}
                    />
                </BCFormItem>

            </BCForm>
            {renderFolderStatus()}
        </div>
    )
}

export default FolderOptions;