import React, { lazy, Suspense } from 'react';
import IPieChartLayoutProps from './entities/IProps';


const BCPieChartLazy = lazy(
    () => import(/* webpackChunkName: "chart" */ "./components")
);

function BCPieChart(props: IPieChartLayoutProps) {
    return (
        <Suspense fallback={<div></div>}>
            <BCPieChartLazy {...props} />
        </Suspense>
    );
}


export { BCPieChart, IPieChartLayoutProps };
export * from "./entities";