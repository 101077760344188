import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from "../forms";

export interface IBaseDepartmentPropertiesFormContent extends IBaseFormContent {
    selectedPropertyId?: number
}

export interface IDepartmentPropertiesFormContent extends IBaseDepartmentPropertiesFormContent {
}

export interface IReadonlyDepartmentPropertiesFormContent extends IBaseDepartmentPropertiesFormContent {
}

export type DepartmentPropertiesFormContent = ReadonlyRecord<IReadonlyDepartmentPropertiesFormContent>;

export const DepartmentPropertiesFormContentFactory = Record<IReadonlyDepartmentPropertiesFormContent>({
    panelId: "",
    showLoading: true,
    selectedPropertyId: -1,
    editWindowGuid: "",
    editWindowisActive: false,
    selectableListPanelisActive: false,
    isEventButtonsActive: true
});

export const DepartmentPropertiesFormContentFactoryFromJS = function (data: IDepartmentPropertiesFormContent) {
    return DepartmentPropertiesFormContentFactory({
        panelId: data.panelId,
        showLoading: data.showLoading,
        selectedPropertyId: data.selectedPropertyId,
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid,
        selectableListPanelisActive: data.selectableListPanelisActive,
        isEventButtonsActive: data.isEventButtonsActive
    });
}