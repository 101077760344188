import * as React from "react";
import * as ReactDOM from "react-dom";

import Button from 'antd/lib/button';
import Steps from 'antd/lib/steps';
import { IStepsProps, ISteps } from './entities';

const Step = Steps.Step;


export default class BCSteps extends React.Component<IStepsProps, IStepsProps> {
    readonly state: IStepsProps;

    constructor(props: IStepsProps) {
        super(props);
        this.onFinish = this.onFinish.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.state = this.props;
    }

    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }
    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    onFinish() {
        if (this.props.onFinish)
            this.props.onFinish();
    }

    containerStyle: React.CSSProperties = { padding: 20 };
    previousButtonStyle: React.CSSProperties = { marginLeft: 8 }
    render() {
        let current: number = this.state.current;
        return (
            <div style={this.containerStyle}>
                <Steps current={current}>
                    {this.state.data.map(item => <Step key={item.title} title={item.title} />)}
                </Steps>
                <div className="steps-content" style={this.containerStyle}>{this.props.data[this.state.current].content}</div>
                <div className="steps-action">
                    {
                        this.state.current < this.state.data.length - 1
                        &&
                        <Button type="primary" disabled={!this.props.data[this.state.current].isCompleted} onClick={this.next}>Next</Button>
                    }
                    {
                        this.state.current === this.state.data.length - 1
                        &&
                        <Button type="primary" disabled={!this.props.data[this.state.current].isCompleted} onClick={this.onFinish} >Finish</Button>
                    }
                    {
                        this.state.current > 0
                        &&
                        <Button style={this.previousButtonStyle} disabled={!this.props.data[this.state.current - 1].isCompleted} onClick={this.prev}>
                            Previous
                        </Button>
                    }
                </div>
            </div>

        );
    }
}

export { IStepsProps, ISteps }