import { createSyncAction, IBaseAction } from '@bimser/core';
import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from "../actions/actionTypes";
import { changeSelectedCultureAction } from '../actions';

export default function* watchChangeSelectedCultureTransaction() {
    yield takeEvery(ActionTypes.CHANGE_SELECTED_CULTURE_ACTION_SAGA, changeSelectedCultureTransaction);
}

export function* changeSelectedCultureTransaction(sagaAction?: IBaseAction<string>) {

    try {
        yield put(changeSelectedCultureAction(sagaAction.payload));//change state
        yield put(createSyncAction(ActionTypes.CHANGE_SELECTED_CULTURE_AFTER_ACTION, sagaAction.payload));
        window.location.reload();
    } catch (error) {
        console.log(error);
    }
}