import { ReadonlyRecord } from "@bimser/core";
import { Record } from "immutable";
import { IBaseFormContent } from ".";
import { Company, CompanyFactory, CompanyFactoryFromJS, ICompany } from "..";


export interface ICompanyListFormContent extends IBaseFormContent {
    selectedCompany?: ICompany
}

export interface IReadonlyCompanyListFormContent extends IBaseFormContent {
    selectedCompany?: Company
}

export type CompanyListFormContent = ReadonlyRecord<IReadonlyCompanyListFormContent>;

export const CompanyListFormContentFactory = Record<IReadonlyCompanyListFormContent>({
    panelId: "",
    selectedCompany: CompanyFactory(),
    editWindowisActive: false,
    editWindowGuid: "",
    showLoading: true
});

export const CompanyListFormContentFactoryFromJS = function (data: ICompanyListFormContent) {
    return CompanyListFormContentFactory({
        panelId: data.panelId,
        selectedCompany: CompanyFactoryFromJS(data.selectedCompany),
        editWindowisActive: data.editWindowisActive,
        editWindowGuid: data.editWindowGuid
    });
}