import { panelListSelectedDataChangeTransaction } from 'common/actions';
import { connect } from "react-redux";
import { WebInterfaceBaseState } from '../../../base/state';
import { IWebInterfaceAction } from '../../../entities';
import SelectionList from '../components';
import { ISelectionListCProps, ISelectionListProps } from "../entities";


// state to component properties
const mapStateToProps = function (state: WebInterfaceBaseState, props: ISelectionListProps): ISelectionListProps {
    return {
    };
}

// components callbacks to store dispatcher
const mapDispatchToProps = function (dispatch: Function): ISelectionListCProps {
    return {
        onSelectedChange: function (data: IWebInterfaceAction) {
            dispatch(panelListSelectedDataChangeTransaction(data));
        }
    };
}

const UserListFormConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(SelectionList);

export default UserListFormConnectedContainer;